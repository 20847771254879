/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef } from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const getGradient = (ctx, chartArea, start_color, stop_color) => {
    let width; let height; let
        gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
        gradient.addColorStop(0, stop_color);
        gradient.addColorStop(1, start_color);
    }
    return gradient;
};
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
);
export interface IPoint {
    x: number,
    y: string,
    content: {
        label: string,
    },
}
interface IPropsHorizontalChart {
    animation?: boolean;
    color?: string,
    alphaColor?: string,
    width?: string,
    height?: string,
    listPoints?: IPoint[]
    getImage?: (any) => void
}

const HorizontalChart: React.FC<IPropsHorizontalChart> = ({
    color = 'rgba(182, 86, 235, 1)',
    alphaColor = 'rgba(182, 86, 235, 0.15)',
    width = '253px',
    height = '499px',
    animation = true,
    listPoints,
    getImage,
}) => {
    const dataset = useMemo(() => ({
        type: 'bar' as const,
        label: 'Dataset 1',
        borderColor: 'rgba(0,0,0,0)',
        backgroundColor(context) {
            const { chart } = context;
            const {
                ctx,
                chartArea,
            } = chart;
            if (!chartArea) {
                return null;
            }
            return getGradient(ctx, chartArea, color, alphaColor);
        },
        borderWidth: 2,
        barPercentage: 0.8,
        categoryPercentage: 1,
        borderRadius: 6,
        fill: false,
        data: listPoints,
    }), [
        color,
        alphaColor,
        listPoints,
    ]);
    const chartRef = useRef(null);
    const dataSet = {
        events: ['click'],
        datasets: [dataset],
        plugin: [ChartDataLabels],

    };
    const tooltipHtml = {
        enabled: false,
    };
    const options: any = {
        animation,
        responsive: true,
        indexAxis: 'y',
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                max: (Math.max(...listPoints.map((i) => i.x)) * 0.2) + Math.max(...listPoints.map((i) => i.x)),
                grid: {
                    color: 'rgba(0,0,0,0)',
                },
            },
        },
        plugins: {
            datalabels: {
                align: 'end',
                anchor: 'end',
                formatter(value) {
                    return value.x;
                },
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: tooltipHtml,
        },
    };
    const getOptions = useMemo(() => options, [options]);
    useEffect(() => {
        // const ctx = chartRef?.current?.ctx;
        if (getImage && chartRef?.current && listPoints?.length) {
            setTimeout(() => getImage(chartRef?.current?.toBase64Image('image/png')), 1400);
        }
    }, [listPoints]);
    return (
        <div style={{
            height, width, position: 'relative', background: '#fff',
        }}
        >
            <Bubble plugins={[ChartDataLabels]} ref={chartRef} options={getOptions} data={dataSet as any} />
        </div>
    );
};

export default React.memo(HorizontalChart);
