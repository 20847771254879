import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PDFWrapperDownloadContainer from '../../../containers/common/PDFWrapper/DownloadLink';
import { useAppDispatch } from '../../../store/hooks';
import categorySelector from '../../../store/info-basket/category/selectors';
import { pdfHandlerReportActions } from '../../../store/pdf-handler/report';
import { PDFReportTypes } from '../../../store/pdf-handler/report/types';
import { pdfHandlerWrapperActions } from '../../../store/pdf-handler/wrapper';
import pdfHandlerWrapperSelectors from '../../../store/pdf-handler/wrapper/selectors';
import { FetchStatus } from '../../../store/types';

const useCreatePdfDocument = (): void => {
    const dispatch = useAppDispatch();
    const reportId = useSelector(pdfHandlerWrapperSelectors.reportId);
    const categoriesLoadingStatus = useSelector(categorySelector.loadingStatus);
    const campaignId = useSelector(pdfHandlerWrapperSelectors.campaignId);
    const reportName = useSelector(pdfHandlerWrapperSelectors.reportName);
    const chartsIds = useSelector(pdfHandlerWrapperSelectors.chartsIds);
    const timeFrame = useSelector(pdfHandlerWrapperSelectors.timeFrame);

    const loadingData = useMemo<boolean>(
        () => categoriesLoadingStatus === FetchStatus.loading,
        [categoriesLoadingStatus],
    );

    useEffect(() => {
        if (loadingData) {
            return;
        }
        if (reportId && campaignId) {
            dispatch(pdfHandlerWrapperActions.setReportInProcess(true));
            dispatch(pdfHandlerWrapperActions.createDownloadLink(<PDFWrapperDownloadContainer reportId={reportId} />));
            dispatch(pdfHandlerReportActions.addReport({
                id: reportId,
                name: reportName,
                type: PDFReportTypes.StandardMonitoring,
                pages: [],
                file: null,
                chartsIds,
                timeFrame,
                created: new Date().toString(),
                campaignId,
                downloadUrl: '',
                createTimeStamp: 0,
                generated: true,
            }));
        }
    }, [reportId, campaignId, reportName, loadingData, timeFrame, chartsIds]);
};

export default useCreatePdfDocument;
