import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Path from '@shared/lib/paths';
import ProjectInfo from '@features/project/create';
import { ICommonInfo } from '@features/project/types';
import { projectInfoRtkQService } from '@services/projects/info/common';
import styles from './styles.module.scss';

const ProjectCreate: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const [info, setInfo] = useState<ICommonInfo>(null);

    const [create, { data, isLoading, isFetching }] = projectInfoRtkQService.useLazyPostCreateProjectQuery();

    const handlerCreate = (value: ICommonInfo): void => {
        setInfo(value);
        create({ name: value?.name, description: value?.description });
    };

    useEffect(() => {
        if (!isLoading && !isFetching && data?.id) {
            navigate(`/${Path.Projects}/${data?.id}`);
        }
    }, [data, isLoading, isFetching]);

    return (
        <div className={styles.projectCreate_root}>
            <span className={styles.projectCreate_title}>Общая информация</span>
            <ProjectInfo info={info} onCreate={handlerCreate} />
        </div>
    );
};

export default ProjectCreate;
