import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISearchRequestState {
    campaignId: number | null;
    searchRequest: string;
}

const initialState: ISearchRequestState = {
    campaignId: null,
    searchRequest: '',
};

const { actions: campaignSearchRequestActions, reducer } = createSlice({
    name: 'searchRequest',
    initialState,
    reducers: {
        setSearchRequest: (state: ISearchRequestState, { payload }: PayloadAction<ISearchRequestState>): void => {
            if (Number(payload?.campaignId) > 0) {
                state.campaignId = payload?.campaignId;
                state.searchRequest = payload?.searchRequest;
            }
        },
    },
});

export { campaignSearchRequestActions };
export default reducer;
