/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams } from 'react-router';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { postsRtkQService } from '@services/campaigns/posts';
import ModalCardPlot from '@components/ModalCardPlot';
import { IApplyFilter, IPost } from '@sections/MyCampaignContainer/CampaingTypes';
import { selectCommonWindowIsIPadSize } from '@store/common/window/selectors';
import ModalDynamicChart from '@components/ModalDynamicChart';
import { Post } from '@features/campaign/tracking';
import CardPlotSkeleton from '@shared/CardPlotSkeleton';
import BloggerProfileContainer from '@containers/info-basket/Blogger/BloggerProfileContainer';
import styles from './styles.module.scss';

const CampaignBasketPage: React.FC = (): React.ReactElement => {
    const { Id: campaignId } = useParams<{ Id: string }>();
    const { data: deletedPosts, isFetching: isDeletedPostsFetching } = postsRtkQService.useGetDeletedPostsQuery({
        campaignId: Number(campaignId),
    });

    const [bloggerModalIsOpen, setOpenBloggerModal] = useState<boolean>(false);
    const [bloggerModalAuthorId, setBloggerModalAuthorId] = useState<number>(0);
    const [isOpenMoreModal, setIsOpenMoreModal] = useState<boolean>(false);
    const [selectPlot, setSelectPlot] = useState<IPost>(null);
    const [isOpenModalDinmayic, openModalDinmaiyc] = useState(false);
    const [restorePost] = postsRtkQService.usePostRestoreMutation();
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);
    const [dateModalPost, setDateModalPost] = useState(null);
    const [pid, setPid] = useState<any>(null);
    const [postData, setPostData] = useState<any>(null);
    // TODO: complete for ModalDynamicChart
    const [applyFilters, setApplyFilters] = useState <Array<IApplyFilter>>([]);

    const openBloggerModal = (authorId: number, statusModal: boolean): void => {
        setBloggerModalAuthorId(authorId);
        setOpenBloggerModal(statusModal);
    };

    const openMoreModal = (post: IPost): void => {
        setIsOpenMoreModal(true);
        setSelectPlot(post);
    };

    const handleRestorePost = (postId: number): void => {
        restorePost({
            campaignId: Number(campaignId),
            id: postId,
        });
    };

    return (
        <>
            <div className={styles.list}>
                {isDeletedPostsFetching
                    && (
                        <>
                            <CardPlotSkeleton />
                            <CardPlotSkeleton />
                            <CardPlotSkeleton />
                            <CardPlotSkeleton />
                            <CardPlotSkeleton />
                        </>
                    )}
                {deletedPosts && !isDeletedPostsFetching && deletedPosts.map((card) => (
                    <Post
                        key={`${card.last_update}_${card.source_name}_cardKey`}
                        openBloggerModal={openBloggerModal}
                        openMoreModal={openMoreModal}
                        openModalChartDinamiyc={(date, id, post) => {
                            setPid(id);
                            setDateModalPost(new Date(date).getTime());
                            setPostData({
                                comments: post?.comment,
                                group: post?.group,
                                label: `${post?.post_id}`,
                                link: post?.post_link,
                                likes: post?.likes,
                                platform: post?.source_type,
                                posted: post?.posted,
                                source: post?.author_name,
                                source_link: post?.post_link,
                                source_subscribers: post?.source_subscribers,
                            });
                            openModalDinmaiyc(true);
                        }}
                        post={card}
                        isIPadSize={isIPadSize}
                        allowRestore
                        handleRestorePost={handleRestorePost}
                    />
                ))}
            </div>

            <ModalDynamicChart
                isOpen={isOpenModalDinmayic}
                close={() => openModalDinmaiyc(false)}
                postDate={dateModalPost}
                pid={pid}
                postData={postData}
                campaign_id={Number(campaignId)}
                filter_apply={applyFilters}
            />

            <ModalCardPlot
                isOpen={isOpenMoreModal}
                post={selectPlot}
                closeModal={() => setIsOpenMoreModal(false)}
            />

            <BloggerProfileContainer
                isOpen={bloggerModalIsOpen}
                authorId={bloggerModalAuthorId}
                setIsOpen={setOpenBloggerModal}
            />
        </>
    );
};
export default CampaignBasketPage;
