/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { TitleChart } from '../../../style';
import DoughnutChart from '../../../../../components/DoughnutChart';

const { Panel } = Collapse;

export const Height = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #DFFCF7;
    border-radius: 6px;
`;
export const Low = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #FEF8E8;
    border-radius: 6px;
`;
const demoPieData = [
    {
        value: 24,
        label: 'vk',
        color: 'blue',
        borderColor: 'black',
    },
    {
        value: 12,
        label: 'ok',
        color: 'orange',
        borderColor: 'black',
    },
    {
        value: 4,
        label: 'tg',
        color: 'lightblue',
        borderColor: 'black',
    },
    {
        value: 10,
        label: 'rss',
        color: 'green',
        borderColor: 'black',
    },
    {
        value: 27,
        label: 'discord',
        color: 'purple',
        borderColor: 'black',
    },
    {
        value: 15,
        label: 'fb',
        color: 'tomato',
        borderColor: 'black',
    },
];
const DoughnutChartDemo = () => (
    <Collapse>
        <Panel
            key="ChartPublicationDemo"
            header={<TitleChart style={{ marginBottom: '12px' }}>Пай чарт с выносом данных</TitleChart>}
        >
            <div style={{ marginTop: '16px' }}>
                <DoughnutChart
                    type="pie"
                    height="300px"
                    width="300px"
                    valueList={demoPieData as any}
                />
            </div>
        </Panel>
    </Collapse>
);
export default DoughnutChartDemo;
