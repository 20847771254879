import TextField from '@entities/TextField';
import { useState } from 'react';
import useValidation from '@shared/hooks/validations/useValidations';
import styles from './styles.module.scss';

const Validations : React.FC = (): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState<string>('');
    const [phone, setNewPhone, phoneError, phoneTooltip] = useValidation({
        value: '',
        rules: 'phone',
        infoWithReplace: 'Допустимы только цифры',
    });
    const [email, setEmail, emailError] = useValidation({
        value: '',
        rules: 'email',
        infoWithReplace: 'Допустимы только цифры',
    });
    const [custom, setCustom, customError, customTooltip] = useValidation(
        {
            value: '',
            rules: (valuetest, callToltip) => {
                if (valuetest.length > 12) callToltip();
                const test = {
                    status: !/[0-9]/.test(valuetest),
                    replaced: valuetest.slice(0, 12),
                    error: 'Любая ошибка, тут ошибка будет если есть числа',
                };
                return test;
            },
            infoWithReplace: 'любавя сопроводительная инфа, тут пусть меньше 12 длина',
        },
    );
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <h1>Валидация</h1>

                <div className={styles.block}>
                    <TextField
                        value="value"
                        onChange={setValue}
                        label="Ограничение по кол-ву"
                        limit={10}
                    />
                    <TextField value={phone} onChange={setNewPhone} label="Телефон" errorText={phoneError} info={phoneTooltip} />
                    <TextField value="value" onChange={setValue} label="Невалидный" errorText="Текст ошибки" />
                </div>

                <div className={styles.block}>
                    <TextField value={email} onChange={setEmail} label="email" errorText={emailError} />
                    <TextField value={custom} onChange={setCustom} label="CUSTOM" errorText={customError} info={customTooltip} />
                </div>
            </div>
        </div>
    );
};
export default Validations;
