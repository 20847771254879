import { useEffect, useRef, useState } from 'react';
import { Button, SvgIcon } from '@shared/index';
import TextField from '@entities/TextField';
import TextArea from '@entities/TextArea';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { sourcesListsRtkQService } from '@services/sources/lists';
import { CommonIconsIds } from '@app/constants/icons';
import styles from './styles.module.scss';

interface IProps {
    sourceId?: number;
    sourceTitle?: string;
    onClose: () => void;
}

const SourceListCreateModal : React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { sourceId, sourceTitle, onClose } = props;
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [source, setSource] = useState<number | null>(sourceId);
    const modalRef = useRef(null);

    const [createList, { isSuccess: isCreatingSuccess }] = sourcesListsRtkQService.usePostCreateListMutation();

    const handleClickClose = (): void => {
        onClose();
        setSuccess(false);
        setSource(sourceId);
    };

    const handleClickCreate = (): void => {
        createList({
            title: name,
            description,
            sourceId: source,
        });
    };

    useEffect(() => {
        setSuccess(isCreatingSuccess);
        if (isCreatingSuccess) {
            setName('');
            setDescription('');
        }
    }, [isCreatingSuccess]);

    useOnClickOutside(modalRef, handleClickClose);

    return (

        <div className={styles.modal}>
            {isSuccess ? (
                <div ref={modalRef} className={styles.successMessage}>
                    <SvgIcon id={CommonIconsIds.IconComplete} size={32} />
                    <p>Список создан</p>
                </div>
            )
                : (
                    <div className={styles.modalContainer}>
                        <span className={styles.modalTitle}>Создание нового списка</span>
                        <TextField
                            value={name}
                            onChange={(value) => setName(value)}
                            label="Наименование"
                            limit={150}
                            placeholder="Введите наименование"
                        />
                        <TextArea
                            value={description}
                            onChange={(value) => setDescription(value)}
                            label="Описание"
                            limit={1000}
                        />
                        {source
                                    && (
                                        <div className={styles.textFieldSource}>
                                            <TextField
                                                value={sourceTitle}
                                                onChange={() => {}}
                                                label="Источник"
                                                size="medium"
                                                readonly
                                            />
                                            <button type="button" onClick={() => setSource(null)}>
                                                <SvgIcon id="BigCross" size={18} />
                                            </button>
                                        </div>
                                    )}

                        <div className={styles.modalButtons}>
                            <Button text="Отменить" type="secondary" size="large" onClick={handleClickClose} />
                            <Button text="Создать" type="default" size="large" onClick={handleClickCreate} />
                        </div>
                    </div>
                )}
        </div>
    );
};
export default SourceListCreateModal;
