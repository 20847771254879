import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ICustomerInfo, IProjectStatus, IQPCustomer } from '@services/projects/types';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';
import styles from './styles.module.scss';
import ContactsCustomer from './CustomerContacts';

interface IPropsContactsProject {
    isLoading: boolean;
    customer: ICustomerInfo;
    onSaveContacts: (value: IQPCustomer) => void;
    status: IProjectStatus;
}

// eslint-disable-next-line arrow-body-style
const ContactsProject: React.FC<IPropsContactsProject> = ({
    isLoading,
    customer,
    onSaveContacts,
    status,
}): JSX.Element => (
    <DefaultWrapperProject style={{ marginBottom: 16 }}>
        <>
            {
                isLoading && (
                    <Skeleton height={100} width={500} />
                )
            }
            {
                !isLoading && (
                    <div className={styles.root_ContactsProject}>
                        <ContactsCustomer customer={customer} onSave={onSaveContacts} status={status} />
                    </div>
                )
            }
        </>
    </DefaultWrapperProject>
);

export default ContactsProject;
