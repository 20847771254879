/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { sourcesRtkQService } from '@services/sources';
import SourceFilter from '../InformationBlock';
import LightBar from '../LightBar';
import LightLine from '../LightLine';
import VerticalInfo from '../VerticalInfo';
import styles from './styles.module.scss';

interface IPropsContentWidget {
    sourceId: number,
    tab: number,
}

const ContentWidget: React.FC<IPropsContentWidget> = ({
    sourceId,
    tab,
}): JSX.Element => {
    // const [isLoading, setIsLoading] = useState(true);
    const { data, isLoading } = sourcesRtkQService.useGetStatQuery({ id: sourceId });
    const sourceFiltersInfo = useMemo(() => {
        if (!data) return null;
        return data.reduce((acc, item) => {
            const newAcc = { ...acc };
            newAcc[item.name] = { ...item };
            return newAcc;
        }, {});
    }, [data]);
    const getDetailInfo = (list) => {
        if (!list || !list?.length) return null;
        return list.map((item) => ({
            data: item?.value,
            offset: 0,
            label: item?.name,
        }));
    };
    const getChartInfo = (list, isFiltred = true) => {
        if (!list?.x?.length || list?.list?.x?.length < 2) return null;
        // const filtredListX = list.x.filter((x, i) => list.y[i]);
        // const filtredListY = list.y.filter((y) => y);
        const formatList = list.x.reduce((acc, x, i) => {
            const newAcc = [...acc];
            const point = {
                x,
                y: list.y[i],
            };
            if (isFiltred) { if (point?.y) newAcc.push(point); } else newAcc.push(point);
            return newAcc;
        }, []);
        return formatList;
    };
    const getNumberForTotal = (str, nt = 1) => {
        if (!str) return '';
        if (str === '0') return '0';
        return `${(+(+str).toFixed(nt)).toLocaleString('ru-RU')}`;
    };
    const getSubscribers = useMemo(() => {
        if (!sourceFiltersInfo || !sourceFiltersInfo?.subscribers) return null;
        const defaultDetailInfo = [
            {
                data: 0,
                offset: 0,
                label: 'сегодня',
            },
            {
                data: 0,
                offset: 0,
                label: 'неделя',
            },
            {
                data: 0,
                offset: 0,
                label: 'месяц',
            },
        ];
        return {
            total: sourceFiltersInfo?.subscribers?.total ? getNumberForTotal(sourceFiltersInfo.subscribers.total, 0) : '',
            detailInfo: getDetailInfo(sourceFiltersInfo.subscribers.addData) || defaultDetailInfo,
            chart: getChartInfo(sourceFiltersInfo.subscribers?.chart),
        };
    }, [sourceFiltersInfo]);
    const getDetailEr = useMemo(() => {
        if (!sourceFiltersInfo || !sourceFiltersInfo?.er) return null;
        const defaultDetailInfo = [
            {
                data: 0,
                offset: 0,
                label: 'комментарии',
            },
            {
                data: 0,
                offset: 0,
                label: 'репосты',
            },
            {
                data: 0,
                offset: 0,
                label: 'реакции',
            },
        ];
        return {
            total: sourceFiltersInfo?.er?.total ? `${getNumberForTotal(sourceFiltersInfo?.er?.total)}%` : '',
            detailInfo: getDetailInfo(sourceFiltersInfo.er.addData) || defaultDetailInfo,
            chart: getChartInfo(sourceFiltersInfo.er?.chart),
        };
    }, [sourceFiltersInfo]);
    const getCoverage = useMemo(() => {
        if (!sourceFiltersInfo || !sourceFiltersInfo?.coverage) return null;
        const defaultDetailInfo = [
            {
                data: 0,
                offset: 0,
                label: '12 часов',
            },
            {
                data: 0,
                offset: 0,
                label: '24 часа',
            },
            {
                data: 0,
                offset: 0,
                label: '48 часов',
            },
        ];
        const total = (
            <span>
                {getNumberForTotal(sourceFiltersInfo?.coverage?.total, 0)}
                <span style={{ fontSize: 12, color: '#262626', marginLeft: 4 }}>
                    просмотров
                </span>
            </span>
        );
        return {
            total: sourceFiltersInfo?.coverage?.total ? total : '',
            detailInfo: getDetailInfo(sourceFiltersInfo.coverage.addData) || defaultDetailInfo,
            chart: getChartInfo(sourceFiltersInfo.coverage?.chart),
        };
    }, [sourceFiltersInfo]);
    const getPosts = useMemo(() => {
        if (!sourceFiltersInfo || !sourceFiltersInfo?.posts) return null;
        const defaultDetailInfo = [
            {
                data: 0,
                offset: 0,
                label: 'комментарии',
            },
            {
                data: 0,
                offset: 0,
                label: 'репосты',
            },
            {
                data: 0,
                offset: 0,
                label: 'реакции',
            },
        ];
        return {
            total: sourceFiltersInfo?.posts.total ? getNumberForTotal(sourceFiltersInfo?.posts.total) : '',
            detailInfo: getDetailInfo(sourceFiltersInfo.posts.addData) || defaultDetailInfo,
            chart: getChartInfo(sourceFiltersInfo.posts?.chart, false),
        };
    }, [sourceFiltersInfo]);
    const getErr24 = useMemo(() => {
        if (!sourceFiltersInfo || !sourceFiltersInfo?.err24 || !sourceFiltersInfo?.err24?.total) return '-';
        const totalNumber = sourceFiltersInfo?.err24?.total;
        return `${(+(+totalNumber).toFixed(1)).toLocaleString('RU-ru')} %`;
    }, [sourceFiltersInfo]);
    const getErr = useMemo(() => {
        if (!sourceFiltersInfo || !sourceFiltersInfo?.err || !sourceFiltersInfo?.err?.total) return '-';
        const totalNumber = sourceFiltersInfo?.err?.total;
        return `${(+(+totalNumber).toFixed(1)).toLocaleString('RU-ru')} %`;
    }, [sourceFiltersInfo]);
    return (
        <div className={styles.root_ContentWidget}>
            <div className={styles.level}>
                <SourceFilter
                    label="Подписчики"
                    importantDetail={getSubscribers?.total}
                    detailInfo={getSubscribers?.detailInfo}
                    isHaveImortantDetail
                    isLoading={isLoading}
                    skeleton={{ width: 250, height: 85 }}
                >
                    <LightLine newData={getSubscribers?.chart} />
                </SourceFilter>
                <SourceFilter
                    label="ER"
                    importantDetail={getDetailEr?.total}
                    detailInfo={getDetailEr?.detailInfo}
                    isHaveImortantDetail
                    isLoading={isLoading}
                    skeleton={{ width: 250, height: 85 }}
                >
                    <LightLine newData={getDetailEr?.chart} />
                </SourceFilter>
                <SourceFilter
                    label="Средний охват"
                    importantDetail={getCoverage?.total}
                    detailInfo={getCoverage?.detailInfo}
                    isHaveImortantDetail
                    isLoading={isLoading}
                    skeleton={{ width: 250, height: 85 }}
                >
                    <LightLine newData={getCoverage?.chart} />
                </SourceFilter>
            </div>
            <div className={styles.level}>
                <VerticalInfo
                    err24={getErr24}
                    err={getErr}
                    isLoading={isLoading}
                />
                <SourceFilter
                    label="Публикации"
                    importantDetail={getPosts?.total}
                    detailInfo={getPosts?.detailInfo}
                    isHaveImortantDetail
                    isLoading={isLoading}
                    skeleton={{ width: 757, height: 85 }}
                >
                    <LightBar newData={getPosts?.chart} />
                    {/* <div style={{ width: 777, height: 85, backgroundColor: '#0EBFA1' }} /> */}
                </SourceFilter>
            </div>
        </div>
    );
};
export default ContentWidget;
