import React from 'react';
import { IProjectStatus } from '@services/projects/types';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';
import StatusInfo from './Status';

interface IPropsBlockStatusProjects {
    isLoading: boolean;
    status: IProjectStatus;
    projectId: number;
    onCloseProject: () => void
}

// eslint-disable-next-line arrow-body-style
const BlockStatusProjects: React.FC<IPropsBlockStatusProjects> = ({
    isLoading, status, projectId, onCloseProject,
}): JSX.Element => (
    <div className={styles.root_BlockStatusProjects}>
        {
            isLoading && <Skeleton height={40} width="100%" />
        }
        {
            !isLoading && <StatusInfo status={status} projectId={projectId} onCloseProject={onCloseProject} />
        }
    </div>
);

export default BlockStatusProjects;
