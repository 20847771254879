import { rtkQApi } from '../../index';
import {
    ICampaignComparisonList,
    ICampaignComparisonListFromApi,
    IQPSaveCampaignComparison,
} from './types';

export const campaignComparisonRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getCampaignComparisonAnalysis: build.query<ICampaignComparisonList[], void>({
            query: () => ({
                url: 'campaign_analysis/get_all_analysis',
                method: 'GET',
            }),
            transformResponse: (
                baseQueryResponse: ICampaignComparisonListFromApi[],
            ): ICampaignComparisonList[] => baseQueryResponse.map((item) => ({
                id: item.id,
                compared_campaigns: item.compared_campaigns?.map((compCompaign) => (
                    { id: compCompaign.compared_campaign_id, name: compCompaign.name }
                )),
                campaign: { id: item.campaign.campaign_id, name: item.campaign.name },
                name: item.name,
                start_date: item.start_date,
                end_date: item.end_date,
            } as ICampaignComparisonList)),
            providesTags: ['CampaignsComparisonList'],
        }),
        getCampaignComparisonAnalysisById: build.query<ICampaignComparisonListFromApi, number>({
            query: (analysis_id) => ({
                url: `campaign_analysis/${analysis_id}`,
                method: 'GET',
            }),
        }),
        postSaveComparisonResult: build.query<ICampaignComparisonListFromApi, IQPSaveCampaignComparison>({
            query: (body) => ({
                url: 'campaign_analysis/',
                method: 'POST',
                body,
            }),
        }),
        deleteCampaignComparisonAnalysis: build.mutation<void, number>({
            query: (analysis_id) => ({
                url: `campaign_analysis/${analysis_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CampaignsComparisonList'],
        }),
    }),
});
