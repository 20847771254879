import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampaignUserRightsState, ICampaignUsersData } from './types';

const initialState: ICampaignUserRightsState = {
    usersState: [],
};

const { actions: campaignUserRightsActions, reducer } = createSlice({
    name: 'userRights',
    initialState,
    reducers: {
        setUsersState: (state: ICampaignUserRightsState, { payload }: PayloadAction<ICampaignUsersData>): void => {
            const exist = state.usersState.filter((item) => item.campaignId === payload.campaignId);

            if (exist.length >= 1) {
                state.usersState = state.usersState.map((usersState) => {
                    if (usersState.campaignId === payload.campaignId) return { campaignId: usersState.campaignId, users: payload.users };
                    return usersState;
                });
            } else {
                state.usersState = state.usersState.concat(payload);
            }
        },
    },
});

export { campaignUserRightsActions };
export default reducer;
