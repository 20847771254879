import React from 'react';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IProps {
    text: string,
}
const EditDate: React.FC<IProps> = ({ text }): JSX.Element => {
    const handler = () => {};
    return (
        <div className={styles.editDate_root}>
            {handler}
            <SvgIcon id="Calendar" size={18} />
            <span className={styles.editDate_text}>{text}</span>
        </div>
    );
};

export default EditDate;
