import React, { useMemo } from 'react';
import { SvgIcon } from '@shared/index';
import classnames from 'classnames';
import styles from './styles.module.scss';

export interface IProps {
    isLoading?: boolean,
    iconId: string,
    iconSize?: number,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    className?: string;
    width?: number;
    height?: number;
}

const IconButton: React.FC<IProps> = ({
    isLoading = false,
    iconId,
    iconSize,
    width,
    height,
    onClick,
    className = '',
}): JSX.Element => {
    const classNames = classnames(isLoading && styles.isLoad, styles.root_iconButton, className);
    const icon = useMemo(() => {
        if (width && height) {
            return <SvgIcon width={width} height={height} id={iconId} />;
        }
        if (iconSize) {
            return <SvgIcon size={iconSize} id={iconId} />;
        }
        return <SvgIcon size={24} id={iconId} />;
    }, [iconSize, width, height]);
    return (
        <div className={classNames}>
            <div
                role="button"
                className={styles.iconButton}
                onClick={(e) => onClick && onClick(e)}
            >
                <div className={styles.icn}>
                    {icon}
                </div>
            </div>
            {isLoading && (
                <div className={styles.loading}>
                    <span />
                </div>
            )}
        </div>
    );
};

export default React.memo(IconButton);
