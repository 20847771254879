import React, { useMemo, useState } from 'react';
import { INumbersInterval } from 'services/sources/types';
import TextField from '@entities/TextField';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './styles.module.scss';

interface IProps {
    interval: INumbersInterval;
    onChangeValue: (value: INumbersInterval) => void;
    unitsMeasurement?: string;
    minValue: number;
    maxValue: number;
}

const AdvancedSearchRange: React.FC<IProps> = ({
    minValue,
    maxValue,
    interval,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    unitsMeasurement,
    onChangeValue,
}): JSX.Element => {
    const [valueFrom, setValueFrom] = useState<string>(
        () => (minValue !== interval?.from ? interval?.from?.toString() ?? '' : ''),
    );
    const [valueTo, setValueTo] = useState<string>(
        () => (maxValue !== interval?.to ? interval?.to?.toString() ?? '' : ''),
    );

    const placeholderFrom = useMemo<string>(
        () => (minValue !== interval?.from ? interval?.from?.toString() ?? '' : ''),
        [minValue, interval],
    );

    const placeholderTo = useMemo<string>(
        () => (maxValue !== interval?.to ? interval?.to?.toString() ?? '' : ''),
        [maxValue, interval],
    );

    const onChangeFrom = (value: string): void => {
        let inputValue = Number(value.replace(/[^+\d]/g, ''));
        inputValue = inputValue > maxValue ? maxValue : inputValue;
        onChangeValue({ from: inputValue >= interval.to ? interval.to - 1 : inputValue, to: interval.to });
        setValueFrom(inputValue ? inputValue.toString() : '');
    };
    const onChangeTo = (value: string): void => {
        let inputValue = Number(value.replace(/[^+\d]/g, ''));
        inputValue = (inputValue > maxValue || inputValue === 0) ? maxValue : inputValue;
        if (inputValue) {
            onChangeValue({ from: interval.from, to: inputValue > maxValue ? maxValue : inputValue });
        }
        setValueTo(inputValue ? inputValue.toString() : '');
    };

    return (
        <div style={{
            display: 'flex', gap: 16, alignItems: 'center', padding: '0 12px',
        }}
        >
            <span className={styles.label}>от</span>
            <TextField
                value={valueFrom}
                size="medium"
                onChange={onChangeFrom}
                label=""
                placeholder={placeholderFrom || minValue.toLocaleString('ru-RU')}
            />
            <span className={styles.label}>до</span>
            <TextField
                value={valueTo}
                size="medium"
                onChange={onChangeTo}
                label=""
                placeholder={placeholderTo || maxValue.toLocaleString('ru-RU')}
            />
        </div>
    );
};

export default AdvancedSearchRange;
