/* eslint-disable no-underscore-dangle */
const getBuilderPoint = (
    ctx,
    oldData,
    labelsOffset,
    centerX,
    centerY,
    index,
    bisectorAngle,
    valueList,
) => {
    const endX = centerX + labelsOffset * Math.cos(bisectorAngle);
    let endY = centerY + labelsOffset * Math.sin(bisectorAngle);
    const offset = endX > 150 ? 1 : -1;
    const text = `${valueList[index]?.value.toLocaleString('ru-Ru')} ${valueList[index]?.label}`;
    const matrix = ctx.measureText(text);
    const widthText = matrix.width;
    const check = oldData.find((prev) => {
        const diffX = Math.abs(prev.endX - endX);
        const diffY = Math.abs(prev.endY - endY);
        if (diffY < 18 && diffX < 20) return true;
        return false;
    });
    if (check) {
        endY += 22 * offset;
    }
    return {
        labelEndX: endX + ((widthText + 16) * offset),
        endX,
        endY,
        offset,
        text,
        widthText,
    };
};

const getBuilderData = (
    ctx,
    oldData,
    labelsOffset,
    centerX,
    centerY,
    startAngle,
    endAngle,
    index,
    max,
    valueList,
) => {
    const angleDif = (endAngle - startAngle) / 2;
    let bisectorAngle = startAngle + angleDif;
    const degsInRod = 0.0872665; // 5 deg
    let textBuilderPoint = getBuilderPoint(
        ctx,
        oldData,
        labelsOffset,
        centerX,
        centerY,
        index,
        bisectorAngle,
        valueList,

    );
    while (textBuilderPoint.labelEndX < 15) {
        bisectorAngle -= degsInRod;
        textBuilderPoint = getBuilderPoint(
            ctx,
            oldData,
            labelsOffset,
            centerX,
            centerY,
            index,
            bisectorAngle,
            valueList,

        );
    }
    while (textBuilderPoint.labelEndX >= max) {
        bisectorAngle -= degsInRod;
        textBuilderPoint = getBuilderPoint(
            ctx,
            oldData,
            labelsOffset,
            centerX,
            centerY,
            index,
            bisectorAngle,
            valueList,
        );
    }
    return {
        ...textBuilderPoint,
        bisectorAngle,
    };
};

export const pieLabels = {
    id: 'labels',
    beforeDraw(chart) {
        const color = 'black';
        const { ctx, config } = chart;
        if (!config?._config?.options?.plugins?.pieLabels) return;
        const valueList = config?._config?.options?.plugins?.pieLabels;
        const labelsOffset = 100;
        const sets = chart?._metasets[0];
        const centerX = chart.width / 2;
        const centerY = chart.height / 2;
        const data = sets?.data;
        data.reduce((acc, point, index) => {
            const endAngle = point?.endAngle;
            const startAngle = point?.startAngle;
            const builderData = getBuilderData(
                ctx,
                acc,
                labelsOffset,
                centerX,
                centerY,
                startAngle,
                endAngle,
                index,
                chart.width - 15,
                valueList,
            );
            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                bisectorAngle, endX, endY, labelEndX, offset, text, widthText,
            } = builderData;
            ctx.beginPath();
            ctx.save();
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(endX, endY);
            ctx.strokeStyle = valueList[index]?.color || color;
            ctx.stroke();
            ctx.closePath();
            ctx.restore();
            ctx.beginPath();

            ctx.beginPath();
            ctx.save();
            ctx.font = 'bold 20px';
            const xText = offset > 0 ? endX + 8 : (endX - (widthText + 8));
            const yText = endY > 150 ? endY + 16 : endY - 8;
            ctx.fillStyle = valueList[index]?.color || color;
            ctx.fillText(text, xText, yText);
            ctx.restore();

            ctx.beginPath();
            ctx.save();
            ctx.moveTo(endX, endY);
            ctx.lineTo(labelEndX, endY);
            ctx.strokeStyle = valueList[index]?.color || color;
            ctx.stroke();
            ctx.closePath();
            ctx.restore();
            acc.push();
            acc.push({
                xText,
                yText,
                ...builderData,
            });
            return acc;
        }, []);
    },
};
