import React, { useEffect, useState } from 'react';
import { DateRangePickerValue } from '@components/DateRangePicker/types';
import { campaignTagsRtkQService } from '@services/campaigns/tags';
import {
    IQueryParamsLoadTags, IQuerySortFieldsLoadTags, ITagModel,
} from '@services/campaigns/tags/types';
import { SortOrder } from '@services/sources/types';
import { ITableColumn } from '@entities/Table/types';
import Table from '@entities/Table/index';
import styles from './styles.module.scss';

interface IProps {
    period: DateRangePickerValue;
    campaignid: number;
}

const limit = 100;

const CampaignTagsTable: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { period, campaignid } = props;
    const [getTags, {
        data: tags,
        isFetching: isTagsFetching,
        isSuccess: isTagsSuccess,
        isError: isTagsError,
    }] = campaignTagsRtkQService.useLazyPostLoadTagsQuery();
    const [tagsList, setTagsList] = useState<ITagModel[]>([]);
    const [acceptIntersection, setAcceptIntersection] = useState<boolean>(true);
    const [params, setParams] = useState<IQueryParamsLoadTags>({
        campaign_id: campaignid,
        page: 1,
        from_date: period[0].format('YYYY-MM-DD'),
        to_date: period[1].format('YYYY-MM-DD'),
    });

    useEffect(() => {
        getTags(params).finally(() => setAcceptIntersection(true));
    }, [params]);

    useEffect(() => {
        setAcceptIntersection(false);
        setParams((prev) => ({
            ...prev,
            from_date: period[0].format('YYYY-MM-DD'),
            to_date: period[1].format('YYYY-MM-DD'),
            page: 1,
        }));
        setTagsList([]);
    }, [period]);

    useEffect(() => {
        if (tags && tags.length >= 1) {
            // TODO: remove after adding id from backend
            const arr = tags.map((item, index) => ({ ...item, id: tagsList.length + index }));
            setTagsList((prev) => prev.concat(arr));
        } else {
            setTagsList([]);
        }
    }, [tags]);

    const onScroll = (): void => {
        if (tags && tags.length >= limit && !isTagsFetching) {
            setParams((prev) => ({ ...prev, page: prev.page + 1 }));
            setAcceptIntersection(false);
        }
    };

    const columns: ITableColumn<ITagModel>[] = [
        {
            id: 1,
            title: null,
            dataIndex: 'id',
            width: 70,
            isIndex: true,
        },
        {
            id: 2,
            title: 'Тег ',
            dataIndex: 'title',
        },
        {
            id: 3,
            title: '% ',
            dataIndex: 'percent',
            render: (value) => (
                <span>
                    {value}
                    {' '}
                    %
                </span>
            ),
            sortable: true,
        },
        {
            id: 4,
            title: 'Сообщения ',
            dataIndex: 'postsCount',
            sortable: true,
        },
        {
            id: 5,
            title: 'Комментарии',
            dataIndex: 'comments',
            sortable: true,
        },
        {
            id: 6,
            title: 'Просмотры',
            dataIndex: 'views',
            sortable: true,
        },
        {
            id: 7,
            title: 'Вовлеченность',
            dataIndex: 'reactions',
            sortable: true,
        },
    ];

    const onSort = (column: ITableColumn<ITagModel>, order: SortOrder): void => {
        setAcceptIntersection(false);
        setParams((prev) => ({
            ...prev,
            order,
            column: IQuerySortFieldsLoadTags[column.dataIndex],
            page: 1,
        }));
        setTagsList([]);
    };

    return (
        <div className={styles.table}>
            <Table
                columns={columns}
                rows={tagsList}
                isLoading={isTagsFetching}
                onSort={onSort}
                onScroll={onScroll}
                scrollCallbackEnable={acceptIntersection}
            />

            <div className={styles.body}>
                {tags?.length < 1 && isTagsSuccess && !isTagsFetching && (
                    <div className={styles.noData}>
                        <p>Нет данных</p>
                    </div>
                )}
                {isTagsError && (
                    <div className={styles.error}>
                        <p>Произошла ошибка при получении данных</p>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CampaignTagsTable;
