export const ListNameDayInWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export type TypeStartDateList = 'week' | 'month';
export interface IDay {
    date: moment.Moment,
    formatDate: string,
    isChecked: boolean,
    weekDayCount: number
}
export interface ICalendar {
    onChange: (date: moment.Moment) => void;
    value?: moment.Moment;
}
export type Week = (IDay | null)[];
export type CalendarMatrix = Week[];
