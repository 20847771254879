import { Dispatch } from 'redux';
import { setUserInfo } from '../../../deprecated/api/user/userApi';
import { AppState, AppThunk } from '../../store';
import { selectUserProfileFetchStatus } from '../selectors';
import { userProfileActions } from '..';
import { FetchStatus } from '../../types';
import { IUserInfo } from '../../../services/common/types';

export const saveUserNameThunk = (inputData: IUserInfo): AppThunk => (dispatch: Dispatch, getState: () => AppState): void => {
    const currentStatus = selectUserProfileFetchStatus(getState());

    if (currentStatus === FetchStatus.loading || !inputData || !inputData?.userId || !inputData?.lastName) {
        return;
    }
    dispatch(userProfileActions.startLoading());

    setUserInfo(inputData.firstName, inputData.middleName, inputData.lastName, inputData.userId)
        .then((data: unknown) => {
            dispatch(userProfileActions.setUserInfo(data));
            dispatch(userProfileActions.successLoading());
        })
        .catch(() => {
            dispatch(userProfileActions.failedLoading());
        });
};
