import React, { useCallback, useEffect, useState } from 'react';
import { sourcesRtkQService } from '@services/sources';
import CardPlotSkeleton from '@shared/CardPlotSkeleton';
import LastItem from '@entities/LastItem';
import {
    IQPLoadSourcePosts, IQPLoadSourcePostsColumn, ISourcePost, SortOrder,
} from '@services/sources/types';
import SvgIcon from '@components/SvgIcon';
import PostsQuantityFilter from '@entities/PostsQuantityFilter';
import { DateRangePickerValue } from '@components/DateRangePicker/types';
import SourcePost from './Post';
import styles from './styles.module.scss';

const sortOptions = [
    { value: IQPLoadSourcePostsColumn.likes, label: 'лайки' },
    { value: IQPLoadSourcePostsColumn.views, label: 'просмотры' },
    { value: IQPLoadSourcePostsColumn.comments, label: 'комментарии' },
    { value: IQPLoadSourcePostsColumn.forwards, label: 'репосты' },
];

interface IProps {
    sourceId: number;
}

const min = 0;
const max = 1000000;

const SourcePosts: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { sourceId } = props;
    const [getPosts, {
        data: postsLoadResponse,
        isFetching: isFetchingPosts,
    }] = sourcesRtkQService.useLazyPostLoadSourcePostsQuery();
    const [sort, setSort] = useState<IQPLoadSourcePostsColumn | null>(null);
    const [sortDirection, setSortDirection] = useState<SortOrder | null>();
    const [posts, setPosts] = useState<ISourcePost[]>([]);
    const [paginationToken, setPaginationToken] = useState<string | null>(null);
    const [date, setDate] = useState<DateRangePickerValue>([
        null, null,
    ]);
    const [viewsFilter, setViewsFilter] = useState<[number, number] | null>(null);

    const [isLoadingPosts, setLoading] = useState<boolean>(false);

    const getPostsQuery = useCallback((token: string | null) => {
        const args: IQPLoadSourcePosts = {
            sourceId,
            limit: 20,
        };
        if (token) args.paginationToken = token;
        if (sort) args.columnName = sort;
        if (sortDirection) args.direction = sortDirection;
        if (date[0] && date[1]) {
            args.dateDilter = {
                from_date: date[0].format('YYYY-MM-DD'),
                to_date: date[1].format('YYYY-MM-DD'),
            };
        }
        if (viewsFilter) {
            args.viewsFilter = {
                from_: viewsFilter[0],
                to_: viewsFilter[1],
            };
        }
        getPosts(args);
    }, [sourceId, isFetchingPosts, sort, sortDirection, date, viewsFilter]);

    useEffect(() => {
        // TODO: delete this
        setPaginationToken(null);
        setPosts([]);
        getPostsQuery(null);
    }, [sourceId, sort, sortDirection, date, viewsFilter]);

    useEffect(() => {
        setPaginationToken(null);
        if (postsLoadResponse && postsLoadResponse.items) {
            setPosts((prevPosts) => prevPosts.concat(postsLoadResponse.items));
        }
        if (postsLoadResponse && postsLoadResponse.nextPageToken) {
            setPaginationToken(postsLoadResponse.nextPageToken);
        }
    }, [postsLoadResponse]);

    const onScroll = (): void => {
        if (paginationToken && !isFetchingPosts) {
            setLoading(true);
            getPostsQuery(paginationToken);
        }
    };

    const onChangeSort = (value: IQPLoadSourcePostsColumn): void => {
        setSort(value);
    };

    const handleChangeSortDirection = (): void => {
        if (!sortDirection) setSortDirection(SortOrder.asc);
        if (sortDirection === SortOrder.asc) setSortDirection(SortOrder.desc);
        if (sortDirection === SortOrder.desc) setSortDirection(null);
    };

    const onChangeViewsFilter = (value: [number, number]): void => {
        setViewsFilter(value);
    };

    useEffect(() => {
        if (isFetchingPosts && isLoadingPosts) {
            setLoading(false);
        }
    }, [isFetchingPosts, isLoadingPosts]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.sort}>
                    <div className={styles.sortOptions}>
                        {sortOptions.map((item) => (
                            <button
                                key={item.value}
                                data-active={sort === item.value}
                                type="button"
                                onClick={() => onChangeSort(item.value)}
                            >
                                {item.label}
                            </button>
                        ))}

                    </div>
                    <button type="button" onClick={handleChangeSortDirection}>
                        <SvgIcon id="ascDescIcon" />
                    </button>
                </div>

                <PostsQuantityFilter
                    date={date}
                    setDate={setDate}
                    viewsFilter={viewsFilter}
                    onChangeViewsFilter={onChangeViewsFilter}
                    rangeMin={min}
                    rangeMax={max}
                />
            </div>
            <div className={styles.list}>
                {posts
                    && posts.length >= 1
                    && posts.map((post) => (
                        <SourcePost key={post.postId} post={post} />
                    ))}
                {isFetchingPosts && (
                    <>
                        <CardPlotSkeleton />
                        <CardPlotSkeleton />
                        <CardPlotSkeleton />
                        <CardPlotSkeleton />
                        <CardPlotSkeleton />
                    </>
                )}
                {posts && posts.length >= 1 && !isFetchingPosts && !isLoadingPosts && <LastItem enabled={false} onLastItem={onScroll} />}
                {!isFetchingPosts && posts?.length < 1 && <p>Посты не найдены.</p>}
            </div>
        </div>
    );
};
export default SourcePosts;
