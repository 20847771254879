import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonProcessStatus, FetchStatus } from '../../types';
import { convertReportDataApiToSummary } from './converter';
import { IPDFHandlerWrapperState, IPDFReportSummary } from './types';

const initialState: IPDFHandlerWrapperState = {
    campaignId: 0,
    reportId: '',
    fileName: '',
    reportName: '',
    timeFrame: '',
    chartList: [],
    document: null,
    pdfWrapperDownload: null,
    processing: null,
    summaryStatusFetch: FetchStatus.undefined,
    summaryData: [],
    fetchErrors: '',
    categoryData: null,
    tagList: [],
    regionData: null,
    rssData: null,
    authorData: null,
    additionalData: null,
    sourcesData: null,
    chartsIds: [],
    reportCreateStatus: CommonProcessStatus.undefined,
};

const { actions: pdfHandlerWrapperActions, reducer } = createSlice({
    name: 'pdfHandlerWrapper',
    initialState,
    reducers: {
        startCreateReport: (state: IPDFHandlerWrapperState): void => {
            state.reportCreateStatus = CommonProcessStatus.process;
        },
        successCreateReport: (state: IPDFHandlerWrapperState): void => {
            state.reportCreateStatus = CommonProcessStatus.success;
        },
        failedCreateReport: (state: IPDFHandlerWrapperState): void => {
            state.reportCreateStatus = CommonProcessStatus.failed;
        },
        startLoadingSummary: (state: IPDFHandlerWrapperState): void => {
            state.summaryStatusFetch = FetchStatus.loading;
        },
        successLoadingSummary: (state: IPDFHandlerWrapperState): void => {
            state.summaryStatusFetch = FetchStatus.success;
        },
        failedLoadingSummary: (state: IPDFHandlerWrapperState): void => {
            state.summaryStatusFetch = FetchStatus.failed;
        },
        setFetchErrors: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<string>): void => {
            state.fetchErrors = payload;
        },
        clearSummaryByCampaignId: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<number>): void => {
            const summary = state.summaryData.filter(({ campaignId }) => campaignId !== payload);
            state.summaryData = [...summary];
        },
        setSummaryDataFromApi: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<{ campaignId: number, data: unknown, period: string }>): void => {
            if (!payload?.campaignId || !payload?.data || !payload?.period) {
                return;
            }
            const summary = state.summaryData.filter(({ campaignId, period }) => !(campaignId === payload.campaignId && period === payload.period));
            const buffer = { ...convertReportDataApiToSummary(payload?.data), period: payload.period, campaignId: payload.campaignId } as IPDFReportSummary;
            state.summaryData = [...summary, buffer];
        },
        setChartList: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any[]>): void => {
            state.chartList = payload;
        },
        setRegionData: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any>): void => {
            state.regionData = payload;
        },
        setCategoryData: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any>): void => {
            state.categoryData = payload;
        },
        setSourcesTypes: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any>): void => {
            state.sourcesData = payload;
        },
        setTagList: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any[]>): void => {
            state.tagList = payload;
        },
        setRssData: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any>): void => {
            state.rssData = payload;
        },
        setAuthorData: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any>): void => {
            state.authorData = payload;
        },
        setAdditionalData: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<any>): void => {
            state.additionalData = payload;
        },
        createDocument: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<JSX.Element>): void => {
            state.document = payload;
        },
        createDownloadLink: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<JSX.Element>): void => {
            if (payload) {
                state.pdfWrapperDownload = payload;
            }
        },
        setFileName: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<string>): void => {
            state.fileName = payload;
        },
        setReportId: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<string>): void => {
            state.reportId = payload;
        },
        setCampaignId: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<number>): void => {
            state.campaignId = payload;
        },
        setReportName: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<string>): void => {
            state.reportName = payload;
        },
        setReportTimeFrame: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<string>): void => {
            state.timeFrame = payload;
        },
        setReportInProcess: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<boolean | null>): void => {
            state.processing = payload;
        },
        setChartsIds: (state: IPDFHandlerWrapperState, { payload }: PayloadAction<number[]>): void => {
            state.chartsIds = payload;
        },
    },
});

export {
    pdfHandlerWrapperActions,
};
export default reducer;
