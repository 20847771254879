import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DnDCardTypes, IQPDashboard } from '@services/dashboard/types';
import { dashboardRtkQService } from '@services/dashboard';
import { trackingCampaingRtqService } from '@services/campaigns/tracking';
import { campaignStatisticsRtkQService } from '@services/campaigns/statistics';
import { campaignsListRtkQService } from '@services/campaigns/campaigns-list';
import { IComparisonFilters, IFilterCampaigns } from '@store/campaign/comparison';
import { Button } from '@shared/index';
import { IDnDCard, IDnDContainer, IDnDContainersMap } from './types';
import CampaignDnDRegions from './DnDComponents/Regions';
import CampaignDropDownCard from './DnDComponents/CampaignDropDown';
import CampaignDnDPosts from './DnDComponents/Posts';
import CampaignDnDTonality from './DnDComponents/Tonality';
import CampaignDnDTags from './DnDComponents/Tags';
import CampaignDnDPlatformsChart from './DnDComponents/PlatformsChart';
import CampaignDnDLineChart from './DnDComponents/LineChart';
import CampaignDnDMapChart from './DnDComponents/MapChart';
import CampaignDnDContainer from './DnDContainer';
import CampaignDnDUsersReaction from './DnDComponents/UsersReaction';
import styles from './styles.module.scss';

const prepareBlank = (ids: number[]): IDnDCard[] => (ids.length > 0
    ? ids.map((id) => ({ id, component: null } as IDnDCard))
    : []);

const initialTopContainersCardsIds: IDnDContainersMap[] = [
    {
        id: 1,
        cardsIds: [DnDCardTypes.campaignSelection, DnDCardTypes.tonality],
    },
    {
        id: 2,
        cardsIds: [DnDCardTypes.likes, DnDCardTypes.authorsQuantity, DnDCardTypes.postsQuantity, DnDCardTypes.tagsQuantity],
    },
    {
        id: 3,
        cardsIds: [DnDCardTypes.regions],
    },
];
const initialBottomContainersCardsIds: IDnDContainersMap[] = [
    {
        id: 4,
        cardsIds: [DnDCardTypes.parametersChart, DnDCardTypes.mapChart],
    },
    {
        id: 5,
        cardsIds: [DnDCardTypes.lastPosts, DnDCardTypes.platformChart],
    },
];

const initialTopContainer: IDnDContainer[] = [
    {
        id: 1,
        style: {
            display: 'flex',
            flexDirection: 'column',
        },
        cards: prepareBlank(initialTopContainersCardsIds.find(({ id }) => id === 1).cardsIds),
    },
    {
        id: 2,
        style: {
            display: 'flex',
            flexDirection: 'column',
        },
        cards: prepareBlank(initialTopContainersCardsIds.find(({ id }) => id === 2).cardsIds),
    },
    {
        id: 3,
        style: {
            display: 'flex',
            flexDirection: 'column',
        },
        cards: prepareBlank(initialTopContainersCardsIds.find(({ id }) => id === 3).cardsIds),
    },
];

const initialBottomContainer: IDnDContainer[] = [
    {
        id: 4,
        style: {
            display: 'flex',
            flexDirection: 'column',
        },
        cards: prepareBlank(initialBottomContainersCardsIds.find(({ id }) => id === 4).cardsIds),
    },
    {
        id: 5,
        style: {
            display: 'flex',
            flexDirection: 'column',
        },
        cards: prepareBlank(initialBottomContainersCardsIds.find(({ id }) => id === 5).cardsIds),
    },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const swap = (data: number[], x: number, y: number): number[] | null => {
    if (!data) {
        return null;
    }
    const buffer = [...data];
    const b = buffer[x];
    buffer[x] = buffer[y];
    buffer[y] = b;
    return buffer;
};

const swapById = (data: number[], id: number, toIndex: number): number[] | null => {
    if (!data) {
        return null;
    }
    const buffer = [...data];
    const fromIndex = buffer.indexOf(id);
    if (fromIndex < 0) {
        return data;
    }
    const b = buffer[fromIndex];
    buffer[fromIndex] = buffer[toIndex];
    buffer[toIndex] = b;
    return buffer;
};

const insertCardId = (data: number[], x: number, newId: number): number[] | null => {
    if (!data) {
        return null;
    }
    return [...data.slice(0, x + 1), newId, ...data.slice(x + 1)];
};

const getContainer = (
    containerId: number,
    firstContainers: IDnDContainersMap[],
    secondContainers: IDnDContainersMap[],
): IDnDContainersMap | null => {
    if (Number.isInteger(containerId) && containerId >= 0) {
        let result = firstContainers.find(({ id }) => id === containerId);
        if (result) {
            return result;
        }
        result = secondContainers.find(({ id }) => id === containerId);
        if (result) {
            return result;
        }
    }
    return null;
};

const dateMinusDays = (fromData: Date, days: number): Date => {
    const result = new Date(fromData.getFullYear(), fromData.getMonth(), fromData.getDate());
    result.setDate(fromData.getDate() - days);
    return result;
};

const dateNow = new Date();
const campaignSelectCardId = DnDCardTypes.campaignSelection;

const CampaignDashboard: React.FC = (): JSX.Element => {
    const { data: campaigns } = campaignsListRtkQService.useGetCampaignsQuery();
    const [
        loadCampaignFilters,
        { data: filters, isLoading: filtersIsLoading, isFetching: filtersIsFetching },
    ] = campaignStatisticsRtkQService.useLazyPostLoadCampaignFiltersQuery();
    const getListCategoryByName = (categories: IFilterCampaigns, name: string, from = -1, to = 11) => {
        const categoryByName = categories?.filters?.find((item) => item?.filtersCategory === name);
        if (!categoryByName && !categoryByName?.filters?.length) return [];
        return categoryByName?.filters.filter((l, i) => i > from && i < to).map((item) => ({
            name: item?.filter_name,
            value: item?.count,
        }));
    };
    const getTags = useMemo(() => getListCategoryByName(filters, 'Теги'), [filters]);
    const getAuthors = useMemo(() => getListCategoryByName(filters, 'Авторы', 0, 10), [filters]);
    const getPlatforms = useMemo(() => getListCategoryByName(filters, 'Типы платформ'), [filters]);
    const [
        loadCampaignSummary,
        { data: campaignSummary, isLoading: summaryIsLoading, isFetching: summaryIsFetching },
    ] = campaignStatisticsRtkQService.useLazyPostLoadCampaignAggQuery();
    const [
        loadPosts,
        { data: posts, isLoading: postsIsLoading, isFetching: postsIsFetching },
    ] = trackingCampaingRtqService.useLazyGetPostsQuery();
    const {
        data: dashboards,
    } = dashboardRtkQService.useGetDashboardsQuery();
    const [deleteDashboard] = dashboardRtkQService.useDeleteDashboardMutation();
    const [createDashboard] = dashboardRtkQService.usePostCreateDashboardMutation();
    const [updateDashboard] = dashboardRtkQService.usePutChangeDashboardMutation();

    const [topContainersMapIds, setTopContainersMapIds] = useState<IDnDContainersMap[]>(initialTopContainersCardsIds);
    const [bottomContainersMapIds, setBottomContainersMapIds] = useState<IDnDContainersMap[]>(initialBottomContainersCardsIds);
    const [campaignId, setCampaignId] = useState<number>(-1);
    const [fromDate] = useState<string>(() => `${(dateMinusDays(dateNow, 30)).toISOString().split('T')[0]}`);
    const [toDate] = useState<string>(() => `${dateNow.toISOString().split('T')[0]}`);
    const [currentDragCardId, setDragCardId] = useState<number | null>(null);
    const [scrollTimestamp, setScrollTimestamp] = useState<number>(0);

    const showCards = useMemo<boolean>(() => (campaignId > 0), [campaignId]);
    const filtersTonality = useMemo<IComparisonFilters | null>(
        () => filters?.filters?.find(({ filtersCategory }) => filtersCategory === 'Тональность'),
        [filters],
    );
    const campaignDropDownCard = useMemo<JSX.Element>(() => (
        <CampaignDropDownCard campaigns={campaigns ?? []} campaignId={campaignId} onSelectCampaign={setCampaignId} scrollTimestamp={scrollTimestamp} />
    ), [campaigns, campaignId, scrollTimestamp]);
    const campaignDnDTonalityCard = useMemo<JSX.Element>(() => (
        <CampaignDnDTonality
            isLoading={filtersIsLoading || filtersIsFetching}
            negative={filtersTonality?.filters?.find(({ filter_name }) => filter_name === 'negative')?.count ?? 0}
            neutral={filtersTonality?.filters?.find(({ filter_name }) => filter_name === 'neutral')?.count ?? 0}
            positive={filtersTonality?.filters?.find(({ filter_name }) => filter_name === 'positive')?.count ?? 0}
        />
    ), [filtersIsLoading, filtersTonality, filtersIsFetching]);

    const componentsMap = useMemo<Map<number, JSX.Element>>(() => new Map([
        [DnDCardTypes.campaignSelection, campaignDropDownCard],
        [DnDCardTypes.tonality, campaignDnDTonalityCard],
        [DnDCardTypes.likes, <CampaignDnDUsersReaction
            key={DnDCardTypes.likes}
            isLoading={summaryIsLoading || summaryIsFetching}
            title="Лайки"
            quantity={campaignSummary?.quantityLikes}
        />],
        [DnDCardTypes.authorsQuantity, <CampaignDnDUsersReaction
            key={DnDCardTypes.authorsQuantity}
            isLoading={summaryIsLoading || summaryIsFetching}
            title="Авторы"
            quantity={campaignSummary?.quantityAuthors}
        />],
        [DnDCardTypes.postsQuantity, <CampaignDnDUsersReaction
            key={DnDCardTypes.postsQuantity}
            isLoading={summaryIsLoading || summaryIsFetching}
            title="Посты"
            quantity={campaignSummary?.quantityPost}
        />],
        [DnDCardTypes.tagsQuantity, <CampaignDnDTags campaignId={campaignId} tags={getTags} key={DnDCardTypes.tagsQuantity} />],
        [DnDCardTypes.regions, <CampaignDnDRegions campaignId={campaignId} key={DnDCardTypes.regions} />],
        [DnDCardTypes.parametersChart, <CampaignDnDLineChart campaignId={campaignId} authors={getAuthors} key={DnDCardTypes.parametersChart} />],
        [DnDCardTypes.mapChart, <CampaignDnDMapChart campaignId={campaignId} key={DnDCardTypes.mapChart} />],
        [DnDCardTypes.lastPosts, <CampaignDnDPosts
            key={DnDCardTypes.lastPosts}
            isLoading={postsIsLoading || postsIsFetching}
            posts={posts ?? []}
        />],
        [DnDCardTypes.platformChart, <CampaignDnDPlatformsChart platforms={getPlatforms} campaignId={campaignId} key={DnDCardTypes.platformChart} />],
    ]), [
        campaignDropDownCard,
        campaignDnDTonalityCard,
        campaignSummary,
        summaryIsLoading,
        summaryIsFetching,
        posts,
        postsIsLoading,
        postsIsFetching,
        scrollTimestamp,
    ]);

    const findCard = useCallback((id: number) => {
        const container = (
            topContainersMapIds.find((item) => item.cardsIds.includes(id))
                || bottomContainersMapIds.find((item) => item.cardsIds.includes(id))
        );
        return !container ? null : {
            containerId: container.id,
            cardIndex: container.cardsIds.indexOf(id),
        };
    }, [topContainersMapIds, bottomContainersMapIds]);

    const moveCard = useCallback((
        toContainerId: number,
        fromContainerId: number,
        dragId: number,
        hoverIndex: number,
    ) => {
        if (toContainerId !== fromContainerId) {
            const toContainer = getContainer(toContainerId, topContainersMapIds, bottomContainersMapIds);
            const fromContainer = getContainer(fromContainerId, topContainersMapIds, bottomContainersMapIds);
            if (fromContainer.cardsIds?.length <= 0 || !fromContainer.cardsIds.includes(dragId)) {
                return;
            }
            toContainer.cardsIds = insertCardId(toContainer.cardsIds, hoverIndex, dragId);
            fromContainer.cardsIds = fromContainer.cardsIds.filter((id) => dragId !== id); // .splice(dragIndex, 1);
        } else {
            const container = getContainer(toContainerId, topContainersMapIds, bottomContainersMapIds);
            container.cardsIds = swapById(container?.cardsIds, dragId, hoverIndex);
        }
        setTopContainersMapIds([...topContainersMapIds]);
        setBottomContainersMapIds([...bottomContainersMapIds]);
    }, [topContainersMapIds, bottomContainersMapIds]);

    const handlerSetDragCard = useCallback((id: number | null): void => {
        if (currentDragCardId !== id) {
            setDragCardId(id);
        }
    }, [currentDragCardId]);

    const visibleTopContainersMapIds = useMemo<IDnDContainersMap[]>(() => topContainersMapIds
        .map((item) => {
            if (!showCards) {
                return item.cardsIds.includes(campaignSelectCardId)
                    ? ({ id: item.id, cardsIds: [campaignSelectCardId] })
                    : null;
            }
            return item;
        }).filter((item) => Boolean(item)), [showCards, topContainersMapIds]);

    const visibleBottomContainersMapIds = useMemo<IDnDContainersMap[]>(() => bottomContainersMapIds
        .map((item) => {
            if (!showCards) {
                return item.cardsIds.includes(campaignSelectCardId)
                    ? ({ id: item.id, cardsIds: [campaignSelectCardId] })
                    : null;
            }
            return item;
        }).filter((item) => Boolean(item)), [showCards, bottomContainersMapIds]);

    const handlerSetDefault = useCallback((): void => {
        if (dashboards?.length > 0) {
            dashboards.forEach((item) => deleteDashboard({ id: item.id }));
        }
        setTopContainersMapIds([...initialTopContainersCardsIds]);
        setBottomContainersMapIds([...initialBottomContainersCardsIds]);
    }, [dashboards, setTopContainersMapIds, setBottomContainersMapIds]);
    const handlerSave = useCallback((): void => {
        const newParams = {
            id: 0,
            index: 0,
            verticalDirection: true,
            containers: [
                {
                    id: 1,
                    index: 0,
                    verticalDirection: false,
                    containers: visibleTopContainersMapIds.map((top, containerIdx) => (
                        {
                            id: top.id,
                            index: containerIdx,
                            containers: null,
                            cards: top.cardsIds.map((card, cardIdx) => (
                                {
                                    id: card,
                                    index: cardIdx,
                                    type: card,
                                }
                            )),
                        } as IQPDashboard
                    )),
                    cards: null,
                },
                {
                    id: 2,
                    index: 1,
                    verticalDirection: false,
                    containers: visibleBottomContainersMapIds.map((top, containerIdx) => (
                        {
                            id: top.id,
                            index: containerIdx,
                            containers: null,
                            cards: top.cardsIds.map((card, cardIdx) => (
                                {
                                    id: card,
                                    index: cardIdx,
                                    type: card,
                                }
                            )),
                        } as IQPDashboard
                    )),
                    cards: null,
                },
            ],
            cards: null,
        };
        const dashboard = (dashboards?.length > 0 ? dashboards[0] : null) ?? null;
        if (dashboard?.id) {
            updateDashboard({ id: dashboard.id, params: newParams });
        } else {
            createDashboard(newParams);
        }
    }, [visibleTopContainersMapIds, visibleBottomContainersMapIds]);

    const topList = useMemo<JSX.Element[]>(() => visibleTopContainersMapIds
        .map((itemIds) => (
            <CampaignDnDContainer
                key={itemIds.id}
                style={initialTopContainer.find(({ id }) => id === itemIds.id)?.style ?? {}}
                cards={itemIds.cardsIds.map((cardId) => (
                    { id: cardId, component: componentsMap.get(cardId) ?? null } as IDnDCard
                ))}
                onMoveCard={moveCard}
                onFindCard={findCard}
                currentDragCardId={currentDragCardId}
                onSetDragCard={handlerSetDragCard}
            />
        )), [
        visibleTopContainersMapIds,
        componentsMap,
        currentDragCardId,
        moveCard,
        findCard,
        handlerSetDragCard,
    ]);

    const bottomList = useMemo<JSX.Element[]>(() => visibleBottomContainersMapIds
        .map((itemIds) => (
            <CampaignDnDContainer
                key={itemIds.id}
                style={initialBottomContainer.find(({ id }) => id === itemIds.id)?.style ?? {}}
                cards={itemIds.cardsIds.map((cardId) => (
                    { id: cardId, component: componentsMap.get(cardId) ?? null } as IDnDCard
                ))}
                onMoveCard={moveCard}
                onFindCard={findCard}
                currentDragCardId={currentDragCardId}
                onSetDragCard={handlerSetDragCard}
            />
        )), [
        visibleBottomContainersMapIds,
        componentsMap,
        currentDragCardId,
        moveCard,
        findCard,
        handlerSetDragCard,
    ]);

    const handlerScroll = (event: React.UIEvent<HTMLDivElement>): void => {
        setScrollTimestamp(event?.timeStamp);
    };

    useEffect(() => {
        if (!(dashboards?.length > 0)) {
            return;
        }
        const { params } = dashboards?.length > 1 ? dashboards[1] : dashboards[0];
        if (params?.containers?.length > 0) {
            if (params?.containers[0]?.containers) {
                setTopContainersMapIds([...params?.containers[0]?.containers?.map((item) => (
                    {
                        id: item.id,
                        cardsIds: item.cards.map((card) => card.id),
                    }
                )) ?? []]);
            }
            if (params?.containers[1]?.containers) {
                setBottomContainersMapIds([...params?.containers[1]?.containers?.map((item) => (
                    {
                        id: item.id,
                        cardsIds: item.cards.map((card) => card.id),
                    }
                )) ?? []]);
            }
        }
    }, [dashboards]);
    useEffect(() => {
        if (Number.isInteger(campaignId) && campaignId > 0 && toDate && fromDate) {
            loadCampaignFilters({ id: campaignId, toDate, fromDate });
            loadCampaignSummary({
                id: campaignId,
                toDate: `${toDate}T00:00:00.000Z`,
                fromDate: `${fromDate}T00:00:00.000Z`,
            });
            loadPosts({
                campaign_id: campaignId,
                data: {
                    date_periods: [],
                    days: [],
                    filters: [],
                    limit: 5,
                    page: 1,
                    sort_params: { column: 'posted', order: 'desc' },
                },
            });
        }
    }, [campaignId, toDate, fromDate]);

    return (
        <div className={styles.campaignDashboard_root} onScroll={handlerScroll}>
            <div style={{ display: 'flex', gap: 12 }}>
                <Button
                    text="Сохранить"
                    type="secondary"
                    size="large"
                    disabled={!(campaignId > 0)}
                    onClick={handlerSave}
                />
                {/* <ButtonSecondary */}
                {/*    style={{ width: 110, height: 48 }} */}
                {/*    disabled={!(campaignId > 0)} */}
                {/*    text="Сохранить" */}
                {/*    onClick={handlerSave} */}
                {/* /> */}
                <Button
                    text="Сбросить"
                    type="secondary"
                    size="large"
                    disabled={!(campaignId > 0)}
                    onClick={handlerSetDefault}
                />
                {/* <ButtonSecondary */}
                {/*    style={{ width: 110, height: 48 }} */}
                {/*    disabled={!(campaignId > 0)} */}
                {/*    text="Сбросить" */}
                {/*    onClick={handlerSetDefault} */}
                {/* /> */}
            </div>
            <DndProvider backend={HTML5Backend}>
                <div style={{
                    display: 'flex', flexDirection: 'column', gap: 28,
                }}
                >
                    <div style={{ display: 'flex', gap: 20 }}>{topList}</div>
                    <div style={{ display: 'flex', gap: 20 }}>{bottomList}</div>
                </div>
            </DndProvider>
        </div>
    );
};

export default CampaignDashboard;
