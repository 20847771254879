import React, {
    CSSProperties, useCallback,
} from 'react';
import { IDnDCard } from 'components/campaign/Dashboard/types';
import CampaignDnDCard from '../DnDCard';
import styles from './styles.module.scss';

interface IProps {
    style: CSSProperties;
    cards: IDnDCard[];
    currentDragCardId: number;
    onSetDragCard: (id: number) => void;
    onMoveCard: (toContainerIndex: number, fromContainerIndex: number, dragIndex: number, hoverIndex: number) => void;
    onFindCard: (id: number) => { containerId: number, cardIndex: number };
}

const CampaignDnDContainer: React.FC<IProps> = ({
    style,
    cards,
    currentDragCardId,
    onSetDragCard,
    onMoveCard,
    onFindCard,
}): JSX.Element => {
    // const style = useMemo<CSSProperties>(() => (
    //     {
    //         display: 'flex',
    //         flexDirection: isVertical ? 'column' : 'row',
    //     } as CSSProperties
    // ), [isVertical]);

    const renderCard = useCallback((card) => (
        <CampaignDnDCard
            key={card.id}
            id={card.id}
            currentDragCardId={currentDragCardId}
            text={card.text}
            onMoveCard={onMoveCard}
            onFindCard={onFindCard}
            onSetDragCard={onSetDragCard}
            component={card.component}
        />
    ), [
        onMoveCard,
        onFindCard,
        onSetDragCard,
        currentDragCardId,
    ]);
    return (
        <div
            className={styles.campaignDnDContainer_root}
            style={style}
        >
            {cards.map((card) => renderCard(card))}
        </div>
    );
};

export default CampaignDnDContainer;
