const platformIconsMap = new Map([
    ['ok.ru', 'OkLogo'],
    ['vk.com', 'VkLogo'],
    ['t.me', 'TgLogo'],
    ['dzen.ru', 'ZenLogo'],
    ['youtube.com', 'YouTubeLogo'],
    ['rutube.ru', 'RuTubeLogo'],
]);

export default {
    platformIconsMap,
};
