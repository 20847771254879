/* eslint-disable @typescript-eslint/no-unused-vars */
import { UploadFile } from 'antd';
import React from 'react';
import CompleteLoad from './Complete';
import Default from './Default';
import ErrorLoad from './Error';
import LoadingFile from './Loading';
import { TStatus } from './types';
import styles from './styles.module.scss';

interface IProps {
    onUploadFile: (file: UploadFile) => void,
    onChangeStatus: (newStatus: TStatus) => void,
    status?: TStatus
}

const BlockLoadFile: React.FC<IProps> = ({
    onUploadFile,
    onChangeStatus,
    status = 'default',
}): JSX.Element => {
    if (status === 'loading') return <LoadingFile />;
    if (status === 'complete') return <CompleteLoad onChangeStatus={onChangeStatus} />;
    if (status === 'error') return <ErrorLoad onChangeStatus={onChangeStatus} />;
    if (status === 'default') return <Default onUploadFile={onUploadFile} />;
    return (
        <div className={styles.containerLoad}>
            <Default onUploadFile={onUploadFile} />
            <LoadingFile />
            <CompleteLoad onChangeStatus={onChangeStatus} />
            <ErrorLoad onChangeStatus={onChangeStatus} />
        </div>
    );
};

export default BlockLoadFile;
