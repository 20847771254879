/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */

import { Column } from '@ant-design/plots';
import React from 'react';
import moment from 'moment';
import styles from './styles.module.scss';

interface IPropsLightBar {
    newData: any[]
}

const LightBar: React.FC<IPropsLightBar> = ({ newData }): JSX.Element => {
    const config = {
        data: newData,
        xField: 'x',
        yField: 'y',
        columnWidthRatio: 0.6,
        color: '#129FD4',
        columnStyle: {
            radius: [2, 2, 0, 0],
        },
        label: false,
        xAxis: {
            label: {
                style: {
                    fontSize: 0,
                    fill: 'rgba(0,0,0,0)',
                    width: 0,
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: 'rgba(0,0,0,0)',
                    },
                },
            },
            line: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
            tickLine: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 0,
                    fill: 'rgba(0,0,0,0)',
                },
            },
            line: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: 'rgba(0,0,0,0)',
                    },
                },
            },
            tickLine: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                    fill: 'rgba(0,0,0,0)',
                },
            },
        },
        legend: false,
        tooltip: {
            fields: ['x', 'y'],
            // eslint-disable-next-line arrow-body-style
            customContent: (title, data) => {
                return `
                <div
                    class=${styles.containerModal}
                >
                    <div>${moment(title).format('DD.MM.YYYY')}</div>
                    <span>${data[0]?.data?.y ? data[0].data.y.toLocaleString() : '-'}</span>
                </div>`;
            },
            showCrosshairs: true,
        },
    };
    if (!newData?.length) return null;
    return <Column {...config as any} width={770} height={100} />;
};
export default LightBar;
