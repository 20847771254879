import { t } from 'i18next';
import React, {
    useEffect,
    useMemo, useState,
} from 'react';
import { ISelectItem, TIDSelect, TSizes } from './types';
import AccordionSelectBody from './ui-components/accordionSelectBody';
import DropdownSelectBody from './ui-components/dropdownSelectBody';
import OptionsMenu from './ui-components/OptionsMenu';
import styles from './styles.module.scss';

interface IProps {
    size?: TSizes,
    infinity?: boolean,
    infinityStep?: number,
    updateInfinityDate?: () => void,
    isUpdateInfinityDate?: boolean,
    customWidth?: string,
    placeholder?: string,
    defaultItem?: TIDSelect,
    isCheckBoxes?: boolean,
    onSelect?: (id: TIDSelect, item?: ISelectItem) => void,
    disabled?: boolean,
    accordion?: boolean,
    accordionHeight?: number,
    header?: JSX.Element,
    footer?: JSX.Element
    items: ISelectItem[],
    isLoading?: boolean,
    onDisplay?: (value: string) => void;
}

const DEFAULT_PLACEHOLDER = 'Выбрать';

const Select: React.FC<IProps> = ({
    size = 'medium',
    infinity = false,
    infinityStep,
    updateInfinityDate,
    isUpdateInfinityDate = false,
    placeholder = DEFAULT_PLACEHOLDER,
    defaultItem,
    items,
    isCheckBoxes,
    disabled,
    header,
    footer,
    onSelect,
    accordion,
    accordionHeight,
    customWidth = '100%',
    isLoading = false,
    onDisplay,
}): JSX.Element => {
    const [selectItemId, setSelectItemId] = useState<TIDSelect>(() => defaultItem || '');
    useEffect(() => {
        if (defaultItem !== selectItemId) setSelectItemId(defaultItem);
    }, [defaultItem]);
    const [isOpen, setIsOpen] = useState(false);
    const [isActiveArrow, setIsActiveArrow] = useState<boolean>(false);

    const classesForSize = useMemo(() => {
        if (size === 'large') {
            if (customWidth === '100%') {
                return `${styles.large} ${styles.largeWidth}`;
            }
            return `${styles[size]}`;
        }
        if (size === 'medium') {
            if (customWidth === '100%') {
                return `${styles.medium} ${styles.mediumWidth}`;
            }
            return `${styles[size]}`;
        }
        if (size === 'small') {
            if (customWidth === '100%') {
                return `${styles.small} ${styles.smallWidth}`;
            }
            return `${styles[size]}`;
        }
        return '';
    }, [customWidth, size]);
    const getMainLabel = useMemo(() => {
        if (selectItemId === '') return placeholder;
        const needItem = items?.find((item) => item?.id === selectItemId);
        if (isCheckBoxes) {
            const listSelected = items.filter((item) => item.select);
            if (!listSelected.length) return placeholder;
            return `Всего: ${listSelected.length}`;
        }
        if (!needItem) {
            // eslint-disable-next-line no-console
            console.log('Ошибка выбранный элемент не найден в списке');
            return placeholder;
        }
        return typeof needItem.label === 'string' ? t(needItem.label) : needItem.label;
    }, [selectItemId, items]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleClickItem = (item: ISelectItem, e: React.MouseEvent) => {
        setSelectItemId(item.id);
        if (onSelect) onSelect(item.id, item);
        if (!isCheckBoxes) setIsOpen(() => false);
    };

    const handleChangeOpen = (status: boolean) => {
        if (disabled) return;
        setIsOpen(status);
    };

    const toggleActiveArrow = () => {
        setIsActiveArrow(!isActiveArrow);
    };

    const OptionsWrapper = useMemo(() => (
        <OptionsMenu
            size={size}
            items={items}
            classesForSize={classesForSize}
            customWidth={customWidth}
            toggleItem={handleClickItem}
            header={header}
            footer={footer}
            infinity={infinity}
            infinityStep={infinityStep}
            isLoading={isLoading}
            isCheckBoxes={isCheckBoxes}
            isUpdateInfinityDate={isUpdateInfinityDate}
            updateInfinityDate={updateInfinityDate}
            onDisplay={onDisplay}
            isAccordion={accordion}
        />
    ), [items, infinity, isUpdateInfinityDate]);

    return (
        accordion
            ? (
                <AccordionSelectBody
                    label={getMainLabel}
                    customWidth={customWidth}
                    classesForSize={classesForSize}
                    accordionHeight={accordionHeight}
                    arrowFlag={isActiveArrow}
                    setArrowFlag={toggleActiveArrow}
                    child={OptionsWrapper}
                    disabled={disabled}
                />
            )
            : (
                <DropdownSelectBody
                    label={getMainLabel}
                    customWidth={customWidth}
                    disabled={disabled}
                    classesForSize={classesForSize}
                    openFlag={isOpen}
                    setOpenFlag={handleChangeOpen}
                    child={OptionsWrapper}
                />
            )
    );
};

export default Select;
