import { IProjectHistoryModel } from '../types';
import { IProjectHistoryFromApi } from '../types/stages/api';

export const transformProjectHistoryResponse = (
    history: IProjectHistoryFromApi[],
): IProjectHistoryModel[] => history.map((historyItem, index) => ({
    id: index,
    model: historyItem.model,
    rawOldData: historyItem.raw_old_data,
    rawNewData: historyItem.raw_new_data,
    dataChanges: historyItem.data_changes ? historyItem.data_changes.map((item) => ({
        field: item.field,
        newValue: item.new_value,
        oldValue: item.old_value,
    })) : null,
    action: historyItem.action,
    created: historyItem.created,
}));
