import { createSlice } from '@reduxjs/toolkit';
import { IAuthState } from 'store/auth/types';
import { removeCookies } from 'cookies-next';
import { AUTH_COOKIE_NAME } from '../../utils/constants';

const initialState: IAuthState = {
    isAuth: false,
};

const { actions: authActions, reducer } = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state: IAuthState): void => {
            state.isAuth = true;
        },
        removeAuth: (state: IAuthState): void => {
            state.isAuth = false;
            removeCookies(AUTH_COOKIE_NAME);
        },
    },
});

export { authActions };
export default reducer;
