import { ISourceListModel, ISourceListModelFromApi } from '../types';

export const transformGetListsResponse = (
    baseQueryResponse: ISourceListModelFromApi[],
): ISourceListModel[] => baseQueryResponse.map((list) => ({
    id: list.id,
    title: list.title,
    description: list.description,
    isFavorite: list.is_favorite,
    count: list.count,
}));
