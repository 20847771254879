import React, { useState } from 'react';
import {
    IFilterCheckboxListContainerProps,
} from './types';
import { IColumnFilterModel } from '../../../../components/DropdownFilterTable/DropdownFilterTableMenu/types';
import { ICheckboxModel } from '../../../../components/Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/types';
import CustomCheckbox from '../../../../components/Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';

const prepareListState = (data: IColumnFilterModel[]): ICheckboxModel[] => {
    if (!data || data?.length <= 0) {
        return [];
    }
    return data.map(({ name, id, isSelected }) => ({
        id,
        visible: true,
        name,
        checked: isSelected,
    } as ICheckboxModel
    ));
};

const FilterCheckboxListContainer: React.FC<IFilterCheckboxListContainerProps> = ({
    data,
}): JSX.Element => {
    const [checkboxList] = useState<ICheckboxModel[]>(prepareListState(data));
    const handlerCheckedFilter = (id: string): void => {
        // eslint-disable-next-line no-console
        console.log('handlerCheckedFilter', id);
    };
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {
                checkboxList?.length > 0 && checkboxList.map((item) => (
                    <CustomCheckbox
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        checked={item.checked ?? false}
                        visible={item.visible}
                        onChecked={handlerCheckedFilter}
                        isRetinaSize={false}
                    />
                ))
            }
        </>
    );
};

export default FilterCheckboxListContainer;
