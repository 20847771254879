import React, {
    useCallback, useEffect, useState,
} from 'react';
import classnames from 'classnames';
import SvgIcon from '@components/SvgIcon';
import PenGray from '@assets/images/pen-gray.svg';
import { Button } from '@shared/index';
import TextArea from '@entities/TextArea';
import TextField from '@entities/TextField';
import { IQPProjectInfo } from '@services/projects/types';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

interface IProps {
    projectId: number;
    name: string;
    description: string;
    onUpdateInfo: (value: IQPProjectInfo) => void;
    isLoading: boolean
}

const DetailInfo: React.FC<IProps> = ({
    isLoading,
    projectId,
    name,
    description,
    onUpdateInfo,
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const [isView, setViewState] = useState(true);
    const [valueName, setValueName] = useState<string>(name);
    const [valueDescription, setValueDescription] = useState<string>(description);
    useEffect(() => {
        setValueName(name);
        setValueDescription(description);
    }, [name, description]);
    const handlerCancel = () => {
        setValueName(name);
        setValueDescription(description);
        setViewState(true);
    };

    const handlerSaveInfo = useCallback((value: IQPProjectInfo): void => {
        onUpdateInfo({
            id: projectId,
            name: value.name,
            description: value.description,
        });
    }, [projectId]);
    if (isLoading) return <Skeleton height={100} width={500} />;
    return (
        <div className={styles.detailInfo}>
            <div className={styles.detailInfo_head}>
                <span className={styles.detailInfo_title}>Общая информация</span>
                <button
                    type="button"
                    onClick={() => setViewState(!isView)}
                    className={classnames(styles.detailInfo_btnPen, !isView ? styles.invisible : '')}
                >
                    <img src={PenGray} alt="PenGray" />
                </button>
            </div>
            <div className={styles.detailInfo_name}>
                <span className={styles.detailInfo_label}>Наименование</span>
                { !isView
                    ? (
                        <TextField
                            value={valueName}
                            placeholder="Введите наименование"
                            onChange={(value: string) => setValueName(value)}
                            className={styles.detailInfo_inputName}
                            wrapperClassName={styles.detailInfo_inputName_wrapper}
                        />
                    )
                    : <span className={styles.detailInfo_campaignName}>{valueName}</span>}
            </div>
            <div className={styles.detailInfo_description}>
                <span className={styles.detailInfo_label}>Описание</span>
                {!isView
                    ? (
                        <TextArea
                            value={valueDescription}
                            placeholder="0 / 3000 символов"
                            className={styles.detailInfo_textarea}
                            onChange={(value) => setValueDescription(value)}
                            wrapperClassName={styles.detailInfo_textarea_wrapper}
                        />
                    )
                    : <p className={classnames(styles.detailInfo_text, { [styles.detailInfo_textOpen]: isOpen })}>{valueDescription}</p>}
                {isView && (
                    <div
                        className={styles.detailInfo_overflowOverlay}
                        style={{ opacity: isOpen ? '0' : '1', height: isOpen ? '0' : null }}
                    />
                )}
            </div>
            {
                !isView
                    ? (
                        <div className={styles.detailInfo_btnContainer}>
                            <Button
                                text="Отмена"
                                type="secondary"
                                size="large"
                                disabled={isView}
                                onClick={handlerCancel}
                            />
                            <Button
                                text="Сохранить"
                                type="default"
                                size="large"
                                disabled={isView || !valueName}
                                onClick={() => handlerSaveInfo({ name: valueName, description: valueDescription })}
                            />
                        </div>
                    )
                    : (
                        <div
                            role="button"
                            className={`${styles.detailInfo_controller} ${isOpen ? styles.close : styles.open}`}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <SvgIcon size={16} id="dropdownArrow" />
                        </div>
                    )
            }
        </div>
    );
};

export default DetailInfo;
