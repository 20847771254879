import React from 'react';
import { Tooltip } from '@shared/index';
import styles from './styles.module.scss';
import { getAbbreviation } from '../../../../../utils/getAbbreviation';

interface IPropsGrowthCell {
    day: number;
    week: number;
    month: number;
}
const labels = ['день', 'неделя', 'месяц'];
const GrowthCell: React.FC<IPropsGrowthCell> = ({ day, week, month }): JSX.Element => {
    const getValue = (number: number) => {
        if (number > 0) return `+${getAbbreviation(number)}`;
        if (number < 0) return `${getAbbreviation(number)}`;
        return `${number || 0}`;
    };
    const getClasses = (value) => {
        if (value > 0) return `${styles.growthPeriod_value} ${styles.pstc}`;
        if (value < 0) return `${styles.growthPeriod_value} ${styles.ngtv}`;
        return styles.growthPeriod_value;
    };
    const getValueWithTooltip = (value: number, i) => (
        <div className={styles.growthPeriod} key={`${i}_growthCellDaysSubcsribers`}>

            <div className={styles.growthPeriod_label}>{`${labels[i]}: `}</div>
            <Tooltip
                content={<div style={{ padding: 4 }}>{value.toLocaleString('ru-RU')}</div>}
                disabled={!value || value < 10000}
                placement="left"
                xOffset={-2}
            >
                <div className={getClasses(value)}>{getValue(value)}</div>
            </Tooltip>

        </div>
    );
    return (
        <div className={styles.growthCell}>
            {[day, week, month].map(getValueWithTooltip)}
        </div>
    );
};

export default React.memo(GrowthCell);
