import React from 'react';
import Sprite from '@assets/Sprite.svg';

interface IProps {
    id: string,
    size?: number,
    height?: number
    width?: number
}

const SvgIcon: React.FC<IProps> = ({
    id,
    size = 24,
    height,
    width,
}): JSX.Element => (
    <svg
        className={`icon icon-${id}`}
        width={width ?? size}
        height={height ?? size}
    >
        <use xlinkHref={`${Sprite}#${id}`} />
    </svg>
);

export default React.memo(SvgIcon);
