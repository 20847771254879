import { userProfileActions } from '../../../store/user-profile';
import { rtkQApi } from '../../index';
import { IQueryParamsUsersFio, IUserInfoFromApi, IUsersFio } from '../types';

export const usersRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postUsersFio: build.query<IUsersFio[], IQueryParamsUsersFio>({
            query: (body) => ({
                url: 'user/get_users_by_ids',
                method: 'POST',
                body,
            }),
        }),
        getUserInfo: build.query<IUserInfoFromApi, void>({
            query: (body) => ({
                url: 'user/info',
                method: 'GET',
                body,
            }),
            async onQueryStarted(args: void, { dispatch, queryFulfilled }) {
                dispatch(userProfileActions.startLoading());
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(userProfileActions.setUserInfo(data));
                        dispatch(userProfileActions.successLoading());
                    })
                    .catch(() => {
                        dispatch(userProfileActions.failedLoading());
                    });
            },
        }),
    }),
});
