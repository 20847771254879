import {
    Chart as ChartJS,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import React, {
    useMemo, useRef, useCallback,
} from 'react';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import EventBusForChartGraph from '../EventBus';

ChartJS.register(
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);
const GraphChart = React.memo(({
    data,
}: any) => {
    const timerTooltip = useRef(null);
    const getConnections = (coordInfo, isNegative) => {
        const sources = coordInfo.map((info) => info.content);
        const collectionsSources = sources.reduce((acc, source) => {
            source.connections.forEach((connection) => {
                const connectionCoord = coordInfo.find((item) => item.content.id === connection);
                const sourceCoord = coordInfo.find((item) => item.content.id === source.id);
                acc.push([connectionCoord, sourceCoord]);
            });
            return acc;
        }, []);
        return collectionsSources.filter((collection) => {
            if (isNegative) {
                if (collection[1].content.rating === 0) return true;
            }
            if (collection[1].content.rating > 0) return true;
            return false;
        });
    };
    const positiveData = useMemo(() => data.filter(((item) => item.content.rating > 0)), [data]);
    const negativeData = useMemo(() => data.filter(((item) => !item.content.rating)), [data]);
    const chartRef = useRef(null);
    const dataSet = {
        events: ['click'],
        datasets: [
            {
                data: positiveData,
                bordrWidth: 2,
                borderColor: '#0EBFA1',
                backgroundColor: '#DFFCF7',
            },
            {
                data: negativeData,
                bordrWidth: 2,
                borderColor: '#E67E22',
                backgroundColor: '#FEF8E8',
            },
            ...getConnections(data, false).map((lineData) => ({
                type: 'line',
                animation: true,
                data: lineData,
                borderColor: '#0EBFA1',
                borderWidth: 1,
                pointRadius: 0,
            })),
            ...getConnections(data, true).map((lineData) => ({
                type: 'line',
                animation: true,
                data: lineData,
                borderColor: '#E67E22',
                borderWidth: 1,
                pointRadius: 0,
            })),
        ],
        plugin: [
            zoomPlugin,
        ],
    };
    const zoomOptions = {
        limits: {
            x: { min: 1, max: 31, minRange: 3 },
            y: { min: 0, max: 23, minRange: 3 },
        },
        pan: {
            enabled: true,
            mode: 'xy',
        },
        zoom: {
            wheel: {
                enabled: true,
            },
            pinch: {
                enabled: true,
            },
        },
    };
    const clearTimerTooltip = () => {
        if (!timerTooltip.current) return;
        clearTimeout(timerTooltip.current);
        timerTooltip.current = null;
    };
    const createPositionModal = (tooltip) => {
        const position = chartRef.current.canvas.getBoundingClientRect();
        const left = position.left + tooltip.caretX;
        const top = position.top + tooltip.caretY;
        const tooltipData = tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex].content;
        const tooltipParams = {
            top, left, data: tooltipData, isShow: true,
        };
        clearTimeout(timerTooltip.current);
        EventBusForChartGraph.emit('setDataForTooltip', [tooltipParams]);
    };
    const hideTooltip = () => {
        if (timerTooltip.current) return;
        timerTooltip.current = setTimeout(() => {
            EventBusForChartGraph.emit('setDataForTooltip', [{
                top: 0, left: 0, data: {}, isShow: false,
            }]);
        }, 300);
    };
    const customTooltipData = useCallback((tooltipModel) => {
        // hide the tooltip
        const { tooltip } = tooltipModel;
        if (tooltipModel.replay) return;
        if (tooltip.opacity === 0) {
            clearTimerTooltip();
            hideTooltip();
            return;
        }
        createPositionModal(tooltip);
    }, []);
    const tooltipHtml = {
        enabled: false,
        external: customTooltipData,
    };
    const options: any = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            x: {
                ticks: {
                    stepSize: 1,
                    callback(value) {
                        const formatValue = value.toFixed(0);
                        const strValue = +formatValue < 10 ? `0${+formatValue}.09` : `${+formatValue}.09`;
                        return strValue;
                    },
                },
            },
            y: {
                stepSize: 1,
                ticks: {
                    stepSize: 1,
                    callback(value) {
                        const formatValue = value.toFixed(0);
                        const strValue = +formatValue < 10 ? `0${+formatValue}:00` : `${+formatValue}:00`;
                        return strValue;
                    },
                },
            },

        },
        plugins: {
            zoom: zoomOptions,
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: tooltipHtml,
        },
    };
    const getOptions = useMemo(() => options, [options]);
    return (
        <div style={{ height: '300px', width: '100%', position: 'relative' }}>
            <Bubble plugins={[zoomPlugin]} ref={chartRef} options={getOptions} data={dataSet} />
        </div>
    );
});

export default React.memo(GraphChart);
