/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */

import { Datum } from '@ant-design/charts';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import React from 'react';
import styles from './styles.module.scss';

interface IPropsLightBar {
    newData?: any[]
}

const LightLine: React.FC<IPropsLightBar> = ({ newData }): JSX.Element => {
    const config = {
        data: newData,
        xField: 'x',
        yField: 'y',
        color: '#0EBFA1',
        smooth: true,
        label: false,
        xAxis: {
            label: {
                style: {
                    fill: 'rgba(0,0,0,0)',
                    lineWidth: 0,
                    fontSize: 0,
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: 'rgba(0,0,0,0)',
                    },
                },
            },
            line: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
            tickLine: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
        },
        yAxis: {
            min: Array.isArray(newData) ? newData?.reduce((acc, item) => (item.y < acc ? item.y : acc), Infinity) : 0,
            label: {
                style: {
                    fontSize: 0,
                    fill: 'rgba(0,0,0,0)',
                    lineWidth: 0,
                },
            },
            line: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: 'rgba(0,0,0,0)',
                    },
                },
            },
            tickLine: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                    fill: 'rgba(0,0,0,0)',
                },
            },
        },
        legend: false,
        tooltip: {
            // formatter: (datum: Datum) => {
            //     return {
            //         name: moment(datum.x).format('DD.MM.YYYY'), value: datum.y ? datum.y.toLocaleString() : '-',
            //     };
            // },
            fields: ['x', 'y'],
            // eslint-disable-next-line arrow-body-style
            customContent: (title, data) => {
                return `
                <div
                    class=${styles.containerModal}
                >
                    <div>${moment(title).format('DD.MM.YYYY')}</div>
                    <span>${data[0]?.data?.y ? data[0].data.y.toLocaleString() : '-'}</span>
                </div>`;
            },
            showCrosshairs: true,
        },
    };
    if (!newData?.length) return null;
    return <Line {...config as any} width={280} height={94} />;
};
export default LightLine;
