import React from 'react';
import { ProcessModalStatus } from '@store/campaign/source/types';
import SvgIcon from '@components/SvgIcon';
import { Button } from '@shared/index';
import { ICampaignProcessModalProps } from './types';
import LinearLoader from '../../../common/Loader';
import styles from './styles.module.scss';

const CampaignProcessModal: React.FC<ICampaignProcessModalProps> = ({
    messages, isOpen,
    onClose, status,
}): JSX.Element => {
    const handlerCloseModal = (): void => {
        if (status !== ProcessModalStatus.undefined) {
            onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modalWindow}>
                <div className={styles.contentContainer}>
                    {
                        status === ProcessModalStatus.process && <div style={{ marginTop: 16 }}><LinearLoader /></div>
                    }
                    <div className={styles.textBlock}>
                        <div className={styles.text}>
                            {
                                messages?.map((item, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <span key={index}>{item}</span>
                                ))
                            }
                        </div>
                        {
                            status === ProcessModalStatus.success && <SvgIcon id="SuccessIcon" size={24} height={19} />
                        }
                        {
                            status === ProcessModalStatus.failed && <SvgIcon id="FailedIcon" size={14} />
                        }
                    </div>

                    <div className={styles.controlBlock}>
                        <Button
                            text="Ok"
                            type="default"
                            size="large"
                            disabled={status === ProcessModalStatus.undefined}
                            onClick={handlerCloseModal}
                        />
                        {/* <ButtonPrimary */}
                        {/*    text="Ok" */}
                        {/*    disabled={status === ProcessModalStatus.undefined} */}
                        {/*    onClick={handlerCloseModal} */}
                        {/*    style={{ width: 60, height: 40 }} */}
                        {/* /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignProcessModal;
