import request from '../requestWrapper';

// export const getUserInfo = async () => request({
//     method: 'GET',
//     url: 'user/info',
//
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

export const setUserInfo = async (
    firstName,
    middleName,
    lastName,
    userId,
) => {
    const data = await request({
        method: 'PATCH',
        url: `/user/${userId}`,
        data: {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
        },
    }, {
        headers: {},
        isAuthRequest: false,
        isNotHandleErrors: true,
    });

    return data;
};
