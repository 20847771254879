/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface ITab {
    label: string,
    id: string,
}

interface IProps {
    items: ITab[],
    selectTab: ITab,
    onChange: (tab: ITab) => void,
}

const MonitoringViewsTabs: React.FC<IProps> = ({
    items,
    selectTab,
    onChange,
}): JSX.Element => {
    const getTabs = useMemo(() => {
        const tabs = items.map((item) => (
            <div
                key={`${item.id}_tabMonitoringViews`}
                className={`${styles.tab} ${item.id === selectTab.id ? styles.select : ''}`}
                onClick={() => selectTab.id !== item.id && onChange(item)}
            >
                {item.label}
            </div>
        ));
        return tabs;
    }, [items, selectTab]);
    return (
        <div className={styles.monitoringViewsTabs_root}>
            {getTabs}
        </div>
    );
};

export default React.memo(MonitoringViewsTabs);
