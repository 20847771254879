/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import {
    ContactType, IContact, IContactPerson, ICustomerInfo, IProjectStatus, IQPCustomer,
} from '@services/projects/types';
// import PenGray from '@assets/images/pen-gray.svg';
import MessageErrorValidation from '@components/Project/Popup';
import { Button, SvgIcon } from '@shared/index';
import TextField from '@entities/TextField';
import styles from './styles.module.scss';

interface IError {
    companyName: boolean,
    address: boolean,
    companyPhone: boolean,
    personName: boolean,
    email: boolean,
    phonePerson: boolean,
}

const error: IError = {
    companyName: false, address: false, companyPhone: false, personName: false, email: false, phonePerson: false,
};

interface IProps {
    customer: ICustomerInfo;
    onSave: (value: IQPCustomer) => void;
    status: IProjectStatus;
}

let initialContacts: IContact[] = [];

const contacts = (value: IContactPerson[]): IContact[] => {
    if (initialContacts.length > 0) {
        return initialContacts;
    }
    if (value?.slice(0, 1)?.length > 0) {
        initialContacts = [...value.slice(0, 1)[0]?.contacts ?? []];
    }

    return initialContacts;
};

const regular = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ContactsCustomer: React.FC<IProps> = ({ customer, onSave, status }): JSX.Element => {
    const startedContacts = {
        companyName: customer?.companyName ?? '',
        address: customer?.address ?? '',
        personName: customer?.contactPersons?.slice(0, 1)[0].fullName ?? '',
        companyPhone: contacts(customer?.contactPersons).find(({ type }) => type === ContactType.phoneNumber)?.value ?? '',
        email: contacts(customer?.contactPersons).find(({ type }) => type === ContactType.email)?.value ?? '',
        phonePerson: contacts(customer?.contactPersons).find(({ type }) => type === ContactType.customerNumber)?.value ?? '',
    };

    const [isEdit, setEditState] = useState<boolean>(false);
    const [isActiveAnimate, setIsActiveAnimate] = useState<boolean>(false);
    const [errorsValidation, setErrorsValidation] = useState<IError>(error);
    const [companyName, setCompanyName] = useState<string>(startedContacts.companyName);
    const [address, setAddress] = useState<string>(startedContacts.address);
    const [personName, setPersonName] = useState<string>(startedContacts.personName);
    const [companyPhone, setPhone] = useState<string>(startedContacts.companyPhone);
    const [email, setEmail] = useState<string>(startedContacts.email);
    const [phonePerson, setPhonePerson] = useState<string>(startedContacts.phonePerson);
    const [isOpen, setOpen] = useState<boolean>(true);

    const isError = useMemo<boolean>(() => (
        errorsValidation.companyName || errorsValidation.personName
        || errorsValidation.companyPhone || errorsValidation.phonePerson
        || errorsValidation.email || errorsValidation.address
    ), [errorsValidation]);

    const isFieldsFill = useMemo<boolean>(() => (
        companyName?.length > 0
        && address?.length > 0
        && personName?.length > 0
        && companyPhone?.length > 0
        && email?.length > 0
        && phonePerson?.length > 0
    ), [companyName, address, personName, companyPhone, email, phonePerson]);

    const validateCompanyName = (value: string): boolean => {
        if (value.length < 5) return true;
        setCompanyName(value);
        return false;
    };
    const validateAddress = (value: string) => {
        if (value.length < 5) return true;
        setAddress(value);
        return false;
    };
    const validatePhone = (value: string, person: boolean) => {
        // const regularPhone = /^\d+$/;
        // if (!regularPhone.test(value) || value.length !== 11) return true;
        // if (!regularPhone.test(value)) return true;
        if (person) {
            setPhonePerson(value);
            return false;
        }
        setPhone(value);
        return false;
    };
    const validateFullName = (value: string) => {
        if (value.length < 5) return true;
        setPersonName(value);
        return false;
    };
    const validateEmail = (value: string) => {
        if (regular.test(String(value).toLowerCase())) {
            setEmail(value);
            return false;
        }
        return true;
    };

    const emptyErrorsValidation: IError = {
        companyName: false,
        address: false,
        companyPhone: false,
        personName: false,
        email: false,
        phonePerson: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const validationForm = () => {
        setErrorsValidation({
            companyName: validateCompanyName(companyName),
            address: validateAddress(address),
            companyPhone: validatePhone(companyPhone, false),
            personName: validateFullName(personName),
            email: validateEmail(email),
            phonePerson: validatePhone(phonePerson, true),
        });
    };

    const checkingValueOrganization = (value: string) => {
        setCompanyName(value);
        if (validateCompanyName(value)) {
            // setErrorsValidation({ ...emptyErrorsValidation, companyName: true });
            return;
        }
        setErrorsValidation({ ...emptyErrorsValidation, companyName: false });
    };
    const checkingValueAddress = (value: string) => {
        setAddress(value);
        if (validateAddress(value)) {
            // setErrorsValidation({ ...emptyErrorsValidation, address: true });
            return;
        }
        setErrorsValidation({ ...emptyErrorsValidation, address: false });
    };
    const checkingValuePhone = (value: string) => {
        setPhone(value);
        if (validatePhone(value, false)) {
            // setErrorsValidation({ ...emptyErrorsValidation, companyPhone: true });
            return;
        }
        setErrorsValidation({ ...emptyErrorsValidation, companyPhone: false });
    };
    const checkingValueFullName = (value: string) => {
        setPersonName(value);
        if (validateFullName(value)) {
            // setErrorsValidation({ ...emptyErrorsValidation, personName: true });
            return;
        }
        setErrorsValidation({ ...emptyErrorsValidation, personName: false });
    };
    const checkingValueEmail = (value: string) => {
        setEmail(value);
        if (validateEmail(value)) {
            // setErrorsValidation({ ...emptyErrorsValidation, email: true });
            return;
        }
        setErrorsValidation({ ...emptyErrorsValidation, email: false });
    };

    const checkingValuePhonePerson = (value: string) => {
        setPhonePerson(value);
        if (validatePhone(value, true)) {
            // setErrorsValidation({ ...emptyErrorsValidation, phonePerson: true });
            return;
        }
        setErrorsValidation({ ...emptyErrorsValidation, phonePerson: false });
    };

    const cancel = () => {
        setErrorsValidation(emptyErrorsValidation);
        setCompanyName(startedContacts.companyName);
        setAddress(startedContacts.address);
        setPersonName(startedContacts.personName);
        setPhone(startedContacts.companyPhone);
        setEmail(startedContacts.email);
        setPhonePerson(startedContacts.phonePerson);
        setEditState(false);
    };

    const startEdit = () => {
        setOpen(true);
        setEditState(true);
    };

    const save = () => {
        // validationForm();
        const commonInfo: IQPCustomer = {
            name: '',
            address,
            companyName,
            personName,
            id: null,
            contacts: [],
        };
        if (companyPhone) {
            commonInfo.contacts.push({
                personId: null,
                type: ContactType.phoneNumber,
                value: companyPhone,
            });
        }
        if (phonePerson) {
            commonInfo.contacts.push({
                personId: null,
                type: ContactType.customerNumber,
                value: phonePerson,
            });
        }
        if (email) {
            commonInfo.contacts.push({
                personId: null,
                type: ContactType.email,
                value: email,
            });
        }
        onSave(commonInfo);
    };

    const setCustomer = useCallback(() => {
        setCompanyName(customer?.companyName ?? '');
        setAddress(customer?.address ?? '');
        if (customer?.contactPersons?.slice(0, 1)?.length > 0) {
            const buffer = customer.contactPersons.slice(0, 1)[0];
            setPersonName(buffer?.fullName ?? '');
            setPhone(buffer?.contacts?.find(({ type }) => type === ContactType.phoneNumber)?.value ?? '');
            setEmail(buffer?.contacts?.find(({ type }) => type === ContactType.email)?.value ?? '');
            setPhonePerson(buffer?.contacts?.find(({ type }) => type === ContactType.customerNumber)?.value ?? '');
        }
    }, [customer]);

    useEffect(() => {
        if (customer) {
            setCustomer();
        }
    }, [customer]);

    /**
     * Если проект вновь создан (stage === 1 && status === 1) и в нём ещё не заполнены контактные данные
     * то по продуктовому флоу блок должен быть в состоянии редактирования
     */
    useEffect(() => {
        if (!isFieldsFill && status?.stage === 1 && status?.status === 1) {
            setEditState(true);
        }
    }, [status, isFieldsFill]);

    const handleClickOnFullForm = () => {
        if (isEdit || isActiveAnimate) return;
        setIsActiveAnimate(true);
        setTimeout(() => setIsActiveAnimate(false), 2600);
    };
    return (
        <div className={styles.contactsCustomer_root}>
            <div className={styles.contactsCustomer_head}>
                <span className={styles.contactsCustomer_title}>Контакты заказчика</span>
                <div className={styles.contactsCustomer_head_actions}>
                    <button
                        type="button"
                        onClick={startEdit}
                        className={classnames(styles.contactsCustomer_btnPen, isEdit ? styles.invisible : '')}
                    >
                        <div className={`${(isActiveAnimate && !isEdit) && styles.animation}`}>
                            {/* <img src={PenGray} alt="PenGray" className={styles.animation} /> */}
                            <SvgIcon size={24} id="ChangeMini_Icn" />
                        </div>
                    </button>
                    <div
                        role="button"
                        className={`${styles.contactsCustomer_controller} ${isOpen ? styles.close : styles.open}`}
                        onClick={() => setOpen(!isOpen)}
                    >
                        <SvgIcon size={16} id="dropdownArrow" />
                    </div>
                </div>
            </div>
            {isOpen
                && (
                    <>
                        <form className={styles.form} onClick={handleClickOnFullForm}>
                            <div className={styles.form_row}>
                                <div className={styles.form_cell}>
                                    <MessageErrorValidation
                                        message="Минимальное значение 5 символов"
                                        active={errorsValidation.companyName}
                                    >
                                        <TextField
                                            value={companyName}
                                            onChange={(value: string) => checkingValueOrganization(value)}
                                            label="Наименование организации"
                                            placeholder="Введите наименование"
                                            readonly={!isEdit}
                                            className={styles.form_inputName}
                                        />
                                    </MessageErrorValidation>
                                </div>

                                <div className={styles.form_cell}>
                                    <MessageErrorValidation message="Минимальное значение 5 символов" active={errorsValidation.address}>
                                        <TextField
                                            label="Адрес"
                                            placeholder="Введите адрес"
                                            value={address}
                                            readonly={!isEdit}
                                            onChange={(value) => checkingValueAddress(value)}
                                            className={styles.form_inputAddress}
                                        />
                                    </MessageErrorValidation>
                                </div>
                                <div className={styles.form_cell}>
                                    <MessageErrorValidation
                                        message="Номер должен состоять из 8 цифр"
                                        active={errorsValidation.companyPhone}
                                    >
                                        <TextField
                                            label="Телефон организации"
                                            placeholder="+7 (___) ___ - __ - __"
                                            className={styles.form_inputPhone}
                                            value={companyPhone}
                                            readonly={!isEdit}
                                            onChange={(value) => checkingValuePhone(value)}
                                        />
                                    </MessageErrorValidation>
                                </div>
                            </div>
                            <span className={styles.form_title}>Контактное лицо</span>
                            <div className={styles.form_row}>
                                <div className={styles.form_cell}>
                                    <MessageErrorValidation message="Минимальное значение 5 символов" active={errorsValidation.personName}>
                                        <TextField
                                            value={personName}
                                            onChange={(value) => checkingValueFullName(value)}
                                            label="ФИО"
                                            placeholder="Введите ФИО"
                                            readonly={!isEdit}
                                            className={styles.form_inputName}
                                        />
                                    </MessageErrorValidation>
                                </div>
                                <div className={styles.form_cell}>
                                    <MessageErrorValidation message="Не корректный E-mail" active={errorsValidation.email}>
                                        <TextField
                                            value={email}
                                            onChange={(value: string) => checkingValueEmail(value)}
                                            label="E-mail"
                                            placeholder="Введите e-mail"
                                            readonly={!isEdit}
                                            className={styles.form_inputAddress}
                                        />
                                    </MessageErrorValidation>
                                </div>
                                <div className={classnames(styles.form_cell)}>
                                    <MessageErrorValidation
                                        message="Номер должен состоять из 8 цифр"
                                        active={errorsValidation.phonePerson}
                                    >
                                        <TextField
                                            label="Телефон"
                                            placeholder="+7 (___) ___ - __ - __"
                                            className={styles.form_inputPhone}
                                            value={phonePerson}
                                            readonly={!isEdit}
                                            onChange={(value) => checkingValuePhonePerson(value)}
                                        />
                                    </MessageErrorValidation>
                                </div>
                            </div>
                        </form>
                        {
                            isEdit && (
                                <div className={styles.contactsCustomer_btnContainer}>
                                    <Button
                                        text="Отмена"
                                        type="secondary"
                                        size="large"
                                        onClick={cancel}
                                    />
                                    <Button
                                        text="Сохранить"
                                        type="default"
                                        size="large"
                                        onClick={save}
                                        disabled={!isEdit || isError || !isFieldsFill}
                                    />
                                </div>
                            )
                        }
                    </>
                )}
        </div>
    );
};

export default ContactsCustomer;
