import { SortOrder } from '@services/sources/types';

export interface IQueryParamsLoadAuthors {
    campaign_id: number;
    page: number;
    from_date?: string;
    to_date?: string;
    order?: SortOrder
    column?: IQuerySortFieldsLoadAuthors;
    search_query?: string;
}

export enum IQuerySortFieldsLoadAuthors {
    likes = 'likes',
    comments = 'comments',
    views = 'views',
    reactions = 'reactions',
    percent = 'percent',
    postsCount = 'posts_count',
}

export interface IAuthorFromApi {
    posts_count: number;
    likes: number;
    views: number;
    comments: number;
    percent: number;
    reactions: number;
    author_id: number;
    author_name: string;
}

export interface IAuthorModel {
    postsCount: number;
    likes: number | null;
    views: number | null;
    comments: number | null;
    percent: number;
    reactions: number | null;
    id: number;
    authorName: string;
}
