export const Sources = [
    {
        id: 75126,
        name: "Stas Vasilev",
        platform: 'telegram',
        type: "blogger",
        rating: 0,
        date: 1662015600,
        matchPercent: 28,
        contracted: true,
        connections: [98767509,98767504349]
    },
    {
        id: 1662396991,
        name: "Минаев Live",
        platform: 'telegram',
        type: "blogger",
        rating: 1,
        date: 1662036991,
        contracted: true,
        matchPercent: 2,
        connections: [45657321210965]
    },
    // 2ое 8
    {
        id: 98767509,
        name: "ТАСС",
        type: "massMedia",
        platform: 'telegram',
        rating: 1,
        date: 1662094800,
        contracted: false,
        matchPercent: 28,
        connections: [45657210965]
    },
    // 2ое 12
    {
        id: 98767504349,
        name: "Комерсант",
        type: "massMedia",
        platform: 'telegram',
        rating: 1,
        date: 1662109200,
        contracted: true,
        matchPercent: 55,
        connections: [15678343208]
    },
    // 3ое 6

    {
        id: 15678343208,
        name: "Badcomedian",
        platform: 'telegram',
        type: "blogger",
        rating: 0,
        date: 1662174000,
        contracted: true,
        matchPercent: 55,
        connections: [4554657210965]
    },
    // 3ое 10
    {
        id: 45657210965,
        name: "РИА Новости",
        platform: 'telegram',
        type: "massMedia",
        rating: 0,
        date: 1662188400,
        contracted: true,
        matchPercent: 55,
        connections: [4554657210965]
    },
    // 3ое 14
    {
        id: 45657321210965,
        name: "РИА Новости",
        platform: 'telegram',
        type: "massMedia",
        rating: 0,
        date: 1662202800,
        contracted: true,
        matchPercent: 28,
        connections: [4554657210965]
    },
    {
        id: 4554657210965,
        name: "РИА Новости",
        platform: 'telegram',
        type: "massMedia",
        rating: 1,
        date: 1662303391,
        contracted: true,
        matchPercent: 80,
        connections: [
            9219841289,
            456574546210965,
            45657454644210965,
            45657114546210965,
            45657466546210965,
            4565745443644210965,
            45623574546210965,1662382591
        ]
    },
    {
        id: 1662382591,
        name: "РИА Новости",
        platform: 'vk',
        type: "massMedia",
        rating: 1,
        date: 1662339600,
        contracted: true,
        matchPercent: 28,
        connections: [45654374546210965,4565745456210965, 1662310591]
    }, 
    {
        id: 9219841289,
        name: "Артемий Лебедев",
        platform: 'vk',
        type: "blogger",
        rating: 1,
        date: 1662382591,
        contracted: true,
        matchPercent: 28,
        connections: [4565745456210965]
    },
    {
        id: 456574546210965,
        name: "РИА Новости",
        platform: 'vk',
        type: "massMedia",
        rating: 0,
        date: 1662393600,
        contracted: true,
        matchPercent: 100,
        connections: [45654374546210965]
    },
    {
        id: 45657454644210965,
        name: "РИА Новости",
        platform: 'vk',
        type: "massMedia",
        rating: 1,
        date: 1662386400,
        contracted: true,
        matchPercent: 28,
        connections: [45654374546210965]
    },
    {
        id: 45657114546210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'ok',
        rating: 1,
        date: 1662372000,
        contracted: true,
        matchPercent: 100,
        connections: [45657454632210965,456574512346210965]
    },
    {
        id: 45657466546210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'ok',
        rating: 1,
        date: 1662364800,
        contracted: false,
        matchPercent: 28,
        connections: [45657454625410965]
    },
    {
        id: 4565745443644210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'ok',
        rating: 1,
        date: 1662404400,
        contracted: true,
        matchPercent: 100,
        connections: [45657454625410965, 45657454632210965]
    },
    {
        id: 45623574546210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'ok',
        rating: 1,
        date: 1662400800,
        contracted: true,
        matchPercent: 28,
        connections: [4565745456210965, 45657454625410965]
    },
    {
        id: 4565745456210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'telegram',
        rating: 1,
        date: 1662483600,
        contracted: true,
        matchPercent: 28,
        connections: [456574545436210965]
    }, 
    {
        id: 45657454625410965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'rss',
        rating: 1,
        date: 1662469200,
        contracted: true,
        matchPercent: 1,
        connections: [456574545436210965]
    },
    {
        id: 45657454632210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'rss',
        rating: 1,
        date: 1662454800,
        contracted: true,
        matchPercent: 28,
        connections: [4565745422456210965]
    },
    {
        id: 456574512346210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'rss',
        rating: 0,
        date: 1662462000,
        contracted: false,
        matchPercent: 28,
        connections: [4565745422456210965]
    },
    {
        id: 45654374546210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'rss',
        rating: 1,
        date: 1662476400,
        contracted: true,
        matchPercent: 28,
        connections: [4565745422456210965]
    },


    {
        id: 4565745422456210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'rss',
        rating: 1,
        date: 1662562800,
        contracted: true,
        matchPercent: 28,
        connections: []
    },
    {
        id: 456574545436210965,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'rss',
        rating: 1,
        date: 1662555600,
        contracted: true,
        matchPercent: 65,
        connections: []
    },
    {
        id: 1662310591,
        name: "РИА Новости",
        type: "massMedia",
        platform: 'rss',
        rating: 0,
        date: 1662548400,
        contracted: false,
        matchPercent: 58,
        connections: []
    }, 
    
]