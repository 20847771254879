import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { ICampaignTrackedWordsBlockProps } from './types';
import CampaignTrackedWordsInputContainer from '../../../../containers/campaign/CampaignTrackedWords';
import CampaignWordsFavoriteListContainer from '../../../../containers/campaign/WordsFavoriteList';
import CampaignSourcesFavoriteListContainer from '../../../../containers/campaign/SourcesFavoriteList';
import { ITrackedSourceModel } from '../../../../store/campaign/tracked-sources/types';
import styles from './styles.module.scss';
import SvgIcon from '../../../SvgIcon';

const CampaignTrackedWordsBlock: React.FC<ICampaignTrackedWordsBlockProps> = ({
    title, placeholder, words,
    onDeleteWord, onAddWord,
    inputTagId, addBtnTagId,
    delBtnTagId, className,
    isDisabled, verifiable,
    isErrorHighlighting, isView,
    withSearch, sourceType,
    fullScreen, isTrackedSources, isIPadSize,
    addFavoriteTagId,
}): JSX.Element => {
    const [currentInputValue, setCurrentInputValue] = useState<string>('');
    const [isOpenWordsFavorite, setOpenWordsFavorite] = useState<boolean>(false);
    const [favorite, setFavorite] = useState<string>('');
    const [lastAddedSource, setAddedSource] = useState<ITrackedSourceModel>(null);

    const handlerDeleteWord = (id: number): void => {
        onDeleteWord(id, withSearch);
    };
    const handlerCloseFavoriteList = (): void => {
        setOpenWordsFavorite(false);
    };

    const handlerOpenFavoriteList = useCallback((): void => {
        if (!isOpenWordsFavorite) {
            setOpenWordsFavorite(true);
        }
    }, [isOpenWordsFavorite]);

    useEffect(() => {
        setFavorite(currentInputValue);
    }, [currentInputValue]);

    if (isDisabled) {
        return null;
    }
    if (isView && !words?.length) {
        return null;
    }

    return (
        <div
            className={classnames(
                styles.campaignTrackedWordsBlock_root,
                styles[fullScreen ? 'fullScreen' : ''],
                className,
            )}
            style={isIPadSize ? { width: 340 } : null}
        >
            <span className={styles.title}>{title}</span>
            {
                !isView && (
                    <div style={{ position: 'relative' }}>
                        <div className={styles.flexRow} style={{ gap: 14 }}>
                            <CampaignTrackedWordsInputContainer
                                addBtnTagId={addBtnTagId}
                                inputTagId={inputTagId}
                                onAddWord={onAddWord}
                                onChangeWord={setCurrentInputValue}
                                onAddedSource={setAddedSource}
                                placeholder={placeholder}
                                verifiable={verifiable}
                                isErrorHighlighting={isErrorHighlighting}
                                withSearch={withSearch}
                                sourceType={sourceType}
                                isTrackedSources={isTrackedSources}
                                isIPadSize={isIPadSize}
                            />
                            {
                                addFavoriteTagId && (
                                    <button
                                        id={addFavoriteTagId}
                                        type="button"
                                        className={styles.favoriteBtn}
                                        onClick={handlerOpenFavoriteList}
                                    >
                                        <SvgIcon id="favorite" size={16} height={22} />
                                    </button>
                                )
                            }
                        </div>
                        {
                            addFavoriteTagId && (
                                withSearch
                                    ? (
                                        <CampaignSourcesFavoriteListContainer
                                            top={-50}
                                            left={500}
                                            isOpen={isOpenWordsFavorite}
                                            onAddWord={onAddWord}
                                            newSource={lastAddedSource}
                                            onClose={handlerCloseFavoriteList}
                                        />
                                    )
                                    : (
                                        <CampaignWordsFavoriteListContainer
                                            top={-50}
                                            left={500}
                                            isOpen={isOpenWordsFavorite}
                                            onAddWord={onAddWord}
                                            newWord={favorite}
                                            onClose={handlerCloseFavoriteList}
                                        />
                                    )
                            )
                        }
                    </div>
                )
            }
            {
                words?.length > 0 && (
                    <div
                        className={classnames(styles.wordsContainer, styles[fullScreen ? 'fullScreen' : ''])}
                        style={isIPadSize ? { width: 300 } : null}
                    >
                        {
                            words.map((item) => (
                                <div className={styles.wordBox} key={item.id}>
                                    <span className={styles.wordBox_text}>{item.name}</span>
                                    {
                                        !isView && (
                                            <button
                                                id={delBtnTagId}
                                                type="button"
                                                className={styles.button}
                                                onClick={() => handlerDeleteWord(item.id)}
                                            >
                                                <SvgIcon id="Cross" size={14} />
                                            </button>
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default CampaignTrackedWordsBlock;
