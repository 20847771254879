import React from 'react';
import { Button, SvgIcon } from '@shared/index';
import styles from './styles.module.scss';

interface IPropsChartStatus {
    isError: boolean,
    isLoading: boolean,
    onUpdateClick: () => void
}
const ChartStatus: React.FC<IPropsChartStatus> = ({ isError, isLoading, onUpdateClick }): JSX.Element => {
    const loadingChartMessage = (
        <div className={styles.statusChart_message}>
            <div>
                Идет процесс построения графиков.
            </div>
            <div>
                Это может занять несколько часов
            </div>
        </div>
    );
    const errorChartMessage = (
        <div>
            <div className={styles.statusChart_title}>
                <SvgIcon id="alert-circle" size={32} />
                <div>
                    К сожалению, что-то пошло не так.
                </div>
            </div>
            <div className={styles.statusChart_message}>
                <div>График построить не удалось.</div>
                <div>Попробуйте обновить данные</div>
            </div>
        </div>
    );
    if (!isError && !isLoading) return null;
    return (
        <div className={styles.statusChart}>
            <div className={styles.statusChart_content}>
                {isLoading ? loadingChartMessage : errorChartMessage}
            </div>
            <Button
                text="Обновить"
                type="default"
                size="large"
                onClick={onUpdateClick}
            />
        </div>
    );
};

export default React.memo(ChartStatus);
