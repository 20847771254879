import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CampaignQuestionModal from '@components/Modal/Campaign/Question';
import CampaignProcessModal from '@components/Modal/Campaign/Process';
import CampaignMenuTabsContainer from '@containers/campaign/Menu';
import { CampaignActiveModalType, ModalAnswerType } from '@store/campaign/source/types';
import campaignSelectors from '@store/campaign/source/selectors';
import { useAppDispatch } from '@store/hooks';
import { campaignActions } from '@store/campaign/source';
import { singleCampaignRtkQService } from '@services/campaigns/single-campaign';
import styles from './styles.module.scss';

const CampaignPage: React.FC = (): JSX.Element => {
    const { Id: urlParametersId } = useParams();

    const dispatch = useAppDispatch();
    const activeModal = useSelector(campaignSelectors.activeModal);
    const modalAnswer = useSelector(campaignSelectors.modalAnswer);
    const processModalMessage = useSelector(campaignSelectors.processModalMessage);
    const processModalStatus = useSelector(campaignSelectors.processModalStatus);
    const campaignFromStore = useSelector(campaignSelectors.currentCampaign);

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [delModalIsOpen, setDelModalIsOpen] = useState<boolean>(false);
    const [activateModalIsOpen, setActivateModalIsOpen] = useState<boolean>(false);
    const [processModalIsOpen, setProcessModalIsOpen] = useState<boolean>(false);

    const [
        getCampaign,
        { data: campaign },
    ] = singleCampaignRtkQService.useLazyGetCampaignQuery();

    const campaignName = useMemo<string>(() => (
        campaignFromStore?.name ? campaignFromStore?.name : campaign?.name
    ), [campaignFromStore, campaign]);

    const campaignIsActive = useMemo<boolean>(() => (
        campaignFromStore?.isActive ? campaignFromStore?.isActive : campaign?.isActive
    ), [campaignFromStore, campaign]);

    const handlerCloseProcessModal = (): void => {
        dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
        setProcessModalIsOpen(false);
    };

    useEffect(() => {
        const id = Number(urlParametersId);
        if (Number.isInteger(id) && campaign?.id !== id) {
            getCampaign({ id });
        }
    }, [urlParametersId, campaign]);

    useEffect(() => {
        switch (activeModal) {
            case CampaignActiveModalType.cancelCreation:
            case CampaignActiveModalType.switchingPage:
                setModalIsOpen(true);
                break;
            case CampaignActiveModalType.deletingCampaign:
                setDelModalIsOpen(true);
                break;
            case CampaignActiveModalType.activatingCampaign:
                setActivateModalIsOpen(true);
                break;
            case CampaignActiveModalType.process:
                setProcessModalIsOpen(true);
                break;
            default:
        }
    }, [activeModal]);
    useEffect(() => {
        if (modalAnswer === ModalAnswerType.reject) {
            setModalIsOpen(false);
            setDelModalIsOpen(false);
            setActivateModalIsOpen(false);
        }
    }, [modalAnswer]);

    return (
        <div className={styles.root}>
            <CampaignMenuTabsContainer />
            <Outlet />
            <CampaignQuestionModal
                submitBtnTagId="campaign-cancellation-yes"
                cancelBtnTagId="campaign-cancellation-cancel"
                messages={['Введенные данные не будут сохранены.', 'Продолжить?']}
                isOpen={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
            />
            <CampaignQuestionModal
                submitBtnTagId="campaign-delete-yes"
                cancelBtnTagId="campaign-delete-cancel"
                messages={[`Вы уверены, что хотите удалить кампанию "${campaignName}"?`]}
                isOpen={delModalIsOpen}
                onClose={() => setDelModalIsOpen(false)}
            />
            <CampaignQuestionModal
                submitBtnTagId="campaign-activate-yes"
                cancelBtnTagId="campaign-activate-cancel"
                messages={[
                    `Вы уверены, что хотите ${campaignIsActive ? 'выключить' : 'включить'} кампанию "${campaignName}"?`,
                ]}
                isOpen={activateModalIsOpen}
                onClose={() => setActivateModalIsOpen(false)}
            />
            <CampaignProcessModal
                submitBtnTagId="campaign-modal-info-btn"
                messages={[processModalMessage]}
                isOpen={processModalIsOpen}
                status={processModalStatus}
                onClose={() => handlerCloseProcessModal()}
            />
        </div>
    );
};

export default CampaignPage;
