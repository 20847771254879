import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonWindowState } from './types';

const initialState: ICommonWindowState = {
    width: 0,
    isRetinaSize: false,
    isIPad: false,
};

const { actions: commonWindowActions, reducer } = createSlice({
    name: 'commonWindow',
    initialState,
    reducers: {
        setRetinaSize: (state: ICommonWindowState): void => {
            state.isRetinaSize = true;
        },
        unsetRetinaSize: (state: ICommonWindowState): void => {
            state.isRetinaSize = false;
        },
        setIPadSize: (state: ICommonWindowState): void => {
            state.isIPad = true;
        },
        unsetIPadSize: (state: ICommonWindowState): void => {
            state.isIPad = false;
        },
        setWidth: (state: ICommonWindowState, { payload }: PayloadAction<number>): void => {
            state.width = payload;
        },
    },
});

export {
    commonWindowActions,
};

export default reducer;
