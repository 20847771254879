import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IconPlus from '../../../../../assets/images/plus.svg';
import IconTrash from '../../../../../assets/images/trash.svg';
import { ICampaignDataModel } from '../../../../../services/campaigns/campaigns-list/types';
import { campaignUsersRtkQService } from '../../../../../services/campaigns/users';
import campaignSelectors from '../../../../../store/campaign/userRights/selectors';
import { ICampaignUserRightsUserInfo } from '../../../../../store/campaign/userRights/types';
// import { useAppDispatch } from '../../../../../store/hooks';
import { CommonProcessStatus, FetchStatus } from '../../../../../store/types';
import IconArrow from '../IconArrow';
import styles from './styles.module.scss';

interface IProps {
    campaign: ICampaignDataModel;
    handleClickDelete: (
        campaignId: number,
        user: ICampaignUserRightsUserInfo,
    ) => void;
    handleClickOpenAddUserModal: (campaignId?: number) => void;
    deleteStatus: CommonProcessStatus;
    addUserStatus: FetchStatus;
    numbering: number
}

const CampaignUserRightsTableRow: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        campaign,
        handleClickDelete,
        handleClickOpenAddUserModal,
        deleteStatus,
        addUserStatus,
        numbering,
    } = props;

    // const dispatch = useAppDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);
    const usersState = useSelector(campaignSelectors.userRightsStateUsers);
    const [users, setUsers] = useState<ICampaignUserRightsUserInfo[]>([]);

    const [getUsers] = campaignUsersRtkQService.useLazyGetCampaignUsersQuery();

    const handleClickToggle = (): void => setOpen((prev) => !prev);

    useEffect(() => {
        if (isOpen && users.length < 1) {
            getUsers({ id: campaign.id });
        }

        if (isOpen && addUserStatus === FetchStatus.success) {
            getUsers({ id: campaign.id });
        }

        if (isOpen && deleteStatus === CommonProcessStatus.success) {
            getUsers({ id: campaign.id });
        }
    }, [campaign, isOpen, addUserStatus, deleteStatus]);

    useEffect(() => {
        if (usersState) {
            const data = usersState.filter((state) => state.campaignId === campaign.id);
            if (data.length === 1) setUsers(data[0].users);
        }
    }, [usersState]);

    const getUserRole = (id: number): string => {
        if (id === 1) return 'Полный доступ';
        if (id === 3) return 'Только чтение';
        return 'Неопределенно';
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.row}>
                <div className={styles.rowName}>
                    <span>{numbering}</span>
                    <span>{campaign.name}</span>
                </div>
                <span
                    className={classnames(
                        styles.rowAccess,
                        isOpen ? styles.hidden : '',
                    )}
                >
                    Полный доступ
                </span>
                <button
                    type="button"
                    onClick={handleClickToggle}
                    className={classnames(
                        styles.rowMoreButton,
                        isOpen ? styles.hidden : '',
                    )}
                >
                    Подробнее
                </button>
                <div className={styles.actions}>
                    <button
                        type="button"
                        className={classnames(
                            styles.arrowButton,
                            isOpen ? styles.arrowButtonOpen : '',
                        )}
                        onClick={handleClickToggle}
                    >
                        <IconArrow />
                    </button>
                    <button
                        type="button"
                        className={styles.addUserButton}
                        onClick={() => handleClickOpenAddUserModal(campaign.id)}
                    >
                        <img src={IconPlus} alt="Icon plus " />
                    </button>
                </div>
            </div>

            {isOpen && users.length >= 1 && (
                <div className={styles.list}>
                    {users.map((userInfo) => (
                        <div key={userInfo.userId} className={styles.listRow}>
                            <span>{getUserRole(userInfo.role.id)}</span>
                            <span>
                                {`${userInfo.lastName} ${
                                    userInfo.middleName ?? ''
                                }`}
                            </span>
                            <span className={styles.actions}>
                                <button
                                    type="button"
                                    onClick={() => handleClickDelete(campaign.id, userInfo)}
                                >
                                    <img src={IconTrash} alt="icon trash" />
                                </button>
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default CampaignUserRightsTableRow;
