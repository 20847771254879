import { rtkQApi } from '../../index';
import { IUserTagModel } from './models';
// import {} from './types';

export const userTagsCampaingRtqService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getUserTags: build.query<IUserTagModel[], any>({
            query: ({ campaign_id }) => ({
                url: `campaign/user_tag/?campaign_id=${campaign_id}`,
                method: 'GET',
            }),
        }),
        createUserTag: build.mutation<any, any>({
            query: (body) => ({
                url: 'campaign/user_tag/',
                method: 'POST',
                body,
            }),
        }),
        autoTag: build.mutation<any, any>({
            query: (body) => ({
                url: 'campaign/user_tag/autotag/',
                method: 'POST',
                body,
            }),
        }),
        addTagToPost: build.mutation<any, any>({
            query: (data) => ({
                url: `campaign/${data.campaign_id}/posts/tag`,
                method: 'PUT',
                body: data.body,
            }),
        }),
        removeTagFromPost: build.mutation<any, any>({
            query: ({ campaign_id, body }) => ({
                url: `campaign/${campaign_id}/posts/remove_tag`,
                method: 'PUT',
                body,
            }),
        }),
        // 1?campaign_id=1
        getUserTagById: build.query<any, any>({
            query: ({ campaign_id, id }) => ({
                url: `campaign/user_tag/${+id}?campaign_id=${campaign_id}`,
                method: 'GET',
            }),
        }),
        removeUserTag: build.query<any, any>({
            query: ({ campaign_id, id }) => ({
                url: `campaign/${campaign_id}/user_tag/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});
