import { IDictionary } from '@store/types';

export enum TableNames {
    'MediaPlan' = 'MediaPlan',
}

export interface IColumnProperties {
    id: number;
    name: string;
    visible: boolean;
    title?: string;
}

export interface ITableProperties {
    isSyncWithLocalStorage: boolean;
    localStorageKey: string;
    columns: IDictionary<IColumnProperties>;
}
