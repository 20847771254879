/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Skeleton from 'react-loading-skeleton';

// eslint-disable-next-line arrow-body-style
const SkeletonList: React.FC = (): JSX.Element => {
    return (
        <>
            <div style={{ marginRight: 16 }}>
                <Skeleton
                    height={32}
                    width={32}
                    style={{ marginBottom: 8 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                />
            </div>
            <div style={{ marginRight: 16 }}>
                <Skeleton
                    height={32}
                    width={32}
                    style={{ marginBottom: 8 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                />
            </div>
            <div style={{ marginRight: 16 }}>
                <Skeleton
                    height={32}
                    width={32}
                    style={{ marginBottom: 8 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                />
            </div>
            <div style={{ marginRight: 16 }}>
                <Skeleton
                    height={32}
                    width={32}
                    style={{ marginBottom: 8 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                />
            </div>
            <div style={{ marginRight: 16 }}>
                <Skeleton
                    height={32}
                    width={32}
                    style={{ marginBottom: 8 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    height={10}
                    width={72}
                />
            </div>
        </>
    );
};

export default SkeletonList;
