import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITrackedSourceModel, ITrackedSourcesState } from './types';
import { FetchStatus } from '../../types';

const initialState: ITrackedSourcesState = {
    data: [],
    selectedSources: {
        excluded: [],
        tracked: [],
    },
    fetchStatus: FetchStatus.undefined,
    fetchErrors: '',
};

const { actions: campaignTrackedSourcesActions, reducer } = createSlice({
    name: 'trackedSources',
    initialState,
    reducers: {
        startLoad: (state: ITrackedSourcesState): void => {
            state.fetchStatus = FetchStatus.loading;
        },
        successLoad: (state: ITrackedSourcesState): void => {
            state.fetchStatus = FetchStatus.success;
        },
        failedLoad: (state: ITrackedSourcesState): void => {
            state.fetchStatus = FetchStatus.failed;
        },
        setFetchErrors: (state: ITrackedSourcesState, { payload }: PayloadAction<string>): void => {
            state.fetchErrors = payload;
        },
        setSourcesFromApi: (state: ITrackedSourcesState, { payload }: PayloadAction<unknown>): void => {
            state.data = !payload ? [] : Object.keys(payload).map((key) => (
                {
                    id: payload[key]?.id,
                    allowInRussia: payload[key]?.allow_in_russia,
                    authorId: payload[key]?.author_id,
                    autoDiscover: payload[key]?.auto_discover,
                    created: payload[key]?.created,
                    enabled: payload[key]?.enabled,
                    link: payload[key]?.link,
                    locationId: payload[key]?.location_id,
                    outSourceId: payload[key]?.out_source_id,
                    parseLink: payload[key]?.parse_link,
                    parsed: payload[key]?.parsed,
                    tag: payload[key]?.tag,
                    title: payload[key]?.title,
                    typeId: payload[key]?.type_id,
                } as ITrackedSourceModel
            ));
        },
        setSelectedSource: (state: ITrackedSourcesState, { payload }: PayloadAction<{
            isTracked: boolean, data: ITrackedSourceModel[],
        }>): void => {
            if (payload?.data) {
                if (payload?.isTracked) {
                    state.selectedSources.tracked = payload.data.length ? [...payload.data] : [];
                } else {
                    state.selectedSources.excluded = payload.data.length ? [...payload.data] : [];
                }
            }
        },
        addSelectedSource: (state: ITrackedSourcesState, { payload }: PayloadAction<{
            isTracked: boolean, data: ITrackedSourceModel,
        }>): void => {
            if (payload?.data) {
                if (payload?.isTracked) {
                    state.selectedSources.tracked = [...state.selectedSources.tracked, payload.data];
                } else {
                    state.selectedSources.excluded = [...state.selectedSources.excluded, payload.data];
                }
            }
        },
        removeSelectedSource: (state: ITrackedSourcesState, { payload }: PayloadAction<{
            isTracked: boolean, id: number,
        }>): void => {
            if (payload) {
                if (payload.isTracked) {
                    state.selectedSources.tracked = [
                        ...state.selectedSources.tracked.filter(({ id }) => id !== payload.id),
                    ];
                } else {
                    state.selectedSources.excluded = [
                        ...state.selectedSources.excluded.filter(({ id }) => id !== payload.id),
                    ];
                }
            }
        },
        clearSelected: (state: ITrackedSourcesState): void => {
            state.selectedSources = {
                tracked: [],
                excluded: [],
            };
        },
        clearData: (state: ITrackedSourcesState): void => {
            state.data = [];
        },
    },
});

export { campaignTrackedSourcesActions };
export default reducer;

// allow_in_russia
// author_id
// auto_discover
// created
// enabled
// id
// link
// location_id
// out_source_id
// parse_link
// parsed
// tag
// title
// type_id
