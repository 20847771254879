import React, { useState } from 'react';
import { IAddUserModelProps } from '@containers/campaign/UsersSelect/AddUserModal/types';
import classnames from 'classnames';
import SvgIcon from '@components/SvgIcon';
import { Button } from '@shared/index';
import styles from './styles.module.css';

const validateEmail = (email: string): boolean => (/\S+@\S+\.\S+/).test(email);

const AddUserModal: React.FC<IAddUserModelProps> = ({
    isOpen, onClose, email, setEmail, onSend,
}): JSX.Element => {
    const [emailValid, setValidEmail] = useState<boolean>(true);
    const handlerChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value);
        if (event.target.value) {
            setValidEmail(validateEmail(event.target.value));
        } else {
            setValidEmail(true);
        }
    };
    const handlerSendEmail = (): void => {
        if (email && emailValid) {
            onSend();
        }
    };
    if (!isOpen) {
        return null;
    }
    return (
        <div className={styles.overlay}>
            <div className={styles.modalWindow}>
                <div className={styles.headerContainer}>
                    <span className={styles.title}>
                        Добавление нового пользователя
                    </span>
                    <button className={styles.closeButton} type="button" onClick={onClose}>
                        <SvgIcon id="BigCross" size={14} />
                    </button>
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.contentText}>
                        Введите в поле e-mail пользователя, которого хотите добавить.
                        <br />
                        Если пользователь не будет найден в системе, ему на почту
                        придет письмо с инструкцией.
                    </div>
                    <div className={styles.emailContainer}>
                        <input
                            className={classnames(styles.inputField, styles[!emailValid && 'invalid'])}
                            placeholder="e-mail"
                            value={email}
                            onChange={handlerChangeEmail}
                        />
                        <Button text="Добавить пользователя" type="default" size="large" onClick={handlerSendEmail} disabled={!email || !emailValid} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUserModal;
