/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
import { ICategory } from '../../../../sections/MyCampaignContainer/CampaingTypes';
import { barChartBaseOptions } from '../../chartOptions';
import PptxSlide from '../../PptxSlide';
import { ICampaignPPTXAdditionalDataPageProps } from './types';
import { renderTagsOnCanvas } from './renderTagsOnCanvas';

class CampaignPPTXAdditionalDataPage extends PptxSlide {
    private options = {
        startY: 90,
    };

    render(props: ICampaignPPTXAdditionalDataPageProps) {
        this.renderHeader({
            date: props.date,
            title: props.title,
            layoutHeight: props.layoutHeight,
            layoutWidth: props.layoutWidth,
            reportName: props.reportName,
            header: {
                pageName: props.header.pageName,
                number: props.header.number,
            },
        });

        if (props.additionalData && props.additionalData.tonality) {
            this.renderTonality(props.additionalData.tonality);
        }

        if (props.sourcesTypes) {
            this.renderAuthorsType(props.sourcesTypes);
        }

        if (props.additionalData && props.additionalData.platfroms) {
            this.renderPlatforms(props.additionalData.platfroms);
        }

        this.renderTags(props.tags, 443, 319);

        return this.slide;
    }

    private renderTags(tags: any, x: number, y: number): void {
        const blockWidth = 400;
        this.addTitle('Теги', x, y, blockWidth);
        const dataUrl = renderTagsOnCanvas(tags.map((item) => item.filter_name));
        this.slide.addImage({
            data: dataUrl,
            x: this.getInches(x),
            y: this.getInches(y + 40),
            w: this.getInches(360),
            h: this.getInches(230),
        });
    }

    private renderAuthorsType(data: any): void {
        this.addTitle('Типы авторов', 443, this.options.startY);

        const bigRectDiameter = 160;
        const smallRectDiameter = 110;

        const blogger = data.filters.find(
            (item) => item.filter_name === 'блоггер',
        );
        const massMedia = data.filters.find(
            (item) => item.filter_name === 'СМИ',
        );

        if (!blogger || !massMedia) return;

        const bloggerTitle = `Блогер ${blogger.count}`;
        const massMediaTitle = `СМИ ${massMedia.count}`;

        this.addText(
            blogger.count > massMedia.count ? bloggerTitle : massMediaTitle,
            {
                x: 500,
                y: this.options.startY + 50,
                fontSize: 20,
                color: 'ffffff',
                fontFace: 'Montserrat SemiBold',
                shape: 'roundRect',
                rectRadius: this.getInches(bigRectDiameter / 2),
                fill: { color: '9545D8' },
                h: this.getInches(bigRectDiameter),
                align: 'center',
                w: this.getInches(bigRectDiameter),
            },
        );
        this.addText(
            blogger.count > massMedia.count ? massMediaTitle : bloggerTitle,
            {
                x: 500 + bigRectDiameter - 30,
                y: this.options.startY + 80,
                fontSize: 15,
                color: 'ffffff',
                fontFace: 'Montserrat SemiBold',
                shape: 'roundRect',
                rectRadius: this.getInches(smallRectDiameter / 2),
                fill: { color: '201D8F' },
                h: this.getInches(smallRectDiameter),
                align: 'center',
                w: this.getInches(smallRectDiameter),
            },
        );
    }

    private renderPlatforms(data: ICategory): void {
        this.addTitle('Платформы', 0, 319);

        const formatLabel = (value: string): string => {
            let result = '';
            switch (value) {
                case 'tg':
                    result = 'Tg';
                    break;
                case 'vk':
                    result = 'Vk';
                    break;
                case 'rss':
                    result = 'RSS';
                    break;
                case 'ok':
                    result = 'Ok';
                    break;
                default:
                    result = 'None';
                    break;
            }
            return result;
        };

        const chartData = [
            {
                name: 'Платформы',
                labels: data.filters.map((item) => formatLabel(item.filter_name)),
                values: data.filters.map((item) => item.count),
            },
        ];

        this.slide.addChart(
            'bar',
            chartData,
            {
                x: this.getInches(20),
                y: this.getInches(365),
                w: this.getInches(400),
                h: this.getInches(230),
                catAxisLabelColor: '000000',
                catAxisLabelFontBold: true,
                catAxisLabelFontFace: 'Montserrat',
                catAxisLabelFontSize: 12,
                catAxisLabelPos: 'high',
                valAxisLineColor: '7F7F7F',
                dataLabelColor: '000000',
                dataLabelFontFace: 'Montserrat',
                dataLabelFontBold: true,
                dataLabelFontSize: 12,
                catAxisLineShow: false,
                valAxisLineShow: false,
                serAxisLineShow: false,
                valAxisHidden: true,
                barDir: 'col', // `col`(vert) | `bar`(horiz)
                showValue: true,
                dataLabelPosition: 'outEnd',
                valAxisLabelFormatCode: '0.#0',
                chartColors: ['81D42F', 'F94144', 'F9C74F', 'F3722C', 'F8961E', '2D9CDB'],
                barGapWidthPct: 50,
                showLegend: false,
                serGridLine: {
                    style: 'none',
                },
                valGridLine: {
                    style: 'none',
                },
                catGridLine: {
                    style: 'none',
                },
            },
        );
    }

    private renderTonality(data: ICategory): void {
        this.addTitle('Тональность', 0, this.options.startY);

        const labels = {
            neutral: 'Нейтрально',
            negative: 'Негативно',
            positive: 'Позитивно',
            skip: 'Пропущено',
        };

        const chartData = [
            {
                name: 'Тональность',
                labels: data.filters.map((item) => labels[item.filter_name]),
                values: data.filters.map((item) => item.count),
            },
        ];

        // @ts-ignore
        this.slide.addChart('bar', chartData, {
            ...barChartBaseOptions,
            x: this.getInches(20),
            y: this.getInches(140),
            w: this.getInches(370),
            h: this.getInches(160),
            chartColors: ['F36869', 'E67E22', '787E93', '81D42F'],
        });
    }
}

export default CampaignPPTXAdditionalDataPage;
