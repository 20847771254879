import Table from '@entities/Table';
import { ITableColumn } from '../../../entities/Table/types';

interface ITestRow {
    id: number
    column_1: string;
    column_2: string;
    column_3: string;
    column_4?: string;
    column_6?: string;
}

const rows: ITestRow[] = [
    {
        id: 1, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_2', column_6: 'column_6',
    },
    {
        id: 2, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 3, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 4, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 5, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 6, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 7, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 8, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 9, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 10, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
    {
        id: 11, column_1: 'column_1', column_2: 'column_2', column_3: 'column_3', column_4: 'column_4', column_6: 'column_6',
    },
];

const TableDemo: React.FC = (): React.ReactElement => {
    const columns: ITableColumn<ITestRow>[] = [
        {
            id: 1435, title: 'ID', dataIndex: 'id', sortable: true,
        },
        {
            id: 1348752, title: 'Column #1', dataIndex: 'column_1',
        },
        { id: 65867533, title: 'Column #2', dataIndex: 'column_2' },
        { id: 44532, title: 'Column #3', dataIndex: 'column_3' },
        {
            id: 15,
            title: 'hye',
            subColumns: [
                {
                    id: 64521,
                    title: 'SUBCOLUMN_1',
                    dataIndex: 'column_4',
                },
                { id: 5347, title: 'SUBCOLUMN_2', dataIndex: 'column_6' },
            ],
        },
    ];
    return (
        <div>
            <Table
                columns={columns}
                rows={rows}
                isLoading={false}
            />
        </div>
    );
};
export default TableDemo;
