interface IChartColor {
    color: string,
    lightColor: string,
    alphaColor: string,
}
export const LIST_COLORS: IChartColor[] = [
    {
        color: 'rgba(14, 191, 161, 1)',
        lightColor: 'rgba(14, 191, 161, 0.65)',
        alphaColor: 'rgba(14, 191, 161, 0.15)',
    },
    {
        color: 'rgba(10, 99, 88, 1)',
        lightColor: '#20BAA3',
        alphaColor: 'rgba(32, 186, 163, 0.15)',
    },
    {
        color: 'rgba(118, 19, 199, 1)',
        lightColor: '#9545D8',
        alphaColor: 'rgba(149, 69, 216, 0.15)',
    },
    {
        color: 'rgba(148, 44, 6, 1)',
        lightColor: '#E05B2B',
        alphaColor: 'rgba(224, 91, 43, 0.15)',
    },
    {
        color: 'rgba(8, 77, 117, 1)',
        lightColor: '#2E6FD9',
        alphaColor: 'rgba(46, 111, 217, 0.15)',
    },
    {
        color: 'rgba(37, 55, 64, 1)',
        lightColor: '#598296',
        alphaColor: 'rgba(89, 130, 150, 0.15)',
    },
    {
        color: 'rgba(32, 29, 143, 1)',
        lightColor: '#4C4CE0',
        alphaColor: 'rgba(76, 76, 224, 0.15)',
    },
    {
        color: 'rgba(61, 115, 7, 1)',
        lightColor: '#81D42F',
        alphaColor: 'rgba(129, 212, 47, 0.15)',
    },
    {
        color: 'rgba(145, 101, 4, 1)',
        lightColor: '#E3A322',
        alphaColor: 'rgba(227, 163, 34, 0.15)',
    },
    {
        color: 'rgba(141, 6, 51, 1)',
        lightColor: '#D92762',
        alphaColor: 'rgba(217, 39, 98, 0.15)',
    },
];
