import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILeftMenuState {
    isExpanded: boolean;
}

const initialState: ILeftMenuState = {
    isExpanded: false,
};

const { actions: leftMenuActions, reducer } = createSlice({
    name: 'leftMenu',
    initialState,
    reducers: {
        setExpanded: (state: ILeftMenuState, { payload }: PayloadAction<boolean>) => {
            state.isExpanded = payload;
        },
    },
});

export { leftMenuActions };
export default reducer;
