/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import {
    Chart as ChartJS,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Bubble, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import EventBusForChartGraph from '../../deprecated/pagesComponents/info-trace/PublicationChartGraph/EventBus';
import TooltipForTracing from '../TooltipForTracing';
import QuickSVGLoader from '../QuickSVGLoader';
import styles from './styles.module.scss';
import { IPropsChartView } from './Types';
import LoaderForChart from './LoaderForChart';

ChartJS.register(
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChartView: React.FC<any> = ({
    animation = true,
    isActiveTooltip = false,
    beginAtZero = false,
    listDatasets,
    width,
    height,
    isFullScreen,
    setIsFullScreen,
    getTooltip,
    getImage,
    onLoadPrevData,
    onLoadNextData,
}) => {
    if (!listDatasets?.length) return <div>Что-то пошло не так</div>;
    const [maxX, setMX] = useState(0);
    const [minX, setMinX] = useState(0);
    const getMaxValueKey = (list, key):any => {
        if (!key) return null;
        let max = {};
        max[key] = 0;
        list.forEach((item) => {
            if (item[key] > max[key]) max = item;
        });
        return max;
    };
    const getMinValueKey = (list, key):any => {
        if (!key) return null;
        let min = {};
        min[key] = Infinity;
        list.forEach((item) => {
            if (item[key] < min[key]) min = item;
        });
        return min;
    };
    useEffect(() => {
        if (!listDatasets) return;
        const allPoints = listDatasets.map((dataset) => dataset.data);
        const listMax = allPoints.map((points) => getMaxValueKey(points, 'x'));
        const max = getMaxValueKey(listMax, 'x');
        const listMin = allPoints.map((points) => getMinValueKey(points, 'x'));
        const min = getMinValueKey(listMin, 'x');
        setMX(max?.x);
        setMinX(min?.x);
    }, [listDatasets]);
    const chartRef = useRef(null);
    const timerTooltip = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [positionAndData, setPositionAndData] = useState({ top: 0, left: 0, data: null });
    const dataSet = {
        events: ['click'],
        datasets: listDatasets,
        plugin: [zoomPlugin],
    };
    const compare = (a, b) => {
        if (a.x < b.x) {
            return -1;
        }
        if (a.x > b.x) {
            return 1;
        }
        return 0;
    };
    const objectHandler:any = {
        // eslint-disable-next-line func-names, object-shorthand, react/no-this-in-sfc
        update: function (n, v) { this[n] = v; },
    };
    const handlerGetNextData = (maxDatasets, lastTick?: moment.Moment) => {
        if (objectHandler?.setter) objectHandler?.setter(true);
        const periods = maxDatasets.map((dataset) => {
            const { data } = dataset;
            // const lastPoint = data?.reverse()?.at(-1);
            const maxDate = data.reduce((max, current) => {
                if (current.x > max.x) return current;
                return max;
            }, data?.reverse()?.at(-1));
            const pointMoment = moment(maxDate.x);
            const periodEndFromFirstTick = lastTick?.clone().add(45, 'd');
            const periodInit = pointMoment.clone().add(1, 'day');
            const periodEnd = periodInit.clone().add(31, 'day');
            return {
                init: periodInit,
                end: periodEndFromFirstTick || periodEnd,
                filter: dataset.filter,
                id: dataset.id,
            };
        });
        periods.forEach((period) => {
            onLoadNextData(
                {
                    init: period?.init.format('YYYY-MM-DD'),
                    end: period?.end.format('YYYY-MM-DD'),
                },
                period.filter,
            ).then((res) => {
                const newPoints = res?.data.map((el) => ({
                    x: new Date(el.dt).getTime(),
                    y: el.count,
                    label: { content: `${el.count}` },
                }));
                dataSet.datasets.forEach((dataset) => {
                    if (dataset.id === period.id) {
                        // eslint-disable-next-line no-param-reassign
                        dataset.data.push(...newPoints.reverse());
                        // eslint-disable-next-line no-param-reassign
                        dataset.data = dataset.data.sort(compare);
                        chartRef.current.update('none');
                        chartRef.current.zoom(1.001);
                    }
                });
                // currentDataset.data = [...currentDataset.data, ...newPoints.reverse()];
                if (objectHandler?.setter) objectHandler?.setter(false);
            });
        });
    };
    const handlerGetPrevData = (minDatasets, firstTick?: moment.Moment) => {
        if (objectHandler?.setter) objectHandler?.setter(true);
        const periods = minDatasets.map((dataset) => {
            const { data } = dataset;
            // const firstPoint = data.at(-1);
            const minDate = data.reduce((min, current) => {
                if (current.x < min.x) return current;
                return min;
            }, data.at(-1));
            const pointMoment = moment(minDate.x);
            const periodInitFromFirstTick = firstTick?.clone().subtract(45, 'd');
            const periodInit = pointMoment.clone().subtract(31, 'day');
            const periodEnd = pointMoment.clone();
            return {
                init: periodInitFromFirstTick || periodInit,
                end: periodEnd,
                filter: dataset.filter,
                id: dataset.id,
            };
        });
        periods.forEach((period) => {
            onLoadPrevData(
                {
                    init: period?.init.format('YYYY-MM-DD'),
                    end: period?.end.format('YYYY-MM-DD'),
                },
                period.filter,
            ).then((res) => {
                const newPoints = res?.data.map((el) => ({
                    x: new Date(el.dt).getTime(),
                    y: el.count,
                    label: { content: `${el.count} ${el.dt}` },
                }));
                dataSet.datasets.forEach((dataset) => {
                    if (dataset.id === period.id) {
                        dataset.data.unshift(...newPoints.reverse());
                        // eslint-disable-next-line no-param-reassign
                        dataset.data = dataset.data.sort(compare);
                        chartRef.current.update();
                    }
                    chartRef.current.update();
                    if (objectHandler?.setter) objectHandler?.setter(false);
                });
            });
        });
    };
    const onZoomComplete = (e) => {
        const lastTick = e.chart?.scales?.x?.ticks.at(-1);
        const firstTick = e.chart?.scales?.x?.ticks[0];
        const momentLastTick = moment(lastTick.value);
        const momentFirstTick = moment(firstTick.value);
        const maxDatasets = listDatasets.filter((dataset) => {
            const maxDate = dataset?.data?.reduce((max, current) => {
                if (current.x > max.x) return current;
                return max;
            }, dataset?.data?.at(0)?.x);
            const result = moment(maxDate?.x).isBefore(momentLastTick);
            return result;
        });
        const minDatasets = listDatasets.filter((dataset) => {
            const minDate = dataset?.data?.reduce((min, current) => {
                if (current.x < min.x) return current;
                return min;
            }, dataset?.data?.at(-1));
            const result = moment(minDate?.x).isAfter(momentFirstTick);
            return result;
        });
        if (maxDatasets.length && onLoadNextData) handlerGetNextData(maxDatasets, momentLastTick);
        if (minDatasets.length && onLoadPrevData) handlerGetPrevData(minDatasets, momentFirstTick);
    };
    const clearTimerTooltip = () => {
        if (!timerTooltip.current) return;
        clearTimeout(timerTooltip.current);
        timerTooltip.current = null;
    };
    const hideTooltip = () => {
        if (timerTooltip.current) return;
        timerTooltip.current = setTimeout(() => {
            EventBusForChartGraph.emit('setTooltip', [null, null, null, null]);
        }, 300);
    };

    const customTooltipData = (tooltipModel) => {
        const { tooltip } = tooltipModel;

        if (tooltip.opacity === 0) {
            clearTimerTooltip();
            hideTooltip();
            return;
        }
        const left = tooltip.caretX;
        const top = tooltip.caretY;
        const data = tooltip.dataPoints[0].raw;
        clearTimerTooltip();
        EventBusForChartGraph.emit('setTooltip', [left, top, true, data]);
    };
    const tooltipHtml = {
        enabled: false,
        external: customTooltipData,
    };
    const panOptions = {
        enabled: true,
        mode: 'xy',
        threshold: 5,
        scaleMode: 'xy',
        modifierKey: 'alt',
        // onPanComplete,
    };
    const zoomOptions = {
        enabled: false,
        mode: 'xy',
        wheel: {
            speed: 0.05,
            enabled: true,
        },
        drag: {
            enabled: true,
        },
        pinch: {
            enabled: true,
        },
        onZoomComplete,
    };
    const plugins = {
        zoom: {
            limits: {
                y: {
                    min: 2667600000,
                    max: 2753999999 + 600000,
                },
                x: {
                    max: maxX,
                    min: minX - 600000 * 24,
                },
            },
            pan: panOptions,
            zoom: zoomOptions,
        },
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: tooltipHtml,
    };
    const scales = {
        x: {
            ticks: {
                padding: 10,
            },
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                    hour: 'HH:mm',
                    minute: 'HH:mm',
                    second: 'HH:mm:ss',
                    day: 'DD.MM',
                },
            },
        },
        y: {
            limits: {
                max: 0,
            },
            ticks: {
                padding: 10,
            },
            type: 'time',
            time: {
                minUnit: 'second',
                // unit: 'minute',
                displayFormats: {
                    hour: 'HH:mm',
                    minute: 'HH:mm',
                    second: 'HH:mm:ss',
                },
            },
        },
    };
    const options: any = {
        animation: true,
        responsive: true,
        maintainAspectRatio: false,
        beginAtZero,
        stacked: false,
        elements: {
            point: {
                borderColor: 'white',
            },
        },
        scales,
        plugins,
    };
    useEffect(() => {
        if (getImage && chartRef?.current) {
            setTimeout(() => getImage(chartRef?.current?.toBase64Image('image/png')), 600);
        }
    }, [listDatasets]);

    const getOptions = options;
    const handleKeyDown = (e: KeyboardEvent) => {
        if (!isFullScreen) return;
        if (e.key === 'Escape') {
            setIsFullScreen(false);
        }
    };
    useEffect(() => {
        if (isFullScreen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullScreen]);
    return (
        <div className={`${isFullScreen ? styles.fullScreen : styles.chartContainer}`}>
            <LoaderForChart data={objectHandler} />

            <Line plugins={[zoomPlugin]} ref={chartRef} options={getOptions} data={dataSet as any} />
            <TooltipForTracing
                isOpen={false}
                x={null}
                y={null}
                clearTimerTooltip={clearTimerTooltip}
                hideTooltip={hideTooltip}
            >
                {getTooltip ? getTooltip(positionAndData?.data?.content) : ''}
            </TooltipForTracing>
        </div>
    );
};

export default React.memo(ChartView);

// 2753999999
// 2753999999
