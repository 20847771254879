import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Path from '../../../../../shared/lib/paths';
import { campaignStatisticsRtkQService } from '../../../../../services/campaigns/statistics';
import campaignComparisonSelectors from '../../../../../store/campaign/comparison/selectors';
import { useAppDispatch } from '../../../../../store/hooks';
import { campaignComparisonActions, ISimplePeriod } from '../../../../../store/campaign/comparison';
import ComparisonPeriodModal from '../../../../../components/campaign/Comparison/Modals/ComparisonPeriod';

const postfixISOTime = 'T00:00:00.000Z';

interface IProps {
    currentModalWindow: string;
    setCurrentModalWindow: (name: string) => void;
}

const ComparisonPeriodModalContainer: React.FC<IProps> = ({
    currentModalWindow,
    setCurrentModalWindow,
}): JSX.Element => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const isSelectPeriod = useSelector(campaignComparisonSelectors.isSelectPeriod);
    const referenceCampaign = useSelector(campaignComparisonSelectors.referenceCampaign);
    const compareCampaigns = useSelector(campaignComparisonSelectors.compareCampaigns);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [loadCampaignSummary] = campaignStatisticsRtkQService.useLazyPostLoadCampaignAggQuery();
    const [loadCampaignFilters] = campaignStatisticsRtkQService.useLazyPostLoadCampaignFiltersQuery();

    const handlerCloseModal = (): void => {
        setIsOpen(false);
        dispatch(campaignComparisonActions.setSelectPeriodState(false));
    };

    const handlerStartComparison = (period: ISimplePeriod): void => {
        dispatch(campaignComparisonActions.setComparisonPeriod(period));
        dispatch(campaignComparisonActions.setCompareDisable());
        // dispatch(campaignComparisonActions.setComparisonCanView(true));
        setIsOpen(false);
        const campaignsIds = [referenceCampaign?.id, ...compareCampaigns.map(({ id }) => id)];
        const fromDate = `${period.start.toISOString().split('T')[0]}`;
        const toDate = `${period.end.toISOString().split('T')[0]}`;
        dispatch(campaignComparisonActions.setSelectPeriodState(false));

        // dispatch(loadCampaignComparisonFiltersThunk(
        //     [referenceCampaign?.id, ...compareCampaigns.map(({ id }) => id)],
        //     [{
        //         from_date: period.start.toISOString().split('T')[0],
        //         to_date: period.end.toISOString().split('T')[0],
        //     }],
        // ));
        dispatch(campaignComparisonActions.setFiltersLoadingStatus());
        dispatch(campaignComparisonActions.cleanFiltersData());
        dispatch(campaignComparisonActions.setCampaignsIdsForFetchFilters(campaignsIds));
        campaignsIds.forEach((id) => loadCampaignFilters({ id, toDate, fromDate }));

        // dispatch(loadCampaignSummaryDataThunk(
        //     [referenceCampaign?.id, ...compareCampaigns.map(({ id }) => id)],
        //     {
        //         from_date: `${period.start.toISOString().split('T')[0]}${postfixISOTime}`,
        //         to_date: `${period.end.toISOString().split('T')[0]}${postfixISOTime}`,
        //     },
        // ));
        dispatch(campaignComparisonActions.setSummaryLoadingStatus());
        dispatch(campaignComparisonActions.cleanSummaryData());
        dispatch(campaignComparisonActions.setCampaignsIdsForFetchAgg(campaignsIds));
        campaignsIds.forEach((id) => loadCampaignSummary(
            { id, toDate: `${toDate}${postfixISOTime}`, fromDate: `${fromDate}${postfixISOTime}` },
        ));

        navigate(`/${Path.Campaign}/${Path.CampaignComparison}`);
    };
    const handlerRemoveCampaign = useCallback((id: number) => {
        dispatch(campaignComparisonActions.removeCampaignForCompare(id));
    }, [dispatch]);
    const handlerOpenModal = (name: string): void => {
        setCurrentModalWindow(name);
        setIsOpen(false);
    };

    useEffect(() => {
        if (isSelectPeriod && !isOpen) {
            setIsOpen(true);
        }
    }, [isSelectPeriod]);
    useEffect(() => {
        if (isSelectPeriod && currentModalWindow === '' && !isOpen) {
            setIsOpen(true);
        }
    }, [currentModalWindow]);

    return (
        <ComparisonPeriodModal
            isOpen={isOpen}
            referenceCampaign={referenceCampaign}
            compareCampaigns={compareCampaigns}
            onCloseModal={handlerCloseModal}
            onStartComparison={handlerStartComparison}
            onOpenListSelectCompany={handlerOpenModal}
            onRemoveCampaign={handlerRemoveCampaign}
        />
    );
};

export default ComparisonPeriodModalContainer;
