/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React from 'react';
import SvgIcon from '../SvgIcon';
// import { Switch } from 'antd';
import styles from './styles.module.scss';

interface IPropsInitailChart {
    onInitialChart: () => void;
}
const InitailChart: React.FC<IPropsInitailChart> = ({
    onInitialChart,
}): JSX.Element => (
    <div className={styles.rootInitalChart} onClick={onInitialChart}>
        <span>Добавить график</span>
        <div>
            <SvgIcon size={31} id="RoundAdd_Icn" />
        </div>
    </div>
);

export default InitailChart;
