import Skeleton from 'react-loading-skeleton';
import TagCrossIcon from '../../../assets/images/tagCross.svg';
import { IFavoriteWords } from '../../../services/campaigns/favorite-words/types';
import styles from './styles.module.scss';

interface IProps {
    words: IFavoriteWords[];
    isLoading: boolean;
    deleteWord: (id: number) => void;
    isError: boolean;
}

const UserProfileFavoriteWords: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        words, isLoading, deleteWord, isError,
    } = props;
    return (
        <div className={styles.container}>
            {isLoading && <Skeleton height={40} />}
            {!isLoading && !isError
                && words.map((word) => (
                    <div className={styles.item} key={word.id}>
                        <p>{word.word}</p>
                        <button
                            type="button"
                            onClick={() => deleteWord(word.id)}
                        >
                            <img src={TagCrossIcon} alt="TagCrossIcon" />
                        </button>
                    </div>
                ))}
            {!isLoading && words?.length < 1 && (
                <p className={styles.emptyText}>Нет избранных слов</p>
            )}
            {!isLoading && isError && (
                <p className={styles.emptyText}>
                    Произошла ошибка при получении данных
                </p>
            )}
        </div>
    );
};
export default UserProfileFavoriteWords;
