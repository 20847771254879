import React, { useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import LinearLoader from '../Loader';
import SvgIcon from '../../SvgIcon';
import { ICommonSelectDropdownListProps } from './types';
import CommonDropdownListMenu from './Menu';
import { ISimpleEntity } from '../../../store/types';

import styles from './styles.module.css';

const CommonSelectDropdownList: React.FC<ICommonSelectDropdownListProps> = ({
    label, data, tagId, onSelectItem, isLoading, onAddUser,
}): JSX.Element => {
    const [menuIsVisible, setVisibleMenu] = useState<boolean>();
    const firstItem = useMemo((): ISimpleEntity => (data?.find(({ id }) => Number(id) !== -1) ?? null), [data]);
    const handlerMouseLeave = (): void => {
        if (menuIsVisible) {
            setVisibleMenu(false);
        }
    };
    const handlerVisible = (visible: boolean): void => {
        setVisibleMenu(visible);
    };
    return (
        <div onMouseLeave={handlerMouseLeave} className={styles.root}>
            <Dropdown
                overlay={(
                    <CommonDropdownListMenu data={data} onSelectItem={onSelectItem} onAddUser={onAddUser} />
                )}
                trigger={['click']}
                visible={menuIsVisible}
                onVisibleChange={handlerVisible}
            >
                {
                    isLoading
                        ? <LinearLoader />
                        : (
                            <span id={tagId} className={styles.label}>
                                {label || (firstItem?.name ?? '')}
                                <SvgIcon id="dropdownArrow" size={17} />
                            </span>
                        )
                }
            </Dropdown>
        </div>
    );
};

export default CommonSelectDropdownList;
