/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import { IChart } from '@containers/CampaignNewCharts/ChartTypes';
import React, { useMemo } from 'react';
import { ChartType, ChartMetricsModel } from '@services/campaigns/Charts/models';
import { Select } from '@shared/index';
import { ISelectItem } from '@shared/ui-components/Select/types';
import styles from './styles.module.scss';

interface IPropsChartMetrics {
    onUpdateChartType: (newChartType: ChartType) => void,
    listSettingForChart: ChartMetricsModel,
    isLoading: boolean,
    chartSetting: IChart,
}

const ChartTypes: React.FC<IPropsChartMetrics> = ({
    onUpdateChartType,
    listSettingForChart,
    isLoading,
    chartSetting,
}): JSX.Element => {
    if (isLoading) return <div> Loading</div>;
    if (!listSettingForChart || !listSettingForChart?.length) return <div>Данных нет</div>;

    const chartTypes = useMemo((): ChartType[] => {
        const listRawMetrics = listSettingForChart.find((item) => item?.chart_type);
        return listRawMetrics.chart_type;
    }, [listSettingForChart]);

    const simpleEntityChartTypes = useMemo((): ISelectItem[] => chartTypes.map((name, index) => ({
        id: index + 1,
        label: name,
        value: name,
    })).filter((item) => item.label !== 'LINE_CHART'), [chartTypes]);

    const selectedChartType = useMemo(
        (): ISelectItem => simpleEntityChartTypes?.find((simpleEntity: ISelectItem) => simpleEntity.value === chartSetting?.chart_type),
        [simpleEntityChartTypes, chartSetting],
    );

    const handleSelectChartType = (id: number): void => {
        const newChartType = simpleEntityChartTypes.find((simpleEntity: ISelectItem) => simpleEntity.id === id);
        onUpdateChartType(newChartType.value as ChartType);
    };

    return (
        <div className={styles.chartType}>
            <div className={styles.dropdownTitile}>
                Тип графика
            </div>
            <div className={styles.dropdownChartSettings}>
                <Select
                    onSelect={handleSelectChartType}
                    items={simpleEntityChartTypes}
                    defaultItem={selectedChartType?.id}
                />
            </div>
            {/* <div className={styles.dropdownChartSettings}>
                <SingleSelectItem
                    onSelect={handleSelectChartType}
                    selectedId={selectedChartType?.id || 0}
                    defaultTitle={<span>Выбрать</span>}
                    list={simpleEntityChartTypes}
                    minWidth={180}
                    isLoading={false}
                />
            </div> */}
        </div>
    );
};

export default React.memo(ChartTypes);
