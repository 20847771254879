import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { ICampaignCategoryDropdownListProps } from './types';
import CampaignDropdownListMenu from './Menu/index';

import styles from './styles.module.scss';
import SvgIcon from '../../../../SvgIcon';

const CampaignCategoryDropdownList: React.FC<ICampaignCategoryDropdownListProps> = ({
    categories, label, onSelectCategory,
}): JSX.Element => {
    const [isVisible, setVisibleMenu] = useState<boolean>();
    return (
        <div>
            <Dropdown
                overlay={(
                    <CampaignDropdownListMenu
                        categories={categories}
                        onSelectCategory={onSelectCategory}
                    />
                )}
                trigger={['click']}
                onVisibleChange={setVisibleMenu}
            >
                <span id="campaign-category" className={styles.label}>
                    {label}
                    <span style={
                        isVisible
                            ? { transform: 'rotate(180deg)', transition: 'transform 0.2s' }
                            : { transform: 'rotate(0deg)', transition: 'transform 0.2s' }
                    }
                    >
                        <SvgIcon id="dropdownArrow" size={17} height={11} />
                    </span>
                </span>
            </Dropdown>
        </div>
    );
};
export default CampaignCategoryDropdownList;
