import { IDashboardFromApi, IQPDashboard } from 'services/dashboard/types';
import { rtkQApi } from '..';

export const dashboardRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getDashboards: build.query<IDashboardFromApi[], void>({
            query: () => ({
                url: 'dashboard/',
                method: 'GET',
            }),
            providesTags: ['CampaignsDashboard'],
        }),
        postCreateDashboard: build.mutation<void, IQPDashboard>({
            query: (body) => ({
                url: 'dashboard/',
                method: 'POST',
                body: {
                    params: body,
                },
            }),
            invalidatesTags: ['CampaignsDashboard'],
        }),
        putChangeDashboard: build.mutation<void, { id: number, params: IQPDashboard }>({
            query: (body) => ({
                url: `dashboard/${body.id}`,
                method: 'PUT',
                body: {
                    params: body?.params,
                },
            }),
            invalidatesTags: ['CampaignsDashboard'],
        }),
        deleteDashboard: build.mutation<void, { id: number }>({
            query: (params) => ({
                url: `dashboard/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CampaignsDashboard'],
        }),
    }),
});
