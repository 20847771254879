/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TextField from '@entities/TextField';
import { ICampaignNameInputProps } from './types';
import ValidationTooltip from '../../Validation';

import styles from './styles.module.scss';

const CampaignNameInput: React.FC<ICampaignNameInputProps> = ({
    value, setValue,
    placeholder, tagId,
    isErrorHighlighting, isView,
    isLoading, isIPadSize, withoutLabel,
    parentValidationIsRelative = true,
}): JSX.Element => {
    const wrapperRef = useRef(null);
    const [focused, setFocus] = useState<boolean>(false);
    // const title = useMemo((): string => (isView ? 'Наименование' : 'Наименование*'), [isView]);
    const handlerChangeValue = (newValue: string): void => {
        if (newValue !== undefined && newValue !== null) {
            setValue(newValue);
        }
    };

    return (
        <>
            {
                isLoading && <Skeleton width={350} height={25} />
            }
            {
                !isLoading && (
                    <div
                        className={styles.campaignNameInput_root}
                        style={isIPadSize ? { minWidth: 350, width: 500 } : null}
                        ref={wrapperRef}
                    >
                        <ValidationTooltip
                            isVisible={isErrorHighlighting}
                            left={154}
                            top={55}
                            width={210}
                            text="Пожалуйста, заполните поле"
                            parentIsRelative={parentValidationIsRelative}
                            parentHTMLElement={wrapperRef?.current as HTMLElement}
                        >
                            {withoutLabel
                                ? <div /> : (
                                    <span className={styles.label}>
                                        {`Наименование${isView ? '' : '*'}:`}
                                    </span>
                                ) }
                        </ValidationTooltip>
                        {/* { */}
                        {/*    isErrorHighlighting && (<div className={styles.tooltip}>Заполните</div>) */}
                        {/* } */}
                        <div
                            className={classnames(
                                styles[isErrorHighlighting ? 'red' : ''],
                            )}
                        >
                            <TextField
                                style={isIPadSize ? { minWidth: 150, width: 250 } : null}
                                onFocus={() => setFocus(true)}
                                onBlur={() => setFocus(false)}
                                onChange={handlerChangeValue}
                                readonly={isView}
                                value={value}
                                placeholder={placeholder}
                                size="medium"
                            />
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default CampaignNameInput;
