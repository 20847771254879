import React, { useState } from 'react';
import SvgIcon from '@components/SvgIcon';
import { ICustomTableRow } from '@entities/TableProjectResults/types';
import { IconButton, Tooltip } from '@shared/index';
import { TextArea } from '@entities/index';
import styles from './styles.module.scss';

interface IProps {
    text: string | number,
    isText: boolean,
    record: ICustomTableRow,
    setActionRows: (id: number) => void,
    onComplete?: (id: number, text: string) => void
}
const placeholder = 'Введите текст';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditComment: React.FC<IProps> = ({
    text,
    isText,
    record,
    setActionRows,
    onComplete,
}): JSX.Element => {
    const [activeModal, setActiveModal] = useState<boolean>(false);
    const [comment, setComment] = useState<string>(text.toString());
    const activeEditRow = (id: number) => {
        setActiveModal(!activeModal);
        setComment(text.toString());
        setActionRows(id);
    };

    const handleComplete = () => {
        activeEditRow(null);
        if (typeof onComplete !== 'function') return;
        onComplete(record.id, comment);
    };
    return (
        <div
            className={styles.editComment_root}
        >
            <div
                role="button"
                onClick={() => activeEditRow(record.id)}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
                <SvgIcon id="GreyPencil" size={24} />
            </div>
            {' '}
            {!text && (
                <span className={styles.editComment_cellPlaceholder}>{placeholder}</span>
            )}
            {text && (
                <Tooltip
                    // @ts-ignore
                    content={(<div style={{ maxWidth: 650 }}>{text.toLocaleString('ru-Ru')}</div>)}
                    placement="bottomLeft"
                    xOffset={-2}
                    yOffset={0}
                    disabled={text.toLocaleString('ru-RU').length < 40}
                    arrow={false}
                >
                    <span className={styles.editComment_text} style={{ color: isText ? '#787E93' : '#3B3D47' }}>
                        {text.toLocaleString('ru-Ru')}
                    </span>
                </Tooltip>
            )}
            {
                activeModal && (
                    <div className={styles.modal_root}>
                        <div className={styles.modal_form}>
                            <div className={styles.modal_text}>
                                <span className={styles.modal_label}>Текст</span>
                                <TextArea
                                    style={{ maxHeight: 100 }}
                                    value={comment}
                                    onChange={(value) => setComment(value)}
                                    placeholder={placeholder}
                                />
                            </div>
                        </div>
                        <div className={styles.modal_controller}>
                            <div className={styles.modal_approval}>
                                <IconButton iconSize={14} iconId="RedCross" onClick={() => activeEditRow(null)} />
                                <IconButton iconSize={18} iconId="Complete" onClick={() => handleComplete()} />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default EditComment;
