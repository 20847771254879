import React from 'react';
import {
    View, StyleSheet, Font, Text,
} from '@react-pdf/renderer';
import { IPDFReportTopPostsAndViews } from 'store/pdf-handler/wrapper/types';
// @ts-ignore
import montserratRegular from '../../../../../fonts/static/Montserrat-Regular.ttf';
// @ts-ignore
import montserratSemiBold from '../../../../../fonts/static/Montserrat-SemiBold.ttf';
// @ts-ignore
import montserratMedium from '../../../../../fonts/static/Montserrat-Medium.ttf';
import { ICampaignSummaryData } from '../../../../../store/campaign/summary/types';
import CampaignPDFTemplatePage from '../TemplatePage';

Font.register({
    family: 'MontserratRegular400',
    src: montserratRegular,
});
Font.register({
    family: 'MontserratMedium500',
    src: montserratMedium,
});
Font.register({
    family: 'MontserratSemiBold600',
    src: montserratSemiBold,
});

const styles = StyleSheet.create({
    root: {
        width: '100%',
        height: '100%',
        padding: 24,
        display: 'flex',
    },
    topContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 26,
    },
    leftBlock: {
        width: 310,
        height: 84,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginRight: 64,
        backgroundColor: '#DFFCF7',
        padding: 12,
        borderRadius: '6px',
    },
    coverageValue: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '36px',
        textAlign: 'center',
        color: '#262626',
    },
    coverageLabel: {
        fontFamily: 'MontserratRegular400',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center',
        color: '#262626',
    },
    totalContainer: {
        width: '100%',
        height: 400,
        marginTop: -50,
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: 12,
    },
    totalTitle: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        textAlign: 'center',
        color: '#787E93',
    },
    totalValue: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '36px',
        textAlign: 'center',
        color: '#262626',
        marginRight: 16,
    },
    totalLabel: {
        fontFamily: 'MontserratRegular400',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center',
        color: '#262626',
        marginTop: 15,
    },
    doubleTotalLabel: {
        fontFamily: 'MontserratRegular400',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center',
        color: '#262626',
    },
    totalRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 32,
    },
    totalColumn: {
        height: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    totalColumnRightAlign: {
        height: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    totalCell: {
        height: 58,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginBottom: 30,
    },
    footerContent: {
        display: 'flex',
        marginTop: 16,
    },
    margin: {
        width: 80,
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    topHeaderCell: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: 12,
        width: 230,
        height: 100,
        borderBottom: 1,
        borderColor: '#DFFCF7',
    },
    topHeaderCellLeft: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: 12,
        borderBottom: 1,
        borderRight: 1,
        borderColor: '#DFFCF7',
        width: 230,
        height: 100,
    },
    topCellHeader: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        textAlign: 'center',
        color: '#262626',
        marginBottom: 10,
    },
    totCellSubHeader: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        textAlign: 'center',
        color: '#787E93',
        marginBottom: 8,
    },
    statisticRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: '#DFFCF7',
    },
    statisticLabelCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 12,
        width: 160,
        height: 30,
        backgroundColor: '#f5fefd',
    },
    statisticValueBorderCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: 1,
        borderColor: '#DFFCF7',
        width: 70,
        height: 30,
    },
    statisticValueCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 70,
        height: 30,
    },
    statisticLabelText: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        textAlign: 'left',
        color: '#000000',
    },
    statisticValueText: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '9px',
        textAlign: 'center',
        color: '#787E93',
    },
});

interface IPDFSummaryDataPageProps {
    reactElementKey: string;
    title: string;
    reportName: string;
    date: string;
    topAuthorsByPosts: IPDFReportTopPostsAndViews[];
    topAuthorsByViews: IPDFReportTopPostsAndViews[];
    amounts: ICampaignSummaryData;
}

const CampaignPDFSummaryDataPage: React.FC<IPDFSummaryDataPageProps> = ({
    title, reactElementKey, reportName, date,
    topAuthorsByPosts, topAuthorsByViews,
    amounts,
}): JSX.Element => {
    const getTextValue = (value: number): string => {
        if (!value) {
            return '0';
        }
        const result = value > 1000000 ? (Math.round(value / 100000) / 10) : value;
        return value > 1000000 ? `${result.toLocaleString('ru-RU')} млн` : result.toLocaleString('ru-RU');
    };
    const cutAuthorName = (value: string): string => {
        if (!value) {
            return '-';
        }

        return value.length > 17 ? `${value.substring(0, 17)}...` : value;
    };
    return (
        <CampaignPDFTemplatePage reactElementKey={reactElementKey} reportName={reportName} date={date} title={title}>
            <View style={styles.root}>
                <View style={styles.topContainer}>
                    <View style={styles.leftBlock}>
                        <Text style={styles.coverageValue}>
                            {
                                getTextValue(amounts?.quantityViews)
                            }
                        </Text>
                        <Text style={styles.coverageLabel}>
                            охват
                        </Text>
                    </View>
                    <View>
                        <View style={styles.topRow}>
                            <View style={styles.topHeaderCellLeft}>
                                <Text style={styles.topCellHeader}>
                                    ТОП-5
                                </Text>
                                <Text style={styles.totCellSubHeader}>
                                    авторов
                                </Text>
                                <Text style={styles.totCellSubHeader}>
                                    по количеству сообщений
                                </Text>
                            </View>
                            <View style={styles.topHeaderCell}>
                                <Text style={styles.topCellHeader}>
                                    ТОП-5
                                </Text>
                                <Text style={styles.totCellSubHeader}>
                                    авторов
                                </Text>
                                <Text style={styles.totCellSubHeader}>
                                    по охвату
                                </Text>
                            </View>
                        </View>
                        {
                            topAuthorsByPosts?.length && topAuthorsByPosts.map((post, index) => {
                                const views = topAuthorsByViews[index];
                                return (
                                    <View key={post.authorId} style={styles.statisticRow}>
                                        <View style={styles.statisticLabelCell}>
                                            <Text style={styles.statisticLabelText}>
                                                {cutAuthorName(post?.authorName)}
                                            </Text>
                                        </View>
                                        <View style={styles.statisticValueBorderCell}>
                                            <Text style={styles.statisticValueText}>
                                                {post?.amountPosts?.toLocaleString('ru-Ru') ?? '0'}
                                            </Text>
                                        </View>
                                        <View style={styles.statisticLabelCell}>
                                            <Text style={styles.statisticLabelText}>
                                                {cutAuthorName(views?.authorName)}
                                            </Text>
                                        </View>
                                        <View style={styles.statisticValueCell}>
                                            <Text style={styles.statisticValueText}>
                                                {views?.amountViews?.toLocaleString('ru-Ru') ?? '0'}
                                            </Text>
                                        </View>
                                    </View>
                                );
                            })
                        }
                    </View>
                </View>
                <View style={styles.totalContainer}>
                    <Text style={styles.totalTitle}>
                        ВСЕГО
                    </Text>
                    <View style={styles.totalRow}>
                        <View style={styles.totalColumnRightAlign}>
                            <View style={styles.totalCell}>
                                <Text style={styles.totalValue}>
                                    {amounts?.quantityPost?.toLocaleString('ru-RU') ?? '0'}
                                </Text>
                            </View>
                            <View style={styles.totalCell}>
                                <Text style={styles.totalValue}>
                                    {amounts?.quantityBloggerPost?.toLocaleString('ru-RU') ?? '0'}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.totalColumn}>
                            <View style={styles.totalCell}>
                                <Text style={styles.totalLabel}>
                                    публикаций
                                </Text>
                            </View>
                            <View style={styles.totalCell}>
                                <Text style={styles.doubleTotalLabel}>
                                    публикаций
                                </Text>
                                <Text style={styles.doubleTotalLabel}>
                                    среди блогеров
                                </Text>
                            </View>
                        </View>
                        <View style={styles.margin} />
                        <View style={styles.totalColumnRightAlign}>
                            <View style={styles.totalCell}>
                                <Text style={styles.totalValue}>
                                    {amounts?.quantityAuthors?.toLocaleString('ru-RU') ?? '0'}
                                </Text>
                            </View>
                            <View style={styles.totalCell}>
                                <Text style={styles.totalValue}>
                                    {amounts?.quantityReactions?.toLocaleString('ru-RU') ?? '0'}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.totalColumn}>
                            <View style={styles.totalCell}>
                                <Text style={styles.totalLabel}>
                                    авторов
                                </Text>
                            </View>
                            <View style={styles.totalCell}>
                                <Text style={styles.totalLabel}>
                                    реакций
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </CampaignPDFTemplatePage>
    );
};

export default CampaignPDFSummaryDataPage;
