/* eslint-disable no-unsafe-optional-chaining */
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { getAbbreviation } from '../../utils/getAbbreviation';
import styles from './styles.module.scss';

interface IPropsQuickInfo {
    countAuthors: number
    bloggerCountPosts: number
    smiCountPosts: number
    countView: number
    countLikes: number
    countReactions: number
    countComments: number,
    isIPadSize: boolean,
    oldData: any,
}

const CampaingTrackingQuickInfo: React.FC<IPropsQuickInfo> = ({
    countAuthors,
    bloggerCountPosts,
    smiCountPosts,
    countView,
    countLikes,
    countReactions,
    countComments,
    isIPadSize,
    oldData,
}): JSX.Element => {
    const getSummationQuantity = useMemo((): number => {
        const summation = (bloggerCountPosts + smiCountPosts).toFixed(0);
        return +summation;
    }, [bloggerCountPosts, smiCountPosts]);
    const diffAuthors = useMemo(() => {
        if (!oldData?.count_authors || countAuthors === oldData?.count_authors) return '';
        const diffValue = countAuthors - oldData?.count_authors;
        const isPositive = diffValue >= 0;
        const className = isPositive ? styles.positiveDiff : styles.negativeDiff;
        const formattValue = `${isPositive ? '+' : ''}${getAbbreviation(diffValue)}`;
        return (
            <div className={`${styles.currentContent} ${className}`}>
                {formattValue}
            </div>
        );
    }, [countAuthors, oldData]);
    const diffSummationQuantity = useMemo(() => {
        if (!oldData?.count_post || getSummationQuantity === oldData?.count_post) return '';
        const summation = (oldData?.count_blogger_post + oldData?.count_smi_post)?.toFixed(0);
        const diffValue = +getSummationQuantity - +summation;
        const isPositive = diffValue >= 0;
        const className = isPositive ? styles.positiveDiff : styles.negativeDiff;
        const formattValue = `${`${isPositive ? '+' : ''}${getAbbreviation(diffValue)}`}`;
        return (
            <div className={`${styles.currentContent} ${className}`}>
                {formattValue}
            </div>
        );
    }, [getSummationQuantity, oldData]);
    const diffCountViews = useMemo(() => {
        if (!oldData?.count_views || countView === oldData?.count_views) return '';
        const diffValue = countView - oldData?.count_views;
        const isPositive = diffValue >= 0;
        const className = isPositive ? styles.positiveDiff : styles.negativeDiff;
        const formattValue = `${isPositive ? '+' : ''}${getAbbreviation(diffValue)}`;
        return (
            <div className={`${styles.currentContent} ${className}`}>
                {formattValue}
            </div>
        );
    }, [countView, oldData]);
    const diffCountLikes = useMemo(() => {
        if (!oldData?.count_likes || countLikes === oldData?.count_likes) return '';
        const diffValue = countLikes - oldData?.count_likes;
        const isPositive = diffValue >= 0;
        const className = isPositive ? styles.positiveDiff : styles.negativeDiff;
        const formattValue = `${isPositive ? '+' : ''}${getAbbreviation(diffValue)}`;
        return (
            <div className={`${styles.currentContent} ${className}`}>
                {formattValue}
            </div>
        );
    }, [countLikes, oldData]);
    const diffCountReactions = useMemo(() => {
        if (!oldData?.count_reactions || countReactions === oldData?.count_reactions) return '';
        const diffValue = countReactions - oldData?.count_reactions;
        const isPositive = diffValue >= 0;
        const className = isPositive ? styles.positiveDiff : styles.negativeDiff;
        const formattValue = `${isPositive ? '+' : ''}${getAbbreviation(diffValue)}`;
        return (
            <div className={`${styles.currentContent} ${className}`}>
                {formattValue}
            </div>
        );
    }, [countReactions, oldData]);
    const diffCountComments = useMemo(() => {
        if (!oldData?.count_comments || countComments === oldData?.count_comments) return '';
        const diffValue = countComments - oldData?.count_comments;
        const isPositive = diffValue >= 0;
        const className = isPositive ? styles.positiveDiff : styles.negativeDiff;
        const formattValue = `${isPositive ? '+' : ''}${getAbbreviation(diffValue)}`;
        return (
            <div className={`${styles.currentContent} ${className}`}>
                {formattValue}
            </div>
        );
    }, [countComments, oldData]);
    const getToltip = (value, diff: React.ReactChild) => (
        <div className={`${styles.contentContainer} ${styles.full}`}>
            <div className={`${styles.currentContent}`}>
                {value}
            </div>
            {diff}
        </div>
    );
    return (
        <div>
            <div className={styles.containerGlobalInfo} style={isIPadSize ? { flexWrap: 'wrap', alignItems: 'stretch' } : null}>
                <div className={isIPadSize ? '' : styles.blockInfo}>
                    <div className={styles.blockTitle}>Авторы</div>
                    <Tooltip
                        placement="bottom"
                        color="white"
                        zIndex={getSummationQuantity >= 10000 ? 10 : -10}
                        title={(getToltip(countAuthors?.toLocaleString('RU-ru') ?? '-', diffAuthors))}
                        overlayStyle={{ maxWidth: '1000px' }}
                        overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                        <div className={styles.contentContainer}>
                            <div className={styles.currentContent}>
                                {countAuthors === undefined || countAuthors === null ? '-' : countAuthors?.toLocaleString('ru-RU')}
                            </div>
                            <div className={styles.hideGradient} />
                            {diffAuthors}
                        </div>
                    </Tooltip>
                </div>
                <div className={isIPadSize ? '' : styles.blockInfo}>
                    <div className={styles.blockTitle}>Сообщения</div>
                    <Tooltip
                        placement="bottom"
                        color="white"
                        zIndex={getSummationQuantity >= 10000 ? 10 : -10}
                        title={(getToltip(getSummationQuantity?.toLocaleString('RU-ru') ?? '-', diffSummationQuantity))}
                        overlayStyle={{ maxWidth: '1000px' }}
                        overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                        <div className={styles.contentContainer}>
                            <div className={styles.currentContent}>
                                {getSummationQuantity === undefined || getSummationQuantity === null ? '-' : getAbbreviation(getSummationQuantity) }
                            </div>
                            <div className={styles.hideGradient} />
                            {diffSummationQuantity}
                        </div>
                    </Tooltip>
                </div>
                <div className={isIPadSize ? '' : styles.blockInfo}>
                    <div className={styles.blockTitle}>Просмотры</div>
                    <Tooltip
                        placement="bottom"
                        color="white"
                        zIndex={countView >= 10000 ? 10 : -10}
                        title={(getToltip(countView?.toLocaleString('RU-ru') ?? '-', diffCountViews))}
                        overlayStyle={{ maxWidth: '1000px' }}
                        overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                        <div className={styles.contentContainer}>
                            <div className={styles.currentContent}>
                                {countView === undefined || countView === null ? '-' : getAbbreviation(countView)}
                            </div>
                            <div className={styles.hideGradient} />
                            {diffCountViews}
                        </div>
                    </Tooltip>
                </div>
                <div className={isIPadSize ? '' : styles.blockInfo}>
                    <div className={styles.blockTitle}>Лайки</div>
                    <Tooltip
                        placement="bottom"
                        color="white"
                        zIndex={countLikes >= 10000 ? 10 : -10}
                        title={(getToltip(countLikes?.toLocaleString('RU-ru') ?? '-', diffCountLikes))}
                        overlayStyle={{ maxWidth: '1000px' }}
                        overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                        <div className={styles.contentContainer}>
                            <div className={styles.currentContent}>
                                {countLikes === undefined || countLikes === null ? '-' : getAbbreviation(countLikes)}
                            </div>
                            <div className={styles.hideGradient} />

                            {diffCountLikes}
                        </div>
                    </Tooltip>
                </div>
                <div className={isIPadSize ? '' : styles.blockInfo}>
                    <div className={styles.blockTitle}>Реакции</div>
                    <Tooltip
                        placement="bottom"
                        color="white"
                        zIndex={countReactions >= 10000 ? 10 : -10}
                        title={(getToltip(countReactions?.toLocaleString('RU-ru') ?? '-', diffCountReactions))}
                        overlayStyle={{ maxWidth: '1000px' }}
                        overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                        <div className={styles.contentContainer}>
                            <div className={styles.currentContent}>
                                {countReactions === undefined || countReactions === null ? '-' : getAbbreviation(countReactions)}
                            </div>
                            <div className={styles.hideGradient} />

                            {diffCountReactions}
                        </div>
                    </Tooltip>
                </div>
                <div className={isIPadSize ? '' : styles.blockInfo}>
                    <div className={styles.blockTitle}>Комментарии</div>
                    <Tooltip
                        placement="bottom"
                        color="white"
                        zIndex={countComments >= 10000 ? 10 : -10}
                        title={(getToltip(countComments?.toLocaleString('RU-ru') ?? '-', diffCountComments))}
                        overlayStyle={{ maxWidth: '1000px' }}
                        overlayInnerStyle={{ borderRadius: '6px' }}
                    >
                        <div className={styles.contentContainer}>
                            <div className={styles.currentContent}>
                                {countComments === undefined || countComments === null ? '-' : getAbbreviation(countComments)}
                            </div>
                            <div className={styles.hideGradient} />

                            {diffCountComments}
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CampaingTrackingQuickInfo);
