import React from 'react';
import styles from './styles.module.scss';

interface ITag {
    name: string,
    count: number,
    color?: string
}
interface IPropsTagList {
    tagList: ITag[]
}
const TooltipOnLinerChart:React.FC<IPropsTagList> = ({
    tagList,
}) => {
    const getMinMax = (list: ITag[]): { min: number, max: number } => {
        const minMax = list.reduce((acc, tag) => {
            const newAcc = JSON.parse(JSON.stringify(acc));
            if (!newAcc.min || newAcc.min > tag.count) newAcc.min = tag.count;
            if (newAcc.max < tag.count) newAcc.max = tag.count;
            return newAcc;
        }, { min: null, max: NaN });
        return minMax;
    };
    const randomColor = () => {
        const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
        const h = randomInt(0, 360);
        const s = randomInt(42, 98);
        const l = randomInt(60, 90);
        return `hsl(${h},${s}%,${l}%)`;
    };
    const getFormattedList = (list: ITag[], min: number, max: number) => list.map((tag) => {
        const newTag = { ...tag, size: 0, color: randomColor() };
        if (tag.count === min) { newTag.size = 10; return newTag; }
        if (tag.count === max) { newTag.size = 42; return newTag; }
        const percentCount = tag.count / max;
        const size = (42 - 8) * percentCount;
        newTag.size = Math.ceil(size) + 12;
        return newTag;
    });
    const getTagList = (list) => list.map((tag) => (
        <div
            key={`${tag.name}_${tag.count}`}
            className={styles.tag}
            style={{
                fontSize: `${tag.size}px`,
                color: tag.color,
            }}
        >
            {tag.name}
        </div>
    ));
    return (
        <div className={styles.tagCloudContainer}>
            {getTagList(getFormattedList(tagList, getMinMax(tagList).min, getMinMax(tagList).max))}
        </div>
    );
};

export default TooltipOnLinerChart;
