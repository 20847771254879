/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Checkbox, Switch, Slider } from 'antd';
import styles from './styles.module.scss';
import okIcon from '../GraphChart/TooltipGraph/ok.svg';
import vkIcon from '../GraphChart/TooltipGraph/vk.svg';
import rssIcon from '../GraphChart/TooltipGraph/rss.svg';
import tgIcon from '../GraphChart/TooltipGraph/tg.svg';
import faqIcon from './faqIcon.svg';

const FiltersChart: React.FC<any> = ({
    isOk,
    setIsOk,
    isVk,
    setIsVk,
    isRss,
    setIsRss,
    isTg,
    setIsTg,
    isMainPlot,
    setIsMainPlot,
    isAltPlot,
    setIsAltPlot,
    isContractedOnly,
    setIsContractedOnly,
    percentMatch,
    setPercentMatch,
}: any): JSX.Element => (
    <div style={{ width: '450px' }}>
        <div className={styles.listTypes}>
            <div className={styles.checkBoxType}>
                <Checkbox
                    checked={isOk}
                    onChange={(value) => {
                        setIsOk(value.target.checked);
                    }}
                />
                <img className={styles.iconTypes} src={okIcon} alt="type" />
            </div>
            <div className={styles.checkBoxType}>
                <Checkbox checked={isVk} onChange={(value) => setIsVk(value.target.checked)} />
                <img className={styles.iconTypes} src={vkIcon} alt="type" />
            </div>
            <div className={styles.checkBoxType}>
                <Checkbox checked={isRss} onChange={(value) => setIsRss(value.target.checked)} />
                <img className={styles.iconTypes} src={rssIcon} alt="type" />
            </div>
            <div className={styles.checkBoxType}>
                <Checkbox checked={isTg} onChange={(value) => setIsTg(value.target.checked)} />
                <img className={styles.iconTypes} src={tgIcon} alt="type" />
            </div>
        </div>
        <div className={styles.typePlots}>
            <div className={styles.filterPlotContainer}>
                <div className={styles.checkBoxType}>
                    <img className={styles.iconFaq} src={faqIcon} alt="type" />
                    <Checkbox checked={isMainPlot} onChange={(value) => setIsMainPlot(value.target.checked)} />
                    <div>Основной сюжет</div>
                </div>
                <div className={styles.iconMainPlot} />
            </div>
            <div className={styles.filterPlotContainer}>
                <div className={styles.checkBoxType}>
                    <img className={styles.iconFaq} src={faqIcon} alt="type" />
                    <Checkbox checked={isAltPlot} onChange={(value) => setIsAltPlot(value.target.checked)} />
                    <div>Основной сюжет</div>
                </div>
                <div className={styles.iconAltPlot} />
            </div>
            <div className={styles.filterPlotContainer}>
                <img className={styles.iconFaq} src={faqIcon} alt="type" />
                <div className={styles.checkBoxType}>
                    <Switch
                        size="small"
                        checked={isContractedOnly}
                        onChange={(value) => setIsContractedOnly(value)}
                    />
                    <div>Показывать только контрактованных</div>
                </div>
            </div>
        </div>
        <div className={styles.matchingThemesContainer}>
            <div className={styles.matchingThemesLabel}>
                Совпадение тематик
            </div>
            <div className={styles.containerSlider}>
                <div className={styles.matchingThemesMark}>0%</div>
                <Slider
                    trackStyle={{ color: '#0EBFA1', background: '#0EBFA1' }}
                    handleStyle={{
                        color: '#0EBFA1', background: '#0EBFA1', borderColor: '#0EBFA1', boxShadow: '0 0 0 0',
                    }}
                    min={0}
                    max={100}
                    step={25}
                    value={percentMatch}
                    onChange={(value) => setPercentMatch(value)}
                />
                <div className={styles.matchingThemesMark}>100%</div>
            </div>
        </div>
    </div>
);

export default FiltersChart;
