import { rtkQApi } from '..';
import { pdfHandlerReportActions } from '../../store/pdf-handler/report';
import { pdfHandlerWrapperActions } from '../../store/pdf-handler/wrapper';
import { convertReportDataApiToSummary } from '../../store/pdf-handler/wrapper/converter';
import { IPDFReportSummary } from '../../store/pdf-handler/wrapper/types';
import campaignUtils from '../../utils/campaign';
import { ICampaignReportAggFromApi, IQPReportAgg } from './types';

const getFromDate = (timeFrame: string): string => campaignUtils.getCampaignParseFromDateNoString(
    timeFrame === '7' ? '2' : '3',
).toISOString();

export const reportsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postLoadReportAgg: build.query<IPDFReportSummary, IQPReportAgg>({
            query: (params: IQPReportAgg) => {
                const fromDate = getFromDate(params.period);
                const toDate = new Date().toISOString();
                return {
                    url: `campaign/${params.id}/report_agg`,
                    method: 'POST',
                    body: {
                        from_date: fromDate,
                        to_date: toDate,
                    },
                };
            },
            async onQueryStarted(args: IQPReportAgg, { dispatch, queryFulfilled }) {
                dispatch(pdfHandlerWrapperActions.startLoadingSummary());
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(pdfHandlerWrapperActions.successLoadingSummary());
                        dispatch(pdfHandlerWrapperActions.setSummaryDataFromApi(
                            { campaignId: args.id, period: args.period, data },
                        ));
                    })
                    .catch((error) => {
                        dispatch(pdfHandlerWrapperActions.failedLoadingSummary());
                        dispatch(pdfHandlerWrapperActions.setFetchErrors(error?.message));
                    });
            },
            transformResponse: (
                baseQueryResponse: ICampaignReportAggFromApi,
            ): IPDFReportSummary => convertReportDataApiToSummary(baseQueryResponse),
        }),
        getSavedReports: build.query<unknown, { id: number }>({
            query: ({ id }) => ({
                url: `campaign/${id}/report`,
                method: 'GET',
            }),
            async onQueryStarted(args: { id: number }, { dispatch, queryFulfilled }) {
                dispatch(pdfHandlerReportActions.startLoading());
                dispatch(pdfHandlerReportActions.clearReports());
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(pdfHandlerReportActions.successLoading());
                        dispatch(pdfHandlerReportActions.addReportsFromApi(data));
                    })
                    .catch((error) => {
                        dispatch(pdfHandlerReportActions.failedLoading());
                        dispatch(pdfHandlerReportActions.setFetchErrors(error?.message));
                    });
            },
        }),
        getChartsForReport: build.query<unknown, { id: number }>({
            query: ({ id }) => ({
                url: `campaign/${id}/chart`,
                method: 'GET',
            }),
        }),
    }),
});
