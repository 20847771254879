import { Dropdown } from 'antd';
import React, { useMemo, useState } from 'react';
import { ISimpleEntity } from 'store/types';
import TextField from '@entities/TextField';
import PlusIcon from '../../../assets/images/plus.svg';
import CampaignAutoCompleteTrackedLinks from '../../../components/campaign/UpdateForm/TrackedWords/AutoComplete';
import { sourcesRtkQService } from '../../../services/campaigns/source';
import { ISource } from '../../../services/campaigns/source/types';
import styles from './styles.module.scss';

interface IProps {
    addSource: (source: ISource) => void;
}

const UserAddingFavoriteSource: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { addSource } = props;
    const [menuIsVisible, setVisibleMenu] = useState<boolean>(true);
    const [inputValue, setInputValue] = useState<string>('');
    const [postSearch, { data: searchData, isLoading: isSearchLoading }] = sourcesRtkQService.useLazyPostSearchQuery();
    const [selectedSource, setSelectedSource] = useState<ISource | null>(null);

    const onMouseLeaveMenu = (): void => {
        if (menuIsVisible) {
            setVisibleMenu(false);
        }
    };

    const onMouseEnterMenu = (): void => {
        if (!menuIsVisible) {
            setVisibleMenu(true);
        }
    };

    const onSelect = (selectedId: number) => {
        if (selectedId) {
            const item = { ...searchData.find(({ id }) => selectedId === id) };
            setSelectedSource(item);
        }
    };

    const handleChangeInputValue = (value: string) => {
        setInputValue(value);
        setSelectedSource(null);
        if (value && value?.length >= 2) {
            postSearch({
                pattern: inputValue,
                platform: ['RSS', 'VK', 'OK', 'TG', 'DZEN'],
            });
        }
    };

    const sourcesList = useMemo<ISimpleEntity[]>(
        () => searchData?.map(({ id, title, link }) => ({
            id,
            name: title,
            value: link,
        })) ?? [],
        [searchData],
    );

    const handleClickAdd = (): void => {
        if (selectedSource) {
            addSource(selectedSource);
            setSelectedSource(null);
            setInputValue('');
        }
    };

    return (
        <div>
            <Dropdown
                trigger={['click']}
                overlay={(
                    <div className={styles.dropdown}>
                        <div
                            onMouseLeave={onMouseLeaveMenu}
                            onMouseEnter={onMouseEnterMenu}
                        >
                            <Dropdown
                                visible={
                                    menuIsVisible
                                    && (Boolean(sourcesList?.length)
                                        || isSearchLoading)
                                }
                                overlay={(
                                    <CampaignAutoCompleteTrackedLinks
                                        list={
                                            inputValue?.length
                                                ? sourcesList
                                                : []
                                        }
                                        onSelect={onSelect}
                                        isLoading={isSearchLoading}
                                    />
                                )}
                            >
                                <TextField
                                    value={selectedSource?.link ?? inputValue}
                                    onChange={handleChangeInputValue}
                                    label="Введите источник"
                                    isSearch
                                />
                            </Dropdown>
                        </div>

                        <button
                            type="button"
                            className={styles.dropdownButton}
                            onClick={handleClickAdd}
                            disabled={!selectedSource}
                        >
                            Добавить
                        </button>
                    </div>
                )}
            >
                <button type="button" className={styles.button}>
                    <span>Добавить источник</span>
                    <div>
                        <img src={PlusIcon} alt="PlusIcon" />
                    </div>
                </button>
            </Dropdown>
        </div>
    );
};
export default UserAddingFavoriteSource;
