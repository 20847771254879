import {
    useCallback, useMemo,
} from 'react';
import { useParams } from 'react-router';
import { campaignReportsRtkQService } from '../../../services/campaigns/reports';
import { singleCampaignRtkQService } from '../../../services/campaigns/single-campaign';
import { campaignStatisticsRtkQService } from '../../../services/campaigns/statistics';
import { reportsRtkQService } from '../../../services/reports';
import PptxFactor from '../../../utils/PptxFactor/Pptx';
import { formatTopPlatforms } from '../../../utils/PptxFactor/utils';
import campaignUtils from '../../../utils/campaign';
import { categoriesRtkQService } from '../../../services/categories';

interface IUsePptxHandlerResponse {
    createPptxFile: (reportName: string, reportPeriod: string) => Promise<File>;
    prepareData: (reportPeriod: string) => Promise<void>;
    isDataLoaded: boolean;
    isLoading: boolean;
    isError: boolean;
}
const usePptxHandler = (): IUsePptxHandlerResponse => {
    const pptx = new PptxFactor();
    const { Id: campaignId } = useParams();
    const [
        getFilters,
        {
            data: filtersData,
            isSuccess: isFiltersSuccess,
            isLoading: isFiltersLoading,
            isError: isFiltersError,
        },
    ] = campaignReportsRtkQService.useLazyGetFiltersQuery();
    const [
        getCampaign,
        {
            data: campaign,
            isSuccess: isCampaignSuccess,
            isLoading: isCampaignLoading,
            isError: isCampaignError,
        },
    ] = singleCampaignRtkQService.useLazyGetCampaignQuery();
    const [
        getCampaignSummary,
        {
            data: campaignSummaryData,
            isSuccess: isCampaignSummaryDataSuccess,
            isLoading: isCampaignSummaryDataLoading,
            isError: isCampaignSummaryDataError,
        },
    ] = campaignStatisticsRtkQService.useLazyPostLoadCampaignSummaryQuery();
    const [
        loadReportAgg,
        {
            data: summaryDataByCampaignIdAndPeriod,
            isSuccess: isReportAggSuccess,
            isLoading: isReportAggLoading,
            isError: isReportAggError,
        },
    ] = reportsRtkQService.useLazyPostLoadReportAggQuery();

    const [
        getCategories,
        {
            data: categories,
            isSuccess: isCategoriesSuccess,
            isLoading: isCategoriesLoading,
            isError: isCategoriesError,
        },
    ] = categoriesRtkQService.useLazyGetCategoriesQuery();

    const isDataLoaded: boolean = useMemo(
        () => isFiltersSuccess
            && isCampaignSuccess
            && isCampaignSummaryDataSuccess
            && isReportAggSuccess
            && isCategoriesSuccess,
        [
            isFiltersSuccess,
            isCampaignSuccess,
            isCampaignSummaryDataSuccess,
            isReportAggSuccess,
        ],
    );

    const isLoading: boolean = useMemo(
        () => isFiltersLoading
            || isCampaignLoading
            || isCampaignSummaryDataLoading
            || isReportAggLoading
            || isCategoriesLoading,
        [
            isFiltersLoading,
            isCampaignLoading,
            isCampaignSummaryDataLoading,
            isReportAggLoading,
        ],
    );

    const isError: boolean = useMemo(
        () => isFiltersError
            || isCampaignError
            || isCampaignSummaryDataError
            || isReportAggError
            || isCategoriesError,
        [
            isFiltersError,
            isCampaignError,
            isCampaignSummaryDataError,
            isReportAggError,
        ],
    );

    const prepareData: (reportPeriod: string) => Promise<void> = useCallback(
        async (reportPeriod: string) => {
            if (!campaignId) return;

            if (!campaign) {
                getCampaign({
                    id: +campaignId,
                });
            }

            if (!filtersData) {
                getFilters({
                    campaign_id: +campaignId,
                });
            }

            if (!campaignSummaryData) {
                getCampaignSummary({ id: +campaignId, period: reportPeriod });
            }

            loadReportAgg({ id: Number(campaignId), period: reportPeriod });

            getCategories();
        },
        [campaignId, campaign, filtersData, campaignSummaryData],
    );

    const createPptxFile = async (reportName: string, period: string): Promise<File> => {
        const date = `${campaignUtils
            .getCampaignParseFromDateNoString(period)
            .format('DD.MM.YYYY')} - ${new Date().toLocaleDateString('ru-RU')}`;
        const tags = filtersData.find((item) => item.filters_category === 'Теги')?.filters || [];
        const sourcesTypes = filtersData.find((item) => item.filters_category === 'Типы отслеживаемых авторов');
        const categiries = filtersData.find((item) => item.filters_category === 'Категории');
        const tonalityList = filtersData.find((item) => item.filters_category === 'Тональность');
        const platformList = filtersData.find((item) => item.filters_category === 'Типы платформ');
        const authorsList = filtersData.find((item) => item.filters_category === 'Авторы');

        const rssByRegion = summaryDataByCampaignIdAndPeriod.topSourcesByRegions.filter((item) => item.authorType === 'СМИ').splice(0, 9);
        const bloggerByRegion = summaryDataByCampaignIdAndPeriod.topSourcesByRegions.filter((item) => item.authorType === 'Блоггер').splice(0, 9);
        const topPlatforms = formatTopPlatforms(summaryDataByCampaignIdAndPeriod?.topViewsByPlatform);
        const categoriesDict = categories.filter(({ id }) => campaign.categoriesIds.includes(Number(id)));
        let selectedCategories = [];

        if (categoriesDict?.length >= 1) {
            selectedCategories = categoriesDict.map((item) => ({
                id: item.id,
                name: item.mainCat,
                value: item.mainCat,
            }));
        } else {
            selectedCategories = [
                {
                    id: 0,
                    name: 'Все категории',
                    value: 'Все категории',
                },
            ];
        }

        const pptxFile = await pptx.createSlides({
            common: {
                date,
                title: campaign.name,
                reportName,
            },
            searchParamsData: {
                categories: selectedCategories ?? [],
                trackedWords: campaign?.includeWords?.map((word, index) => ({
                    id: index,
                    name: word,
                    value: word,
                })),
                platforms: campaign?.sourceType?.map((word, index) => ({
                    id: index,
                    name: word,
                    value: word,
                })),
                excludedLinks: campaign?.sources?.map((item) => ({
                    id: item.id,
                    name: item.link,
                    value: item.link,
                })),
            },
            summaryData: {
                topAuthorsByPosts:
                    summaryDataByCampaignIdAndPeriod?.topAuthorsByPosts,
                topAuthorsByViews:
                    summaryDataByCampaignIdAndPeriod?.topAuthorsByViews,
                amounts: campaignSummaryData,
            },
            topPlatforms,
            authorsData: authorsList,
            tags: tags.slice(0, 10),
            additionalData: {
                platfroms: platformList,
                tonality: tonalityList,
            },
            sourcesTypes,
            categiries,
            rssByRegion,
            bloggerByRegion,
        });

        return pptxFile;
    };

    return {
        createPptxFile,
        prepareData,
        isDataLoaded,
        isLoading,
        isError,
    };
};

export default usePptxHandler;
