import React, { useEffect } from 'react';
import { WordCloud } from '@ant-design/plots';
import Skeleton from 'react-loading-skeleton';
import { chartsCampaingRtqService } from '../../../../../services/campaigns/Charts';

interface IPropsCampaignDnDTags {
    campaignId: number,
    tags: any
}

const CampaignDnDTags: React.FC<IPropsCampaignDnDTags> = ({ campaignId, tags }): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [getChartData, { data, isError }] = chartsCampaingRtqService.useNewGetChartDataMutation();
    useEffect(() => {
        if (typeof campaignId === 'number') {
            getChartData({
                campaign_id: campaignId,
                chart_type: 'TAG_CHART',
                dimensions: ['TAGS'],
                filters_include: [],
                metrics: ['POSTS'],
                name: 'Новый график',
                time_frame: 'DAY',
            } as any);
        }
    }, [campaignId]);
    const config = {
        data: tags,
        wordField: 'name',
        weightField: 'value',
        colorField: 'name',
        wordStyle: {
            fontFamily: 'Verdana',
            fontSize: [4, 56],
            rotation: -5,
        },
        random: () => 0.5,
    };
    if (!tags?.length) {
        return (
            <div style={{
                width: 346, height: 86, background: 'white', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                <Skeleton height={86 - 12} width={342 - 12} />
            </div>
        );
    }
    if (isError) return null;

    if (isError) {
        return (
            <div style={{
                width: 346, height: 86, background: 'white', borderRadius: 6, padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                Произошла непредвиденная ошибка
            </div>
        );
    }
    return (
        <div style={{
            width: 346, height: 86, background: 'white', borderRadius: 6,
        }}
        >
            <WordCloud {...config as any} />
        </div>
    );
};

export default CampaignDnDTags;
