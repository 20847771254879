/* eslint-disable no-prototype-builtins */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IColumnProperties,
    ITableProperties,
    TableNames,
} from '@store/common/tableProperties/types';
import { IDictionary, TableLocalStorageKeys } from '@store/types';

const initialState: IDictionary<ITableProperties> = {
    [TableNames.MediaPlan]: (JSON.parse(localStorage.getItem(TableLocalStorageKeys.MediaPlan)) as ITableProperties) ?? {
        isSyncWithLocalStorage: false,
        localStorageKey: TableLocalStorageKeys.MediaPlan,
        columns: {},
    },
};
const setColumnVisibility = (column: IColumnProperties, properties: ITableProperties, visible: boolean): IDictionary<IColumnProperties> => {
    const result = { ...properties.columns };
    if (result?.hasOwnProperty(column.name)) {
        result[column.name].visible = visible;
    } else {
        result[column.name] = { ...column, visible } as IColumnProperties;
    }
    return result;
};

const getStateWithLocalStorage = (properties: ITableProperties): ITableProperties => {
    let result: ITableProperties = {
        ...properties,
        columns: { ...properties.columns },
    };
    if (!properties.isSyncWithLocalStorage) {
        result = JSON.parse(localStorage.getItem(result.localStorageKey)) as ITableProperties;
        if (!result) {
            return {
                ...properties,
                columns: { ...properties.columns },
                isSyncWithLocalStorage: true,
            } as ITableProperties;
        }
    }
    return result;
};

const { actions: tablePropertiesAction, reducer } = createSlice({
    name: 'tableProperties',
    initialState,
    reducers: {
        setVisibilityColumnMediaPlan: (state: IDictionary<ITableProperties>, { payload }: PayloadAction<IColumnProperties>): void => {
            if (state.hasOwnProperty(TableNames.MediaPlan)) {
                const properties = state[TableNames.MediaPlan];
                const buffer = getStateWithLocalStorage(properties);
                buffer.columns = setColumnVisibility(payload, buffer, payload.visible);

                // update store
                properties.columns = buffer.columns;
                properties.isSyncWithLocalStorage = buffer.isSyncWithLocalStorage;
                // update localStorage
                localStorage.setItem(buffer.localStorageKey, JSON.stringify(buffer));
            }
        },
    },
});

export {
    tablePropertiesAction,
};

export default reducer;
