/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';
import { Checkbox } from 'antd';
import styles from './styles.module.scss';
import { IChartDataLegend } from '../types';

interface IProps {
    onChange: (chart: IChartDataLegend, disabled: boolean) => void,
    data: IChartDataLegend[],
}

const LegendMonitoringChartViews: React.FC<IProps> = ({
    data,
    onChange,
}): JSX.Element => {
    const getListCheckBoxes = useMemo(() => {
        const list = data.map((chart) => (
            <div
                key={`${chart.label}_chart_checkbox_monitoring`}
                className={styles.legend}
            >
                <div className={styles.legend_chekbox}>
                    <Checkbox
                        checked={!chart.disabled}
                        onChange={() => onChange(chart, !chart.disabled)}
                    />
                </div>
                <div
                    className={styles.legend_block}
                    style={{ borderColor: chart.allColors.default, backgroundColor: chart.allColors.light }}
                />
                <div className={styles.legend_label}>
                    {chart.label}
                </div>
            </div>
        ));
        return list;
    }, [data]);
    return (
        <div className={styles.legendMonitoringChartViews}>
            <div className={styles.legendMonitoringChartViews_root}>
                {getListCheckBoxes}
            </div>
        </div>
    );
};

export default React.memo(LegendMonitoringChartViews);
