import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Path from '@shared/lib/paths';
import { campaignComparisonActions } from '@store/campaign/comparison';
import campaignComparisonSelectors from '@store/campaign/comparison/selectors';
import currentPageSelectors from '@store/common/current-page/selectors';
import { useAppDispatch } from '@store/hooks';
import BreadCrumbs from '@entities/BreadCrumbs';
import { breadCrumbsActions } from '@store/common/breadCrumbs';
import SvgIcon from '@components/SvgIcon';
import UserPanel from '../UserPanel';
import BackArrowIcon from './imgs/back-arrow.svg';
import styles from './styles.module.scss';
import campaignUtils from '../../utils/campaign';

const HeaderContainer: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // const currenBasketSubpage = useSelector(currentInfoBasketSubpageSelector);
    const campaign = useSelector(currentPageSelectors.currentCampaign);
    const source = useSelector(currentPageSelectors.currentSource);
    const project = useSelector(currentPageSelectors.currentProject);
    const isSavedAnalysis = useSelector(campaignComparisonSelectors.campaignSavedAnalysis);
    const comparisonEnabled = useSelector(campaignComparisonSelectors.campaignCompareEnabled);
    const currentPage = useMemo(() => {
        let isNotFound = false;
        let name = '';
        const { pathname } = location;
        const editCampaignPrefix = 'Редактировать кампанию';
        const editProjectPrefix = 'Редактировать проект';
        switch (pathname) {
            case Path.InfoTrace:
                name = 'Информационный шлейф социальных медиа';
                break;
            case Path.InfoBasket:
                name = 'Информационная корзина';
                break;
            case Path.History:
                name = 'История использования';
                break;
            case Path.UserProfile:
                name = 'Мой профиль';
                break;
            case `/${Path.Campaign}`:
            case `/${Path.Campaign}/${Path.CampaignList}`:
                name = 'Мои кампании';
                break;
            case `/${Path.Campaign}/${Path.CampaignCreate}`:
                name = 'Новая кампания';
                break;
            case `/${Path.Campaign}/${Path.Charts}`:
                name = 'Графики';
                break;
            case `/${Path.Campaign}/${Path.SavedCharts}`:
                name = 'Сохраненные графики';
                break;
            case `/${Path.Settings}/${Path.Notification}`:
                name = 'Уведомления';
                break;
            case `/${Path.Settings}/${Path.UserRights}`:
                name = 'Права';
                break;
            case Path.UserProfileFavorites:
                name = 'Избранное';
                break;
            case `/${Path.CampaignDashboard}`:
                name = 'Дашборд';
                break;
            case `/${Path.Sources}/${Path.SourcesList}`:
                name = 'Источники';
                break;
            case `/${Path.Projects}/${Path.ProjectList}`:
                name = 'Проекты';
                break;
            case `/${Path.Projects}/${Path.ProjectCreate}`:
                name = 'Создание проекта';
                break;
            default:
                isNotFound = true;
        }

        if (comparisonEnabled && pathname.includes(`/${Path.Campaign}/${Path.CampaignList}`)) {
            name = 'Сравнение';
        } else if (isNotFound) {
            const parts = pathname.split('/');
            if (pathname.includes(`/${Path.Campaign}/${Path.CampaignUpdate}/`)) {
                name = campaign?.name
                    ? `${editCampaignPrefix} "${campaign?.name}"`
                    : editCampaignPrefix;
            } else if (campaignUtils.locationIsTrackingCampaign(pathname)) {
                name = campaign?.name
                    ? `Кампания "${campaign?.name}"`
                    : 'Кампания';
            } else if (
                pathname.includes(`/${Path.Campaign}/${Path.Charts}/`)
                && parts?.length === 4
                && Number.isInteger((Number(parts[3])))
            ) {
                if (parts[2] === 'charts') {
                    name = 'Графики';
                }
            } else if (pathname.includes(`/${Path.Campaign}/${Path.Reports}/`)) {
                name = 'Отчёты';
            } else if (pathname.includes(`/${Path.Campaign}/${Path.SavedCharts}/`)) {
                name = 'Сохраненные графики';
            } else if (comparisonEnabled || pathname.includes(`/${Path.Campaign}/${Path.CampaignComparison}`)) {
                name = 'Сравнение';
            } else if (pathname.includes(`/${Path.Campaign}/${Path.SearchRequest}`)) {
                name = 'Поисковый запрос';
            } else if (pathname.includes(`/${Path.Campaign}/${Path.CampaignComparisonsList}`)) {
                name = 'Конкурентные анализы';
            } else if (pathname.includes(`/${Path.Campaign}/${Path.Tags}`)) {
                name = 'Теги';
            } else if (pathname.includes(`/${Path.Campaign}/${Path.Authors}`)) {
                name = 'Авторы';
            } else if (pathname.includes(`/${Path.Campaign}/${Path.CampaignBasket}`)) {
                name = 'Корзина';
            } else if (pathname.includes(`/${Path.Sources}/${Path.SourcesFavorite}`)) {
                name = 'Избранное';
            } else if (pathname.includes(`/${Path.Sources}/${Path.SourcesRoll}`)) {
                name = 'Списки';
            } else if (pathname.includes(`/${Path.Sources}/`)) {
                name = `Источник ${source?.title}`;
            } else if (pathname.includes(`/${Path.Projects}/`)) {
                name = project?.name
                    ? `${editProjectPrefix} "${project?.name}"`
                    : editProjectPrefix;
            }
        }
        return name;
    }, [location, campaign, source, project, comparisonEnabled]);

    const handlerReturnToMainPage = (): void => {
        dispatch(campaignComparisonActions.clearAll());
        navigate(-1);
    };

    useEffect(() => {
        const { pathname } = location;
        if ((comparisonEnabled || isSavedAnalysis) && !(
            pathname.includes(`/${Path.Campaign}/${Path.CampaignComparison}`)
            || pathname.includes(`/${Path.Campaign}/${Path.CampaignList}`)
        )) {
            dispatch(campaignComparisonActions.clearAll());
        }
    }, [location, comparisonEnabled, isSavedAnalysis]);

    useEffect(() => {
        dispatch(breadCrumbsActions.addPathHistory({ title: currentPage, pathname: location.pathname }));
    }, [location.pathname]);

    useEffect(() => {
        dispatch(breadCrumbsActions.updateCurrentPathTitle(currentPage));
    }, [currentPage]);

    return (
        <div className={styles.headerContainer_root}>
            <div className={styles.leftContent}>
                <BreadCrumbs />
                <div className={styles.titleContainer}>
                    {/* { */}
                    {/*    location?.pathname === Path.InfoBasket && currenBasketSubpage === InfoBasketSubpage.result && ( */}
                    {/*        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    {/*        <img */}
                    {/*            src={BackArrowIcon} */}
                    {/*            alt="BackArrowIcon" */}
                    {/*            style={{ cursor: 'pointer' }} */}
                    {/*            onClick={changeBasketSubpage} */}
                    {/*            width="8" */}
                    {/*            height="16" */}
                    {/*            className={styles.titleContainer_img} */}
                    {/*        /> */}
                    {/*    ) */}
                    {/* } */}
                    {
                        location?.pathname !== `/${Path.Campaign}/${Path.CampaignList}` && (
                            <button
                                type="button"
                                className={styles.titleContainer_linkArrow}
                                onClick={handlerReturnToMainPage}
                            >
                                <img
                                    src={BackArrowIcon}
                                    alt="BackArrowIcon"
                                    style={{ cursor: 'pointer' }}
                                    // onClick={changeBasketSubpage}
                                    width="8"
                                    height="16"
                                />
                            </button>
                            // <Link to={Path.Campaign} className={styles.titleContainer_linkArrow}>
                            //     {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                            //
                            // </Link>
                        )
                    }
                    <span className={styles.titleContainer_text}>{currentPage}</span>
                    {location?.pathname.includes(Path.Sources)
                        && (
                            <div className={styles.blockInfo}>
                                <SvgIcon id="InfoIcon" size={24} />
                                <span className={styles.blockInfo_text}>Данный раздел находится в разработке</span>
                            </div>
                        )}

                </div>
            </div>
            <UserPanel />
        </div>
    );
};

export default HeaderContainer;
