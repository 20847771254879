import { IChartPointsModel } from '../../services/campaigns/Charts/models';

export function createPointsFromCommons() {
    return false;
}

export class WordCloudChart {
    subjects: IChartPointsModel[];

    options: any = {};

    dataset:any = {
        borderWidth: 1,
        radius: '40%',
    };

    constructor(subjects: IChartPointsModel[]) {
        this.subjects = subjects;
    }

    getWords() {
        return this.subjects.map((item) => {
            const newItem = {
                value: +item.y[0],
                name: item?.subjectValues?.join(',') || 'Не определено',
            };
            return newItem;
        });
    }

    public create(type): any {
        // const data = getSettingsForData(listPiecesPie);
        return {
            typeChart: type,
            data: this.getWords(),
        };
    }
}
