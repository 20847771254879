import { FetchStatus, IDictionary } from 'store/types';

export const enum PDFReportTypes {
    Empty = 0,
    StandardMonitoring = 1,
}

export interface IPDFHandlerReportPage {
    id: number;
    /**
     * порядковый номер страницы в отчёте
     */
    sequentialNumber: number;
    body: JSX.Element;
}

export interface IPDFHandlerReportFile {
    name: string;
    content: File;
}

export interface IPDFHandlerReport {
    id: string;
    type: PDFReportTypes;
    name: string;
    timeFrame: string;
    chartsIds: number[];
    pages: IPDFHandlerReportPage[];
    file: IPDFHandlerReportFile | null;
    downloadUrl: string;
    created: string;
    campaignId: number;
    createTimeStamp: number;
    generated: boolean
}

export interface IPDFHandlerReportState {
    saveReportStatus: FetchStatus;
    loadReportStatus: FetchStatus;
    deleteReportStatus: FetchStatus;
    loadReportFileStatus: FetchStatus;
    fetchErrors: string;
    ids: string[];
    reports: IDictionary<IPDFHandlerReport> | null;
}
