import { ISimpleEntity } from '@store/types';
import SvgIcon from '@shared/ui-components/SvgIcon';
import sourcesConstants from '@app/constants/sources';

const keysIcon = [...sourcesConstants.platformIconsMap.keys()];

const getPlatformIconName = (item: ISimpleEntity): string => {
    const itemStr = item?.value?.toString();
    if (itemStr) {
        return keysIcon.find((key) => itemStr.includes(key));
    }
    return null;
};

const getPlatformIcon = (item: ISimpleEntity): JSX.Element | null => {
    if (item) {
        const keyMap = getPlatformIconName(item);
        return keyMap ? <SvgIcon id={sourcesConstants.platformIconsMap.get(keyMap)} size={24} /> : null;
    }
    return null;
};

export default {
    getPlatformIconName,
    getPlatformIcon,
};
