import { FetchStatus } from '../../types';
import { AppState } from '../../store';

const campaignState = (state: AppState) => state.campaign.source;
const campaigns = (state: AppState) => campaignState(state).campaigns;
const createBegun = (state: AppState) => campaignState(state).creatingBegun;
const modalAnswer = (state: AppState) => campaignState(state).modalAnswer;
const activeModal = (state: AppState) => campaignState(state).activeModal;
const needClearCampaignPage = (state: AppState) => campaignState(state).needClearCampaignPage;
const createStatus = (state: AppState) => campaignState(state).fetchCreateStatus;
const usersIsLoading = (state: AppState) => campaignState(state).fetchUsersStatus === FetchStatus.loading;
const userIds = (state: AppState) => campaignState(state).userIds;
const firstCampaignIdByUserId = (state: AppState, userId: string) => campaignState(state).campaigns.find(({ userId: id }) => id === userId).id;
const userById = (state: AppState, userId: string) => campaignState(state).users.find(({ userId: id }) => userId === id);
const currentCampaign = (state: AppState) => campaignState(state).campaign;
const fetchCampaignStatus = (state: AppState) => campaignState(state).fetchCampaignStatus;
const actionCampaign = (state: AppState) => campaignState(state).actionCampaign;
const updateCampaignStatus = (state: AppState) => campaignState(state).updateCampaignStatus;
const deleteCampaignStatus = (state: AppState) => campaignState(state).deleteCampaignStatus;
const processModalMessage = (state: AppState) => campaignState(state).processModalMessage;
const processModalStatus = (state: AppState) => campaignState(state).processModalStatus;
const singleCampaignUsers = (state: AppState) => campaignState(state).singleCampaignUsers;
const singleCampaignUsersStatus = (state: AppState) => campaignState(state).singleCampaignUsersStatus;
const addUserToCampaignStatus = (state: AppState) => campaignState(state).addUserStatus;
const singleCampaignOwner = (state: AppState) => campaignState(state).campaign?.owner;
const selectedCategories = (state: AppState) => campaignState(state).campaign?.categoriesIds;
const currentCampaignName = (state: AppState) => campaignState(state).campaign?.name;
const currentCampaignId = (state: AppState) => campaignState(state).campaign?.id;
const sources = (state: AppState) => campaignState(state).campaign?.sources;
const excludeSources = (state: AppState) => campaignState(state).campaign?.excludeSources;

export default {
    campaigns,
    createBegun,
    modalAnswer,
    activeModal,
    needClearCampaignPage,
    createStatus,
    userById,
    userIds,
    firstCampaignIdByUserId,
    usersIsLoading,
    currentCampaign,
    fetchCampaignStatus,
    actionCampaign,
    updateCampaignStatus,
    deleteCampaignStatus,
    processModalMessage,
    processModalStatus,
    singleCampaignUsers,
    singleCampaignUsersStatus,
    addUserToCampaignStatus,
    singleCampaignOwner,
    selectedCategories,
    currentCampaignName,
    currentCampaignId,
    sources,
    excludeSources,
};
