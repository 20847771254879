import { AppState, AppThunk } from 'store/store';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { getReportFile, saveReportWithFile } from '../../../deprecated/api/reports/reportsApi';
import { pdfHandlerReportActions } from '../report';
import pdfHandlerReportSelectors from '../report/selectors';
import pdfHandlerWrapperSelectors from '../wrapper/selectors';
import { pdfHandlerWrapperActions } from '../wrapper';

const savePdfReportWithFileThunk = (campaignId: number, reportId: string): AppThunk => (dispatch: Dispatch, getState: () => AppState): void => {
    if (!campaignId || Number.isNaN(campaignId) || !reportId) {
        dispatch(pdfHandlerReportActions.setFetchErrors(`не корректные campaignId: ${campaignId} и reportId: ${reportId}`));
        dispatch(pdfHandlerReportActions.failedSave());
        dispatch(pdfHandlerWrapperActions.failedCreateReport());
        return;
    }
    const report = pdfHandlerReportSelectors.reportById(getState(), reportId);
    const chartsIds = pdfHandlerWrapperSelectors.chartsIds(getState());

    if (
        !report?.file?.content || report?.file?.content.size <= 0
        || !report?.file?.name || !report?.name || !report?.timeFrame || !chartsIds?.length
    ) {
        dispatch(pdfHandlerReportActions.setFetchErrors(`не удалось получить отчёт id: ${reportId}`));
        dispatch(pdfHandlerReportActions.failedSave());
        dispatch(pdfHandlerWrapperActions.failedCreateReport());
    }

    dispatch(pdfHandlerReportActions.startSave());

    const data = new FormData();
    data.append('file', report.file.content);
    data.append('body', JSON.stringify({
        name: report.name,
        time_frame: report.timeFrame,
        chart_ids: chartsIds,
        filename: report.file.name,
    }));
    saveReportWithFile(campaignId, data)
        .then((responseReport: any) => {
            dispatch(pdfHandlerReportActions.successSave());
            const id = responseReport?.id;
            if (id) {
                dispatch(pdfHandlerReportActions.startLoadFile());
                getReportFile(campaignId, id)
                    .then((response: unknown) => {
                        dispatch(pdfHandlerWrapperActions.successCreateReport());
                        dispatch(pdfHandlerReportActions.successLoadFile());
                        dispatch(pdfHandlerReportActions.setDownloadUrlForReport({ reportId, response }));
                    })
                    .catch((error: AxiosError) => {
                        dispatch(pdfHandlerWrapperActions.failedCreateReport());
                        dispatch(pdfHandlerReportActions.failedLoadFile());
                        dispatch(pdfHandlerReportActions.setFetchErrors(error?.message));
                    });
            }
        })
        .catch((error: AxiosError) => {
            dispatch(pdfHandlerWrapperActions.failedCreateReport());
            dispatch(pdfHandlerReportActions.failedSave());
            dispatch(pdfHandlerReportActions.setFetchErrors(error?.message));
        });
};

export default savePdfReportWithFileThunk;
