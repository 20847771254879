import { ListCategory, IApplyFilter } from '../../../sections/MyCampaignContainer/CampaingTypes';

export const getListFilters = (data: ListCategory): IApplyFilter[] => {
    if (!data) return [];
    const listAcceptsFilters = data.reduce((acc, category) => {
        const listAccepts = category.filters.filter((filter) => filter.checked || filter.include);
        if (listAccepts.length) {
            const listApplyFilter: IApplyFilter[] = listAccepts.map((filter) => {
                const newFilter: IApplyFilter = {
                    filters_category: category.filters_category,
                    filter_name: filter.filter_name,
                };
                return newFilter;
            });
            return [...acc, ...listApplyFilter];
        }
        return acc;
    }, []);
    return listAcceptsFilters;
};
