import React from 'react';
import { TStatus } from '../types';
import styles from './styles.module.scss';

interface IPropsCompleteLoad {
    onChangeStatus: (newStatus: TStatus) => void,
}
const CompleteLoad: React.FC<IPropsCompleteLoad> = ({ onChangeStatus }): JSX.Element => (
    <div
        className={styles.blockLoadFile_root}
        onMouseEnter={() => onChangeStatus('default')}
    >
        <div className={styles.btnWithLabel}>
            <span className={styles.label}>Файл добавлен</span>
            <div style={{
                position: 'relative',
                display: 'block',
                flexGrow: 1,
            }}
            >
                <svg
                    className={styles.checkmark}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                >
                    <circle
                        className={styles.checkmark__circle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                    />
                    <path
                        className={styles.checkmark__check}
                        fill="none"
                        d="M12.1 27.2l12.1 9.2 16.7-16.8"
                    />
                </svg>
            </div>
        </div>
        <div className={styles.additionlInfo}>
            <span>успешно </span>
        </div>
    </div>
);

export default CompleteLoad;
