import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import {
    ICampaignSourceTypesBlockProps,
    ISourceTypesCheckboxesModel,
} from '@components/campaign/UpdateForm/Sources/SourceTypes/types';
import { LogoIconsIds } from '@app/constants/icons';
import SvgIcon from '@components/SvgIcon';
import CustomCheckbox from '@components/Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';
import ValidationTooltip from '@components/campaign/Validation';
import YouTubeLogo from '../../../../../assets/images/youTube.svg';

import styles from './styles.module.scss';

const initialSourceCheckboxes: ISourceTypesCheckboxesModel[] = [
    {
        id: '1',
        visible: true,
        name: 'Все источники',
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-source-all',
    },
    {
        id: '2',
        visible: true,
        name: 'СМИ',
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-source-smi',
    },
    {
        id: '3',
        visible: true,
        name: 'Блогеры',
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-source-blogger',
    },
];

const initialPlatformCheckboxes: ISourceTypesCheckboxesModel[] = [
    {
        id: '1',
        visible: true,
        name: 'Все платформы',
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-all',
    },
    {
        id: '2',
        visible: true,
        name: '',
        labelElement: (<SvgIcon id={LogoIconsIds.RssLogo} />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-rss',
    },
    {
        id: '3',
        visible: true,
        name: '',
        labelElement: (<SvgIcon id={LogoIconsIds.VkLogo} />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-vk',
    },
    {
        id: '4',
        visible: true,
        name: '',
        labelElement: (<SvgIcon id={LogoIconsIds.OkLogo} />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-ok',
    },
    {
        id: '5',
        visible: true,
        name: '',
        labelElement: (<SvgIcon id={LogoIconsIds.TgLogo} />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-tg',
    },
    {
        id: '6',
        visible: true,
        name: '',
        labelElement: (<SvgIcon id={LogoIconsIds.ZenLogo} />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-zen',
    },
    {
        id: '7',
        visible: true,
        name: '',
        labelElement: (<img src={YouTubeLogo} width={24} height={16} alt="YouTubeLogo" />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-youTube',
    },
    {
        id: '8',
        visible: true,
        name: '',
        labelElement: (<SvgIcon id={LogoIconsIds.RuTubeLogo} />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: 'campaign-platform-ruTube',
    },
    {
        id: '9',
        visible: true,
        name: '',
        labelElement: (<SvgIcon id={LogoIconsIds.VkVideo} />),
        checked: false,
        isRetinaSize: false,
        onChecked: null,
        tagId: '',
    },
];

const CampaignSourceTypes: React.FC<ICampaignSourceTypesBlockProps> = ({
    checkedBloggerIds, checkedSourceTypeIds,
    handlerSetBlogger, handlerSetSourceType,
    isErrorHighlighting, isView, isIPadSize,
}): JSX.Element => {
    const [
        sourceCheckboxes,
        setSourceCheckboxes,
    ] = useState<ISourceTypesCheckboxesModel[]>(initialSourceCheckboxes);
    const [
        platformCheckboxes,
        setPlatformCheckboxes,
    ] = useState<ISourceTypesCheckboxesModel[]>(initialPlatformCheckboxes);

    const sourceIsSelected = useMemo((): boolean => sourceCheckboxes
        .some(({ checked }) => checked), [sourceCheckboxes]);
    const platformIsSelected = useMemo((): boolean => platformCheckboxes
        .some(({ checked }) => checked), [platformCheckboxes]);

    const handlerSourceChecked = (id: string): void => {
        const current = sourceCheckboxes.find((item) => item.id === id);
        const allSource = sourceCheckboxes.find((item) => item.id === '1');
        const amountCheckedWithoutAll = sourceCheckboxes.filter((item) => item.id !== '1')?.length;
        if (current) {
            // если выбираем все источники, то автоматом ставим галки в остальных
            if (current.id === '1') {
                const buffer = [...sourceCheckboxes.map((item) => (
                    { ...item, checked: !current.checked }
                ))];
                setSourceCheckboxes(buffer);
                handlerSetBlogger(buffer.map((item) => ({ id: Number(item.id), checked: item.checked })));
                return;
            }
            // если выделен общий чекбокс и снята галка с любого другого - снимаем выделение с общего
            if (current.id !== '1') {
                if (allSource.checked) {
                    allSource.checked = false;
                } else if (!allSource.checked && amountCheckedWithoutAll === 1) {
                    allSource.checked = true;
                }
            }
            current.checked = !current.checked;
            setSourceCheckboxes([...sourceCheckboxes]);
            handlerSetBlogger(sourceCheckboxes.map((item) => ({ id: Number(item.id), checked: item.checked })));
        }
    };

    const handlerPlatformChecked = (id: string): void => {
        setPlatformCheckboxes((prevCheckboxes) => {
            let updatedCheckboxes;
            if (id === '1') { // если выбираем все источники, то автоматом ставим галки в остальных
                updatedCheckboxes = prevCheckboxes.map(
                    (checkbox) => ({ ...checkbox, checked: !prevCheckboxes[0].checked }),
                );
            } else {
                updatedCheckboxes = prevCheckboxes.map((checkbox) => (
                    checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
                ));

                const isAllChecked = updatedCheckboxes.slice(1).every((checkbox) => checkbox.checked);
                updatedCheckboxes[0] = { ...updatedCheckboxes[0], checked: isAllChecked };
            }
            handlerSetSourceType?.(updatedCheckboxes.map((item) => ({ id: Number(item.id), checked: item.checked })));
            return updatedCheckboxes;
        });
    };

    useEffect(() => {
        setPlatformCheckboxes(initialPlatformCheckboxes.map((item) => (
            { ...item, checked: checkedSourceTypeIds.includes(Number(item.id)) }
        )));
    }, [checkedSourceTypeIds]);
    useEffect(() => {
        setSourceCheckboxes(initialSourceCheckboxes.map((item) => (
            { ...item, checked: checkedBloggerIds.includes(Number(item.id)) }
        )));
    }, [checkedBloggerIds]);

    return (
        <div className={styles.root} style={isIPadSize ? { flexDirection: 'column', gap: 24 } : null}>
            <div className={classnames(styles.contentContainer, styles.trackedSourcesBlock)}>
                <ValidationTooltip
                    isVisible={isErrorHighlighting && !sourceIsSelected}
                    left={285}
                    top={75}
                    width={200}
                    text="Пожалуйста, выберите тип источника"
                >
                    <span className={styles.title}>
                        {`Типы отслеживаемых источников${isView ? '' : '*'}:`}
                    </span>
                </ValidationTooltip>
                <div className={styles.checkboxContainer}>
                    {
                        sourceCheckboxes.map((item) => (
                            <CustomCheckbox
                                key={item.id}
                                id={item.id}
                                tagId={item.tagId}
                                checked={item.checked}
                                isRetinaSize={false}
                                name={item.name}
                                onChecked={handlerSourceChecked}
                                textClassName={styles.checkboxLabel}
                                isError={isErrorHighlighting && !sourceIsSelected}
                                isDisabled={isView}
                            />
                        ))
                    }
                </div>
            </div>
            <div className={classnames(styles.contentContainer, styles.platformsBlock)}>
                <ValidationTooltip
                    isVisible={isErrorHighlighting && !platformIsSelected}
                    left={155}
                    top={75}
                    width={200}
                    text="Пожалуйста, выберите тип платформы"
                >
                    <span className={styles.title}>
                        {`Типы платформ${isView ? '' : '*'}:`}
                    </span>
                </ValidationTooltip>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={styles.checkboxContainer}>
                        {
                            platformCheckboxes.filter(({ id }) => ['1', '2', '3', '4', '5'].includes(id)).map((item) => (
                                <CustomCheckbox
                                    key={item.id}
                                    id={item.id}
                                    tagId={item.tagId}
                                    checked={item.checked}
                                    isRetinaSize={false}
                                    name={item.name}
                                    labelElement={item.labelElement}
                                    onChecked={handlerPlatformChecked}
                                    textClassName={styles.checkboxLabel}
                                    isError={isErrorHighlighting && !platformIsSelected}
                                    isDisabled={isView}
                                />
                            ))
                        }
                    </div>
                    <div style={{ width: '100%', paddingLeft: 168 }}>
                        <div className={styles.checkboxContainer}>
                            {
                                platformCheckboxes.filter(({ id }) => !['1', '2', '3', '4', '5'].includes(id)).map((item) => (
                                    <CustomCheckbox
                                        key={item.id}
                                        id={item.id}
                                        tagId={item.tagId}
                                        checked={item.checked}
                                        isRetinaSize={false}
                                        name={item.name}
                                        labelElement={item.labelElement}
                                        onChecked={handlerPlatformChecked}
                                        textClassName={styles.checkboxLabel}
                                        isError={isErrorHighlighting && !platformIsSelected}
                                        isDisabled={isView}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignSourceTypes;
