/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { IQueryParamsReportAggs } from '../../../services/campaigns/tracking/types';
import { trackingCampaingRtqService } from '../../../services/campaigns/tracking';

export const NEW_FORMAT_DATA = 'YYYY-MM-DDTHH:mm';
/**
*/
const useGetBackgroundInfo = (
    query: IQueryParamsReportAggs,
) => {
    // const now = trackingCampaingRtqService.useGetReportAggQuery(query);
    const getQueryForOld = useMemo(():IQueryParamsReportAggs => {
        const {
            from_date: fromDate,
            to_date: toDate,
        } = query;
        const nowFromDate = fromDate ? moment(fromDate) : null;
        const nowToDate = toDate ? moment(toDate) : null;
        if (!fromDate || !toDate) return null;
        const diff = Math.abs(moment.duration(nowToDate.diff(nowFromDate)).asDays()) + 1;
        const oldFromDate = nowFromDate.clone().subtract(diff, 'd');
        const oldToDate = nowToDate.clone().subtract(diff, 'd');
        return { ...query, from_date: oldFromDate.format(NEW_FORMAT_DATA), to_date: oldToDate.format(NEW_FORMAT_DATA) };
    }, [query]);
    const isSkipOldData = useMemo(():boolean => !(query?.from_date && query?.to_date), [query]);
    // const old = trackingCampaingRtqService.useGetReportAggQuery(getQueryForOld, { skip: isSkipOldData && !getQueryForOld });
    return {
        now: trackingCampaingRtqService.useGetReportAggQuery(query),
        old: trackingCampaingRtqService.useGetReportAggQuery(getQueryForOld, { skip: isSkipOldData && !getQueryForOld }),
    };
};

export default useGetBackgroundInfo;
