import React, { useCallback, useMemo, useRef } from 'react';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import classnames from 'classnames';
import { Switch } from 'antd';
import { useAppDispatch } from '@store/hooks';
import { tablePropertiesAction } from '@store/common/tableProperties';
import { IColumnProperties } from '@store/common/tableProperties/types';
import styles from './styles.module.scss';

interface IProps {
    selectedColumns: IColumnProperties[],
    setSelectedColumns: (list: IColumnProperties[]) => void;
    onVisible: (value: boolean) => void;
}
const ColumnsViewer: React.FC<IProps> = ({
    selectedColumns,
    setSelectedColumns,
    onVisible,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const modalRef = useRef(null);

    const visibleColumnAmount = useMemo<number>(() => (
        selectedColumns.filter(({ visible }) => visible)?.length ?? 0
    ), [selectedColumns]);

    const setListSelectedColumns = useCallback((name: string) => {
        const column = selectedColumns.find((item) => item.name === name);
        // проверка на минимальное кол-во видимых колонок
        if (column.visible && visibleColumnAmount < 2) {
            return;
        }
        dispatch(tablePropertiesAction.setVisibilityColumnMediaPlan({
            name, visible: !column.visible, id: column.id,
        }));
        setSelectedColumns(selectedColumns.map((item) => (
            { ...item, visible: item.name === name ? !item.visible : item.visible } as IColumnProperties
        )));
    }, [visibleColumnAmount, selectedColumns]);
    useOnClickOutside(modalRef, () => onVisible(false));
    return (
        <div ref={modalRef} className={classnames(styles.selectColumns, styles.main)}>
            <div className={styles.list}>
                {
                    selectedColumns.map((item) => (
                        <span
                            key={item.name}
                            className={styles.controlBlock}
                        >
                            <Switch
                                size="small"
                                checked={item.visible}
                                onChange={() => setListSelectedColumns(item.name)}
                                style={{
                                    transform: 'rotate(180deg)',
                                    background: !item.visible ? '#787E93' : null,
                                }}
                            />
                            <button
                                type="button"
                                className={styles.text}
                                onClick={() => setListSelectedColumns(item.name)}
                            >
                                {item.title ?? item.name}
                            </button>
                        </span>
                    ))
                }
            </div>
        </div>
    );
};

export default ColumnsViewer;
