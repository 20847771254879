import {
    INumbersInterval, INumbersIntervalApi, SortOrder, TStackDirection,
} from '@services/sources/types/models';

export type Filter = {
    [key: string]: (string | number)[];
};

export type RangeFilter = {
    [key: string]: INumbersInterval;
};

export interface IQPSourcesWithSortingAndFiltering {
    list_id?: number,
    page: number;
    limit: number;
    orderBy: string;
    orderDirection: SortOrder;
    filterParams: Filter | RangeFilter;
    rangeParams: RangeFilter;
    nextPageToken?: string;
    prevPageToken?: string;
    searchValue?: string;
}

export interface IQPAddSource {
    tag: string;
    title: string;
    parseLink: string;
    link: string;
    enabled: boolean;
    typeId: number;
    categories: number[];
}

export interface IQPLoadSourcePosts {
    sourceId: number;
    limit: number;
    paginationToken?: string;
    columnName?: IQPLoadSourcePostsColumn;
    direction?: SortOrder;
    dateDilter?: {
        from_date: string;
        to_date: string;
    };
    viewsFilter?: {
        from_: number,
        to_: number
    },
}

export interface IQPAuthors {
    direction: TStackDirection;
    limit: number;
    value: string;
    orderDirection: string;
    title: string;
}

export enum IQPLoadSourcePostsColumn {
    likes = 'likes',
    postId = 'post_id',
    views = 'views',
    comments = 'comments',
    forwards = 'forwards',
}

export interface IQPSaveAdvancedFilters {
    title: string[];
    author_id: number[];
    type_id: number[];
    category_id: number[];
    subscribers: number;
    er_avg: INumbersIntervalApi[];
    views_avg: INumbersIntervalApi[];
    id: number;
    project_id: number;
}

export interface IQPCreateSourceList {
    title: string;
    description: string;
    isFavorite?: boolean;
    sourceId?: number;
}

export interface IQPAddSourcesToLists {
    listIds: number[];
    sourceIds: number[] | null[];
}

export interface IAddSourceToListsQuery {
    list_ids: number[];
    source_ids: number[];
}
