import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import campaignUtils from '../../../utils/campaign';
import { ITrackedSourceModel } from '../tracked-sources/types';
import { IUserInfo } from '../../../services/common/types';

const convertToObjectCampaign = (item: unknown): ICampaignDataModel => {
    const result = {} as ICampaignDataModel;
    Object.keys(item).forEach((key: string) => {
        switch (key) {
            case 'id':
                result.id = item[key];
                break;
            case 'name':
                result.name = item[key];
                break;
            case 'parse_from':
                if (item[key]) {
                    result.parseFromDate = new Date(item[key]);
                    result.parseFrom = result.parseFromDate.toLocaleDateString('ru-RU');
                }
                break;
            case 'create_time':
                result.createTimestamp = item[key];
                result.createDate = new Date(result.createTimestamp).toLocaleDateString('ru-RU');
                break;
            case 'owner_id':
                result.userId = item[key];
                break;
            case 'isActive':
                result.isActive = item[key];
                break;
            case 'status':
                result.isActive = campaignUtils.campaignStatusToBool(item[key]?.name);
                break;
            default:
                break;
        }
    });
    return result;
};

export const convertDataApiToCampaign = (data: unknown): ICampaignDataModel[] => {
    const result: ICampaignDataModel[] = [];
    if (!data) {
        return result;
    }

    if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
            if (item) {
                result.push(convertToObjectCampaign(item));
            }
        });
    }

    return result;
};

const convertUserApiToObjectModel = (item: unknown): IUserInfo => {
    const result = {} as IUserInfo;
    Object.keys(item).forEach((key: string) => {
        switch (key) {
            case 'user_id':
                result.userId = item[key];
                break;
            case 'email':
                result.email = item[key];
                break;
            case 'first_name':
                result.firstName = item[key];
                break;
            case 'last_name':
                result.lastName = item[key];
                break;
            case 'middle_name':
                result.middleName = item[key];
                break;
            case 'role':
                if (item[key]?.name) {
                    result.owner = item[key]?.name === 'owner';
                }
                break;
            default:
                break;
        }
    });
    return result;
};

export const convertDataApiToUser = (data: unknown): IUserInfo | null => {
    if (!data) {
        return null;
    }
    return Array.isArray(data) && data.length > 0 ? convertUserApiToObjectModel(data[0]) : convertDataApiToUser(data);
};

export const convertDataApiToArrayUser = (data: unknown): IUserInfo[] | null => {
    if (!data) {
        return null;
    }
    const result: IUserInfo[] = [];
    if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
            result.push(convertUserApiToObjectModel(item));
        });
    } else {
        result.push(convertDataApiToUser(data));
    }
    return result;
};

export const convertFromSingleCampaignApi = (data: unknown): ICampaignDataModel | null => {
    if (!data) {
        return null;
    }
    const buffer = Array.isArray(data) && data.length > 0 ? data[0] : data;
    const result = {} as ICampaignDataModel;
    Object.keys(buffer).forEach((key: string) => {
        switch (key) {
            case 'id':
                result.id = buffer[key];
                break;
            case 'name':
                result.name = buffer[key];
                break;
            case 'parse_from':
                result.parseFromDate = new Date(buffer[key]);
                result.parseFrom = result.parseFromDate.toLocaleDateString('ru-RU');
                break;
            case 'status':
                result.isActive = campaignUtils.campaignStatusToBool(buffer[key]?.name);
                break;
            case 'create_time':
                result.createTimestamp = buffer[key];
                result.createDate = result.createTimestamp.split('T')[0] ?? '';
                break;
            case 'criteria':
                Object.keys(buffer[key]).forEach((subKey) => {
                    switch (subKey) {
                        case 'source_type':
                            result.sourceType = buffer[key][subKey];
                            break;
                        case 'categories':
                            result.categoriesIds = buffer[key][subKey];
                            break;
                        case 'is_blogger':
                            result.isBlogger = buffer[key][subKey];
                            break;
                        // case 'source_links':
                        //     result.includeLinks = buffer[key][subKey];
                        //     break;
                        case 'exclude_words':
                            result.excludeWords = buffer[key][subKey];
                            break;
                        case 'include_words':
                            result.includeWords = buffer[key][subKey];
                            break;
                        case 'sources':
                        case 'exclude_sources':
                            if (!buffer[key][subKey]) {
                                break;
                            }
                            result[
                                subKey === 'sources' ? 'sources' : 'excludeSources'
                            ] = Object.keys(buffer[key][subKey]).map((sourcesKey) => (
                                {
                                    id: buffer[key][subKey][sourcesKey]?.id,
                                    allowInRussia: buffer[key][subKey][sourcesKey]?.allow_in_russia,
                                    authorId: buffer[key][subKey][sourcesKey]?.author_id,
                                    autoDiscover: buffer[key][subKey][sourcesKey]?.auto_discover,
                                    created: buffer[key][subKey][sourcesKey]?.created,
                                    enabled: buffer[key][subKey][sourcesKey]?.enabled,
                                    link: buffer[key][subKey][sourcesKey]?.link,
                                    locationId: buffer[key][subKey][sourcesKey]?.location_id,
                                    outSourceId: buffer[key][subKey][sourcesKey]?.out_source_id,
                                    parseLink: buffer[key][subKey][sourcesKey]?.parse_link,
                                    parsed: buffer[key][subKey][sourcesKey]?.parsed,
                                    tag: buffer[key][subKey][sourcesKey]?.tag,
                                    title: buffer[key][subKey][sourcesKey]?.title,
                                    typeId: buffer[key][subKey][sourcesKey]?.type_id,
                                } as ITrackedSourceModel
                            ));
                            break;
                        default:
                            break;
                    }
                });
                break;
            default:
                break;
        }
    });
    return result;
};
