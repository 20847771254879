import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import useResizeObserver from 'use-resize-observer';
import SearchPhraseParametersContextMenu from '../../ContextMenu/PhraseParameters';
import SearchLogicOperatorContextMenu from '../../ContextMenu/LogicOperator';
import { IPhraseParameters, PhraseWordLogicOperatorType } from '../../types';
import CrossIcon from '../../imgs/cross.svg';
import VerticalDotsIcon from '../../imgs/verticalDots.svg';
import VerticalLineIcon from '../../imgs/verticalLine.svg';
import helpers from '../../helpers';
import CustomCheckbox from '../../../../Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';
import styles from './styles.module.scss';

interface IProps {
    isView: boolean;
    isPreview: boolean;
    isLoading: boolean;
    isLastTag: boolean;
    index: number;
    onlyOrderOperators: boolean;
    withoutOrderOperators: boolean;
    parameters: IPhraseParameters;
    logicOperator: PhraseWordLogicOperatorType | null;
    onChangeLogicOperator: (phraseIndex: number, operatorIndex: PhraseWordLogicOperatorType) => void;
    onChangeParameters: (phraseIndex: number, value: IPhraseParameters) => void;
    onDeletePhrase: (index: number) => void;
    onDeleteLogicOperator: (index: number) => void;
    previewChecked: boolean;
    onCheckedPreview: (phraseIndex: number, value: boolean) => void;
}

const contextMenuOffset = 10;

const SearchWordPhrase: React.FC<IProps> = ({
    index,
    isLoading,
    isLastTag,
    onlyOrderOperators,
    withoutOrderOperators,
    parameters,
    logicOperator,
    onChangeLogicOperator,
    onChangeParameters,
    onDeletePhrase,
    onDeleteLogicOperator,
    isView,
    isPreview,
    previewChecked,
    onCheckedPreview,
}): JSX.Element => {
    const { ref: logicOperatorRef, width: logicOperatorWidth } = useResizeObserver<HTMLDivElement>();
    const { ref: tagRef, width: tagWidth } = useResizeObserver<HTMLDivElement>();

    const [logicMenuIsOpen, setOpenForLogicMenu] = useState<boolean>(false);
    const [inputPhraseIsOpen, setOpenInputPhrase] = useState<boolean>(false);

    const handlerOpenLogicContext = (): void => {
        setOpenForLogicMenu(true);
    };
    const handlerChangeLogicOperator = (operatorIndex: number): void => {
        if (onChangeLogicOperator) {
            onChangeLogicOperator(index, operatorIndex);
        }
        setOpenForLogicMenu(false);
    };
    const handlerOpenInputPhrase = (): void => {
        setOpenInputPhrase(true);
    };
    const handlerChangeParameters = (value: IPhraseParameters): void => {
        if (onChangeParameters) {
            onChangeParameters(index, value);
        }
        setOpenInputPhrase(false);
    };
    const handlerDeletePhrase = (): void => {
        if (onDeletePhrase) {
            onDeletePhrase(index);
        }
    };
    const handlerDeleteLogicOperator = (): void => {
        if (onDeleteLogicOperator) {
            onDeleteLogicOperator(index);
        }
    };
    const handlerCloseWordPhraseItemMenu = (): void => {
        setOpenInputPhrase(false);
    };
    const handlerCloseLogicMenu = (): void => {
        setOpenForLogicMenu(false);
    };
    const handlerCheckedPreview = (phraseIndex: number, value: boolean): void => {
        if (onCheckedPreview) {
            onCheckedPreview(phraseIndex, value);
        }
    };

    if (isLoading) {
        return <Skeleton height={40} width={60} />;
    }

    return (
        <div className={styles.phrase_container}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                {
                    isPreview && (
                        <CustomCheckbox
                            id={`checkbox-phrase-preview-${index}`}
                            style={{ gap: 0 }}
                            isDisabled={isView}
                            textClassName={styles.checkboxText}
                            name=""
                            checked={previewChecked}
                            onChecked={() => handlerCheckedPreview(index, !previewChecked)}
                            isRetinaSize={false}
                        />
                    )
                }
                <div
                    className={(previewChecked && isPreview) ? styles.phrase_previewTag : styles.phrase_tag}
                    style={{ position: 'relative' }}
                    ref={tagRef}
                >
                    <span className={styles.phrase_text}>{parameters?.value ?? ''}</span>
                    {
                        !isView && (
                            <button
                                className={`${styles.btn} ${styles.flexRow}`}
                                type="button"
                                onClick={handlerDeletePhrase}
                            >
                                <img src={CrossIcon} alt="CrossIcon" />
                            </button>
                        )
                    }
                    <img src={VerticalLineIcon} alt="VerticalLineIcon" />
                    <button
                        className={`${styles.btn} ${styles.flexRow}`}
                        type="button"
                        onClick={handlerOpenInputPhrase}
                    >
                        <img src={VerticalDotsIcon} alt="VerticalDotsIcon" />
                    </button>
                    <SearchPhraseParametersContextMenu
                        isView={isView}
                        top={0}
                        left={tagWidth + contextMenuOffset}
                        isOpen={inputPhraseIsOpen}
                        parameters={parameters}
                        onChangeParameters={handlerChangeParameters}
                        onClose={handlerCloseWordPhraseItemMenu}
                    />
                </div>
            </div>
            {
                logicOperator !== null && (
                    <div
                        className={(previewChecked && isPreview) ? styles.phrase_previewTag : styles.phrase_tag}
                        style={{ position: 'relative' }}
                        ref={logicOperatorRef}
                    >
                        <span className={styles.phrase_text}>
                            {helpers.getNameForLogicOperator(logicOperator)}
                        </span>
                        {
                            isLastTag && !isView && (
                                <>
                                    <button
                                        className={`${styles.btn} ${styles.flexRow}`}
                                        type="button"
                                        onClick={handlerDeleteLogicOperator}
                                    >
                                        <img src={CrossIcon} alt="CrossIcon" />
                                    </button>
                                    <img src={VerticalLineIcon} alt="VerticalLineIcon" />
                                </>
                            )
                        }
                        {
                            !isView && (
                                <button
                                    className={`${styles.btn} ${styles.flexRow}`}
                                    type="button"
                                    onClick={handlerOpenLogicContext}
                                >
                                    <img src={VerticalDotsIcon} alt="VerticalDotsIcon" />
                                </button>
                            )
                        }
                        <SearchLogicOperatorContextMenu
                            isView={isView}
                            top={0}
                            left={logicOperatorWidth + contextMenuOffset}
                            onlyOrderOperators={onlyOrderOperators}
                            withoutOrderOperators={withoutOrderOperators}
                            isOpen={logicMenuIsOpen}
                            onSelectItem={handlerChangeLogicOperator}
                            onClose={handlerCloseLogicMenu}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default SearchWordPhrase;
