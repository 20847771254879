import { IDocument } from '@components/Project/types';
import {
    ICreateProjectQueryParams, IDownloadDocumentModel,
    IProjectModel,
    IQueryParamsChangeDocument,
    IQueryParamsSaveDocument,
} from '@services/projects/types';
import { rtkQApi } from '../..';
import { getListDocuments } from '../transformers/getListDocuments';

export const projectsListService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getAllProjects: build.query<IProjectModel[], void>({
            query: () => ({
                url: '/media_projects/project/',
                method: 'GET',
            }),
            providesTags: ['ProjectsList'],
        }),
        createProject: build.query<IProjectModel, ICreateProjectQueryParams>({
            query: (body: ICreateProjectQueryParams) => ({
                url: '/media_projects/project/',
                method: 'POST',
                body,
            }),
            providesTags: ['ProjectsList'],
        }),
        removeProject: build.query<any, { id: number }>({
            query: ({ id }) => ({
                url: `/media_projects/project/${id}`,
                method: 'DELETE',
            }),
        }),
        saveDocument: build.mutation<any, IQueryParamsSaveDocument>({
            query: ({ file, type_id, project_id }) => {
                const body = new FormData();
                body.append('file', file);
                body.append(
                    'body',
                    JSON.stringify({
                        type_id,
                        project_id,
                    }),
                );
                return {
                    url: '/media_projects/file/',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['LoadDocument', 'ProjectStatus'],
        }),
        removeDocument: build.mutation<void, { url: string }>({
            query: ({ url }) => ({
                url: `/media_projects/file/${url}`,
                method: 'DELETE',
            }),
            // invalidatesTags: ['DocumentList'],
        }),
        // TODO Projects
        changeDocument: build.mutation<void, IQueryParamsChangeDocument>({
            query: ({ id, body }) => ({
                url: `/media_projects/file/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['DocumentList'],
        }),
        downloadDocument: build.mutation<IDownloadDocumentModel, { url: string }>({
            query: ({ url }) => ({
                url: `/media_projects/file/${url}`,
                method: 'GET',
            }),
            invalidatesTags: ['DownloadDocument'],
        }),
        getListFiles: build.query<IDocument[], { id: number }>({
            query: ({ id }) => ({
                url: `/media_projects/file/?project_id=${+id}`,
                method: 'GET',
            }),
            transformResponse: getListDocuments,
            providesTags: ['DocumentList'],
        }),
    }),
});
