/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import { Collapse } from 'antd';
import React, {
    useRef,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container } from './Container';

const SortableDemo = () => {
    const chartRef = useRef(null);
    return (
        <DndProvider backend={HTML5Backend}>
            <Container />
        </DndProvider>
    );
};
export default SortableDemo;
