/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { TTonality } from '../../../../widgets/campaign/tracking/TonalityChartContainer/types';
import styles from './styles.module.scss';

interface IPropsSort {
    isShowOld: boolean,
    now: any,
    old: any,
    positive: boolean,
    neutral: boolean,
    negative: boolean,
    oldPositive: boolean,
    oldNeutral: boolean,
    oldNegative: boolean,
    onChangeShowData: (tonality: TTonality) => void
}

const TonalityChartControls: React.FC<IPropsSort> = ({
    isShowOld,
    now,
    old,
    positive,
    neutral,
    negative,
    oldPositive,
    oldNeutral,
    oldNegative,
    onChangeShowData,
}): JSX.Element => {
    const getStringWithDates = (points): string => {
        const firstDate = moment(points[0]?.x).format('DD.MM.YYYY');
        const lastDate = moment(points.at(-1)?.x).format('DD.MM.YYYY');
        return `${lastDate} - ${firstDate}`;
    };
    const getNowDates = useMemo(() => {
        if (!isShowOld) return '';
        if (!now?.length) return '';
        return getStringWithDates(now);
    }, [now]);
    const getOldDates = useMemo(() => {
        if (!isShowOld) return '';
        if (!old?.length) return '';
        return getStringWithDates(old);
    }, [old]);
    const circle = (color) => (
        <div style={{
            background: color, width: '12px', height: '12px', borderRadius: '100%', marginLeft: '6px',
        }}
        />
    );
    const circleDashed = (color) => (
        <div style={{
            border: `2px dashed ${color}`, width: '12px', height: '12px', borderRadius: '100%', marginLeft: '6px',
        }}
        />
    );

    return (

        <div className={styles.filtersChart}>
            {
                isShowOld && getNowDates && (
                    <div style={{ marginRight: '16px' }}>
                        <div className={styles.checkboxWithLabel}>
                            <div className={styles.datesInfo}>{getNowDates}</div>
                        </div>
                        <div className={styles.checkboxWithLabel} style={{ marginBottom: '0px' }}>
                            <div className={styles.datesInfo}>{getOldDates}</div>
                        </div>
                    </div>
                )
            }
            <div className={styles.checkboxWithLabel} style={{ marginBottom: '0px' }}>
                <div className={styles.checkBoxContent}>
                    <div className={styles.checkboxWithLabel} style={{ color: '#0EBFA1' }}>
                        <Checkbox
                            id="tonality-chart-positive"
                            checked={positive}
                            onChange={(value) => onChangeShowData('positive')}
                        />
                        {circle('#0EBFA1')}
                        <div className={styles.labelCheckbox}>Позитивно</div>
                    </div>
                    {
                        isShowOld ? (
                            <div className={styles.checkboxWithLabel} style={{ color: '#0EBFA1', marginBottom: '0px' }}>
                                <Checkbox
                                    id="tonality-chart-negative-old"
                                    checked={oldPositive}
                                    onChange={(value) => onChangeShowData('old_positive')}
                                />
                                {circleDashed('#0EBFA1')}
                                <div className={styles.labelCheckbox}>Позитивно</div>
                            </div>
                        ) : ''
                    }

                </div>
            </div>
            <div className={styles.checkboxWithLabel} style={{ marginBottom: '0px' }}>
                <div className={styles.checkBoxContent}>
                    <div className={styles.checkboxWithLabel} style={{ color: '#E67E22' }}>
                        <Checkbox
                            id="tonality-chart-neutral"
                            checked={neutral}
                            onChange={(value) => onChangeShowData('neutral')}
                        />
                        {circle('#E67E22')}
                        <div className={styles.labelCheckbox}>Нейтрально</div>
                    </div>
                    { isShowOld ? (
                        <div className={styles.checkboxWithLabel} style={{ color: '#E67E22', marginBottom: '0px' }}>
                            <Checkbox
                                id="tonality-chart-negative-old"
                                checked={oldNeutral}
                                onChange={(value) => onChangeShowData('old_neutral')}
                            />
                            {circleDashed('#E67E22')}
                            <div className={styles.labelCheckbox}>Нейтрально</div>
                        </div>
                    ) : ''}
                </div>
            </div>
            <div className={styles.checkboxWithLabel} style={{ color: '#F36869', marginBottom: '0px' }}>
                <div className={styles.checkBoxContent}>
                    <div className={styles.checkboxWithLabel} style={{ color: '#F36869' }}>
                        <Checkbox
                            id="tonality-chart-negative"
                            checked={negative}
                            onChange={(value) => onChangeShowData('negative')}
                        />
                        {circle('#F36869')}
                        <div className={styles.labelCheckbox}>Негативно</div>
                    </div>
                    { isShowOld ? (
                        <div className={styles.checkboxWithLabel} style={{ color: '#F36869', marginBottom: '0px' }}>
                            <Checkbox
                                id="tonality-chart-negative-old"
                                checked={oldNegative}
                                onChange={(value) => onChangeShowData('old_negative')}
                            />
                            {circleDashed('#F36869')}
                            <div className={styles.labelCheckbox}>Негативно</div>
                        </div>
                    ) : '' }
                </div>
            </div>
        </div>
    );
};
export default React.memo(TonalityChartControls);
