import React from 'react';
import classnames from 'classnames';
import { IBloggerCommonContentProps } from './types';
import { NewsSourceType } from '../../../../containers/info-basket/subpages/results/ResultTable/types';
import CloseBtnIcon from '../../../../assets/images/modal-cross.svg';
import BloggerNoPhotoIcon from '../imgs/blogger-no-photo.svg';
import PersonAddIcon from '../../../../assets/images/person-add.svg';
import NewsIcon from '../../../../assets/images/news.svg';
import UpdateArrowsIcon from '../imgs/update-arrows.svg';
import TooltipIcon from '../imgs/tooltip-blogger.svg';
import { getBloggerMetricsTypeName } from '../../../../utils/commons/commonUtils';
import QuickSVGLoader from '../../../QuickSVGLoader';

import styles from './styles.module.scss';

const handleKeyDown = () => {
    // eslint-disable-next-line no-console
};
// todo spam on retina size
const BloggerProfileCommonContent: React.FC<IBloggerCommonContentProps> = ({
    data, setIsOpen, isLoading, isRetinaSize,
}): JSX.Element => (
    <div className={styles.root}>
        {
            isLoading && (
                <div className={styles.loaderContainer}>
                    <div style={{ width: '100%' }}>
                        <QuickSVGLoader />
                    </div>
                    <div className={styles.topContainer_closeCrossContainer} style={{ width: 20 }}>
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                        <img
                            src={CloseBtnIcon}
                            onClick={() => setIsOpen(false)}
                            onKeyDown={handleKeyDown}
                            alt="CloseBtnIcon"
                        />
                    </div>
                </div>
            )
        }
        {
            !isLoading && (
                <>
                    <div className={classnames(styles.topContainer, styles[isRetinaSize ? 'retina' : ''])}>
                        <div className={styles.topContainer_photo}>
                            {
                                data?.logoLink
                                    ? <img src={data?.logoLink} width={isRetinaSize ? 52 : 80} alt="BloggerPhoto" />
                                    : <img src={BloggerNoPhotoIcon} width={isRetinaSize ? 52 : 80} alt="BloggerPhoto" />
                            }
                        </div>
                        <div className={classnames(
                            styles.topContainer_bloggerName,
                            styles.bloggerNameText,
                            styles[isRetinaSize ? 'retina' : ''],
                        )}
                        >
                            {data?.name?.length > 27 ? `${data?.name.substring(0, 27)}...` : data?.name}
                        </div>
                        <div className={classnames(styles.topContainer_bloggerType, styles.bloggerTypeText)}>
                            {
                                data?.type === NewsSourceType.blogger
                                    ? <img src={PersonAddIcon} alt="PersonAddIcon" height="20" />
                                    : <img src={NewsIcon} alt="NewsIcon" height="20" />
                            }
                            {
                                data?.type === NewsSourceType.blogger
                                    ? 'Блогер'
                                    : 'СМИ'
                            }
                        </div>
                        <div
                            className={classnames(
                                styles.topContainer_bloggerContract,
                                styles.bloggerContractText,
                                styles[data?.isContract ? '' : 'noContract'],
                            )}
                        >
                            {
                                data?.isContract
                                    ? 'Контрактованный'
                                    : 'Не контрактованный'
                            }
                        </div>
                        <div
                            className={classnames(
                                styles.topContainer_bloggerActualDate,
                                styles.bloggerActualDateText,
                                styles[isRetinaSize ? 'retina' : ''],
                            )}
                        >
                            <img src={UpdateArrowsIcon} alt="UpdateArrowsIcon" />
                            <div className={classnames(
                                styles.actualDateTextContainer,
                                // styles[isRetinaSize ? 'retina' : ''],
                            )}
                            >
                                {
                                    `Данные обновлены по состоянию на ${data?.actualDate ?? '-'}`
                                }
                            </div>
                        </div>
                        <div className={styles.topContainer_closeCrossContainer}>
                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                            <img
                                src={CloseBtnIcon}
                                onClick={() => setIsOpen(false)}
                                onKeyDown={handleKeyDown}
                                alt="CloseBtnIcon"
                            />
                        </div>
                    </div>
                    <div className={classnames(styles.bottomContainer, styles[isRetinaSize ? 'retina' : ''])}>
                        {
                            data?.metrics?.length && (
                                data?.metrics.map((item) => (
                                    <div
                                        className={classnames(
                                            styles.bottomContainer_item,
                                            styles[isRetinaSize ? 'retina' : ''],
                                        )}
                                        key={item.type}
                                    >
                                        <div className={styles.bottomContainer_itemNumber}>
                                            <div className={classnames(
                                                styles.bottomContainer_itemNumberText,
                                                styles[isRetinaSize ? 'retina' : ''],
                                            )}
                                            >
                                                {item.value?.toLocaleString('ru-RU') ?? '-'}
                                            </div>
                                            <img
                                                src={TooltipIcon}
                                                alt="tooltip"
                                                width={isRetinaSize ? 12 : 14}
                                                height={isRetinaSize ? 12 : 14}
                                            />
                                        </div>
                                        <div className={classnames(
                                            styles.bottomContainer_itemText,
                                            styles[isRetinaSize ? 'retina' : ''],
                                        )}
                                        >
                                            {getBloggerMetricsTypeName(item.type)}
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </>
            )
        }
        <div className={styles.horizontalLine} />
    </div>
);

export default BloggerProfileCommonContent;
