/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from 'react';
import EventBusForChartGraph from '../../deprecated/pagesComponents/info-trace/PublicationChartGraph/EventBus';
import styles from './styles.module.scss';
import useGetDate from '../../shared/hooks/useFormatDate';
import CalendarIcon from './CalendarIcon.svg';
import vkIcon from './vk.svg';
import okIcon from './ok.svg';
import tgIcon from './tg.svg';
import rssIcon from './rss.svg';
import SourceIcon from './Source.svg';

const icons = {
    vk: vkIcon,
    ok: okIcon,
    tg: tgIcon,
    rss: rssIcon,
};
interface IPropsWrapperTooltip {
    isOpen?: boolean,
    x?: number,
    y?: number,
    clearTimerTooltip?: () => void
    hideTooltip?: () => void
}
const TooltipForTracing: React.FC<IPropsWrapperTooltip> = ({
    isOpen, x, y, clearTimerTooltip, hideTooltip, children,
}) => {
    const [dx, sdx] = useState(0);
    const [dy, sdy] = useState(0);
    const [dio, sdio] = useState(false);
    const [tooltip, setTooltip] = useState(null);
    const getLeft = useMemo(() => `${(x || dx) + 16}px`, [x, dx]);
    const getTop = useMemo(() => `${(y || dy) + 12}px`, [y, dy]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [posted, setPosted] = useGetDate(null, 'DD.MM.YYYY hh:mm');
    const handleShowTooltip = (nx, ny, no, data) => {
        sdx(nx);
        setTooltip(data?.content);
        sdy(ny);
        sdio(no);
        if (data?.content?.posted) setPosted(new Date(data?.content?.posted));
    };
    useEffect(() => {
        EventBusForChartGraph.on('setTooltip', handleShowTooltip);
        return () => {
            EventBusForChartGraph.off('setTooltip', handleShowTooltip);
        };
    }, []);
    const openNewTab = (link) => {
        window.open(link, '_blank');
    };
    const getSubscribers = useMemo(() => {
        if (!tooltip?.source_subscribers) return 'Данных пока нет';
        // eslint-disable-next-line no-unsafe-optional-chaining
        return `${tooltip?.source_subscribers.toLocaleString('ru-RU')} Подписчиков`;
    }, [tooltip?.source_subscribers]);
    const getTooltip = useMemo(() => (
        <div className={styles.container}>
            <div className={styles.date}>
                <img src={CalendarIcon} alt="icon" />
                <div style={{ marginLeft: '8px' }}>{posted.date}</div>
            </div>
            <div className={styles.title}>
                <div>
                    <img src={icons[tooltip?.platform]} alt="icon" />
                </div>
                <div className={styles.name}>{tooltip?.source}</div>
            </div>
            <div className={styles.countSubscribers}>
                {getSubscribers}
            </div>
            <div
                className={styles.link}
                onClick={() => openNewTab(tooltip?.link)}
            >
                <img src={SourceIcon} alt="icon" />
                <div style={{ marginLeft: '8px' }}>
                    Ссылка на пост
                </div>
            </div>
        </div>
    ), [tooltip]);
    if (!isOpen && !dio) return <div />;
    return (
        <div
            // onMouseMove={() => clearTimerTooltip()}
            // onMouseLeave={() => hideTooltip()}
            style={{
                zIndex: 2, position: 'absolute', left: getLeft, top: getTop,
            }}
        >
            {tooltip && getTooltip}
            {children}
        </div>
    );
};

export default TooltipForTracing;
