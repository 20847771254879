import React, { useMemo } from 'react';
import classnames from 'classnames';
import styles from './styles.module.css';
import { IPropsQuickLoader } from './types';

const QuickSVGLoader: React.FC<IPropsQuickLoader> = ({
    size = 'medium',
}): JSX.Element => {
    const sizeParserRadius = useMemo(() => {
        if (size === 'large') return '24';
        if (size === 'medium') return '10';
        if (size === 'small') return '5';
        return '';
    }, [size]);
    const sizeParserXY = useMemo(() => {
        if (size === 'large') return '21';
        if (size === 'medium') return '5.5';
        if (size === 'small') return '1';
        return '';
    }, [size]);
    const sizeClass = useMemo(() => {
        if (size === 'large') {
            return `${styles.large}`;
        }
        if (size === 'medium') {
            return `${styles.medium}`;
        }
        if (size === 'small') {
            return `${styles.small}`;
        }
        return '';
    }, [size]);

    return (
        <div className={styles.root}>
            <svg className={classnames(styles.svgContainer, sizeClass)}>
                <circle className={classnames(styles.circleContainer, sizeClass)} cx={sizeParserXY} cy={sizeParserXY} r={sizeParserRadius} />
            </svg>
        </div>
    );
};

export default QuickSVGLoader;
