import React, { useState } from 'react';
import { useParams } from 'react-router';
import SourcePageComponent from './component';
import { SourcePageTab } from './types';

const SourcePage: React.FC = (): React.ReactElement => {
    const { Id: sourceId } = useParams();
    const [tab, setTab] = useState<SourcePageTab>(SourcePageTab.info);

    const handleChangeTab = (value: SourcePageTab): void => {
        setTab(value);
    };

    return (
        <SourcePageComponent
            sourceId={Number(sourceId)}
            tab={tab}
            handleChangeTab={handleChangeTab}
        />
    );
};
export default SourcePage;
