import React, { useEffect, useMemo, useState } from 'react';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';
import MonitoringChartViews from '@features/project/MonitoringChartViews';
import MonitoringChartER from '@features/project/MonitoringChartER';
import { projectMediaPlanRtkQService } from '@services/projects/media-plan';
import { SvgIcon } from '@shared/index';
import { IconButtonWithLabel } from '@entities/index';
import { projectsResultsService } from '@services/projects/results';
import { message } from 'antd';
import styles from './styles.module.scss';

interface IPropsMonitoring {
    projectId: number,
}
const Monitoring: React.FC<IPropsMonitoring> = ({ projectId }): JSX.Element => {
    const [getMonitoring, { isError, data, isLoading }] = projectMediaPlanRtkQService.useLazyGetMonitoringQuery();
    const [downloadResults] = projectsResultsService.useLazyDownloadResultsQuery();
    const [isOpen, setOpen] = useState<boolean>(true);
    useEffect(() => {
        getMonitoring(projectId);
    }, [projectId]);
    const handleClickOnDownload = () => {
        if (!data) {
            message.error('Произошла ошибка');
            return;
        }
        downloadResults({
            campaign_id: data.campaign_id,
            from_date: data.start_date.split('T')[0],
            to_date: data.end_date.split('T')[0],
        });
    };
    const getTemplate = useMemo(() => {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        if (!isOpen) return <></>;
        return (
            <div>
                <div className={styles.content}>
                    <div className={styles.content_title}>График просмотров</div>
                    <MonitoringChartViews
                        projectId={projectId}
                        campaignId={data?.campaign_id}
                        from_date={data?.start_date}
                        end_date={data?.end_date}
                    />
                </div>
                <div className={styles.content}>
                    <div className={styles.content_title}>График вовлеченности(ER)</div>
                    <MonitoringChartER
                        projectId={projectId}
                        campaignId={data?.campaign_id}
                        from_date={data?.start_date}
                        end_date={data?.end_date}
                    />
                </div>
            </div>
        );
    }, [isError, isLoading, data, isOpen]);
    if (isLoading) return null;
    return (
        <DefaultWrapperProject style={{ marginTop: 16, marginBottom: 16 }}>
            <div className={styles.monitoring_root}>
                <div className={styles.header}>
                    <span className={styles.header_title}>Мониторинг</span>
                    <div className={styles.header_actions}>
                        {
                            !isOpen && (
                                <IconButtonWithLabel
                                    iconSize={16}
                                    iconId="Download"
                                    label="Скачать"
                                    onClick={handleClickOnDownload}
                                    labelStyle={{ color: '#0EBFA1' }}
                                />
                            )
                        }
                        <div
                            role="button"
                            className={`${styles.header_controller} ${isOpen ? styles.close : styles.open}`}
                            onClick={() => setOpen(!isOpen)}
                        >
                            <SvgIcon size={16} id="dropdownArrow" />
                        </div>
                    </div>
                </div>
                {getTemplate}
                {
                    isOpen && (
                        <div className={styles.monitoring_controls}>
                            <IconButtonWithLabel
                                iconSize={16}
                                iconId="Download"
                                label="Скачать"
                                onClick={handleClickOnDownload}
                                labelStyle={{ color: '#0EBFA1' }}
                            />
                        </div>
                    )
                }
            </div>
        </DefaultWrapperProject>
    );
};

export default Monitoring;
