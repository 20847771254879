import React from 'react';
import { ICampaignSwitcher } from 'components/campaign/UpdateForm/Switcher/types';
import classnames from 'classnames';

import styles from './styles.module.css';

const CampaignSwitcher: React.FC<ICampaignSwitcher> = ({
    isActive, isInverse, onSwitch, label, isDisabled,
}): JSX.Element => {
    const getActiveState = (): boolean => (isInverse ? !isActive : isActive);
    const handlerClick = (): void => {
        if (isDisabled) {
            return;
        }
        if (!getActiveState()) {
            onSwitch(!isActive);
        }
    };
    return (
        <div className={styles.root}>
            <button type="button" className={classnames(styles.outerCircle, styles[isDisabled ? 'disabled' : ''])} onClick={handlerClick}>
                <div className={classnames(styles.circle, styles[getActiveState() ? '' : 'disabled'])} />
            </button>
            <button type="button" className={classnames(styles.labelButton, styles[isDisabled ? 'disabled' : ''])} onClick={handlerClick}>
                <span className={classnames(styles.text)}>{label ?? ''}</span>
            </button>
        </div>
    );
};

export default CampaignSwitcher;
