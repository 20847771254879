import React, { useState } from 'react';
import { ICampaignComparisonList } from 'services/campaigns/comparison/types';
import BasketIcon from '../../assets/images/Comparison/basket.svg';
import ArrowIcon from '../../assets/images/Comparison/arrow.svg';
import { ISimplePeriod } from '../../store/campaign/comparison';
import styles from './styles.module.scss';

interface IProps {
    analysisId: number;
    index: number;
    comparisonData: ICampaignComparisonList
    onDeleteCampaignAnalysis: (id: number) => void;
    onStartComparison: (id: number, period: ISimplePeriod) => void;
    onNavigateToCampaign: (id: number) => void;
}

const getDate = (value: string): string => {
    if (!value) {
        return '';
    }
    const parts = value.split('-');
    if (parts?.length !== 3) {
        return '';
    }
    return `${parts[2]}.${parts[1]}.${parts[0]}`;
};

const CampaignComparisonsTableRow: React.FC<IProps> = ({
    analysisId,
    index,
    comparisonData,
    onDeleteCampaignAnalysis,
    onStartComparison,
    onNavigateToCampaign,
}): JSX.Element => {
    const [isActiveArrow, setIsActiveArrow] = useState<boolean>(false);
    return (
        <>
            <div className={styles.competitiveCampaignAnalysis_row}>
                <span className={styles.competitiveCampaignAnalysis_number}>{index}</span>
                <span
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => {}}
                    onClick={() => onStartComparison(
                        analysisId,
                        {
                            start: new Date(comparisonData?.start_date),
                            end: new Date(comparisonData?.end_date),
                        } as ISimplePeriod,
                    )}
                    className={styles.competitiveCampaignAnalysis_name}
                >
                    {comparisonData?.name}
                </span>
                <span className={styles.competitiveCampaignAnalysis_date}>{`${getDate(comparisonData?.start_date)} - ${getDate(comparisonData?.end_date)}`}</span>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => {}}
                    onClick={() => onDeleteCampaignAnalysis(analysisId)}
                >
                    <img src={BasketIcon} alt="Basket" style={{ cursor: 'pointer' }} />
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => {}}
                    onClick={() => setIsActiveArrow(!isActiveArrow)}
                    className={styles.competitiveCampaignAnalysis_controller}
                >
                    <div className={styles.competitiveCampaignAnalysis_arrow}>
                        <div className={isActiveArrow ? styles.competitiveCampaignAnalysis_verticalLine : null}>
                            <img
                                src={ArrowIcon}
                                style={isActiveArrow ? { transform: 'rotate(180deg)' } : null}
                                alt="ThinArrow"
                                className={styles.competitiveCampaignAnalysis_img}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isActiveArrow
                && (
                    <>
                        <div className={styles.competitiveCampaignAnalysis_row}>
                            <span className={styles.competitiveCampaignAnalysis_campaignLabel}>Эталонная кампания:</span>
                            <button
                                type="button"
                                className={styles.competitiveCampaignAnalysis_referenceCampaign}
                                onClick={() => onNavigateToCampaign(comparisonData?.campaign?.id ?? -1)}
                            >
                                {comparisonData?.campaign?.name ?? '-'}
                            </button>
                        </div>
                        <div className={styles.competitiveCampaignAnalysis_row}>
                            <span className={styles.competitiveCampaignAnalysis_campaignLabel}>Дополнительные кампании: </span>
                            <div className={styles.competitiveCampaignAnalysis_blockCampaigns}>
                                {
                                    comparisonData?.compared_campaigns?.map((item) => (
                                        <button
                                            key={item?.id}
                                            type="button"
                                            className={styles.competitiveCampaignAnalysis_campaign}
                                            onClick={() => onNavigateToCampaign(item?.id ?? -1)}
                                        >
                                            {item?.name}
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                )}
        </>
    );
};

export default CampaignComparisonsTableRow;
