import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ICampaignUserRightsUserInfo } from 'store/campaign/userRights/types';
import { campaignsListRtkQService } from '../../../../services/campaigns/campaigns-list';
import campaignSelectors from '../../../../store/campaign/source/selectors';
// import loadCampaignsIfNotExists from '../../../../store/campaign/source/thunks/load-campaigns';
// import { useAppDispatch } from '../../../../store/hooks';
import { campaignUsersRtkQService } from '../../../../services/campaigns/users';
import { CommonProcessStatus } from '../../../../store/types';
import CampaignUserRightsTableRow from './TableRow';
import CampaignUserDeleteModal from './UserDeleteModal';
import styles from './styles.module.scss';

interface IProps {
    handleClickOpenAddUserModal: (campaignId?: number) => void;
}

const CampaignUserRightsTable: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { handleClickOpenAddUserModal } = props;
    // const campaigns = useSelector(campaignSelectors.campaigns);
    // const dispatch = useAppDispatch();
    const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<ICampaignUserRightsUserInfo | null>(null);
    const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(
        null,
    );
    const [deleteStatus, setDeleteStatus] = useState<CommonProcessStatus>(
        CommonProcessStatus.undefined,
    );
    const addUserStatus = useSelector(campaignSelectors.addUserToCampaignStatus);

    const { data: campaigns, isLoading } = campaignsListRtkQService.useGetCampaignsQuery();
    const [deleteUserFromCampaign] = campaignUsersRtkQService.useLazyDeleteUserFromCampaignQuery();

    const handleClickDelete = (
        campaignId: number,
        user: ICampaignUserRightsUserInfo,
    ): void => {
        setSelectedUser(user);
        setSelectedCampaignId(campaignId);
        setOpenDeleteModal(true);
    };

    const handleClickDeleteConfirm = async (): Promise<void> => {
        setDeleteStatus(CommonProcessStatus.process);
        try {
            deleteUserFromCampaign({
                campaignId: selectedCampaignId,
                userId: selectedUser.userId,
            });
            setDeleteStatus(CommonProcessStatus.success);
        } catch (error) {
            setDeleteStatus(CommonProcessStatus.failed);
        }
    };

    const handleClickCloseDeleteModal = (): void => {
        setOpenDeleteModal(false);
        setDeleteStatus(CommonProcessStatus.undefined);
    };

    return (
        <>
            <div className={styles.table}>
                <div className={styles.header}>
                    <span>Наименование кампании</span>
                    <span>Тип прав</span>
                    <span>Дополнительно</span>
                    <span />
                </div>

                <div className={styles.body}>
                    {
                        !isLoading && campaigns?.length > 0 && campaigns.map((campaign, index) => (
                            <CampaignUserRightsTableRow
                                key={campaign.id}
                                campaign={campaign}
                                handleClickDelete={handleClickDelete}
                                handleClickOpenAddUserModal={handleClickOpenAddUserModal}
                                deleteStatus={deleteStatus}
                                addUserStatus={addUserStatus}
                                numbering={index + 1}
                            />
                        ))
                    }
                </div>
            </div>

            <CampaignUserDeleteModal
                messages={[
                    `Вы уверены, что хотите удалить пользователя ${selectedUser?.lastName} ?`,
                ]}
                isOpen={isOpenDeleteModal}
                onClose={handleClickCloseDeleteModal}
                onConfirm={handleClickDeleteConfirm}
                status={deleteStatus}
            />
        </>
    );
};
export default CampaignUserRightsTable;
