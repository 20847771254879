/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/naming-convention */

import React, { useCallback, useMemo, useState } from 'react';
import { chartsCampaingRtqService } from '../../services/campaigns/Charts';
import { IChart } from '../../containers/CampaignNewCharts/ChartTypes';
import { CreateDataset } from '../../utils/Charts/CreatePointsFromCommons';
import { IWithParamsCommonModel } from '../../services/campaigns/Charts/models';
import useDebounce from '../../shared/hooks/useDebounce';

// interface IChartItemProps {
//     chart: IChart;
//     onUpdateChart: (field, value) => void,
// }
const useChartSettings = ({
    chart, onUpdateChart,
}): any => {
    const [isOpen, setIsOpen] = useState<boolean>(() => chart?.isInitial);
    const [chartSettings, setChartSettings] = useState(null);
    const [name, setName] = useState(() => chart?.name || 'Новый график');
    const [oldData, setOldData] = useState<IChart>();
    const [showError, setShowError] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loader, setLoader] = useState(false);
    const [getChartData] = chartsCampaingRtqService.useLazyNewGetChartDataLzyQuery({
    });
    const getDataForChart = () => {
        setLoader(true);
        const formChart = {
            name: 'Новый график',
            time_frame: chart.time_frame,
            chart_type: chart.chart_type,
            campaign_id: chart.campaign_id,
            from_date: chart.from_date,
            to_date: chart.to_date,
            metrics: [
                chart?.metrics,
            ],
            dimensions: chart?.dimensions || [],
            filters_include: chart.filters_include || [],
        };
        if (isOpen) {
            getChartData(formChart as any).then((res) => {
                const testdata = (res as unknown as { data: IWithParamsCommonModel })?.data;
                if (testdata) {
                    const newDataset = new CreateDataset(testdata.data.slice(0, 30));
                    const newSettings = newDataset.create(chart.chart_type);
                    setChartSettings(newSettings);
                    setOldData(chart);
                } else {
                    setShowError(true);
                }
            })
                .finally(() => {
                    setLoader(false);
                });
        }
    };

    useDebounce(() => {
        if (chart) {
            getDataForChart();
        }
    }, 10, [chart]);
    useDebounce(() => {
        if (chart?.name !== name) { onUpdateChart('name', name); }
    }, 350, [name]);
    const test = useCallback(getDataForChart, []);
    return [{
        chartSettings,
        oldData,
        showError,
        loader,
        isOpen,
        name,
    }, {
        setIsOpen,
        setName,
        setChartSettings,
        getDataForChart: test,
        setShowError,
        setLoader,
    }];
};

export default useChartSettings;
