import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { campaignSearchRequestRtkQService } from '../../../services/campaigns/search-request';
import { useAppDispatch } from '../../../store/hooks';
import { campaignSearchRequestActions } from '../../../store/campaign/search-request';
import CampaignSearchRequest from '../../../components/campaign/SearchRequest';
import searchRequestHelpers from '../../../components/campaign/SearchRequest/helpers';
import SearchRequestPreviewModal from '../../../components/campaign/SearchRequest/Modal/Preview';
import {
    ISearchGroup,
} from '../../../components/campaign/SearchRequest/types';

interface IProps {
    isView: boolean,
    searchRequest: ISearchGroup,
    onSave: (searchString: string) => void;
}
const getRequestString = (data: string[]): string => {
    const result = [...data];
    // const result = data.reduce((acc, curr) => {
    //     if (acc[acc.length - 1] === '!') {
    //         return [...acc.slice(0, acc.length - 1), `!${curr}`];
    //     }
    //     return [...acc, ...curr.split(' ')];
    // }, [] as string[]);
    return result
        .join(' ')
        .replaceAll('( ', '(')
        .replaceAll('(" ', '("')
        .replaceAll(' )', ')')
        .replaceAll(' ")', '")')
        .replaceAll('! ', '!')
        .replaceAll('!!', '!');
};

const CampaignSearchRequestContainer: React.FC<IProps> = ({
    isView,
    searchRequest,
    onSave,
}): JSX.Element => {
    const { Id: campaignId } = useParams();
    const dispatch = useAppDispatch();

    const [
        loadPreviewData,
        {
            data: previewData,
            isLoading: loadingPreview,
        },
    ] = campaignSearchRequestRtkQService.useLazyPostLoadPreviewQuery();

    const [isPreview, setPreview] = useState<boolean>(false);
    const [previewIsOpen, setOpenPreview] = useState<boolean>(false);

    const campaignIdNumber = useMemo<number>(() => (
        Number.isNaN(Number(campaignId)) ? -1 : Number(campaignId)
    ), [campaignId]);

    const handlerSaveSearchRequest = (value: ISearchGroup): void => {
        if (!isView) {
            const searchString = getRequestString(searchRequestHelpers.requestStringObjToArray(value, false));
            dispatch(campaignSearchRequestActions.setSearchRequest({
                campaignId: campaignIdNumber,
                searchRequest: searchString,
            }));
            onSave(searchString);
        }
    };
    const handlerLoadPreview = (value: ISearchGroup): void => {
        if (!isView && isPreview) {
            const searchString = getRequestString(searchRequestHelpers.requestStringObjToArray(value, isPreview));
            loadPreviewData({ queryString: searchString });
            setOpenPreview(true);
        }
    };
    const handlerClosePreview = (): void => {
        if (isPreview && previewIsOpen) {
            setOpenPreview(false);
        }
    };

    return (
        <>
            <CampaignSearchRequest
                isView={isView}
                isPreview={isPreview}
                campaignId={campaignIdNumber}
                onSave={handlerSaveSearchRequest}
                searchRequest={searchRequest}
                onChangePreviewState={setPreview}
                onLoadPreview={handlerLoadPreview}
            />
            <SearchRequestPreviewModal
                isOpen={previewIsOpen}
                onClose={handlerClosePreview}
                isLoading={loadingPreview}
                data={previewData?.highlight ?? []}
            />
        </>
    );
};

export default CampaignSearchRequestContainer;
