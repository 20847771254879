import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Checkbox } from 'antd';
import Skeleton from 'react-loading-skeleton';
import NewChart from '../../../../NewChart';
import { chartsCampaingRtqService } from '../../../../../services/campaigns/Charts';
import { CreateDataset } from '../../../../../utils/Charts/CreatePointsFromCommons';
import styles from './styles.module.scss';

interface IPropsCampaignDnDTags {
    campaignId: number,
    authors: any
}
const OLD_PERIOD_FORMAT = 'YYYY-MM-DD';

const CampaignDnDLineChart: React.FC<IPropsCampaignDnDTags> = ({ campaignId, authors }): JSX.Element => {
    const [getChartData, { data, isLoading, isError }] = chartsCampaingRtqService.useNewGetChartDataMutation();
    const getAuthors = useMemo(() => {
        if (!authors?.length) return [];
        return authors.map((item) => ({
            filter_name: item.name,
            filters_category: 'Авторы',
        }));
    }, [authors]);
    const [chartSettings, setChartSettings] = useState(null);
    useEffect(() => {
        if (!data?.data) return;
        const dataset = new CreateDataset(data.data.slice(0, 30));
        setChartSettings(dataset.create('TIME_CHART'));
    }, [data]);
    useEffect(() => {
        if (!getAuthors.length) return;
        getChartData({
            name: 'LINE CHART DASHBOARD',
            time_frame: 'DAY',
            chart_type: 'TIME_CHART',
            campaign_id: campaignId,
            from_date: moment().subtract(15, 'd').format(OLD_PERIOD_FORMAT),
            to_date: moment().format(OLD_PERIOD_FORMAT),
            metrics: ['POSTS'],
            dimensions: ['AUTHORS'],
            filters_include: getAuthors,
        } as any);
    }, [campaignId, getAuthors]);
    const handleChangeShowDataset = (content) => {
        setChartSettings((prev) => {
            const newData = prev?.data?.datasets?.map((dataset) => {
                if (dataset.id === content?.dataset?.id) {
                    const newDataset = { ...dataset };
                    newDataset.hidden = !newDataset?.hidden;
                    return newDataset;
                }
                return dataset;
            });
            return {
                ...prev,
                data: { datasets: newData },
            };
        });
    };
    const getStatusCheckBox = (item) => {
        const currentId = item?.dataset?.id;
        const currentDataset = chartSettings?.data?.datasets?.find((elem) => elem?.id === currentId);
        if (currentDataset) return !currentDataset?.hidden;
        return false;
    };
    const getLabel = (labels) => {
        if (!labels || !labels?.length) return 'не определено';

        return (
            <div>
                {labels.map((item) => (
                    <div key={item}>
                        {item}
                    </div>
                ))}
            </div>
        );
    };
    if (!chartSettings?.data || isLoading) {
        return (
            <div style={{
                width: 812,
                height: 436,
                background: 'white',
                borderRadius: 6,
                position: 'relative',
                display: 'flex',
                padding: 12,
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <Skeleton height={436 - 24} width={812 - 28} />
            </div>
        );
    }
    if (isError) return null;
    if (isError) {
        return (
            <div style={{
                width: 812, height: 436, background: 'white', borderRadius: 6, padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                Произошла непредвиденная ошибка
            </div>
        );
    }
    return (
        <div style={{
            width: 812, height: 436, background: 'white', borderRadius: 6, position: 'relative', display: 'flex', padding: 12,
        }}
        >
            <div style={{ width: '100%' }}>
                {chartSettings && <NewChart dataFormat={chartSettings} />}
            </div>
            <div className={styles.listCheckBoxes}>
                {
                    chartSettings?.listCheckBoxes
                        && chartSettings?.listCheckBoxes.map((item) => (
                            <div className={styles.checkBoxItem} key={item?.dataset?.label}>
                                <Checkbox checked={getStatusCheckBox(item)} onChange={() => handleChangeShowDataset(item)} />
                                <div className={styles.label}>{getLabel(item?.labels)}</div>
                            </div>
                        ))
                }
            </div>
        </div>
    );
};

export default CampaignDnDLineChart;
