/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from '@shared/index';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IPropsModalChangeName {
    isOpen: boolean,
    setOpen: (value: null) => void,
    onChangeName: (newName: string) => void,
    name?: string,

}

const ModalChangeName: React.FC<IPropsModalChangeName> = ({
    isOpen = true,
    setOpen,
    onChangeName,
    name,
// eslint-disable-next-line arrow-body-style
}): JSX.Element => {
    const [newName, setName] = useState<string>(() => name);
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            onChangeName(newName);
        }
        if (event.key === 'Escape') {
            setOpen(null);
        }
    };
    useEffect(() => {
        setName(name?.split('.')[0]);
    }, [name]);
    if (!isOpen) return null;
    return (
        <div
            className={`${styles.root_ModalChangeName}`}
            onKeyDown={handleKeyDown}
        >
            <div className={styles.modalOverlay} onClick={() => setOpen(null)} />
            <div className={styles.modalContainer}>
                <div className={styles.btnClose} onClick={() => setOpen(null)}>
                    <SvgIcon id="Close" />
                </div>
                <div className={styles.modalTitle}>Переименование файла</div>
                <div className={styles.label}>Наименование</div>
                <input
                    type="text"
                    placeholder="Введите наименование"
                    className={styles.inputName}
                    value={newName}
                    onChange={(event) => setName(event.target.value.replaceAll('.', ''))}
                />
                <div className={styles.footer}>
                    <div className={styles.footerContent}>
                        <Button
                            text="Отменить"
                            type="secondary"
                            size="large"
                            onClick={() => setOpen(null)}
                        />
                        {/* <ButtonSecondary text="Отменить" onClick={() => setOpen(null)} style={{ width: 108, height: 48, marginRight: 16 }} /> */}
                        <Button
                            text="Продолжить"
                            type="default"
                            size="large"
                            onClick={() => onChangeName(newName)}
                        />
                        {/* <ButtonPrimary text="Продолжить" style={{ width: 132, height: 48 }} onClick={() => onChangeName(newName)} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalChangeName;
