import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Path from '../../shared/lib/paths';
import { authActions } from '../../store/auth';
import { useAppDispatch } from '../../store/hooks';
// import ArrowIcon from './imgs/arrow.svg';
// import PersonIcon from './imgs/person.svg';
// import BellIcon from './imgs/bell.svg';
// import StarIcon from './imgs/star.svg';
import QuestionIcon from '../../assets/images/question.svg';
import { selectUserProfileFetchData } from '../../store/user-profile/selectors';
import { usersRtkQService } from '../../services/common/users';

import styles from './styles.module.scss';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  min-width: 230px;
  max-width: 230px;
  padding-bottom: 6px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  margin-right: 24px;
`;

const PersonName = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #262626;
`;

const PersonPosition = styled(PersonName)`
  font-size: 12px;
  color: #787E93;
`;

const Content = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 32px;
  height: 32px;

  margin-right: 24px;
  
  cursor: pointer;
`;

const ItemText = styled.div`
  margin-left: 10px;
  
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: flex-end;

  color: #787E93;
`;

const MenuContainer = styled(Menu)`
    padding: 16px;
    border-radius: 6px;
`;

const feedbackTooltipText = 'Форма обратной связи';
// const userProfileTooltipText = 'Мой профиль';

const UserPanel: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        lastName, firstName, middleName,
    } = useSelector(selectUserProfileFetchData);

    const [getUserInfo, { isLoading }] = usersRtkQService.useLazyGetUserInfoQuery();

    const [modalIsOpen, setModalOpen] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>(`${lastName ?? ''} ${firstName ?? ''} ${middleName ?? ''}`.trim());

    const onClickFeedback = () => {
        if (!modalIsOpen) {
            setModalOpen(true);
        }
    };

    const onClickUserProfile = () => {
        navigate(Path.UserProfile);
    };

    const handleLogout: MenuProps['onClick'] = (event): void => {
        switch (event.key) {
            case '3':
                dispatch(authActions.removeAuth());
                navigate(Path.Auth);
                break;
            default:
                break;
        }
    };

    const menu = (
        <MenuContainer
            id="profile-logout"
            onClick={handleLogout}
            items={[
                {
                    label: <ItemText>Выход</ItemText>,
                    key: '3',
                    icon: <img src="/images/exit.svg" alt="exit" width="20" />,
                },
            ]}
        />
    );

    useEffect(() => {
        getUserInfo();
    }, []);
    useEffect(() => {
        if (!isLoading && !middleName && !firstName && !lastName) {
            getUserInfo();
        }
    }, [lastName, firstName, middleName, isLoading]);

    useEffect(() => {
        const name = `${lastName ?? ''} ${firstName ?? ''} ${middleName ?? ''}`.trim();
        setUserName(name);
    }, [lastName, firstName, middleName]);

    return (
        <Container>
            <Content id="common-feedback" onClick={onClickFeedback}>
                <div className={styles.tooltip} data-tooltip={feedbackTooltipText}>
                    <img src={QuestionIcon} alt="QuestionIcon" height="25" />
                </div>
            </Content>
            {/* <Content> */}
            {/*    <img src={StarIcon} alt="StarIcon" height="25" /> */}
            {/* </Content> */}
            {/* <Content> */}
            {/*    <img src={BellIcon} alt="BellIcon" height="25" /> */}
            {/* </Content> */}
            {/* <Content onClick={onClickUserProfile}> */}
            {/*    <div className={styles.tooltip} data-tooltip={userProfileTooltipText} style={{ width: 120 }}> */}
            {/*        <img src={PersonIcon} alt="PersonIcon" height="25" /> */}
            {/*    </div> */}
            {/* </Content> */}
            <Dropdown overlay={menu}>
                <Description id="common-profile" style={{ cursor: 'pointer' }} onClick={onClickUserProfile}>
                    <PersonName className={styles.userName}>{userName ?? '-'}</PersonName>
                    <PersonPosition>Медиааналитик</PersonPosition>
                </Description>
            </Dropdown>
            {/* <Dropdown overlay={menu}> */}
            {/*    <Content> */}
            {/*        <img src={ArrowIcon} alt="ArrowIcon" /> */}
            {/*    </Content> */}
            {/* </Dropdown> */}
        </Container>
    );
};

export default UserPanel;
