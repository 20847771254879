import React from 'react';
import Sprite from '../../assets/Sprite.svg';

type SvgIconProps = {
    id: string,
    size?: number,
    height?: number
};
const SvgIcon = ({ id, size = 24, height }: SvgIconProps) => (
    <svg className={`icon icon-${id}`} width={size} height={height ?? size}>
        <use xlinkHref={`${Sprite}#${id}`} />
    </svg>
);
export default SvgIcon;
