import React from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Path from '../../../shared/lib/paths';
import { selectRequestAccessStatus } from '../../../store/request-access/selectors';
import { EmailSendStatus } from '../../../store/types';
import BigPostalEnvelope from '../../../assets/images/big-postal-envelope.svg';

import styles from './styles.module.scss';

const FinishAccessForm: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const sendStatus = useSelector(selectRequestAccessStatus);

    const handlerClickBtn = (): void => {
        navigate(Path.Auth);
    };

    const prepareMessage = (): JSX.Element => {
        // eslint-disable-next-line default-case
        switch (sendStatus) {
            case EmailSendStatus.failed:
                return (
                    <div className={styles.errorMsg}>
                        В процессе отправки произошла ошибка. Повторите запрос
                    </div>
                );
            case EmailSendStatus.sent:
                return (
                    <div className={styles.successMsg}>
                        Ваша заявка успешна отправлена!
                    </div>
                );
            case EmailSendStatus.sending:
                return (
                    <div className={styles.infoMsg}>
                        Отправка ...
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.root}>
            <img src={BigPostalEnvelope} alt="email" />
            {prepareMessage()}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                id="request-ok"
                className={classnames(
                    styles.completeButton,
                    styles.completeButton_text,
                    styles[sendStatus === EmailSendStatus.sending ? 'disabled' : ''],
                )}
                onClick={handlerClickBtn}
            >
                ok
            </div>
        </div>
    );
};

export default FinishAccessForm;
