import React, { useState } from 'react';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { Button } from '@shared/index';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IProps {
    referenceCampaign: ICampaignDataModel;
    compareCampaigns: ICampaignDataModel[];
    onRemoveCampaign: (id: number) => void;
    onCancelCompare: () => void;
    onStartCompare: () => void;
}

const CampaignComparisonList: React.FC<IProps> = ({
    referenceCampaign,
    compareCampaigns,
    onRemoveCampaign,
    onCancelCompare,
    onStartCompare,
}): JSX.Element => {
    const [dateNow] = useState<string>((new Date().toLocaleDateString('ru-Ru')));
    const handlerRemoveCampaign = (id: number): void => {
        if (onRemoveCampaign) {
            onRemoveCampaign(id);
        }
    };
    return (
        <div className={styles.campaignCompareList_root}>
            <div className={styles.listContainer}>
                {
                    !!referenceCampaign && (
                        <>
                            <span className={styles.campaignTitle}>Эталонная кампания</span>
                            <div className={styles.campaignContainer}>
                                <div className={styles.campaignData}>
                                    <span className={styles.campaignData_name}>
                                        {
                                            referenceCampaign.name?.length > 20
                                                ? `${referenceCampaign.name.slice(0, 17)}...`
                                                : referenceCampaign.name
                                        }
                                    </span>
                                    <span className={styles.campaignData_dateContainer}>
                                        <span className={styles.campaignData_date}>
                                            {referenceCampaign.parseFrom}
                                        </span>
                                        <span className={styles.campaignData_date}>-</span>
                                        <span className={styles.campaignData_date}>
                                            {dateNow}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    compareCampaigns?.length > 0 && (
                        <div className={styles.compareList}>
                            {
                                compareCampaigns.map(({
                                    id, name, parseFrom,
                                }) => (
                                    <div key={id} className={styles.campaignContainer}>
                                        <div className={`${styles.campaignData} ${styles.campaignBoxColor}`}>
                                            <span className={styles.campaignData_name}>
                                                { name?.length > 20 ? `${name.slice(0, 17)}...` : name }
                                            </span>
                                            <span className={styles.campaignData_dateContainer}>
                                                <span className={styles.campaignData_date}>
                                                    {parseFrom}
                                                </span>
                                                <span className={styles.campaignData_date}>-</span>
                                                <span className={styles.campaignData_date}>
                                                    {dateNow}
                                                </span>
                                            </span>
                                        </div>
                                        <button type="button" style={{ cursor: 'pointer' }} onClick={() => handlerRemoveCampaign(id)}>
                                            <SvgIcon id="Cross" size={14} />
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <div className={styles.hintContainer}>
                <div className={styles.hintContainer_block}>
                    {compareCampaigns.length !== 4 && (
                        <div className={styles.hintContainer_textBox}>
                            <span
                                className={styles.hintContainer_text}
                            >
                                Теперь выберите дополнительно кампании к сравнению.
                            </span>
                            <span className={styles.hintContainer_text}>
                                Максимально:
                                {' '}
                                <b>5 кампаний</b>
                            </span>
                        </div>
                    )}
                    <div className={styles.controlContainer}>
                        <Button
                            text="Далее"
                            type="default"
                            size="medium"
                            disabled={!compareCampaigns?.length}
                            onClick={onStartCompare}
                        />
                        <Button
                            text="Отмена"
                            type="secondary"
                            size="medium"
                            onClick={onCancelCompare}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignComparisonList;
