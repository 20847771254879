import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQPSourcesWithSortingAndFiltering, SortOrder } from '@services/sources/types';

export interface ISourceListState {
    page: number,
    filters: IQPSourcesWithSortingAndFiltering | null;
}

const initialState: ISourceListState = {
    page: 1,
    filters: {
        page: 1,
        limit: 15,
        orderBy: 'subscribers',
        orderDirection: SortOrder.desc,
        filterParams: null,
        rangeParams: null,
    },
};

const { actions: sourceListActions, reducer } = createSlice({
    name: 'sourceList',
    initialState,
    reducers: {
        // setPage: (state: ISourceListState, { payload }: PayloadAction<number>): void => {
        //     state.page = payload;
        //     // state.filters = { ...state.filters, page: payload };
        // },
        setFilters: (state: ISourceListState, { payload }: PayloadAction<IQPSourcesWithSortingAndFiltering>): void => {
            state.filters = payload;
            state.page = payload.page;
        },
        clear: (state: ISourceListState): void => {
            state.page = initialState.page;
            state.filters = { ...initialState.filters };
        },
    },
});

export { sourceListActions };
export default reducer;
