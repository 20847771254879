import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { IProjectInfo } from '@services/projects/types';
import { ISourceModel } from '@services/sources/types';

export enum TableLocalStorageKeys {
    'MediaPlan' = 'MediaPlanSelectColumns',
    'AdvancedSearch' = 'AdvancedSearchHistory',
}

export enum FetchStatus {
    'undefined' = 0,
    'loading' = 1,
    'failed' = 2,
    'success' = 3,
    'process' = 4,
    'update' = 5,
}

export enum CommonProcessStatus {
    undefined = 0,
    process = 1,
    failed = 2,
    success = 3,
}

export enum EmailSendStatus {
    'undefined' = 0,
    'sending' = 1,
    'failed' = 2,
    'sent' = 3,
}

export enum CleanFieldState {
    'newText' = 0,
    'canBeCleaned' = 1,
}

export const enum MessageType {
    error = 0,
    info = 1,
    warning = 2,
}

export interface IMessage {
    description: string;
    type: MessageType;
}

export interface IDictionary<T> {
    [key: string]: T
}

export interface ISimpleEntity {
    id?: string | number;
    name: string;
    value: string | number;
}

export interface ICategory {
    id: string;
    mainCat: string;
    media: ISimpleEntity | null;
    bloggers: ISimpleEntity | null;
    selected?: boolean | null;
    isRetinaSize: boolean;
}

export interface IRegion {
    id: string;
    name: string;
    checked: boolean;
    visible?: boolean;
}

export interface IRegionState {
    entities: IDictionary<IRegion>,
    ids: string[],
    status: FetchStatus;
}

export interface ICategoryState {
    entities: IDictionary<ICategory>;
    ids: string[];
    status: FetchStatus;
}

export interface IPeriod {
    id: string;
    name: string;
    selectedName: string;
    isSelected: boolean;
}

export interface IPeriodState {
    entities: IDictionary<IPeriod>,
    ids: string[],
}

export interface IInfoBasketFiltersState {
    periods: IPeriod[];
    regions: IRegion[];
    category: ICategory[];
}

export interface ICurrentPageState {
    currentCampaign: ICampaignDataModel;
    currentSource: ISourceModel;
    currentProject: IProjectInfo;
}

export interface ISearchResultState {
    error: string;
    warning: string;
    errorTextAnalyze: string;
    warningTextAnalyze: string;
    info: string;
    isLoading: boolean;
    isTextAnalyzeLoading: boolean;
    isComponentProcessing: boolean;
    wasTextAnalyzeLaunched: boolean;
    wasTextAnalyzeLaunchedAndFinish: boolean;
    wasLaunched: boolean;
    textFieldCleanState: CleanFieldState;
}
