import React from 'react';
import { WordCloud } from '@ant-design/plots';

const ChartWordCloud: React.FC<any> = ({ data }) => {
    const config = {
        data,
        wordField: 'name',
        weightField: 'value',
        colorField: 'name',
        wordStyle: {
            fontFamily: 'Verdana',
            fontSize: [4, 56],
            rotation: -5,
        },
        random: () => 0.5,
    };

    return <WordCloud {...config as any} />;
};

export default ChartWordCloud;
