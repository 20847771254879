import React from 'react';
import classnames from 'classnames';
import styles from '../styles.module.scss';

interface ICampaignReportsNameFieldProps {
    reportName: string;
    setValue: (value: string) => void;
    isIPadSize: boolean;
}
const CampaignReportsNameField: React.FC<ICampaignReportsNameFieldProps> = ({
    reportName, setValue, isIPadSize,
}): JSX.Element => {
    const handlerChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event?.target?.value) {
            setValue(event.target.value);
        } else {
            setValue('');
        }
    };
    return (
        <div className={classnames(styles.flexColumn, styles.gap20)} style={isIPadSize ? { maxWidth: 360 } : null}>
            <div className={classnames(styles.flexRow, styles.gap20)}>
                <div className={classnames(styles.circle, styles.active)}>1</div>
                <span className={styles.label}>Название отчета</span>
            </div>
            <div className={classnames(styles.flexRow, styles.gap20)}>
                <div style={{ width: 24 }} />
                <div className={styles.inputContainer} style={isIPadSize ? { maxWidth: 360 } : null}>
                    <input
                        className={classnames(styles.input, styles.input_text)}
                        placeholder="Введите текст..."
                        value={reportName}
                        onChange={handlerChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default CampaignReportsNameField;
