import classnames from 'classnames';
import React from 'react';
import styles from './style.module.scss';

interface IProps {
    tagId: string | null;
    children: React.ReactNode;
    onClick: (value: number) => void;
    value: number;
    isActive?: boolean;
    disabled?: boolean;
    className?: string
}

const RadioButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        children, isActive, tagId, onClick, value, className,
    } = props;
    const map = new Map([
        ['4 часа', '4h'],
        ['8 часов', '8h'],
        ['сутки', '24h'],
        ['неделя', 'week'],
    ]);

    return (
        <button
            id={`${tagId}-${map.get(children?.toString())}`}
            onClick={() => !props.disabled && onClick(value)}
            className={classnames(styles.button, className, isActive ? styles.active : '', props.disabled ? styles.disabled : '')}
            type="button"
        >
            <div />
            <span>{children}</span>
        </button>
    );
};
export default RadioButton;
