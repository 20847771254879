import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFavoriteSourceModel } from 'services/campaigns/favorite-sources/types';

export interface IFavoriteSourcesState {
    data: IFavoriteSourceModel[];
}

const initialState: IFavoriteSourcesState = {
    data: [],
};

const { actions: favoriteSourcesActions, reducer } = createSlice({
    name: 'favoriteSources',
    initialState,
    reducers: {
        setFavoriteSources: (state: IFavoriteSourcesState, { payload }: PayloadAction<IFavoriteSourceModel[]>): void => {
            state.data = payload;
        },
        clearAll: () => ({ ...initialState }),
    },
});

export { favoriteSourcesActions };
export default reducer;
