/* eslint-disable @typescript-eslint/no-unused-vars */
import Skeleton from 'react-loading-skeleton';
import { Checkbox } from 'antd';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import TableHeaderCell from '@shared/TableHeaderCell';
import { SortOrder } from '@services/sources/types';
import useDebounce from '@shared/hooks/useDebounce';
import Pagination from '@shared/Pagination';
import { Tooltip } from '@shared/index';
import styles from './styles.module.scss';
import { ITableColumn, ITableProps, ITableRow } from './types';
import LastItem from '../LastItem';

/**
 * Компонент общей таблицы
 * @param props
 * @constructor
 */
const Table = <T extends ITableRow, R extends T[]>(props: ITableProps<T, R>): React.ReactElement => {
    const {
        rows, columns, isLoading, rowSelection,
        selectedRows, onSelect, onSort, onSearch, pagination, onScroll,
        scrollCallbackEnable, className, filter,
    } = props;

    const [selectedIds, setSelectedIds] = useState<number[]>(selectedRows ?? []);
    const [sort, setSort] = useState<{ column: ITableColumn<T>, order: SortOrder } | null>(null);
    const [search, setSearch] = useState<{ column: ITableColumn<T>, value: string } | null>(null);
    const [minHeight, setMinHeight] = useState<number>(0);
    const [focusedRowIndex, setFocusedRowIndex] = useState<number>(-1);
    const [escapePressed, setEscapePressed] = useState<boolean>(false);
    const [activeHeaderPopoverId, setActiveHeaderPopover] = useState<number>(-1);

    const onScrollCallback = pagination ? false : onScroll;

    const gridTemplateColumns = useMemo(() => {
        let style = '';
        if (rowSelection) style = '40px ';
        style += columns.map((column) => {
            if (column.width) return `${column.width}px`;
            return 'minmax(0px, 1fr)';
        }).join(' ');

        return style;
    }, [rowSelection, columns]);

    const renderCellValue = (column: ITableColumn<T>, row: T, rowIndex: number): JSX.Element | string | number | T[keyof T] => {
        if (column.render) return column.render(row[column.dataIndex], row);
        if (column.isIndex) return rowIndex + 1;
        return row[column.dataIndex];
    };

    const onSelectRow = (row: T, e: any, clickedIndex): void => {
        if (!e.shiftKey || selectedIds.length < 1) {
            const isSelected = e.target.checked;
            let changedSelectedIds = selectedIds;

            if (isSelected && selectedIds) changedSelectedIds = selectedIds.concat(row.id);
            if (!isSelected) changedSelectedIds = selectedIds.filter((id) => id !== row.id);

            setSelectedIds(changedSelectedIds);
            if (onSelect && !rowSelection) onSelect(row as any);
            else if (onSelect) onSelect(changedSelectedIds);
        }

        if (e.shiftKey && selectedIds.length >= 1) {
            const sortedSelectedIds = selectedIds.sort((a, b) => a - b);
            let startIndex = 0;
            rows.forEach((item, index) => {
                if (sortedSelectedIds[0] === item.id) startIndex = index;
            });

            const ids = rows.slice(startIndex, clickedIndex + 1).map((item) => item.id);
            setSelectedIds(ids);
            if (onSelect) onSelect(ids);
        }
    };

    const allRowsSelected = useMemo(() => {
        if (!rowSelection) return false;
        if (!rows) return false;
        return selectedIds.length === rows.length;
    }, [selectedIds, rowSelection, rows]);

    const handleClickSelectAllRows = (e: CheckboxChangeEvent): void => {
        const isSelected = e.target.checked;
        let changedSelectedIds = [];
        if (isSelected) changedSelectedIds = rows.map((row) => row.id);
        if (!isSelected) changedSelectedIds = [];
        setSelectedIds(changedSelectedIds);
    };

    const handleClickSort = (column: ITableColumn<T>, order: SortOrder): void => {
        setSort({ column, order });
        if (onSort) onSort(column, order);
    };

    const handleChangeSearch = (column: ITableColumn<T>, value: string): void => {
        setSearch({ column, value });
    };

    useDebounce(() => {
        if (search) {
            onSearch(search.column, search.value);
        }
    }, 1000, [search]);

    const isRowsVisible = useMemo(() => {
        if (!rows || rows?.length < 1) return false;
        if (rows && rows.length >= 1 && onScrollCallback) return true;
        if (isLoading && !onScrollCallback) return false;
        return true;
    }, [rows, onScrollCallback, isLoading]);

    const clearSearch = (): void => {
        setSearch(null);
        onSearch(search.column, '');
    };

    const setTableMinHeight = (value: number): void => {
        setMinHeight(value);
    };

    const handlerChangeVisibleHeaderPopover = (id: number): void => {
        setActiveHeaderPopover((prev) => (prev === id ? -1 : id));
    };

    const keysHandler = useCallback((e) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            let nextVal = focusedRowIndex;
            if (e.key === 'ArrowDown') nextVal += 1;
            if (e.key === 'ArrowUp') nextVal -= 1;

            if (nextVal < 0) nextVal = 0;
            if (nextVal === rows.length) nextVal -= 1;
            setFocusedRowIndex(nextVal);
        }
        if (e.key === 'Escape') {
            setEscapePressed(true);
        }
    }, [rows, focusedRowIndex]);

    useEffect(() => {
        window.addEventListener('keydown', keysHandler);

        return () => {
            window.removeEventListener('keydown', keysHandler);
        };
    }, [keysHandler]);

    const getColumnHeader = (column) => {
        if (!column?.subColumns) {
            if (column?.headerCellRender) {
                return column?.headerCellRender(column);
            }
            // headerSubCellRender
            return (
                <TableHeaderCell
                    key={column.id}
                    column={column}
                    classes={column?.headerColumnClasses}
                    sort={sort}
                    search={search}
                    {...(column.sortable ? { onSort: handleClickSort } : {})}
                    {...(column.searchable ? { onSearch: handleChangeSearch } : {})}
                    clearSearch={clearSearch}
                    setTableMinHeight={setTableMinHeight}
                    escapePressed={escapePressed}
                    setEscapePressed={setEscapePressed}
                    visibleHeaderPopoverId={activeHeaderPopoverId}
                    onVisibleHeaderPopover={handlerChangeVisibleHeaderPopover}
                />
            );
        }
        return (
            <div style={{ width: 'fit-content' }}>
                {
                    column?.headerCellRender ? column?.headerCellRender(column)
                        : (
                            <TableHeaderCell
                                key={column.id}
                                classes={column?.headerColumnClasses}
                                column={column}
                                sort={sort}
                                search={search}
                                {...(column.sortable ? { onSort: handleClickSort } : {})}
                                {...(column.searchable ? { onSearch: handleChangeSearch } : {})}
                                clearSearch={clearSearch}
                                setTableMinHeight={setTableMinHeight}
                                escapePressed={escapePressed}
                                setEscapePressed={setEscapePressed}
                                visibleHeaderPopoverId={activeHeaderPopoverId}
                                onVisibleHeaderPopover={handlerChangeVisibleHeaderPopover}
                            />
                        )
                }
                <div style={{ display: 'flex' }}>
                    {column?.subColumns.map((subColumn) => (
                        subColumn?.headerCellRender ? subColumn.headerCellRender(column)
                            : (
                                <TableHeaderCell
                                    key={subColumn.id}
                                    classes={subColumn?.headerColumnClasses}
                                    column={subColumn}
                                    sort={sort}
                                    search={search}
                                    {...(subColumn.sortable ? { onSort: handleClickSort } : {})}
                                    {...(subColumn.searchable ? { onSearch: handleChangeSearch } : {})}
                                    clearSearch={clearSearch}
                                    setTableMinHeight={setTableMinHeight}
                                    escapePressed={escapePressed}
                                    setEscapePressed={setEscapePressed}
                                    visibleHeaderPopoverId={activeHeaderPopoverId}
                                    onVisibleHeaderPopover={handlerChangeVisibleHeaderPopover}
                                />
                            )
                    ))}
                </div>
            </div>
        );
    };
    const getRowFromColumns = (column, cellValue, row, rowIndex) => {
        if (!column?.subColumns) {
            return (
                <div key={column.id} className={styles.rowCell}>
                    {column.maxLengthForTooltipe && !column.render ? (
                        <>
                            <Tooltip
                            // @ts-ignore
                                content={cellValue}
                                placement="bottomLeft"
                                xOffset={-2}
                                yOffset={0}
                                disabled={String(cellValue).length < column.maxLengthForTooltipe}
                                arrow={false}
                            >
                                {cellValue}
                            </Tooltip>
                            <div className={styles.hiddenField} />
                        </>
                    )
                        : cellValue}
                </div>
            );
        }
        return (
            <div style={{ display: 'flex' }}>
                {column?.subColumns.map((subColumn) => {
                    const subCellValue = renderCellValue(subColumn, row, rowIndex);

                    return (
                        <div key={`subColumnId_${subColumn.id}`} className={styles.rowCell}>
                            {subColumn.maxLengthForTooltipe && !subColumn.render ? (
                                <>
                                    <Tooltip
                                        // @ts-ignore
                                        content={cellValue}
                                        placement="bottomLeft"
                                        xOffset={-2}
                                        yOffset={0}
                                        disabled={String(cellValue).length < subColumn.maxLengthForTooltipe}
                                        arrow={false}
                                    >
                                        {subCellValue}
                                    </Tooltip>
                                    <div className={styles.hiddenField} />
                                </>
                            )
                                : subCellValue}
                        </div>
                    );
                })}
            </div>
        );

        // (column?.subColumns)
    };
    return (
        <div className={styles.wrapper} style={rows?.length > 5 ? { overflowY: 'auto' } : null}>
            <div className={classNames(styles.table, className)} style={{ minHeight }}>
                <div className={styles.header} style={{ gridTemplateColumns }}>
                    {rowSelection && (
                        <div className={styles.headerCheckboxCell}>
                            <Checkbox
                                checked={allRowsSelected}
                                onChange={handleClickSelectAllRows}
                            />
                        </div>
                    )}
                    {columns.map(getColumnHeader)}
                </div>
                {!isLoading && filter && filter.isFiltered
                    && (
                        <div className={styles.filtered}>
                            {filter.text && (
                                <p>{filter.text}</p>
                            )}
                            <button type="button" onClick={filter.clearFilter}>Сбросить фильтры</button>
                        </div>
                    )}
                <div className={styles.body}>
                    {isRowsVisible && rows.map((row, rowIndex) => (
                        <div
                            key={row.id}
                            className={classNames(
                                styles.row,
                                rowSelection && selectedIds.includes(row.id) ? styles.rowChecked : undefined,
                                rowIndex === focusedRowIndex ? styles.rowFocused : undefined,
                            )}
                            style={{ gridTemplateColumns }}
                            role="button"
                        >
                            {rowSelection
                                && (
                                    <div className={styles.rowCell}>
                                        <Checkbox
                                            checked={selectedIds.includes(row.id)}
                                            onClick={(e) => onSelectRow(row, e, rowIndex)}
                                        />
                                    </div>
                                )}
                            {columns.map((column) => {
                                const cellValue = renderCellValue(column, row, rowIndex);
                                return getRowFromColumns(column, cellValue, row, rowIndex);
                            })}
                        </div>
                    ))}
                    {isLoading && <Skeleton width="100%" height={44} count={15} />}
                    {rows && rows.length > 1 && onScrollCallback && !isLoading
                    && (
                        <LastItem
                            onLastItem={() => (scrollCallbackEnable && onScroll())}
                            enabled={false}
                        />
                    )}
                </div>

                {pagination && rows && rows.length >= 1
                && (
                    <div className={styles.pagination}>
                        <Pagination
                            total={pagination.total}
                            currentPage={pagination.currentPage}
                            onChange={pagination.onChange}
                            limit={pagination.limit}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Table;
