import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInfoBasketSubpageResult } from './types';

const initialState: IInfoBasketSubpageResult = {
    activeRows: [],
    audience: 0,
    comments: 0,
    views: 0,
    bloggers: 0,
    media: 0,
};

const { actions: infoBasketSubpageResultActions, reducer } = createSlice({
    name: 'infoBasketSubpageResult',
    initialState,
    reducers: {
        clearAll: (state: IInfoBasketSubpageResult) => {
            state.activeRows = initialState.activeRows;
            state.audience = initialState.audience;
            state.comments = initialState.comments;
            state.views = initialState.views;
            state.bloggers = initialState.bloggers;
            state.media = initialState.bloggers;
        },
        addAudience: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.audience += payload;
        },
        addComments: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.comments += payload;
        },
        addViews: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.views += payload;
        },
        addBloggers: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.bloggers += payload;
        },
        addMedia: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.media += payload;
        },
        removeAudience: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.audience -= payload;
        },
        removeComments: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.comments -= payload;
        },
        removeViews: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.views -= payload;
        },
        removeBloggers: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.bloggers -= payload;
        },
        removeMedia: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<number>,
        ) => {
            state.media -= payload;
        },
        selectRow: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<string>,
        ) => {
            if (!payload) {
                return;
            }
            if (!state.activeRows.includes(payload)) {
                state.activeRows.push(payload);
            }
        },
        deselectRow: (
            state: IInfoBasketSubpageResult,
            { payload }: PayloadAction<string>,
        ) => {
            if (!payload) {
                return;
            }
            if (state.activeRows.includes(payload)) {
                state.activeRows = state.activeRows.filter((id) => id !== payload);
            }
        },
        deselectAllRows: (
            state: IInfoBasketSubpageResult,
        ) => {
            state.activeRows = [];
        },
    },
});

export { infoBasketSubpageResultActions };
export default reducer;
