/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';
import TableProjectResults from '@entities/TableProjectResults';
import IconButtonWithLabel from '@entities/IconButtonWithLabel';
import { Button } from '@shared/index';
import { ICustomTableColumn, ICustomTableRow } from '@entities/TableProjectResults/types';
import EditComment from '@entities/table-components/EditComment';
import { ModalXlsxExport, SourceCell } from '@entities/index';
import { projectsResultsService } from '@services/projects/results';
import { SortOrder, SourceFieldsEnum } from '@services/sources/types';
import { projectMediaPlanRtkQService } from '@services/projects/media-plan';
import { message } from 'antd';
import { projectLonglistRtkQService } from '@services/projects/longlist';
import ResultsCompleteModal from '../../../modals/ProjectResultsComplete';
import styles from './styles.module.scss';

interface IPropsResults {
    projectId: number,
    projectIsEnded: boolean
}
const listParams = [
    {
        selected: true,
        name: 'Источник',
        value: SourceFieldsEnum.title,
    },
    {
        selected: true,
        name: 'Подписчики',
        value: SourceFieldsEnum.subscribers,
    },
    {
        selected: true,
        name: 'Цена',
        value: SourceFieldsEnum.price,
    },
    {
        selected: true,
        name: 'Просмотры План',
        value: SourceFieldsEnum.planViews,
    },
    {
        selected: true,
        name: 'Просмотры Факт',
        value: SourceFieldsEnum.factViews,
    },
    {
        selected: true,
        name: 'Просмотры %',
        value: SourceFieldsEnum.ratioViews,
    },
    {
        selected: true,
        name: 'ER План',
        value: SourceFieldsEnum.planER,
    },
    {
        selected: true,
        name: 'ER Факт',
        value: SourceFieldsEnum.factER,
    },
    {
        selected: true,
        name: 'ER %',
        value: SourceFieldsEnum.ratioER,
    },
    {
        selected: true,
        name: 'CPV План',
        value: SourceFieldsEnum.planCPV,
    },
    {
        selected: true,
        name: 'CPV Факт',
        value: SourceFieldsEnum.factCPV,
    },
    {
        selected: true,
        name: 'CPV %',
        value: SourceFieldsEnum.ratioCpv,
    },
    {
        selected: true,
        name: 'Комментарий',
        value: SourceFieldsEnum.comment,
    },
];
const Results: React.FC<IPropsResults> = ({ projectId, projectIsEnded }): JSX.Element => {
    const [fields, setFields] = useState(() => listParams);
    const [
        loadSources,
        {
            data: sourceData,
            isLoading: isLoadingSources,
        },
    ] = projectLonglistRtkQService.useLazyLoadSourcesForLonglistQuery();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [downloadSources, { data: downloadedSourcesXlsx, isLoading: isLoadingDownload }] = projectMediaPlanRtkQService.useLazyPostDownloadSourcesQuery();
    const [getFacts, { data: facts }] = projectsResultsService.useLazyGetResultFactsQuery();
    const [stopProject] = projectsResultsService.useLazyStopProjectQuery();
    const [updateComment] = projectsResultsService.useLazyUpdateCommentQuery();
    const [sortParams, setSortParams] = useState<{ asc: boolean, dataIndex: '' }>({ asc: false, dataIndex: '' });
    useEffect(() => {
        if (downloadedSourcesXlsx) {
            const hiddenElement = document.createElement('a');
            const url = window.URL || window.webkitURL;
            const blobPDF = url.createObjectURL(downloadedSourcesXlsx);
            hiddenElement.href = blobPDF;
            hiddenElement.target = '_blank';
            hiddenElement.download = 'Результаты.xlsx';
            hiddenElement.click();
        }
    }, [downloadedSourcesXlsx, projectId]);
    const getXLSXFile = () => {
        downloadSources({ id: projectId, set_type_id: 3, fields: fields.filter((item) => item.selected).map((item) => item.value) })
            .catch(() => message.error('Произошла ошибка'));
    };
    const handleClickOnDownload = () => {
        setIsOpenModal(true);
    };
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);
    useEffect(() => {
        getFacts(projectId);
    }, [projectId]);
    // eslint-disable-next-line @typescript-eslint/comma-dangle
    const getValueOrNoneSymbole = (value: number | null, prefix: string = '') => {
        if (value === null || value === undefined) return '-';
        return value > 0 && value < 0.001 ? '0.001>' : (`${(Math.round(value * 1000) / 1000).toLocaleString('ru-RU')} ${prefix}`);
    };
    const getPercent = (from: number, int: number): string => {
        if (typeof from !== 'number' || typeof int !== 'number') return '-';
        if (!from || !int) return '-';
        const percent = (int / from) * 100;
        if (percent > 10) return `${(Math.round(percent * 10) / 10).toLocaleString('ru-RU')}%`;
        return percent > 0 && percent < 0.001 ? '0.001%>' : `${(Math.round(percent * 1000) / 1000).toLocaleString('ru-RU')}%`;
    };
    const getFormatFacts = useMemo(() => {
        if (sourceData?.sources) {
            const { sources } = sourceData;
            const rowsFacts = sources.map((source) => {
                // eslint-disable-next-line no-unsafe-optional-chaining
                const fact = facts?.find((current) => +source?.id === +current.id);
                const newItem: ICustomTableRow = {
                    actions: false,
                    id: fact?.id || source.id,
                    logoUrl: source?.logoUrl || (source as any)?.logo_url,
                    title: source?.title || '-',
                    subscribers: `${getValueOrNoneSymbole(fact?.subscribers || source.subscribers)}`,
                    price: `${getValueOrNoneSymbole(fact?.price)}`,
                    viewsPlan: `${getValueOrNoneSymbole(fact?.plan_views)}`,
                    viewsFact: `${getValueOrNoneSymbole(fact?.fact_views)}`,
                    viewsPercent: getPercent(fact?.plan_views, fact?.fact_views),
                    erPlan: `${getValueOrNoneSymbole(fact?.plan_er)}`,
                    erFact: `${getValueOrNoneSymbole(fact?.fact_er)}`,
                    erPercent: getPercent(fact?.plan_er, fact?.fact_er),
                    CPVPlan: `${getValueOrNoneSymbole(fact?.plan_cpv, 'руб.')}`,
                    CPVFact: `${getValueOrNoneSymbole(fact?.fact_cpv, 'руб.')}`,
                    CPVPercent: getPercent(fact?.plan_cpv, fact?.fact_cpv),
                    comment: (fact?.comment || source?.content) ?? '',
                };
                return newItem;
            });
            return rowsFacts;
        }
        return [];
    }, [facts, sourceData]);
    const getSortedFacts = useMemo(() => {
        const filtred = getFormatFacts.sort((a, b) => {
            if (a[sortParams?.dataIndex] > b[sortParams?.dataIndex]) return 1;
            if (a[sortParams?.dataIndex] < b[sortParams?.dataIndex]) return -1;
            return 0;
        });
        return sortParams.asc ? filtred.reverse() : filtred;
    }, [getFormatFacts, sortParams]);
    const handleStopProject = (comment: string) => {
        stopProject({
            id: projectId,
            body: {
                comment,
            },
        });
    };
    const handleComplete = (id, comment) => {
        if (id) {
            updateComment({ id: projectId, sourceId: id, comment }).then(() => {
                getFacts(projectId);
            });
        }
    };
    const setActionRows = (id: number) => {
        // setRows((prev) => prev.map((row) => ({ ...row, actions: row.id === id })));
    };

    const columns: ICustomTableColumn[] = [
        {
            id: 1,
            title: null,
            isIndex: true,
            dataIndex: 'id',
            width: 40,
            icon: null,
        },
        {
            id: 2,
            title: 'Источник',
            dataIndex: 'title',
            sortable: true,
            width: 354,
            icon: null,
            render: (value, record) => (<SourceCell value={value} logoUrl={record?.logoUrl} sourceId={-1} />),
        },
        {
            id: 3,
            title: 'Подписчики',
            dataIndex: 'subscribers',
            sortable: true,
            width: 143,
            icon: null,
        },
        {
            id: 4,
            title: 'Цена',
            dataIndex: 'price',
            sortable: true,
            width: 96,
            icon: null,
        },
        {
            id: 5,
            title: 'План',
            dataIndex: 'viewsPlan',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 6,
            title: 'Факт',
            dataIndex: 'viewsFact',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 7,
            title: '%',
            dataIndex: 'viewsPercent',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 8,
            title: 'План',
            dataIndex: 'erPlan',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 9,
            title: 'Факт',
            dataIndex: 'erFact',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 10,
            title: '%',
            dataIndex: 'erPercent',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 11,
            title: 'План',
            dataIndex: 'CPVPlan',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 12,
            title: 'Факт',
            dataIndex: 'CPVFact',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 13,
            title: '%',
            dataIndex: 'CPVPercent',
            sortable: true,
            width: 100,
            icon: null,
        },
        {
            id: 14,
            title: 'Комментарий',
            dataIndex: 'comment',
            sortable: true,
            width: 188,
            icon: null,
            render: (value, record) => (
                <EditComment
                    text={value}
                    isText
                    record={record}
                    setActionRows={setActionRows}
                    onComplete={handleComplete}
                />
            ),
        },
    ];

    const handleSort = (column, value) => {
        setSortParams(() => ({
            asc: value !== 'desc',
            dataIndex: column.dataIndex,
        }));
    };

    useEffect(() => {
        if (projectId) {
            loadSources({
                projectId,
                limit: 100,
                page: 1,
                set_type_id: 3,
                orderBy: 'subscribers',
                orderDirection: SortOrder.desc,
                filterParams: null,
                rangeParams: null,
            });
        }
    }, [projectId, projectIsEnded]);

    return (
        <>
            <ModalXlsxExport
                visible={isOpenModal}
                setVisible={setIsOpenModal}
                values={fields}
                setValues={setFields as any}
                onClickSubmit={getXLSXFile}
            />
            <DefaultWrapperProject style={{ marginTop: 16 }}>
                <div className={styles.results_root}>
                    <div className={styles.results_header}>
                        <span className={styles.results_header_title}>Результаты проекта</span>
                    </div>
                    <div className={styles.results_tableWrapper}>
                        <TableProjectResults
                            columns={columns}
                            rows={getSortedFacts}
                            onSearch={() => null}
                            onSort={handleSort}
                            onSelect={() => null}
                            className={styles.results_table}
                        />
                    </div>
                    <ResultsCompleteModal
                        projectId={projectId}
                        isOpen={modalVisibility}
                        onApply={handleStopProject}
                        onClose={() => setModalVisibility(false)}
                    />
                    <div className={styles.results_footer}>
                        <IconButtonWithLabel
                            iconSize={16}
                            iconId="Download"
                            label="Скачать"
                            onClick={handleClickOnDownload}
                            labelStyle={{ color: '#0EBFA1' }}
                        />
                        {!projectIsEnded && (
                            <Button
                                text="Завершить"
                                type="default"
                                size="large"
                                onClick={() => setModalVisibility(true)}
                            />
                        )}
                    </div>
                </div>
            </DefaultWrapperProject>
        </>
    );
};

export default Results;
