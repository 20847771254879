import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

const ProjectsPage: React.FC = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { Id: urlParametersId } = useParams();

    return (
        <div style={{ height: '100%', overflowY: 'auto' }}>
            <Outlet />
        </div>
    );
};

export default ProjectsPage;
