/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import DatePicker from '../../../../components/DatePicker';
import { DateRangePickerValue } from '../../../../components/DateRangePicker/types';
import styles from './styles.module.scss';

interface IPropsCampaignHeaderFilters {
    campaignCreateDate: string,
    period: DateRangePickerValue,
    onChangePeriod: (datePicker: DateRangePickerValue) => void,
    children?: JSX.Element
}

const HeaderFilters: React.FC<IPropsCampaignHeaderFilters> = ({
    campaignCreateDate,
    period,
    onChangePeriod,
    children,
// eslint-disable-next-line arrow-body-style
}): JSX.Element => {
    return (
        <div>
            <div className={styles.root_headerFilters}>
                {campaignCreateDate && (
                    <div>
                        <div className={styles.dateCreateCampaing}>
                            <span>Дата создания:  </span>
                            <div>{campaignCreateDate}</div>
                        </div>
                    </div>
                )}
                <div className={styles.seporatorLineHeaderFilters} />
                <div className={styles.contentDatePickerContainer}>
                    <DatePicker value={period} onChange={onChangePeriod} />
                    <div className={styles.seporatorLineHeaderFilters} style={{ marginBottom: 4, marginTop: 16 }} />
                </div>
            </div>
            {children}
        </div>
    );
};
export default HeaderFilters;
