/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import { IChart } from 'containers/CampaignNewCharts/ChartTypes';
import React, { useMemo } from 'react';
import { Metric, ChartMetricsModel } from 'services/campaigns/Charts/models';
import { Select } from '@shared/index';
import { ISelectItem } from '@shared/ui-components/Select/types';
import styles from './styles.module.scss';

interface IPropsChartMetrics {
    onUpdateMetric: (newMetric: Metric) => void,
    listSettingForChart: ChartMetricsModel,
    isLoading: boolean,
    chartSetting: IChart,
}

const ChartMetrics: React.FC<IPropsChartMetrics> = ({
    onUpdateMetric,
    listSettingForChart,
    isLoading,
    chartSetting,
}): JSX.Element => {
    if (isLoading) return <div> Loading</div>;
    if (!listSettingForChart || !listSettingForChart?.length) return <div>Данных нет</div>;

    const metrics = useMemo((): Metric[] => {
        const listRawMetrics = listSettingForChart.find((item) => item?.metrics_entity);
        return listRawMetrics.metrics_entity;
    }, [listSettingForChart]);

    const simpleEntityMetrics = useMemo((): ISelectItem[] => {
        const itemsForSelect = metrics.map((name, index) => ({
            id: index + 1,
            label: name,
            value: name,
        }));
        const listAcceptedMetrics = itemsForSelect.filter((item) => {
            const check = chartSetting.dimensions.some((dimension) => dimension === item.value);
            return !check;
        });
        return listAcceptedMetrics;
    }, [metrics, chartSetting]);

    const selectedMetric = useMemo(
        (): ISelectItem => simpleEntityMetrics?.find((simpleEntity: ISelectItem) => simpleEntity.value === chartSetting?.metrics),
        [simpleEntityMetrics, chartSetting],
    );

    const handleSelectMetric = (id): void => {
        const newMetric = simpleEntityMetrics.find((simpleEntity: ISelectItem) => simpleEntity.id === id);
        onUpdateMetric(newMetric.value as Metric);
    };
    return (
        <div className={`${styles.rootMetrics} ${styles.selectRoot}`}>
            <div className={styles.titleMetrics}>Что считаем (метрика)</div>
            {/* <div className={styles.dropdownMetrics}> */}
            <Select
                onSelect={handleSelectMetric}
                items={simpleEntityMetrics}
                defaultItem={selectedMetric?.id}
            />
            {/* <SingleSelectItem
                    onSelect={handleSelectMetric}
                    selectedId={selectedMetric?.id || 0}
                    defaultTitle={<span>Выбрать</span>}
                    list={simpleEntityMetrics}
                    minWidth={190}
                    isLoading={false}
                /> */}
            {/* </div> */}
        </div>
    );
};

export default React.memo(ChartMetrics);
