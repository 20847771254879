import React, { useCallback, useEffect, useState } from 'react';
import { ISelectItem } from '@shared/ui-components/Select/types';
import { Select } from '@shared/index';
import { IQPLoadSourcePosts, ISourcePost } from '@services/sources/types';
import { sourcesRtkQService } from '@services/sources';
import styles from './styles.module.scss';

const SelectDemo: React.FC = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [checkboxes, setCheckboxes] = useState<ISelectItem[]>([
        {
            id: 1,
            value: 'midValue',
            label: 'Лейбл номер 1',
        },
        {
            id: 2,
            value: 'smallLabel',
            label: 'млнк. лейбл',
        },
        {
            id: 3,
            value: 'bigValue',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: '1431',
            value: 'bigValue1431',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: 4,
            value: 'midValue4',
            label: 'Лейбл номер 1',
        },
        {
            id: 'dsfacxsasfd',
            value: 'smallLabel5',
            label: 'млнк. лейбл',
        },
        {
            id: 5,
            value: 'midValue6',
            label: 'Лейбл номер 1',
        },
        {
            id: 'dsfv32412edsf',
            value: 'smallLabel7',
            label: 'млнк. лейбл',
        },
        {
            id: '08423dscf324rflds',
            value: 'bigValue8',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: 6,
            value: 'bigValue9',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
    ]);
    const smallData: ISelectItem[] = [
        {
            id: 1,
            value: 'midValue',
            label: 'Лейбл номер 1',
        },
        {
            id: 2,
            value: 'smallLabel',
            label: 'млнк. лейбл',
        },
        {
            id: 3,
            value: 'bigValue',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
    ];
    const bigData: ISelectItem[] = [
        {
            id: 1,
            value: 'midValue',
            label: 'Лейбл номер 1',
        },
        {
            id: 2,
            value: 'smallLabel',
            label: 'млнк. лейбл',
        },
        {
            id: 3,
            value: 'bigValue',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: '1431',
            value: 'bigValue1431',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: 4,
            value: 'midValue4',
            label: 'Лейбл номер 1',
        },
        {
            id: 'dsfacxsasfd',
            value: 'smallLabel5',
            label: 'млнк. лейбл',
        },
        {
            id: 5,
            value: 'midValue6',
            label: 'Лейбл номер 1',
        },
        {
            id: 'dsfv32412edsf',
            value: 'smallLabel7',
            label: 'млнк. лейбл',
        },
        {
            id: '08423dscf324rflds',
            value: 'bigValue8',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: 6,
            value: 'bigValue9',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
    ];
    const handleSelectItem = (id) => {
        setCheckboxes((prev) => prev.map((item) => ({
            ...item,
            select: id === item.id ? !item.select : item.select,
        })));
    };

    const sourceId = 6659382;

    const [getPosts, {
        data: postsLoadResponse,
        isFetching: isFetchingPosts,
    }] = sourcesRtkQService.useLazyPostLoadSourcePostsQuery();

    const [posts, setPosts] = useState<ISelectItem[]>([]);
    const [infinityStep, setInfinityStep] = useState<number>(0);

    const [isLoadingPosts, setLoading] = useState<boolean>(false);
    const [paginationToken, setPaginationToken] = useState<string | null>(null);

    const parsePostsToSelect = (postsFromBack: ISourcePost[]): ISelectItem[] => postsFromBack.map((post) => {
        const selectOption: ISelectItem = {
            id: post.postId,
            value: post.postId.toString(),
            label: post.postId.toString(),
        };
        return selectOption;
    });

    const getPostsQuery = useCallback((token: string | null) => {
        const args: IQPLoadSourcePosts = {
            sourceId,
            limit: 20,
        };
        if (token) args.paginationToken = token;
        getPosts(args).then((res) => setInfinityStep(res.data.items.length));
    }, [isFetchingPosts]);

    useEffect(() => {
        setPaginationToken(null);
        setPosts([]);
        getPostsQuery(null);
    }, []);

    useEffect(() => {
        setPaginationToken(null);
        if (postsLoadResponse && postsLoadResponse.items) {
            setPosts((prevPosts) => [...prevPosts, ...parsePostsToSelect(postsLoadResponse.items)]);
        }
        if (postsLoadResponse && postsLoadResponse.nextPageToken) {
            setPaginationToken(postsLoadResponse.nextPageToken);
        }
    }, [postsLoadResponse]);

    const onScroll = useCallback(() => {
        if (paginationToken && !isFetchingPosts) {
            setLoading(true);
            getPostsQuery(paginationToken);
        }
    }, [paginationToken, isFetchingPosts]);

    useEffect(() => {
        if (isFetchingPosts && isLoadingPosts) {
            setLoading(false);
        }
    }, [isFetchingPosts, isLoadingPosts]);

    return (
        <div className={styles.selectDemo_root}>
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 24 }}>
                    <div className={styles.selectDemo}>
                        <Select size="large" items={bigData} defaultItem={2} />
                        <span>- Large</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="medium" items={bigData} />
                        <span>- Medium</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="small" items={bigData} />
                        <span>- Small</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="large" items={smallData} />
                        <span>- Large</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="medium" items={smallData} />
                        <span>- Medium</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="small" items={smallData} />
                        <span>- Small</span>
                    </div>
                </div>
                <div>
                    <div className={styles.selectDemo}>
                        <Select
                            size="large"
                            items={checkboxes}
                            isCheckBoxes
                            onSelect={handleSelectItem}
                            footer={(
                                <div style={{
                                    height: 50, background: 'lightblue', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                }}
                                >
                                    {' '}
                                    Footer
                                </div>
                            )}

                        />
                        <span>- Large custom footer</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="medium" items={smallData} />
                        <span>- Medium</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="small" items={smallData} />
                        <span>- Small</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="large" items={smallData} />
                        <span>- Large</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select size="medium" items={smallData} />
                        <span>- Medium</span>
                    </div>
                    <div className={styles.selectDemo}>
                        <Select
                            size="small"
                            items={checkboxes}
                            isCheckBoxes
                            footer={<div style={{ height: 60, background: 'lightblue' }}> Footer</div>}
                            header={<div style={{ height: 60, background: 'lightblue' }}> header</div>}
                        />
                        <span>- Small with custom blocks</span>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={styles.selectDemo}>
                    <Select size="large" items={bigData} disabled />
                    <span>- Large disabled</span>
                </div>
                <div className={styles.selectDemo}>
                    <Select size="medium" items={bigData} disabled />
                    <span>- Medium disabled</span>
                </div>
                <div className={styles.selectDemo}>
                    <Select size="small" items={bigData} disabled />
                    <span>- Small disabled</span>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={styles.selectDemo}>
                    <Select size="large" items={[]} isLoading />
                    <span>- Large isLoading</span>
                </div>
                <div className={styles.selectDemo}>
                    <Select size="medium" items={smallData} isLoading />
                    <span>- Medium isLoading</span>
                </div>
                <div className={styles.selectDemo}>
                    <Select size="small" items={bigData} isLoading />
                    <span>- Small isLoading</span>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                <div className={styles.selectDemo}>
                    <Select size="large" items={bigData} customWidth="450px" />
                    <span>- Large customWidth 450px</span>
                </div>
                <div className={styles.selectDemo}>
                    <Select size="medium" items={bigData} customWidth="450px" />
                    <span>- Medium customWidth 450px</span>
                </div>
                <div className={styles.selectDemo}>
                    <Select size="small" items={bigData} customWidth="450px" />
                    <span>- Small customWidth 450px</span>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                <div className={styles.selectDemo}>
                    <Select
                        size="large"
                        items={posts}
                        accordion
                        infinity
                        infinityStep={infinityStep}
                        updateInfinityDate={onScroll}
                        isUpdateInfinityDate={isLoadingPosts}
                    />
                    <span>- Large infinity</span>
                </div>
            </div>
        </div>
    );
};

export default SelectDemo;
