import React from 'react';
import Skeleton from 'react-loading-skeleton';
import BlockInfo from './Info';
import styles from './styles.module.scss';

interface IPropsBlockInfo {
    isLoading: boolean;
    name: string;
    create: string;
}

// eslint-disable-next-line arrow-body-style
const BlockInfoProject: React.FC<IPropsBlockInfo> = ({
    isLoading, name, create,
}): JSX.Element => (
    <div className={styles.root_BlockInfoProject}>
        {
            isLoading && (
                <Skeleton height={70} width={200} />
            )
        }
        {
            !isLoading && (
                <BlockInfo name={name} create={create} />
            )
        }
    </div>
);

export default BlockInfoProject;
