import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReportPdfPageInput } from 'shared/hooks/pdf-handler/types';
import { FetchStatus, IDictionary } from '../../types';
import { convertSavedReportFromApi } from './converter';
import { IPDFHandlerReport, IPDFHandlerReportPage, IPDFHandlerReportState } from './types';

const initialState: IPDFHandlerReportState = {
    saveReportStatus: FetchStatus.undefined,
    loadReportStatus: FetchStatus.undefined,
    deleteReportStatus: FetchStatus.undefined,
    loadReportFileStatus: FetchStatus.undefined,
    fetchErrors: '',
    ids: [],
    reports: {},
};

const { actions: pdfHandlerReportActions, reducer } = createSlice({
    name: 'pdfHandlerReport',
    initialState,
    reducers: {
        startLoadFile: (state: IPDFHandlerReportState): void => {
            state.loadReportFileStatus = FetchStatus.loading;
        },
        successLoadFile: (state: IPDFHandlerReportState): void => {
            state.loadReportFileStatus = FetchStatus.success;
        },
        failedLoadFile: (state: IPDFHandlerReportState): void => {
            state.loadReportFileStatus = FetchStatus.failed;
        },
        startSave: (state: IPDFHandlerReportState): void => {
            state.saveReportStatus = FetchStatus.loading;
        },
        successSave: (state: IPDFHandlerReportState): void => {
            state.saveReportStatus = FetchStatus.success;
        },
        failedSave: (state: IPDFHandlerReportState): void => {
            state.saveReportStatus = FetchStatus.failed;
        },
        startDelete: (state: IPDFHandlerReportState): void => {
            state.deleteReportStatus = FetchStatus.loading;
        },
        successDelete: (state: IPDFHandlerReportState): void => {
            state.deleteReportStatus = FetchStatus.success;
        },
        failedDelete: (state: IPDFHandlerReportState): void => {
            state.deleteReportStatus = FetchStatus.failed;
        },
        startLoading: (state: IPDFHandlerReportState): void => {
            state.loadReportStatus = FetchStatus.loading;
        },
        successLoading: (state: IPDFHandlerReportState): void => {
            state.loadReportStatus = FetchStatus.success;
        },
        failedLoading: (state: IPDFHandlerReportState): void => {
            state.loadReportStatus = FetchStatus.failed;
        },
        setFetchErrors: (state: IPDFHandlerReportState, { payload }: PayloadAction<string>): void => {
            state.fetchErrors = payload;
        },
        addReport: (state: IPDFHandlerReportState, { payload }: PayloadAction<IPDFHandlerReport>): void => {
            if (payload?.id) {
                state.reports[payload.id] = payload;
                state.ids = [...state.ids, payload.id];
            }
        },
        clearReports: (state: IPDFHandlerReportState): void => {
            state.reports = {};
            state.ids = [];
        },
        addReportsFromApi: (state: IPDFHandlerReportState, { payload }: PayloadAction<unknown>): void => {
            if (payload && Array.isArray(payload)) {
                const buffer = convertSavedReportFromApi(payload);
                const { campaignId } = buffer[0];
                const idsWithoutOldReports = state.ids
                    .map((id) => (state.reports[id].campaignId === campaignId ? null : id))
                    .filter((id) => Boolean(id));
                const newReports = {
                    ...idsWithoutOldReports.reduce((acc: IDictionary<IPDFHandlerReport>, curr: string) => {
                        acc[curr] = state.reports[curr];
                        return acc;
                    }, {} as IDictionary<IPDFHandlerReport>),
                    ...buffer.reduce((acc: IDictionary<IPDFHandlerReport>, curr: IPDFHandlerReport) => {
                        acc[curr.id] = curr;
                        return acc;
                    }, {} as IDictionary<IPDFHandlerReport>),
                };
                state.ids = [...Object.keys(newReports)];
                state.reports = newReports;
            }
        },
        removeReportByReportId: (state: IPDFHandlerReportState, { payload }: PayloadAction<string>): void => {
            const newReports = state.ids
                .filter((id) => id !== payload)
                .reduce((acc: IDictionary<IPDFHandlerReport>, curr: string) => {
                    acc[curr] = state.reports[curr];
                    return acc;
                }, {} as IDictionary<IPDFHandlerReport>);
            state.reports = newReports;
            state.ids = [...Object.keys(newReports)];
        },
        addPageInReport: (state: IPDFHandlerReportState, { payload }: PayloadAction<IReportPdfPageInput>): void => {
            if (payload?.reportId && payload?.page) {
                const report = state.reports[payload.reportId];
                if (report) {
                    state.reports[payload.reportId].pages = [...report.pages, payload.page];
                }
            }
        },
        setPagesInReport: (state: IPDFHandlerReportState, { payload }: PayloadAction<{ reportId: string, pages: IPDFHandlerReportPage[] }>): void => {
            if (payload?.reportId && payload?.pages) {
                if (state.reports[payload.reportId]) {
                    state.reports[payload.reportId].pages = [...payload.pages];
                }
            }
        },
        addReportFile: (state: IPDFHandlerReportState, { payload }: PayloadAction<{ reportId: string, content: File, name: string }>): void => {
            if (payload?.reportId && payload?.content) {
                const report = state.reports[payload.reportId];
                if (report) {
                    state.reports[payload.reportId].file = { name: payload?.name, content: payload.content };
                }
            }
        },
        setDownloadUrlForReport: (state: IPDFHandlerReportState, { payload }: PayloadAction<{ reportId: string, response: any }>): void => {
            if (payload?.reportId && payload?.response?.file_url) {
                const report = state.reports[payload.reportId];
                const url = payload.response?.file_url;
                if (report && url) {
                    state.reports[payload.reportId].downloadUrl = url;
                }
            }
        },
    },
});

export {
    pdfHandlerReportActions,
};
export default reducer;
