/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import TooltipWrapper from '../TooltipWrapper';

interface IItem {
    color?: string,
    borderColor?: string,
    value: number,
    label: string,
}
interface IPropsDoughnutProps {
    animation?: boolean;
    type?: 'doughnut' | 'pie';
    valueList: IItem[];
    isActiveTooltip?: boolean;
    height: string;
    width: string;
    textColor?: string;
    getTooltip?: (any) => JSX.Element;
    getImage?: (any) => void
}

ChartJS.register(ArcElement, Tooltip, Legend);
const DoughnutChart: React.FC<IPropsDoughnutProps> = ({
    type = 'doughnut', valueList, isActiveTooltip = false, getTooltip, getImage, height, width, textColor = 'white', animation = true,
}) => {
    const chartRef = useRef(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const timerTooltip = useRef(null);
    const [positionAndData, setPositionAndData] = useState({ top: 0, left: 0, data: null });
    const randomColor = () => {
        const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
        const h = randomInt(0, 255);
        const s = randomInt(0, 255);
        const l = randomInt(0, 255);
        return `rgba(${h},${s},${l}, 0.1)`;
    };
    const hideTooltip = () => {
        if (timerTooltip.current) return;
        timerTooltip.current = setTimeout(() => {
            setIsShowModal(false);
        }, 300);
    };
    const clearTimerTooltip = () => {
        if (!timerTooltip.current) return;
        clearTimeout(timerTooltip.current);
        timerTooltip.current = null;
    };
    const customTooltipData = (tooltipModel) => {
        if (!isActiveTooltip) return;
        if (tooltipModel.replay) return;
        const { tooltip } = tooltipModel;
        if (tooltip.opacity === 0) {
            clearTimerTooltip();
            hideTooltip();
            return;
        }
        setIsShowModal(true);
        const position = chartRef.current.canvas.getBoundingClientRect();
        const left = position.left + tooltip.caretX;
        const top = position.top + tooltip.caretY;
        const data = {
            content: {
                label: `текущие значение ${tooltip.dataPoints[0].raw}`,
            },
        };
        // const formatData = { content: data };
        clearTimerTooltip();
        setPositionAndData({
            top, left, data,
        });
    };
    const getFormatData = useMemo(() => valueList.reduce((acc, item) => {
        const color = item.color || randomColor();
        const newAcc = acc;
        newAcc.data.push(item.value);
        newAcc.backgroundColor.push(color);
        newAcc.borderColor.push(item.borderColor || color);
        return newAcc;
    }, {
        data: [],
        backgroundColor: [],
        borderColor: [],
    }), [valueList]);
    const getBuilderPoint = (
        ctx,
        oldData,
        labelsOffset,
        centerX,
        centerY,
        index,
        bisectorAngle,
    ) => {
        const endX = centerX + labelsOffset * Math.cos(bisectorAngle);
        let endY = centerY + labelsOffset * Math.sin(bisectorAngle);
        const offset = endX > 150 ? 1 : -1;
        const text = `${valueList[index]?.value.toLocaleString('ru-Ru')} ${valueList[index]?.label}`;
        const matrix = ctx.measureText(text);
        const widthText = matrix.width;
        const check = oldData.find((prev) => {
            const diffX = Math.abs(prev.endX - endX);
            const diffY = Math.abs(prev.endY - endY);
            if (diffY < 18 && diffX < 20) return true;
            return false;
        });
        if (check) {
            endY += 22 * offset;
        }
        return {
            labelEndX: endX + ((widthText + 16) * offset),
            endX,
            endY,
            offset,
            text,
            widthText,
        };
    };
    const getBuilderData = (
        ctx,
        oldData,
        labelsOffset,
        centerX,
        centerY,
        startAngle,
        endAngle,
        index,
        max,
    ) => {
        const angleDif = (endAngle - startAngle) / 2;
        let bisectorAngle = startAngle + angleDif;
        const degsInRod = 0.0872665; // 5 deg
        let textBuilderPoint = getBuilderPoint(
            ctx,
            oldData,
            labelsOffset,
            centerX,
            centerY,
            index,
            bisectorAngle,
        );
        while (textBuilderPoint.labelEndX < 15) {
            bisectorAngle -= degsInRod;
            textBuilderPoint = getBuilderPoint(
                ctx,
                oldData,
                labelsOffset,
                centerX,
                centerY,
                index,
                bisectorAngle,
            );
        }
        while (textBuilderPoint.labelEndX >= max) {
            bisectorAngle -= degsInRod;
            textBuilderPoint = getBuilderPoint(
                ctx,
                oldData,
                labelsOffset,
                centerX,
                centerY,
                index,
                bisectorAngle,
            );
        }
        return {
            ...textBuilderPoint,
            bisectorAngle,
        };
    };

    const labels = {
        id: 'labels',
        beforeDraw(chart) {
            const color = 'black';
            const { ctx } = chart;
            const labelsOffset = 100;
            const sets = chart?._metasets[0];
            const centerX = chart.width / 2;
            const centerY = chart.height / 2;
            const data = sets?.data;
            data.reduce((acc, point, index) => {
                const endAngle = point?.endAngle;
                const startAngle = point?.startAngle;
                // const angleDif = (endAngle - startAngle) / 2;
                // const bisectorAngle = startAngle + angleDif;
                // const endX = centerX + labelsOffset * Math.cos(bisectorAngle);
                // const endY = centerY + labelsOffset * Math.sin(bisectorAngle);
                // const offset = endX > 150 ? 1 : -1;
                const builderData = getBuilderData(
                    ctx,
                    acc,
                    labelsOffset,
                    centerX,
                    centerY,
                    startAngle,
                    endAngle,
                    index,
                    chart.width - 15,
                );
                const {
                    bisectorAngle, endX, endY, labelEndX, offset, text, widthText,
                } = builderData;
                ctx.beginPath();
                ctx.save();
                ctx.moveTo(centerX, centerY);
                ctx.lineTo(endX, endY);
                ctx.strokeStyle = valueList[index]?.color || color;
                ctx.stroke();
                ctx.closePath();
                ctx.restore();
                ctx.beginPath();

                ctx.beginPath();
                ctx.save();
                ctx.font = 'bold 20px';
                const xText = offset > 0 ? endX + 8 : (endX - (widthText + 8));
                const yText = endY > 150 ? endY + 16 : endY - 8;
                ctx.fillStyle = valueList[index]?.color || color;
                ctx.fillText(text, xText, yText);
                ctx.restore();

                ctx.beginPath();
                ctx.save();
                ctx.moveTo(endX, endY);
                ctx.lineTo(labelEndX, endY);
                ctx.strokeStyle = valueList[index]?.color || color;
                ctx.stroke();
                ctx.closePath();
                ctx.restore();
                acc.push();
                acc.push({
                    xText,
                    yText,
                    ...builderData,
                });
                return acc;
            }, []);
        },
    };
    const dataSet = useMemo(() => ({
        events: ['click'],
        labels: valueList.map((item) => item.label),
        datasets: [
            {
                data: getFormatData.data,
                backgroundColor: getFormatData.backgroundColor,
                borderColor: getFormatData.borderColor,
                borderWidth: 1,
                radius: '40%',
            },
        ],
        plugins: {
            labels: {
                test: 'test',
            },
        },
    }), [getFormatData, valueList]);

    const tooltipHtml = {
        enabled: false,
        external: customTooltipData,
    };
    const options: any = {
        animation,
        // responsive: true,
        // maintainAspectRatio: false,
        cutoutPercentage: 30,
        outerRadius: 100,
        plugins: {
            tooltip: tooltipHtml,
            label: valueList,
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };

    useEffect(() => {
        if (getImage !== undefined) { if (valueList?.length) setTimeout(() => getImage(chartRef?.current?.toBase64Image('image/png')), 2000); }
    }, [valueList]);
    const getOptions = useMemo(() => options, [options, valueList]);
    if (valueList.length < 1) return <div />;
    return (
        <div style={{
            height, width, position: 'relative', background: '#fff',
        }}
        >
            {type === 'doughnut'
                ? <Doughnut ref={chartRef} options={getOptions} data={dataSet as any} />
                : <Pie plugins={[labels]} ref={chartRef} options={getOptions} data={dataSet as any} />}

            <TooltipWrapper
                isOpen={isShowModal}
                x={positionAndData.left}
                y={positionAndData.top}
                clearTimerTooltip={clearTimerTooltip}
                hideTooltip={hideTooltip}
            >
                {getTooltip && getTooltip(positionAndData?.data?.content)}
            </TooltipWrapper>
        </div>
    );
};

export default DoughnutChart;
