/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { useExpanded, useResizeColumns, useTable } from 'react-table';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';
import {
    IEntity,
    IPlatform,
    IQPSourcesWithSortingAndFiltering,
    ISourceFiltersRanges,
    ISourceRow,
    RangeFilter,
    Filter,
    SortOrder,
} from '@services/sources/types';
import { sourcesRtkQService } from '@services/sources';
import SortArrow from '@assets/images/sort-arrow.svg';
import Path from '@shared/lib/paths';
import useDebounce from '@shared/hooks/useDebounce';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import classnames from 'classnames';
import { useAppDispatch } from '@store/hooks';
import { sourceListActions } from '@store/source/list';
import { useSelector } from 'react-redux';
import sourceListSelectors from '@store/source/list/selectors';
import SvgIcon from '@components/SvgIcon';
import SubscribersFilter from '@components/sources/List/Filtres/Subscribers';
import ERFilter from '@components/sources/List/Filtres/ER';
import { ICategory } from '@store/types';
import QuickSVGLoader from '@components/QuickSVGLoader';
import {
    debounceTime, distinctUntilChanged, filter, map, ReplaySubject,
} from 'rxjs';
import { ModalError, SourceAddingListsModal, SourceListCreateModal } from '@entities/index';
import { message } from 'antd';
import SourcesListCell from './Cell';
import CategoryBlock from './Cell/Category/index';
import CategoryFilter from './Filtres/Category';
import SourcesFilter from './Filtres/Sources/index';
import PlatformFilter from './Filtres/Platform/index';
import AuthorFilter from './Filtres/Author';
import styles from './styles.module.scss';

interface IProps {
    platforms: IEntity[];
    categoriesData: ICategory[];
    // authorsData: IAuthors;
    // errorAuthors: FetchBaseQueryError;
    isLoadingPlatforms: boolean;
    isLoadingCategories: boolean;
    // isLoadingAuthors: boolean;
    platformByNameMap: Map<string, IEntity>;
    platformByIdMap: Map<number, IPlatform>;
    platformsIcon: Map<string, string>;
    // onGetAuthors: (params: IQPAuthors) => void;
    clearFilters: () => void;
    limitSourcesInPage?: number;
    isSelected?: boolean;
    activeRowIds?: number[];
    onSelectRow?: (value: ISourceRow) => void;
    ranges: ISourceFiltersRanges;
    searchValueFlow?: ReplaySubject<string>;
    invisibleIds?: number[];
}

const cellsMap = new Map([
    [0, 'sourceCell'],
    [1, 'linkCell'],
    [2, 'platformCell'],
    [3, 'authorCell'],
    [4, 'categoryCell'],
    [5, 'subscribersCell'],
    [6, 'viewsAvgCell'],
    [7, 'erCell'],
    [8, 'subscriberGrowthCell'],
    [9, 'actionsCell'],
    [10, 'priceCell'],
]);

const filtersKeysMap = new Map([
    ['type_id', 'Платформа'],
    ['author_id', 'Автор'],
    ['category_id', 'Категория'],
    ['title', 'Источник'],
    ['subscribers', 'Подписчики'],
    ['er_avg', 'ER'],
]);

const initialColumns = [
    {
        Header: 'Источник',
        accessor: 'source',
        maxWidth: 300,
        minWidth: 300,
    },
    {
        Header: 'Ссылка',
        accessor: 'link',
    },
    {
        Header: 'Платформа',
        accessor: 'platform',
        maxWidth: 120,
        minWidth: 120,
    },
    {
        Header: 'Автор',
        accessor: 'author',
    },
    {
        Header: 'Категория',
        accessor: 'category',
    },
    {
        Header: 'Подписчики',
        accessor: 'subscribers',
        maxWidth: 80,
        minWidth: 80,
    },
    {
        Header: 'Просмотры',
        accessor: 'viewsAvg',
        maxWidth: 80,
        minWidth: 80,
    },
    {
        Header: 'ER',
        accessor: 'er',
        maxWidth: 100,
        minWidth: 100,
    },
    {
        Header: 'Прирост подписчиков',
        accessor: 'subscriberGrowth',
    },
    {
        Header: 'Действия',
        accessor: 'actions',
        maxWidth: 150,
        minWidth: 150,
    },
];

const notSortAndFilter = ['Ссылка', 'Действия', 'Прирост подписчиков', 'Просмотры'];

const maxRangesMap = new Map([
    ['er_avr', 3100],
    ['subscribers', 500000000],
]);

const compareRange = (key: string, fromValue: number, toValue: number): boolean => (
    maxRangesMap.has(key) && (fromValue > 0 || toValue < maxRangesMap.get(key))
);

let startLoadByScroll = false;

const SourcesList: React.FC<IProps> = ({
    isLoadingPlatforms,
    isLoadingCategories,
    platforms,
    categoriesData,
    platformsIcon,
    platformByNameMap,
    platformByIdMap,
    clearFilters,
    limitSourcesInPage = 50,
    isSelected,
    activeRowIds,
    onSelectRow,
    ranges,
    searchValueFlow,
    invisibleIds = [],
}): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isClickArrow, setIsClickArrow] = useState<boolean>(false);
    const [activeRow, setActiveRow] = useState<number>(null);
    const [activeFilter, setActiveFilter] = useState<string>('');
    const [isFirstLoading, setFirstLoading] = useState<boolean>(true);
    const [isPreloading, setPreloading] = useState<boolean>(false);
    const [scrollProgress, setScrollProgress] = useState<number>(0);
    const [sourcesLocal, setSourcesLocal] = useState<any[]>([]);
    const [sourcesFromApi, setSourcesFromApi] = useState<ISourceRow[]>([]);
    const [data, setData] = useState<ISourceRow[]>([]);
    const [isModalAddingListOpen, setModalAddingListOpen] = useState<boolean>(false);
    const [addingListSourceId, setAddingListSourceId] = useState<number | null>(null);
    const [isSourceFavoriteAddingError, setIsSourceFavoriteAddingError] = useState<boolean>(false);
    const [isSourceFavoriteRemovingError, setIsSourceFavoriteRemovingError] = useState<boolean>(false);
    const [isOpenModalCreateList, setIsOpenModalCreateList] = useState<{ sourceId: number, sourceTitle: string }>(null);
    const currentPage = useSelector(sourceListSelectors.activePage);
    const currentSortingAndFilters = useSelector(sourceListSelectors.activeFilters);
    const { data: lists } = sourcesRtkQService.useGetListsQuery();
    const [updateSources, { isError: isErrorUpdateSources, error: updateSourcesError }] = sourcesRtkQService.useAddSourcesToListsMutation();
    const [removeSourcesFromList, { isError: isErrorRemoveSources, error: removeSourcesError }] = sourcesRtkQService.useRemoveSourcesFromListMutation();
    const [
        getNewSources,
        {
            data: sourcesNew,
            isFetching: isFetchingSources,
            isLoading: isLoadingSources,
            error,
        },
    ] = sourcesRtkQService.useLazyPostLoadSourcesInfinityQuery();
    const filledFiltersKeys = useMemo<string[]>(() => Object
        .keys(currentSortingAndFilters?.filterParams ?? [])
        .filter((key) => (
            (currentSortingAndFilters?.filterParams[key] as (string | number)[])?.length > 0
        )), [currentSortingAndFilters]);
    const filledRangesKeys = useMemo<string[]>(() => Object
        .keys(currentSortingAndFilters?.rangeParams ?? [])
        .filter((key) => (
            compareRange(key, currentSortingAndFilters?.rangeParams[key].from, currentSortingAndFilters?.rangeParams[key].to)
        )), [currentSortingAndFilters]);
    const nextPageToken = useMemo<string>(() => sourcesNew?.nextPageToken, [sourcesNew?.nextPageToken]);

    const columns = useMemo(() => (
        isSelected ? initialColumns.filter(({ accessor }) => accessor !== 'actions') : [...initialColumns]
    ), [isSelected]);

    const renderRowSubComponent = useCallback(
        ({ row }) => (row.values.category?.length
            ? <CategoryBlock categories={row.values.category} setIsClickArrow={setIsClickArrow} setActiveRow={setActiveRow} />
            : null),
        [],
    );

    const getFavoriteId = useMemo(() => {
        if (!Array.isArray(lists)) return null;
        const favorite = lists.find((item) => item.isFavorite);
        return favorite.id;
    }, [lists]);

    const selectThTitle = (title: string) => {
        if (title === activeFilter) {
            setActiveFilter('');
            return;
        }
        setActiveFilter(title);
    };

    const handlerCloseFilters = (): void => {
        setActiveFilter('');
    };

    const handlerNavigateToSourcePage = (dataId: number, column: string): void => {
        if (isSelected) {
            return;
        }
        if (column === 'source' && dataId) {
            navigate(`/${Path.Sources}/${dataId}`);
        }
    };

    const handlerCleanFilters = (): void => {
        dispatch(sourceListActions.clear());
        setPreloading(true);
        clearFilters();
    };

    const getThIcon = useCallback((element: string): JSX.Element => {
        if (
            (filledFiltersKeys?.length > 0
            && filledFiltersKeys.some((key) => filtersKeysMap.get(key) === element))
            || (filledRangesKeys?.length > 0
                && filledRangesKeys.some((key) => filtersKeysMap.get(key) === element))
        ) {
            return (
                <SvgIcon id="FilteredIcon" size={12} />
            );
        }
        if (filtersKeysMap.get(currentSortingAndFilters?.orderBy) === element) {
            return (
                <div
                    style={{
                        transform:
                            currentSortingAndFilters?.orderDirection === SortOrder.asc
                                ? 'scale(1, -1)'
                                : '',
                        height: 16,
                    }}
                >
                    <SvgIcon id="SortingIcon" size={16} />
                </div>
            );
        }
        return (
            <img src={SortArrow} style={activeFilter === element ? { transform: 'rotate(180deg)' } : {}} alt="SortArrow" />
        );
    }, [currentSortingAndFilters, filledFiltersKeys, activeFilter]);

    const getThTitle = useCallback((element: string) => {
        // TODO - N.Sychev скоро заменим на другой компонент
        if (element === 'Прирост подписчиков' || element === 'Действия') return <span>{element}</span>;
        if (notSortAndFilter.includes(element)) return <span style={{ padding: 10 }}>{element}</span>;

        return (
            <div
                role="button"
                tabIndex={0}
                onKeyPress={() => null}
                onClick={() => selectThTitle(element)}
                style={{ cursor: 'pointer' }}
                className={styles.title}
            >
                <span>
                    {element}
                </span>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {getThIcon(element)}
                </div>
            </div>
        );
    }, [activeFilter, currentSortingAndFilters, getThIcon]);

    const handlerSort = useCallback((field: string, direction: SortOrder): void => {
        const newFilters = {
            ...currentSortingAndFilters, orderBy: field, orderDirection: direction, page: currentPage,
        };
        dispatch(sourceListActions.setFilters(newFilters));
        setPreloading(true);
    }, [currentPage, currentSortingAndFilters]);

    const handlerSelectFilter = useCallback((ids: number[], filterName: string): void => {
        const newFilters = {
            ...currentSortingAndFilters,
            filterParams: { ...currentSortingAndFilters?.filterParams, [filterName]: ids },
            page: 1,
        } as IQPSourcesWithSortingAndFiltering;
        dispatch(sourceListActions.setFilters(newFilters));
        setPreloading(true);
    }, [currentPage, currentSortingAndFilters]);

    const handlerSelectRangeFilter = useCallback((value: [number, number], filterName: string): void => {
        const newFilters: IQPSourcesWithSortingAndFiltering = {
            ...currentSortingAndFilters,
            rangeParams: { ...currentSortingAndFilters?.rangeParams, [filterName]: { from: value[0], to: value[1] } },
            page: 1,
        };
        dispatch(sourceListActions.setFilters(newFilters));
        setPreloading(true);
    }, [currentPage, currentSortingAndFilters]);

    useDebounce(
        () => {
            // const params = {
            //     ...currentSortingAndFilters.filterParams,
            //     ...currentSortingAndFilters.rangeParams,
            // } as (Filter | RangeFilter);
            // const filters = {
            //     ...currentSortingAndFilters,
            //     filterParams: params,
            //     rangeParams: null,
            // } as IQPSourcesWithSortingAndFiltering;
            getNewSources({ ...currentSortingAndFilters, limit: limitSourcesInPage });
        },
        500,
        [currentSortingAndFilters, limitSourcesInPage],
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 100,
            maxWidth: 700,
        }),
        [],
    );

    /**
     * Фильтруем существующий список источников, если указаны id источников, которые нужно скрыть
     * (например при добавлении в лонглист, скрываем источники, находящиеся в лонглисте)
     */
    const dataFiltered = useMemo(() => (
        invisibleIds?.length > 0
            ? (sourcesNew?.sources ?? [])
                .filter(({ id }) => !invisibleIds.includes(id))
            : sourcesNew?.sources
    ), [sourcesNew, invisibleIds]);

    useEffect(() => {
        const errorBase = error as FetchBaseQueryError;
        if (
            errorBase?.status === 404
            || errorBase?.status === 500
            || errorBase?.status === 'FETCH_ERROR'
        ) {
            setSourcesLocal([]);
            setSourcesFromApi([]);
            setData([]);
            return;
        }
        let result = [];
        if (startLoadByScroll) {
            result = [...sourcesLocal];
        } else {
            setSourcesLocal([]);
            setSourcesFromApi([]);
        }
        result = [...result, ...(dataFiltered?.map((item: ISourceRow) => ({
            source: item?.title ?? '---',
            link: { link: item?.link ?? null, title: item?.link ? 'Ссылка' : '---' },
            platform: item?.sourceType.title,
            author: item?.author.title ?? '---',
            category: item?.categories.map(({ title }) => title),
            subscribers: item?.subscribers,
            viewsAvg: item?.viewsAvg,
            er: item?.er,
            actions: item.id,
            price: 0,
            dataId: item?.id,
            subscriberGrowth: {
                day: [item?.subscriberGrowth.day, item?.subscriberGrowthPercent.day],
                week: [item?.subscriberGrowth.week, item?.subscriberGrowthPercent.week],
                month: [item?.subscriberGrowth.month, item?.subscriberGrowthPercent.month],
            },
            lists: item?.lists,
            logoUrl: item?.logoUrl,
        })) ?? [])];
        setSourcesLocal(result);
        setSourcesFromApi((prev) => ([...prev, ...(dataFiltered ?? [])]));
        if (startLoadByScroll) {
            setTimeout(() => { startLoadByScroll = false; }, 500);
        }
        setData(result);
    }, [dataFiltered, error]);

    const handlerSelectRow = useCallback((value: number): void => {
        if (isSelected && onSelectRow) {
            const actual = sourcesFromApi?.find(({ id }) => id === value);
            if (actual) {
                onSelectRow(actual);
            }
        }
    }, [sourcesFromApi]);

    const noDataFound = useMemo<boolean>(() => (
        !isPreloading && !isFirstLoading && !isFetchingSources && !isLoadingSources && data?.length <= 0
    ), [isPreloading, isFirstLoading, isFetchingSources, isLoadingSources, data]);
    const loadingData = useMemo<boolean>(() => (
        isPreloading || isFirstLoading || isFetchingSources || isLoadingSources
    ), [isPreloading, isFirstLoading, isFetchingSources, isLoadingSources]);

    const platformsFromApi = useMemo<IEntity[]>(() => (
        !isLoadingPlatforms ? platforms : []
    ), [isLoadingPlatforms, platforms]);

    const isFiltered = useMemo<boolean>((): boolean => {
        const filtersKeys = Object.keys(currentSortingAndFilters?.filterParams || {});
        const rangeFiltersKeys = Object.keys(currentSortingAndFilters?.rangeParams || {});
        let result = false;
        if (currentSortingAndFilters?.searchValue?.length >= 3) {
            return true;
        }
        if (filtersKeys?.length > 0) {
            result = filtersKeys.some((item) => (
                (currentSortingAndFilters?.filterParams[item] as (string | number)[])?.length > 0
            ));
        }
        if (!result && rangeFiltersKeys?.length > 0) {
            result = rangeFiltersKeys.some((item) => (
                currentSortingAndFilters?.rangeParams[item]?.from > 0
                || currentSortingAndFilters?.rangeParams[item]?.to < maxRangesMap.get(item)
            ));
        }
        return result;
    }, [currentSortingAndFilters]);

    const filterComponent = useMemo(() => [
        {
            name: 'Источник',
            component: <SourcesFilter
                sortState={{
                    orderBy: currentSortingAndFilters?.orderBy,
                    orderDirection: currentSortingAndFilters?.orderDirection,
                }}
                onSort={handlerSort}
                onClose={handlerCloseFilters}
            />,
        },
        {
            name: 'Платформа',
            component: <PlatformFilter
                platformsFromApi={platformsFromApi}
                onSelect={handlerSelectFilter}
                currentSortingAndFilters={currentSortingAndFilters}
                onClose={handlerCloseFilters}
                platformByIdMap={platformByIdMap}
                platformsIcon={platformsIcon}
                platformByNameMap={platformByNameMap}
            />,
        },
        {
            name: 'Подписчики',
            component: <SubscribersFilter
                currentSortingAndFilters={currentSortingAndFilters}
                onSort={handlerSort}
                onSelect={handlerSelectRangeFilter}
                onClose={handlerCloseFilters}
                rangeMin={ranges?.subscribers?.from ?? 0}
                rangeMax={ranges?.subscribers?.to ?? 100}
            />,
        },
        {
            name: 'Автор',
            component: <AuthorFilter
                size="medium"
                // authorsData={authorsData}
                // errorAuthors={errorAuthors}
                // onGetAuthors={onGetAuthors}
                currentSortingAndFilters={currentSortingAndFilters}
                onSelect={handlerSelectFilter}
                onClose={handlerCloseFilters}
                // isLoading={isLoadingAuthors}
            />,
        },
        {
            name: 'Категория',
            component: <CategoryFilter
                size="medium"
                isLoading={isLoadingCategories}
                categoriesData={categoriesData}
                currentSortingAndFilters={currentSortingAndFilters}
                onSelect={handlerSelectFilter}
                onClose={handlerCloseFilters}
            />,
        },
        {
            name: 'ER',
            component: <ERFilter
                currentSortingAndFilters={currentSortingAndFilters}
                onSort={handlerSort}
                onSelect={handlerSelectRangeFilter}
                onClose={handlerCloseFilters}
                rangeMin={ranges?.erAvg?.from ?? 0}
                rangeMax={ranges?.erAvg?.to ?? 100}
            />,
        },
        // { name: 'Прирост подписчиков', component: <SubscriberGrowthFilter /> },
        // { name: 'Цена', component: <PriceFilter /> },
    ], [
        currentSortingAndFilters,
        handlerCloseFilters,
        platformsFromApi,
        handlerSelectFilter,
        categoriesData,
        isLoadingCategories,
    ]);

    const componentFilter = useMemo<JSX.Element>(() => (
        filterComponent.find((item) => item.name === activeFilter)?.component
    ), [
        activeFilter,
        currentSortingAndFilters,
        categoriesData,
        isLoadingCategories,
    ]);

    const getComponentFilter = (title: string): JSX.Element | null => {
        if (title === activeFilter) return componentFilter;
        return null;
    };

    const containerRef = useRef(null);
    const [isShowBottomPadding, setIsShowBottomPadding] = useState(false);
    useLayoutEffect(() => {
        setIsShowBottomPadding(containerRef.current.offsetWidth < 1420);
        setTimeout(() => setFirstLoading(false), 600);
    }, []);

    useEffect(() => {
        if (isPreloading && isFetchingSources) {
            setPreloading(false);
        }
    }, [isPreloading, isFetchingSources]);

    useEffect(() => {
        if (scrollProgress > 98 && !loadingData && !startLoadByScroll && nextPageToken) {
            startLoadByScroll = true;
            setScrollProgress(0);
            getNewSources({
                ...currentSortingAndFilters,
                limit: limitSourcesInPage,
                prevPageToken: nextPageToken,
            });
        }
    }, [scrollProgress, dataFiltered, loadingData, startLoadByScroll, nextPageToken]);

    const handlerStartSearch = useCallback((value: string): void => {
        const newFilters: IQPSourcesWithSortingAndFiltering = {
            ...currentSortingAndFilters,
            limit: limitSourcesInPage,
            searchValue: value,
            page: 1,
        };
        dispatch(sourceListActions.setFilters(newFilters));
    }, [currentSortingAndFilters, limitSourcesInPage]);

    useEffect(() => {
        if (searchValueFlow) {
            const search$ = searchValueFlow.pipe(
                map((event) => event),
                debounceTime(500),
                filter((searchParam) => (!searchParam || searchParam.length >= 3)),
                distinctUntilChanged(),
            );

            const subscription = search$.subscribe((res) => handlerStartSearch(res));
            return () => {
                subscription.unsubscribe();
            };
        }
        return () => null;
    }, []);
    const handleAddToFavorite = useCallback((sourceId: number): void => {
        if (typeof getFavoriteId !== 'number') return null;
        updateSources({
            list_ids: [getFavoriteId],
            source_ids: [sourceId],
        });
        setData((prev) => prev.map((source) => (
            { ...source, lists: sourceId === (source as any).dataId ? [...source.lists, getFavoriteId] : source.lists })));
    }, [getFavoriteId]);

    const handleRemoveFromFavorite = useCallback((sourceId: number): void => {
        if (typeof getFavoriteId !== 'number') return null;
        removeSourcesFromList({
            list_id: getFavoriteId,
            source_ids: [sourceId],
        });
        setData((prev) => prev.map((source) => (
            { ...source, lists: sourceId === (source as any).dataId ? source.lists.filter((id) => id !== getFavoriteId) : source.lists })));
    }, [getFavoriteId]);
    const handleChangeStatusFavorite = (sourceId: number, source) => {
        if (source?.lists?.some((id) => id === getFavoriteId)) {
            handleRemoveFromFavorite(sourceId);
            return;
        }
        handleAddToFavorite(sourceId);
    };
    const onClickAddToList = (listsForAdd: number[], sourceId: number): void => {
        // setModalAddingListOpen(true);
        // setAddingListSourceId(sourceId);
        updateSources({
            list_ids: listsForAdd,
            source_ids: [sourceId],
        }).then(() => {
            getNewSources({ ...currentSortingAndFilters, limit: limitSourcesInPage });
        }).catch((err) => {
            message.error('При добавлении в списки произошла ошибка');
            // eslint-disable-next-line no-console
            console.log('При добавлении в списки произошла ошибка', err);
        });
    };
    const handleCreateList = (sourceId: number): void => {
        const { title } = sourcesFromApi.find((item) => item.id === sourceId);
        setIsOpenModalCreateList({ sourceId, sourceTitle: title });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        visibleColumns,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useResizeColumns,
        useExpanded,
    );

    /**
     * Определяет расположение скролла в % от высоты элемента
     * @param {React.UIEvent<HTMLDivElement>} event - событие скролла на элементе
     */
    const handlerScroll = useCallback((event: React.UIEvent<HTMLDivElement>): void => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;
        const { scrollTop } = event.currentTarget;
        const progress = ((scrollTop + containerHeight) / scrollHeight) * 100;
        setScrollProgress(progress);
    }, [data]);
    const getListAcceptedLists = (listsInSource: number[]) => {
        const formatLists = lists?.filter(({ isFavorite }) => !isFavorite)
            .map(({ id, title }) => ({
                id,
                label: title,
                isSelect: false,
            }));
        const localLists = formatLists || [];
        const result = localLists.filter((current) => !listsInSource.some((id) => id === current.id));
        return result;
    };

    useEffect(() => {
        if (isErrorUpdateSources) {
            setIsSourceFavoriteAddingError(isErrorUpdateSources);
        }
    }, [isErrorUpdateSources]);

    useEffect(() => {
        if (isErrorRemoveSources) {
            setIsSourceFavoriteRemovingError(isErrorRemoveSources);
        }
    }, [isErrorRemoveSources]);

    return (
        <div ref={containerRef} className={styles.sourcesList_root}>
            <div className={styles.tableContainer} onScroll={handlerScroll}>
                <table {...getTableProps()} className={styles.table}>
                    <thead className={styles.thead}>
                        {headerGroups.map((headerGroup, idxTr) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <tr key={idxTr} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, idxColumns) => (
                                    <th
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={idxColumns}
                                        {...column.getHeaderProps({
                                            // style: { minWidth: column.minWidth, maxWidth: column.maxWidth },
                                        })}
                                        className={styles.th}
                                    >
                                        {getThTitle(column.render('Header'))}
                                        {getComponentFilter(column.render('Header'))}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {
                            !loadingData && !noDataFound && isFiltered && (
                                <tr
                                    key={-1}
                                >
                                    <td
                                        className={styles.tableControls}
                                        colSpan={10}
                                    >
                                        <span className={styles.tableControls_label}>
                                            {`Найдено ${(sourcesNew?.total ?? 0).toLocaleString('ru-RU')} источников`}
                                        </span>
                                        <button
                                            className={styles.tableControls_cleanBtn}
                                            type="button"
                                            onClick={handlerCleanFilters}
                                        >
                                            сбросить все фильтры
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
                    </thead>
                    {
                        (!loadingData || startLoadByScroll) && (
                            <tbody {...getTableBodyProps()} className={styles.tbody}>
                                {
                                    rows.map((row, idxRow: number) => {
                                        prepareRow(row);
                                        return (
                                            <>
                                                <tr
                                                    className={styles.tr}
                                                    key={row.id}
                                                >
                                                    {row.cells.map((cell, idxCell: number) => (
                                                        <td
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={idxCell}
                                                            {...cell.getCellProps({
                                                                style: {
                                                                    minWidth: cell.column.minWidth,
                                                                    maxWidth: cell.column.maxWidth,
                                                                },
                                                            })}
                                                            className={styles.td}
                                                        >
                                                            <SourcesListCell
                                                                isFavorite={row?.original?.lists?.some((id) => id === getFavoriteId)}
                                                                lists={getListAcceptedLists(row?.original?.lists)}
                                                                cellName={cellsMap.get(idxCell)}
                                                                index={idxRow}
                                                                dataId={row?.original?.dataId}
                                                                rowNumber={idxRow + ((currentPage - 1) * 15)}
                                                                value={cell.render('Cell')}
                                                                cellDescription={cell}
                                                                isClickArrow={isClickArrow}
                                                                setIsClickArrow={setIsClickArrow}
                                                                activeRow={activeRow}
                                                                setActiveRow={setActiveRow}
                                                                onClick={handlerNavigateToSourcePage}
                                                                isSelected={isSelected ?? false}
                                                                activeRowIds={activeRowIds ?? []}
                                                                onSelect={handlerSelectRow}
                                                                onClickAddToList={onClickAddToList}
                                                                onCreateList={handleCreateList}
                                                                onSetInFavorite={
                                                                    (sourceId) => handleChangeStatusFavorite(sourceId, row?.original)
                                                                }
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                                {
                                                    activeRow === idxRow && isClickArrow
                                                        ? (
                                                            <tr key={`${row.id}_category`}>
                                                                <td colSpan={visibleColumns.length}>
                                                                    {renderRowSubComponent({ row })}
                                                                </td>
                                                            </tr>
                                                        )
                                                        : null
                                                }
                                            </>
                                        );
                                    })
                                }
                            </tbody>

                        )
                    }
                </table>
                {
                    startLoadByScroll && (
                        <div style={{
                            height: 150,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                            <QuickSVGLoader size="large" />
                        </div>
                    )
                }
                {
                    loadingData && !startLoadByScroll && (
                        <Skeleton width="100%" height={85} count={limitSourcesInPage} />
                    )
                }
                {
                    noDataFound && (
                        <div className={classnames(styles.noData, styles.noData_value)}>
                            Данные не найдены
                        </div>
                    )
                }
            </div>
            {
                isOpenModalCreateList && (
                    <SourceListCreateModal
                        onClose={() => setIsOpenModalCreateList(null)}
                        sourceId={isOpenModalCreateList.sourceId}
                        sourceTitle={isOpenModalCreateList.sourceTitle}
                    />
                )
            }
            <SourceAddingListsModal
                isVisible={isModalAddingListOpen}
                onClose={() => setModalAddingListOpen(false)}
                sourceIds={[addingListSourceId]}
                selectedSourceTitle={sourcesFromApi?.find(({ id }) => id === addingListSourceId)?.title}
            />

            <ModalError
                visible={isSourceFavoriteAddingError}
                type="warning"
                title="Ошибка"
                // @ts-ignore TODO: remove ts-ignore
                text={updateSourcesError?.data?.detail ?? 'Произошла ошибка при добавлении в избранное'}
                onClose={() => setIsSourceFavoriteAddingError(false)}
            />

            <ModalError
                visible={isSourceFavoriteRemovingError}
                type="warning"
                title="Ошибка"
                // @ts-ignore TODO: remove ts-ignore
                text={removeSourcesError?.data?.detail ?? 'Произошла ошибка при удалении из избранного'}
                onClose={() => setIsSourceFavoriteRemovingError(false)}
            />
        </div>
    );
};

export default React.memo(SourcesList);
