/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React from 'react';
import styles from './styles.module.scss';
import RadioButton from '../campaign/PlatformsSelectButtons/RadioButton';
import { ISimpleEntity } from '../../store/types';

interface IColumnRadioButtonsProps {
    listButtons: ISimpleEntity[];
    activeRadioButtonId?: number | string;
    disabled?: boolean;
    select?: (id: number) => void;
}
const InitailChart: React.FC<IColumnRadioButtonsProps> = ({
    listButtons,
    activeRadioButtonId,
    disabled,
    select,
}): JSX.Element => {
    if (!listButtons?.length) return null;
    return (
        <div className={styles.rootColumnRadionButtons}>
            {
                listButtons.map((button) => (
                    <RadioButton
                        key={button.id}
                        tagId={`${button.value}`}
                        onClick={select}
                        value={+button.id}
                        isActive={+button.id === activeRadioButtonId}
                        className={styles.radioButton}
                        disabled={disabled}
                    >
                        {button.name}
                    </RadioButton>
                ))
            }
        </div>
    );
};

export default InitailChart;
