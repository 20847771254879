import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
// import RssIcon from '@assets/images/sourceTypes/rss.svg';
// import VkIcon from '@assets/images/sourceTypes/vk.svg';
// import OkIcon from '@assets/images/sourceTypes/ok.svg';
// import TgIcon from '@assets/images/sourceTypes/tg.svg';
// import ZenIcon from '@assets/images/sourceTypes/zen.svg';
// import RuTubeLogo from '@assets/images/ruTube.svg';
// import YouTubeLogo from '@assets/images/youTube.svg';
import CustomCheckbox from '@components/Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';
import { IEntity, IPlatform, IQPSourcesWithSortingAndFiltering } from '@services/sources/types';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IProps {
    isOpen?: boolean;
    platformsFromApi: IEntity[];
    onSelect: (ids: number[], name: string) => void;
    onClose: () => void;
    currentSortingAndFilters: IQPSourcesWithSortingAndFiltering;
    platformByNameMap: Map<string, IEntity>;
    platformByIdMap: Map<number, IPlatform>;
    platformsIcon: Map<string, string>;
}

type PlatformCheckbox = { name: string, icon: string, checked: boolean };

// const platformByNameMap: Map<string, IEntity> = new Map([
//     ['RSS', { id: 1, title: 'rss' }],
//     ['Vkontakte', { id: 2, title: 'vk' }],
//     ['Telegram', { id: 3, title: 'tg' }],
//     ['Odnoklassniki', { id: 4, title: 'ok' }],
//     ['Дзен', { id: 5, title: 'dzen' }],
//     ['RuTube', { id: 6, title: 'rutube' }],
//     ['YouTube', { id: 7, title: 'youtube' }],
// ]);
// const platformByIdMap: Map<number, IPlatform> = new Map([
//     [1, { id: 1, title: 'RSS', icon: 'rss' }],
//     [2, { id: 2, title: 'Vkontakte', icon: 'vk' }],
//     [3, { id: 3, title: 'Telegram', icon: 'tg' }],
//     [4, { id: 4, title: 'Odnoklassniki', icon: 'ok' }],
//     [5, { id: 5, title: 'Дзен', icon: 'dzen' }],
//     [6, { id: 6, title: 'RuTube', icon: 'rutube' }],
//     [7, { id: 7, title: 'YouTube', icon: 'youtube' }],
// ]);

// const initialStatePlatformIcon = [
//     { name: 'Vkontakte', icon: 'vk', checked: false },
//     { name: 'RSS', icon: 'rss', checked: false },
//     { name: 'Дзен', icon: 'dzen', checked: false },
//     { name: 'Telegram', icon: 'tg', checked: false },
//     { name: 'Odnoklassniki', icon: 'ok', checked: false },
//     { name: 'RuTube', icon: 'rutube', checked: false },
//     { name: 'YouTube', icon: 'youtube', checked: false },
// ];

// const PlatformsIcon = new Map([
//     ['rss', 'RssLogo'],
//     ['vk', 'VkLogo'],
//     ['ok', 'OkLogo'],
//     ['tg', 'TgLogo'],
//     ['dzen', 'ZenLogo'],
//     ['rutube', 'RuTubeLogo'],
//     ['youtube', 'YouTubeLogo'],
// ]);

const initialStatePlatforms: PlatformCheckbox[] = [];

const getStatePlatforms = (platforms: IEntity[], platformsMap: Map<number, IPlatform>): PlatformCheckbox[] => (
    initialStatePlatforms?.length < 1
        ? (
            platforms?.map((item) => (platformsMap.get(item.id)
                ? {
                    name: platformsMap.get(item.id).title,
                    icon: platformsMap.get(item.id).icon,
                    checked: false,
                }
                : {
                    name: item.title,
                    icon: null,
                    checked: false,
                }))
        )
        : initialStatePlatforms
);

const PlatformFilter: React.FC<IProps> = ({
    platformsFromApi,
    currentSortingAndFilters,
    onSelect,
    onClose,
    platformsIcon,
    platformByIdMap,
    platformByNameMap,
    isOpen = true,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const [platforms, setPlatforms] = useState<PlatformCheckbox[]>(
        () => getStatePlatforms(platformsFromApi, platformByIdMap),
    );
    // const [isAllCheckedState, setAllChecked] = useState<boolean>(false);

    const checkedFiltersIds = useMemo<number[]>(() => (
        currentSortingAndFilters.filterParams?.type_id as number[]
    ), [currentSortingAndFilters]);

    const isAllChecked = useMemo<boolean>(() => (
        (platforms ?? []).every(({ checked }) => checked)
    ), [platforms]);

    const selectAllPlatform = () => {
        const result = [
            ...getStatePlatforms(platformsFromApi, platformByIdMap)
                .map((item) => ({ ...item, checked: !isAllChecked })),
        ];
        setPlatforms(result);
        onSelect(!isAllChecked ? [...platformByNameMap.values()].map(({ id }) => id) : [], 'type_id');
    };
    const handlerCheckedPlatform = (name: string) => {
        setPlatforms(platforms.map((item) => {
            if (name !== item.name && item.checked) return item;
            if (name === item.name && !item.checked) return ({ name: item.name, icon: item.icon, checked: true });
            if (name === item.name && item.checked) return ({ name: item.name, icon: item.icon, checked: false });
            return ({ name: item.name, icon: item.icon, checked: false });
        }));
        const ids = (checkedFiltersIds ?? []).includes(platformByNameMap.get(name)?.id ?? -1)
            ? (checkedFiltersIds ?? []).filter((id) => id !== platformByNameMap.get(name)?.id ?? -1)
            : [...(checkedFiltersIds ?? []), platformByNameMap.get(name)?.id ?? -1];
        onSelect(ids, 'type_id');
    };

    useEffect(() => {
        const ids = currentSortingAndFilters.filterParams?.type_id as number[];
        if (!(ids?.length > 0)) {
            return;
        }
        const checkedIds = [
            ...(platforms ?? []).filter((item) => item.checked).map((item) => platformByNameMap.get(item.name).id),
            ...(ids ?? []),
        ];
        if (ids?.length === platformByNameMap.size) {
            setPlatforms(platforms.map((item) => ({ name: item.name, icon: item.icon, checked: true })));
        } else if (ids?.length > 0) {
            setPlatforms([
                ...getStatePlatforms(platformsFromApi, platformByIdMap).map((item) => (
                    { ...item, checked: checkedIds.includes(platformByNameMap.get(item.name).id) }
                )),
            ]);
        }
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.platformFilter} ref={refModal}>
            <div className={styles.platformFilter_listPlatforms}>
                <div className={styles.platformFilter_headCheckBox}>
                    <CustomCheckbox
                        id="1"
                        checked={isAllChecked}
                        isRetinaSize={false}
                        name="Все"
                        onChecked={() => selectAllPlatform()}
                        textClassName={styles.checkboxLabel}
                        isError={false}
                        isDisabled={false}
                    />
                </div>
                <div className={styles.platformFilter_listIcon}>
                    {(platforms ?? []).map((item) => (
                        <div key={item.name} className={styles.platformFilter_lineIcon}>
                            <CustomCheckbox
                                id="1"
                                checked={item.checked}
                                isRetinaSize={false}
                                name={null}
                                labelElement={(
                                    <div className={styles.platformFilter_icon} style={{ marginLeft: -12 }}>
                                        <SvgIcon id={platformsIcon.get(item.icon)} size={24} />
                                    </div>
                                )}
                                onChecked={() => handlerCheckedPlatform(item.name)}
                                isError={false}
                                isDisabled={false}
                            />
                            <button
                                type="button"
                                onClick={() => handlerCheckedPlatform(item.name)}
                                className={styles.platformFilter_label}
                            >
                                {item.name}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlatformFilter;
