import {
    IEntity, IPlatform, IQPSourcesWithSortingAndFiltering, SortOrder,
} from '@services/sources/types';
import { SourcesFiltersTypes } from '@sections/Source/types';
import { LogoIconsIds } from '@app/constants/icons';

const platformByNameMap: Map<string, IEntity> = new Map([
    ['RSS', { id: 1, title: 'rss' }],
    ['Vkontakte', { id: 2, title: 'vk' }],
    ['Telegram', { id: 3, title: 'tg' }],
    ['Odnoklassniki', { id: 4, title: 'ok' }],
    ['Дзен', { id: 5, title: 'dzen' }],
    ['RuTube', { id: 6, title: 'rutube' }],
    ['YouTube', { id: 7, title: 'youtube' }],
    ['VkVideo', { id: 8, title: 'vk_video' }],
]);

const platformByIdMap: Map<number, IPlatform> = new Map([
    [1, { id: 1, title: 'RSS', icon: 'rss' }],
    [2, { id: 2, title: 'Vkontakte', icon: 'vk' }],
    [3, { id: 3, title: 'Telegram', icon: 'tg' }],
    [4, { id: 4, title: 'Odnoklassniki', icon: 'ok' }],
    [5, { id: 5, title: 'Дзен', icon: 'dzen' }],
    [6, { id: 6, title: 'RuTube', icon: 'rutube' }],
    [7, { id: 7, title: 'YouTube', icon: 'youtube' }],
    [8, { id: 8, title: 'VkVideo', icon: 'vk_video' }],
]);

const platformsIcon: Map<string, string> = new Map([
    ['rss', LogoIconsIds.RssLogo],
    ['vk', LogoIconsIds.VkLogo],
    ['ok', LogoIconsIds.OkLogo],
    ['tg', LogoIconsIds.TgLogo],
    ['dzen', LogoIconsIds.ZenLogo],
    ['rutube', LogoIconsIds.RuTubeLogo],
    ['youtube', LogoIconsIds.YouTubeLogo],
    ['vk_video', LogoIconsIds.VkVideo],
]);

const platformsShortsMap: Map<string, IEntity> = new Map([
    ['rss', { id: 1, title: 'RSS' }],
    ['vk', { id: 2, title: 'Vkontakte' }],
    ['tg', { id: 3, title: 'Telegram' }],
    ['ok', { id: 4, title: 'Odnoklassniki' }],
    ['dzen', { id: 5, title: 'Дзен' }],
    ['rutube', { id: 6, title: 'rutube' }],
    ['youtube', { id: 7, title: 'youtube' }],
    ['vk_video', { id: 8, title: 'vk_video' }],
]);

const initialFilters: IQPSourcesWithSortingAndFiltering = {
    page: 1,
    limit: 15,
    orderBy: 'subscribers',
    orderDirection: SortOrder.desc,
    filterParams: {
        [SourcesFiltersTypes.platforms]: [],
        [SourcesFiltersTypes.categories]: [],
        [SourcesFiltersTypes.authors]: [],
    },
    rangeParams: {
        [SourcesFiltersTypes.subscribers]: { from: 0, to: 1000000 },
        [SourcesFiltersTypes.er]: { from: 0, to: 100 },
        [SourcesFiltersTypes.views]: { from: 0, to: 1000000 },
    },
};

export default {
    platformByNameMap,
    platformByIdMap,
    platformsShortsMap,
    platformsIcon,
    initialFilters,
};
