import moment from 'moment';
import { DateRangePickerValue } from '../DateRangePicker/types';

/**
 * @returns [from, to] - type moment
 */
export const getPeriodForCurrentWeek = (days?: number): DateRangePickerValue => {
    const currentDate = moment(new Date());
    const from = currentDate.clone().subtract(days || 7, 'd');
    return [from, currentDate];
};
