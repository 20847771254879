import { FetchStatus } from 'store/types';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import { IUserInfo } from 'services/common/types';

export const enum ModalAnswerType {
    undefined = 0,
    accept = 1,
    reject = 2,
}

export const enum CampaignActionTypes {
    undefined = 0,
    view = 1,
    edit = 2,
    create = 3,
    tracking = 4,
}

export const enum ProcessModalStatus {
    undefined = 0,
    failed = 1,
    success = 2,
    process = 3,
}

export const enum CampaignActiveModalType {
    undefined = 0,
    cancelCreation = 1,
    switchingPage = 2,
    deletingCampaign = 3,
    activatingCampaign = 4,
    process = 5,
}

export const enum FetchCheckStatus {
    undefined = 0,
    process = 1,
    failed = 2,
    correct = 3,
    invalid = 4,
}

export interface ISingleCampaignUsersModel {
    campaignId: number;
    users: IUserInfo[];
}

export interface ICampaignState {
    campaigns: ICampaignDataModel[];
    campaign: ICampaignDataModel,
    fetchCreateStatus: FetchStatus;
    fetchUsersStatus: FetchStatus;
    fetchCampaignStatus: FetchStatus;
    updateCampaignStatus: FetchStatus;
    deleteCampaignStatus: FetchStatus;
    singleCampaignUsersStatus: FetchStatus;
    addUserStatus: FetchStatus;
    creatingBegun: boolean;
    modalAnswer: ModalAnswerType;
    activeModal: CampaignActiveModalType;
    needClearCampaignPage: boolean;
    fetchErrors: string;
    userIds: string[];
    users: IUserInfo[];
    singleCampaignUsers: ISingleCampaignUsersModel;
    actionCampaign: CampaignActionTypes;
    processModalMessage: string;
    processModalStatus: ProcessModalStatus;
}
