import React, { useCallback, useMemo, useState } from 'react';
import { ISimpleEntity } from '@store/types';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { IComparisonSummaryData, IFilterCampaigns, ISimplePeriod } from '@store/campaign/comparison';
import { Button } from '@shared/index';
import CampaignComparisonResultName from './Modal/ComparisonName';
import CampaignComparisonPlatforms from './DropDownComponents/Platforms';
import CampaignComparisonStatistics from './DropDownComponents/Statistics';
import CampaignComparisonMediaType from './DropDownComponents/MediaType';
import CampaignComparisonTonality from './DropDownComponents/Tonality';
import CampaignComparisonCategory from './DropDownComponents/Category';
import CampaignComparisonRegion from './DropDownComponents/Region';
import styles from './styles.module.scss';

interface IProps {
    isSavedAnalysis: boolean;
    comparisonPeriod: ISimplePeriod;
    filters: IFilterCampaigns[];
    referenceCampaign: ICampaignDataModel | null;
    compareCampaigns: ICampaignDataModel[];
    comparisonSummary: IComparisonSummaryData[];
    onSaveResult: (name: string, startDate: string, endDate: string) => void;
}

const components: ISimpleEntity[] = [
    { id: 'campaign-comparison-authors', name: 'Авторы', value: '' },
    { id: 'campaign-comparison-messages', name: 'Сообщения', value: 'quantityPost' },
    { id: 'campaign-comparison-views', name: 'Просмотры', value: 'quantityViews' },
    { id: 'campaign-comparison-likes', name: 'Лайки', value: 'quantityLikes' },
    { id: 'campaign-comparison-reaction', name: 'Реакции', value: 'quantityReactions' },
    { id: 'campaign-comparison-comments', name: 'Комментарии', value: 'quantityComments' },
];

const CampaignComparisonResult: React.FC<IProps> = ({
    isSavedAnalysis,
    comparisonPeriod,
    filters,
    referenceCampaign,
    compareCampaigns,
    comparisonSummary,
    onSaveResult,
}): JSX.Element => {
    const [comparisonName, setName] = useState<string>('');
    const [nameModalIsOpen, setOpenStatus] = useState<boolean>(false);

    const headerContent = useMemo<JSX.Element>(() => (
        <div className={styles.resultAnalytics_introInfo}>
            <span className={styles.resultAnalytics_orderPeriod}>
                {
                    `Период сравнения: ${comparisonPeriod.start.toLocaleDateString('ru-RU')} - ${comparisonPeriod.end.toLocaleDateString('ru-RU')}`
                }
            </span>
            <span className={styles.resultAnalytics_referenceCampaign}>
                Эталонная кампания:
                <b style={{ color: 'black', marginLeft: '5px' }}>{referenceCampaign?.name ?? '-'}</b>
            </span>
        </div>
    ), [referenceCampaign, comparisonPeriod]);

    const handlerSaveResult = useCallback((): void => {
        setOpenStatus(false);
        onSaveResult(
            comparisonName,
            comparisonPeriod.start.toLocaleDateString('en-CA'),
            comparisonPeriod.end.toLocaleDateString('en-CA'),
        );
    }, [comparisonName, comparisonPeriod.start, comparisonPeriod.end]);
    const handlerCloseModal = (): void => {
        if (nameModalIsOpen) {
            setOpenStatus(false);
        }
    };
    const handlerOpenModal = (): void => {
        setOpenStatus(true);
    };

    return (
        <div className={styles.resultAnalytics}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {headerContent}
                {!isSavedAnalysis && (
                    <Button
                        tagId="campaign-comparison-save"
                        text="Сохранить"
                        type="secondary"
                        size="large"
                        onClick={handlerOpenModal}
                    />
                    // <ButtonSecondary
                    //     text="Сохранить"
                    //     style={{ width: 130, height: 48 }}
                    //     onClick={handlerOpenModal}
                    //     tagId="campaign-comparison-save"
                    // />
                )}
            </div>
            <div className={styles.resultAnalytics_listDropDown}>
                {
                    components.map((item) => (
                        <CampaignComparisonStatistics
                            key={item.id}
                            tagId={item.id as string}
                            title={item.name}
                            compareCampaigns={compareCampaigns}
                            referenceCampaign={referenceCampaign}
                            filters={filters}
                            isSummaryComponent={Boolean(item.value)}
                            comparisonSummary={comparisonSummary}
                            summaryField={item.value as string}
                        />
                    ))
                }
                <CampaignComparisonTonality
                    filters={filters}
                    referenceCampaign={referenceCampaign}
                    compareCampaigns={compareCampaigns}
                />
                <CampaignComparisonMediaType
                    filters={filters}
                    referenceCampaign={referenceCampaign}
                    compareCampaigns={compareCampaigns}
                />
                <CampaignComparisonPlatforms
                    compareCampaigns={compareCampaigns}
                    referenceCampaign={referenceCampaign}
                    filters={filters}
                    tagId="campaign-comparison-comments"
                />
                <CampaignComparisonCategory
                    filters={filters}
                    referenceCampaign={referenceCampaign}
                    compareCampaigns={compareCampaigns}
                />
                <CampaignComparisonRegion
                    filters={filters}
                    referenceCampaign={referenceCampaign}
                    compareCampaigns={compareCampaigns}
                />
            </div>
            <CampaignComparisonResultName
                isOpen={nameModalIsOpen}
                name={comparisonName}
                onClose={handlerCloseModal}
                onChangeName={setName}
                onSaveResult={handlerSaveResult}
            />
        </div>
    );
};

export default CampaignComparisonResult;
