import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Select } from '@shared/index';
import { ISelectItem, TIDSelect } from '@shared/ui-components/Select/types';
import {
    INumbersInterval,
    SortOrder,
    TStackDirection,
    IEntity,
    IPlatform,
    IQPSourcesWithSortingAndFiltering,
    ISourceFiltersRanges,
    RangeFilter,
} from '@services/sources/types';
import SvgIcon from '@components/SvgIcon';
import { SourcesFiltersTypes } from '@sections/types';
import { ICategory } from '@store/types';
import { sourcesRtkQService } from '@services/sources';
import AdvancedSearchRange from '@entities/AdvancedSearch/Range';
import { TextField } from '@entities/index';
import styles from './styles.module.scss';

interface IProps {
    currentFilters: IQPSourcesWithSortingAndFiltering;
    activeSidePage: boolean;
    setActiveSidePage: (value: boolean) => void;
    platforms: IEntity[];
    categories: ICategory[];
    searchValue: string;
    isLoadingPlatforms: boolean;
    isLoadingCategories: boolean;
    platformsIcon: Map<string, string>;
    platformByIdMap: Map<number, IPlatform>;
    onSelectFilter: (ids: number[], filterName: string) => void;
    onSelectRangeFilters: (range: INumbersInterval, filterName: string) => void;
    onApply: (ranges: RangeFilter, title: string) => void;
    ranges: ISourceFiltersRanges;
    withoutSearch?: boolean;
}

const limitOnAuthors = 20;

const AdvancedSearch: React.FC<IProps> = ({
    currentFilters,
    activeSidePage,
    setActiveSidePage,
    platforms,
    categories,
    searchValue,
    isLoadingPlatforms,
    isLoadingCategories,
    platformsIcon,
    platformByIdMap,
    onSelectFilter,
    onSelectRangeFilters,
    onApply,
    ranges,
    withoutSearch = false,
}): JSX.Element => {
    const [isShowSidePage, setIsShowSidePage] = useState<boolean>(false);
    const [authors, setAuthors] = useState<ISelectItem[]>([]);
    const [nextPageKey, setNextPage] = useState<string>('');
    const [currentDirection, setDirection] = useState<TStackDirection>('NEXT');
    const [infinityStep, setInfinityStep] = useState<number>(0);
    const [localSearchValue, setSearchValue] = useState<string>(searchValue);

    const [getAuthors, {
        data: authorsRtkQ,
        isLoading: isLoadingAuthors,
        isFetching: isFetchingAuthors,
        error,
    }] = sourcesRtkQService.useLazyPostLoadAuthorsQuery();

    const platformsIds = useMemo<number[]>(() => (
        [...(currentFilters.filterParams[SourcesFiltersTypes.platforms] as number[])]
    ), [currentFilters.filterParams]);

    const categoriesIds = useMemo<number[]>(() => (
        [...(currentFilters.filterParams[SourcesFiltersTypes.categories] as number[])]
    ), [currentFilters.filterParams]);

    const authorsIds = useMemo<number[]>(() => (
        [...(currentFilters.filterParams[SourcesFiltersTypes.authors] as number[])]
    ), [currentFilters.filterParams]);

    const subscribersInterval = useMemo<INumbersInterval>(() => {
        const val = { ...currentFilters.rangeParams[SourcesFiltersTypes.subscribers] };
        if (!val && !ranges?.subscribers) {
            return { from: 0, to: 1000000 };
        }
        if (!val) {
            return ranges?.subscribers;
        }
        val.from = val.from < ranges?.subscribers.from ? ranges?.subscribers.from : val.from;
        val.to = (val.to > ranges?.subscribers.to || val.to < val.from) ? ranges?.subscribers.to : val.to;
        return val;
    }, [currentFilters.rangeParams, ranges]);

    const erInterval = useMemo<INumbersInterval>(() => {
        const val = { ...currentFilters.rangeParams[SourcesFiltersTypes.er] };
        if (!val && !ranges?.erAvg) {
            return { from: 0, to: 100 };
        }
        if (!val) {
            return ranges?.erAvg;
        }
        val.from = val.from < ranges?.erAvg.from ? ranges?.erAvg.from : val.from;
        val.to = (val.to > ranges?.erAvg.to || val.to < val.from) ? ranges?.erAvg.to : val.to;
        return val;
    }, [currentFilters.rangeParams, ranges]);

    const viewsInterval = useMemo<INumbersInterval>(() => {
        const val = { ...currentFilters.rangeParams[SourcesFiltersTypes.views] };
        if (!val && !ranges?.viewsAvg) {
            return { from: 0, to: 1000000 };
        }
        if (!val) {
            return ranges?.viewsAvg;
        }
        val.from = val.from < ranges?.viewsAvg.from ? ranges?.viewsAvg.from : val.from;
        val.to = (val.to > ranges?.viewsAvg.to || val.to < val.from) ? ranges?.viewsAvg.to : val.to;
        return val;
    }, [currentFilters.rangeParams, ranges]);

    const platformsForSelect = useMemo<ISelectItem[]>(() => (platforms ?? []).map((item) => {
        const platform = platformByIdMap.get(item.id);
        return {
            id: item.id,
            value: item.title,
            select: platformsIds.includes(item.id),
            label: (
                platformByIdMap.has(item.id)
                    ? (
                        <div style={{ display: 'flex', gap: 8 }}>
                            <SvgIcon id={platformsIcon.get(platform?.icon)} />
                            <span>{platform?.title}</span>
                        </div>
                    )
                    : item.title
            ),
        };
    }), [platforms, platformsIds]);

    const categoriesForSelect = useMemo<ISelectItem[]>(() => (categories ?? []).map((item) => {
        const id = Number(item.id);
        return {
            id,
            value: item.mainCat,
            select: categoriesIds.includes(id),
            label: item.mainCat,
        };
    }), [categories, categoriesIds]);

    const closeModalSidePage = () => {
        setIsShowSidePage(true);
        setTimeout(() => setActiveSidePage(!activeSidePage), 700);
    };
    const handleSelectPlatform = (id: TIDSelect): void => {
        const newArr = platformsIds.includes(Number(id))
            ? platformsIds.filter((item) => item !== Number(id))
            : [...platformsIds, Number(id)];
        onSelectFilter(
            newArr,
            SourcesFiltersTypes.platforms,
        );
    };
    const handleSelectCategories = (id: TIDSelect): void => {
        const newArr = categoriesIds.includes(Number(id))
            ? categoriesIds.filter((item) => item !== Number(id))
            : [...categoriesIds, Number(id)];
        onSelectFilter(
            newArr,
            SourcesFiltersTypes.categories,
        );
    };

    const handleSelectAuthors = (id: TIDSelect): void => {
        const newArr = authorsIds.includes(Number(id))
            ? authorsIds.filter((item) => item !== Number(id))
            : [...authorsIds, Number(id)];
        onSelectFilter(
            newArr,
            SourcesFiltersTypes.authors,
        );
    };

    // const handlerSelectSource = (id: number): void => {
    //     closeModalSidePage();
    //     onSelectSource(id);
    // };

    const handlerChangeSearchValue = (value: string): void => {
        setSearchValue(value);
    };

    const handlerApply = (): void => {
        onSelectRangeFilters(subscribersInterval, SourcesFiltersTypes.subscribers);
        onSelectRangeFilters(erInterval, SourcesFiltersTypes.er);
        onSelectRangeFilters(viewsInterval, SourcesFiltersTypes.views);
        closeModalSidePage();
        const subscribers = (
            subscribersInterval.to === ranges.subscribers?.to && subscribersInterval.from === ranges.subscribers?.from
        ) ? null : subscribersInterval;
        const er = erInterval.to === ranges.erAvg.to && erInterval.from === ranges.erAvg.from ? null : erInterval;
        const views = viewsInterval.to === ranges.viewsAvg.to && viewsInterval.from === ranges.viewsAvg.from ? null : viewsInterval;
        const result: RangeFilter = {};
        if (subscribers) {
            result[SourcesFiltersTypes.subscribers] = subscribersInterval;
        }
        if (er) {
            result[SourcesFiltersTypes.er] = erInterval;
        }
        if (views) {
            result[SourcesFiltersTypes.views] = viewsInterval;
        }
        onApply((!er && !subscribers && !views) ? null : result, localSearchValue);
    };

    const handlerAuthorsOnLastItem = useCallback(() => {
        getAuthors({
            value: nextPageKey, orderDirection: SortOrder.asc, direction: 'NEXT', limit: limitOnAuthors, title: '',
        }).then((res) => setInfinityStep(res.data.data.length));
        setDirection('NEXT');
    }, [currentDirection, nextPageKey]);

    useEffect(() => {
        setTimeout(() => setIsShowSidePage(true), 100);
        getAuthors({
            value: '', orderDirection: SortOrder.asc, direction: 'NEXT', limit: limitOnAuthors, title: '',
        });
    }, []);

    useEffect(() => {
        if (authorsRtkQ?.data?.length > 0 && !isLoadingAuthors && !isFetchingAuthors && !error) {
            const ids = authors.map((item) => item.id);
            const result = [
                ...authors,
                ...(authorsRtkQ?.data ?? [])
                    .filter(({ id }) => !ids.includes(id))
                    .map(({ id, title }) => ({ id, label: title, select: false } as ISelectItem)),
            ];
            setAuthors(result.map((item) => ({ ...item, select: authorsIds.includes(Number(item.id)) })));
            setNextPage(authorsRtkQ?.nextPage);
        }
    }, [
        authorsRtkQ,
        currentDirection,
        isLoadingAuthors,
        isFetchingAuthors,
        error,
        authorsIds,
    ]);

    return (
        <div className={styles.advancedSearch}>
            <div className={styles.sidePage} style={{ right: isShowSidePage ? '0' : '-744px' }}>
                <div className={styles.sidePage_header}>
                    <span className={styles.sidePage_title}>Расширенный поиск</span>
                    <button
                        type="button"
                        onClick={closeModalSidePage}
                        className={styles.sidePage_btnCross}
                    >
                        <SvgIcon id="BigCross" size={22} />
                    </button>
                </div>
                <div className={styles.sidePage_contetnWrapper}>
                    <div className={styles.sidePage_wrap}>
                        { !withoutSearch && (
                            <div style={{ width: 530, marginBottom: 8 }}>
                                <TextField
                                    size="large"
                                    placeholder="Поиск источника"
                                    value={localSearchValue}
                                    onChange={handlerChangeSearchValue}
                                />
                            </div>
                        )}
                        <Select
                            size="large"
                            placeholder="Платформы"
                            items={platformsForSelect ?? []}
                            accordion
                            onSelect={handleSelectPlatform}
                            isCheckBoxes
                            customWidth="380px"
                            isLoading={isLoadingPlatforms}
                        />
                        <Select
                            size="large"
                            placeholder="Категории"
                            items={categoriesForSelect ?? []}
                            accordion
                            onSelect={handleSelectCategories}
                            isCheckBoxes
                            customWidth="380px"
                            isLoading={isLoadingCategories}
                        />
                        <Select
                            size="large"
                            placeholder="Автор"
                            items={authors ?? []}
                            accordion
                            onSelect={handleSelectAuthors}
                            isCheckBoxes
                            customWidth="380px"
                            isLoading={isLoadingAuthors || isFetchingAuthors}
                            infinity
                            infinityStep={infinityStep}
                            updateInfinityDate={handlerAuthorsOnLastItem}
                            isUpdateInfinityDate={isFetchingAuthors}
                        />
                        <Select
                            size="large"
                            placeholder="Подписчики"
                            items={[]}
                            accordion
                            customWidth="380px"
                            header={(
                                <AdvancedSearchRange
                                    unitsMeasurement=""
                                    interval={subscribersInterval}
                                    minValue={ranges?.subscribers.from ?? 0}
                                    maxValue={ranges?.subscribers.to ?? 1000000}
                                    onChangeValue={(range) => (
                                        onSelectRangeFilters(range, SourcesFiltersTypes.subscribers)
                                    )}
                                />
                            )}
                            accordionHeight={60}
                        />
                        <Select
                            size="large"
                            placeholder="ER"
                            items={[]}
                            accordion
                            customWidth="380px"
                            header={(
                                <AdvancedSearchRange
                                    unitsMeasurement=""
                                    interval={erInterval}
                                    minValue={ranges?.erAvg.from ?? 0}
                                    maxValue={ranges?.erAvg.to ?? 100}
                                    onChangeValue={(range) => (
                                        onSelectRangeFilters(range, SourcesFiltersTypes.er)
                                    )}
                                />
                            )}
                            accordionHeight={60}
                        />
                        <Select
                            size="large"
                            placeholder="Просмотры"
                            items={[]}
                            accordion
                            customWidth="380px"
                            header={(
                                <AdvancedSearchRange
                                    unitsMeasurement=""
                                    interval={viewsInterval}
                                    minValue={ranges?.viewsAvg.from ?? 0}
                                    maxValue={ranges?.viewsAvg.to ?? 1000000}
                                    onChangeValue={(range) => (
                                        onSelectRangeFilters(range, SourcesFiltersTypes.views)
                                    )}
                                />
                            )}
                            accordionHeight={60}
                        />
                    </div>
                    <div style={{
                        display: 'flex', justifyContent: 'flex-end', gap: 16, margin: '16px 32px',
                    }}
                    >
                        <Button text="Отменить" type="secondary" size="large" onClick={closeModalSidePage} />
                        <Button text="Применить" type="default" size="large" onClick={handlerApply} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedSearch;
