import { IUserInfo } from 'services/common/types';

export const convertApiToUserInfo = (data: unknown): IUserInfo => {
    const result = {
        lastName: '',
        email: '',
        firstName: '',
        middleName: '',
    } as IUserInfo;

    if (!data) {
        return result;
    }

    Object.keys(data).forEach((name) => {
        switch (name) {
            case 'last_name':
                result.lastName = data[name];
                break;
            case 'middle_name':
                result.middleName = data[name];
                break;
            case 'first_name':
                result.firstName = data[name];
                break;
            case 'email':
                result.email = data[name];
                break;
            case 'id':
                result.userId = data[name];
                break;
            default:
                break;
        }
    });

    return result;
};
