import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useGetUserRole from '@shared/hooks/campaign/useGetUserRole';
import { ICampaignUserRole } from '@store/campaign/userRights/types';
import { singleCampaignRtkQService } from '../../../../services/campaigns/single-campaign';
import { useAppDispatch } from '../../../../store/hooks';
import { FetchStatus } from '../../../../store/types';
import campaignSelectors from '../../../../store/campaign/source/selectors';
import CampaignStatus from '../../../../components/campaign/Menu/CampaignStatus';
import { campaignActions } from '../../../../store/campaign/source';

interface ICampaignStatusContainerProps {
    campaignId: number;
    disabled?: boolean;
}

const CampaignStatusContainer: React.FC<ICampaignStatusContainerProps> = ({
    campaignId,
    disabled = false,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const isUpdate = useSelector(campaignSelectors.updateCampaignStatus);
    const userRole = useGetUserRole();
    const [
        getCampaign,
        {
            data: campaign,
            isLoading: isLoadingCampaignRtkQ,
        },
    ] = singleCampaignRtkQService.useLazyGetCampaignQuery();

    const [
        changeCampaignStatus,
    ] = singleCampaignRtkQService.usePostChangeCampaignStatusMutation();

    const handlerActivateCampaign = (value: boolean): void => {
        if (campaign) {
            changeCampaignStatus({ id: campaignId, status: value });
        }
    };
    useEffect(() => {
        if (Boolean(campaignId) && campaign?.id !== campaignId) {
            dispatch(campaignActions.clearCurrentCampaign());
            getCampaign({ id: campaignId });
        }
    }, [campaignId, campaign]);

    return (
        <CampaignStatus
            isActive={campaign?.isActive}
            isLoading={!campaign || isLoadingCampaignRtkQ || isUpdate === FetchStatus.update}
            disabled={disabled || userRole === ICampaignUserRole.readOnly}
            onChangeStatus={handlerActivateCampaign}
        />
    );
};

export default CampaignStatusContainer;
