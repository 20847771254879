import { IMessageModalState } from 'store/common/message-modal/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IMessageModalState = {
    confirmButtonText: 'Ok',
    message: null,
    confirmButtonTagId: '-',
};

const { actions: messageModalActions, reducer } = createSlice({
    name: 'messageModal',
    initialState,
    reducers: {
        setMessage: (state: IMessageModalState, { payload }: PayloadAction<JSX.Element>) => {
            state.message = payload;
        },
        clearMessage: (state: IMessageModalState) => {
            state.message = null;
        },
        setButtonText: (state: IMessageModalState, { payload }: PayloadAction<string>) => {
            state.confirmButtonText = payload ?? 'Ok';
        },
        setButtonTagId: (state: IMessageModalState, { payload }: PayloadAction<string>) => {
            state.confirmButtonTagId = payload ?? '-';
        },
    },
});

export {
    messageModalActions,
};
export default reducer;
