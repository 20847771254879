import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { AppThunk } from 'store/store';
import { getSourcesForSearchLink } from '../../../../deprecated/api/campaign/campaignApi';
import { campaignTrackedSourcesActions } from '..';

const loadCampaignTrackedSourcesThunk = (searchString: string, platforms: string[]): AppThunk => (dispatch: Dispatch): void => {
    if (!searchString || !platforms?.length) {
        return;
    }

    dispatch(campaignTrackedSourcesActions.startLoad());

    getSourcesForSearchLink(searchString, platforms)
        .then((response: unknown) => {
            dispatch(campaignTrackedSourcesActions.successLoad());
            dispatch(campaignTrackedSourcesActions.setSourcesFromApi(response));
        })
        .catch((error: AxiosError) => {
            dispatch(campaignTrackedSourcesActions.failedLoad());
            dispatch(campaignTrackedSourcesActions.setFetchErrors(error?.message));
        });
};

export default loadCampaignTrackedSourcesThunk;
