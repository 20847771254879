import {
    IQPSourcesWithSortingAndFiltering, INumbersInterval, RangeFilter,
} from '@services/sources/types';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { ISimpleEntity } from '@store/types';
import { sourcesRtkQService } from '@services/sources';
import AddSourceModal from '@components/sources/AddSource';
import AdvancedSearch from '@widgets/sources/sidepage/AdvancedSearch';
import SourcesTopPanel from '@components/sources/TopPanel';
import SourcesList from '@components/sources/List';
import { SourcesFiltersTypes } from '@sections/types';
import { categoriesRtkQService } from '@services/categories';
import { useNavigate } from 'react-router';
import Path from '@shared/lib/paths';
import { sourceListActions } from '@store/source/list';
import { useAppDispatch } from '@store/hooks';
import sourceMaps from '@app/constants/sourceMaps';
import styles from './styles.module.scss';

// import useDebounce from '@shared/hooks/useDebounce';
// import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

// @n.sychev TODO перейти на structuredClone после задач по "приборке" npm пакетов в packaje.json и обновлении ts
const deepCopy = (value: any): any => JSON.parse(JSON.stringify(value));

// const limitOnAuthors = 20;

const SourcesListSection: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isModal, setIsModal] = useState<boolean>(false);
    // const [sources, setSources] = useState<ISimpleEntity[]>([]);
    const [activeSidePage, setActiveSidePage] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchValueTopPanel, setSearchValueTopPanel] = useState<string>('');
    const [advancedFilters, setFilters] = useState<IQPSourcesWithSortingAndFiltering>(
        deepCopy(sourceMaps.initialFilters),
    );
    const [
        searchSourceTopPanel,
        {
            data: searchResultTopPanel,
            isLoading: isLoadingTopPanel,
            isFetching: isFetchingTopPanel,
            isError: isErrorTopPanel,
        },
    ] = sourcesRtkQService.useLazyGetSearchSourceQuery();
    const {
        data: platforms,
        isFetching: isFetchingPlatforms,
        isLoading: isLoadingPlatforms,
    } = sourcesRtkQService.useGetLoadPlatformsQuery();
    const {
        data: categories,
        isLoading: isLoadingCategories,
        isFetching: isFetchingCategories,
    } = categoriesRtkQService.useGetCategoriesQuery();
    const {
        data: filtersRanges,
        isLoading: isLoadingRanges,
        isFetching: isFetchingRanges,
        isError: isErrorRanges,
    } = sourcesRtkQService.useGetFiltersRangesQuery();

    const sourcesTopPanel = useMemo(() => {
        if (isLoadingTopPanel || isFetchingTopPanel) {
            return [];
        }
        return (isErrorTopPanel || searchValueTopPanel.length <= 3)
            ? []
            : searchResultTopPanel?.map((item) => ({
                id: item?.id, name: item?.title, value: item?.link,
            } as ISimpleEntity));
    }, [searchResultTopPanel, isErrorTopPanel, searchValueTopPanel, isLoadingTopPanel, isFetchingTopPanel]);

    const handlerStartSearchForTopPanel = (value: string): void => {
        searchSourceTopPanel({ limit: 15, substring: value });
    };

    const handlerSelectFilter = useCallback((ids: number[], filterName: string): void => {
        const newFilters = {
            ...advancedFilters,
            filterParams: { ...advancedFilters?.filterParams, [filterName]: ids },
            page: 1,
        } as IQPSourcesWithSortingAndFiltering;
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerSelectRangeFilter = useCallback((range: INumbersInterval | null, filterName: string): void => {
        let newFilters: IQPSourcesWithSortingAndFiltering = {
            ...advancedFilters,
            rangeParams: { ...advancedFilters?.rangeParams, [filterName]: range },
            page: 1,
        };
        if (range === null) {
            const ranges = null;
            Object.keys(newFilters.rangeParams).forEach((key) => {
                if (newFilters.rangeParams[key] !== null) {
                    ranges[key] = newFilters.rangeParams[key];
                }
            });
            newFilters = { ...newFilters, rangeParams: (ranges === null) ? null : ranges };
        }
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerSelectSource = (sourceId: number): void => {
        if (sourceId > 0) {
            navigate(`/${Path.Sources}/${sourceId}`);
        }
    };

    const handlerApplyAdvancedSearch = useCallback((ranges: RangeFilter, title: string): void => {
        const newFilters = {
            ...advancedFilters,
            page: 1,
            rangeParams: ranges,
            searchValue: title,
        };
        setSearchValue(title);
        dispatch(sourceListActions.setFilters(newFilters));
    }, [advancedFilters]);

    const handlerClearAllFilters = useCallback((): void => {
        setFilters({ ...deepCopy(sourceMaps.initialFilters), rangeParams: filtersRanges });
        setSearchValue('');
    }, [filtersRanges]);

    useEffect(() => {
        if (!isLoadingRanges && !isFetchingRanges && !isErrorRanges) {
            setFilters((prev) => (
                {
                    ...prev,
                    rangeParams: {
                        ...prev.rangeParams,
                        [SourcesFiltersTypes.subscribers]: {
                            from: prev.rangeParams[SourcesFiltersTypes.subscribers].from,
                            to: filtersRanges.subscribers.to,
                        },
                        [SourcesFiltersTypes.views]: {
                            from: prev.rangeParams[SourcesFiltersTypes.views].from,
                            to: filtersRanges.viewsAvg.to,
                        },
                    },
                }
            ));
        }
    }, [
        filtersRanges,
        isLoadingRanges,
        isFetchingRanges,
        isErrorRanges,
    ]);

    return (
        <div className={styles.sources_root}>
            {isModal && <AddSourceModal setIsModal={setIsModal} />}
            {activeSidePage && (
                <AdvancedSearch
                    currentFilters={advancedFilters}
                    activeSidePage={activeSidePage}
                    setActiveSidePage={setActiveSidePage}
                    platforms={platforms}
                    categories={categories}
                    isLoadingPlatforms={isLoadingPlatforms || isFetchingPlatforms}
                    isLoadingCategories={isLoadingCategories || isFetchingCategories}
                    platformsIcon={sourceMaps.platformsIcon}
                    platformByIdMap={sourceMaps.platformByIdMap}
                    searchValue={searchValue}
                    onSelectFilter={handlerSelectFilter}
                    onSelectRangeFilters={handlerSelectRangeFilter}
                    onApply={handlerApplyAdvancedSearch}
                    ranges={filtersRanges}
                />
            )}
            <SourcesTopPanel
                searchValue={searchValueTopPanel}
                isLoading={isLoadingTopPanel || isFetchingTopPanel}
                setIsModal={setIsModal}
                setSearchValue={setSearchValueTopPanel}
                sources={sourcesTopPanel}
                activeSidePage={activeSidePage}
                setActiveSidePage={setActiveSidePage}
                onSelectSource={handlerSelectSource}
                onStartSearch={handlerStartSearchForTopPanel}
            />
            <SourcesList
                isLoadingPlatforms={isLoadingPlatforms || isFetchingPlatforms}
                isLoadingCategories={isLoadingCategories || isFetchingCategories}
                platforms={platforms}
                categoriesData={categories}
                platformByNameMap={sourceMaps.platformByNameMap}
                platformsIcon={sourceMaps.platformsIcon}
                platformByIdMap={sourceMaps.platformByIdMap}
                clearFilters={handlerClearAllFilters}
                ranges={filtersRanges}
            />
        </div>
    );
};

export default SourcesListSection;
