import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ISimpleEntity } from '@store/types';
import { Dropdown } from 'antd';
import SvgIcon from '@components/SvgIcon';
import CampaignAutoCompleteTrackedLinks from '@components/campaign/UpdateForm/TrackedWords/AutoComplete';
import IconButtonWithLabel from '@entities/IconButtonWithLabel';
import classnames from 'classnames';
import { Button } from '@shared/index';
import {
    debounceTime, distinctUntilChanged, filter, fromEvent, map,
} from 'rxjs';
import { advancedSearchActions } from '@store/source/advanced-search';
import { useDispatch, useSelector } from 'react-redux';
import advancedSearchSelectors from '@store/source/advanced-search/selectors';
import sourcesHelpers from '@app/helpers/sources';
import styles from './styles.module.scss';

interface IProps {
    isDisabledAddSource?: boolean;
    searchValue: string;
    isLoading: boolean;
    sources: ISimpleEntity[];
    setIsModal: (isModal: boolean) => void;
    setSearchValue: (value: string) => void;
    activeSidePage: boolean;
    setActiveSidePage: (value: boolean) => void;
    onSelectSource: (id: number) => void;
    onStartSearch: (value: string) => void;
    isDarkSearch?: boolean;
}

let timeoutDelayId: NodeJS.Timeout | null = null;

const SourcesTopPanel: React.FC<IProps> = ({
    isDisabledAddSource,
    searchValue,
    isLoading,
    sources,
    isDarkSearch,
    setIsModal,
    setSearchValue,
    setActiveSidePage,
    activeSidePage,
    onSelectSource,
    onStartSearch,
}): JSX.Element => {
    const searchInputRef = useRef(null);
    const dispatch = useDispatch();

    const [menuIsVisible, setVisibleMenu] = useState<boolean>(false);
    const [isInputFocused, setInputFocus] = useState<boolean>(false);

    const searchHistory = useSelector(advancedSearchSelectors.advancedSearchHistory);

    const magnifierIsVisible = useMemo<boolean>(() => (
        !isInputFocused && !(searchValue?.length > 0)
    ), [isInputFocused, searchValue]);

    const currentListSources = useMemo<ISimpleEntity[]>(() => {
        if (isInputFocused && searchHistory?.length > 0 && !searchValue) {
            return searchHistory.slice(0, 5).map((item) => ({
                id: item.id,
                value: item.link,
                name: item.title,
            } as ISimpleEntity));
        }
        return sources;
    }, [isInputFocused, searchHistory, sources, searchValue]);

    const handlerChangeSearchValue = (value: string): void => {
        if (setSearchValue) {
            setSearchValue(value);
        }
    };

    const handlerChangeFocus = (state: boolean): void => {
        setTimeout(() => setInputFocus(state), 200);
    };

    const onMouseLeaveMenu = useCallback((): void => {
        if (!timeoutDelayId && menuIsVisible) {
            timeoutDelayId = setTimeout(() => setVisibleMenu(false), 300);
            return;
        }
        if (!timeoutDelayId && !menuIsVisible) {
            return;
        }
        if (timeoutDelayId && !menuIsVisible) {
            clearTimeout(timeoutDelayId);
            timeoutDelayId = null;
        }
    }, [menuIsVisible, timeoutDelayId]);
    const onMouseEnterMenu = useCallback((): void => {
        if (!timeoutDelayId && !menuIsVisible) {
            setVisibleMenu(true);
            return;
        }
        if (timeoutDelayId && !menuIsVisible) {
            clearTimeout(timeoutDelayId);
            timeoutDelayId = null;
            setVisibleMenu(true);
            return;
        }
        if (timeoutDelayId && menuIsVisible) {
            clearTimeout(timeoutDelayId);
            timeoutDelayId = null;
        }
    }, [menuIsVisible, timeoutDelayId]);

    // useDebounce(() => {
    //     if (searchValue?.length > 3 && onSearch) {
    //         onSearch(searchValue);
    //     }
    // }, 250, [searchValue, onSearch]);

    const searchField = useMemo<JSX.Element>(() => (
        <>
            <div style={{ display: 'flex', margin: '2px 24px 0px 0' }}>
                <div
                    className={classnames(
                        styles.sourcesTopPanel_searchField,
                        isInputFocused ? styles.sourcesTopPanel_searchField_focus : '',
                    )}
                    style={magnifierIsVisible ? {
                        backgroundColor: isDarkSearch && '#F8FBFF',
                    } : {
                        backgroundColor: isDarkSearch && '#F8FBFF',
                        justifyContent: 'space-between',
                    }}
                    onMouseLeave={onMouseLeaveMenu}
                    onMouseEnter={onMouseEnterMenu}
                >
                    {magnifierIsVisible && (
                        <div className={styles.sourcesTopPanel_imgSearch}>
                            <SvgIcon id="Search" size={16} />
                        </div>
                    )}
                    <input
                        ref={searchInputRef}
                        placeholder="Поиск источника"
                        className={styles.sourcesTopPanel_input}
                        style={!magnifierIsVisible ? {
                            marginLeft: 10,
                            backgroundColor: isDarkSearch && '#F8FBFF',
                        } : {
                            backgroundColor: isDarkSearch && '#F8FBFF',

                        }}
                        autoComplete="on"
                        onFocus={() => handlerChangeFocus(true)}
                        onBlur={() => handlerChangeFocus(false)}
                        onChange={(event) => handlerChangeSearchValue(event.target.value)}
                        value={searchValue}
                    />
                    {
                        searchValue?.length > 0 && (
                            <button
                                type="button"
                                className={styles.sourcesTopPanel_imgCross}
                                onClick={() => handlerChangeSearchValue('')}
                            >
                                <SvgIcon id="Cross" size={14} />
                            </button>
                        )
                    }
                </div>
            </div>
            <div style={{ display: 'flex', margin: '2px 0 0 0', gap: 24 }}>
                <Button
                    text="Расширенный поиск"
                    type="secondary"
                    size="large"
                    onClick={() => setActiveSidePage(!activeSidePage)}
                />
                {
                    !isDisabledAddSource && (
                        <IconButtonWithLabel
                            iconSize={31}
                            iconId="RoundAdd_Icn"
                            label="Добавить источник"
                            onClick={() => setIsModal(true)}
                            labelStyle={{ fontSize: 16 }}
                        />
                    )
                }
            </div>
        </>
    ), [searchValue, onMouseLeaveMenu, onMouseEnterMenu, isInputFocused]);

    const handlerSelect = useCallback((selectedId: number) => {
        if (selectedId) {
            const curr = currentListSources.find(({ id }) => id === selectedId);
            // если поисковая строка не пустая, значит добавляем выранный источник в историю
            if (searchValue?.length > 0 && !searchHistory?.find(({ id }) => selectedId === id)) {
                dispatch(advancedSearchActions.addHistory({
                    id: selectedId,
                    title: curr.name,
                    link: curr.value.toString(),
                    platformIconName: sourcesHelpers.getPlatformIconName(curr),
                }));
            }
            onSelectSource(selectedId);
        }
    }, [currentListSources, searchValue, searchHistory]);

    useEffect(() => {
        const searchInput = searchInputRef.current;

        const search$ = fromEvent(searchInput, 'input').pipe(
            map((event: React.ChangeEvent<HTMLInputElement>) => event.target.value),
            debounceTime(500),
            filter((searchParam) => (!searchParam || searchParam.length >= 3)),
            distinctUntilChanged(),
        );

        const subscription = search$.subscribe((res) => onStartSearch(res));
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return (
        <div
            className={styles.sourcesTopPanel_root}
        >
            <Dropdown
                visible={(menuIsVisible && (isLoading || currentListSources?.length > 0))}
                overlay={(
                    <CampaignAutoCompleteTrackedLinks
                        maxHeight={400}
                        list={currentListSources?.length > 0 ? currentListSources : []}
                        onSelect={handlerSelect}
                        isLoading={isLoading}
                        onMouseLive={onMouseLeaveMenu}
                        onMouseEnter={onMouseEnterMenu}
                    />
                )}
            >
                {searchField}
            </Dropdown>
        </div>
    );
};

export default SourcesTopPanel;
