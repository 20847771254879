/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useEffect } from 'react';
import { Button } from '@shared/index';
import { postsRtkQService } from '@services/campaigns/posts';
import styles from './styles.module.scss';
import ChartTracking from '../ChartTracking';
import RadialGraph from './RadialGraph';
import QuickSVGLoader from '../QuickSVGLoader';

type Tabs = 'graph' | 'bars' | 'bubleGraph';

const ChartEngagementDynamics: React.FC<any> = ({
    close,
    isOpen,
    postDate,
    pid,
    postData,
}) => {
    const [activeTab, setActiveTab] = useState<Tabs>('graph');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [dataset, setDataset] = useState(null);
    const [
        getListPost, { isLoading },
    ] = postsRtkQService.useLazyGetGraphPostsQuery();
    useEffect(() => {
        getListPost({ id: pid || 27833623 }).then((res) => {
            setDataset(res?.data);
        });
    }, []);
    const getCurrentTab = useMemo(() => {
        const listTabs = {
            graph: <ChartTracking
                postDate={postDate}
                data={dataset}
                postData={postData}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
            />,
            bubleGraph: <RadialGraph pid={pid} dataset={dataset} />,
        };
        if (isLoading) {
            return (
                <div
                    style={{
                        height: '400px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <QuickSVGLoader />
                </div>
            );
        }
        return listTabs[activeTab];
    }, [activeTab, dataset, isLoading, isFullScreen]);
    if (!isOpen) return <div />;
    if (!dataset) {
        return (
            <div style={{
                color: '#F36869', padding: '16px', backgroundColor: '#fff', borderRadius: '6px', zIndex: 999,
            }}
            >
                Данных нет
            </div>
        );
    }
    return (
        <div className={styles.root_ModalEngagement}>

            <div>
                <div className={styles.header}>
                    <div
                        className={`${styles.bar} ${activeTab === 'graph' && styles.select}`}
                        onClick={() => { setActiveTab('graph'); }}
                    >
                        Граф
                    </div>
                    <div className={`${styles.bar} ${activeTab === 'bubleGraph' && styles.select}`} onClick={() => { setActiveTab('bubleGraph'); }}>Бабл Графк</div>
                </div>
                <div className={`${isFullScreen ? styles.fullScreen : styles.smallScreen}`}>
                    {getCurrentTab}
                </div>
                <Button
                    text="Развернуть"
                    type="secondary"
                    size="large"
                    onClick={() => setIsFullScreen(true)}
                />
            </div>
        </div>
    );
};

export default ChartEngagementDynamics;
