import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { sourcesRtkQService } from '@services/sources';
import { categoriesRtkQService } from '@services/categories';
import CardList from '@widgets/CardList';
import sourceMaps from '@app/constants/sourceMaps';
import { message } from 'antd';
import SvgIcon from '@shared/ui-components/SvgIcon';
import SourceListCreateModal from '@entities/SourceListCreateModal';
import { deepCopy } from '../../utils/helpers/deepCopy';
import styles from './styles.module.scss';

const SourcesLists: React.FC = (): JSX.Element => {
    const { data: listsRaw, refetch } = sourcesRtkQService.useGetListsQuery();
    const [isOpenCreateList, setOpenCreateList] = useState<boolean>(false);
    const [removeSourceList] = sourcesRtkQService.useRemoveSourcesListMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const success = (content) => {
        messageApi.open({
            type: 'success',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_succes,
        });
    };
    const error = (content) => {
        messageApi.open({
            type: 'error',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_error,
        });
    };
    const {
        data: platforms,
        isFetching: isFetchingPlatforms,
        isLoading: isLoadingPlatforms,
    } = sourcesRtkQService.useGetLoadPlatformsQuery();
    const {
        data: categories,
        isLoading: isLoadingCategories,
        isFetching: isFetchingCategories,
    } = categoriesRtkQService.useGetCategoriesQuery();
    const {
        data: filtersRanges,
    } = sourcesRtkQService.useGetFiltersRangesQuery();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [advancedFilters, setFilters] = useState<any>(
        deepCopy(sourceMaps.initialFilters),
    );
    const getFavoriteId = useMemo(() => {
        if (!Array.isArray(listsRaw)) return null;
        const favorite = listsRaw.find((item) => item.id);
        return favorite.id;
    }, [listsRaw]);
    const getLists = useMemo(() => {
        if (!listsRaw?.length) return [];
        return listsRaw.filter((list) => !list?.isFavorite).reverse();
    }, [listsRaw]);
    const handleRemoveList = (id: number) => {
        const needList = getLists.find(({ id: rid }) => id === rid);
        removeSourceList(id).then(() => {
            refetch();
            success(`Вы успешно удалили список ${needList.title}`);
        }).catch(() => {
            error(`При удалении списка ${needList.title} произошла ошибка`);
        });
    };
    const handlerClearAllFilters = useCallback((): void => {
        setFilters({ ...deepCopy(sourceMaps.initialFilters), rangeParams: filtersRanges });
    }, [filtersRanges]);
    useEffect(() => {
        refetch();
    }, []);
    return (
        <div className={styles.sourcesLists_root}>
            {contextHolder}
            {
                getLists?.map((list) => (
                    <CardList
                        updateLists={refetch}
                        isLoadingPlatforms={isLoadingPlatforms || isFetchingPlatforms}
                        isLoadingCategories={isLoadingCategories || isFetchingCategories}
                        platforms={platforms}
                        categoriesData={categories}
                        platformByNameMap={sourceMaps.platformByNameMap}
                        platformsIcon={sourceMaps.platformsIcon}
                        platformByIdMap={sourceMaps.platformByIdMap}
                        clearFilters={handlerClearAllFilters}
                        ranges={filtersRanges}
                        lists={listsRaw}
                        favoriteId={getFavoriteId}
                        list_id={list.id}
                        {...list}
                        key={`${list.id}_listIdSourcesCard`}
                        onRemove={handleRemoveList}
                    />
                ))
            }
            {!getLists.length
                && (
                    <div className={styles.empty}>
                        <p>У вас пока нет списков</p>
                        <button type="button" onClick={() => setOpenCreateList(true)}>
                            <SvgIcon id="PlusCircle" size={18} />
                            Создать список
                        </button>
                    </div>
                )}

            {isOpenCreateList
                && <SourceListCreateModal onClose={() => setOpenCreateList(false)} />}

        </div>
    );
};

export default SourcesLists;
