import { IAnalyzeQuotationsState } from './types';

const convertToObject = (data: unknown): IAnalyzeQuotationsState => {
    const result: IAnalyzeQuotationsState = {
        contractedText: null,
        mood: '',
        content: '',
        parseTimestamp: 0,
        category: null,
        postedTimestamp: 0,
        link: '',
        title: null,
        negative: '',
        source: '',
        neutral: '',
        positive: '',
        skip: '',
        id: -1,
        prediction: 0,
        subcategory: null,
    } as IAnalyzeQuotationsState;

    Object.keys(data).forEach((name) => {
        switch (name) {
            case 'category':
                result.category = data[name];
                break;
            case 'contracted_text':
                result.contractedText = data[name];
                break;
            case 'content':
                result.content = data[name];
                break;
            // case 'semantic':
            //     result.mood = data[name]?.mood;
            //     break;
            case 'id':
                result.id = data[name];
                break;
            case 'post_link':
                result.link = data[name];
                break;
            // case 'mood':
            //     result.mood = data[name];
            //     break;
            case 'negative':
                result.negative = data[name];
                break;
            case 'neutral':
                result.neutral = data[name];
                break;
            case 'parse_timestamp':
                result.parseTimestamp = data[name];
                break;
            case 'positive':
                result.positive = data[name];
                break;
            // case 'posted_timestamp':
            //     result.postedTimestamp = data[name];
            //     break;
            case 'posted':
                result.posted = data[name];
                result.postedTimestamp = new Date(data[name]).getTime() / 1000;
                break;
            case 'prediction':
                result.prediction = data[name];
                break;
            case 'skip':
                result.skip = data[name];
                break;
            // case 'source':
            //     result.source = data[name];
            //     break;
            case 'source_tag':
                result.source = data[name];
                break;
            // case 'categories':
            //     if (data[name] && Array.isArray(data[name]) && data[name].length > 0) {
            //         result.subcategory = data[name][0]?.title;
            //     }
            //     break;
            case 'main_category':
                if (data[name]) {
                    result.subcategory = data[name].title;
                }
                break;
            case 'title':
                result.title = data[name];
                break;
            case 'semantic':
                if (data[name]) {
                    result.positive = data[name].positive;
                    result.neutral = data[name].neutral;
                    result.negative = data[name].negative;
                    result.mood = data[name].mood;
                    result.skip = data[name].skip;
                }
                break;
            default:
                break;
        }
    });

    return result;
};

export const convertApiToAnalyzeQuotations = (data: unknown): IAnalyzeQuotationsState[] => {
    const result: IAnalyzeQuotationsState[] = [];

    if (!data) {
        return result;
    }

    if (Array.isArray(data)) {
        data.forEach((item: unknown) => {
            result.push(convertToObject(item));
        });
    } else {
        result.push(convertToObject(data));
    }

    return result;
};
