import React, { useMemo } from 'react';
import styles from './styles.module.scss';

interface IProps {
    isActive: boolean,
    customHeight?: number
}

const AccordionDropDown: React.FC<IProps> = ({ isActive, customHeight, children }): JSX.Element => {
    const actualHeight = useMemo<number | null>(() => (
        customHeight > 0 ? customHeight : null
    ), [customHeight]);
    return (
        <div className={styles.accordionDropDownList} style={{ height: !isActive ? '0' : actualHeight }}>
            {
                children || null
            }
        </div>
    );
};

export default AccordionDropDown;
