import React from 'react';
import styles from './styles.module.scss';
import SvgIcon from '../SvgIcon';

interface IProps {
    findString: string,
    onChangeFind: (value: string) => void
}
const FiltersFindInput: React.FC<IProps> = ({
    findString,
    onChangeFind,
}): JSX.Element => (
    <div className={styles.containerFind}>
        <input type="text" value={findString} onChange={(e) => onChangeFind(e.target.value)} />
        <div className={styles.containerIcon}>
            <SvgIcon id="FindIcon" size={16} />
        </div>
    </div>
);

export default FiltersFindInput;
