import classNames from 'classnames';
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import IconCross from '../../../assets/images/cross_modal_red.svg';
import PercentIcon from '../../../assets/images/percent.svg';
import IconTrashGray from '../../../assets/images/trash-gray.svg';
import { postsRtkQService } from '../../../services/campaigns/posts';
import { campaignTagsRtkQService } from '../../../services/campaigns/tags';
import { ITagModel } from '../../../services/campaigns/tags/types';
import styles from './styles.module.scss';

interface IProps {
    tag: ITagModel;
    index: number;
    campaignid: number;
}

const CampaignUserTagsTableRow: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { tag, index, campaignid } = props;
    const [deleteTag, { isError: isDeletedError, isSuccess: isDeletedSuccess }] = campaignTagsRtkQService.useLazyDeleteUserTagQuery();
    const [deletePostsByTag, {
        isError: isDeletedPostsError,
        isSuccess: isPostsDeletedSuccess, isFetching: isPostsDeletedFetching,
    }] = postsRtkQService.useLazyDeletePostsByUserTagQuery();
    const [isPostsDeleted, setPostsDeleted] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [isRemovalOptionsVisible, setRemovalOptionsVisible] = useState<boolean>(false);
    const removalOptionsRef = useRef();

    const handleClickDelete = (): void => {
        setError(false);
        deleteTag({
            campaign_id: campaignid,
            id: tag.id,
        });
        setRemovalOptionsVisible(false);
    };

    const handleClickDeletePosts = (): void => {
        setError(false);
        deletePostsByTag({
            campaignId: campaignid,
            id: tag.id,
        });
        setRemovalOptionsVisible(false);
    };

    const infoVisible = useMemo(
        () => !isDeletedSuccess && !isPostsDeleted,
        [isDeletedSuccess, isPostsDeleted],
    );

    useEffect(() => {
        setError(isDeletedError || isDeletedPostsError);
    }, [isDeletedError, isDeletedPostsError]);

    useEffect(() => {
        if (!isPostsDeletedFetching) setPostsDeleted(isPostsDeletedSuccess);
    }, [isPostsDeletedSuccess, isPostsDeletedFetching]);

    useOnClickOutside(removalOptionsRef, () => setRemovalOptionsVisible(false));

    return (
        <>
            {infoVisible && (
                <div className={styles.row}>
                    <span>{index}</span>
                    <span className={styles.rowName}>
                        {tag.title}
                    </span>
                    <span>{tag.manual ? 'Ручной' : 'Автоматический'}</span>
                    <span>
                        {tag.percent}
                        {' '}
                        <img src={PercentIcon} alt="PercentIcon" />
                    </span>
                    <span>{tag.postsCount}</span>
                    <span>{tag.comments}</span>
                    <span>{tag.views}</span>
                    <span>{tag.reactions}</span>
                    <span className={styles.rowActions}>
                        <div ref={removalOptionsRef}>
                            <button type="button" onClick={() => setRemovalOptionsVisible((prev) => !prev)}>
                                <img src={IconTrashGray} alt="Icon Trash" />
                            </button>

                            {isRemovalOptionsVisible
                            && (
                                <div className={styles.rowRemovalOptions}>
                                    <button type="button" onClick={handleClickDelete}>Удалить тег</button>
                                    <button type="button" onClick={handleClickDeletePosts}>Удалить все сообщения, помеченные тегом</button>
                                </div>
                            )}
                        </div>
                    </span>
                </div>
            )}
            {isDeletedSuccess && (
                <div className={styles.rowInformationPlate}>
                    <p>Тег удален.</p>
                </div>
            )}
            {isPostsDeleted
                && (
                    <div className={classNames(styles.rowPostsDeleted, styles.rowInformationPlate)}>
                        <p>
                            Посты с тегом “
                            {tag.title}
                            ” удалены.
                        </p>
                        <button type="button" onClick={() => setPostsDeleted(false)}>
                            <img src={IconCross} alt="IconCross" />
                        </button>
                    </div>
                )}
            {isError
                && (
                    <div className={classNames(styles.rowInformationPlate, styles.rowError)}>
                        <p>Произошла ошибка</p>
                        <button type="button" onClick={() => setError(false)}>
                            <img src={IconCross} alt="IconCross" />
                        </button>
                    </div>
                )}
        </>
    );
};
export default CampaignUserTagsTableRow;
