/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useMemo, useRef } from 'react';
import QuickSVGLoader from '@components/QuickSVGLoader';
import { TSizes } from '@components/QuickSVGLoader/types';
import useOnViewport from '../helpers/useOnViewport';
import MyCompaignPlotListSkeleton from '../../widgets/campaign/tracking/PostList/Skeleton';
import styles from './styles.module.scss';

interface IPropsLastItem {
    enabled: boolean,
    size?: TSizes,
    loadingType?: TLoadingType,
    onLastItem?: () => void
}

export type TLoadingType = 'SKELETON' | 'CIRCLE';

const LastItem: React.FC<IPropsLastItem> = ({
    enabled,
    onLastItem,
    size,
    loadingType = 'SKELETON',
}): JSX.Element => {
    const lastItemRef = useRef(null);
    const onView = useOnViewport(lastItemRef);

    const loadingChild = useMemo(() => {
        if (loadingType === 'CIRCLE') {
            return (
                <div className={styles.loaderWrapper}>
                    <QuickSVGLoader size={size} />
                </div>
            );
        } return (<MyCompaignPlotListSkeleton />);
    }, [loadingType]);

    useEffect(() => {
        if (onView) onLastItem();
    }, [onView]);
    return (
        <div ref={lastItemRef} className={styles.root_lastItem}>
            {enabled && loadingChild}
        </div>

    );
};
export default React.memo(LastItem);
