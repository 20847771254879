import React from 'react';
import { SvgIcon, Tooltip } from '@shared/index';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
    isPlan?: true;
    tooltipTxt: string;
    tooltipIconId: string;
    tooltipIconSize: number;
}

const LongListTooltip: React.FC<IProps> = ({
    isPlan = false,
    tooltipTxt,
    tooltipIconId,
    tooltipIconSize,
}): JSX.Element => (
    <div className={classnames(styles.longlist_tooltip, isPlan ? styles.headerPlanValues : styles.headerAvgValues)}>
        <div>{isPlan ? 'План' : 'Среднее'}</div>
        <Tooltip content={(
            <div style={{ boxSizing: 'border-box', padding: '8px', width: '232px' }}>
                {tooltipTxt}
            </div>
        )}
        >
            <div className={styles.icon_layout}><SvgIcon id={tooltipIconId} size={tooltipIconSize} /></div>
        </Tooltip>

    </div>
);

export default LongListTooltip;
