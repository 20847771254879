/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-loop-func */

import { randomHexColor, randomInt } from '../../utils';

const getRandomFontBold = () => {
    const fonts = ['700', '500', '400', '900', '600'];
    return fonts[Math.floor(Math.random() * fonts.length)];
};

export const renderTagsOnCanvas = (words: string[]): string => {
    const canvasWidth = 600;
    const canvasHeight = 400;
    const canvas = document.createElement('canvas');
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;
    const ctx = canvas.getContext('2d');

    const minSpacing = 5;

    const placedWords = [];

    const getRandomWord = () => words[Math.floor(Math.random() * words.length)];

    while (placedWords.length < words.length) {
        const word = getRandomWord();
        const fontSize = randomInt(16, 20);
        const fontBold = getRandomFontBold();
        ctx.font = `normal normal ${fontBold} ${fontSize}px Montserrat`;
        const wordWidth = ctx.measureText(word).width;

        const x = randomInt(0, canvasWidth - wordWidth);
        const y = randomInt(0, canvasHeight - fontSize);

        // check if the word overlaps with any previously placed word
        let overlapping = false;
        for (let i = 0; i < placedWords.length; i++) {
            const placedWord = placedWords[i];
            if (
                x + wordWidth + minSpacing > placedWord.x
                && x < placedWord.x + placedWord.width + minSpacing
                && y + fontSize + minSpacing > placedWord.y
                && y < placedWord.y + placedWord.fontSize + minSpacing
            ) {
                overlapping = true;
                break;
            }
        }

        if (
            !overlapping && x + wordWidth <= canvasWidth && y + fontSize <= canvasHeight
        ) {
            ctx.font = `normal normal ${fontBold} ${fontSize}px Montserrat`;
            ctx.fillStyle = randomHexColor();
            ctx.fillText(word, x, y + fontSize);
            placedWords.push({
                word,
                x,
                y,
                width: wordWidth,
                fontSize,
            });
        }
    }

    return canvas.toDataURL();
};
