import React from 'react';
import styles from './styles.module.scss';
import Download from '../imgs/download.svg';

interface IProps {
    message: string;
    link: boolean;
}
const StageMessage: React.FC<IProps> = ({
    message, link,
}): JSX.Element => (
    <div className={styles.stageMessage}>
        <p className={styles.stageMessage_message}>
            {message}
        </p>
        {link ? (
            <div className={styles.stageMessage_link}>
                <span>Скачать шаблон</span>
                <img src={Download} alt="Download" />
            </div>
        ) : null}
    </div>
);

export default StageMessage;
