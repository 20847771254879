/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import NoSourceIco from '@assets/images/sources/noSource.svg';
import { SvgIcon } from '@shared/index';
import styles from './styles.module.scss';

interface IPropsPlatformCell {
    name: string,
}
const platformsLabels = {
    rss: 'RSS',
    tg: 'Телеграм',
    vk: 'Вк',
    ok: 'Однаклассники',
    dzen: 'Дзен',
    rutube: 'RuTube',
    youtube: 'YouTube',
    vk_video: 'VK Видео',
};
const platformsIcons = {
    rss: 'RssLogo',
    tg: 'TgLogo',
    vk: 'VkLogo',
    ok: 'OkLogo',
    dzen: 'ZenLogo',
    rutube: 'RuTubeLogo',
    youtube: 'YouTubeLogo',
    vk_video: 'VkVideoLogo',
};
const PlatformCell: React.FC<IPropsPlatformCell> = ({ name }): JSX.Element => (
    <div
        role="button"
        className={styles.platformCell}
    >
        {platformsIcons[name] ? <SvgIcon id={platformsIcons[name]} size={20} /> : ''}
        <div className={styles.platformCell_label}>{platformsLabels[name] || name}</div>
    </div>
);

export default React.memo(PlatformCell);
