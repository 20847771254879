/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Tooltip } from '@shared/index';
import SvgIcon from '@components/SvgIcon';
import Popup from '@entities/Popup';
import styles from './styles.module.scss';

const TooltipsDemo: React.FC = (): JSX.Element => {
    const [isOpenClick, setIsOpenClick] = useState(false);
    const handleChangeOpen = (status: boolean) => {
        setIsOpenClick(status);
    };
    const testContentForPopUp = (
        <div style={{ maxWidth: 240 }}>
            <span>Информация. Какой-то короткий объясняющий текст</span>
        </div>
    );
    return (
        <div className={styles.tooltipsDemo_root}>
            <div className={styles.block}>
                <Tooltip content={<span>Big Label tooltip</span>}>
                    <div className={`${styles.label} ${styles.label_big}`}>Big label</div>
                </Tooltip>
                <Tooltip content={<span>Mid Label tooltip</span>}>
                    <div className={`${styles.label} ${styles.label_mid}`}>Mid label</div>
                </Tooltip>
                <Tooltip content={<span>Small Label tooltip</span>} placement="bottomLeft">
                    <div className={`${styles.label} ${styles.label_small}`}>Small label</div>
                </Tooltip>
            </div>
            <div className={styles.block}>
                <Tooltip content={<span>Big icn tooltip</span>} placement="right">
                    <div className={`${styles.label} ${styles.label_big}`}>
                        <SvgIcon id="ChartsIcon" size={42} />
                    </div>
                </Tooltip>
                <div>
                    <Tooltip content={<span>mid icn tooltip</span>} placement="bottom">
                        <div className={`${styles.label} ${styles.label_big}`}>
                            <SvgIcon id="ChartsIcon" size={28} />
                        </div>
                    </Tooltip>
                </div>
                <Tooltip content={<span>small icn tooltip</span>} placement="right">
                    <div className={`${styles.label} ${styles.label_big}`}>
                        <SvgIcon id="ChartsIcon" size={14} />
                    </div>
                </Tooltip>
            </div>

            <div className={styles.block} style={{ marginLeft: 24 }}>
                <Tooltip content={<span>Big icn tooltip</span>} placement="top">
                    <div
                        className={`${styles.label} ${styles.label_big}`}
                        style={{
                            borderRadius: '100%', height: 45, width: 45, backgroundColor: 'lightblue',
                        }}
                    />
                </Tooltip>
                <Tooltip content={<span>mid icn tooltip</span>} placement="right">
                    <div
                        className={`${styles.label} ${styles.label_big}`}
                        style={{
                            borderRadius: '100%', height: 30, width: 30, backgroundColor: 'tomato',
                        }}
                    />
                </Tooltip>
                <Tooltip content={<span>small icn tooltip</span>} placement="bottom">
                    <div
                        className={`${styles.label} ${styles.label_big}`}
                        style={{
                            borderRadius: '100%', height: 15, width: 15, backgroundColor: 'lightgreen',
                        }}
                    />
                </Tooltip>
            </div>
            <div className={styles.block}>
                <Popup
                    content={testContentForPopUp}
                    placement="topLeft"
                    xOffset={-0.95}
                >
                    <div
                        className={`${styles.label} ${styles.label_big}`}
                    >
                        Click
                    </div>
                </Popup>
            </div>
        </div>
    );
};

export default TooltipsDemo;
