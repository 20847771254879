import { rtkQApi } from '@services/index';
import {
    IQPAddSourceInProject,
    IQPCreateMediaPlan,
    IQPDownloadSources, IQPPlanFields,
    IQPProjectSources,
    IQPUpdateMediaPlanSources,
} from '@services/projects/types/longlist/queryParams';
import { ISource, ISourceRow, SortOrder } from '@services/sources/types';
import { transformSourcesForLongListResponse } from '@services/projects/transformers/getSourcesForLongList';
import { TFactResultModel } from '@services/projects/types/results/models';

export const projectLonglistRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        addSourceInProject: build.mutation<void, IQPAddSourceInProject>({
            query: (params) => ({
                url: `media_projects/project/${params.projectId}/sources`,
                method: 'POST',
                body: params.sources.map((item: ISourceRow) => ({
                    source_id: item.id,
                    title: item.title,
                    link: item.link,
                    type_id: item.sourceType.id,
                    type_title: item.sourceType.title,
                    author_id: item.author.id,
                    author_title: item.author.title,
                    is_blogger: 'True',
                    category_ids: item.categories.map(({ id }) => id),
                    category_titles: item.categories.map(({ title }) => title),
                    subscribers: item.subscribers,
                    er_avg: item.er,
                    views_avg: item?.viewsAvg,
                    err: 0,
                    err24: 0,
                    price: 0,
                    content: '',
                    integration_link: null,
                    logo_url: item?.logoUrl,
                    plan_cpv: item.viewsAvg === 0 ? 0 : item.price / item.viewsAvg,
                    plan_views: item.viewsAvg,
                    plan_er: item.erAvg,
                    // start_date: dateNow.toISOString(),
                    // end_date: dateNext.toISOString(),
                })),
            }),
            invalidatesTags: ['LongLists'],
        }),
        loadSourcesForLonglist: build.query<ISource, IQPProjectSources>({
            query: (params) => ({
                url: `media_projects/project/${params.projectId}/get_sources`,
                method: 'POST',
                body: {
                    set_type_id: params.set_type_id,
                    page: params.page,
                    limit: params.limit,
                    order_by: params.orderBy,
                    order_direction: SortOrder[params.orderDirection],
                    filter_params: params.filterParams,
                    range_params: params.rangeParams,
                },
            }),
            transformResponse: transformSourcesForLongListResponse,
            providesTags: ['LongLists'],
        }),
        putCreateMediaPlan: build.query<void, IQPCreateMediaPlan>({
            query: (params) => ({
                url: `media_projects/project/${params.projectId}/make_mediaplan `,
                method: 'PUT',
                body: params.sources.map((item: ISourceRow) => ({
                    source_id: item.sourceId,
                    id: item.id,
                    title: item.title,
                    link: item.link,
                    type_id: item.sourceType.id,
                    type_title: item.sourceType.title,
                    author_id: item.author.id,
                    author_title: item.author.title,
                    is_blogger: 'True',
                    category_ids: item.categories.map(({ id }) => id),
                    category_titles: item.categories.map(({ title }) => title),
                    subscribers: item.subscribers,
                    er_avg: item.er,
                    logo_url: item?.logoUrl,
                    views_avg: 0,
                    err: 0,
                    err24: 0,
                    price: 0,
                    content: '',
                    integration_link: null,
                    start_date: item?.startDate?.toISOString() ?? null,
                    end_date: item?.endDate?.toISOString() ?? null,
                })),
                transformResponse: transformSourcesForLongListResponse,
                providesTags: ['LongLists'],
            }),
        }),
        putUpdateMediaPlanSource: build.mutation<void, IQPUpdateMediaPlanSources>({
            query: (params) => ({
                url: `media_projects/project/${params.projectId}/sources`,
                method: 'PUT',
                body: {
                    sources: [{
                        id: params.source.id,
                        source_id: params.source.sourceId,
                        title: params.source.title,
                        link: params.source.link,
                        type_id: params.source.sourceType.id,
                        type_title: params.source.sourceType.title,
                        author_id: params.source.author.id,
                        author_title: params.source.author.title,
                        is_blogger: 'True',
                        category_ids: params.source.categories.map(({ id }) => id),
                        category_titles: params.source.categories.map(({ title }) => title),
                        subscribers: params.source.subscribers,
                        er_avg: params.source.er,
                        content: params.source.content,
                        integration_link: (params?.source?.integrationLink === '' || !params.source.integrationLink)
                            ? null
                            : params.source.integrationLink,
                        start_date: params.source?.startDate?.toISOString() ?? null,
                        end_date: params.source?.endDate?.toISOString() ?? null,
                    }],
                },
            }),
            invalidatesTags: ['LongLists'],
        }),
        putUpdatePlanFields: build.mutation<TFactResultModel[], IQPPlanFields>({
            query: (params) => ({
                url: `/media_projects/project/${params.projectId}/sources`,
                method: 'PUT',
                body: {
                    sources: [
                        {
                            id: params.id,
                            source_id: params.sourceId,
                            plan_views: params.planViews ?? null,
                            plan_er: params.planEr ?? null,
                            plan_cpv: params.planCpv ?? null,
                            price: params.price ?? null,
                        },
                    ],
                },
            }),
            invalidatesTags: ['LongLists'],
        }),
        postDownloadSources: build.query<Blob, IQPDownloadSources>({
            query: (params) => ({
                url: `media_projects/project/${params.id}/download_project_sources`,
                method: 'POST',
                body: {
                    fields: params.fields,
                    set_type_id: params.set_type_id,
                },
                responseHandler: ((response) => response.blob()),
                cache: 'no-cache',
            }),
        }),
    }),
});
