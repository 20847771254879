import React, { useCallback } from 'react';
import { ISimpleEntity } from '@store/types';
import SvgIcon from '@shared/ui-components/SvgIcon';
import sourcesHelpers from '@app/helpers/sources';
import LinearLoader from '../../../../common/Loader';
import styles from './styles.module.scss';

interface IProps {
    maxHeight?: number | null;
    list: ISimpleEntity[];
    onSelect: (id: number) => void;
    isLoading: boolean;
    onMouseLive?: () => void;
    onMouseEnter?: () => void;
}

const CampaignAutoCompleteTrackedLinks: React.FC<IProps> = ({
    maxHeight,
    onSelect,
    list,
    isLoading,
    onMouseLive,
    onMouseEnter,
}): JSX.Element => {
    const handlerSelectItem = (id: number | string): void => {
        onSelect(Number(id));
    };
    const handlerMouseLeave = (): void => {
        if (onMouseLive) {
            onMouseLive();
        }
    };
    const handlerMouseEnter = (): void => {
        if (onMouseEnter) {
            onMouseEnter();
        }
    };
    const getName = useCallback((value: string): string => (
        value?.length > 55 ? `${value.substring(0, 55)}...` : value
    ), []);

    return (
        <div
            className={styles.campaignAutoCompleteTrackedLinks_root}
            style={maxHeight ? { maxHeight } : {}}
            onMouseLeave={handlerMouseLeave}
            onMouseEnter={handlerMouseEnter}
        >
            {
                isLoading && (
                    <div className={styles.loaderContainer}>
                        Подгружаем список
                        <div style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
                        }}
                        >
                            <LinearLoader />
                        </div>
                    </div>
                )
            }
            {
                !isLoading && list?.length > 0 && (
                    <div className={styles.wrapper}>
                        {
                            list.map((item) => (
                                <button
                                    key={item.id}
                                    className={styles.sourceButton}
                                    type="button"
                                    onClick={() => handlerSelectItem(item.id)}
                                >
                                    <div className={styles.sourceButton_title}>{getName(item?.name ?? '---')}</div>
                                    <div className={styles.sourceButton_link}>
                                        { sourcesHelpers.getPlatformIcon(item) ?? <SvgIcon id="RssLogo" /> }
                                        {item?.value ?? '---'}
                                    </div>
                                </button>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default CampaignAutoCompleteTrackedLinks;
