import { combineReducers } from 'redux';
import analyzeResultReducer from './analyze-result';
import analyzeAnalyticsReducer from './analyze-analytics';
import analyzeQuotationsReducer from './analyze-quotations';
import analyzeFoundPostReducer from './found-post';

const infoTraceReducers = () => combineReducers({
    analyzeResult: analyzeResultReducer,
    analyzeAnalytics: analyzeAnalyticsReducer,
    analyzeQuotations: analyzeQuotationsReducer,
    analyzeFoundPost: analyzeFoundPostReducer,
});

export default infoTraceReducers;
