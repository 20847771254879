/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { TitleChart } from '../../../style';
import BubbleChart from '../../../../../components/BubbleChart';

const { Panel } = Collapse;

export const Height = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #DFFCF7;
    border-radius: 6px;
`;
export const Low = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #FEF8E8;
    border-radius: 6px;
`;
const testProps = [
    {
        id: 123,
        value: 1628,
        label: 'блогеры',
    },
    {
        id: '1d2fsx4f56tg',
        value: 68,
        label: 'СМИ',
    },
    {
        id: 124,
        value: 112538,
        label: 'Бри',
    },
    {
        id: '23',
        value: 100134,
        label: 'Стримеры',
    },
    {
        id: '12',
        value: 80432,
        label: 'Три',
    },
];

const BigBubbleDemo = () => (
    <Collapse>
        <Panel
            key="ChartPublicationDemo"
            header={<TitleChart style={{ marginBottom: '12px' }}>Биг бабл</TitleChart>}
        >
            <div>
                <BubbleChart data={testProps} />
            </div>
        </Panel>
    </Collapse>
);
export default BigBubbleDemo;
