import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '../../types';
import { IInfoBasketPeriod, IInfoBasketSourceFiltersApi, IInfoBasketSourceState } from './types';
import { convertApiToInfoBasketSourceTable } from './converter';
import { getFiltersTypes, prepareTableData } from './utils';

const initialTableFilters = {
    categories: [],
    platforms: [],
    mediaTypes: [],
};

const initialState: IInfoBasketSourceState = {
    filters: {
        categories: [],
        period: {} as IInfoBasketPeriod,
        locations: [],
    },
    tableFilters: initialTableFilters,
    data: [],
    tableData: [],
    status: FetchStatus.undefined,
};

const { actions: infoBasketSourceActions, reducer } = createSlice({
    name: 'infoBasketSource',
    initialState,
    reducers: {
        startLoading: (state: IInfoBasketSourceState): void => {
            state.status = FetchStatus.loading;
        },
        failedLoading: (state: IInfoBasketSourceState): void => {
            state.status = FetchStatus.failed;
        },
        successLoading: (state: IInfoBasketSourceState): void => {
            state.status = FetchStatus.success;
        },
        setFilters: (state: IInfoBasketSourceState, { payload }: PayloadAction<IInfoBasketSourceFiltersApi>): void => {
            state.filters = payload;
        },
        clearData: (state: IInfoBasketSourceState): void => {
            state.data = initialState.data;
            state.tableFilters = initialTableFilters;
            state.tableData = [];
        },
        setData: (state: IInfoBasketSourceState, { payload }: PayloadAction<unknown>): void => {
            state.data = convertApiToInfoBasketSourceTable(payload);
            state.tableFilters = getFiltersTypes(state.data);
            state.tableData = prepareTableData(state.data);
        },
    },
});

export { infoBasketSourceActions };
export default reducer;
