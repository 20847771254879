import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import DatePicker from '../../components/DatePicker';
import { DateRangePickerValue } from '../../components/DateRangePicker/types';
import CampaignTagsTable from './CampaignTagsTable';
import CampaignUserTagsTable from './CampaignUserTagsTable';
import styles from './styles.module.scss';

const CampaignTagsPage: React.FC = (): React.ReactElement => {
    const { Id: campaignId } = useParams<{ Id: string }>();
    const [activeTab, setActiveTab] = useState<1 | 2>(1);
    const [currentPeriod, setCurrentPeriod] = useState<DateRangePickerValue>([
        moment().subtract(30, 'day'),
        moment(),
    ]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.tabs}>
                    <button
                        type="button"
                        data-active={activeTab === 1}
                        onClick={() => setActiveTab(1)}
                    >
                        Теги
                    </button>
                    <button
                        type="button"
                        data-active={activeTab === 2}
                        onClick={() => setActiveTab(2)}
                    >
                        Пользовательские теги
                    </button>
                </div>

                <div className={styles.calendar}>
                    <DatePicker
                        value={currentPeriod}
                        onChange={setCurrentPeriod}
                    />
                </div>
            </div>

            {activeTab === 1 && (
                <CampaignTagsTable
                    period={currentPeriod}
                    campaignid={Number(campaignId)}
                />
            )}
            {activeTab === 2
                && (
                    <CampaignUserTagsTable
                        period={currentPeriod}
                        campaignid={Number(campaignId)}
                    />
                )}
        </div>
    );
};
export default CampaignTagsPage;
