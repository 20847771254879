import React, { useEffect, useState } from 'react';
import { Button, SvgIcon } from '@shared/index';
// import TextField from '@entities/TextField';
import { CrossIconsIds } from '@app/constants/icons';
import TextArea from '@entities/TextArea';
import styles from './styles.module.scss';

interface IProps {
    isOpen: boolean;
    title: string;
    textContent: string;
    textOnAccept?: string;
    textOnCancel?: string;
    onClose: () => void;
    onAccept: (comment: string) => void;
}

/**
 * Компонент модального окна для удаления с полем комментария
 * @param {boolean} isOpen - флаг отображения модального окна
 * @param {string} title - Заголовок
 * @param {string} textContent - Лейбл над филдом
 * @param {string} textOnAccept - Текст кнопки подтверждения(Дэфолтное: Подтвердить)
 * @param {string} textOnCancel - Текст кнопки отмены(Дэфолтное: Отменить)
 * @param {() => void} onClose - callback функция для закрытия модального окна
 * @param {(comment: string) => void} onApply - callback функция для применения удаления сущности
 */
const RemoveWithCommment: React.FC<IProps> = ({
    isOpen,
    title,
    textContent,
    textOnAccept = 'Подтвердить',
    textOnCancel = 'Отменить',
    onClose,
    onAccept,
}): JSX.Element => {
    const [comment, setComment] = useState<string>('');
    const handlerClose = (): void => {
        onClose();
    };
    const handlerApply = (): void => {
        onAccept(comment);
        onClose();
    };
    useEffect(() => {
        setComment('');
    }, [isOpen]);
    if (!isOpen) {
        return null;
    }
    return (
        <div className={styles.deleteModal_root}>
            <div className={styles.deleteModal_window}>
                <div className={styles.deleteModal_container}>
                    <div className={styles.deleteModal_container_header}>
                        <span className={styles.deleteModal_container_title}>{title}</span>
                        <button type="button" onClick={handlerClose}>
                            <SvgIcon id={CrossIconsIds.BigCross} size={14} />
                        </button>
                    </div>
                    <TextArea
                        defaultHeight={120}
                        className={styles.textArea}
                        label={textContent}
                        placeholder="Введите комментарий"
                        value={comment}
                        onChange={setComment}
                    />
                    <div className={styles.deleteModal_container_controls}>
                        <Button text={textOnAccept} disabled={!comment.length} type="default" size="large" onClick={handlerApply} />
                        <Button text={textOnCancel} type="secondaryDanger" size="large" onClick={handlerClose} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoveWithCommment;
