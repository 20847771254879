import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CampaignReportsFilters from '../../components/campaign/Report/Filters';
import usePptxHandler from '../../shared/hooks/pptx-handler/usePptxHandler';
import { campaignReportsRtkQService } from '../../services/campaigns/reports';
import CampaignReportsList from './CampaignReportsList';
import CampaignReportCreationProcess from './CreationProcess';
import styles from './styles.module.scss';

const CampaignReportsPage: React.FC = (): React.ReactElement => {
    const { Id: campaignId } = useParams();

    const [saveReportFile] = campaignReportsRtkQService.usePostSaveReportFileMutation();

    const [reportPeriod, setReportPeriod] = useState<string>('7');
    const [reportName, setReportName] = useState<string>('');
    const [clickedCreate, setClickedCreate] = useState<boolean>(false);

    const {
        createPptxFile, prepareData, isDataLoaded: isPptxDataLoaded, isLoading: isPptxLoading, isError: isPptxError,
    } = usePptxHandler();

    const handleChangePeriod = (period: string): void => {
        setReportPeriod(period);
    };

    const onClickCreateReport = (name: string): void => {
        setReportName(name);
        setClickedCreate(true);
        prepareData(reportPeriod);
    };

    const createReport = async (): Promise<void> => {
        const pptxFile = await createPptxFile(
            reportName,
            reportPeriod,
        );

        saveReportFile({
            campaignId,
            data: {
                file: pptxFile,
                reportName,
                reportTimeFrame: reportPeriod === '7' ? 'WEEK' : 'MONTH',
                extension: 'pptx',
                generated: true,
                chart_ids: [],
            },
        });

        setClickedCreate(false);
    };

    useEffect(() => {
        if (isPptxDataLoaded && clickedCreate) {
            createReport();
        }
    }, [isPptxDataLoaded, clickedCreate]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftColumnt}>
                {isPptxError || isPptxLoading
                    ? <CampaignReportCreationProcess isError={isPptxError} isLoading={isPptxLoading} />
                    : (
                        <CampaignReportsFilters
                            onSelectPeriod={handleChangePeriod}
                            onCreateReport={onClickCreateReport}
                        />
                    )}
            </div>

            <CampaignReportsList campaignId={campaignId} />
        </div>
    );
};
export default CampaignReportsPage;
