import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import SvgIcon from '@components/SvgIcon';
import TextArea from '@entities/TextArea';
import { Tooltip } from '@shared/index';
import TextField from '@entities/TextField';
import { Popover } from 'antd';
import styles from './styles.module.scss';

interface IProps {
    disabled?: boolean;
    cellId: string;
    id: number;
    sourceId: number;
    currentOpenId: string;
    text: string | number;
    integrationLink: string;
    isText: boolean;
    setActionRows: (id: number) => void;
    onChange: (sourceId: number, content: string, integrationLink: string) => void;
    onOpen: (id: string) => void;
}

const EditText: React.FC<IProps> = ({
    cellId,
    id,
    sourceId,
    disabled,
    currentOpenId,
    text,
    integrationLink,
    isText,
    setActionRows,
    onChange,
    onOpen,
}): JSX.Element => {
    const [isOpen, setOpenModal] = useState<boolean>(false);
    const [integrationLinkValue, setIntegrationLink] = useState<string>(integrationLink);
    const [localValue, setLocalValue] = useState<string>(text?.toString() ?? '');

    const handlerStartEdit = useCallback((): void => {
        setOpenModal(true);
        setLocalValue(text?.toString() ?? '');
        setIntegrationLink(integrationLink);
        setActionRows(id);
    }, []);

    const handlerOpenModal = useCallback((): void => {
        if (disabled) return;
        onOpen(cellId);
        setOpenModal(true);
        handlerStartEdit();
    }, [cellId]);

    const handlerApprove = useCallback(() => {
        setOpenModal(false);
        onChange(sourceId, localValue, integrationLinkValue);
    }, [sourceId, localValue, integrationLinkValue]);

    const handlerCancel = (): void => {
        setOpenModal(false);
        setLocalValue('');
        setIntegrationLink('');
    };

    const content = useMemo<JSX.Element>(() => (
        <div>
            <div className={styles.modal_form}>
                <div className={styles.modal_link}>
                    <span className={styles.modal_label}>Ссылка</span>
                    <TextField value={integrationLinkValue} onChange={setIntegrationLink} placeholder="Ссылка" />
                </div>
                <div className={styles.modal_text}>
                    <span className={styles.modal_label}>Текст</span>
                    <TextArea defaultHeight={200} value={localValue} onChange={(value) => setLocalValue(value)} />
                </div>
            </div>
            <div className={styles.modal_controller}>
                <div className={styles.modal_approval}>
                    <div
                        role="button"
                        onClick={handlerCancel}
                        className={styles.modal_close}
                    >
                        <SvgIcon id="RedCross" size={14} />
                    </div>
                    |
                    <div
                        role="button"
                        onClick={handlerApprove}
                        className={styles.modal_complete}
                    >
                        <SvgIcon id="Complete" size={18} />
                    </div>
                </div>
            </div>
        </div>
    ), [localValue, integrationLinkValue]);

    useEffect(() => {
        if (isOpen && cellId !== currentOpenId) {
            setOpenModal(false);
            setLocalValue('');
        }
    }, [currentOpenId, cellId, isOpen]);

    return (
        <Popover placement="rightTop" content={content} visible={isOpen}>
            <div
                className={styles.editText_root}
            >
                {
                    !disabled && (
                        <div
                            role="button"
                            onClick={handlerOpenModal}
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        >
                            <SvgIcon id="GreyPencil" size={24} />
                        </div>
                    )
                }
                {' '}
                <span className={styles.editText_text} style={{ color: isText ? '#787E93' : '#3B3D47' }}>
                    <Tooltip
                    // @ts-ignore
                        content={(<div style={{ maxWidth: 650 }}>{text}</div>)}
                        placement="bottomLeft"
                        xOffset={-2}
                        yOffset={0}
                        disabled={String(text).length < 15}
                        arrow={false}
                    >
                        <div style={{ maxWidth: 200 }}>
                            {text.toLocaleString('ru-Ru')}
                        </div>
                    </Tooltip>
                </span>
            </div>
        </Popover>
    );
};

export default EditText;
