import { useSelector } from 'react-redux';
import { selectUserProfileState } from '@store/user-profile/selectors';
import campaignSelectors from '@store/campaign/userRights/selectors';
import { useEffect, useMemo } from 'react';
import { campaignUsersRtkQService } from '@services/campaigns/users';
import currentPageSelectors from '@store/common/current-page/selectors';
import { ICampaignUserRole } from '@store/campaign/userRights/types';

const useGetUserRole = (): ICampaignUserRole | null => {
    const currentUser = useSelector(selectUserProfileState);
    const campaignUsers = useSelector(campaignSelectors.userRightsStateUsers);
    const currentCampaign = useSelector(currentPageSelectors.currentCampaign);

    const [getCampaignUsers] = campaignUsersRtkQService.useLazyGetCampaignUsersQuery();

    useEffect(() => {
        if (currentCampaign) {
            const campaignUsersExist = campaignUsers.filter((item) => item.campaignId === currentCampaign.id);
            if (!campaignUsersExist?.length) {
                getCampaignUsers({ id: currentCampaign.id });
            }
        }
    }, [currentCampaign]);

    const currentCampaignUsers = useMemo(() => {
        if (!campaignUsers?.length || !currentCampaign) return null;
        const data = campaignUsers.filter((campaign) => campaign.campaignId === currentCampaign.id);
        if (!data[0]) return null;
        return data[0].users;
    }, [campaignUsers, currentCampaign]);

    const role = useMemo(() => {
        if (!currentUser || !currentCampaign || !currentCampaignUsers) return null;
        const campaignUser = currentCampaignUsers.filter((user) => user.userId === currentUser.data.userId)[0];
        return campaignUser.role.id;
    }, [currentUser, currentCampaign, currentCampaignUsers]);

    return role;
};

export default useGetUserRole;
