import { IInfoBasketTableFilters } from 'store/info-basket/source/types';
import { IUGSTableData } from 'components/Table/info-basket-result/types';
import {
    IInfoBasketSource,
    IInfoBasketTable,
    NewsSourceType,
} from '../../../containers/info-basket/subpages/results/ResultTable/types';

const getPlatformTitle = (value: string): string => {
    switch (value.toLowerCase()) {
        case 'ok':
            return 'Ok.ru';
        case 'tg':
            return 'Теlegram';
        case 'vk':
            return 'Vkontakte';
        case 'rss':
            return 'Новости';
        default:
            return '';
    }
};

export const getFiltersTypes = (data: IInfoBasketSource[]): IInfoBasketTableFilters => {
    const platforms: string[] = [];
    const mediaTypes: string[] = [];
    const categories: string[] = [];

    for (let i = 0; i < data.length; i += 1) {
        const item = data[i];

        item.resource.forEach((name: string) => {
            const buffer = name.toLowerCase();
            if (!platforms.includes(buffer)) {
                platforms.push(buffer);
            }
        });
        const mediaType = item.typeId ? 'Блогер' : 'СМИ';
        if (!mediaTypes.includes(mediaType)) {
            mediaTypes.push(mediaType);
        }

        item.category.forEach((name: string) => {
            const buffer = name.toLowerCase();
            if (!categories.includes(buffer)) {
                categories.push(buffer);
            }
        });
    }

    return {
        platforms: platforms.map(
            (item, index) => ({
                id: index,
                name: getPlatformTitle(item),
                value: getPlatformTitle(item),
                isSelected: false,
            }),
        ),
        mediaTypes: mediaTypes.map(
            (item, index) => ({
                id: index,
                name: item,
                value: item,
                isSelected: false,
            }),
        ),
        categories: categories.map(
            (item, index) => ({
                id: index,
                name: `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`,
                value: `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`,
                isSelected: false,
            }),
        ),

    };
};

export const prepareTableData = (data: IInfoBasketSource[]): IUGSTableData<IInfoBasketTable>[] => {
    if (!data || !data.length) {
        return [];
    }
    return data.map((item: IInfoBasketSource) => {
        const categoryCount = item?.category.length > 1 ? `(+${item.category.length - 1})` : '';
        return {
            rowId: item.id.toString(),
            data: {
                id: item.id,
                name: { colId: 1, value: item.title },
                price: {
                    colId: 2,
                    value: item.price ?? 0,
                    valueStr: (item.price ?? 0).toLocaleString('ru-RU'),
                },
                category: { colId: 3, value: `${item.category[0]} ${categoryCount}`, allValues: item.category },
                type: { colId: 4, name: item.typeId ? 'Блогер' : 'СМИ', type: item.typeId ? NewsSourceType.blogger : NewsSourceType.media },
                audience: {
                    colId: 5,
                    value: item.subscribers ?? 0,
                    valueStr: (item.subscribers ?? 0).toLocaleString('ru-RU'),
                },
                platform: { colId: 6, value: item.resource },
                city: { colId: 7, value: item.city },
                views: item.postsViews,
                comments: item.postsComments,
            },
        };
    });
};
