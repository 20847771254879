/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import EventBusForChartGraph from '../../deprecated/pagesComponents/info-trace/PublicationChartGraph/EventBus';
import styles from './styles.module.scss';
import { userTagsCampaingRtqService } from '../../services/campaigns/userTags';
import QuickSVGLoader from '../QuickSVGLoader';
import SvgIcon from '../SvgIcon';
import InputFindTags from './InputFindTags';

interface IPropsModalCreateTag {
    listTags: string[],
    campaignId: number,
    postId: number,
    isOpen: boolean,
    onClose: () => void,
    onCreateTags: (tags: string[], remove: string[]) => void,
}

const ModalCreateManualTag: React.FC<IPropsModalCreateTag> = ({
    listTags,
    campaignId,
    postId,
    isOpen,
    onClose,
    onCreateTags,
}): JSX.Element => {
    if (!isOpen) return null;
    const [listCreatedTag, setListCreatedTag] = useState<string[]>([]);
    const [listRemovedTag, setListRemovedTag] = useState<string[]>([]);
    const [listAddTag, setListAddTag] = useState<any[]>([]);
    const [listAddTagName, setListAddTagName] = useState<string[]>([]);
    const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState<boolean>(false);
    const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>(false);
    const isLoading = useMemo(() => (isLoadingAdd || isLoadingRemove || isLoadingCreate), [isLoadingAdd, isLoadingRemove, isLoadingCreate]);
    const [createTag] = userTagsCampaingRtqService.useCreateUserTagMutation();
    const [addTagToPost] = userTagsCampaingRtqService.useAddTagToPostMutation();
    const [removeTagToPost] = userTagsCampaingRtqService.useRemoveTagFromPostMutation();
    const onAddTag = (tag: number, name: string) => {
        setListRemovedTag((prev) => prev.filter((id) => id !== name));
        setListAddTag((prev) => [...prev, tag]);
        setListAddTagName((prev) => [...prev, name]);
    };
    const onRemoveTag = (tag: number, name: string) => {
        setListAddTag((prev) => prev.filter((id) => id !== tag));
        setListAddTagName((prev) => prev.filter((id) => id !== name));
        setListRemovedTag((prev) => [...prev, name]);
    };
    const onChangeCreateTag = (tag: string) => {
        setListCreatedTag((prev) => {
            const has = prev.some((item) => item === tag);
            if (has) return prev.filter((item) => item !== tag);
            return [...prev, tag];
        });
    };
    const handlerAddTagToPost = (tagId) => addTagToPost({
        campaign_id: +campaignId,
        body: {
            tag_id: +tagId,
            post_id: +postId,
        },
    });
    const handlerRemoveTagToPost = (tagName) => removeTagToPost({
        campaign_id: +campaignId,
        body: {
            user_tag: tagName,
            post_id: +postId,
        },
    });
    const handleClickOnSave = () => {
        if (listCreatedTag) {
            setIsLoadingCreate(true);
            const listPromiseCreated = listCreatedTag.map((item) => createTag({
                title: item,
                campaign_id: campaignId,
                manual: true,
            }).then((res) => {
                const tagId = (res as any)?.data?.id;
                return handlerAddTagToPost(tagId);
            }));
            Promise.all(listPromiseCreated).then((res) => {
                onCreateTags(listCreatedTag, []);
                setIsLoadingCreate(false);
            });
        }
        if (listRemovedTag?.length) {
            setIsLoadingRemove(true);
            const listRemoveTagPromise = listRemovedTag.map((id) => handlerRemoveTagToPost(id));
            Promise.all(listRemoveTagPromise).then((res) => {
                onCreateTags([], listRemovedTag);
                setIsLoadingRemove(false);
            });
        }
        if (listAddTag?.length) {
            setIsLoadingAdd(true);
            const listAddTagPromise = listAddTag.map((id) => handlerAddTagToPost(id));
            Promise.all(listAddTagPromise).then((res) => {
                onCreateTags(listAddTagName, []);
                setIsLoadingAdd(false);
            });
        }
    };
    useEffect(() => {
        if (isLoading) { onClose(); }
        EventBusForChartGraph.emit('changeTags', []);
    }, [isLoading]);

    return (

        <div className={styles.overlay}>
            <div className={styles.rootModalCreateTag}>
                <div onClick={onClose} className={styles.btnClose}>
                    <SvgIcon id="Close" size={12} />
                </div>
                { isLoading
                && (
                    <div className={styles.loaderOverlay}>
                        <QuickSVGLoader />
                    </div>
                )}
                <div className={styles.tagName}>
                    Введите теги через запятую
                </div>
                <InputFindTags
                    campaignId={campaignId}
                    postId={postId}
                    listDefaults={listTags}
                    onAddTag={onAddTag}
                    onRemoveTag={onRemoveTag}
                    onChangeCreateTag={onChangeCreateTag}
                />

                {/* FOOTER */}
                <div className={styles.footer}>
                    <div
                        className={styles.btnSave}
                        onClick={handleClickOnSave}
                    >
                        Сохранить
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreateManualTag;
