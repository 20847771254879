/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { ITonalitySourceModel } from '../../../../services/campaigns/tracking/models';
import { trackingCampaingRtqService } from '../../../../services/campaigns/tracking';
import { DateRangePickerValue } from '../../../../components/DateRangePicker/types';
import { IApplyFilter } from '../../../../sections/MyCampaignContainer/CampaingTypes';
import styles from './styles.module.scss';
import useGetTonalityQuery from '../../../../shared/hooks/campaign/useGetTonality';
import { TTonality } from './types';
import MyCampaignChartContainerSkeleton from './Skeleton';
import { TonalityChartControls } from '../../../../features/campaign/tracking';
import { TonalityChart } from '../../../../entities';

export const OLD_FORMAT_DATA = 'YYYY-MM-DD';

interface IPropsTonalityChartContainer {
    campaignId: number,
    filters: IApplyFilter[],
    period: DateRangePickerValue,
    isIPad: boolean,
}

type TListActiveTonality = TTonality[];

const TonalityChartContainer: React.FC<IPropsTonalityChartContainer> = ({
    campaignId,
    filters,
    period,
    isIPad,
}): JSX.Element => {
    const [listActiveTonality, setListActiveTonality] = useState<TListActiveTonality>(
        ['positive', 'negative', 'neutral', 'old_positive', 'old_negative', 'old_neutral'],
    );
    const checkActive = (current: TTonality) => !!listActiveTonality.some((tonality: TTonality) => tonality === current);
    const { nowQuery, oldQuery, skipOld } = useGetTonalityQuery(campaignId, filters, period);
    const now = trackingCampaingRtqService.useGetTonalityQuery(nowQuery);
    const old = trackingCampaingRtqService.useGetTonalityQuery(oldQuery, { skip: skipOld });

    const { data: nowData, isFetching: isFetchingNow, isLoading: isLoadingNow } = now;
    const { data: oldData, isFetching: isFetchingOld, isLoading: isLoadingOld } = old;
    const getFormData = (data: ITonalitySourceModel[]) => data.reduce((acc, item) => {
        const newAcc = { ...acc };
        const x = new Date(item.dt).getTime();
        newAcc.positive.push({ y: item.tonality.positive, x });
        newAcc.negative.push({ y: item.tonality.negative, x });
        newAcc.neutral.push({ y: item.tonality.neutral, x });
        return newAcc;
    }, {
        positive: [],
        negative: [],
        neutral: [],
    });
    const getNowData = useMemo(() => {
        if (!nowData) return null;
        return getFormData(nowData);
    }, [nowData, nowQuery, oldQuery]);
    const getOldData = useMemo(() => {
        if (!oldData) return null;
        return getFormData(oldData);
    }, [oldData, nowQuery, oldQuery]);
    const handleChangeShow = (tonality: TTonality) => {
        setListActiveTonality((prev) => {
            const check = checkActive(tonality);
            if (check) return prev.filter((item) => item !== tonality);
            return [tonality, ...prev];
        });
    };
    const isShowSkeleton = useMemo(() => {
        if (isLoadingNow || isFetchingNow || isLoadingOld || isFetchingOld) return true;
        if (now && (isLoadingOld || isFetchingOld)) return true;
        return false;
    }, [now, old, isLoadingNow, isLoadingOld, isFetchingNow, isFetchingNow]);
    const emptyWithY = (list) => {
        if (!list) return true;
        const result = list.reduce((sum, item) => {
            const newSum = sum;
            if (!item || !item?.y || Number.isNaN(item?.y)) {
                return newSum;
            }
            return newSum + +item.y;
        }, 0);
        return !(result > 0);
    };
    const isEmpty = useMemo(() => {
        const emptyPositive = emptyWithY(getNowData?.positive);
        const emptyNeutral = emptyWithY(getNowData?.neutral);
        const emptyNegative = emptyWithY(getNowData?.negative);
        const emptyOldPositive = emptyWithY(getOldData?.positive);
        const emptyOldNeutral = emptyWithY(getOldData?.neutral);
        const emptyOldNegative = emptyWithY(getOldData?.negative);
        const nowEmpty = emptyPositive && emptyNeutral && emptyNegative;
        const oldEmpty = emptyOldPositive && emptyOldNeutral && emptyOldNegative;
        return !!nowEmpty && !!oldEmpty;
    }, [getNowData, getOldData]);
    if (isLoadingNow || isFetchingNow || isLoadingOld || isFetchingOld) {
        return (
            <div
                className={styles.containerTonalityChart}
                style={isIPad ? { width: 600 } : null}
            >
                {(isLoadingNow || isFetchingNow || isLoadingOld || isFetchingOld) && <MyCampaignChartContainerSkeleton />}
            </div>
        );
    }
    if (isEmpty) return null;
    return (
        <div
            className={styles.containerTonalityChart}
            style={isIPad ? { width: 600 } : null}
        >
            <TonalityChartControls
                isShowOld={Array.isArray(oldData) && !!oldData.length}
                now={nowData}
                old={oldData}
                positive={checkActive('positive')}
                neutral={checkActive('neutral')}
                negative={checkActive('negative')}
                oldPositive={checkActive('old_positive')}
                oldNeutral={checkActive('old_neutral')}
                oldNegative={checkActive('old_negative')}
                onChangeShowData={handleChangeShow}
            />
            <TonalityChart
                positiveData={checkActive('positive') && getNowData ? getNowData?.positive : []}
                neutralData={checkActive('neutral') && getNowData ? getNowData?.neutral : []}
                negativeData={checkActive('negative') && getNowData ? getNowData?.negative : []}
                positiveDataOld={checkActive('old_positive') && getOldData ? getOldData?.positive : []}
                neutralDataOld={checkActive('old_neutral') && getOldData ? getOldData?.neutral : []}
                negativeDataOld={checkActive('old_negative') && getOldData ? getOldData?.negative : []}
            />
        </div>
    );
};

export default React.memo(TonalityChartContainer);
