import { rtkQApi } from '@services/index';
import { currentPageActions } from '@store/common/current-page';
import { IProjectInfo, IQPProjectInfo } from '@services/projects/types';
import { transformProjectInfoResponse } from '@services/projects/transformers/getProgect';

export const projectInfoRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postCreateProject: build.query<any, IQPProjectInfo>({
            query: (params) => ({
                url: 'media_projects/project/',
                method: 'POST',
                body: {
                    name: params.name,
                    description: params.description,
                },
                invalidatesTags: ['ProjectsList'],
                providesTags: ['ProjectsList'],
            }),
        }),
        putUpdateProject: build.mutation<any, IQPProjectInfo>({
            query: (params) => ({
                url: `media_projects/project/${params.id}`,
                method: 'PUT',
                body: {
                    name: params.name,
                    description: params.description,
                },
            }),
            invalidatesTags: ['ProjectStatus'],
        }),
        getProject: build.query<IProjectInfo, { id: number }>({
            query: (params) => ({
                url: `media_projects/project/${params.id}`,
                method: 'GET',
            }),
            transformResponse: transformProjectInfoResponse,
            async onQueryStarted(args: { id: number }, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(currentPageActions.setCurrentProject(data));
                    });
            },
        }),
        getProjects: build.query<any, void>({
            query: () => ({
                url: 'media_projects/project/',
                method: 'GET',
            }),
        }),
    }),
});
