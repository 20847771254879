import React from 'react';
import { ICommonDropdownListMenuProps } from 'components/common/SelectDropdownList/Menu/types';

import styles from './styles.module.scss';

const CommonDropdownListMenu: React.FC<ICommonDropdownListMenuProps> = ({
    data, onSelectItem, onAddUser,
}): JSX.Element => {
    const handlerSelectItem = (id: string | number): void => {
        if (onSelectItem) {
            onSelectItem(Number(id));
        }
    };
    return (
        <div className={styles.root}>
            <button
                type="button"
                className={styles.addUserBox}
                onClick={onAddUser}
            >
                Добавить пользователя
            </button>
            <div className={styles.wrapper}>
                {
                    data?.length && data.map(({ id, name }) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <span key={id} className={styles.item} onClick={() => handlerSelectItem(id)}>{name}</span>
                    ))
                }
            </div>
        </div>
    );
};

export default CommonDropdownListMenu;
