export enum ContactType {
    email = 1,
    phoneNumber = 2,
    customerNumber = 3,
}

export interface IContact {
    personId: number | null;
    type: ContactType;
    value: string;
}

export interface IContactPerson {
    id: number | null;
    fullName: string;
    contacts: IContact[];
}

export interface ICustomerInfo {
    id: number;
    address: string;
    companyName: string;
    contactPersons: IContactPerson[];
}
