import { AppState } from 'store/store';

const sourceState = (state: AppState) => state.sources.sourceList;
const activePage = (state: AppState) => sourceState(state).filters.page;
const activeFilters = (state: AppState) => sourceState(state).filters;

export default {
    activePage,
    activeFilters,
};
