import React, { useEffect, useMemo, useState } from 'react';
import { ISimpleEntity } from '@store/types';
import { sourcesRtkQService } from '@services/sources';
import { categoriesRtkQService } from '@services/categories';
import CrossLiteIcon from '@assets/images/crossLite.svg';
import SvgIcon from '@components/SvgIcon';
import TextField from '@entities/TextField';
import { Button, Select } from '@shared/index';
import { ISelectItem } from '@shared/ui-components/Select/types';
import SourceSearchField from '@components/sources/AddSource/SearchField';
import { LogoIconsIds } from '@app/constants/icons';
import LinearLoader from '../../common/Loader';
import IconSwitcher from './Switcher/index';
import styles from './styles.module.scss';

// import CategoryDropDown from '@components/sources/AddSource/CategoryDropDown';

interface IProps {
    setIsModal: (isModal: boolean) => void,
}
interface IListPlatform {
    platform: string,
    isInverse: boolean,
}

type ErrorResponse = {
    status: number;
    data: {
        detail : string;
    }
};

const icons = new Map([
    ['ok.ru', LogoIconsIds.OkLogo],
    ['vk.com', LogoIconsIds.VkLogo],
    ['t.me', LogoIconsIds.TgLogo],
    ['dzen.ru', LogoIconsIds.ZenLogo],
    ['youtube.com', LogoIconsIds.YouTubeLogo],
    ['rutube.ru', LogoIconsIds.RuTubeLogo],
    ['vk_video', LogoIconsIds.VkVideo],
]);

const keysIcon = [...icons.keys()];

const initialPlatform = [
    { platform: 'vk', isInverse: true },
    // { platform: 'rss', isInverse: false },
    { platform: 'dzen', isInverse: false },
    { platform: 'tg', isInverse: false },
    { platform: 'ok', isInverse: false },
    { platform: 'rutube', isInverse: false },
    { platform: 'youtube', isInverse: false },
    { platform: 'vk_video', isInverse: false },
] as IListPlatform[];

const rssIcon = (itemStr: string): any => {
    if (itemStr) {
        const keyMap = keysIcon.find((key) => itemStr.includes(key));
        return keyMap ? <SvgIcon id={icons.get(keyMap)} size={24} /> : null;
    }
    return null;
};

const AddSourceModal: React.FC<IProps> = ({ setIsModal }): JSX.Element => {
    const [listPlatform, setListPlatform] = useState<IListPlatform[]>(initialPlatform);
    const [checkedCategoriesIds, setCheckedCategoriesIds] = useState<string[]>([]);
    const [link, setLink] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isAdded, setAddedState] = useState<boolean>(false);
    const [checkboxes, setCheckboxes] = useState<ISelectItem[]>(null);
    const [categorySearchValue, setCategorySearch] = useState<string>('');

    const {
        data: categories,
        isLoading: isLoadingCategories,
        isFetching: isFetchingCategories,
    } = categoriesRtkQService.useGetCategoriesQuery();

    const handleSelectItem = (id) => {
        setCheckboxes((prev) => prev.map((item) => ({
            ...item,
            select: id === item.id ? !item.select : item.select,
        })));
    };

    const [
        addSource,
        {
            data: source,
            isLoading: isLoadingSource,
            isFetching: isFetchingSource,
            isError: isSourceError,
            error: sourceError,
        },
    ] = sourcesRtkQService.useLazyPostAddSourcesQuery();
    const { data: sourceTypes } = sourcesRtkQService.useGetLoadSourcesQuery();

    const activePlatform = useMemo<ISimpleEntity>(() => (
        (sourceTypes ?? []).find(
            ({ value }) => value.toString() === listPlatform.find(({ isInverse }) => isInverse)?.platform,
        )
    ), [listPlatform, sourceTypes]);

    const sourceName = useMemo((): string => (
        (source?.title ?? '---') > 95 ? `${source.title.substring(0, 95)}...` : source?.title ?? '---'
    ), [source]);

    const addedSource = useMemo<JSX.Element>(() => (
        source?.link && (
            <div
                key={source.id}
                className={styles.addSource_sourceResult}
            >
                <div className={styles.addSource_sourceResult_title}>{sourceName}</div>
                <div className={styles.addSource_sourceResult_link}>
                    { rssIcon(source?.link) ?? <SvgIcon id="RssLogo" /> }
                    {source?.link ?? '---'}
                </div>
            </div>
        )
    ), [source, sourceName]);

    const addBtnIsDisabled = useMemo<boolean>(() => (
        listPlatform?.some(({ isInverse }) => isInverse) && !(link?.length > 0) && Number(activePlatform?.id) > 0
    ), [listPlatform, link, activePlatform]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlerChecked = (id: string): void => {
        if (checkedCategoriesIds.includes(id)) {
            setCheckedCategoriesIds(checkedCategoriesIds.filter((item) => item !== id));
            return;
        }
        setCheckedCategoriesIds([...checkedCategoriesIds, id]);
    };
    const handlerAddSource = (): void => {
        addSource({
            tag: activePlatform.value.toString(),
            typeId: Number(activePlatform?.id),
            parseLink: link,
            link,
            title: link,
            enabled: true,
            categories: checkedCategoriesIds.map((id) => Number(id)),
        });
    };
    const handlerActivePlatformIcon = (name: string): void => {
        setListPlatform(listPlatform.map(
            (item) => ({ platform: item.platform, isInverse: name === item.platform }),
        ));
    };
    const handlerReload = (): void => {
        setAddedState(false);
        setLink('');
        setCheckedCategoriesIds([]);
        setErrorMessage(null);
    };

    const handlerSearchCategory = (value: string): void => {
        setCategorySearch(value);
    };

    useEffect(() => {
        if (isSourceError) {
            const error = sourceError as ErrorResponse;
            if (error?.status === 409) {
                setErrorMessage(error?.data?.detail);
                return;
            }
            if (error?.status === 422) {
                setErrorMessage('Не существующий источник.');
                return;
            }
            setErrorMessage('Повторите попытку');
            return;
        }
        setErrorMessage(null);
    }, [isSourceError, sourceError]);

    useEffect(() => {
        if (source && !isSourceError) {
            setAddedState(true);
        }
    }, [isSourceError, source]);

    useEffect(() => {
        const result = categorySearchValue?.length > 3
            ? (categories ?? []).filter((item) => item.mainCat.includes(categorySearchValue))
            : [...(categories ?? [])];
        setCheckboxes((result ?? []).map((item) => ({
            id: item.id,
            value: item.mainCat,
            label: item.mainCat,
            select: false,
        })));
    }, [categories, isLoadingCategories, isFetchingCategories, categorySearchValue]);

    return (
        <div className={styles.addSource}>
            <div className={styles.addSource_window}>
                <div className={styles.addSource_container}>
                    {
                        (isLoadingSource || isFetchingSource) && (
                            <div style={{ height: 270 }}>
                                <div className={styles.addSource_titlePlatform}>
                                    <span>{' '}</span>
                                    <button
                                        type="button"
                                        onClick={() => setIsModal(false)}
                                    >
                                        <img src={CrossLiteIcon} alt="BigCross" />
                                    </button>
                                </div>
                                <div style={{
                                    display: 'flex', height: 220, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12,
                                }}
                                >
                                    <span className={styles.addSource_message}>Поиск и добавление источника</span>
                                    <LinearLoader />
                                </div>
                            </div>
                        )
                    }
                    {
                        (errorMessage || isAdded) && (
                            <div style={{ height: 270 }}>
                                <div className={styles.addSource_titlePlatform}>
                                    <span>{' '}</span>
                                    <button
                                        type="button"
                                        onClick={() => setIsModal(false)}
                                    >
                                        <img src={CrossLiteIcon} alt="BigCross" />
                                    </button>
                                </div>
                                <div style={{
                                    display: 'flex', height: 220, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 6,
                                }}
                                >
                                    {
                                        isAdded && (
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                                                <span className={styles.addSource_message}>Источник успешно добавлен.</span>
                                                {addedSource}
                                            </div>
                                        )
                                    }
                                    {
                                        errorMessage && (
                                            <>
                                                <span className={styles.addSource_message}>Произошла ошибка.</span>
                                                <button
                                                    type="button"
                                                    onClick={handlerReload}
                                                    className={styles.addSource_messageReload}
                                                >
                                                    Попробовать другой источник
                                                </button>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        !(isLoadingSource || isFetchingSource || errorMessage || isAdded) && (
                            <>
                                <div className={styles.addSource_titlePlatform}>
                                    <span>Типы платформ</span>
                                    <button
                                        type="button"
                                        onClick={() => setIsModal(false)}
                                    >
                                        <img src={CrossLiteIcon} alt="BigCross" />
                                    </button>
                                </div>
                                <div className={styles.addSource_socialIconList}>
                                    {listPlatform.map((element) => (
                                        <IconSwitcher
                                            key={element.platform}
                                            label={element.platform}
                                            isInverse={element.isInverse}
                                            onSwitch={handlerActivePlatformIcon}
                                            isDisabled={false}
                                        />
                                    ))}
                                </div>
                                <div className={styles.addSource_source}>
                                    <TextField
                                        label="Ссылка на источник"
                                        value={link}
                                        onChange={(value) => setLink(value)}
                                        placeholder="https://www.rbc.ru/politics... "
                                    />
                                </div>
                                <div className={styles.addSource_category}>
                                    <div className={styles.addSource_categoryPanel}>
                                        <Select
                                            size="large"
                                            placeholder="Категории"
                                            items={checkboxes ?? []}
                                            accordion
                                            onSelect={handleSelectItem}
                                            isCheckBoxes
                                            header={(
                                                <div style={{
                                                    minHeight: 50, display: 'flex', alignItems: 'center', marginTop: 8,
                                                }}
                                                >
                                                    <SourceSearchField
                                                        searchValue={categorySearchValue}
                                                        onSearch={handlerSearchCategory}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.addSource_controller}>
                                    <Button
                                        type="default"
                                        size="large"
                                        text="Добавить"
                                        onClick={handlerAddSource}
                                        disabled={addBtnIsDisabled}
                                    />
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AddSourceModal;
