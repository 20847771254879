import { rtkQApi } from '../..';
import { campaignActions } from '../../../store/campaign/source';
import { ISingleCampaignUsersModel } from '../../../store/campaign/source/types';
import { campaignUserRightsActions } from '../../../store/campaign/userRights';
import { ICampaignUserRightsUserInfo } from '../../../store/campaign/userRights/types';
import { IUserInfo } from '../../common/types';
import { ICampaignUserFromApi, IQPAddUser } from './types/index';

export const campaignUsersRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postAddUserCampaign: build.query<void, IQPAddUser>({
            query: (params: IQPAddUser) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: `camp_an/campaign/${params.id}/user`,
                    method: 'POST',
                    body: {
                        email: params.email,
                    },
                };
            },
            async onQueryStarted(args: IQPAddUser, { dispatch, queryFulfilled }) {
                dispatch(campaignActions.startUserAdding());
                queryFulfilled
                    .then(() => {
                        dispatch(campaignActions.successUserAdding());
                    })
                    .catch((error) => {
                        dispatch(campaignActions.failedUserAdding());
                        dispatch(campaignActions.setFetchErrors(error?.message));
                    });
            },
        }),
        getCampaignUsers: build.query<ICampaignUserRightsUserInfo[], { id: number }>({
            query: (params: { id: number }) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: `campaign/${params.id}/user`,
                    method: 'GET',
                };
            },
            transformResponse: (
                baseQueryResponse: ICampaignUserFromApi[],
                meta,
                arg,
            ): ICampaignUserRightsUserInfo[] => baseQueryResponse.map((item) => ({
                userId: item.user_id,
                email: item.email,
                firstName: item.first_name,
                middleName: item.middle_name,
                lastName: item.last_name,
                campaignId: arg.id,
                id: item.id,
                role: item.role,
            } as ICampaignUserRightsUserInfo)),
            async onQueryStarted(args: { id: number }, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(campaignUserRightsActions.setUsersState({ campaignId: args.id, users: data }));
                    });
            },
        }),
        getSingleCampaignUsers: build.query<ISingleCampaignUsersModel, { id: number }>({
            query: (params: { id: number }) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: `campaign/${params.id}/user`,
                    method: 'GET',
                };
            },
            transformResponse: (baseQueryResponse: ICampaignUserFromApi[], meta, arg): ISingleCampaignUsersModel => {
                const users = baseQueryResponse.map((item) => ({
                    userId: item.user_id,
                    owner: item.role?.name === 'owner',
                    email: item.email,
                    firstName: item.first_name,
                    middleName: item.middle_name,
                    lastName: item.last_name,
                } as IUserInfo));
                return { users, campaignId: arg.id };
            },
            async onQueryStarted(args: { id: number }, { dispatch, queryFulfilled }) {
                dispatch(campaignActions.loadingSingleCampaignUsers());
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(campaignActions.successSingleCampaignUsers());
                        dispatch(campaignActions.setSingleCampaignUsersId(args.id));
                        dispatch(campaignActions.setSingleCampaignOwnerFromApi(data?.users));
                    })
                    .catch((error) => {
                        dispatch(campaignActions.failedSingleCampaignUsers());
                        dispatch(campaignActions.setFetchErrors(error?.message));
                    });
            },
        }),
        deleteUserFromCampaign: build.query<void, { campaignId: number, userId: string }>({
            query: (params) => ({
                url: `campaign/${params?.campaignId}/user`,
                method: 'DELETE',
                body: {
                    user_id: params.userId,
                },
            }),
        }),
    }),
});
