import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { LogoImageInList } from '@shared/index';
import { useAppDispatch } from '@store/hooks';
import { currentPageActions } from '@store/common/current-page';
import { sourcesRtkQService } from '@services/sources';
import SvgIcon from '@components/SvgIcon';
import useGetDate from '@shared/hooks/useFormatDate';
import styles from './styles.module.scss';
// import NoPhotoImg from '../../../assets/images/sources/noSource.svg';

const platformsMap = new Map([
    ['rss', { element: <SvgIcon key={0} id="RssLogo" size={24} />, title: 'RSS' }],
    ['vk', { element: <SvgIcon key={1} id="VkLogo" size={24} />, title: 'Vkontakte' }],
    ['tg', { element: <SvgIcon key={2} id="TgLogo" size={24} />, title: 'Telegram' }],
    ['ok', { element: <SvgIcon key={3} id="OkLogo" size={24} />, title: 'Odnoklassniki' }],
    ['dzen', { element: <SvgIcon key={4} id="ZenLogo" size={24} />, title: 'Дзен' }],
    ['rutube', { element: <SvgIcon key={5} id="RuTubeLogo" size={24} />, title: 'rutube' }],
    ['youtube', { element: <SvgIcon key={6} id="YouTubeLogo" size={23} height={16} />, title: 'youtube' }],
]);

interface IProps {
    sourceId: number
}

const SourceHeader : React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { sourceId } = props;
    const [getSource, { data: sourceInfo, isFetching }] = sourcesRtkQService.useLazyGetSourceQuery();
    const dispatch = useAppDispatch();

    const [isCategoriesExpand, setCategoriesExpand] = useState<boolean>(false);
    const [created, setCreated] = useGetDate(new Date(sourceInfo?.created.replace('+0000', '')), 'DD.MM.YYYY HH:mm');
    const toggleCategoriesExpand = (): void => {
        setCategoriesExpand((prev) => !prev);
    };

    useEffect(() => {
        if (sourceInfo) {
            dispatch(currentPageActions.setCurrentSource(sourceInfo));
        }
    }, [sourceInfo]);

    useEffect(() => {
        if (sourceId) {
            dispatch(currentPageActions.clearCurrentSource());
            getSource({ sourceId });
        }
    }, [sourceId]);

    useEffect(() => {
        setCreated(new Date(sourceInfo?.created.replace('+0000', '')));
    }, [sourceInfo]);

    return (
        <>
            {sourceInfo
                && (
                    <div className={styles.wrapper}>
                        <div className={styles.photo}>
                            <LogoImageInList logoUrl={sourceInfo?.logoUrl} height={138} width={138} />
                            {/* <img src={NoPhotoImg} alt="" /> */}
                        </div>
                        <div className={styles.content}>
                            <div className={styles.info}>
                                <div className={styles.infoSide}>
                                    <div className={styles.name}>
                                        <p>{sourceInfo?.title ?? '-'}</p>
                                        {platformsMap.get(sourceInfo?.sourceType?.title)?.element}
                                    </div>
                                    <p className={styles.type}>
                                        публичный источник

                                        <a href={sourceInfo.link} target="_blank" className={styles.link} rel="noreferrer">
                                            <SvgIcon id="LinkIcon" size={12} />
                                            <p>{sourceInfo?.link ?? '-'}</p>
                                        </a>
                                    </p>
                                    <div className={styles.author}>
                                        <p>
                                            автор:
                                            {' '}
                                            {sourceInfo?.author?.title}
                                        </p>
                                        {/* <Link to="href">{sourceInfo.author.title}</Link> */}
                                    </div>
                                </div>
                                <div className={styles.infoSide}>
                                    <div className={styles.date}>
                                        <p>добавлен в систему</p>
                                        <p>{created?.date}</p>
                                    </div>
                                </div>
                            </div>
                            {sourceInfo?.categories && (
                                <>
                                    <button
                                        type="button"
                                        onClick={toggleCategoriesExpand}
                                        className={classNames(styles.categoriesButton, isCategoriesExpand ? styles.categoriesButtonActive : '')}
                                    >
                                        <p>категории</p>
                                        <div>
                                            <SvgIcon id="ArrowCarousel" size={20} />
                                        </div>
                                    </button>
                                    {isCategoriesExpand && (
                                        <div className={styles.categories}>
                                            {sourceInfo?.categories?.map((item) => (
                                                <div key={item.id}>{item?.title}</div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            {isFetching && !sourceInfo && <Skeleton className={styles.skeleton} height={200} />}
        </>
    );
};
export default SourceHeader;
