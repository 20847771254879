import React from 'react';
import classnames from 'classnames';
import { ICheckboxProps } from './types';
import UGCMSCheckbox from '../../../../../common/Checkbox';

import styles from './styles.module.scss';

const CustomCheckbox: React.FunctionComponent<ICheckboxProps> = ({
    checked, name, onChecked, id, isRetinaSize,
    textClassName, labelElement, isDisabled,
    tagId, classNames, isError, style,
}): JSX.Element => {
    const handleChecked = (): void => {
        if (!isDisabled && onChecked) {
            onChecked(id);
        }
    };

    return (
        <div
            id={tagId ? '' : `basket-region-${id}`}
            className={classnames(styles.container, classNames ?? '')}
            style={style}
        >
            <UGCMSCheckbox
                tagId={tagId}
                isError={isError}
                isChecked={checked}
                isDisabled={isDisabled ?? false}
                onChecked={handleChecked}
            />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                className={classnames(
                    styles.labelContainer,
                    textClassName || styles.text,
                    styles[isRetinaSize ? 'retina' : ''],
                )}
                onClick={() => handleChecked()}
            >
                {labelElement || name}
            </div>
        </div>
    );
};
export default CustomCheckbox;
