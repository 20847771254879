import React from 'react';
import { IDropdownList } from 'components/Select/SimpleSelect/DropdownList/types';
import classnames from 'classnames';

import styles from './styles.module.scss';

const DropdownList: React.FunctionComponent<IDropdownList> = ({ items, onSelect, isRetinaSize }: IDropdownList): JSX.Element => {
    const onHandlerClick = (id: string): void => {
        onSelect(id);
    };

    return (
        <div className={classnames(
            styles.container,
            styles[isRetinaSize ? 'retina' : ''],
        )}
        >
            {
                items.length > 0
            && items.map((item) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <span
                    id={`basket-period-${item.id}`}
                    className={classnames(
                        styles.text,
                        styles[isRetinaSize ? 'retina' : ''],
                    )}
                    key={item.id}
                    onClick={() => onHandlerClick(item.id)}
                >
                    {item.name}
                </span>
            ))
            }
        </div>
    );
};

export default DropdownList;
