import { IFoundPostState } from './types';

export const convertApiToFoundPost = (data: unknown): IFoundPostState => {
    const result = {
        source: '',
        newsId: '',
        content: '',
        title: '',
        logo: '',
        link: '',
    } as IFoundPostState;

    if (!data) {
        return result;
    }

    Object.keys(data).forEach((name) => {
        switch (name) {
            case 'source':
                result.source = data[name];
                break;
            case 'newsId':
                result.newsId = data[name];
                break;
            // case 'postedTimestamp':
            //     // eslint-disable-next-line @typescript-eslint/dot-notation
            //     result.newsId = `${data['source']}-${data['postedTimestamp']}`;
            //     break;
            case 'content':
                result.content = data[name];
                break;
            case 'title':
                result.title = data[name];
                break;
            case 'logo':
                result.logo = data[name];
                break;
            case 'link':
                result.link = data[name];
                break;
            default:
                break;
        }
    });

    return result;
};
