import React, { useEffect, useMemo } from 'react';
import { ISimpleEntity } from '@store/types';
import CampaignTagsFavoriteList from '@components/campaign/TagsFavoriteList/Modals/FavoriteList';
import { favoriteWordsRtkQService } from '@services/campaigns/favorite-words';

interface IProps {
    top: number;
    left: number;
    isOpen: boolean;
    onAddWord: (value: string, id: number) => void;
    onClose: () => void;
    newWord: string;
}

const CampaignWordsFavoriteListContainer: React.FC<IProps> = ({
    top,
    left,
    isOpen,
    onAddWord,
    onClose,
    newWord,
}): JSX.Element => {
    const [
        getFavoriteWords,
        { currentData: favoriteWords, isLoading },
    ] = favoriteWordsRtkQService.useLazyGetFavoriteWordsQuery();
    const [
        addFavoriteWord,
        { data: newFavoriteWord, isLoading: addIsLoading, isSuccess: addIsSuccess },
    ] = favoriteWordsRtkQService.useLazyPostCreateFavoriteWordQuery();
    const [
        deleteFavoriteWord,
        { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess },
    ] = favoriteWordsRtkQService.useDeleteFavoriteWordMutation();

    const currentWords = useMemo<string[]>(() => {
        if (isLoading) {
            return [];
        }
        return favoriteWords?.map(({ word }) => word) ?? [];
    }, [favoriteWords, isLoading]);

    const tagList = useMemo<ISimpleEntity[]>(() => {
        if (isLoading) {
            return [];
        }
        let result: ISimpleEntity[] = favoriteWords?.map(
            ({ id, word }) => ({ id, name: word, value: word } as ISimpleEntity),
        ) ?? [];
        if (newWord) {
            const words = favoriteWords?.map(({ word }) => word) ?? [];
            if (!words.includes(newWord)) {
                result = [{ id: -1, value: newWord, name: newWord }, ...result];
            }
        }
        return result;
    }, [favoriteWords, newWord, isLoading]);

    const handlerAddFavorite = (): void => {
        if (newWord && !currentWords.includes(newWord)) {
            addFavoriteWord({ word: newWord });
        }
    };
    const handlerDeleteFavorite = (id: number): void => {
        const deletedWord = favoriteWords.find(({ id: wordId }) => wordId === id);
        if (deletedWord) {
            deleteFavoriteWord({ id });
        }
    };

    useEffect(() => {
        if (isOpen) {
            getFavoriteWords();
        }
    }, [isOpen]);
    useEffect(() => {
        if (!deleteIsLoading && deleteIsSuccess) {
            getFavoriteWords();
        }
    }, [deleteIsLoading, deleteIsSuccess]);
    useEffect(() => {
        if (addIsSuccess && !addIsLoading && newFavoriteWord?.id > 0 && newFavoriteWord?.word === newWord) {
            getFavoriteWords();
        }
    }, [addIsSuccess, addIsLoading, newWord, newFavoriteWord]);

    return (
        <CampaignTagsFavoriteList
            top={top}
            left={left}
            isLoading={isLoading}
            isOpen={isOpen}
            onAddWord={onAddWord}
            onClose={onClose}
            tagList={tagList}
            onAddFavorite={handlerAddFavorite}
            onDeleteFavorite={handlerDeleteFavorite}
        />
    );
};

export default CampaignWordsFavoriteListContainer;
