import React from 'react';
import styles from './styles.module.scss';

interface IPropsAuthorCell {
    name: string;
}
const AuthorCell: React.FC<IPropsAuthorCell> = ({ name }): JSX.Element => (
    <div className={styles.authorCell}>
        <div className={styles.authorCell_label}>{name || 'Не определенно'}</div>
    </div>
);

export default React.memo(AuthorCell);
