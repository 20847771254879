/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import CampaignStatusContainer from '../../../../containers/campaign/Menu/CampaignStatus';
import styles from './styles.module.scss';

interface IPropsCampaignStatus {
    campaignId: number,
    isActive: boolean,
}

const StatusToggle: React.FC<IPropsCampaignStatus> = ({
    campaignId,
    isActive,
}): JSX.Element => {
    const classContainer = isActive ? styles.campaingStatusContainer : styles.campaingStatusContainerDisabled;

    return (
        <div className={classContainer}>
            <div className={styles.campaingStatusLabel}>Статус кампании:</div>
            <div className={styles.campaingStatusToggle}>
                <CampaignStatusContainer campaignId={campaignId} />
            </div>
        </div>
    );
};
export default StatusToggle;
