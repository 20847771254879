/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { t } from 'i18next';
import styles from './styles.module.scss';

interface ICampaingFilterProps {
    checkBoxId: string,
    label: string,
    count: number,

    checked: boolean,
    disableCounter: boolean,
    withConditions?: boolean
    disabled?: boolean,

    rootClasses?: string,
    handlerConditions?: (...args: any) => any,
    onChange: (event: CheckboxChangeEvent) => void,
}
const CampaingFilter: React.FC<ICampaingFilterProps> = ({
    checkBoxId, label, count, checked, disableCounter, withConditions, disabled, rootClasses, handlerConditions, onChange,
}): JSX.Element => (
    <div className={`${styles.containerFilterCheckBox} ${rootClasses} ${disabled ? styles.disabledField : ''}`}>
        <div className={styles.checkboxWithContent}>
            <div className={styles.disabled} />
            <Checkbox
                id={checkBoxId}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className={styles.labelCheckBox}>{t(label)}</div>
                {withConditions && (
                    <div
                        className={styles.labelAdditionLink}
                        style={{ zIndex: 1 }}
                        onClick={() => {
                            handlerConditions();
                        }}
                    >
                        Просмотреть
                    </div>
                )}
            </div>
        </div>
        {
            !disableCounter && (
                <div className={styles.checkboxCounter}>
                    {count}
                </div>
            )
        }
    </div>
);

export default CampaingFilter;
