import { IApplyFilter, IListFilters, ListCategory } from '../../../../sections/MyCampaignContainer/CampaingTypes';

/* eslint-disable @typescript-eslint/naming-convention */
export const constructorApplyFilter = (filters_category, filter_name): IApplyFilter => ({ filters_category, filter_name });

export const getListFilters = (listCategories: ListCategory): IListFilters => {
    if (!listCategories) {
        return {
            filters_include: [],
            filters_exclude: [],
        };
    }
    const result = listCategories.reduce((filterList: IListFilters, category) => {
        const { filters, filters_category } = category;
        const { filters_exclude, filters_include }: IListFilters = { ...filterList };
        filters.forEach(({
            checked, include, exclude, filter_name,
        }) => {
            if (checked || include) filters_include.push(constructorApplyFilter(filters_category, filter_name));
            if (!checked && exclude) filters_exclude.push(constructorApplyFilter(filters_category, filter_name));
        });
        return filterList;
    }, {
        filters_include: [],
        filters_exclude: [],
    });
    return result;
};

export const isEqualFilters = (current: IApplyFilter, check: IApplyFilter) => {
    const isName = current.filter_name === check.filter_name;
    const isCategory = current.filters_category === check.filters_category;
    return isName === isCategory;
};
