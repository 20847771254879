import { combineReducers } from 'redux';
import reportReducer from './report/index';
import wrapperReducer from './wrapper/index';

const commonReducers = () => combineReducers({
    report: reportReducer,
    wrapper: wrapperReducer,
});

export default commonReducers;
