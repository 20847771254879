import React, { useMemo } from 'react';
import classnames from 'classnames';
import LastItem from '@entities/LastItem';
import getKey from '@app/helpers/getKey';
import Skeleton from 'react-loading-skeleton';
import { Checkbox, Menu } from 'antd';
import { t } from 'i18next';
import { IPropsOptions, ISelectItem } from '../types';
import styles from '../styles.module.scss';

// TODO разделить логику: первоначальная подгрузка данных чере skeleton, догрузка списка через circle loader
const OptionsMenu: React.FC<IPropsOptions> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    size = 'medium',
    infinity = false,
    infinityStep,
    updateInfinityDate,
    isUpdateInfinityDate = false,
    items,
    isCheckBoxes,
    header,
    footer,
    classesForSize,
    toggleItem,
    customWidth = '100%',
    onDisplay,
    isLoading = false,
    isAccordion = false,
}): JSX.Element => {
    const step = useMemo(() => {
        const resultPX = (items.length - infinityStep) * 48;
        return resultPX;
    }, [items.length]);

    const classesForSkeleton = useMemo(() => {
        if (isLoading === false) return '';
        if (size === 'large') return styles.skeletonOverlay_large;
        if (size === 'medium') return styles.skeletonOverlay_medium;
        if (size === 'small') return styles.skeletonOverlay_small;
        return '';
    }, [size, isLoading]);

    const classesForScroll = useMemo(() => {
        if (size === 'large' && items.length > 5) return styles.withScroll;
        if (size === 'medium' && items.length > 6) return styles.withScroll;
        if (size === 'small' && items.length > 7) return styles.withScroll;
        return '';
    }, [items, size]);

    // TODO N.Sychev - добавил для отладки unit tests, позже необходимо убрать
    // проблема в этой записи - typeof item.label === 'string' ? t(item.label) : item.label
    // если оставить только item.label, тесты видят результат
    const getElement = (item: ISelectItem): JSX.Element => {
        if (onDisplay) onDisplay(item.label.toString());
        return <span>{typeof item.label === 'string' ? t(item.label) : item.label}</span>;
    };

    const listItems = items.map((item) => (
        <div
            role="button"
            className={classnames(
                styles.item,
                classesForSize,
                styles.content,
                item.classes || '',
                item.select ? styles.selected : '',
            )}
            onClick={(e) => toggleItem(item, e)}
            key={getKey(item.id)}
        >
            {
                isCheckBoxes && (
                    <Checkbox
                        checked={item.select}
                        style={{ marginRight: 8 }}
                    />
                )
            }
            {
                getElement(item)
            }
            {/* <span data-testid="test_456">{typeof item.label === 'string' ? t(item.label) : item.label}</span> */}
        </div>
    ));

    const toggleScroll = () => {
        const container = document.getElementsByClassName(styles.containerScroll)[0];
        updateInfinityDate();
        if (container) container.scroll(0, step);
    };

    return (
        <Menu style={{ height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {header && header}
                <div className={classesForScroll && styles.containerScroll}>
                    {isLoading && !isAccordion && (
                        <Skeleton
                            containerClassName={classnames(classesForSkeleton, styles.skeletonOverlay)}
                            height={25}
                            count={items.length === 0 ? 1 : items.length}
                            key={getKey(items.length)}
                        />
                    )}
                    {
                        isAccordion && (
                            <div
                                className={classnames(
                                    styles.menu,
                                    classesForSize,
                                    classesForScroll,
                                )}
                                style={{ width: customWidth }}
                            >
                                {listItems}
                                {infinity && (
                                    <LastItem
                                        onLastItem={toggleScroll}
                                        loadingType="CIRCLE"
                                        size={size}
                                        enabled={items.length === infinityStep || isUpdateInfinityDate}
                                    />
                                )}
                            </div>
                        )
                    }
                    {
                        !isAccordion && !isLoading && (
                            <div
                                className={classnames(
                                    styles.menu,
                                    classesForSize,
                                    classesForScroll,
                                )}
                                style={{ width: customWidth }}
                            >
                                {listItems}
                                {infinity && (
                                    <LastItem
                                        onLastItem={toggleScroll}
                                        loadingType="SKELETON"
                                        size={size}
                                        enabled={items.length === infinityStep || isUpdateInfinityDate}
                                    />
                                )}
                            </div>
                        )
                    }
                </div>
                <div>{footer && footer}</div>
            </div>
        </Menu>
    );
};

export default OptionsMenu;
