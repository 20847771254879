import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { ISimplePeriod } from 'store/campaign/comparison';
import LineSlider from './LineSlider';
import DateSliderTickLabels from './TickLabels';
import { ISimpleEntity } from '../../../../../../store/types';
import commonUtilities from '../../../../../../utils/commons/commonUtils';

import styles from './styles.module.scss';

interface IProps {
    lineWidth: number;
    stepWidth: number;
    countCompany: number;
    minDate: Date;
    maxDate: Date;
    dateStart: Date;
    defaultStartDate: Date;
    dateEnd: Date;
    onChangeComparisonPeriod?: (value: ISimplePeriod) => void;
    defaultPeriodChoice?: ISimplePeriod | null;
    isPeriodChoice?: boolean;
    zoomIncreaseDecrease: number;
    onChangeGranularity: (value: number) => void;
}

const amountDaysOffset = 2;
const oneDay = 1000 * 60 * 60 * 24;
const sliderHalfWidth = 12 / 2;
const granularityMaxInDays = 7;
const granularityMinInDays = 1;

const calculateGranularity = (
    isInvalidRange: boolean,
    amountTicks: number,
    maxLabelsAmount: number,
    zoomIncreaseDecrease: number,
): number => {
    if (isInvalidRange) {
        return granularityMinInDays;
    }
    const result = Math.ceil(amountTicks / maxLabelsAmount) + zoomIncreaseDecrease;
    if (result > granularityMaxInDays) {
        return granularityMaxInDays;
    }
    if (result < granularityMinInDays) {
        return granularityMinInDays;
    }
    return result;
};

const DateSlider: React.FC<IProps> = ({
    minDate,
    maxDate,
    defaultStartDate,
    dateStart,
    dateEnd,
    countCompany,
    lineWidth,
    stepWidth,
    isPeriodChoice = false,
    defaultPeriodChoice = null,
    onChangeComparisonPeriod,
    zoomIncreaseDecrease,
    onChangeGranularity,
}): JSX.Element => {
    const [currentStartDate, setCurrentStartDate] = useState<Date>(
        () => defaultStartDate,
    );
    const [currentEndDate, setCurrentEndDate] = useState<Date>(
        () => defaultPeriodChoice?.end ?? dateEnd,
    );

    const isInvalidRange = useMemo<boolean>(() => (
        !dateEnd || !dateStart || dateEnd <= dateStart
    ), [dateStart, dateEnd]);
    const maxLabelsAmount = useMemo<number>(() => (Math.floor(lineWidth / stepWidth)), [lineWidth, stepWidth]);
    const tickWeight = useMemo<number>(() => (lineWidth / maxLabelsAmount), [lineWidth, maxLabelsAmount]);
    const amountTicks = useMemo<number>(() => {
        if (isInvalidRange || !minDate || !maxDate) {
            return 0;
        }
        const result = amountDaysOffset * 2 + ((maxDate.getTime() - minDate.getTime()) / oneDay);
        return result < maxLabelsAmount ? maxLabelsAmount : result;
    }, [minDate, maxDate, isInvalidRange, maxLabelsAmount]);

    const [granularityInDays, setGranularity] = useState<number>(() => (
        calculateGranularity(isInvalidRange, amountTicks, maxLabelsAmount, zoomIncreaseDecrease)
    ));

    const firstDateInRange = useMemo<Date | null>(() => {
        if (!minDate) {
            return null;
        }
        const result = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
        if (isInvalidRange) {
            return result;
        }
        result.setDate(result.getDate() - amountDaysOffset);
        return result;
    }, [minDate, isInvalidRange]);
    const lastDateInRange = useMemo<Date | null>(() => {
        if (!maxDate) {
            return null;
        }
        const result = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());
        if (isInvalidRange) {
            return result;
        }
        result.setDate(result.getDate() + amountDaysOffset);
        return result;
    }, [maxDate, isInvalidRange]);
    const tickLabels = useMemo<ISimpleEntity[]>(() => {
        const result: ISimpleEntity[] = [];
        if (isInvalidRange || !firstDateInRange) {
            return result;
        }
        const prevDate = new Date(firstDateInRange.getFullYear(), firstDateInRange.getMonth(), firstDateInRange.getDate());
        const curDate = new Date(firstDateInRange.getFullYear(), firstDateInRange.getMonth(), firstDateInRange.getDate());
        const nextDate = new Date(firstDateInRange.getFullYear(), firstDateInRange.getMonth(), firstDateInRange.getDate());
        for (let i = 0; i < maxLabelsAmount; i += 1) {
            nextDate.setDate(nextDate.getDate() + granularityInDays);
            let value = '';
            const middleDay = commonUtilities.getMiddleDayInMonth(curDate);
            if (
                nextDate.getMonth() !== curDate.getMonth()
                || curDate.getMonth() !== prevDate.getMonth()
                || i === 0
                || maxLabelsAmount - i === 1
            ) {
                value = curDate.getDate().toString();
            }

            if (i > 0 && prevDate.getDate() < middleDay && nextDate.getDate() > middleDay) {
                const monthName = commonUtilities.capitalize(curDate.toLocaleString('ru-RU', { month: 'long' }));
                if (result[i - 1].value === monthName) {
                    result[i - 1].value = '';
                    result[i - 1].name = '';
                }
                value = monthName;
            }
            result.push({
                id: i,
                name: value,
                value,
            });
            curDate.setDate(curDate.getDate() + granularityInDays);
            if (i > 0) {
                prevDate.setDate(prevDate.getDate() + granularityInDays);
            }
        }
        return result;
    }, [isInvalidRange, granularityInDays, firstDateInRange, lastDateInRange, maxLabelsAmount]);
    const calculatesDefaultStartLineSlider = useMemo<number>(() => {
        if (isInvalidRange || !firstDateInRange) {
            return 0;
        }
        return Math.round((
            ((defaultStartDate.getTime() - firstDateInRange.getTime()) / oneDay) / granularityInDays
        ) * tickWeight);
    }, [firstDateInRange, defaultStartDate, isInvalidRange, granularityInDays]);
    const calculatesStartLineSlider = useMemo<number>(() => {
        if (isInvalidRange || !firstDateInRange) {
            return 0;
        }
        return Math.round((
            ((dateStart.getTime() - firstDateInRange.getTime()) / oneDay) / granularityInDays
        ) * tickWeight);
    }, [firstDateInRange, dateStart, isInvalidRange, granularityInDays]);
    const defaultStartLineSlider = useMemo<number>(() => (
        calculatesDefaultStartLineSlider < 0 ? 0 : calculatesDefaultStartLineSlider
    ), [calculatesDefaultStartLineSlider]);
    const startLineSlider = useMemo<number>(() => (
        calculatesStartLineSlider < 0 ? 0 : calculatesStartLineSlider
    ), [calculatesStartLineSlider]);
    const endLineSlider = useMemo<number>(() => {
        if (isInvalidRange) {
            return 0;
        }
        let result = Math.round((
            ((dateEnd.getTime() - dateStart.getTime()) / oneDay) / granularityInDays
        ) * tickWeight);

        // define the width for the tracking period bar of the current campaign,
        // taking into account the zoom (exceeding the right border of the scale)
        if (calculatesStartLineSlider < 0) {
            if (result > Math.abs(calculatesStartLineSlider)) {
                result -= Math.abs(calculatesStartLineSlider);
            } else if (result > lineWidth && lineWidth > Math.abs(calculatesStartLineSlider)) {
                result = lineWidth - Math.abs(calculatesStartLineSlider);
            } else {
                result -= Math.abs(calculatesStartLineSlider);
            }
        } else {
            result = calculatesStartLineSlider + result > lineWidth ? lineWidth - calculatesStartLineSlider : result;
        }
        if (result > lineWidth) {
            return lineWidth;
        }
        return result < 0 ? 0 : result;
    }, [dateEnd, dateStart, isInvalidRange, tickWeight, granularityInDays, calculatesStartLineSlider, lineWidth]);
    // const startCircleSlider = useMemo<number>(() => {
    //     if (isInvalidRange) {
    //         return 0;
    //     }
    //     return Math.round((
    //         ((currentStartDate.getTime()) / oneDay) / granularityInDays
    //     ) * tickWeight);
    // }, [currentStartDate, isInvalidRange, granularityInDays]);
    // const endCircleSlider = useMemo<number>(() => {
    //     if (isInvalidRange || startCircleSlider > lineWidth) {
    //         return 0;
    //     }
    //     let result = Math.round((
    //         ((currentEndDate.getTime() - currentStartDate.getTime()) / oneDay) / granularityInDays
    //     ) * tickWeight);
    //
    //     // define the width for the tracking period bar of the current campaign,
    //     // taking into account the zoom (exceeding the right border of the scale)
    //     result = startCircleSlider + result > lineWidth ? lineWidth - startCircleSlider : result;
    //     return result;
    // }, [currentEndDate, currentStartDate, isInvalidRange, tickWeight, granularityInDays, startCircleSlider, lineWidth]);

    const dateMove = (isMoveStartDate: boolean) => useCallback((offsetX: number): void => {
        if (!isPeriodChoice || !firstDateInRange) {
            return;
        }
        const offset = offsetX + sliderHalfWidth;
        const bufferDate = new Date(firstDateInRange.getFullYear(), firstDateInRange.getMonth(), firstDateInRange.getDate());
        bufferDate.setDate(firstDateInRange.getDate() + (offset * granularityInDays) / tickWeight);
        const dateStr = bufferDate.toLocaleDateString('ru-RU');
        const currentStartDateStr = currentStartDate.toLocaleDateString('ru-RU') ?? '';
        if (isMoveStartDate) {
            if (currentStartDateStr !== dateStr) {
                setCurrentStartDate(bufferDate);
                onChangeComparisonPeriod({ start: bufferDate, end: currentEndDate });
            }
            return;
        }
        const currentEndDateStr = currentEndDate.toLocaleDateString('ru-RU') ?? '';
        if (currentEndDateStr !== dateStr) {
            setCurrentEndDate(bufferDate);
            onChangeComparisonPeriod({ start: currentStartDate, end: bufferDate });
        }
    }, [
        isPeriodChoice,
        firstDateInRange,
        onChangeComparisonPeriod,
        granularityInDays,
        tickWeight,
        currentStartDate,
        currentEndDate,
    ]);
    const handlerMoveStartDate = dateMove(true);
    const handlerMoveEndDate = dateMove(false);

    useEffect(() => {
        // granularity of the timeline for componentDidMount
        onChangeGranularity(granularityMinInDays);
    }, []);
    useEffect(() => {
        const result = calculateGranularity(isInvalidRange, amountTicks, maxLabelsAmount, zoomIncreaseDecrease);
        onChangeGranularity(result);
        setGranularity(result);
    }, [isInvalidRange, amountTicks, maxLabelsAmount, zoomIncreaseDecrease]);

    if (isInvalidRange) {
        return null;
    }

    return (
        <div className={styles.dateSlider_root}>
            <LineSlider
                max={lineWidth}
                end={endLineSlider}
                countCompany={countCompany}
                defaultStartDate={defaultStartLineSlider}
                start={startLineSlider}
                // startCircleSlider={startCircleSlider}
                // endCircleSlider={endCircleSlider}
                isPeriodChoice={isPeriodChoice}
                onMoveStartDate={handlerMoveStartDate}
                onMoveEndDate={handlerMoveEndDate}
                currentStartDate={currentStartDate.toLocaleDateString('ru-RU') ?? ''}
                currentEndDate={currentEndDate.toLocaleDateString('ru-RU') ?? ''}
            />
            <DateSliderTickLabels
                maxWidth={lineWidth}
                data={tickLabels}
                tickWidth={stepWidth}
            />
        </div>
    );
};

export default DateSlider;
