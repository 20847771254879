import { AppState } from '@store/store';
import { TableNames } from '@store/common/tableProperties/types';

const tablePropertiesState = (state: AppState) => state.common.tableProperties;

const mediaPlanColumnProperties = (state: AppState) => (
    // eslint-disable-next-line no-prototype-builtins
    tablePropertiesState(state).hasOwnProperty(TableNames.MediaPlan) ? tablePropertiesState(state)[TableNames.MediaPlan]?.columns ?? {} : null
);

export default {
    mediaPlanColumnProperties,
};
