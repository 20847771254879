import { Dispatch } from 'redux';
import { AppState, AppThunk } from 'store/store';
import { getCategories } from '../../../../deprecated/api/categories/infoBasketApi';
import { infoBasketCategoryActions } from '../index';
import categorySelectors from '../selectors';

export const loadCategoriesIfNotExists = (): AppThunk => (dispatch: Dispatch, getState: () => AppState): void => {
    if (categorySelectors.ids(getState())?.length > 0) {
        return;
    }

    dispatch(infoBasketCategoryActions.startLoading());

    getCategories()
        .then((data: any) => {
            dispatch(infoBasketCategoryActions.successLoading(data));
        })
        .catch(() => {
            dispatch(infoBasketCategoryActions.failedLoading());
        });
};
