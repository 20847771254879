export const longlistHeaderCellsTooltips = {
    views: {
        plan: [
            'Плановое количество просмотров рекламного поста.',
            'Заполняется вручную.',
            'По умолчанию равно среднему.',
        ],
        avg: [
            'Количество просмотров, которое в среднем набирает один пост в источнике',
        ],
    },
    ER: {
        plan: [
            'Плановый показатель вовлеченности аудитории, который ожидается по результатам рекламной кампании.',
            'Заполняется вручную.',
            'По умолчанию равно среднему.',
        ],
        avg: [
            'Средний показатель вовлеченности аудитории данного источника.',
        ],
    },
    CPV: {
        plan: [
            'Плановая стоимость одного просмотра рекламного поста, которая ожидается по результатам рекламной кампании.',
            'Заполняется вручную.',
            'По умолчанию равно среднему.',
        ],
        avg: [
            'Стоимость одного просмотра.',
            'Считается исходя из введенной вами цены и среднего количества просмотров.',
        ],
    },
};
