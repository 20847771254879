import { IChartPointsModel } from '../../services/campaigns/Charts/models';

export function createPointsFromCommons() {
    return false;
}

export class MapChart {
    subjects: IChartPointsModel[];

    options: any = {};

    dataset:any = {
        borderWidth: 1,
        radius: '40%',
    };

    constructor(subjects: IChartPointsModel[]) {
        this.subjects = subjects;
    }

    getWords() {
        const listWithClones = this.subjects.map((item) => {
            const newItem = {
                value: +item.y[0],
                name: item?.subjectValues?.join(',') || 'Не определено',
            };
            return newItem;
        });
        return listWithClones.reduce((acc, item) => {
            const newAcc = [...acc];
            const clone = newAcc.find((elem) => elem.name === item.name);
            if (clone) clone.value += item.value;
            else newAcc.push(item);
            return newAcc;
        }, []);
    }

    public create(type): any {
        // const data = getSettingsForData(listPiecesPie);
        return {
            typeChart: type,
            data: this.getWords(),
        };
    }
}
