import { ICurrentPageState } from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { ISourceModel } from '@services/sources/types';
import { IProjectInfo } from '@services/projects/types';
import { convertFromSingleCampaignApi } from '@store/campaign/source/converter';

const initialState: ICurrentPageState = {
    currentCampaign: null,
    currentSource: null,
    currentProject: null,
};

const { actions: currentPageActions, reducer } = createSlice({
    name: 'currentPage',
    initialState,
    reducers: {
        clearCurrentCampaign: (state: ICurrentPageState): void => {
            state.currentCampaign = null;
        },
        setDataSingleCampaignFromApi: (state: ICurrentPageState, { payload }: PayloadAction<unknown>): void => {
            state.currentCampaign = convertFromSingleCampaignApi(payload);
        },
        setCurrentCampaign: (state: ICurrentPageState, { payload }: PayloadAction<ICampaignDataModel>): void => {
            state.currentCampaign = payload;
        },
        clearCurrentSource: (state: ICurrentPageState): void => {
            state.currentSource = null;
        },
        setCurrentSource: (state: ICurrentPageState, { payload }: PayloadAction<ISourceModel>): void => {
            state.currentSource = payload;
        },
        clearCurrentProject: (state: ICurrentPageState): void => {
            state.currentProject = null;
        },
        setCurrentProject: (state: ICurrentPageState, { payload }: PayloadAction<IProjectInfo>): void => {
            state.currentProject = payload;
        },
    },
});

export { currentPageActions };
export default reducer;
