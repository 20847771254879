import React from 'react';
import Skeleton from 'react-loading-skeleton';
import CampaignDnDSinglePost from './SinglePost';
import { IPosts } from '../../../../../services/campaigns/tracking/models';
import CalendarIcon from '../../../../../assets/images/CampaignsDnD/calendar.svg';
import styles from './styles.module.scss';

interface IProps {
    isLoading: boolean;
    posts: IPosts[];
}

const dateNow = new Date().toLocaleDateString('ru-Ru');

const CampaignDnDPosts: React.FC<IProps> = ({
    isLoading,
    posts,
}): JSX.Element => (
    <div className={styles.campaignDnDPosts_root}>
        <div className={styles.title}>
            Последние 5 сообщений
            <img src={CalendarIcon} alt="CalendarIcon" />
            <span>{dateNow}</span>
        </div>
        {
            isLoading && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                    <Skeleton height={40} width={260} />
                </div>
            )
        }
        {
            !isLoading && (
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    {
                        posts.map((item) => (
                            <CampaignDnDSinglePost
                                key={item.id}
                                sourceType={item.source_type}
                                title={item.title}
                                content={item.content}
                                likes={item.likes}
                                reposts={item.reposts}
                                comments={item.comments}
                                views={item.views}
                                lastUpdate={item.last_update}
                            />
                        ))
                    }
                </div>
            )
        }
    </div>
);

export default CampaignDnDPosts;
