import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Path from '../../../shared/lib/paths';
import { usersRtkQService } from '../../../services/common/users';
import { previewSingleCampaignRtkQService } from '../../../services/campaigns/single-preview';
import { categoriesRtkQService } from '../../../services/categories';
import {
    ISearchGroup,
} from '../../../components/campaign/SearchRequest/types';
import { ICampaignDataModel } from '../../../services/campaigns/campaigns-list/types';
import SearchRequestPageModal from '../../../components/campaign/SearchRequest/Modal/SearchPage';
import { FetchStatus } from '../../../store/types';
import CampaignCreateForm from '../../../components/campaign/Create';
import campaignSelectors from '../../../store/campaign/source/selectors';
import { campaignActions } from '../../../store/campaign/source';
import { useAppDispatch } from '../../../store/hooks';
import { CampaignActiveModalType, ModalAnswerType } from '../../../store/campaign/source/types';
import { campaignTrackedSourcesActions } from '../../../store/campaign/tracked-sources';
import { messageModalActions } from '../../../store/common/message-modal';
import { selectCommonWindowIsIPadSize } from '../../../store/common/window/selectors';
import { singleCampaignRtkQService } from '../../../services/campaigns/single-campaign';
import trackedSourcesSelectors from '../../../store/campaign/tracked-sources/selectors';
import searchRequestHelpers from '../../../components/campaign/SearchRequest/helpers';
import { ITrackedWordModel } from '../../../components/campaign/UpdateForm/TrackedWords/types';
import favoriteSourcesSelectors from '../../../store/campaign/favorite-sources/selectors';
import { IFavoriteSourceModel } from '../../../services/campaigns/favorite-sources/types';
import { ITrackedSourceModel } from '../../../store/campaign/tracked-sources/types';

const getCategoriesIds = (ids: number[]): number[] => {
    if (Array.isArray(ids) && ids.every((id) => id === 0)) {
        return [];
    }
    return ids;
};

const CampaignCreateContainer: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const { Id: urlParametersId } = useParams();

    const dispatch = useAppDispatch();
    const campaignCategoriesIds = useSelector(campaignSelectors.selectedCategories);
    const needClear = useSelector(campaignSelectors.needClearCampaignPage);
    const campaignActiveModal = useSelector(campaignSelectors.activeModal);
    const campaignModalAnswer = useSelector(campaignSelectors.modalAnswer);
    const createStatus = useSelector(campaignSelectors.createStatus);
    const campaignName = useSelector(campaignSelectors.currentCampaignName);
    const campaignId = useSelector(campaignSelectors.currentCampaignId);
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);
    const trackedSources = useSelector(trackedSourcesSelectors.tracked);
    const excludedSources = useSelector(trackedSourcesSelectors.excluded);
    const favoriteSourcesData = useSelector(favoriteSourcesSelectors.favoriteSources);

    const [createCampaign] = singleCampaignRtkQService.usePostCreateCampaignMutation();
    const [
        getCategories,
        { data: categories, isLoading: isLoadingCategories },
    ] = categoriesRtkQService.useLazyGetCategoriesQuery();
    const [
        getPreviewPosts,
        { data: previewPosts, isLoading: isLoadingPreviewPosts },
    ] = previewSingleCampaignRtkQService.useLazyGetCampaignPostPreviewQuery();
    const { data: userInfo } = usersRtkQService.useGetUserInfoQuery();

    const [campaignCreateBegun, setCreateBegun] = useState<boolean>(false);
    const [dataForCreate, setDataForCreate] = useState<ICampaignDataModel | null>(null);
    const [isOpenSearchRequestModal, setOpenStateSearchRequestModal] = useState<boolean>(false);
    const [createSearchRequest, setSearchRequest] = useState<ISearchGroup | null>(null);
    const [isSearchRequest, setIsSearchRequest] = useState<boolean>(false);
    const [searchRequestStr, setSearchRequestStr] = useState<string>('');
    const [selectedFavoriteSources, setFavoriteSources] = useState<IFavoriteSourceModel[]>([]);

    const handlerCreateBegun = (): void => {
        dispatch(campaignActions.setCreateStatus(true));
        if (!campaignCreateBegun) {
            setCreateBegun(true);
        }
    };
    const handlerCancelClick = (): void => {
        dispatch(campaignActions.setActiveModal(CampaignActiveModalType.cancelCreation));
        if (campaignCreateBegun) {
            setCreateBegun(false);
        }
    };
    const handlerCreateClick = (data: ICampaignDataModel): void => {
        // dispatch(createCampaignThunk(data));
        setDataForCreate(data);
    };
    const handlerDeleteTrackedSource = (isTracked: boolean, id: number): void => {
        dispatch(campaignTrackedSourcesActions.removeSelectedSource({ isTracked, id }));
    };
    const handlerClearAllSelectedSources = (): void => {
        dispatch(campaignTrackedSourcesActions.clearSelected());
    };
    const handlerDataCleared = (): void => {
        dispatch(campaignActions.setClearDataCampaign(false));
        dispatch(campaignActions.setCreateStatus(false));
    };
    const handlerCreateSearchRequest = (): void => {
        // navigate(`/${Path.Campaign}/${Path.SearchRequest}/${campaignId ?? 0}`);
        setOpenStateSearchRequestModal(true);
    };
    const handlerCloseSearchRequestModal = (): void => {
        setOpenStateSearchRequestModal(false);
    };
    const handlerSaveSearchRequestModal = (searchString: string): void => {
        setSearchRequestStr(searchString);
        setOpenStateSearchRequestModal(false);
        // const testStr = '(((t4_1 & !t4_1) | (t3)) & (t2)) & !(t1_1 & t1_2)';
        // const testStr = '(((t1 | !t2) & (t3 | t4) & !(t5 & t6)) | !(t6)) | (t34) | (t90)';
        // const testStr = '(((t1) & (t2)) & (t3) | (t4)) & (t5) & !(t6)';
        // const testStr = '(((t1 | t2) & (t3) & !(t4)) | (t5) & (t6 & t7 | t8)) | !(t9) & (t10)';
        // const testStr = '("t1 | t2 "~3) & (t3) & !(t4) | (t5) & ("t6 & t7 | t8 "/4) | !(t9) & (t10)';
        // const groups = searchRequestHelpers.getGroups(testStr, 0);
        // const testResult = searchRequestHelpers.searchStringToNode('((("t1fd fdfd" | t2) & ("t3gfdg gfhgf") & !(t4)) | (t5) & (t6 & t7 | t8)) | !(t9) & (t10)');
        const preparedStr = searchRequestHelpers.replaceRepetitionsCondition(searchString);
        const groups = searchRequestHelpers.getGroups(preparedStr, 0);
        //     if (acc.some(({ level }) => level === curr.level)) {
        //         acc.find(({ level }) => level === curr.level).data.push(curr.phrasesString);
        //     } else {
        //         acc.push({ level: curr.level, data: [curr.phrasesString] });
        //     }
        //     return acc;
        // }, [] as { level: number; data: string[] }[]));
        const nested = searchRequestHelpers.parseSearchRequest(groups);
        const rebuildObj = searchRequestHelpers.resetGroupIndex(nested, 1, null, null);
        setSearchRequest(rebuildObj);
    };
    const handlerCheckedSearchRequest = (value: boolean): void => {
        setIsSearchRequest(value);
    };
    const handlerSetFavoriteSources = (value: ITrackedWordModel[]): void => {
        const selectedIds = value.map(({ id }) => id);
        const result = favoriteSourcesData?.data?.filter((item) => selectedIds.includes(item.id));
        setFavoriteSources([...result]);
    };
    const handlerGetPreviewPosts = (data: ICampaignDataModel): void => {
        getPreviewPosts({
            name: data?.name,
            categories: data?.categoriesIds,
            searchQuery: data?.searchQuery,
            isComplexQuery: data?.isComplexQuery,
            excludeWords: data?.excludeWords,
            isBlogger: data?.isBlogger,
            includeWords: data?.includeWords,
            parseFrom: data?.parseFrom,
            isTrackedLinks: data?.isTrackedLinks,
            sources: data?.sources,
            sourceType: data?.sourceType,
            withoutPopup: false,
            shortUser: userInfo,
        });
    };

    const selectedCategoriesIds = useMemo<number[]>(() => getCategoriesIds(campaignCategoriesIds) ?? [], []);

    useEffect(() => {
        // dispatch(loadCategoriesIfNotExists());
        // dispatch(campaignActions.setCreateStatus(false));
        getCategories();
    }, []);
    useEffect(() => {
        if (campaignActiveModal === CampaignActiveModalType.cancelCreation) {
            if (campaignModalAnswer === ModalAnswerType.accept) {
                dispatch(campaignActions.setClearDataCampaign(true));
            }
            // если отмена - то задаём пустое активное окно
            if (campaignModalAnswer !== ModalAnswerType.undefined) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
            }
        }
    }, [campaignModalAnswer, dispatch]);
    useEffect(() => {
        if (createStatus === FetchStatus.success) {
            dispatch(campaignActions.setInitialCreatingStatus());

            dispatch(messageModalActions.setMessage(
                (
                    <>
                        <span>
                            Вы только что создали Кампанию
                            {` ${campaignName ?? '-'}`}
                            .
                        </span>
                        <span>Для просмотра результатов отслеживания</span>
                        <span>перейдите во вкладку Отслеживание</span>
                    </>
                ),
            ));
            dispatch(messageModalActions.setButtonTagId('button-campaign-create-ok'));
            dispatch(messageModalActions.setButtonText('Ok'));

            const id = Number(urlParametersId);
            if (campaignId !== id || !urlParametersId) {
                dispatch(campaignActions.setCreateStatus(false));
                dispatch(campaignActions.setViewActionCampaign());
                navigate(`/${Path.Campaign}/${Path.CampaignUpdate}/${campaignId}`);
            }
        }
    }, [createStatus, campaignName, dispatch, urlParametersId, campaignId]);
    useEffect(() => {
        if (dataForCreate) {
            const sourcesBuffer = [
                ...selectedFavoriteSources.map((item) => ({
                    id: item?.source?.id,
                    allowInRussia: item?.source?.allow_in_russia,
                    authorId: item?.source?.author_id,
                    created: item?.source?.created,
                    autoDiscover: item?.source?.auto_discover,
                    enabled: item?.source?.enabled,
                    link: item?.source?.link,
                    outSourceId: item?.source?.out_source_id,
                    parsed: item?.source?.parsed ?? '',
                    tag: item?.source?.tag,
                    locationId: item?.source?.location_id,
                    title: item?.source?.title,
                    parseLink: item?.source?.parse_link,
                    typeId: item?.source?.type_id,
                } as ITrackedSourceModel)),
                ...(dataForCreate.isTrackedLinks ? trackedSources : excludedSources),
            ];
            createCampaign({
                name: dataForCreate.name,
                isTrackedLinks: dataForCreate.isTrackedLinks,
                categories: dataForCreate.categoriesIds,
                parseFrom: dataForCreate.parseFrom,
                sourceType: dataForCreate.sourceType,
                includeWords: dataForCreate.includeWords,
                excludeWords: dataForCreate.excludeWords,
                isBlogger: dataForCreate.isBlogger,
                withoutPopup: false,
                isComplexQuery: isSearchRequest,
                searchQuery: !searchRequestStr ? null : searchRequestStr,
                sources: sourcesBuffer,
            });
            setDataForCreate(null);
        }
    }, [dataForCreate]);

    return (
        <>
            <CampaignCreateForm
                isLoading={false}
                categories={categories}
                selectedCategoriesIds={selectedCategoriesIds}
                onCreateBegun={handlerCreateBegun}
                onCancelClick={handlerCancelClick}
                onCreateClick={handlerCreateClick}
                onDeleteTrackedSource={handlerDeleteTrackedSource}
                needClear={needClear}
                onClearAllSelectedSources={handlerClearAllSelectedSources}
                onCleared={handlerDataCleared}
                onCreateSearchRequest={handlerCreateSearchRequest}
                isIPadSize={isIPadSize}
                checkedSearchRequest={handlerCheckedSearchRequest}
                isSearchRequest={isSearchRequest}
                searchRequestStr={searchRequestStr}
                onSelectedFavoriteSources={handlerSetFavoriteSources}
                onGetPreviewPosts={handlerGetPreviewPosts}
                previewPosts={previewPosts ?? []}
                isLoadingPreviewPosts={isLoadingPreviewPosts}
                isLoadingCategories={isLoadingCategories}
            />
            <SearchRequestPageModal
                isView={false}
                searchRequest={createSearchRequest}
                isOpen={isOpenSearchRequestModal}
                onClose={handlerCloseSearchRequestModal}
                onSave={handlerSaveSearchRequestModal}
            />
        </>
    );
};

export default CampaignCreateContainer;
