/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { Popover } from 'antd';
import DropDownControls from '@features/source/TabelSources/ui/DropDownControls';
import { SvgIcon } from '@shared/index';
import styles from './styles.module.scss';

interface IItemSelcet {
    id: number,
    label: string,
    isSelect: boolean
}
interface IProps {
    onClickAddToList: (lists: number[]) => void;
    onCreateList: () => void;
    onSetInFavorite: () => void;
    sourceId: number;
    sourceTitle: string;
    isFavorit: boolean;
    lists: IItemSelcet[];
}

const SourcesListCellActions: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        onClickAddToList, onSetInFavorite, onCreateList, sourceId, sourceTitle, isFavorit, lists,
    } = props;
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const handleClickOnCreateList = () => {
        onCreateList();
        setIsMenuOpen(false);
    };
    const menuRef = useRef(null);
    return (
        <div className={styles.actionsCell}>
            <button
                type="button"
                className={`${isFavorit ? styles.active : ''} ${styles.actionsCellFavorite}`}
                onClick={onSetInFavorite}
            >
                <SvgIcon id="FavoriteIcon_New" size={24} />
            </button>

            <div className={styles.actionsCellMenu} ref={menuRef}>
                <Popover
                    content={(
                        <DropDownControls
                            lists={[...lists]}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            onSetSourceInList={(listsId) => onClickAddToList(listsId)}
                            onCreateList={handleClickOnCreateList}
                        />
                    )}
                    trigger="click"
                    visible={isMenuOpen}
                    onVisibleChange={setIsMenuOpen}
                    overlayClassName={styles.tooltipOverlay}
                    placement="bottomRight"
                    align={{ offset: [-10, -8] }}
                    showArrow={false}
                    overlayInnerStyle={{ borderRadius: 6, overflow: 'hidden', padding: 0 }}
                >
                    <button type="button" className={styles.actionsCellMenuButton} onClick={() => setIsMenuOpen((prev) => !prev)}>
                        <span />
                    </button>
                </Popover>
                {/* {isMenuOpen && (
                    <div className={styles.actionsCellMenuList}>
                        <button type="button" onClick={onClickAddToList}>
                            <SvgIcon id={CommonIconsIds.PlusLargeIcon} size={24} />
                            <span>Добавить в список</span>
                        </button>
                        <SourceListCreateModal sourceId={sourceId} sourceTitle={sourceTitle} />
                    </div>
                )} */}
            </div>
        </div>
    );
};
export default SourcesListCellActions;
