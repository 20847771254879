import React, { useMemo, useState } from 'react';
// import Pagination from '@shared/Pagination';
// import { LastItem } from '@entities/index';
// import Skeleton from 'react-loading-skeleton';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classnames from 'classnames';
import useDebounce from '@shared/hooks/useDebounce';
import { SortOrder } from '@services/sources/types';
import TableHeaderProjectResult from '@entities/TableHeaderProjectResults';
import { ICustomTableColumn, ICustomTableProps, ICustomTableRow } from './types';
import styles from './styles.module.scss';

const TableMediaPlan = (props: ICustomTableProps): React.ReactElement => {
    const {
        rows, columns, rowSelection,
        selectedRows, onSelect, onSort, onSearch,
        className,
    } = props;
    const [selectedIds, setSelectedIds] = useState<number[]>(selectedRows ?? []);
    const [sort, setSort] = useState<{ column: ICustomTableColumn, order: SortOrder } | null>(null);
    const [search, setSearch] = useState<{ column: ICustomTableColumn, value: string } | null>(null);

    const gridTemplateColumns = useMemo(() => {
        let style = '';
        if (rowSelection) style = '40px ';
        style += columns.map((column) => {
            if (column.width) return `${column.width}px`;
            return 'minmax(0px, 1fr)';
        }).join(' ');

        return style;
    }, [rowSelection, columns]);

    const renderCellValue = (
        column: ICustomTableColumn,
        row: ICustomTableRow,
        rowIndex: number,
    ): JSX.Element | string | number | ICustomTableRow[keyof ICustomTableRow] => {
        if (column.render) return column.render(row[column.dataIndex], row);
        if (column.isIndex) return rowIndex + 1;

        return row[column.dataIndex];
    };

    const onSelectRow = (row: ICustomTableRow, e: CheckboxChangeEvent): void => {
        const isSelected = e.target.checked;
        let changedSelectedIds = selectedIds;

        if (isSelected && selectedIds) changedSelectedIds = selectedIds.concat(row.id);
        if (!isSelected) changedSelectedIds = selectedIds.filter((id) => id !== row.id);

        setSelectedIds(changedSelectedIds);
        if (onSelect && !rowSelection) onSelect(row as any);
        else if (onSelect) onSelect(changedSelectedIds);
    };

    const allRowsSelected = useMemo(() => {
        if (!rowSelection) return false;
        if (!rows) return false;
        return selectedIds.length === rows.length;
    }, [selectedIds, rowSelection, rows]);

    const handleClickSelectAllRows = (e: CheckboxChangeEvent): void => {
        const isSelected = e.target.checked;
        let changedSelectedIds = [];
        if (isSelected) changedSelectedIds = rows.map((row) => row.id);
        if (!isSelected) changedSelectedIds = [];
        setSelectedIds(changedSelectedIds);
    };

    const handleClickSort = (column: ICustomTableColumn, order: SortOrder): void => {
        setSort({ column, order });
        if (onSort) onSort(column, order);
    };

    const handleChangeSearch = (column: ICustomTableColumn, value: string): void => {
        setSearch({ column, value });
    };

    useDebounce(() => {
        if (search) {
            onSearch(search.column, search.value);
        }
    }, 1000, [search]);

    // const isRowsVisible = useMemo(() => {
    //     if (!rows || rows?.length < 1) return false;
    //     if (rows && rows.length >= 1 && onScroll) return true;
    //     return !(isLoading && !onScroll);
    // }, [rows, onScroll, isLoading]);

    const clearSearch = (): void => {
        setSearch(null);
        onSearch(search.column, '');
    };

    const rowCellClass = (column: ICustomTableColumn) => {
        switch (column.dataIndex) {
            case 'viewsPlan':
                return classnames(styles.rowCell, styles.rowCellPlanViews);
            case 'erPlan':
                return classnames(styles.rowCell, styles.rowCellPlanER);
            case 'CPVPlan':
                return classnames(styles.rowCell, styles.rowCellPlanCPV);
            case 'viewsFact':
                return classnames(styles.rowCell, styles.rowCellFactViews);
            case 'erFact':
                return classnames(styles.rowCell, styles.rowCellFactER);
            case 'CPVFact':
                return classnames(styles.rowCell, styles.rowCellFactCPV);
            case 'viewsPercent':
                return classnames(styles.rowCell, styles.rowCellPercentViews);
            case 'erPercent':
                return classnames(styles.rowCell, styles.rowCellPercentER);
            case 'CPVPercent':
                return classnames(styles.rowCell, styles.rowCellPercentCPV);
            default:
                return styles.rowCell;
        }
    };

    const getColumn = (column: ICustomTableColumn, row, rowIndex) => {
        const tempClass = rowCellClass(column);
        return (
            <div key={column.id} className={tempClass} style={{ justifyContent: column.isIndex ? 'center' : ' flex-start' }}>
                {renderCellValue(column, row, rowIndex)}
            </div>
        );
    };

    return (
        <div className={classnames(styles.table, className)}>
            <div className={styles.header} style={{ gridTemplateColumns }}>
                {rowSelection && (
                    <div className={styles.headerCheckboxCell}>
                        <Checkbox
                            checked={allRowsSelected}
                            onChange={handleClickSelectAllRows}
                        />
                    </div>
                )}
                {columns.map((column) => (
                    <TableHeaderProjectResult
                        key={column.id}
                        column={column}
                        sort={sort}
                        search={search}
                        {...(column.sortable ? { onSort: handleClickSort } : {})}
                        {...(column.searchable ? { onSearch: handleChangeSearch } : {})}
                        clearSearch={clearSearch}
                    />
                ))}
            </div>
            <div className={styles.body}>
                {rows.map((row, rowIndex) => (
                    <div
                        key={row.id}
                        className={classnames(styles.row, rowSelection && selectedIds.includes(row.id) ? styles.rowSelected : undefined)}
                        style={{
                            gridTemplateColumns,
                            border: row.actions ? '1px solid green' : null,
                            borderRadius: row.actions ? '8px' : null,
                        }}
                        role="button"
                        onClick={(e: any) => onSelectRow(row, e)}
                    >
                        {rowSelection
                            && (
                                <div className={styles.rowCell}>
                                    <Checkbox
                                        checked={selectedIds.includes(row.id)}
                                        onChange={(e: CheckboxChangeEvent) => onSelectRow(row, e)}
                                    />
                                </div>
                            )}
                        {columns.map((column) => (
                            getColumn(column, row, rowIndex)
                        ))}
                    </div>
                ))}
                {/* {isLoading && <Skeleton width="100%" height={44} count={15} />} */}
                {/* {rows && rows.length > 1 && onScroll
                    && (
                        <LastItem
                            onLastItem={() => (scrollCallbackEnable && onScroll())}
                            enabled={false}
                        />
                    )} */}
            </div>

            {/* {pagination
                && (
                    <div className={styles.pagination}>
                        <Pagination
                            total={pagination.total}
                            currentPage={pagination.currentPage}
                            onChange={pagination.onChange}
                            limit={pagination.limit}
                        />
                    </div>
                )} */}
        </div>
    );
};

export default TableMediaPlan;
