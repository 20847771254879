import React from 'react';
import SvgIcon from '@components/SvgIcon';
import classnames from 'classnames';
import { Dropdown } from 'antd';
import { IPropsDropdown } from '../types';
import styles from '../styles.module.scss';

const DropdownSelectBody: React.FC<IPropsDropdown> = ({
    label,
    classesForSize,
    disabled,
    openFlag,
    setOpenFlag,
    customWidth,
    child,
}): JSX.Element => (
    <Dropdown
        overlayClassName={`${styles.containerDropdown}`}
        visible={openFlag}
        onVisibleChange={setOpenFlag}
        overlay={child}
        trigger={['click']}
        destroyPopupOnHide
    >
        <div
            className={classnames(
                styles.item,
                classesForSize,
                styles.main,
                openFlag ? styles.pressed : '',
                disabled ? styles.disabled : '',
            )}
            style={{ width: customWidth }}
        >
            <span>
                {label}
            </span>
            <div
                className={classnames(
                    styles.icn,
                    classesForSize,
                    openFlag ? styles.open : styles.close,
                    disabled ? styles.disabled : '',
                )}
            >
                <SvgIcon id="selectArrow" size={12} height={8} />
            </div>
        </div>
    </Dropdown>
);

export default DropdownSelectBody;
