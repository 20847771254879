import React from 'react';
import styles from './styles.module.scss';

const LinearLoader: React.FC = (): JSX.Element => (
    <div className={styles.wrapper}>
        <div />
        <div />
        <div />
    </div>
);
export default LinearLoader;
