import React, { useMemo, useState } from 'react';
import { Button } from '@shared/index';
import SvgIcon from '@components/SvgIcon';
import moment from 'moment';
import DatePicker from '@entities/DatePicker';
import styles from './styles.module.scss';

enum AddMediaPlanStage {
    firstScreen = 0,
    secondScreen = 1,
}

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onApply: (dateStart: Date, dateEnd: Date) => void;
}

// const dateFormatIso = 'DD.MM.YYYYTHH:mm:ssZ';
// const dateNow = new Date().toISOString().split('T')[0];

const AddMediaPlan: React.FC<IProps> = ({
    isOpen,
    onClose,
    onApply,
}): JSX.Element => {
    const [stage, setStage] = useState<AddMediaPlanStage>(
        AddMediaPlanStage.firstScreen,
    );
    const [startDate, setStartDate] = useState<moment.Moment>(null);
    const [endDate, setEndDate] = useState<moment.Moment>(null);

    const saveIsDisable = useMemo<boolean>(() => {
        if (!startDate || !endDate) {
            return true;
        }
        if (startDate > endDate) {
            return true;
        }
        return false;
    }, [startDate, endDate]);

    const handlerClose = (): void => {
        setStage(AddMediaPlanStage.firstScreen);
        setStartDate(null);
        setEndDate(null);
        onClose();
    };

    const handlerSelectStartDate = (value: moment.Moment): void => {
        setStartDate(value);
    };

    const handlerSelectEndDate = (value: moment.Moment): void => {
        setEndDate(value);
    };

    const handlerApply = (): void => {
        onApply(
            startDate.toDate(),
            endDate.toDate(),
        );
        handlerClose();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.addMediaPlan_root}>
            <div className={styles.addMediaPlan_window}>
                <div className={styles.addMediaPlan_container}>
                    <div className={styles.addMediaPlan_container_header}>
                        <span className={styles.addMediaPlan_title}>Важно</span>
                        <button type="button" onClick={handlerClose}>
                            <SvgIcon id="BigCross" size={14} />
                        </button>
                    </div>
                    <div className={styles.addMediaPlan_container_content}>
                        <span className={styles.addMediaPlan_textContent}>
                            При создании Медиаплана, внесение изменений в Лонглист будет невозможно.
                        </span>
                        {
                            stage === AddMediaPlanStage.secondScreen && (
                                <div className={styles.addMediaPlan_container_content}>
                                    <div className={styles.addMediaPlan_devider} />
                                    <span className={styles.addMediaPlan_simpleTextContent}>
                                        Для перехода в Медиаплан, выберите даты начала и завершения
                                    </span>
                                    <div style={{ display: 'flex', gap: 24, justifyContent: 'center' }}>
                                        <DatePicker
                                            placeholder="Дата начала"
                                            onChange={handlerSelectStartDate}
                                        />
                                        <DatePicker
                                            placeholder="Дата завершения"
                                            onChange={handlerSelectEndDate}
                                        />
                                    </div>
                                    {startDate && endDate && startDate > endDate
                                        && <span className={styles.addMediaPlan_simpleTextError}>Дата начала не может быть позже даты завершения</span>}
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.addMediaPlan_controls}>
                        {
                            stage === AddMediaPlanStage.firstScreen && (
                                <Button
                                    text="Понятно"
                                    type="default"
                                    size="large"
                                    onClick={() => setStage(AddMediaPlanStage.secondScreen)}
                                />
                            )
                        }
                        {
                            stage === AddMediaPlanStage.secondScreen && (
                                <>
                                    <Button
                                        text="Отменить"
                                        type="secondary"
                                        size="large"
                                        onClick={handlerClose}
                                    />
                                    <Button
                                        text="Сохранить"
                                        type="default"
                                        size="large"
                                        disabled={saveIsDisable}
                                        onClick={handlerApply}
                                    />
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMediaPlan;
