import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Path from '../../../../shared/lib/paths';
import { FetchStatus } from '../../../../store/types';
import campaignComparisonSelectors from '../../../../store/campaign/comparison/selectors';
import CampaignComparisonResult from '../../../../components/campaign/Comparison/Result';
import CampaignComparisonResultSkeleton from '../../../../components/campaign/Comparison/Result/Skeleton';
import { campaignComparisonRtkQService } from '../../../../services/campaigns/comparison';

const CampaignComparisonResultContainer: React.FC = (): JSX.Element => {
    // const canView = useSelector(campaignComparisonSelectors.comparisonCanView);
    const location = useLocation();
    const navigate = useNavigate();

    const filtersFetchStatus = useSelector(campaignComparisonSelectors.filtersFetchStatus);
    const summaryFetchStatus = useSelector(campaignComparisonSelectors.summaryFetchStatus);
    const comparisonFilters = useSelector(campaignComparisonSelectors.comparisonFilters);
    const referenceCampaign = useSelector(campaignComparisonSelectors.referenceCampaign);
    const compareCampaigns = useSelector(campaignComparisonSelectors.compareCampaigns);
    const comparisonSummary = useSelector(campaignComparisonSelectors.comparisonSummary);
    const comparisonPeriod = useSelector(campaignComparisonSelectors.comparisonPeriod);
    const isSavedAnalysis = useSelector(campaignComparisonSelectors.campaignSavedAnalysis);

    const [saveComparisonResult] = campaignComparisonRtkQService.useLazyPostSaveComparisonResultQuery();

    const handlerSaveComparisonResult = (name: string, startDate: string, endDate: string) => {
        saveComparisonResult({
            name,
            campaign_id: referenceCampaign.id,
            compared_campaigns: [...compareCampaigns.map((campaign) => campaign.id)],
            start_date: startDate,
            end_date: endDate,
        });
    };

    useEffect(() => {
        if (!referenceCampaign && !(compareCampaigns.length > 0) && filtersFetchStatus === FetchStatus.undefined) {
            navigate(`/${Path.Campaign}/${Path.CampaignList}`);
        }
    }, [referenceCampaign, compareCampaigns, filtersFetchStatus]);

    if (!location.pathname.includes(`/${Path.Campaign}/${Path.CampaignComparison}`)) {
        return null;
    }
    if (!referenceCampaign && !(compareCampaigns.length > 0) && filtersFetchStatus === FetchStatus.undefined) {
        return null;
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {
                (filtersFetchStatus === FetchStatus.loading || summaryFetchStatus === FetchStatus.loading)
                    ? <CampaignComparisonResultSkeleton />
                    : (
                        <CampaignComparisonResult
                            isSavedAnalysis={isSavedAnalysis}
                            comparisonPeriod={comparisonPeriod}
                            filters={comparisonFilters}
                            referenceCampaign={referenceCampaign}
                            compareCampaigns={compareCampaigns}
                            comparisonSummary={comparisonSummary}
                            onSaveResult={handlerSaveComparisonResult}
                        />
                    )
            }
        </>
    );
};

export default CampaignComparisonResultContainer;
