import classNames from 'classnames';
import React from 'react';
import { SortOrder } from '@services/sources/types';
import IconArrow from './IconArrow';
import styles from './styles.module.scss';

interface IProps {
    sort?: SortOrder | null;
    onClick: (sortOrder: SortOrder) => void;
}

const SortButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { sort, onClick } = props;
    let className = styles.wrapper;

    switch (sort) {
        case SortOrder.asc:
            className = classNames(className, styles.asc);
            break;
        case SortOrder.desc:
            className = classNames(className, styles.desc);
            break;
        default:
            className = styles.wrapper;
            break;
    }

    const handleClick = () => {
        let order = null;
        if (!sort) order = SortOrder.desc;
        if (sort === SortOrder.desc) order = SortOrder.asc;
        if (sort === SortOrder.asc) order = null;

        onClick(order);
    };

    return (
        <button type="button" className={className} onClick={handleClick}>
            <IconArrow />
            <IconArrow />
        </button>
    );
};
export default SortButton;
