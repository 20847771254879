import React from 'react';
import { Button } from '@shared/index';
import styles from './styles.module.scss';

interface ICommonSimpleMessageModalProps {
    isOpen: boolean;
    message: JSX.Element;
    confirmButtonText: string;
    onClickConfirm: () => void;
    tagId: string;
}

const CommonSimpleMessageModal: React.FC<ICommonSimpleMessageModalProps> = ({
    isOpen, onClickConfirm, confirmButtonText, message, tagId,
}): JSX.Element => {
    if (!isOpen || !message) {
        return null;
    }
    return (
        <div className={styles.commonSimpleMessageModal_root}>
            <div className={styles.modalWindow}>
                <div className={styles.contentContainer}>
                    <div className={styles.textBlock}>
                        {
                            message ?? null
                        }
                    </div>

                    <div className={styles.controlBlock}>
                        <Button
                            tagId={tagId}
                            text={confirmButtonText?.length > 10 ? `${confirmButtonText.substring(0, 10)}...` : confirmButtonText}
                            type="default"
                            size="large"
                            onClick={onClickConfirm}
                        />
                        {/* <ButtonPrimary */}
                        {/*    tagId={tagId} */}
                        {/*    text={confirmButtonText?.length > 10 ? `${confirmButtonText.substring(0, 10)}...` : confirmButtonText} */}
                        {/*    onClick={onClickConfirm} */}
                        {/*    style={{ width: 60, height: 40 }} */}
                        {/* /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommonSimpleMessageModal;
