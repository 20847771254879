import React, { CSSProperties, useMemo } from 'react';
import { PhraseWordLogicOperatorType } from '../../types';
import CrossIcon from '../../imgs/cross.svg';
import helpers from '../../helpers';

import styles from './styles.module.scss';

interface IProps {
    isView;
    groupIndex: number;
    operator: PhraseWordLogicOperatorType | null;
    onlyOrderOperator: boolean;
    withoutOrderOperator: boolean;
    setOperator: (groupIndex: number, index: number) => void;
    deleteOperator: (groupIndex: number) => void;
    style?: CSSProperties;
}

const LogicOperatorTypeMap = Object.keys(PhraseWordLogicOperatorType)
    .slice(Object.keys(PhraseWordLogicOperatorType).length / 2, Object.keys(PhraseWordLogicOperatorType).length)
    .map((value, index) => ({ index, name: value, label: value }));

const SearchGroupLogicOperator: React.FC<IProps> = ({
    isView,
    groupIndex,
    operator,
    onlyOrderOperator,
    withoutOrderOperator,
    setOperator,
    deleteOperator,
    style,
}): JSX.Element => {
    const list = useMemo<{ index: number, name: string, label: string }[]>(() => {
        if (onlyOrderOperator) {
            return LogicOperatorTypeMap.filter(({ index }) => index === PhraseWordLogicOperatorType.order);
        }
        if (withoutOrderOperator) {
            return LogicOperatorTypeMap.filter(({ index }) => index !== PhraseWordLogicOperatorType.order);
        }
        return [...LogicOperatorTypeMap];
    }, [onlyOrderOperator, withoutOrderOperator]);

    const handlerSelectOperator = (operatorIndex: number): void => {
        if (setOperator) {
            setOperator(groupIndex, operatorIndex);
        }
    };
    const handlerDeleteOperator = (): void => {
        if (deleteOperator) {
            deleteOperator(groupIndex);
        }
    };

    return (
        <div className={styles.searchGroupLogicOperator_container} style={style}>
            {
                !(operator in PhraseWordLogicOperatorType)
                    ? !isView && (
                        <>
                            <div className={styles.searchGroupLogicOperator_text}>
                                Выберите логический оператор для групп
                            </div>
                            {
                                list.map((item) => (
                                    <button
                                        type="button"
                                        key={item.index}
                                        className={`${styles.boxDashed} ${styles.boxDashed_text}`}
                                        onClick={() => handlerSelectOperator(item.index)}
                                    >
                                        {
                                            helpers.getNameForLogicOperator(item.index)
                                        }
                                    </button>
                                ))
                            }
                        </>
                    )
                    : (
                        <>
                            <div className={`${styles.boxDashedGreen} ${styles.boxDashedGreen_text}`}>
                                {
                                    helpers.getNameForLogicOperator(operator)
                                }
                            </div>
                            {
                                !isView && (
                                    <button
                                        type="button"
                                        onClick={() => handlerDeleteOperator()}
                                    >
                                        <img src={CrossIcon} alt="CrossIcon" />
                                    </button>
                                )
                            }
                        </>
                    )
            }
        </div>
    );
};

export default SearchGroupLogicOperator;
