/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect, useMemo, useState,
} from 'react';
import {
    Chart as ChartJS,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { message } from 'antd';
import { t } from 'i18next';
import { pieLabels } from '../LabelPlugin';
import { exceptionsChartService } from '../../../services/campaigns/Charts/exceptions';
import QuickSVGLoader from '../../QuickSVGLoader';

ChartJS.register(
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NewLineChart: React.FC<any> = ({
    dataFormat,
    chartRef,
    data: newData,
    onLoadNextData,
    onLoadPrevData,
}) => {
    const [updaetPoints] = exceptionsChartService.useLazyUpdateChartDataLQuery();
    const [isLoadingChank, setIsLoadingChank] = useState(false);
    const newOptions = { ...dataFormat?.options };
    const onPanComplete = (e) => {
        const allPoints = dataFormat?.data?.datasets?.reduce((acc, item) => [...acc, ...item.data], []);
        // const maxPoint = allPoints.reduce((acc, item) => {
        //     if (item.x > acc.x) return item;
        //     return acc;
        // }, { x: 0 });
        const lastPoint = allPoints.reduce((acc, item) => {
            if (item.x > acc.x) return item;
            return acc;
        }, { x: 0 });
        const firstPoint = dataFormat?.data?.datasets[0].data[0];
        const maxDateOnChart = Math.floor(e.chart.scales.x.max);
        const minDateOnChart = Math.floor(e.chart.scales.x.min);
        if (lastPoint?.x < maxDateOnChart) {
            setIsLoadingChank(true);
            const params = onLoadNextData(maxDateOnChart);
            updaetPoints(params.data, false).then((res) => {
                const listNewData = res.data.data.map((item) => {
                    const newListPoints = item.x.map((date, index) => ({
                        x: new Date(date).getTime(),
                        x2: new Date(date).getTime(),
                        y: item.y[index],
                        content: {
                            descriptions: t(item.subjectNames),
                            values: t(item.subjectValues),
                        },
                    }));
                    return { ...item, newListPoints };
                });
                newData.datasets = newData.datasets.map((item) => {
                    const newDataset = listNewData.find((elem) => (elem.subjectValues.join('_') === item.label));
                    // eslint-disable-next-line no-param-reassign
                    const toSpread = newDataset ? newDataset?.newListPoints?.reverse() : [];
                    item.data = [...item.data, ...toSpread].sort((a, b) => a.x - b.x);
                    item.tension = 0.37;
                    return item;
                });
                // eslint-disable-next-line no-underscore-dangle
                chartRef.current.config._config.data.datasets = newData.datasets;
                chartRef.current.zoom(1.001);
                chartRef.current.update();
                setIsLoadingChank(false);
            })
                .catch(() => {
                    setIsLoadingChank(false);
                    message.error('Произошла ошибка');
                });
        } else if (firstPoint?.x > minDateOnChart) {
            setIsLoadingChank(true);
            const params = onLoadPrevData(minDateOnChart); updaetPoints(params.data, false).then((res) => {
                const listNewData = res.data.data.map((item) => {
                    const newListPoints = item.x.map((date, index) => ({
                        x: new Date(date).getTime(),
                        y: item.y[index],
                        content: {
                            descriptions: item.subjectNames,
                            values: item.subjectValues,
                        },
                    }));
                    return { ...item, newListPoints };
                });
                newData.datasets = newData.datasets.map((item) => {
                    const newDataset = listNewData.find((elem) => (elem.subjectValues.join('_') === item.label));
                    const toSpread = newDataset ? newDataset?.newListPoints : [];
                    item.data = [...item.data, ...toSpread].sort((a, b) => a.x - b.x);
                    item.tension = 0.37;
                    return item;
                });
                // eslint-disable-next-line no-underscore-dangle
                chartRef.current.config._config.data.datasets = newData.datasets;
                chartRef.current.zoom(1.001);
                chartRef.current.update();
                setIsLoadingChank(false);
            })
                .catch(() => {
                    setIsLoadingChank(false);
                    message.error('Произошла ошибка');
                });
        }
    };
    useEffect(() => {
        if (newOptions?.plugins?.zoom?.pan) {
            newOptions.plugins.zoom.pan.onPanComplete = onPanComplete;
        }
    }, [dataFormat]);

    const getDatasets = useMemo(() => {
        const updatedData = { ...newData };
        updatedData.datasets = updatedData.datasets.map((item) => ({
            ...item,
            xAxisID: 'x2',
        }));
        const shadowAxisDataset = updatedData?.datasets[0];
        if (shadowAxisDataset && updatedData?.datasets) {
            const cloned = JSON.parse(JSON.stringify(shadowAxisDataset));
            cloned.xAxisID = 'x';
            cloned.borderColor = 'rgba(0,0,0,0)';
            cloned.pointBackgroundColor = 'rgba(0,0,0,0)';
            cloned.pointBorderColor = 'rgba(0,0,0,0)';
            cloned.pointBorderWidth = 0;
            cloned.pointRadius = 0;
            updatedData?.datasets.unshift(cloned);
        }
        return updatedData;
    }, [dataFormat, newData]);
    const memoTest = useMemo(
        () => (
            <Line
                plugins={[zoomPlugin, pieLabels]}
                ref={chartRef}
                options={newOptions}
                data={getDatasets as any}
            />
        ),
        [dataFormat, getDatasets],
    );
    const loader = (
        <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.03)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 6,
        }}
        >
            <QuickSVGLoader />
        </div>
    );
    return (
        // <Line plugins={[zoomPlugin, pieLabels]} ref={chartRef} options={newOptions} data={newData as any} />
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {isLoadingChank && loader}
            {memoTest}
        </div>
    );
};

export default React.memo(NewLineChart, (next, prev) => false);
