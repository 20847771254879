import { sha512 } from 'js-sha512';
import { rtkQApi } from '..';
import { IQPUserAuthorization, IUserAuthorizationResponse } from './types';

export const userAuthorizationRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postAuth: build.query<IUserAuthorizationResponse, IQPUserAuthorization>({
            query: (params) => ({
                url: '/auth',
                method: 'POST',
                body: {
                    email: params.email,
                    password: sha512(params.password),
                },
            }),
        }),
    }),
});
