import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { getReportFile } from '../../../deprecated/api/reports/reportsApi';
import { pdfHandlerReportActions } from '../report';
import { AppThunk } from '../../store';

const loadPdfReportFileThunk = (campaignId: number, reportId: number): AppThunk => (dispatch: Dispatch): void => {
    if (!campaignId || Number.isNaN(campaignId) || !reportId || Number.isNaN(reportId)) {
        return;
    }

    dispatch(pdfHandlerReportActions.startLoadFile());

    getReportFile(campaignId, reportId)
        .then((response: any) => {
            dispatch(pdfHandlerReportActions.successLoadFile());
            dispatch(pdfHandlerReportActions.setDownloadUrlForReport({ reportId: reportId.toString(), response }));

            // if (response?.file_url) {
            //     downloadReportFile(response?.file_url)
            //     // fetch(response?.file_url, {
            //     //     method: 'GET',
            //     //     headers: {
            //     //         'Content-Type': 'application/pdf',
            //     //     },
            //     // })
            //     //     .then((responseData) => {
            //     //         // eslint-disable-next-line no-debugger
            //     //         debugger;
            //     //         return responseData.blob();
            //     //     })
            //         .then((blob: any) => {
            //             // eslint-disable-next-line no-debugger
            //             debugger;
            //             // Create blob link to download
            //             const url = window.URL.createObjectURL(
            //                 new Blob([blob]),
            //             );
            //             const link = document.createElement('a');
            //             link.href = url;
            //             link.setAttribute(
            //                 'download',
            //                 'FileName.pdf',
            //             );
            //
            //             // Append to html link element page
            //             document.body.appendChild(link);
            //
            //             // Start download
            //             link.click();
            //
            //             // Clean up and remove the link
            //             link.parentNode.removeChild(link);
            //         });
            // }
        })
        .catch((error: AxiosError) => {
            dispatch(pdfHandlerReportActions.failedLoadFile());
            dispatch(pdfHandlerReportActions.setFetchErrors(error?.message));
        });
};

export default loadPdfReportFileThunk;
