/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { IChart } from '../../containers/CampaignNewCharts/ChartTypes';
import ChartItem from '../ChartItem';
import InitailChart from '../InitailChart';
import styles from './styles.module.scss';

interface IPropsChartList {
    charts: IChart[];
    onCreateChart: () => void;
    onRemoveChart: (id: number, isSaved) => void;
    onSaveChart: (id: number, newChart: IChart) => void;
    onSelectChart: (id: number) => void;
    setPrevSettings: (chart: IChart) => void;
    onUpdateChart: (field, value) => void,

}
const ChartList: React.FC<IPropsChartList> = ({
    charts, onCreateChart, onRemoveChart, onSaveChart, onSelectChart, setPrevSettings, onUpdateChart,
}): JSX.Element => {
    if (!charts) return null;
    const chartList = useMemo(() => charts.map((chart) => (
        <ChartItem
            key={chart?.id || chart?.intermediumId}
            chart={chart}
            removeChart={onRemoveChart}
            saveChart={onSaveChart}
            selectChart={onSelectChart}
            setPrevSettings={setPrevSettings}
            onUpdateChart={onUpdateChart}
        />
    )), [charts]);
    if (!charts) return null;
    return (
        <div className={styles.rootChartList}>
            <InitailChart onInitialChart={onCreateChart} />
            {chartList}
        </div>
    );
};

export default ChartList;
