import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';

export interface ICampaignTableProps {
    data: ICampaignDataModel[],
    header: ICampaignTableHeaderColumn[];
    isLoading: boolean;
    isCampaignUpdate: boolean;
    isCampaignDeleting: boolean;
    isCampaignCompare: boolean;
    onDelete: (id: number) => void;
    onActivate: (id: number) => void;
    onNavigateToCampaign: (id: number) => void;
    onCheckedRow: (item: ICampaignDataModel) => (eventValChange: CheckboxChangeEvent) => void
    activeRowIds: number[];
    referenceCampaignId: number | null;
    onClickRow: (item: ICampaignDataModel) => void;
}

export const enum CampaignColumnFilterType {
    'default' = 0,
    'name' = 1,
    'date' = 2,
    'author' = 3,
    'status' = 4,
}

export interface ICampaignTableHeaderColumn {
    id: number;
    name: string;
    cellWidth: string;
    minWidth: number;
    maxWidth: number;
    columnType: CampaignColumnFilterType;
}
