import ReactMarkdown from 'react-markdown';
import PostImagesCarousel from '../../../../entities/PostImagesCarousel';
import { ISourcePost } from '../../../../services/sources/types';
import SvgIcon from '../../../../components/SvgIcon';
import styles from './styles.module.scss';
import useGetDate from '../../../../shared/hooks/useFormatDate';
import PostIndocators from '../../../../shared/PostIndicators';

interface IProps {
    post: ISourcePost;
}

const SourcePost: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { post } = props;
    const [posted] = useGetDate(new Date(post?.posted.replace('+0000', '')), 'HH:mm DD.MM.YYYY');
    const [updated] = useGetDate(
        new Date(post?.lastUpdate),
        'HH:mm DD.MM.YYYY',
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.headerCalendar}>
                    <SvgIcon id="Calendar" size={16} />
                    <span>{posted?.date}</span>
                </div>
                <a
                    href={post.postLink}
                    className={styles.headerLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    <SvgIcon id="LinkIcon" size={12} />
                    <span>Пост</span>
                </a>
            </div>

            {post.images && post.images.length >= 1 && (
                <PostImagesCarousel id={post.postId} images={post.images} />
            )}

            <div className={styles.content}>
                <ReactMarkdown className={styles.contentText}>
                    {post.content}
                </ReactMarkdown>
            </div>

            <div className={styles.footer}>
                <PostIndocators
                    likes={post.likes}
                    reposts={post.forwards}
                    comments={post.comments}
                    views={post.views}
                />

                {post?.lastUpdate
                    && (
                        <div className={styles.footerUpdatedDate}>
                            Обновлено
                            {' '}
                            {updated?.date}
                        </div>
                    )}
            </div>
        </div>
    );
};
export default SourcePost;
