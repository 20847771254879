import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { Filter, IQPSourcesWithSortingAndFiltering } from '@services/sources/types';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { Checkbox, Menu } from 'antd';
import getKey from '@app/helpers/getKey';
import { ISelectItem, TSizes } from '@shared/ui-components/Select/types';
import FiltersSearchField from '@components/sources/List/Filtres/SearchField';
import classnames from 'classnames';
import { ICategory } from '@store/types';
import styles from './styles.module.scss';

type CategoryType = { id: number, name: string, checked: boolean };

interface IProps {
    isOpen?: boolean;
    isLoading: boolean;
    categoriesData: ICategory[];
    size: TSizes;
    currentSortingAndFilters: IQPSourcesWithSortingAndFiltering;
    onSelect: (ids: number[], name: string) => void;
    onClose: () => void;
}

const CategoryFilter: React.FC<IProps> = ({
    size = 'medium',
    isLoading,
    categoriesData,
    currentSortingAndFilters,
    onSelect,
    onClose,
    isOpen = true,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [allCategory, setAllCategory] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');

    if (!isOpen) {
        return null;
    }

    const checkedIds = useMemo<number[]>(() => (
        (currentSortingAndFilters.filterParams as Filter)?.category_id?.length > 0
            ? (currentSortingAndFilters.filterParams as Filter).category_id.map((item) => Number(item)) : []
    ), [currentSortingAndFilters]);

    const filterData = useMemo<ISelectItem[]>(() => {
        const result = searchValue === ''
            ? (categories ?? [])
            : (categories ?? []).filter((item) => (
                item.name.toLowerCase().indexOf(searchValue.toLowerCase()) === 0
            ));
        return result.map((el) => ({
            id: el.id,
            value: el.name,
            label: el.name,
            select: checkedIds.includes(el.id),
        }));
    }, [searchValue, categories, checkedIds]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectAllCategories = (e: React.MouseEvent) => {
        setAllCategory(!allCategory);
        const result = categories.map((item) => ({ id: item.id, name: item.name, checked: !allCategory }));
        setCategories(result);
        onSelect(!allCategory ? result.map(({ id }) => id) : [], 'category_id');
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlerCheckedCategory = (item: ISelectItem, e: React.MouseEvent) => {
        const result = categories.map((el) => ({
            ...el, checked: el.checked ? el.id !== item.id : el.id === item.id,
        }));
        const select = result.filter(({ checked }) => checked).map(({ id: lvId }) => lvId);
        onSelect(select, 'category_id');
        setCategories(result);
    };

    useEffect(() => {
        if (isLoading) {
            return;
        }
        setAllCategory(categoriesData?.length === checkedIds?.length);
        setCategories(categoriesData.map((item) => (
            { id: Number(item.id), name: item.mainCat, checked: checkedIds.includes(Number(item.id)) } as CategoryType
        )));
    }, [categoriesData, checkedIds, isLoading]);

    return (
        <div className={styles.categoryFilter} ref={refModal}>
            <div className={styles.categoryFilter_list}>
                {
                    isLoading
                        ? <Skeleton width="100%" height={25} count={10} />
                        : (
                            <>
                                <FiltersSearchField
                                    placeholder="Поиск"
                                    searchValue={searchValue}
                                    onSearch={setSearchValue}
                                />
                                <div className={styles.categoryFilter_headCheckBox}>
                                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                    <div
                                        className={`${styles.item} ${styles[size]} ${styles.content} ${allCategory ? styles.selected : ''}`}
                                        key={getKey(-1)}
                                        onClick={(e) => selectAllCategories(e)}
                                    >
                                        <Checkbox
                                            checked={allCategory}
                                            style={{ marginRight: 8 }}
                                        />
                                        <span>Все</span>
                                    </div>
                                </div>
                                {
                                    filterData?.length <= 0 && (
                                        <span className={styles.emptyMessage}>
                                            Ничего не найдено
                                        </span>
                                    )
                                }
                                {
                                    filterData?.length > 0 && (
                                        <Menu style={{
                                            display: 'flex', flexDirection: 'column', height: '100%', maxHeight: 208,
                                        }}
                                        >
                                            <div style={{
                                                display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden',
                                            }}
                                            >
                                                <div className={styles.containerScroll}>
                                                    <div className={`${styles.menu}`}>
                                                        {
                                                            filterData.map((item) => (
                                                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                                <div
                                                                    className={
                                                                        classnames(
                                                                            styles.item,
                                                                            styles[size],
                                                                            styles.content,
                                                                            item.select ? styles.selected : '',
                                                                        )
                                                                    }
                                                                    key={item.id}
                                                                    onClick={(e) => handlerCheckedCategory(item, e)}
                                                                >
                                                                    <Checkbox
                                                                        checked={item.select}
                                                                        style={{ marginRight: 8 }}
                                                                    />
                                                                    <span>{item.label}</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Menu>
                                    )
                                }
                            </>
                        )
                }
            </div>
        </div>
    );
};

export default CategoryFilter;
