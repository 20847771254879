/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox, Menu } from 'antd';
import { t } from 'i18next';
import React, { useState } from 'react';
import getKey from '@app/helpers/getKey';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { IPropsCheck, ICheckItem } from './types';

const CheckBoxList: React.FC<IPropsCheck> = ({
    items,
    header,
    footer,
    onCheck,
    haveCheckAll = false,
    customHeight = '100%',
}): JSX.Element => {
    const [checkAll, setCheckAll] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChangeCheckAll = (flag: boolean, e: React.MouseEvent) => {
        setCheckAll(flag);
        items.forEach((item) => {
            if (onCheck) onCheck(item.id, flag);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleClickItem = (item: ICheckItem, e: React.MouseEvent) => {
        if (onCheck) onCheck(item.id, !item.isChecked);
        if (checkAll === true) setCheckAll(false);
    };

    const listItems = (items.map((item) => (
        <div
            className={classnames(
                styles.item,
                styles.content,
                item.isChecked ? styles.checked : '',
                item.classes || '',
            )}
            key={getKey(item.id)}
            onClick={(e) => handleClickItem(item, e)}
        >
            <Checkbox
                checked={item.isChecked}
                style={{ marginRight: 8 }}
            />
            <span>{t(item.label)}</span>
        </div>
    )));

    return (
        <Menu style={{ height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {header && header}
                {haveCheckAll && (
                    <div className={styles.containerCheckAll}>
                        <div
                            className={classnames(
                                styles.item,
                                styles.content,
                                styles.checkAll,
                                checkAll ? styles.checked : '',
                            )}
                            key={getKey(0)}
                            onClick={(e) => handleChangeCheckAll(!checkAll, e)}
                        >
                            <Checkbox
                                checked={checkAll}
                                style={{ marginRight: 8 }}
                            />
                            <span>{t('Все')}</span>
                        </div>
                    </div>

                )}
                <div className={classnames(styles.containerScroll, styles.menu)} style={{ height: customHeight }}>
                    <div
                        className={classnames(
                            styles.menu,
                            haveCheckAll ? styles.haveCheckAll : '',
                        )}
                    >
                        {listItems}
                    </div>
                </div>
                <div>{footer && footer}</div>
            </div>
        </Menu>
    );
};

export default React.memo(CheckBoxList);
