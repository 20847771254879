import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
// import CellEditModalWrapper from '@modals/table/CellEditModalWrapper';
import SvgIcon from '@components/SvgIcon';
// import Popup from '@entities/Popup';
import TextField from '@entities/TextField';
import { Popover } from 'antd';
import styles from './styles.module.scss';

interface IProps {
    id: number;
    sourceId: number;
    value: string;
    unit?: string;
    classesValue?: string;
    currentOpenId: string;
    cellId: string;
    onOpen: (id: string) => void;
    onChange: (id: number, sourceId: number, value: string) => void;
    isEditable: boolean;
}

const getValueWithUnit = (value: string, unit: string): JSX.Element => {
    if (!value) {
        return <div>-</div>;
    }
    return (
        <div>
            {`${Number(value).toLocaleString('ru-RU')} ${unit}`}
        </div>
    );
};

const EditNumber: React.FC<IProps> = ({
    id,
    sourceId,
    currentOpenId,
    cellId,
    value,
    unit,
    classesValue,
    onChange,
    onOpen,
    isEditable,
}): JSX.Element => {
    const [isOpen, setActiveModal] = useState<boolean>(false);
    const [localValue, setLocalValue] = useState<string>(value);

    const handlerChangeValue = (inputVal: string): void => {
        if (!Number.isNaN(Number(inputVal))) {
            setLocalValue(inputVal);
        }
    };

    const handlerCancel = (): void => {
        setActiveModal(false);
        setLocalValue('');
    };

    const handlerOpenModal = useCallback((): void => {
        onOpen(cellId);
        setLocalValue(value);
        setActiveModal(true);
    }, [value, cellId]);

    const handlerChange = (): void => {
        onChange(id, sourceId, localValue);
        setActiveModal(false);
    };

    const content = useMemo<JSX.Element>(() => (
        <div style={{ padding: 6 }}>
            <TextField
                value={localValue}
                onChange={handlerChangeValue}
                placeholder="Значение"
                size="medium"
            />
            <div className={styles.controller}>
                <div className={styles.editNumber_approval}>
                    <div
                        role="button"
                        onClick={handlerCancel}
                        className={styles.editNumber_close}
                    >
                        <SvgIcon id="RedCross" size={14} />
                    </div>
                    |
                    <div
                        role="button"
                        onClick={handlerChange}
                        className={styles.editNumber_complete}
                    >
                        <SvgIcon id="Complete" size={18} />
                    </div>
                </div>
            </div>
        </div>
    ), [value, localValue]);

    useEffect(() => {
        if (isOpen && cellId !== currentOpenId) {
            setActiveModal(false);
            setLocalValue('');
        }
    }, [currentOpenId, cellId, isOpen]);

    return (
        <div style={{ display: 'flex', gap: 16 }}>
            {/* <Popup */}
            {/*   content={content} */}
            {/*   placement="topLeft" */}
            {/*   xOffset={-0.95} */}
            {/* > */}
            {/*   <div */}
            {/*       role="button" */}
            {/*       // onClick={() => setActiveModal(true)} */}
            {/*       style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} */}
            {/*   > */}
            {/*       <SvgIcon id="GreyPencil" size={24} /> */}
            {/*   </div> */}
            {/* </Popup> */}

            <Popover placement="rightTop" content={content} visible={isOpen}>
                <div className={classesValue}>
                    {
                        isEditable && (
                            <div
                                role="button"
                                onClick={handlerOpenModal}
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                                <SvgIcon id="GreyPencil" size={24} />
                            </div>
                        )
                    }
                    <div style={isEditable ? {} : { marginLeft: 12 }}>
                        {getValueWithUnit(value, unit ?? '')}
                    </div>
                </div>
            </Popover>

            {/* <CellEditModalWrapper */}
            {/*    isOpen={isOpen} */}
            {/*    onClose={handlerCancel} */}
            {/* > */}
            {/*    {content} */}
            {/* </CellEditModalWrapper> */}
        </div>
    );
};

export default EditNumber;
