import React from 'react';
import classnames from 'classnames';
import SvgIcon from '../../SvgIcon';
import styles from './styles.module.scss';

interface IUGCMSCheckboxProps {
    onChecked: (value: boolean) => void;
    tagId: string;
    isChecked: boolean;
    isError: boolean;
    isDisabled: boolean;
}

const UGCMSCheckbox: React.FC<IUGCMSCheckboxProps> = ({
    tagId, isDisabled,
    isChecked, isError, onChecked,
}): JSX.Element => {
    const handlerClick = (): void => {
        if (!isDisabled && onChecked) {
            onChecked(!isChecked);
        }
    };
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
            id={tagId}
            className={classnames(
                styles.container,
                styles[isError ? 'error' : ''],
                styles[isDisabled ? 'disabled' : ''],
                styles[isChecked ? 'checked' : ''],
            )}
            onClick={handlerClick}
        >
            {isChecked && <SvgIcon id="CheckMark" size={13} height={10} />}
        </div>
    );
};

export default UGCMSCheckbox;
