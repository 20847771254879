import React, { CSSProperties } from 'react';
import styles from './styles.module.scss';

interface IPropsDefaultWrapperProject {
    children: JSX.Element
    style?: CSSProperties
}

// eslint-disable-next-line arrow-body-style
const DefaultWrapperProject: React.FC<IPropsDefaultWrapperProject> = ({ children, style }): JSX.Element => {
    return (
        <div className={styles.root_DefaultWrapperProject} style={style}>
            {children}
        </div>
    );
};

export default DefaultWrapperProject;
