import {
    Chart as ChartJS,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Bubble, Line } from 'react-chartjs-2';
import React, {
    useMemo, useRef,
} from 'react';
import 'chartjs-adapter-moment';

ChartJS.register(
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);
const TonalityChart: React.FC<any> = React.memo(({
    positiveData,
    neutralData,
    negativeData,
    positiveDataOld,
    neutralDataOld,
    negativeDataOld,
}: any) => {
    const withOld = useMemo(
        () => !!positiveDataOld?.length || !!neutralDataOld?.length || !!negativeDataOld?.length,
        [positiveDataOld, neutralDataOld, negativeDataOld],
    );
    const chartRef = useRef(null);
    const dataSet = {
        events: ['click'],
        datasets: [
            {
                type: 'line',
                data: positiveData,
                borderWidth: 1,
                borderColor: '#0EBFA1',
                backgroundColor: '#0EBFA1',
                xAxisID: 'x1',
            },
            {
                type: 'line',
                data: neutralData,
                borderWidth: 1,
                borderColor: '#E67E22',
                backgroundColor: '#E67E22',
                xAxisID: 'x1',
            },
            {
                type: 'line',
                data: negativeData,
                borderWidth: 1,
                borderColor: '#F36869',
                backgroundColor: '#F36869',
                xAxisID: 'x1',
            },
            {
                type: 'line',
                data: positiveDataOld,
                borderDash: [10, 5],
                borderWidth: 1,
                borderColor: '#0EBFA1',
                backgroundColor: '#0EBFA1',
                xAxisID: 'x2',
            },
            {
                type: 'line',
                data: neutralDataOld,
                borderDash: [10, 5],
                borderWidth: 1,
                borderColor: '#E67E22',
                backgroundColor: '#E67E22',
                xAxisID: 'x2',
            },
            {
                type: 'line',
                data: negativeDataOld,
                borderDash: [10, 5],
                borderWidth: 1,
                borderColor: '#F36869',
                backgroundColor: '#F36869',
                xAxisID: 'x2',
            },
        ],

        plugin: [
        ],
    };
    const tooltipHtml = {
        enabled: false,
    };
    const options: any = {
        spanGaps: 1000 * 60 * 60 * 24 * 1,
        responsive: true,
        maintainAspectRatio: false,
        beginAtZero: true,
        stacked: false,
        elements: {
            line: {
                tension: 0.37,
            },
            point: {
                borderColor: 'white',
            },
        },
        scales: {
            x1: {
                position: 'bottom',
                ticks: {
                    padding: 10,
                    color: withOld ? '#0EBFA1' : '#4f4f4f',
                },
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'DD.MM',
                    },
                },
            },
            x2: {
                display: withOld,
                position: 'bottom',
                ticks: {
                    padding: 10,
                    color: '#E67E22',
                },
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'DD.MM',
                    },
                },
            },
            y: {
                ticks: {
                    padding: 10,
                    maxTicksLimit: 7,
                    callback(value) {
                        if (Math.floor(value) === value) { return `${value}`; }
                        return '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: tooltipHtml,
        },
    };
    const getOptions = useMemo(() => options, [options]);
    return (
        <div style={{
            height: '200px', width: '100%', position: 'relative', background: '#fff',
        }}
        >
            <Bubble ref={chartRef} options={getOptions} data={dataSet as any} />
        </div>
    );
});

export default React.memo(TonalityChart);
