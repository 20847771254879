import { SortOrder } from '@services/sources/types';

export interface IQueryParamsLoadTags {
    campaign_id: number;
    page: number;
    from_date?: string;
    to_date?: string;
    order?: SortOrder;
    column?: IQuerySortFieldsLoadTags;
}

export enum IQuerySortFieldsLoadTags {
    comments = 'comments',
    views = 'views',
    reactions = 'reactions',
    percent = 'percent',
    postsCount = 'posts_count',
}

export interface ITagFromApi {
    posts_count: number;
    likes: number | null;
    views: number | null;
    comments: number | null;
    percent: number;
    reactions: number | null;
    title: string;
    manual?: boolean | null;
    id?: number;
}

export interface ITagModel {
    postsCount: number;
    likes: number;
    views: number;
    comments: number;
    percent: number;
    reactions: number;
    title: string;
    manual?: boolean;
    id: number;
}

export interface IQueryParamsDeleteUserTag {
    id: number;
    campaign_id: number;
}
