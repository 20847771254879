/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import CampaignReportPDFFrontPage from '../../../../components/campaign/Report/PDFPages/FrontPage';
import CampaignPDFSearchParamsPage from '../../../../components/campaign/Report/PDFPages/SearchParams';
import CampaignPDFSummaryDataPage from '../../../../components/campaign/Report/PDFPages/SummaryDataPage';
import TemplateAdditionalData from '../../../../components/campaign/Report/PDFPages/TemplateAdditionalData';
import TemplateBarChart from '../../../../components/campaign/Report/PDFPages/TemplateBarChart';
import TemplateChartPage from '../../../../components/campaign/Report/PDFPages/TemplateChartPage';
import TemplateChartRegionData from '../../../../components/campaign/Report/PDFPages/TemplateChartRegionData';
import PDFTopPostsPage, { IPDFTopPostsModel } from '../../../../components/campaign/Report/PDFPages/TopPostsPage';
import campaignSelectors from '../../../../store/campaign/source/selectors';
// import campaignSummarySelectors from '../../../../store/campaign/summary/selectors';
import { useAppDispatch } from '../../../../store/hooks';
import categorySelector from '../../../../store/info-basket/category/selectors';
import { pdfHandlerReportActions } from '../../../../store/pdf-handler/report';
import pdfHandlerWrapperSelectors from '../../../../store/pdf-handler/wrapper/selectors';
import { IPDFReportTopPlatform } from '../../../../store/pdf-handler/wrapper/types';
import { AppState } from '../../../../store/store';
import { IDictionary, ISimpleEntity } from '../../../../store/types';
import campaignUtils from '../../../../utils/campaign';

interface IPDFWrapperDocumentContainerProps {
    reportId: string;
}

const PDFWrapperDocumentContainer: React.FC<IPDFWrapperDocumentContainerProps> = ({ reportId }): JSX.Element => {
    const dispatch = useAppDispatch();
    const reportName = useSelector(pdfHandlerWrapperSelectors.reportName);
    const reportTimeFrame = useSelector(pdfHandlerWrapperSelectors.timeFrame);
    const chartList = useSelector(pdfHandlerWrapperSelectors.chartList);
    const regionData = useSelector(pdfHandlerWrapperSelectors.regionData);
    const categoryData = useSelector(pdfHandlerWrapperSelectors.getCategoryData);
    const sourcesTypes = useSelector(pdfHandlerWrapperSelectors.getSourcesType);
    const authorsData = useSelector(pdfHandlerWrapperSelectors.getAuthorsData);
    const additionalData = useSelector(pdfHandlerWrapperSelectors.additionalData);
    const tags = useSelector(pdfHandlerWrapperSelectors.getTags);
    const categoriesEntities = useSelector(categorySelector.entities);
    const campaign = useSelector(campaignSelectors.currentCampaign);
    // const campaignSummaryData = useSelector(campaignSummarySelectors.data);
    const summaryDataByCampaignIdAndPeriod = useSelector(
        (state: AppState) => pdfHandlerWrapperSelectors.summaryDataByCampaignIdAndPeriod(state, campaign?.id, reportTimeFrame),
    );
    // const reportsInStore = useSelector(pdfHandlerReportSelectors.reports);
    const [categoriesDict, setCategoriesDict] = useState<ISimpleEntity[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<ISimpleEntity[]>([]);
    const [campaignName, setCampaignName] = useState<string>('');
    const period = useMemo<string>(() => (
        `${campaignUtils.getCampaignParseFromDateNoString(reportTimeFrame)
            .format('DD.MM.YYYY')} - ${new Date().toLocaleDateString('ru-RU')}`
    ), [reportTimeFrame]);

    const topPlatforms = useCallback((first: string, second: string) => summaryDataByCampaignIdAndPeriod?.topViewsByPlatform
        .filter(({ sourceType, views }) => views > 0 && [first, second].includes(sourceType))
        .reduce((acc: IDictionary<IPDFTopPostsModel>, curr: IPDFReportTopPlatform) => {
            if (!acc[curr.sourceType]) {
                acc[curr.sourceType] = { posts: [] };
            } else if (acc[curr.sourceType].posts.length >= 4) {
                return acc;
            }
            acc[curr.sourceType].posts.push({
                id: acc[curr.sourceType].posts.length + 1,
                content: curr.content,
                views: curr.views,
                link: curr.postLink,
            });
            return acc;
        }, {} as IDictionary<IPDFTopPostsModel>), [campaign, reportTimeFrame, summaryDataByCampaignIdAndPeriod]);

    const getRssOkPage = (index: number): JSX.Element => {
        const localData = topPlatforms('rss', 'ok');
        return (!localData || !Object.keys(localData)?.length)
            ? null
            : (
                <PDFTopPostsPage
                    title={`${index < 10 ? '0' : ''}${index} ТОП постов`}
                    reportName={reportName}
                    date={period}
                    reactElementKey="100"
                    data={localData}
                />
            );
    };

    useEffect(() => {
        if (categoriesEntities && categoriesDict?.length <= 0) {
            const result: ISimpleEntity[] = [
                {
                    id: 0,
                    name: 'Все категории',
                    value: 'Все категории',
                },
            ];
            Object.keys(categoriesEntities).forEach((key) => {
                result.push({
                    id: categoriesEntities[key]?.id,
                    name: categoriesEntities[key]?.mainCat,
                    value: categoriesEntities[key]?.mainCat,
                });
            });
            setCategoriesDict(result);
        }
    }, [categoriesEntities]);

    useEffect(() => {
        if (campaign && categoriesDict?.length) {
            setCampaignName(campaign?.name);
            if (campaign?.categoriesIds?.length) {
                setSelectedCategories([...categoriesDict.filter(({ id }) => campaign.categoriesIds.includes(Number(id)))]);
            }
        }
    }, [campaign, categoriesDict]);
    const getChartPages = useMemo(() => {
        const listGroups = chartList.reduce((acc, item) => {
            const newAcc = acc.map((oldGroup) => {
                const biggerCheck = oldGroup.find((elem) => elem.isBig);
                const sizeCheck = oldGroup.length < 2;
                if (!biggerCheck && sizeCheck && !item.isBig) {
                    return [oldGroup[0], item];
                }
                return oldGroup;
            });
            const checkon = newAcc.flat().find((old) => old.id === item.id);
            if (!checkon) {
                const group = [item];
                newAcc.push(group);
                return newAcc;
            }
            return newAcc;
        }, []);
        return listGroups.map((group, index) => {
            const template = {
                id: 3 + index,
                sequentialNumber: 2 + index,
                body: (
                    <TemplateChartPage
                        chartList={group as any[]}
                        reactElementKey={`${3 + index}_charts` as string}
                        reportName={reportName}
                        date={period}
                        title={`0${3 + index} Графики`}
                    />
                ),
            };
            return template;
        });
    }, [chartList]);

    const getRegionDataPages = useMemo(() => {
        const index = 3 + getChartPages.length;
        return (
            {
                id: index,
                sequentialNumber: index,
                body: (
                    <TemplateChartRegionData
                        data={regionData}
                        reactElementKey={`${index}_charts` as string}
                        reportName={reportName}
                        date={period}
                        title={`0${index} Регионы`}
                    />),
            }
        );
    }, [regionData, getChartPages]);

    const getCategoryPages = useMemo(() => {
        const index = 4 + getChartPages.length;
        return (
            {
                id: index,
                sequentialNumber: index,
                body: (
                    <TemplateBarChart
                        data={categoryData}
                        reactElementKey={`${index}_category_chart` as string}
                        reportName={reportName}
                        date={period}
                        title={`${index >= 10 ? index : `0${index}`}  Категории`}
                    />),
            }
        );
    }, [regionData, getChartPages]);
    const getAdditionalDataPages = useMemo(() => {
        const index = 5 + getChartPages.length;
        return (
            {
                id: index,
                sequentialNumber: index,
                body: (
                    <TemplateAdditionalData
                        tags={tags}
                        data={additionalData}
                        sourcesType={sourcesTypes}
                        reactElementKey={`${index}_charts` as string}
                        reportName={reportName}
                        date={period}
                        title={`${index >= 10 ? index : `0${index}`}  Регионы`}
                    />),
            }
        );
    }, [additionalData, getChartPages, tags]);
    const getAuthorsPages = useMemo(() => {
        const index = 6 + getChartPages.length;
        return (
            {
                id: index,
                sequentialNumber: index,
                body: (
                    <TemplateBarChart
                        data={authorsData}
                        reactElementKey={`${index}_authors_chart` as string}
                        reportName={reportName}
                        date={period}
                        title={`${index >= 10 ? index : `0${index}`} Авторы`}
                    />),
            }
        );
    }, [regionData, getChartPages]);
    useEffect(() => {
        if (!reportId || !campaignName) {
            return;
        }
        const pageIndex = Math.ceil((chartList?.length ?? 0) / 2) + 7;
        dispatch(pdfHandlerReportActions.setPagesInReport({
            reportId,
            pages: [
                {
                    id: 1,
                    sequentialNumber: 0,
                    body: <CampaignReportPDFFrontPage reactElementKey="1" title={campaignName} date={period} />,
                },
                {
                    id: 2,
                    sequentialNumber: 1,
                    body: (
                        <CampaignPDFSearchParamsPage
                            reactElementKey="2"
                            reportName={reportName}
                            date={period}
                            title="01 параметры поиска"
                            categories={selectedCategories ?? []}
                            trackedWords={
                                campaign?.includeWords?.map(
                                    (word, index) => ({ id: index, name: word, value: word }),
                                )
                            }
                            platforms={
                                campaign?.sourceType?.map(
                                    (word, index) => ({ id: index, name: word, value: word }),
                                )
                            }
                            excludedLinks={
                                campaign?.sources?.map(
                                    (item) => ({ id: item.id, name: item.link, value: item.link }),
                                )
                            }
                        />
                    ),
                },
                {
                    id: 3,
                    sequentialNumber: 2,
                    body: (
                        <CampaignPDFSummaryDataPage
                            reactElementKey="3"
                            reportName={reportName}
                            date={period}
                            title="02 Сводная часть"
                            topAuthorsByPosts={summaryDataByCampaignIdAndPeriod?.topAuthorsByPosts}
                            topAuthorsByViews={summaryDataByCampaignIdAndPeriod?.topAuthorsByViews}
                            amounts={campaignSummaryData}
                        />
                    ),
                },
                getRegionDataPages,
                getCategoryPages,
                getAuthorsPages,
                getAdditionalDataPages,
                {
                    id: 100,
                    sequentialNumber: 100,
                    body: getRssOkPage(pageIndex),
                },
                {
                    id: 101,
                    sequentialNumber: 101,
                    body: (
                        <PDFTopPostsPage
                            title={`${pageIndex < 10 ? '0' : ''}${pageIndex + 1} ТОП постов`}
                            reportName={reportName}
                            date={period}
                            reactElementKey="101"
                            data={topPlatforms('tg', 'vk')}
                        />
                    ),
                },
                ...getChartPages as any[],
            ],
        }));
    }, [reportId, chartList, campaignName, selectedCategories, summaryDataByCampaignIdAndPeriod, topPlatforms, period]);
    return null;
};

export default PDFWrapperDocumentContainer;
