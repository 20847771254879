import React from 'react';
import { Outlet } from 'react-router-dom';
import MyCampaignContainer from '../../sections/MyCampaignContainer';

import styles from './styles.module.scss';

const CampaignPage: React.FC = (): JSX.Element => (
    <div className={styles.root}>
        <MyCampaignContainer />
        <Outlet />
    </div>
);

export default CampaignPage;
