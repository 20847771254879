import { rtkQApi } from '../../index';
import {
    IQueryParamsGetReportFileUrl,
    IQueryParamsReportDelete, IQueryParamsSaveReportFile, IReportFromApi, IReportModel,
} from './types';

export const campaignReportsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postSaveReportFile: build.mutation<any, { campaignId: string, data: IQueryParamsSaveReportFile }>({
            query: (params) => {
                const body = new FormData();
                body.append('file', params.data.file);
                body.append(
                    'body',
                    JSON.stringify({
                        name: params.data.reportName,
                        time_frame: params.data.reportTimeFrame,
                        chart_ids: params.data.chart_ids,
                        filename: `${params.data.reportName}.${params.data.extension}`,
                        generated: params.data.generated,
                    }),
                );

                return {
                    url: `campaign/${params.campaignId}/report`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['CampaignSaveReportFile'],
        }),
        getFilters: build.query<any, { campaign_id: number }>({
            query: (body) => ({
                url: 'campaign/filters',
                method: 'POST',
                body,
            }),
        }),
        getSavedReports: build.query<IReportModel[], { id: number }>({
            query: ({ id }) => ({
                url: `campaign/${id}/report`,
                method: 'GET',
            }),
            transformResponse: (baseQueryResponse: IReportFromApi[]): IReportModel[] => {
                // @ts-ignore
                const sortedArr = baseQueryResponse.sort((a, b) => new Date(b.created) - new Date(a.created));

                return sortedArr.map((item) => ({
                    id: item.id,
                    campaignId: item.campaign_id,
                    name: item.name,
                    timeFrame: item.time_frame,
                    chartIds: item.chart_ids,
                    fileName: item.filename,
                    created: item.created,
                    generated: item.generated,
                } as IReportModel));
            },
            providesTags: ['CampaignSaveReportFile'],
        }),
        deleteReport: build.mutation<void, IQueryParamsReportDelete>({
            query: (body) => ({
                url: `campaign/${body.campaignId}/report/${body.reportId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CampaignSaveReportFile'],
        }),
        getReportFileUrl: build.query<{ file_url: string }, IQueryParamsGetReportFileUrl>({
            query: (body) => ({
                url: `campaign/${body.campaignId}/report/${body.reportId}/download`,
                method: 'GET',
            }),
        }),
    }),
});
