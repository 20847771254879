/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import moment from 'moment';
import { IQueryParamsTonality } from '../../../services/campaigns/tracking/types';
import { IApplyFilter } from '../../../containers/CampaignNewCharts/ChartTypes';
import { DateRangePickerValue } from '../../../components/DateRangePicker/types';

export const OLD_FORMAT_DATA = 'YYYY-MM-DD';
/**
*/
const useGetTonalityQuery = (
    campaignId: number,
    filters: IApplyFilter[],
    period: DateRangePickerValue,
) => {
    // const now = trackingCampaingRtqService.useGetReportAggQuery(query);
    const getQuery = useMemo((): IQueryParamsTonality => {
        const query: IQueryParamsTonality = {
            campaign_id: campaignId,
            filters_include: filters,
        };
        if (Array.isArray(period) && period?.length) {
            query.from_date = period[0].format(OLD_FORMAT_DATA);
            query.to_date = period[1].format(OLD_FORMAT_DATA);
        }
        return query;
    }, [period, filters, campaignId]);
    const getQueryForOld = useMemo(():IQueryParamsTonality => {
        const {
            from_date: fromDate,
            to_date: toDate,
        } = getQuery;
        const nowFromDate = fromDate ? moment(fromDate) : null;
        const nowToDate = toDate ? moment(toDate) : null;
        if (!fromDate || !toDate) return null;
        const diff = Math.abs(moment.duration(nowToDate.diff(nowFromDate)).asDays()) + 1;
        const oldFromDate = nowFromDate.clone().subtract(diff, 'd');
        const oldToDate = nowToDate.clone().subtract(diff, 'd');
        return { ...getQuery, from_date: oldFromDate.format(OLD_FORMAT_DATA), to_date: oldToDate.format(OLD_FORMAT_DATA) };
    }, [getQuery]);
    const isSkipOldData = useMemo(():boolean => !(getQuery?.from_date && getQuery?.to_date), [getQuery]);
    // const old = trackingCampaingRtqService.useGetReportAggQuery(getQueryForOld, { skip: isSkipOldData && !getQueryForOld });
    return {
        nowQuery: getQuery,
        oldQuery: getQueryForOld,
        skipOld: isSkipOldData,
    };
};

export default useGetTonalityQuery;
