import moment from 'moment';
import { t } from 'i18next';
import { getRandomNumber } from '../commons/commonUtils';
import { LIST_COLORS } from '../constants/Charts/colors';
import { AxisType, IChartPointsModel } from '../../services/campaigns/Charts/models';

export function createPointsFromCommons() {
    return false;
}

const randomColor = () => {
    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const r = randomInt(0, 255);
    const g = randomInt(0, 255);
    const b = randomInt(0, 255);
    return {
        color: `rgba(${r},${g},${b}, 1)`,
        lightColor: `rgba(${r},${g},${b}, 0.6)`,
        alphaColor: `rgba(${r},${g},${b}, 0.15)`,
    };
};

const getListCheckBoxes = (listPoints) => {
    if (!listPoints) return [];
    return listPoints?.map((item) => {
        const descriptions = item.data[0].content?.descriptions;
        const values = item.data[0].content?.values;
        const labels = descriptions?.map((description, index) => `${t(description)}: ${t(values[index])}`) || [];
        return { dataset: item, labels };
    });
};

const getGradient = (ctx, chartArea, start_color, stop_color) => {
    let width; let height; let
        gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, stop_color);
        gradient.addColorStop(1, start_color);
    }
    return gradient;
};
export function getTimeFromISOS(dt: string) {
    return new Date(dt).getTime();
}
type CoordinateTest = number | string;

interface IPointsTest {
    x: CoordinateTest,
    y: CoordinateTest,
    content?: any,
}

export class LineChartCreateDataset {
    subjects: IChartPointsModel[];

    options: any = {
        animation: true,
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,
    };

    panOptions = {
        enabled: true,
        mode: 'x',
        threshold: 5,
        scaleMode: 'x',
        // onPanComplete,
    };

    zoomOptions = {
        mode: 'x',
        wheel: {
            speed: 0.05,
            enabled: false,
        },
        pinch: {
            enabled: true,
        },
        // onZoomComplete,
    };

    scales = {};

    constructor(subjects: IChartPointsModel[]) {
        if (!this.subjects) { this.subjects = subjects; }
    }

    public getListPoints<
        // eslint-disable-next-line function-paren-newline
        CallbackX extends (arg: IChartPointsModel['x']) => CoordinateTest,
        CallbackY extends (arg: IChartPointsModel['y']) => CoordinateTest,
    >(
        listX: IChartPointsModel['x'],
        listY: IChartPointsModel['y'],
        typeX: AxisType,
        typeY: AxisType,
        content?: any,
        callbackX?: CallbackX,
        callbackY?: CallbackY,
    ): IPointsTest | any {
        if (!this.subjects) return [];
        const anyValue = (value) => value;
        const handlers = {
            dt: callbackX || getTimeFromISOS,
            counter: callbackY || anyValue,
        };
        return listX.map((x, index) => ({
            x: handlers[typeX](x),
            x2: handlers[typeX](x),
            y: handlers[typeY](listY[index]),
            content,
        }));
    }

    public getTimeScales() {
        const scales = {
            ...this.scales,
            x: {
                offsetAfterAutoskip: true,
                grid: {
                    color: 'rgba(0,0,0,0)',
                },
                ticks: {
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    // autoSkip: true,
                    maxTicksLimit: 11,
                    maxRotation: 10,
                    minRotation: 0,
                },
                type: 'time',
                time: {
                    unit: 'month',
                    displayFormats: {
                        month: 'MMMM',
                    },
                },
            },
            x2: {
                offsetAfterAutoskip: true,
                ticks: {
                    padding: 10,
                    // autoSkip: true,
                    maxTicksLimit: 11,
                    maxRotation: 10,
                    minRotation: 0,
                },
                type: 'time',
                time: {
                    displayFormats: {
                        hour: 'hh:mm',
                        day: 'DD.MM',
                        month: 'MMMM',
                    },
                },
            },
            y: {
                min: 0,
                ticks: {
                    padding: 10,
                    maxTicksLimit: 7,
                    callback(value) {
                        if (Math.floor(value) === value) { return `${value}`; }
                        return '';
                    },
                },
            },
        };
        return scales;
    }

    getZoomOptions() {
        const zoomOptions = {
            ...this.zoomOptions,
            drag: {
                enabled: true,
            },
        // onZoomComplete,
        };
        return zoomOptions;
    }

    getPanOptions() {
        const panOptions = {
            ...this.panOptions,
            modifierKey: 'alt',
            // onPanComplete,
        };
        return panOptions;
    }

    public getPlugins({ disableZoom, disableTooltip }) {
        const plugins = {
            zoom: !disableZoom && {
                pan: this.getPanOptions(),
                zoom: this.getZoomOptions(),
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            // tooltip: tooltipHtml,
            tooltip: !disableTooltip && {
                bodyFont: {
                    size: '14px',
                    weight: 500,
                },
                footerFont: {
                    size: '14px',
                    weight: 400,
                },
                titleFont: {
                    size: '14px',
                    weight: 400,
                },
                callbacks: {
                    label(data) {
                        return t(data?.dataset?.label);
                    },
                    title(data) {
                        return moment(data[0]?.parsed?.x).format('DD.MM.YYYY  hh:mm');
                    },
                    footer(data) {
                        const content = data[0]?.raw?.content;
                        const value = data[0]?.parsed?.y.toLocaleString('ru');
                        if (content) {
                            const list = content?.descriptions.map((elem, index) => `${t(elem)}: ${t(content?.values[index])}`);
                            return [...list, `значение: ${t(value)}`];
                        }
                        return value;
                    },
                },
                displayColors: false,
                backgroundColor: 'white',
                borderColor(data) {
                    return data?.tooltipItems[0]?.dataset?.allColors?.color || '#dfdfdf';
                },
                // borderColor: '#dfdfdf',
                bodyColor: '#787E93',
                footerColor: '#787E93',
                titleColor: '#787E93',
                borderWidth: 1,
                borderRadius: 6,
            },
        };
        return plugins;
    }

    public getOptions(
        beginAtZero?: boolean,
        intersect?: boolean,
        disableZoom?: boolean,
        disableTooltip?: boolean,
    ) {
        const options: any = {
            ...this.options,
            beginAtZero,
            responsive: true,
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect, // old listDatasets.length > 1,
            },
            elements: {
                line: {
                    tension: 0.37,
                },
                point: {
                    borderColor: 'white',
                },
            },
            scales: this.getTimeScales(),
            plugins: this.getPlugins({
                disableZoom,
                disableTooltip,
            }),
            // events: ['click'],
        };
        return options;
    }

    public create(type): any {
        const listPoints = this.subjects.map((subject, index) => {
            const color = LIST_COLORS[index] || randomColor();
            // randomColor
            return {
                id: `${index}_${getRandomNumber(0, new Date().getTime())}`,
                label: subject?.subjectValues?.length ? subject.subjectValues[subject.subjectValues.length - 1] : ' ',
                type: 'line',
                isShow: true,
                fill: false,
                backgroundColor(context) {
                    const {
                        ctx,
                        chartArea,
                    } = context.chart;
                    if (!chartArea) {
                        return null;
                    }
                    return getGradient(ctx, chartArea, color.lightColor, color.alphaColor);
                },
                allColors: color,
                borderColor: color.color,
                borderWidth: 1,
                pointBackgroundColor: color.color,
                pointBorderColor: 'white',
                pointRadius: 4,
                hidden: false,
                pointBorderWidth: 1.5,
                data: this.getListPoints(
                    subject.x,
                    subject.y,
                    subject.xType,
                    subject.yType,
                    {
                        descriptions: subject.subjectNames,
                        values: subject.subjectValues,
                    },
                ),
            };
        });
        const testOptions = this.getOptions();
        return {
            options: testOptions,
            typeChart: type,
            listCheckBoxes: getListCheckBoxes(listPoints),
            data: {
                datasets: listPoints,
            },
        };
    }
}
