import {
    IQPSourcesWithSortingAndFiltering, INumbersInterval, RangeFilter, ISourceRow,
} from '@services/sources/types';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Path from '@shared/lib/paths';
import { useNavigate } from 'react-router';
import { ISimpleEntity } from '@store/types';
import { useAppDispatch } from '@store/hooks';
import { categoriesRtkQService } from '@services/categories';
import { sourcesRtkQService } from '@services/sources';
import AddSourceModal from '@components/sources/AddSource';
import SourcesTopPanel from '@components/sources/TopPanel';
import AdvancedSearch from '@widgets/sources/sidepage/AdvancedSearch';
import { sourceListActions } from '@store/source/list';
import sourceMaps from '@app/constants/sourceMaps';
import TabelSources from '@features/source/TabelSources';
import { useSelector } from 'react-redux';
import sourceListSelectors from '@store/source/list/selectors';
import { message } from 'antd';
import styles from './styles.module.scss';

const LIMIT_FAVORITS = 50;
const deepCopy = (value: any): any => JSON.parse(JSON.stringify(value));
const FavoritSourcesSection: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const [updateSources] = sourcesRtkQService.useAddSourcesToListsMutation();
    const [removeSourcesFromList] = sourcesRtkQService.useRemoveSourcesFromListMutation();
    const { data: lists } = sourcesRtkQService.useGetListsQuery();
    const [getSources, { isFetching, isLoading }] = sourcesRtkQService.useLazyPostLoadSourcesInfinityQuery();

    const [isModal, setIsModal] = useState<boolean>(false);
    const [activeSidePage, setActiveSidePage] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchValueTopPanel, setSearchValueTopPanel] = useState<string>('');
    const [nextToken, setNextToken] = useState<string>(null);
    const [sources, setSources] = useState<ISourceRow[]>([]);
    const currentSortingAndFilters = useSelector(sourceListSelectors.activeFilters);
    const success = (content) => {
        messageApi.open({
            type: 'success',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_succes,
        });
    };
    const error = (content) => {
        messageApi.open({
            type: 'error',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_error,
        });
    };
    const [advancedFilters, setFilters] = useState<IQPSourcesWithSortingAndFiltering>(
        deepCopy(sourceMaps.initialFilters),
    );
    const [
        searchSourceTopPanel,
        {
            data: searchResultTopPanel,
            isLoading: isLoadingTopPanel,
            isFetching: isFetchingTopPanel,
            isError: isErrorTopPanel,
        },
    ] = sourcesRtkQService.useLazyGetSearchSourceQuery();
    const {
        data: platforms,
        isFetching: isFetchingPlatforms,
        isLoading: isLoadingPlatforms,
    } = sourcesRtkQService.useGetLoadPlatformsQuery();
    const {
        data: categories,
        isLoading: isLoadingCategories,
        isFetching: isFetchingCategories,
    } = categoriesRtkQService.useGetCategoriesQuery();
    const {
        data: filtersRanges,
        // isLoading: isLoadingRanges,
        // isFetching: isFetchingRanges,
        // isError: isErrorRanges,
    } = sourcesRtkQService.useGetFiltersRangesQuery();
    const sourcesTopPanel = useMemo(() => {
        if (isLoadingTopPanel || isFetchingTopPanel) {
            return [];
        }
        return (isErrorTopPanel || searchValueTopPanel.length <= 3)
            ? []
            : searchResultTopPanel?.map((item) => ({
                id: item?.id, name: item?.title, value: item?.link,
            } as ISimpleEntity));
    }, [searchResultTopPanel, isErrorTopPanel, searchValueTopPanel, isLoadingTopPanel, isFetchingTopPanel]);

    const getFavoriteId = useMemo(() => {
        if (!Array.isArray(lists)) return null;
        const favorite = lists.find((item) => item.isFavorite);
        return favorite.id;
    }, [lists]);

    const handlerStartSearchForTopPanel = (value: string): void => {
        searchSourceTopPanel({ limit: 15, substring: value });
    };

    const handlerSelectFilter = useCallback((ids: number[], filterName: string): void => {
        const newFilters = {
            ...advancedFilters,
            filterParams: { ...advancedFilters?.filterParams, [filterName]: ids },
            page: 1,
        } as IQPSourcesWithSortingAndFiltering;
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerSelectRangeFilter = useCallback((range: INumbersInterval | null, filterName: string): void => {
        let newFilters: IQPSourcesWithSortingAndFiltering = {
            ...advancedFilters,
            rangeParams: { ...advancedFilters?.rangeParams, [filterName]: range },
            page: 1,
        };
        if (range === null) {
            const ranges = null;
            Object.keys(newFilters.rangeParams).forEach((key) => {
                if (newFilters.rangeParams[key] !== null) {
                    ranges[key] = newFilters.rangeParams[key];
                }
            });
            newFilters = { ...newFilters, rangeParams: (ranges === null) ? null : ranges };
        }
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerSelectSource = (sourceId: number): void => {
        if (sourceId > 0) {
            navigate(`/${Path.Sources}/${sourceId}`);
        }
    };

    const handlerApplyAdvancedSearch = useCallback((ranges: RangeFilter, title: string): void => {
        const newFilters = {
            ...advancedFilters,
            page: 1,
            rangeParams: {},
            searchValue: title,
            list_id: getFavoriteId,
            limit: LIMIT_FAVORITS,
        };
        setSearchValue(title);
        dispatch(sourceListActions.setFilters(newFilters));
    }, [advancedFilters, nextToken, getFavoriteId]);

    const handleAddSourceToList = (list_ids: number[], sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        updateSources({
            list_ids,
            source_ids: [sourceId],
        }).then(() => {
            // setSources((prev) => [...prev.map((source) => (
            //     { ...source, title: 'HUE', lists: sourceId === source.id ? [...source.lists, ...list_ids] : source.lists }))]);
            setSources((prev) => {
                const clone = [...prev];
                const updated = clone.map((source) => ({
                    ...source,
                    lists: sourceId === source.id ? [...source.lists, ...list_ids] : source.lists,
                }));
                return updated;
            });
            success(`${title} успешно добавлен в выбранные списки`);
        }).catch((err) => {
            // message.error('При добавлении в избранное произошла ошибка');
            error(`При добавлении ${title} в списки произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При добавлении в списки произошла ошибка', err);
        });
    };
    const handleAddFavorite = (sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        if (typeof getFavoriteId !== 'number') return;
        updateSources({
            list_ids: [getFavoriteId],
            source_ids: [sourceId],
        }).then(() => {
            setSources((prev) => prev.map((source) => (
                { ...source, lists: sourceId === source.id ? [...source.lists, getFavoriteId] : source.lists })));
            success(`${title} успешно добавлен в избранное`);
        }).catch((err) => {
            // message.error('При добавлении в избранное произошла ошибка');
            error(`При добавлении ${title} в избранное произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При добавлении в избранное произошла ошибка', err);
        });
    };
    const handleRemoveFromFavorite = (sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        if (typeof getFavoriteId !== 'number') return;
        removeSourcesFromList({
            list_id: getFavoriteId,
            source_ids: [sourceId],
        }).then(() => {
            setSources((prev) => prev.map((source) => (
                { ...source, lists: sourceId === source.id ? source.lists.filter((id) => id !== getFavoriteId) : source.lists })));
            success(`${title} успешно убран из избраного`);
        }).catch((err) => {
            error(`При удалении ${title} из избраного произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При удалении из избранного произошла ошибка', err);
        });
    };
    const handleGetNextSource = () => {
        const newFilters = {
            ...advancedFilters,
            page: (advancedFilters?.page || 1) + 1,
            list_id: getFavoriteId,
            limit: LIMIT_FAVORITS,
            prevPageToken: nextToken,
            filtersRanges: null,
        };
        dispatch(sourceListActions.setFilters(newFilters));
    };
    const getLists = useMemo(() => {
        const filtred = lists?.filter(({ id }) => id !== getFavoriteId) || [];
        return filtred;
    }, [lists]);
    // COMPONENT_MOUNT
    useEffect(() => {
        if (!getFavoriteId) return;
        if (!sources.length) {
            getSources({
                ...currentSortingAndFilters,
                filterParams: {},
                rangeParams: {},
                limit: LIMIT_FAVORITS,
                list_id: getFavoriteId,
            }).then((res) => {
                if (res?.data) {
                    setSources(res.data.sources);
                    setNextToken(res.data.nextPageToken);
                }
            });
        } else if (currentSortingAndFilters.page === 1) {
            getSources({
                ...currentSortingAndFilters,
                filterParams: {},
                rangeParams: {},
                limit: LIMIT_FAVORITS,
                list_id: getFavoriteId,
            }).then((res) => {
                if (res?.data) {
                    setSources(res.data.sources);
                    setNextToken(res.data.nextPageToken);
                }
            });
        } else {
            getSources({
                ...currentSortingAndFilters,
                filterParams: {},
                rangeParams: {},
                limit: LIMIT_FAVORITS,
                list_id: getFavoriteId,
            }).then((res) => {
                if (res?.data) {
                    setSources((prev) => [...prev, ...res.data.sources]);
                    setNextToken(res.data.nextPageToken);
                }
            });
        }
    }, [getFavoriteId, currentSortingAndFilters]);
    useEffect(() => () => {
        dispatch(sourceListActions.clear());
        setSources([]);
        setNextToken('');
    }, []);
    return (
        <div className={styles.sources_root}>
            {contextHolder}
            {isModal && <AddSourceModal setIsModal={setIsModal} />}
            {activeSidePage && (
                <AdvancedSearch
                    currentFilters={advancedFilters}
                    activeSidePage={activeSidePage}
                    setActiveSidePage={setActiveSidePage}
                    platforms={platforms}
                    categories={categories}
                    isLoadingPlatforms={isLoadingPlatforms || isFetchingPlatforms}
                    isLoadingCategories={isLoadingCategories || isFetchingCategories}
                    platformsIcon={sourceMaps.platformsIcon}
                    platformByIdMap={sourceMaps.platformByIdMap}
                    searchValue={searchValue}
                    onSelectFilter={handlerSelectFilter}
                    onSelectRangeFilters={handlerSelectRangeFilter}
                    onApply={handlerApplyAdvancedSearch}
                    ranges={filtersRanges}
                />
            )}
            {
                false && (
                    <SourcesTopPanel
                        searchValue={searchValueTopPanel}
                        isLoading={isLoadingTopPanel || isFetchingTopPanel}
                        setIsModal={setIsModal}
                        setSearchValue={setSearchValueTopPanel}
                        sources={sourcesTopPanel}
                        activeSidePage={activeSidePage}
                        setActiveSidePage={setActiveSidePage}
                        onSelectSource={handlerSelectSource}
                        onStartSearch={handlerStartSearchForTopPanel}
                    />
                )
            }
            {
                true && (
                    <div style={{ height: 16 }} />
                )
            }
            <TabelSources
                listSources={sources}
                lists={getLists}
                isLoading={isFetching || isLoading}
                favoriteId={getFavoriteId}
                isDisabledcheckboxes
                isDisabledInfinityScroll={!nextToken}
                listExceptedIds={[]}
                onAddSourceToList={handleAddSourceToList}
                onAddFavorite={handleAddFavorite}
                onRemoveFromFavorite={handleRemoveFromFavorite}
                onGetNextSource={handleGetNextSource}
                onChangeSelected={() => null}
            />
        </div>
    );
};

export default FavoritSourcesSection;
