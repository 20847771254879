import { createSlice } from '@reduxjs/toolkit';
import { ICommonAnalyzeState } from 'store/common/analyze/types';

const initialState: ICommonAnalyzeState = {
    needStop: false,
};

const { actions: commonAnalyzeActions, reducer } = createSlice({
    name: 'commonAnalyze',
    initialState,
    reducers: {
        stopAnalyze: (
            state: ICommonAnalyzeState,
        ) => {
            state.needStop = true;
        },
        stopAnalyzeCompleted: (
            state: ICommonAnalyzeState,
        ) => {
            state.needStop = false;
        },
    },
});

export { commonAnalyzeActions };
export default reducer;
