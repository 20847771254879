/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useMemo } from 'react';
import {
    View, StyleSheet, Font, Text, Image,
} from '@react-pdf/renderer';
import CampaignPDFTemplatePage from '../TemplatePage';

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const styles = StyleSheet.create({
    root: {
        width: '100%',
        height: '100%',
        padding: 24,
        display: 'flex',
    },
});

interface IPDFChartList {
    data: any;
    sourcesType: any;
    tags: any[];
    reactElementKey: string;
    title: string;
    reportName: string;
    date: string;
}
const TemplateAdditionalData: React.FC<IPDFChartList> = ({
    data,
    tags,
    sourcesType,
    reactElementKey,
    reportName,
    date,
    title,
}): JSX.Element => {
    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const formatting = useMemo(() => {
        const result = tags.reduce((acc, item) => {
            if (!acc.length) {
                acc.push([item]);
                return acc;
            }
            const last = acc.at(-1);
            const withBigger = last.find((elem) => elem.fontSize === '32px');
            if ((withBigger && last.length < 2) || (!withBigger && last.length < 4)) {
                last.push(item);
            } else {
                acc.push([item]);
            }
            return acc;
        }, []);
        return result;
    }, [tags]);
    const getPaddingBottom = (index: number) => {
        const list = [
            randomInt(-8, 0),
            randomInt(-4, 4),
            randomInt(0, 6),
        ];
        return list[index];
    };

    return (
        <CampaignPDFTemplatePage reactElementKey={reactElementKey} reportName={reportName} date={date} title={title}>
            <View style={styles.root}>
                <View>
                    <View
                        style={{
                            position: 'absolute',
                            top: '16px',
                            left: '16px',
                            width: `${330}px`,
                            height: `${200}px`,
                        }}
                    >
                        <Text style={{
                            fontFamily: 'MontserratMedium500',
                            marginLeft: '0px',
                            marginBottom: '8px',
                            backgroundColor: '#f5fefd',
                            fontSize: '16px',
                            color: '#787E93',
                            paddingTop: '8px',
                            paddingBottom: '12px',
                            paddingLeft: '24px',
                        }}
                        >
                            {'Тональность'}
                        </Text>
                        <Image
                            style={{
                                marginTop: '8px',
                            }}
                            src={data?.tonnality}
                        />
                    </View>
                    <View
                        style={{
                            position: 'absolute',
                            top: '16px',
                            left: '392px',
                            width: `${380}px`,
                            height: `${380}px`,
                        }}
                    >
                        <Text style={{
                            width: '450px',
                            fontFamily: 'MontserratMedium500',
                            marginLeft: '0px',
                            marginBottom: '8px',
                            backgroundColor: '#f5fefd',
                            fontSize: '16px',
                            color: '#787E93',
                            paddingTop: '8px',
                            paddingBottom: '12px',
                            paddingLeft: '24px',
                        }}
                        >
                            {'Типы авторов'}
                        </Text>
                        <Image
                            style={{
                                width: `${380}px`,
                                marginTop: '-100px',
                                height: `${380}px`,
                                marginLeft: '20px',
                            }}
                            src={sourcesType}
                        />
                    </View>
                    <View
                        style={{
                            position: 'absolute',
                            top: '280px',
                            left: '16px',
                            width: `${330}px`,
                            height: `${270}px`,
                        }}
                    >
                        <Text style={{
                            fontFamily: 'MontserratMedium500',
                            marginLeft: '0px',
                            marginBottom: '8px',
                            backgroundColor: '#f5fefd',
                            fontSize: '16px',
                            color: '#787E93',
                            paddingTop: '8px',
                            paddingBottom: '12px',
                            paddingLeft: '24px',
                            width: '350px',
                        }}
                        >
                            {'Платформы'}
                        </Text>
                        <Image
                            style={{
                                width: `${250}px`,
                                height: `${270}px`,
                                marginTop: '-32px',
                                marginLeft: '40px',
                            }}
                            src={data?.platforms}
                        />
                    </View>
                    <View
                        style={{
                            position: 'absolute',
                            top: '280px',
                            left: '392px',
                            width: `${250}px`,
                            height: `${150}px`,
                        }}
                    >
                        <Text style={{
                            width: '450px',
                            fontFamily: 'MontserratMedium500',
                            marginLeft: '0px',
                            marginBottom: '16px',
                            backgroundColor: '#f5fefd',
                            fontSize: '16px',
                            color: '#787E93',
                            paddingTop: '8px',
                            paddingBottom: '12px',
                            paddingLeft: '24px',
                        }}
                        >
                            {'Теги'}
                        </Text>
                        <View style={{
                            width: '400px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                        >
                            {
                                formatting.map((group, index) => (
                                    <View
                                        key={`group_${index}`}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginBottom: '16px',
                                            marginLeft: '26px',
                                        }}
                                    >
                                        {
                                            group.map((item) => (
                                                <Text
                                                    style={{
                                                        fontFamily: 'MontserratMedium500',
                                                        fontSize: item.fontSize,
                                                        color: item.color,
                                                        marginRight: '24px',
                                                        marginBottom: `${getPaddingBottom(index)}px`,
                                                    }}
                                                    key={item.label + item.fontSize}
                                                >
                                                    {item.label}
                                                </Text>
                                            ))
                                        }
                                    </View>
                                ))
                            }
                        </View>
                    </View>
                </View>
            </View>
        </CampaignPDFTemplatePage>
    );
};

export default TemplateAdditionalData;
