export const enum ColumnFilterInfoBasketType {
    default = 0,
    single = 1,
    category = 2,
    platform = 3,
    title = 4,
    price = 5,
    audience = 6,
    mediaType = 7,
}

export interface IDropdownFilterInfoBasketTableProps {
    name: string;
    filterType: ColumnFilterInfoBasketType;
    invisible?: boolean;
    isRetinaSize: boolean;
}
