import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import { IPeriod } from 'store/types';
import classnames from 'classnames';
import ArrowIcon from '../../../assets/images/arrow.svg';
import DropdownList from './DropdownList/DropdownList';
import { ISelectPeriodItemProps, ISimpleSelectItemData } from './types';

import styles from './styles.module.scss';

const getSelectedName = (id: string, list: IPeriod[]): string => {
    if (!id || !list) {
        return '';
    }
    return list.find((item: IPeriod) => item.id === id)?.selectedName;
};

const SimpleSelectItem: React.FunctionComponent<ISelectPeriodItemProps> = (
    {
        defaultTitle, list, selectedId, onSelect, isRetinaSize, tagId, notShadow, isDisabled = false, isCommon = false,
    }: ISelectPeriodItemProps,
): React.ReactElement => {
    const [content] = useState<string>(defaultTitle);
    const [periodName, setPeriodName] = useState<string>(() => getSelectedName(selectedId, list));
    const [menuIsVisible, setVisibleMenu] = useState<boolean>();

    useEffect(() => {
        setPeriodName(getSelectedName(selectedId, list));
    }, [selectedId, list]);

    const onHandlerClick = (id: string): void => {
        onSelect(id);
    };

    const onMouseLeave = (): void => {
        if (menuIsVisible) {
            setVisibleMenu(false);
        }
    };

    const handlerVisible = (visible: boolean): void => {
        setVisibleMenu(visible);
    };

    const menu = (
        <DropdownList items={list} onSelect={onHandlerClick} isRetinaSize={isRetinaSize} />
    );
    const selectedItem = useMemo((): ISimpleSelectItemData => list.find(({ id }) => id === selectedId), [selectedId]);
    const periodText = (): string => {
        if (isRetinaSize) {
            switch (selectedId) {
                case '1':
                    return '1 день';
                case '2':
                    return '7 дней';
                case '3':
                    return periodName;
                default:
                    break;
            }
        }
        return `За ${periodName}`;
    };

    return (
        <div onMouseLeave={onMouseLeave}>
            <Dropdown
                visible={menuIsVisible}
                overlay={menu}
                className={styles.dropdown}
                trigger={['click']}
                onVisibleChange={handlerVisible}
                disabled={isDisabled ?? false}
            >
                <button
                    id={tagId}
                    type="button"
                    className={classnames(
                        styles.selectItemContainer,
                        styles[selectedId ? 'filled' : ''],
                        styles[selectedId ? 'filled' : ''],
                        styles[isRetinaSize ? 'retina' : ''],
                        styles[notShadow ? '' : 'shadow'],
                    )}
                >
                    {
                        selectedId
                            ? (
                                <span className={classnames(
                                    styles.regionsAmountText,
                                    styles[isRetinaSize ? 'retina' : ''],

                                )}
                                >
                                    {
                                        isCommon
                                            ? selectedItem?.name
                                            : periodText()
                                    }
                                </span>
                            )
                            : (
                                <span
                                    className={classnames(
                                        styles.selectItemContainer_text,
                                        styles[isRetinaSize ? 'retina' : ''],
                                    )}
                                >
                                    {content}
                                </span>
                            )
                    }
                    <img src={ArrowIcon} alt="ArrowIcon" />
                </button>
            </Dropdown>
        </div>
    );
};

export default SimpleSelectItem;
