import React, {
    useEffect, useRef, useState,
} from 'react';
import {
    Chart as ChartJS,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ControlPanel from '@shared/ui-components/ControlPanel';
import { OPTIONS } from './settings';
import { IPropsLineChart } from '../types';

ChartJS.register(
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
);

const NewLineChart: React.FC<IPropsLineChart> = ({
    chartData,
}) => {
    const chartRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(() => false);
    const handleMoveChart = (count: number) => {
        chartRef?.current.pan(count);
    };
    const handleChangeZoom = (count: number) => {
        chartRef?.current.zoom(count);
    };
    const handleClickOnLeft = () => {
        handleMoveChart(20);
    };
    const handleClickOnRight = () => {
        handleMoveChart(-20);
    };
    const handleClickOnShrink = () => {
        handleChangeZoom(0.9);
    };
    const handleClickOnEnlarge = () => {
        handleChangeZoom(1.1);
    };
    const handleResetZoom = () => {
        chartRef?.current?.resetZoom();
    };
    const handleKeyDown = (e: KeyboardEvent) => {
        if (!isFullScreen) return;
        if (e.key === 'Escape') {
            setIsFullScreen(false);
        }
    };
    useEffect(() => {
        if (isFullScreen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullScreen]);

    const createDataset = () => {
        const listDatasets = chartData?.map((item) => (
            {
                type: 'line',
                label: item.label || '',
                isShow: true,
                allColors: item.color,
                borderColor: item.color,
                borderWidth: 1.2,
                pointBackgroundColor: item.color,
                pointBorderColor: 'white',
                pointRadius: 4,
                hidden: false,
                pointBorderWidth: 1.5,
                data: item?.points?.map((point) => ({ ...point, x2: point.x })),
            }
        ));
        return { datasets: listDatasets };
    };
    return (
        <div style={{ width: '100%', height: 450, position: 'relative' }}>
            <div style={{ height: 'calc(100% - 38px)' }}>
                <Line
                    plugins={[zoomPlugin]}
                    ref={chartRef}
                    options={OPTIONS as any}
                    data={createDataset() as any}
                />
            </div>
            <ControlPanel
                onClickOnShrink={handleClickOnShrink}
                onClickOnEnlarge={handleClickOnEnlarge}
                onClickOnLeft={handleClickOnLeft}
                onClickOnRight={handleClickOnRight}
                onResetZoom={handleResetZoom}
                setIsFullScreen={setIsFullScreen}
            />
        </div>
    );
};

export default React.memo(NewLineChart);
