/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import { ISpecificFilterQuery } from '../../../../services/campaigns/userFilters/types';
import { userFiltersCampaingRtqService } from '../../../../services/campaigns/userFilters';
import styles from './styles.module.scss';
import { TTypeModal } from '../types';
import Modalnfo from '../Modalnfo';
import ModalChange from '../ModalChange';

interface IPropsLastItem {
    isOpen: boolean,
    onClose: () => void,
    id: number,
    campaignId: number,
    typeModal: TTypeModal,
    onChangeFilters?: (args: any) => void
}

const ModalWrapper: React.FC<IPropsLastItem> = ({
    campaignId,
    typeModal,
    id,
    isOpen,
    onClose,
    onChangeFilters,
}): JSX.Element => {
    const [removeFilter] = userFiltersCampaingRtqService.useLazyDeleteApplyFilterQuery();
    const [currentType, setCurrentType] = useState<TTypeModal | ''>(() => typeModal || 'view');
    useEffect(() => {
        setCurrentType(typeModal);
    }, [typeModal]);
    const getQuery = useMemo((): ISpecificFilterQuery => ({
        campaign_id: campaignId,
        id,
    }), [campaignId, id]);
    const { data } = userFiltersCampaingRtqService.useGetSpecificFilterQuery(getQuery, { skip: !id || !campaignId });
    const getDefaultData = useMemo(() => {
        if (data) return null;
        return {
            title: '',
            campaign_id: campaignId,
            filters_include: [],
            filters_exclude: [],
        };
    }, [data]);
    const handleGoToChange = () => {
        setCurrentType('change');
    };
    const onRemove = () => {
        removeFilter({
            id: data?.id, campaign_id: campaignId,
        }).then((res) => {
            if (res?.isError) {
                message.error('При удалении произошла ошибка.');
            } else {
                message.success(`Вы успешно удалили конфигурацию: ${data?.title}`);
            }
        }).catch(() => {
            message.error('При удалении произошла ошибка.');
        }).finally(() => {
            onClose();
        });
    };
    const handleCloseOverlay = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentType('');
        onClose();
    };
    if (!isOpen) return null;
    return (
        <div
            className={styles.root_wrapperUserFilterModal}
        >
            <div
                className={styles.root_wrapperUserFilterModal}
                onClick={handleCloseOverlay}
            />
            <div>
                { currentType === 'view'
                && (
                    <Modalnfo
                        data={data}
                        onGoToChange={handleGoToChange}
                        onRemove={onRemove}
                        onClose={onClose}
                    />
                )}
                {
                    currentType === 'change'
                && (
                    <ModalChange
                        campaignId={campaignId}
                        data={data || getDefaultData}
                        onClose={onClose}
                        onChangeFilters={onChangeFilters}
                    />
                )
                }
            </div>
        </div>

    );
};
export default ModalWrapper;
