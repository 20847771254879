/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import BloggerProfileContainer from '../../../../containers/info-basket/Blogger/BloggerProfileContainer';
import { DateRangePickerValue } from '../../../../components/DateRangePicker/types';
import { IApplyFilter, IPost } from '../../../../sections/MyCampaignContainer/CampaingTypes';
import { trackingCampaingRtqService } from '../../../../services/campaigns/tracking';
import { IDataQueryParamsCampaignPosts, IQueryParamsCampaingPosts } from '../../../../services/campaigns/tracking/types';
import { postsRtkQService } from '../../../../services/campaigns/posts';
import { TOrder, TSorts } from './types';
import ModalDynamicChart from '../../../../components/ModalDynamicChart';
import ModalCardPlot from '../../../../components/ModalCardPlot';
import styles from './styles.module.scss';
import { Post, Sort } from '../../../../features/campaign/tracking';
import { LastItem } from '../../../../entities';

const DEFAULT_LIMIT_ON_POSTS = 20;

interface IProps {
    campaignId: number,
    filters: IApplyFilter[],
    period: DateRangePickerValue,
    isIPad: boolean,
    onError: (status: boolean) => void,
}

const OLD_PERIOD_FORMAT = 'YYYY-MM-DD';

const PostList: React.FC<IProps> = ({
    campaignId,
    filters,
    period,
    isIPad,
    onError,
}): JSX.Element => {
    const [selectPost, setSelectPost] = useState<IPost>(null);
    const [modalDynamicData, setModalDynamicData] = useState<IPost>(null);
    const [isInitial, setIsInitial] = useState(true);
    const [isActiveScroll, setActiveScroll] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [sortType, setSortType] = useState<TSorts>('posted');
    const [orderType, setOrderType] = useState<TOrder>('desc');
    const [posts, setPosts] = useState<IPost[]>([]);
    const [authorId, setAuthorId] = useState<number | null>(null);
    const [removedPosts, setRemovedPosts] = useState<IPost[]>([]);
    const [deletePost] = postsRtkQService.useDeletePostMutation();
    const [restorePost] = postsRtkQService.usePostRestoreMutation();
    const getParms = useMemo((): IQueryParamsCampaingPosts => {
        const defaultParams: IDataQueryParamsCampaignPosts = {
            filters_include: filters,
            limit: DEFAULT_LIMIT_ON_POSTS,
            page: pageNumber,
            sort_params: {
                order: orderType,
                column: sortType,
            },
        };
        if (Array.isArray(period) && period.length) {
            defaultParams.date_periods = [{
                from_date: period[0].format(OLD_PERIOD_FORMAT),
                to_date: period[1].format(OLD_PERIOD_FORMAT),
            }];
        }
        const result: IQueryParamsCampaingPosts = {
            campaign_id: campaignId,
            data: defaultParams,
        };
        return result;
    }, [campaignId, filters, period, pageNumber, sortType, orderType]);
    const {
        data,
        currentData,
        error,
        isUninitialized,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        refetch,
    } = trackingCampaingRtqService.useGetPostsQuery(getParms);
    // eslint-disable-next-line arrow-body-style
    const isenabledScroll = useMemo(() => {
        return !isFetching && !isError && isActiveScroll && !!posts.length && data?.length >= DEFAULT_LIMIT_ON_POSTS;
    }, [isFetching, isLoading, isError, posts, isActiveScroll]);
    useEffect(() => {
        onError(isError);
    }, [isError]);
    const clear = () => {
        setPosts([]);
        setPageNumber(1);
    };
    useEffect(() => {
        if (data && isInitial) setIsInitial(false);
    }, [data, currentData, error, isUninitialized, isLoading, isFetching, isSuccess, isError]);
    useEffect(() => {
        if (posts) clear();
    }, [filters]);
    useEffect(() => {
        if (!isInitial) refetch();
    }, [getParms]);
    useEffect(() => {
        if (data) { setPosts((prev) => [...prev, ...data]); }
    }, [data]);
    const handleOpenBloggerModal = (newAuthorId: number) => {
        if (!newAuthorId) return;
        setAuthorId(newAuthorId);
    };
    const handleOpenModalDinmaiyc = (selectedPost: IPost) => {
        if (!selectedPost) return;
        setModalDynamicData(selectedPost);
    };
    const handlerOnLastItem = () => {
        if (isenabledScroll) setPageNumber((prev) => prev + 1);
    };
    const handlerOnChangeSortType = useCallback((type: TSorts) => {
        if (sortType === type) return;
        setSortType(type);
        clear();
    }, [sortType]);
    const handlerOnChangeorderType = useCallback((order: TOrder) => {
        setOrderType(order);
        clear();
    }, []);
    const handleDeletePost = (postId: number) => {
        const post = posts.find((item) => item.post_id === postId);
        setPosts((prev) => prev.filter((item) => item.post_id !== postId));
        setRemovedPosts((prev) => [...prev, post]);
        deletePost({ campaignId, id: postId });
    };
    const handleRestorePost = (postId) => {
        restorePost({ campaignId, id: postId });
        refetch();
    };
    const AllPosts = posts.map((card) => (
        <Post
            key={`${card.last_update}_${card.source_name}_${card.author_id}_${card.post_link}_cardKey`}
            post={card}
            isIPadSize={isIPad}
            allowDelete
            openBloggerModal={() => handleOpenBloggerModal(card?.author_id)}
            openModalChartDinamiyc={() => handleOpenModalDinmaiyc(card)}
            openMoreModal={() => setSelectPost(card)}
            handleDeletePost={handleDeletePost}
            handleRestorePost={handleRestorePost}
        />
    ));
    useEffect(() => {
        setTimeout(() => setActiveScroll(true), 1200);
    }, []);
    const LastItemWithSkeleton = (
        <LastItem
            onLastItem={handlerOnLastItem}
            enabled={data?.length === DEFAULT_LIMIT_ON_POSTS || isLoading}
        />
    );
    return (
        <div className={styles.root_ContainerInfo} style={isIPad ? { minWidth: 400, width: 600 } : null}>
            <ModalCardPlot
                isOpen={!!selectPost}
                post={selectPost}
                closeModal={() => setSelectPost(null)}
            />
            <ModalDynamicChart
                isOpen={!!modalDynamicData}
                close={() => setModalDynamicData(null)}
                postDate={new Date(modalDynamicData?.last_update).getTime()}
                pid={modalDynamicData?.post_id}
                postData={modalDynamicData}
                campaign_id={campaignId}
                filter_apply={filters}
            />
            { !!authorId && (
                <BloggerProfileContainer
                    isOpen={!!authorId}
                    authorId={authorId}
                    setIsOpen={() => setAuthorId(null)}
                />
            )}
            <Sort
                type={sortType}
                order={orderType}
                onChangeType={handlerOnChangeSortType}
                onChangeOrder={handlerOnChangeorderType}
            />
            {AllPosts}
            {LastItemWithSkeleton}
        </div>
    );
};
export default React.memo(PostList, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
