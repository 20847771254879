import React, { useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import classnames from 'classnames';
import SingleDropdownList from '../../../common/SingleSelectItem/SingleDropdownList';
import ArrowIcon from './dropdownArrow.svg';
import { ISimpleEntity } from '../../../../store/types';
import { ICampaignMenuTab } from '../types';
import styles from '../styles.module.scss';

interface ICampaignMenuDropdownTabProps {
    selectedId: number;
    tagId?: string;
    items: ICampaignMenuTab[];
    defaultTitle: string;
    onSelect: (id: number) => void;
}

const CampaignMenuDropdownTab: React.FC<ICampaignMenuDropdownTabProps> = ({
    tagId, selectedId, items, onSelect, defaultTitle,
}): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [menuIsVisible, setVisibleMenu] = useState<boolean>();
    const handlerSelect = (id: number): void => {
        onSelect(id);
    };
    // const onMouseLeave = (): void => {
    //     if (menuIsVisible) {
    //         setVisibleMenu(false);
    //     }
    // };
    // const handlerVisibleChange = (visible: boolean): void => {
    //     setVisibleMenu(visible);
    // };
    const list = useMemo<ISimpleEntity[]>(() => items.map((el) => ({ id: el.id, value: el.title, name: el.title })), [items]);
    const selectedItem = useMemo<ISimpleEntity>((): ISimpleEntity => list.find(({ id }) => id === selectedId), [selectedId, list]);
    const activeTabId = useMemo<number>((): number => items.find(({ isActive }) => isActive)?.id ?? 0, [items]);

    return (
        <div>
            <Dropdown
                visible={menuIsVisible}
                overlay={
                    <SingleDropdownList items={list} onSelect={handlerSelect} tagId={tagId} isLoading={false} />
                }
                className={styles.dropdown}
                trigger={['click']}
            >
                <button
                    id={tagId}
                    type="button"
                    className={classnames(
                        styles.selectItemContainer,
                        styles[selectedId ? 'filled' : ''],
                    )}
                >
                    {
                        selectedId
                            ? (
                                <span className={classnames(
                                    styles.dropdownTitleText,
                                    styles[selectedId === activeTabId ? 'active' : ''],
                                )}
                                >
                                    {selectedItem?.name}
                                </span>
                            )
                            : (
                                <span
                                    className={classnames(
                                        styles.selectItemContainer_text,
                                    )}
                                >
                                    {defaultTitle}
                                </span>
                            )
                    }
                    <img src={ArrowIcon} alt="ArrowIcon" />
                </button>
            </Dropdown>
        </div>
    );
};

export default CampaignMenuDropdownTab;
