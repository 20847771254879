import { IChartPointsModel } from '../../services/campaigns/Charts/models';
import { BarChart } from './BarChart';
import { BarChartRegion } from './BarChartRegion';
import { LabelsPieChart } from './LabelsPieChart';
import { LineChartCreateDataset } from './LineChart';
import { MapChart } from './MapChart';
import { WordCloudChart } from './WordCloudChart';

export function createPointsFromCommons() {
    return false;
}

export class CreateDataset {
    subjects: IChartPointsModel[];

    constructor(subjects: IChartPointsModel[]) {
        this.subjects = subjects;
    }

    public create(typeChart): any {
        const listChartsByType = {
            LINE_CHART: new LineChartCreateDataset(this.subjects),
            BUBBLE_CHART: new MapChart(this.subjects),
            TIME_CHART: new LineChartCreateDataset(this.subjects),
            BAR_CHART: new BarChart(this.subjects),
            PIE_CHART: new LabelsPieChart(this.subjects),
            MAP_CHART: new MapChart(this.subjects),
            TAG_CHART: new WordCloudChart(this.subjects),
            BAR_CHART_REGION: new BarChartRegion(this.subjects),
        };
        return listChartsByType[typeChart]?.create(typeChart);
    }
}
