import React, { useMemo } from 'react';
import { ISimpleEntity } from 'store/types';
import styles from '../styles.module.scss';

interface IProps {
    maxWidth: number;
    tickWidth: number;
    data: ISimpleEntity[];
}

interface ITickLabel {
    id: number;
    value: string;
    width: number;
}

const monthsNameList = [
    'Январь', 'Февраль', 'Март',
    'Апрель', 'Май', 'Июнь', 'Июль',
    'Август', 'Сентябрь', 'Октябрь',
    'Ноябрь', 'Декабрь',
];

const DateSliderTickLabels: React.FC<IProps> = ({
    maxWidth,
    data,
    tickWidth,
}): JSX.Element => {
    const labels = useMemo<ITickLabel[]>(() => {
        if (!(data?.length > 0)) {
            return [];
        }
        let lastMonthLabel: ISimpleEntity = null;
        let emptySpace = false;
        let monthLabelWidth = 0;
        return data.reduce((acc, cur) => {
            if (!cur.name) {
                emptySpace = true;
                monthLabelWidth += tickWidth;
                return acc;
            }
            if (monthsNameList.includes(cur.name)) {
                lastMonthLabel = cur;
                monthLabelWidth += tickWidth;
                return acc;
            }
            if (emptySpace && cur.name && lastMonthLabel) {
                const result = [
                    ...acc,
                    { id: Number(lastMonthLabel.id), value: lastMonthLabel.value.toString(), width: monthLabelWidth },
                    { id: Number(cur.id), value: cur.value.toString(), width: tickWidth },
                ];
                lastMonthLabel = null;
                emptySpace = false;
                monthLabelWidth = 0;
                return result;
            }
            acc.push({ id: Number(cur.id), value: cur.value.toString(), width: tickWidth });
            return acc;
        }, [] as ITickLabel[]);
    }, [data]);

    return (
        <div className={styles.tickLabels_root} style={{ width: maxWidth }}>
            <div className={styles.tickLabels_row}>
                {
                    data?.length > 0 && (
                        data.map(({ id }) => (
                            <div key={id} className={styles.tick} style={{ width: tickWidth }} />
                        ))
                    )
                }
            </div>
            <div className={styles.tickLabels_row} style={{ marginLeft: -(tickWidth / 2) }}>
                {
                    labels?.length > 0 && (
                        labels.map(({ id, value, width }) => (
                            <span
                                key={id}
                                className={`${styles.tick_label} ${styles.noSelect}`}
                                style={{ width }}
                            >
                                {value}
                            </span>
                        ))
                    )
                }
            </div>
        </div>
    );
};

export default DateSliderTickLabels;
