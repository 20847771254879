import classnames from 'classnames';
import React, {
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { ISelectItem } from '@shared/ui-components/Select/types';
import { selectCommonWindowIsIPadSize } from '@store/common/window/selectors';
import { Button } from '@shared/index';
import CampaignReportsNameField from './ReportName';
import CampaignReportsPeriodField from './ReportPeriod';
import styles from './styles.module.scss';

const listPeriods: ISelectItem[] = [
    { id: 1, value: '7 дней', label: '7 дней' },
    { id: 2, value: '30 дней', label: '30 дней' },
];

interface ICampaignReportsFiltersProps {
    onCreateReport: (reportName: string) => void;
    onSelectPeriod: (period: string) => void;
}

const CampaignReportsFilters: React.FC<ICampaignReportsFiltersProps> = ({
    onCreateReport,
    onSelectPeriod,
}): JSX.Element => {
    const [name, setName] = useState<string>('');
    const [periodId, setPeriodId] = useState<number>(1);
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);

    const handlerSelectPeriod = (id: number): void => {
        setPeriodId(id);
        onSelectPeriod(id === 1 ? '7' : '30');
    };

    return (
        <div className={styles.campaignReportsFilters_root} style={isIPadSize ? { minWidth: 200, width: 500 } : null}>
            <div className={classnames(styles.flexColumn, styles.gap16)}>
                <div className={styles.header}>Конструктор отчетов</div>
                <div className={styles.headerHl} />
            </div>
            <div className={styles.title}>
                Для создания отчета выберите необходимые параметры:
            </div>
            {true && (
                <>
                    <CampaignReportsNameField
                        reportName={name}
                        setValue={setName}
                        isIPadSize={isIPadSize}
                    />
                    <CampaignReportsPeriodField
                        setValue={handlerSelectPeriod}
                        listValues={listPeriods}
                        selectItemId={periodId}
                    />
                    <div className={styles.buttonContainer}>
                        <Button
                            text="Создать отчёт"
                            type="default"
                            size="large"
                            disabled={!name}
                            onClick={() => onCreateReport(name)}
                        />
                        {/* <ButtonPrimary */}
                        {/*    onClick={() => onCreateReport(name)} */}
                        {/*    text="Создать отчёт" */}
                        {/*    disabled={!name} */}
                        {/*    style={{ width: 157, height: 48 }} */}
                        {/* /> */}
                    </div>
                </>
            )}
        </div>
    );
};

export default CampaignReportsFilters;
