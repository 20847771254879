import { IHistoryLinks } from '../../../services/history/types';

const convertToObject = (item: unknown): IHistoryLinks => {
    const result: IHistoryLinks = {} as IHistoryLinks;

    Object.keys(item).forEach((key: string) => {
        switch (key) {
            case 'id':
                result.id = item[key];
                break;
            case 'source':
                result.source = item[key];
                break;
            case 'content':
                result.content = item[key];
                break;
            case 'uid':
                result.uid = item[key];
                break;
            case 'analyze_time':
                result.analyzeTime = new Date(item[key]).getTime() / 1000;
                break;
            case 'date_end':
                result.dateEnd = new Date(item[key]).getTime() / 1000;
                result.dateEndStr = item[key];
                break;
            case 'date_start':
                result.dateStart = new Date(item[key]).getTime() / 1000;
                break;
            case 'categories':
                result.categories = item[key];
                break;
            case 'locations':
                result.locations = item[key];
                break;
            case 'category_ids':
                result.categoryIds = item[key];
                break;
            case 'location_ids':
                result.locationIds = item[key];
                break;
            default:
                break;
        }
    });

    return result;
};

export const convertApiToHistoryLinks = (data: unknown): IHistoryLinks[] => {
    const result: IHistoryLinks[] = [];
    if (!data) {
        return result;
    }

    if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
            if (item) {
                result.push(convertToObject(item));
            }
        });
    } else {
        result.push(convertToObject(data));
    }

    return result;
};
