/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback, useEffect, useMemo, useRef,
} from 'react';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IPropsControlPanel {
    onClickOnShrink: () => void,
    onClickOnEnlarge: () => void,
    onClickOnLeft: () => void,
    onClickOnRight: () => void,
    onResetZoom: () => void,
    setIsFullScreen: (value: boolean) => void
}
const ControlPanel: React.FC<IPropsControlPanel> = ({
    onClickOnShrink,
    onClickOnEnlarge,
    onClickOnLeft,
    onClickOnRight,
    onResetZoom,
    setIsFullScreen,
}) => {
    return (
        <div className={styles.rootControls}>
            <div className={styles.container}>
                <div className={styles.controlContainer}>
                    <div className={styles.control} style={{ marginRight: 16 }} onClick={onClickOnShrink}>
                        <div className={styles.minus} />
                    </div>
                    <div className={styles.control} onClick={onClickOnEnlarge}>
                        <SvgIcon id="RoundAdd_Icn_NoneFill" size={32} />
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.controlContainer}>
                    <div className={`${styles.control} ${styles.squery}`} style={{ marginRight: 16 }} onClick={onClickOnLeft}>
                        <SvgIcon id="Left_Control" size={17} />
                    </div>
                    <div className={`${styles.control} ${styles.squery}`} style={{ paddingLeft: 8 }} onClick={onClickOnRight}>
                        <SvgIcon id="Right_Control" size={17} />
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.controlContainer} onClick={onResetZoom}>
                    <div className={`${styles.control} ${styles.squery}`}>
                        <SvgIcon id="Reset_Control" size={24} />
                    </div>
                </div>
            </div>
            {/* <div className={styles.container} onClick={() => setIsFullScreen(true)}>
                <div className={styles.controlContainer}>
                    <div className={`${styles.control} ${styles.squery}`}>
                        <SvgIcon id="FullScreen_Control" size={21} />
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default React.memo(ControlPanel);
