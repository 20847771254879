import React from 'react';
import { Button } from '@shared/index';
import { useAppDispatch } from '@store/hooks';
import { campaignActions } from '@store/campaign/source';
import { ModalAnswerType } from '@store/campaign/source/types';

import styles from './styles.module.scss';

interface ICampaignQuestionModalProps {
    messages: string[];
    isOpen: boolean;
    onClose: () => void;
    submitBtnTagId: string;
    cancelBtnTagId: string;
}

const CampaignQuestionModal: React.FC<ICampaignQuestionModalProps> = ({
    messages, isOpen, onClose,
    submitBtnTagId, cancelBtnTagId,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const handlerOkClick = (): void => {
        dispatch(campaignActions.setModalAnswer(ModalAnswerType.accept));
        onClose();
    };
    const handlerCancelClick = (): void => {
        dispatch(campaignActions.setModalAnswer(ModalAnswerType.reject));
        onClose();
    };
    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modalWindow}>
                <div className={styles.contentContainer}>
                    <div className={styles.text}>
                        {
                            messages?.map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <span key={index}>{item}</span>
                            ))
                        }
                    </div>
                    <div className={styles.controlBlock}>
                        <Button
                            tagId={submitBtnTagId}
                            text="Да"
                            type="default"
                            size="medium"
                            onClick={handlerOkClick}
                        />
                        <Button
                            tagId={cancelBtnTagId}
                            text="Отмена"
                            type="secondary"
                            size="medium"
                            onClick={handlerCancelClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignQuestionModal;
