export interface ICampaignReportsListItem {
    id: number;
    dateCreate: string;
    reportId: string;
    reportPeriod: string;
    reportName: string;
    fileName: string;
    fileContent: Blob | null;
    downloadUrl: string;
    createTimeStamp?: number;
    generated: boolean
}

export enum CampaignReportFormat {
    pdf,
    pptx,
}
