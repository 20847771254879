/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback, useEffect, useMemo, useRef,
} from 'react';
import {
    Chart as ChartJS,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Pie, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import SvgIcon from '@components/SvgIcon';
import { pieLabels } from './LabelPlugin';
import styles from './styles.module.scss';
import ChartWordCloud from './WordCloud';
import MapRussia from '../MapRussia';
import RoseChart from './RoseChart';
import NewLineChart from './NewLineChart';

ChartJS.register(
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NewChart: React.FC<any> = ({
    dataFormat, isFullScreen, setIsFullScreen, onGetNextDate, onGetPrevData, legend,
}) => {
    const chartRef = useRef(null);
    const data = {
        ...dataFormat.data,
    };
    const handleMoveChart = (count: number) => {
        chartRef?.current.pan(count);
    };
    const handleChangeZoom = (count: number) => {
        chartRef?.current.zoom(count);
    };
    const handleClickOnLeft = () => {
        handleMoveChart(20);
    };
    const handleClickOnRight = () => {
        handleMoveChart(-20);
    };
    const handleClickOnShrink = () => {
        handleChangeZoom(0.9);
    };
    const handleClickOnEnlarge = () => {
        handleChangeZoom(1.1);
    };
    const handleResetZoom = () => {
        chartRef?.current?.resetZoom();
    };
    const handleKeyDown = (e: KeyboardEvent) => {
        if (!isFullScreen) return;
        if (e.key === 'Escape') {
            setIsFullScreen(false);
        }
    };
    useEffect(() => {
        if (isFullScreen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullScreen]);
    const onLoadNextData = useCallback((...args) => {
        return onGetNextDate(args[0]);
    }, [dataFormat]);
    const onLoadPrevData = useCallback((...args) => {
        return onGetPrevData(args[0]);
    }, [dataFormat]);
    const memoLineChart = useMemo(() => (
        <NewLineChart
            dataFormat={dataFormat}
            chartRef={chartRef}
            data={data}
            onLoadNextData={onLoadNextData}
            onLoadPrevData={onLoadPrevData}
        />
    ), [dataFormat,
        chartRef,
        isFullScreen,
        data]);
    if (dataFormat?.typeChart === 'BUBBLE_CHART') return <RoseChart data={dataFormat.data} />;
    if (dataFormat?.typeChart === 'TAG_CHART') return <ChartWordCloud data={dataFormat.data} />;
    if (dataFormat?.typeChart === 'MAP_CHART') {
        return (
            <div style={{ marginLeft: 20, marginTop: 16 }}><MapRussia sizeMap={780} data={dataFormat.data} checker="districtName" /></div>
        );
    }
    return (
        <>
            {
                isFullScreen && (
                    <div style={{
                        top: 0,
                        left: 0,
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        zIndex: 100,
                        background: 'white',
                    }}
                    >
                        <div style={{ display: 'flex', height: '100%' }}>
                            {memoLineChart}
                            {legend}
                        </div>
                        <div className={styles.rootControls} style={{ position: 'absolute', bottom: 32, zIndex: 101 }}>
                            <div className={styles.container}>
                                <div className={styles.controlContainer}>
                                    <div className={styles.control} style={{ marginRight: 16 }} onClick={handleClickOnShrink}>
                                        <div className={styles.minus} />
                                    </div>
                                    <div className={styles.control} onClick={handleClickOnEnlarge}>
                                        <SvgIcon id="RoundAdd_Icn_NoneFill" size={32} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.controlContainer}>
                                    <div className={`${styles.control} ${styles.squery}`} style={{ marginRight: 16 }} onClick={handleClickOnLeft}>
                                        <SvgIcon id="Left_Control" size={17} />
                                    </div>
                                    <div className={`${styles.control} ${styles.squery}`} style={{ paddingLeft: 8 }} onClick={handleClickOnRight}>
                                        <SvgIcon id="Right_Control" size={17} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.controlContainer} onClick={handleResetZoom}>
                                    <div className={`${styles.control} ${styles.squery}`}>
                                        <SvgIcon id="Reset_Control" size={24} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.container} onClick={() => setIsFullScreen(false)}>
                                <div className={styles.controlContainer}>
                                    <div className={`${styles.control} ${styles.squery}`}>
                                        <SvgIcon id="FullScreen_Control" size={21} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className={styles.btnResetZoom} onClick={handleResetZoom}>
                        Сбросить зум
                    </div>
                    <div className={styles.btnResetZoom} onClick={() => setIsFullScreen(true)}>
                        Развернуть
                    </div> */}
                        </div>
                    </div>
                )
            }
            <div
                className={`${styles.newChartDefaultContainer}
                ${styles[dataFormat?.typeChart]}
                ${styles.smallScreen}`}
            >
                {
                    dataFormat?.typeChart === 'PIE_CHART'
                        ? <Pie plugins={[pieLabels]} ref={chartRef} options={dataFormat?.options} data={data as any} />
                        : (
                            memoLineChart || null
                        )
                        // : <Line plugins={[zoomPlugin, pieLabels]} ref={chartRef} options={dataFormat?.options} data={data as any} />
                }

            </div>
            { dataFormat?.typeChart === 'TIME_CHART' && (
                <div className={styles.rootControls}>
                    <div className={styles.container}>
                        <div className={styles.controlContainer}>
                            <div className={styles.control} style={{ marginRight: 16 }} onClick={handleClickOnShrink}>
                                <div className={styles.minus} />
                            </div>
                            <div className={styles.control} onClick={handleClickOnEnlarge}>
                                <SvgIcon id="RoundAdd_Icn_NoneFill" size={32} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.controlContainer}>
                            <div className={`${styles.control} ${styles.squery}`} style={{ marginRight: 16 }} onClick={handleClickOnLeft}>
                                <SvgIcon id="Left_Control" size={17} />
                            </div>
                            <div className={`${styles.control} ${styles.squery}`} style={{ paddingLeft: 8 }} onClick={handleClickOnRight}>
                                <SvgIcon id="Right_Control" size={17} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.controlContainer} onClick={handleResetZoom}>
                            <div className={`${styles.control} ${styles.squery}`}>
                                <SvgIcon id="Reset_Control" size={24} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.container} onClick={() => setIsFullScreen(true)}>
                        <div className={styles.controlContainer}>
                            <div className={`${styles.control} ${styles.squery}`}>
                                <SvgIcon id="FullScreen_Control" size={21} />
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.btnResetZoom} onClick={handleResetZoom}>
                        Сбросить зум
                    </div>
                    <div className={styles.btnResetZoom} onClick={() => setIsFullScreen(true)}>
                        Развернуть
                    </div> */}
                </div>
            )}
        </>
    );
};

export default React.memo(NewChart, (next, prev) => {
    return false;
});
