/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React, {
    useRef, useState,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Collapse } from 'antd';
import { Sources } from '../dataSets';
import GraphChart from '../GraphChart';
import TooltipGraph from '../GraphChart/TooltipGraph';
import {
    ChartWithDescription, TitleChart,
} from '../../../style';
import FiltersChart from '../FiltersChart';

const { Panel } = Collapse;

export const Height = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #DFFCF7;
    border-radius: 6px;
`;
export const Low = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #FEF8E8;
    border-radius: 6px;
`;

const ChartGraphWitTooltipDemo = () => {
    const chartRef = useRef(null);
    const [isOk, setIsOk] = useState(true);
    const [isVk, setIsVk] = useState(true);
    const [isRss, setIsRss] = useState(true);
    const [isTg, setIsTg] = useState(true);
    const [isMainPlot, setIsMainPlot] = useState(true);
    const [isAltPlot, setIsAltPlot] = useState(true);
    const [isContractedOnly, setIsContractedOnly] = useState(false);
    const [percentMatch, setPercentMatch] = useState(0);
    const getListAcceptPlatforms = () => {
        const list = [];
        if (isOk) list.push('ok');
        if (isVk) list.push('vk');
        if (isTg) list.push('telegram');
        if (isRss) list.push('rss');
        return list;
    };
    const getTypesPlots = () => {
        const list = [];
        if (isMainPlot) list.push('main');
        if (isAltPlot) list.push('alt');
        return list;
    };
    const sourcesFilter = (source) => {
        const isCorrectPlatfrom = getListAcceptPlatforms().find((item) => item === source.platform);
        const currentPlotType = source.rating ? 'main' : 'alt';
        const isCorrectTypePlots = getTypesPlots().find((type) => type === currentPlotType);
        const isContracetdCheck = isContractedOnly ? source.contracted : true;
        const checkMatch = source.matchPercent >= percentMatch;
        if (isCorrectPlatfrom && isCorrectTypePlots && isContracetdCheck && checkMatch) return source;
        return false;
    };
    const getFiltredSources = (sources) => sources.filter(sourcesFilter);
    const getProbability = () => {
        const param = getFiltredSources(Sources)?.map((item) => {
            const momentHour = moment(item.date * 1000);
            return {
                y: +momentHour.format('H'),
                x: +moment(item.date * 1000).format('D'),
                content: item,
                r: 8,
            };
        });
        return param;
    };
    return (
        <Collapse>
            <Panel
                key="ChartPublicationDemo"
                header={<TitleChart style={{ marginBottom: '12px' }}>Публикации сюжета</TitleChart>}
            >
                <div>
                    <ChartWithDescription style={{ display: 'inline-flex ', position: 'relative', width: '100%' }}>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <TooltipGraph />
                            <GraphChart
                                ref={chartRef}
                                data={getProbability()}
                            />
                        </div>
                        <FiltersChart
                            isOk={isOk}
                            setIsOk={setIsOk}
                            isVk={isVk}
                            setIsVk={setIsVk}
                            isRss={isRss}
                            setIsRss={setIsRss}
                            isTg={isTg}
                            setIsTg={setIsTg}
                            isMainPlot={isMainPlot}
                            setIsMainPlot={setIsMainPlot}
                            isAltPlot={isAltPlot}
                            setIsAltPlot={setIsAltPlot}
                            isContractedOnly={isContractedOnly}
                            setIsContractedOnly={setIsContractedOnly}
                            percentMatch={percentMatch}
                            setPercentMatch={setPercentMatch}
                        />
                    </ChartWithDescription>
                </div>
            </Panel>
        </Collapse>
    );
};
export default ChartGraphWitTooltipDemo;
