export const dateTimeToTime = (value: string): string => {
    if (!value) {
        return '';
    }
    const buffer = value.split('T');
    return buffer?.length > 1 ? buffer[1].split(':').slice(0, 2).join(':') : '';
};

export const dateTimeToDate = (value: string): string => {
    if (!value) {
        return '';
    }
    const buffer = value.split('T');
    if (buffer?.length > 1) {
        // 2023-03-22
        const parts = buffer[0].split('-');
        return new Date(Number(parts[0]), Number(parts[1]), Number(parts[0])).toLocaleDateString('ru-RU');
    }
    return '';
};

// export default {
//     dateTimeToTime,
//     dateTimeToDate,
// };
