import React from 'react';
import { IProjectStatus } from '@services/projects/types';
import SvgIcon from '@components/SvgIcon';
import ProjectHistory from '@features/project/history';
import styles from './styles.module.scss';

interface IProps {
    status: IProjectStatus;
    projectId: number;
    onCloseProject: () => void;
}

const StatusInfo: React.FC<IProps> = ({ status, projectId, onCloseProject }): JSX.Element => (
    <div className={styles.statusInfo}>
        <div className={styles.statusInfo_head}>
            <span className={styles.statusInfo_title}>Статус проекта</span>
            <span className={styles.statusInfo_textStatus}>{status?.statusLabel ?? '-'}</span>
            {
                status?.status < 6
                && (
                    <div
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => null}
                        onClick={onCloseProject}
                        className={styles.statusInfo_btnCross}
                    >
                        <SvgIcon id="RedCross" size={14} />
                    </div>
                )
            }
        </div>
        <ProjectHistory projectId={projectId} />
    </div>
);

export default StatusInfo;
