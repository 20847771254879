/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { Checkbox } from 'antd';
import Path from '@shared/lib/paths';
import SvgIcon from '@components/SvgIcon';
import { ITableColumn } from '@entities/Table/types';
import { projectsListService } from '@services/projects';
import { IconButtonWithLabel, Table } from '@entities/index';
import { IProjectModel, IProjectUserDescriptionModel } from '@services/projects/types';
import DeleteModal from '@modals/DeleteModal';
import { SortOrder } from '@services/sources/types/models';
import { BlockSortAscDesc } from '@shared/index';
import styles from './styles.module.scss';

interface IPeriod {
    start: string | null,
    finish: string | null
}
interface IProjectWithPeriod extends IProjectModel {
    period: IPeriod,
    status?: {
        description: string,
        id: number,
        name: string,
        stage_id: number,
        stage: {
            id: number,
            description: string,
            name: string,
        }
    }
}
// eslint-disable-next-line arrow-body-style
const ProjectListPage: React.FC = (): JSX.Element => {
    const { data: listProjects, isLoading, refetch } = projectsListService.useGetAllProjectsQuery();
    const [isShowModalAcceptRemove, setShowModalAcceptRemove] = useState(false);
    const [idForRemove, setIdForRemove] = useState<number>(null);
    const [removeProject] = projectsListService.useLazyRemoveProjectQuery();
    const [accepted, setAccepted] = useState<string[]>([]);
    const [rows, setRows] = useState<IProjectWithPeriod[]>([]);
    const [oldRows, setOldRows] = useState<IProjectWithPeriod[]>([]);
    const [filters, setFilters] = useState([
        {
            id: 1,
            checked: false,
            label: 'Создан',
        },
        {
            id: 2,
            checked: false,
            label: 'В процессе',
        },
        {
            id: 3,
            checked: false,
            label: 'Завершен',
        },
    ]);
    const navigate = useNavigate();

    const handleSelectProject = (id) => {
        navigate(`/projects/${id}`);
    };
    const getDefaultProjects = useMemo((): IProjectWithPeriod[] => listProjects?.map((project) => ({
        ...project,
        period: {
            start: project?.start,
            finish: project?.finish,
        },
    })), [listProjects]);
    const handleRemove = () => {
        removeProject({ id: idForRemove }).finally(() => {
            refetch();
        });
    };
    useEffect(() => {
        const allFalse = filters.every((filter) => !filter.checked);
        if (rows.length) {
            setOldRows([...rows]);
        }
        if (allFalse) setRows([...oldRows]);
        else if (filters[0].checked) {
            setRows([...oldRows]);
        } else {
            setRows([]);
        }
    }, [filters]);
    useEffect(() => {
        setRows(listProjects?.map((project) => ({
            ...project,
            period: {
                start: project?.start,
                finish: project?.finish,
            },
        })));
    }, [listProjects]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateRows = (value: string) => {
        setRows(getDefaultProjects.filter((item) => item.name.includes(value)));
    };
    const handleSearch = (column, value) => {
        updateRows(value);
    };
    const handleSort = (column, value) => {
        const filtred = getDefaultProjects.sort((a, b) => {
            if (a[column?.dataIndex] > b[column?.dataIndex]) return 1;
            if (a[column?.dataIndex] < b[column?.dataIndex]) return -1;
            return 0;
        });
        if (column?.dataIndex === 'created') {
            setRows(value === 'desc' ? filtred.reverse() : filtred);
        } else { setRows(value === 'asc' ? filtred.reverse() : filtred); }
    };
    const getPeriod = (start, end) => {
        if (!start && !end) return 'Не определен';
        const startString = moment(start).format('DD.MM.YYYY');
        const endString = moment(end).format('DD.MM.YYYY');
        if (!start) return `Не определенна - ${endString}`;
        if (!end) return `${startString} - Не определенна`;
        return `${startString} - ${endString}`;
    };
    const getAuthorFullName = (firstName, lastName, midName) => {
        let fullName = '';
        if (firstName) fullName += `${firstName}`;
        if (lastName) fullName += ` ${lastName}`;
        if (midName) fullName += ` ${midName}`;
        return fullName || 'Неизвестно';
    };
    const changeAcceptedStatus = (filter) => {
        setAccepted((prev) => {
            const isAccepted = prev.some((name) => filter === name);
            if (isAccepted) return prev.filter((name) => name !== filter);
            return [...prev, filter];
        });
    };
    const getListAccetedCheckBox = useMemo(() => {
        if (!rows?.length) return [];
        const list = [...new Set(rows?.map((item) => {
            if (item?.status?.name) return item?.status?.name;
            if (item.finish) return 'Завершен';
            return 'Создан';
        }))];
        if (!list.length) return null;
        return (
            list.map((filter) => (
                <div key={`${filter}_filterForExceptOtherStatused`} className={styles.filterStatus_item}>
                    <Checkbox
                        checked={accepted.some((name) => filter === name)}
                        onChange={() => changeAcceptedStatus(filter)}
                    />
                    <div style={{ marginLeft: 8 }}>
                        {filter}
                    </div>
                </div>
            ))
        );
    }, [rows, listProjects, accepted]);
    const getRows = useMemo(() => {
        if (!accepted.length) return rows;
        return rows.filter((item) => accepted.some((accept) => {
            let name;
            if (item?.status?.name) name = item.status.name;
            else if (item.finish) name = 'Завершен';
            else name = 'Создан';
            return accept === name;
        }));
    }, [rows, listProjects, accepted]);
    const sortFilter = (status: SortOrder) => {
        if (status === SortOrder.asc) {
            setRows((prev) => [...prev].sort((a, b) => (a?.status?.stage?.id || 1) - (b?.status?.stage?.id || 1)));
            return;
        }
        setRows((prev) => [...prev].sort((a, b) => (b?.status?.stage?.id || 1) - (a?.status?.stage?.id || 1)));
    };
    const columns: ITableColumn<IProjectWithPeriod>[] = useMemo(() => [
        {
            id: 1,
            title: null,
            dataIndex: 'id',
            width: 40,
            isIndex: true,
        },
        {
            id: 2,
            title: 'Проект',
            dataIndex: 'name',
            width: 190,
            sortable: true,
            sortType: 'text',
            searchable: true,
            render: (value: string, row: IProjectWithPeriod) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSelectProject(row?.id)}
                >
                    {value}
                </div>
            ),
        },
        {
            id: 3,
            title: 'Дата создания',
            dataIndex: 'created',
            width: 170,
            sortable: true,
            render: (created: string) => (
                <span>{moment(created).format('DD.MM.YYYY')}</span>
            ),
        },
        {
            id: 4,
            title: 'Период',
            dataIndex: 'period',
            width: 230,
            sortable: false,
            render: (period: IPeriod) => (
                <span>{getPeriod(period?.start, period?.finish)}</span>
            ),
        },
        {
            id: 5,
            title: 'Автор',
            dataIndex: 'users',
            render: (customer: IProjectUserDescriptionModel[] | null) => (
                <span>{getAuthorFullName(customer[0]?.first_name, customer[0]?.last_name, customer[0]?.middle_name)}</span>
            ),
            width: 210,
            sortable: false,
        },
        {
            id: 6,
            title: 'Сумма',
            dataIndex: 'customer',
            width: 180,
            sortable: false,
            render: () => <div>Не определена</div>,
        },
        {
            id: 7,
            title: 'Статус',
            dataIndex: 'customer',
            width: 200,
            render: (_, record) => {
                if (record?.status?.name) return <div>{record?.status?.name}</div>;
                if (record.finish) return <div>Завершен</div>;
                return <div>Создан</div>;
            },
            renderFilter: (record) => (
                <div className={styles.filter_status}>
                    <BlockSortAscDesc onSort={sortFilter} />
                    <div className={styles.filterStatus_list}>
                        {getListAccetedCheckBox}
                    </div>
                </div>
            ),
        },
        {
            id: 8,
            title: 'Действия',
            dataIndex: 'id',
            width: 80,
            render: (id) => (
                <div
                    className={styles.btnRemove}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIdForRemove(Number(id));
                        setShowModalAcceptRemove(true);
                    }}
                >
                    <SvgIcon size={24} id="RemoveIcon" />
                </div>
            ),
        },
    ], [rows, listProjects, getListAccetedCheckBox, accepted]);
    const handlerCreateProject = (): void => {
        navigate(`/${Path.Projects}/${Path.ProjectCreate}`);
    };
    useEffect(() => { refetch(); }, []);
    const clearFilter = (): void => {
        setFilters((prev) => prev.map((item) => ({ ...item, checked: false })));
    };

    return (
        <div className={styles.projectList}>
            <DeleteModal
                isOpen={isShowModalAcceptRemove}
                onClose={() => setShowModalAcceptRemove(false)}
                textContent="Вы уверенны, что хотите удалить проект?"
                title="Удаление проекта"
                onApply={handleRemove}
            />
            <div style={{ marginBottom: 16 }}>
                <IconButtonWithLabel iconSize={31} iconId="RoundAdd_Icn" label="Добавить проект" onClick={handlerCreateProject} />
            </div>
            <Table
                columns={columns}
                rows={getRows}
                isLoading={isLoading}
                onSearch={handleSearch}
                onSort={handleSort}
                filter={{
                    isFiltered: filters.filter((item) => item.checked).length >= 1,
                    clearFilter,
                }}
                className={styles.table}
            />
        </div>
    );
};

export default ProjectListPage;
