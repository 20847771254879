import Skeleton from 'react-loading-skeleton';
import OkIcon from '../../../assets/images/sourceTypes/ok.svg';
import RssIcon from '../../../assets/images/sourceTypes/rss.svg';
import TgIcon from '../../../assets/images/sourceTypes/tg.svg';
import VkIcon from '../../../assets/images/sourceTypes/vk.svg';
import ZenIcon from '../../../assets/images/sourceTypes/zen.svg';
import TagCrossIcon from '../../../assets/images/tagCross.svg';
import { IFavoriteSourceModel } from '../../../services/campaigns/favorite-sources/types';
import styles from './styles.module.scss';

const icons = new Map([
    [1, OkIcon],
    [2, VkIcon],
    [3, TgIcon],
    [4, ZenIcon],
    [5, RssIcon],
]);

interface IProps {
    sources: IFavoriteSourceModel[] | undefined;
    isLoading: boolean;
    deleteSource: (id: number) => void;
    isError: boolean;
}

const UserProfileFavoriteSources: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        sources, isLoading, deleteSource, isError,
    } = props;

    return (
        <div className={styles.container}>
            {isLoading && <Skeleton height={40} />}
            {!isLoading
                && !isError
                && sources.map((source) => (
                    <div className={styles.item} key={source.id}>
                        <img
                            src={icons.get(source.source.type_id)}
                            alt="source icon"
                        />
                        <p>{source.source.title}</p>
                        <button
                            type="button"
                            onClick={() => deleteSource(source.id)}
                        >
                            <img src={TagCrossIcon} alt="TagCrossIcon" />
                        </button>
                    </div>
                ))}
            {!isLoading && sources?.length < 1 && (
                <p className={styles.emptyText}>Нет избранных источников</p>
            )}
            {!isLoading && isError && (
                <p className={styles.emptyText}>
                    Произошла ошибка при получении данных
                </p>
            )}
        </div>
    );
};
export default UserProfileFavoriteSources;
