/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { IUserFilter } from '@services/campaigns/userFilters/types';
import MyCompaignFilters from '../../CampaingCategories';
import FiltersWithSettings from './FiltersWithSettings';
import { TTypeModal } from './types';
import ModalWrapper from './ModalWrapper';

interface IPropsUserFilters {
    campaingId: number,
    userFilters: IUserFilter[],
    onChangeFilters: (filter: IUserFilter) => void
    handleSelectFilter: (id: number) => void
    handleRemoveFilter: (id: number) => void
}
const UserFilters: React.FC<IPropsUserFilters> = ({
    campaingId,
    userFilters,
    onChangeFilters,
    handleSelectFilter,
    handleRemoveFilter,
}): JSX.Element => {
    const [modal, setModal] = useState(null);

    const openModal = (id: number, type: TTypeModal) => {
        setModal({ id, type });
    };

    const handleOpenModalChange = (id: number) => {
        openModal(id, 'view');
    };
    const handleopenModalInfo = (id: number) => {
        openModal(id, 'change');
    };
    const handleClickControl = () => {
        setModal({ id: null, type: 'change' });
    };
    return (
        <>
            <ModalWrapper
                isOpen={!!modal}
                onClose={() => setModal(null)}
                id={modal?.id}
                campaignId={campaingId}
                typeModal={modal?.type}
                onChangeFilters={onChangeFilters}
            />
            <MyCompaignFilters
                withControls
                seporatorLine
                onClickControl={handleClickControl}
                isExclude={false}
                changeTypeFiltersApply={() => null}
                // todo exclude/include
            >
                <FiltersWithSettings
                    userFilters={userFilters}
                    onSelectFilter={handleSelectFilter}
                    openModalChange={handleOpenModalChange}
                    openModalInfo={handleopenModalInfo}
                    removeFilter={handleRemoveFilter}
                />
            </MyCompaignFilters>
        </>
    );
};

export default UserFilters;
