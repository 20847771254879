import { IDictionary, IPeriod, IPeriodState } from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IPeriodState = {
    entities: {},
    ids: [],
};

const { actions: infoBasketPeriodActions, reducer } = createSlice({
    name: 'infoBasketPeriod',
    initialState,
    reducers: {
        insertAll: (
            state: IPeriodState,
            { payload }: PayloadAction<unknown>,
        ) => {
            const buffer = payload as IPeriod[];
            if (state.ids.length > 0) {
                return { ...state };
            }
            const localState = {
                entities: {
                    ...state.entities,
                    ...buffer.reduce(
                        (acc: IDictionary<IPeriod>, curr: IPeriod) => {
                            acc[curr.id] = curr;
                            return acc;
                        },
                        {} as IDictionary<IPeriod>,
                    ),
                },
                ids: [...state.ids, ...buffer.map(({ id }) => id)],
            } as IPeriodState;
            return { ...localState };
        },
        onSelect: (
            state: IPeriodState,
            { payload }: PayloadAction<string>,
        ) => {
            state.entities[payload].isSelected = true;
        },
        onDeselect: (
            state: IPeriodState,
            { payload }: PayloadAction<string[]>,
        ) => {
            payload.forEach((id) => {
                state.entities[id].isSelected = false;
            });
        },
        deselectAll: (state: IPeriodState): void => {
            state.ids.forEach((id) => {
                state.entities[id].isSelected = false;
            });
        },
    },
    // extraReducers: {
    //     [HYDRATE]: (state: IPeriodState, action: any) => {
    //         if (!action.payload.infoBasketPeriod.ids) {
    //             return state;
    //         }
    //         return ({ ...action.payload.infoBasketPeriod });
    //     },
    // },
});

export { infoBasketPeriodActions };
export default reducer;
