import React, { useEffect, useMemo, useState } from 'react';
import { sourcesAdvancedFiltersRtkQService, sourcesRtkQService } from '@services/sources';
import { Alert } from 'antd';
import Skeleton from 'react-loading-skeleton';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import SvgIcon from '@components/SvgIcon';
import sourceMaps from '@app/constants/sourceMaps';
import {
    IEntity, INumbersInterval, IPlatform, IQPSourcesWithSortingAndFiltering, SortOrder,
} from '@services/sources/types';
import { categoriesRtkQService } from '@services/categories';
import { Button } from '@shared/index';
import { sourceListActions } from '@store/source/list';
import { useAppDispatch } from '@store/hooks';
import styles from './styles.module.scss';

interface IProps {
    projectId: number;
    platformsFromApi: IEntity[];
    visible: boolean;
    onCancel: () => void;
    onRepeatSearch: () => void;
}

type Filter = {
    [key: string]: (string | number)[];
};

export type RangeFilter = {
    [key: string]: INumbersInterval;
};

type TPlatformIcons = { id: number, name: string, icon: string };
const initialStatePlatforms: TPlatformIcons[] = [];
const getStatePlatforms = (platforms: IEntity[], platformsMap: Map<number, IPlatform>): TPlatformIcons[] => (
    initialStatePlatforms?.length < 1
        ? (
            platforms?.map((item) => (platformsMap.get(item.id)
                ? {
                    id: item.id,
                    name: platformsMap.get(item.id).title,
                    icon: platformsMap.get(item.id).icon,
                }
                : {
                    id: item.id,
                    name: item.title,
                    icon: null,
                }))
        )
        : initialStatePlatforms
);

const SavedFilters: React.FC<IProps> = ({
    projectId,
    platformsFromApi,
    visible,
    onCancel,
    onRepeatSearch,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const {
        data: categories,
    } = categoriesRtkQService.useGetCategoriesQuery();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [platforms, setPlatforms] = useState<TPlatformIcons[]>(
        () => getStatePlatforms(platformsFromApi, sourceMaps.platformByIdMap),
    );

    const [
        getSavedFilters,
        {
            data,
            isLoading: isLoadingFilters,
            isFetching: isFetchingFilters,
            isError,
            error,
        },
    ] = sourcesAdvancedFiltersRtkQService.useLazyGetLoadSavedFiltersQuery();

    const [
        getSources,
        {
            data: sources,
        },
    ] = sourcesRtkQService.useLazyPostLoadSourcesInfinityQuery();

    const isLoading = useMemo<boolean>(() => (
        isLoadingFilters || isFetchingFilters
    ), [isLoadingFilters, isFetchingFilters]);

    const errorMsg = useMemo<string>(() => (
        ((error as FetchBaseQueryError)?.data as { detail: string })?.detail ?? ''
    ), [error]);

    const authors = useMemo(() => (
        sources?.sources?.map((item) => (item?.author as { id: number, title: string }))
    ), [sources]);
    const platformsForUI = useMemo(() => (
        platforms?.filter((item) => data?.type_id.includes(Number(item?.id)))
    ), [data, platforms]);
    const categoriesForUI = useMemo(() => (
        categories?.filter((item) => data?.category_id.includes(Number(item?.id)))
    ), [data, categories]);
    const subscribers = useMemo(() => data?.subscribers && (
        <div className={styles.range_row}>
            от
            <span className={styles.range_item}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {data?.subscribers?.from_.toLocaleString('ru-RU')}
            </span>
            до
            <span className={styles.range_item}>

                {/* eslint-disable-next-line no-underscore-dangle */}
                {data?.subscribers?.to_.toLocaleString('ru-RU')}
            </span>
        </div>
    ), [data]);
    const er = useMemo(() => data?.er_avg && (
        <div className={styles.range_row}>
            от
            <span className={styles.range_item}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {data?.er_avg?.from_.toLocaleString('ru-RU')}
                {' '}
                %
            </span>
            до
            <span className={styles.range_item}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {data?.er_avg?.to_.toLocaleString('ru-RU')}
                {' '}
                %
            </span>
        </div>
    ), [data]);
    const views = useMemo(() => data?.views_avg && (
        <div className={styles.range_row}>
            от
            <span className={styles.range_item}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {data?.views_avg?.from_.toLocaleString('ru-RU')}
            </span>
            до
            <span className={styles.range_item}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {data?.views_avg?.to_.toLocaleString('ru-RU')}
            </span>
        </div>
    ), [data]);

    const noData = useMemo<boolean>(() => (
        !authors?.length
        && !platformsForUI?.length
        && !categoriesForUI?.length
        && !data?.views_avg
        && !data?.subscribers
        && !data?.er_avg
    ), [categoriesForUI, platformsForUI, authors]);

    const handlerRepeatSearch = (): void => {
        const rangeParams: RangeFilter = {};
        const filterParams: Filter = {};
        if (data?.views_avg) {
            // eslint-disable-next-line no-underscore-dangle
            rangeParams.views_avg = { from: data.views_avg.from_, to: data.views_avg.to_ };
        }
        if (data?.subscribers) {
            // eslint-disable-next-line no-underscore-dangle
            rangeParams.subscribers = { from: data.subscribers.from_, to: data.subscribers.to_ };
        }
        if (data?.er_avg) {
            // eslint-disable-next-line no-underscore-dangle
            rangeParams.er_avg = { from: data.er_avg.from_, to: data.er_avg.to_ };
        }
        if (data?.author_id?.length > 0) {
            filterParams.author_id = data?.author_id;
        }
        if (data?.type_id?.length > 0) {
            filterParams.type_id = data?.type_id;
        }
        if (data?.category_id?.length > 0) {
            filterParams.category_id = data?.category_id;
        }
        const filtersForSources = {
            filterParams,
            rangeParams,
            page: 1,
            limit: 15,
            orderBy: 'subscribers',
            orderDirection: SortOrder.desc,
        } as IQPSourcesWithSortingAndFiltering;
        dispatch(sourceListActions.setFilters(filtersForSources));
        onRepeatSearch();
        onCancel();
    };

    useEffect(() => {
        if (visible && projectId > 0) {
            getSavedFilters({ projectId });
        }
    }, [projectId, visible]);

    useEffect(() => {
        if (data?.author_id?.length > 0) {
            getSources({
                filterParams: { author_id: data.author_id },
                page: 1,
                limit: data.author_id.length + 10,
                orderBy: 'subscribers',
                orderDirection: SortOrder.desc,
                paginationToken: null,
                rangeParams: null,
            } as IQPSourcesWithSortingAndFiltering);
        }
    }, [data]);

    return (
        <div className={styles.savedFilters_wrapper}>
            {
                isLoading && (
                    <Skeleton count={8} height={90} />
                )
            }
            {
                !noData && (
                    <div className={styles.content}>
                        {
                            platformsForUI?.length > 0 && (
                                <div className={styles.platform}>
                                    <span className={styles.platform_title}>Платформы</span>
                                    <div className={styles.platform_list}>
                                        {
                                            platformsForUI?.map((item) => (
                                                <div key={item.name} className={styles.platform_item}>
                                                    <SvgIcon id={sourceMaps.platformsIcon.get(item.icon)} size={24} />
                                                    <span className={styles.platform_name}>{item.name}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            authors?.length > 0 && (
                                <div className={styles.categories}>
                                    <span className={styles.categories_title}>Авторы</span>
                                    <div className={styles.categories_list}>
                                        {authors?.map((author) => (
                                            <span key={author.id} className={styles.categories_item}>{author.title}</span>
                                        ))}
                                    </div>
                                </div>
                            )
                        }
                        {
                            views && (
                                <div className={styles.range}>
                                    <span className={styles.range_title}>Просмотры</span>
                                    {views}
                                </div>
                            )
                        }
                        {
                            categoriesForUI?.length > 0 && (
                                <div className={styles.categories}>
                                    <span className={styles.categories_title}>Категории</span>
                                    <div className={styles.categories_list}>
                                        {categoriesForUI?.map((category) => (
                                            <span key={category.id} className={styles.categories_item}>{category.mainCat}</span>
                                        ))}
                                    </div>
                                </div>
                            )
                        }
                        {
                            subscribers && (
                                <div className={styles.range}>
                                    <span className={styles.range_title}>Подписчики</span>
                                    {subscribers}
                                </div>
                            )
                        }
                        {
                            er && (
                                <div className={styles.range}>
                                    <span className={styles.range_title}>ER</span>
                                    {er}
                                </div>
                            )
                        }
                    </div>
                )
            }
            {!isLoading && !isError && noData && (
                <Alert
                    type="info"
                    message="Нет записей."
                />
            )}
            {isError && !isLoading && errorMsg && (
                <Alert
                    type="error"
                    message={errorMsg}
                />
            )}
            <div className={styles.controls}>
                <Button text="Отменить" type="secondary" size="large" onClick={onCancel} />
                <Button text="Повторить поиск" type="default" size="large" onClick={handlerRepeatSearch} />
            </div>
        </div>
    );
};

export default SavedFilters;
