/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { TChangedFilter } from '../../../shared/hooks/campaign/useCategories/types';
import { IApplyFilter, ListCategory, IFilter } from '../../../sections/MyCampaignContainer/CampaingTypes';
import MyCompaignFilters from '../../CampaingCategories';
import MyCampaignCategoriesSkeleton from './Skeleton';
import styles from './styles.module.scss';

interface IFiltersProps {
    disabledSeporator?: boolean,
    filters: ListCategory,
    listExclude: string[],
    listOpenFilters: string[],
    isLoading: boolean,
    isIPad: boolean,
    upChild?: JSX.Element,
    downChild?: JSX.Element,
    onUpdateApplyFilters?: (list: IApplyFilter, type: TChangedFilter) => void,
    onUpdateOpenFilters: (name: string) => void,
    onConfirm?: () =>void,
    changeTypeFiltersApply: (type: 1 | 2, name: string) => void
}
const TrackingLeftBar: React.FC<IFiltersProps> = ({
    disabledSeporator,
    filters,
    listExclude,
    listOpenFilters,
    isLoading,
    isIPad,
    upChild,
    downChild,
    onUpdateApplyFilters,
    onUpdateOpenFilters,
    onConfirm,
    changeTypeFiltersApply,
}): JSX.Element => {
    if (isLoading) {
        return (
            <div className={styles.campaignFiltersContent}>
                <MyCampaignCategoriesSkeleton />
            </div>
        );
    }
    const updateCategories = useCallback((categoryName: string, filterName: string, type: string) => {
        onUpdateApplyFilters({
            filters_category: categoryName,
            filter_name: filterName,
        }, type as any || 'include');
    }, [listExclude]);
    const handlerOpenFilter = useCallback((name) => {
        onUpdateOpenFilters(name);
    }, [listExclude]);
    const getIsOpenFilter = (name: string): boolean => {
        const isOpen = listOpenFilters.some((filter) => filter === name);
        return isOpen;
    };
    const getCounterIncWithEx = (filtersCount: IFilter[]) => filtersCount.reduce((acc, item) => {
        let newAcc = acc;
        if (item.include) newAcc += 1;
        if (item.exclude) newAcc += 1;
        return newAcc;
    }, 0);
    if (!filters?.length && !isLoading) return null;
    return (
        <div className={styles.campaignFiltersContent}>
            {upChild}
            {filters.map((category, index) => (
                <div key={`categoryContainer_${category.filters_category}_${getCounterIncWithEx(category.filters)}`}>
                    <MyCompaignFilters
                        disabledSeporator
                        isExclude={listExclude.some((name) => category.filters_category)}
                        category={category}
                        seporatorLine={disabledSeporator ? false : filters.length !== index + 1}
                        updateCategories={updateCategories}
                        isOpen={getIsOpenFilter(category.filters_category)}
                        onOpen={handlerOpenFilter}
                        changeTypeFiltersApply={(type) => changeTypeFiltersApply(type, category.filters_category)}
                    />
                </div>
            ))}
            {downChild}
        </div>
    );
};

export default React.memo(TrackingLeftBar);
