import React from 'react';
import {
    BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import CampaignNotificationContainer from '@containers/campaign/Notification';
import CampaignComparisonsListContainer from '@widgets/campaign/comparison/List';
import ProtectedLayout from '@routes/protected-layout/ProtectedLayout';
import CampaignUserRightsContainer from '@containers/campaign/UserRights';
import CampaignsListContainer from '@containers/campaign/List/CampaignsList';
import CampaignCreateContainer from '@containers/campaign/Create/CampaignCreate';
import CampaignUpdateContainer from '@containers/campaign/Update/CampaignUpdate';
import CampaignNewCharts from '@containers/CampaignNewCharts';
import CampaignReportsContainer from '@containers/campaign/Reports';
import CampaignComparisonResultContainer from '@containers/campaign/Comparison/Result';
import Path from '@shared/lib/paths';
import ProjectPage from '@sections/Project';
import SourcesListSection from '@sections/Source';
import FavoritSources from '@sections/FavoritSources';
import RestorePassword from './RestorePassword';
import RequestAccessPage from './RequestAccess/RequestAccessPage';
import UserProfilePage from './UserProfile';
import UserProfileFavoritesPage from './UserProfileFavorites';
import SourcesPage from './Sources';
import SourcePage from './Source';
import SettingsPage from './Settings';
import CampaignPage from './Сampaign';
import MyCampaignPage from './MyCampaignPage';
import CampaignReportsPage from './CampaignReports';
import CampaignTagsPage from './CampaignTags';
import CampaignAuthorsPage from './CampaignAuthors';
import CampaignBasketPage from './CampaignBasket';
import CampaignDashboardPage from './CampaignDashboard';
import AuthPage from './Auth';
import NotFoundPage from './NotFound';
import NonAuthLayout from '../routes/non-auth-layout/NonAuthLayout';
import ChartGraphDemo from './ChartGraphDemo';
import ProjectsPage from './Projects';
import ProjectListPage from './ProjectList';
import SourcesLists from './SourcesLists';

const RouterLayout: React.FC = (): JSX.Element => (
    <BrowserRouter>
        <Routes>
            <Route path={Path.ChartGraphDemo} element={<ChartGraphDemo />} />
            <Route element={<NonAuthLayout />}>
                <Route path="*" element={<NotFoundPage />} />
                <Route path={Path.App} element={<AuthPage />} />
                <Route path={Path.Auth} element={<AuthPage />} />
                <Route path={Path.RestorePassword} element={<RestorePassword />}>
                    <Route path="*" element={<RestorePassword />} />
                </Route>
                <Route path={Path.RequestAccess} element={<RequestAccessPage />} />
            </Route>
            <Route element={<ProtectedLayout />}>
                <Route path={Path.UserProfile} element={<UserProfilePage />} />
                <Route path={Path.UserProfileFavorites} element={<UserProfileFavoritesPage />} />
                <Route path={`/${Path.Sources}/*`} element={<SourcesPage />}>
                    <Route path={Path.SourcesList} element={<SourcesListSection />} />
                    <Route path={Path.SourcesFavorite} element={<FavoritSources />} />
                    <Route path={Path.SourcesRoll} element={<SourcesLists />} />
                    {/* <Route path={Path.SourcesRoll} element={<SourcesLists />} /> */}
                    <Route path=":Id" element={<SourcePage />} />
                </Route>
                <Route path={`/${Path.Settings}/*`} element={<SettingsPage />}>
                    <Route path={Path.Notification} element={<CampaignNotificationContainer />} />
                    <Route path={Path.UserRights} element={<CampaignUserRightsContainer />} />
                </Route>
                <Route path={`/${Path.Campaign}/*`} element={<CampaignPage />}>
                    <Route index element={<Navigate to={Path.CampaignList} replace />} />
                    <Route path={Path.CampaignList} element={<CampaignsListContainer />} />
                    <Route path={Path.CampaignCreate} element={<CampaignCreateContainer />} />
                    <Route path={`${Path.CampaignUpdate}/:Id`} element={<CampaignUpdateContainer />} />
                    <Route path={`:${Path.Campaign}Id`} element={<MyCampaignPage />} />
                    <Route path={`${Path.Charts}/:Id`} element={<CampaignNewCharts />} />
                    <Route path={`new-${Path.Charts}/:Id`} element={<CampaignNewCharts />} />
                    <Route path={`${Path.SavedCharts}/:Id`} element={<CampaignNewCharts isSaved />} />
                    <Route path={`${Path.Reports}/prev/:Id`} element={<CampaignReportsContainer />} />
                    <Route path={`${Path.Reports}/:Id`} element={<CampaignReportsPage />} />
                    <Route path={Path.CampaignComparison} element={<CampaignComparisonResultContainer />} />
                    {/* <Route path={`${Path.SearchRequest}/:Id`} element={<CampaignSearchRequestContainer />} /> */}
                    <Route path={`${Path.CampaignComparisonsList}`} element={<CampaignComparisonsListContainer />} />
                    <Route path={`${Path.CampaignComparisonsList}/:Id`} element={<CampaignComparisonsListContainer />} />
                    <Route path={`${Path.Tags}/:Id`} element={<CampaignTagsPage />} />
                    <Route path={`${Path.Authors}/:Id`} element={<CampaignAuthorsPage />} />
                    <Route path={`${Path.CampaignBasket}/:Id`} element={<CampaignBasketPage />} />
                </Route>
                <Route path={`/${Path.Projects}/*`} element={<ProjectsPage />}>
                    <Route path={Path.ProjectList} element={<ProjectListPage />} />
                    <Route path=":Id" element={<ProjectPage />} />
                    <Route path={Path.ProjectCreate} element={<ProjectPage />} />
                </Route>
                <Route path={Path.CampaignDashboard} element={<CampaignDashboardPage />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

export default RouterLayout;
