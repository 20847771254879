import IconLeftChart from '../../images/frontPageLeftChart.svg';
import IconRightChart from '../../images/frontPageRightChart.svg';
import IconLogo from '../../images/logo.svg';
import PptxSlide from '../../PptxSlide';
import { ICampaignPPTXFrontPageProps } from './types';

class CampaignPPTXFrontPage extends PptxSlide {
    render(props: ICampaignPPTXFrontPageProps) {
        this.addShape('rect', {
            x: 0,
            y: 0,
            w: '100%',
            h: 85,
            fill: { color: 'EDFFFC' },
        });
        this.slide.addImage({
            path: IconLogo,
            x: this.getInches(32),
            y: this.getInches(34),
            w: this.getInches(253),
            h: this.getInches(28.5),
        });
        this.slide.addImage({
            path: IconLeftChart,
            x: 0,
            y: props.layoutHeight - this.getInches(141),
            w: this.getInches(385),
            h: this.getInches(141),
        });
        this.slide.addImage({
            path: IconRightChart,
            x: props.layoutWidth - this.getInches(145),
            y: props.layoutHeight - this.getInches(376),
            w: this.getInches(145),
            h: this.getInches(376),
        });
        this.addText(props.title, {
            x: 0,
            y: 220,
            h: 56,
            w: '100%',
            fontSize: 40,
            align: 'center',
            color: '262626',
            fontFace: 'Montserrat SemiBold',
        });

        this.addText(props.date, {
            x: 0,
            y: 291,
            w: '100%',
            fontSize: 32,
            align: 'center',
            color: '000000',
            fontFace: 'Montserrat',
            h: this.getInches(45),
        });

        return this.slide;
    }
}

export default CampaignPPTXFrontPage;
