/* eslint-disable class-methods-use-this */
import pptxgen from 'pptxgenjs';
import IconOk from './images/ok.svg';
import IconRss from './images/rss.svg';
import IconTg from './images/tg.svg';
import IconVk from './images/vk.svg';
import IconDzen from './images/dzen.svg';
import IconRutube from './images/rutube.svg';
import IconYoutube from './images/youtube.svg';

import { ICampaignPPTXPageProps } from './types';

class PptxSlide {
    instance: pptxgen;

    slide: pptxgen.Slide;

    constructor(instance: pptxgen) {
        this.instance = instance;
        this.slide = this.instance.addSlide();
    }

    renderHeader(props: ICampaignPPTXPageProps): void {
        this.addShape('rect', {
            x: 0,
            y: 0,
            w: '100%',
            h: 73,
            fill: { color: 'EDFFFC' },
        });
        this.addText(props.header.number, {
            fontSize: 16,
            color: '0EBFA1',
            fontFace: 'Montserrat Bold',
            x: 24,
            y: 32,
            h: 21,
        });
        this.addText(props.header.pageName.toUpperCase(), {
            fontSize: 16,
            color: '0EBFA1',
            fontFace: 'Montserrat Medium',
            x: 53,
            y: 32,
            h: 21,
        });
        this.addText(props.reportName, {
            align: 'right',
            margin: [0, 24, 0, 0],
            y: 23,
            w: '100%',
            fontSize: 12,
            color: '787E93',
            fontFace: 'Montserrat Medium',
            h: 13,
        });
        this.addText(props.date, {
            align: 'right',
            margin: [0, 24, 0, 0],
            w: '100%',
            y: 36,
            fontSize: 12,
            color: '787E93',
            fontFace: 'Montserrat',
            h: 20,
        });
    }

    addText(text: string, args: pptxgen.TextPropsOptions): void {
        const align = args.align ?? 'left';
        const shift = (align === 'left' && !args.shape) ? 8 : 0;
        const options = {
            ...args,
            x: Number.isInteger(args.x) ? this.getInches(Number(args.x) - shift) : args.x,
            y: Number.isInteger(args.y) ? this.getInches(Number(args.y)) : args.y,
            w: Number.isInteger(args.w) ? this.getInches(Number(args.w)) : args.w,
            h: Number.isInteger(args.h) ? this.getInches(Number(args.h)) : args.h,
            fontSize: this.convertPixelToPoints(args.fontSize),
        };

        this.slide.addText(text, options);
    }

    addShape(shape: pptxgen.SHAPE_NAME, args: pptxgen.ShapeProps): void {
        const options = {
            ...args,
            x: Number.isInteger(args.x) ? this.getInches(Number(args.x)) : args.x,
            y: Number.isInteger(args.y) ? this.getInches(Number(args.y)) : args.y,
            w: Number.isInteger(args.w) ? this.getInches(Number(args.w)) : args.w,
            h: Number.isInteger(args.h) ? this.getInches(Number(args.h)) : args.h,
            rectRadius: args.rectRadius ? this.getInches(args.rectRadius) : 0,
        };
        this.slide.addShape(shape, options);
    }

    getPlatformIcon(value: string | number): string | null {
        const val = String(value).toLowerCase();
        if (val === 'tg') return IconTg;
        if (val === 'vk') return IconVk;
        if (val === 'ok') return IconOk;
        if (val === 'rss') return IconRss;
        if (val === 'dzen') return IconDzen;
        if (val === 'youtube') return IconYoutube;
        if (val === 'rutube') return IconRutube;
        return null;
    }

    getPlatformText(value: string | number): string | null {
        const val = String(value).toLowerCase();
        if (val === 'tg') return 'Telegram';
        if (val === 'vk') return 'Vkontakte';
        if (val === 'ok') return 'Ok.ru';
        if (val === 'rss') return 'RSS';
        if (val === 'dzen') return 'Dzen';
        if (val === 'youtube') return 'Youtube';
        if (val === 'rutube') return 'Rutube';
        return null;
    }

    addTitle(title: string, x: number, y: number, w: number = 400): void {
        this.addShape('rect', {
            w,
            h: 40,
            fill: { color: 'EDFFFC' },
            x,
            y,
        });

        this.addText(title, {
            color: '787E93',
            fontFace: 'Montserrat SemiBold',
            fontSize: 16,
            margin: [24, 8, 8, 20],
            x,
            y,
            h: 25,
            align: 'left',
            w,
        });
    }

    getInches(px: number): number {
        const inch = 0.01; // px
        return inch * px;
    }

    private convertPixelToPoints(px: number): number {
        // eslint-disable-next-line no-mixed-operators
        return 3 / 4 * px;
    }
}

export default PptxSlide;
