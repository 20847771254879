import React from 'react';
import { Switch } from 'antd';
import LinearLoader from '../../../common/Loader';
import styles from './styles.module.scss';

interface ICampaignStatusProps {
    isLoading: boolean;
    isActive: boolean;
    disabled: boolean;
    onChangeStatus: (value: boolean) => void;
}

const CampaignStatus: React.FC<ICampaignStatusProps> = ({
    isActive, isLoading,
    onChangeStatus, disabled,
}): JSX.Element => {
    const handlerActivateCampaign = (): void => {
        if (onChangeStatus && !disabled) {
            onChangeStatus(isActive);
        }
    };
    return (
        <div className={styles.root}>
            {
                isLoading
                    ? <LinearLoader />
                    : (
                        <span
                            className={styles.switchControl}
                            style={{ color: (isActive ? '#0EBFA1' : '#787E93') }}
                        >
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <span style={{ cursor: 'pointer' }} onClick={handlerActivateCampaign}>
                                {
                                    isActive ? 'Включена' : 'Выключена'
                                }
                            </span>
                            <Switch
                                id="campaign-activate-on-menu-switch"
                                size="small"
                                checked={isActive}
                                disabled={disabled}
                                onChange={handlerActivateCampaign}
                            />
                        </span>
                    )
            }
        </div>
    );
};

export default CampaignStatus;
