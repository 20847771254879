import React, { CSSProperties } from 'react';
import SvgIcon from '../../SvgIcon';
import styles from './styles.module.scss';

interface IPropsCollapseContainer {
    children: JSX.Element,
    style?: CSSProperties,
    classesForContainer?: string,
    classesForControll?: string,
    isOpen: boolean,
    isOnlyCollapse?: boolean,
    setOpen?: () => void,

}

const CollapseContainer: React.FC<IPropsCollapseContainer> = ({
    children,
    style,
    classesForContainer,
    classesForControll,
    isOpen,
    isOnlyCollapse = false,
    setOpen,
// eslint-disable-next-line arrow-body-style
}): JSX.Element => {
    return (
        <div
            style={style}
            className={`${styles.root_CollapseContainer} ${classesForContainer} ${isOpen ? styles.open : styles.close}`}
        >
            {children}
            {
                isOnlyCollapse ? null : (
                    <div
                        role="button"
                        className={`${styles.controller} ${isOpen ? styles.open : styles.close} ${classesForControll}`}
                        onClick={() => setOpen()}
                    >
                        <SvgIcon size={16} id="dropdownArrow" />
                    </div>
                )
            }
            {
                isOnlyCollapse ? null : (
                    <div
                        className={`${styles.overflowOverlay} ${isOpen ? styles.open : styles.close}`}
                    />
                )
            }
        </div>
    );
};

export default CollapseContainer;
