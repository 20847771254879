import React from 'react';
import {
    View, StyleSheet, Font, Text, Image,
} from '@react-pdf/renderer';
// @ts-ignore
import montserratRegular from '../../../../../fonts/static/Montserrat-Regular.ttf';
// @ts-ignore
import montserratSemiBold from '../../../../../fonts/static/Montserrat-SemiBold.ttf';
// @ts-ignore
import montserratMedium from '../../../../../fonts/static/Montserrat-Medium.ttf';
import CampaignPDFTemplatePage from '../TemplatePage';
import { LIST_COLORS } from '../../../../../utils/constants/Charts/colors';

Font.register({
    family: 'MontserratRegular400',
    src: montserratRegular,
});
Font.register({
    family: 'MontserratMedium500',
    src: montserratMedium,
});
Font.register({
    family: 'MontserratSemiBold600',
    src: montserratSemiBold,
});

const styles = StyleSheet.create({
    root: {
        width: '100%',
        height: '100%',
        padding: 24,
        display: 'flex',
    },
    topContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 26,
    },
    leftBlock: {
        width: 240,
        height: 103,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginRight: 64,
        backgroundColor: '#DFFCF7',
        padding: 24,
    },
    coverageValue: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '36px',
        textAlign: 'center',
        color: '#262626',
    },
    coverageLabel: {
        fontFamily: 'MontserratRegular400',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center',
        color: '#262626',
    },
    totalContainer: {
        width: '100%',
        height: 400,
        marginTop: -50,
        display: 'flex',
        alignItems: 'flex-start',
    },
    totalTitle: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        textAlign: 'center',
        color: '#787E93',
    },
    totalValue: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '36px',
        textAlign: 'center',
        color: '#262626',
        marginRight: 16,
    },
    totalLabel: {
        fontFamily: 'MontserratRegular400',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        textAlign: 'center',
        color: '#262626',
    },
    totalRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 32,
    },
    totalColumn: {
        height: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    totalColumnRightAlign: {
        height: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    totalCell: {
        height: 58,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginBottom: 30,
    },
    footerContent: {
        display: 'flex',
        marginTop: 16,
    },
    margin: {
        width: 80,
    },
    topRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    topHeaderCell: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: 12,
        width: 230,
        height: 80,
        borderBottom: 1,
        borderColor: '#DFFCF7',
    },
    topHeaderCellLeft: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: 12,
        borderBottom: 1,
        borderRight: 1,
        borderColor: '#DFFCF7',
        width: 230,
        height: 80,
    },
    topCellHeader: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        textAlign: 'center',
        color: '#262626',
        marginBottom: 8,
    },
    totCellSubHeader: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        textAlign: 'center',
        color: '#787E93',
        marginBottom: 8,
    },
    statisticRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: '#DFFCF7',
    },
    statisticLabelCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 18,
        width: 160,
        height: 30,
        backgroundColor: '#f5fefd',
    },
    statisticValueBorderCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: 1,
        borderColor: '#DFFCF7',
        width: 70,
        height: 30,
    },
    statisticValueCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 70,
        height: 30,
    },
    statisticLabelText: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        textAlign: 'center',
        color: '#000000',
    },
    statisticValueText: {
        fontFamily: 'MontserratSemiBold600',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '9px',
        textAlign: 'center',
        color: '#787E93',
    },
});

interface IPDFChartList {
    chartList: any[];
    reactElementKey: string;
    title: string;
    reportName: string;
    date: string;
}
const TemplateChartPage: React.FC<IPDFChartList> = ({
    chartList = [],
    reactElementKey,
    reportName,
    date,
    title,
}): JSX.Element => (
    <CampaignPDFTemplatePage reactElementKey={reactElementKey} reportName={reportName} date={date} title={title}>
        <View style={styles.root}>
            <View>
                {chartList.map((chart, index) => (
                    <View
                        style={{
                            position: 'absolute',
                            top: '16px',
                            left: index === 0 ? '0px' : '384px',
                        }}
                        key={`${chart.id}_${chart.name}`}
                    >
                        <Text style={{
                            fontFamily: 'MontserratMedium500',
                            marginLeft: '0px',
                            marginBottom: '8px',
                            width: '338px',
                            backgroundColor: '#f5fefd',
                            fontSize: '16px',
                            color: '#787E93',
                            paddingTop: '8px',
                            paddingBottom: '12px',
                            paddingLeft: '24px',
                        }}
                        >
                            {chart.name}
                        </Text>
                        <Image
                            style={{
                                width: chart.isBig ? '800px' : '368px',
                                height: chart.isBig ? '300px' : '232px',
                            }}
                            key={`${chart.id}_${chart.name}`}
                            src={chart.img}
                        />
                        <View style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            width: '400px',
                            justifyContent: 'flex-start',
                        }}
                        >
                            {chart?.filters.map((item, filterIndex) => (
                                <View
                                    key={item?.filter_name}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row-reverse',
                                        justifyContent: 'flex-end',
                                        width: '180px',
                                    }}
                                >
                                    <Text
                                        style={{
                                            paddingTop: '6px',
                                            fontFamily: 'MontserratMedium500',
                                            fontSize: '10px',
                                            marginBottom: '6px',
                                            marginRight: '6px',
                                            width: '148px',
                                        }}
                                    >
                                        {item?.filter_name}
                                    </Text>
                                    <View
                                        style={{
                                            marginRight: '8px',
                                            width: '28px',
                                            height: '16px',
                                            borderRadius: '6px',
                                            border: `1px solid ${LIST_COLORS[filterIndex].color}`,
                                            backgroundColor: LIST_COLORS[filterIndex].alphaColor,
                                        }}
                                    />
                                </View>
                            ))}
                        </View>
                    </View>
                ))}
            </View>
        </View>
    </CampaignPDFTemplatePage>
);

export default TemplateChartPage;
