import { AppState } from 'store/store';

const currentCampaign = (state: AppState) => state.common.currentPage?.currentCampaign;
const currentSource = (state: AppState) => state.common.currentPage?.currentSource;
const currentProject = (state: AppState) => state.common.currentPage?.currentProject;
// const currentProject = (state: AppState) => state.common.currentPage?;

export default {
    currentCampaign,
    currentSource,
    currentProject,
};
