import { combineReducers } from 'redux';
import currentPageReducer from './current-page';
import analyzeReducer from './analyze';
import feedbackReducer from './feedback';
import leftMenuReducer from './left-menu';
import windowReducer from './window';
import messageModalReducer from './message-modal';
import breadCrumbsReducer from './breadCrumbs';
import tablePropertiesReducer from './tableProperties';

const commonReducers = () => combineReducers({
    currentPage: currentPageReducer,
    analyze: analyzeReducer,
    feedback: feedbackReducer,
    leftMenu: leftMenuReducer,
    commonWindow: windowReducer,
    messageModal: messageModalReducer,
    breadCrumbs: breadCrumbsReducer,
    tableProperties: tablePropertiesReducer,
});

export default commonReducers;
