import React, {
    useEffect,
    useMemo, useRef, useState,
} from 'react';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { Calendar, SvgIcon } from '@shared/index';
import classnames from 'classnames';
import moment from 'moment';
import styles from './styles.module.scss';

interface IProps {
    size?: 'large' | 'medium' | 'small';
    onChange: (date: moment.Moment) => void;
    value?: moment.Moment;
    classNames?: string;
    placeholder?: string
}

const FORMAT_DEFAULT = 'DD.MM.YYYY';

const DatePicker: React.FunctionComponent<IProps> = ({
    onChange, value, classNames = '', size = 'small', placeholder = 'placeholder',
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [initDate, setInitDate] = useState<moment.Moment | null>(() => {
        if (!value) return null;
        return value;
    });
    const refModal = useRef(null);
    const handlerOnClickOutside = (): void => {
        if (isOpen) {
            setIsOpen(false);
        }
    };
    useOnClickOutside(refModal, handlerOnClickOutside);

    const svgSize = useMemo(() => {
        if (size && size === 'large') return 24;
        if (size && size === 'medium') return 20;
        if (size && size === 'small') return 16;
        return 16;
    }, [size]);

    const wrapperStyle = useMemo(() => {
        if (isOpen) return styles.containerCalendarWrapper;
        return styles.wrapperHide;
    }, [isOpen]);

    const getLabelInitDate = useMemo(() => {
        if (!initDate) return placeholder;
        return initDate.format(FORMAT_DEFAULT);
    }, [initDate]);

    useEffect(() => {
        setIsOpen(false);
    }, [initDate]);

    const calendarHandler = (newValue: moment.Moment) => {
        setInitDate(newValue);
        onChange(newValue);
    };

    return (
        <div role="button" className={classnames(`${styles.currentPeriod} ${classNames}`)} onClick={() => setIsOpen(true)}>
            <SvgIcon id="Calendar" size={svgSize} />
            <span id="track-filter-date-start">{getLabelInitDate}</span>
            <div className={wrapperStyle} ref={refModal}>
                <Calendar
                    onChange={calendarHandler}
                />
            </div>
        </div>
    );
};

export default React.memo(DatePicker);
