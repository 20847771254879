export const enum ColumnFilterModelType {
    default = 0,
    date = 1,
    period = 2,
    dashboard = 3,
}

export interface IColumnFilterModel {
    id: string | number;
    value: string | number;
    name: string;
    isSelected: boolean;
}

export interface IDropdownFilterTableMenu {
    type: ColumnFilterModelType;
    isSorting: boolean;
    isFiltering: boolean;
    isAscending: boolean;
    filterData: IColumnFilterModel[];
}

export interface IDropdownFilterTableMenuProps extends IDropdownFilterTableMenu {
    onSort: (ascending: boolean) => void;
    onApplyFilters: () => void;
    isVisible: boolean;
    setVisibleMenu: (value: boolean) => void;
}
