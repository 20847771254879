import React from 'react';
import { SvgIcon } from '@shared/index';
import styles from './styles.module.scss';

interface IPropsAddButton {
    isLoading?: boolean,
    iconId: string,
    handleClick?: React.MouseEventHandler<HTMLDivElement>
}
const IconButtonLoadFile: React.FC<IPropsAddButton> = ({
    isLoading = false,
    iconId,
    handleClick,
}): JSX.Element => (
    <div className={`${isLoading && styles.isLoad} ${styles.root_iconButton}`}>
        <div
            role="button"
            className={styles.iconButton}
            onClick={(e) => handleClick && handleClick(e)}
        >
            <div className={styles.icn}>
                <SvgIcon size={24} id={iconId} />
            </div>
        </div>
        {isLoading && (
            <div className={styles.loading}>
                <span />
            </div>
        )}
    </div>
);

export default IconButtonLoadFile;
