import React from 'react';
import { LogoImageInList, Tooltip } from '@shared/index';
import Path from '@shared/lib/paths';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface IProps {
    value: string;
    logoUrl: string;
    sourceId: number;
}

const SourceCell: React.FC<IProps> = ({ value, logoUrl, sourceId }): JSX.Element => {
    const handler = () => {
    };
    return (
        <div className={styles.sourceCell_root}>
            {handler}
            <Link to={`/${Path.Sources}/${sourceId}`}>
                <LogoImageInList logoUrl={logoUrl} />
            </Link>
            {/* <span className={styles.sourceCell_text}>{value}</span> */}
            <Link to={`/${Path.Sources}/${sourceId}`}>
                <div className={styles.sourceCell_textWithTooltip}>
                    <Tooltip
                        content={(<span>{value}</span>)}
                        placement="bottomLeft"
                        xOffset={-2}
                        yOffset={0}
                        disabled={value?.length < 22}
                        arrow={false}
                    >
                        {value}
                    </Tooltip>
                    <div className={styles.hiddenField} />
                </div>
            </Link>
        </div>
    );
};

export default SourceCell;
