import React from 'react';
import { ICampaignSourceBlockProps } from 'components/campaign/UpdateForm/Sources/types';
import CampaignSourceTypes from './SourceTypes';
import CustomCheckbox from '../../../Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';

import styles from './styles.module.scss';

const CampaignSourcesBlock: React.FC<ICampaignSourceBlockProps> = ({
    checkedBloggerIds, checkedSourceTypeIds,
    handlerSetBlogger, handlerSetSourceType,
    isErrorHighlighting, isView, isIPadSize,
}): JSX.Element => {
    const handlerDownloadCommentsChecked = (id: string): void => {
        // eslint-disable-next-line no-console
        console.log('handlerDownloadCommentsChecked', id);
    };

    return (
        <div className={styles.root} style={isIPadSize ? {} : null}>
            <CampaignSourceTypes
                handlerSetBlogger={handlerSetBlogger}
                handlerSetSourceType={handlerSetSourceType}
                checkedBloggerIds={checkedBloggerIds}
                checkedSourceTypeIds={checkedSourceTypeIds}
                isErrorHighlighting={isErrorHighlighting}
                isView={isView}
                isIPadSize={isIPadSize}
            />
            {
                false && (
                    <CustomCheckbox
                        id="1"
                        tagId="campaign-load-comments"
                        checked={false}
                        isRetinaSize={false}
                        isDisabled
                        name="Загружать все комментарии к сообщениям"
                        onChecked={handlerDownloadCommentsChecked}
                        textClassName={styles.checkboxLabel}
                    />
                )
            }
        </div>
    );
};

export default CampaignSourcesBlock;
