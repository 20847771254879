import React from 'react';
import projectConstants from '@app/constants/projects';
import styles from './styles.module.scss';

interface IProps {
    platformName: string;
}

const PlatformCell: React.FC<IProps> = ({ platformName }): JSX.Element => {
    const getPlatform = (name: string): JSX.Element => {
        const item = projectConstants.platformsMap.get(name);
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {item?.element}
                <span>{item?.title}</span>
            </div>
        );
    };
    return (
        <div className={styles.platformCell}>
            <div className={styles.platformCell_linkBlock}>
                {getPlatform(platformName)}
            </div>
        </div>
    );
};

export default PlatformCell;
