/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState } from 'react';
import SelectDemo from '@components/SelectDemo';
import CheckBoxListDemo from '@components/CheckBoxListDemo';
import TooltipsDemo from '@components/TooltipsDemo';
import ButtonDemo from '@features/demos/Button';
import IconsDemo from '@features/demos/Icons';
import TextFieldDemo from '@features/demos/TextField';
import TableDemo from '@features/demos/Table';
import Validations from '@features/demos/Validations';
import MarkDownDemo from '@features/demos/Markdown';
import ModalsDemo from '@features/demos/Modals';
import PublicationChartGraph from '../../deprecated/pagesComponents/info-trace/PublicationChartGraph';
import styles from './styles.module.scss';

const listTabs = {
    selects: <SelectDemo />,
    charts: <PublicationChartGraph />,
    checkboxes: <CheckBoxListDemo />,
    tooltips: <TooltipsDemo />,
    buttons: <ButtonDemo />,
    textField: <TextFieldDemo />,
    validations: <Validations />,
    table: <TableDemo />,
    icons: <IconsDemo />,
    markdown: <MarkDownDemo />,
    modals: <ModalsDemo />,
};
const ChartGraphDemo = () => {
    const [currentTab, setCurrentTab] = useState('modals');
    const viewTab = useMemo(() => listTabs[currentTab], [currentTab]);
    return (
        <div className={styles.layout}>
            <div className={styles.tabs}>
                <div className={`${styles.tab} ${currentTab === 'icons' ? styles.select : ''}`} onClick={() => setCurrentTab('icons')}>Иконки</div>
                <div className={`${styles.tab} ${currentTab === 'charts' ? styles.select : ''}`} onClick={() => setCurrentTab('charts')}>Графики </div>
                <div className={`${styles.tab} ${currentTab === 'checkboxes' ? styles.select : ''}`} onClick={() => setCurrentTab('checkboxes')}>Чекбоксы</div>
                <div className={`${styles.tab} ${currentTab === 'selects' ? styles.select : ''}`} onClick={() => setCurrentTab('selects')}>Селекты</div>
                <div className={`${styles.tab} ${currentTab === 'tooltips' ? styles.select : ''}`} onClick={() => setCurrentTab('tooltips')}>Тултипы</div>
                <div className={`${styles.tab} ${currentTab === 'buttons' ? styles.select : ''}`} onClick={() => setCurrentTab('buttons')}>Кнопки</div>
                <div className={`${styles.tab} ${currentTab === 'textField' ? styles.select : ''}`} onClick={() => setCurrentTab('textField')}>Поля</div>
                <div className={`${styles.tab} ${currentTab === 'validations' ? styles.select : ''}`} onClick={() => setCurrentTab('validations')}>Валидация</div>
                <div className={`${styles.tab} ${currentTab === 'validations' ? styles.select : ''}`} onClick={() => setCurrentTab('markdown')}>markdown</div>
                <div className={`${styles.tab} ${currentTab === 'table' ? styles.select : ''}`} onClick={() => setCurrentTab('table')}>Таблица</div>
                <div className={`${styles.tab} ${currentTab === 'modals' ? styles.select : ''}`} onClick={() => setCurrentTab('modals')}>Модальные окна</div>
            </div>
            {viewTab}
        </div>
    );
};

export default ChartGraphDemo;
