import { ISimpleEntity } from '../../../../store/types';
import PptxSlide from '../../PptxSlide';
import { ICampaignPPTXSearchParamsPageProps } from './types';

class CampaignPPTXSearchParamsPage extends PptxSlide {
    render(props: ICampaignPPTXSearchParamsPageProps) {
        if (props.categories) {
            this.renderBlock(
                'Выбранные категории:',
                props.categories,
                this.getInches(97),
                this.getInches(129),
            );
        }

        if (props.trackedWords) {
            this.renderBlock(
                'Отслеживаемые слова:',
                props.trackedWords,
                this.getInches(185),
                this.getInches(217),
            );
        }

        if (props.excludedLinks) {
            this.renderBlock(
                'Исключаемые источники:',
                props.excludedLinks,
                this.getInches(345),
                this.getInches(375),
            );
        }

        if (props.platforms) {
            this.renderPlatforms(props.platforms);
        }

        this.renderHeader({
            date: props.date,
            title: props.title,
            layoutHeight: props.layoutHeight,
            layoutWidth: props.layoutWidth,
            reportName: props.reportName,
            header: {
                pageName: props.header.pageName,
                number: props.header.number,
            },
        });
        return this.slide;
    }

    private renderPlatforms(platforms: ISimpleEntity[]): void {
        this.renderBlockLabel('Типы платформ:', this.getInches(273));

        platforms.forEach((platform, index) => {
            const x = this.getInches(index >= 1 ? 24 + (40 * index + 1) : 24);
            this.slide.addImage({
                path: this.getPlatformIcon(platform.value),
                x,
                y: this.getInches(305),
                w: this.getInches(24),
                h: this.getInches(24),
            });
        });
    }

    private renderBlock(
        label: string,
        data: ISimpleEntity[],
        textY: number,
        blocksY: number,
    ): void {
        this.renderBlockLabel(label, textY);

        let prevRectWidth = 0;
        data.forEach((item) => {
            prevRectWidth = this.renderBlockRect(item, blocksY, prevRectWidth);
        });
    }

    private renderBlockLabel(label: string, y: number): void {
        this.addText(label, {
            x: 24,
            y,
            w: '100%',
            fontSize: 14,
            color: '787E93',
            fontFace: 'Montserrat Medium',
            h: 20,
        });
    }

    private renderBlockRect(
        item: ISimpleEntity,
        y: number,
        prevRectWidth: number,
    ): number {
        const { name } = item;
        const { length } = name;
        const wordLength = length > 10 ? 14 : 20;
        const w = length * wordLength;
        const x = prevRectWidth > 1
            ? 24 + prevRectWidth + 16
            : 24;

        this.addText(name, {
            x,
            y,
            margin: 10,
            fontSize: 14,
            color: '000000',
            fontFace: 'Montserrat Regular',
            shape: 'roundRect',
            rectRadius: this.getInches(6),
            fill: { color: 'F8FBFF' },
            h: 40,
            w,
        });

        return w;
    }
}

export default CampaignPPTXSearchParamsPage;
