/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { IApplyFilter } from '@sections/MyCampaignContainer/CampaingTypes';
import { Button } from '@shared/index';
import styles from './styles.module.scss';
import FooterCreateUserFilter from '../FooterCreateUserFilter';

interface IPropsCampaignFooterFilters {
    onSave: (data: any) => void,
    onCleanFilters: () => void,
    onConfirm: () => void
    includes: IApplyFilter[],
    excludes: IApplyFilter[],
    campaignId: number,

}

const FooterFilters: React.FC<IPropsCampaignFooterFilters> = ({
    onSave,
    onCleanFilters,
    onConfirm,
    includes,
    excludes,
    campaignId,
}): JSX.Element => {
    const [isCreatingFilter, setIsCreating] = useState<boolean>(false);
    if (isCreatingFilter) {
        return (
            <FooterCreateUserFilter
                campaignId={campaignId}
                includes={includes}
                excludes={excludes}
                onSave={onSave}
                onCancel={() => setIsCreating(false)}
            />
        );
    }
    return (
        <div className={styles.root_footerFilters}>
            <Button
                text="Сбросить фильтры"
                type="default"
                size="large"
                onClick={onCleanFilters}
            />
            {/* <ButtonPrimary */}
            {/*    style={{ width: 196, height: 48, fontSize: 16 }} */}
            {/*    text="Сбросить фильтры" */}
            {/*    onClick={onCleanFilters} */}
            {/* /> */}
            <div style={{ margin: '10px 0' }}>
                <Button
                    text="Сохранить фильтр"
                    type="secondary"
                    size="large"
                    disabled={!includes?.length && !excludes?.length}
                    onClick={() => setIsCreating(true)}
                />
            </div>
            {/* <ButtonSecondary */}
            {/*    style={{ */}
            {/*        width: 196, height: 48, fontSize: 16, marginBottom: 10, marginTop: 10, */}
            {/*    }} */}
            {/*    disabled={!includes?.length && !excludes?.length} */}
            {/*    text="Сохранить фильтр" */}
            {/*    onClick={() => setIsCreating(true)} */}
            {/* /> */}
            <Button
                text="Применить фильтры"
                type="default"
                size="large"
                onClick={onConfirm}
            />
            {/* <ButtonPrimary */}
            {/*    style={{ width: 216, height: 48, fontSize: 16 }} */}
            {/*    text="Применить фильтры" */}
            {/*    onClick={onConfirm} */}
            {/* /> */}
        </div>
    );
};
export default FooterFilters;
