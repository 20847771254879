import { IPDFHandlerReport } from './types';

export const convertSavedReportFromApi = (data: unknown): IPDFHandlerReport[] => {
    if (!data || !Array.isArray(data)) {
        return [];
    }
    return data.map((item) => ({
        name: item?.name,
        chartsIds: item?.chart_ids,
        created: item?.created,
        campaignId: item?.campaign_id,
        timeFrame: item?.time_frame,
        file: { name: item?.filename, content: null },
        id: item?.id,
        createTimeStamp: new Date(item?.created).getTime(),
        generated: item?.generated,
    } as IPDFHandlerReport));
};
