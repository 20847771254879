import { ICategory } from 'store/types';
import { AppState } from 'store/store';

const categoryState = (state: AppState) => state.infoBasket.category;
const ids = (state: AppState) => categoryState(state).ids;
const entities = (state: AppState) => categoryState(state).entities;
const loadingStatus = (state: AppState) => categoryState(state).status;
const categoryById = (state: AppState, id: string) => categoryState(state).entities[id];

export const getSelectedCategory = (state: AppState): ICategory[] => {
    const result: ICategory[] = [];
    ids(state).forEach((id) => {
        if (entities(state)[id].selected) {
            result.push(entities(state)[id]);
        }
    });

    return result;
};

export default {
    ids,
    entities,
    loadingStatus,
    categoryById,
};
