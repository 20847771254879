import React, { useEffect, useMemo, useState } from 'react';
import { ISimpleEntity } from 'store/types';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import styles from './styles.module.scss';
import SingleSelectItem from '../../../../common/SingleSelectItem';

interface IProps {
    campaigns: ICampaignDataModel[];
    campaignId: number;
    onSelectCampaign: (id: number) => void;
    scrollTimestamp: number;
}
const CampaignDropDownCard: React.FC<IProps> = ({
    campaigns,
    campaignId,
    onSelectCampaign,
    scrollTimestamp,
}): JSX.Element => {
    const listValues = useMemo<ISimpleEntity[]>(() => campaigns?.map(
        ({ id, name }) => ({ id, name, value: name } as ISimpleEntity
        ),
    ), [campaigns]);

    const [needClose, setClose] = useState<boolean>(true);
    const handlerClosedDropDown = (): void => {
        setClose(true);
    };
    const handlerOpenDropDown = (): void => {
        setClose(true);
    };
    useEffect(() => {
        if (needClose) {
            setClose(false);
        }
    }, [scrollTimestamp]);

    return (
        <div className={styles.campaignDropDownCard_root}>
            <div className={styles.title}>
                Кампания
            </div>
            <div className={styles.dropdownBorder} style={{ width: 256 }}>
                <SingleSelectItem
                    tagId="campaign-comparison-controls"
                    onSelect={onSelectCampaign}
                    selectedId={campaignId}
                    defaultTitle={<span className={styles.dropdownBorder_text}>Выберите кампанию</span>}
                    list={listValues}
                    height={30}
                    minWidth={220}
                    isLoading={false}
                    isVisible={needClose}
                    onClose={handlerClosedDropDown}
                    onOpen={handlerOpenDropDown}
                />
            </div>
        </div>
    );
};

export default CampaignDropDownCard;
