import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

const MyCampaignCategoriesSkeleton : React.FC = (): React.ReactElement => (
    <div className={styles.wrapper} style={{ minWidth: '286px' }}>
        <Skeleton width="260px" height="100px" />
        <Skeleton width="260px" height="500px" />
    </div>
);
export default MyCampaignCategoriesSkeleton;
