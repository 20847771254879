import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from '@ant-design/plots';
import Skeleton from 'react-loading-skeleton';
import { CreateDataset } from '../../../../../utils/Charts/CreatePointsFromCommons';
import { chartsCampaingRtqService } from '../../../../../services/campaigns/Charts';

interface IPropsCampaignDnDRegions {
    campaignId: number
}
const CampaignDnDRegions: React.FC<IPropsCampaignDnDRegions> = ({ campaignId }): JSX.Element => {
    const [getChartData, { data, isLoading, isError }] = chartsCampaingRtqService.useNewGetChartDataMutation();
    const [chartSetting, setChartSettings] = useState();
    const getData = useMemo(() => {
        if (!data || !Array.isArray(data?.data)) return [];
        const group = data?.data.reduce((acc, item) => {
            if (!acc.length) return [item];
            const check = acc.find((elem) => item.subjectValues[0] === elem.subjectValues[0]);
            if (check) {
                return acc.map((elem) => {
                    if (item.subjectValues[0] === elem.subjectValues[0]) {
                        return {
                            ...elem,
                            y: [elem.y[0] + item.y[0]],
                        };
                    }
                    return elem;
                });
            }
            return [item, ...acc];
        }, []);
        const sorted = group.sort((first, second) => second.y[0] - first.y[0]);
        const filterFromBroken = sorted.filter((item) => {
            const isNotNotDefined = item.subjectValues[0] !== 'Не определено';
            const isNotAllcountry = item.subjectValues[0] !== 'Россия';
            const isNotNull = item.subjectValues[0] !== null;
            const isNotNulluble = item.subjectValues[0] !== '0';
            const isNotOne = item.subjectValues[0] !== '1';
            return isNotNotDefined && isNotAllcountry && isNotNull && isNotNulluble && isNotOne && item.subjectValues[0];
        });
        const firstTeen = filterFromBroken.filter((e, i) => i < 9);
        // todo charts
        if (firstTeen.length < 2) return [];
        if (firstTeen[1].y[0] - firstTeen[0].y[0] < -10000) {
            // eslint-disable-next-line operator-assignment
            firstTeen.map((item, index) => {
                if (index > 0) return item;
                return {
                    ...item,
                    y: [item.y[0] / 10],
                };
            });
        }
        const list = firstTeen.map((item) => ({ region: item?.subjectValues[0], value: item?.y[0] }));
        return list;
    }, [data]);
    const config = {
        data: getData,
        xField: 'value',
        yField: 'region',
        seriesField: 'region',
        barWidthRatio: 0.7,
        barStyle: {
            radius: [6, 6, 6, 6],
        },
        label: {
            position: 'right',
            style: {
                fill: '#787E93',
            },
        },
        xAxis: {
            label: {
                style: {
                    fill: 'rgba(0,0,0,0)',
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: 'rgba(0,0,0,0)',
                    },
                },
            },
            tickLine: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
        },
        yAxis: {
            line: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: 'rgba(0,0,0,0)',
                    },
                },
            },
            tickLine: {
                style: {
                    stroke: 'rgba(0,0,0,0)',
                },
            },
        },
        legend: {
            position: 'top-left',
        },
    };
    useEffect(() => {
        const newDataset = new CreateDataset(data?.data);
        const { subjects } = newDataset;
        if (Array.isArray(subjects)) {
            setChartSettings(newDataset.create('BAR_CHART_REGION'));
        }
    }, [data]);
    useEffect(() => {
        if (typeof campaignId === 'number') {
            getChartData({
                campaign_id: campaignId,
                chart_type: 'BAR_CHART',
                dimensions: ['LOCATION'],
                filters_include: [],
                metrics: ['POSTS'],
                name: 'Новый график',
                time_frame: 'DAY',
            } as any);
        }
    }, [campaignId]);
    if (isLoading) {
        return (
            <div style={{
                width: 487, height: 252, background: 'white', borderRadius: 6, padding: 12, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                <Skeleton width={487 - 24} height={252 - 24} />
            </div>
        );
    }
    if (isError) return null;

    if (isError) {
        return (
            <div style={{
                width: 487, height: 252, background: 'white', borderRadius: 6, padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                Произошла непредвиденная ошибка
            </div>
        );
    }
    return (
        <div style={{
            width: 487, height: 252, background: 'white', borderRadius: 6, padding: 12,
        }}
        >
            { chartSetting && (<Bar {...config as any} />)}
        </div>
    );
};

export default CampaignDnDRegions;
