import React, { useState } from 'react';
import { SvgIcon } from '@shared/index';
import CollapseContainer from '@components/Project/CollapseContainer';
import {
    ICustomSourceListRaw, IEntity, IPlatform, ISourceFiltersRanges,
} from '@services/sources/types';
import { ICategory } from '@store/types';
import styles from './styles.module.scss';
import DetailCardInfo from './DetailCardInfo';

interface IPropsList {
    id: number;
    title: string;
    count: number;
    description: string;
    initialIsOpen?: boolean;
    onRemove: (id: number) => void;
    updateLists: () => void;

    platforms: IEntity[];
    categoriesData: ICategory[];
    isLoadingPlatforms: boolean;
    isLoadingCategories: boolean;
    platformByNameMap: Map<string, IEntity>;
    platformByIdMap: Map<number, IPlatform>;
    platformsIcon: Map<string, string>;
    ranges: ISourceFiltersRanges;
    list_id?: number,
    clearFilters?: () => void;
    lists: ICustomSourceListRaw[];
    favoriteId: number,
}
const CardList: React.FC<IPropsList> = ({
    id, title, count, description, initialIsOpen, onRemove, updateLists, ...table
}): React.ReactElement => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isOpen, setIsOpen] = useState<boolean>(() => !!initialIsOpen);
    return (
        <div className={styles.cardList_root}>
            <CollapseContainer
                isOpen={isOpen}
                isOnlyCollapse={false}
                classesForControll={styles.controllerHide}
                setOpen={() => setIsOpen((prev) => !prev)}
                style={{ maxHeight: 200, width: '100%', position: 'relative' }}
            >
                <>
                    <div className={styles.cardList}>
                        <div className={styles.cardInfo}>
                            <div className={styles.cardInfo_title}>{title}</div>
                            <div className={styles.cardInfo_counter}>{`${count} источника`}</div>
                            <div className={styles.cardInfo_description}>{description}</div>
                        </div>

                    </div>
                    {
                        isOpen && (
                            <DetailCardInfo {...table} updateLists={updateLists} />
                        )
                    }
                </>
            </CollapseContainer>

            <div className={styles.btnContainer}>
                <div
                    role="button"
                    className={styles.cardList_remover}
                    onClick={() => onRemove(id)}
                >
                    <SvgIcon id="RemoveIcon" size={24} />
                </div>
            </div>
        </div>
    );
};
export default React.memo(CardList);
