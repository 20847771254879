import React from 'react';
import { Button } from '@shared/index';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IProps {
    isOpen: boolean;
    name: string;
    onClose: () => void;
    onChangeName: (value: string) => void;
    onSaveResult: () => void;
}

const CampaignComparisonResultName: React.FC<IProps> = ({
    isOpen,
    onClose,
    name,
    onChangeName,
    onSaveResult,
}): JSX.Element => {
    const handlerCloseModal = (): void => {
        if (onClose) {
            onClose();
        }
    };
    const handlerChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeName(event?.currentTarget?.value ?? '');
    };
    const handlerSaveResult = (): void => {
        onSaveResult();
    };
    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.campaignComparisonResultName_root}>
            <div className={styles.modalWindow}>
                <div className={styles.contentContainer}>
                    <div className={styles.flexRow} style={{ justifyContent: 'space-between', height: 38 }}>
                        <div className={styles.contentContainer_title}>
                            Наименование конкурентного анализа
                        </div>
                        <div className={styles.modalCrossWrapper}>
                            <button type="button" style={{ padding: 0, background: 'none' }} onClick={handlerCloseModal}>
                                <SvgIcon id="BigCross" size={14} height={13} />
                            </button>
                        </div>
                    </div>
                    <div className={styles.contentContainer_contentBox}>
                        <div className={styles.inputBox}>
                            <input
                                className={`${styles.input} ${styles.input_text}`}
                                placeholder="Введите наименование"
                                value={name ?? ''}
                                onChange={handlerChangeValue}
                            />
                        </div>
                        <div style={{
                            display: 'flex', justifyContent: 'flex-end', height: 56, paddingRight: 4,
                        }}
                        >
                            <Button text="Сохранить" type="default" size="large" onClick={handlerSaveResult} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignComparisonResultName;
