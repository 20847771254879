import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CampaignProcessModal from '../../../components/Modal/Campaign/Process';
import { ProcessModalStatus } from '../../../store/campaign/source/types';
import { ICampaignUsersSelectProps } from './types';
import CommonSelectDropdownList from '../../../components/common/SelectDropdownList';
import campaignSelectors from '../../../store/campaign/source/selectors';
import { useAppDispatch } from '../../../store/hooks';
// import loadUsersForSingleCampaignThunk from '../../../store/campaign/source/thunks/load-users-for-sigle-campaign';
import { FetchStatus, ISimpleEntity } from '../../../store/types';
// import addUserToCampaignThunk from '../../../store/campaign/source/thunks/add-user-campaign';
import AddUserModal from './AddUserModal';
import { campaignActions } from '../../../store/campaign/source';
import { campaignUsersRtkQService } from '../../../services/campaigns/users';
import styles from './styles.module.scss';

const CampaignUsersSelectContainer: React.FC<ICampaignUsersSelectProps> = ({
    tagId, label, campaignId,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    // const users = useSelector(campaignSelectors.singleCampaignUsers);
    const usersStatus = useSelector(campaignSelectors.singleCampaignUsersStatus);
    const addUserStatus = useSelector(campaignSelectors.addUserToCampaignStatus);

    const [addUserCampaign] = campaignUsersRtkQService.useLazyPostAddUserCampaignQuery();
    const { data: users } = campaignUsersRtkQService.useGetSingleCampaignUsersQuery({ id: campaignId });

    const [emailModalIsOpen, setEmailModalOpen] = useState<boolean>();
    const [processModalIsOpen, setProcessModalOpen] = useState<boolean>();
    const [processModalStatus, setProcessModalStatus] = useState<ProcessModalStatus>(ProcessModalStatus.undefined);
    const [email, setEmail] = useState<string>('');
    const [processMessage, setProcessMessage] = useState<string[]>([]);

    const handlerAddUser = (): void => {
        setEmailModalOpen(true);
    };

    const handlerEmailModalClose = (): void => {
        setEmailModalOpen(false);
        setEmail('');
    };

    const handlerEmailModalSend = (): void => {
        // dispatch(addUserToCampaignThunk(campaignId, email));
        if (Number.isInteger(campaignId)) {
            addUserCampaign({ id: campaignId, email });
        }
        setEmailModalOpen(false);
        setProcessModalStatus(ProcessModalStatus.process);
        setProcessMessage([`Добавляем пользователя по e-mail: ${email}`]);
        setProcessModalOpen(true);
    };

    const handlerProcessModalClose = (): void => {
        setProcessModalOpen(false);
        setProcessModalStatus(ProcessModalStatus.undefined);
        setProcessMessage([]);
        setEmail('');
    };

    const list = useMemo((): ISimpleEntity[] => users?.users?.map(
        ({ userId, lastName }) => ({ id: userId, name: lastName } as ISimpleEntity),
    ), [users]);

    // useEffect(() => {
    //     dispatch(loadUsersForSingleCampaignThunk(campaignId));
    // }, [campaignId]);
    useEffect(() => {
        if (addUserStatus === FetchStatus.success) {
            setProcessModalStatus(ProcessModalStatus.success);
            setProcessMessage([`Пользователю отправлено сообщение на e-mail: ${email}`]);
            dispatch(campaignActions.clearSingleCampaignUsers());
            // dispatch(loadUsersForSingleCampaignThunk(campaignId));
            return;
        }
        if (addUserStatus === FetchStatus.failed) {
            setProcessModalStatus(ProcessModalStatus.failed);
            setProcessMessage(['Произошла ошибка при отправке.']);
            return;
        }
        if (addUserStatus === FetchStatus.process) {
            setProcessModalStatus(ProcessModalStatus.process);
        }
    }, [addUserStatus]);

    return (
        <div className={styles.root}>
            <CommonSelectDropdownList
                tagId={tagId}
                label={label}
                isLoading={!list || usersStatus === FetchStatus.loading}
                data={list}
                onAddUser={handlerAddUser}
            />
            <AddUserModal
                isOpen={emailModalIsOpen}
                onClose={handlerEmailModalClose}
                email={email}
                setEmail={setEmail}
                onSend={handlerEmailModalSend}
            />
            <CampaignProcessModal
                submitBtnTagId="campaign-add-user-process"
                isOpen={processModalIsOpen}
                onClose={handlerProcessModalClose}
                messages={processMessage}
                status={processModalStatus}
            />
        </div>
    );
};

export default React.memo(CampaignUsersSelectContainer);
