import React, { useMemo, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import CustomCheckbox from '../../../Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';
import SearchPhraseParametersContextMenu from '../ContextMenu/PhraseParameters';
import SearchLogicOperatorContextMenu from '../ContextMenu/LogicOperator';
import SearchWordPhrase from './WordPhrase';
import styles from './styles.module.scss';
import PlusIcon from '../imgs/plus.svg';
import HorizontalDotsIcon from '../imgs/horizontalDots.svg';
import {
    // ComparisonParametersType,
    IGroupParameters,
    IPhraseParameters,
    ISearchPhraseWord,
    PhraseWordLogicOperatorType,
} from '../types';
import SearchGroupContextMenu from '../ContextMenu/Group';
import SearchGroupParametersContextMenu from '../ContextMenu/GroupParameters';

interface IProps {
    isView: boolean;
    isPreview: boolean;
    groupPreviewChecked: boolean;
    id: number;
    index: number;
    nextGroupIndex: number;
    phrases: ISearchPhraseWord[];
    groupParameters: IGroupParameters;
    onChangeGroupParameters: (groupIndex: number, value: IGroupParameters) => void;
    onAddGroup: (index: number) => void;
    onDeleteGroup: (groupIndex: number) => void;
    setPhrases: (index: number, value: ISearchPhraseWord[]) => void;
    onCheckedPreview: (groupIndex: number, value: boolean) => void;
}

// const LogicOperatorTypeMap = new Map(
//     Object.keys(PhraseWordLogicOperatorType)
//         .slice(Object.keys(PhraseWordLogicOperatorType).length / 2, Object.keys(PhraseWordLogicOperatorType).length)
//         .map((value, index) => [index, value]),
// );

const groupName = 'Группа';
const contextMenuOffset = 10;
const initialContextMenuOffset = 70;
const addNewGroupMenuId = 1;
const initialParameters: IPhraseParameters = {
    value: '',
    considerDeclensions: false,
    isTrack: true,
    placeInSentence: null,
    numberOccurrences: null, // { quantity: 0, parameter: ComparisonParametersType.equal },
};

const CampaignSearchGroup: React.FC<IProps> = ({
    id,
    index: groupIdx,
    nextGroupIndex,
    phrases,
    groupParameters,
    onChangeGroupParameters,
    onAddGroup,
    onDeleteGroup,
    setPhrases,
    isView,
    isPreview,
    groupPreviewChecked,
    onCheckedPreview,
}): JSX.Element => {
    const { ref, width: containerWidth } = useResizeObserver<HTMLDivElement>();
    const { ref: groupRef, width: groupWidth } = useResizeObserver<HTMLDivElement>();
    const { ref: initialEntityRef, width: initialContainerWidth } = useResizeObserver<HTMLDivElement>();

    const [initialIsOpen, setOpenInitial] = useState<boolean>(false);
    const [newPhraseIsOpen, setNewPhraseOpen] = useState<boolean>(false);
    const [logicMenuIsOpen, setOpenForLogicMenu] = useState<boolean>(false);
    const [isOpenGroupMenu, setOpenGroupMenu] = useState<boolean>(false);
    const [isOpenGroupParameters, setOpenGroupParameters] = useState<boolean>(false);

    const excludedParams = useMemo<number[]>(() => {
        if (isView) {
            return isView ? [addNewGroupMenuId, 2] : [];
        }
        return nextGroupIndex > 0 ? [addNewGroupMenuId] : [];
    }, [isView, nextGroupIndex]);
    const onlyOrderOperators = useMemo<boolean>(() => (
        phrases?.length > 1
        && phrases?.some(
            ({ logicOperator }) => logicOperator === PhraseWordLogicOperatorType.order,
        )
    ), [phrases]);
    const withoutOrderOperators = useMemo<boolean>(() => (
        phrases?.length > 1
        && phrases?.every(
            ({ logicOperator }) => logicOperator !== PhraseWordLogicOperatorType.order,
        )
    ), [phrases]);
    const isInitial = useMemo<boolean>(() => (
        !(phrases?.length > 0)
    ), [phrases]);
    const lastPhrase = useMemo<ISearchPhraseWord | null>(() => (
        phrases?.slice(-1)[0] ?? null
    ), [phrases]);

    const penultimatePhrase = useMemo<ISearchPhraseWord | null>(() => {
        if (phrases.length > 0) {
            return phrases[phrases.length - 1];
        }
        return null;
    }, [phrases]);

    const handlerAddPhrase = (): void => {
        setOpenInitial(true);
    };

    const handlerAddEntity = (): void => {
        if (!lastPhrase && !(phrases?.length > 0)) {
            setOpenInitial(true);
            return;
        }
        if (lastPhrase && !(lastPhrase.logicOperator in PhraseWordLogicOperatorType)) {
            setOpenForLogicMenu(true);
        } else {
            setNewPhraseOpen(true);
        }
    };

    const addLogicOperator = (operator: PhraseWordLogicOperatorType): void => {
        const updated = phrases.filter(({ index }) => index !== lastPhrase.index);
        setPhrases(groupIdx, [...updated, {
            index: lastPhrase.index,
            parameters: lastPhrase.parameters,
            logicOperator: operator,
            indexNextPhrase: lastPhrase.indexNextPhrase,
            parentGroupIndex: lastPhrase.parentGroupIndex,
            previewChecked: false,
        }]);
        setOpenForLogicMenu(false);
    };

    const handlerChangeLogicOperator = (phraseIndex: number, operatorIndex: PhraseWordLogicOperatorType): void => {
        if (!(operatorIndex in PhraseWordLogicOperatorType)) {
            return;
        }
        // // если есть хотя бы один оператор порядка в группе, то все операторы меняем на операторы порядка
        // if (operatorIndex === PhraseWordLogicOperatorType.order) {
        //     setPhrases(groupIdx, [...phrases.map((item) => ({
        //         ...item,
        //         logicOperator: PhraseWordLogicOperatorType.order,
        //     }))]);
        //     return;
        // }
        const changedPhrase = phrases.find(({ index }) => phraseIndex === index);
        changedPhrase.logicOperator = operatorIndex;
        setPhrases(groupIdx, [...phrases]);
    };

    const handlerChangePhraseParameters = (phraseIndex: number, value: IPhraseParameters): void => {
        const changedPhrase = phrases.find(({ index }) => phraseIndex === index);
        changedPhrase.parameters = value;
        setPhrases(groupIdx, [...phrases]);
    };

    const handlerChangePhrasePreview = (phraseIndex: number, value: boolean): void => {
        const changedPhrase = phrases.find(({ index }) => phraseIndex === index);
        changedPhrase.previewChecked = value;
        setPhrases(groupIdx, [...phrases]);
    };

    const handlerChangeFirstPhraseParameters = (value: IPhraseParameters): void => {
        const newIndex = ((lastPhrase?.index) ?? 0) + 1;
        setPhrases(groupIdx, [...phrases, {
            index: newIndex,
            parameters: value,
            logicOperator: null,
            indexNextPhrase: null,
            previewChecked: false,
            parentGroupIndex: groupIdx,
        }]);
        setOpenInitial(false);
    };

    const handlerChangeNewPhraseParameters = (value: IPhraseParameters): void => {
        const newIndex = ((lastPhrase?.index) ?? 0) + 1;
        const prevPhrases = phrases.filter(({ index }) => index !== penultimatePhrase?.index ?? -1);
        const newPhrase = {
            index: newIndex,
            parameters: value,
            logicOperator: null,
            indexNextPhrase: null,
        } as ISearchPhraseWord;
        if (penultimatePhrase) {
            setPhrases(groupIdx, [
                ...prevPhrases,
                {
                    ...penultimatePhrase,
                    indexNextPhrase: newIndex,
                },
                newPhrase,
            ]);
        } else {
            setPhrases(groupIdx, [
                ...phrases,
                newPhrase,
            ]);
        }
        setNewPhraseOpen(false);
    };

    const handlerDeletePhrase = (index: number): void => {
        const changedPhrase = phrases.find(({ index: idx }) => idx === index);
        const prev = phrases.find(({ indexNextPhrase: idx }) => idx === index);
        if (prev) {
            prev.indexNextPhrase = changedPhrase.indexNextPhrase;
        }
        const newPhrases = [
            ...phrases.filter(({ index: idx }) => idx !== index),
        ];
        setPhrases(groupIdx, newPhrases);
    };
    const handlerDeleteLogicOperator = (index: number): void => {
        const changedPhrase = phrases.find(({ index: idx }) => idx === index);
        changedPhrase.logicOperator = null;
        setPhrases(groupIdx, [...phrases]);
    };

    const handlerCloseInitialWordPhraseItemMenu = (): void => {
        setOpenInitial(false);
    };
    const handlerCloseWordPhraseItemMenu = (): void => {
        setNewPhraseOpen(false);
    };
    const handlerCloseLogicMenu = (): void => {
        setOpenForLogicMenu(false);
    };

    const handlerOpenGroupMenu = (): void => {
        setOpenGroupMenu(true);
    };
    const handlerCloseGroupMenu = (): void => {
        setOpenGroupMenu(false);
    };
    const handlerCloseGroupParametersMenu = (): void => {
        setOpenGroupParameters(false);
    };

    const handlerSelectGroupMenu = (groupMenuId: number): void => {
        if (groupMenuId === 1 && onAddGroup) {
            onAddGroup(groupIdx);
        }
        if (groupMenuId === 2) {
            onDeleteGroup(groupIdx);
        }
        if (groupMenuId === 3) {
            setOpenGroupParameters(true);
        }
        setOpenGroupMenu(false);
    };
    const handlerChangeGroupParameters = (groupIndex: number, value: IGroupParameters): void => {
        if (onChangeGroupParameters) {
            onChangeGroupParameters(groupIndex, value);
        }
        setOpenGroupParameters(false);
    };

    const initial = useMemo(() => (
        <div style={{ position: 'relative' }}>
            <div className={styles.initial}>
                <span className={styles.initial_title}>Строка состояния</span>
                <div className={styles.initial_box}>
                    <span className={styles.initial_text} ref={initialEntityRef}>
                        Добавить слово / фразу
                    </span>
                    <button className={`${styles.flexRow}`} type="button" onClick={handlerAddPhrase}>
                        <img src={PlusIcon} alt="PlusIcon" />
                    </button>
                </div>
            </div>
            <SearchPhraseParametersContextMenu
                isView={isView}
                top={0}
                left={initialContainerWidth + initialContextMenuOffset}
                isOpen={initialIsOpen}
                parameters={initialParameters}
                onChangeParameters={handlerChangeFirstPhraseParameters}
                onClose={handlerCloseInitialWordPhraseItemMenu}
            />
        </div>
    ), [
        isInitial,
        initialIsOpen,
        initialContainerWidth,
    ]);

    if (isInitial) {
        return initial;
    }

    return (
        <div id={`${id}`} style={{ position: 'relative' }}>
            <div className={styles.campaignSearchGroup_container} ref={ref}>
                <div className={styles.campaignSearchGroup_top} ref={groupRef} style={{ position: 'relative' }}>
                    <span
                        className={styles.campaignSearchGroup_title}
                        style={isPreview ? { marginLeft: 24 } : null}
                    >
                        {`${groupName} ${groupIdx}`}
                    </span>
                    <button
                        type="button"
                        onClick={handlerOpenGroupMenu}
                    >
                        <img src={HorizontalDotsIcon} alt="HorizontalDotsIcon" />
                    </button>
                    <SearchGroupContextMenu
                        isView={isView}
                        top={0}
                        left={groupWidth + contextMenuOffset}
                        isOpen={isOpenGroupMenu}
                        onSelect={handlerSelectGroupMenu}
                        onClose={handlerCloseGroupMenu}
                        excludedItems={excludedParams}
                    />
                    <SearchGroupParametersContextMenu
                        index={groupIdx}
                        top={0}
                        isView={isView}
                        left={groupWidth + contextMenuOffset}
                        isOpen={isOpenGroupParameters}
                        parameters={groupParameters}
                        onChangeParameters={handlerChangeGroupParameters}
                        onClose={handlerCloseGroupParametersMenu}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    {
                        isPreview && (
                            <CustomCheckbox
                                id={`checkbox-group-preview-${groupIdx}`}
                                style={{ gap: 0 }}
                                isDisabled={isView}
                                textClassName={styles.checkboxText}
                                name=""
                                checked={groupPreviewChecked}
                                onChecked={() => onCheckedPreview(groupIdx, !groupPreviewChecked)}
                                isRetinaSize={false}
                            />
                        )
                    }
                    <div className={styles.campaignSearchGroup_content}>
                        {
                            phrases?.length > 0 && (
                                phrases.map((tag) => (
                                    <SearchWordPhrase
                                        key={tag.index}
                                        index={tag.index}
                                        isView={isView}
                                        previewChecked={tag.previewChecked}
                                        isPreview={isPreview}
                                        onlyOrderOperators={onlyOrderOperators}
                                        withoutOrderOperators={withoutOrderOperators}
                                        parameters={tag.parameters}
                                        logicOperator={tag.logicOperator}
                                        isLastTag={!(tag.indexNextPhrase > 0)}
                                        isLoading={false}
                                        onChangeLogicOperator={handlerChangeLogicOperator}
                                        onChangeParameters={handlerChangePhraseParameters}
                                        onDeletePhrase={handlerDeletePhrase}
                                        onDeleteLogicOperator={handlerDeleteLogicOperator}
                                        onCheckedPreview={handlerChangePhrasePreview}
                                    />
                                ))
                            )
                        }
                        {
                            !isView && (
                                <button className={`${styles.btn} ${styles.flexRow}`} type="button" onClick={handlerAddEntity}>
                                    <img src={PlusIcon} alt="PlusIcon" />
                                </button>
                            )
                        }
                    </div>
                </div>
                <SearchLogicOperatorContextMenu
                    isView={isView}
                    top={0}
                    left={containerWidth + contextMenuOffset}
                    isOpen={logicMenuIsOpen}
                    onlyOrderOperators={onlyOrderOperators}
                    withoutOrderOperators={withoutOrderOperators}
                    onSelectItem={addLogicOperator}
                    onClose={handlerCloseLogicMenu}
                />
                <SearchPhraseParametersContextMenu
                    isView={isView}
                    top={0}
                    left={groupWidth + contextMenuOffset}
                    isOpen={newPhraseIsOpen}
                    parameters={initialParameters}
                    onChangeParameters={handlerChangeNewPhraseParameters}
                    onClose={handlerCloseWordPhraseItemMenu}
                />
            </div>
        </div>
    );
};

export default CampaignSearchGroup;
