import { AppState } from 'store/store';

export const selectUserProfileState = (state: AppState) => state.userProfile;
export const selectUserProfileFetchStatus = (state: AppState) => selectUserProfileState(state).status;
export const selectUserProfileFetchData = (state: AppState) => selectUserProfileState(state).data;

export const selectUserProfileEmail = (state: AppState): string => {
    const { email } = selectUserProfileState(state).data;
    return email;
};

export const selectUserProfileName = (state: AppState): string => {
    const {
        lastName, firstName, middleName,
    } = selectUserProfileState(state).data;
    return `${lastName ?? ''} ${firstName ?? ''} ${middleName ?? ''}`.trim();
};
