/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
import React, { useMemo, useRef, useEffect } from 'react';
import { RadialGraph } from '@ant-design/graphs';
import EventBusForChartGraph from '../../deprecated/pagesComponents/info-trace/PublicationChartGraph/EventBus';
import TooltipForTracing from '../TooltipForTracing';

const listColorsLine = {
    repost: '#E67E22',
    mention: '#0EBFA1',
};

const DemoRadialGraph: React.FC<any> = ({ postDate, pid, dataset }) => {
    const chartRef = useRef();
    const getFromatDataset = useMemo(() => {
        const nodes = dataset?.nodes?.map((node) => ({
            id: node[1]?.label,
            ...node[1],
            label: pid === node[0] ? 'Тек.' : 'Доч.',
            content: node[1],
        }));
        const edges = dataset?.edges?.map((edge, index) => ({
            source: `${edge[1]}`,
            target: `${edge[0]}`,
            color: listColorsLine[dataset?.edge_labels[index]] || '#787E93',
        }));
        return { nodes, edges };
    }, [dataset]);

    const fetchData = (node) => new Promise((resolve) => {
        resolve([]);
    });
    const handlerClickOnNode = (e) => {
        // eslint-disable-next-line no-underscore-dangle
        const model = e?.item?._cfg?.model;
        if (!model) EventBusForChartGraph.emit('setTooltip', [null, null, null, null]);
        // eslint-disable-next-line no-unsafe-optional-chaining
        EventBusForChartGraph.emit('setTooltip', [e?.canvasX + 20, e?.canvasY + 60, true, model]);
    };
    const asyncData = async (node) => fetchData(node);

    const config = {
        data: getFromatDataset,
        autoFit: true,
        layout: {
            unitRadius: 150,
            nodeSize: 20,
            nodeSpacing: 50,
        },
        nodeCfg: {
            asyncData,
            size: 20,
            // eslint-disable-next-line arrow-body-style
            style: (e) => {
                return {
                    fill: e?.id === `${pid}` ? '#DFFCF7' : '#E1E4E7',
                    stroke: e?.id === `${pid}` ? '#0EBFA1' : '#787E93',
                    strokeWidth: 3,
                };
            },
            labelCfg: {
                style: {
                    fontSize: 5,
                    fill: '#787E93',
                },
            },
        },
        menuCfg: {
            customContent: (e) => (
                <div>
                    <button
                        onClick={() => {
                            (chartRef as any).current.emit('node:click', e);
                        }}
                    >
                    </button>
                </div>
            ),
        },
        edgeCfg: {
            style: (e) => ({
                lineWidth: 1,
                stroke: e?.color || 'red',
            }),
            endArrow: {
                d: 10,
                size: 2,
            },
        },
        behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
        onReady: (graph) => {
            chartRef.current = graph;
            graph.on('click', handlerClickOnNode);
        },
    };
    return (
        <>
            <TooltipForTracing />
            <RadialGraph {...config} />
        </>
    );
    // return <LineChartDemo />;
};

export default DemoRadialGraph;
