/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from 'antd';
import moment from 'moment';
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import SvgIcon from '../SvgIcon';
import styles from './styles.module.scss';
import useOnClickOutside from '../../shared/hooks/useOnClickOutside';

const ListNameDayInWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
type TypeStartDateList = 'week' | 'month';
type TypeSelectDay = 'init' | 'last';
interface IDay {
    date: moment.Moment,
    formatDate: string,
    isChecked: boolean,
    weekDayCount: number
}
interface IPorpDatePicker {
    onChange: (date: any) => void;
    // eslint-disable-next-line react/no-unused-prop-types
    value?: [moment.Moment, moment.Moment]
}
type Week = (IDay | null)[];
type CalendarMatrix = Week[];
// const DatePicker: React.FunctionComponent<> = (): JSX.Element => {
const DatePicker: React.FunctionComponent<IPorpDatePicker> = ({ onChange, value }): JSX.Element => {
    const getMonth = (date: Date | moment.Moment) => moment(date);
    const getPrevMonth = (date: Date) => {
        const prev = moment(date).subtract(1, 'month');
        return getMonth(prev);
    };
    moment.locale('ru');
    const [currentMonth, setCurrentMonth] = useState<moment.Moment>(() => getMonth(new Date()));
    const [prevMonth, setPrevMonth] = useState<moment.Moment>(() => getPrevMonth(new Date()));
    const [prevCalendar, setPrevCalendar] = useState<CalendarMatrix>([]);
    const [calendar, setCalendar] = useState<CalendarMatrix>([]);
    const [initDate, setInitDate] = useState<moment.Moment | null>(() => {
        if (!value) return null;
        return value[0];
    });
    const [lastDate, setLastDate] = useState<moment.Moment | null>(() => {
        if (!value) return null;
        return value[1];
    });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [typeSelect, setTypeSelect] = useState<TypeSelectDay>('init');
    const getFormatDay = (day, format?: string):string => day.format(format || 'DD.MM.YYYY dddd');
    const getStartMonth = (day) => moment(day).startOf('month');
    const getEndMonth = (day) => moment(day).endOf('month');
    const getStartWeek = (day) => moment(day).startOf('week');
    const getEndWeek = (day) => moment(day).endOf('week');
    const refModal = useRef();
    const handlerOnClickOutside = (): void => {
        if (isOpen) {
            setIsOpen(false);
        }
    };
    useOnClickOutside(refModal, handlerOnClickOutside);
    const getListDayFromPeriod = (initialStartDay: moment.Moment, initialEndDay: moment.Moment) => {
        const startDay = initialStartDay.clone();
        const endDay = initialEndDay.clone();
        const day = startDay.clone();
        const result:CalendarMatrix = [];
        while (day.isBefore(endDay)) {
            // todo
            const cloneDay = day.clone();
            const currentDay:IDay = {
                date: cloneDay,
                formatDate: getFormatDay(day),
                isChecked: false,
                weekDayCount: cloneDay.weekday(),
            };
            if (!result.length) {
                result.push([currentDay]);
            } else {
                const lastWeek = result.length ? result[result.length - 1] : null;
                const lastDay = lastWeek?.length ? lastWeek[lastWeek.length - 1] : null;
                if (lastDay.weekDayCount === 6) {
                    result.push([currentDay]);
                } else {
                    lastWeek.push(currentDay);
                }
            }
            day.add(1, 'd');
        }
        return result;
    };
    const getDateList = (day, typeStart?: TypeStartDateList) => {
        const startMonthDay = getStartMonth(day);
        const endMonthDay = getEndMonth(day);
        if (typeStart === 'week') {
            const startInitialWeek = getStartWeek(startMonthDay);
            const endLastWeek = getEndWeek(endMonthDay);
            return getListDayFromPeriod(startInitialWeek, endLastWeek);
        }
        return getListDayFromPeriod(startMonthDay, endMonthDay);
    };
    // const test = (day) => {
    //     const withWeek = getDateList(day, 'week');
    //     const prevOnlyMonthWithWeek = getDateList(moment(day).subtract(1, 'month'), 'week');
    //     setCalendar(withWeek);
    //     setPrevCalendar(prevOnlyMonthWithWeek);
    // };
    const getIsHide = (day: IDay, isCurrent: boolean) => {
        if (isCurrent) {
            const startDateFromCurrentDay = getStartMonth(day.date);
            const startDayInCurrentMonth = getStartMonth(currentMonth);
            return startDateFromCurrentDay.isBefore(startDayInCurrentMonth);
        }
        const endPrevMonth = getEndMonth(prevMonth);
        return day.date.isAfter(endPrevMonth);
    };
    const getIsPassive = (day: IDay, isCurrent: boolean) => {
        if (isCurrent) {
            const endCurrentMonth = getEndMonth(currentMonth);
            return day.date.isAfter(endCurrentMonth);
        }
        const startPrevMonth = getStartMonth(prevMonth);
        return day.date.isBefore(startPrevMonth);
    };
    const getDayClasses = (day: IDay, isCurrent: boolean) => {
        const { date } = day;
        const hide = getIsHide(day, isCurrent)
            ? styles.hide : '';
        const passive = getIsPassive(day, isCurrent) ? styles.passive : '';
        const isInitDay = date.isSame(initDate);
        const isLastDay = date.isSame(lastDate);
        const isAfterInitDay = date.isAfter(initDate);
        const isBeforeLastDate = date.isBefore(lastDate);
        const selectDate = isAfterInitDay && isBeforeLastDate ? styles.selectDay : '';
        const initDay = isInitDay ? styles.initDay : '';
        const lastDay = isLastDay ? styles.lastDay : '';
        const isSelectOneDay = lastDate?.isSame(initDate) && lastDate?.format('DD.MM.YYYY') === day.date.format('DD.MM.YYYY');
        const alllSelect = isSelectOneDay ? `${styles.lastDay} ${styles.initDay}` : '';
        return `${styles.labelDay} ${passive} ${selectDate} ${hide} ${initDay} ${lastDay} ${alllSelect}`;
    };
    const selectPeriodState = (day: IDay, isCurrent): any => {
        // eslint-disable-next-line no-useless-return
        const { date } = day;
        if (getIsHide(day, isCurrent)) return;
        if (!initDate && !lastDate) {
            setInitDate(date);
            setLastDate(date);
            setTypeSelect('last');
            return;
        }
        if (typeSelect === 'init') {
            if (date.isSame(lastDate) || date.isSame(initDate)) {
                setInitDate(date);
                setTypeSelect('init');
            } else if (date.isBefore(lastDate)) {
                setInitDate(date);
                setTypeSelect('last');
            } else if (date.isAfter(lastDate)) {
                setInitDate(lastDate.clone());
                setLastDate(date);
                setTypeSelect('init');
            }
        } else if (typeSelect === 'last') {
            if (date.isSame(lastDate) || date.isSame(initDate)) {
                setLastDate(date);
                setTypeSelect('last');
            } else if (date.isAfter(initDate)) {
                setLastDate(date);
                setTypeSelect('init');
            } else if (date.isBefore(initDate)) {
                setInitDate(date);
                setTypeSelect('last');
            }
        }
    };
    // eslint-disable-next-line arrow-body-style
    const getWeek = (week, isCurrent) => {
        return week.map((day) => (
            <div
                className={getDayClasses(day, isCurrent)}
                key={`${day.formatDate}_${day.weekDayCount}`}
                onClick={() => selectPeriodState(day, isCurrent)}
            >
                {day.date.format('D')}
            </div>
        ));
    };
    const getCurrentMonthCalendar = useMemo(() => (
        calendar.map((week, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.weekContainer} key={`week_${index}`}>
                {getWeek(week, true)}
            </div>
        ))
    ), [calendar, initDate, lastDate]);
    const getPrevCalendar = useMemo(() => (
        prevCalendar.map((week, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.weekContainer} key={`week_${index}`}>
                {getWeek(week, false)}
            </div>
        ))
    ), [prevCalendar, initDate, lastDate]);
    const handlerNextMonth = () => {
        setPrevMonth(currentMonth.clone());
        const nextMonth = currentMonth.clone();
        nextMonth.add(1, 'month');
        setCurrentMonth(nextMonth);
    };
    const handlerPrevMonth = () => {
        setCurrentMonth(prevMonth.clone());
        const newPrevMonth = prevMonth.clone();
        newPrevMonth.subtract(1, 'month');
        setPrevMonth(newPrevMonth);
    };
    useEffect(() => {
        // currentMonth
        // prevMonth
        const currentDateList = getDateList(currentMonth, 'week');
        const prevDateList = getDateList(prevMonth, 'week');
        setCalendar(currentDateList);
        setPrevCalendar(prevDateList);
    }, [currentMonth, prevMonth]);
    const getLabelInitDate = useMemo(() => {
        if (!initDate) return 'дд.мм.гггг';
        return initDate.format('DD.MM.YYYY');
    }, [initDate]);
    const getLabelLastDate = useMemo(() => {
        if (!lastDate) return 'дд.мм.гггг';
        return lastDate.format('DD.MM.YYYY');
    }, [lastDate]);
    const acceptChange = () => {
        onChange([initDate, lastDate]);
        setIsOpen(false);
    };
    const setTimePeriod = (type: string) => {
        const listHandlers = {
            today: () => {
                const today = moment(new Date());
                setInitDate(today.clone());
                setLastDate(today.clone());
                setTypeSelect('last');
            },
            week: () => {
                const today = moment(new Date());
                setInitDate(today.clone().startOf('week'));
                setLastDate(today.clone().endOf('week').subtract(23, 'hours').subtract(59, 'minutes'));
                setTypeSelect('init');
            },
            month: () => {
                const today = moment(new Date());
                setInitDate(today.clone().startOf('month'));
                setLastDate(today.clone().endOf('month').endOf('week').subtract(23, 'hours')
                    .subtract(59, 'minutes'));
                setTypeSelect('init');
            },
        };
        listHandlers[type]();
    };
    return (
        <div className={styles.conatinerDatePicker}>
            <div className={styles.currentPeriod} onClick={() => setIsOpen(true)}>
                <div style={{ marginBottom: '-12px', marginRight: '8px' }}>
                    <SvgIcon id="Calendar" />
                </div>
                <span id="track-filter-date-start">{getLabelInitDate}</span>
                <span id="track-filter-date-start" style={{ marginRight: '6px', marginLeft: '6px' }}>-</span>
                <span>{getLabelLastDate}</span>
            </div>
            { isOpen
                ? (
                    <div ref={refModal}>
                        <div className={styles.conatinerCalendar}>
                            <div className={styles.bodyCalendar}>
                                <div className={styles.containerMonth}>
                                    <div className={styles.monthTitle}>
                                        <div className={`${styles.icn} ${styles.prev}`} onClick={handlerPrevMonth}>
                                            <SvgIcon id="ArrowLeft" />
                                        </div>

                                        {prevMonth.format('MMMM YYYY')}
                                    </div>
                                    <div className={styles.weekContainer}>
                                        {
                                            ListNameDayInWeek.map((day) => (
                                                <div className={`${styles.labelDay} ${styles.name}`} key={day}>
                                                    {day}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    { getPrevCalendar }
                                </div>
                                <div className={styles.containerMonth}>
                                    <div className={styles.monthTitle}>
                                        {currentMonth.format('MMMM YYYY')}
                                        <div className={`${styles.icn} ${styles.next}`} onClick={handlerNextMonth}>
                                            <SvgIcon id="ArrowLeft" />
                                        </div>
                                    </div>
                                    <div className={styles.weekContainer}>
                                        {
                                            ListNameDayInWeek.map((day) => (
                                                <div className={`${styles.labelDay} ${styles.name}`} key={day}>
                                                    {day}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    { getCurrentMonthCalendar }
                                </div>
                            </div>
                            <div className={styles.footer}>
                                <div className={styles.footerActions}>
                                    <button
                                        type="button"
                                        onClick={() => setTimePeriod('today')}
                                    >
                                        сегодня
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setTimePeriod('week')}
                                    >
                                        неделя
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setTimePeriod('month')}
                                    >
                                        месяц
                                    </button>
                                </div>

                                <button
                                    type="button"
                                    className={styles.footerConfirmButton}
                                    onClick={acceptChange}
                                >
                                    Применить
                                </button>
                            </div>
                        </div>
                    </div>
                ) : ''}
        </div>
    );
};

export default DatePicker;
