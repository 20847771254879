import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@shared/index';
import {
    IGroupParameters, ISearchGroup, ISearchPhraseWord, PhraseWordLogicOperatorType,
} from './types';
import CampaignSearchGroup from './Group';
import helpers from './helpers';
import SearchNestedGroup from './Group/NestedGroup';
import CrossIcon from './imgs/cross.svg';
import styles from './styles.module.scss';

interface IProps {
    isView: boolean;
    isPreview: boolean;
    campaignId: number;
    searchRequest: ISearchGroup;
    onSave: (value: ISearchGroup) => void;
    onChangePreviewState: (value: boolean) => void;
    onLoadPreview: (value: ISearchGroup) => void;
}

const initialGroupParameters: IGroupParameters = {
    considerSequence: false,
    distanceEntities: null,
    numberMatches: null,
};

const CampaignSearchRequest: React.FC<IProps> = ({
    isView,
    isPreview,
    campaignId,
    searchRequest,
    onSave,
    onChangePreviewState,
    onLoadPreview,
}): JSX.Element => {
    const [mainGroup, setMainGroup] = useState<ISearchGroup>({
        id: 1,
        index: 1,
        parentIndex: null,
        phrases: [],
        children: null,
        parameters: initialGroupParameters,
        previewChecked: false,
    });
    const [lastIndex, setLastIndex] = useState<number>(1);
    const [needCheckedForGroups, setCheckedForGroups] = useState<boolean>(false);

    const saveIsDisabled = useMemo<boolean>(() => (
        !(mainGroup?.children?.length > 0 || mainGroup?.phrases?.length > 0)
    ), [mainGroup]);

    const handlerAddGroup = (groupIndex: number): void => {
        const newIndex = lastIndex + 1;
        const current = helpers.getGroup(mainGroup, groupIndex);
        if (current.parentIndex !== null) {
            const parent = helpers.getGroup(mainGroup, current.parentIndex);
            if (parent?.children.length > 0) {
                parent.children.push({
                    id: newIndex,
                    index: newIndex,
                    parentIndex: current.parentIndex,
                    phrases: [],
                    children: null,
                    parameters: current.parameters,
                    previewChecked: current.previewChecked,
                });
                current.indexNextGroup = newIndex;
            } else {
                parent.phrases = [];
                parent.children = [current];
            }
            // JSON.parse(JSON.stringify(mainGroup))
            setMainGroup(helpers.resetGroupIndex({
                ...mainGroup,
                // phrases: [...parent.phrases],
                // children: [...parent.children],
            }, 1, null, null));
            setLastIndex(newIndex);
        } else {
            current.parentIndex = newIndex;
            current.indexNextGroup = newIndex + 1;
            const newGroup = {
                id: newIndex,
                index: newIndex,
                parentIndex: null,
                phrases: [],
                previewChecked: false,
                children: [
                    current,
                    {
                        id: newIndex + 1,
                        index: newIndex + 1,
                        parentIndex: newIndex,
                        phrases: [],
                        children: null,
                        parameters: initialGroupParameters,
                        previewChecked: current.previewChecked,
                    },
                ],
                parameters: initialGroupParameters,
            };
            setMainGroup(helpers.resetGroupIndex(newGroup, 1, null, null));
            setLastIndex(newIndex + 1);
        }
    };

    // {
    //     id: 1,
    //     index: 1,
    //     parentIndex: null,
    //     phrases: [],
    //     children: null,
    //     parameters: initialGroupParameters,
    //     previewChecked: false,
    // }

    const handlerDeleteGroup = (groupIndex: number): void => {
        const current = helpers.getGroup(mainGroup, groupIndex);
        if (current) {
            const withoutIndex = helpers.getGroupWithoutIndex(mainGroup, groupIndex);
            if (!withoutIndex) {
                setMainGroup({ ...helpers.getInitialGroup(), id: 1, index: 1 });
            } else {
                // JSON.parse(JSON.stringify(withoutIndex))
                setMainGroup(helpers.resetGroupIndex(withoutIndex, 1, null, null));
            }
        }
    };
    const handlerSetPhrases = (groupIndex: number, value: ISearchPhraseWord[]): void => {
        const current = helpers.getGroup(mainGroup, groupIndex);
        if (!current) {
            return;
        }
        const orderOperatorExists = value
            .some(({ logicOperator }) => logicOperator === PhraseWordLogicOperatorType.order);
        current.phrases = orderOperatorExists ? value.map((item) => (
            {
                ...item,
                logicOperator: (item?.logicOperator in PhraseWordLogicOperatorType)
                    ? PhraseWordLogicOperatorType.order
                    : null,
            }
        )) : value;
        current.previewChecked = current?.children?.some(
            (item) => item?.phrases?.some(({ previewChecked }) => previewChecked),
        );
        setMainGroup(JSON.parse(JSON.stringify(mainGroup)));
        setCheckedForGroups(true);
    };
    const handlerSetOperator = (groupIndex: number, operatorIndex: PhraseWordLogicOperatorType): void => {
        if (!(operatorIndex in PhraseWordLogicOperatorType)) {
            return;
        }
        const current = helpers.getGroup(mainGroup, groupIndex);
        if (!current) {
            return;
        }

        // // если есть хотя бы один оператор порядка, то все операторы меняем на операторы порядка
        // if (operatorIndex === PhraseWordLogicOperatorType.order) {
        //     const parent = helpers.getGroup(mainGroup, current.parentIndex);
        //     if (parent && parent?.children?.length > 0) {
        //         for (let i = 0; i < parent.children.length; i += 1) {
        //             // только не для последних групп
        //             if (parent.children[i]?.indexNextGroup > 0) {
        //                 parent.children[i].operator = PhraseWordLogicOperatorType.order;
        //             }
        //         }
        //         setMainGroup(JSON.parse(JSON.stringify(mainGroup)));
        //         return;
        //     }
        // }

        current.operator = operatorIndex;
        setMainGroup(JSON.parse(JSON.stringify(mainGroup)));
    };
    const handlerDeleteOperator = (groupIndex: number): void => {
        const current = helpers.getGroup(mainGroup, groupIndex);
        if (current) {
            current.operator = null;
            setMainGroup(JSON.parse(JSON.stringify(mainGroup)));
        }
    };
    const handlerChangeGroupParameters = (groupIndex: number, value: IGroupParameters): void => {
        const current = helpers.getGroup(mainGroup, groupIndex);
        current.parameters = value;
        setMainGroup(JSON.parse(JSON.stringify(mainGroup)));
    };
    const handlerChangeGroupPreview = (groupIndex: number, value: boolean): void => {
        const current = helpers.getGroup(mainGroup, groupIndex);
        current.previewChecked = value;
        setMainGroup(JSON.parse(JSON.stringify(mainGroup)));
    };
    const handlerLoadPreviewData = (): void => {
        if (isPreview && onLoadPreview) {
            onLoadPreview(JSON.parse(JSON.stringify(mainGroup)));
        }
    };
    const handlerActivatePreview = (): void => {
        if (!isPreview && onChangePreviewState) {
            onChangePreviewState(true);
        }
    };
    const handlerCancelPreview = (): void => {
        if (isPreview && onChangePreviewState) {
            onChangePreviewState(false);
            setCheckedForGroups(false);
        }
    };

    useEffect(() => {
        if (searchRequest) {
            setMainGroup(searchRequest);
        }
    }, [searchRequest]);
    useEffect(() => {
        if (isPreview && needCheckedForGroups) {
            setMainGroup(JSON.parse(JSON.stringify(helpers.checkedPreview(mainGroup))));
            setCheckedForGroups(false);
        }
    }, [isPreview, mainGroup, needCheckedForGroups]);

    return (
        <div id={`${campaignId ?? 'not-created-campaign-group'}`} className={styles.campaignSearchRequest_root}>
            <div className={styles.flexRow} style={{ justifyContent: 'flex-end', padding: '6px 0' }}>
                {
                    isPreview
                        ? (
                            <div className={`${styles.flexRow}`} style={{ gap: 16 }}>
                                <button type="button" onClick={handlerLoadPreviewData}>
                                    <span className={styles.preview_text}>Проверить выбранное </span>
                                </button>
                                <button
                                    type="button"
                                    className={`${styles.flexRow}`}
                                    style={{ gap: 10 }}
                                    onClick={handlerCancelPreview}
                                >
                                    <span className={styles.preview_cancelText}>Сбросить</span>
                                    <img src={CrossIcon} alt="CrossIcon" height={16} />
                                </button>
                            </div>
                        )
                        : (
                            <button type="button" onClick={handlerActivatePreview}>
                                <span className={styles.preview_text}>Предварительная проверка</span>
                            </button>
                        )
                }

            </div>
            <div className={styles.campaignSearchRequest_container}>
                <div className={styles.campaignSearchRequest_contentColumn}>
                    {
                        mainGroup.children?.length > 0 && (
                            <SearchNestedGroup
                                isView={isView}
                                isPreview={isPreview}
                                group={mainGroup}
                                onAddGroup={handlerAddGroup}
                                onChangeGroupParameters={handlerChangeGroupParameters}
                                onGroupPreviewChecked={handlerChangeGroupPreview}
                                onDeleteGroup={handlerDeleteGroup}
                                onAddPhrase={handlerSetPhrases}
                                setOperator={handlerSetOperator}
                                deleteOperator={handlerDeleteOperator}
                            />
                        )
                    }
                    {
                        !(mainGroup.children?.length > 0) && (
                            <CampaignSearchGroup
                                id={mainGroup.index}
                                isView={isView}
                                isPreview={isPreview}
                                index={mainGroup.index}
                                nextGroupIndex={mainGroup.indexNextGroup}
                                phrases={mainGroup.phrases}
                                groupParameters={mainGroup.parameters ? mainGroup.parameters : initialGroupParameters}
                                onAddGroup={handlerAddGroup}
                                onChangeGroupParameters={handlerChangeGroupParameters}
                                onDeleteGroup={handlerDeleteGroup}
                                setPhrases={handlerSetPhrases}
                                groupPreviewChecked={mainGroup.previewChecked}
                                onCheckedPreview={handlerChangeGroupPreview}
                            />
                        )
                    }
                </div>
                <div className={styles.campaignSearchRequest_controlsColumn}>
                    <Button
                        text="Сохранить"
                        type="default"
                        size="large"
                        onClick={() => onSave(JSON.parse(JSON.stringify(mainGroup)))}
                        disabled={saveIsDisabled || isView}
                    />
                </div>
            </div>
        </div>
    );
};

export default CampaignSearchRequest;
