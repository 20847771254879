import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { ISimpleEntity } from '@store/types';
import Path from '@shared/lib/paths';
import LeftNarrowMenu from '@components/common/NarrowMenu';
import { campaignActions } from '@store/campaign/source';
import campaignSelectors from '@store/campaign/source/selectors';
import currentPageSelectors from '@store/common/current-page/selectors';
import { CampaignActiveModalType, ModalAnswerType } from '@store/campaign/source/types';
import { leftMenuActions } from '@store/common/left-menu';
import { useAppDispatch } from '@store/hooks';
import { campaignComparisonActions } from '@store/campaign/comparison';
import { campaignCommonActions } from '@store/campaign/common';

const NarrowMenuContainer: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = useParams();
    const dispatch = useAppDispatch();
    const campaignCreatingBegun = useSelector(campaignSelectors.createBegun);
    const campaignActiveModal = useSelector(campaignSelectors.activeModal);
    const campaignModalAnswer = useSelector(campaignSelectors.modalAnswer);
    // const activeCampaign = useSelector(campaignCommonSelectors.leftMenuActiveCampaign);
    const campaign = useSelector(currentPageSelectors.currentCampaign);

    const [activeNavigateHref, setActiveNavHref] = useState<string>(null);
    const [activeCampaignId, setCampaignId] = useState<number | null>(null);

    const activeCampaign = useMemo<ISimpleEntity>(() => (
        campaign ? ({ id: campaign.id, name: campaign.name, value: campaign.name }) : null
    ), [campaign]);

    const handlerNavigate = (path: string): void => {
        if (campaignCreatingBegun) {
            dispatch(campaignActions.setActiveModal(CampaignActiveModalType.switchingPage));
            setActiveNavHref(path);
        } else {
            setActiveNavHref(null);
            dispatch(campaignComparisonActions.clearAll());
            if (path) {
                navigate(path);
            }
        }
    };
    const handlerSetActiveCampaign = (id: number): void => {
        if (activeCampaignId !== id) {
            setCampaignId(id);
            dispatch(campaignActions.clearCurrentCampaign());
            dispatch(campaignActions.setViewActionCampaign());
            dispatch(campaignCommonActions.setLeftMenuCampaignId(id));
            navigate(`/${Path.Campaign}/${Path.CampaignUpdate}/${id}`);
        }
    };

    const setMenuExpanded = (bool: boolean): void => {
        dispatch(leftMenuActions.setExpanded(bool));
    };

    useEffect(() => {
        if (!activeNavigateHref) {
            return;
        }
        if (campaignActiveModal === CampaignActiveModalType.switchingPage) {
            if (campaignModalAnswer === ModalAnswerType.accept) {
                navigate(activeNavigateHref);
                setActiveNavHref(null);
            }
            if (campaignModalAnswer !== ModalAnswerType.undefined) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
            }
        }
    }, [campaignModalAnswer]);
    useEffect(() => {
        if ((urlParams?.campaignId || urlParams?.Id) && location.pathname.includes(Path.Campaign)) {
            const id = urlParams?.campaignId ? Number(urlParams?.campaignId) : Number(urlParams?.Id);
            if (activeCampaignId !== id) {
                setCampaignId(Number.isInteger(id) ? id : null);
            }
        } else {
            setCampaignId(null);
        }
    }, [urlParams, activeCampaignId]);

    return (
        <LeftNarrowMenu
            onNavigate={handlerNavigate}
            location={location.pathname}
            setMenuExpanded={setMenuExpanded}
            activeCampaignId={activeCampaignId}
            activeCampaign={activeCampaign}
            setActiveCampaign={handlerSetActiveCampaign}
        />
    );
};

export default NarrowMenuContainer;
