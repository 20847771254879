import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import classnames from 'classnames';
import SvgIcon from '@components/SvgIcon';
import {
    debounceTime, distinctUntilChanged, filter, fromEvent, map,
} from 'rxjs';
import styles from './styles.module.scss';

interface IProps {
    placeholder: string;
    searchValue: string;
    onSearch: (value: string) => void;
    onStartSearch?: (value: string) => void;
}

const FiltersSearchField: React.FC<IProps> = ({
    placeholder, searchValue, onSearch, onStartSearch,
}): JSX.Element => {
    const searchInputRef = useRef(null);
    const [isInputFocused, setInputFocus] = useState<boolean>(false);

    const magnifierIsVisible = useMemo<boolean>(() => (
        !isInputFocused && !(searchValue?.length > 0)
    ), [isInputFocused, searchValue]);

    const handlerChangeSearchValue = (value: string): void => {
        if (onSearch) {
            onSearch(value);
        }
    };
    useEffect(() => {
        if (onStartSearch) {
            const searchInput = searchInputRef.current;

            const search$ = fromEvent(searchInput, 'input').pipe(
                map((event: React.ChangeEvent<HTMLInputElement>) => event.target.value),
                debounceTime(500),
                filter((searchParam) => (!searchParam || searchParam.length >= 3)),
                distinctUntilChanged(),
            );

            const subscription = search$.subscribe((res) => onStartSearch(res));
            return () => {
                subscription.unsubscribe();
            };
        }
        return () => null;
    }, []);
    return (
        <div
            className={classnames(
                styles.searchField,
                isInputFocused ? styles.searchField_focus : '',
            )}
            style={magnifierIsVisible ? {} : { justifyContent: 'space-between' }}
        >
            {magnifierIsVisible && (
                <div className={styles.searchField_imgSearch}>
                    <SvgIcon id="Search" size={16} />
                </div>
            )}
            <input
                ref={searchInputRef}
                placeholder={placeholder}
                className={styles.searchField_input}
                style={!magnifierIsVisible ? { marginLeft: 10 } : {}}
                autoComplete="on"
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                onChange={(event) => handlerChangeSearchValue(event.target.value)}
                value={searchValue}
            />
            {
                searchValue?.length > 0 && (
                    <button
                        type="button"
                        className={styles.searchField_imgCross}
                        onClick={() => handlerChangeSearchValue('')}
                    >
                        <SvgIcon id="Cross" size={14} />
                    </button>
                )
            }
        </div>
    );
};

export default FiltersSearchField;
