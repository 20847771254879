import classnames from 'classnames';
import { useState } from 'react';
import IconMore from '../arrowMore.svg';
import styles from './styles.module.scss';

interface IProps {
    links: string[]
}

const ModalCardPlotLinks: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { links } = props;

    const [hidden, setHidden] = useState<boolean>(links && links.length > 2);

    if (!links || links.length < 1) return null;

    return (
        <div className={styles.container}>
            <p className={styles.title}>Обнаруженные ссылки:</p>

            <div className={classnames(styles.list, hidden ? styles.listHidden : undefined)}>
                {links.map((link) => (
                    <a key={link} href={link} target="_blank" rel="noreferrer">{link}</a>
                ))}

                {hidden
                    && (
                        <div className={styles.listMore}>
                            <button type="button" onClick={() => setHidden(false)}>
                                <img src={IconMore} alt="Icon More" />
                            </button>
                        </div>
                    )}
            </div>
        </div>
    );
};
export default ModalCardPlotLinks;
