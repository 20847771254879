import moment from 'moment';
import { IDocument } from '@components/Project/types';
import { IDocumentModel } from '@services/projects/types';
// import { IDocumentModel } from '../types/list/models';

export const getListDocuments = (documents: IDocumentModel[]): IDocument[] => {
    const sortedDocuments = documents.sort((a, b) => new Date(a.upload_time).getTime() - new Date(b.upload_time).getTime());
    return sortedDocuments.map((document) => ({
        id: document.id,
        label: document.name,
        date: moment(document.upload_time).format('DD.MM.YYYY'),
        type_id: document?.type_id,
        type: document.extension as any,
    }));
};
