import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnalyzeAnalyticsState, IAnalyzeAnalyticsEntity, IAnalyzeAnalyticsAuthors } from './types';
import { convertApiToAnalyzeAnalytics } from './converter';

const initialState: IAnalyzeAnalyticsState = {
    rss: {} as IAnalyzeAnalyticsEntity,
    tg: {} as IAnalyzeAnalyticsEntity,
    ok: {} as IAnalyzeAnalyticsEntity,
    total: {} as IAnalyzeAnalyticsEntity,
    vk: {} as IAnalyzeAnalyticsEntity,
    authors: {} as IAnalyzeAnalyticsAuthors,
};

const { actions: analyzeAnalyticsActions, reducer } = createSlice({
    name: 'analyzeAnalytics',
    initialState,
    reducers: {
        setAnalytics: (state: IAnalyzeAnalyticsState, { payload }: PayloadAction<unknown>) => {
            const buffer = convertApiToAnalyzeAnalytics(payload);
            return buffer;
        },
    },
});

export { analyzeAnalyticsActions };
export default reducer;
