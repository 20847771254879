import React, { useMemo, useState } from 'react';
import { ArrowIconsIds } from '@app/constants/icons';
import SvgIcon from '../../SvgIcon';
import BlockLoadFile from '../../../entities/BlockLoadFile';
import DocumentList from '../DocumentList';
import styles from './styles.module.scss';
import useDocuments from '../hooks/useDocuments';

interface IPropsCurrentDocuments {
    projectId: number,
}

const ProjectTemplates: React.FC<IPropsCurrentDocuments> = ({
    projectId,
}): JSX.Element => {
    const [
        savedDocuments,
        {
            isInitialisationDocuments,
            statusNewDocument,
        },
        {
            handleRemoveFile,
            setStatusNewDocument,
            handleUploadFile,
            onChangeNameInList,
        },
    ] = useDocuments(true, projectId);
    const [openList, setOpenList] = useState(false);
    const handleChangeOpen = () => {
        setOpenList((prev) => !prev);
    };

    const wrapperStyles = useMemo<React.CSSProperties>(() => ({
        visibility: openList ? 'visible' : 'hidden',
        height: openList ? 'fit-content' : '0px',
        zIndex: openList ? 2 : -100,
    }), [openList]);

    const templatesTitle = (
        <div
            role="button"
            className={`${styles.titleTemplates} ${openList ? styles.open : styles.close}`}
            onClick={() => handleChangeOpen()}
        >
            <span className={styles.templateTitle}>
                Шаблоны документов
            </span>
            <SvgIcon size={24} id={ArrowIconsIds.DropdownArrow3} />
        </div>
    );

    return (
        <div className={styles.root_CurrentDocuments}>
            {
                templatesTitle
            }
            {
                openList && (
                    <div className={styles.content} style={wrapperStyles}>
                        <DocumentList
                            isLoading={isInitialisationDocuments}
                            isLoadFile={statusNewDocument === 'loading'}
                            documentList={savedDocuments}
                            onRemoveFile={handleRemoveFile}
                            onChangeNameInList={onChangeNameInList}
                        />
                        <BlockLoadFile
                            onChangeStatus={(newStatus) => setStatusNewDocument(newStatus)}
                            status={statusNewDocument}
                            onUploadFile={handleUploadFile}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default ProjectTemplates;
