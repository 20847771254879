import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

interface IProps {
    isLoading: boolean;
    title: string;
    quantity: number;
}
const CampaignDnDUsersReaction: React.FC<IProps> = ({
    isLoading,
    title,
    quantity,
}): JSX.Element => (
    <div className={styles.campaignDnDUsersReaction_root}>
        <span className={styles.statisticsByCategory_title}>{title}</span>
        {
            isLoading && (
                <Skeleton height={20} width={180} />
            )
        }
        {
            !isLoading && (
                <span className={styles.statisticsByCategory_amount}>{quantity}</span>
            )
        }
        {/* <span className={styles.statisticsByCategory_percent} style={{ color: '#0EBFA1' }}> */}
        {/*    {Number.isInteger(percent) && percent > 0 ? `${percent}%` : '-'} */}
        {/* </span> */}
    </div>
);

export default CampaignDnDUsersReaction;
