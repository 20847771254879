import React, { useCallback, useMemo, useState } from 'react';
import { IFilterCampaigns } from 'store/campaign/comparison';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import styles from './styles.module.scss';
import { ISliderGradient } from '../../types';
import SvgIcon from '../../../../../SvgIcon';

interface IRegion{
    name: string,
    data: IElement[]
}
interface IElement{
    id: number;
    name: string;
    percent: number;
    quantity: number;
    quantityStr: string;
}

interface IProps {
    filters: IFilterCampaigns[];
    referenceCampaign: ICampaignDataModel | null;
    compareCampaigns: ICampaignDataModel[];
}

const filterType = 'Регион';
const title = 'Регионы';
const stylesSlide: ISliderGradient = {
    style1: styles.dropDownRegion_slide,
    style2: styles.leftBlur,
    style3: styles.rightBlur,
};
const stylesSlideText: ISliderGradient = {
    style1: styles.dropDownRegion_text,
    style2: styles.leftTextBlur,
    style3: styles.rightTextBlur,
};

const calculatePercentDiff = (percent): string => {
    if (!percent || Number.isNaN(percent)) return '-';
    if (!(percent - 100)) return '0%';
    if (percent - 100 < 100) return `${(percent - 100)?.toLocaleString('ru-RU')}%`;
    return `${(percent)?.toLocaleString('ru-RU')}%`;
};

const getGradient = (data: ISliderGradient) => (count: number, categoriesLength: number) => (index: number): string => {
    if (count && index === count) {
        return `${data.style1} ${data.style2}`;
    }
    if ((count + 4) === index && count !== categoriesLength - 5) {
        return `${data.style1} ${data.style3}`;
    }
    return `${data.style1}`;
};

const getGradientSlide = getGradient(stylesSlide);
const getGradientSlideText = getGradient(stylesSlideText);

const CampaignComparisonRegion: React.FC<IProps> = ({
    filters,
    referenceCampaign,
    compareCampaigns,
}): JSX.Element => {
    const categories = useMemo<string[]>(() => (
        filters?.find(
            (element) => element.campaignId === referenceCampaign.id,
        )?.filters
            .find((item) => item.filtersCategory === filterType)?.filters
            .map((el) => el.filter_name)
    ), [filters, referenceCampaign]);

    const filteredCategories = useMemo<string[]>(() => {
        const result: string[] = [];
        categories?.forEach((nameCategory) => {
            const array = filters?.map((company) => company?.filters
                .find((category) => category.filtersCategory === filterType)?.filters
                .find((item) => item.filter_name === nameCategory)?.filter_name);
            if (array.indexOf(undefined) === -1) result.push(array[0]);
        });
        return result;
    }, [categories]);

    const shift = 158;
    const [activeDropDown, setActiveDropDown] = useState<boolean>(true);
    const [count, setCount] = useState<number>(0);
    const [category, setCategory] = useState<React.CSSProperties>({ transform: 'translateX(0)' });
    const [translateValue, setTranslateValue] = useState<number>(0);

    const getHeaderGradient = useMemo(() => getGradientSlide(
        count,
        filteredCategories.length,
    ), [filteredCategories, count]);
    const getHeaderTextGradient = useMemo(() => getGradientSlideText(
        count,
        filteredCategories.length,
    ), [filteredCategories, count]);

    const backArrowSlide = () => {
        if (count) {
            setCategory({ transform: `translateX(${translateValue + shift}px)` });
            setTranslateValue(translateValue + shift);
            setCount(count - 1);
        }
    };
    const forwardArrowSlide = () => {
        if ((filteredCategories.length - 5 !== count) && (filteredCategories.length - 1)) {
            setCategory({ transform: `translateX(${translateValue - shift}px)` });
            setTranslateValue(translateValue - shift);
            setCount(count + 1);
        }
    };

    const findCampaignAndFilters = useCallback((campaignId: number, type: string, filterName: string): number => {
        const result = (filters
            .find(({ campaignId: itemId }) => itemId === campaignId)?.filters
            .find((item) => item.filtersCategory === type)?.filters
            .find(({ filter_name }) => filter_name === filterName)?.count) ?? 0;
        return (Number.isNaN(result) ? 0 : result);
    }, [filters]);

    const calculatedPercent = (campaignId: number, value: string): number => {
        const quantity = findCampaignAndFilters(campaignId, filterType, value);
        const result = Math.round((quantity / findCampaignAndFilters(referenceCampaign.id, filterType, value)) * 10000) / 100;
        return Number.isNaN(result) ? 0 : result;
    };

    const arrObj = useMemo<IRegion[]>(
        () => {
            const result = [];
            filteredCategories.forEach((value) => {
                result.push({
                    name: value,
                    data: [
                        {
                            id: referenceCampaign.id,
                            name: referenceCampaign.name,
                            percent: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantity: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantityStr: findCampaignAndFilters(referenceCampaign.id, filterType, value)
                                .toLocaleString('ru-RU'),
                        } as IElement,
                        ...compareCampaigns.map(({ id: itemId, name }) => {
                            const quantity = findCampaignAndFilters(itemId, filterType, value) ?? 0;
                            return {
                                id: itemId,
                                name,
                                percent: calculatedPercent(itemId, value),
                                quantity,
                                quantityStr: quantity.toLocaleString('ru-RU'),
                            } as IElement;
                        }),
                    ],
                } as IRegion);
            });
            return result;
        },
        [referenceCampaign, compareCampaigns],
    );

    return (
        <div className={styles.dropDownRegion}>
            <div className={styles.dropDownRegion_lineStart}>
                <div className={styles.dropDownRegion_name}>{title}</div>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => null}
                    onClick={() => setActiveDropDown(!activeDropDown)}
                    className={styles.dropDownRegion_arrow}
                >
                    <div style={activeDropDown ? { transform: ' rotate(0)' } : { transform: ' rotate(180deg)' }}>
                        <SvgIcon id="DropDownArrow" size={17} height={12} />
                    </div>
                </div>
            </div>
            {activeDropDown && (
                <div className={styles.dropDownRegion_block}>
                    <div className={styles.dropDownRegion_list}>
                        <div className={styles.dropDownRegion_title}>
                            <div
                                key={referenceCampaign.id}
                                className={styles.dropDownRegion_cell}
                                style={{ color: '#0ebfa1' }}
                            >
                                {referenceCampaign.name}
                            </div>
                            {compareCampaigns.map((element) => (
                                <div
                                    key={element.id}
                                    className={styles.dropDownRegion_cell}
                                >
                                    {element.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    {!!arrObj.length && (
                        <div className={styles.dropDownRegion_rowHeader}>
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => null}
                                onClick={() => backArrowSlide()}
                                style={{ cursor: 'pointer' }}
                            >
                                {arrObj.length > 5 && (
                                    <div className={styles.dropDownRegion_imgBack}>
                                        <SvgIcon id="DropDownArrow" size={17} height={12} />
                                    </div>
                                )}
                            </div>
                            <div className={styles.dropDownRegion_slider}>
                                {arrObj.map((element, indexArrObj) => (
                                    <div key={element.name}>
                                        <div className={getHeaderGradient(indexArrObj)} style={category}>
                                            <span className={getHeaderTextGradient(indexArrObj)}>{element.name}</span>
                                        </div>
                                        <div className={styles.dropDownRegion_info} style={category}>
                                            <div className={styles.dropDownRegion_quantity}>
                                                {element.data.map((item) => (
                                                    <div
                                                        key={item.id}
                                                        className={styles.dropDownRegion_cell}
                                                    >
                                                        {item.quantityStr}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className={styles.dropDownRegion_percent}>
                                                {element.data.map((item, index) => {
                                                    if (!index) return <div className={styles.dropDownRegion_cell} />;
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            className={styles.dropDownRegion_cell}
                                                            style={item.percent >= 100 ? { color: 'green' } : { color: 'red' }}
                                                        >
                                                            {calculatePercentDiff(item.percent)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => null}
                                onClick={() => forwardArrowSlide()}
                                style={{ cursor: 'pointer' }}
                            >
                                {arrObj.length > 5 && (
                                    <div className={styles.dropDownRegion_imgForward}>
                                        <SvgIcon id="DropDownArrow" size={17} height={12} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CampaignComparisonRegion;
