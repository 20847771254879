import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISavedChartFilterApplyModel, ISavedChartModel, ISavedChartsState } from './types';

const initialState: ISavedChartsState = {
    campaignId: 0,
    charts: [],
};

const { actions: campaignSavedChartsActions, reducer } = createSlice({
    name: 'savedCharts',
    initialState,
    reducers: {
        setChartsFromApi: (state: ISavedChartsState, { payload }: PayloadAction<unknown>): void => {
            if (payload && Array.isArray(payload)) {
                state.charts = payload?.map((metaItem) => ({
                    name: metaItem?.name,
                    activeAvailablePeriodId: metaItem?.active_available_period_id,
                    activeCountedId: metaItem?.active_counted_id,
                    campaignId: metaItem?.campaign_id,
                    activeDestinationId: metaItem?.active_destination_id,
                    activePlatformId: metaItem?.active_platform_id,
                    id: metaItem?.id,
                    timeFrame: metaItem?.time_frame,
                    filterApply: metaItem?.filter_apply?.map((applyItem) => ({
                        filterName: applyItem?.filter_name,
                        filtersCategory: applyItem?.filters_category,
                    } as ISavedChartFilterApplyModel)),
                } as ISavedChartModel));
            }
        },
    },
});

export { campaignSavedChartsActions };
export default reducer;
