import React, { useCallback, useMemo } from 'react';
import projectConstants from '@app/constants/projects';
import Path from '@shared/lib/paths';
import { Link } from 'react-router-dom';
import { LogoImageInList, Tooltip } from '@shared/index';
import { Checkbox } from 'antd';
import SourcesListCellActions from '@entities/SourceListCellActions';
import styles from './styles.module.scss';
import LinkIcon from './imgs/link.svg';
import ArrowUp from './imgs/arrowUp.svg';

interface IItemSelcet {
    id: number,
    label: string,
    isSelect: boolean
}
interface IProps {
    dataId: any;
    index: number;
    rowNumber: number;
    value: any;
    cellDescription: any,
    cellName: string;
    isClickArrow: boolean;
    setIsClickArrow: (value: boolean) => void;
    activeRow: number;
    setActiveRow: (value: number) => void;
    onClick: (rowNumber: number, column: string) => void;
    isSelected: boolean;
    isFavorite: boolean;
    lists: IItemSelcet[];
    activeRowIds: number[];
    onSelect: (value: number) => void;
    onClickAddToList: (listsForAdd: number[], sourceId: number) => void;
    onCreateList: (sourceId: number) => void;
    onSetInFavorite: (sourceId: number) => void;
}

const positiveColors = (val: number): string => (val > 0 ? '#0EBFA1' : '#3B3D47');
const period = {
    day: 'день',
    week: 'неделя',
    month: 'месяц',
};

const SourcesListCell: React.FC<IProps> = ({
    dataId,
    index,
    rowNumber,
    lists,
    value,
    cellDescription,
    isFavorite,
    cellName,
    isClickArrow,
    setIsClickArrow,
    activeRow,
    setActiveRow,
    onClick,
    isSelected,
    activeRowIds,
    onSelect,
    onCreateList,
    onClickAddToList,
    onSetInFavorite,
}): JSX.Element => {
    const handlerNavigateToLink = useCallback((url: string) => window.open(url, '_blank'), []);

    const handlerClickCategory = useCallback(() => {
        if (index === activeRow) {
            setIsClickArrow(!isClickArrow);
        } else {
            setIsClickArrow(true);
        }
        setActiveRow(index);
    }, [isClickArrow, index, activeRow]);

    const getPlatform = (name: string): JSX.Element => {
        const item = projectConstants.platformsMap.get(name);
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                {item?.element}
                <span>{item?.title}</span>
            </div>
        );
    };
    const linkCell = useMemo<JSX.Element>(() => {
        const item = value.props.data[index].link;
        return (
            <div className={styles.linkCell}>
                <div className={styles.linkCell_linkBlock}>
                    <img src={LinkIcon} alt="Link" />
                    <button type="button" onClick={() => handlerNavigateToLink(item?.link)}>{item?.title}</button>
                </div>
            </div>
        );
    }, [value]);

    const sourceCell = useMemo<JSX.Element>(() => (
        <div
            className={styles.sourceCell}
        >
            <span className={styles.sourceCell_index}>{rowNumber + 1}</span>
            {isSelected && (
                <>
                    <div style={{ paddingLeft: 12 }}>
                        <Checkbox
                            checked={activeRowIds.includes(Number(dataId))}
                            onChange={() => onSelect(dataId)}
                        />
                    </div>
                    <span
                        className={styles.sourceCell_img}
                    >
                        <LogoImageInList logoUrl={value.props.data[index].logoUrl} />
                    </span>
                    <div className={styles.sourceCell_AuthorName}>
                        <Tooltip
                            content={value}
                            placement="bottomLeft"
                            xOffset={-2}
                            yOffset={0}
                            disabled={cellDescription?.value?.length < 22}
                            arrow={false}
                        >
                            {value}
                        </Tooltip>
                        <div className={styles.hiddenField} />
                    </div>
                </>
            )}
            {!isSelected && (
                <>
                    <button
                        type="button"
                        onClick={() => onClick(dataId, 'source')}
                        className={styles.sourceCell_img}
                    >
                        <LogoImageInList logoUrl={value.props.data[index].logoUrl} />
                    </button>
                    <Link to={`/${Path.Sources}/${dataId}`}>
                        <div className={styles.sourceCell_AuthorName}>
                            <Tooltip
                                content={value}
                                placement="bottomLeft"
                                xOffset={-2}
                                yOffset={0}
                                disabled={cellDescription?.value?.length < 22}
                                arrow={false}
                            >
                                {value}
                            </Tooltip>
                            <div className={styles.hiddenField} />
                        </div>
                    </Link>
                </>
            )}
            {/* <span style={{ maxWidth: 152 }}>{value}</span> */}
        </div>
    ), [value, dataId, cellDescription, isSelected, activeRowIds]);

    const platformCell = useMemo<JSX.Element>(() => {
        if (cellName !== 'platformCell') {
            return null;
        }
        return (
            <div className={styles.platformCell}>
                <div className={styles.platformCell_linkBlock}>
                    {getPlatform(value.props.data[index].platform)}
                </div>
            </div>
        );
    }, [value, cellName]);

    const authorCell = useMemo<JSX.Element>(() => {
        const localVal = value?.props?.data[index]?.author;
        return (
            <div className={styles.authorCell}>
                <span>{localVal.length > 30 ? `${localVal.substring(0, 27)}...` : localVal}</span>
            </div>
        );
    }, [value, index]);

    const subscribersCell = useMemo<JSX.Element>(() => (
        <div className={styles.subscribersCell}>
            <span>{value?.props?.data[index]?.subscribers?.toLocaleString('ru-RU')}</span>
        </div>
    ), [value]);

    const viewsAvgCell = useMemo<JSX.Element>(() => {
        if (cellName !== 'viewsAvgCell') {
            return null;
        }
        return (
            <div className={styles.subscribersCell}>
                <span>{Math.round(value?.props?.data[index]?.viewsAvg).toLocaleString('ru-RU')}</span>
            </div>
        );
    }, [value, cellName]);

    const erCell = useMemo<JSX.Element>(() => {
        const localVal = Math.round((value?.props?.data[index]?.er ?? 0) * 100) / 100;
        return (
            <div className={styles.erCell}>
                <span style={{ whiteSpace: 'nowrap' }}>
                    {`${localVal.toLocaleString('ru-RU')} %`}
                </span>
            </div>
        );
    }, [value]);

    const priceCell = useMemo<JSX.Element>(() => (
        <div className={styles.priceCell}>
            <span>{value}</span>
        </div>
    ), [value]);

    const categoryCell = useMemo<JSX.Element>(() => (
        <div className={styles.categoryCell}>
            <div className={styles.categoryCell_block}>
                {value.props.data[index].category.map((item) => <span key={item}>{item}</span>)}
            </div>
            {
                value.props.data[index].category?.length > 1
                    ? <div className={styles.categoryCell_blockBlur} style={isClickArrow && activeRow === index ? { display: 'none' } : null} />
                    : ''
            }
            {
                value.props.data[index].category?.length > 1 ? (
                    <div
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => null}
                        onClick={handlerClickCategory}
                        className={styles.categoryCell_imgBtn}
                    >
                        <img src={ArrowUp} alt="ArrowUp" style={isClickArrow && activeRow === index ? { transform: 'rotate(-0.50turn)' } : null} />
                    </div>
                ) : ''
            }
        </div>
    ), [value, index, isClickArrow, setIsClickArrow, activeRow]);

    const subscriberGrowthCell = useMemo<JSX.Element>(() => {
        const data = value.props.data[index].subscriberGrowth;

        return (
            <div className={styles.subscriberGrowthCell}>
                {Object.keys(data).map((item: string) => (
                    <div key={item} className={styles.subscriberGrowthCell_block}>
                        <span className={styles.subscriberGrowthCell_name}>{period[item]}</span>
                        <span
                            className={styles.subscriberGrowthCell_number}
                            style={data[item][0] >= 0 ? { color: positiveColors(data[item][0]) } : { color: 'red' }}
                        >
                            {(data[item][0] > 0 ? ` +${data[item][0].toLocaleString('ru-RU')}` : ` ${data[item][0].toLocaleString('ru-RU')}`)}
                        </span>
                    </div>
                ))}
            </div>
        );
    }, [value, index]);

    const cellMap = new Map([
        ['sourceCell', sourceCell],
        ['linkCell', linkCell],
        ['subscriberGrowthCell', subscriberGrowthCell],
        ['platformCell', platformCell],
        ['authorCell', authorCell],
        ['categoryCell', categoryCell],
        ['subscribersCell', subscribersCell],
        ['erCell', erCell],
        ['priceCell', priceCell],
        ['actionsCell', <SourcesListCellActions
            key={dataId}
            onClickAddToList={(listsForAdd) => onClickAddToList(listsForAdd, dataId)}
            onCreateList={() => onCreateList(dataId)}
            onSetInFavorite={() => onSetInFavorite(dataId)}
            sourceId={dataId}
            sourceTitle={value?.props?.data[index]?.source}
            isFavorit={isFavorite}
            lists={lists || []}
        />],
        ['viewsAvgCell', viewsAvgCell],
    ]);

    return (
        cellMap.get(cellName)
    );
};

export default SourcesListCell;
