/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import NoSourceIco from '@assets/images/sources/noSource.svg';
import { SvgIcon } from '@shared/index';
import styles from './styles.module.scss';

interface IPropsLinkCell {
    link: string;
}
const LinkCell: React.FC<IPropsLinkCell> = ({ link }): JSX.Element => (
    <div
        role="button"
        className={styles.linkCell}
        onClick={() => window.open(link, '_blank')}
    >
        <SvgIcon id="LinkIcon" size={12} />
        <div className={styles.linkCell_label}>ссылка</div>
    </div>
);

export default React.memo(LinkCell);
