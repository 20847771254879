import React, { useState } from 'react';
import { CheckBoxList } from '@shared/index';
import { ICheckItem } from '@shared/ui-components/CheckBoxList/types';
import styles from './styles.module.scss';

const CheckBoxListDemo: React.FC = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [checkboxes, setCheckboxes] = useState<ICheckItem[]>([
        {
            id: 1,
            value: 'midValue',
            label: 'Лейбл номер 1',
        },
        {
            id: 2,
            value: 'smallLabel',
            label: 'млнк. лейбл',
        },
        {
            id: 3,
            value: 'bigValue',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: '1431',
            value: 'bigValue1431',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: 4,
            value: 'midValue4',
            label: 'Лейбл номер 1',
        },
        {
            id: 'dsfacxsasfd',
            value: 'smallLabel5',
            label: 'млнк. лейбл',
        },
        {
            id: 5,
            value: 'midValue6',
            label: 'Лейбл номер 1',
        },
        {
            id: 'dsfv32412edsf',
            value: 'smallLabel7',
            label: 'млнк. лейбл',
        },
        {
            id: '08423dscf324rflds',
            value: 'bigValue8',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
        {
            id: 6,
            value: 'bigValue9',
            label: 'Пример длинного текста в две и более строк по ширине',
        },
    ]);

    const handleCheckItem = (id, flag) => {
        setCheckboxes((prev) => prev.map((item) => ({
            ...item,
            isChecked: id === item.id ? flag : item.isChecked,
        })));
    };

    return (
        <div className={styles.checkboxesDemo_root}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            >
                <div className={styles.checkboxesDemo}>
                    <CheckBoxList
                        onCheck={handleCheckItem}
                        items={checkboxes}
                        footer={(
                            <div style={{
                                height: 60,
                                background: 'lightblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                Footer
                            </div>
                        )}
                        header={(
                            <div style={{
                                height: 60,
                                background: 'lightblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                                header
                            </div>
                        )}
                        haveCheckAll
                        customHeight="200px"
                    />
                </div>
            </div>
        </div>
    );
};

export default CheckBoxListDemo;
