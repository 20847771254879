import React, {
    useCallback, useMemo, useState,
} from 'react';
import { ICustomSourceListRaw, ISourceRow } from '@services/sources/types';
import { ITableColumn } from '@entities/Table/types';
import Table from '@entities/Table';
import QuickSVGLoader from '@components/QuickSVGLoader';
import { SourceListCreateModal } from '@entities/index';
import styles from './styles.module.scss';
import { IItemSelcet, ITestRow } from './types';
import { getColumns } from './getColumns';

interface IPropsTabelSources {
    listSources: ISourceRow[];
    lists: ICustomSourceListRaw[];
    isLoading: boolean;
    favoriteId?: number;
    isDisabledInfinityScroll?: boolean;
    isDisabledcheckboxes?: boolean;
    listExceptedIds?: number[];
    onAddSourceToList?: (listId: number[], sourceId: number) => void;
    onAddFavorite?: (sourceId: number) => void;
    onRemoveFromFavorite?: (sourceId: number) => void;
    onGetNextSource?: () => void;
    onChangeSelected?: (source: ITestRow) => void
}

const TabelSources: React.FC<IPropsTabelSources> = ({
    listSources,
    lists,
    isLoading,
    favoriteId,
    isDisabledInfinityScroll,
    isDisabledcheckboxes,
    listExceptedIds,
    onAddSourceToList,
    onAddFavorite,
    onRemoveFromFavorite,
    onGetNextSource,
    onChangeSelected,
}): JSX.Element => {
    // const currentSortingAndFilters = useSelector(sourceListSelectors.activeFilters);
    const [isOpenModalCreateList, setIsOpenModalCreateList] = useState<{ sourceId: number, sourceTitle: string }>(null);
    // const [getSources, { isFetching, isLoading }] = sourcesRtkQService.useLazyPostLoadSourcesInfinityQuery();
    // const [nextPageToken, setNextToken] = useState<string>(null);
    // const [updateSources] = sourcesRtkQService.useAddSourcesToListsMutation();
    // const [removeSourcesFromList] = sourcesRtkQService.useRemoveSourcesFromListMutation();

    // const handleAddToFavorite = (sourceId: number): void => {
    // if (typeof favoriteId !== 'number') return;
    // updateSources({
    //     list_ids: [favoriteId],
    //     source_ids: [sourceId],
    // }).then(() => {
    //     setListSources((prev) => prev.map((source) => (
    //         { ...source, lists: sourceId === source.id ? [...source.lists, favoriteId] : source.lists })));
    // }).catch((err) => {
    //     message.error('При добавлении в избранное произошла ошибка');
    //     // eslint-disable-next-line no-console
    //     console.log('При добавлении в избранное произошла ошибка', err);
    // });
    // };
    // const handleRemoveFromFavorite = (sourceId: number): void => {
    // if (typeof favoriteId !== 'number') return;
    // removeSourcesFromList({
    //     list_id: favoriteId,
    //     source_ids: [sourceId],
    // }).then(() => {
    //     setListSources((prev) => prev.map((source) => (
    //         { ...source, lists: sourceId === source.id ? source.lists.filter((id) => id !== favoriteId) : source.lists })));
    // }).catch((err) => {
    //     message.error('При удалении из избранного произошла ошибка');
    //     // eslint-disable-next-line no-console
    //     console.log('При удалении из избранного произошла ошибка', err);
    // });
    // };
    // const handleSetSourceInList = useCallback((list_ids: number[], sourceId: number) => {
    // updateSources({
    //     list_ids,
    //     source_ids: [sourceId],
    // });
    // }, []);
    const handlerCreateList = useCallback((sourceId: number, title: string) => {
        setIsOpenModalCreateList({ sourceId, sourceTitle: title });
    }, []);
    const getRows = useMemo(() => {
        if (!listSources) return [];
        const filtredOfExcepted = [...listSources].filter(({ id }) => !listExceptedIds?.some((exc) => id === exc));
        const rows: ITestRow[] = [...filtredOfExcepted].map((source) => ({
            id: source.id,
            source: {
                id: source.id,
                logoUrl: source.logoUrl,
                name: source.title,
            },
            link: source.link,
            platform: source.sourceType?.title || 'null',
            author: source.author,
            categories: source.categories,
            subscribers: source.subscribers,
            views: source.viewsAvg,
            er: source.er,
            subscriberGrowth: source.subscriberGrowth,
            actions: {
                sourceId: source.id,
                isFavorite: source.lists.some((listId) => listId === favoriteId),
                activeList: (lists || []).filter(({ id: lid }) => !(source.lists || []).some((slid) => slid === lid)),
            },
        }));
        return rows;
    }, [listSources, favoriteId, listExceptedIds, lists]);
    const getCurrentColumns: ITableColumn<ITestRow>[] = getColumns(
        lists.map(({ id, title }):IItemSelcet => ({ id, label: title, isSelect: false })),
        isDisabledcheckboxes,
        onAddSourceToList,
        handlerCreateList,
        onAddFavorite,
        onRemoveFromFavorite,
        onChangeSelected,
    );
    // const getNewSources = (sourcesParams?: any) => {
    // getSources({ ...sourcesParams, list_id }).then((res) => {
    //     if (res?.data) {
    //         setNextToken(res.data.nextPageToken);
    //         setListSources(res.data.sources);
    //     }
    // });
    // };
    // const handleScrollTable = () => {
    // getSources({
    //     ...currentSortingAndFilters,
    //     limit: limitSourcesInPage,
    //     prevPageToken: nextPageToken,
    //     list_id,
    // }).then((res) => {
    //     if (res?.data) {
    //         setNextToken(res.data.nextPageToken);
    //         setListSources((prev) => [...prev, ...res.data.sources]);
    //     }
    // });
    // };
    // useDebounce(() => {
    //     getNewSources({ ...currentSortingAndFilters, limit: limitSourcesInPage });
    // }, 500, [currentSortingAndFilters, updater, list_id]);
    return (
        <div>
            <Table
                columns={getCurrentColumns}
                rows={getRows}
                isLoading={false}
                onScroll={onGetNextSource}
                scrollCallbackEnable={!isLoading && !isDisabledInfinityScroll}
            />
            {
                isLoading && (
                    <div className={styles.loader}>
                        <QuickSVGLoader size="large" />
                    </div>
                )
            }
            {
                isOpenModalCreateList && (
                    <SourceListCreateModal
                        onClose={() => setIsOpenModalCreateList(null)}
                        sourceId={isOpenModalCreateList.sourceId}
                        sourceTitle={isOpenModalCreateList.sourceTitle}
                    />
                )
            }
        </div>
    );
};

export default TabelSources;
