import React, {
    useEffect, useRef, useState,
} from 'react';
import {
    Chart as ChartJS,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { COLORS_FOR_CHARTS } from '@shared/lib/consts';
import { OPTIONS } from './settings';
import { IPropsBarChart } from '../types';

const splitterString = (str: string, maxCount = 15): string[] => {
    const arrFromStr = str.split(' ');
    const result = arrFromStr.reduce((acc, item) => {
        const newAcc = [...acc];
        if (!newAcc.length) {
            newAcc.push(item);
            return newAcc;
        }
        const prevString = newAcc[newAcc.length - 1];
        const concatPrevNow = `${prevString} ${item}`;
        if (concatPrevNow.length < maxCount) {
            newAcc[newAcc.length - 1] = concatPrevNow;
        } else {
            newAcc.push(item);
        }
        return newAcc;
    }, []);
    return result;
};

ChartJS.register(
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
);

const NewBarChart: React.FC<IPropsBarChart> = ({
    chartData,
}) => {
    const chartRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(() => false);
    const handleKeyDown = (e: KeyboardEvent) => {
        if (!isFullScreen) return;
        if (e.key === 'Escape') {
            setIsFullScreen(false);
        }
    };
    useEffect(() => {
        if (isFullScreen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullScreen]);

    const createDataset = () => {
        const labels = [];
        const dataSet = {
            type: 'bar',
            label: 'allLabels',
            pointBorderColor: 'white',
            pointRadius: 8,
            hidden: false,
            data: [],
            fillColor: COLORS_FOR_CHARTS,
            backgroundColor(e) {
                const needChart = chartData.find((chart) => chart.data === e.raw);
                return needChart?.allColors.default || 'rgba(230, 230, 230, 0.2)';
            },
        };
        chartData.forEach((item, index) => {
            labels.push(splitterString(item?.label));
            dataSet.data.push(item.data);
            dataSet.fillColor.push(COLORS_FOR_CHARTS[index]);
        });

        return { datasets: [dataSet], labels };
    };
    return (
        <div style={{ width: '100%', height: 450, position: 'relative' }}>
            <div style={{ height: 'calc(100% - 38px)' }}>
                <Line
                    ref={chartRef}
                    options={OPTIONS as any}
                    data={createDataset() as any}
                />
            </div>
        </div>
    );
};

export default React.memo(NewBarChart);
