/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classnames from 'classnames';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import CampaignReportsListItem from '../../../components/campaign/Report/ReportsList/ReportItem';
import CampaignUploadReport from '../../../components/campaign/Report/ReportsList/Upload';
import { campaignReportsRtkQService } from '../../../services/campaigns/reports';
import { selectCommonWindowIsIPadSize } from '../../../store/common/window/selectors';
import styles from './styles.module.scss';

interface IProps {
    campaignId: string
}

const CampaignReportsList: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { campaignId } = props;

    const [getReports, { data: reports, isFetching, isError: isFetchingError }] = campaignReportsRtkQService.useLazyGetSavedReportsQuery();
    const [deleteReport, { isLoading: isDeleteLoading, isError: isDeleteError }] = campaignReportsRtkQService.useDeleteReportMutation();
    const [getReportFileUrl, { data: reportDownloadLink, isError: isReportDownloadLink }] = campaignReportsRtkQService.useLazyGetReportFileUrlQuery();

    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);

    useEffect(() => {
        if (campaignId) {
            getReports({
                id: +campaignId,
            });
        }
    }, [campaignId]);

    const handlerDeleteAllReports = (): void => {
        // eslint-disable-next-line no-console
        console.log('Удаляем вообще ВСЁ!');
    };

    const handleClickDelete = (reportId: number | string): void => {
        // TODO: Add error catch
        deleteReport({
            campaignId,
            reportId,
        });
    };

    const handleClickDownload = (reportId: number | string): void => {
        // TODO: Add error catch
        getReportFileUrl({
            campaignId,
            reportId,
        });
    };

    useEffect(() => {
        if (reportDownloadLink && reportDownloadLink.file_url) {
            const pptxWindow = window.open();
            if (!pptxWindow?.location?.href) {
                window?.open(reportDownloadLink.file_url, 'Download');
            } else {
                pptxWindow.location.href = reportDownloadLink.file_url;
            }
        }
    }, [reportDownloadLink]);

    useEffect(() => {
        if (isFetchingError) console.log('Reports fetching error');
    }, [isFetchingError]);

    useEffect(() => {
        if (isDeleteError) console.log('Report delete error');
    }, [isDeleteError]);

    useEffect(() => {
        if (isReportDownloadLink) console.log('Report get download link error');
    }, [isReportDownloadLink]);

    return (
        <div className={styles.campaignReportsList_root}>
            <div className={classnames(styles.flexColumn, styles.gap16)}>
                <div className={classnames(styles.flexRow)}>
                    <div className={styles.header}>
                        Отчеты
                    </div>
                    <CampaignUploadReport campaignId={campaignId} />
                    <button
                        type="button"
                        className={classnames(styles.delAllItems)}
                        onClick={handlerDeleteAllReports}
                    >
                        Удалить все отчеты
                    </button>
                </div>
                <div className={styles.headerHl} />
            </div>
            {
                (isFetching || isDeleteLoading) && <Skeleton containerClassName={styles.skeleton} count={8} height={40} />
            }
            {
                !isFetching && !isDeleteLoading && reports?.map((item) => (
                    <CampaignReportsListItem
                        key={item.id}
                        data={item}
                        onDelete={handleClickDelete}
                        onDownload={handleClickDownload}
                        isIPadSize={isIPadSize}
                    />
                ))
            }
        </div>
    );
};
export default CampaignReportsList;
