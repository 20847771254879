import React, { useCallback, useMemo, useState } from 'react';
import { IFilterCampaigns } from 'store/campaign/comparison';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import styles from './styles.module.scss';
import SvgIcon from '../../../../../SvgIcon';

interface IMediaType {
    name: string,
    companies: string[],
    data: IElement[]
}

interface IElement {
    id: number;
    name: string;
    percent: number;
    quantity: number;
    quantityStr: string;
}

interface IProps {
    filters: IFilterCampaigns[];
    referenceCampaign: ICampaignDataModel | null;
    compareCampaigns: ICampaignDataModel[];
}
const typeInformationSources = ['СМИ', 'блоггер'];
const filterType = 'Типы отслеживаемых авторов';
const title = 'Типы отслеживаемых источников';

const CampaignComparisonMediaType: React.FC<IProps> = ({ filters, referenceCampaign, compareCampaigns }): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const handleKeyDown = (): void => {
        // eslint-disable-next-line no-console
    };

    const findCampaignAndFilters = useCallback((campaignId: number, type: string, filterName: string): number => {
        const result = (filters
            .find(({ campaignId: itemId }) => itemId === campaignId)?.filters
            .find((item) => item.filtersCategory === type)?.filters
            .find(({ filter_name }) => filter_name === filterName)?.count) ?? 0;
        return (Number.isNaN(result) ? 0 : result);
    }, [filters]);

    const calculatePercent = (dividend : number, divisor: number) => {
        if (divisor === 0) {
            return dividend;
        }
        return Math.round(((dividend ?? 0) / divisor) * 10000) / 100;
    };

    const arrObj = useMemo<IMediaType[]>(
        () => {
            const result = [];
            typeInformationSources.forEach((value) => {
                result.push({
                    name: value === 'блоггер' ? 'Блогеры' : value,
                    companies: [referenceCampaign.name, ...compareCampaigns.map((company) => company.name)],
                    data: [
                        {
                            id: referenceCampaign.id,
                            name: referenceCampaign.name,
                            percent: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantity: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantityStr: findCampaignAndFilters(referenceCampaign.id, filterType, value)
                                .toLocaleString('ru-RU'),
                        } as IElement,
                        ...compareCampaigns.map(({ id: itemId, name }) => {
                            const quantity = findCampaignAndFilters(itemId, filterType, value) ?? 0;
                            return {
                                id: itemId,
                                name,
                                percent: calculatePercent(quantity, findCampaignAndFilters(referenceCampaign.id, filterType, value)),
                                quantity,
                                quantityStr: quantity.toLocaleString('ru-RU'),
                            } as IElement;
                        }),
                    ],
                } as IMediaType);
            });
            return result;
        },
        [referenceCampaign, compareCampaigns],
    );

    const calculationOfPercentageDifference = (percent): string => {
        if (!percent || Number.isNaN(percent)) return ' - ';
        if (!(percent - 100)) return '0%';
        if (percent - 100 < 100) return `${(percent - 100)?.toLocaleString('ru-RU')}%`;
        return `${(percent)?.toLocaleString('ru-RU')}%`;
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const calculateCoefficientForGraph = (arrObj: IMediaType) : number => {
        let max = 0;
        arrObj.data.forEach((item) => {
            if (item.quantity > max) max = item.quantity;
        });
        return max ? 200 / max : max;
    };

    return (
        <div className={styles.mediaType}>
            <div className={styles.mediaType_lineStart}>
                <div className={styles.mediaType_name}>{title}</div>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    onClick={() => setIsOpen(!isOpen)}
                    className={styles.mediaType_arrow}
                >
                    <div style={isOpen ? { transform: ' rotate(0)' } : { transform: ' rotate(180deg)' }}>
                        <SvgIcon id="DropDownArrow" size={17} height={12} />
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className={styles.mediaType_list}>
                    <div className={styles.mediaType_title}>
                        {arrObj[0].companies.map((element, index) => (
                            <div
                                key={element}
                                className={styles.mediaType_cell}
                                style={!index ? { color: '#0ebfa1' } : null}
                            >
                                {element}
                            </div>
                        ))}
                    </div>
                    {arrObj.map((element, indexArrObj) => (
                        <div key={element.name} className={styles.mediaType_block}>
                            <div className={styles.mediaType_nameSource}>
                                {element.name}
                            </div>
                            <div className={styles.mediaType_info}>
                                <div className={styles.mediaType_schedule}>
                                    {element.data.map((item) => (
                                        <div key={item.percent} className={styles.mediaType_cell}>
                                            <div
                                                className={styles.mediaType_line}
                                                style={{ width: `${Math.abs(item.quantity * calculateCoefficientForGraph(arrObj[indexArrObj]))}px` }}
                                            />
                                        </div>

                                    ))}
                                </div>
                                <div className={styles.mediaType_quantity}>
                                    {element.data.map((item) => (
                                        <div
                                            key={item.id}
                                            className={styles.mediaType_cell}
                                        >
                                            {item.quantityStr}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.mediaType_percent}>
                                    {element.data.map((item, index) => {
                                        if (!index) return <div className={styles.mediaType_cell} />;
                                        if (!arrObj[indexArrObj].data[0].quantity) {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className={styles.mediaType_cell}
                                                    style={item.percent ? { color: 'green' } : { color: 'red' }}
                                                >
                                                    {item.percent ? `${item.percent * 100}%` : ' - '}
                                                </div>
                                            );
                                        }
                                        return (
                                            <div
                                                key={item.id}
                                                className={styles.mediaType_cell}
                                                style={item.percent >= 100 ? { color: 'green' } : { color: 'red' }}
                                            >
                                                {calculationOfPercentageDifference(item.percent)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CampaignComparisonMediaType;
