/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { IQueryParamsCampaingFilters } from '../../../services/campaigns/tracking/types';
import { trackingCampaingRtqService } from '../../../services/campaigns/tracking';
import { IApplyFilter, ListCategory } from '../../../sections/MyCampaignContainer/CampaingTypes';

type TSetterBody = (body: IQueryParamsCampaingFilters) => void;
type TSetterAppyFilters = (filterApply: IApplyFilter) => void;
type THookGetFilters = [
    TSetterBody,
    TSetterAppyFilters,
    ListCategory,
    boolean,
    IQueryParamsCampaingFilters,
    ListCategory,
];
/**
    * Возврощает кортеж
    * @param initialBody - Обязательный параметр отвечающий за инициализацию хука(Боди для запроса)
    * @returns - { TSetterBody } - updateBody
    * @returns - { TSetterAppyFilters } - updateApplyFilters
    * @returns - { ListCategory } - filters
    * @returns - { boolean } - isLoading
    * @returns - { IQueryParamsCampaingFilters } - prevBody
    * @returns - { ListCategory } - prevFilters
*/
const useGetFilters = (
    initialBody: IQueryParamsCampaingFilters,
): THookGetFilters => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [body, setBody] = useState<IQueryParamsCampaingFilters>(() => initialBody);
    const [prevBody, setPrevBody] = useState<IQueryParamsCampaingFilters>();
    const [filters, setFilters] = useState<ListCategory>([]);
    const [prevFilters, setPrevFilters] = useState<ListCategory>();
    const [getFilters] = trackingCampaingRtqService.useLazyGetFiltersQuery();
    const updateBody = (newBody: IQueryParamsCampaingFilters) => {
        setBody((prev) => {
            setPrevBody(prev);
            return newBody;
        });
    };
    const updateApplyFilter = (applyFilter: IApplyFilter) => {
        setFilters((prev) => prev.map((item) => {
            const newItem = { ...item };
            if (item.filters_category === applyFilter.filters_category) {
                const newFilters = item.filters.map((filter) => {
                    const newFilter = { ...filter };
                    if (filter.filter_name === applyFilter.filter_name) {
                        newFilter.include = !filter?.include;
                    }
                    return newFilter;
                });
                newItem.filters = newFilters;
            }
            return newItem;
        }));
    };
    useEffect(() => {
        setIsLoading(true);
        getFilters(body).then((res) => {
            const newFilters = res?.data;
            if (!newFilters) return;
            setFilters((prev) => {
                setPrevFilters(prev);
                return newFilters;
            });
        })
            .finally(() => {
                setIsLoading(false);
            });
    }, [body]);

    return [updateBody, updateApplyFilter, filters, isLoading, prevBody, prevFilters];
};

export default useGetFilters;
