import { AppState } from 'store/store';

const comparisonState = (state: AppState) => state.campaign.comparison;
const campaignCompareEnabled = (state: AppState) => comparisonState(state).isCompare;
const referenceCampaign = (state: AppState) => comparisonState(state).referenceCampaign;
const compareCampaigns = (state: AppState) => comparisonState(state).compareCampaigns;
const isSelectPeriod = (state: AppState) => comparisonState(state).isSelectPeriod;
const comparisonCanView = (state: AppState) => comparisonState(state).comparisonCanView;
const comparisonPeriod = (state: AppState) => comparisonState(state).comparisonPeriod;
const comparisonFilters = (state: AppState) => comparisonState(state)?.comparisonFilters ?? [];
const comparisonSummary = (state: AppState) => comparisonState(state).comparisonSummary;
const filtersFetchStatus = (state: AppState) => comparisonState(state).filtersFetchStatus;
const summaryFetchStatus = (state: AppState) => comparisonState(state).summaryFetchStatus;
const campaignSavedAnalysis = (state: AppState) => comparisonState(state).isSavedAnalysis;

export default {
    campaignCompareEnabled,
    referenceCampaign,
    compareCampaigns,
    isSelectPeriod,
    comparisonCanView,
    comparisonPeriod,
    comparisonFilters,
    comparisonSummary,
    filtersFetchStatus,
    summaryFetchStatus,
    campaignSavedAnalysis,
};
