import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ISimpleCheckItem } from '@components/sources/types';
import Skeleton from 'react-loading-skeleton';
import {
    Filter, IQPSourcesWithSortingAndFiltering, SortOrder, TStackDirection,
} from '@services/sources/types';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { ISelectItem, TSizes } from '@shared/ui-components/Select/types';
import { Checkbox, Menu } from 'antd';
import classnames from 'classnames';
import FiltersSearchField from '@components/sources/List/Filtres/SearchField';
import getKey from '@app/helpers/getKey';
import { sourcesRtkQService } from '@services/sources';
import ListLoadTrigger from './ListLoadTrigger';
import styles from './styles.module.scss';

interface IProps {
    size: TSizes;
    isOpen?: boolean;
    onSelect: (ids: number[], name: string) => void;
    onClose: () => void;
    currentSortingAndFilters: IQPSourcesWithSortingAndFiltering;
}

const limitOnAuthors = 20;

const AuthorFilter: React.FC<IProps> = ({
    size,
    currentSortingAndFilters,
    onSelect,
    onClose,
    isOpen = true,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const [authors, setAuthors] = useState<ISimpleCheckItem[]>([]);
    const [allAuthor, setAllAuthor] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [nextPageKey, setNextPage] = useState<string>('');
    const [prevPageKey, setPrevNextPage] = useState<string>('');
    const [currentDirection, setDirection] = useState<TStackDirection>('NEXT');
    const [loadingActive, setLoading] = useState<boolean>(true);

    const [getAuthors, {
        data: authorsRtkQ,
        isLoading: isLoadingAuthors,
        isFetching: isFetchingAuthors,
        error,
    }] = sourcesRtkQService.useLazyPostLoadAuthorsQuery();

    const isLoading = useMemo<boolean>(() => (
        isLoadingAuthors || isFetchingAuthors
    ), [isLoadingAuthors, isFetchingAuthors]);

    if (!isOpen) {
        return null;
    }

    const checkedIds = useMemo<number[]>(() => (
        (currentSortingAndFilters.filterParams as Filter)?.author_id?.length > 0
            ? (currentSortingAndFilters.filterParams as Filter).author_id.map((item) => Number(item)) : []
    ), [currentSortingAndFilters]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectAllAuthor = (e: React.MouseEvent) => {
        setAllAuthor(!allAuthor);
        const result = authors?.map((item) => ({ ...item, checked: !allAuthor } as ISimpleCheckItem));
        setAuthors(result);
        onSelect(!allAuthor ? result.map(({ id }) => id) : [], 'author_id');
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlerCheckedAuthor = (item: ISelectItem, e: React.MouseEvent) => {
        const result = authors.map((el) => ({
            ...el, checked: el.checked ? el.id !== item.id : el.id === item.id,
        }));
        onSelect(result.filter(({ checked }) => checked).map(({ id: lvId }) => lvId), 'author_id');
        setAuthors(result);
    };
    const filterData = useMemo<ISelectItem[]>(
        () =>
        // const result = searchValue === ''
        //     ? (authors ?? [])
        //     : (authors ?? []).filter((item) => (
        //         item.label.toLowerCase().indexOf(searchValue.toLowerCase()) === 0
        //     ));
            // eslint-disable-next-line implicit-arrow-linebreak
            (authors ?? []).map((el) => ({
                id: el.id,
                value: el.label,
                label: el.label,
                select: checkedIds.includes(el.id),
            })),
        [authors, checkedIds],
    );

    const authorsIds = useMemo<number[]>(() => (authors.map(({ id }) => id)), [authors]);

    const handlerOnLastItem = useCallback(() => {
        setLoading(true);
        getAuthors({
            value: nextPageKey,
            orderDirection: SortOrder.asc,
            direction: 'NEXT',
            limit: limitOnAuthors,
            title: searchValue,
        });
        setDirection('NEXT');
    }, [currentDirection, nextPageKey, searchValue]);

    const handlerOnFirstItem = useCallback(() => {
        getAuthors({
            value: prevPageKey,
            orderDirection: SortOrder.asc,
            direction: 'PREVIOUS',
            limit: limitOnAuthors,
            title: searchValue,
        });
        setDirection('PREVIOUS');
    }, [currentDirection, prevPageKey, searchValue]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const FirstItemWithSkeleton = (
        <ListLoadTrigger onLoad={handlerOnFirstItem} />
        // <LastItem
        //     onLastItem={handlerOnFirstItem}
        //     enabled={(prevPageKey && authors?.length >= limitOnAuthors) || isLoading || isFetching}
        // />
    );

    const LastItemWithSkeleton = useMemo<JSX.Element>(() => (
        searchValue.length <= 0 && !loadingActive && !isLoading && <ListLoadTrigger onLoad={handlerOnLastItem} />
    ), [handlerOnLastItem, searchValue, isLoading, loadingActive]);

    const handlerStartSearch = (value: string): void => {
        setLoading(true);
        setAuthors([]);
        getAuthors({
            value: nextPageKey,
            orderDirection: SortOrder.asc,
            direction: 'NEXT',
            limit: limitOnAuthors,
            title: value,
        });
        setDirection('NEXT');
    };

    useEffect(() => {
        getAuthors({
            value: '',
            orderDirection: SortOrder.asc,
            direction: 'NEXT',
            limit: limitOnAuthors,
            title: '',
        });
    }, []);

    useEffect(() => {
        if (isLoading && loadingActive) {
            // setTimeout(() => setLoading(false), 500);
            setLoading(false);
        }
    }, [loadingActive, isLoading]);

    useEffect(() => {
        // const newIds = (authorsRtkQ?.data ?? []).map(({ id }) => id);
        // if (newIds?.length > 0 && newIds.some((id) => !authorsIds.includes(id))) {
        if (authorsRtkQ?.data?.length > 0 && !isLoading && !error) {
            const result = [
                ...authors,
                ...(authorsRtkQ?.data ?? [])
                    .filter(({ id }) => !authorsIds.includes(id))
                    .map(({ id, title }) => ({ id, label: title, checked: false } as ISimpleCheckItem)),
            ];
            setAllAuthor(checkedIds?.length === result?.length);
            setAuthors(result.map((item) => ({ ...item, checked: checkedIds.includes(item.id) })));
            setPrevNextPage(authorsRtkQ?.previousPage);
            setNextPage(authorsRtkQ?.nextPage);
        }
    }, [
        authorsRtkQ,
        currentDirection,
        isLoading,
        error,
        checkedIds,
    ]);

    return (
        <div className={styles.authorFilter} ref={refModal}>
            <FiltersSearchField
                placeholder="Поиск"
                searchValue={searchValue}
                onSearch={setSearchValue}
                onStartSearch={handlerStartSearch}
            />
            <div className={styles.authorFilter_headCheckBox}>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    className={`${styles.item} ${styles[size]} ${styles.content} ${allAuthor ? styles.selected : ''}`}
                    key={getKey(-1)}
                    onClick={(e) => selectAllAuthor(e)}
                >
                    <Checkbox
                        checked={allAuthor}
                        style={{ marginRight: 8 }}
                    />
                    <span>Все</span>
                </div>
            </div>
            <div className={styles.authorFilter_list}>
                {
                    (filterData?.length <= 0 && !isLoading && !loadingActive) && (
                        <span className={styles.emptyMessage}>
                            Ничего не найдено
                        </span>
                    )
                }
                {
                    filterData?.length > 0 && (
                        <Menu style={{
                            display: 'flex', flexDirection: 'column', height: '100%',
                        }}
                        >
                            <div style={{
                                display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden',
                            }}
                            >
                                <div className={styles.containerScroll}>
                                    <div className={`${styles.menu}`}>
                                        {
                                            filterData.map((item) => (
                                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                                <div
                                                    className={
                                                        classnames(
                                                            styles.item,
                                                            styles[size],
                                                            styles.content,
                                                            item.select ? styles.selected : '',
                                                        )
                                                    }
                                                    key={item.id}
                                                    onClick={(e) => handlerCheckedAuthor(item, e)}
                                                >
                                                    <Checkbox
                                                        checked={item.select}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                    <span>{item.label}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </Menu>
                    )
                }
                {(isLoading || loadingActive) && <Skeleton width="100%" height={25} count={10} />}
                {LastItemWithSkeleton}
            </div>
            {/* <div className={styles.sorting}> */}
            {/*    <span className={styles.sorting_title}>Сортировка</span> */}
            {/*    <div className={styles.sorting_row}> */}
            {/*        <img src={SortingIcon} alt="SortingIcon" className={styles.sorting_img} /> */}
            {/*        <span className={styles.sorting_symbol}>По убыванию</span> */}
            {/*    </div> */}
            {/*    <div className={styles.sorting_row}> */}
            {/*        <img src={SortingIcon} alt="SortingIcon" className={styles.sorting_img} style={{ transform: 'scale(1, -1)' }} /> */}
            {/*        <span className={styles.sorting_symbol}>По возрастанию</span> */}
            {/*    </div> */}
            {/* </div> */}
        </div>
    );
};

export default AuthorFilter;
