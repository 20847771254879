import React, { useMemo } from 'react';
import IconPdf from '../../../../../assets/images/formatPdf.svg';
import IconPptx from '../../../../../assets/images/formatPptx.svg';
import { IReportModel } from '../../../../../services/campaigns/reports/types';
import DownloadIcon from '../imgs/download.svg';
import GarbageCanIcon from '../imgs/garbage-can.svg';
import IconPerson from '../imgs/person.svg';
import styles from '../styles.module.scss';

interface ICampaignReportsListItemProps {
    data: IReportModel;
    onDelete: (reportId: string | number) => void;
    onDownload: (reportId: string | number) => void;
    isIPadSize: boolean;
}
const CampaignReportsListItem: React.FC<ICampaignReportsListItemProps> = ({
    data, isIPadSize, onDelete, onDownload,
}): JSX.Element => {
    const { createDate, createTime } = useMemo<{ createDate: string, createTime: string }>(() => {
        if (!data?.created) {
            return { createDate: '', createTime: '' };
        }
        const localDate = new Date(data?.created);
        return {
            createDate: localDate.toLocaleDateString('ru-Ru'),
            createTime: localDate.toLocaleTimeString('ru-Ru').split(':').slice(0, 2).join(':'),
        };
    }, [data?.created]);

    if (!data) {
        return null;
    }

    const ext = data.fileName.split('.').pop();

    return (
        <div className={styles.flexRow} style={{ justifyContent: 'flex-start', gap: 16 }}>
            <div className={styles.dateLabel}>
                <span>{createTime}</span>
                <span>{createDate}</span>
            </div>
            <div className={styles.format}>
                {ext === 'pdf' && <img src={IconPdf} alt="IconPdf" />}
                {ext === 'pptx' && <img src={IconPptx} alt="IconPptx" />}
            </div>
            <div className={styles.reportItemContainer} style={isIPadSize ? { maxWidth: 280 } : null}>
                <div
                    className={styles.reportItemContainer_reportName}
                    style={isIPadSize ? { maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' } : null}
                >
                    {data.name ?? '-'}
                </div>
                <div className={styles.reportItemContainer_reportControls}>
                    {!data.generated
                        && <img src={IconPerson} alt="IconPerson" />}
                    <button
                        className={styles.buttonControl}
                        type="button"
                        onClick={() => onDownload(data.id)}
                    >
                        <img src={DownloadIcon} alt={DownloadIcon} />
                    </button>
                    <button
                        className={styles.buttonControl}
                        type="button"
                        onClick={() => onDelete(data.id)}
                    >
                        <img src={GarbageCanIcon} alt={GarbageCanIcon} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CampaignReportsListItem;
