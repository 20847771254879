import React, { useEffect, useRef } from 'react';
import useOnViewport from '../../../../../../entities/helpers/useOnViewport';

interface IProps {
    onLoad: () => void
}
const ListLoadTrigger: React.FC<IProps> = ({ onLoad }): JSX.Element => {
    const lastItemRef = useRef(null);
    const onView = useOnViewport(lastItemRef);

    useEffect(() => {
        if (onView) onLoad();
    }, [onView]);

    return (
        <div ref={lastItemRef} style={{ width: '100%' }} />
    );
};

export default ListLoadTrigger;
