import { ICustomerInfo, IQPCustomer } from '@services/projects/types';
import { transformCustomerInfoResponse } from '@services/projects/transformers/getCustomerProject';
import { rtkQApi } from '@services/index';

export const projectCustomerRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        putUpdateCustomerInfo: build.mutation<ICustomerInfo, IQPCustomer>({
            query: (params) => ({
                url: `media_projects/project/${params?.id}/customer_info`,
                method: 'PUT',
                body: {
                    company_name: params.companyName,
                    address: params.address,
                    contact_persons: [
                        {
                            full_name: params.personName,
                            contacts: params.contacts.map(({ type, value }) => ({
                                contact_type_id: Number(type),
                                value,
                            })),
                        },
                    ],
                },
            }),
            transformResponse: transformCustomerInfoResponse,
            invalidatesTags: ['ProjectStatus'],
        }),
        getContactTypes: build.query<any, void>({
            query: () => ({
                url: 'media_projects/info/enums',
                method: 'GET',
            }),
        }),
    }),
});
