/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import SvgIcon from '../../../../components/SvgIcon';
import { TSorts, TOrder } from '../../../../widgets/campaign/tracking/PostList/types';
import styles from './styles.module.scss';

interface IPropsSort {
    type: TSorts,
    order: TOrder,
    onChangeType: (type: TSorts) => void,
    onChangeOrder: (type: TOrder) => void,
}

const PostListSort: React.FC<IPropsSort> = ({
    onChangeType,
    onChangeOrder,
    type,
    order,
}): JSX.Element => {
    const getStyles = (itemType: TSorts, curentType: TSorts) => {
        const select = itemType === curentType ? styles.isSelectSort : '';
        return `${`${styles.containerSortItem} ${select}`}`;
    };
    return (

        <div className={styles.root_Sort}>
            <div className={styles.containerSorts}>
                <div
                    role="button"
                    className={getStyles('posted', type)}
                    onClick={() => onChangeType('posted')}
                >
                    Дата
                </div>
                <div
                    role="button"
                    className={getStyles('comments', type)}
                    onClick={() => onChangeType('comments')}
                >
                    Комментарии
                </div>
                <div
                    role="button"
                    className={getStyles('likes', type)}
                    onClick={() => {
                        onChangeType('likes');
                    }}
                >
                    Лайки
                </div>
                <div
                    role="button"
                    className={getStyles('reposts', type)}
                    onClick={() => onChangeType('reposts')}
                >
                    Репосты
                </div>
            </div>
            <div
                style={{ cursor: 'pointer' }}
                role="button"
                onClick={() => onChangeOrder(order === 'asc' ? 'desc' : 'asc')}
            >
                <SvgIcon id="ascDescIcon" />
            </div>
        </div>
    );
};
export default React.memo(PostListSort);
