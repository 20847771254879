/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ISimpleEntity } from '../../../store/types';
import ArrowIcon from '../../../assets/images/arrow.svg';
import SingleDropdownList from './SingleDropdownList';
import styles from './styles.module.scss';

interface ISingleSelectItemProps {
    defaultTitle: JSX.Element;
    list: ISimpleEntity[];
    selectedId: number | string | null;
    onSelect: (id: number) => void;
    tagId?: string;
    minWidth?: number;
    height?: number;
    isLoading: boolean;
    isVisible?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
}

const SingleSelectItem: React.FC<ISingleSelectItemProps> = ({
    list, selectedId,
    onSelect,
    defaultTitle,
    tagId,
    isLoading,
    minWidth = 180, height = 40,
    isVisible,
    onClose,
    onOpen,
}): JSX.Element => {
    const { t } = useTranslation();
    const [menuIsVisible, setVisibleMenu] = useState<boolean>(false);
    const onHandlerClick = (id: number): void => {
        onSelect(id);
    };
    const selectedItem = useMemo<ISimpleEntity | null>(
        (): ISimpleEntity => list?.find(
            ({ id }) => id === selectedId,
        ) ?? null,
        [selectedId, list],
    );

    const handlerSetVisible = (value: boolean): void => {
        if (value) {
            onOpen();
        }
        setVisibleMenu(value);
    };

    useEffect(() => {
        if (onClose && isVisible === false && menuIsVisible) {
            setVisibleMenu(false);
            onClose();
        }
    }, [isVisible, menuIsVisible]);

    return (
        <div>
            <Dropdown
                visible={menuIsVisible}
                overlay={
                    <SingleDropdownList items={list} onSelect={onHandlerClick} tagId={tagId} isLoading={isLoading} />
                }
                className={styles.dropdown}
                trigger={['click']}
                onVisibleChange={handlerSetVisible}
            >
                <button
                    id={`${tagId}-${selectedItem?.name?.toLowerCase()}`}
                    type="button"
                    className={classnames(
                        styles.selectItemContainer,
                        styles[selectedId ? 'filled' : ''],
                    )}
                    style={{
                        minWidth: `${minWidth}px`,
                        height,
                    }}
                >
                    {
                        Boolean(selectedItem?.name) && Number.isInteger(selectedId) && selectedId >= 0
                            ? (
                                <span className={classnames(
                                    styles.regionsAmountText,
                                )}
                                >
                                    {t(selectedItem?.name)}
                                </span>
                            )
                            : (
                                <span
                                    className={classnames(
                                        styles.selectItemContainer_text,
                                    )}
                                >
                                    {defaultTitle}
                                </span>
                            )
                    }
                    <img src={ArrowIcon} alt="ArrowIcon" />
                </button>
            </Dropdown>
        </div>
    );
};

export default SingleSelectItem;
