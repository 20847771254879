import React, { useCallback, useEffect, useMemo } from 'react';
import ReactPDF, { PDFDownloadLink } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import LinearLoader from '../../../../components/common/Loader';
import { pdfHandlerReportActions } from '../../../../store/pdf-handler/report';
import pdfHandlerWrapperSelectors from '../../../../store/pdf-handler/wrapper/selectors';
import pdfHandlerReportSelectors from '../../../../store/pdf-handler/report/selectors';
import { pdfHandlerWrapperActions } from '../../../../store/pdf-handler/wrapper';
import { useAppDispatch } from '../../../../store/hooks';
import { AppState } from '../../../../store/store';
import PDFWrapperDocument from '../../../../components/campaign/Report/PDFDocument';
import PDFWrapperDocumentContainer from '../Document';

interface IPDFWrapperDownloadContainerProps {
    reportId: string;
}

const PDFWrapperDownloadContainer: React.FC<IPDFWrapperDownloadContainerProps> = ({ reportId }): JSX.Element => {
    const dispatch = useAppDispatch();
    const document = useSelector(pdfHandlerWrapperSelectors.document);
    const fileName = useSelector(pdfHandlerWrapperSelectors.fileName);
    const report = useSelector((state: AppState) => pdfHandlerReportSelectors.reportById(state, reportId));

    const documentContainer = useMemo<JSX.Element>(() => <PDFWrapperDocumentContainer reportId={reportId} />, [reportId]);

    const prepareReportLink = useCallback((value: ReactPDF.BlobProviderParams): JSX.Element => {
        if (!value || typeof value?.loading !== 'boolean') {
            return null;
        }
        dispatch(pdfHandlerWrapperActions.setReportInProcess(value.loading));
        if (!value.loading && value?.blob?.size !== report?.file?.content.size) {
            const content = new File([value.blob], fileName);
            dispatch(pdfHandlerReportActions.addReportFile({ reportId, name: fileName, content }));
            dispatch(pdfHandlerWrapperActions.setReportInProcess(null));
            dispatch(pdfHandlerWrapperActions.createDocument(null)); // обнуляем документ в store, иначе следующий документ будет предыдущим
        }
        return value.loading ? <LinearLoader /> : <span>Скачать</span>;
    }, [dispatch, report]);

    useEffect(() => {
        if (report?.pages?.length && report?.id === reportId) {
            dispatch(pdfHandlerWrapperActions.createDocument(
                <PDFWrapperDocument pages={report.pages.map((item) => item)} />,
            ));
        }
    }, [report, reportId]);

    return (
        <>
            {
                !documentContainer ? null : <div style={{ visibility: 'collapse' }}>{documentContainer}</div>
            }
            {
                !!document && report?.pages?.length && (
                    <PDFDownloadLink document={document} fileName={fileName}>
                        {prepareReportLink}
                    </PDFDownloadLink>
                )
            }
        </>
    );
};

export default React.memo(PDFWrapperDownloadContainer);
