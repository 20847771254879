import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    FetchStatus, ICategory, ICategoryState, IDictionary,
} from '../../types';
import { convertApiToCategory } from './converter';

const initialState: ICategoryState = {
    entities: {},
    ids: [],
    status: FetchStatus.undefined,
};

const { actions: infoBasketCategoryActions, reducer } = createSlice({
    name: 'infoBasketCategory',
    initialState,
    reducers: {
        startLoading: (state: ICategoryState) => {
            state.status = FetchStatus.loading;
        },
        failedLoading: (state: ICategoryState) => {
            state.status = FetchStatus.failed;
        },
        setLoadingSuccess: (state: ICategoryState) => {
            state.status = FetchStatus.success;
        },
        successLoading: (
            state: ICategoryState,
            { payload }: PayloadAction<unknown>,
        ) => {
            const buffer = convertApiToCategory(payload);
            // if (state.ids.length > 0) {
            //     return { ...state, status: FetchStatus.success };
            // }
            const localState = {
                entities: {
                    ...state.entities,
                    ...buffer.reduce(
                        (acc: IDictionary<ICategory>, curr: ICategory) => {
                            acc[curr.id] = curr;
                            return acc;
                        },
                        {} as IDictionary<ICategory>,
                    ),
                } as IDictionary<ICategory>,
                ids: [...state.ids, ...buffer.map(({ id }) => id)],
                status: FetchStatus.success,
            } as ICategoryState;
            return { ...state, ...localState };
        },
        onSelect: (
            state: ICategoryState,
            { payload }: PayloadAction<string>,
        ) => {
            state.entities[payload].selected = true;
        },
        onSelectGroup: (
            state: ICategoryState,
            { payload }: PayloadAction<string[]>,
        ) => {
            payload.forEach((id) => {
                state.entities[id].selected = true;
            });
        },
        onDeselect: (
            state: ICategoryState,
            { payload }: PayloadAction<string>,
        ) => {
            state.entities[payload].selected = false;
        },
        deselectAll: (state: ICategoryState) => {
            state.ids.forEach((id) => {
                state.entities[id].selected = false;
            });
        },
    },
});

export { infoBasketCategoryActions };
export default reducer;
