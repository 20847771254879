import React, { useMemo } from 'react';
import { ISearchRequestPreview } from 'services/campaigns/search-request/types';
import ModalBigCrossIcon from '@assets/images/crossLite.svg';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

interface IPreviewWithHighlights {
    id: number;
    title: JSX.Element;
    content: JSX.Element;
}

interface IProps {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    data: ISearchRequestPreview[];
}

const splitByHighlights = (value: string, highlightStyle: string): JSX.Element | null => {
    if (!value) {
        return null;
    }
    const result = value.split('<b>').reduce((acc, curr) => {
        if (curr.includes('</b>')) {
            const highlights = curr.split('</b>');
            if (highlights?.length > 1) {
                return (
                    <>
                        {acc}
                        <span className={highlightStyle}>{highlights[0]}</span>
                        <span>{highlights[1]}</span>
                    </>
                );
            }
        }
        return (
            <>
                {acc}
                <span>{curr}</span>
            </>
        );
        // eslint-disable-next-line react/jsx-no-useless-fragment
    }, <></>);
    return result;
};

const SearchRequestPreviewModal: React.FC<IProps> = ({
    isOpen,
    isLoading,
    onClose,
    data,
}): JSX.Element => {
    const dataWithHighlights = useMemo<IPreviewWithHighlights[]>(() => {
        if (!(data?.length > 0)) {
            return [];
        }
        return data.map(({ id, title, content }) => ({
            id,
            title: splitByHighlights(title, `${styles.preview_result_title} ${styles.highlighted}`),
            content: splitByHighlights(content, `${styles.preview_result_text} ${styles.highlighted}`),
        } as IPreviewWithHighlights));
    }, [data]);

    const handlerCloseModal = (): void => {
        if (onClose) {
            onClose();
        }
    };
    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.searchRequestPreviewModal_root}>
            <div className={styles.modalWindow}>
                <div className={styles.contentContainer}>
                    <div className={styles.flexRow} style={{ justifyContent: 'space-between', height: 38 }}>
                        <div className={styles.contentContainer_title}>
                            Результат проверки
                        </div>
                        <div className={styles.modalCrossWrapper}>
                            <button type="button" style={{ padding: 0, background: 'none' }} onClick={handlerCloseModal}>
                                <img src={ModalBigCrossIcon} alt="ModalBigCrossIcon" />
                            </button>
                        </div>
                    </div>
                    <div className={styles.contentContainer_contentBox}>
                        <div className={styles.preview_result_listBox}>
                            {
                                isLoading && (new Array(3).fill('skeleton').map((item, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={`${item}-${index}`} className={styles.preview_result_article}>
                                        <Skeleton height={40} width={250} />
                                        <Skeleton height={100} width={250} />
                                    </div>
                                )))
                            }
                            {
                                !isLoading && dataWithHighlights?.map((item) => (
                                    <div key={item.id} className={styles.preview_result_article}>
                                        <div className={styles.preview_result_title}>
                                            {item.title}
                                        </div>
                                        <div className={styles.preview_result_text}>
                                            {item.content}
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                !isLoading && dataWithHighlights?.length <= 0 && (
                                    <span>Отсутствуют данные</span>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchRequestPreviewModal;
