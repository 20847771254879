export const enum DashboardType {
    infoTrace = 0,
    infoBasket = 1,
}

export const enum InfoBasketFilterType {
    location = 0,
    category = 1,
}

export const enum HistoryTablePeriodType {
    day = 0,
    week = 1,
    month = 2,
    twoMonth = 3,
    year = 4,
    threeMonth = 5,
}

export interface IHistoryTablePeriod {
    type: HistoryTablePeriodType;
    value: number;
    title: string;
}

export interface IHistoryTableDashboard {
    type: DashboardType;
    title: string;
}

export interface IHistoryTableDate {
    dateUnix: number;
    dateEnd: string;
    date: string;
    time: string;
}

export interface IHistoryTableInfoBasketSource {
    categories: string[];
    locations: string[];
    categoryIds: number[];
    locationIds: number[];
}

export interface IHistoryTable {
    id: number;
    date: IHistoryTableDate;
    link: string;
    isLink: boolean;
    period: IHistoryTablePeriod;
    dashboard: IHistoryTableDashboard;
    onSeeResult: (row: IHistoryTable) => void;
    infoBasketData?: IHistoryTableInfoBasketSource;
}
