/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import SvgIcon from '@components/SvgIcon';
import TextArea from '@entities/TextArea';
import { Button, IconButton } from '@shared/index';
import { Upload, message } from 'antd';
import useDocuments from '@components/Project/hooks/useDocuments';
import Skeleton from 'react-loading-skeleton';
import { projectsListService } from '@services/projects';
import LinearLoader from '@components/common/Loader';
import styles from './styles.module.scss';

interface IProps {
    onClose: () => void;
    onApply?: (comment?: string) => void;
    isOpen: boolean;
    withAccepted?: boolean,
    currentSaved?: boolean,
    projectName?: string
    projectId: number
}

const ResultsCompleteModal: React.FC<IProps> = ({
    onClose,
    onApply,
    isOpen,
    withAccepted,
    currentSaved = true,
    projectName = '',
    projectId,
}): JSX.Element => {
    const [isAccepted, setIsAccepted] = useState(false);
    const [isSaved, setSaved] = useState(currentSaved);
    // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-unused-vars
    const [needDocuments, { isLoadingRemove }, { handleUploadFile, handleRemoveFile }] = useDocuments(false, projectId, 4);
    const [downloadDocument, { isLoading: isLoadingDownload }] = projectsListService.useDownloadDocumentMutation();
    const getListOnLoadedDocs = useMemo(() => needDocuments.filter((doc) => doc.type_id === 4), [needDocuments]);
    const [isComplete, setComplete] = useState(false);
    const [removeId, setRemoveId] = useState<number>(-1);
    const [downloadId, setDownloadId] = useState<number>(-1);
    // LinearLoader
    const handleClickOnDownload = (id: number) => {
        setDownloadId(id);
        downloadDocument({ url: `${id}?project_id=${+projectId}` }).then((res: any) => {
            if (!res?.data) {
                message.error('При загрузке файла произошла ошибка');
            } else {
                window.open(res?.data?.file_url, '_blank');
                message.success('Файл успешно загружен');
            }
        })
            .catch(() => {
                message.error('При загрузке файла произошла ошибка');
            })
            .finally(() => {
                setDownloadId(-1);
            });
    };
    const handleClickOnRemove = (id: number) => {
        handleRemoveFile(id);
        setRemoveId(id);
    };
    const props = {
        name: 'file',
        showUploadList: false,
        accept: '.word,.pptx,.xlsx,.pdf,.doc',
        action(file) {
            handleUploadFile(file);
            return null;
        },
    };
    const getContent = useMemo(() => ({
        title: isSaved ? 'Результаты проекта' : 'Закрытие проекта',
        description: isSaved ? 'Оставьте комментарий о результатах проекта, чтобы в будущем можно было обратиться к документу и прочитать выводы' : 'Вы уверены, что хотите закрыть проект?Данные восстановить будет нельзя.\nДля закрытия проекта, необходимо написать комментарий',
    }), [isSaved]);
    const handlerClose = (): void => {
        setSaved(currentSaved);
        setComplete(false);
        onClose();
    };
    const [description, setDescription] = useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [attachFile, setAttachFile] = useState<boolean>(false);
    const handlerApply = (): void => {
        if (!isSaved) {
            setSaved(true);
            return;
        }
        if (!isComplete) {
            if (typeof onApply === 'function') {
                onApply(description);
            }
            setComplete(true);
            return;
        }
        onClose();
    };
    useEffect(() => () => {
        setIsAccepted(false);
        setComplete(false);
        setRemoveId(-1);
        setDownloadId(-1);
    }, []);
    if (!isOpen) {
        return null;
    }
    const warning = isSaved ? (
        <div className={styles.resultsComplete_container_warning}>
            <div className={styles.resultsComplete_warning}>
                <SvgIcon id="warning_circle" size={21} />
                <span className={styles.resultsComplete_textContent}>
                    Не забудьте добавить закрывающие документы
                </span>
            </div>
        </div>
    ) : null;
    const addComment = isSaved ? (
        <TextArea
            label="Комментарий"
            value={description}
            onChange={(value: string) => setDescription(value)}
        />
    ) : null;
    if (withAccepted && !isAccepted) {
        return (
            <div className={styles.resultsComplete_root}>
                <div className={`${styles.resultsComplete_window} ${styles.adaptiveSize}`}>
                    <div className={styles.resultsComplete_container}>
                        <div className={styles.resultsComplete_container_header}>
                            <span className={styles.resultsComplete_title}>
                                Закрытие проекта
                            </span>
                            <button type="button" onClick={handlerClose}>
                                <SvgIcon id="BigCross" size={14} />
                            </button>
                        </div>
                        <div style={{ marginTop: 12 }}>
                            <div>
                                Вы уверенны, что хотите закрыть проект?
                            </div>
                            <div>
                                После завершения проекта, открытие его вновь будет невозможно.
                            </div>
                        </div>
                        <div className={styles.resultsComplete_controls}>
                            <div />
                            <div className={styles.resultsComplete_controls_right}>
                                <Button
                                    text="Отменить"
                                    type="secondary"
                                    size="large"
                                    onClick={handlerClose}
                                />
                                <Button
                                    text="Продолжить"
                                    type="danger"
                                    size="large"
                                    onClick={() => setIsAccepted(true)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (withAccepted ? isComplete && isAccepted : isAccepted) {
        return (
            <div className={styles.resultsComplete_root}>
                <div className={`${styles.resultsComplete_window} ${styles.short}`}>
                    <div className={styles.resultsComplete_container}>
                        <div className={styles.resultsComplete_container_header}>
                            <span className={styles.resultsComplete_title} />
                            <button type="button" onClick={handlerClose}>
                                <SvgIcon id="BigCross" size={14} />
                            </button>
                        </div>
                        <div className={styles.resultsComplete_container_content}>
                            <span className={styles.resultsComplete_textContent}>
                                {`Проект ${projectName} успешно закрыт`}
                            </span>
                        </div>
                        <div className={`${styles.resultsComplete_controls} ${styles.short}`}>
                            <Button
                                text="Хорошо"
                                type="default"
                                size="large"
                                onClick={handlerClose}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className={styles.resultsComplete_root}>
            <div className={styles.resultsComplete_window}>
                <div className={styles.resultsComplete_container}>
                    <div className={styles.resultsComplete_container_header}>
                        <span className={styles.resultsComplete_title}>{getContent.title}</span>
                        <button type="button" onClick={handlerClose}>
                            <SvgIcon id="BigCross" size={14} />
                        </button>
                    </div>
                    <div className={styles.resultsComplete_container_content}>
                        <span className={styles.resultsComplete_textContent}>
                            {getContent.description}
                        </span>
                        {addComment}
                    </div>
                    {!getListOnLoadedDocs?.length && warning}
                    {(
                        getListOnLoadedDocs.map((item, index) => (isLoadingRemove && removeId === item.id
                            ? (
                                <Skeleton
                                    width="100%"
                                    height={36}
                                />
                            )
                            : (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={styles.modal_file} key={`attachFile_${item}_${index}`}>
                                    <input placeholder={item.label} className={styles.modal_nameFile} />
                                    <div className={styles.modal_file_controls}>
                                        {isLoadingDownload && downloadId === item.id
                                            ? <LinearLoader />
                                            : <IconButton iconSize={16} iconId="Download" onClick={() => handleClickOnDownload(item.id)} />}

                                        <IconButton iconSize={14} iconId="BigCross" onClick={() => handleClickOnRemove(item.id)} />
                                    </div>
                                </div>
                            )))

                    )}
                    <div className={styles.resultsComplete_controls}>
                        <Upload {...props}>
                            <IconButton iconSize={24} iconId="PaperClip" onClick={() => setAttachFile(true)} />
                        </Upload>

                        <div className={styles.resultsComplete_controls_right}>
                            <Button
                                text="Отменить"
                                type="secondary"
                                size="large"
                                onClick={handlerClose}
                            />

                            <Button
                                text={isSaved ? 'Сохранить' : 'Продолжить'}
                                type="danger"
                                size="large"
                                onClick={handlerApply}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultsCompleteModal;
