/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'antd';
import RadioButton from '../campaign/PlatformsSelectButtons/RadioButton';
import styles from './styles.module.scss';
import { ICategory, IFilter } from '../../sections/MyCampaignContainer/CampaingTypes';
import FiltersFindInput from '../FiltersFindInput';
import CampaingFilter from '../CampaingFilter';
import HeaderPanel from './HeaderPanel';
import RadioPanel from './RadioPanel';

interface IFiltersProps {
    disabledSeporator?: boolean,
    withControls?: boolean,
    isExclude: boolean,
    isImportant?: boolean,
    seporatorLine?: boolean,
    isOpen?: boolean,
    max?: number,
    category?: ICategory,
    onClickControl?: (...args: any) => any
    updateCategories?: (categoryName: string, filterName: string, type: string) => void,
    onOpen?: (name: string) => void,
    changeTypeFiltersApply: (type: 1 | 2) => void,
    children?: JSX.Element
}

const listId = {
    'neutral': 'track-filter-neutral',
    'negative': 'track-filter-negative',
    'positive': 'track-filter-positive',
    'блоггер': 'track-filter-blogger',
    'СМИ': 'track-filter-smi',
    'tg': 'track-filter-tg',
    'rss': 'track-filter-rss',
    'vk': 'track-filter-vk',
    'ok': 'track-filter-ok',
};

const MyCompaignFilters: React.FC<IFiltersProps> = ({
    disabledSeporator,
    category,
    isExclude,
    max = 5,
    isOpen = false,
    seporatorLine = true,
    isImportant = false,
    withControls,
    updateCategories,
    onClickControl,
    onOpen,
    changeTypeFiltersApply,
    children,
}): JSX.Element => {
    const [countShowing, setCountShowing] = useState(max);
    const [findString, onChangeFind] = useState('');
    const [isShowCollapse, setIsShowCollapse] = useState(false);
    const memoCategory = useMemo(() => category, [category]);
    const getFiltredFilters = (filters: IFilter[], find: string) => {
        const filtred = filters?.filter((filter) => filter.filter_name.toLocaleLowerCase().includes(find.toLocaleLowerCase()));
        return filtred;
    };
    const memoFilters = useMemo(() => getFiltredFilters(category?.filters, findString), [category?.filters, findString]);
    const handleClickOnMore = (): void => {
        setCountShowing(countShowing + max);
        if (!isShowCollapse) setIsShowCollapse(true);
    };
    const handleClickOnHide = (): void => {
        setCountShowing(max);
        if (isShowCollapse) setIsShowCollapse(false);
    };
    const getNameWithBigger = (name: string) => {
        const arrName = name.split('');
        arrName[0] = arrName[0]?.toLocaleUpperCase();
        return arrName.join('');
    };
    const showingFilters = useMemo(() => memoFilters?.filter((item, index) => item && index + 1 <= countShowing), [memoCategory, countShowing, category, findString]);
    const getTemplateFilters = useMemo(() => (
        showingFilters?.map((filter) => (
            <CampaingFilter
                key={`${filter.count}_${filter.filter_name}_${new Date().getTime()}_${category?.filters_category}`}
                checkBoxId={listId[filter.filter_name]}
                label={getNameWithBigger(filter.filter_name)}
                handlerConditions={filter?.handler}
                count={filter.count}
                disabled={filter.disabled}
                withConditions={filter?.withConditions}
                checked={filter.include || filter.exclude}
                disableCounter={category?.disableCounter}
                onChange={(value) => updateCategories(memoCategory.filters_category, filter.filter_name, isExclude ? 'exclude' : 'include')}
            />
        ))
    ), [memoCategory, category, showingFilters, memoFilters, findString]);

    const handleOnChangeCollapse = () => {
        if (typeof onOpen === 'function') onOpen(category?.filters_category);
    };
    const getActiveKey = useMemo((): string => {
        if (!isOpen) return '';
        return 'key-types';
    }, [isOpen]);
    // const setTypeChage = (type: 1 | 2) => {
    //     changeTypeFiltersApply(type);
    // };
    const moreButton = countShowing <= memoFilters?.length ? <div className={styles.moreController} onClick={handleClickOnMore}>Еще</div> : '';
    const hideButton = isShowCollapse ? <div className={`${styles.moreController}`} onClick={handleClickOnHide}>Свернуть</div> : '';
    const findInput = category?.filters?.length > max ? <FiltersFindInput findString={findString} onChangeFind={onChangeFind} /> : '';
    return (
        <>
            <Collapse
                onChange={handleOnChangeCollapse}
                defaultActiveKey={getActiveKey}
                ghost
            >

                <Collapse.Panel
                    className={`${styles.customPanel} ${disabledSeporator && styles.smallPanel}`}
                    key="key-types"
                    header={(
                        <HeaderPanel
                            onClickControl={(e: React.MouseEvent) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClickControl(e);
                            }}
                            withControls={withControls}
                            name={memoCategory?.filters_category || 'Пользовательские фильтры'}
                            isImportant={isImportant}
                        />
                    )}
                >
                    { children || (showingFilters?.length ? getTemplateFilters : <div className={styles.emptyFilters}>пусто</div>) }
                    <div className={styles.containerControllers}>
                        { moreButton }
                        { hideButton }
                    </div>
                    { findInput }

                </Collapse.Panel>
            </Collapse>
            {seporatorLine ? <div className={styles.seporatorLine} /> : ''}
        </>
    );
};

export default React.memo(MyCompaignFilters);
