const scales = {
    x: {
        grid: {
            color: 'rgba(0,0,0,0)',
        },
    },
    y: {
        border: false,
        min: 0,
        ticks: {
            padding: 10,
            maxTicksLimit: 7,
            callback(value) {
                if (Math.floor(value) === value) { return `${value}`; }
                return '';
            },
        },
        grid: {
            color: 'rgba(0,0,0,0)',
        },
    },
};
const plugins = {
    legend: {
        display: false,
    },
    title: {
        display: false,
    },
    // tooltip: tooltipHtml,
    tooltip: {
        bodyFont: {
            size: '14px',
            weight: 500,
        },
        footerFont: {
            size: '14px',
            weight: 400,
        },
        titleFont: {
            size: '14px',
            weight: 400,
        },
        displayColors: false,
        backgroundColor: 'white',
        borderColor(data) {
            return data?.tooltipItems[0]?.dataset?.allColors?.color || '#dfdfdf';
        },
        callbacks: {
            label(data) {
                const value = data?.formattedValue?.replaceAll(',', '.');
                return `ER ${value}%`;
            },
        },
        // borderColor: '#dfdfdf',
        bodyColor: '#787E93',
        footerColor: '#787E93',
        titleColor: '#787E93',
        borderWidth: 1,
        borderRadius: 6,
    },
};
export const OPTIONS = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false,
    stacked: false,
    beginAtZero: false,
    scales,
    plugins,
};
