export enum Platforms {
    VK = 'vk',
    OK = 'ok',
    RUTUBE = 'rutube',
    YOUTUBE = 'youtube',
    RSS = 'rss',
    DZEN = 'dzen',
    TG = 'tg',
}

export const DATE_FORMAT_FOR_QUERY = 'YYYY-MM-DD';
export const DATE_FORMAT_FOR_QUERY_TIME = 'YYYY-MM-DDThh:mm';

export const COLORS_FOR_CHARTS = [
    {
        light: '#E1F3FC',
        default: '#2F9FE0',
        dark: '#084D75',
    },
    {
        light: '#E6FAF7',
        default: '#20BAA3',
        dark: '#0A6358',
    },
    {
        light: '#EEDEFA',
        default: '#9545D8',
        dark: '#7613C7',
    },
    {
        light: '#FCE8E1',
        default: '#E05B2B',
        dark: '#942C06',
    },
    {
        light: '#E6EEFC',
        default: '#2E6FD9',
        dark: '#084D75',
    },
    {
        light: '#ebf3f7',
        default: '#598296',
        dark: '#253740',
    },
    {
        light: '#E3E3FA',
        default: '#4C4CE0',
        dark: '#201D8F',
    },
    {
        light: '#F1FAE6',
        default: '#81D42F',
        dark: '#3D7307',
    },
    {
        light: '#FCF1DC',
        default: '#E3A322',
        dark: '#916504',
    },
    {
        light: '#F7DAE3',
        default: '#D92762',
        dark: '#8D0633',
    },
];

export const TAB_LIST_MONITORING = [
    {
        label: 'Общий',
        id: 'shared_tab',
    },
    {
        label: 'Детализированный',
        id: 'detail_tab',
    },
];

// const generateDataset = (from?, to?) => {
//     const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
//     let currentDateTimestamp = Math.floor(new Date().getTime()); // текущая дата в виде unix timestamp
//     const oneDayInSeconds = 24 * 60 * 60;
//     // eslint-disable-next-line no-return-assign
//     const data = Array.from({ length: 100 }).map(() => ({
//         x: currentDateTimestamp += oneDayInSeconds * 1000, // увеличиваем timestamp на один день
//         y: generateRandomNumber(from || 1, to || 250),
//         content: null,
//     }));
//     return data;
// };
