import { IChartPointsModel } from '../../services/campaigns/Charts/models';

interface IPiecePieChart {
    value: number,
    label: string,
    color: string,
    borderColor: string,
}
export function createPointsFromCommons() {
    return false;
}
const randomColor = () => {
    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const h = randomInt(0, 255);
    const s = randomInt(0, 255);
    const l = randomInt(0, 255);
    return `rgba(${h},${s},${l}, 0.8)`;
};

function getSettingsForData(piecesPie: IPiecePieChart[]) {
    const settings = piecesPie.reduce((acc, item) => {
        const color = item.color || randomColor();
        const newAcc = acc;
        newAcc.data.push(item.value);
        newAcc.backgroundColor.push(color);
        newAcc.borderColor.push(item.borderColor || color);
        return newAcc;
    }, {
        data: [],
        backgroundColor: [],
        borderColor: [],
    });
    return settings;
}
export class BarChart {
    subjects: IChartPointsModel[];

    options: any = {};

    dataset:any = {
        borderWidth: 1,
        radius: '40%',
    };

    constructor(subjects: IChartPointsModel[]) {
        this.subjects = subjects;
    }

    getOptions(animation?: boolean) {
        const options: any = {
            ...this.options,
            animation,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                tooltip: {
                    bodyFont: {
                        size: '14px',
                        weight: 500,
                    },
                    footerFont: {
                        size: '14px',
                        weight: 400,
                    },
                    titleFont: {
                        size: '14px',
                        weight: 400,
                    },
                    callbacks: {
                        label(data) {
                            return data?.dataset?.label;
                        },
                    },
                    displayColors: false,
                    backgroundColor: 'white',
                    borderColor(data) {
                        return data?.tooltipItems[0]?.dataset?.allColors?.color || '#dfdfdf';
                    },
                    // borderColor: '#dfdfdf',
                    bodyColor: '#787E93',
                    footerColor: '#787E93',
                    titleColor: '#787E93',
                    borderWidth: 1,
                    borderRadius: 6,
                },
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        callback: () => '',
                    },
                },
            },
        };
        return options;
    }

    getPiecesPie() {
        return this.subjects.map((item) => {
            const newItem = {
                value: +item.y[0],
                label: item?.subjectValues?.join(','),
                color: randomColor(),
                borderColor: 'rgba(0,0,0,0)',
            };
            return newItem;
        });
    }

    public create(type): any {
        const listPiecesPie = this.getPiecesPie();
        const options = this.getOptions();
        const data = getSettingsForData(listPiecesPie);
        return {
            options,
            typeChart: type,
            data: {
                labels: listPiecesPie.map((item) => item.label),
                datasets: [
                    {
                        type: 'bar',
                        data: data.data,
                        backgroundColor: data.backgroundColor,
                        borderColor: data.borderColor,
                    },
                ],
            },
        };
    }
}
