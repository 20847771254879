import { useState } from 'react';
import Showing from './Showing.svg';
import UnShowing from './UnShowing.svg';
import styles from './styles.module.scss';

const AuthPage = function AuthPage({
    placeholder, type, value, setValue, withImage, onKeyDown, tagId,
}: any) {
    const [isShowing, setIsShowgin] = useState(type !== 'password');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleClickOnShowing = () => {
        if (type !== 'password') return;
        setIsShowgin(!isShowing);
    };
    return (
        <div className={styles.inputContainer}>
            <input
                className={styles.authInput}
                id={tagId}
                placeholder={placeholder}
                type={isShowing ? 'text' : 'password'}
                onChange={handleChange}
                value={value}
                onKeyDown={onKeyDown}
                style={{ paddingRight: withImage ? '24px' : '8px' }}
            />
            {
                value.length > 0 && withImage
            && (
                <button type="button" className={styles.showingContainer} id="auth-showpass" onClick={(handleClickOnShowing)}>
                    <img src={isShowing ? Showing : UnShowing} alt="Showing" />
                </button>
            )
            }
        </div>
    );
};

export default AuthPage;
