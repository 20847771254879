/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Path from '../../../shared/lib/paths';
import BubbleChart from '../../../components/BubbleChart';
import DoughnutChart from '../../../components/DoughnutChart';
import HorizontalChart, { IPoint } from '../../../components/HorizontalChart';
import { IRawChart } from '../../../components/campaign/ChartFilters/types';
import CampaignReportsChartList from '../../../components/campaign/Report/ChartList';
import CampaignReportsList from '../../../components/campaign/Report/ReportsList';
import LinearLoader from '../../../components/common/Loader';
import useCreatePdfDocument from '../../../shared/hooks/pdf-handler/useCreatePdfDocument';
import usePdfHandler from '../../../shared/hooks/pdf-handler/usePdfHandler';
import { reportsRtkQService } from '../../../services/reports';
import { campaignReportsRtkQService } from '../../../services/campaigns/reports';
import { selectCommonWindowIsIPadSize } from '../../../store/common/window/selectors';
import { useAppDispatch } from '../../../store/hooks';
import pdfHandlerReportSelectors from '../../../store/pdf-handler/report/selectors';
import deleteReportFromCampaignThunk from '../../../store/pdf-handler/thunks/delete-report-from-campaign';
import loadPdfReportFileThunk from '../../../store/pdf-handler/thunks/load-file-report';
import savePdfReportWithFileThunk from '../../../store/pdf-handler/thunks/save-report';
import { pdfHandlerWrapperActions } from '../../../store/pdf-handler/wrapper';
import pdfHandlerWrapperSelectors from '../../../store/pdf-handler/wrapper/selectors';
import { AppState } from '../../../store/store';
import { CommonProcessStatus, FetchStatus } from '../../../store/types';
import { ICategory, IFilter } from '../../../sections/MyCampaignContainer/CampaingTypes';
import MessageArrowIcon from './imgs/MessageArrow.svg';
import styles from './styles.module.scss';
import { CampaignReportFormat, ICampaignReportsListItem } from './types';

const CampaignReportsContainer: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { Id: campaignId } = useParams();
    const [regionData, setRegionData] = useState({ rss: null, blogger: null });
    const [additionalData, setAdditionalData] = useState({
        tonnality: null,
        platforms: null,
    });
    // const [testImg, setTestImg] = useState('');
    const [listImages, setListImages] = useState([]);
    const timer = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInitiationChart, setIsInitiationChart] = useState<boolean>(true);
    const [chartsForChoice, setChartsForChoice] = useState<IRawChart[]>([]);
    // const [loadingCharts, setLoadingCharts] = useState<boolean>(false);
    const [allCharts, setCharts] = useState<IRawChart[]>([]);
    const [selectedCharts, setSelectedCharts] = useState<IRawChart[]>([]);
    const [categiries, setCategiry] = useState<ICategory>(null);
    const [authors, setAuthors] = useState<ICategory>(null);

    useCreatePdfDocument();
    // const { createPptxFile } = usePptxHandler();

    const {
        file,
        reportId: newReportId,
        downloadLink,
        documentIsPreparing,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        loadingData,
    } = usePdfHandler();

    const savedReports = useSelector(pdfHandlerReportSelectors.reports);
    const loadReportStatus = useSelector(
        pdfHandlerReportSelectors.loadReportStatus,
    );
    const reportsIds = useSelector(pdfHandlerReportSelectors.reportsIds);
    const reportSummaryStatus = useSelector(
        pdfHandlerWrapperSelectors.summaryDataFetchStatus,
    );
    // const campaignSummaryStatus = useSelector(
    //     campaignSummarySelectors.fetchStatus,
    // );
    const reportCreateStatus = useSelector(
        pdfHandlerWrapperSelectors.reportCreateStatus,
    );

    // TODO: delete one of the services: reportsRtkQService | campaignReportsRtkQService
    // const [getCampaignSummary] = campaignStatisticsRtkQService.useLazyPostLoadCampaignSummaryQuery();
    const [loadReportAgg] = reportsRtkQService.useLazyPostLoadReportAggQuery();
    const [loadSavedReports] = reportsRtkQService.useLazyGetSavedReportsQuery();
    const [loadChartsForReport, { data: chartsData, isLoading: loadingCharts }] = reportsRtkQService.useLazyGetChartsForReportQuery();

    const [reportName, setReportName] = useState<string>('');
    const [reportPeriod, setReportPeriod] = useState<string>('week');
    // const [selectedChartsIds, setChartsIds] = useState<number[]>([]);
    const [reports, setReports] = useState<ICampaignReportsListItem[]>([]);
    const [startCreateReport, setCreateReportStart] = useState<boolean>(false);
    const [tonality, setTonality] = useState<ICategory>();
    const [platfroms, setPlatfroms] = useState<ICategory>();
    // const [sourcesTypes, setSourcesTypes] = useState<ICategory>();
    const [sourcesTypesData, setSourcesTypesData] = useState<any[]>([]);
    const [clickedReportId, setClickedReportId] = useState<string>('');
    const [clearImages, setClearImages] = useState<boolean>(false);
    const [format, setFormat] = useState<CampaignReportFormat>(
        CampaignReportFormat.pptx,
    );
    const downloadUrl = useSelector((state: AppState) => pdfHandlerReportSelectors.urlByReportId(state, clickedReportId));
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);

    // const [saveReportFile] = campaignReportsRtkQService.useLazyPostSaveReportFileQuery();
    const [getFilters, { data: filtersData }] = campaignReportsRtkQService.useLazyGetFiltersQuery();

    const handlerCreateReport = useCallback(
        (name: string): void => {
            // dispatch(loadPdfCampaignSummaryDataThunk(Number(campaignId), timeFrame));
            // getCampaignSummary({ id: Number(campaignId), period: timeFrame });
            setReportName(name);
            // setReportPeriod(timeFrame);
            setClearImages(true);
            setCreateReportStart(true);
            dispatch(pdfHandlerWrapperActions.startCreateReport());
        },
        [campaignId, allCharts, format],
    );

    useEffect(() => {
        const createPptx = async () => {
            // const reportTimeFrame = reportPeriod === '7' ? 'WEEK' : 'MONTH';
            // const pptxFile = await createPptxFile(
            //     reportName,
            //     reportPeriod === '7' ? 'WEEK' : 'MONTH',
            // );
            setCreateReportStart(false);
            setSelectedCharts([]);
            setChartsForChoice([...allCharts]);
            // saveReportFile({
            //     campaignId,
            //     data: {
            //         file: pptxFile,
            //         reportName,
            //         reportTimeFrame,
            //         extension: 'pptx',
            //         generated: true,
            //         chart_ids: [],
            //     },
            // });
        };
        if (
            // campaignSummaryStatus === FetchStatus.success
            reportSummaryStatus === FetchStatus.success
            && startCreateReport
            && format === CampaignReportFormat.pptx
        ) {
            createPptx();
        }
    }, [
        // campaignSummaryStatus,
        reportSummaryStatus,
        startCreateReport,
        format,
        reportName,
        reportPeriod,
        allCharts,
    ]);

    const handleChangeFormat = (value: CampaignReportFormat): void => {
        setFormat(value);
    };

    const handlerSelectPeriod = useCallback(
        (period: string): void => {
            // dispatch(loadPdfReportSummaryDataThunk(Number(campaignId), period));
            loadReportAgg({ id: Number(campaignId), period });
            if (period) {
                setReportPeriod(period);
            }
        },
        [campaignId, allCharts, reportPeriod],
    );
    const handlerDeleteReport = useCallback(
        (id: string) => {
            if (campaignId && reportsIds?.length && reportsIds.includes(id)) {
                dispatch(
                    deleteReportFromCampaignThunk(
                        Number(campaignId),
                        Number(id),
                    ),
                );
            }
        },
        [reportsIds, campaignId],
    );
    const getListImages = useCallback(
        (list: any): void => {
            if (selectedCharts.length === list.length) {
                setListImages(list);
                dispatch(pdfHandlerWrapperActions.setChartList(list));
            }
        },
        [selectedCharts],
    );
    const updateCharts = (chart: any): void => {
        if (!chart) return;
        setIsLoading(true);
        setIsInitiationChart(true);
        setTimeout(() => {
            setIsInitiationChart(false);
        }, 1500);
        setChartsForChoice((prev) => prev.filter((item) => item.id !== chart.id));
        setSelectedCharts((prev) => {
            const newSelectedCharts = [...prev];
            newSelectedCharts.push(chart);
            return newSelectedCharts;
        });
    };

    const handlerDownloadReport = (id: string): void => {
        const report = reports.find(({ reportId }) => id === reportId);
        if (report && report?.reportId) {
            setClickedReportId(report.reportId);
            dispatch(
                loadPdfReportFileThunk(
                    Number(campaignId),
                    Number(report?.reportId),
                ),
            );
        }
    };

    const summaryDataByCampaignIdAndPeriod = useSelector((state: AppState) => pdfHandlerWrapperSelectors.summaryDataByCampaignIdAndPeriod(
        state,
        +campaignId,
        reportPeriod,
    ));
    const getRssByRegion = useMemo(() => {
        if (!summaryDataByCampaignIdAndPeriod) return [];
        const rssList = summaryDataByCampaignIdAndPeriod.topSourcesByRegions.filter(
            (item) => item.authorType === 'СМИ',
        );
        const formatList = rssList.map((item) => ({
            x: item.amountSources,
            y: item.regionTitle,
            constent: { label: item.regionTitle },
        }));
        return formatList.splice(0, 9);
    }, [summaryDataByCampaignIdAndPeriod]);
    const getBloggerByRegion = useMemo(() => {
        if (!summaryDataByCampaignIdAndPeriod) return [];
        const bloggerList = summaryDataByCampaignIdAndPeriod.topSourcesByRegions.filter(
            (item) => item.authorType === 'Блоггер',
        );
        const formatList = bloggerList.map((item) => ({
            x: item.amountSources,
            y: item.regionTitle,
            constent: { label: item.regionTitle },
        }));
        return formatList.splice(0, 9);
    }, [summaryDataByCampaignIdAndPeriod]);

    const reportCreateMessage = useMemo<JSX.Element>(() => {
        if (
            reportCreateStatus === CommonProcessStatus.process
            // && campaignSummaryStatus !== FetchStatus.failed
            && reportSummaryStatus !== FetchStatus.failed
        ) {
            return (
                <div className={styles.messageBox}>
                    <span>Идет процесс создания отчета</span>
                    <span>Это может занять некоторое время.</span>
                    <span
                        className={styles.flexRow}
                        style={{ justifyContent: 'space-between' }}
                    >
                        Готовый отчет появится справа
                        <img src={MessageArrowIcon} alt={MessageArrowIcon} />
                    </span>
                    <div
                        className={styles.flexRow}
                        style={{ justifyContent: 'center' }}
                    >
                        <LinearLoader />
                    </div>
                </div>
            );
        }
        if (
            reportCreateStatus === CommonProcessStatus.failed
            // || campaignSummaryStatus === FetchStatus.failed
            || reportSummaryStatus === FetchStatus.failed
        ) {
            return (
                <div className={styles.messageBox}>
                    <span className={styles.messageBox_error}>
                        При формировании отчёта произошла ошибка.
                    </span>
                    <span className={styles.messageBox_error}>
                        Повторите попытку через несколько минут.
                    </span>
                </div>
            );
        }

        return null;
    }, [reportCreateStatus, reportSummaryStatus]);

    useEffect(() => {
        if (downloadUrl) {
            const pdfWindow = window.open();
            if (!pdfWindow?.location?.href) {
                window?.open(downloadUrl, 'Download');
            } else {
                pdfWindow.location.href = downloadUrl;
            }
        }
    }, [downloadUrl]);
    useEffect(() => {
        if (!timer.current) {
            const newTimer = setTimeout(() => {
                setIsLoading(false);
                timer.current = null;
            }, 300);
            timer.current = newTimer;
        }
    }, [listImages]);
    useEffect(() => {
        if (
            // campaignSummaryStatus === FetchStatus.success
            reportSummaryStatus === FetchStatus.success
            && startCreateReport
            && format === CampaignReportFormat.pdf
        ) {
            const uuid = uuidv4();
            dispatch(pdfHandlerWrapperActions.setReportId(uuid));
            dispatch(
                pdfHandlerWrapperActions.setChartsIds(
                    selectedCharts.map(({ id }) => id),
                ),
            );
            dispatch(
                pdfHandlerWrapperActions.setCampaignId(Number(campaignId)),
            );
            dispatch(pdfHandlerWrapperActions.setReportName(reportName));
            dispatch(pdfHandlerWrapperActions.setFileName(`${reportName}.pdf`));
            dispatch(
                pdfHandlerWrapperActions.setReportTimeFrame(
                    reportPeriod === '7' ? 'WEEK' : 'MONTH',
                ),
            );
        }
    }, [
        // campaignSummaryStatus,
        reportSummaryStatus,
        startCreateReport,
        reportName,
        reportPeriod,
        allCharts,
        format,
    ]);
    useEffect(() => {
        if (
            startCreateReport
            && reportCreateStatus !== CommonProcessStatus.process
            && format === CampaignReportFormat.pdf
        ) {
            setCreateReportStart(false);
            setSelectedCharts([]);
            setChartsForChoice([...allCharts]);
        }
    }, [reportCreateStatus, startCreateReport, allCharts, format]);
    useEffect(() => {
        const id = Number(campaignId);
        if (id) {
            dispatch(pdfHandlerWrapperActions.clearSummaryByCampaignId(id));
            // dispatch(loadPdfReportSummaryDataThunk(id, '7'));
            loadReportAgg({ id, period: '7' }); // получаем по умолчанию, т.к. выбран этот период
            // dispatch(loadSingleCampaignIfNotExists(id));
            // dispatch(loadSavedChartsForSingleCampaignThunk(id));
            // dispatch(loadSavedReportsForSingleCampaignThunk(id));
            loadChartsForReport({ id });
            loadSavedReports({ id });
        }
    }, [campaignId]);
    useEffect(() => {
        if (
            newReportId
            && file?.content
            && file.content?.size > 0
            && reportName
        ) {
            const report = reports.find(
                ({ id }) => newReportId === id.toString(),
            );
            if (!report || report?.fileContent?.size !== file.content.size) {
                setReports(
                    [
                        {
                            reportId: newReportId,
                            fileContent: file.content,
                            fileName: file?.name,
                            reportPeriod,
                            reportName,
                            id: reports.length + 1,
                            dateCreate: new Date().toLocaleDateString('ru-RU'),
                            downloadUrl: '',
                            generated: true,
                        },
                        ...reportsIds.map(
                            (id) => ({
                                id: Number(id),
                                reportName: savedReports[id]?.name,
                                reportPeriod: '',
                                reportId: id,
                                dateCreate: savedReports[id]?.created,
                                fileContent:
                                savedReports[id]?.file?.content,
                                fileName: savedReports[id]?.file?.name,
                                downloadUrl: savedReports[id]?.downloadUrl,
                                generated: savedReports[id]?.generated,
                            } as ICampaignReportsListItem),
                        ),
                    ].sort((a, b) => b.createTimeStamp - a.createTimeStamp),
                );
                dispatch(
                    savePdfReportWithFileThunk(Number(campaignId), newReportId),
                );
            }
        }
    }, [file, newReportId]);
    useEffect(() => {
        setReports([
            ...reportsIds
                .map(
                    (id) => ({
                        id: Number(id),
                        reportName: savedReports[id]?.name,
                        reportPeriod: '',
                        reportId: id,
                        dateCreate: savedReports[id]?.created,
                        fileContent: savedReports[id]?.file?.content,
                        fileName: savedReports[id]?.file?.name,
                        downloadUrl: savedReports[id]?.downloadUrl,
                        createTimeStamp: savedReports[id]?.createTimeStamp,
                        generated: savedReports[id]?.generated,
                    } as ICampaignReportsListItem),
                )
                .sort((a, b) => b.createTimeStamp - a.createTimeStamp),
        ]);
    }, [savedReports]);
    const getFormatCategories = useMemo((): IPoint[] => {
        if (!categiries) return [];
        const maxedList = categiries?.filters.filter(
            (item, index) => index < 20,
        );
        const result = maxedList.map((item) => {
            const newItem = {
                x: item.count,
                y: item.filter_name,
                content: { label: item.filter_name },
            };
            return newItem;
        });
        return result;
    }, [categiries]);
    const getFormatAuthors = useMemo((): IPoint[] => {
        if (!authors) return [];
        const maxedList = authors?.filters.filter((item, index) => index < 20);
        const result = maxedList.map((item) => {
            const newItem = {
                x: item.count,
                y: item.filter_name,
                content: { label: item.filter_name },
            };
            return newItem;
        });
        return result;
    }, [authors]);
    const getFormatTonlity = useMemo((): IPoint[] => {
        if (!tonality) return [];
        const maxedList = tonality?.filters.filter(
            (item) => item.filter_name !== 'skip',
        );
        const result = maxedList.map((item) => {
            const translate = {
                negative: 'Негативно',
                positive: 'Позитивно',
                neutral: 'Нейтрально',
            };
            const newItem = {
                x: item.count,
                y: translate[item.filter_name],
                content: { label: item.filter_name },
            };
            return newItem;
        });
        return result;
    }, [tonality]);
    const getFormatPlatfroms = useMemo((): any[] => {
        if (!platfroms) return [];
        const listColors = {
            rss: '#0EBFA1',
            ok: '#E67E22',
            vk: '#0077FF',
            tg: '#129FD4',
            dzen: '#ffcc01',
        };
        const maxedList = platfroms?.filters.filter(
            (item) => item.filter_name !== 'skip',
        );
        const result = maxedList.map((item) => {
            const newItem = {
                value: item.count,
                label: item.filter_name,
                color: listColors[item.filter_name],
            };
            return newItem;
        });
        return result;
    }, [platfroms]);
    // const getFormatSourceTypes = useMemo((): any[] => {
    //     if (!platfroms) return [];
    //     const maxedList = sourcesTypes?.filters;
    //     const result = maxedList.map((item) => {
    //         const newItem = {
    //             value: item.count,
    //             label: item.filter_name,
    //             color: item.filter_name === 'СМИ' ? '#201D8F' : '#9545D8',
    //         };
    //         return newItem;
    //     });
    //     return result;
    // }, [sourcesTypes]);
    const updateSourcesTypesData = (img) => {
        dispatch(pdfHandlerWrapperActions.setSourcesTypes(img));
    };
    const updateDataCategory = (img) => {
        dispatch(pdfHandlerWrapperActions.setCategoryData(img));
    };
    const updateDataAuthors = (img) => {
        dispatch(pdfHandlerWrapperActions.setAuthorData(img));
    };
    const updateDataRegion = (img, type) => {
        setRegionData((prev) => ({
            ...prev,
            [type]: img,
        }));
    };
    useEffect(() => {
        dispatch(pdfHandlerWrapperActions.setRegionData({ ...regionData }));
    }, [regionData]);
    const updateDataAdditional = (img, type) => {
        setAdditionalData((prev) => ({
            ...prev,
            [type]: img,
        }));
    };
    useEffect(() => {
        dispatch(
            pdfHandlerWrapperActions.setAdditionalData({ ...additionalData }),
        );
    }, [additionalData]);
    const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const randomColor = () => {
        const h = randomInt(0, 360);
        const s = randomInt(42, 98);
        const l = randomInt(60, 90);
        return `hsl(${h},${s}%,${l}%)`;
    };
    const getShufflingTags = (
        tags: Array<{ label: string; count: number; fontSize: number }>,
    ) => {
        const first = tags[0] ? JSON.parse(JSON.stringify(tags[0])) : tags[0];
        const second = tags[1] ? JSON.parse(JSON.stringify(tags[1])) : tags[1];
        const three = tags[2] ? JSON.parse(JSON.stringify(tags[2])) : tags[2];
        tags.splice(0, 3);
        const newTags = tags.sort(() => Math.random() - 0.5);
        newTags.splice(1, 0, three);
        newTags.splice(4, 0, first);
        newTags.splice(7, 0, second);
        return tags;
    };
    const formatTags = (tags: ICategory | null) => {
        if (!tags) return [];
        const maxSize = 32;
        const filters = Array.from(tags.filters);
        const shortList = filters.splice(0, 10);
        const data = shortList.reduce(
            (
                acc: Array<{ label: string; count: number; fontSize: number }>,
                item,
            ) => {
                if (!acc.length) {
                    return [
                        {
                            label: item.filter_name,
                            count: item.count,
                            fontSize: maxSize,
                        },
                    ];
                }
                const bigger = acc[0];
                const percent = (item.count / bigger.count).toFixed(1);
                const size = maxSize * +percent;
                const newItem = {
                    label: item.filter_name,
                    count: item.count,
                    fontSize: size >= 8 ? +size.toFixed(0) : randomInt(8, 14),
                };
                acc.push(newItem);
                return acc;
            },
            [],
        );
        const shuffling = getShufflingTags(data);
        const result = shuffling.map((item) => ({
            label: item?.label,
            fontSize: `${item?.fontSize}px`,
            color: randomColor(),
        }));
        return result;
    };
    const formingSourcesTypes = (sourceTypeFilters: IFilter[]) => sourceTypeFilters.map((item, index) => ({
        id: index,
        label: item.filter_name,
        value: item.count,
    }));
    useEffect(() => {
        if (campaignId) {
            getFilters({
                campaign_id: +campaignId,
            });
        }
    }, [campaignId]);

    useEffect(() => {
        if (filtersData) {
            setIsInitiationChart(false);
            const categoryList = filtersData.find(
                (item) => item.filters_category === 'Категории',
            );
            const authorsList = filtersData.find(
                (item) => item.filters_category === 'Авторы',
            );
            const tonalityList = filtersData.find(
                (item) => item.filters_category === 'Тональность',
            );
            const platformList = filtersData.find(
                (item) => item.filters_category === 'Типы платформ',
            );
            const sourceTypesList = filtersData.find(
                (item) => item.filters_category === 'Типы отслеживаемых авторов',
            );
            const newTags = filtersData.find(
                (item) => item.filters_category === 'Теги',
            );

            const tags = formatTags(newTags);
            dispatch(pdfHandlerWrapperActions.setTagList(tags));
            setCategiry(categoryList);
            setAuthors(authorsList);
            setTonality(tonalityList);
            setPlatfroms(platformList);
            // setSourcesTypes(sourceTypesList);
            setSourcesTypesData(formingSourcesTypes(sourceTypesList?.filters ?? []));
            setTimeout(() => {
                setIsInitiationChart(false);
            }, 2000);
        }
    }, [filtersData]);

    useEffect(() => {
        if (chartsData) {
            // @ts-ignore
            setCharts([...chartsData]);
            // @ts-ignore
            setChartsForChoice([...chartsData]);
        }
    }, [chartsData]);

    if (!campaignId) {
        return <Navigate to={`${Path.Campaign}/${Path.CampaignList}`} />;
    }

    return (
        <div className={styles.campaignReportsContainer_root}>
            {documentIsPreparing !== null && downloadLink ? (
                <div
                    id="new-report-download-link"
                    style={{ visibility: 'collapse' }}
                >
                    {downloadLink}
                </div>
            ) : null}
            <div
                className={styles.flexRow}
                style={
                    isIPadSize
                        ? { minWidth: 400, width: '100%', gap: 0 }
                        : { gap: 24 }
                }
            >
                <div
                    className={styles.flexColumn}
                    style={isIPadSize ? { minWidth: 400, width: 500 } : null}
                >
                    {/* { */}
                    {/*    isInitiationChart */}
                    {/*        ? ( */}
                    {/*            <div className={styles.loader}> */}
                    {/*                <QuickSVGLoader /> */}
                    {/*            </div> */}
                    {/*        ) */}
                    {/*        : '' */}
                    {/* } */}
                    <div
                        style={{ position: 'relative', height: 'fit-content' }}
                    >
                        <div
                            className={styles.messageWrapper}
                            style={
                                isIPadSize
                                    ? { minWidth: 400, width: 500 }
                                    : null
                            }
                        >
                            {startCreateReport ? (
                                reportCreateMessage
                            ) : (
                                <div>
                                    {/* <CampaignReportsFilters
                                        onSelectPeriod={handlerSelectPeriod}
                                        // disabledCreateBtn={
                                        //     startCreateReport
                                        //     || isLoading
                                        //     || isInitiationChart
                                        // }
                                        onCreateReport={handlerCreateReport}
                                        loadingDataForReport={
                                        // loadingData -> Always return true from usePdfHandler
                                            isLoading
                                        || isInitiationChart
                                        }
                                        updateCharts={updateCharts}
                                        charts={chartsForChoice}
                                        loadingCharts={loadingCharts}
                                        handleChangeFormat={handleChangeFormat}
                                        format={format}
                                        isIPadSize={isIPadSize}
                                    /> */}
                                </div>
                            )}
                        </div>
                    </div>
                    <CampaignReportsChartList
                        campaingId={campaignId}
                        charts={selectedCharts}
                        getListImages={getListImages}
                        isLoading={isLoading}
                        clearImages={clearImages}
                        imagesCleared={() => setClearImages(false)}
                    />
                </div>
                <CampaignReportsList
                    reports={reports}
                    isLoading={
                        loadReportStatus === FetchStatus.loading
                        || startCreateReport
                    }
                    onDeleteReport={handlerDeleteReport}
                    onDownloadReport={handlerDownloadReport}
                    isIPadSize={isIPadSize}
                    campaignId={campaignId}
                />
            </div>
            <div className={styles.hiddenChart}>
                <BubbleChart
                    animation={false}
                    data={sourcesTypesData}
                    getImage={updateSourcesTypesData}
                />
            </div>
            <div className={styles.hiddenChart}>
                {/* category */}
                <HorizontalChart
                    animation={false}
                    listPoints={getFormatCategories}
                    width={`${510 * 1.5}px`}
                    height={`${490 * 1.5}px`}
                    color="rgba(56, 163, 225, 1)"
                    alphaColor="rgba(56, 163, 225, 0.15)"
                    getImage={updateDataCategory}
                />
            </div>
            <div className={styles.hiddenChart}>
                {/* authors */}
                <HorizontalChart
                    animation={false}
                    listPoints={getFormatAuthors}
                    width={`${510 * 1.5}px`}
                    height={`${490 * 1.5}px`}
                    getImage={updateDataAuthors}
                />
            </div>
            <div className={styles.hiddenChart}>
                {/* rss by region */}
                <HorizontalChart
                    animation={false}
                    listPoints={getRssByRegion as any}
                    width={`${480 * 1.3}px`}
                    color="rgba(56, 163, 225, 1)"
                    alphaColor="rgba(56, 163, 225, 0.15)"
                    height={`${410 * 1.3}px`}
                    getImage={(e) => updateDataRegion(e, 'rss')}
                />
            </div>
            <div className={styles.hiddenChart}>
                {/* authors by region */}
                <HorizontalChart
                    animation={false}
                    listPoints={getBloggerByRegion as any}
                    width={`${480 * 1.3}px`}
                    height={`${410 * 1.3}px`}
                    getImage={(e) => updateDataRegion(e, 'blogger')}
                />
            </div>
            <div className={styles.hiddenChart}>
                {/* Tonnality */}
                <HorizontalChart
                    animation={false}
                    listPoints={getFormatTonlity}
                    width={`${330 * 1.5}px`}
                    height={`${180 * 1.5}px`}
                    getImage={(e) => updateDataAdditional(e, 'tonnality')}
                />
            </div>
            <div className={styles.hiddenChart}>
                {/* platforms */}
                <DoughnutChart
                    animation={false}
                    width={`${350}px`}
                    height={`${350}px`}
                    valueList={getFormatPlatfroms}
                    type="pie"
                    getImage={(e) => updateDataAdditional(e, 'platforms')}
                />
            </div>
        </div>
    );
};
export default React.memo(CampaignReportsContainer);
