import { Dropdown } from 'antd';
import React, { useState } from 'react';
import TextField from '@entities/TextField';
import PlusIcon from '../../../assets/images/plus.svg';
import styles from './styles.module.scss';

interface IProps {
    addWord: (word: string) => void;
}

const UserAddingFavoriteWord: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { addWord } = props;
    const [inputValue, setInputValue] = useState<string>('');

    const handleChangeInputValue = (value: string) => {
        setInputValue(value);
    };

    const handleClickAdd = (): void => {
        if (inputValue.length >= 2) {
            addWord(inputValue);
            setInputValue('');
        }
    };

    return (
        <div>
            <Dropdown
                trigger={['click']}
                overlay={(
                    <div className={styles.dropdown}>
                        {/* <p className={styles.dropdownTitle}>Введите слово</p>
                        <div className={styles.searchInput}>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleChangeInputValue}
                            />
                            <img src={SearchIcon} alt="SearchIcon" width={16} height={16} />
                        </div> */}

                        <TextField
                            label="Введите слово"
                            value={inputValue}
                            onChange={handleChangeInputValue}
                            isSearch
                        />

                        <button
                            type="button"
                            className={styles.dropdownButton}
                            onClick={handleClickAdd}
                            disabled={inputValue.length < 2}
                        >
                            Добавить
                        </button>
                    </div>
                )}
            >
                <button
                    type="button"
                    className={styles.button}
                >
                    <span>Добавить слово</span>
                    <div>
                        <img src={PlusIcon} alt="PlusIcon" />
                    </div>
                </button>
            </Dropdown>
        </div>
    );
};
export default UserAddingFavoriteWord;
