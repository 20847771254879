import { combineReducers } from 'redux';
import sourceReducer from './source/index';
import tableReducer from './table/index';
import savedChartsReducer from './charts/index';
import trackedSourcesReducer from './tracked-sources/index';
import userRightsReducer from './userRights/index';
import campaignComparisonReducer from './comparison/index';
import campaignSearchRequestReducer from './search-request/index';
import favoriteSourcesReducer from './favorite-sources/index';
import campaignCommonReducer from './common/index';

const campaignReducers = () => combineReducers({
    source: sourceReducer,
    table: tableReducer,
    savedCharts: savedChartsReducer,
    trackedSources: trackedSourcesReducer,
    userRights: userRightsReducer,
    comparison: campaignComparisonReducer,
    searchRequest: campaignSearchRequestReducer,
    favoriteSources: favoriteSourcesReducer,
    campaignCommon: campaignCommonReducer,
});

export default campaignReducers;
