/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { setCookies } from 'cookies-next';
import { useNavigate } from 'react-router';
import { userAuthorizationRtkQService } from '@services/authorization';
import LoginInput from '@components/LoginInput';
import QuickSVGLoader from '@components/QuickSVGLoader';
import { Button } from '@shared/index';
import Path from '@shared/lib/paths';
import { initRecoveryPassword } from '../../deprecated/api/restorePassword';
import { AUTH_COOKIE_NAME } from '../../utils/constants';
import BigLogo from './BigLogo.svg';
import ArrowLeft from './ArrowLeft.svg';
import Bars from './Bars.svg';
import Chart from './Chart.svg';
import styles from './styles.module.scss';

const AuthPage = function AuthPage() {
    const navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [isComplete, setComplete] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('auth');
    const inputsRef = useRef(null);

    const [
        authorization,
        {
            data: authData, isFetching, isLoading, isError, isSuccess,
        },
    ] = userAuthorizationRtkQService.useLazyPostAuthQuery();

    const handleClickAuth = async () => {
        authorization({ password, email: login.trim() });
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter' && password?.length > 0 && login?.length > 0 && !isLoading) {
            handleClickAuth();
        }
    };
    const handleRestoreAuth = (): void => {
        // init_password_recovery
        initRecoveryPassword(email).then(() => {
            setComplete(true);
        });
    };

    useEffect(() => {
        if (!(isFetching || isLoading)) {
            if (isSuccess) {
                if (authData?.token?.length > 0) {
                    setCookies(AUTH_COOKIE_NAME, authData.token);
                    navigate(Path.Campaign);
                }
            }
        }
    }, [authData, isFetching, isLoading, isSuccess]);

    const restorePasswordMarkup = (
        <>
            <div className={styles.labelBack} onClick={() => setType('auth')}>
                <img src={ArrowLeft} alt="ArrowLeft" />
                <div className={styles.labelLeft}>Я помню свой пароль!</div>
            </div>
            <LoginInput onKeyDown={handleKeyDown} placeholder="Email" type="text" value={email} setValue={setEmail} withImage={false} />
            <Button text="Отправить" type="default" size="large" onClick={() => handleRestoreAuth()} />
        </>
    );
    const authMarkup = (
        <>
            <div className={styles.maskInputsContent}>
                Mask
                {login.length > password.length ? login : password}
            </div>
            <LoginInput tagId="auth-login" onKeyDown={handleKeyDown} placeholder="Login" type="text" value={login} setValue={setLogin} withImage={false} />
            <LoginInput tagId="auth-pass" onKeyDown={handleKeyDown} placeholder="Password" type="password" value={password} setValue={setPassword} withImage />
            <Button tagId="auth-enter" text="Войти" type="default" size="large" onClick={handleClickAuth} />
        </>
    );
    const authInformation = isError
        ? (
            <div className={styles.exceptionBlock}>
                <div className={styles.exception}>
                    <div style={{ marginBottom: '16px' }}>Не правильный логин или пароль!</div>
                    <div>Проверьте введенные данные и попытайтесь снова</div>
                </div>
                <div className={styles.forgetPassword} onClick={() => setType('restorePassword')}>
                    <div>Забыли пароль</div>
                </div>
            </div>
        )
        : (
            <div className={styles.createAccountInfo}>
                У меня нет учетной записи.
                <div className={styles.underlineText} id="auth-request-acces" onClick={() => navigate(Path.RequestAccess)}>
                    Получить доступ
                    <div className={styles.underline} />
                </div>
            </div>
        );
    const resotreInformation = (
        <div className={styles.exceptionBlockInfromation}>
            <div className={styles.informationForgot}>
                <div>Чтобы сбросить пароль, введите свой адрес электронной почты.</div>
                <div>Мы вышлем вам ссылку для создания нового пароля.</div>
            </div>
        </div>
    );
    const getCurrentMarkup = (typeMarkup) => {
        const listMarkups = {
            auth: authMarkup,
            restorePassword: restorePasswordMarkup,
        };
        return listMarkups[typeMarkup];
    };
    const getMarkupBottomInfo = (typeMarkup) => {
        const listMarkups = {
            auth: authInformation,
            restorePassword: resotreInformation,
        };
        return listMarkups[typeMarkup];
    };
    const setDefaults = () => {
        setType('auth');
        setLogin('');
        setEmail('');
        setPassword('');
        setComplete(false);
    };
    const defaultLayout = (
        <div className={styles.layout}>
            {
                isLoading && (
                    <div className={styles.containerLoader}>
                        <QuickSVGLoader />
                    </div>
                )
            }
            <div className={styles.centeredMarkaup}>
                <div className={styles.logoContainer}>
                    <img src={BigLogo} alt="BigLogo" />
                </div>
            </div>
            <div
                className={styles.authBlock}
                style={{
                    background: isError ? 'none' : '#DFFCF7',
                }}
            >
                <div className={styles.authContainer} ref={inputsRef}>
                    {getCurrentMarkup(type)}
                </div>
            </div>
            <div className={styles.startedMarkup}>
                {
                    getMarkupBottomInfo(type)
                }
            </div>
            <div className={styles.barsContainer}>
                <img src={Bars} alt="" />
            </div>
            <div className={styles.chartContainer}>
                <img src={Chart} alt="" />
            </div>
        </div>
    );
    const CompleteLayout = (
        <div className={styles.layout}>
            <div className={styles.centeredMarkaup}>
                <button type="button" className={styles.AuthButton} onClick={() => setDefaults()}>
                    Успешно отправлено!
                </button>
            </div>
            <div className={styles.barsContainer}>
                <img src={Bars} alt="" />
            </div>
            <div className={styles.chartContainer}>
                <img src={Chart} alt="" />
            </div>
        </div>
    );
    return (
        isComplete ? CompleteLayout : defaultLayout
    );
};

export default AuthPage;
