/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { IUserFilter } from '../../../../services/campaigns/userFilters/types';
import { TChangedFilter } from '../../../../shared/hooks/campaign/useCategories/types';
import { getListFilters } from '../../../../components/campaign/TrackingLeftBar/helpers';
import styles from './styles.module.scss';
import TrackingLeftBar from '../../../../components/campaign/TrackingLeftBar';
import {
    IApplyFilter, IFilter, IListFilters, ListCategory,
} from '../../../../sections/MyCampaignContainer/CampaingTypes';
import StatusToggle from '../../../../features/campaign/tracking/StatusToggle';
import FooterFilters from '../../../../features/campaign/tracking/FooterFilters';
import { DateRangePickerValue } from '../../../../components/DateRangePicker/types';
import HeaderFilters from '../../../../features/campaign/tracking/HeaderFilters';
import UserTags from '../../../../components/UserTags';
import UserFilters from '../../../../components/campaign/UserFilters';
import { IArgSetFilters } from './types';
import { userFiltersCampaingRtqService } from '../../../../services/campaigns/userFilters';

interface IPropsCampaignFilters {
    campaignId: number,
    campaignCreateDate: string,
    isIPad: boolean,
    isActiveCampaign: boolean,
    isLoadingCategories: boolean,
    filtersTags: any,
    setFiltersTags: any,
    filters: IListFilters,
    categories: ListCategory,
    period: DateRangePickerValue,
    setPeriod: (period: DateRangePickerValue) => void,
    onSetFilters?: (setting?: IArgSetFilters) => void,
    onUpdateCategories?: (filter: IApplyFilter | IApplyFilter[], type: IApplyFilter | IApplyFilter[], upd?: boolean) => void,
    onCleanFilters?: () => void,
    activeTags: IFilter[]
}

const CampaignFilters: React.FC<IPropsCampaignFilters> = ({
    campaignId,
    isActiveCampaign,
    campaignCreateDate,
    isIPad,
    categories,
    isLoadingCategories,
    filtersTags,
    setFiltersTags,
    period,
    filters,
    setPeriod,
    onSetFilters,
    onUpdateCategories,
    onCleanFilters,
    activeTags,
}): JSX.Element => {
    const [listOpenFilters, setListOpenFilters] = useState<string[]>([]);
    const [listExclude, setListExclude] = useState<string[]>([]);
    const [getAllFilters] = userFiltersCampaingRtqService.useLazyGetAllUserFiltersQuery();
    const [getFilter] = userFiltersCampaingRtqService.useLazyGetSpecificFilterQuery();
    const [removeFilter] = userFiltersCampaingRtqService.useLazyDeleteApplyFilterQuery();
    const [userFilters, setUserFilters] = useState<IUserFilter[]>([]);
    const handleChangeUserFilters = (newFilterIncludes: IApplyFilter[], newFilterExcludes: IApplyFilter[]) => {
        onUpdateCategories(newFilterIncludes, newFilterExcludes, true);
        setListOpenFilters([...newFilterIncludes, ...newFilterExcludes].map((i) => i.filters_category));
    };
    const handleSelectFilter = (id: number) => {
        getFilter({ id, campaign_id: campaignId }).then((res) => {
            if (res && res?.data?.filters_include?.length) {
                handleChangeUserFilters(res?.data?.filters_include, res?.data?.filters_exclude);
            }
        });
    };
    const handleRemoveFilter = (id: number) => {
        removeFilter({ campaign_id: campaignId, id });
        setUserFilters((prev) => prev.filter((item) => item?.id !== id));
    };
    const createListFilters = () => {
        getAllFilters(campaignId)
            .then((res) => {
                setUserFilters(res?.data || []);
            });
    };
    const onChangeFilters = (filter: IUserFilter) => {
        setUserFilters((prev) => [filter, ...prev]);
    };
    const handlerUpdateApplyFilters = (newApplyFilter: IApplyFilter, type: TChangedFilter) => {
        if (listExclude.some((item) => item !== newApplyFilter.filters_category)) onUpdateCategories(null, newApplyFilter);
        else onUpdateCategories(newApplyFilter, null);
    };
    const handleUpdateOpenFilters = useCallback((name: string): void => {
        setListOpenFilters((prev) => {
            const hasFilter = prev.some((filter) => filter === name);
            if (hasFilter) return prev.filter((filter) => filter !== name);
            return [name, ...prev];
        });
    }, [listExclude]);
    const handleChangePeriod = (newPeriod: DateRangePickerValue) => {
        setPeriod(newPeriod);
    };
    const handle = () => {
        onSetFilters({
            period,
            filters: getListFilters(categories),
        });
    };
    const handleCreateFilters = (data: any) => {
        if (data?.title && data?.id) setUserFilters((prev) => [{ id: data?.id, title: data?.title }, ...prev]);
    };
    const handleChangeUserTags = (filter, name) => {
        const filterFromTag:IApplyFilter = {
            filter_name: name,
            filters_category: filter,
        };
        onUpdateCategories(filterFromTag, null);
    };
    const handlerChangeTypeFiltersApply = (type: 1 | 2, name: string) => {
        const isSome = listExclude.some((filter) => filter === name);
        if (isSome && type === 1) setListExclude((prev) => prev.filter((filter) => filter !== name));
        if (!isSome && type === 2) setListExclude((prev) => [name, ...prev]);
    };
    const Header = (
        <HeaderFilters
            campaignCreateDate={campaignCreateDate}
            period={period}
            onChangePeriod={handleChangePeriod}
        >
            <div>
                <UserTags
                    activeTags={activeTags}
                    campaignId={campaignId}
                    filtersTags={filtersTags}
                    setFiltersTags={setFiltersTags}
                    isExclude={listExclude.some((i) => i === 'Пользовательские теги')}
                    updateCategories={handleChangeUserTags}
                    changeTypeFiltersApply={handlerChangeTypeFiltersApply}
                />
                <UserFilters
                    campaingId={campaignId}
                    userFilters={userFilters}
                    onChangeFilters={onChangeFilters}
                    handleSelectFilter={handleSelectFilter}
                    handleRemoveFilter={handleRemoveFilter}
                />
            </div>

        </HeaderFilters>
    );
    const mounted = () => {
        createListFilters();
    };
    useEffect(mounted, []);
    const listCurrentFilters = useMemo(() => getListFilters(categories), [categories]);
    return (
        <div
            className={styles.root_campaignFilters}
            style={isIPad ? { minWidth: 296, width: 296 } : null}
        >
            <StatusToggle
                campaignId={campaignId}
                isActive={isActiveCampaign}
            />
            <TrackingLeftBar
                filters={categories}
                listExclude={listExclude}
                listOpenFilters={listOpenFilters}
                isLoading={isLoadingCategories}
                isIPad={isIPad}
                onUpdateApplyFilters={handlerUpdateApplyFilters}
                onUpdateOpenFilters={handleUpdateOpenFilters}
                changeTypeFiltersApply={handlerChangeTypeFiltersApply}
                upChild={Header}
                downChild={(
                    <FooterFilters
                        onCleanFilters={onCleanFilters}
                        includes={listCurrentFilters}
                        // todo excludes
                        excludes={listCurrentFilters}
                        campaignId={campaignId}
                        onSave={handleCreateFilters}
                        onConfirm={handle}
                    />
                )}
            />
        </div>

    );
};
export default React.memo(CampaignFilters);
