enum Path {
    App = '/',
    Auth = '/auth',
    InfoTrace = '/info-trace',
    InfoBasket = '/info-basket',
    History = '/history',
    UserProfile = '/user-profile',
    Notification = 'notification',
    UserRights = 'user-rights',
    Campaign = 'campaign',
    CampaignList = 'list',
    Charts = 'charts',
    SavedCharts = 'saved',
    Tags = 'tags',
    View = 'view',
    CampaignCreate = 'create',
    CampaignUpdate = 'update',
    Reports = 'reports',
    RestorePassword = '/restore_password',
    RequestAccess = '/request-access',
    ChartGraphDemo = '/ChartGraphDemo',
    CampaignComparison = 'campaign-comparison',
    CampaignComparisonsList = 'comparison-list',
    UserProfileFavorites = '/user-profile-favorites',
    SearchRequest = 'search-request',
    CampaignDashboard = 'campaign-dashboard',
    Authors = 'authors',
    Settings = 'settings',
    CampaignBasket = 'basket',
    Sources = 'sources',
    SourcesList = 'list',
    Projects = 'projects',
    ProjectList = 'project-list',
    ProjectCreate = 'project-create',
    SourcesRoll = 'roll',
    SourcesFavorite = 'favorite',
}

export default Path;
