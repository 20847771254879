import React, { useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import classnames from 'classnames';
import { IDropdownColumnFilterTableProps } from './types';
import { CampaignColumnFilterType } from '../../campaign/List/Table/types';
import DropdownColumnFilterTableMenuContainer from '../../../containers/campaign/DropdownColumnFilterTable';
import FilterCrossIcon from './imgs/filters-cross.svg';
import SortArrowIcon from './imgs/sort-arrow.svg';

import styles from './styles.module.scss';

const DropdownColumnFilterTable: React.FC<IDropdownColumnFilterTableProps> = ({
    filterType, invisible, name,
}): JSX.Element => {
    const [menuIsVisible, setVisibleMenu] = useState<boolean>();
    const [selectedAmount, setSelectedAmount] = useState<number>(0);
    const [needClear, onClear] = useState<boolean>(false);

    const crossIsVisible = useMemo((): boolean => (
        filterType === CampaignColumnFilterType.status
    ) && selectedAmount > 0, [filterType, selectedAmount]);

    if (invisible) {
        return null;
    }

    return (
        <div>
            <Dropdown
                overlay={(
                    <DropdownColumnFilterTableMenuContainer
                        filterType={filterType}
                        isVisible={invisible}
                        onClear={onClear}
                        needClear={needClear}
                        setVisibleMenu={setVisibleMenu}
                        onSelectItem={setSelectedAmount}
                    />
                )}
                trigger={['click']}
                className={styles.cursorPointer}
            >
                <div
                    className={classnames(
                        styles.dropdownName,
                        styles[menuIsVisible ? 'open' : ''],
                    )}
                >
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <img
                        className={classnames(styles.cross, styles[crossIsVisible ? '' : 'invisible'])}
                        src={FilterCrossIcon}
                        alt="FilterCross"
                        onClick={() => onClear(true)}
                    />
                    <div className={styles.textContainer}>{name ?? ''}</div>

                    <div className={styles.arrowContainer}>
                        <img
                            style={{ cursor: 'pointer' }}
                            className={classnames(styles.arrow, styles[menuIsVisible ? 'open' : ''])}
                            src={SortArrowIcon}
                            alt="SortArrowIcon"
                            width="12"
                            height="6"
                        />
                        <div className={classnames(styles.counterContainer, styles[selectedAmount > 0 ? '' : 'invisible'])}>
                            <div className={styles.counterContainer_counter}>{selectedAmount}</div>
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>
    );
};

export default DropdownColumnFilterTable;
