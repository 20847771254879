import { rtkQApi } from '../../index';
import { transformGetListsCountsResponse } from '../transformers/getListCounts';
import { transformGetListsResponse } from '../transformers/getLists';
import {
    IQPAddSourcesToLists, IQPCreateSourceList, ISourceListModel, ISourceListsCountsModel,
} from '../types';

export const sourcesListsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getLists: build.query<ISourceListModel[], void>({
            query: () => ({
                url: 'sources_api/lists/',
                method: 'GET',
            }),
            providesTags: ['SourceLists'],
            transformResponse: transformGetListsResponse,
        }),
        postCreateList: build.mutation<void, IQPCreateSourceList>({
            query: (body) => ({
                url: 'sources_api/lists/',
                method: 'POST',
                body: {
                    title: body.title,
                    description: body.description,
                    is_favorite: body.isFavorite,
                    source_id: body.sourceId,
                },
            }),
            invalidatesTags: ['SourceLists', 'SourceListsCounts'],
        }),
        postAddSourcesToLists: build.query<void, IQPAddSourcesToLists>({
            query: (body) => ({
                url: 'sources_api/source_list/',
                method: 'POST',
                body: {
                    list_ids: body.listIds,
                    source_ids: body.sourceIds,
                },
            }),
        }),
        getListsCounts: build.query<ISourceListsCountsModel, void>({
            query: () => ({
                url: 'sources_api/lists/counts',
                method: 'GET',
            }),
            providesTags: ['SourceListsCounts'],
            transformResponse: transformGetListsCountsResponse,
        }),
    }),
});
