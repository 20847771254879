import request from '../requestWrapper';

// export const getReportSummary = async (
//     campaignId: number,
//     fromDate: string,
//     toDate: string,
// ) => request({
//     method: 'POST',
//     url: `campaign/${campaignId}/report_agg`,
//     data: {
//         from_date: fromDate,
//         to_date: toDate,
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

export const saveReportWithFile = async (
    campaignId: number,
    data: FormData,
) => request({
    method: 'POST',
    url: `campaign/${campaignId}/report`,
    data,
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

export const getSavedReports = async (
    campaignId: number,
) => request({
    method: 'GET',
    url: `campaign/${campaignId}/report`,
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

export const deleteReportFromCampaign = async (
    campaignId,
    reportId,
) => request({
    method: 'DELETE',
    url: `campaign/${campaignId}/report/${reportId}`,
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

export const getReportFile = async (
    campaignId: number,
    reportId: number,
) => request({
    method: 'GET',
    url: `campaign/${campaignId}/report/${reportId}/download`,
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});
export const downloadReportFile = async (
    url: string,
) => request({
    method: 'GET',
    url,
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});
