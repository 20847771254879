import React, {
    useEffect,
    useMemo, useRef, useState,
} from 'react';
import { ICampaignComparisonList } from 'services/campaigns/comparison/types';
import { useNavigate } from 'react-router';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import Skeleton from 'react-loading-skeleton';
import Path from '../../../../shared/lib/paths';
import Search from '../../../../assets/images/Comparison/search.svg';
import CampaignComparisonsTableRow from '../../../../entities/Row';
import styles from './styles.module.scss';
import { campaignComparisonActions, ISimplePeriod } from '../../../../store/campaign/comparison';
import { useAppDispatch } from '../../../../store/hooks';
import { campaignStatisticsRtkQService } from '../../../../services/campaigns/statistics';

interface IProps {
    isLoading: boolean;
    data: ICampaignComparisonList[];
    onDelete: (id: number) => void;
}

const postfixISOTime = 'T00:00:00.000Z';

const CampaignComparisonsTable: React.FC<IProps> = ({
    isLoading,
    data,
    onDelete,
}): JSX.Element => {
    const divNameRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [searchValue, setSearchValue] = useState<string>('');

    const [loadCampaignSummary] = campaignStatisticsRtkQService.useLazyPostLoadCampaignAggQuery();
    const [loadCampaignFilters] = campaignStatisticsRtkQService.useLazyPostLoadCampaignFiltersQuery();

    const inputPlaceHolder = useMemo<string>(() => (
        isDisabled ? 'Наименование конкурентного анализа' : 'Поиск'
    ), [isDisabled]);
    const filteredData = useMemo(() => (
        !searchValue
            ? data
            : data.filter(({ name }) => name.toLowerCase().indexOf(searchValue) >= 0)
    ), [searchValue, data]);

    const handlerDeleteCampaignAnalysis = (id: number): void => {
        onDelete(id);
    };

    const handlerStartComparison = (selectedId: number, period: ISimplePeriod): void => {
        const comparisonData = data.find(({ id }) => id === selectedId);
        if (
            !comparisonData
            || comparisonData?.campaign?.id <= 0
            || comparisonData?.compared_campaigns?.length <= 0
        ) {
            return;
        }

        dispatch(campaignComparisonActions.setComparisonPeriod(period));
        dispatch(campaignComparisonActions.setCompareDisable());
        dispatch(campaignComparisonActions.setSavedAnalysisEnable());

        const campaignsIds = [comparisonData.campaign.id, ...comparisonData.compared_campaigns.map(({ id }) => id)];

        dispatch(campaignComparisonActions.setSelectPeriodState(false));
        const fromDate = `${period.start.toISOString().split('T')[0]}`;
        const toDate = `${period.end.toISOString().split('T')[0]}`;

        dispatch(campaignComparisonActions.setFiltersLoadingStatus());
        dispatch(campaignComparisonActions.cleanFiltersData());
        dispatch(campaignComparisonActions.setCampaignsIdsForFetchFilters(campaignsIds));
        campaignsIds.forEach((id) => loadCampaignFilters({ id, toDate, fromDate }));

        dispatch(campaignComparisonActions.setSummaryLoadingStatus());
        dispatch(campaignComparisonActions.cleanSummaryData());
        dispatch(campaignComparisonActions.setCampaignsIdsForFetchAgg(campaignsIds));
        campaignsIds.forEach((id) => loadCampaignSummary(
            { id, toDate: `${toDate}${postfixISOTime}`, fromDate: `${fromDate}${postfixISOTime}` },
        ));

        dispatch(campaignComparisonActions.setReferenceCampaign(
            { id: comparisonData.campaign.id, name: comparisonData.campaign.name ?? '-' } as ICampaignDataModel,
        ));
        comparisonData.compared_campaigns.forEach((campaign) => dispatch(campaignComparisonActions.addCampaignForCompare(
            { id: campaign.id, name: campaign.name ?? '-' } as ICampaignDataModel,
        )));

        navigate(`/${Path.Campaign}/${Path.CampaignComparison}`);
    };
    const handlerNavigateToCampaign = (id: number): void => {
        if (id > 0) {
            dispatch(campaignComparisonActions.clearAll());
            navigate(`/${Path.Campaign}/${Path.CampaignUpdate}/${id}`);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (divNameRef.current && !divNameRef.current.contains(event.target)) {
                if (!isDisabled) {
                    inputRef.current.disabled = true;
                    setIsDisabled(true);
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDisabled]);

    useEffect(() => {
        if (isDisabled) {
            inputRef.current.disabled = true;
        } else {
            inputRef.current.disabled = false;
            inputRef.current.focus();
        }
    }, [isDisabled]);

    return (
        <div className={styles.campaignAnalysisTable_root}>
            <div className={styles.header}>
                <div ref={divNameRef} className={styles.header_name}>
                    <input
                        ref={inputRef}
                        placeholder={inputPlaceHolder}
                        disabled
                        type="text"
                        value={searchValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
                        className={styles.header_input}
                    />
                    <button
                        type="button"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => setIsDisabled(!isDisabled)}
                    >
                        <img src={Search} alt="Search" className={styles.header_search} />
                    </button>
                </div>
                <span className={styles.header_period}>Период</span>
                <span className={styles.header_actions}>Действия</span>
            </div>
            {
                isLoading && (
                    <Skeleton width="100%" height={25} count={20} />
                )
            }
            {
                !isLoading && (
                    filteredData?.length > 0
                        ? (
                            <div className={styles.tableBody}>
                                {filteredData.map((item, index) => (
                                    <CampaignComparisonsTableRow
                                        key={item.id}
                                        analysisId={item.id}
                                        index={index + 1}
                                        comparisonData={item}
                                        onDeleteCampaignAnalysis={handlerDeleteCampaignAnalysis}
                                        onStartComparison={handlerStartComparison}
                                        onNavigateToCampaign={handlerNavigateToCampaign}
                                    />
                                ))}
                            </div>
                        )
                        : (<div className={styles.noDataElement}>Отсутствуют данные</div>)
                )
            }
        </div>
    );
};
export default CampaignComparisonsTable;
