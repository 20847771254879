import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHistoryLinksState } from './types';
import { FetchStatus } from '../../types';
import { convertApiToHistoryLinks } from './converter';
import { IHistoryLinks } from '../../../services/history/types';

const initialStateInfoBasketFilters: IHistoryLinks = {
    id: -1,
    analyzeTime: 0,
    content: '',
    dateEnd: 0,
    dateEndStr: '',
    categories: [],
    dateStart: 0,
    uid: '',
    source: '',
    locations: [],
    locationIds: [],
    categoryIds: [],
};

const initialState: IHistoryLinksState = {
    activeLink: '',
    activeText: '',
    needSearch: false,
    needChangeRoute: false,
    status: FetchStatus.undefined,
    data: [],
    needInfoBasketSearch: false,
    infoBasketFiltersForSearch: initialStateInfoBasketFilters,
};

const { actions: historyLinksActions, reducer } = createSlice({
    name: 'historyLinks',
    initialState,
    reducers: {
        clearAll: () => initialState,
        setLink: (state: IHistoryLinksState, { payload }: PayloadAction<string>): void => {
            state.needChangeRoute = true;
            state.needSearch = true;
            state.activeText = '';
            state.activeLink = payload;
        },
        setText: (state: IHistoryLinksState, { payload }: PayloadAction<string>): void => {
            state.needChangeRoute = true;
            state.needSearch = true;
            state.activeLink = '';
            state.activeText = payload;
        },
        startLoading: (state: IHistoryLinksState): void => {
            state.status = FetchStatus.loading;
        },
        failedLoading: (state: IHistoryLinksState): void => {
            state.status = FetchStatus.failed;
        },
        successLoading: (state: IHistoryLinksState, { payload }: PayloadAction<unknown>): void => {
            state.status = FetchStatus.success;
            state.data = convertApiToHistoryLinks(payload);
        },
        changeRouteComplete: (state: IHistoryLinksState): void => {
            state.needChangeRoute = false;
        },
        searchComplete: (state: IHistoryLinksState): void => {
            state.needSearch = false;
            state.needInfoBasketSearch = false;
        },
    },
});

export { historyLinksActions };
export default reducer;
