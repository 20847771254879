import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHistoryTableFiltersState } from 'store/history-table/types';
import { DashboardType, HistoryTablePeriodType } from '../../containers/history/HistoryTable/types';
import {
    ColumnFilterModelType,
    IDropdownFilterTableMenu,
} from '../../components/DropdownFilterTable/DropdownFilterTableMenu/types';

const initialPeriod: IDropdownFilterTableMenu = {
    type: ColumnFilterModelType.period,
    isFiltering: true,
    isSorting: false,
    isAscending: false,
    filterData: [
        {
            id: HistoryTablePeriodType.day, isSelected: false, name: '1 день', value: '1 день',
        },
        {
            id: HistoryTablePeriodType.week, isSelected: false, name: '7 дней', value: '7 дней',
        },
        {
            id: HistoryTablePeriodType.twoMonth, isSelected: false, name: '60 дней', value: '60 дней',
        },
        {
            id: HistoryTablePeriodType.threeMonth, isSelected: false, name: '90 дней', value: '90 дней',
        },
        {
            id: HistoryTablePeriodType.year, isSelected: false, name: '1 год', value: '1 год',
        },
    ],
};

const initialDashboard: IDropdownFilterTableMenu = {
    type: ColumnFilterModelType.dashboard,
    isFiltering: true,
    isSorting: false,
    isAscending: false,
    filterData: [
        {
            id: DashboardType.infoBasket, isSelected: false, name: 'Информационная корзина', value: 'Информационная корзина',
        },
        {
            id: DashboardType.infoTrace, isSelected: false, name: 'Информационный шлейф', value: 'Информационный шлейф',
        },
    ],
};

const initialState: IHistoryTableFiltersState = {
    date: {
        type: ColumnFilterModelType.date,
        isFiltering: false,
        isSorting: true,
        isAscending: false,
        filterData: [],
    },
    period: initialPeriod,
    bufferPeriod: initialPeriod,
    dashboard: initialDashboard,
    bufferDashboard: initialDashboard,
    filtersApplied: false,
};

const checkFiltersApplied = (state: IHistoryTableFiltersState): boolean => {
    if (state.period.filterData.some((item) => item.isSelected)) {
        return true;
    }
    return state.dashboard.filterData.some((item) => item.isSelected);
};

const { actions: historyTableFiltersActions, reducer } = createSlice({
    name: 'historyTable',
    initialState,
    reducers: {
        clearAll: () => ({ ...initialState }),
        sortDate: (state: IHistoryTableFiltersState, { payload }: PayloadAction<boolean>): void => {
            state.date.isAscending = payload;
        },
        clearPeriod: (state: IHistoryTableFiltersState): void => {
            for (let i = 0; i < state.period.filterData.length; i += 1) {
                state.period.filterData[i].isSelected = false;
            }
            for (let i = 0; i < state.bufferPeriod.filterData.length; i += 1) {
                state.bufferPeriod.filterData[i].isSelected = false;
            }
        },
        clearDashboard: (state: IHistoryTableFiltersState): void => {
            for (let i = 0; i < state.dashboard.filterData.length; i += 1) {
                state.dashboard.filterData[i].isSelected = false;
            }
            for (let i = 0; i < state.bufferDashboard.filterData.length; i += 1) {
                state.bufferDashboard.filterData[i].isSelected = false;
            }
        },
        updateBufferPeriod: (state: IHistoryTableFiltersState): void => {
            state.period.filterData.forEach((item) => {
                const cur = state.bufferPeriod.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
        },
        selectBufferPeriod: (state: IHistoryTableFiltersState, { payload }: PayloadAction<string>): void => {
            const period = state.bufferPeriod.filterData.find((item) => item.id === payload);
            if (period) {
                period.isSelected = !period.isSelected;
            }
        },
        setPeriodFromBuffer: (state: IHistoryTableFiltersState): void => {
            state.bufferPeriod.filterData.forEach((item) => {
                const cur = state.period.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
            state.filtersApplied = checkFiltersApplied(state);
        },
        updateBufferDashboard: (state: IHistoryTableFiltersState): void => {
            state.dashboard.filterData.forEach((item) => {
                const cur = state.bufferDashboard.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
        },
        selectBufferDashboard: (state: IHistoryTableFiltersState, { payload }: PayloadAction<string>): void => {
            const period = state.bufferDashboard.filterData.find((item) => item.id === payload);
            if (period) {
                period.isSelected = !period.isSelected;
            }
        },
        setDashboardFromBuffer: (state: IHistoryTableFiltersState): void => {
            state.bufferDashboard.filterData.forEach((item) => {
                const cur = state.dashboard.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
            state.filtersApplied = checkFiltersApplied(state);
        },
    },
});

export { historyTableFiltersActions };
export default reducer;
