/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, {
    useEffect, useMemo, useState,
} from 'react';
import { SvgIcon } from '@shared/index';
import moment from 'moment';
import {
    CalendarMatrix, ICalendar, IDay, ListNameDayInWeek, TypeStartDateList, Week,
} from './types';
import styles from './styles.module.scss';

const FORMAT_FULL = 'DD.MM.YYYY dddd';
const FORMAT_YEARS_AND_MONTH = 'MMMM YYYY';

const Calendar: React.FunctionComponent<ICalendar> = ({ onChange, value }): JSX.Element => {
    moment.locale('ru');
    const getMonth = (date: Date | moment.Moment) => moment(date);
    const [currentMonth, setCurrentMonth] = useState<moment.Moment>(() => getMonth(new Date()));
    const [calendarMatrix, setCalendarMatrix] = useState<CalendarMatrix>([]);
    const [initDate, setInitDate] = useState<moment.Moment | null>(() => {
        if (!value) return null;
        return value;
    });
    const getFormatDay = (day: moment.Moment, format?: string):string => day.format(format || FORMAT_FULL);
    const getStartMonth = (day: moment.Moment) => moment(day).startOf('month');
    const getEndMonth = (day: moment.Moment) => moment(day).endOf('month');
    const getStartWeek = (day: moment.Moment) => moment(day).startOf('week');
    const getEndWeek = (day: moment.Moment) => moment(day).endOf('week');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getIsPassive = (day: IDay) => false; // day.date.isAfter(moment(new Date()));

    const getIsHide = (day: IDay, isCurrent: boolean) => {
        if (isCurrent) {
            const startDateFromCurrentDay = getStartMonth(day.date);
            const startDayInCurrentMonth = getStartMonth(currentMonth);
            return startDateFromCurrentDay.isBefore(startDayInCurrentMonth);
        }
        return false;
    };

    const getListDayFromPeriod = (initialStartDay: moment.Moment, initialEndDay: moment.Moment) => {
        const startDay = initialStartDay.clone();
        const endDay = initialEndDay.clone();
        const day = startDay.clone();
        const result:CalendarMatrix = [];
        while (day.isBefore(endDay)) {
            const cloneDay = day.clone();
            const currentDay:IDay = {
                date: cloneDay,
                formatDate: getFormatDay(day),
                isChecked: false,
                weekDayCount: cloneDay.weekday(),
            };
            if (!result.length) {
                result.push([currentDay]);
            } else {
                const lastWeek = result?.length ? result[result.length - 1] : null;
                const lastDay = lastWeek?.length ? lastWeek[lastWeek.length - 1] : null;
                if (lastDay && lastDay.weekDayCount === 6) {
                    result.push([currentDay]);
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    lastWeek && lastWeek.push(currentDay);
                }
            }
            day.add(1, 'd');
        }
        return result;
    };

    const getDateList = (day: moment.Moment, typeStart?: TypeStartDateList) => {
        const startMonthDay = getStartMonth(day);
        const endMonthDay = getEndMonth(day);
        if (typeStart === 'week') {
            const startInitialWeek = getStartWeek(startMonthDay);
            const endLastWeek = getEndWeek(endMonthDay);
            return getListDayFromPeriod(startInitialWeek, endLastWeek);
        }
        return getListDayFromPeriod(startMonthDay, endMonthDay);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectPeriodState = (day: IDay, isCurrent: boolean) => {
        const { date } = day;
        // if (!(date.isAfter(moment(new Date())) || getIsHide(day, isCurrent))) {
        //     setInitDate(date);
        //     onChange(date);
        // }
        setInitDate(date);
        onChange(date);
    };

    const getDayClasses = (day: IDay, isCurrent: boolean) => {
        const { date } = day;
        const hide = getIsHide(day, isCurrent)
            ? styles.hide : '';
        const passive = getIsPassive(day) ? styles.passive : '';
        const isInitDay = date.isSame(initDate);
        const initDay = isInitDay ? styles.selectDay : '';
        return `${styles.labelDay} ${passive} ${hide} ${initDay}`;
    };

    const getWeek = (week: Week, isCurrent: boolean) => week.map((day) => {
        if (day) {
            return (
                <div
                    className={getDayClasses(day, isCurrent)}
                    key={`${day.formatDate}_${day.weekDayCount}`}
                    onClick={() => selectPeriodState(day, isCurrent)}
                    role="button"
                >
                    {day.date.format('D')}
                </div>
            );
        }
    });

    const getCurrentMonthCalendar = useMemo(() => (
        calendarMatrix.map((week, index) => (
            <div className={styles.weekContainer} key={`week_${index}`}>
                {getWeek(week, true)}
            </div>
        ))
    ), [calendarMatrix, initDate]);

    const handlerPrevMonth = () => {
        const prevMonth = currentMonth.clone();
        prevMonth.subtract(1, 'month');
        setCurrentMonth(prevMonth);
    };

    const handlerNextMonth = () => {
        const nextMonth = currentMonth.clone();
        nextMonth.add(1, 'month');
        setCurrentMonth(nextMonth);
    };

    useEffect(() => {
        const currentDateList = getDateList(currentMonth, 'week');
        setCalendarMatrix(currentDateList);
    }, [currentMonth]);

    return (
        <div className={styles.containerCalendar}>
            <div className={styles.bodyCalendar}>
                <div className={styles.containerMonth}>
                    <div className={styles.monthTitle}>
                        <div
                            className={`${styles.icn} ${styles.prev}`}
                            onClick={handlerPrevMonth}
                            role="button"
                        >
                            <SvgIcon id="arrow-horizontal" size={18} />
                        </div>
                        {currentMonth.format(FORMAT_YEARS_AND_MONTH)}
                        <div
                            className={`${styles.icn} ${styles.next}`}
                            onClick={handlerNextMonth}
                            role="button"
                        >
                            <SvgIcon id="arrow-horizontal" size={18} />
                        </div>
                    </div>
                    <div className={styles.weekContainer}>
                        {
                            ListNameDayInWeek.map((day) => (
                                <div className={`${styles.labelDay} ${styles.name}`} key={day}>
                                    {day}
                                </div>
                            ))
                        }
                    </div>
                    { getCurrentMonthCalendar }
                </div>
            </div>
        </div>
    );
};

export default React.memo(Calendar);
