import { ISource, ISourceRow } from '@services/sources/types';

export const transformSourcesForLongListResponse = (baseQueryResponse: any): ISource => {
    const result: ISource = { total: 0, sources: [] };
    result.total = baseQueryResponse?.total;
    result.sources = baseQueryResponse?.sources?.map(
        (item: any) => ({
            subscriberGrowth: {
                day: (item?.subscriber_growth ?? []).find((el) => el.name === 'сегодня')?.value ?? 0,
                month: (item?.subscriber_growth ?? []).find((el) => el.name === 'месяц')?.value ?? 0,
                week: (item?.subscriber_growth ?? []).find((el) => el.name === 'неделя')?.value ?? 0,
            },
            author: {
                id: item?.author_id,
                title: item?.author_title,
            },
            sourceType: {
                id: item?.type_id,
                title: item?.type_title,
            },
            id: item?.id,
            sourceId: item?.source_id,
            subscriberGrowthPercent: {
                day: item?.subscriber_growth_percent?.day ?? 0,
                month: item?.subscriber_growth_percent?.month ?? 0,
                week: item?.subscriber_growth_percent?.week ?? 0,
            },
            title: item?.title,
            subscribers: item?.subscribers,
            categories: item?.category_ids?.length > 0 ? item?.category_ids.map((elem, id) => ({
                id: elem,
                title: item?.category_titles[id],
            })) : [],
            er: item?.er_avg,
            link: item?.link,
            price: item?.price,
            startDate: !item?.start_date ? null : new Date(item?.start_date),
            endDate: !item?.end_date ? null : new Date(item?.end_date),
            content: item?.content,
            integrationLink: item?.integration_link,
            logoUrl: item?.logo_url,
            planViews: item?.plan_views,
            planEr: item?.plan_er,
            planCpv: item?.plan_cpv,
            viewsAvg: item?.views_avg,
            is_deleted: item?.is_deleted,
            comment: item?.comment,
        } as ISourceRow),
    ) ?? [];
    return result;
};
