import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

const getReduction = (number: number) => {
    if (number > 999 && number < 1000000) return { abbreviation: 'К', reduction: (number / 1000).toFixed(0) };
    if (number > 999999) return { abbreviation: 'М', reduction: (number / 1000000).toFixed(0) };
    return { abbreviation: '', reduction: number };
};
const scales = {
    x: {
        offsetAfterAutoskip: true,
        border: {
            display: false,
        },
        grid: {
            color: 'rgba(0,0,0,0)',
        },
        ticks: {
            padding: 10,
            font: {
                size: 14,
            },
            // autoSkip: true,
            maxTicksLimit: 11,
            maxRotation: 10,
            minRotation: 0,
        },
        type: 'time',
        time: {
            displayFormats: {
                hour: 'hh:mm',
                day: 'DD.MM',
                month: 'MMMM',
            },
        },
    },
    // x2: {
    //     offsetAfterAutoskip: true,
    //     grid: {
    //         color: 'rgba(0,0,0,0)',
    //     },
    //     ticks: {
    //         padding: 10,
    //         maxTicksLimit: 11,
    //         maxRotation: 10,
    //         minRotation: 0,
    //     },
    //     type: 'time',
    //     time: {
    //         displayFormats: {
    //             hour: 'hh:mm',
    //             day: 'DD.MM',
    //             month: 'MMMM',
    //         },
    //     },
    // },
    y: {
        grid: {
            color: 'rgba(0,0,0,0)',
        },
        min: 0,
        ticks: {
            padding: 10,
            maxTicksLimit: 7,
            callback(value) {
                if (Math.floor(value) === value) {
                    const abb = getReduction(value);
                    return `${abb.reduction} ${abb.abbreviation}`;
                }

                return '';
            },
        },
    },
};
const plugins = {
    zoom: {
        pan: {
            enabled: true,
            mode: 'x',
            threshold: 5,
            scaleMode: 'x',
            modifierKey: 'alt',
            // onPanComplete,
        },
        zoom: {
            mode: 'x',
            wheel: {
                speed: 0.05,
                enabled: false,
            },
            pinch: {
                enabled: true,
            },
            drag: {
                enabled: true,
            },
        // onZoomComplete,
        },
    },
    legend: {
        display: false,
    },
    title: {
        display: false,
    },
    tooltip: {
        bodyFont: {
            size: '14px',
            weight: 500,
        },
        footerFont: {
            size: '14px',
            weight: 400,
        },
        titleFont: {
            size: '14px',
            weight: 400,
        },
        callbacks: {
            label(data) {
                return data?.dataset?.label === 'default' ? '' : data?.dataset?.label;
            },
            title(data) {
                return moment(data[0]?.parsed?.x).format('DD.MM.YYYY  hh:mm');
            },
            footer(data) {
                // const content = data[0]?.raw?.content;
                const value = data[0]?.parsed?.y.toLocaleString('ru');
                // if (content) {
                //     const list = content?.descriptions.map((elem, index) => `${t(elem)}: ${t(content?.values[index])}`);
                //     return [...list, `значение: ${value}`];
                // }
                return `Значение: ${value}`;
            },
        },
        displayColors: false,
        backgroundColor: 'white',
        borderColor(data) {
            return data?.tooltipItems[0]?.dataset?.allColors?.color || '#dfdfdf';
        },
        // borderColor: '#dfdfdf',
        bodyColor: '#787E93',
        footerColor: '#787E93',
        titleColor: '#787E93',
        borderWidth: 1,
        borderRadius: 6,
    },
};
export const OPTIONS = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false,
    stacked: false,
    beginAtZero: false,
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: true, // old listDatasets.length > 1,
    },
    elements: {
        line: {
            tension: 0.37,
        },
        point: {
            borderColor: 'white',
        },
    },
    scales,
    plugins,
    // events: ['click'],
};
