import React from 'react';

import styles from '../styles.module.scss';

interface IProps {
    size: number,
    text: string,
    left: number,
}
const TableHeaderCompositeResults = (
    props: IProps,
): React.ReactElement => {
    const {
        size, text, left,
    } = props;

    return (
        <div className={styles.compositeHeaderCell} style={{ width: size, left }}>
            <div className={styles.compositeHeaderCellTitle}>
                {text}
            </div>
        </div>
    );
};

export default TableHeaderCompositeResults;
