/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';

interface IPropsLastItem {
    onCleanFilters: () => void
    onRepeat: () => void
}

const ErrorPage: React.FC<IPropsLastItem> = ({
    onCleanFilters,
    onRepeat,
}): JSX.Element => (
    <div className={styles.empty__root}>
        <div className={styles.empty__content}>
            По заданным фильтрам произошла непредвиденная ошибка попробуйте сбросить
            {' '}
            <span
                className={styles.empty__link}
                onClick={onCleanFilters}
            >
                фильтры кампании
            </span>
            {' '}
            или
            {' '}
            <span
                className={styles.empty__link}
                onClick={onRepeat}
            >
                повторить попытку
            </span>
        </div>
    </div>

);
export default React.memo(ErrorPage);
