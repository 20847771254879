/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import { IChart } from 'containers/CampaignNewCharts/ChartTypes';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { ISimpleEntity } from 'store/types';
import { TimeFrame, ChartMetricsModel } from '../../services/campaigns/Charts/models';
import ColumnRadioButtons from '../ColumnRadioButtons';
import styles from './styles.module.scss';

const axis = [
    {
        name: 'Y',
        value: 'Y',
        id: 1,
    },
    {
        name: 'X',
        value: 'X',
        id: 12,
    },
];

interface IPropsChartMetrics {
    onUpdateTimeFrame: (newTimeFrame: TimeFrame) => void,
    listSettingForChart: ChartMetricsModel,
    isLoading: boolean,
    chartSetting: IChart,
}

const ChartTimeFrames: React.FC<IPropsChartMetrics> = ({
    onUpdateTimeFrame,
    listSettingForChart,
    isLoading,
    chartSetting,
}): JSX.Element => {
    if (isLoading) return <div> Loading</div>;
    if (!listSettingForChart || !listSettingForChart?.length) return <div>Данных нет</div>;

    const timeFrames = useMemo((): TimeFrame[] => {
        const listRawMetrics = listSettingForChart.find((item) => item?.time_frame);
        return listRawMetrics.time_frame.filter((time_frame) => time_frame !== 'MONTH');
    }, [listSettingForChart]);

    const simpleEntityTimeFrames = useMemo((): ISimpleEntity[] => timeFrames.map((name, index) => ({
        id: index + 1,
        name: t(name),
        value: name,
    })), [timeFrames]);

    const selectedTimeFrame = useMemo(
        (): ISimpleEntity => simpleEntityTimeFrames?.find((simpleEntity: ISimpleEntity) => simpleEntity.value === chartSetting?.time_frame),
        [simpleEntityTimeFrames, chartSetting],
    );

    const handleSelectTimeFrame = (id: number): void => {
        const newChartType = simpleEntityTimeFrames.find((simpleEntity: ISimpleEntity) => simpleEntity.id === id);
        onUpdateTimeFrame(newChartType.value as TimeFrame);
    };
    return (
        <div className={styles.radioButtons}>
            <div style={{ marginLeft: 4 }}>
                <div className={`${styles.dropdownTitile} ${styles.smallWidtTitle}`}>
                    Временной промежуток
                </div>
                <ColumnRadioButtons
                    listButtons={simpleEntityTimeFrames}
                    activeRadioButtonId={selectedTimeFrame?.id || 0}
                    select={handleSelectTimeFrame}
                />
            </div>
            <div style={{ opacity: 0 }}>
                <div className={`${styles.dropdownTitile} ${styles.smallWidtTitle}`}>
                    Ведущая ось
                </div>
                <ColumnRadioButtons
                    listButtons={axis}
                    activeRadioButtonId={1}
                    disabled
                />
            </div>
        </div>
    );
};

export default React.memo(ChartTimeFrames);
