import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import MapRussia from '../../../../MapRussia';
import { chartsCampaingRtqService } from '../../../../../services/campaigns/Charts';
import { CreateDataset } from '../../../../../utils/Charts/CreatePointsFromCommons';

interface IPropsMapChart {
    campaignId: number,
}

const CampaignDnDMapChart: React.FC<IPropsMapChart> = ({ campaignId }): JSX.Element => {
    const [getChartData, { data, isLoading, isError }] = chartsCampaingRtqService.useNewGetChartDataMutation();
    const [chartSettings, setChartSettings] = useState(null);
    useEffect(() => {
        if (!data?.data) return;
        const dataset = new CreateDataset(data.data);
        setChartSettings(dataset.create('MAP_CHART'));
    }, [data]);
    useEffect(() => {
        getChartData({
            name: 'Dashboard map',
            time_frame: 'DAY',
            chart_type: 'MAP_CHART',
            campaign_id: campaignId,
            metrics: [
                'POSTS',
            ],
            dimensions: [
                'LOCATION',
            ],
            filters_include: [],
        } as any);
    }, [campaignId]);
    const colors = {
        dfo: '#EDFFFC',
        sevkav: '#EDFFFC',
        ufo: '#EDFFFC',
        center: '#EDFFFC',
        privol: '#EDFFFC',
        ural: '#EDFFFC',
        sibir: '#EDFFFC',
        sevzap: '#EDFFFC',
    };
    if (!chartSettings?.data || isLoading) {
        return (
            <div style={{
                width: 812, height: 486, background: 'white', borderRadius: 6, padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                <Skeleton height={486 - 32} width={812 - 32} />
            </div>
        );
    }
    if (isError) return null;

    if (isError) {
        return (
            <div style={{
                width: 812, height: 486, background: 'white', borderRadius: 6, padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                Произошла непредвиденная ошибка
            </div>
        );
    }
    return (
        <div style={{
            width: 812, height: 486, background: 'white', borderRadius: 6, padding: 16, display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
            { chartSettings && <MapRussia defaulStroke="rgba(14, 191, 161, 0.4)" data={chartSettings.data} sizeMap={670} colorsRegion={colors} checker="districtName" />}
        </div>
    );
};

export default CampaignDnDMapChart;
