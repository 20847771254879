import { IApplyFilter } from 'sections/MyCampaignContainer/CampaingTypes';
import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import CampaingTrackingQuickInfo from '../../components/CampaingTrackingQuickInfo';
import { DateRangePickerValue } from '../../components/DateRangePicker/types';
import { IQueryParamsReportAggs } from '../../services/campaigns/tracking/types';
import useGetBackgroundInfo from '../../shared/hooks/campaign/useGetBackgroundInfo';

interface IPropsCampaignFooterFilters {
    campaignId: number,
    period: DateRangePickerValue,
    filters: IApplyFilter[],
}

const NEW_FORMAT_DATA = 'YYYY-MM-DDTHH:mm';

const BackgroundInformation: React.FC<IPropsCampaignFooterFilters> = ({
    campaignId,
    period,
    filters,
// eslint-disable-next-line arrow-body-style
}): JSX.Element => {
    const getQuery = useMemo((): IQueryParamsReportAggs => {
        const query: IQueryParamsReportAggs = {
            campaign_id: campaignId,
            filters_include: filters,
        };
        if (Array.isArray(period) && period?.length) {
            query.from_date = period[0].format(NEW_FORMAT_DATA);
            query.to_date = period[1].format(NEW_FORMAT_DATA);
        }
        return query;
    }, [period, filters, campaignId]);
    const { now, old } = useGetBackgroundInfo(getQuery);
    const { data: nowData, isFetching: isLoadingNow } = now;
    const { data: oldData, isFetching: isLoadingOld } = old;
    if (isLoadingNow || isLoadingOld) {
        return (
            <div style={{ marginBottom: 16 }}>
                <Skeleton height={85} width="100%" />
            </div>
        );
    }
    return (
        <div>
            <CampaingTrackingQuickInfo
                countAuthors={nowData?.count_authors}
                countComments={nowData?.count_comments}
                countLikes={nowData?.count_likes}
                countView={nowData?.count_views}
                countReactions={nowData?.count_reactions}
                bloggerCountPosts={nowData?.count_blogger_post}
                smiCountPosts={nowData?.count_smi_post}
                isIPadSize={false}
                oldData={oldData}
            />
        </div>
    );
};
export default BackgroundInformation;
