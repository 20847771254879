/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react';
import { IQueryParamsCampaingFilters, ListPeriods } from '../../../../services/campaigns/tracking/types';
import { DateRangePickerValue } from '../../../../components/DateRangePicker/types';
import { trackingCampaingRtqService } from '../../../../services/campaigns/tracking';
import {
    IApplyFilter,
    TApplyFilterOrMany,
    ListCategory,
    IFilter,
    ICategory,
    IListFilters,
} from '../../../../sections/MyCampaignContainer/CampaingTypes';
import { TChangedFilter } from './types';
import { getListFilters } from './helpres';

const OLD_PERIOD_FORMAT = 'YYYY-MM-DD';
type TFuncIntegrate = (include: IApplyFilter[], exclude: IApplyFilter[]) => void;
interface IHandlersUseCatqgories {
    updateListFilters: (...args: any) => void
    repeat: (...args: any) => void
    sendRequest: (...args: any) => void
    init: (...args: any) => void,
    integrate: TFuncIntegrate
}
type TUseCategories = [
    ListCategory,
    IListFilters,
    (changeble: TApplyFilterOrMany, type: TApplyFilterOrMany, upd?: boolean) => void,
    IHandlersUseCatqgories,
    boolean,
    boolean,
    boolean,
    IFilter[],
];

const initListFilters = () => ({
    filters_include: [],
    filters_exclude: [],
});

const useCategories = (campaignId:number, period:DateRangePickerValue): TUseCategories => {
    const exceptions = ['Пользовательские фильтры', 'Пользовательские теги'];
    const [categories, setCategories] = useState<ListCategory>();
    const filtredCategories = useMemo(() => categories?.filter(({ filters_category }) => !exceptions.some((e) => e === filters_category)), [categories]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getActiveuserTags = useMemo(():IFilter[] => {
        if (!categories) return [];
        const userTags = categories.find((category) => category.filters_category === exceptions[1]);
        return userTags?.filters || [];
    }, [categories]);
    const [after, setAfter] = useState(false);
    const [filtersApply, setFiltersApply] = useState<IListFilters>(initListFilters());
    const [prevQuery, setPrevQuery] = useState<IQueryParamsCampaingFilters>();
    const getDatePeriod = (p: DateRangePickerValue): ListPeriods => {
        if (!p || !p[0] || !p[1]) return undefined;
        return [{
            from_date: p[0].format(OLD_PERIOD_FORMAT),
            to_date: p[1].format(OLD_PERIOD_FORMAT),
        }];
    };
    const datePeriods: ListPeriods = useMemo(() => getDatePeriod(period), [period]);
    const [trigger, request] = trackingCampaingRtqService.useLazyGetFiltersQuery();
    const {
        data, isFetching, isLoading, isError,
    } = request;
    useEffect(() => {
        setCategories(data);
    }, [data]);
    const changeFiltersCategory = (prev: IFilter[], name: string, type: TChangedFilter): IFilter[] => {
        const result = prev.map((filter) => {
            if (filter.filter_name === name) {
                const newFilter: IFilter = { ...filter, [type]: !filter[type] };
                return newFilter;
            }
            return filter;
        });
        return result;
    };
    const updateCategory = (prev: ListCategory, { filters_category, filter_name }: IApplyFilter, type: TChangedFilter) => {
        const result: ListCategory = prev.map((item) => {
            if (item.filters_category !== filters_category) return item;
            const updCategory: ICategory = {
                ...item,
                filters: changeFiltersCategory(item.filters, filter_name, type),
            };
            return updCategory;
        });
        return result;
    };
    const updateCategories = (prev: ListCategory, changeble: IApplyFilter[], type: TChangedFilter) => {
        const result = changeble.reduce((newCategories: ListCategory, filter: IApplyFilter) => {
            const needCategory = newCategories.find((category) => category.filters_category === filter.filters_category);
            if (!needCategory) return newCategories;
            const updCategories = updateCategory(newCategories, filter, type);
            return updCategories;
        }, [...prev]);
        return result;
    };
    const getQuery = (
        filters_exclude?: IApplyFilter[],
        filters_include?:IApplyFilter[],
        date_periods?: ListPeriods,
    ): IQueryParamsCampaingFilters => {
        const currentFilters = getListFilters(categories);
        return {
            campaign_id: campaignId,
            filters_exclude: filters_exclude || currentFilters.filters_exclude,
            filters_include: filters_include || currentFilters.filters_include,
            date_periods: date_periods || datePeriods,
        };
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const update = (changeble?: TApplyFilterOrMany, changebleExclude?: TApplyFilterOrMany, upd?: boolean) => {
        if (changeble) {
            if (Array.isArray(changeble)) setCategories((prev) => updateCategories(prev, changeble, 'include'));
            else setCategories((prev) => updateCategory(prev, changeble, 'include'));
        }
        if (changebleExclude) {
            if (Array.isArray(changebleExclude)) setCategories((prev) => updateCategories(prev, changebleExclude, 'exclude'));
            else setCategories((prev) => updateCategory(prev, changebleExclude, 'exclude'));
        }
    };
    const integrate: TFuncIntegrate = (includes, excludes) => {
        const query = getQuery(excludes, includes);
        setFiltersApply({
            filters_exclude: excludes,
            filters_include: includes,
        });
        trigger(query).then(() => {
            setPrevQuery(query);
        });
    };
    const updateListFilters = () => {
        const old = JSON.parse(JSON.stringify({
            filters_include: filtersApply.filters_include,
            filters_exclude: filtersApply.filters_exclude,
        }));
        setFiltersApply(old);
    };
    const init = () => {
        setFiltersApply(initListFilters());
        trigger({
            campaign_id: campaignId,
        });
    };
    const shuffle = (filters) => {
        const clone = [...filters];
        // eslint-disable-next-line no-plusplus
        for (let i = clone.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [clone[i], clone[j]] = [clone[j], clone[i]];
        }
        return clone;
    };
    const repeat = () => {
        setFiltersApply({
            filters_include: shuffle(prevQuery.filters_include) || [],
            filters_exclude: shuffle(prevQuery.filters_exclude) || [],
        });
        trigger(prevQuery);
    };
    const sendRequest = () => {
        const query = getQuery();
        setFiltersApply({
            filters_include: query.filters_include || [],
            filters_exclude: query.filters_exclude || [],
        });
        trigger(query).then(() => {
            setPrevQuery(query);
        })
            .finally(() => {
                if (after) { setAfter(false); }
            });
    };
    useEffect(() => {
        if (after && categories) {
            sendRequest();
        }
    }, [after, categories]);
    const handlers = {
        updateListFilters,
        repeat,
        sendRequest,
        init,
        integrate,
    };
    useEffect(() => {
        init();
    }, [campaignId]);
    return [filtredCategories,
        filtersApply,
        update,
        handlers,
        isFetching,
        isLoading,
        isError,
        getActiveuserTags,
    ];
};

export default useCategories;
