/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment from 'moment';
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import SvgIcon from '../SvgIcon';
import styles from './styles.module.scss';
import useOnClickOutside from '../../shared/hooks/useOnClickOutside';

const ListNameDayInWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
type TypeStartDateList = 'week' | 'month';
interface IDay {
    date: moment.Moment,
    formatDate: string,
    isChecked: boolean,
    weekDayCount: number
}
interface IPorpDatePicker {
    onChange: (date: moment.Moment) => void;
    // eslint-disable-next-line react/no-unused-prop-types
    value?: moment.Moment
}
type Week = (IDay | null)[];
type CalendarMatrix = Week[];
// const DatePicker: React.FunctionComponent<> = (): JSX.Element => {
const DatePickerSmall: React.FunctionComponent<IPorpDatePicker> = ({ onChange, value }): JSX.Element => {
    const getMonth = (date: Date | moment.Moment) => moment(date);
    moment.locale('ru');
    const [currentMonth, setCurrentMonth] = useState<moment.Moment>(() => getMonth(new Date()));
    const [calendar, setCalendar] = useState<CalendarMatrix>([]);
    const [initDate, setInitDate] = useState<moment.Moment | null>(() => {
        if (!value) return null;
        return value;
    });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const getFormatDay = (day, format?: string):string => day.format(format || 'DD.MM.YYYY dddd');
    const getStartMonth = (day) => moment(day).startOf('month');
    const getEndMonth = (day) => moment(day).endOf('month');
    const getStartWeek = (day) => moment(day).startOf('week');
    const getEndWeek = (day) => moment(day).endOf('week');
    const refModal = useRef();
    const handlerOnClickOutside = (): void => {
        if (isOpen) {
            setIsOpen(false);
        }
    };
    useOnClickOutside(refModal, handlerOnClickOutside);
    const getListDayFromPeriod = (initialStartDay: moment.Moment, initialEndDay: moment.Moment) => {
        const startDay = initialStartDay.clone();
        const endDay = initialEndDay.clone();
        const day = startDay.clone();
        const result:CalendarMatrix = [];
        while (day.isBefore(endDay)) {
            // todo
            const cloneDay = day.clone();
            const currentDay:IDay = {
                date: cloneDay,
                formatDate: getFormatDay(day),
                isChecked: false,
                weekDayCount: cloneDay.weekday(),
            };
            if (!result.length) {
                result.push([currentDay]);
            } else {
                const lastWeek = result?.length ? result[result.length - 1] : null;
                const lastDay = lastWeek?.length ? lastWeek[lastWeek.length - 1] : null;
                if (lastDay.weekDayCount === 6) {
                    result.push([currentDay]);
                } else {
                    lastWeek.push(currentDay);
                }
            }
            day.add(1, 'd');
        }
        return result;
    };
    const getDateList = (day, typeStart?: TypeStartDateList) => {
        const startMonthDay = getStartMonth(day);
        const endMonthDay = getEndMonth(day);
        if (typeStart === 'week') {
            const startInitialWeek = getStartWeek(startMonthDay);
            const endLastWeek = getEndWeek(endMonthDay);
            return getListDayFromPeriod(startInitialWeek, endLastWeek);
        }
        return getListDayFromPeriod(startMonthDay, endMonthDay);
    };
    const getIsHide = (day: IDay, isCurrent: boolean) => {
        if (isCurrent) {
            const startDateFromCurrentDay = getStartMonth(day.date);
            const startDayInCurrentMonth = getStartMonth(currentMonth);
            return startDateFromCurrentDay.isBefore(startDayInCurrentMonth);
        }
        // todo
        return false;
    };
    const getIsPassive = (day: IDay) => day.date.isAfter(moment(new Date()));
    const getDayClasses = (day: IDay, isCurrent: boolean) => {
        const { date } = day;
        const hide = getIsHide(day, isCurrent)
            ? styles.hide : '';
        const passive = getIsPassive(day) ? styles.passive : '';
        const isInitDay = date.isSame(initDate);
        const initDay = isInitDay ? styles.selectDay : '';
        return `${styles.labelDay} ${passive} ${hide} ${initDay}`;
    };
    const selectPeriodState = (day: IDay, isCurrent): any => {
        // eslint-disable-next-line no-useless-return
        const { date } = day;
        if (date.isAfter(moment(new Date()))) return;
        if (getIsHide(day, isCurrent)) return;
        setInitDate(date);
        onChange(date);
    };
    // eslint-disable-next-line arrow-body-style
    const getWeek = (week, isCurrent) => {
        return week.map((day) => (
            <div
                className={getDayClasses(day, isCurrent)}
                key={`${day.formatDate}_${day.weekDayCount}`}
                onClick={() => selectPeriodState(day, isCurrent)}
            >
                {day.date.format('D')}
            </div>
        ));
    };
    const getCurrentMonthCalendar = useMemo(() => (
        calendar.map((week, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.weekContainer} key={`week_${index}`}>
                {getWeek(week, true)}
            </div>
        ))
    ), [calendar, initDate]);
    const handlerPrevMonth = () => {
        const prevMonth = currentMonth.clone();
        prevMonth.subtract(1, 'month');
        setCurrentMonth(prevMonth);
    };
    const handlerNextMonth = () => {
        const nextMonth = currentMonth.clone();
        nextMonth.add(1, 'month');
        setCurrentMonth(nextMonth);
    };
    useEffect(() => {
        // currentMonth
        // prevMonth
        const currentDateList = getDateList(currentMonth, 'week');
        setCalendar(currentDateList);
    }, [currentMonth]);
    const getLabelInitDate = useMemo(() => {
        if (!initDate) return 'дд.мм.гггг';
        return initDate.format('DD.MM.YYYY');
    }, [initDate]);
    return (
        <div className={styles.conatinerDatePicker}>
            <div className={styles.currentPeriod} onClick={() => setIsOpen(true)}>
                <div style={{ marginBottom: '-12px', marginRight: '8px' }}>
                    <SvgIcon id="Calendar" />
                </div>
                <span id="track-filter-date-start">{getLabelInitDate}</span>
            </div>
            { isOpen
                ? (
                    <div ref={refModal}>
                        <div className={styles.conatinerCalendar}>
                            <div className={styles.bodyCalendar}>
                                <div className={styles.containerMonth}>
                                    <div className={styles.monthTitle}>
                                        <div className={`${styles.icn} ${styles.prev}`} onClick={handlerPrevMonth}>
                                            <SvgIcon id="ArrowLeft" />
                                        </div>
                                        {currentMonth.format('MMMM YYYY')}
                                        <div className={`${styles.icn} ${styles.next}`} onClick={handlerNextMonth}>
                                            <SvgIcon id="ArrowLeft" />
                                        </div>
                                    </div>
                                    <div className={styles.weekContainer}>
                                        {
                                            ListNameDayInWeek.map((day) => (
                                                <div className={`${styles.labelDay} ${styles.name}`} key={day}>
                                                    {day}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    { getCurrentMonthCalendar }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''}
        </div>
    );
};

export default DatePickerSmall;
