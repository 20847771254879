import React from 'react';
import { Button } from '@shared/index';
// import CampaignTrackedWordsBlock from '@components/campaign/UpdateForm/TrackedWords/index';

// interface ITrackedWordModel {
//     id: number;
//     name: string;
// }
const ButtonDemo: React.FC = (): JSX.Element => {
    const handlerOnClick = (type: string): void => {
        // eslint-disable-next-line no-console
        console.log('handlerOnClick', type);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <div style={{ display: 'flex', gap: 16 }}>
                <Button text="Сохранить" type="default" size="large" onClick={() => handlerOnClick('default large')} />
                <Button
                    text="Проверка title кнопки очень длинное название очень длинное название"
                    type="default"
                    size="large"
                    onClick={() => null}
                />
                <Button text="Сохранить" type="default" size="medium" onClick={() => handlerOnClick('default medium')} />
                <Button text="Сохранить" type="default" size="small" onClick={() => handlerOnClick('default small')} />
            </div>
            <div style={{ display: 'flex', gap: 16 }}>
                <Button text="Сохранить" type="secondary" size="large" onClick={() => handlerOnClick('secondary large')} />
                <Button text="Сохранить" type="secondary" size="medium" onClick={() => handlerOnClick('secondary medium')} />
                <Button text="Сохранить" type="secondary" size="small" onClick={() => handlerOnClick('secondary small')} />
            </div>
            <div style={{ display: 'flex', gap: 16 }}>
                <Button text="Сохранить" type="danger" size="large" onClick={() => handlerOnClick('danger large')} />
                <Button text="Сохранить" type="danger" size="medium" onClick={() => handlerOnClick('danger medium')} />
                <Button text="Сохранить" type="danger" size="small" onClick={() => handlerOnClick('danger small')} />
            </div>
            <div style={{ display: 'flex', gap: 16 }}>
                <Button text="Сохранить" type="danger" size="large" disabled onClick={() => handlerOnClick('disabled large')} />
                <Button text="Сохранить" type="danger" size="medium" disabled onClick={() => handlerOnClick('disabled medium')} />
                <Button text="Сохранить" type="danger" size="small" disabled onClick={() => handlerOnClick('disabled small')} />
            </div>
            <div style={{ display: 'flex', gap: 16 }}>
                <Button text="Сохранить" type="secondary" size="large" onClick={() => handlerOnClick('disabled large')} disabled />
                <Button text="Сохранить" type="secondary" size="medium" onClick={() => handlerOnClick('disabled medium')} disabled />
                <Button text="Сохранить" type="secondary" size="small" onClick={() => handlerOnClick('disabled small')} disabled />
            </div>
        </div>
    );
};

export default ButtonDemo;
