import { IPeriod } from 'store/types';

export const infoBasketPeriodsMock: IPeriod[] = [
    {
        id: '1', name: '60 дней', selectedName: '60 дней', isSelected: false,
    },
    {
        id: '2', name: '90 дней', selectedName: '90 дней', isSelected: false,
    },
    {
        id: '3', name: '1 год', selectedName: '1 год', isSelected: false,
    },
];

export const historyPeriodsMock: IPeriod[] = [
    {
        id: '1', name: '1 день', selectedName: '1 день', isSelected: false,
    },
    {
        id: '2', name: 'неделя', selectedName: 'неделю', isSelected: false,
    },
    {
        id: '3', name: 'весь', selectedName: 'всё время', isSelected: false,
    },
];

export const campaignPeriodsMock: IPeriod[] = [
    {
        id: '1', name: '1 день', selectedName: '1 день', isSelected: false,
    },
    {
        id: '2', name: '7 дней', selectedName: '7 дней', isSelected: false,
    },
    {
        id: '3', name: '30 дней', selectedName: '30 дней', isSelected: false,
    },
];
