/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Tooltip } from '@shared/index';
import styles from './styles.module.scss';
import DefaultContent from './DefaultContent';

interface IPropsTooltip {
    children: React.ReactNode;
    content: JSX.Element;
    placement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom',
    yOffset?: number,
    xOffset?: number,

}
const Popup: React.FC<IPropsTooltip> = ({
    children,
    content,
    placement = 'bottom',
    yOffset,
    xOffset,
}): JSX.Element => {
    const [isOpenClick, setIsOpenClick] = useState(false);
    const handleChangeOpen = (status: boolean) => {
        setIsOpenClick(status);
    };
    const defaultContent = <DefaultContent onClose={() => handleChangeOpen(false)}>{content}</DefaultContent>;
    return (
        <div className={styles.block}>
            <Tooltip
                content={defaultContent}
                placement={placement}
                trigger="click"
                isOpen={isOpenClick}
                yOffset={yOffset}
                xOffset={xOffset}
                onOpenChange={handleChangeOpen}
            >
                {children}
            </Tooltip>
        </div>
    );
};

export default Popup;
