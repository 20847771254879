import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { authorsRtkQService } from '../../../services/authors';
import { IBloggerCommonModel, IBloggerPublicationModel } from '../../../services/authors/types';
import {
    BloggerProfileCommonMetricsType,
    BloggerProfileStatisticsType,
    IBloggerProfileModel,
    IBloggerProfilePublicationsModel,
} from '../../../components/info-basket/BloggerProfile/types';
import { NewsSourceType } from '../subpages/results/ResultTable/types';
import { IBloggerProfileContainerProps } from './types';
import BloggerProfile from '../../../components/info-basket/BloggerProfile/index';
import { selectCommonWindowIsRetinaSize } from '../../../store/common/window/selectors';

const convertToBloggerProfile = (common: IBloggerCommonModel, publications: IBloggerPublicationModel[]): IBloggerProfileModel => ({
    type: common.isBlogger ? NewsSourceType.blogger : NewsSourceType.media,
    name: common.title,
    actualDate: common.lastUpdateDate,
    actualTime: common.lastUpdateTime,
    isContract: false,
    logoLink: common?.logoLink,
    categories: common?.categories,
    metrics: [
        {
            type: BloggerProfileCommonMetricsType.posts,
            value: common?.posts,
        },
        {
            type: BloggerProfileCommonMetricsType.likes,
            value: common?.likes,
        },
        {
            type: BloggerProfileCommonMetricsType.audience,
            value: common?.subscribers,
        },
        {
            type: BloggerProfileCommonMetricsType.engagement,
            value: null,
        },
    ],
    statistics: [
        {
            type: BloggerProfileStatisticsType.ok,
            value: common.sourceTypePostsAmount?.ok,
        },
        {
            type: BloggerProfileStatisticsType.rss,
            value: common.sourceTypePostsAmount?.rss,
        },
        {
            type: BloggerProfileStatisticsType.vk,
            value: common.sourceTypePostsAmount?.vk,
        },
        {
            type: BloggerProfileStatisticsType.tg,
            value: common.sourceTypePostsAmount?.tg,
        },
        {
            type: BloggerProfileStatisticsType.zen,
            value: common.sourceTypePostsAmount?.zen,
        },
    ],
    publications: publications.reduce((acc: IBloggerProfilePublicationsModel[], cur: IBloggerPublicationModel) => {
        acc.push({
            time: cur.postedTime,
            date: cur.postedDate,
            content: cur.content,
            img: null,
            link: cur.link,
            id: cur.id,
            postedTimestamp: cur.postedTimestamp,
        } as IBloggerProfilePublicationsModel);
        return acc;
    }, [] as IBloggerProfilePublicationsModel[]).sort((a, b) => b.postedTimestamp - a.postedTimestamp),
} as IBloggerProfileModel);

const limitPostsAmount = 30;

const BloggerProfileContainer: React.FC<IBloggerProfileContainerProps> = ({ isOpen, setIsOpen, authorId }): JSX.Element => {
    const isRetinaSize = useSelector(selectCommonWindowIsRetinaSize);

    const [
        getAuthor,
        {
            data: author,
            isLoading: authorIsLoading,
            isFetching: authorIsFetching,
            isSuccess: authorIsSuccess,
        },
    ] = authorsRtkQService.useLazyGetAuthorQuery();
    const [
        getPublications,
        {
            data: publications,
            isLoading: publicationsIsLoading,
            isFetching: publicationsIsFetching,
            isSuccess: publicationsIsSuccess,
        },
    ] = authorsRtkQService.useLazyGetAuthorPublicationsQuery();

    const [bloggerApiPublication, setBloggerApiPublication] = useState<IBloggerPublicationModel[]>([]);

    const bloggerData = useMemo<IBloggerProfileModel | null>(() => (
        (
            !authorIsLoading
            && !authorIsLoading
            && bloggerApiPublication?.length > 0
            && authorIsSuccess
        )
            ? convertToBloggerProfile(author, bloggerApiPublication)
            : null
    ), [
        author,
        bloggerApiPublication,
        authorIsLoading,
        authorIsSuccess,
    ]);

    const [postsOffset, setPostsOffset] = useState<number>(0);
    const [isMaxPublicationsAmount, setPublicationsIsMax] = useState<boolean>(false);
    const [needAddPublications, setAddPublications] = useState<boolean>(false);

    const onAddVisiblePublication = useCallback((): void => {
        if (!(publicationsIsLoading || publicationsIsFetching)) {
            getPublications({ id: authorId, limit: limitPostsAmount, offset: postsOffset });
            setAddPublications(true);
            setPostsOffset(postsOffset + limitPostsAmount);
        }
    }, [publicationsIsLoading, publicationsIsFetching, authorId, limitPostsAmount, postsOffset]);

    useEffect(() => {
        if (needAddPublications && !publicationsIsLoading && !publicationsIsFetching && publicationsIsSuccess) {
            setAddPublications(false);
            setBloggerApiPublication([...bloggerApiPublication, ...publications]);
        }
    }, [
        setBloggerApiPublication,
        setAddPublications,
        bloggerApiPublication,
        publicationsIsLoading,
        publicationsIsFetching,
        publicationsIsSuccess,
        publications,
        needAddPublications,
    ]);

    useEffect(() => {
        if (!isOpen) {
            setPostsOffset(0);
            setPublicationsIsMax(false);
            setBloggerApiPublication([]);
        } else if (Number.isInteger(authorId)) {
            setPostsOffset(limitPostsAmount);
            getAuthor({ id: authorId });
            getPublications({ id: authorId, limit: limitPostsAmount, offset: 0 });
            setAddPublications(true);
        }
    }, [isOpen, authorId, limitPostsAmount]);

    useEffect(() => {
        if (bloggerData?.publications?.length > 0) {
            const curMaxAmount = (postsOffset) > 0 ? (postsOffset) : limitPostsAmount;
            if (bloggerData?.publications.length > 0 && bloggerData?.publications.length < curMaxAmount && !isMaxPublicationsAmount) {
                setPublicationsIsMax(true);
            }
        }
    }, [bloggerData]);

    return (
        <BloggerProfile
            isRetinaSize={isRetinaSize}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={bloggerData}
            isLoadingData={authorIsLoading || authorIsFetching}
            isLoadingPublications={publicationsIsLoading || publicationsIsFetching}
            addVisiblePublication={onAddVisiblePublication}
            isMaxPublicationsAmount={isMaxPublicationsAmount}
        />
    );
};

export default BloggerProfileContainer;
