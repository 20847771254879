import React, { useEffect, useState } from 'react';
import moment from 'moment';
import CalendarIcon from './CalendarIcon.svg';
import WrapperTooltip from '../WrapperTooltip';
import styles from './styles.module.scss';
import EventBusForChartGraph from '../../EventBus';
import vkIcon from './vk.svg';
import okIcon from './ok.svg';
import tgIcon from './tg.svg';
import rssIcon from './rss.svg';
import SourceIcon from './Source.svg';

const icons = {
    vk: vkIcon,
    ok: okIcon,
    telegram: tgIcon,
    rss: rssIcon,
};
const TooltipGraph = () => {
    const [onTooltip, setOnTooltip] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [data, setData]: any = useState({});
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const handleShowTooltip = (dataForModal) => {
        if (!dataForModal.isShow && onTooltip) {
            return;
        }
        setIsShow(dataForModal.isShow);
        setX(dataForModal.left);
        setY(dataForModal.top);
        setData(dataForModal.data);
    };
    const getDate = (unix) => {
        const ddmmyyyy = moment(unix).format('DD.MM.YYYY');
        const hhmm = moment(unix).format('HH:mm');
        return `${ddmmyyyy} ${hhmm}`;
    };
    const getRating = (rating, platform) => {
        const isPositive = !!rating;
        return (
            <div className={styles.containerRating}>
                <div className={styles.ratingIcon}>
                    <img src={icons[platform]} alt="icon" />
                </div>
                <div className={styles.ratingLabelContainer}>
                    <div className={styles.ratingIndicator} style={{ background: isPositive ? '#0EBFA1' : '#F36869' }} />
                    <div className={styles.ratingLabel} style={{ color: '#787E93' }}>Негативно</div>
                </div>
            </div>
        );
    };
    const getContracted = (isContracted) => (
        <div style={{ color: isContracted ? '#0EBFA1' : '#F36869' }}>
            {`${isContracted ? 'Контрактованный' : 'Не контрактованный'}`}
        </div>
    );
    const getSourceInform = (type, name) => (
        <div className={styles.containerSourceInform}>
            <div className={styles.sourceLabel}>{type === 'massMedia' ? 'СМИ' : 'Блогер'}</div>
            <div className={styles.sourceIcon}>
                <img src={SourceIcon} alt="src" />
            </div>
            <div className={styles.sourceName}>{name}</div>
        </div>
    );
    useEffect(() => {
        EventBusForChartGraph.on('setDataForTooltip', handleShowTooltip);
        return () => {
            EventBusForChartGraph.off('setDataForTooltip', handleShowTooltip);
        };
    });
    const updatedShow = (status) => {
        setOnTooltip(status);
        if (!status) setIsShow(false);
    };
    if (!isShow || !data) { return null; }
    return (
        <WrapperTooltip x={x} y={y} handlerOnMouseEnter={(status) => updatedShow(status)}>
            <div className={styles.titleDate}>
                <img src={CalendarIcon} alt="CalendarIcon" style={{ marginRight: '8px' }} />
                {`${getDate(data.date * 1000)}`}
            </div>
            {getRating(data.rating, data.platform)}
            {getContracted(data.contracted)}
            {getSourceInform(data.type, data.name)}
        </WrapperTooltip>
    );
};

export default TooltipGraph;
