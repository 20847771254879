import { barChartBaseOptions } from '../../chartOptions';
import PptxSlide from '../../PptxSlide';
import { ICampaignPPTXAuthorsPageProps } from './types';

class CampaignPPTXAuthorsPage extends PptxSlide {
    render(props: ICampaignPPTXAuthorsPageProps) {
        this.renderHeader({
            date: props.date,
            title: props.title,
            layoutHeight: props.layoutHeight,
            layoutWidth: props.layoutWidth,
            reportName: props.reportName,
            header: {
                pageName: props.header.pageName,
                number: props.header.number,
            },
        });

        const options = {
            chartWidth: 350,
            chartHeight: 510,
            spaceBetween: 83,
        };

        if (props.authorsData) {
            const chunks = [];
            for (let i = 0; i < props.authorsData.filters.length; i += 15) {
                const chunk = props.authorsData.filters.slice(i, i + 15);
                chunks.push(chunk);
            }

            const firstLabels: string[] = [];
            const firstValues: number[] = [];
            chunks[0].forEach((item) => {
                firstLabels.push(item.filter_name);
                firstValues.push(item.count);
            });

            const firstChartOptions = {
                ...barChartBaseOptions,
                x: this.getInches(20),
                y: this.getInches(85),
                w: this.getInches(options.chartWidth + 50),
                h: this.getInches(options.chartHeight),
            };

            this.slide.addChart(
                'bar',
                [
                    {
                        name: 'Авторы',
                        labels: firstLabels,
                        values: firstValues,
                    },
                ],
                // @ts-ignore
                firstChartOptions,
            );

            if (chunks[1]) {
                const secondLabels: string[] = [];
                const secondValues: number[] = [];
                chunks[1].forEach((item) => {
                    secondLabels.push(item.filter_name);
                    secondValues.push(item.count);
                });

                const secondChartOptions = {
                    ...barChartBaseOptions,
                    x: this.getInches(options.spaceBetween + options.chartWidth),
                    y: this.getInches(85),
                    w: this.getInches(options.chartWidth),
                    h: this.getInches(options.chartHeight),
                    chartColors: ['9545D8'],
                };

                this.slide.addChart(
                    'bar',
                    [
                        {
                            name: 'Авторы',
                            labels: secondLabels,
                            values: secondValues,
                        },
                    ],
                    // @ts-ignore
                    secondChartOptions,
                );
            }
        }

        return this.slide;
    }
}

export default CampaignPPTXAuthorsPage;
