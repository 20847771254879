import moment, { Moment } from 'moment';
import { ISimpleEntity } from 'store/types';
import Path from '../../shared/lib/paths';

enum CampaignStatusFromBackendTypes {
    'running' = 1,
    'pending' = 2,
    'stopped' = 3,
    'deleted' = 4,
}

const campaignPlatformsTypeState: ISimpleEntity[] = [
    { id: 1, name: 'ALL', value: 'ALL' },
    { id: 2, name: 'RSS', value: 'RSS' },
    { id: 3, name: 'VK', value: 'VK' },
    { id: 4, name: 'OK', value: 'OK' },
    { id: 5, name: 'TG', value: 'TG' },
    { id: 6, name: 'DZEN', value: 'DZEN' },
    { id: 7, name: 'YOUTUBE', value: 'YOUTUBE' },
    { id: 8, name: 'RUTUBE', value: 'RUTUBE' },
    { id: 9, name: 'VK_VIDEO', value: 'VK_VIDEO' },
];

const allPlatformsIdsWithoutCommon = [2, 3, 4, 5, 6, 7, 8, 9];
const oneDayMs = 1000 * 60 * 60 * 24;

const campaignStatusToBool = (status: string): boolean => status === 'running';
const campaignStatusToString = (isActive: boolean): string => (isActive ? 'running' : 'stopped');
const getCampaignPlatformIdByName = (inputName: string): number => campaignPlatformsTypeState.find(
    ({ name }) => name === inputName,
).id as number ?? -1;
const getCampaignPlatformByNames = (names: string[]): number[] => {
    if (!names?.length) {
        return [];
    }
    const result = names.map((name) => getCampaignPlatformIdByName(name));
    return (result.every((id) => allPlatformsIdsWithoutCommon.includes(id)) && result.length >= allPlatformsIdsWithoutCommon.length)
        ? [1, ...allPlatformsIdsWithoutCommon]
        : result;
};
const getCampaignPlatformNamesByIds = (ids: number[]): string[] => {
    if (!ids?.length) {
        return [];
    }
    const qIds = ids.filter((id) => id !== 1)?.length; // без ALL, т.к. этот item не приходит с бэка
    const qInitial = campaignPlatformsTypeState.filter(({ id }) => Number(id) !== 1)?.length;
    if (qIds === qInitial) {
        return campaignPlatformsTypeState.map(({ name }) => name);
    }

    return ids.map((id) => campaignPlatformsTypeState
        .find(({ id: itemId }) => itemId === id)?.name ?? '')
        .filter((name) => Boolean(name));
};

const getCampaignPeriodIdFromParsedDate = (parseFrom: string, createDate: string): string => {
    if (!parseFrom || !createDate) {
        return '';
    }
    const from = parseFrom.split('.').map((item) => Number(item));
    const create = createDate.split('.').map((item) => Number(item));
    const fromMs = new Date(from[2], from[1], from[0]).getTime();
    const createMs = new Date(create[2], create[1], create[0]).getTime();
    const days = Math.round(((createMs - fromMs) / oneDayMs));
    if (days <= 30 && days > 7) {
        return '3';
    }
    if (days <= 7 && days > 1) {
        return '2';
    }
    if (days > 30) {
        return '';
    }
    return '1';
};

const getAmountDaysFromPeriodId = (id: string): number => {
    switch (id.toLowerCase()) {
        case '1':
            return 1;
        case 'week':
        case '2':
        case '7':
            return 7;
        case 'month':
        case '30':
        case '3':
            return 30;
        default:
            return 0;
    }
};

const getCampaignParseFromDate = (periodId: string): string => (
    moment().subtract(getAmountDaysFromPeriodId(periodId), 'd').format('YYYY-MM-DD')
);

const getCampaignParseFromDateNoString = (periodId: string): Moment => (
    moment().subtract(getAmountDaysFromPeriodId(periodId), 'd')
);

const locationIsTrackingCampaign = (location: string): boolean => {
    if (!location?.length) {
        return false;
    }
    const parts = location.split('/');
    return (
        parts?.length > 2
        && Number.isInteger(Number(parts[parts.length - 1]))
        && parts[parts.length - 2] === Path.Campaign
    );
};

export default {
    CampaignStatusFromBackendTypes,
    campaignPlatformsTypeState,
    campaignStatusToBool,
    campaignStatusToString,
    getCampaignPlatformIdByName,
    getCampaignPlatformByNames,
    getCampaignPlatformNamesByIds,
    getCampaignPeriodIdFromParsedDate,
    locationIsTrackingCampaign,
    getCampaignParseFromDate,
    getCampaignParseFromDateNoString,
};
