import classNames from 'classnames';
import React, { useMemo } from 'react';
import SvgIcon from '@components/SvgIcon';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Tooltip } from '@shared/index';
import styles from './styles.module.scss';

interface IProps {
    value: string;
    errorText?: string;
    warningText?: string;
    size?: 'large' | 'medium' | 'small';
    placeholderStyle?: React.CSSProperties,
    label?: string;
    placeholder?: string;
    limit?: number;
    disabled?: boolean
    className?: string
    wrapperClassName?: string;
    readonly?: boolean
    isSearch?: boolean
    onChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    info?: string[],
    style?: React.CSSProperties;
    id?: string;
}

const TextField: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        value,
        errorText,
        warningText,
        size,
        placeholderStyle,
        label,
        placeholder,
        limit,
        disabled,
        className,
        wrapperClassName,
        readonly,
        isSearch,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        info,
        style,
        id,
    } = props;

    const inputClassName = useMemo(() => {
        const arr = [styles.input];
        if (value?.length >= 1) arr.push(styles.inputWithText);
        if (errorText) arr.push(styles.inputError);
        if (warningText) arr.push(styles.inputWarning);
        if (size && size === 'medium') arr.push(styles.inputSizeMedium);
        if (size && size === 'small') arr.push(styles.inputSizeSmall);
        if (className) arr.push(className);
        return arr;
    }, [value, errorText, warningText, size, className]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let val = event.target.value;
        if (limit) val = val.substring(0, limit);
        onChange(val);
    };

    const handleClickClear = (): void => {
        onChange('');
    };

    const classNameForWrapper = useMemo(() => (wrapperClassName || styles.inputWrapper), [wrapperClassName]);

    return (
        <div
            className={styles.wrapper}
        >
            {(label || limit) && (
                <div className={styles.header}>
                    {label && <p className={styles.label}>{label}</p>}
                    {limit && (
                        <p
                            className={classNames(
                                styles.limit,
                                value?.length >= 1 ? styles.limitTyping : '',
                                value?.length === limit ? styles.limitMax : '',
                            )}
                        >
                            {`${value?.length} / ${limit}`}
                        </p>
                    )}
                </div>
            )}
            <div
                className={classNameForWrapper}
            >

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        id={id}
                        type="text"
                        className={inputClassName.join(' ')}
                        value={value}
                        onChange={handleChange}
                        disabled={disabled}
                        readOnly={readonly}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        style={style}
                    />
                    <Tooltip
                        isOpen={info?.length > 0}
                        content={<div>{info?.map((item) => (<div key={`inputInfo_${item}`} className={styles.additionInfo}>{item}</div>))}</div>}
                        placement="right"
                    >
                        <div style={{ right: 0, width: 0 }} />
                    </Tooltip>
                </div>

                {placeholder && value?.length < 1 && (
                    <p className={styles.inputPlaceholder} style={placeholderStyle}>{placeholder}</p>
                )}
                {value?.length >= 1 && !readonly && (
                    <button type="button" className={styles.cross} onClick={handleClickClear}>
                        <SvgIcon id="BigCross" size={18} />
                    </button>
                )}
                {isSearch && value?.length < 1 && <div className={styles.iconSearch}><SvgIcon id="Search" size={16} /></div>}
            </div>

            {errorText && <p className={styles.textError}>{errorText}</p>}
            {warningText && <p className={styles.textWarning}>{warningText}</p>}
        </div>
    );
};
export default TextField;
