import {
    IPDFReportSummary,
    IPDFReportTopPlatform,
    IPDFReportTopPostsAndViews,
    IPDFReportTopSourcesByRegions,
} from 'store/pdf-handler/wrapper/types';

const convertAuthorsByPostsOrViewsToObject = (item: unknown): IPDFReportTopPostsAndViews => {
    const result = {} as IPDFReportTopPostsAndViews;
    if (!item) {
        return result;
    }
    Object.keys(item).forEach((key: string) => {
        switch (key) {
            case 'author_id':
                result.authorId = item[key];
                break;
            case 'author_name':
                result.authorName = item[key];
                break;
            case 'count_posts':
                result.amountPosts = item[key];
                break;
            case 'count_views':
                result.amountViews = item[key];
                break;
            default:
                break;
        }
    });

    return result;
};

const convertViewsByPlatformToObject = (item: unknown): IPDFReportTopPlatform => {
    const result = {} as IPDFReportTopPlatform;
    if (!item) {
        return result;
    }
    Object.keys(item).forEach((key: string) => {
        switch (key) {
            case 'content':
                result.content = item[key];
                break;
            case 'post_link':
                result.postLink = item[key];
                break;
            case 'source_type':
                result.sourceType = item[key];
                break;
            case 'top':
                result.topSequential = item[key];
                break;
            case 'views':
                result.views = item[key];
                break;
            default:
                break;
        }
    });

    return result;
};

const convertSummaryApiToObject = (item: unknown): IPDFReportSummary => {
    const result = {
        topAuthorsByPosts: [],
        topAuthorsByViews: [],
        topViewsByPlatform: [],
    } as IPDFReportSummary;
    Object.keys(item).forEach((key: string) => {
        switch (key) {
            case 'top_authors_by_posts_count':
                if (Array.isArray(item[key]) && item[key].length > 0) {
                    item[key].forEach((value) => {
                        result.topAuthorsByPosts.push(convertAuthorsByPostsOrViewsToObject(value));
                    });
                }
                break;
            case 'top_authors_by_views':
                if (Array.isArray(item[key]) && item[key].length > 0) {
                    item[key].forEach((value) => {
                        result.topAuthorsByViews.push(convertAuthorsByPostsOrViewsToObject(value));
                    });
                }
                break;
            case 'top_views_by_platform':
                if (Array.isArray(item[key]) && item[key].length > 0) {
                    item[key].forEach((value) => {
                        result.topViewsByPlatform.push(convertViewsByPlatformToObject(value));
                    });
                }
                break;
            case 'top_sources_by_regions':
                if (Array.isArray(item[key]) && item[key].length > 0) {
                    result.topSourcesByRegions = (Object.keys(item[key]).map((subItem) => ({
                        amountSources: item[key][subItem]?.count_sources,
                        authorType: item[key][subItem]?.author_type,
                        regionTitle: item[key][subItem]?.region_title,
                    } as IPDFReportTopSourcesByRegions)));
                }
                break;
            default:
                break;
        }
    });
    return result;
};

export const convertReportDataApiToSummary = (data: unknown): IPDFReportSummary => {
    if (!data) {
        return {} as IPDFReportSummary;
    }

    if (Array.isArray(data) && data.length > 0) {
        if (data[0]) {
            return convertSummaryApiToObject(data[0]);
        }
    }

    return convertSummaryApiToObject(data);
};
