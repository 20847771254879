import { rtkQApi } from '../../index';
import {
    IWithParamsCommonModel,
} from './models';
import {
    IQueryParamsGetPointsForChart,
} from './types';

export const exceptionsChartService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        // New
        updateChartDataM: build.mutation<IWithParamsCommonModel, IQueryParamsGetPointsForChart>({
            query: (body) => ({
                url: 'campaign/chart/with_params_common',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['CampaignDeletedPosts'],
        }),
        updateChartDataL: build.query<IWithParamsCommonModel, IQueryParamsGetPointsForChart>({
            query: (body) => ({
                url: 'campaign/chart/with_params_common',
                method: 'POST',
                body,
            }),
            providesTags: [{ type: 'DocumentList', id: 23432 }],
        }),
    }),
});
