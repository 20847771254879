import { message, UploadFile } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { TStatus } from '@entities/BlockLoadFile/types';
import { IQueryParamsSaveDocument } from '@services/projects/types/list/types';
import { projectsListService } from '@services/projects';
import { IDocument } from '../types';

type TDocumentsWithActions = [
    IDocument[],
    {
        isInitialisationDocuments: boolean,
        statusNewDocument: TStatus,
        isLoadingRemove: boolean
    },
    {
        handleRemoveFile: (id: number) => void,
        setStatusNewDocument: React.Dispatch<React.SetStateAction<TStatus>>,
        handleUploadFile: (file: UploadFile) => void,
        onChangeNameInList: (id: number, name: string) => void
    },
];

const PSEVDO_VALUE = 9797979797;

const useDocuments = (isShablons: boolean, projectId : number, typeId?: number):TDocumentsWithActions => {
    const [getSavedDocuments, { isLoading: isInitialisationDocuments }] = projectsListService.useLazyGetListFilesQuery();
    const [savedDocuments, setSavedDocuments] = useState<IDocument[]>([]);
    const [statusNewDocument, setStatusNewDocument] = useState<TStatus>('default');
    const [saveDocument] = projectsListService.useSaveDocumentMutation();
    const [removeDocument, { isLoading: isLoadingRemove }] = projectsListService.useRemoveDocumentMutation();

    const removePvsevdoItem = () => {
        setSavedDocuments((prev) => prev.filter((doc) => doc.id !== PSEVDO_VALUE));
    };
    const getTypeId = () => {
        if (typeId) return typeId;
        if (isShablons) return 2;
        return savedDocuments.length > 0 ? 3 : 1;
        // return savedDocuments.length > 0 ? 1 : 3;
    };
    const handleErrorResponse = () => {
        message.error('Произошла непредвиденная ошибка');
        removePvsevdoItem();
        setStatusNewDocument('error');
    };
    const handleUploadFile = (file: UploadFile) => {
        setStatusNewDocument('loading');
        const rawNewDocument: IQueryParamsSaveDocument = {
            type_id: getTypeId(),
            file: file as any,
        };

        if (!isShablons) rawNewDocument.project_id = projectId;
        setSavedDocuments((prev) => [...prev, {
            id: PSEVDO_VALUE,
            label: `${PSEVDO_VALUE}`,
            date: `${PSEVDO_VALUE}`,
            type_id: typeId || isShablons ? 2 : 3,
            type: 'word',
        }]);
        return saveDocument({
            ...rawNewDocument,
        }).then((res: any) => {
            if (!res?.data) handleErrorResponse();
            else {
                message.success('Загрузка прошла успешно');
                setStatusNewDocument('complete');
                setSavedDocuments((prev) => prev.map((doc) => {
                    if (doc.id !== PSEVDO_VALUE) return doc;
                    return {
                        id: res.data.id,
                        label: res.data.name,
                        date: moment(res.data.upload_time).format('DD.MM.YYYY'),
                        type: res.data.extension,
                        type_id: res?.data?.type_id || 3,
                    };
                }));
            }
        }).catch(handleErrorResponse);
    };

    const getterDocs = () => getSavedDocuments({ id: +projectId }).then(({ data }) => {
        if (data) {
            setSavedDocuments([...data]);
        }
    });
    const handleRemoveFile = (id: number) => {
        removeDocument({ url: `${id}?project_id=${+projectId}` }).then(() => {
            // setSavedDocuments((prev) => prev.filter((doc) => doc.id !== id));
            getterDocs();
            message.error('Удаленно');
        }).catch(() => {
            message.error('При удалении произошла ошибка');
        });
    };
    useEffect(() => {
        getterDocs();
    }, [projectId]);
    const needDocuments = useMemo(() => {
        if (isShablons) return savedDocuments.filter((doc) => doc.type_id === 2);
        if (typeId === 4) return savedDocuments.filter((doc) => doc.type_id === 4);
        return savedDocuments.filter((doc) => doc.type_id !== 2 && doc.type_id !== 4);
    }, [projectId, isShablons, savedDocuments]);
    const onChangeNameInList = (id: number, name: string) => {
        setSavedDocuments((prev) => prev.map((doc) => ({ ...doc, label: id === doc.id ? name : doc.label })));
    };
    return [
        needDocuments,
        {
            isInitialisationDocuments,
            statusNewDocument,
            isLoadingRemove,
        },
        {
            handleRemoveFile,
            setStatusNewDocument,
            handleUploadFile,
            onChangeNameInList,
        },
    ];
};

export default useDocuments;
