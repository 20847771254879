import SvgIcon from '@components/SvgIcon';
import { IColumnProperties } from '@store/common/tableProperties/types';
import { LogoIconsIds } from '@app/constants/icons';

const platformsMap = new Map([
    ['rss', { element: <SvgIcon key={0} id={LogoIconsIds.RssLogo} size={24} />, title: 'RSS' }],
    ['vk', { element: <SvgIcon key={1} id={LogoIconsIds.VkLogo} size={24} />, title: 'Vkontakte' }],
    ['tg', { element: <SvgIcon key={2} id={LogoIconsIds.TgLogo} size={24} />, title: 'Telegram' }],
    ['ok', { element: <SvgIcon key={3} id={LogoIconsIds.OkLogo} size={24} />, title: 'Odnoklassniki' }],
    ['dzen', { element: <SvgIcon key={4} id={LogoIconsIds.ZenLogo} size={24} />, title: 'Дзен' }],
    ['rutube', { element: <SvgIcon key={5} id={LogoIconsIds.RuTubeLogo} size={24} />, title: 'rutube' }],
    ['youtube', { element: <SvgIcon key={6} id={LogoIconsIds.YouTubeLogo} size={24} />, title: 'youtube' }],
    ['vk_video', { element: <SvgIcon key={7} id={LogoIconsIds.VkVideo} size={24} />, title: 'VkVideo' }],
]);

const excelMediaplanColumns: IColumnProperties[] = [
    {
        id: 1, name: 'id', visible: true, title: 'Номер строки',
    },
    {
        id: 2, name: 'source', visible: true, title: 'Источники',
    },
    {
        id: 3, name: 'price', visible: true, title: 'Цена',
    },
    {
        id: 4, name: 'startDate', visible: true, title: 'Дата начала',
    },
    {
        id: 5, name: 'endDate', visible: true, title: 'Дата завершения',
    },
    {
        id: 6, name: 'content', visible: true, title: 'Текст',
    },
    {
        id: 7, name: 'actions', visible: true, title: 'Действия',
    },
];

export default {
    platformsMap,
    excelMediaplanColumns,
};
