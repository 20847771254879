import React, { useState } from 'react';
import { message, Tooltip } from 'antd';
import { useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { projectsListService } from '@services/projects';
import { TDocumentType } from '../types';
import styles from './styles.module.scss';
import SvgIcon from '../../SvgIcon';
import SkeletonDocumentCard from './Skeleton';
import ModalChangeName from '../ModalChangeName';

interface IOpenModal {
    name?: string,
    id?: number,
}
interface IPropsDocumentCard {
    isBrief: boolean,
    type_id?: number,
    isLoading: boolean,
    id: number,
    label: string,
    date: string,
    type: TDocumentType,
    onDelete?: (id: number) => void,
    onChangeNameInList: (id: number, newName: string) => void,
}

const ListIconDocs = {
    word: 'Word_Icon',
    pdf: 'PDF_Icon',
    pptx: 'PPTX_Icon',
    xlsx: 'XLSX_Icon',
};

const DocumentCard: React.FC<IPropsDocumentCard> = ({
    isBrief, type_id, type, label, date, isLoading, id, onDelete, onChangeNameInList,
}): JSX.Element => {
    const { Id: projectId } = useParams();
    const [openModal, setOpenModal] = useState<IOpenModal | null>(null);
    const [isRemoving, setIsRemoving] = useState<boolean | string>(false);
    const [download, setDownload] = useState<boolean | string>(false);
    const [isUpdatingName, setIsUpdatingName] = useState<boolean>(false);
    const [downloadDocument] = projectsListService.useDownloadDocumentMutation();
    const [removeDocument] = projectsListService.useRemoveDocumentMutation();
    const [changeDocument] = projectsListService.useChangeDocumentMutation();

    const handleClickOnDocument = () => {
        setDownload(true);
        let urlDownload = '';
        if (type_id && type_id !== 2) urlDownload = `${id}?project_id=${+projectId}`;
        else urlDownload = `${id}`;
        downloadDocument({ url: urlDownload })
            .then((res: any) => {
                if (!res?.data) {
                    message.error(`При загрузке файла ${label} произошла ошибка`);
                    setDownload('При загрузке произошла ошибка');
                } else {
                    window.open(res?.data?.file_url, '_blank');
                    setDownload(false);
                    message.success(`Файл ${label} успешно загружен`);
                }
            })
            .catch(() => {
                setDownload('При загрузке произошла ошибка');
                message.error(`При загрузке файла ${label} произошла ошибка`);
            });
    };
    const handleClickOnDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsRemoving(true);
        let urlRemove = '';
        if (type_id && type_id !== 2) urlRemove = `${id}?project_id=${+projectId}`;
        else urlRemove = `${id}`;
        removeDocument({ url: urlRemove })
            .then((res: any) => {
                if (res?.error) {
                    message.error(`При удалении файла ${label} произашла ошибка!`);
                    setIsRemoving('Произашла ошибка!');
                } else {
                    if (onDelete) onDelete(id);
                    message.success(`Файл ${label} удален!`);
                }
            })
            .catch(() => {
                message.error(`При удалении файла ${label} произашла ошибка!`);
                setIsRemoving('Произашла ошибка!');
            });
    };
    const onChangeName = (newName: string) => {
        setIsUpdatingName(true);
        setOpenModal(null);
        setDownload(true);
        // TODO Projects types
        const rawQuery: any = {
            id: +id,
            body: {
                new_name: newName,
            },
        };
        if (type_id && type_id !== 2) {
            rawQuery.body.project_id = projectId;
        }
        changeDocument(rawQuery)
            .then((res: any) => {
                if (!res?.data) {
                    setDownload('Произошла ошибка');
                    message.error(`При смене названия ${label} произашла ошибка!`);
                } else if (res?.error) {
                    setDownload('Произошла ошибка');
                    message.error(`При смене названия ${label} произашла ошибка!`);
                } else {
                    onChangeNameInList(id, res?.data?.name);
                    setDownload(false);
                    message.success(`Название файла ${label} успешно заменено на ${res?.data?.name}`);
                }
            })
            .catch(() => {
                setDownload('Произошла ошибка');
                message.error(`При смене названия ${label} произашла ошибка!`);
            })
            .finally(() => setIsUpdatingName(false));
    };
    const handleClickOnChangeName = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpenModal({ name: label });
    };
    const getStatusRemoving = (proccess) => {
        if (typeof proccess === 'string') {
            return (
                <div
                    className={styles.labelErrorRemoving}
                    onMouseEnter={() => setIsRemoving(false)}
                >
                    Ошибка при удалении
                </div>
            );
        }
        return (
            <div className={styles.removeItemAnimation}>
                <SvgIcon size={28} id="RemoveIcon" />
            </div>
        );
    };
    const removeAnimation = isRemoving && (
        <div
            className={styles.containerItemAnimation}
        >
            {getStatusRemoving(isRemoving)}
        </div>
    );
    const getStatusDownload = (proccess) => {
        if (typeof proccess === 'string') {
            return (
                <div
                    className={styles.labelErrorRemoving}
                    onMouseEnter={() => setDownload(false)}
                >
                    При загрузке возникла ошибка!
                </div>
            );
        }
        return (
            <div className={styles.downloading}>
                <span />
            </div>
        );
    };
    const downloadAnimation = download && (
        <div
            className={styles.containerItemAnimation}
        >
            {getStatusDownload(download)}
        </div>
    );
    if (isLoading) {
        return <SkeletonDocumentCard />;
    }
    return (
        <div className={styles.root_DocumentCard}>
            <ModalChangeName
                isOpen={!!openModal}
                setOpen={setOpenModal}
                onChangeName={onChangeName}
                name={openModal?.name}
            />
            {removeAnimation}
            {downloadAnimation}
            <div
                className={`${styles.content_DocumentCard} ${styles.deleted}`}
                onClick={handleClickOnDocument}
                key={`documentCard_${id}`}
                role="button"
            >
                <div role="button" className={styles.btnClose} onClick={handleClickOnDelete}>
                    <SvgIcon size={14} id="NewClose" />
                </div>
                <div className={`${styles.svgIcn} ${isBrief ? styles.select : ''}`}>
                    <SvgIcon size={32} id={ListIconDocs[type]} />
                </div>
                <Tooltip
                    placement="bottom"
                    color="white"
                    zIndex={label.length > 7 ? 1010 : -2}
                    title={label}
                    overlayStyle={{ maxWidth: '1000px' }}
                    overlayInnerStyle={{
                        borderRadius: '6px', color: '#000', fontSize: '14px', padding: 8,
                    }}
                >
                    {isUpdatingName
                        ? (
                            <div style={{
                                position: 'relative',
                                marginBottom: 2,
                                marginTop: 4,
                            }}
                            >
                                <Skeleton
                                    height={12}
                                    width={72}

                                />
                            </div>
                        )
                        : (
                            <div className={styles.label}>
                                <span>
                                    {label}
                                </span>
                                <div
                                    className={styles.svgIcn}
                                    role="button"
                                    onClick={handleClickOnChangeName}
                                >
                                    <SvgIcon size={16} id="ChangeIcon" />
                                </div>
                            </div>
                        )}

                </Tooltip>
                <div className={styles.date}>
                    {date}
                </div>
            </div>
        </div>
    );
};

export default DocumentCard;
