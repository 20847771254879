import React, { useMemo } from 'react';
import { ISimpleEntity } from 'store/types';
import SvgIcon from '../../../../SvgIcon';
import CampaignTagsFavoriteListSkeleton from './Skeleton';
import styles from './styles.module.scss';

interface IProps {
    top: number;
    left: number;
    isOpen: boolean;
    isLoading: boolean;
    tagList: ISimpleEntity[];
    onAddWord: (value: string, id: number) => void | null;
    onClose: () => void;
    onAddFavorite: () => void;
    onDeleteFavorite: (id: number) => void;
}

const CampaignTagsFavoriteList: React.FC<IProps> = ({
    top,
    left,
    isOpen,
    isLoading,
    tagList,
    onAddWord,
    onClose,
    onAddFavorite,
    onDeleteFavorite,
}): JSX.Element => {
    const dataIsAbsent = useMemo<JSX.Element>(() => (
        isLoading
            ? <CampaignTagsFavoriteListSkeleton />
            : (
                <span
                    style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                    className={styles.modalCard_tagContainer_text}
                >
                    Нет данных
                </span>
            )
    ), [isLoading]);
    if (!isOpen) {
        return null;
    }
    return (
        <div className={styles.campaignWordsFavoriteList_root} style={{ top, left }}>
            <div className={styles.modalCard}>
                <div className={styles.modalCard_header}>
                    <div className={styles.modalCard_title}>
                        Добавить в избранное
                        <button
                            type="button"
                            className={styles.modalCard_btn}
                            onClick={onAddFavorite}
                        >
                            <SvgIcon id="favorite" size={16} height={22} />
                        </button>
                    </div>
                    <button
                        type="button"
                        className={styles.modalCard_btn}
                        onClick={onClose}
                    >
                        <SvgIcon id="BigCross" size={14} />
                    </button>
                </div>
                <div className={styles.modalCard_content}>
                    {
                        !isLoading && tagList?.length > 0
                            ? (
                                tagList.map((tag) => (
                                    <div
                                        key={tag.id}
                                        className={styles.modalCard_tagContainer}
                                        style={tag.id < 0 ? { backgroundColor: '#E8F1F9' } : null}
                                    >
                                        <div
                                            className={styles.modalCard_tagContainer_text}
                                            role="button"
                                            tabIndex={0}
                                            onKeyPress={() => null}
                                            onClick={() => onAddWord(tag.value.toString(), Number(tag.id))}
                                        >
                                            {tag.value}
                                        </div>
                                        {
                                            tag.id > 0 && (
                                                <button
                                                    type="button"
                                                    className={styles.modalCard_btn}
                                                    onClick={() => onDeleteFavorite(Number(tag.id))}
                                                >
                                                    <SvgIcon id="TagCross" size={16} />
                                                </button>
                                            )
                                        }
                                    </div>
                                ))
                            )
                            : dataIsAbsent
                    }
                </div>
            </div>
        </div>
    );
};

export default CampaignTagsFavoriteList;
