import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserProfileState } from 'services/common/types';
import { FetchStatus } from '../types';
import { convertApiToUserInfo } from './converter';

const initialState: IUserProfileState = {
    data: {
        userId: '',
        lastName: '',
        email: '',
        firstName: '',
        middleName: '',
        owner: false,
    },
    status: FetchStatus.undefined,
};

const { actions: userProfileActions, reducer } = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        startLoading: (state: IUserProfileState): void => {
            state.status = FetchStatus.loading;
        },
        failedLoading: (state: IUserProfileState): void => {
            state.status = FetchStatus.failed;
        },
        successLoading: (state: IUserProfileState): void => {
            state.status = FetchStatus.success;
        },
        setUserInfo: (state: IUserProfileState, { payload }: PayloadAction<unknown>) => {
            state.data = convertApiToUserInfo(payload);
        },
        setFirstName: (state: IUserProfileState, { payload }: PayloadAction<string>) => {
            state.data.firstName = payload;
        },
    },
});

export { userProfileActions };
export default reducer;
