import React, {
    MouseEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import styles from './styles.module.scss';

interface IProps {
    max: number;
    defaultStartDate: number;
    start: number;
    end: number;
    countCompany: number;
    // startCircleSlider: number;
    // endCircleSlider: number;
    currentStartDate?: string;
    currentEndDate?: string;
    onMoveStartDate?: (x: number) => void;
    onMoveEndDate?: (x: number) => void;
    isPeriodChoice: boolean | null;
}

/** dimensions of elements */
const triangleAbsoluteLeftOffset = 41;
const padding = 26;
const sliderWidth = 12;
const sliderHalfWidth = sliderWidth / 2;
const hintHalfWidth = (96 - sliderWidth) / 2;

const calculateOffsetRightCircle = (start: number, end: number, max: number): number => {
    const result = start + end > sliderHalfWidth ? start + end - sliderHalfWidth : 0;
    return result > max ? max : result;
};
const calculateOffsetLeftCircle = (start: number, end: number, max: number): number => {
    const curEnd = calculateOffsetRightCircle(start, end, max);
    return start > curEnd ? curEnd : start;
};

const isLeftBorderOfWindow = (leftCircleOffsetX: number): boolean => (
    leftCircleOffsetX + sliderHalfWidth + padding - hintHalfWidth <= 0
);
const isInvertedTriangle = (leftCircleOffsetX: number, rightCircleOffsetX: number): boolean => (
    leftCircleOffsetX >= rightCircleOffsetX - sliderWidth - hintHalfWidth * 2
);
const getHintOffsetX = (curCircleOffsetX: number): number => (
    isLeftBorderOfWindow(curCircleOffsetX) ? -(hintHalfWidth - sliderWidth) : curCircleOffsetX - hintHalfWidth
);
const getHitTriangleOffsetX = (curCircleOffsetX: number): number => (
    isLeftBorderOfWindow(curCircleOffsetX)
        ? (triangleAbsoluteLeftOffset - sliderWidth + curCircleOffsetX)
        : triangleAbsoluteLeftOffset
);
const getTopForLeftHint = (leftCircleOffsetX: number, rightCircleOffsetX: number): number => (
    leftCircleOffsetX >= rightCircleOffsetX - sliderWidth - hintHalfWidth * 2 ? 14 : -40
);

const LineSlider: React.FC<IProps> = ({
    max,
    defaultStartDate,
    start,
    end,
    countCompany,
    // startCircleSlider,
    // endCircleSlider,
    isPeriodChoice,
    onMoveStartDate,
    onMoveEndDate,
    currentStartDate,
    currentEndDate,
}): JSX.Element => {
    const [leftCircleOffsetX, setLeftCircleOffsetX] = useState<number>(() => calculateOffsetLeftCircle(start, end, max));
    const [rightCircleOffsetX, setRightCircleOffsetX] = useState<number>(() => calculateOffsetRightCircle(start, end, max));
    const [isLeftCircleDrag, setIsLeftCircleDrag] = useState<boolean>(false);
    const [isRightCircleDrag, setIsRightCircleDrag] = useState<boolean>(false);
    const [isLeftCircleMouseDown, setIsLeftCircleMouseDown] = useState<boolean>(false);
    const [isRightCircleMouseDown, setIsRightCircleMouseDown] = useState<boolean>(false);
    const [isLeftCircleMouseOver, setIsLeftCircleMouseOver] = useState<boolean>(false);
    const [isRightCircleMouseOver, setIsRightCircleMouseOver] = useState<boolean>(false);

    const maxEndOffset = useMemo<number>(() => calculateOffsetRightCircle(start, end, max), [start, end, max]);
    const widthRectangle = useMemo<number>(() => (
        rightCircleOffsetX - leftCircleOffsetX
    ), [rightCircleOffsetX, leftCircleOffsetX]);
    const heightRectangle = useMemo<number>(() => (
        (countCompany * 120) + (30 / countCompany)
    ), [countCompany]);

    const handlerOnStartDateMove = useCallback((x: number): void => {
        if (isPeriodChoice && onMoveStartDate) {
            onMoveStartDate(x);
        }
    }, [isPeriodChoice, onMoveStartDate]);
    const handlerOnEndDateMove = useCallback((x: number): void => {
        if (isPeriodChoice && onMoveEndDate) {
            onMoveEndDate(x);
        }
    }, [isPeriodChoice, onMoveEndDate]);

    const setLeftCircleOffsetWithCheck = useCallback((value: number): void => {
        setLeftCircleOffsetX(value > rightCircleOffsetX ? rightCircleOffsetX : value);
    }, [rightCircleOffsetX]);

    const handlerMouseMove = useCallback((element) => {
        if (!isPeriodChoice || element?.movementX === 0) {
            return;
        }
        element.preventDefault();
        if (
            isLeftCircleDrag
        ) {
            const offset = leftCircleOffsetX + (element?.movementX ?? 0);
            if (
                leftCircleOffsetX === offset
                || offset > rightCircleOffsetX
                || (offset > max - sliderHalfWidth || offset < -sliderHalfWidth)
            ) {
                return;
            }
            // проверка выхода за минимально возможную дату периода
            if (offset < defaultStartDate) {
                return;
            }
            setLeftCircleOffsetWithCheck(offset);
            handlerOnStartDateMove(offset);
            return;
        }
        if (
            isRightCircleDrag
        ) {
            const offset = rightCircleOffsetX + (element?.movementX ?? 0);
            if (
                rightCircleOffsetX === offset
                || offset < leftCircleOffsetX
                || (offset > max - sliderHalfWidth || offset < -sliderHalfWidth)
            ) {
                return;
            }
            // проверка выхода за максимально возможную дату периода
            if (offset > maxEndOffset) {
                return;
            }
            setRightCircleOffsetX(offset);
            handlerOnEndDateMove(offset);
        }
        element.stopPropagation();
    }, [
        handlerOnStartDateMove,
        handlerOnEndDateMove,
        isLeftCircleDrag,
        leftCircleOffsetX,
        isRightCircleDrag,
        rightCircleOffsetX,
        setLeftCircleOffsetWithCheck,
    ]);
    const handlerEndDrag = useCallback((element) => {
        if (!isPeriodChoice) {
            return;
        }
        if (element && isLeftCircleMouseDown) {
            setIsLeftCircleMouseDown(false);
        }
        if (element && isRightCircleMouseDown) {
            setIsRightCircleMouseDown(false);
        }
        if (isLeftCircleDrag) {
            setIsLeftCircleDrag(false);
        }
        if (isRightCircleDrag) {
            setIsRightCircleDrag(false);
        }
    }, [
        isLeftCircleMouseDown,
        isLeftCircleDrag,
        setIsLeftCircleDrag,
        isRightCircleDrag,
        setIsRightCircleDrag,
        isRightCircleMouseDown,
        setIsRightCircleMouseDown,
        setIsLeftCircleMouseDown,
    ]);

    const handlerLeftCircleMouseEnter = (element: MouseEvent<HTMLDivElement>): void => {
        if (element && !isLeftCircleMouseOver) {
            setIsLeftCircleMouseOver(true);
        }
    };
    const handlerRightCircleMouseEnter = (element: MouseEvent<HTMLDivElement>): void => {
        if (element && !isRightCircleMouseOver) {
            setIsRightCircleMouseOver(true);
        }
    };
    const handlerLeftCircleMouseLeave = (element: MouseEvent<HTMLDivElement>): void => {
        if (element && isLeftCircleMouseOver) {
            setIsLeftCircleMouseOver(true);
        }
    };
    const handlerRightCircleMouseLeave = (element: MouseEvent<HTMLDivElement>): void => {
        if (element && isRightCircleMouseOver) {
            setIsRightCircleMouseOver(true);
        }
    };
    const handlerLeftCircleMouseDown = (element: MouseEvent<HTMLDivElement>): void => {
        if (element) {
            if (!isLeftCircleMouseDown) {
                setIsLeftCircleMouseDown(true);
                if (isLeftCircleMouseOver) {
                    setIsLeftCircleDrag(true);
                }
            }
        }
    };
    const handlerRightCircleMouseDown = (element: MouseEvent<HTMLDivElement>): void => {
        if (element) {
            if (!isRightCircleMouseDown) {
                setIsRightCircleMouseDown(true);
                if (isRightCircleMouseOver) {
                    setIsRightCircleDrag(true);
                }
            }
        }
    };
    useEffect(() => {
        if (defaultStartDate > start) {
            setLeftCircleOffsetWithCheck(calculateOffsetLeftCircle(defaultStartDate, end, max));
        } else {
            setLeftCircleOffsetWithCheck(calculateOffsetLeftCircle(start, end, max));
        }
        setRightCircleOffsetX(calculateOffsetRightCircle(start, end, max));
    }, [defaultStartDate, start, end, max]);
    useEffect(() => {
        if (!isPeriodChoice) {
            return;
        }
        window.addEventListener('mouseup', handlerEndDrag);
        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('mouseup', handlerEndDrag);
        };
    }, [handlerEndDrag, isPeriodChoice]);

    useEffect(() => {
        if (
            !isPeriodChoice
            || (!isLeftCircleMouseDown && !isRightCircleMouseDown)
        ) {
            return;
        }
        window.addEventListener('mousemove', handlerMouseMove);
        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('mousemove', handlerMouseMove);
        };
    }, [isLeftCircleMouseDown, isRightCircleMouseDown, handlerMouseMove, isPeriodChoice]);

    return (
        <div className={styles.lineSlider_root} style={{ width: max }}>
            <span className={styles.inactiveLine} style={{ width: max }} />
            {
                start < max && (<span className={styles.activeLine} style={{ left: start, width: end }} />)
            }
            {
                isPeriodChoice && (
                    <>
                        <div
                            className={styles.hint}
                            style={{
                                left: getHintOffsetX(leftCircleOffsetX),
                                top: getTopForLeftHint(leftCircleOffsetX, rightCircleOffsetX),
                            }}
                        >
                            <span className={styles.hint_text}>
                                {currentStartDate ?? '-'}
                            </span>
                            <span
                                className={
                                    `${
                                        isInvertedTriangle(leftCircleOffsetX, rightCircleOffsetX)
                                            ? styles.triangleInverted
                                            : styles.triangle
                                    }`
                                }
                                style={{
                                    left: getHitTriangleOffsetX(leftCircleOffsetX),
                                }}
                            />
                        </div>
                        <div
                            className={styles.hint}
                            style={{ left: getHintOffsetX(rightCircleOffsetX) }}
                        >
                            <span className={styles.hint_text}>
                                {currentEndDate ?? '-'}
                            </span>
                            <span
                                className={styles.triangle}
                                style={{
                                    left: getHitTriangleOffsetX(rightCircleOffsetX),
                                }}
                            />
                        </div>
                        <div
                            role="none"
                            className={styles.circle}
                            style={{ left: leftCircleOffsetX }}
                            onMouseEnter={handlerLeftCircleMouseEnter}
                            onMouseLeave={handlerLeftCircleMouseLeave}
                            onMouseDown={handlerLeftCircleMouseDown}
                        />
                        <div
                            className={styles.rectangle}
                            style={{
                                left: leftCircleOffsetX + 6,
                                width: widthRectangle,
                                height: heightRectangle,
                            }}
                        />
                        <div
                            role="none"
                            className={styles.circle}
                            style={{ left: rightCircleOffsetX }}
                            onMouseEnter={handlerRightCircleMouseEnter}
                            onMouseLeave={handlerRightCircleMouseLeave}
                            onMouseDown={handlerRightCircleMouseDown}
                        />
                    </>
                )
            }
        </div>
    );
};

export default LineSlider;
