import React, { useMemo, useState } from 'react';
import BlockLoadFile from '../../../entities/BlockLoadFile';
import CollapseContainer from '../CollapseContainer';
import DocumentList from '../DocumentList';
import styles from './styles.module.scss';
import useDocuments from '../hooks/useDocuments';

interface IPropsCurrentDocuments {
    projectId: number;
}

const ProjectDocuments: React.FC<IPropsCurrentDocuments> = ({
    projectId,
}): JSX.Element => {
    const [
        savedDocuments,
        {
            isInitialisationDocuments,
            statusNewDocument,
        },
        {
            handleRemoveFile,
            setStatusNewDocument,
            handleUploadFile,
            onChangeNameInList,
        },
    ] = useDocuments(false, projectId);
    const [openList, setOpenList] = useState(false);
    const handleChangeOpen = () => {
        setOpenList((prev) => !prev);
    };

    const isShowControlsCollapse = useMemo(() => savedDocuments?.length > 10, [savedDocuments]);

    return (
        <div className={styles.root_CurrentDocuments}>
            <div className={styles.title}>Документы</div>
            <CollapseContainer
                isOpen={openList}
                setOpen={handleChangeOpen}
                isOnlyCollapse={!isShowControlsCollapse}
                style={{
                    maxHeight: 122,
                }}
            >
                <div className={styles.content}>
                    <DocumentList
                        isLoading={isInitialisationDocuments}
                        isLoadFile={statusNewDocument === 'loading'}
                        documentList={savedDocuments}
                        onRemoveFile={handleRemoveFile}
                        onChangeNameInList={onChangeNameInList}
                    />
                    <BlockLoadFile
                        onChangeStatus={(newStatus) => setStatusNewDocument(newStatus)}
                        status={statusNewDocument}
                        onUploadFile={handleUploadFile}
                    />
                </div>
            </CollapseContainer>
        </div>
    );
};

export default ProjectDocuments;
