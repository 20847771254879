export enum ProjectContactTypes {
    email = 1,
    phoneNumber = 2,
    customerNumber = 3,
}

export enum ProjectStageTypes {
    brief = 1,
    longList = 2,
    mediaPlanStage = 3,
    monitoring = 4,
    resultsStage = 5,
}

export enum ProjectStatusTypes {
    takeToWork = 1,
    inProgress = 2,
    mediaPlan = 3,
    inWork = 4,
    results = 5,
    completed = 6,
    cancelled = 7,
}

export interface IProjectEnums {
    contactTypeEnum: ProjectContactTypes,
}

export interface IProjectStatus {
    stage: ProjectStageTypes;
    stageLabel: string;
    status: ProjectStatusTypes;
    statusLabel: string;
}

export interface IProjectHistoryModel {
    id: number;
    model: string;
    rawOldData: any;
    rawNewData: any;
    dataChanges: {
        field: string;
        newValue: string;
        oldValue: string;
    }[] | null;
    action: string;
    created: string;
}

export interface IProjectHistoryTableModel {
    id: number;
    field: string;
    date: string;
    oldValue: string;
    newValue: string;
    link?: string;
    isGrid?: boolean;
    oldGridRows?: string[];
    newGridRows?: string[];
}
