import { IMessage } from 'store/types';
import { FiltersValuesTypes } from 'store/infoTrace';

export enum AnalyzeLinkStatus {
    undefined = 0,
    process = 1,
    success = 2,
    checkAnalyze = 3,
    analyzeStop = 4,
    failed = 5,
}

export interface IAnalyzeFetchOutputData {
    link?: string;
    text?: string;
    idempotencyKey: string;
    filter: number;
}

export interface IAnalyzeMlResult {
    analytics: any;
    quotations: any;
    authors: any;
}

export interface IAnalyzeResult {
    title: any;
    link: any;
    source: string;
    postedTimestamp: string;
    newsId: string;
    content: string;
    logo: any;
    mlResult: IAnalyzeMlResult
    taskId: string;
    activePeriod: number;
}

export interface IAnalyzeLinkState {
    outputFetchData: IAnalyzeFetchOutputData;
    outputTextFetchData: IAnalyzeFetchOutputData;
    status: AnalyzeLinkStatus;
    mainPostStatus: AnalyzeLinkStatus;
    analyzeResult: IAnalyzeResult;
    message?: IMessage;
    textFieldMessage?: IMessage;
    taskId: string;
    link: string;
    text: string;
    activeFilter: FiltersValuesTypes;
    needSearchForTextAfterChangeFilter: boolean;
    needSearchForLinkAfterChangeFilter: boolean;
}
