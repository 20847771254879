import React from 'react';
import { IRequestAccessInputProps } from './types';

import styles from './styles.module.scss';

// const regexPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$/g;
// const phoneErrMsg = 'Поле телефон имеет неверный формат.';
// const emailErrMsg = 'Поле email имеет неверный формат.';

const RequestAccessInput: React.FC<IRequestAccessInputProps> = (
    {
        value, placeholder, setValue, tagId,
    },
):JSX.Element => {
    // const [error, setError] = useState<string>('');
    const handlerChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const result = event.target.value.substring(0, 100);
        // if (result.length > 0) {
        //     if (type === 'email') {
        //         if (!result.includes('@') && !error) {
        //             setValidationError(emailErrMsg);
        //             setError(emailErrMsg);
        //         } else if (result.includes('@') && error) {
        //             setError('');
        //             setValidationError('');
        //         }
        //     } else if (type === 'phone') {
        //         if (!regexPhone.test(result) && !error) {
        //             setValidationError(phoneErrMsg);
        //             setError(phoneErrMsg);
        //         } else if (regexPhone.test(result) && error.length > 0) {
        //             setError('');
        //             setValidationError('');
        //         }
        //     }
        // } else {
        //     setError('');
        //     setValidationError('');
        // }

        setValue(result);
    };

    return (
        <div className={styles.container}>
            <input
                id={tagId}
                className={styles.inputField}
                placeholder={placeholder}
                value={value}
                onChange={handlerChangeValue}
            />
        </div>
    );
};

export default RequestAccessInput;
