import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ITrackedWordModel } from '@components/campaign/UpdateForm/TrackedWords/types';
import { IFavoriteSourceModel } from '@services/campaigns/favorite-sources/types';
import Path from '@shared/lib/paths';
import { categoriesRtkQService } from '@services/categories';
import { singleCampaignRtkQService } from '@services/campaigns/single-campaign';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { selectCommonWindowIsIPadSize } from '@store/common/window/selectors';
import { messageModalActions } from '@store/common/message-modal';
import { useAppDispatch } from '@store/hooks';
import { FetchStatus } from '@store/types';
import campaignSelectors from '@store/campaign/source/selectors';
import trackedSourcesSelectors from '@store/campaign/tracked-sources/selectors';
import favoriteSourcesSelectors from '@store/campaign/favorite-sources/selectors';
import { campaignActions } from '@store/campaign/source';
import {
    CampaignActionTypes,
    CampaignActiveModalType,
    ModalAnswerType,
} from '@store/campaign/source/types';
import CampaignUpdateForm from '@components/campaign/UpdateForm';
import { campaignTrackedSourcesActions } from '@store/campaign/tracked-sources';
import { ITrackedSourceModel } from '@store/campaign/tracked-sources/types';
import SearchRequestPageModal from '@components/campaign/SearchRequest/Modal/SearchPage';
import { ISearchGroup } from '@components/campaign/SearchRequest/types';
import searchRequestHelpers from '@components/campaign/SearchRequest/helpers';
import campaignUtils from '../../../utils/campaign';

const initialCheckedSourceTypeIds = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const initialCheckedBloggerIds = [1, 2, 3];

const getCategoriesIds = (ids: number[]): number[] => {
    if (Array.isArray(ids) && ids.every((id) => id === 0)) {
        return [];
    }
    return ids;
};

const CampaignUpdateContainer: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const { pathname: locationPathname } = useLocation();
    const { Id: urlParametersId } = useParams();

    const dispatch = useAppDispatch();
    const needClear = useSelector(campaignSelectors.needClearCampaignPage);
    const campaignModalAnswer = useSelector(campaignSelectors.modalAnswer);
    const campaignActiveModal = useSelector(campaignSelectors.activeModal);
    const createStatus = useSelector(campaignSelectors.createStatus);
    const updateStatus = useSelector(campaignSelectors.updateCampaignStatus);
    const sources = useSelector(campaignSelectors.sources);
    const excludeSources = useSelector(campaignSelectors.excludeSources);
    const actionCampaign = useSelector(campaignSelectors.actionCampaign);
    const selectedTrackedSource = useSelector(trackedSourcesSelectors.tracked);
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);
    const excludedSources = useSelector(trackedSourcesSelectors.excluded);
    const favoriteSourcesData = useSelector(favoriteSourcesSelectors.favoriteSources);

    const [
        getCampaign,
        {
            data: campaign,
            isLoading: isLoadingCampaignRtkQ,
        },
    ] = singleCampaignRtkQService.useLazyGetCampaignQuery();
    const [updateCampaign] = singleCampaignRtkQService.usePatchUpdateCampaignMutation();
    // const { data: users } = campaignUsersRtkQService.useGetSingleCampaignUsersQuery({ id: Number(urlParametersId) });
    const [
        getCategories,
        { data: categories, isLoading: isLoadingCategories },
    ] = categoriesRtkQService.useLazyGetCategoriesQuery();

    const [checkedSourceTypes, setCheckedSourceTypes] = useState<number[]>([]);
    const [checkedBloggers, setCheckedBloggers] = useState<number[]>([]);
    const [campaignName, setCampaignName] = useState<string>('');
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [selectedTrackedWords, setSelectedTrackedWords] = useState<string[]>([]);
    const [selectedExcludedWords, setSelectedExcludedWords] = useState<string[]>([]);
    const [selectedExcludedLinks, setSelectedExcludedLinks] = useState<string[]>([]);
    const [campaignId, setCampaignId] = useState<number>(null);
    const [campaignCreateBegun, setCreateBegun] = useState<boolean>(false);
    const [dataForUpdate, setDataForUpdate] = useState<ICampaignDataModel | null>(null);

    const [isOpenSearchRequestModal, setOpenStateSearchRequestModal] = useState<boolean>(false);
    const [searchRequestObj, setSearchRequestObj] = useState<ISearchGroup | null>(null);
    const [searchRequestStr, setSearchRequestStr] = useState<string>('');
    const [selectedFavoriteSources, setFavoriteSources] = useState<IFavoriteSourceModel[]>([]);

    const isSearchRequest = useMemo<boolean>(() => (campaign?.isComplexQuery) ?? false, [campaign]);
    const isEditCampaign = useMemo<boolean>(() => (
        actionCampaign === CampaignActionTypes.edit && locationPathname.includes(Path.CampaignUpdate)
    ), [actionCampaign, locationPathname]);
    const isViewCampaign = useMemo<boolean>(() => (
        (actionCampaign === CampaignActionTypes.view || Boolean(urlParametersId))
        && actionCampaign !== CampaignActionTypes.edit && !locationPathname.includes(Path.CampaignCreate)
    ), [actionCampaign, urlParametersId]);
    const isExcludedLinks = useMemo<boolean>(() => (!(sources?.length) && excludeSources?.length > 0), [sources, excludeSources]);
    const bufferSelectedTrackedSource = useMemo<ITrackedSourceModel[]>(() => (
        (sources?.length > 0 ? sources : excludeSources) ?? []
    ), [sources, excludeSources]);

    const finishUpdate = useCallback((): void => {
        if (campaignCreateBegun) {
            setCreateBegun(false);
        }
    }, [dispatch, campaignCreateBegun]);
    const handlerCreateBegun = (): void => {
        dispatch(campaignActions.setCreateStatus(true));
        if (!campaignCreateBegun) {
            setCreateBegun(true);
        }
    };
    const handlerDataCleared = (): void => {
        dispatch(campaignActions.setClearDataCampaign(false));
        dispatch(campaignActions.setCreateStatus(false));
    };
    const handlerCancelClick = (): void => {
        dispatch(campaignActions.setActiveModal(CampaignActiveModalType.cancelCreation));
        finishUpdate();
    };
    const handlerUpdateClick = useCallback((data: ICampaignDataModel): void => {
        if (!isEditCampaign) {
            return;
        }
        setDataForUpdate(data);
    }, [isEditCampaign, dispatch]);
    const handlerDeleteTrackedSource = (isTracked: boolean, id: number): void => {
        dispatch(campaignTrackedSourcesActions.removeSelectedSource({ isTracked, id }));
    };
    const handlerClearAllSelectedSources = (): void => {
        dispatch(campaignTrackedSourcesActions.clearSelected());
    };
    const handlerCreateSearchRequest = (): void => {
        setOpenStateSearchRequestModal(true);
    };
    const handlerCloseSearchRequestModal = (): void => {
        setOpenStateSearchRequestModal(false);
    };
    const handlerSaveSearchRequestModal = (searchString: string): void => {
        setSearchRequestStr(searchString);
        setOpenStateSearchRequestModal(false);
        const preparedStr = searchRequestHelpers.replaceRepetitionsCondition(searchString);
        const groups = searchRequestHelpers.getGroups(preparedStr, 0);
        const nested = searchRequestHelpers.parseSearchRequest(groups);
        setSearchRequestObj(nested);
    };
    const handlerSetFavoriteSources = (value: ITrackedWordModel[]): void => {
        const selectedIds = value.map(({ id }) => id);
        const result = favoriteSourcesData?.data?.filter((item) => selectedIds.includes(item.id));
        setFavoriteSources([...result]);
    };

    useEffect(() => {
        // dispatch(loadCategoriesIfNotExists());
        // dispatch(campaignActions.setCreateStatus(false));
        getCategories();
    }, []);
    useEffect(() => {
        const urlId = Number(urlParametersId);
        if (Boolean(urlId) && campaign?.id !== urlId) {
            getCampaign({ id: urlId });
        }
    }, [campaign, urlParametersId]);
    useEffect(() => {
        if (campaignActiveModal === CampaignActiveModalType.cancelCreation) {
            if (campaignModalAnswer === ModalAnswerType.accept) {
                dispatch(campaignActions.setClearDataCampaign(true));
            }
            // если отмена - то задаём пустое активное окно
            if (campaignModalAnswer !== ModalAnswerType.undefined) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
                if (isEditCampaign) {
                    dispatch(campaignActions.setViewActionCampaign());
                }
            }
        }
    }, [campaignModalAnswer, dispatch]);
    useEffect(() => {
        if (createStatus === FetchStatus.success) {
            dispatch(campaignActions.setInitialCreatingStatus());
            dispatch(messageModalActions.setMessage(
                (
                    <>
                        <span>
                            Вы только что создали Кампанию
                            {` ${campaign?.name ?? '-'}`}
                            .
                        </span>
                        <span>Для просмотра результатов отслеживания</span>
                        <span>перейдите во вкладку Отслеживание</span>
                    </>
                ),
            ));
            dispatch(messageModalActions.setButtonTagId('button-campaign-create-ok'));
            dispatch(messageModalActions.setButtonText('Ok'));

            const id = Number(urlParametersId);
            if (campaign?.id !== id || !urlParametersId) {
                dispatch(campaignActions.setCreateStatus(false));
                dispatch(campaignActions.setViewActionCampaign());
                navigate(`/${Path.Campaign}/${Path.CampaignUpdate}/${campaign?.id}`);
            }
        }
    }, [createStatus, campaign, dispatch]);
    useEffect(() => {
        const view = isViewCampaign || isEditCampaign;
        if (view) {
            let res = campaign?.isBlogger === null ? initialCheckedBloggerIds : [];
            if (campaign?.isBlogger === true || campaign?.isBlogger === false) {
                res = campaign.isBlogger ? [3] : [2];
            }
            setCheckedBloggers(res);
        } else {
            setCheckedBloggers([]);
        }

        let bufferTypes = campaignUtils.getCampaignPlatformByNames(campaign?.sourceType?.map((name) => name));
        bufferTypes = bufferTypes.some((id) => id === 1) ? initialCheckedSourceTypeIds : bufferTypes;
        setCheckedSourceTypes(view ? bufferTypes : []);

        setCampaignName(view ? (campaign?.name ?? '') : '');
        setSelectedCategories(view ? (getCategoriesIds(campaign?.categoriesIds) ?? []) : []);
        setSelectedTrackedWords(view ? (campaign?.includeWords ?? []) : []);
        setSelectedExcludedWords(view ? (campaign?.excludeWords ?? []) : []);
        setSelectedExcludedLinks(view ? (campaign?.excludeLinks ?? []) : []);
        setCampaignId(view ? (campaign?.id ?? null) : null);
        setSearchRequestStr(campaign?.searchQuery ?? '');
        if (campaign?.searchQuery) {
            const preparedStr = searchRequestHelpers.replaceRepetitionsCondition(campaign?.searchQuery);
            const buffer = searchRequestHelpers.getGroups(preparedStr, 0);
            setSearchRequestObj(
                searchRequestHelpers.parseSearchRequest(
                    buffer,
                ),
            );
        }
    }, [isEditCampaign, isViewCampaign, campaign]);
    useEffect(() => {
        if (!urlParametersId) {
            dispatch(campaignTrackedSourcesActions.clearSelected());
            return;
        }
        const id = Number(urlParametersId);
        if (campaign?.id !== id) {
            dispatch(campaignTrackedSourcesActions.clearSelected());
        }
    }, [urlParametersId, campaign, dispatch]);
    useEffect(() => {
        if (dataForUpdate) {
            dispatch(campaignActions.setUpdateCampaignStatus());
            dispatch(campaignActions.setViewActionCampaign());
            const sourcesBuffer = [
                ...selectedFavoriteSources.map((item) => ({
                    id: item?.source?.id,
                    allowInRussia: item?.source?.allow_in_russia,
                    authorId: item?.source?.author_id,
                    created: item?.source?.created,
                    autoDiscover: item?.source?.auto_discover,
                    enabled: item?.source?.enabled,
                    link: item?.source?.link,
                    outSourceId: item?.source?.out_source_id,
                    parsed: item?.source?.parsed,
                    tag: item?.source?.tag,
                    locationId: item?.source?.location_id,
                    title: item?.source?.title,
                    parseLink: item?.source?.parse_link,
                    typeId: item?.source?.type_id,
                } as ITrackedSourceModel)),
                ...(dataForUpdate.isTrackedLinks ? selectedTrackedSource : excludedSources),
            ];
            updateCampaign({
                id: dataForUpdate.id,
                name: dataForUpdate.name,
                isTrackedLinks: dataForUpdate.isTrackedLinks,
                categories: dataForUpdate.categoriesIds,
                parseFrom: dataForUpdate.parseFrom,
                sourceType: dataForUpdate.sourceType,
                includeWords: dataForUpdate.includeWords,
                excludeWords: dataForUpdate.excludeWords,
                isBlogger: dataForUpdate.isBlogger,
                withoutPopup: true,
                searchQuery: !searchRequestStr ? null : searchRequestStr,
                isComplexQuery: isSearchRequest,
                sources: sourcesBuffer,
            });
            finishUpdate();
            dispatch(campaignActions.setCreateStatus(false));
            setDataForUpdate(null);
        }
    }, [dataForUpdate]);

    return (
        <>
            <CampaignUpdateForm
                campaignId={campaignId}
                name={campaignName}
                categories={categories}
                onCreateBegun={handlerCreateBegun}
                isCreating={campaignCreateBegun}
                needClear={needClear}
                onCleared={handlerDataCleared}
                onCancelClick={handlerCancelClick}
                onUpdateClick={handlerUpdateClick}
                isUpdate={isEditCampaign}
                isView={isViewCampaign}
                isActive={campaign?.isActive}
                isLoading={
                    updateStatus === FetchStatus.process
                    || updateStatus === FetchStatus.update
                    || isLoadingCampaignRtkQ
                }
                checkedSourceTypes={checkedSourceTypes}
                checkedBloggers={checkedBloggers}
                selectedCategories={selectedCategories}
                selectedTrackedWords={selectedTrackedWords}
                selectedExcludedWords={selectedExcludedWords}
                selectedExcludedLinks={selectedExcludedLinks}
                onDeleteTrackedSource={handlerDeleteTrackedSource}
                selectedTrackedSource={selectedTrackedSource}
                onClearAllSelectedSources={handlerClearAllSelectedSources}
                bufferSelectedTrackedSource={bufferSelectedTrackedSource}
                isExcludedLinks={isExcludedLinks}
                isIPadSize={isIPadSize}
                parseFrom={campaign?.parseFrom}
                onCreateSearchRequest={handlerCreateSearchRequest}
                searchRequestStr={searchRequestStr}
                isSearchRequest={isSearchRequest}
                onSelectedFavoriteSources={handlerSetFavoriteSources}
                isLoadingCategories={isLoadingCategories}
            />
            <SearchRequestPageModal
                searchRequest={searchRequestObj}
                isOpen={isOpenSearchRequestModal}
                isView={isViewCampaign}
                onClose={handlerCloseSearchRequestModal}
                onSave={handlerSaveSearchRequestModal}
            />
        </>
    );
};

export default CampaignUpdateContainer;
