import request from '../requestWrapper';

export const getCategories = async () => request({
    method: 'GET',
    url: 'category',

}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

export const getLocations = async () => request({
    method: 'GET',
    url: 'location/',

}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

export const getBloggersAndMedia = async () => request({
    method: 'GET',
    url: 'category/category_by_sources',

}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});
