// @ts-nocheck

import classnames from 'classnames';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ICampaignReportsListItem } from '../../../../containers/campaign/Reports/types';
import CampaignReportsListItem from './ReportItem/index';

import styles from './styles.module.scss';
import CampaignUploadReport from './Upload';

interface ICampaignReportsListProps {
    reports: ICampaignReportsListItem[];
    isLoading: boolean;
    onDeleteReport: (id: string) => void;
    onDownloadReport: (id: string) => void;
    isIPadSize: boolean;
    campaignId: string
}

const CampaignReportsList: React.FC<ICampaignReportsListProps> = ({
    reports, isLoading,
    onDeleteReport, onDownloadReport, isIPadSize, campaignId,
}): JSX.Element => {
    const handlerDeleteAllReports = (): void => {
        // eslint-disable-next-line no-console
        // удалаем все
    };
    const handlerDeleteReport = (reportId: string): void => {
        onDeleteReport(reportId);
    };
    if (!reports?.length && !isLoading) {
        return null;
    }
    return (
        <div className={styles.campaignReportsList_root}>
            <div className={classnames(styles.flexColumn, styles.gap16)}>
                <div className={classnames(styles.flexRow)}>
                    <div className={styles.header}>
                        Отчеты
                    </div>
                    <CampaignUploadReport campaignId={campaignId} />
                    <button
                        type="button"
                        className={classnames(styles.delAllItems)}
                        onClick={handlerDeleteAllReports}
                    >
                        Удалить все отчеты
                    </button>
                </div>
                <div className={styles.headerHl} />
            </div>
            {
                isLoading && <Skeleton count={8} height={25} width={isIPadSize ? 400 : 540} />
            }
            {
                !isLoading && reports?.map((item) => (
                    <CampaignReportsListItem
                        key={item.id}
                        data={item}
                        onDelete={handlerDeleteReport}
                        onDownload={onDownloadReport}
                        isIPadSize={isIPadSize}
                    />
                ))
            }
        </div>
    );
};

export default CampaignReportsList;
