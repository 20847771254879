import React from 'react';
import { IconButtonLoadFile, Wait } from '@shared/index';
import styles from './styles.module.scss';

const LoadingFile: React.FC = (): JSX.Element => (
    <div className={styles.blockLoadFileLoading_root}>
        <div className={styles.btnWithLabel}>
            <span className={styles.labelLoading}>
                Загрузка
                <Wait />
            </span>
            <IconButtonLoadFile iconId="RoundAdd_Icn_NoneFill" isLoading />
        </div>
    </div>
);

export default LoadingFile;
