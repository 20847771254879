import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestAccess, IRequestAccessState } from './types';
import { EmailSendStatus } from '../types';

const initialState: IRequestAccessState = {
    status: EmailSendStatus.undefined,
    data: {
        userPost: '',
        userName: '',
        comment: '',
        email: '',
        organizationName: '',
        phone: '',
    },
};

const { actions: requestAccessActions, reducer } = createSlice({
    name: 'requestAccess',
    initialState,
    reducers: {
        setRequestData: (state: IRequestAccessState, { payload }: PayloadAction<IRequestAccess>): void => {
            if (!payload) {
                return;
            }
            state.data = { ...payload };
        },
        sending: (
            state: IRequestAccessState,
        ) => {
            state.status = EmailSendStatus.sending;
        },
        sendFailed: (
            state: IRequestAccessState,
        ) => {
            state.status = EmailSendStatus.failed;
        },
        sendComplete: (
            state: IRequestAccessState,
        ) => {
            state.status = EmailSendStatus.sent;
        },
    },
});

export { requestAccessActions };
export default reducer;
