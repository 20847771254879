import React, {
    Dispatch, SetStateAction, useRef, useState,
} from 'react';
import SvgIcon from '@components/SvgIcon';
import { DateRangePickerValue } from '@components/DateRangePicker/types';
import DatePicker from '@components/DatePicker';
import InputsSlider from '@shared/ui-components/InputsSlider';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import styles from './styles.module.scss';

interface IProps {
    date: DateRangePickerValue;
    setDate: Dispatch<SetStateAction<DateRangePickerValue>>;
    viewsFilter: [number, number] | null;
    onChangeViewsFilter: (filterValue: [number, number]) => void;
    rangeMin: number;
    rangeMax: number;
}

const PostsQuantityFilter: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const {
        date,
        setDate,
        viewsFilter,
        onChangeViewsFilter,
        rangeMin,
        rangeMax,
    } = props;
    const wrapperRef = useRef(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    useOnClickOutside(wrapperRef, () => setOpen(false));

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            <button
                className={styles.button}
                type="button"
                onClick={() => setOpen((prev) => !prev)}
            >
                <span>Упорядочить</span>
                <SvgIcon id="dropdownArrow" size={12} />
            </button>

            {isOpen && (
                <div className={styles.modal}>
                    <span>По дате</span>
                    <div className={styles.datePicker}>
                        <DatePicker value={date} onChange={setDate} />
                    </div>
                    <span>По количеству просмотров</span>
                    <InputsSlider
                        defaultValue={[rangeMin, rangeMax]}
                        value={viewsFilter}
                        onChange={onChangeViewsFilter}
                        min={rangeMin}
                        max={rangeMax}
                    />
                </div>
            )}
        </div>
    );
};
export default PostsQuantityFilter;
