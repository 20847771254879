import React, { useRef } from 'react';
import IconComplete from '@assets/images/complete.svg';
import IconCrossModalRed from '@assets/images/cross_modal_red.svg';
import Loader from '@components/Loader';
import SvgIcon from '@components/SvgIcon';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { CommonProcessStatus } from '@store/types';
import { Button } from '@shared/index';
import styles from './styles.module.scss';

interface ICampaignUserDeleteModalProps {
    messages: string[];
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    status: CommonProcessStatus;
}

const CampaignUserDeleteModal: React.FC<ICampaignUserDeleteModalProps> = ({
    messages,
    isOpen,
    onClose,
    onConfirm,
    status,
}): JSX.Element => {
    const handlerOkClick = (): void => {
        onConfirm();
    };
    const handlerCancelClick = (): void => {
        onClose();
    };

    const refSuccessModal = useRef();

    useOnClickOutside(refSuccessModal, () => {
        if (status === CommonProcessStatus.success) {
            onClose();
        }
    });

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modalWindow}>
                {status === CommonProcessStatus.undefined && (
                    <div className={styles.content}>
                        <div className={styles.contentText}>
                            {messages?.map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <span key={index}>{item}</span>
                            ))}
                        </div>
                        <div className={styles.contentControlBlock}>
                            <Button
                                text="Да"
                                type="default"
                                size="large"
                                onClick={handlerOkClick}
                            />
                            {/* <ButtonPrimary */}
                            {/*    text="Да" */}
                            {/*    disabled={false} */}
                            {/*    onClick={handlerOkClick} */}
                            {/*    style={{ width: 60, height: 40 }} */}
                            {/* /> */}
                            <Button
                                text="Отмена"
                                type="secondary"
                                size="large"
                                onClick={handlerCancelClick}
                            />
                            {/* <ButtonSecondary */}
                            {/*    text="Отмена" */}
                            {/*    disabled={false} */}
                            {/*    onClick={handlerCancelClick} */}
                            {/*    style={{ width: 80, height: 40 }} */}
                            {/* /> */}
                        </div>
                    </div>
                )}

                {status === CommonProcessStatus.failed && (
                    <div className={styles.errorContainer}>
                        <p>
                            Что-то пошло не так!
                            <br />
                            Пожалуйста, повторите попытку чуть позже
                        </p>
                        <button type="button" onClick={handlerCancelClick}>
                            <img src={IconCrossModalRed} alt="" />
                        </button>
                    </div>
                )}

                {status === CommonProcessStatus.process && (
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                )}

                {status === CommonProcessStatus.success && (
                    <div
                        className={styles.success}
                        ref={refSuccessModal}
                    >
                        <p>Успешно отправлено</p>
                        <img src={IconComplete} alt="" />

                        <button type="button" onClick={handlerCancelClick}>
                            <SvgIcon id="BigCross" size={14} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CampaignUserDeleteModal;
