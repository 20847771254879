/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment';
import QuickSVGLoader from '../../QuickSVGLoader';
import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoaderForChart: React.FC<any> = ({ data }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        data.update('setter', setShow);
    }, [show]);
    if (!show) return <div />;
    return (

        <div style={{
            position: 'absolute',
            zIndex: 10,
            width: '100%',
            height: '100%',
            background: 'rgba(0,0,0,0.05)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '6px',
        }}
        >
            <QuickSVGLoader />
        </div>
    );
};

export default LoaderForChart;
