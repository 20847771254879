import { IQPDashboard } from 'services/dashboard/types/queryParams';

export enum DnDCardTypes {
    tonality = 0,
    likes = 1,
    authorsQuantity = 2,
    postsQuantity = 3,
    tagsQuantity = 4,
    campaignSelection = 5,
    regions = 6,
    parametersChart = 7,
    mapChart = 8,
    platformChart = 9,
    lastPosts = 10,
}

export interface IDashboardCard {
    id: number;
    index: number;
    type: DnDCardTypes;
}

export interface IDashboardFromApi {
    id: number;
    params: IQPDashboard;
    user_id: string;
}
