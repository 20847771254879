import {
    AnyAction, configureStore, ThunkAction, ThunkDispatch,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import sourceReducers from '@store/source';
import { rtkQApi } from '../services';
import searchResultReducer from './info-trace/search';
import infoBasketReducers from './info-basket';
import infoTraceReducers from './info-trace';
import historyReducers from './history';
import commonReducers from './common';
import historyTableFiltersReducer from './history-table';
import newsAnalysisReducers from './news-analysis';
import { IS_DEV } from '../utils/api';
import requestAccessReducer from './request-access';
import userProfileReducer from './user-profile';
import authReducer from './auth';
import campaignReducers from './campaign/index';
import pdfHandlerReducer from './pdf-handler/index';

const rootReducer = combineReducers({
    infoBasket: infoBasketReducers(),
    infoTrace: infoTraceReducers(),
    history: historyReducers(),
    common: commonReducers(),
    newsAnalysis: newsAnalysisReducers(),
    searchResult: searchResultReducer,
    historyTableFilters: historyTableFiltersReducer,
    requestAccess: requestAccessReducer,
    userProfile: userProfileReducer,
    auth: authReducer,
    campaign: campaignReducers(),
    pdfHandler: pdfHandlerReducer(),
    sources: sourceReducers(),
    [rtkQApi.reducerPath]: rtkQApi.reducer,
});

const middlewareHandler = (getDefaultMiddleware: any) => {
    const middlewareList = [
        ...getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat([
                rtkQApi.middleware,
            ]),
    ];
    // middlewareList.push(rtkQueryErrorLogger);
    return middlewareList;
};

export const store = configureStore({
    reducer: rootReducer,
    devTools: IS_DEV,
    middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
});

// type StoreType = ReturnType<typeof makeStore>;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<T = void> = ThunkAction<T, AppState, unknown, AnyAction>;
export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>;

// export const reduxWrapper = createWrapper<StoreType>(makeStore, { debug: isDev });
