import { env } from '../env';

const devUrl = process.env.REACT_APP_BACKEND_URL ?? 'https://client.test.resheniy.ru';
const prodUrl = env.REACT_APP_BACKEND_URL;
const devApiUrl = `${devUrl}/client-api/api/v2`;
const prodApiUrl = `${prodUrl}/api/v2`;
process.env.GENERATE_SOURCEMAP = false;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const CLIENT_API_URL = IS_DEV ? devApiUrl : prodApiUrl;
export const CURRENT_STAND = env.REACT_APP_CURRENT_STAND?.toLowerCase() ?? 'development';
export const CURRENT_STAND_URL = IS_DEV ? devUrl : prodUrl;
export const NOTIFICATION_TG_URL = env.REACT_APP_NOTIFICATION_TG_URL ?? 'https://t.me/test_ntfy_bot';