/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/naming-convention */

import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { Checkbox } from 'antd';
import { useParams } from 'react-router';
import moment from 'moment';
import QuickSVGLoader from '../QuickSVGLoader';
import { chartsCampaingRtqService } from '../../services/campaigns/Charts';
import { IChart } from '../../containers/CampaignNewCharts/ChartTypes';
import SvgIcon from '../SvgIcon';
import styles from './styles.module.scss';
import NewChart from '../NewChart';
// imx
import useChartSettings from './useChartSettings';

interface IChartItemProps {
    chart: IChart;
    removeChart: (id, isSaved) => void;
    saveChart: (id, data: IChart) => void;
    selectChart: (id) => void;
    setPrevSettings: (chart: IChart) => void;
    onUpdateChart: (field, value) => void,
}
const ChartItem: React.FC<IChartItemProps> = ({
    chart, removeChart, saveChart, selectChart, setPrevSettings, onUpdateChart,
}): JSX.Element => {
    const [{
        chartSettings,
        oldData,
        showError,
        loader,
        isOpen,
        name,
    }, {
        setIsOpen,
        setName,
        setChartSettings,
        getDataForChart,
        setShowError,
        setLoader,
    }] = useChartSettings({ chart, onUpdateChart });
    const { Id: campaingId } = useParams();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const addToMainPage = () => {};
    const [createNewChart] = chartsCampaingRtqService.useSaveChartMutation();
    const saveNewChart = () => {
        if (chart?.id) return;
        createNewChart({
            campaingId: +campaingId,
            body: {
                name,
                time_frame: chart?.time_frame,
                chart_type: chart?.chart_type,
                from_date: `${chart?.from_date}`,
                to_date: `${chart?.to_date}`,
                metric: chart?.metrics,
                dimensions: chart?.dimensions,
                filters_include: chart?.filters_include,
                filters_exclude: [],
            },
        }).then((res) => {
            if ((res as any)?.data) {
                saveChart(
                    chart?.intermediumId,
                    (res as any)?.data as IChart,
                );
            }
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getNextChartData = useCallback((maxDate: number) => {
        const newToDate = moment(maxDate).add(12, 'd').format('YYYY-MM-DD');
        const formChart = {
            name: 'Новый график',
            time_frame: chart.time_frame,
            chart_type: chart.chart_type,
            campaign_id: chart.campaign_id,
            from_date: chart.to_date,
            to_date: newToDate,
            metrics: [
                chart?.metrics,
            ],
            dimensions: chart?.dimensions || [],
            filters_include: chart.filters_include || [],
        };
        // return formChart;
        return { data: (formChart as any) };
    }, [chartSettings]);
    const getPrevChartData = useCallback((minDate: number) => {
        const newFromDate = moment(minDate).subtract(12, 'd').format('YYYY-MM-DD');
        const formChart = {
            name: 'Новый график',
            time_frame: chart.time_frame,
            chart_type: chart.chart_type,
            campaign_id: chart.campaign_id,
            from_date: newFromDate,
            to_date: chart.from_date,
            metrics: [
                chart?.metrics,
            ],
            dimensions: chart?.dimensions || [],
            filters_include: chart.filters_include || [],
        };
        return { data: (formChart as any) };
    }, [chartSettings]);
    const classesForRootHidden = useMemo(() => {
        if (chart?.isSelect) return `${styles.rootHiddenChartItem} ${styles.rootSelect}`;
        return styles.rootHiddenChartItem;
    }, [chart?.isSelect]);
    const classesForRootFull = useMemo(() => {
        if (chart?.isSelect) return `${styles.rootChartItem} ${styles.rootSelect}`;
        return styles.rootChartItem;
    }, [chart?.isSelect]);
    const clickOnHidden = () => {
        setIsOpen((prev) => !prev);
        selectChart(chart?.id || chart.intermediumId);
    };
    const getStatusCheckBox = (item) => {
        const currentId = item?.dataset?.id;
        const currentDataset = chartSettings?.data?.datasets?.find((elem) => elem?.id === currentId);
        if (currentDataset) return !currentDataset?.hidden;
        return false;
    };
    const handleChangeShowDataset = (content) => {
        setChartSettings((prev) => {
            const newData = prev?.data?.datasets?.map((dataset) => {
                if (dataset.id === content?.dataset?.id) {
                    const newDataset = { ...dataset };
                    newDataset.hidden = !newDataset?.hidden;
                    return newDataset;
                }
                return dataset;
            });
            return {
                ...prev,
                data: { datasets: newData },
            };
        });
    };
    const getLabel = (labels) => {
        if (!labels || !labels?.length) return 'не определено';

        return (
            <div>
                {labels.map((item) => (
                    <div key={item}>
                        {item}
                    </div>
                ))}
            </div>
        );
    };
    const legends = (
        <div className={styles.listCheckBoxes}>
            {
                chartSettings?.listCheckBoxes?.length > 1
        && chartSettings?.listCheckBoxes.map((item) => (
            <div className={styles.checkBoxItem} key={item?.dataset?.label}>
                <div style={{
                    minWidth: 32,
                    height: 16,
                    borderRadius: 4,
                    border: `1px solid ${item.dataset?.allColors?.color}`,
                    backgroundColor: item.dataset?.allColors?.alphaColor,
                    marginRight: 6,
                }}
                />
                <Checkbox checked={getStatusCheckBox(item)} onChange={() => handleChangeShowDataset(item)} />
                <div className={styles.label}>{getLabel(item?.labels)}</div>
            </div>
        ))
            }
        </div>
    );
    if (!isOpen) {
        return (
            <div className={classesForRootHidden} onClick={clickOnHidden}>
                <div className={`${styles.left} ${styles.defaultContainer}`}>
                    <div className={styles.chartDate}>{moment(chart?.created).format('DD.MM.YYYY')}</div>
                    <div className={styles.chartTitle}>{chart?.name}</div>
                </div>
                <div className={`${styles.right} ${styles.defaultContainer}`}>
                    <div>
                        <SvgIcon id="AddStart" />
                    </div>
                    <div style={{ fill: '#787E93' }}>
                        <SvgIcon id="dropdownArrow" />
                    </div>
                </div>
            </div>
        );
    }
    const handleSelectChart = () => {
        if (!chart?.isSelect) selectChart(chart.id || chart.intermediumId);
    };
    return (
        <div className={classesForRootFull}>
            {
                loader
                && (
                    <div className={styles.loaderContainer}>
                        <QuickSVGLoader />
                    </div>
                )
            }
            {
                showError
                && (
                    <div className={styles.errorModal}>
                        <div className={styles.errorDescription}>Произошла ошибка</div>
                        <div className={styles.footerControls}>
                            <div className={styles.rootControls}>
                                <div
                                    onClick={() => {
                                        setLoader(true);
                                        setShowError(false);
                                        setPrevSettings(oldData);
                                    }}
                                    className={styles.btnDone}
                                >
                                    Продолжить

                                </div>
                                <div
                                    onClick={() => {
                                        setShowError(false);
                                        getDataForChart();
                                    }}
                                    className={styles.btnReapit}
                                >
                                    Повторить попытку
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={`${styles.header} ${styles.defaultContainer}`}>
                <div className={`${styles.chartTitle} ${styles.defaultContainer}`}>
                    <span onClick={handleSelectChart}>
                        <input type="text" className={styles.inputChangeName} value={name} onChange={(e) => setName(e.target.value)} />
                    </span>
                    <SvgIcon id="ChangeIcon" />
                </div>
                <div className={`${styles.chartHeaderControls} ${styles.defaultContainer}`}>
                    <div
                        onClick={() => saveNewChart()}
                        // onClick={() => saveChart(chart?.id || chart?.intermediumId)}
                        className={`${styles.chartSave} ${styles.defaultContainer}`}
                    >
                        <span>
                            {chart?.id ? 'Сохранен' : 'Добавить в Сохраненные'}
                        </span>
                        <SvgIcon id={chart?.id ? 'BlueStar' : 'AddStart'} />
                    </div>
                    <div
                        onClick={addToMainPage}
                        className={`${styles.addToMain} ${styles.defaultContainer}`}
                    >
                        <span>
                            Добавить на Главную
                        </span>
                    </div>
                    <div
                        onClick={() => removeChart(chart?.id || chart?.intermediumId, !!chart?.id)}
                        className={`${styles.chartDelete} ${styles.defaultContainer}`}
                    >
                        <SvgIcon id="Trashcan" />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex' }} className={`${isFullScreen && styles.fullScreen}`}>
                {
                    chartSettings
                    && (
                        <div
                            className={styles.newChartContainer}
                            style={{
                                // todo - A.Pushkin Исправить верстку в графике, при заполнении на 100% контейнер расширяется.
                                maxWidth: chartSettings?.listCheckBoxes?.length > 1 ? 'calc(100% - 150px)' : 'calc(100% - 150px)',
                            }}
                        >
                            <NewChart
                                legend={legends}
                                dataFormat={chartSettings}
                                isFullScreen={isFullScreen}
                                setIsFullScreen={(status) => setIsFullScreen(status)}
                                onGetNextDate={getNextChartData}
                                onGetPrevData={getPrevChartData}
                            />
                        </div>
                    )
                }
                {legends}
            </div>
        </div>
    );
};

export default React.memo(ChartItem);
