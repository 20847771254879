import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { deleteReportFromCampaign } from '../../../deprecated/api/reports/reportsApi';
import { AppThunk } from '../../store';
import { pdfHandlerReportActions } from '../report';

const deleteReportFromCampaignThunk = (campaignId: number, reportId: number): AppThunk => (dispatch: Dispatch): void => {
    if (!campaignId || Number.isNaN(campaignId) || !reportId || Number.isNaN(reportId)) {
        dispatch(pdfHandlerReportActions.failedDelete());
        dispatch(pdfHandlerReportActions.setFetchErrors('некорректные id кампании или отчёта'));
        return;
    }

    dispatch(pdfHandlerReportActions.startDelete());

    deleteReportFromCampaign(campaignId, reportId)
        .then(() => {
            dispatch(pdfHandlerReportActions.successDelete());
            dispatch(pdfHandlerReportActions.removeReportByReportId(reportId.toString()));
        })
        .catch((error: AxiosError) => {
            dispatch(pdfHandlerReportActions.failedDelete());
            dispatch(pdfHandlerReportActions.setFetchErrors(error?.message));
        });
};

export default deleteReportFromCampaignThunk;
