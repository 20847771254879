import {
    IAnalyzeAnalyticsState,
    IAnalyzeAnalyticsEntity,
    IAnalyzeAnalyticsAuthors,
} from './types';
import { IAnalyzeAnalyticsAuthor } from '../../../services/info-trace/author/types';

const convertAuthor = (data: unknown): IAnalyzeAnalyticsAuthor[] => {
    if (!data || !Array.isArray(data) || data?.length <= 0) {
        return [] as IAnalyzeAnalyticsAuthor[];
    }

    const result = [] as IAnalyzeAnalyticsAuthor[];

    data.forEach((item) => {
        const model = {} as IAnalyzeAnalyticsAuthor;
        Object.keys(item).forEach((key) => {
            switch (key) {
                case 'id':
                    model.id = item[key];
                    break;
                case 'title':
                    model.title = item[key];
                    break;
                case 'is_blogger':
                    model.isBlogger = item[key];
                    break;
                case 'logo_link':
                    model.logoLink = item[key];
                    break;
                case 'posts_count':
                    model.postsCount = item[key];
                    break;
                default:
                    break;
            }
        });
        result.push(model);
    });

    return result;
};

export const convertApiToAnalyzeAnalytics = (data: unknown): IAnalyzeAnalyticsState => {
    const result = {
        total: {},
        rss: {},
        tg: {},
        vk: {},
        ok: {},
        authors: {},
    } as IAnalyzeAnalyticsState;

    if (!data) {
        return result;
    }

    try {
        Object.keys(data).forEach((name) => {
            if (name && data[name]) {
                if (name === 'authors') {
                    const authors = {} as IAnalyzeAnalyticsAuthors;
                    if (data[name]?.defenders?.length > 0) {
                        authors.defenders = convertAuthor(data[name]?.defenders);
                    }
                    if (data[name]?.haters?.length > 0) {
                        authors.haters = convertAuthor(data[name]?.haters);
                    }
                    result.authors = authors;
                } else if (name === 'analytics') {
                    Object.keys(data[name]).forEach((key) => {
                        result[key] = {
                            mood: data[name][key].mood ?? undefined,
                            count: data[name][key].count ?? undefined,
                            comments: data[name][key].comments ?? undefined,
                            likes: data[name][key].likes ?? undefined,
                            views: data[name][key].views ?? undefined,

                        } as IAnalyzeAnalyticsEntity;
                    });
                }
            }
        });
    } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error('convertApiToAnalyzeAnalytics', err);
        return result;
    }
    return result;
};
