import React, { useState } from 'react';
import AscendingSortIcon from '@assets/images/ascending-sort.svg';
import DescendingSortIcon from '@assets/images/descending-sort.svg';
import FilterCheckboxListContainer from '@containers/campaign/DropdownColumnFilterTable/FilterCheckboxList';
import { Button } from '@shared/index';
import { IColumnFilterModel } from '../../../DropdownFilterTable/DropdownFilterTableMenu/types';
import styles from './styles.module.scss';

interface IDropdownColumnFilterTableMenuProps {
    isSorting: boolean;
    isFiltering: boolean;
    isAscending: boolean;
    filterData: IColumnFilterModel[];
    setVisibleMenu: (value: boolean) => void;
    onSort?: (ascending: boolean) => void;
    onApplyFilters?: () => void;
}

const handleKeyDown = () => {
    // eslint-disable-next-line no-console
};

const DropdownColumnFilterTableMenu: React.FC<IDropdownColumnFilterTableMenuProps> = ({
    isFiltering, isSorting, isAscending,
    onSort, onApplyFilters, setVisibleMenu,
    filterData,
}): JSX.Element => {
    const [sortIsAscending, setAscending] = useState<boolean>(isAscending);

    const handlerApplyFilters = (): void => {
        if (onSort) {
            onSort(sortIsAscending);
        }
        if (onApplyFilters) {
            onApplyFilters();
        }
        setVisibleMenu(false);
    };

    return (
        <div className={styles.root}>
            {
                isSorting && (
                    <>
                        <div className={styles.title}>Сортировка</div>
                        <div
                            className={styles.sortRowContainer}
                            role="button"
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            onClick={() => setAscending(true)}
                        >
                            <img src={AscendingSortIcon} alt="ascending" />
                            <div className={styles[sortIsAscending ? 'text_active' : 'text']}>По возрастанию</div>
                        </div>
                        <div
                            className={styles.sortRowContainer}
                            role="button"
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            onClick={() => setAscending(false)}
                        >
                            <img src={DescendingSortIcon} alt="descending" />
                            <div className={styles[sortIsAscending ? 'text' : 'text_active']}>По убыванию</div>
                        </div>
                    </>
                )
            }
            {
                isFiltering && (
                    <>
                        <div className={styles.title}>Фильтры</div>
                        <div className={styles.filterContainer}>
                            <FilterCheckboxListContainer data={filterData} />
                        </div>
                    </>
                )
            }
            <div className={styles.buttonContainer}>
                <Button text="Ok" type="default" size="medium" onClick={handlerApplyFilters} />
            </div>
        </div>
    );
};

export default DropdownColumnFilterTableMenu;
