import React from 'react';
import classnames from 'classnames';
import SvgIcon from '@components/SvgIcon';
import { LogoIconsIds } from '@app/constants/icons';
import styles from './styles.module.scss';

interface IProps {
    label: string;
    isInverse: boolean;
    isDisabled: boolean;
    onSwitch: (value: string) => void;
}

const PlatformsIcon = new Map([
    ['rss', LogoIconsIds.RssLogo],
    ['vk', LogoIconsIds.VkLogo],
    ['ok', LogoIconsIds.OkLogo],
    ['tg', LogoIconsIds.TgLogo],
    ['dzen', LogoIconsIds.ZenLogo],
    ['rutube', LogoIconsIds.RuTubeLogo],
    ['youtube', LogoIconsIds.YouTubeLogo],
    ['vk_video', LogoIconsIds.VkVideo],
]);
const IconSwitcher: React.FC<IProps> = ({
    label, isDisabled, isInverse, onSwitch,
}): JSX.Element => {
    const handlerClick = (): void => {
        if (isDisabled) {
            return;
        }
        if (!isInverse) {
            onSwitch(label);
        }
    };
    return (
        <div className={styles.iconSwitcher}>
            <button type="button" className={classnames(styles.iconSwitcher_outerCircle, styles[isDisabled ? 'disabled' : ''])} onClick={handlerClick}>
                <div className={classnames(styles.iconSwitcher_circle, styles[isInverse ? '' : 'disabled'])} />
            </button>
            <button type="button" className={classnames(styles.iconSwitcher_labelButton, styles[isDisabled ? 'disabled' : ''])} onClick={handlerClick}>
                <SvgIcon id={PlatformsIcon.get(label)} />
            </button>
        </div>
    );
};

export default IconSwitcher;
