/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import styles from './styles.module.scss';

type Token = {
    type: string;
    style: React.CSSProperties;
    content: any;
};
type TCssProps = {
    [key: string]: React.CSSProperties
};
const parseText = (text: string, classes: string[] = [], cssStyles: TCssProps = {}): JSX.Element | string => {
    const tokens: Token[] = [];
    let cursor = 0;
    let buffer = '';
    const classMap: { [key: string]: string } = {
        '**': styles.mdB,
        '*': styles.mbI,
        _: styles.mbI,
        '~~': styles.mbC,
        '`': styles.mdTC,
        µ: styles.mbL,
    };
    const styleMap: TCssProps = cssStyles;
    // console.log(styleMap);
    while (cursor < text.length) {
        let matched = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const styleMark in classMap) {
            if (text.startsWith(styleMark, cursor)) {
                if (buffer) {
                    tokens.push({ type: 'text', style: {}, content: buffer });
                    buffer = '';
                }

                cursor += styleMark.length;
                const endPos = text.indexOf(styleMark, cursor);

                if (endPos !== -1) {
                    const innerText = text.slice(cursor, endPos);
                    const innerToken = parseText(innerText, [...classes, classMap[styleMark]], cssStyles);
                    tokens.push({ type: classMap[styleMark], style: styleMap[styleMark], content: innerToken });
                    cursor = endPos + styleMark.length;
                }

                matched = true;
                break;
            }
        }

        if (!matched) {
            buffer += text[cursor];
            cursor += 1;
        }
    }

    if (buffer) {
        tokens.push({ type: 'text', style: {}, content: buffer });
    }
    if (classes.length) {
        return (
            <span className={classes.join(' ')}>
                {
                    tokens.map(
                        (token, i) => {
                            // console.log(styleMap[token.type], token);
                            return (token.type === 'text' ? token.content
                                : <span className={token.type} style={token.style} key={i}>{token.content}</span>);
                        },
                    )
                }
            </span>
        );
    }
    return (
        <>
            {tokens.map((token, i) => {
                // console.log(styleMap[token.type], token);
                return (token.type === 'text' ? token.content
                    : <span className={token.type} style={token.style} key={i}>{token.content}</span>);
            })}
        </>
    );
};

const MarkdownParser: React.FC<{ text: string, styledShanblons?: TCssProps }> = ({ text, styledShanblons }) => <>{parseText(text, [], styledShanblons)}</>;

export default MarkdownParser;
