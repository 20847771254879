import React, { useMemo, CSSProperties } from 'react';

const WrapperChartTooltip = ({
    // eslint-disable-next-line react/prop-types
    x, y, children, handlerOnMouseEnter,
}) => {
    const computedX = useMemo(() => `${x}px`, [x]);
    const computedY = useMemo(() => `${y - 100}px`, [y]);
    const computedStyles = useMemo(():CSSProperties => {
        const styles:CSSProperties = {
            left: computedX,
            top: computedY,
            position: 'absolute',
            zIndex: 1,
            background: '#fff',
            maxWidth: '340px',
            borderRadius: '6px',
            boxShadow: '0px 0px 10px rgba(14, 191, 161, 0.15)',
            padding: '10px',
            border: '16px solid #DFFCF7',
        };
        return styles;
    }, [computedX, computedY]);
    const handlerMouseEnter = (status) => {
        if (handlerOnMouseEnter) handlerOnMouseEnter(status);
    };
    return (
        <div style={computedStyles} onMouseEnter={() => handlerMouseEnter(true)} onMouseLeave={() => handlerMouseEnter(false)}>
            {children}
        </div>
    );
};

export default WrapperChartTooltip;
