export const barChartBaseOptions = {
    chartColors: ['2F9FE0'],
    barDir: 'bar',
    catAxisLabelColor: '787E93',
    catAxisLabelFontFace: 'Montserrat SemiBold',
    catAxisLabelFontSize: 7,
    catAxisLabelFontBold: true,
    catAxisLineShow: false,
    catAxisOrientation: 'maxMin',
    catAxisMinorTickMark: 'cross',
    serGridLine: {
        style: 'none',
    },
    valGridLine: {
        style: 'none',
    },
    catGridLine: {
        style: 'none',
    },
    valAxisHidden: true,
    showValue: true,
    valAxisLabelFontFace: 'Montserrat SemiBold',
    valAxisLabelFontSize: 7,
    valAxisLabelColor: '787E93',
    valAxisLabelFontBold: true,
    barGapWidthPct: 100,
    dataLabelFontFace: 'Montserrat SemiBold',
    dataLabelColor: '787E93',
    dataLabelFontBold: true,
    dataLabelFontSize: 7,
    catAxisMultiLevelLabels: true,
};
