/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { IApplyFilter } from '@sections/MyCampaignContainer/CampaingTypes';
import { IModelUserFilter } from '@services/campaigns/userFilters/types';
import styles from './styles.module.scss';
import SvgIcon from '../../../SvgIcon';

interface IPropsLastItem {
    data: IModelUserFilter,
    onClose: () => void,
    onGoToChange: () => void,
    onRemove: () => void,
}

const Modalnfo: React.FC<IPropsLastItem> = ({
    data,
    onClose,
    onGoToChange,
    onRemove,
}): JSX.Element => {
    const groupFilltersApply = (filters: IApplyFilter[]): IApplyFilter[][] => filters.reduce((acc, item) => {
        const newAcc = [...acc];
        const group = newAcc.find((elem) => elem.some((filter) => filter.filters_category === item.filters_category));
        if (group) {
            group.push(item);
        } else {
            newAcc.push([item]);
        }
        return newAcc;
    }, []);
    const includes = useMemo(() => {
        if (!data?.filters_include) return [];
        return groupFilltersApply(data?.filters_include);
    }, [data, data?.filters_include]);
    const getListViewFilters = (filters: IApplyFilter[]) => filters.map((filter) => (
        <div className={styles.contentItem} key={`${getListViewFilters}_${new Date()}_${filter.filter_name}`}>{filter.filter_name}</div>
    ));
    const listItems = includes.map((category) => (
        <div className={styles.item} key={new Date().getTime() + category[0].filters_category}>
            <div className={styles.item_label}>
                {category[0].filters_category}
            </div>
            <div className={styles.content}>
                {getListViewFilters(category)}
            </div>
        </div>
    ));
    return (
        <div
            className={styles.root_ModalInfo}
        >
            <div className={styles.constrols}>

                <div style={{ fill: '#787E93' }} onClick={onGoToChange}>
                    <SvgIcon
                        id="ChangeIcon"
                        size={24}
                    />
                </div>
                <div style={{ fill: '#787E93' }} onClick={onRemove}>
                    <SvgIcon
                        id="RemoveIcon"
                        size={24}
                    />
                </div>
                <div style={{ fill: '#787E93' }} onClick={onClose}>
                    <SvgIcon
                        id="Close"
                        size={16}
                    />
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.item_label}>
                    Наименование конфигурации фильтров
                </div>
                <div className={styles.title}>
                    {data?.title}
                </div>
                {listItems}
            </div>
        </div>

    );
};
export default React.memo(Modalnfo);
