import React, { useEffect, useMemo } from 'react';
import CampaignTagsFavoriteList from '@components/campaign/TagsFavoriteList/Modals/FavoriteList';
import { favoriteSourcesRtkQService } from '@services/campaigns/favorite-sources';
import { ISimpleEntity } from '@store/types';
import { ITrackedSourceModel } from '@store/campaign/tracked-sources/types';

interface IProps {
    top: number;
    left: number;
    isOpen: boolean;
    onAddWord: (value: string, id: number) => void;
    onClose: () => void;
    newSource: ITrackedSourceModel;
}

const CampaignSourcesFavoriteListContainer: React.FC<IProps> = ({
    top,
    left,
    newSource,
    isOpen,
    onAddWord,
    onClose,
}): JSX.Element => {
    const [
        getFavoriteSources,
        { currentData: favoriteSources, isLoading },
    ] = favoriteSourcesRtkQService.useLazyGetFavoriteSourcesQuery();
    const [
        addFavoriteSource,
        { data: newFavoriteSource, isLoading: addIsLoading, isSuccess: addIsSuccess },
    ] = favoriteSourcesRtkQService.useLazyPostCreateFavoriteSourceQuery();
    const [
        deleteFavoriteSource,
        { isLoading: deleteIsLoading, isFetching: deleteIsFetching, isSuccess: deleteIsSuccess },
    ] = favoriteSourcesRtkQService.useLazyDeleteFavoriteSourceQuery();

    const currentLinks = useMemo<string[]>(() => {
        if (isLoading) {
            return [];
        }
        return favoriteSources?.map(({ source }) => source?.link) ?? [];
    }, [favoriteSources, isLoading]);
    const tagList = useMemo<ISimpleEntity[]>(() => {
        if (isLoading) {
            return [];
        }
        let result: ISimpleEntity[] = favoriteSources?.map(
            ({ id, source }) => ({ id, name: source?.title, value: source?.link } as ISimpleEntity),
        ) ?? [];
        if (newSource?.link) {
            const links = favoriteSources?.map(({ source }) => source?.link) ?? [];
            if (!links.includes(newSource?.link)) {
                result = [{ id: -1, value: newSource?.link, name: newSource?.title }, ...result];
            }
        }
        return result;
    }, [favoriteSources, newSource, isLoading]);

    const handlerAddFavorite = (): void => {
        if (newSource?.link && !currentLinks.includes(newSource?.link)) {
            addFavoriteSource({
                source: {
                    id: newSource.id,
                    author_id: newSource.authorId,
                    title: newSource.title,
                    link: newSource.link,
                    allow_in_russia: newSource.allowInRussia,
                    auto_discover: newSource.autoDiscover,
                    created: newSource.created,
                    out_source_id: newSource.outSourceId,
                    enabled: newSource.enabled,
                    location_id: newSource.locationId,
                    parse_link: newSource.parseLink,
                    parsed: newSource.parsed,
                    tag: newSource.tag,
                    type_id: newSource.typeId,
                },
            });
        }
    };
    const handlerDeleteFavorite = (id: number): void => {
        const deletedSource = favoriteSources.find(({ id: sourceId }) => sourceId === id);
        if (deletedSource) {
            deleteFavoriteSource({ id });
        }
    };

    useEffect(() => {
        if (isOpen) {
            getFavoriteSources();
        }
    }, [isOpen]);
    useEffect(() => {
        if (!deleteIsLoading && !deleteIsFetching && deleteIsSuccess) {
            getFavoriteSources();
        }
    }, [deleteIsSuccess, deleteIsLoading, deleteIsFetching]);
    useEffect(() => {
        if (addIsSuccess && !addIsLoading && newFavoriteSource?.id > 0) {
            getFavoriteSources();
        }
    }, [addIsSuccess, addIsLoading, newFavoriteSource]);

    return (
        <CampaignTagsFavoriteList
            top={top}
            left={left}
            isLoading={isLoading || addIsLoading || deleteIsLoading}
            isOpen={isOpen}
            onClose={onClose}
            tagList={tagList}
            onAddWord={onAddWord}
            onAddFavorite={handlerAddFavorite}
            onDeleteFavorite={handlerDeleteFavorite}
        />
    );
};

export default CampaignSourcesFavoriteListContainer;
