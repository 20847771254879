/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SvgIcon from '../SvgIcon';
import styles from './styles.module.scss';
import { userTagsCampaingRtqService } from '../../services/campaigns/userTags';
import QuickSVGLoader from '../QuickSVGLoader';

interface IPropsModalCreateTag {
    campaignId: number,
    isOpen: string | boolean,
    onClose: () => void,
}

const ModalTagInfo: React.FC<IPropsModalCreateTag> = ({
    campaignId,
    isOpen,
    onClose,
}): JSX.Element => {
    if (!isOpen) return null;
    const [includes, setIncludes] = useState<string[]>([]);
    const [excludes, setExcludes] = useState<string[]>([]);
    const [tagName, setTagTagName] = useState<string>('');
    const [tagDate, setTagTagDate] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [getTag] = userTagsCampaingRtqService.useLazyGetUserTagByIdQuery();
    const deCompose = (query: string) => {
        const cleanString = query.replaceAll('(', '').replaceAll(')', '');
        const arr = cleanString.split('&');
        const rawNewExcludes = arr.filter((str) => str.includes('!'));
        const newExcludes = rawNewExcludes.map((str) => str.replaceAll('"', '').replaceAll('!', ''));
        const rawNewIncludes = arr.filter((str) => !str.includes('!'));
        const newIncludes = rawNewIncludes.map((str) => str.replaceAll('"', ''));
        setExcludes(newExcludes);
        setIncludes(newIncludes);
    };
    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            getTag({ id: isOpen, campaign_id: campaignId }).then((res) => {
                if (res?.data?.id) {
                    setTagTagName(res?.data?.title);
                    setTagTagDate(moment(res?.data?.parse_from).format('DD.MM.YYYY'));
                    deCompose(res?.data?.query);
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [isOpen]);
    const getTamplateWords = (words: string[], exclude: boolean = false) => words.map((word) => (
        <div className={`${styles.wordContainer} ${exclude && styles.excludeWord}`} key={word}>
            <div className={styles.word}>
                {word}
            </div>
        </div>
    ));
    return (

        <div className={styles.overlay}>
            <div className={styles.rootModal}>
                { isLoading
                && (
                    <div className={styles.loaderOverlay}>
                        <QuickSVGLoader />
                    </div>
                )}
                <div onClick={onClose} className={styles.btnClose}>
                    <SvgIcon id="Close" size={12} />
                </div>
                <div className={styles.item}>
                    <div className={styles.itemLabel}>Дата, с которой сообщения помечаются тегом</div>
                    <div className={styles.itemContent}>{tagDate}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.itemLabel}>Название тега</div>
                    <div className={styles.itemContent}>{tagName}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.itemLabel}>Тип слов:</div>
                </div>
                <div
                    className={styles.item}
                    style={{ marginLeft: '16px' }}
                >
                    <div className={styles.itemContent}>
                        <div className={styles.itemWords}>
                            <div className={styles.itemLabel}>Отслеживаемые </div>
                            <div className={styles.itemContentWords}>
                                {getTamplateWords(includes, false)}
                            </div>
                        </div>
                        <div className={styles.itemWords}>
                            <div className={styles.itemLabel}>Исключающие </div>
                            <div className={styles.itemContentWords}>
                                {getTamplateWords(excludes, true)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalTagInfo;
