import { createSlice } from '@reduxjs/toolkit';
import { ICampaignTableState } from 'store/campaign/table/types';
import { CampaignColumnFilterType, ICampaignTableHeaderColumn } from '../../../components/campaign/List/Table/types';

const initialHeaderStructure: ICampaignTableHeaderColumn[] = [
    {
        id: 1,
        name: 'Наименование',
        cellWidth: '12%',
        minWidth: 180,
        maxWidth: 200,
        columnType: CampaignColumnFilterType.name,
    },
    {
        id: 2,
        name: 'Дата',
        cellWidth: '8%',
        minWidth: 100,
        maxWidth: 220,
        columnType: CampaignColumnFilterType.date,
    },
    {
        id: 3,
        name: 'Автор кампании',
        cellWidth: '8%',
        minWidth: 110,
        maxWidth: 110,
        // columnType: CampaignColumnFilterType.author,
        columnType: CampaignColumnFilterType.default,
    },
    // {
    //     id: 4,
    //     name: 'Статус',
    //     cellWidth: '5%',
    //     minWidth: 110,
    //     maxWidth: 110,
    //     // columnType: CampaignColumnFilterType.status,
    //     columnType: CampaignColumnFilterType.default,
    // },
    {
        id: 5,
        name: 'Действия',
        cellWidth: '5%',
        minWidth: 150,
        maxWidth: 200,
        columnType: CampaignColumnFilterType.default,
    },
];

const initialState: ICampaignTableState = {
    headerStructure: initialHeaderStructure,
};

const { actions: campaignTableActions, reducer } = createSlice({
    name: 'campaignTable',
    initialState,
    reducers: {
        clearAll: () => ({ ...initialState }),
    },
});

export { campaignTableActions };
export default reducer;
