import { ICategory } from 'store/types';
import { ICategoriesFromApi } from './types';
import { rtkQApi } from '../index';

export const categoriesRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getCategories: build.query<ICategory[], void>({
            query: () => ({
                url: 'category',
                method: 'GET',
            }),
            transformResponse: (
                baseQueryResponse: ICategoriesFromApi[],
            ): ICategory[] => baseQueryResponse.map((item) => ({
                id: item?.id.toString(),
                mainCat: item?.title,
                bloggers: null,
                isRetinaSize: false,
                media: null,
                selected: null,
            } as ICategory)),
        }),
    }),
});
