import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import ReactDOM from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import './app/i18n';
import './app/index.css';
import { store } from '@store/store';
import RouterLayout from './pages';
import ErrorBoundary from './infrastructure/hocs/ErrorBoundary/ErrorBoundary';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <ErrorBoundary>
        <ConfigProvider locale={ru_RU}>
            <Provider store={store}>
                <SkeletonTheme baseColor="#F5F9FE" highlightColor="#E8F1F9">
                    <RouterLayout />
                </SkeletonTheme>
            </Provider>
        </ConfigProvider>
    </ErrorBoundary>,
);
