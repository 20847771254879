import React, { useState } from 'react';
import getKey from '@app/helpers/getKey';
import { SvgIcon } from '@shared/index';
import styles from './styles.module.scss';

interface IPropsCatigoriesCell {
    categories: {
        id: number,
        title: string,
    }[];
}
const CatigoriesCell: React.FC<IPropsCatigoriesCell> = ({ categories }): JSX.Element => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className={`${styles.catigoriesCell_root} ${isOpen ? styles.open : categories?.length > 2 && styles.close}`}>
            <div className={styles.catigoriesCell}>
                {categories.map((item, key) => (
                    <div className={styles.catigoriesCell_item} key={getKey(key * 7937)}>{item.title}</div>
                ))}
            </div>
            { categories?.length > 2 && (
                <div
                    role="button"
                    className={`${styles.controller} ${isOpen ? styles.open : styles.close}`}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    <SvgIcon size={16} id="dropdownArrow" />
                </div>
            )}
        </div>
    );
};

export default React.memo(CatigoriesCell);
