import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { IUserInfo } from 'services/common/types';
import Path from '../../../../shared/lib/paths';
import { singleCampaignRtkQService } from '../../../../services/campaigns/single-campaign';
import { ToolTipElementType } from '../../../../components/campaign/Menu/CampaignActions/types';
import CampaignMenuActions from '../../../../components/campaign/Menu/CampaignActions';
import campaignSelectors from '../../../../store/campaign/source/selectors';
import { useAppDispatch } from '../../../../store/hooks';
import { campaignActions } from '../../../../store/campaign/source';
import campaignUtils from '../../../../utils/campaign/index';
import { CampaignActionTypes } from '../../../../store/campaign/source/types';
import { selectUserProfileFetchData } from '../../../../store/user-profile/selectors';

interface ICampaignMenuActionsContainersProps {
    onDelete: () => void;
}

const CampaignMenuActionsContainers: React.FC<ICampaignMenuActionsContainersProps> = ({
    onDelete,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { Id: urlParametersId } = useParams();

    // const campaign = useSelector(campaignSelectors.currentCampaign);
    const campaignAction = useSelector(campaignSelectors.actionCampaign);
    const currentUser = useSelector(selectUserProfileFetchData);
    // const campaignOwner = useSelector(campaignSelectors.singleCampaignOwner);

    // const {
    //     data: campaignRtkQ,
    //     isLoading: isLoadingCampaignRtkQ,
    // } = campaignsListRtkQService.useGetCampaignQuery({ id: Number(campaignId) });

    const [getCampaign, {
        data: campaign, isLoading, isFetching, isSuccess,
    }] = singleCampaignRtkQService.useLazyGetCampaignQuery();

    const campaignOwner = useMemo<IUserInfo | null>(() => {
        if (!isLoading && !isFetching && isSuccess) {
            return campaign?.owner;
        }
        return null;
    }, [campaign, isLoading, isFetching]);
    const activeElements = useMemo((): ToolTipElementType[] => {
        let result = [ToolTipElementType.edit, ToolTipElementType.delete, ToolTipElementType.download];
        if (location.pathname.includes('update')) {
            result = result.filter((item) => item !== ToolTipElementType.download);
        }
        if (campaignAction === CampaignActionTypes.edit) {
            result = result.filter((item) => item !== ToolTipElementType.edit);
        }
        return result;
    }, [location, campaignAction]);

    const handlerDownload = (): void => {
        // eslint-disable-next-line no-console
        console.log('start downloading something');
    };

    const handlerEditCampaign = (): void => {
        if (location.pathname.includes('update')) {
            dispatch(campaignActions.setEditActionCampaign());
        }
        if (campaignUtils.locationIsTrackingCampaign(location.pathname)) {
            const path = `/${Path.Campaign}/${Path.CampaignUpdate}`;
            // dispatch(campaignActions.clearCurrentCampaign());
            dispatch(campaignActions.setViewActionCampaign());
            // dispatch(loadSingleCampaignIfNotExists(campaignId));
            navigate(path);
        }
    };
    const handlerDeleteCampaign = (): void => {
        if (onDelete) {
            onDelete();
        }
    };
    useEffect(() => {
        const id = Number(urlParametersId ?? -1);
        if (Number.isInteger(id) && id > 0) {
            getCampaign({ id });
        }
    }, [urlParametersId]);

    return (
        <CampaignMenuActions
            onDownload={handlerDownload}
            onEdit={handlerEditCampaign}
            onDelete={handlerDeleteCampaign}
            noDelete={campaignOwner?.userId !== currentUser?.userId}
            activeElements={activeElements}
        />
    );
};

export default CampaignMenuActionsContainers;
