import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import TextField from '@entities/TextField';
import SvgIcon from '../../../../SvgIcon';
import { ICampaignTrackedWordsInputProps } from './types';

import styles from './styles.module.scss';

const CampaignTrackedWordsInput: React.FC<ICampaignTrackedWordsInputProps> = ({
    inputTagId,
    placeholder,
    isErrorHighlighting,
    withSearch,
    needClearInput,
    isIPadSize,
    addBtnTagId,
    onClear,
    onAddWord,
    onChangeWord,
}): JSX.Element => {
    const [trackedWord, setTrackedWord] = useState<string>('');
    const handlerChangeTrackedWord = (value: string): void => {
        if (value !== undefined && value !== null) {
            setTrackedWord(value);
            onChangeWord(value);
        }
    };
    const handlerAddWord = (): void => {
        if (!withSearch && trackedWord) {
            setTrackedWord('');
            onAddWord(trackedWord, null);
            onChangeWord('');
        }
    };
    const handlerKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter' && trackedWord?.length > 0) {
            handlerAddWord();
        }
    };
    useEffect(() => {
        if (onClear && needClearInput) {
            setTrackedWord('');
            onChangeWord('');
            onClear();
        }
    }, [needClearInput, onClear]);
    return (
        <div
            className={classnames(
                styles.wrapper,
                styles[isErrorHighlighting ? 'red' : ''],
            )}
            style={isIPadSize ? { width: 340 } : null}
        >
            <TextField
                // label={inputLabel}
                id={inputTagId}
                placeholder={placeholder}
                value={trackedWord}
                onChange={handlerChangeTrackedWord}
                onKeyDown={handlerKeyDown}
                isSearch
            />
            <button
                id={addBtnTagId}
                type="button"
                className={classnames(styles.button, trackedWord?.length < 1 ? styles.hidden : '')}
                onClick={handlerAddWord}
            >
                <SvgIcon id="InputIcon" size={24} />
            </button>
        </div>
    );
};

export default CampaignTrackedWordsInput;
