/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import MarkDownText from '../MarkDownText';
import styles from './styles.module.scss';

interface IProps {
    text: string,
    keyWords: Array<string>,
    colorHeightlight?: string,
    maxHeight?: number,
    scroll?: boolean
}
const TextHeightlighting: React.FC<IProps> = ({
    text, keyWords, colorHeightlight = 'red', maxHeight = 1280, scroll = false,
}): JSX.Element => {
    const getArrayWords = (allText: string) => {
        const matcherWords = allText?.match(/<b>.*?<\/b>/g);
        const listKeyWords = matcherWords ? allText?.match(/<b>.*?<\/b>/g)?.map((item) => item.replaceAll('<b>', '').replaceAll('</b>', '')) : [];
        const fromText = allText?.replaceAll('<b>', '§').replaceAll('</b>', '§');
        const listValues = fromText?.split('§');
        const markup = listValues?.map((str, index) => {
            const check = listKeyWords?.find((word) => word === str);
            const key = `lighting__key__${index}`;
            if (check) {
                return (
                    <ReactMarkdown key={key} className={`${styles.containerText} ${styles.lighthing}`}>{str}</ReactMarkdown>
                );
            }
            return (
                <ReactMarkdown key={key} className={styles.containerText}>{str}</ReactMarkdown>
            );
        });
        return markup;
    };
    return (
        <div className={styles.lighthingContainer} style={{ maxHeight: `${maxHeight}px`, overflow: scroll ? 'scroll' : 'hidden' }}>
            {getArrayWords(text)}
        </div>
    );
};

export default TextHeightlighting;
