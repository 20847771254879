import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { IconButtonLoadFile } from '@shared/index';
import Path from '@shared/lib/paths';
import SvgIcon from '@components/SvgIcon';
import CampaignComparisonListContainer from '../../../containers/campaign/List/Comparison';
import LinearLoader from '../../common/Loader';
import CampaignMenuDropdownTab from './DropdownTabs';
import CampaignMenuHint from './Hint';
import { ICampaignMenuTab } from './types';
import styles from './styles.module.scss';

interface ICampaignMenuTabsProps {
    tabs: ICampaignMenuTab[];
    selectChartTabId: number;
    onCreateCampaign: () => void;
    onCompareCampaign: () => void;
    onClickTracking: () => void;
    onClickUpdate: () => void;
    onClickReports: () => void;
    onClickCharts: () => void;
    onClickSavedCharts: () => void;
    onClickNewCharts: () => void;
    onSelectChartTab: (tabId: number) => void;
    isCompare: boolean;
    referenceCampaign: ICampaignDataModel | null;
    onCancelCompare: () => void;
}

const CampaignMenuTabs: React.FC<ICampaignMenuTabsProps> = ({
    tabs, onCreateCampaign, onCompareCampaign,
    onClickTracking, onClickUpdate, onClickNewCharts,
    onClickReports, onClickCharts, onClickSavedCharts,
    onSelectChartTab, selectChartTabId,
    isCompare, referenceCampaign,
    onCancelCompare,
}): JSX.Element => {
    const [hintIsOpen, setHintOpen] = useState<boolean>(true);
    const activeTab = useMemo<string>(() => [
        ...tabs, ...tabs.map(({ items }) => items).flat(),
    ].find(({ isActive }) => isActive)?.name, [tabs]);

    const hintOpenStatus = useMemo<boolean>(() => (isCompare && hintIsOpen), [isCompare, hintIsOpen]);

    const handlerClick = (toTab: string): void => {
        if (toTab === 'update') {
            onClickUpdate();
        }
        if (toTab === 'tracking') {
            onClickTracking();
        }
        if (toTab === 'charts') {
            onClickCharts();
        }
        if (toTab === 'reports') {
            onClickReports();
        }
        if (toTab === 'saved') {
            onClickSavedCharts();
        }
        if (toTab === 'new-charts') {
            onClickNewCharts();
        }
    };
    useEffect(() => {
        if (!isCompare && !hintIsOpen) {
            setHintOpen(true);
        }
    }, [isCompare, hintIsOpen]);

    return (
        <div className={styles.campaignMenuTabs_root}>
            <div className={styles.leftControls}>
                {
                    !isCompare && activeTab === 'list' && (
                        <>
                            <button
                                id="campaign-create-tab"
                                type="button"
                                className={styles.linkButton}
                                onClick={() => onCreateCampaign()}
                            >
                                <span className={styles.comparisonTab_text}>
                                    Создать кампанию
                                </span>
                            </button>
                            <Link
                                id="campaign-create-plus"
                                to={`/${Path.Campaign}/${Path.CampaignCreate}`}
                                className={styles.circle}
                                onClick={() => onCreateCampaign()}
                            >
                                <div style={{ marginTop: 8, marginLeft: 8 }}>
                                    <IconButtonLoadFile iconId="RoundAdd_Icn_NoneFill" />
                                </div>
                            </Link>
                            <span style={{ minWidth: 20 }} />
                            {
                                !isCompare && (
                                    <div className={styles.comparisonTab}>
                                        <button
                                            id="campaign-compare-tab"
                                            type="button"
                                            className={styles.linkButton}
                                            onClick={() => onCompareCampaign()}
                                        >
                                            <span className={styles.comparisonTab_text}>
                                                Сравнить кампании
                                            </span>
                                        </button>
                                        <button
                                            id="campaign-compare-btn"
                                            type="button"
                                            className={styles.linkButton}
                                            onClick={() => onCompareCampaign()}
                                        >
                                            <SvgIcon id="scales" />
                                        </button>
                                    </div>
                                )
                            }
                        </>
                    )
                }
                {
                    isCompare && !referenceCampaign && (
                        <CampaignMenuHint isOpen={hintOpenStatus} setOpen={setHintOpen} onCancel={onCancelCompare} />
                    )
                }
                {
                    isCompare && referenceCampaign && <CampaignComparisonListContainer />
                }
                {
                    (activeTab === 'create') && (
                        <div
                            id="campaign-create-tab"
                            style={{ cursor: 'default' }}
                            className={classnames(styles.item, styles.linkButton, styles.active)}
                        >
                            Создание
                        </div>
                    )
                }
                {
                    Boolean(activeTab) && activeTab !== 'list' && activeTab !== 'create' && tabs?.map((tab) => (
                        tab?.items?.length > 0
                            ? (
                                <CampaignMenuDropdownTab
                                    key={tab.id}
                                    items={tab.items}
                                    tagId={tab.tagId}
                                    defaultTitle="Графики"
                                    selectedId={selectChartTabId}
                                    onSelect={onSelectChartTab}
                                />
                            )
                            : (
                                <button
                                    key={tab.id}
                                    id={tab.tagId}
                                    type="button"
                                    className={classnames(styles.item, styles[tab.isActive ? 'active' : ''], styles.linkButton)}
                                    onClick={() => handlerClick(tab.name)}
                                >
                                    {
                                        tab.isLoading ? <LinearLoader /> : tab.title
                                    }
                                </button>
                            )
                    ))
                }
            </div>
        </div>
    );
};

export default CampaignMenuTabs;
