import React, { useEffect, useMemo, useState } from 'react';
import TabelSources from '@features/source/TabelSources';
import {
    ICustomSourceListRaw, ISourceRow, SortOrder,
} from '@services/sources/types';
import { Button } from '@shared/index';
import { ITestRow } from '@features/source/TabelSources/types';
import ModalAddSources from '@widgets/CardList/ModalAddSources';
import { message } from 'antd';
import { sourcesRtkQService } from '@services/sources';
import styles from './styles.module.scss';

const LIMIT_LISTS_COUNTER = 150;
interface IPropsDetailCardInfo {
    lists: ICustomSourceListRaw[];
    list_id?: number;
    updateLists: () => void
}

const DetailCardInfo: React.FC<IPropsDetailCardInfo> = ({ list_id, lists, updateLists }): React.ReactElement => {
    const getListInfo = useMemo(() => (lists || []).find(({ id }) => id === list_id), [list_id, lists]);
    const [selectedSources, setSelectedSource] = useState<number[]>([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [updateSources] = sourcesRtkQService.useAddSourcesToListsMutation();
    const [removeSourcesFromList] = sourcesRtkQService.useRemoveSourcesFromListMutation();
    const [getSources, { isFetching, isLoading }] = sourcesRtkQService.useLazyPostLoadSourcesInfinityQuery();
    const getFavoriteId = useMemo(() => lists?.find(({ isFavorite }) => isFavorite).id || -1, [lists]);
    const [nextToken, setNextToken] = useState<string>(null);
    const [sources, setSources] = useState<ISourceRow[]>([]);
    const success = (content) => {
        messageApi.open({
            type: 'success',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_succes,
        });
    };
    const error = (content) => {
        messageApi.open({
            type: 'error',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_error,
        });
    };

    const handleAddToList = (listIds): void => {
        updateSources({
            list_ids: [list_id],
            source_ids: listIds,
        }).then(() => {
            setSelectedSource([]);
            setIsOpenModal(false);
            getSources({
                page: 1,
                filterParams: {},
                orderBy: 'subscribers',
                orderDirection: SortOrder.desc,
                rangeParams: {},
                limit: LIMIT_LISTS_COUNTER,
                list_id,
            }).then((res) => {
                if (res?.data) {
                    setSources(res.data.sources);
                    setNextToken(res.data.nextPageToken);
                    updateLists();
                    success(`Выбранные источники успешно добавленны в список: ${getListInfo.title}`);
                }
            });
        }).catch((err) => {
            error('При добавлении произошла ошибка');
            // eslint-disable-next-line no-console
            console.log('При добавлении произошла ошибка', err);
        });
    };
    const handleRemoveFromList = (): void => {
        removeSourcesFromList({
            list_id,
            source_ids: selectedSources,
        }).then(() => {
            setSources((prev) => prev.filter(({ id }) => !selectedSources.some((removeId) => removeId === id)));
            setSelectedSource([]);
            updateLists();
            success(`Выбранные источники успешно удаленны из списка ${getListInfo.title}`);
        }).catch((err) => {
            error('При удалении из произошла ошибка');
            // eslint-disable-next-line no-console
            console.log('При удалении из произошла ошибка', err);
        });
    };
    const handleChangeSelectedSource = ({ id }: ITestRow) => {
        if (!selectedSources.some((idCurrent) => id === idCurrent)) {
            setSelectedSource((prev) => [...prev, id]);
            return;
        }
        setSelectedSource((prev) => prev.filter((idCurrent) => idCurrent !== id));
    };
    const handleAddSourceToList = (list_ids: number[], sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        updateSources({
            list_ids,
            source_ids: [sourceId],
        }).then(() => {
            // setSources((prev) => [...prev.map((source) => (
            //     { ...source, title: 'HUE', lists: sourceId === source.id ? [...source.lists, ...list_ids] : source.lists }))]);
            setSources((prev) => {
                const clone = [...prev];
                const updated = clone.map((source) => ({
                    ...source,
                    lists: sourceId === source.id ? [...source.lists, ...list_ids] : source.lists,
                }));
                return updated;
            });
            updateLists();
            success(`${title} успешно добавлен в выбранные списки`);
        }).catch((err) => {
            // message.error('При добавлении в избранное произошла ошибка');
            error(`При добавлении ${title} в списки произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При добавлении в списки произошла ошибка', err);
        });
    };
    const handleAddFavorite = (sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        if (typeof getFavoriteId !== 'number') return;
        updateSources({
            list_ids: [getFavoriteId],
            source_ids: [sourceId],
        }).then(() => {
            setSources((prev) => prev.map((source) => (
                { ...source, lists: sourceId === source.id ? [...source.lists, getFavoriteId] : source.lists })));
            success(`${title} успешно добавлен в избранное`);
            updateLists();
        }).catch((err) => {
            // message.error('При добавлении в избранное произошла ошибка');
            error(`При добавлении ${title} в избраное произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При добавлении в избраное произошла ошибка', err);
        });
    };
    const handleRemoveFromFavorite = (sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        if (typeof getFavoriteId !== 'number') return;
        removeSourcesFromList({
            list_id: getFavoriteId,
            source_ids: [sourceId],
        }).then(() => {
            setSources((prev) => prev.map((source) => (
                { ...source, lists: sourceId === source.id ? source.lists.filter((id) => id !== getFavoriteId) : source.lists })));
            success(`${title} успешно убран из избраного`);
        }).catch((err) => {
            error(`При удалении ${title} из избраного произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При удалении из избранного произошла ошибка', err);
        });
    };
    const handleGetNextSource = () => {
        getSources({
            page: 1,
            filterParams: {},
            orderBy: 'subscribers',
            orderDirection: SortOrder.desc,
            rangeParams: {},
            limit: LIMIT_LISTS_COUNTER,
            list_id,
            prevPageToken: nextToken,
        }).then((res) => {
            if (res?.data) {
                setSources((prev) => [...prev, ...res.data.sources]);
                setNextToken(res.data.nextPageToken);
            }
        });
    };
    useEffect(() => {
        if (!list_id) return;
        if (!sources.length && !nextToken) {
            getSources({
                page: 1,
                filterParams: {},
                orderBy: 'subscribers',
                orderDirection: SortOrder.desc,
                rangeParams: {},
                limit: LIMIT_LISTS_COUNTER,
                list_id,
            }).then((res) => {
                if (res?.data) {
                    setSources(res.data.sources);
                    setNextToken(res.data.nextPageToken);
                }
            });
        }
    }, [list_id]);
    useEffect(() => () => {
        setSources([]);
        setNextToken('');
    }, []);
    return (
        <div className={styles.detailCardInfo}>
            {contextHolder}
            <ModalAddSources
                favoritId={getFavoriteId}
                exceptionsIds={sources.map(({ id }) => id)}
                success={success}
                error={error}
                isOpen={isOpenModal}
                onAddSources={handleAddToList}
                close={() => setIsOpenModal(false)}
            />
            <div className={styles.detailCardInfo_controls}>
                <Button
                    text="Убрать из списка"
                    type="secondaryDanger"
                    size="medium"
                    disabled={selectedSources?.length <= 0}
                    onClick={handleRemoveFromList}
                />
                <Button
                    text="Добавить в список"
                    type="default"
                    size="medium"
                    onClick={() => setIsOpenModal(true)}
                />
            </div>
            <div className={styles.detailCardInfo_content}>
                <TabelSources
                    listSources={sources}
                    lists={lists}
                    isLoading={isFetching || isLoading}
                    favoriteId={getFavoriteId}
                    isDisabledInfinityScroll={!nextToken}
                    listExceptedIds={[]}
                    onAddSourceToList={handleAddSourceToList}
                    onAddFavorite={handleAddFavorite}
                    onRemoveFromFavorite={handleRemoveFromFavorite}
                    onGetNextSource={handleGetNextSource}
                    onChangeSelected={handleChangeSelectedSource}
                />
            </div>
        </div>
    );
};
export default DetailCardInfo;
