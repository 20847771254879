import React, { useMemo, useRef, useState } from 'react';
import { SortOrder } from '@services/sources/types';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { ICustomTableColumn } from '@entities/TableProjectResults/types';
import classNames from 'classnames';
import SvgIcon from '@components/SvgIcon';
import TableSort from '../../modals/TableSort';
import styles from './styles.module.scss';
import TableHeaderCompositeResults from './CompositeHeaderCell';

interface IProps {
    column: ICustomTableColumn;
    sort: { column: ICustomTableColumn; order: SortOrder } | null;
    search: { column: ICustomTableColumn, value: string } | null;
    onSort?: (column: ICustomTableColumn, order: SortOrder) => void;
    onSearch?: (column: ICustomTableColumn, value: string) => void;
    clearSearch: () => void;
}
const TableHeaderProjectResult = (
    props: IProps,
): React.ReactElement => {
    const {
        column, sort, onSort, onSearch, search,
        clearSearch,
    } = props;
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [searchVisible, setSearchVisible] = useState<boolean>(false);
    const modalRef = useRef(null);
    const searchModalRef = useRef(null);
    const isSorted = useMemo<boolean>(() => {
        if (!sort) return false;
        return sort.column.id === column.id;
    }, [sort]);

    const isModalOptionsExist = useMemo(
        () => column.sortable || column.renderFilter,
        [column.sortable, column.renderFilter],
    );

    const searchValue = useMemo(() => {
        if (!search) return '';
        if (search.column.id !== column.id) return '';
        return search.value;
    }, [search]);

    const closeModal = (): void => {
        setModalVisible(false);
    };

    const handleClickSort = (sortOrder: SortOrder) => {
        onSort(column, sortOrder);
        closeModal();
    };

    useOnClickOutside(modalRef, closeModal);
    useOnClickOutside(searchModalRef, () => setSearchVisible(false));

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onSearch(column, value);
    };

    const headerWrapperClass = useMemo(() => {
        if (['viewsPlan', 'erPlan', 'CPVPlan'].includes(column.dataIndex)) {
            return classNames(styles.wrapper, styles.wrapperComposite);
        }
        if (['viewsFact', 'erFact', 'CPVFact'].includes(column.dataIndex)) {
            return classNames(styles.wrapper, styles.wrapperComposite);
        }
        if (['viewsPercent', 'erPercent', 'CPVPercent'].includes(column.dataIndex)) {
            return classNames(styles.wrapper, styles.wrapperComposite);
        }
        return styles.wrapper;
    }, [column.dataIndex]);

    const headerCellClass = useMemo(() => {
        switch (column.dataIndex) {
            case 'comment':
                return classNames(styles.resultsCell, styles.resultsCellComment);
            case 'viewsPlan':
                return classNames(styles.resultsCell, styles.resultsCellPlanViews);
            case 'erPlan':
                return classNames(styles.resultsCell, styles.resultsCellPlanER);
            case 'CPVPlan':
                return classNames(styles.resultsCell, styles.resultsCellPlanCPV);
            case 'viewsFact':
                return classNames(styles.resultsCell, styles.resultsCellFactViews);
            case 'erFact':
                return classNames(styles.resultsCell, styles.resultsCellFactER);
            case 'CPVFact':
                return classNames(styles.resultsCell, styles.resultsCellFactCPV);
            case 'viewsPercent':
                return classNames(styles.resultsCell, styles.resultsCellPercentViews);
            case 'erPercent':
                return classNames(styles.resultsCell, styles.resultsCellPercentER);
            case 'CPVPercent':
                return classNames(styles.resultsCell, styles.resultsCellPercentCPV);
            default:
                return styles.resultsCell;
        }
    }, [column.dataIndex]);

    const addExtraCellForComposite = useMemo(() => {
        if (column.dataIndex === 'viewsPlan') {
            return <TableHeaderCompositeResults size={300} left={0} text="Просмотры" />;
        }
        if (column.dataIndex === 'erPlan') {
            return <TableHeaderCompositeResults size={285} left={8} text="ER" />;
        }
        if (column.dataIndex === 'CPVPlan') {
            return <TableHeaderCompositeResults size={300} left={0} text="CPV" />;
        }
        return null;
    }, [column.dataIndex]);

    return (
        <div className={headerWrapperClass} ref={modalRef}>
            {addExtraCellForComposite}
            <div className={styles.container}>
                <div className={headerCellClass}>
                    <div
                        onClick={() => setModalVisible((prev) => !prev)}
                        role="button"
                        className={classNames(
                            styles.cellValue,
                            isModalOptionsExist ? styles.cellButton : undefined,
                        )}
                    >
                        {column.icon || ''}
                        {column.title || ''}
                        {isModalOptionsExist && !isSorted && (
                            <SvgIcon id="SortingArrow" size={12} height={6} />
                        )}

                        {isSorted && (
                            <div
                                style={{
                                    transform:
                                        sort?.order === SortOrder.asc
                                            ? 'scale(1, -1)'
                                            : '',
                                    height: 16,
                                }}
                            >
                                <SvgIcon id="SortingIcon" size={16} />
                            </div>
                        )}
                    </div>

                    {column.searchable && (
                        <button
                            type="button"
                            onClick={() => setSearchVisible(true)}
                            className={styles.searchButton}
                        >
                            <SvgIcon
                                id="Search"
                                size={16}
                            />
                        </button>
                    )}
                </div>
                {searchVisible && column.searchable
                    && (
                        <div className={styles.cellSearch} ref={searchModalRef}>
                            <input type="text" value={searchValue} onChange={handleChangeSearch} />
                            <button type="button" onClick={clearSearch}>
                                <SvgIcon id={searchValue.length >= 1 ? 'Cross' : 'Search'} size={16} />
                            </button>
                        </div>
                    )}
            </div>

            {isModalOptionsExist && isModalVisible && (
                <div className={styles.modal} style={{ right: 0 }}>
                    {column.sortable
                        && (
                            <TableSort
                                onSort={handleClickSort}
                                type={column.sortType ?? 'number'}
                                activeOrder={sort ? sort.order : null}
                            />
                        )}

                    {column.renderFilter && column.renderFilter(column)}
                </div>
            )}
        </div>
    );
};

export default TableHeaderProjectResult;
