import React from 'react';
import { SvgIcon } from '@shared/index';
import {
    ArrowIconsIds, CrossIconsIds, LogoIconsIds, CommonIconsIds,
} from '@app/constants/icons';
import styles from './styles.module.scss';

const IconsDemo: React.FC = (): JSX.Element => (
    <div className={styles.demoIcons_root}>
        <div className={styles.groupIcons}>
            <div className={styles.groupIcons_label}>
                Стрелки
            </div>
            <div className={styles.groupIcons_list}>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: DropdownArrow - EIcons.DropdownArrow
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.DropDownArrow} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ArrowLeft - EIcons.ArrowLeft
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.ArrowLeft} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: NextArrow - EIcons.NextArrow
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.NextArrow} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: BackArrow - EIcons.BackArrow
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.BackArrow} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ArrowRight - EIcons.ArrowRight
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.ArrowRight} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: LeftArrowIcon - EIcons.LeftArrowIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.LeftArrowIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RightArrowIcon - EIcons.RightArrowIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.RightArrowIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: DropDownArrow - EIcons.DropDownArrow2
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.DropdownArrow2} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ActiveArrowIcon - EIcons.ActiveArrowIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.ActiveArrowIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: InactiveArrowIcon - EIcons.InactiveArrowIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.InactiveArrowIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: SelectArrow - EIcons.SelectArrow
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.SelectArrow} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ArrowCarousel - EIcons.ArrowCarousel
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.ArrowCarousel} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: LeftControl - EIcons.LeftControl
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.LeftControl} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RightControl - EIcons.RightControl
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.RightControl} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: SortingArrow - EIcons.SortingArrow
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={ArrowIconsIds.SortingArrow} />
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.groupIcons}>
            <div className={styles.groupIcons_label}>
                Крестики
            </div>
            <div className={styles.groupIcons_list}>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Close - EIcons.Close
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.Close} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: NewClose - EIcons.NewClose
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.NewClose} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: HintCross - EIcons.HintCross
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.HintCross} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: TagCross - EIcons.TagCross
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.TagCross} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: FailCross - EIcons.FailCross
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.FailCross} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: FailedIcon - EIcons.FailedIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.FailedIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Cross - EIcons.Cross
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.Cross} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RedCross - EIcons.RedCross
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.RedCross} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: BigCross - EIcons.BigCross
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CrossIconsIds.BigCross} />
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.groupIcons}>
            <div className={styles.groupIcons_label}>
                Логотипы
            </div>
            <div className={styles.groupIcons_list}>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RssLogo - EIcons.RssLogo
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={LogoIconsIds.RssLogo} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: TgLogo - EIcons.TgLogo
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={LogoIconsIds.TgLogo} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: VkLogo - EIcons.VkLogo
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={LogoIconsIds.VkLogo} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: OkLogo - EIcons.OkLogo
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={LogoIconsIds.OkLogo} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: YouTubeLogo - EIcons.YouTubeLogo
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={LogoIconsIds.YouTubeLogo} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ZenLogo - EIcons.ZenLogo
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={LogoIconsIds.ZenLogo} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RuTubeLogo - EIcons.RuTubeLogo
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={LogoIconsIds.RuTubeLogo} />
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.groupIcons}>
            <div className={styles.groupIcons_label}>
                Остальное
            </div>
            <div className={styles.groupIcons_list}>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: WarningCircle - EIcons.WarningCircle
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.WarningCircle} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Download - EIcons.Download
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Download} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: AddPlus - EIcons.AddPlus
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.AddPlus} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Trashcan - EIcons.Trashcan
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Trashcan} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: PlayInCircle - EIcons.PlayInCircle
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.PlayInCircle} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RemoveIcon - EIcons.RemoveIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.RemoveIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: FindIcon - EIcons.FindIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.FindIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: CheckboxArrow - EIcons.CheckboxArrow
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.CheckboxArrow} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: CampaignDownload - EIcons.CampaignDownload
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.CampaignDownload} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ChartUp - EIcons.ChartUp
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ChartUp} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ChartVerticalBar - EIcons.ChartVerticalBar
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ChartVerticalBar} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ChartLine - EIcons.ChartLine
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ChartLine} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: AddStart - EIcons.AddStart
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.AddStart} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ChangeIcon - EIcons.ChangeIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ChangeIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: BlueStar - EIcons.BlueStar
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.BlueStar} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ChartInTable - EIcons.ChartInTable
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ChartInTable} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Calendar - EIcons.Calendar
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Calendar} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ascDescIcon - EIcons.AscDescIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.AscDescIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Scales - EIcons.Scales
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Scales} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Person - EIcons.Person
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Person} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: LinkIcon - EIcons.LinkIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.LinkIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ArrowDownBlue - EIcons.ArrowDownBlue
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ArrowDownBlue} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Favorite - EIcons.Favorite
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Favorite} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: InputIcon - EIcons.InputIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.InputIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Search - EIcons.Search
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Search} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: AddInput - EIcons.AddInput
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.AddInput} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ThreeDot - EIcons.ThreeDot
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ThreeDot} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: WordIcon - EIcons.WordIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.WordIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: PDFIcon - EIcons.PDFIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.PDFIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: PPTXIcon - EIcons.PPTXIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.PPTXIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: XLSXIcon - EIcons.XLSXIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.XLSXIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ChangeMiniIcn - EIcons.ChangeMiniIcn
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ChangeMiniIcn} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RoundAddIcn - EIcons.RoundAddIcn
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.RoundAddIcn} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RoundAddIcnNoneFill - EIcons.RoundAddIcnNoneFill
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.RoundAddIcnNoneFill} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: SortingIcon - EIcons.SortingIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.SortingIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Like - EIcons.Like
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Like} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Reports - EIcons.Reports
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Reports} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Comments - EIcons.Comments
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Comments} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Views - EIcons.Views
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Views} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: CheckMark - EIcons.CheckMark
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.CheckMark} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: SuccessIcon - EIcons.SuccessIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.SuccessIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: CampaignMenuIcon - EIcons.CampaignMenuIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.CampaignMenuIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: SettingsIcon - EIcons.SettingsIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.SettingsIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ParametersIcon - EIcons.ParametersIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ParametersIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ClockIcon - EIcons.ClockIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ClockIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ChartsIcon - EIcons.ChartsIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ChartsIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ReportsIcon - EIcons.ReportsIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ReportsIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: TagsIcon - EIcons.TagsIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.TagsIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: AuthorsIcon - EIcons.AuthorsIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.AuthorsIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: TrashCanIcon - EIcons.TrashCanIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.TrashCanIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ProjectsIcon - EIcons.ProjectsIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ProjectsIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: SourcesIcon - EIcons.SourcesIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.SourcesIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: DashboardIcon - EIcons.DashboardIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.DashboardIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: CampaignIcon - EIcons.CampaignIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.CampaignIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: InfoIcon - EIcons.InfoIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.InfoIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: FilteredIcon - EIcons.FilteredIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.FilteredIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: PlusIcon - EIcons.PlusIcon
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.PlusIcon} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: noSource - EIcons.noSource
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.noSource} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: PaperClip - EIcons.PaperClip
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.PaperClip} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: GreyPencil - EIcons.GreyPencil
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.GreyPencil} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: CalendarSmall - EIcons.CalendarSmall
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.CalendarSmall} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Complete - EIcons.Complete
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Complete} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: Visibility - EIcons.Visibility
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.Visibility} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ResetControl - EIcons.ResetControl
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ResetControl} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: FullScreenControl - EIcons.FullScreenControl
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.FullScreenControl} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: RefreshCow - EIcons.RefreshCow
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.RefreshCow} />
                    </div>
                </div>
                <div className={styles.icn}>
                    <div className={styles.icn_label}>
                        Название: ArrowHorizontal - EIcons.ArrowHorizontal
                    </div>
                    <div className={styles.icn_image}>
                        <SvgIcon id={CommonIconsIds.ArrowHorizontal} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default IconsDemo;
