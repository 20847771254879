import React from 'react';
import classnames from 'classnames';
import QuickSVGLoader from '../../../QuickSVGLoader';
import { getBloggerStatisticsTypeName } from '../../../../utils/commons/commonUtils';
import { BloggerProfileStatisticsType } from '../types';
import { IBloggerProfilePublicationsProps } from './types';
import RssIcon from '../imgs/rss.svg';
import VkIcon from '../imgs/vk.svg';
import TgIcon from '../imgs/tg.svg';
import OkIcon from '../imgs/ok.svg';
import ZenIcon from '../imgs/zen.svg';

import styles from './styles.module.scss';

const getClassNameForStatisticType = (type: BloggerProfileStatisticsType): string => {
    switch (type) {
        case BloggerProfileStatisticsType.rss:
            return 'statisticContainer_rssTitle';
        case BloggerProfileStatisticsType.tg:
            return 'statisticContainer_tgTitle';
        case BloggerProfileStatisticsType.ok:
            return 'statisticContainer_okTitle';
        case BloggerProfileStatisticsType.vk:
            return 'statisticContainer_vkTitle';
        case BloggerProfileStatisticsType.zen:
            return 'statisticContainer_zenTitle';
        default:
            return '';
    }
};

const getClassIconForStatisticType = (type: BloggerProfileStatisticsType): JSX.Element => {
    switch (type) {
        case BloggerProfileStatisticsType.rss:
            return <img src={RssIcon} alt="RssIcon" width="24" height="24" />;
        case BloggerProfileStatisticsType.tg:
            return <img src={TgIcon} alt="TgIcon" width="24" height="24" />;
        case BloggerProfileStatisticsType.ok:
            return <img src={OkIcon} alt="OkIcon" width="24" height="24" />;
        case BloggerProfileStatisticsType.vk:
            return <img src={VkIcon} alt="VkIcon" width="24" height="24" />;
        case BloggerProfileStatisticsType.zen:
            return <img src={ZenIcon} alt="ZenIcon" width="24" height="24" />;
        default:
            return null;
    }
};

const BloggerProfilePublications: React.FC<IBloggerProfilePublicationsProps> = ({
    statistics, publications, isLoading,
    addVisiblePublication, isMaxPublicationsAmount,
    isRetinaSize,
}): JSX.Element => {
    const handlerAppendPublications = (): void => {
        if (!isLoading) {
            addVisiblePublication();
        }
    };
    return (
        <div className={styles.root}>
            <div className={classnames(styles.titleContainer, styles[isRetinaSize ? 'retina' : ''])}>
                <div className={classnames(styles.titleContainer_leftBox, styles[isRetinaSize ? 'retina' : ''])}>
                    Публикации аккаунта
                </div>
                <div className={classnames(styles.titleContainer_rightBox, styles[isRetinaSize ? 'retina' : ''])}>
                    Данные за последние 7 дней
                </div>
            </div>
            {
                isLoading && publications?.length <= 0 && (
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 400,
                    }}
                    >
                        <QuickSVGLoader />
                    </div>
                )
            }
            {
                (!isLoading || publications?.length > 0) && (
                    <>
                        <div className={classnames(styles.statisticContainer, styles[isRetinaSize ? 'retina' : ''])}>
                            {
                                statistics?.length > 0 && (
                                    statistics.map((item) => (
                                        <div key={item.type} className={styles.statisticContainer_item}>
                                            <div className={classnames(
                                                styles.statisticContainer_item_title,
                                                styles[getClassNameForStatisticType(item.type)],
                                            )}
                                            >
                                                {
                                                    getClassIconForStatisticType(item.type)
                                                }
                                                {
                                                    getBloggerStatisticsTypeName(item.type)
                                                }
                                            </div>
                                            <div className={styles.statisticContainer_itemNumberText}>
                                                {item.value?.toLocaleString('ru-RU') ?? '-'}
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                        </div>
                        <div className={classnames(styles.publicationsContainer, styles[isRetinaSize ? 'retina' : ''])}>
                            {
                                publications?.length > 0 && (
                                    publications.map((item) => (
                                        <div className={styles.publicationsContainer_item} key={item.id}>
                                            <a
                                                className={styles.publicationsContainer_item_header}
                                                target="_blank"
                                                href={item?.link || ''}
                                                rel="noreferrer"
                                            >
                                                <div className={styles.publicationsContainer_item_date}>
                                                    {item.date}
                                                </div>
                                                <div className={styles.publicationsContainer_item_time}>
                                                    {item.time}
                                                </div>
                                            </a>
                                            <p className={styles.publicationsContainer_item_textContent}>
                                                {`${item.content.substring(0, 255)}`}
                                            </p>
                                        </div>
                                    ))
                                )
                            }
                            {
                                isLoading && (
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 100,
                                    }}
                                    >
                                        <QuickSVGLoader />
                                    </div>
                                )
                            }
                            {
                                publications?.length > 0 && !isMaxPublicationsAmount && (
                                    <div className={styles.publicationsContainer_gradientContainer}>
                                        <div className={styles.publicationsContainer_gradientBox} />
                                    </div>
                                )
                            }
                        </div>
                        {
                            publications?.length > 0 && !isMaxPublicationsAmount && (
                                <div className={styles.showButtonContainer}>
                                    <button
                                        type="button"
                                        className={classnames(
                                            styles.showButtonContainer_body,
                                            styles[isLoading ? 'disable' : ''],
                                        )}
                                        onClick={handlerAppendPublications}
                                    >
                                        Показать еще
                                    </button>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default BloggerProfilePublications;
