import Arrow from '@assets/images/back-arrow.svg';
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import SvgIcon from '@components/SvgIcon';
import { projectsStageService } from '@services/projects';
import Table from '@entities/Table';
import { ITableColumn } from '@entities/Table/types';
import moment from 'moment';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { Alert } from 'antd';
import { IProjectHistoryModel, IProjectHistoryTableModel } from '@services/projects/types';
import styles from './styles.module.scss';

enum HistoryDataModel {
    ProjectModel = 0,
    CustomerInfoModel = 1,
    CustomerContactPersonModel = 2,
    CustomerContactModel = 3,
    FileModel = 4,
    SourcesProjectModel = 5,
}

const dataModels = Object.values(HistoryDataModel);

const fieldsMap = new Map([
    ['name', 'Наименование'],
    ['description', 'Описание'],
    ['status_id', 'Статус'],
    ['finish', 'Окончание'],
    ['address', 'Адрес'],
    ['company_name', 'Наименование организации'],
    ['full_name', 'ФИО'],
    ['contact_type_id_2', 'Телефон организации'],
    ['contact_type_id_3', 'Телефон'],
    ['contact_type_id_1', 'E-mail'],
    ['type_id_3', 'Бриф файл'],
    ['longlist_source', 'Лонглист источник'],
    ['longlist_source_field', 'Лонглист, источник, поле:'],
]);

const statusLabelMap = new Map([
    [1, 'Взять в работу'],
    [2, 'В проработке'],
    [3, 'Подготовка медиаплана'],
    [4, 'Мониторинг'],
    [5, 'Подведение итогов'],
    [6, '---'],
]);

interface IProps {
    projectId: number | null;
}

let ids = 1000000;

const getKey = (): number => {
    ids -= 1;
    return ids;
};

const ProjectHistory: React.FC<IProps> = ({ projectId }): React.ReactElement => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const [
        getHistory,
        {
            data: history,
            isLoading,
            isFetching,
            isError,
        },
    ] = projectsStageService.useLazyGetHistoryQuery();
    const containerRef = useRef(null);

    const historyLoading = useMemo<boolean>(
        () => (isLoading || isFetching),
        [isLoading, isFetching],
    );

    const prepareProjectMainInfo = (data: IProjectHistoryModel): IProjectHistoryTableModel[] => {
        if (!data?.action || !data?.rawNewData) {
            return [];
        }
        if (data.action === 'CREATE') {
            return [
                {
                    id: getKey(),
                    field: fieldsMap.get('name'),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.name ?? '-',
                },
                {
                    id: getKey(),
                    field: fieldsMap.get('description'),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.description ?? '-',
                },
                {
                    id: getKey(),
                    field: fieldsMap.get('status_id'),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.status_id ? statusLabelMap.get(data.rawNewData.status_id) : '-',
                },
            ];
        }
        if (data.action === 'UPDATE' && data?.dataChanges?.length > 0) {
            return data?.dataChanges?.filter(({ field }) => fieldsMap.has(field))
                .map((item) => {
                    const result = {
                        id: getKey(),
                        field: fieldsMap.get(item.field),
                        date: data.created,
                        oldValue: item.oldValue ?? '-',
                        newValue: item.newValue ?? '-',
                    };
                    if (item.field === 'status_id') {
                        result.oldValue = statusLabelMap.get(Number(item.oldValue));
                        result.newValue = statusLabelMap.get(Number(item.newValue));
                    }
                    return result;
                });
        }

        return [];
    };

    const prepareCustomerInfoModel = (data: IProjectHistoryModel): IProjectHistoryTableModel[] => {
        if (!data?.action || !data?.rawNewData) {
            return [];
        }
        if (data.action === 'CREATE') {
            return [
                {
                    id: getKey(),
                    field: fieldsMap.get('address'),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.address ?? '-',
                },
                {
                    id: getKey(),
                    field: fieldsMap.get('company_name'),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.company_name ?? '-',
                },
            ];
        }
        return [];
    };

    const prepareCustomerContactPersonModel = (data: IProjectHistoryModel): IProjectHistoryTableModel[] => {
        if (!data?.action || !data?.rawNewData) {
            return [];
        }
        if (data.action === 'CREATE') {
            return [
                {
                    id: getKey(),
                    field: fieldsMap.get('full_name'),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.full_name ?? '-',
                },
            ];
        }
        return [];
    };

    const prepareCustomerContactModel = (data: IProjectHistoryModel): IProjectHistoryTableModel[] => {
        if (!data?.action || !data?.rawNewData) {
            return [];
        }
        if (data.action === 'CREATE') {
            const result = [];

            if ([1, 2, 3].includes(data.rawNewData?.contact_type_id)) {
                result.push({
                    id: getKey(),
                    field: fieldsMap.get(`contact_type_id_${data.rawNewData.contact_type_id}`),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.value ?? '-',
                });
            }

            return result;
        }
        return [];
    };

    const prepareFileModel = (data: IProjectHistoryModel): IProjectHistoryTableModel[] => {
        if (!data?.action || !data?.rawNewData) {
            return [];
        }
        if (data.action === 'CREATE') {
            return [
                {
                    id: getKey(),
                    field: fieldsMap.get(`type_id_${data.rawNewData.type_id}`),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.name ?? '-',
                },
            ];
        }
        return [];
    };

    const prepareSourcesProjectModel = (data: IProjectHistoryModel): IProjectHistoryTableModel[] => {
        if (!data?.action || !data?.rawNewData) {
            return [];
        }
        if (data.action === 'CREATE') {
            return [
                {
                    id: getKey(),
                    field: fieldsMap.get('longlist_source'),
                    date: data.created,
                    oldValue: '-',
                    newValue: data.rawNewData?.title ?? '-',
                    link: data.rawNewData?.link ?? '-',
                },
            ];
        }
        if (data.action === 'UPDATE' && data?.dataChanges?.length > 0) {
            return data?.dataChanges?.map((item) => {
                const result = {
                    id: getKey(),
                    field: `${fieldsMap.get('longlist_source_field')} ${item.field}`,
                    date: data.created,
                    oldValue: item.oldValue ?? '-',
                    newValue: item.newValue ?? '-',
                };
                return result;
            });
        }
        // НЕ УДАЛЯТЬ!!! (может понадобиться, после доработке макета)
        // if (data.action === 'UPDATE' && data?.dataChanges?.length > 0) {
        //     return [{
        //         id: getKey(),
        //         field: fieldsMap.get('longlist_source_fields'),
        //         date: data.created,
        //         isGrid: true,
        //         oldGridRows: [
        //             ...(data?.dataChanges ?? []).map((item) => item.field),
        //             ...(data?.dataChanges ?? []).map((item) => item.oldValue),
        //         ],
        //         newGridRows: [
        //             ...(data?.dataChanges ?? []).map((item) => item.field),
        //             ...(data?.dataChanges ?? []).map((item) => item.newValue),
        //         ],
        //         oldValue: '',
        //         newValue: '',
        //     }];
        // }
        // НЕ УДАЛЯТЬ!!!
        return [];
    };

    const selectHandler = (type: HistoryDataModel, data: IProjectHistoryModel): IProjectHistoryTableModel[] => {
        switch (type) {
            case HistoryDataModel.ProjectModel:
                return prepareProjectMainInfo(data);
            case HistoryDataModel.CustomerInfoModel:
                return prepareCustomerInfoModel(data);
            case HistoryDataModel.CustomerContactPersonModel:
                return prepareCustomerContactPersonModel(data);
            case HistoryDataModel.CustomerContactModel:
                return prepareCustomerContactModel(data);
            case HistoryDataModel.FileModel:
                return prepareFileModel(data);
            case HistoryDataModel.SourcesProjectModel:
                return prepareSourcesProjectModel(data);
            default:
                return [];
        }
    };

    const historyData = useMemo<IProjectHistoryTableModel[]>(() => {
        const result: IProjectHistoryTableModel[] = [];
        (history ?? [])
            .filter((item) => dataModels.includes(item.model))
            .forEach((item) => result.push(...selectHandler(HistoryDataModel[item.model], item)));
        return result;
    }, [history]);

    useEffect(() => {
        if (projectId && isOpen) {
            getHistory({ id: projectId });
        }
    }, [projectId, isOpen]);

    const toggleOpen = () => {
        return;
        setOpen(true);
    };
    const close = () => {
        setOpen(false);
    };

    const columns: ITableColumn<IProjectHistoryTableModel>[] = [
        {
            id: 1,
            title: 'Поле',
            dataIndex: 'field',
            render: (value) => (
                <div className={styles.cellFields}>
                    {value}
                </div>
            ),
            width: 170,
        },
        {
            id: 2,
            title: 'Дата и время',
            dataIndex: 'date',
            render: (created: string) => (
                <span>{moment(created).format('DD.MM.YYYY HH:mm')}</span>
            ),
            width: 150,
        },
        {
            id: 3,
            title: 'Исходное значение',
            dataIndex: 'oldValue',
            render: (value, record): JSX.Element => {
                if (record?.link) {
                    return (
                        <a href={record?.link} target="_blank" className={styles.link} rel="noreferrer">
                            <p>{value}</p>
                        </a>
                    );
                }
                if (record?.isGrid && record?.oldGridRows?.length > 1) {
                    return (
                        <div
                            className={styles.gridValues}
                            style={{
                                gridTemplateColumns: `repeat(${record.oldGridRows.length / 2}, 1fr)`,
                            }}
                        >
                            {record.oldGridRows.map((item) => (<span key={getKey()}>{item}</span>))}
                        </div>
                    );
                }
                return <span>{value}</span>;
            },
        },
        {
            id: 4,
            title: 'Новое значение',
            dataIndex: 'newValue',
            render: (value, record): JSX.Element => {
                if (record?.link) {
                    return (
                        <a href={record?.link} target="_blank" className={styles.link} rel="noreferrer">
                            <p>{value}</p>
                        </a>
                    );
                }
                if (record?.isGrid && record?.newGridRows?.length > 1) {
                    return (
                        <div
                            className={styles.gridValues}
                            style={{
                                gridTemplateColumns: `repeat(${record.newGridRows.length / 2}, 1fr)`,
                            }}
                        >
                            {record.newGridRows.map((item) => (<span key={getKey()}>{item}</span>))}
                        </div>
                    );
                }
                return <span>{value}</span>;
            },
        },
    ];

    useOnClickOutside(containerRef, close);

    return (
        <>
            <div
                role="button"
                className={styles.status_history}
                onClick={toggleOpen}
            >
                <span className={styles.status_link}>История изменений</span>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => null}
                    onClick={() => null}
                    className={styles.status_btnArrow}
                >
                    <img src={Arrow} alt="Arrow" />
                </div>
            </div>
            {isOpen && (
                <div className={styles.sidebarWrapper}>
                    <div className={styles.sidebarContainer} ref={containerRef}>
                        <div className={styles.sidebarHeader}>
                            <h2>История изменений</h2>
                            <button type="button" onClick={close}>
                                <SvgIcon id="Cross" size={24} />
                            </button>
                        </div>
                        <div style={{
                            height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto',
                        }}
                        >
                            <Table
                                columns={columns}
                                rows={historyData ?? []}
                                isLoading={historyLoading}
                            />
                        </div>
                        {!historyLoading && historyData?.length < 1 && !isError && (
                            <Alert
                                type="info"
                                message="Нет записей."
                            />
                        )}
                        {isError && !historyLoading && (
                            <Alert
                                type="error"
                                message="Произошла ошибка при получении данных с сервера."
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default ProjectHistory;
