import { rtkQApi } from '../..';
import { IQPQueryString, IQueryStringFromApi } from './types';

export const campaignSearchRequestRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postLoadPreview: build.query<IQueryStringFromApi, IQPQueryString>({
            query: (params: IQPQueryString) => ({
                url: 'campaign/search/preview',
                method: 'POST',
                body: {
                    query_string: params.queryString,
                    highlight_limit: 200,
                    post_preview_limit: 10,
                },
            }),
            transformResponse: (baseQueryResponse: IQueryStringFromApi): IQueryStringFromApi => ({
                count: baseQueryResponse.count,
                highlight: baseQueryResponse?.highlight.map((item, index) => (
                    { id: index, title: item.title, content: item.content }
                )),
            } as IQueryStringFromApi),
        }),
    }),
});
