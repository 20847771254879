import React from 'react';
import { ICampaignDropdownListMenuProps } from './types';
import LinearLoader from '../../../../../common/Loader';

import styles from './styles.module.scss';

const CampaignDropdownListMenu: React.FC<ICampaignDropdownListMenuProps> = ({ categories, onSelectCategory }): JSX.Element => {
    const handlerClickItem = (id: string): void => {
        onSelectCategory(Number(id));
    };
    return (
        <div className={styles.root}>
            {
                !categories?.length && (
                    <div className={styles.loaderContainer}>
                        Подгружаем список
                        <LinearLoader />
                    </div>
                )
            }
            {
                categories?.length > 0 && (
                    <div className={styles.wrapper}>
                        {
                            categories?.map((item) => (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                <span className={styles.item} key={item.id} onClick={() => handlerClickItem(item.id)}>{item.mainCat}</span>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default CampaignDropdownListMenu;
