import React from 'react';
import styles from './styles.module.scss';
import BigCross from '../../../../../assets/images/crossLite.svg';

interface IProps {
    categories: string[];
    setIsClickArrow: (value: boolean) => void;
    setActiveRow: (value: number) => void;
}
const SourcesCategoryBlock: React.FC<IProps> = ({
    categories,
    setIsClickArrow,
    setActiveRow,
}): JSX.Element => {
    const handlerClickCross = () => {
        setIsClickArrow(false);
        setActiveRow(null);
    };
    return (
        <div className={styles.categoryBlock}>
            <div className={styles.categoryBlock_head}>
                <span className={styles.categoryBlock_title}>Категории:</span>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => null}
                    onClick={handlerClickCross}
                    style={{ cursor: 'pointer' }}
                >
                    <img src={BigCross} alt="BigCross" />
                </div>
            </div>
            <div className={styles.categoryBlock_block}>
                {/* eslint-disable-next-line react/no-array-index-key */}
                {categories.map((category, index) => <span key={index} className={styles.categoryBlock_category}>{category}</span>)}
            </div>
        </div>
    );
};

export default SourcesCategoryBlock;
