import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Path from '../../shared/lib/paths';
import AccessForm from '../../components/RequestAccessInput/AccessForm/AccessForm';
import FinishAccessForm from '../../components/RequestAccessInput/FinishForm/FinishForm';
import { sendRequestAccessThunk } from '../../store/request-access/thunks/send-request-access';
import { useAppDispatch } from '../../store/hooks';
import { requestAccessActions } from '../../store/request-access';
import { IRequestAccess } from '../../store/request-access/types';
import ResheniyLogoIcon from '../../assets/images/resheniy-logo.svg';
import { selectRequestAccessStatus } from '../../store/request-access/selectors';
import { EmailSendStatus } from '../../store/types';
import BackArrowIcon from '../../assets/images/back-arrow.svg';
import BarsIcon from '../../assets/images/bars-background.svg';
import ChartIcon from '../../assets/images/chart-background.svg';

import styles from './styles.module.scss';

const RequestAccessPage: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const sendStatus = useSelector(selectRequestAccessStatus);

    const [isVisibleAccessForm, setVisibleAccessForm] = useState<boolean>(
        sendStatus === EmailSendStatus.undefined
        || sendStatus === EmailSendStatus.failed,
    );

    const handlerSendRequestAccess = ({
        organizationName, phone, userPost, comment, userName, email,
    }: IRequestAccess): void => {
        if (
            !organizationName || !phone || !userPost
            || !userName || !email
        ) {
            return;
        }
        dispatch(requestAccessActions.setRequestData({
            organizationName,
            phone,
            userPost,
            comment: comment ?? '',
            userName,
            email,
        } as IRequestAccess));
        dispatch(sendRequestAccessThunk());
    };

    useEffect(() => {
        setVisibleAccessForm(sendStatus === EmailSendStatus.undefined || sendStatus === EmailSendStatus.failed);
    }, [sendStatus]);

    return (
        <div className={styles.root}>
            <div className={styles.logoContainer}>
                <img src={ResheniyLogoIcon} alt="BigLogo" />
            </div>
            <div className={classnames(styles.formContainer, styles[isVisibleAccessForm ? '' : 'finish'])}>
                {
                    isVisibleAccessForm
                        ? (
                            <div className={styles.backLinkContainer}>
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <div id="request-back" className={styles.backLinkContainer_link} onClick={() => navigate(Path.Auth)}>
                                    <img src={BackArrowIcon} alt="back" />
                                    Назад
                                </div>
                            </div>
                        )
                        : null
                }
                {
                    isVisibleAccessForm
                        ? (<AccessForm onSendRequest={handlerSendRequestAccess} />)
                        : (<FinishAccessForm />)
                }
            </div>
            <div className={styles.footerContainer} />
            <div className={styles.barsContainer}>
                <img src={BarsIcon} alt="BarsIcon" />
            </div>
            <div className={styles.chartContainer}>
                <img src={ChartIcon} alt="ChartIcon" />
            </div>
        </div>
    );
};

export default RequestAccessPage;
