import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import PercentIcon from '@assets/images/percent.svg';
import { DateRangePickerValue } from '@components/DateRangePicker/types';
import SortButton from '@components/SortButton';
import { campaignTagsRtkQService } from '@services/campaigns/tags';
import { IQueryParamsLoadTags, IQuerySortFieldsLoadTags, ITagModel } from '@services/campaigns/tags/types';
import { SortOrder } from '@services/sources/types';
import { ICampaignTagsHeaderColumn } from '../types';
import CampaignUserTagsTableRow from './CampaignUserTagsTableRow';
import styles from './styles.module.scss';

interface IProps {
    period: DateRangePickerValue;
    campaignid: number;
}

const limit = 100;

const CampaignUserTagsTable: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { period, campaignid } = props;
    const [getUserTags, {
        data: tags,
        isFetching: isTagsFetching,
        isSuccess: isTagsSuccess,
        isError: isTagsError,
    }] = campaignTagsRtkQService.useLazyPostLoadUserTagsQuery();
    const loaderMark = useRef(null);
    const [tagsList, setTagsList] = useState<ITagModel[]>([]);
    const [acceptIntersection, setAcceptIntersection] = useState<boolean>(true);
    const [params, setParams] = useState<IQueryParamsLoadTags>({
        campaign_id: campaignid,
        page: 1,
        from_date: period[0].format('YYYY-MM-DD'),
        to_date: period[1].format('YYYY-MM-DD'),
        order: SortOrder.desc || null,
        column: IQuerySortFieldsLoadTags.postsCount || null,
    });

    useEffect(() => {
        getUserTags(params).finally(() => setAcceptIntersection(true));
    }, [params]);

    const handleClickSort = (column: IQuerySortFieldsLoadTags, order: SortOrder): void => {
        setAcceptIntersection(false);
        setParams((prev) => ({
            ...prev,
            order,
            column: order === null ? null : column,
            page: 1,
        }));
        setTagsList([]);
    };

    useEffect(() => {
        setAcceptIntersection(false);
        setParams((prev) => ({
            ...prev,
            from_date: period[0].format('YYYY-MM-DD'),
            to_date: period[1].format('YYYY-MM-DD'),
            page: 1,
        }));
        setTagsList([]);
    }, [period]);

    useEffect(() => {
        if (tags && tags.length >= 1) {
            setTagsList((prev) => prev.concat(tags));
        } else {
            setTagsList([]);
        }
    }, [tags]);

    const handlerIntersection = (e) => {
        const target = e[0];
        if (target.isIntersecting && acceptIntersection && tags && tags.length >= limit && !isTagsFetching) {
            setParams((prev) => ({ ...prev, page: prev.page + 1 }));
            setAcceptIntersection(false);
        }
    };
    useEffect(() => {
        const observer = new IntersectionObserver(handlerIntersection);
        if (loaderMark.current) observer.observe(loaderMark.current);
        return () => {
            if (loaderMark.current) observer.unobserve(loaderMark.current);
        };
    }, [loaderMark.current]);

    const columns: ICampaignTagsHeaderColumn[] = [
        {
            id: 1,
        },
        {
            id: 2,
            content: <p>Тег</p>,
        },
        {
            id: 3,
            content: <p>Тип тега</p>,
        },
        {
            id: 4,
            content: <img src={PercentIcon} alt="PercentIcon" />,
            sort: {
                column: IQuerySortFieldsLoadTags.percent,
            },
        },
        {
            id: 5,
            content: <p>Сообщения</p>,
            sort: {
                column: IQuerySortFieldsLoadTags.postsCount,
            },
        },
        {
            id: 6,
            content: <p>Комментарии</p>,
            sort: {
                column: IQuerySortFieldsLoadTags.comments,
            },
        },
        {
            id: 7,
            content: <p>Просмотры</p>,
            sort: {
                column: IQuerySortFieldsLoadTags.views,
            },
        },
        {
            id: 8,
            content: <p>Вовлеченность</p>,
            sort: {
                column: IQuerySortFieldsLoadTags.reactions,
            },
        },
        {
            id: 9,
            content: <p>Действия</p>,
        },
    ];

    return (
        <div className={styles.table}>
            <div className={styles.header}>
                {columns.map((item) => (
                    <div key={item.id} className={classNames(styles.headerColumn)}>
                        {item.content && item.content}
                        {item.sort && (
                            <div className={classNames(styles.headerColumnSortButton, params.column === item.sort.column ? styles.headerColumnSortButtonVisible : '')}>
                                <SortButton
                                    sort={params.column === item.sort.column ? params.order : null}
                                    onClick={(sortOrder) => handleClickSort(item.sort.column, sortOrder)}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className={styles.body}>
                {tagsList && tagsList.length >= 1 && tagsList.map((item, index) => (
                    <CampaignUserTagsTableRow
                        key={item.title + item.comments}
                        tag={item}
                        index={index + 1}
                        campaignid={campaignid}
                    />
                ))}
                {isTagsFetching
                    && <Skeleton count={12} height={44} />}

                {tags?.length < 1 && isTagsSuccess && !isTagsFetching && (
                    <div className={styles.noData}>
                        <p>Нет данных</p>
                    </div>
                )}
                {isTagsError && (
                    <div className={styles.error}>
                        <p>Произошла ошибка при получении данных</p>
                    </div>
                )}

                <div style={{ width: '100%', height: '50px' }} ref={loaderMark} />
            </div>
        </div>
    );
};
export default CampaignUserTagsTable;
