import React from 'react';
import { Document } from '@react-pdf/renderer';
import { IPDFHandlerReportPage } from '../../../../store/pdf-handler/report/types';

interface IPDFWrapperContainerProps {
    pages: IPDFHandlerReportPage[],
}

const PDFWrapperDocument: React.FC<IPDFWrapperContainerProps> = ({ pages }): JSX.Element => {
    if (!pages?.length) {
        return null;
    }
    return (
        <Document>
            {
                pages
                    .filter((page) => Boolean(page?.body))
                    .sort((first, second) => first.sequentialNumber - second.sequentialNumber)
                    .map((page) => (page.body))
            }
        </Document>
    );
};

export default PDFWrapperDocument;
