import { combineReducers } from 'redux';
import categoryReducer from './category';
import periodReducer from './period';
import regionReducer from './region';
import subpageReducer from './subpage';
import subpageResultReducer from './subpage/result';
import sourceReducer from './source';
import infoBasketTableFiltersReducer from './table';
import bloggerInfoReducer from './blogger';

const infoBasketReducers = () => combineReducers({
    category: categoryReducer,
    period: periodReducer,
    region: regionReducer,
    subpage: subpageReducer,
    subpageResult: subpageResultReducer,
    source: sourceReducer,
    infoBasketTableFilters: infoBasketTableFiltersReducer,
    bloggerInfo: bloggerInfoReducer,
});

export default infoBasketReducers;
