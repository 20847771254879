import React, {
    useCallback,
    useEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
    style?: React.CSSProperties;
    label?: string;
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    className?: string;
    wrapperClassName?: string;
    disabled?: boolean;
    readonly?: boolean;
    limit?: number
    defaultHeight?: number
}

const DefaultMinHeight = 105;
const MaxHeight = 300;
const DefaultLimit = 3000;

const TextArea: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        style,
        label,
        placeholder,
        onChange,
        value,
        className,
        wrapperClassName,
        disabled,
        readonly,
        limit,
        defaultHeight,
    } = props;
    const [textAreaHeight, setTextAreaHeight] = useState<number>(defaultHeight ?? DefaultMinHeight);
    const [focus, setFocus] = useState<boolean>(false);
    const textAreaRef = useRef(null);
    const maxLimit = limit ?? DefaultLimit;

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const newValue = e.target.value.substring(0, maxLimit);
        onChange(newValue);
    };

    const getHeight = useCallback(() => {
        const minHeight = defaultHeight ?? DefaultMinHeight;
        if (!value || !textAreaRef.current) return minHeight;
        if (textAreaRef.current.scrollHeight < minHeight) return minHeight;
        return textAreaRef.current.scrollHeight;
    }, [value, defaultHeight, textAreaRef]);

    useEffect(() => {
        const newHeight = getHeight();
        setTextAreaHeight(newHeight);
    }, [value, defaultHeight, textAreaRef]);

    const inputClassName = useMemo(() => {
        const arr = [styles.textarea];
        if (value?.length >= 1) arr.push(styles.textareaWithText);
        if (className) arr.push(className);
        if (textAreaHeight > MaxHeight) arr.push(styles.textareaWithScroll);
        return arr;
    }, [value, className, textAreaHeight]);

    const classNameForWrapper = useMemo(() => (wrapperClassName || styles.textareaWrapper), [wrapperClassName]);

    return (
        <div className={styles.wrapper}>
            {label && <p className={styles.label}>{label}</p>}
            <div
                className={classNames(classNameForWrapper, styles.container)}
                data-disabled={disabled}
                data-readonly={readonly}
                data-focus={focus}
            >
                <textarea
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className={inputClassName.join(' ')}
                    disabled={disabled}
                    readOnly={readonly}
                    ref={textAreaRef}
                    style={{ height: textAreaHeight, maxHeight: MaxHeight, ...style }}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
                {value.length >= 1 && (
                    <div className={classNames(styles.counter, value.length === maxLimit ? styles.counterMax : '')}>
                        {`${value.length} / ${maxLimit}`}
                    </div>
                )}
            </div>
        </div>
    );
};
export default TextArea;
