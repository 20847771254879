import React, { useMemo, useState } from 'react';
// import Skeleton from 'react-loading-skeleton';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';
import CollapseContainer from '@components/Project/CollapseContainer';
import IconButtonWithLabel from '@entities/IconButtonWithLabel';
import useDocuments from '@components/Project/hooks/useDocuments';
import { message } from 'antd';
import LinearLoader from '@components/common/Loader';
import { projectsListService } from '@services/projects';
import styles from './styles.module.scss';

interface IProps {
    comment: string,
    projectId: number,
}

// eslint-disable-next-line arrow-body-style
const ResultInfo: React.FC<IProps> = ({ comment, projectId }): JSX.Element => {
    const [isFull, setIsFull] = useState(false);
    const handleChangeOpen = () => {
        setIsFull((prev) => !prev);
    };
    const [downloadDocument, { isLoading: isLoadingDownload }] = projectsListService.useDownloadDocumentMutation();
    const [downloadId, setDownloadId] = useState<number>(-1);
    const [savedDocuments] = useDocuments(false, projectId, 4);
    const getOnlyResultsDocs = useMemo(() => savedDocuments?.filter((doc) => doc.type_id === 4) || [], [savedDocuments]);
    const handleClickOnDownload = (id: number) => {
        setDownloadId(id);
        downloadDocument({ url: `${id}?project_id=${+projectId}` }).then((res: any) => {
            if (!res?.data) {
                message.error('При загрузке файла произошла ошибка');
            } else {
                window.open(res?.data?.file_url, '_blank');
                message.success('Файл успешно загружен');
            }
        })
            .catch(() => {
                message.error('При загрузке файла произошла ошибка');
            })
            .finally(() => {
                setDownloadId(-1);
            });
    };
    return (
        <DefaultWrapperProject style={{ marginBottom: 16 }}>
            <div className={styles.container}>
                <div className={styles.title}>Результаты проекта</div>
                <CollapseContainer
                    isOpen={isFull}
                    setOpen={handleChangeOpen}
                    classesForContainer={styles.containCollapse}
                    classesForControll={styles.classForControll}
                    style={{
                        maxHeight: 122,
                    }}
                >
                    <div className={styles.content}>
                        <div className={styles.subTitle}>Комментарий</div>
                        <div className={styles.comment}>
                            {comment}
                        </div>
                        <div className={styles.documents}>
                            {getOnlyResultsDocs.map((doc) => (
                                <div className={styles.documents_item} key={`resultsBlock_${doc.id}_${doc.label}`}>
                                    <div className={styles.documentLabel}>{doc.label}</div>
                                    {
                                        downloadId === doc.id && isLoadingDownload
                                            ? <LinearLoader />
                                            : (
                                                <IconButtonWithLabel
                                                    iconSize={16}
                                                    iconId="Download"
                                                    label="Скачать"
                                                    onClick={() => handleClickOnDownload(doc.id)}
                                                    labelStyle={{ color: '#0EBFA1' }}
                                                />
                                            )
                                    }

                                </div>
                            ))}
                        </div>
                    </div>
                </CollapseContainer>
            </div>
        </DefaultWrapperProject>
    );
};

export default ResultInfo;
