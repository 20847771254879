/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import {
    View, StyleSheet, Font, Image,
} from '@react-pdf/renderer';
import CampaignPDFTemplatePage from '../TemplatePage';

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const styles = StyleSheet.create({
    root: {
        width: '100%',
        height: '100%',
        padding: 24,
        display: 'flex',
    },
});

interface IPDFChartList {
    data: any;
    reactElementKey: string;
    title: string;
    reportName: string;
    date: string;
}
const TemplateBarChart: React.FC<IPDFChartList> = ({
    data,
    reactElementKey,
    reportName,
    date,
    title,
}): JSX.Element => (
    <CampaignPDFTemplatePage reactElementKey={reactElementKey} reportName={reportName} date={date} title={title}>
        <View style={styles.root}>
            <View>
                <View
                    style={{
                        position: 'absolute',
                        top: '-8px',
                        left: '150px',
                        width: `${510}px`,
                        height: `${490}px`,
                    }}
                >
                    <Image
                        src={data}
                    />
                </View>
            </View>
        </View>
    </CampaignPDFTemplatePage>
);

export default TemplateBarChart;
