/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import NewLineChart from '@entities/Charts/MonitoringLineChart';
import { chartsCampaingRtqService } from '@services/campaigns/Charts';
import moment from 'moment';
import { COLORS_FOR_CHARTS, DATE_FORMAT_FOR_QUERY, TAB_LIST_MONITORING } from '@shared/lib/consts';
import LegendMonitoringChartViews from '@entities/Charts/Legend';
import MonitoringViewsTabs from '@shared/Tabs';
import ChartStatus from '@entities/ChartStatus';
import styles from './styles.module.scss';

interface IListPresets {
    shared_tab: [string[], number]
    detail_tab: [string[], number]
}

interface IProps {
    projectId: number,
    campaignId: number,
    from_date: string,
    end_date: string,
}

const MonitoringChartViews: React.FC<IProps> = ({
    projectId, campaignId, from_date, end_date,
}): JSX.Element => {
    const PRESETS: IListPresets = {
        // 96
        shared_tab: [[], campaignId],
        detail_tab: [['SOURCES'], campaignId],
    };
    const [selectTab, setSelectTab] = useState(() => TAB_LIST_MONITORING[0]);
    const [getChartData, { data, isError }] = chartsCampaingRtqService.useNewGetChartDataMutation();
    const [listChartData, setListChartData] = useState([]);
    const getLoading = useMemo(() => !!data?.data.some((item) => !item?.x?.length), [data]);
    const modifiGetChartData = (dimensions: string[], campaign_id: number) => {
        if (!campaign_id) return;
        getChartData({
            name: 'LINE CHART MONITORING',
            time_frame: 'DAY',
            chart_type: 'TIME_VIEWS_CHART',
            campaign_id,
            from_date: moment(from_date).format(DATE_FORMAT_FOR_QUERY),
            to_date: moment(end_date).format(DATE_FORMAT_FOR_QUERY),
            metrics: ['VIEWS'],
            dimensions,
            filters_include: [],
        } as any);
    };
    useEffect(() => {
        modifiGetChartData(...PRESETS[selectTab.id as keyof IListPresets]);
    }, [selectTab, from_date, end_date]);
    useEffect(() => {
        if (data?.data) {
            const needData = data.data.filter((item, index) => index <= 9);
            const chartData = needData?.map((item, index) => ({
                color: COLORS_FOR_CHARTS[index]?.default ?? '#dfdfdf',
                label: item.subjectValues ? item.subjectValues[0] : 'default',
                disabled: false,
                allColors: COLORS_FOR_CHARTS[index],
                points: item?.x?.map((dt, di: number) => ({
                    x: new Date(dt).getTime(),
                    y: item?.y[di] || 0,
                    content: null,
                })),
            }));
            setListChartData(chartData);
        } else { setListChartData([]); }
    }, [data]);
    const handleChageExceptions = (elem, disabled) => {
        setListChartData((prev) => prev.map((chart) => {
            if (chart.label !== elem.label) return chart;
            return { ...elem, disabled };
        }));
    };
    const getHandledListChartData = useMemo(() => {
        const newList = listChartData.filter((item) => !item.disabled);
        return newList;
    }, [listChartData, data, selectTab]);
    const getLegend = useMemo(() => {
        if (selectTab.id === 'shared_tab') return null;
        return (
            <LegendMonitoringChartViews
                onChange={handleChageExceptions}
                data={listChartData}
            />
        );
    }, [selectTab, listChartData]);
    const handleClickOnUpdate = () => {
        modifiGetChartData(...PRESETS[selectTab.id as keyof IListPresets]);
    };
    if (!projectId || !campaignId) return null;
    return (
        <div className={styles.monitoringChartViews}>
            <div className={styles.monitoringChartViews_tabs}>
                <MonitoringViewsTabs
                    items={TAB_LIST_MONITORING}
                    selectTab={selectTab}
                    onChange={setSelectTab}
                />
            </div>
            <NewLineChart chartData={getHandledListChartData as any} />
            <div className={styles.monitoringChartViews_legend}>
                {getLegend}
            </div>
            <ChartStatus isError={isError} isLoading={getLoading} onUpdateClick={handleClickOnUpdate} />
        </div>
    );
};

export default MonitoringChartViews;
