/* eslint-disable max-len */
const IconArrow: React.FC = (): React.ReactElement => (
    <svg
        width="14"
        height="24"
        viewBox="0 0 14 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.797 14.6292L7.36569 21.2819V0.520833C7.36569 0.3827 7.31162 0.250224 7.21539 0.152549C7.11915 0.0548735 6.98863 0 6.85253 0C6.71643 0 6.58591 0.0548735 6.48967 0.152549C6.39344 0.250224 6.33937 0.3827 6.33937 0.520833V21.2528L0.908107 14.6285C0.865023 14.5759 0.812149 14.5324 0.752505 14.5005C0.69286 14.4686 0.627613 14.449 0.560489 14.4427C0.493364 14.4364 0.425677 14.4436 0.361291 14.4638C0.296906 14.4841 0.237083 14.517 0.185238 14.5608C0.133394 14.6045 0.0905429 14.6582 0.0591324 14.7187C0.0277218 14.7792 0.00836669 14.8455 0.00217205 14.9136C-0.0040226 14.9817 0.00306458 15.0504 0.0230289 15.1158C0.0429933 15.1811 0.0754438 15.2418 0.118528 15.2944L6.47142 23.0424C6.51957 23.1012 6.5799 23.1486 6.64814 23.1811C6.71637 23.2137 6.79083 23.2305 6.86621 23.2306C7.01879 23.2306 7.16384 23.1611 7.26169 23.0424L13.5879 15.2944C13.6748 15.188 13.7165 15.0508 13.7038 14.9132C13.6911 14.7755 13.625 14.6486 13.5202 14.5604C13.4153 14.4722 13.2802 14.4299 13.1445 14.4428C13.0089 14.4557 12.8839 14.5227 12.797 14.6292Z"
            fill="#B3B6C2"
        />
        <path
            d="M12.797 14.6292L7.36569 21.2819V0.520833C7.36569 0.3827 7.31162 0.250224 7.21539 0.152549C7.11915 0.0548735 6.98863 0 6.85253 0C6.71643 0 6.58591 0.0548735 6.48967 0.152549C6.39344 0.250224 6.33937 0.3827 6.33937 0.520833V21.2528L0.908107 14.6285C0.865023 14.5759 0.812149 14.5324 0.752505 14.5005C0.69286 14.4686 0.627613 14.449 0.560489 14.4427C0.493364 14.4364 0.425677 14.4436 0.361291 14.4638C0.296906 14.4841 0.237083 14.517 0.185238 14.5608C0.133394 14.6045 0.0905429 14.6582 0.0591324 14.7187C0.0277218 14.7792 0.00836669 14.8455 0.00217205 14.9136C-0.0040226 14.9817 0.00306458 15.0504 0.0230289 15.1158C0.0429933 15.1811 0.0754438 15.2418 0.118528 15.2944L6.47142 23.0424C6.51957 23.1012 6.5799 23.1486 6.64814 23.1811C6.71637 23.2137 6.79083 23.2305 6.86621 23.2306C7.01879 23.2306 7.16384 23.1611 7.26169 23.0424L13.5879 15.2944C13.6748 15.188 13.7165 15.0508 13.7038 14.9132C13.6911 14.7755 13.625 14.6486 13.5202 14.5604C13.4153 14.4722 13.2802 14.4299 13.1445 14.4428C13.0089 14.4557 12.8839 14.5227 12.797 14.6292Z"
            fill="black"
            fillOpacity="0.2"
        />
    </svg>
);
export default IconArrow;
