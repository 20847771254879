export enum PlatformType {
    rss = 0,
    tg = 1,
    ok = 2,
    vk = 3,
}
export interface ISliderGradient {
    style1: string;
    style2: string;
    style3: string;
}
