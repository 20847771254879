import moment from 'moment';
import { useMemo, useState } from 'react';
// type zeroToFive = 0 | 1 | 2 | 3 | 4 | 5;
// type oneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
// type zeroToNine = 0 | oneToNine;
// type mm = `1${0 | 1 | 2}` | `0${oneToNine}`;
// type yyyy = `19${zeroToNine}${zeroToNine}` | `20${zeroToNine}${zeroToNine}`;
// type dd = `${0}${oneToNine}` | `${1|2}${zeroToNine}` | `3${0|1}`;
// type hours = `0${zeroToNine}` | `1${zeroToNine}` | `2${0 | 1 | 3}`
// type timeTicker = `${zeroToFive}${zeroToNine}`
// type DayTime = `${hours}:${timeTicker}:${timeTicker}`
// type DayTimeWithSeconds = `${hours}:${timeTicker}:${timeTicker}`
// type FormatDate = `${yyyy}-${mm}-${dd}`;
interface IDescriptionDate {
    date: string,
    unix: number,
    offset: number
}
type DefaultFormatts = 'DD.MM.YY' | 'DD.MM.YYYY' | 'DD.MM' | 'DD.MM.YYYY HH:mm' | 'hh:mm';
type DateSetter = (date: Date) => void;
type FormattSetter = (format: DefaultFormatts | string) => void;
type IReturnedData = [IDescriptionDate, DateSetter, FormattSetter];
const addHoursToDate = (UNIXdate:number, hours: number):number => UNIXdate + (hours * 60 * 60 * 1000);
function useGetDate(date?: Date | number, initialFormat?: DefaultFormatts | string): IReturnedData {
    const offset = new Date().getTimezoneOffset() / 60;
    const [initialDate, setInitialDate] = useState<Date>(() => (date ? new Date(date) : new Date()));
    const [format, setFormat] = useState<string>(() => initialFormat || 'DD.MM');
    const currentUnixDate = useMemo(() => initialDate.getTime(), [initialDate]);
    const dateWithUtc = useMemo(() => addHoursToDate(currentUnixDate, offset * -1), [currentUnixDate]);
    const formated = useMemo(() => moment(new Date(dateWithUtc)).format(format), [dateWithUtc]);
    const descriptionDate: IDescriptionDate = {
        date: formated,
        unix: dateWithUtc,
        offset,
    };
    return [descriptionDate, setInitialDate, setFormat];
}

export default useGetDate;
