import React, { useMemo, useRef } from 'react';
import useOnClickOutside from '../../../../../shared/hooks/useOnClickOutside';
import CrossIcon from '../../imgs/crossMenu.svg';
import PlusIcon from '../../imgs/plusMenu.svg';
import EditIcon from '../../imgs/edit.svg';
import styles from './styles.module.scss';

export interface IGroupMenu {
    id: number;
    label: string;
    img: JSX.Element;
}

interface IProps {
    isView: boolean;
    top: number;
    left: number;
    isOpen: boolean;
    onClose: () => void;
    onSelect: (id: number) => void;
    excludedItems: number[];
}

const menuItems: IGroupMenu[] = [
    { id: 1, label: 'Добавить группу', img: <img src={PlusIcon} alt="PlusIcon" /> },
    { id: 2, label: 'Убрать группу', img: <img src={CrossIcon} alt="CrossIcon" /> },
    { id: 3, label: 'Редактировать условия', img: <img src={EditIcon} alt="EditIcon" /> },
];

const viewConditions = 'Просмотреть условия';

const SearchGroupContextMenu: React.FC<IProps> = ({
    isView,
    top,
    left,
    isOpen,
    onClose,
    onSelect,
    excludedItems,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const filteredItems = useMemo<IGroupMenu[]>(() => (
        menuItems.filter(({ id }) => !excludedItems.includes(id))
    ), [excludedItems]);

    const handlerSelectItem = (id: number): void => {
        if (onSelect) {
            onSelect(id);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.searchGroupContextMenu_root} style={{ top, left }} ref={refModal}>
            <div className={styles.modalContextMenu}>
                <div className={styles.list_container}>
                    {
                        filteredItems.map((item) => (
                            <button
                                key={item.id}
                                type="button"
                                className={styles.list_item}
                                onClick={() => handlerSelectItem(item.id)}
                            >
                                <span style={{ display: 'flex', alignItems: 'center', width: 20 }}>
                                    {
                                        item.img
                                    }
                                </span>
                                <span className={styles.list_text}>
                                    {
                                        (isView && item.id === 3) ? viewConditions : item.label
                                    }
                                </span>
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchGroupContextMenu;
