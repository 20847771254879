import React from 'react';
import styles from './styles.module.scss';

interface IProps {
    name: string;
    create: string;
}

const BlockInfo: React.FC<IProps> = ({
    name, create,
}): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handler = () => {};
    return (
        <div className={styles.blockInfo}>
            <div className={styles.blockInfo_row}>
                <span className={styles.blockInfo_label}>Автор:</span>
                <span className={styles.blockInfo_text}>{name}</span>
            </div>
            <div className={styles.blockInfo_row}>
                <span className={styles.blockInfo_label}>Дата создания:</span>
                <span className={styles.blockInfo_text}>{create}</span>
            </div>
        </div>
    );
};

export default BlockInfo;
