/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styles from './styles.module.scss';
import ChartEngagementDynamics from '../ChartEngagementDynamics';
import { IPropsChartEngagementDynamics } from '../ChartEngagementDynamics/types';

const ModalDynamicChart: React.FC<IPropsChartEngagementDynamics> = ({
    close,
    isOpen,
    postDate,
    pid,
    postData,
}): JSX.Element => {
    if (!isOpen) return <div />;
    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalOverlay} onClick={() => close()} />
            <ChartEngagementDynamics
                postData={postData}
                isOpen={isOpen}
                close={() => close()}
                postDate={postDate}
                pid={pid}
            />
        </div>
    );
};

export default ModalDynamicChart;
