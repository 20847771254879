import { rtkQApi } from '../..';
import {
    IAuthorFromApi, IAuthorModel, IQueryParamsLoadAuthors,
} from './types';

export const campaignAuthorsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postLoad: build.query<IAuthorModel[], IQueryParamsLoadAuthors>({
            query: (params) => ({
                url: 'campaign/summary_data/authors',
                method: 'POST',
                body: params,
            }),
            transformResponse: (
                baseQueryResponse: IAuthorFromApi[],
            ): IAuthorModel[] => baseQueryResponse.map(
                (item) => ({
                    id: item.author_id,
                    postsCount: item.posts_count,
                    likes: item.likes ?? 0,
                    views: item.views ?? 0,
                    comments: item.comments ?? 0,
                    percent: item.percent,
                    reactions: item.reactions ?? 0,
                    authorName: item.author_name,
                } as IAuthorModel),
            ),
        }),
    }),
});
