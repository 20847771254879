/* eslint-disable arrow-body-style */
import { ITableColumn } from '@entities/Table/types';
import { IItemSelcet, ITestRow } from './types';
import {
    ActionCell, AuthorCell, CatigoriesCell, ErCell, GrowthCell, LinkCell, NumberTooltipCell, PlatformCell, SourceCell,
} from './ui';
// import {
//     AuthorFilter, CategoryFilter, ERFilter, PlatformFilter, SubscribersFilter,
// } from './Filtres';

export const getColumns = (
    listListsSource: IItemSelcet[],
    isDisabledcheckboxes: boolean,
    handlerSetSourceInList: (listId: number[], sourceId: number) => void,
    handlerCreateList: (sourceId: number, title: string) => void,
    handlerAddedToFavorits: (sourceId: number) => void,
    handlerRemoveFromFavorits: (sourceId: number) => void,
    onChangeSelected: (source: ITestRow) => void,
): ITableColumn<ITestRow>[] => {
    const columns: ITableColumn<ITestRow>[] = [
        {
            id: 11,
            title: 'Источник',
            dataIndex: 'source',
            width: 240,
            render: (params, source) => (
                <SourceCell
                    {...params}
                    isDisabledCheckboxes={isDisabledcheckboxes}
                    onChangeChecked={() => onChangeSelected(source)}
                />
            ),
        },
        {
            id: 12,
            title: 'Ссылка',
            dataIndex: 'link',
            width: 120,
            render: (link) => (<LinkCell link={link} />),
        },
        {
            id: 22,
            title: 'Платформа',
            dataIndex: 'platform',
            width: 134,
            render: (name) => (<PlatformCell name={name} />),
        },
        {
            id: 31,
            title: 'Автор',
            dataIndex: 'author',
            width: 150,
            render: (name) => (<AuthorCell name={name?.title} />),
        },
        {
            id: 33,
            title: 'Категории',
            dataIndex: 'categories',
            width: 190,
            render: (items) => (<CatigoriesCell categories={items} />),
        },
        {
            id: 34,
            title: 'Подписчики',
            dataIndex: 'subscribers',
            width: 143,
            render: (subscribers) => (<NumberTooltipCell value={+subscribers} />),
        },
        {
            id: 43,
            title: 'Просмотры',
            dataIndex: 'views',
            width: 143,
            render: (views) => (<NumberTooltipCell value={+views} />),

        },
        {
            id: 44,
            title: 'ER',
            dataIndex: 'er',
            width: 83,
            render: (er) => (<ErCell value={+er} />),
        },
        {
            id: 45,
            width: 225,
            title: 'Прирост подписчиков',
            dataIndex: 'subscriberGrowth',
            render: (growth) => (<GrowthCell {...growth} />),
        },
        {
            id: 54,
            title: 'Действия',
            dataIndex: 'actions',
            width: 99,
            render: ({ sourceId, isFavorite, activeList }, row) => {
                return (
                    <ActionCell
                        isFavorits={isFavorite}
                        listsSources={activeList.filter((item) => !item?.isFavorite).map((item) => ({
                            id: item?.id,
                            label: item.title,
                            isSelect: false,
                        }))}
                        sourceId={sourceId}
                        onSetSourceInList={handlerSetSourceInList}
                        onCreateList={() => handlerCreateList(sourceId, row.source.name)}
                        onChangeStatusFavorite={isFavorite ? handlerRemoveFromFavorits : handlerAddedToFavorits}
                    />
                );
            },
        },
    ];
    return columns;
};
