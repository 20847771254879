import React, { useMemo } from 'react';
import { ICampaignComparisonList } from 'services/campaigns/comparison/types';
import { useParams } from 'react-router-dom';
import { campaignComparisonRtkQService } from '../../../../services/campaigns/comparison';
import CampaignComparisonsTable from '../../../../features/campaign/comparison/List';

const CampaignComparisonsListContainer: React.FC = (): JSX.Element => {
    const { Id: urlParametersId } = useParams();
    const {
        data: allDataList,
        isLoading,
        isFetching,
    } = campaignComparisonRtkQService.useGetCampaignComparisonAnalysisQuery();
    const [
        deleteComparison,
        { isLoading: isLoadingDelete },
    ] = campaignComparisonRtkQService.useDeleteCampaignComparisonAnalysisMutation();

    const campaignId = useMemo<number | null>(() => {
        const id = Number(urlParametersId);
        return urlParametersId && Number.isInteger(id) ? id : null;
    }, [urlParametersId]);
    const currentCampaignDataList = useMemo<ICampaignComparisonList[]>(() => {
        if (!(allDataList?.length > 0)) {
            return [];
        }
        return campaignId > 0
            ? allDataList?.filter(({ campaign }) => campaign.id === campaignId)
            : allDataList;
    }, [allDataList, campaignId]);

    const handlerDeleteComparison = (id: number): void => {
        deleteComparison(id);
    };

    return (
        <CampaignComparisonsTable
            data={currentCampaignDataList}
            isLoading={
                isLoading || isFetching || isLoadingDelete
            }
            onDelete={handlerDeleteComparison}
        />
    );
};

export default CampaignComparisonsListContainer;
