/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styles from './styles.module.scss';

interface IFiltersHeaderProps {
    name: string,
    isImportant: boolean,
    withControls: boolean,
    onClickControl?: (...args: any) => any

}

const HeaderPanel: React.FC<IFiltersHeaderProps> = ({
    name,
    isImportant,
    withControls,
    onClickControl,

}): JSX.Element => (
    <div className={`${isImportant && styles.important} ${styles.titile_container}`}>
        <div style={{ width: 'fit-content' }}>
            {name}
        </div>
        { withControls && (
            <div
                className={styles.customControls}
                onClick={onClickControl}
            >
                +
            </div>
        )}
    </div>
);

export default HeaderPanel;
