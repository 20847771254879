import {
    IQueryParamsChangeStatus,
    IQueryParamsCreateCampaign,
    IQueryParamsUpdateCampaign,
} from 'services/campaigns/single-campaign/types';
import { CampaignActiveModalType, ProcessModalStatus } from '../../../store/campaign/source/types';
import { rtkQApi } from '../../index';
import { ICampaignDataModel, ISingleCampaignFromApi } from '../campaigns-list/types';
import campaignUtils from '../../../utils/campaign';
import { campaignActions } from '../../../store/campaign/source';
import { currentPageActions } from '../../../store/common/current-page';
import { campaignTrackedSourcesActions } from '../../../store/campaign/tracked-sources';
import { ITrackedSourceModel } from '../../../store/campaign/tracked-sources/types';

const prepareSources = (dataApi: unknown): ITrackedSourceModel[] => {
    if (!dataApi) {
        return [];
    }
    return Object.keys(dataApi)
        .map((sourcesKey) => (
            {
                id: dataApi[sourcesKey]?.id,
                allowInRussia: dataApi[sourcesKey]?.allow_in_russia,
                authorId: dataApi[sourcesKey]?.author_id,
                autoDiscover: dataApi[sourcesKey]?.auto_discover,
                created: dataApi[sourcesKey]?.created,
                enabled: dataApi[sourcesKey]?.enabled,
                link: dataApi[sourcesKey]?.link,
                locationId: dataApi[sourcesKey]?.location_id,
                outSourceId: dataApi[sourcesKey]?.out_source_id,
                parseLink: dataApi[sourcesKey]?.parse_link,
                parsed: dataApi[sourcesKey]?.parsed,
                tag: dataApi[sourcesKey]?.tag,
                title: dataApi[sourcesKey]?.title,
                typeId: dataApi[sourcesKey]?.type_id,
            } as ITrackedSourceModel
        ));
};
const transformSingleCampaignResponse = (baseQueryResponse: ISingleCampaignFromApi): ICampaignDataModel => {
    const parseFromDate = new Date(baseQueryResponse.parse_from);
    const result = {
        id: baseQueryResponse.id,
        name: baseQueryResponse.name,
        parseFrom: parseFromDate.toLocaleDateString('ru-RU'),
        parseFromDate,
        createDate: new Date(baseQueryResponse.create_time).toLocaleDateString('ru-RU'),
        createTimestamp: baseQueryResponse.create_time,
        isActive: campaignUtils.campaignStatusToBool(baseQueryResponse.status.name),
        sourceType: baseQueryResponse?.criteria?.source_type,
        categoriesIds: baseQueryResponse?.criteria?.categories,
        isBlogger: baseQueryResponse?.criteria?.is_blogger,
        includeWords: baseQueryResponse?.criteria?.include_words,
        excludeWords: baseQueryResponse?.criteria?.exclude_words,
        sources: prepareSources(baseQueryResponse?.criteria?.sources),
        excludeSources: prepareSources(baseQueryResponse?.criteria?.exclude_sources),
        isComplexQuery: baseQueryResponse?.criteria?.is_complex_query,
        searchQuery: baseQueryResponse?.criteria?.search_query,
        owner: { userId: baseQueryResponse?.owner_id },
    } as ICampaignDataModel;
    return result;
};

export const singleCampaignRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getCampaign: build.query<ICampaignDataModel, { id: number }>({
            query: (params: { id: number }) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: `campaign/${params.id}`,
                    method: 'GET',
                };
            },
            providesTags: ['SingleCampaign'],
            transformResponse: transformSingleCampaignResponse,
            async onQueryStarted(args: { id: number }, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(campaignActions.setDataSingleCampaign(data));
                        dispatch(currentPageActions.setCurrentCampaign(data));
                        dispatch(campaignTrackedSourcesActions.setSelectedSource({
                            isTracked: data?.sources?.length > 0, data: (data?.sources?.length > 0 ? data?.sources : data?.excludeSources) ?? [],
                        }));
                    });
            },
        }),
        deleteCampaign: build.mutation<void, { id: number }>({
            query: (params: { id: number }) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: `campaign/${params.id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['CampaignsList'],
            async onQueryStarted(args: { id: number }, { dispatch, queryFulfilled }) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.process));
                dispatch(campaignActions.setModalProcessMessage('Удаляем кампанию...'));
                dispatch(campaignActions.startCampaignDeleting());
                queryFulfilled
                    .then(() => {
                        dispatch(campaignActions.deleteCampaignFromList(args?.id));
                        dispatch(campaignActions.successCampaignDeleting());
                        dispatch(campaignActions.setModalProcessMessage('Кампания удалена'));
                        dispatch(campaignActions.setModalProcessStatus(ProcessModalStatus.success));
                    })
                    .catch((error) => {
                        dispatch(campaignActions.failedCampaignDeleting());
                        dispatch(campaignActions.setFetchErrors(error?.message));
                        dispatch(campaignActions.setModalProcessMessage('Произошла ошибка при удалении кампании.'));
                        dispatch(campaignActions.setModalProcessStatus(ProcessModalStatus.failed));
                    });
            },
        }),
        postChangeCampaignStatus: build.mutation<void, IQueryParamsChangeStatus>({
            query: (params) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: `campaign/${params.id}/status`,
                    method: 'POST',
                    body: {
                        cur_status: campaignUtils.campaignStatusToString(params.status),
                        new_status: campaignUtils.campaignStatusToString(!params.status),
                    },
                };
            },
            invalidatesTags: ['SingleCampaign', 'CampaignsList'],
            async onQueryStarted(args: IQueryParamsChangeStatus, { dispatch, queryFulfilled }) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.process));
                dispatch(campaignActions.setModalProcessMessage(args.status ? 'Отключаем кампанию...' : 'Включаем кампанию...'));
                dispatch(campaignActions.setUpdateCampaignStatus());
                queryFulfilled
                    .then(() => {
                        dispatch(campaignActions.setSuccessUpdateCampaignStatus());
                        if (args.status) {
                            dispatch(campaignActions.setNotActiveStatusCampaign(args.id));
                        } else {
                            dispatch(campaignActions.setActiveStatusCampaign(args.id));
                        }
                        dispatch(campaignActions.setModalProcessMessage(args.status ? 'Кампания выключена' : 'Кампания включена'));
                        dispatch(campaignActions.setModalProcessStatus(ProcessModalStatus.success));
                    })
                    .catch((error) => {
                        dispatch(campaignActions.setFailedUpdateCampaignStatus());
                        dispatch(campaignActions.setFetchErrors(error?.message));
                        dispatch(campaignActions.setModalProcessMessage('Произошла ошибка при обновлении статуса кампании.'));
                        dispatch(campaignActions.setModalProcessStatus(ProcessModalStatus.failed));
                    });
            },
        }),
        postCreateCampaign: build.mutation<ICampaignDataModel, IQueryParamsCreateCampaign>({
            query: (params: IQueryParamsCreateCampaign) => {
                const sources = params.sources?.map((item) => ({
                    allow_in_russia: item?.allowInRussia,
                    author_id: item?.authorId,
                    auto_discover: item?.autoDiscover,
                    created: item?.created,
                    enabled: item?.enabled,
                    id: item?.id,
                    link: item?.link,
                    location_id: item?.locationId,
                    out_source_id: Number.isInteger(item?.outSourceId) ? item?.outSourceId : 0,
                    parse_link: item?.parseLink,
                    parsed: item?.parsed,
                    tag: item?.tag,
                    title: item?.title,
                    type_id: item?.typeId,
                }));
                return {
                    url: 'camp_an/campaign/',
                    method: 'POST',
                    body: {
                        name: params.name,
                        parse_from: params.parseFrom,
                        criteria: {
                            categories: params.categories,
                            include_words: params.includeWords,
                            exclude_words: params.excludeWords,
                            source_type: params.sourceType,
                            is_blogger: params.isBlogger,
                            is_complex_query: params.isComplexQuery,
                            search_query: params.searchQuery,
                            sources: params.isTrackedLinks ? sources : [],
                            exclude_sources: params.isTrackedLinks ? [] : sources,
                        },
                    },
                };
            },
            invalidatesTags: ['CampaignsList'],
            transformResponse: transformSingleCampaignResponse,
            async onQueryStarted(args: IQueryParamsCreateCampaign, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(campaignTrackedSourcesActions.setSelectedSource({ isTracked: args.isTrackedLinks, data: args.sources }));
                        if (args.withoutPopup) {
                            dispatch(campaignActions.setInitialCreatingStatus());
                        } else {
                            dispatch(campaignActions.successCreating());
                        }
                        dispatch(campaignActions.successCampaignLoading());
                        dispatch(campaignActions.setDataSingleCampaign(data));
                        dispatch(currentPageActions.setCurrentCampaign(data));
                    })
                    .catch((error) => {
                        dispatch(campaignActions.failedCreating());
                        dispatch(campaignActions.setFetchErrors(error?.message));
                    });
            },
        }),
        patchUpdateCampaign: build.mutation<ICampaignDataModel, IQueryParamsUpdateCampaign>({
            query: (params) => {
                const sources = params.sources?.map((item) => ({
                    allow_in_russia: item?.allowInRussia,
                    author_id: item?.authorId,
                    auto_discover: item?.autoDiscover,
                    created: item?.created,
                    enabled: item?.enabled,
                    id: item?.id,
                    link: item?.link,
                    location_id: item?.locationId,
                    out_source_id: item?.outSourceId,
                    parse_link: item?.parseLink,
                    parsed: item?.parsed,
                    tag: item?.tag,
                    title: item?.title,
                    type_id: item?.typeId,
                }));
                return {
                    url: `campaign/${params.id}`,
                    method: 'PATCH',
                    body: {
                        name: params.name,
                        criteria: {
                            categories: params.categories,
                            include_words: params.includeWords,
                            exclude_words: params.excludeWords,
                            source_type: params.sourceType,
                            is_blogger: params.isBlogger,
                            sources: params.isTrackedLinks ? sources : [],
                            exclude_sources: params.isTrackedLinks ? [] : sources,
                            is_complex_query: params.isComplexQuery,
                            search_query: params.searchQuery,
                        },
                    },
                };
            },
            invalidatesTags: ['SingleCampaign'],
            transformResponse: transformSingleCampaignResponse,
            async onQueryStarted(args: IQueryParamsCreateCampaign, { dispatch, queryFulfilled }) {
                dispatch(campaignActions.setUpdateCampaignStatus());
                queryFulfilled
                    .then(() => {
                        dispatch(campaignTrackedSourcesActions.setSelectedSource({ isTracked: args.isTrackedLinks, data: args.sources }));
                        dispatch(campaignActions.setSuccessUpdateCampaignStatus());
                        dispatch(campaignActions.successCampaignLoading());
                    })
                    .catch((error) => {
                        dispatch(campaignActions.setFailedUpdateCampaignStatus());
                        dispatch(campaignActions.setFetchErrors(error?.message));
                    });
            },
        }),
    }),
});
