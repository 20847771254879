import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './styles.module.scss';

const SourcesPage: React.FC = (): JSX.Element => (
    <div className={styles.sourcesPage_root}>
        <Outlet />
    </div>
);

export default SourcesPage;
