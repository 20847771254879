import { AppState } from 'store/store';

const pdfHandlerReportState = (state: AppState) => state.pdfHandler.report;
const reports = (state: AppState) => pdfHandlerReportState(state)?.reports;
const reportsIds = (state: AppState) => pdfHandlerReportState(state)?.ids;
const reportById = (state: AppState, id: string) => pdfHandlerReportState(state)?.reports[id];
const pagesByReportId = (state: AppState, id: string) => pdfHandlerReportState(state)?.reports[id]?.pages;
const pageByReportIdAndPageId = (state: AppState, reportId: string, pageId: number) => pdfHandlerReportState(state)
    .reports[reportId]?.pages.find(({ id }) => pageId === id);
const fileByReportId = (state: AppState, reportId: string) => pdfHandlerReportState(state).reports[reportId]?.file;
const urlByReportId = (state: AppState, reportId: string) => pdfHandlerReportState(state).reports[reportId]?.downloadUrl;
const loadReportStatus = (state: AppState) => pdfHandlerReportState(state)?.loadReportStatus;

export default {
    reports,
    reportsIds,
    reportById,
    pagesByReportId,
    pageByReportIdAndPageId,
    fileByReportId,
    loadReportStatus,
    urlByReportId,
};
