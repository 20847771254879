import React, { useMemo, useRef } from 'react';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import SortingIcon from '@assets/images/sorting.svg';
import { SortOrder } from '@services/sources/types';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
    isOpen?: boolean;
    sortState: { orderDirection: SortOrder, orderBy: string };
    onSort: (field: string, direction: SortOrder) => void;
    onClose: () => void;
}

const name = 'title';

const SourcesFilter: React.FC<IProps> = ({
    onSort, sortState, onClose, isOpen = true,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const isAscFiltered = useMemo<boolean | null>(() => {
        if (sortState?.orderBy !== name) {
            return null;
        }
        return sortState?.orderDirection === SortOrder.asc;
    }, [sortState]);

    if (!isOpen) {
        return null;
    }
    return (
        <div className={styles.sourcesFilter} ref={refModal}>
            <span className={styles.sourcesFilter_title}>Сортировка</span>
            <button className={styles.sourcesFilter_row} type="button" onClick={() => onSort('title', SortOrder.asc)}>
                <img src={SortingIcon} alt="SortingIcon" className={styles.sourcesFilter_img} style={{ transform: 'scale(1, -1)' }} />
                <span>От </span>
                {' '}
                <span
                    className={
                        classnames(styles.sourcesFilter_symbol, isAscFiltered ? styles.active : '')
                    }
                >
                    A
                </span>
                {' '}
                <span> до Я</span>
            </button>
            <button className={styles.sourcesFilter_row} type="button" onClick={() => onSort('title', SortOrder.desc)}>
                <img src={SortingIcon} alt="SortingIcon" className={styles.sourcesFilter_img} />
                <span>От </span>
                {' '}
                <span
                    className={
                        classnames(styles.sourcesFilter_symbol, (!isAscFiltered && isAscFiltered !== null) ? styles.active : '')
                    }
                >
                    Я
                </span>
                {' '}
                <span> до А</span>
            </button>
        </div>
    );
};

export default SourcesFilter;
