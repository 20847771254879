import {
    IBloggerCommonFromApi,
    IBloggerCommonModel,
    IBloggerPublicationFromApi,
    IBloggerPublicationModel,
    IQPAuthorPublications,
} from 'services/authors/types';
import { IDictionary } from 'store/types';
import { rtkQApi } from '..';

const transformAuthorResponse = (baseQueryResponse: IBloggerCommonFromApi): IBloggerCommonModel => {
    const date = new Date(baseQueryResponse?.last_update);
    const result = {
        id: baseQueryResponse?.id,
        comments: baseQueryResponse?.comments,
        title: baseQueryResponse?.title,
        forwards: baseQueryResponse?.forwards,
        isBlogger: Boolean(baseQueryResponse?.is_blogger),
        likes: baseQueryResponse?.likes,
        posts: baseQueryResponse?.posts_count,
        logoLink: baseQueryResponse?.logo_link,
        views: baseQueryResponse?.views,
        subscribers: baseQueryResponse?.subscribers,
        lastUpdateDate: date.toLocaleDateString('ru-RU'),
        lastUpdateTime: date.toLocaleTimeString('ru-RU'),
        lastUpdateTimestamp: (date.getTime() / 1000),
        categories: (
            Object.keys(baseQueryResponse?.categories).reduce(
                (acc: IDictionary<number>, cur: string) => {
                    acc[cur] = baseQueryResponse.categories[cur];
                    return acc;
                },
                {} as IDictionary<number>,
            )
        ),
        sourceTypePostsAmount: (
            Object.keys(baseQueryResponse?.source_type_posts_count).reduce(
                (acc: IDictionary<number>, cur: string) => {
                    acc[cur] = baseQueryResponse.categories[cur];
                    return acc;
                },
                {} as IDictionary<number>,
            )
        ),
    } as IBloggerCommonModel;
    return result;
};

const transformPublicationsResponse = (baseQueryResponse: IBloggerPublicationFromApi[]): IBloggerPublicationModel[] => (
    baseQueryResponse.map((item) => {
        const date = new Date(item?.posted);
        return {
            id: item?.id,
            title: item?.title,
            content: item?.content,
            outPostId: item?.out_post_id,
            parsedDateTime: item?.parsed,
            postedDate: date.toLocaleDateString('ru-RU'),
            postedTime: date.toLocaleTimeString('ru-RU'),
            postedTimestamp: (date.getTime() / 1000),
            link: item?.post_link,
            sourceId: item?.source_id,
        } as IBloggerPublicationModel;
    })
);

export const authorsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getAuthor: build.query<IBloggerCommonModel, { id: number }>({
            query: (params) => ({
                url: `info/author/${params.id}`,
                method: 'GET',
            }),
            transformResponse: transformAuthorResponse,
        }),
        getAuthorPublications: build.query<IBloggerPublicationModel[], IQPAuthorPublications>({
            query: (params) => ({
                url: `info/author/${params.id}/posts?limit=${params.limit}&offset=${params.offset}`,
                method: 'GET',
            }),
            transformResponse: transformPublicationsResponse,
        }),
    }),
});
