/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import React, {
    useState, useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { recoveryPassword } from '../../deprecated/api/restorePassword';
import Path from '../../shared/lib/paths';
import BigLogo from './BigLogo.svg';
import ArrowLeft from './ArrowLeft.svg';
import Bars from './Bars.svg';
import Chart from './Chart.svg';
import LoginInput from '../../components/LoginInput';
// import QuickSVGLoader from '../../components/QuickSVGLoader';

const Layout = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const ContainerTitle = styled.div`
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
    min-width: 236px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #0EBFA1;
`;
const BarsContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20%;
`;
const ChartContainer = styled.div`
    z-index: -1;
    position: absolute;
    bottom: 0;
    right: 170px;
`;
const AuthBlock = styled.div`
    width: 100%;
    height: 100%;
    max-height: 314px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
`;
const AuthButton = styled.button`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: #0EBFA1;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 131.5%;
    color: #FFFFFF;
`;

const LogoContainer = styled.div`
    max-width: 638px;
    display: flex;
`;
const CenteredMarkaup = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const CreateAccountInfo = styled.div`
    margin-left: 8px;
`;
const Exception = styled.div`
    border-radius: 6px;
    padding: 16px;
    color: #F36869;
    font-weight: 500;
    font-size: 16px;
    background: #fdebec;
    margin-bottom: 16px;
`;
const ExceptionBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
`;
const ERROR_LANGUAGE_INPUT = 'Допустимы только латинские буквы и символы';
const ERROR_CONFIRM_PASSWORD = 'Пароли не совпадают';
const RestorePassword = function RestorePassword() {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [isComplete, setIsComplete] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const location = useLocation();
    const getTitle = useMemo(() => (isConfirm ? 'Теперь еще раз введите' : 'Введите свой новый пароль'), [isConfirm]);
    const getButtonLabel = useMemo(() => (isConfirm ? 'Сменить пароль' : 'Подтвердить'), [isConfirm]);
    const getInputValue = useMemo(() => (isConfirm ? confirmPassword : newPassword), [isConfirm, newPassword, confirmPassword]);
    const valueSetter = (value): void => {
        const regExp = /[а-яА-ЯЁё-і]/g;
        const newValue = value.replace(/[а-яА-ЯЁё-і]/g, '');
        if (regExp.test(value)) setError(ERROR_LANGUAGE_INPUT);
        else setError('');
        if (isConfirm) setConfirmPassword(newValue);
        else setNewPassword(newValue);
    };
    const handleClickOnAcceptButton = () => {
        if (!isConfirm) {
            setIsConfirm(true);
            return;
        }
        if (newPassword !== confirmPassword) {
            setError(ERROR_CONFIRM_PASSWORD);
            return;
        }
        setError('');
        const currentPath = location.pathname.split('/').filter((item) => item);
        const token = currentPath[1];
        recoveryPassword(
            token,
            newPassword,
            confirmPassword,
        )
            .then((res) => {
                setIsComplete(true);
            })
            .catch((err) => {
                setError('Ошибка, попробуйте проверить данные и повторить');
            });
    };
    const redirectToAuth = () => {
        navigate(Path.Auth);
    };
    const createNewPassMarkup = (

        <AuthBlock>
            <div>
                {
                    isConfirm
                        ? (
                            <ContainerTitle onClick={() => setIsConfirm(false)}>
                                <img src={ArrowLeft} alt="ArrowLeft" />
                                <CreateAccountInfo>{getTitle}</CreateAccountInfo>
                            </ContainerTitle>
                        )
                        : (
                            <ContainerTitle>
                                <div>Введите свой новый пароль</div>
                            </ContainerTitle>
                        )
                }
                <LoginInput placeholder="Введите пароль" type="password" value={getInputValue} setValue={valueSetter} withImage />
                <AuthButton onClick={handleClickOnAcceptButton}>{getButtonLabel}</AuthButton>
            </div>
        </AuthBlock>
    );
    const completeMarkup = (
        <div style={{ marginTop: '200px', marginBottom: '200px' }}>
            <AuthButton onClick={redirectToAuth}>Вcе прошло успешно!</AuthButton>
        </div>
    );
    return (
        <Layout>
            <CenteredMarkaup>
                <LogoContainer>
                    <img src={BigLogo} alt="BigLogo" />
                </LogoContainer>
                {
                    isComplete ? completeMarkup : createNewPassMarkup
                }
                <ExceptionBlock>
                    {
                        error
            && (
                <Exception>
                    <div>{error}</div>
                </Exception>
            )
                    }
                </ExceptionBlock>
                <ChartContainer>
                    <img src={Chart} alt="" />
                </ChartContainer>
            </CenteredMarkaup>

            <BarsContainer>
                <img src={Bars} alt="" />
            </BarsContainer>
            <ChartContainer>
                <img src={Chart} alt="" />
            </ChartContainer>
        </Layout>
    );
};

export default RestorePassword;
