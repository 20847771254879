/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef } from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface IPointData {
    label: string,

    id?: number | string,
    value: number,

    textColor?: string,
    backgroundColor?: string
}
interface IPosition {
    x: number,
    y: number,
    r: number
}
const DEFAULT_COLORS = ['#9545D8', '#201D8F', '#0077FF', '#E67E22', '#0EBFA1'];
const MAX_RADIUS = 80;
const DEFAULT_POSITIONS:IPosition[] = [
    {
        x: 23,
        y: 24,
        r: MAX_RADIUS,
    },
    {
        x: 38,
        y: 30,
        r: 55,
    },
    {
        x: 26,
        y: 38,
        r: 40,
    },
    {
        x: 8,
        y: 28,
        r: 35,
    },
    {
        x: 22,
        y: 12,
        r: 25,
    },
];
interface IProps {
    data: IPointData[],
    animation?: boolean,
    getImage?: (any) => void
}

const minmax = {
    datalabels: {
        labels: {
            value: {
                color: 'white',
            },
        },
    },
    fill: false,
    data: [
        {
            x: 50, y: 50, r: 0, label: '', value: '',
        },
        {
            x: 0, y: 0, r: 0, label: '', value: '',
        },
    ],
};
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
);
const BubbleChart: React.FC<IProps> = ({
    data,
    animation = true,
    getImage,
}) => {
    const createDataset = (item, index) => {
        const newItem = {
            datalabels: {
                labels: {
                    value: {
                        color: item.textColor || 'white',
                    },
                },
            },
            label: item.label,
            borderColor: 'white',
            background: item.textColor || DEFAULT_COLORS[index],
            backgroundColor: item.textColor || DEFAULT_COLORS[index],
            borderWidth: 2,
            barPercentage: 0.8,
            categoryPercentage: 1,
            borderRadius: 6,
            fill: false,
            data: [
                {
                    x: DEFAULT_POSITIONS[index].x,
                    y: DEFAULT_POSITIONS[index].y,
                    r: DEFAULT_POSITIONS[index].r,
                    label: item.label,
                    value: item.value,
                },
            ],
        };
        return newItem;
    };
    const getPercent = (a, b) => (a / b);
    const getRadius = (maxValue, value) => {
        if (maxValue === value) return MAX_RADIUS;
        const percent = getPercent(value, maxValue).toFixed(4);
        const radius = MAX_RADIUS * +percent;
        if (radius < 25) return 25;
        if (radius > 55) return 55;
        return radius;
    };
    const getDatasets = useMemo(
        () => data.map((item, index) => {
            const newItem = {
                datalabels: {
                    labels: {
                        value: {
                            color: item.textColor || 'white',
                        },
                    },
                },
                label: item.label,
                borderColor: 'white',
                background: item.textColor || DEFAULT_COLORS[index],
                backgroundColor: item.textColor || DEFAULT_COLORS[index],
                borderWidth: 2,
                barPercentage: 0.8,
                categoryPercentage: 1,
                borderRadius: 6,
                fill: false,
                data: [
                    {
                        x: DEFAULT_POSITIONS[index].x,
                        y: DEFAULT_POSITIONS[index].y,
                        r: getRadius(data[0]?.value, item.value),
                        label: item.label,
                        value: item.value,
                    },
                ],
            };
            return newItem;
        }),
        [data],
    );
    const chartRef = useRef(null);
    const getData = useMemo(() => ({
        events: ['click'],
        plugin: [ChartDataLabels],
        datasets: [
            minmax,
            ...getDatasets.reverse(),
        ],

    }), [getDatasets]);
    const tooltipHtml = {
        enabled: false,
    };
    const options: any = {
        animation,
        responsive: true,
        indexAxis: 'y',
        maintainAspectRatio: false,
        beginAtZero: true,
        scales: {
            x: {
                border: {
                    display: false,
                },
                ticks: {
                    callback(value) {
                        return '';
                    },
                },
                grid: {
                    borderColor: 'rgba(0,0,0,0)',
                    color() {
                        return 'rgba(0,0,0,0)';
                    },
                },
            },
            y: {

                ticks: {
                    callback(value) {
                        return '';
                    },
                },
                grid: {
                    borderColor: 'rgba(0,0,0,0)',
                    color() {
                        return 'rgba(0,0,0,0)';
                    },
                },
            },
        },
        plugins: {
            datalabels: {
                align: 'center',
                anchor: 'center',
                textAlign: 'center',
                font: {
                    weight: 'bold',
                },
                title: {
                    color: 'red',
                },
                formatter(value) {
                    return `${value.label}\n${value.value}`;
                },
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: tooltipHtml,
        },
    };
    const getOptions = useMemo(() => options, [options]);
    useEffect(() => {
        // const ctx = chartRef?.current?.ctx;
        if (getImage && chartRef?.current && data?.length) {
            setTimeout(() => getImage(chartRef?.current?.toBase64Image('image/png')), 1400);
        }
    }, [data]);
    return (
        <div style={{
            position: 'relative', background: '#fff', height: '450px', width: '450px',
        }}
        >
            <Bubble plugins={[ChartDataLabels]} ref={chartRef} options={getOptions} data={getData as any} />
        </div>
    );
};

export default BubbleChart;
