import SvgIcon from '@components/SvgIcon';
import { SortOrder } from '@services/sources/types';
import styles from './styles.module.scss';

interface IPropsBlockSortAscDesc {
    onSort: (order: SortOrder) => void;
}

// eslint-disable-next-line react/prop-types
const BlockSortAscDesc: React.FC<IPropsBlockSortAscDesc> = ({ onSort }): React.ReactElement => (
    <div className={styles.numberSort}>
        <button
            type="button"
            onClick={() => onSort(SortOrder.desc)}
        >
            <SvgIcon id="SortingIcon" size={16} />
            <span>По убыванию</span>
        </button>
        <button type="button" onClick={() => onSort(SortOrder.asc)}>
            <SvgIcon id="SortingIcon" size={16} />
            <span>По возрастанию</span>
        </button>
    </div>
);
export default BlockSortAscDesc;
