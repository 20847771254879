import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from '../styles.module.scss';

const CampaignTagsFavoriteListSkeleton: React.FC = (): JSX.Element => (
    <div className={styles.campaignWordsFavoriteList_skeleton}>
        <Skeleton className={styles.skeletonCell} height={40} width={60} />
        <Skeleton className={styles.skeletonCell} height={40} width={60} />
        <Skeleton className={styles.skeletonCell} height={40} width={60} />
        <Skeleton className={styles.skeletonCell} height={40} width={60} />
        <Skeleton className={styles.skeletonCell} height={40} width={60} />
    </div>
);

export default CampaignTagsFavoriteListSkeleton;
