/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { getCookie } from 'cookies-next';
import { CLIENT_API_URL } from '../../utils/api';
import { AUTH_COOKIE_NAME } from '../../utils/constants';

export const instance = (headers) => axios.create({
    baseURL: CLIENT_API_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-request-id': uuidv4(),
        ...headers,
    },
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
});

export const createRequest = async (
    {
        headers,
    } = {
        headers: {},
    },
) => {
    const HEADERS = {
        Authorization: `Bearer ${getCookie(AUTH_COOKIE_NAME)}`,
        ...headers,
    };
    const requestInstance = await instance(HEADERS);
    return requestInstance;
};
