import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { Button } from '@shared/index';
import RadioButton from '../../../PlatformsSelectButtons/RadioButton';
import { ComparisonParametersType, IGroupParameters } from '../../types';
// import MoreOrEqualActiveIcon from '../../imgs/moreOrEqualActive.svg';
// import MoreOrEqualIcon from '../../imgs/moreOrEqual.svg';
// import LessOrEqualActiveIcon from '../../imgs/lessOrEqualActive.svg';
// import LessOrEqualIcon from '../../imgs/lessOrEqual.svg';
// import UnequalActiveIcon from '../../imgs/unequalActive.svg';
// import UnequalIcon from '../../imgs/unequal.svg';
// import EqualActiveIcon from '../../imgs/equalActive.svg';
// import EqualIcon from '../../imgs/equal.svg';
// import SplitLineIcon from '../../imgs/splitLine.svg';

import styles from './styles.module.scss';

interface IProps {
    isView;
    index: number;
    top: number;
    left: number;
    isOpen: boolean;
    parameters: IGroupParameters;
    onClose: () => void;
    onChangeParameters: (groupIndex: number, value: IGroupParameters) => void;
}

const SearchGroupParametersContextMenu: React.FC<IProps> = ({
    isView,
    index,
    top,
    left,
    isOpen,
    parameters,
    onClose,
    onChangeParameters,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const [currentParameters, setParameters] = useState<IGroupParameters>(parameters);
    const [distanceEntitiesNumber, setDistanceEntitiesNumber] = useState<number>(
        parameters?.distanceEntities?.quantity > 0 ? parameters.distanceEntities.quantity : 0,
    );
    const [numberMatches, setNumberMatches] = useState<number>(
        parameters?.numberMatches > 0 ? parameters.numberMatches : 0,
    );

    const handlerChangeParameters = (): void => {
        if (onChangeParameters) {
            onChangeParameters(index, {
                ...currentParameters,
                distanceEntities: currentParameters?.distanceEntities
                    ? {
                        parameter: currentParameters?.distanceEntities?.parameter,
                        quantity: distanceEntitiesNumber,
                    }
                    : null,
                numberMatches: currentParameters?.numberMatches === null ? null : numberMatches,
            });
        }
    };
    // const handlerChangeConsiderSequence = (value: boolean): void => {
    //     setParameters({ ...currentParameters, considerSequence: value });
    // };
    const handlerChangeDistanceEntities = (value: boolean): void => {
        setParameters({
            ...currentParameters,
            numberMatches: value ? null : currentParameters.numberMatches,
            distanceEntities: value ? { parameter: ComparisonParametersType.equal, quantity: 1 } : null,
        });
    };
    // const handlerChangeDistanceEntitiesParameter = (value: ComparisonParametersType): void => {
    //     setParameters({
    //         ...currentParameters,
    //         distanceEntities: { parameter: value, quantity: currentParameters.distanceEntities.quantity },
    //     });
    // };
    const handlerChangeDistanceEntitiesNumber = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (Number.isInteger(Number(event.currentTarget.value))) {
            let value = Math.round(Number(event.currentTarget.value));
            value = value < 0 ? 0 : value;
            setDistanceEntitiesNumber(value > 1000 ? 1000 : value);
        }
    };
    const handlerChangeNumberMatches = (value: boolean): void => {
        setParameters({
            ...currentParameters,
            distanceEntities: value ? null : currentParameters.distanceEntities,
            numberMatches: value ? 0 : null,
        });
        // { parameter: ComparisonParametersType.equal, quantity: 1 }
    };
    const handlerChangeNumberMatchesValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (Number.isInteger(Number(event.currentTarget.value))) {
            let value = Math.round(Number(event.currentTarget.value));
            value = value < 0 ? 0 : value;
            setNumberMatches(value > 1000 ? 1000 : value);
        }
    };

    useEffect(() => {
        if (parameters && (
            parameters?.considerSequence !== currentParameters?.considerSequence
            || parameters?.distanceEntities !== currentParameters?.distanceEntities
            || parameters?.numberMatches !== currentParameters?.numberMatches
        )) {
            setParameters({ ...parameters });
        }
    }, [parameters]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.searchGroupParametersContextMenu_root} style={{ top, left }} ref={refModal}>
            <div className={styles.modalContextMenu}>
                <div className={styles.contentWrapper}>
                    {/* <div className={styles.parameterWrapper}> */}
                    {/*    <span className={styles.label}>Порядок сущностей в поисковом запросе</span> */}
                    {/*    <div className={`${styles.flexRow} ${styles.gap16}`}> */}
                    {/*        <RadioButton */}
                    {/*            onClick={() => handlerChangeConsiderSequence(false)} */}
                    {/*            value={0} */}
                    {/*            isActive={currentParameters?.considerSequence ?? false} */}
                    {/*            className={styles.radioButton} */}
                    {/*        > */}
                    {/*            Не учитывать */}
                    {/*        </RadioButton> */}
                    {/*        <RadioButton */}
                    {/*            onClick={() => handlerChangeConsiderSequence(true)} */}
                    {/*            value={0} */}
                    {/*            isActive={!currentParameters?.considerSequence ?? false} */}
                    {/*            className={styles.radioButton} */}
                    {/*        > */}
                    {/*            Учитывать */}
                    {/*        </RadioButton> */}
                    {/*    </div> */}
                    {/* </div> */}
                    <div className={styles.parameterWrapper}>
                        <span className={styles.label}>Расстояние (кол-во между сущностями)</span>
                        <div className={`${styles.flexRow} ${styles.gap16}`}>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeDistanceEntities(false)}
                                value={0}
                                isActive={Boolean(currentParameters?.distanceEntities) === false}
                                className={styles.radioButton}
                            >
                                Не учитывать
                            </RadioButton>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeDistanceEntities(true)}
                                value={0}
                                isActive={Boolean(currentParameters?.distanceEntities)}
                                className={styles.radioButton}
                            >
                                Задать
                            </RadioButton>
                        </div>
                        {
                            currentParameters?.distanceEntities && (
                                <div className={styles.distanceEntities}>
                                    {/* { */}
                                    {/*    currentParameters?.distanceEntities.parameter === ComparisonParametersType.moreOrEqual */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={MoreOrEqualActiveIcon} alt="MoreOrEqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeDistanceEntitiesParameter(ComparisonParametersType.moreOrEqual)} */}
                                    {/*            > */}
                                    {/*                <img src={MoreOrEqualIcon} alt="MoreOrEqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* { */}
                                    {/*    currentParameters?.distanceEntities.parameter === ComparisonParametersType.lessOrEqual */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={LessOrEqualActiveIcon} alt="LessOrEqualActiveIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeDistanceEntitiesParameter(ComparisonParametersType.lessOrEqual)} */}
                                    {/*            > */}
                                    {/*                <img src={LessOrEqualIcon} alt="LessOrEqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* { */}
                                    {/*    currentParameters?.distanceEntities.parameter === ComparisonParametersType.unequal */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={UnequalActiveIcon} alt="UnequalActiveIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeDistanceEntitiesParameter(ComparisonParametersType.unequal)} */}
                                    {/*            > */}
                                    {/*                <img src={UnequalIcon} alt="UnequalIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* { */}
                                    {/*    currentParameters?.distanceEntities.parameter === ComparisonParametersType.equal */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={EqualActiveIcon} alt="EqualActiveIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeDistanceEntitiesParameter(ComparisonParametersType.equal)} */}
                                    {/*            > */}
                                    {/*                <img src={EqualIcon} alt="EqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* <img src={SplitLineIcon} alt="SplitLineIcon" /> */}
                                    <input
                                        className={`${styles.input} ${styles.input_text} ${styles.backgroundNone}`}
                                        readOnly={isView}
                                        placeholder="Введите число"
                                        value={distanceEntitiesNumber}
                                        onChange={handlerChangeDistanceEntitiesNumber}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.parameterWrapper}>
                        <span className={styles.label}>Кол-во совпадений</span>
                        <div className={`${styles.flexRow} ${styles.gap16}`}>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeNumberMatches(false)}
                                value={0}
                                isActive={currentParameters?.numberMatches === null}
                                className={styles.radioButton}
                            >
                                Не учитывать
                            </RadioButton>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeNumberMatches(true)}
                                value={0}
                                isActive={Number.isInteger(currentParameters?.numberMatches)}
                                className={styles.radioButton}
                            >
                                Задать
                            </RadioButton>
                        </div>
                        {
                            Number.isInteger(currentParameters?.numberMatches) && (
                                <div className={styles.distanceEntities}>
                                    <input
                                        className={`${styles.input} ${styles.input_text} ${styles.backgroundNone}`}
                                        disabled={isView}
                                        placeholder="Введите число"
                                        value={numberMatches}
                                        onChange={handlerChangeNumberMatchesValue}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
                    <Button text="Ok" type="default" size="medium" disabled={isView} onClick={handlerChangeParameters} />
                </div>
            </div>
        </div>
    );
};

export default SearchGroupParametersContextMenu;
