import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import { FetchStatus } from '../../types';
import {
    convertDataApiToArrayUser,
    convertDataApiToCampaign,
    convertDataApiToUser,
    convertFromSingleCampaignApi,
} from './converter';
import {
    CampaignActionTypes,
    CampaignActiveModalType,
    ICampaignState, ISingleCampaignUsersModel,
    ModalAnswerType,
    ProcessModalStatus,
} from './types';

const initialState: ICampaignState = {
    campaigns: [],
    campaign: null,
    fetchCreateStatus: FetchStatus.undefined,
    fetchUsersStatus: FetchStatus.undefined,
    fetchCampaignStatus: FetchStatus.undefined,
    updateCampaignStatus: FetchStatus.undefined,
    deleteCampaignStatus: FetchStatus.undefined,
    singleCampaignUsersStatus: FetchStatus.undefined,
    addUserStatus: FetchStatus.undefined,
    creatingBegun: false,
    activeModal: CampaignActiveModalType.undefined,
    modalAnswer: ModalAnswerType.undefined,
    needClearCampaignPage: false,
    fetchErrors: null,
    users: [],
    userIds: [],
    singleCampaignUsers: null,
    actionCampaign: undefined,
    processModalMessage: '',
    processModalStatus: ProcessModalStatus.undefined,
};

const { actions: campaignActions, reducer } = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        clearAll: () => ({ ...initialState }),
        startUserAdding: (state: ICampaignState): void => {
            state.addUserStatus = FetchStatus.process;
        },
        successUserAdding: (state: ICampaignState): void => {
            state.addUserStatus = FetchStatus.success;
        },
        failedUserAdding: (state: ICampaignState): void => {
            state.addUserStatus = FetchStatus.failed;
        },
        clearUserAdding: (state: ICampaignState): void => {
            state.addUserStatus = FetchStatus.undefined;
        },
        setEditActionCampaign: (state: ICampaignState): void => {
            state.actionCampaign = CampaignActionTypes.edit;
        },
        setViewActionCampaign: (state: ICampaignState): void => {
            state.actionCampaign = CampaignActionTypes.view;
        },
        clearActionCampaign: (state: ICampaignState): void => {
            state.actionCampaign = CampaignActionTypes.undefined;
        },
        loadingSingleCampaignUsers: (state: ICampaignState) => {
            state.singleCampaignUsersStatus = FetchStatus.loading;
        },
        successSingleCampaignUsers: (state: ICampaignState) => {
            state.singleCampaignUsersStatus = FetchStatus.success;
        },
        failedSingleCampaignUsers: (state: ICampaignState) => {
            state.singleCampaignUsersStatus = FetchStatus.failed;
        },
        startCampaignDeleting: (state: ICampaignState): void => {
            state.deleteCampaignStatus = FetchStatus.process;
        },
        failedCampaignDeleting: (state: ICampaignState): void => {
            state.deleteCampaignStatus = FetchStatus.failed;
        },
        successCampaignDeleting: (state: ICampaignState): void => {
            state.deleteCampaignStatus = FetchStatus.success;
        },
        clearCampaignDeletingStatus: (state: ICampaignState): void => {
            state.deleteCampaignStatus = FetchStatus.undefined;
        },
        startCampaignLoading: (state: ICampaignState): void => {
            state.fetchCampaignStatus = FetchStatus.loading;
        },
        failedCampaignLoading: (state: ICampaignState): void => {
            state.fetchCampaignStatus = FetchStatus.failed;
        },
        successCampaignLoading: (state: ICampaignState): void => {
            state.fetchCampaignStatus = FetchStatus.success;
        },
        setInitialCampaignStatus: (state: ICampaignState): void => {
            state.fetchCampaignStatus = FetchStatus.undefined;
        },
        setUpdateCampaignStatus: (state: ICampaignState): void => {
            state.updateCampaignStatus = FetchStatus.update;
        },
        setSuccessUpdateCampaignStatus: (state: ICampaignState): void => {
            state.updateCampaignStatus = FetchStatus.success;
        },
        setFailedUpdateCampaignStatus: (state: ICampaignState): void => {
            state.updateCampaignStatus = FetchStatus.failed;
        },
        setInitialUpdateCampaignStatus: (state: ICampaignState): void => {
            state.updateCampaignStatus = FetchStatus.undefined;
        },
        startUsersLoading: (state: ICampaignState): void => {
            state.fetchUsersStatus = FetchStatus.loading;
        },
        failedUsersLoading: (state: ICampaignState): void => {
            state.fetchUsersStatus = FetchStatus.failed;
        },
        successUsersLoading: (state: ICampaignState): void => {
            state.fetchUsersStatus = FetchStatus.success;
        },
        setInitialUsersStatus: (state: ICampaignState): void => {
            state.fetchUsersStatus = FetchStatus.undefined;
        },
        startCreating: (state: ICampaignState): void => {
            state.fetchCreateStatus = FetchStatus.process;
        },
        failedCreating: (state: ICampaignState): void => {
            state.fetchCreateStatus = FetchStatus.failed;
        },
        successCreating: (state: ICampaignState): void => {
            state.fetchCreateStatus = FetchStatus.success;
        },
        setInitialCreatingStatus: (state: ICampaignState): void => {
            state.fetchCreateStatus = FetchStatus.undefined;
        },
        clearListData: (state: ICampaignState): void => {
            state.campaigns = [];
        },
        setFetchErrors: (state: ICampaignState, { payload }: PayloadAction<string>): void => {
            state.fetchErrors = payload;
        },
        deleteCampaignFromList: (state: ICampaignState, { payload }: PayloadAction<number>): void => {
            if (!Number.isNaN(payload)) {
                state.campaigns = [...state.campaigns.filter(({ id }) => id !== payload)];
            }
        },
        setDataAfterSortingAndFiltering: (state: ICampaignState, { payload }: PayloadAction<ICampaignDataModel[]>): void => {
            if (!payload?.length) {
                return;
            }
            state.campaigns = [...payload];
        },
        setDataFromRtkQApi: (state: ICampaignState, { payload }: PayloadAction<ICampaignDataModel[]>): void => {
            state.campaigns = payload;
        },
        setDataFromApi: (state: ICampaignState, { payload }: PayloadAction<unknown>): void => {
            const result = convertDataApiToCampaign(payload);
            state.userIds = result
                .map(({ userId }) => userId)
                .reduce((acc, cur) => (
                    acc.includes(cur) ? acc : [...acc, cur]
                ), []);
            state.campaigns = result;
        },
        appendUserDataFromApi: (state: ICampaignState, { payload }: PayloadAction<unknown>): void => {
            const result = convertDataApiToUser(payload);
            if (result) {
                state.users.push(result);
            }
            if (result?.owner && state?.campaign) {
                state.campaign.owner = result;
            }
        },
        clearCurrentCampaign: (state: ICampaignState): void => {
            state.campaign = null;
        },
        setDataSingleCampaignFromApi: (state: ICampaignState, { payload }: PayloadAction<unknown>): void => {
            state.campaign = convertFromSingleCampaignApi(payload);
        },
        setDataSingleCampaign: (state: ICampaignState, { payload }: PayloadAction<ICampaignDataModel>): void => {
            state.campaign = payload;
        },
        setActiveStatusCampaign: (state: ICampaignState, { payload }: PayloadAction<number>): void => {
            if (state.campaign) {
                state.campaign.isActive = true;
            }
            if (state.campaigns && state.campaigns?.length > 0) {
                const campaign = state.campaigns.find(({ id }) => id === payload);
                if (campaign) {
                    campaign.isActive = true;
                }
            }
        },
        setNotActiveStatusCampaign: (state: ICampaignState, { payload }: PayloadAction<number>): void => {
            if (state.campaign) {
                state.campaign.isActive = false;
            }
            if (state.campaigns && state.campaigns?.length > 0) {
                const campaign = state.campaigns.find(({ id }) => id === payload);
                if (campaign) {
                    campaign.isActive = false;
                }
            }
        },
        setCreateStatus: (state: ICampaignState, { payload }: PayloadAction<boolean>): void => {
            state.creatingBegun = payload;
        },
        setActiveModal: (state: ICampaignState, { payload }: PayloadAction<CampaignActiveModalType>): void => {
            state.activeModal = payload;
            state.modalAnswer = ModalAnswerType.undefined;
            state.processModalMessage = '';
            state.processModalStatus = ProcessModalStatus.undefined;
        },
        setModalProcessMessage: (state: ICampaignState, { payload }: PayloadAction<string>): void => {
            state.processModalMessage = payload;
        },
        setModalProcessStatus: (state: ICampaignState, { payload }: PayloadAction<ProcessModalStatus>): void => {
            state.processModalStatus = payload;
        },
        setModalAnswer: (state: ICampaignState, { payload }: PayloadAction<ModalAnswerType>): void => {
            if (payload === ModalAnswerType.accept && state.activeModal === CampaignActiveModalType.switchingPage) {
                state.creatingBegun = false;
                state.needClearCampaignPage = true;
            }
            state.modalAnswer = payload;
        },
        setClearDataCampaign: (state: ICampaignState, { payload }: PayloadAction<boolean>): void => {
            state.needClearCampaignPage = payload;
        },
        clearSingleCampaignUsers: (state: ICampaignState): void => {
            state.singleCampaignUsers = null;
        },
        setSingleCampaignUsersId: (state: ICampaignState, { payload }: PayloadAction<number>): void => {
            if (!state.singleCampaignUsers || !state.singleCampaignUsers.users) {
                state.singleCampaignUsers = { users: null, campaignId: payload };
            } else {
                state.singleCampaignUsers.campaignId = payload;
            }
        },
        setSingleCampaignUsersFromApi: (state: ICampaignState, { payload }: PayloadAction<unknown>): void => {
            if (!state.singleCampaignUsers || !state.singleCampaignUsers.campaignId) {
                state.singleCampaignUsers = { users: convertDataApiToArrayUser(payload), campaignId: null };
            } else {
                state.singleCampaignUsers.users = convertDataApiToArrayUser(payload);
            }
        },
        setSingleCampaignUsers: (state: ICampaignState, { payload }: PayloadAction<ISingleCampaignUsersModel>): void => {
            state.singleCampaignUsers = payload;
        },
        setSingleCampaignOwnerFromApi: (state: ICampaignState, { payload }: PayloadAction<unknown>): void => {
            const curOwner = convertDataApiToArrayUser(payload).find(({ owner }) => owner);
            if (curOwner) {
                state.campaign.owner = curOwner;
            }
        },
    },
});

export { campaignActions };
export default reducer;
