import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IColumnFilterModel } from '../../../components/DropdownFilterTable/DropdownFilterTableMenu/types';
import { CampaignColumnFilterType } from '../../../components/campaign/List/Table/types';
import { IDropdownColumnFilterTableContainerProps } from './types';
import DropdownColumnFilterTableMenu
    from '../../../components/common/DropdownColumnFilterTable/DropdownColumnFilterTableMenu';
import campaignSourceSelectors from '../../../store/campaign/source/selectors';
import { useAppDispatch } from '../../../store/hooks';
import { campaignActions } from '../../../store/campaign/source';

const filtersData: IColumnFilterModel[] = [
    {
        id: 1,
        name: 'Активно',
        value: 'Активно',
        isSelected: false,
    },
    {
        id: 2,
        name: 'Не активно',
        value: 'Не активно',
        isSelected: false,
    },
];

const campaignTableColumnMap = new Map([
    [CampaignColumnFilterType.name, 'name'],
    [CampaignColumnFilterType.default, 'default'],
    [CampaignColumnFilterType.author, 'author'],
    [CampaignColumnFilterType.date, 'createTimestamp'],
    [CampaignColumnFilterType.status, 'status'],
]);

const DropdownColumnFilterTableMenuContainer: React.FC<IDropdownColumnFilterTableContainerProps> = ({
    filterType, setVisibleMenu,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const tableData = useSelector(campaignSourceSelectors.campaigns);
    const handlerSort = useCallback((ascending: boolean): void => {
        const columnName = campaignTableColumnMap.get(filterType);
        if (!columnName || !tableData?.length || !tableData[0][columnName]) {
            return;
        }
        const buffer = [...tableData].sort((a, b) => (
            ascending
                ? (a[columnName]).localeCompare(b[columnName])
                : (b[columnName]).localeCompare(a[columnName])
        ));
        dispatch(campaignActions.setDataAfterSortingAndFiltering(buffer));
    }, [tableData, filterType]);

    return (
        <DropdownColumnFilterTableMenu
            filterData={filtersData}
            isFiltering={filterType === CampaignColumnFilterType.status}
            isAscending={false}
            isSorting={filterType !== CampaignColumnFilterType.status}
            setVisibleMenu={setVisibleMenu}
            onSort={handlerSort}
        />
    );
};

export default DropdownColumnFilterTableMenuContainer;
