/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import { Checkbox, Switch } from 'antd';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import { selectUserProfileState } from '@store/user-profile/selectors';
import { useSelector } from 'react-redux';
import { IUserInfo } from 'services/common/users/types';
import CampaignHeaderCell from './HeaderCell';
import { CampaignColumnFilterType, ICampaignTableHeaderColumn, ICampaignTableProps } from './types';
import GarbageBasketIcon from '../../Menu/CampaignActions/imgs/garbage-basket.svg';

import styles from './styles.module.scss';

const getColumnTitle = (isCampaignCompare: boolean, header: ICampaignTableHeaderColumn): string => {
    if (!isCampaignCompare) {
        return header.name;
    }
    if (header.columnType === CampaignColumnFilterType.date) {
        return 'Период';
    }
    if (header.name === 'Действия') {
        return 'Статус';
    }

    return header.name;
};

/**
 * Получить ФИО автора
 * @param item
 */
const getAuthorName = (item: IUserInfo): string => {
    if (!item) {
        return '-';
    }
    const name = `${item?.firstName} ${item?.lastName ?? ''}` ?? 'loading';
    return name.length > 30 ? `${name.substring(0, 30)}...` : name;
};

const CampaignTable: React.FC<ICampaignTableProps> = ({
    data, header,
    isLoading, isCampaignUpdate, isCampaignCompare,
    onDelete, isCampaignDeleting,
    onActivate, onNavigateToCampaign, onClickRow, onCheckedRow,
    activeRowIds, referenceCampaignId,
}): JSX.Element => {
    const currentUser = useSelector(selectUserProfileState);
    const [searchIsActive, setActiveSearch] = useState<boolean>(false);
    const [dateNow] = useState<string>(() => (new Date().toLocaleDateString('ru-Ru')));
    const [campaigns, setCampaigns] = useState<ICampaignDataModel[]>(
        data.map((item) => ({ ...item })),
    );

    const handlerNavigateToCampaign = (id: number): void => {
        if (isCampaignCompare) {
            return;
        }
        if (id && onNavigateToCampaign) {
            onNavigateToCampaign(id);
        }
    };

    const handlerChangeStatus = useCallback((id: number, ownerId: string) : void => {
        if (onActivate && id && !isCampaignCompare && currentUser.data.userId === ownerId) {
            onActivate(id);
        }
    }, [isCampaignCompare]);

    const checkingClickRow = (event, item: ICampaignDataModel) => {
        if (event.target.tagName !== 'INPUT') onClickRow(item);
    };

    const tableColumnGroup = useMemo((): JSX.Element => (
        <div className={styles.tableColGroup}>
            {
                header.map((headerRow) => (
                    <div
                        className={styles.tableCol}
                        style={{ width: headerRow.cellWidth, maxWidth: headerRow.maxWidth, minWidth: headerRow.minWidth }}
                        key={headerRow.id}
                    />
                ))
            }
        </div>
    ), [header]);
    useEffect(() => {
        setCampaigns(data.map((item) => ({ ...item })));
    }, [data]);

    return (
        <>
            <div className={styles.table}>
                {tableColumnGroup}
                <div className={styles.tableThead}>
                    <div className={styles.tableHeadTr}>
                        {
                            header?.length > 0 && header.map((headerRow) => (
                                <div className={styles.tableTh} key={headerRow.id}>
                                    <CampaignHeaderCell
                                        columnId={headerRow.id}
                                        name={getColumnTitle(isCampaignCompare, headerRow)}
                                        searchIsActive={searchIsActive}
                                        setActiveSearch={setActiveSearch}
                                        columnType={headerRow.columnType}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={styles.tableBodyWrapper}>
                {
                    !isCampaignDeleting && !isCampaignUpdate && !isLoading && data?.length > 0 && (
                        <div className={styles.table}>
                            {tableColumnGroup}
                            <div className={styles.tableTbody}>
                                {
                                    Boolean(campaigns?.length) && campaigns.map((item: ICampaignDataModel, index: number) => (
                                        <div
                                            key={item.id}
                                            role="button"
                                            tabIndex={0}
                                            onKeyPress={() => null}
                                            onClick={(event) => checkingClickRow(event, item)}
                                            className={styles.tableTr}
                                            style={activeRowIds.includes(item.id) ? { background: 'rgba(223, 252, 247, 0.2)' } : null}
                                        >
                                            <div className={styles.tableTd}>
                                                <div
                                                    className={classnames(styles.cell)}
                                                    style={{ cursor: `${isCampaignCompare ? 'default' : 'pointer'}` }}
                                                    onClick={() => handlerNavigateToCampaign(item.id)}
                                                >
                                                    <div className={styles.cellNameContainer}>
                                                        {
                                                            isCampaignCompare && (
                                                                <span style={{ marginLeft: 6 }}>
                                                                    <Checkbox
                                                                        checked={activeRowIds.includes(item.id)}
                                                                        onChange={onCheckedRow(item)}
                                                                        disabled={item.id === referenceCampaignId}
                                                                    />
                                                                </span>
                                                            )
                                                        }
                                                        <div
                                                            className={styles.cellName}
                                                        >
                                                            <span
                                                                style={{ marginLeft: `${isCampaignCompare ? '0px' : '10px'}` }}
                                                            >
                                                                {index + 1}
                                                            </span>
                                                            <span>{item.name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.tableTd}>
                                                <div
                                                    style={{ cursor: `${isCampaignCompare ? 'default' : 'pointer'}` }}
                                                    className={classnames(styles.cell, styles.filtered)}
                                                    onClick={() => handlerNavigateToCampaign(item.id)}
                                                >
                                                    {
                                                        isCampaignCompare
                                                            ? (
                                                                <span style={{ display: 'flex', width: '100%' }}>
                                                                    {item.parseFrom}
                                                                    -
                                                                    {dateNow}
                                                                </span>
                                                            )
                                                            : (
                                                                <span>
                                                                    {item.createDate}
                                                                </span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.tableTd}>
                                                <div
                                                    style={{ cursor: `${isCampaignCompare ? 'default' : 'pointer'}` }}
                                                    className={classnames(styles.cell, styles.filtered)}
                                                    onClick={() => handlerNavigateToCampaign(item.id)}
                                                >
                                                    {getAuthorName(item?.owner)}
                                                </div>
                                            </div>
                                            <div className={styles.tableTd}>
                                                <div className={classnames(styles.cell, styles.filtered)}>
                                                    <div className={styles.actionCell}>
                                                        <span
                                                            className={styles.controlBlock}
                                                            style={{
                                                                justifyContent: 'space-between',
                                                                color: (item.isActive ? '#0EBFA1' : '#787E93'),
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    cursor: `${(isCampaignCompare
                                                                    || currentUser.data.userId !== item.owner.userId) ? 'default' : 'pointer'}`,
                                                                }}
                                                                onClick={() => handlerChangeStatus(item.id, item.owner.userId)}
                                                            >
                                                                {
                                                                    item.isActive ? 'Выкл.' : 'Вкл.'
                                                                }
                                                            </span>
                                                            {currentUser.data.userId === item.owner.userId
                                                                && (
                                                                    <Switch
                                                                        id={`campaign-activate-on-list-switch-${item.id}`}
                                                                        size="small"
                                                                        disabled={isCampaignCompare}
                                                                        checked={item.isActive}
                                                                        onChange={() => onActivate(item.id)}
                                                                    />
                                                                )}
                                                        </span>
                                                        <span style={{ minWidth: 24 }} />
                                                        <span
                                                            className={styles.controlBlock}
                                                            style={{ color: '#F36869' }}
                                                        >
                                                            {
                                                                !item.noDelete && !isCampaignCompare
                                                                    ? (
                                                                        <button
                                                                            id={`campaign-delete-on-list-btn-${item.id}`}
                                                                            className={styles.controlButton}
                                                                            type="button"
                                                                            onClick={() => onDelete(item.id)}
                                                                        >
                                                                            <img src={GarbageBasketIcon} alt="GarbageBasketIcon" />
                                                                        </button>
                                                                    )
                                                                    : null
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    (isCampaignDeleting || isCampaignUpdate || isLoading) && (
                        <Skeleton width="100%" height={25} count={20} />
                    )
                }
                {
                    !isCampaignDeleting && !isCampaignUpdate && !isLoading && !data?.length && (
                        <div>
                            <div className={styles.noDataContainer}>
                                Нет данных для отображения
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default CampaignTable;
