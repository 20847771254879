import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBreadCrumbPath, ICommonBreadCrubmsState } from './types';

const initialState: ICommonBreadCrubmsState = {
    pathsHistory: [],
    updatedFromStorage: false,
};

const localStorageKey = 'breadCrumbs';

const { actions: breadCrumbsActions, reducer } = createSlice({
    name: 'breadCrumbs',
    initialState,
    reducers: {
        addPathHistory: (state: ICommonBreadCrubmsState, { payload }: PayloadAction<IBreadCrumbPath>): void => {
            let arr = state.pathsHistory;
            if (!state.updatedFromStorage) {
                const storageData = localStorage.getItem(localStorageKey);
                if (storageData) {
                    arr = arr.concat(JSON.parse(storageData));
                }
                state.updatedFromStorage = true;
            }
            if (!arr || arr.length < 1) {
                arr = arr.concat(payload);
            }
            if (arr && arr.length >= 1 && arr[arr.length - 1].pathname !== payload.pathname) {
                arr = arr.filter((item) => item.pathname !== payload.pathname);
                arr = arr.concat(payload);
            }

            if (arr.length > 5) arr.splice(0, arr.length - 5);
            state.pathsHistory = arr;
            localStorage.setItem(localStorageKey, JSON.stringify(arr));
        },
        updateCurrentPathTitle: (state: ICommonBreadCrubmsState, { payload }: PayloadAction<string>): void => {
            if (state.pathsHistory && state.pathsHistory.length >= 1) {
                state.pathsHistory[state.pathsHistory.length - 1].title = payload;
            }
        },
    },
});

export {
    breadCrumbsActions,
};

export default reducer;
