import { Dispatch } from 'redux';
import emailjs from '@emailjs/browser';
import { AppState, AppThunk } from '../../store';
import { selectRequestAccessData, selectRequestAccessStatus } from '../selectors';
import { EmailSendStatus } from '../../types';
import { requestAccessActions } from '../index';

export const sendRequestAccessThunk = (): AppThunk => (dispatch: Dispatch, getState: () => AppState): void => {
    const status = selectRequestAccessStatus(getState());
    const data = selectRequestAccessData(getState());

    if (status === EmailSendStatus.sending || !data) {
        return;
    }

    if (!data.email
    || !data.userName
        || !data.phone
        || !data.userPost
        || !data.organizationName) {
        dispatch(requestAccessActions.sendFailed());
    }

    const templateParams = {
        userName: data.userName,
        userEmail: data.email,
        userPost: data.userPost,
        phone: data.phone,
        organizationName: data.organizationName,
        comment: data.comment,
    };

    dispatch(requestAccessActions.sending());
    emailjs.send('service_d2iil0u', 'template_g3lgsmm', templateParams, 'i0gK-UkTyXk5bxhGt')
        .then(() => {
            dispatch(requestAccessActions.sendComplete());
        }, () => {
            dispatch(requestAccessActions.sendFailed());
        });
};
