import { UploadFile, UploadProps } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React from 'react';
import { IconButtonLoadFile } from '@shared/index';
import styles from './styles.module.scss';

interface IProps {
    onUploadFile: (file: UploadFile) => void,
}

const Default: React.FC<IProps> = ({
    onUploadFile,
}): JSX.Element => {
    const props: UploadProps = {
        style: {
            position: 'absolute', top: 0, left: 0, background: 'rgba(0,0,0,0)', border: 'none', width: '100%', height: '100%', zIndex: 15,
        },
        name: 'file',
        beforeUpload: (file: UploadFile) => {
            onUploadFile(file);
            return false;
        },
        itemRender: () => null,
        accept: '.word,.pptx,.xlsx,.pdf',
        multiple: false,
    };
    return (
        <div className={styles.blockLoadFile_root}>
            <Dragger {...props} />
            <div className={styles.btnWithLabel}>
                <span className={styles.label}>Добавьте файл</span>
                <div style={{
                    position: 'relative',
                    display: 'block',
                    flexGrow: 1,
                }}
                >
                    <IconButtonLoadFile iconId="RoundAdd_Icn_NoneFill" />
                </div>
            </div>
            <div className={styles.additionlInfo}>
                <span>или перетащите </span>
            </div>
        </div>
    );
};

export default Default;
