/* eslint-disable max-len */
import React from 'react';
import MarkdownParser from '@shared/ui-components/Markdown';
import styles from './styles.module.scss';

const text3 = 'Этот **жирный текст** включает *курсив* и даже **жирный *и курсивный* текст**. Не забудем про ~~зачеркнутый текст~~ и `строчный код`. Вот так можно сделать **жирный `строчный код` внутри жирного текста**. Как насчет ~~зачеркнутого *и курсивного*~~? И последний вариант: `строчный код` с **жирным `и строчным кодом` внутри**.';

const text4 = text3.split(' ').map((word) => (word === 'строчным' ? `_µ${word}µ_` : word)).join(' ');

const MarkDownDemo: React.FC = (): JSX.Element => (
    <div className={styles.demoIcons_root}>
        <div style={{ marginBottom: 16 }}>
            <MarkdownParser
                text="Это **жирный текст**, а это _курсив_. Тут и **жирный _и курсив_** одновременно."
            />
        </div>
        <div style={{ marginBottom: 16 }}>

            <MarkdownParser
                text=" Этот *текст* служит **примером** для парсера. Если *всё* **работает**, то некоторые слова должны быть *курсивными*, а **другие** — жирными. Работает ли вложенность, как, например, в этом **жирном *курсивном* жирном** слове? Проверим!"
            />
        </div>
        <div style={{ marginBottom: 16 }}>
            <MarkdownParser
                text={text4}
                styledShanblons={{
                    '**': {
                        color: 'red',
                    },
                }}
            />
        </div>

        <div>

            <MarkdownParser
                text={text3}
                styledShanblons={{
                    '~~': {
                        color: 'green',

                    },
                    '`': {
                        backgroundColor: 'tomato',
                    },
                }}
            />
        </div>
    </div>

);

export default MarkDownDemo;
