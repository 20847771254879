import React from 'react';
import classnames from 'classnames';
// import SvgIcon from '../../../SvgIcon';
import useGetUserRole from '@shared/hooks/campaign/useGetUserRole';
import { ICampaignUserRole } from '@store/campaign/userRights/types';
import DownloadIcon from './imgs/download.svg';
import DeleteIcon from './imgs/garbage-basket.svg';
import EditIcon from './imgs/pen.svg';
import { ToolTipElementType } from './types';
import styles from './styles.module.scss';

const tooltipTextMap = new Map([
    [ToolTipElementType.download, 'Скачать'],
    [ToolTipElementType.edit, 'Редактировать'],
    [ToolTipElementType.delete, 'Удалить'],
]);

interface ICampaignMenuActionsProps {
    isVisible?: boolean;
    noDelete: boolean;
    onEdit: () => void;
    onDelete: () => void;
    onDownload: () => void;
    activeElements: ToolTipElementType[];
}

const CampaignMenuActions: React.FC<ICampaignMenuActionsProps> = ({
    onDownload, onEdit, onDelete,
    activeElements, noDelete,
    isVisible = true,
}): JSX.Element => {
    const userRole = useGetUserRole();
    const handlerDownload = (): void => {
        if (onDownload) {
            onDownload();
        }
    };
    const handlerEditCampaign = (): void => {
        if (onEdit && userRole !== ICampaignUserRole.readOnly) {
            onEdit();
        }
    };
    const handlerDeleteCampaign = (): void => {
        if (onDelete) {
            onDelete();
        }
    };

    if (!isVisible) {
        return null;
    }
    return (
        <div className={styles.root}>
            {
                activeElements.includes(ToolTipElementType.download) && (
                    <div className={styles.tooltip} data-tooltip={tooltipTextMap.get(ToolTipElementType.download)}>
                        <button
                            id="campaign-download"
                            type="button"
                            className={classnames(styles.button, styles.green)}
                            onClick={handlerDownload}
                        >
                            {/* <SvgIcon id="CampaignDownload" size={16} /> */}
                            <img src={DownloadIcon} alt="DownloadIcon" />
                        </button>
                    </div>
                )
            }
            {
                activeElements.includes(ToolTipElementType.edit) && userRole !== ICampaignUserRole.readOnly && (
                    <div className={styles.tooltip} data-tooltip={tooltipTextMap.get(ToolTipElementType.edit)}>
                        <button
                            id="campaign-edit"
                            type="button"
                            className={classnames(styles.button, styles.green)}
                            onClick={handlerEditCampaign}
                        >
                            <img src={EditIcon} alt="EditIcon" />
                        </button>
                    </div>
                )
            }
            {
                !noDelete
                    ? (
                        <div className={styles.tooltip} data-tooltip={tooltipTextMap.get(ToolTipElementType.delete)}>
                            <button
                                id="campaign-delete"
                                type="button"
                                className={classnames(styles.button, styles.red)}
                                onClick={handlerDeleteCampaign}
                            >
                                <img src={DeleteIcon} alt="DeleteIcon" />
                            </button>
                        </div>
                    )
                    : null
            }
        </div>
    );
};

export default CampaignMenuActions;
