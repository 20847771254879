/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import EventBusForChartGraph from '../../deprecated/pagesComponents/info-trace/PublicationChartGraph/EventBus';
import { ICategory, IFilter } from '../../sections/MyCampaignContainer/CampaingTypes';
import MyCompaignFilters from '../CampaingCategories';
import { userTagsCampaingRtqService } from '../../services/campaigns/userTags';
import ModalCreateTag from '../ModalCreateTag';
import ModalTagInfo from '../ModalTagInfo';

interface IPropsUserTags {
    campaignId: number,
    isExclude: boolean,
    filtersTags: any,
    setFiltersTags: any,
    updateCategories: (categoryName: string, filterName: string, type: string) => any,
    changeTypeFiltersApply: (type: 1 | 2, name: string) => void,
    activeTags: IFilter[]

}
const UserTags: React.FC<IPropsUserTags> = ({
    campaignId,
    isExclude,
    filtersTags,
    setFiltersTags,
    updateCategories,
    changeTypeFiltersApply,
    activeTags,
}): JSX.Element => {
    const [isOpenModalCreateTag, setIsOpenModalCreateTag] = useState<any>(false);
    const [isOpenModalTagInfo, setIsOpenModalTagInfo] = useState<any>(false);
    const [getTags] = userTagsCampaingRtqService.useLazyGetUserTagsQuery();
    // const [filtersTags, setFiltersTags] = useState<IFilter[]>([]);
    const tagsCategory = useMemo((): ICategory => ({
        filters_category: 'Пользовательские теги',
        filters: filtersTags.map((item) => ({
            ...item,
            disabled: !activeTags.find((tag) => tag.filter_name.toLowerCase() === item.filter_name.toLowerCase()),
        })),
        total_filters_count: 0,
        disableCounter: true,
    }), [filtersTags, activeTags]);
    const handleChangeCategory = (categoryName: string, filterName: string, type: string): void => {
        setFiltersTags((prev) => prev.map((filter) => {
            if (filter.filter_name === filterName) {
                const newFilter = { ...filter };
                newFilter[(type as any)] = !newFilter[(type as any)];
                // todo excludes
                // newFilter[(type as any)] = newFilter[(type as any)];
                return newFilter;
            }
            return filter;
        }));
        setTimeout(() => updateCategories(categoryName, filterName, type), 10);
    };
    const handleAddTag = () => {
        // EventBusForChartGraph.emit('openModalCreateTag', []);
        setIsOpenModalCreateTag(true);
    };
    const openModal = (id) => {
        setIsOpenModalTagInfo(id);
    };
    const generateTagsFilters = (rawTags: any[]) => {
        const result: IFilter[] = rawTags.map((item): IFilter => {
            const newItem: IFilter = {
                filter_name: item?.title,
                count: 0,
                checked: false,
                include: false,
                exclude: false,
                withConditions: item?.manual === false,
                id: item?.id,
                handler: () => openModal(item?.id),
            };
            return newItem;
        });
        setFiltersTags(result.reverse());
    };
    const createListTags = () => {
        getTags({ campaign_id: campaignId })
            .then((res) => {
                if (Array.isArray(res?.data)) generateTagsFilters(res?.data as any[]);
            });
    };
    const handleChangeTypeFiltersApply = (type: 1 | 2) => { changeTypeFiltersApply(type, 'Пользовательские теги'); };
    // mounted
    const handlerCreateTag = () => {
        createListTags();
    };
    useEffect(() => {
        if (!filtersTags?.length) createListTags();
        EventBusForChartGraph.on('changeTags', createListTags);
        return () => {
            EventBusForChartGraph.off('changeTags', createListTags);
        };
    }, []);
    return (
        <>
            <ModalTagInfo
                campaignId={campaignId}
                onClose={() => setIsOpenModalTagInfo(false)}
                isOpen={isOpenModalTagInfo}
            />
            <ModalCreateTag
                onCreateTag={handlerCreateTag}
                campaignId={+campaignId}
                onClose={() => setIsOpenModalCreateTag(false)}
                isOpen={isOpenModalCreateTag}
            />
            <MyCompaignFilters
                isExclude={isExclude}
                withControls
                seporatorLine
                category={tagsCategory}
                onClickControl={handleAddTag}
                updateCategories={handleChangeCategory}
                changeTypeFiltersApply={handleChangeTypeFiltersApply}
            />
        </>
    );
};

export default UserTags;
