import { AppState } from 'store/store';

const trackedSources = (state: AppState) => state.campaign.trackedSources;
const fetchStatus = (state: AppState) => trackedSources(state)?.fetchStatus;
const data = (state: AppState) => trackedSources(state)?.data;
const tracked = (state: AppState) => trackedSources(state)?.selectedSources.tracked;
const excluded = (state: AppState) => trackedSources(state)?.selectedSources.excluded;
const empty = null;

export default {
    fetchStatus,
    data,
    tracked,
    excluded,
    empty,
};
