import React, { useRef } from 'react';
import classnames from 'classnames';
import { IBloggerProfileProps } from './types';
import BloggerProfileCategories from './Categories/BloggerProfileCategories';
import BloggerProfilePublications from './Publications/BloggerProfilePublications';
import BloggerProfileCommonContent from './CommonContent/BloggerProfileCommonContent';
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';

import styles from './styles.module.scss';

const BloggerProfile: React.FC<IBloggerProfileProps> = ({
    isOpen, setIsOpen, data,
    isLoadingData, isLoadingPublications,
    addVisiblePublication, isMaxPublicationsAmount,
    isRetinaSize,
}):JSX.Element => {
    const ref = useRef();
    const handlerOnClickOutside = (): void => {
        if (isOpen) {
            setIsOpen(false);
        }
    };
    useOnClickOutside(ref, handlerOnClickOutside);
    return isOpen && (
        <div className={styles.overlay}>
            <div className={classnames(styles.modalWindow, styles[isRetinaSize ? 'retina' : ''])} ref={ref}>
                <BloggerProfileCommonContent isRetinaSize={isRetinaSize} setIsOpen={setIsOpen} data={data} isLoading={isLoadingData} />
                <BloggerProfileCategories isRetinaSize={isRetinaSize} dataSet={data} />
                <BloggerProfilePublications
                    isRetinaSize={isRetinaSize}
                    statistics={data?.statistics}
                    publications={data?.publications}
                    isLoading={isLoadingPublications}
                    addVisiblePublication={addVisiblePublication}
                    isMaxPublicationsAmount={isMaxPublicationsAmount}
                />
            </div>
        </div>
    );
};
export default BloggerProfile;
