import { rtkQApi } from '../../index';
import { IFavoriteWords, IQueryParamsCreateWord } from './types';

export const favoriteWordsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getFavoriteWords: build.query<IFavoriteWords[], void>({
            query: () => ({
                url: 'campaign/favorite_words/',
                method: 'GET',
            }),
            providesTags: ['FavoriteWordsList'],
        }),
        postCreateFavoriteWord: build.query<IFavoriteWords, IQueryParamsCreateWord>({
            query: (body) => ({
                url: 'campaign/favorite_words/',
                method: 'POST',
                body,
            }),
        }),
        deleteFavoriteWord: build.mutation<void, { id: number }>({
            query: (params) => ({
                url: `campaign/favorite_words/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['FavoriteWordsList'],
        }),
    }),
});
