import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'ru',
        resources: {
            ru: {
                translation: {
                    TIME_CHART: 'Линейный график',
                    BUBBLE_CHART: 'Градусная диаграмма',
                    BAR_CHART: 'Гистограммы',
                    PIE_CHART: 'Круговая диаграмма',
                    MAP_CHART: 'Карта',
                    TAG_CHART: 'Облако тегов',

                    semantic: 'тональность',
                    neutral: 'нейтрально',
                    positive: 'положительно',
                    negative: 'негативно',

                    Semantic: 'Тональность',
                    Neutral: 'Нейтрально',
                    Positive: 'Положительно',
                    Negative: 'Негативно',
                    Skip: 'Не известно',

                    platform: 'платформа',
                    skip: 'не определено',
                    SEMANTIC: 'Тональность',
                    LOCATION: 'Регионы',
                    location: 'регионы',
                    AUTHORS: 'Авторы',
                    authors: 'авторы',
                    POSTS: 'Посты',
                    PLATFORM: 'Платформы',

                    SOURCE: 'Источники',
                    PLATFORM_TYPE: 'платформы',
                    CAMPAIGN: 'кампании',
                    HOUR: '1 час',
                    FOUR_HOUR: '4 часа',
                    EIGHT_HOUR: '8 часов',
                    DAY: 'Сутки',
                    WEEK: 'Неделя',
                    MONTH: 'Месяц',
                    start: 'В начале',
                    middle: 'В середине',
                    end: 'В конце',

                    // статусы проекта
                    cancelled: 'Отменён',
                    completed: 'Завершён',
                    inProgress: 'В проработке',
                    inWork: 'В работе',
                    mediaPlan: 'Подготовка медиаплана',
                    results: 'Подведение итогов',
                    takeToWork: 'Взять в работу',

                    // этапы проекта
                    brief: 'Бриф',
                    longList: 'Лонглист',
                    mediaPlanStage: 'Медиаплан',
                    monitoring: 'Мониторинг',
                    resultsStage: 'Результат проекта',
}
            }
        }
    })