const getReduction = (number: number) => {
    if (number > 9999 && number < 1000000) return { abbreviation: ' К', reduction: (number / 1000).toFixed(0) };
    if (number > 999999 && number < 1000000000) return { abbreviation: ' МЛН', reduction: (number / 1000000).toFixed(0) };
    if (number >= 1000000000) return { abbreviation: ' МЛРД', reduction: (number / 1000000000).toFixed(0) };
    return { abbreviation: '', reduction: number };
};
export const getAbbreviation = (number: number) => {
    if (number < 10000) return number?.toLocaleString('ru-RU');
    const currentReductin = getReduction(number);
    const newNumber = `${currentReductin.reduction}${currentReductin.abbreviation}`;
    return newNumber;
};
