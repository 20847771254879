import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IPDFHandlerReportPage } from '../../../store/pdf-handler/report/types';
import { useAppDispatch } from '../../../store/hooks';
import { pdfHandlerReportActions } from '../../../store/pdf-handler/report';
import pdfHandlerWrapperSelectors from '../../../store/pdf-handler/wrapper/selectors';
import pdfHandlerReportSelectors from '../../../store/pdf-handler/report/selectors';
import { AppState } from '../../../store/store';
import { loadCategoriesIfNotExists } from '../../../store/info-basket/category/thunks/load-category';
import categorySelector from '../../../store/info-basket/category/selectors';
import { FetchStatus } from '../../../store/types';
import { infoBasketCategoryActions } from '../../../store/info-basket/category';
import { IPdfHandlerHook } from './types';

const usePdfHandler = (): IPdfHandlerHook => {
    const dispatch = useAppDispatch();
    const reportId = useSelector(pdfHandlerWrapperSelectors.reportId);
    const downloadLink = useSelector(pdfHandlerWrapperSelectors.pdfWrapperDownload);
    const document = useSelector(pdfHandlerWrapperSelectors.document);
    const documentIsPreparing = useSelector(pdfHandlerWrapperSelectors.processing);
    const file = useSelector((state: AppState) => pdfHandlerReportSelectors.fileByReportId(state, reportId));
    const categoriesLoadingStatus = useSelector(categorySelector.loadingStatus);
    const categoriesIds = useSelector(categorySelector.ids);

    const loadingData = useMemo<boolean>(
        () => categoriesLoadingStatus === FetchStatus.loading,
        [categoriesLoadingStatus],
    );

    const addPdfPage = useCallback((page: IPDFHandlerReportPage): void => {
        dispatch(pdfHandlerReportActions.addPageInReport({ page, reportId }));
    }, [reportId]);

    useEffect(() => {
        if (!categoriesIds?.length) {
            dispatch(loadCategoriesIfNotExists());
        }
    }, [categoriesIds]);
    useEffect(() => {
        if (categoriesLoadingStatus === FetchStatus.loading && Boolean(categoriesIds?.length)) {
            dispatch(infoBasketCategoryActions.setLoadingSuccess());
        }
    }, [categoriesLoadingStatus, categoriesIds]);

    return {
        reportId,
        downloadLink,
        document,
        file,
        documentIsPreparing,
        loadingData,
        addPdfPage,
    };
};

export default usePdfHandler;
