export enum ArrowIconsIds {
    // Стрелки
    DropDownArrow = 'DropDownArrow',
    DropdownArrow2 = 'dropdownArrow',
    DropdownArrow3 = 'dropdownArrowGrey',
    ArrowLeft = 'ArrowLeft',
    NextArrow = 'NextArrow',
    BackArrow = 'BackArrow',
    ArrowRight = 'ArrowRight',
    LeftArrowIcon = 'LeftArrowIcon',
    RightArrowIcon = 'RightArrowIcon',
    ActiveArrowIcon = 'ActiveArrowIcon',
    InactiveArrowIcon = 'InactiveArrowIcon',
    SelectArrow = 'selectArrow',
    ArrowCarousel = 'ArrowCarousel',
    LeftControl = 'Left_Control',
    RightControl = 'Right_Control',
    SortingArrow = 'SortingArrow',
}
export enum CrossIconsIds {
    // Крестики
    Close = 'Close',
    NewClose = 'NewClose',
    HintCross = 'HintCross',
    TagCross = 'TagCross',
    FailCross = 'FailCross',
    FailedIcon = 'FailedIcon',
    Cross = 'Cross',
    RedCross = 'RedCross',
    BigCross = 'BigCross',
    WhiteCross = 'WhiteCross',
}

export enum LogoIconsIds {
    // Логотипы
    RssLogo = 'RssLogo',
    TgLogo = 'TgLogo',
    VkLogo = 'VkLogo',
    OkLogo = 'OkLogo',
    ZenLogo = 'ZenLogo',
    YouTubeLogo = 'YouTubeLogo',
    RuTubeLogo = 'RuTubeLogo',
    VkVideo = 'VkVideoLogo',
}

export enum CommonIconsIds {
    // Остальное
    WarningCircle = 'warning_circle',
    Download = 'Download',
    HelpCircle = 'help-circle',
    AddPlus = 'Add_PLUS',
    Trashcan = 'Trashcan',
    PlayInCircle = 'Play_in_circle',
    RemoveIcon = 'RemoveIcon',
    FindIcon = 'FindIcon',
    CheckboxArrow = 'checkboxArrow',
    CampaignDownload = 'CampaignDownload',
    ChartUp = 'ChartUp',
    ChartVerticalBar = 'ChartVerticalBar',
    ChartLine = 'ChartLine',
    AddStart = 'AddStart',
    ChangeIcon = 'ChangeIcon',
    BlueStar = 'BlueStar',
    ChartInTable = 'ChartInTable',
    Calendar = 'Calendar',
    AscDescIcon = 'ascDescIcon',
    Scales = 'scales',
    Person = 'Person',
    LinkIcon = 'LinkIcon',
    ArrowDownBlue = 'ArrowDownBlue',
    Favorite = 'favorite',
    InputIcon = 'InputIcon',
    Search = 'Search',
    AddInput = 'addInput',
    ThreeDot = 'ThreeDot',
    WordIcon = 'Word_Icon',
    PDFIcon = 'PDF_Icon',
    PPTXIcon = 'PPTX_Icon',
    XLSXIcon = 'XLSX_Icon',
    ChangeMiniIcn = 'ChangeMini_Icn',
    RoundAddIcn = 'RoundAdd_Icn',
    RoundAddIcnNoneFill = 'RoundAdd_Icn_NoneFill',
    SortingIcon = 'SortingIcon',
    Like = 'Like',
    Reports = 'Reports',
    Comments = 'Comments',
    Views = 'Views',
    CheckMark = 'CheckMark',
    SuccessIcon = 'SuccessIcon',
    CampaignMenuIcon = 'CampaignMenuIcon',
    SettingsIcon = 'SettingsIcon',
    ParametersIcon = 'ParametersIcon',
    ClockIcon = 'ClockIcon',
    ChartsIcon = 'ChartsIcon',
    ReportsIcon = 'ReportsIcon',
    TagsIcon = 'TagsIcon',
    AuthorsIcon = 'AuthorsIcon',
    TrashCanIcon = 'TrashCanIcon',
    ProjectsIcon = 'ProjectsIcon',
    SourcesIcon = 'SourcesIcon',
    SourceInactiveIcon = 'SourcesGrayIcon',
    DashboardIcon = 'DashboardIcon',
    CampaignIcon = 'CampaignIcon',
    InfoIcon = 'InfoIcon',
    FilteredIcon = 'FilteredIcon',
    PlusIcon = 'PlusIcon',
    noSource = 'noSource',
    PaperClip = 'PaperClip',
    GreyPencil = 'GreyPencil',
    CalendarSmall = 'CalendarSmall',
    Complete = 'Complete',
    Visibility = 'Visibility',
    ResetControl = 'Reset_Control',
    FullScreenControl = 'FullScreen_Control',
    RefreshCow = 'refresh-cow',
    ArrowHorizontal = 'arrow-horizontal',
    FavoriteIcon = 'FavoriteIcon',
    PlusLargeIcon = 'PlusLargeIcon',
    SourcesFavourite = 'SourcesFavouriteIcon',
    SourcesFavouriteActive = 'SourcesFavouriteIconActive',
    SourcesList = 'SourcesListsIcon',
    SourcesListActive = 'SourcesListsIconActive',
    IconComplete = 'IconComplete',
    Error = 'Error',
    Warning = 'Warning',
    PlusCircle = 'PlusCircle',
}
