import React, { useEffect, useMemo, useState } from 'react';
import { Switch } from 'antd';
import { ITableColumn } from '../../../entities/Table/types';
import SvgIcon from '../../../components/SvgIcon';
import { campaignNotificationRtkQService } from '../../../services/campaigns/notification';
import { campaignsListRtkQService } from '../../../services/campaigns/campaigns-list';
import styles from './styles.module.scss';
import { Table } from '../../../entities';
import { INotificationTableRow } from './types';
import { NOTIFICATION_TG_URL } from '../../../utils/api';

const CampaignNotificationContainer: React.FC = (): JSX.Element => {
    const { data: campaigns, isFetching: campaignsIsFetching } = campaignsListRtkQService.useGetCampaignsQuery();
    const [
        getNotifications,
        { data: campaignsNotifications, isFetching: notificationsIsFetching },
    ] = campaignNotificationRtkQService.useLazyGetNotificationQuery();
    const [setNotificationStatus] = campaignNotificationRtkQService.usePutSetNotificationStatusMutation();
    const [
        getSubscribeTgToken,
        {
            data: tgTokenData,
            isFetching: isFetchingTgToken,
        },
    ] = campaignNotificationRtkQService.useLazyGetSubscribeTgTokenQuery();

    const [checkingSubscribe, setCheckingSubscribe] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<any>(null);
    const [checkingSubscribeResult, setCheckingSubscribeResult] = useState<boolean>(false);
    const [checkingSubscribeCampaignName, setCheckingSubscribeCampaignName] = useState<null | string>(null);

    useEffect(() => {
        if (checkingSubscribe && !timerId) {
            setTimerId(
                setInterval(() => getNotifications(), 3000),
            );
        }
        if (!checkingSubscribe && timerId) {
            clearTimeout(timerId);
            setTimerId(null);
        }
        if (checkingSubscribeResult) {
            clearTimeout(timerId);
            setTimerId(null);
            setCheckingSubscribe(false);
            setCheckingSubscribeResult(false);
            setCheckingSubscribeCampaignName(null);
        }
    }, [checkingSubscribe, timerId, checkingSubscribeResult]);

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        if (!isFetchingTgToken && tgTokenData?.token) {
            const botHref = `${NOTIFICATION_TG_URL}?start=${tgTokenData.token}`;
            window.open(botHref, '_blank');
            setCheckingSubscribe(true);
        }
    }, [isFetchingTgToken, tgTokenData]);

    useEffect(() => {
        if (campaignsNotifications && campaignsNotifications.length >= 1 && checkingSubscribeCampaignName && !notificationsIsFetching) {
            const exist = campaignsNotifications.filter((item) => item.campaignName === checkingSubscribeCampaignName);
            if (exist[0]) setCheckingSubscribeResult(true);
        }
    }, [checkingSubscribeCampaignName, campaignsNotifications, notificationsIsFetching]);

    const rows: INotificationTableRow[] = useMemo(() => {
        if (!campaigns || !campaignsNotifications) return [];
        return campaigns.map((campaign) => {
            const notificationExist = campaignsNotifications.filter(
                (item) => item.campaignName === campaign.name,
            );
            const status = notificationExist[0] ? notificationExist[0].enabled : false;
            const subscribed = Boolean(notificationExist[0]);
            return {
                id: campaign.id,
                name: campaign.name,
                status,
                subscribed,
                notificationId: notificationExist[0] ? notificationExist[0].id : null,
            };
        });
    }, [campaigns, campaignsNotifications]);

    const isLoadingRows = useMemo(() => notificationsIsFetching || campaignsIsFetching, [notificationsIsFetching, campaignsIsFetching]);

    const handlerChangeNotification = (
        campaignId: number,
        enabled: boolean,
    ): void => {
        setNotificationStatus({ id: campaignId, enabled });
    };

    const handlerSubscribe = async (campaignId: number, campaignName: string) => {
        getSubscribeTgToken({ id: campaignId });
        setCheckingSubscribeCampaignName(campaignName);
    };

    const columns: ITableColumn<INotificationTableRow>[] = useMemo(() => [
        {
            id: 1,
            title: null,
            dataIndex: 'id',
            width: 70,
            isIndex: true,
        },
        {
            id: 2,
            title: 'Наименование кампании',
            dataIndex: 'name',
        },
        {
            id: 3,
            title: 'Тип уведомления',
            dataIndex: 'id',
            render: () => <span>О новых сообщениях</span>,
        },
        {
            id: 4,
            title: 'Каналы',
            dataIndex: 'id',
            render: () => (
                <div className={styles.rowChannel}>
                    <SvgIcon id="TgLogo" />
                    <p>Telegram</p>
                </div>
            ),
        },
        {
            id: 5,
            title: 'Статус',
            dataIndex: 'status',
            render: (_value, record) => (
                <Switch
                    id="campaign-activate-on-notification"
                    size="small"
                    checked={record.status}
                    disabled={!record.subscribed}
                    onChange={() => handlerChangeNotification(record.notificationId, !record.status)}
                />
            ),
        },
        {
            id: 6,
            title: 'Дополнительно',
            dataIndex: 'subscribed',
            render: (value, record) => (
                <div>
                    {!value && (
                        <button
                            type="button"
                            className={styles.subscribeBtn}
                            onClick={() => handlerSubscribe(record.id, record.name)}
                        >
                            Подписаться
                        </button>
                    )}
                    {value
                            && (
                                <a
                                    target="_blank"
                                    href={NOTIFICATION_TG_URL}
                                    rel="noreferrer"
                                    className={styles.linkChatBot}
                                >
                                    Ссылка на чат-бот
                                </a>
                            )}
                </div>
            ),
        },
    ], [handlerChangeNotification, handlerSubscribe]);

    return (
        <Table
            columns={columns}
            rows={rows}
            isLoading={isLoadingRows}
        />
    );
};

export default CampaignNotificationContainer;
