import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
    CommonInfo, LongList, BlockStatus, Milestones, Detail, Contacts, Documents, MediaPlan,
} from '@widgets/project';
import { ICustomerInfo, IQPCustomer, IQPProjectInfo } from '@services/projects/types';
import BlockInfoProject from '@components/Project/BlockInfo';
import { projectCustomerRtkQService, projectInfoRtkQService, projectsStageService } from '@services/projects';
import { sourcesRtkQService } from '@services/sources';
import { categoriesRtkQService } from '@services/categories';
import { ISourceRow } from '@services/sources/types';
import { projectMediaPlanRtkQService } from '@services/projects/media-plan';
import Results from '@widgets/project/Results';
import Monitoring from '@widgets/project/Monitoring';
import moment from 'moment';
import { DATE_FORMAT_FOR_QUERY } from '@shared/lib/consts';
import ResultInfo from '@widgets/project/ResultInfo';
import { projectsResultsService } from '@services/projects/results';
import styles from './styles.module.scss';
// import SourcesForProject from '../../modals/SourcesForProject';
import ResultsCompleteModal from '../../modals/ProjectResultsComplete';

const ProjectPage: React.FC = (): JSX.Element => {
    const { Id: urlParametersId } = useParams();
    const [addSourcesIsOpen, setSourcesListState] = useState<boolean>(false);
    const [isOpenModalStopProject, setIsOpenModalStopProject] = useState<boolean>(false);
    // const [isActiveSidePage, setSidePageState] = useState<boolean>(false);
    const projectId = useMemo<number | null>(() => (
        Number.isInteger(Number(urlParametersId)) ? Number(urlParametersId) : null
    ), [urlParametersId]);
    const [stopProject] = projectsResultsService.useLazyStopProjectQuery();
    const [
        getProject,
        {
            data: project,
            isLoading: isLoadingProject,
            isFetching: isFetchingProject,
        },
    ] = projectInfoRtkQService.useLazyGetProjectQuery();
    const [
        updateCustomerInfo,
        {
            data: customerInfo,
            isLoading: isLoadingCustomerInfo,
        },
    ] = projectCustomerRtkQService.usePutUpdateCustomerInfoMutation();
    const [
        updateProjectInfo,
        {
            data: projectInfo,
            isLoading: isLoadingCommonInfo,
        },
    ] = projectInfoRtkQService.usePutUpdateProjectMutation();
    const [
        getStatus,
        {
            data: status,
            isFetching: isFetchingStatus,
            isLoading: isLoadingStatus,
        },
    ] = projectsStageService.useLazyGetStatusQuery();
    const [
        addSourcesInMediaPlan, { isLoading: isLoadingMediaPlan },
    ] = projectMediaPlanRtkQService.usePostAddSourcesInMediaPlanMutation();
    const {
        data: platforms,
        isFetching: isFetchingPlatforms,
        isLoading: isLoadingPlatforms,
    } = sourcesRtkQService.useGetLoadPlatformsQuery();
    const {
        data: categories,
        isLoading: isLoadingCategories,
        isFetching: isFetchingCategories,
    } = categoriesRtkQService.useGetCategoriesQuery();
    const isLoading = useMemo<boolean>(() => (
        isLoadingProject || isFetchingProject
    ), [isLoadingProject, isFetchingProject]);
    const isLoadingCustomer = useMemo<boolean>(() => (
        isLoadingCustomerInfo
    ), [isLoadingCustomerInfo]);
    const isLoadingInfo = useMemo<boolean>(() => (
        isLoadingCommonInfo
    ), [isLoadingCommonInfo]);
    const commonInfo = useMemo<{ name: string, description: string }>(() => {
        if (projectInfo) {
            return {
                name: projectInfo?.name,
                description: projectInfo?.description,
            };
        }
        return {
            name: project?.name,
            description: project?.description,
        };
    }, [projectInfo, project]);
    const customer = useMemo<ICustomerInfo | null>(() => (
        customerInfo || project?.customer
    ), [project, customerInfo]);
    const user = useMemo<string>(() => (
        project?.users[0]?.lastName ? project?.users[0]?.lastName : '---'
    ), [project]);
    const handlerUpdateProjectInfo = useCallback((value: IQPProjectInfo): void => {
        updateProjectInfo({
            id: projectId,
            name: value.name,
            description: value.description,
        });
    }, [projectId]);
    const handlerSaveContacts = useCallback((value: IQPCustomer): void => {
        updateCustomerInfo({
            id: projectId,
            name: value.name,
            address: value.address,
            companyName: value.companyName,
            personName: value.personName,
            contacts: value.contacts,
        });
    }, [projectId]);
    const handleStopProject = (comment: string) => {
        stopProject({
            id: projectId,
            body: {
                comment,
            },
        });
    };
    const handleCloseProject = () => setIsOpenModalStopProject(true);
    const handlerAddMediaPlan = useCallback((startDate: Date, endDate: Date, sources: ISourceRow[]): void => {
        addSourcesInMediaPlan({
            projectId,
            sources: sources.map((item) => ({ ...item, startDate, endDate })),
        });
    }, [projectId]);
    const [
        startMonitoring, { data: monitoringData, isError: isErrorCreateMonitoring },
    ] = projectMediaPlanRtkQService.usePostStartMonitoringMutation();
    const handleClickOnCreateMonitoring = useCallback((sources: ISourceRow[]) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const params = sources?.reduce((acc, item) => {
            const newAcc = JSON.parse(JSON.stringify(acc));
            newAcc.include_words.push(item.content);
            if (item.integrationLink) { newAcc.include_words.push(item.integrationLink); }
            newAcc.sources.push({ id: item.sourceId, link: item.link });
            return newAcc;
        }, {
            include_words: [],
            sources: [],
        });
        startMonitoring({
            project_name: project?.name,
            projectId,
            parse_from: moment(sources[0].startDate).format(DATE_FORMAT_FOR_QUERY),
            projectName: project?.name,
            ...params,
        });
    }, [project, projectId]);

    /**
     * Проект завершён
     */
    const projectIsComplete = useMemo<boolean>(() => (
        status?.stage === 5 && status?.status === 6
    ), [status]);

    useEffect(() => {
        if (monitoringData) { getStatus({ id: projectId }); }
    }, [monitoringData]);
    useEffect(() => {
        if (projectId && !isLoadingProject && !isFetchingProject && project?.id !== projectId) {
            getProject({ id: projectId });
            getStatus({ id: projectId });
            // getHistory({ id: projectId });
        }
    }, [projectId, isLoadingProject, isFetchingProject, project, status]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            <ResultsCompleteModal
                onClose={() => setIsOpenModalStopProject(false)}
                onApply={handleStopProject}
                isOpen={isOpenModalStopProject}
                withAccepted
                projectName={project?.name}
                projectId={projectId}
            />
            {
                !urlParametersId
                    ? (<CommonInfo />)
                    : (
                        <>
                            <div className={styles.viewPage_root}>
                                <div className={styles.viewPage_header}>
                                    <Milestones
                                        isLoading={isFetchingStatus || isLoadingStatus}
                                        status={status}
                                        projectIsComplete={projectIsComplete}
                                    />
                                    <BlockInfoProject
                                        isLoading={isLoading}
                                        name={user}
                                        create={project?.created.toLocaleDateString('ru-RU')}
                                    />
                                    <BlockStatus
                                        isLoading={isFetchingStatus || isLoadingStatus}
                                        status={status}
                                        projectId={projectId}
                                        onCloseProject={handleCloseProject}
                                    />
                                </div>
                                {
                                    status?.status >= 6 && (
                                        <ResultInfo projectId={projectId} comment={project?.comment} />
                                    )
                                }
                                <Detail
                                    isLoading={isLoading || isLoadingInfo}
                                    projectId={projectId}
                                    name={commonInfo?.name}
                                    description={commonInfo?.description}
                                    onUpdateInfo={handlerUpdateProjectInfo}
                                />
                                <Contacts
                                    isLoading={isLoadingCustomer || isLoading}
                                    customer={customer}
                                    onSaveContacts={handlerSaveContacts}
                                    status={status}
                                />
                                <Documents isLoading={isLoading} />
                                <LongList
                                    addSourcesIsOpen={addSourcesIsOpen}
                                    projectName={project?.name}
                                    stage={status?.stage ?? -1}
                                    projectId={projectId}
                                    platforms={platforms}
                                    isLoadingMediaPlan={isLoadingMediaPlan}
                                    isLoadingCategories={isLoadingPlatforms || isFetchingPlatforms}
                                    isLoadingPlatforms={isLoadingCategories || isFetchingCategories}
                                    categories={categories}
                                    openAdd={() => setSourcesListState(true)}
                                    setSourcesListState={(value) => setSourcesListState(value)}
                                    onCreateMediaPlan={handlerAddMediaPlan}
                                />
                                <MediaPlan
                                    projectId={projectId}
                                    projectName={project?.name}
                                    stage={status?.stage ?? -1}
                                    monitoringIsError={isErrorCreateMonitoring}
                                    onClickStartMonitoring={handleClickOnCreateMonitoring}
                                />
                                {
                                    status?.stage >= 4 && (
                                        <Monitoring
                                            projectId={projectId}
                                        />
                                    )
                                }
                                {
                                    status?.stage > 4 && (
                                        <Results
                                            projectId={projectId}
                                            projectIsEnded={status?.status >= 6}
                                        />
                                    )
                                }
                            </div>
                            {/* <SourcesForProject
                                isOpen={addSourcesIsOpen}
                                projectId={projectId}
                                sources={sourcesLongList}
                                isLoadingPlatforms={isLoadingPlatforms || isFetchingPlatforms}
                                isLoadingCategories={isLoadingCategories || isFetchingCategories}
                                platforms={platforms}
                                categories={categories}
                                onClose={() => setSourcesListState(false)}
                                onGetSources={() => null}
                            /> */}
                        </>
                    )
            }
        </>
    );
};

export default ProjectPage;
