import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import classnames from 'classnames';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { campaignsListRtkQService } from '@services/campaigns/campaigns-list';
import { INarrowMenuData } from '../../../NarrowMenu/types';
import styles from '../styles.module.scss';

interface IProps {
    campaignId: number;
    campaignName: string;
    items: INarrowMenuData[];
    location: string;
    onNavigate: (path: string) => void;
    setActiveCampaign: (id: number) => void;
}
const CampaignMenuItems: React.FC<IProps> = ({
    campaignId,
    campaignName,
    items,
    location,
    onNavigate,
    setActiveCampaign,
}): JSX.Element => {
    const handlerMenuClick = (path: string): void => {
        onNavigate(path);
    };
    const divRef = useRef(null);
    const inputRef = useRef(null);
    const [focused, setFocus] = React.useState<boolean>(false);
    const [valueSearch, setValueSearch] = useState<string>('');

    const { data: campaigns, isSuccess } = campaignsListRtkQService.useGetCampaignsQuery();

    const filteredData = useMemo<ICampaignDataModel[]>(() => {
        if (valueSearch === '') return [];
        return campaigns.filter((item) => item.name.toLowerCase().includes(valueSearch.toLowerCase()));
    }, [valueSearch, campaigns]);

    const checkActiveItem = (href: string): boolean => {
        const locationWithException = location.includes('/campaign/saved/') ? `${location}`.replace('saved', 'charts') : location;
        return locationWithException.includes(href);
    };
    const handlerSetCampaign = (id: number): void => {
        const localCampaign = filteredData.find(({ id: cId }) => cId === id);
        setActiveCampaign(id);
        if (localCampaign?.name) {
            setFocus(false);
            setValueSearch(localCampaign.name);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (divRef.current && !divRef.current.contains(event.target)) {
                if (focused) {
                    setFocus(false);
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [focused, setFocus]);

    return (
        <>
            {
                Boolean(campaignName) && (
                    <div ref={divRef} className={styles.campaignMenuItems}>
                        <input
                            ref={inputRef}
                            value={valueSearch}
                            placeholder={campaignName?.length > 20 ? `${campaignName.substring(0, 15)}...` : campaignName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValueSearch(event.target.value)}
                            onFocus={() => setFocus(true)}
                            className={styles.campaignMenuItems_campaignName}
                        />
                        {isSuccess && focused
                            && (
                                <div className={styles.campaignMenuItems_list} style={filteredData?.length ? null : { height: 0 }}>
                                    {filteredData.map((item) => (
                                        <span
                                            key={item.id}
                                            role="button"
                                            tabIndex={0}
                                            onKeyPress={() => null}
                                            onClick={() => handlerSetCampaign(item.id)}
                                            className={styles.campaignMenuItems_campaign}
                                        >
                                            {item.name}
                                        </span>
                                    ))}
                                </div>
                            )}
                    </div>
                )
            }
            {
                campaignId > 0 && items.map((item) => (
                    <button
                        id={item.tagId}
                        type="button"
                        key={item.id}
                        className={classnames(styles.subLink_item, styles[checkActiveItem(item.href) ? 'subLink_active' : ''])}
                        onClick={() => handlerMenuClick(item.href)}
                    >
                        {
                            checkActiveItem(item.href)
                                ? (
                                    <div className={styles.menuItem_active}>
                                        {item.ActiveIcon}
                                    </div>
                                )
                                : item.Icon
                        }
                        <span style={{ display: 'flex', textAlign: 'left' }} className={styles[checkActiveItem(item.href) ? 'subLink_active' : '']}>
                            {item.title}
                        </span>
                    </button>
                ))
            }
        </>
    );
};

export default CampaignMenuItems;
