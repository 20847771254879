import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CleanFieldState, ISearchResultState } from '../../types';

const initialState: ISearchResultState = {
    error: '',
    warning: '',
    errorTextAnalyze: '',
    warningTextAnalyze: '',
    info: '',
    isLoading: false,
    wasLaunched: false,
    wasTextAnalyzeLaunched: false,
    isTextAnalyzeLoading: false,
    isComponentProcessing: false,
    wasTextAnalyzeLaunchedAndFinish: false,
    textFieldCleanState: CleanFieldState.newText,
};

const { actions: searchResultActions, reducer } = createSlice({
    name: 'searchResult',
    initialState,
    reducers: {
        clearAll: (state: ISearchResultState) => {
            state.error = '';
            state.warning = '';
            state.errorTextAnalyze = '';
            state.warningTextAnalyze = '';
            state.info = '';
            state.isLoading = false;
            state.wasLaunched = false;
            state.wasTextAnalyzeLaunched = false;
            state.isTextAnalyzeLoading = false;
            state.isComponentProcessing = false;
            state.wasTextAnalyzeLaunchedAndFinish = false;
            state.textFieldCleanState = CleanFieldState.newText;
        },
        textFieldCanBeCleaned: (state: ISearchResultState) => {
            state.textFieldCleanState = CleanFieldState.canBeCleaned;
        },
        textFieldDefaultState: (state: ISearchResultState) => {
            state.textFieldCleanState = CleanFieldState.newText;
        },
        clearMessages: (state: ISearchResultState) => {
            state.error = '';
            state.warning = '';
            state.errorTextAnalyze = '';
            state.warningTextAnalyze = '';
            state.info = '';
        },
        clearWarnOrErr: (state: ISearchResultState) => {
            state.errorTextAnalyze = '';
            state.warningTextAnalyze = '';
        },
        isLoading: (state: ISearchResultState) => {
            state.isLoading = true;
            state.wasLaunched = true;
        },
        setTextAnalyzeLaunchedAndFinish: (state: ISearchResultState) => {
            state.wasTextAnalyzeLaunchedAndFinish = true;
        },
        isTextAnalyzeLoading: (state: ISearchResultState) => {
            state.isTextAnalyzeLoading = true;
            state.wasTextAnalyzeLaunched = true;
        },
        finishTextAnalyze: (state: ISearchResultState) => {
            state.wasTextAnalyzeLaunched = false;
        },
        loadingCompleted: (state: ISearchResultState) => {
            state.isLoading = false;
        },
        loadingTextAnalyzeCompleted: (state: ISearchResultState) => {
            state.isTextAnalyzeLoading = false;
        },
        componentProcessing: (state: ISearchResultState) => {
            state.isComponentProcessing = true;
        },
        componentProcessingCompleted: (state: ISearchResultState) => {
            state.isComponentProcessing = false;
        },
        setTextAnalyzeError: (
            state: ISearchResultState,
            { payload }: PayloadAction<string>,
        ) => {
            if (state.isTextAnalyzeLoading) {
                return;
            }
            state.errorTextAnalyze = payload;
            state.warningTextAnalyze = '';
            state.info = '';
        },
        setTextAnalyzeWarning: (
            state: ISearchResultState,
            { payload }: PayloadAction<string>,
        ) => {
            if (state.isTextAnalyzeLoading) {
                return;
            }
            if (!state.errorTextAnalyze) {
                state.warningTextAnalyze = payload;
            }
            state.info = '';
        },
        setError: (
            state: ISearchResultState,
            { payload }: PayloadAction<string>,
        ) => {
            state.error = payload;
            state.warning = '';
            state.info = '';
        },
        setWarning: (
            state: ISearchResultState,
            { payload }: PayloadAction<string>,
        ) => {
            if (state.isLoading) {
                return;
            }
            if (!state.error) {
                state.warning = payload;
            }
            state.info = '';
        },
        setInfo: (
            state: ISearchResultState,
            { payload }: PayloadAction<string>,
        ) => {
            if (state.isLoading) {
                return;
            }
            state.error = '';
            state.warning = '';
            state.info = payload;
        },
    },
});

export { searchResultActions };
export default reducer;
