/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import BarChart from '@entities/Charts/MonitoringBarChart';
import { chartsCampaingRtqService } from '@services/campaigns/Charts';
import moment from 'moment';
import { DATE_FORMAT_FOR_QUERY, COLORS_FOR_CHARTS } from '@shared/lib/consts';
import LegendMonitoringChartViews from '@entities/Charts/Legend';
import { trackingCampaingRtqService } from '@services/campaigns/tracking';
import Select from '@shared/ui-components/Select';
import ChartStatus from '@entities/ChartStatus';
import styles from './styles.module.scss';

interface IProps {
    projectId: number,
    campaignId: number,
    from_date: string,
    end_date: string,
}

const MonitoringChartER: React.FC<IProps> = ({
    projectId, campaignId, from_date, end_date,
}): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [getChartData, { data, isError }] = chartsCampaingRtqService.useNewGetChartDataMutation();
    const [categoriesForSelect, setCategories] = useState([]);
    const getLoading = useMemo(() => !!data?.data.some((item) => !item?.y?.length), [data]);
    const [
        getFilters,
        {
            data: categoriesData,
        },
    ] = trackingCampaingRtqService.useLazyGetFiltersQuery();
    const [listChartData, setListChartData] = useState([]);
    const modifiGetChartData = (campaign_id: number) => {
        if (!campaignId) return;
        getChartData({
            name: 'BAR CHART DASHBOARD',
            time_frame: 'DAY',
            chart_type: 'ER_CHART',
            campaign_id,
            from_date: moment(from_date).format(DATE_FORMAT_FOR_QUERY),
            to_date: moment(end_date).add(14, 'd').format(DATE_FORMAT_FOR_QUERY),
            metrics: ['ER'],
            dimensions: [],
            filters_include: categoriesForSelect.filter((item) => item.select)
                .map((item) => ({ filter_name: item.value, filters_category: 'Категории' })),
        } as any);
    };
    useEffect(() => {
        if (campaignId) {
            getFilters({
                campaign_id: campaignId,
                filters_include: [],
            });
        }
    }, [categoriesForSelect, campaignId]);
    useEffect(() => {
        // 96
        modifiGetChartData(campaignId);
    }, [categoriesForSelect]);
    useEffect(() => {
        const filter = categoriesData?.find((item) => item.filters_category === 'Категории');
        const onlyCategories = filter?.filters;
        const selectItems = onlyCategories?.map((item, index) => {
            const newItem = {
                id: `${index}_${item.filter_name}`,
                value: item.filter_name,
                label: item.filter_name,
                select: false,
            };
            return newItem;
        });
        if (selectItems?.length) { setCategories(selectItems); }
    }, [categoriesData]);
    const handleSelectCategory = useCallback((id: string) => {
        setCategories((prev) => prev.map((category) => {
            const rest = {
                ...category,
                select: category.id === id ? !category?.select : !!category?.select,
            };
            return rest;
        }));
    }, [categoriesForSelect]);
    useEffect(() => {
        if (data?.data) {
            const needData = data.data.filter((item, index) => index <= 9);
            const chartData = needData?.map((item, index) => ({
                color: COLORS_FOR_CHARTS[index]?.default ?? '#dfdfdf',
                label: item.subjectValues ? item.subjectValues[0] : 'default',
                disabled: false,
                allColors: COLORS_FOR_CHARTS[index],
                data: item?.y[0],
            }));
            setListChartData(chartData);
        } else { setListChartData([]); }
    }, [data]);
    const handleChageExceptions = (elem, disabled) => {
        setListChartData((prev) => prev.map((chart) => {
            if (chart.label !== elem.label) return chart;
            return { ...elem, disabled };
        }));
    };
    const getHandledListChartData = useMemo(() => {
        const newList = listChartData.filter((item) => !item.disabled);
        return newList;
    }, [listChartData, data]);
    const getLegend = useMemo(() => (
        <LegendMonitoringChartViews
            onChange={handleChageExceptions}
            data={listChartData}
        />
    ), [listChartData]);
    const handleClickOnUpdate = () => {
        modifiGetChartData(campaignId);
    };
    if (!projectId || !campaignId) return null;
    return (
        <div className={styles.monitoringChartViews}>
            <div style={{ display: 'flex' }}>
                <BarChart chartData={getHandledListChartData as any} />
                <Select
                    size="medium"
                    isCheckBoxes
                    placeholder="Категории"
                    items={categoriesForSelect}
                    onSelect={handleSelectCategory}
                />
            </div>
            <ChartStatus isError={isError} isLoading={getLoading} onUpdateClick={handleClickOnUpdate} />
            <div className={styles.monitoringChartViews_legend}>
                {getLegend}
            </div>
        </div>
    );
};

export default MonitoringChartER;
