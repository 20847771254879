import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './styles.module.scss';

const SettingsPage: React.FC = (): JSX.Element => (
    <div className={styles.settingsPage_root}>
        <Outlet />
    </div>
);

export default SettingsPage;
