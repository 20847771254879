import { ISource, ISourceRow } from '@services/sources/types';

export const transformSourcesResponse = (baseQueryResponse: any): ISource => {
    const result: ISource = { total: 0, sources: [] };
    result.total = baseQueryResponse?.total;
    result.sources = baseQueryResponse?.sources?.map(
        (item: any) => ({
            subscriberGrowth: {
                day: (item?.subscriber_growth ?? []).find((el) => el.name === 'сегодня')?.value ?? 0,
                month: (item?.subscriber_growth ?? []).find((el) => el.name === 'месяц')?.value ?? 0,
                week: (item?.subscriber_growth ?? []).find((el) => el.name === 'неделя')?.value ?? 0,
            },
            author: item?.author,
            sourceType: item?.source_type,
            id: item?.id,
            subscriberGrowthPercent: {
                day: item?.subscriber_growth_percent?.day ?? 0,
                month: item?.subscriber_growth_percent?.month ?? 0,
                week: item?.subscriber_growth_percent?.week ?? 0,
            },
            title: item?.title,
            subscribers: item?.subscribers,
            categories: item?.categories,
            er: item?.er_avg,
            link: item?.link,
            logoUrl: item?.logo_url,
        } as ISourceRow),
    ) ?? [];
    return result;
};

export const transformInfinitySourcesResponse = (baseQueryResponse: any): ISource => {
    const result: ISource = {
        total: 0, sources: [], previousPageToken: '', nextPageToken: '',
    };
    result.previousPageToken = baseQueryResponse?.previous_page_token;
    result.nextPageToken = baseQueryResponse?.next_page_token;
    result.total = baseQueryResponse?.total ?? 0;
    result.sources = baseQueryResponse?.items?.map(
        (item: any) => ({
            subscriberGrowth: {
                day: (item?.subscriber_growth ?? []).find((el) => el.name === 'сегодня')?.value ?? 0,
                month: (item?.subscriber_growth ?? []).find((el) => el.name === 'месяц')?.value ?? 0,
                week: (item?.subscriber_growth ?? []).find((el) => el.name === 'неделя')?.value ?? 0,
            },
            author: item?.author,
            sourceType: item?.source_type,
            id: item?.id,
            subscriberGrowthPercent: {
                day: item?.subscriber_growth_percent?.day ?? 0,
                month: item?.subscriber_growth_percent?.month ?? 0,
                week: item?.subscriber_growth_percent?.week ?? 0,
            },
            title: item?.title,
            subscribers: item?.subscribers,
            categories: item?.categories,
            er: item?.er_avg,
            erAvg: item?.er_avg,
            link: item?.link,
            logoUrl: item?.logo_url,
            viewsAvg: item?.views_avg,
            lists: item?.lists || [],
        } as ISourceRow),
    ) ?? [];
    return result;
};

export const transformLonglistResponse = (baseQueryResponse: any): ISource => {
    const result: ISource = { total: 0, sources: [] };
    result.total = baseQueryResponse?.total;
    result.sources = baseQueryResponse?.sources?.map(
        (item: any) => ({
            subscriberGrowth: {
                day: (item?.subscriber_growth ?? []).find((el) => el.name === 'сегодня')?.value ?? 0,
                month: (item?.subscriber_growth ?? []).find((el) => el.name === 'месяц')?.value ?? 0,
                week: (item?.subscriber_growth ?? []).find((el) => el.name === 'неделя')?.value ?? 0,
            },
            author: {
                id: item?.author_id,
                title: item?.author_title,
            },
            sourceType: {
                id: item?.type_id,
                title: item?.type_title,
            },
            id: item?.id,
            subscriberGrowthPercent: {
                day: item?.subscriber_growth_percent?.day ?? 0,
                month: item?.subscriber_growth_percent?.month ?? 0,
                week: item?.subscriber_growth_percent?.week ?? 0,
            },
            title: item?.title,
            subscribers: item?.subscribers,
            categories: item?.category_ids?.length > 0 ? item?.category_ids.map((elem, id) => ({
                id: elem,
                title: item?.category_titles[id],
            })) : [],
            er: item?.er_avg,
            link: item?.link,
            price: item?.price,
        } as ISourceRow),
    ) ?? [];
    return result;
};
