/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Checkbox } from 'antd';
import moment from 'moment';
import { userTagsCampaingRtqService } from '@services/campaigns/userTags';
import { Button } from '@shared/index';
import LightInput from '../LightInput';
import SvgIcon from '../SvgIcon';
import styles from './styles.module.scss';
import DatePickerSmall from '../DatePickerSmall';
import QuickSVGLoader from '../QuickSVGLoader';

interface IPropsModalCreateTag {
    campaignId: number,
    isOpen: boolean,
    onClose: () => void,
    onCreateTag: () => void,
}

const ModalCreateTag: React.FC<IPropsModalCreateTag> = ({
    campaignId,
    isOpen,
    onClose,
    onCreateTag,
}): JSX.Element => {
    if (!isOpen) return null;
    const [isNotify, setIsNotify] = useState<boolean>(false);
    const [includes, setIncludes] = useState<string[]>([]);
    const [excludes, setExcludes] = useState<string[]>([]);
    const [name, setName] = useState<string>('');
    const [parseFrom, setParseFrom] = useState<moment.Moment>(() => moment(new Date()));
    const [isLoading, setIsLoading] = useState(false);
    const [createTag, { isError }] = userTagsCampaingRtqService.useCreateUserTagMutation();
    const [updateAutoTags] = userTagsCampaingRtqService.useAutoTagMutation();
    const handleSetIncludeWord = (word: string) => {
        setIncludes((prev) => [word, ...prev]);
    };
    const handleSetExcludeeWord = (word: string) => {
        setExcludes((prev) => [word, ...prev]);
    };

    const filterIncludes = (word: string): void => {
        setIncludes((prev) => prev.filter((include) => include !== word));
    };
    const filterExcludes = (word: string): void => {
        setExcludes((prev) => prev.filter((include) => include !== word));
    };
    const composeQuery = (includesWord: string[], excludesWords: string[]) => {
        const instructionIncludes = includesWord.map((word) => `"${word}"`).join(' & ');
        const formExcludes = excludesWords.map((word) => `!"${word}"`);
        const instructionExcludes = formExcludes.join(' & ');
        return instructionIncludes && instructionExcludes
            ? `(${instructionIncludes}) & (${instructionExcludes})` : `${instructionIncludes || instructionExcludes}`;
    };
    const onRemoveWord = (exclude, word) => {
        if (exclude) filterExcludes(word);
        else filterIncludes(word);
    };
    const getTagParams = () => ({
        title: name,
        campaign_id: campaignId,
        query: composeQuery(includes, excludes),
        parse_from: parseFrom.format('YYYY-MM-DD'),
        manual: false,
    });
    const clearFields = () => {
        setName('');
        setParseFrom(moment(new Date()));
        setIncludes([]);
        setExcludes([]);
    };
    const updateTagsInCampaign = (tagId) => {
        updateAutoTags({
            tag_id: tagId,
            campaign_id: campaignId,
        }).then(() => {
            clearFields();
        });
    };
    const handleClickOnSave = () => {
        const tagParams = getTagParams();
        setIsLoading(true);
        createTag(tagParams).then((res) => {
            const tagId = (res as any)?.data?.id;
            if (tagId) {
                updateTagsInCampaign(tagId);
                onCreateTag();
                onClose();
            }
        })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const getTamplateWords = (words: string[], exclude: boolean = false) => words.map((word) => (
        <div className={`${styles.wordContainer} ${exclude && styles.excludeWord}`} key={word}>
            <div className={styles.word}>
                {word}
            </div>
            <div
                className={`${styles.removeWordIcn} ${exclude && styles.excludeWord}`}
                onClick={() => onRemoveWord(exclude, word)}
            >
                <SvgIcon id="BigCross" size={16} />
            </div>
        </div>
    ));
    const content = (
        <>
            { isLoading
                && (
                    <div className={styles.loaderOverlay}>
                        <QuickSVGLoader />
                    </div>
                )}
            <div className={styles.header}>
                <div
                    style={{ marginRight: '8px' }}
                >
                    Выберите дату, с которой нужно тегировать сообщения
                </div>
                <div onClick={onClose}>
                    <SvgIcon id="Close" size={12} />
                </div>
            </div>
            <div
                style={{ marginBottom: '16px' }}
            >
                <DatePickerSmall
                    value={parseFrom}
                    onChange={setParseFrom}
                />
            </div>
            <div className={styles.tagName}>
                Название тега
            </div>
            <input
                className={styles.tagNameInput}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            {/* Тип Слов */}
            <div>
                <div className={styles.grayLabel}>
                    Тип слов:
                </div>
                <div className={styles.content}>
                    <div className={styles.item}>
                        <div className={styles.listWordsLabel}>
                            Отслеживаемые:
                        </div>
                        <LightInput
                            onSetWord={handleSetIncludeWord}
                        />
                        <div className={styles.listWords}>
                            {getTamplateWords(includes, false)}
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.listWordsLabel}>
                            Исключающие:
                        </div>
                        <LightInput
                            onSetWord={handleSetExcludeeWord}
                        />
                        <div className={styles.listWords}>
                            {getTamplateWords(excludes, true)}
                        </div>
                    </div>
                </div>
            </div>
            {/* Уведомлять о появлении сообщения с данным тегом */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    marginBottom: '16px',
                }}
            >
                <Checkbox
                    checked={isNotify}
                    onChange={(value) => setIsNotify(value.target.checked)}
                />
                <div
                    className={styles.grayLabel}
                    style={{ marginBottom: '0px', marginLeft: '8px' }}
                >
                    Уведомлять о появлении сообщения с данным тегом
                </div>

            </div>
            {/* FOOTER */}
            <div className={styles.footer}>
                <Button
                    text="Сохранить"
                    type="default"
                    size="large"
                    disabled={
                        (!includes?.length && !excludes?.length) || !name
                    }
                    onClick={handleClickOnSave}
                />
                {/* <ButtonPrimary */}
                {/*    style={{ width: 150, height: 36, fontSize: 14 }} */}
                {/*    disabled={ */}
                {/*        (!includes?.length && !excludes?.length) || !name */}
                {/*    } */}
                {/*    text="Сохранить" */}
                {/*    onClick={handleClickOnSave} */}
                {/* /> */}
            </div>
        </>
    );
    const errorDescription = (
        <div className={styles.error}>
            Произошла непредвиденная ошибка
        </div>
    );
    return (

        <div
            className={styles.overlay}
            onClick={() => {
                if (isError) onClose();
            }}
        >
            <div className={styles.rootModalCreateTag}>
                {
                    isError ? errorDescription : content
                }
            </div>
        </div>
    );
};

export default ModalCreateTag;
