import {
    RefObject, useEffect, useMemo, useState,
} from 'react';

export default function useOnViewport(ref: RefObject<any>) {
    const [onViewport, setOnViewport] = useState<boolean>(false);

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setOnViewport(entry.isIntersecting)), []);

    useEffect(() => {
        if (ref && ref.current) observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref.current, observer]);

    return onViewport;
}
