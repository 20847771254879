import React from 'react';
import SvgIcon from '@components/SvgIcon';
import { AccordionDropDown } from '@shared/index';
import classnames from 'classnames';
import { IPropsAccordion } from '../types';
import styles from '../styles.module.scss';

const AccordionSelectBody: React.FC<IPropsAccordion> = ({
    label,
    accordionHeight,
    classesForSize,
    disabled,
    arrowFlag,
    customWidth,
    setArrowFlag,
    child,
}): JSX.Element => (
    <div className={styles.accordionDropdown} style={{ width: customWidth || '100%' }}>
        <div
            role="button"
            onClick={setArrowFlag}
            className={classnames(
                styles.accordionDropdown,
                styles.main,
                disabled ? styles.disabled : '',
                classesForSize,
            )}
            style={{
                width: customWidth || '100%',
                background: arrowFlag ? 'rgba(222, 225, 231, 0.3)' : 'white',
                cursor: 'pointer',
            }}
        >
            <span>{label}</span>
            <div
                role="button"
                tabIndex={0}
                onKeyPress={() => null}
                onClick={setArrowFlag}
                className={styles.arrow}
                style={{
                    cursor: 'pointer',
                    transform: !arrowFlag ? 'rotate(0.50turn)' : 'rotate(1turn)',
                    transition: 'all 0.3s',
                }}
            >
                <SvgIcon id="selectArrow" size={12} height={8} />
            </div>
        </div>
        <AccordionDropDown isActive={arrowFlag} customHeight={accordionHeight}>
            {child}
        </AccordionDropDown>
    </div>
);

export default AccordionSelectBody;
