/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';
import ChartDimensions from '../ChartDimensions';
import ChartTimeFrames from '../ChartTimeFrames';
import styles from './styles.module.scss';
import { IApplyFilter } from '../../sections/MyCampaignContainer/CampaingTypes';
import DatePicker from '../DatePicker';
import { DateRangePickerValue } from '../DateRangePicker/types';
import ChartMetrics from '../ChartMetrics';
import { chartsCampaingRtqService } from '../../services/campaigns/Charts';
import { Dimension, Metric } from '../../services/campaigns/Charts/models';
import { ChartType, TimeFrame } from '../../services/campaigns/new-charts/models';
import { IChart } from '../../containers/CampaignNewCharts/ChartTypes';
import NewRightBar from '../NewRightBar';
import ChartTypes from '../ChartTypes';

type TParamForSettings = 'metrics' | 'chart_type' | 'time_frame' | 'dimensions' | 'filters_include';

interface IFiltersProps {
    onUpdateChart: (field, value) => void,
    chartSetting: IChart,
    campaingId: number,
    isIPadSize: boolean,
}
const ChartSettings: React.FC<IFiltersProps> = ({
    onUpdateChart,
    chartSetting,
    campaingId,
    isIPadSize,
}): JSX.Element => {
    const [
        getSettingsForChart,
        {
            data: listSettingForChart,
            isLoading: isLoadingMetrics,
        },
    ] = chartsCampaingRtqService.useLazyGetSettingsForChartQuery();
    const getCurrentPeriod = useMemo(
        (): DateRangePickerValue => ([moment(new Date(chartSetting?.from_date)), moment(new Date(chartSetting?.to_date))]),
        [chartSetting],
    );
    // const [currentPeriod, setCurrentPeriod] = useState<DateRangePickerValue>(getInitalCurrentPeriod);
    const handleUpdateSettings = useCallback(<T extends unknown>(type: TParamForSettings, param: T) => {
        onUpdateChart(type, param);
    }, [listSettingForChart, chartSetting]);

    const handleOnChangePeriod = useCallback((date) => {
        onUpdateChart('from_date', date[0].format('YYYY-MM-DD'));
        onUpdateChart('to_date', date[1].format('YYYY-MM-DD'));
        date[0].format('YYYY-MM-DD');
        date[1].format('YYYY-MM-DD');
    }, [chartSetting?.from_date, chartSetting?.to_date]);
    // mounted
    useEffect(() => {
        getSettingsForChart();
    }, []);
    if (!chartSetting) return null;
    return (
        <div className={styles.rootChartSettings}>
            <ChartMetrics
                onUpdateMetric={(e) => { handleUpdateSettings('metrics', e); }}
                listSettingForChart={listSettingForChart}
                isLoading={isLoadingMetrics}
                chartSetting={chartSetting}
            />
            <div className={styles.campaignFiltersContainer} style={isIPadSize ? { minWidth: 296, width: 296 } : null}>
                <NewRightBar
                    onChangeFiltersInclude={(e) => { handleUpdateSettings('filters_include', e); }}
                    chartSetting={chartSetting}
                    campaingId={campaingId}
                    isIPadSize={isIPadSize}
                >
                    <div>
                        {chartSetting && (
                            <div className={styles.contentDatePickerContainer}>
                                <DatePicker value={getCurrentPeriod} onChange={handleOnChangePeriod} />
                            </div>
                        )}
                        <div className={styles.chartTypesContainer}>
                            <ChartTypes
                                onUpdateChartType={(e) => handleUpdateSettings('chart_type', e)}
                                listSettingForChart={listSettingForChart}
                                isLoading={isLoadingMetrics}
                                chartSetting={chartSetting}
                            />
                        </div>
                        { chartSetting?.chart_type === 'TIME_CHART' && (
                            <ChartTimeFrames
                                onUpdateTimeFrame={(e) => handleUpdateSettings('time_frame', e)}
                                listSettingForChart={listSettingForChart}
                                isLoading={isLoadingMetrics}
                                chartSetting={chartSetting}
                            />
                        )}
                        <ChartDimensions
                            onUpdateChartDimensions={(e) => handleUpdateSettings('dimensions', e)}
                            listSettingForChart={listSettingForChart}
                            isLoading={isLoadingMetrics}
                            chartSetting={chartSetting}
                        />
                    </div>
                </NewRightBar>
            </div>

        </div>
    );
};

export default ChartSettings;
