import { rtkQApi } from '@services/index';
import { IQPSaveAdvancedFilters, ISourceAdvancedFilters } from '@services/sources/types';

const transformSourceAdvancedFilters = (baseQueryResponse: any): ISourceAdvancedFilters => ({
    id: baseQueryResponse?.id,
    title: baseQueryResponse?.title,
    subscribers: baseQueryResponse?.subscribers,
    authorId: baseQueryResponse?.author_id,
    categoryId: baseQueryResponse?.category_id,
    err: baseQueryResponse?.err,
    erAvg: baseQueryResponse?.er_avg,
    err24: baseQueryResponse?.err24,
    typeId: baseQueryResponse?.type_id,
    viewsAvg: baseQueryResponse?.views_avg,
});

export const sourcesAdvancedFiltersRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getLoadAdvancedFilters: build.query<unknown, void>({
            query: () => ({
                url: 'sources_api/user_source_filters/',
                method: 'GET',
            }),
            transformResponse: transformSourceAdvancedFilters,
        }),
        postUpdateAdvancedFilters: build.query<unknown, ISourceAdvancedFilters>({
            query: (params) => ({
                url: 'sources_api/user_source_filters/',
                method: 'POST',
                body: {
                    id: params.id,
                    title: params.title,
                    subscribers: params.subscribers,
                    author_id: params.authorId,
                    category_id: params.categoryId,
                    type_id: params.typeId,
                    err: params.err,
                    er_avg: params.erAvg,
                    err24: params.err24,
                    views_avg: params.viewsAvg,
                },
            }),
            transformResponse: transformSourceAdvancedFilters,
        }),
        postSaveFilters: build.mutation<unknown, IQPSaveAdvancedFilters>({
            query: (params) => ({
                url: `media_projects/user_source_filters/${params.project_id}`,
                method: 'POST',
                body: {
                    title: params.title,
                    subscribers: params.subscribers,
                    author_id: params.author_id,
                    category_id: params.category_id,
                    type_id: params.type_id,
                    er_avg: params.er_avg,
                    views_avg: params.views_avg,
                },
            }),
            invalidatesTags: ['SavedFilters'],
        }),
        getLoadSavedFilters: build.query<any, { projectId: number }>({
            query: (params) => ({
                url: `media_projects/user_source_filters/${params.projectId}`,
                method: 'GET',
            }),
            providesTags: ['SavedFilters'],
        }),
    }),
});
