import { AxiosRequestConfig } from 'axios';
import HandlerStack from './HandlerAPI';
import { createRequest } from './newRequest';

const request = async (
    options: AxiosRequestConfig,
    headers,
) => {
    const checkToken = HandlerStack.getCheckToken();
    const handleRequest: any = new Promise((resolve, reject) => {
        checkToken
            .then(async () => {
                const instanceRequest = await createRequest(headers);
                const currentRequest = instanceRequest.request(options);
                currentRequest
                    .then((res) => resolve(res.data))
                    .catch((err) => {
                        // if (err.response?.status === 401) {
                        //     const refreshToken = HandlerStack.refreshCheckToken();
                        //     refreshToken
                        //         .then(async () => {
                        //             const newInstanceRequest = await createRequest(headers);
                        //             const newCurrentRequest = newInstanceRequest.request(options);
                        //             newCurrentRequest
                        //                 .then((result) => resolve(result.data))
                        //                 .catch((error) => {
                        //                     reject(error);
                        //                 });
                        //         })
                        //         .catch((error) => reject(error));
                        // } else {
                        //     reject(err);
                        // }
                        reject(err);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
    return handleRequest;
};

export default request;
