import React from 'react';
import { useParams } from 'react-router';
import ProjectDocuments from '@components/Project/ProjectDocuments';
import ProjectTemplates from '@components/Project/ProjectTemplates';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';

interface IPropsDocumentsProject {
    isLoading: boolean
}

// eslint-disable-next-line arrow-body-style
const DocumentsProject: React.FC<IPropsDocumentsProject> = (): JSX.Element => {
    const { Id: projectId } = useParams();
    return (
        <DefaultWrapperProject>
            <>
                <ProjectDocuments projectId={Number(projectId)} />
                <ProjectTemplates projectId={Number(projectId)} />
            </>
        </DefaultWrapperProject>
    );
};

export default DocumentsProject;
