import React from 'react';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';
import { IQPProjectInfo } from '@services/projects/types';
import DetailInfo from './Info';

interface IProps {
    isLoading: boolean;
    projectId: number;
    name: string;
    description: string;
    onUpdateInfo: (value: IQPProjectInfo) => void;
}

// eslint-disable-next-line arrow-body-style
const DetailProject: React.FC<IProps> = ({
    isLoading, projectId, name, description, onUpdateInfo,
}): JSX.Element => (
    <DefaultWrapperProject style={{ marginBottom: 16 }}>
        <DetailInfo
            isLoading={isLoading}
            projectId={projectId}
            name={name}
            description={description}
            onUpdateInfo={onUpdateInfo}
        />
    </DefaultWrapperProject>
);

export default DetailProject;
