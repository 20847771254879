import { rtkQApi } from '../../index';
import {
    ICreateUserFilterQuery, ISpecificFilterQuery, IUpdateUserFilterQuery, IUserFilter,
} from './types';
// import {} from './types';

export const userFiltersCampaingRtqService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        createUserFilter: build.mutation<any, ICreateUserFilterQuery>({
            query: (body) => ({
                url: 'campaign/user_filters',
                method: 'POST',
                body,
            }),
        }),
        getAllUserFilters: build.query<IUserFilter[], number>({
            query: (campaign_id) => ({
                url: `campaign/${campaign_id}/user_filters`,
                method: 'GET',
            }),
        }),
        getSpecificFilter: build.query<any, ISpecificFilterQuery>({
            query: ({ campaign_id, id }) => ({
                url: `campaign/${campaign_id}/user_filters/${id}`,
                method: 'GET',
            }),
        }),
        postApplyFilter: build.query<any, ISpecificFilterQuery>({
            query: ({ campaign_id, id }) => ({
                url: `campaign/${campaign_id}/user_filters/${id}`,
                method: 'POST',
            }),
        }),
        deleteApplyFilter: build.query<any, ISpecificFilterQuery>({
            query: ({ campaign_id, id }) => ({
                url: `campaign/${campaign_id}/user_filters/${id}`,
                method: 'DELETE',
            }),
        }),
        updateUserFilter: build.mutation<any, IUpdateUserFilterQuery>({
            query: ({ body, id }) => ({
                url: `campaign/user_filters/${id}`,
                method: 'PUT',
                body,
            }),
        }),
    }),
});
