import styled from 'styled-components';

const LoaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    transform: scale(1.2);
`;

const InsideLoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

function Loader({ className } : { className?: string }) {
    return (
        <LoaderContainer className={className}>
            <svg
                width="46"
                height="46"
                viewBox="0 0 38 38"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#1D202F"
            >
                <g
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        transform="translate(1 1)"
                        strokeWidth="2"
                    >
                        <circle
                            strokeOpacity=".5"
                            cx="18"
                            cy="18"
                            r="18"
                        />
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"
                            />
                        </path>
                    </g>
                </g>
            </svg>

            <InsideLoaderContainer>
                <svg
                    width="40"
                    height="20"
                    viewBox="0 0 120 30"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="15"
                        cy="22"
                        r="15"
                        fill="#5A81FA"
                    >
                        <animate
                            attributeName="r"
                            from="15"
                            to="15"
                            begin="0s"
                            dur="0.8s"
                            values="15;9;15"
                            calcMode="linear"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="fill-opacity"
                            from="1"
                            to="1"
                            begin="0s"
                            dur="0.8s"
                            values="1;.5;1"
                            calcMode="linear"
                            repeatCount="indefinite"
                        />
                    </circle>
                    <circle
                        cx="60"
                        cy="22"
                        r="9"
                        fillOpacity="0.3"
                        fill="#5A81FA"
                    >
                        <animate
                            attributeName="r"
                            from="9"
                            to="9"
                            begin="0s"
                            dur="0.8s"
                            values="9;15;9"
                            calcMode="linear"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="fill-opacity"
                            from="0.5"
                            to="0.5"
                            begin="0s"
                            dur="0.8s"
                            values=".5;1;.5"
                            calcMode="linear"
                            repeatCount="indefinite"
                        />
                    </circle>
                    <circle
                        cx="105"
                        cy="22"
                        r="15"
                        fill="#5A81FA"
                    >
                        <animate
                            attributeName="r"
                            from="15"
                            to="15"
                            begin="0s"
                            dur="0.8s"
                            values="15;9;15"
                            calcMode="linear"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="fill-opacity"
                            from="1"
                            to="1"
                            begin="0s"
                            dur="0.8s"
                            values="1;.5;1"
                            calcMode="linear"
                            repeatCount="indefinite"
                        />
                    </circle>
                </svg>
            </InsideLoaderContainer>
        </LoaderContainer>
    );
}

Loader.defaultProps = {
    className: '',
};

export default Loader;
