import { IRegion } from 'store/types';

const convertToObject = (item: unknown): IRegion => {
    const result: IRegion = {} as IRegion;

    Object.keys(item).forEach((key: string) => {
        if (key === 'id') {
            result[key] = item[key];
        } else if (key === 'location_name' || key === 'name' || key === 'title') {
            result.name = item[key];
        }
    });

    result.checked = false;
    result.visible = true;

    return result;
};

export const convertApiToLocation = (data: unknown): IRegion[] => {
    const result: IRegion[] = [];
    if (!data) {
        return result;
    }

    if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
            if (item) {
                result.push(convertToObject(item));
            }
        });
    } else {
        result.push(convertToObject(data));
    }

    return result;
};
