/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import SvgIcon from '../SvgIcon';
import styles from './styles.module.scss';

interface IPropsLightInput {
    onSetWord: (value: string) => void
}

const LightInput: React.FC<IPropsLightInput> = ({
    onSetWord,
}): JSX.Element => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const handleAddInput = () => {
        onSetWord(value);
        setValue('');
    };
    const clearInput = () => {
        setValue('');
    };
    const enterKeyDownHandler = (e) => {
        if (e?.code === 'Enter') { handleAddInput(); }
        if (e?.code === 'Escape') { clearInput(); }
    };

    return (
        <div className={styles.addWordContainer}>
            <div
                className={`${styles.removeWord} ${isFocus && styles.show}`}
                onClick={clearInput}
            >
                <SvgIcon
                    id="Close"
                    size={14}
                />
            </div>
            <input
                type="text"
                className={styles.inputAddWord}
                placeholder="Введите текст"
                value={value}
                onKeyDown={enterKeyDownHandler}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={(e) => setValue(e.target.value)}
            />
            <div
                className={`${styles.addWord} ${isFocus && styles.show}`}
                onClick={handleAddInput}
            >
                <SvgIcon
                    id="addInput"
                    size={16}
                />
            </div>
        </div>
    );
};

export default LightInput;
