import styles from './styles.module.scss';

interface IProps {
    children: React.ReactNode;
}

const ModalMessageContainer: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { children } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {children}
            </div>
        </div>
    );
};
export default ModalMessageContainer;
