export enum PhraseWordLogicOperatorType {
    and = 0,
    or = 1,
    andNot = 2,
    orNot = 3,
    order = 4,
}

export enum ComparisonParametersType {
    moreOrEqual = 0,
    lessOrEqual = 1,
    unequal = 2,
    equal = 3,
}

export enum LocationType {
    start = 0,
    // middle = 1,
    end = 1,
}

export interface ISearchPhraseWord {
    parameters: IPhraseParameters | null;
    indexNextPhrase?: number | null;
    logicOperator?: PhraseWordLogicOperatorType | null;
    index: number;
    previewChecked: boolean;
    parentGroupIndex: number;
}

export interface ISearchGroup {
    id: number;
    index: number;
    parentIndex?: number | null;
    indexNextGroup?: number | null;
    phrases: ISearchPhraseWord[];
    parameters: IGroupParameters;
    children?: ISearchGroup[] | null;
    operator?: PhraseWordLogicOperatorType | null;
    previewChecked: boolean;
}

export interface IPhraseOccurrencesParameters {
    parameter: ComparisonParametersType;
    quantity: number;
}

export interface IPhraseParameters {
    // значение
    value: string;
    // отслеживать Результаты  с этим словом
    isTrack: boolean;
    // учитывать (падежи, склонения и тд)
    considerDeclensions: boolean;
    // Количество вхождений, null - не учитывать
    numberOccurrences: IPhraseOccurrencesParameters | null;
    // Место в предложении, null - не учитывать
    placeInSentence: LocationType | null;
}

export interface IGroupParameters {
    // учитывать последовательность
    considerSequence: boolean;
    // Расстояние (кол-во между сущностями), null - не учитывать
    distanceEntities: IPhraseOccurrencesParameters | null;
    // Кол-во совпадений
    numberMatches: number | null;
}

export interface IMidtermGroups {
    level: number;
    phrasesString: string;
}
