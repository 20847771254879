/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { TitleChart } from '../../../style';
import TagCloud from '../../../../../components/TagCloud';

const { Panel } = Collapse;

export const Height = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #DFFCF7;
    border-radius: 6px;
`;
export const Low = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #FEF8E8;
    border-radius: 6px;
`;
const demoCloudTags = [
    {
        name: 'Tag 1',
        count: 1,
    },
    {
        name: 'Tag 2',
        count: 2345,
    },
    {
        name: 'Tag 3',
        count: 2234,
    },
    {
        name: 'Tag 4',
        count: 2134,
    },
    {
        name: 'Tag 5',
        count: 20,
    },
    {
        name: 'Tag 6',
        count: 40,
    },
    {
        name: 'Tag 7',
        count: 3425,
    },
    {
        name: 'Tag 15',
        count: 20,
    },
    {
        name: 'Tag 55',
        count: 2256,
    },
    {
        name: 'Tag 54',
        count: 2314,
    },
    {
        name: 'Tag 52',
        count: 2144,
    },
    {
        name: 'Tag 985',
        count: 212,
    },
    {
        name: 'Tag 764',
        count: 225,
    },
    {
        name: 'Tag 11',
        count: 235,
    },

    {
        name: 'Tag 125',
        count: 2256,
    },
];
const TagCloudDemo = () => (
    <Collapse>
        <Panel
            key="ChartPublicationDemo"
            header={<TitleChart style={{ marginBottom: '12px' }}>Облако Тегов</TitleChart>}
        >
            <div style={{ marginTop: '16px' }}>
                <TagCloud tagList={demoCloudTags} />
            </div>
        </Panel>
    </Collapse>
);
export default TagCloudDemo;
