import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInfoBasketTableFiltersState } from './types';
import { ColumnFilterInfoBasketType } from '../../../components/DropDownFilterInfoBasketTable/types';
import {
    IDropdownFilterInfoTableMenu,
} from '../../../components/DropDownFilterInfoBasketTable/DropdownFilterTableInfoMenu/types';

const initialCategory: IDropdownFilterInfoTableMenu = {
    type: ColumnFilterInfoBasketType.category,
    isFiltering: true,
    isSorting: false,
    isAscending: false,
    filterData: [
        {
            id: 1, name: 'Наука', value: 'Наука', isSelected: false,
        },
    ],
};

const initialPlatform: IDropdownFilterInfoTableMenu = {
    type: ColumnFilterInfoBasketType.platform,
    isFiltering: true,
    isSorting: false,
    isAscending: false,
    filterData: [
        {
            id: 1, name: 'Теlegram', value: 'Теlegram', isSelected: false,
        },
        {
            id: 2, name: 'Ok.ru', value: 'Ok.ru', isSelected: false,
        },
        {
            id: 3, name: 'Vkontakte', value: 'Vkontakte', isSelected: false,
        },
        {
            id: 4, name: 'Новости', value: 'Новости', isSelected: false,
        },
    ],
};

const initialMediaType: IDropdownFilterInfoTableMenu = {
    type: ColumnFilterInfoBasketType.mediaType,
    isFiltering: true,
    isSorting: false,
    isAscending: false,
    filterData: [
        {
            id: 1, name: 'Блогер1', value: 'Блогер1', isSelected: false,
        },
        {
            id: 2, name: 'СМИ', value: 'СМИ', isSelected: false,
        },
    ],
};

const initialState: IInfoBasketTableFiltersState = {
    price: {
        type: ColumnFilterInfoBasketType.price,
        isAscending: false,
        isSorting: true,
        isFiltering: false,
        filterData: [],
    },
    audience: {
        type: ColumnFilterInfoBasketType.audience,
        isAscending: false,
        isSorting: true,
        isFiltering: false,
        filterData: [],
    },
    title: {
        type: ColumnFilterInfoBasketType.title,
        isAscending: false,
        isSorting: true,
        isFiltering: false,
        filterData: [],
    },
    category: initialCategory,
    platform: initialPlatform,
    mediaType: initialMediaType,
    bufferCategory: initialCategory,
    bufferPlatform: initialPlatform,
    bufferMediaType: initialMediaType,
    currentSortColumn: ColumnFilterInfoBasketType.default,
    filtersApplied: false,
    titleSearchWord: '',
};

const checkFiltersApplied = (state: IInfoBasketTableFiltersState): boolean => {
    if (state.category.filterData.some((item) => item.isSelected)) {
        return true;
    }
    if (state.mediaType.filterData.some((item) => item.isSelected)) {
        return true;
    }
    return state.platform.filterData.some((item) => item.isSelected);
};

const { actions: infoBasketTableFiltersActions, reducer } = createSlice({
    name: 'infoBasketTableFilters',
    initialState,
    reducers: {
        clearAll: () => ({ ...initialState }),
        setCategory: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<IDropdownFilterInfoTableMenu>): void => {
            state.category = payload;
            state.bufferCategory = payload;
        },
        setPlatform: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<IDropdownFilterInfoTableMenu>): void => {
            state.platform = payload;
            state.bufferPlatform = payload;
        },
        setMediaType: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<IDropdownFilterInfoTableMenu>): void => {
            state.mediaType = payload;
            state.bufferMediaType = payload;
        },
        sortPrice: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<boolean>): void => {
            state.price.isAscending = payload;
            state.currentSortColumn = ColumnFilterInfoBasketType.price;
        },
        sortAudience: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<boolean>): void => {
            state.audience.isAscending = payload;
            state.currentSortColumn = ColumnFilterInfoBasketType.audience;
        },
        sortTitle: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<boolean>): void => {
            state.title.isAscending = payload;
            state.currentSortColumn = ColumnFilterInfoBasketType.title;
        },
        searchByTitle: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<string>): void => {
            state.titleSearchWord = payload;
        },
        clearCategory: (state: IInfoBasketTableFiltersState): void => {
            for (let i = 0; i < state.category.filterData.length; i += 1) {
                state.category.filterData[i].isSelected = false;
            }
            for (let i = 0; i < state.bufferCategory.filterData.length; i += 1) {
                state.bufferCategory.filterData[i].isSelected = false;
            }
        },
        clearPlatform: (state: IInfoBasketTableFiltersState): void => {
            for (let i = 0; i < state.platform.filterData.length; i += 1) {
                state.platform.filterData[i].isSelected = false;
            }
            for (let i = 0; i < state.bufferPlatform.filterData.length; i += 1) {
                state.bufferPlatform.filterData[i].isSelected = false;
            }
        },
        clearMediaType: (state: IInfoBasketTableFiltersState): void => {
            for (let i = 0; i < state.mediaType.filterData.length; i += 1) {
                state.mediaType.filterData[i].isSelected = false;
            }
            for (let i = 0; i < state.bufferMediaType.filterData.length; i += 1) {
                state.bufferMediaType.filterData[i].isSelected = false;
            }
        },
        updateBufferCategory: (state: IInfoBasketTableFiltersState): void => {
            state.category.filterData.forEach((item) => {
                const cur = state.bufferCategory.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
        },
        selectBufferCategory: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<string>): void => {
            const category = state.bufferCategory.filterData.find((item) => item.id === payload);
            if (category) {
                category.isSelected = !category.isSelected;
            }
        },
        setCategoryFromBuffer: (state: IInfoBasketTableFiltersState): void => {
            state.bufferCategory.filterData.forEach((item) => {
                const cur = state.category.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
            state.filtersApplied = checkFiltersApplied(state);
        },
        updateBufferPlatform: (state: IInfoBasketTableFiltersState): void => {
            state.platform.filterData.forEach((item) => {
                const cur = state.bufferPlatform.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
        },
        selectBufferPlatform: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<string>): void => {
            const platform = state.bufferPlatform.filterData.find((item) => item.id === payload);
            if (platform) {
                platform.isSelected = !platform.isSelected;
            }
        },
        setPlatformFromBuffer: (state: IInfoBasketTableFiltersState): void => {
            state.bufferPlatform.filterData.forEach((item) => {
                const cur = state.platform.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
            state.filtersApplied = checkFiltersApplied(state);
        },
        updateBufferMediaType: (state: IInfoBasketTableFiltersState): void => {
            state.mediaType.filterData.forEach((item) => {
                const cur = state.bufferMediaType.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
            });
        },
        selectBufferMediaType: (state: IInfoBasketTableFiltersState, { payload }: PayloadAction<string>): void => {
            const mediaType = state.bufferMediaType.filterData.find((item) => item.id === payload);
            if (mediaType) {
                mediaType.isSelected = !mediaType.isSelected;
            }
        },
        setMediaTypeFromBuffer: (state: IInfoBasketTableFiltersState): void => {
            state.bufferMediaType.filterData.forEach((item) => {
                const cur = state.mediaType.filterData.find((period) => period.id === item.id);
                cur.isSelected = item.isSelected;
                if (!state.filtersApplied) {
                    state.filtersApplied = true;
                }
            });
            state.filtersApplied = checkFiltersApplied(state);
        },
    },
});

export { infoBasketTableFiltersActions };
export default reducer;
