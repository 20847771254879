import {
    ComparisonParametersType,
    IMidtermGroups,
    ISearchGroup,
    ISearchPhraseWord,
    LocationType,
    PhraseWordLogicOperatorType,
} from '../types';

interface IRepetitionsConditionQuantity {
    quantity: number;
    index: number;
}
interface IRepetitionsCondition {
    quantities: IRepetitionsConditionQuantity[];
    phrases: string[];
}

const getInitialGroup = (): ISearchGroup => ({
    id: -1,
    index: -1,
    parentIndex: null,
    indexNextGroup: null,
    operator: null,
    phrases: [],
    children: [],
    previewChecked: false,
    parameters: {
        considerSequence: false,
        distanceEntities: null, // { parameter: ComparisonParametersType.equal, quantity: 0 },
        numberMatches: null,
    },
});

const getNameForLogicOperator = (value: PhraseWordLogicOperatorType): string => {
    switch (value) {
        case PhraseWordLogicOperatorType.andNot:
            return 'И НЕ';
        case PhraseWordLogicOperatorType.orNot:
            return 'ИЛИ НЕ';
        case PhraseWordLogicOperatorType.and:
            return 'И';
        case PhraseWordLogicOperatorType.or:
            return 'ИЛИ';
        case PhraseWordLogicOperatorType.order:
            return '<<';
        default:
            return '';
    }
};

const typeToManticoreOperator = (value: PhraseWordLogicOperatorType | null): string => {
    if (value in PhraseWordLogicOperatorType) {
        switch (value) {
            case PhraseWordLogicOperatorType.or:
                return '|';
            case PhraseWordLogicOperatorType.and:
                return '&';
            case PhraseWordLogicOperatorType.orNot:
                return '| !';
            case PhraseWordLogicOperatorType.andNot:
                return '& !';
            case PhraseWordLogicOperatorType.order:
                return '<<';
            default:
                return '';
        }
    }
    return '';
};

const manticoreOperatorToType = (value: string): PhraseWordLogicOperatorType | null => {
    if (value) {
        switch (value) {
            case '|':
                return PhraseWordLogicOperatorType.or;
            case '&':
                return PhraseWordLogicOperatorType.and;
            case '| !':
                return PhraseWordLogicOperatorType.orNot;
            case '& !':
                return PhraseWordLogicOperatorType.andNot;
            case '<<':
                return PhraseWordLogicOperatorType.order;
            default:
                return null;
        }
    }
    return null;
};

const manticoreOperators = [
    '|', '&', '| !', '& !', '<<',
];
const manticoreOperatorInType = (value: string): boolean => manticoreOperators.includes(value);

const checkedPreview = (data: ISearchGroup): ISearchGroup => {
    if (!data) {
        return data;
    }

    const result: ISearchGroup = { ...data, phrases: [...(data?.phrases ?? [])] };

    if (data?.children?.length > 0) {
        for (let i = 0; i < data.children.length; i += 1) {
            result.children[i] = { ...checkedPreview(data.children[i]) };
        }
        result.previewChecked = result.children.some(({ previewChecked }) => previewChecked);
    } else {
        result.previewChecked = result.phrases?.some(({ previewChecked }) => previewChecked);
    }

    return result;
};

const getGroupWithoutIndex = (data: ISearchGroup, index: number): ISearchGroup | null => {
    if (data.index === index) {
        return null;
    }
    if (!(data.children?.length > 0)) {
        return data;
    }
    const result = {
        id: data.id,
        index: data.index,
        parentIndex: data.parentIndex,
        phrases: data.phrases,
        indexNextGroup: data.indexNextGroup,
        operator: data.operator,
        children: null,
    } as ISearchGroup;
    const children: ISearchGroup[] = [];
    const buffer = data.children.filter((item) => item.index !== index);
    if (buffer?.length > 1) {
        for (let i = 0; i < buffer?.length ?? 0; i += 1) {
            if (buffer[i].children?.length > 0) {
                const nested = getGroupWithoutIndex(buffer[i], index);
                if (nested) {
                    children.push(nested);
                }
            } else {
                children.push(buffer[i]);
            }
        }
        result.children = children.length > 0 ? children : null;
    } else if (buffer?.length > 0) {
        result.phrases = [...buffer[0].phrases];
    }

    return result;
};

const getGroup = (data: ISearchGroup, index: number): ISearchGroup | null => {
    if (data.index === index) {
        return data;
    }
    if (!(data.children?.length > 0)) {
        return null;
    }
    for (let i = 0; i < data.children?.length ?? 0; i += 1) {
        if (data.children[i].index === index) {
            return data.children[i];
        }
    }
    for (let i = 0; i < data.children.length; i += 1) {
        const result = getGroup(data.children[i], index);
        if (result) {
            return result;
        }
    }
    return null;
};

/**
 * Определить есть ли условие о количестве вхождений в строке
 * значение - "t * t * t", показывает количество вхождений t в поисковом запросе
 * @param value - фраза/слово
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkNumberOfRepetitions = (value: string): boolean => {
    if (value.includes('*')) {
        const result = value.split(' ');
        if (result?.length > 1) {
            const count = (value.split(result[0])?.length ?? 0) - 1;

            return count > 1;
        }
    }
    return false;
};

/**
 * привести запись "t * t * t * t * t", к виду "t*5"
 * @param value - фраза/слово
 */
const getPhraseFromRepetitionsCondition = (value: string): string => {
    const result = value.split(' ');
    if (result?.length > 1) {
        const count = (value.split(result[0])?.length ?? 0) - 1;
        return count > 1 ? `${result[0]}*${count}` : value;
    }
    return value;
};

const replaceRepetitionsCondition = (data: string): string => {
    let subStr = '';
    let count = 0;
    let result = '';
    if (!data.includes('*')) {
        return data;
    }
    data.split('').reduce((acc, curr) => {
        if (curr === '(') {
            subStr = '';
            count = 0;
        } else if (curr === ')') {
            if (subStr) {
                if (count > 0) {
                    result = `${acc.slice(0, acc.length - 1 - subStr.length)}${getPhraseFromRepetitionsCondition(subStr)}`;
                } else {
                    result = `${acc.slice(0, acc.length - subStr.length)}${subStr})`;
                }
                count = 0;
                subStr = '';
                return result;
            }
            result += ')';
            return result;
        } else if (curr === '*' && subStr) {
            count += 1;
            subStr += curr;
        } else {
            subStr += curr;
        }
        return acc + curr;
    }, '');
    return result;
};

const getGroups = (data: string, level: number): IMidtermGroups[] => {
    let curLevel = level;
    let phrase = '';
    let result: IMidtermGroups[] = [];
    if (data.includes('(') || data.includes(')')) {
        for (let i = 0; i < data.length; i += 1) {
            if (data[i] !== '(' && data[i] !== ')') {
                phrase += data[i];
            } else if (data[i] === '(') {
                if (phrase) {
                    result.push({ level: curLevel, phrasesString: phrase.trim() });
                    phrase = '';
                }
                const buffer = getGroups(data.slice(i + 1), curLevel + 1);
                result = [...result, ...buffer];
                return result;
            } else if (data[i] === ')') {
                if (phrase) {
                    result.push({ level: curLevel, phrasesString: phrase.trim() });
                    phrase = '';
                }
                curLevel -= 1;
            }
        }
        if (phrase) {
            result.push({ level: curLevel, phrasesString: phrase.trim() });
        }
    } else {
        return [{ level: curLevel, phrasesString: data }];
    }
    return result;
};

const getStartOrEnd = (value: string) : LocationType | null => {
    if (value.includes('^')) {
        return LocationType.start;
    }
    if (value.includes('$')) {
        return LocationType.end;
    }
    return null;
};

const getPhrasesFromRepetitionsConditionString = (value: string): IRepetitionsCondition => {
    const result: IRepetitionsCondition = { phrases: [], quantities: [] };
    const parts = value.split(' ');
    for (let i = 0; i < parts.length; i += 1) {
        if (parts[i].includes('*')) {
            const repetitionsCondition = parts[i].split('*');
            if (repetitionsCondition?.length > 1) {
                const quantity = Number(repetitionsCondition[1]);
                result.quantities.push({ index: i, quantity: Number.isInteger(quantity) ? quantity : 0 });
                result.phrases.push(repetitionsCondition[0]);
            }
        } else {
            result.phrases.push(parts[i]);
        }
    }
    return result;
};

const preparePhrases = (data: string): ISearchPhraseWord[] => {
    let parts: string[] = [];
    let quantityOccurrences: { quantity: number, index: number }[] = [];
    let start = false;
    const withoutSpaces = Array.from(data).reduce((acc, curr) => {
        if (!start && curr === '"') {
            start = true;
        } else if (start && curr === '"') {
            start = false;
        }
        return start && curr === ' ' ? `${acc}_` : `${acc}${curr}`;
    }, '');

    if (withoutSpaces.includes('*')) {
        const wsResult = getPhrasesFromRepetitionsConditionString(withoutSpaces);
        quantityOccurrences = [...wsResult.quantities];
        parts = wsResult.phrases;
    } else {
        parts = withoutSpaces
            .replaceAll('& !', '&! ')
            .replaceAll('| !', '|! ')
            .split(' ')
            .map((item) => (item === '&!' || item === '|!' ? `${item[0]} ${item[1]}` : item));
    }

    const result: ISearchPhraseWord[] = [];
    let prev: ISearchPhraseWord = null;
    let idx = 0;
    for (let j = 0; j < parts?.length; j += 1) {
        const placeInSentence = getStartOrEnd(parts[j]);
        const isTrack = !parts[j].includes('!');
        const considerDeclensions = parts[j].includes('=');
        const value = parts[j]
            .replace('^', '')
            .replace('$', '')
            .replace('=', '')
            .replaceAll('!', '')
            .replaceAll('"', '')
            .replaceAll('_', ' ');

        const qValueCondition = quantityOccurrences?.find(({ index }) => index === j);
        const current = {
            index: idx,
            indexNextPhrase: ((j + 1) < parts.length ? idx + 1 : null),
            parameters: {
                value,
                isTrack,
                considerDeclensions,
                numberOccurrences: qValueCondition
                    ? { parameter: ComparisonParametersType.equal, quantity: qValueCondition.quantity }
                    : null,
                placeInSentence,
            },
            logicOperator: null,
        } as ISearchPhraseWord;
        if (!manticoreOperatorInType(parts[j])) {
            result.push(current);
            idx += 1;
        }
        if (prev && manticoreOperatorInType(parts[j])) {
            prev.logicOperator = manticoreOperatorToType(parts[j]);
        }
        prev = current;
    }

    return result;
};

const getMaxIndexFromNestedGroups = (data: ISearchGroup): number => {
    let max = 0;
    if (!data?.index) {
        return 0;
    }
    if (data?.children?.length > 0) {
        for (let i = 0; i < data?.children.length; i += 1) {
            const maxFromNested = getMaxIndexFromNestedGroups(data?.children[i]);
            if (maxFromNested > max) {
                max = maxFromNested;
            }
        }
    } else if (data?.index > max) {
        max = data?.index;
    }
    return max;
};

const getNestedGroups = (data: IMidtermGroups[], operators: IMidtermGroups[], index: number): ISearchGroup | null => {
    const result: ISearchGroup = getInitialGroup();
    let currentIdx = index;
    result.id = currentIdx;
    result.index = currentIdx;

    let nestedGroup: ISearchGroup = null;

    if (data.some(({ level }) => level === (index + 1))) {
        const parentGroupOperators = data.filter(({ level, phrasesString }) => (
            level === currentIdx && manticoreOperatorInType(phrasesString)
        ));
        nestedGroup = getNestedGroups(data.filter(({ level }) => level > index), parentGroupOperators, index + 1);
        if (nestedGroup) {
            nestedGroup.parentIndex = index;
            if (operators?.length > 0) {
                nestedGroup.operator = manticoreOperatorToType(operators[0].phrasesString);
            }
            result.children.push(nestedGroup);
        }
    }
    const groups = data.filter(({ level }) => level === index);
    const maxIdxFromNested = getMaxIndexFromNestedGroups(nestedGroup);
    currentIdx = maxIdxFromNested > currentIdx ? maxIdxFromNested : currentIdx;

    for (let i = 0; i < groups?.length; i += 1) {
        if (!manticoreOperatorInType(groups[i]?.phrasesString)) {
            currentIdx += 1;
            if (nestedGroup && !Number.isInteger(nestedGroup.indexNextGroup)) {
                nestedGroup.indexNextGroup = currentIdx;
            }
            const currGroup = getInitialGroup();
            currGroup.id = currentIdx;
            currGroup.index = currentIdx;
            currGroup.parentIndex = index < 0 ? null : index;

            // параметры групп
            const deIndex = groups[i].phrasesString.indexOf('~');
            const nmIndex = groups[i].phrasesString.indexOf('/');
            let phrases = groups[i].phrasesString;
            if (deIndex > 0) {
                let distanceEntities = 0;
                const distanceEntitiesStr = phrases.slice(deIndex + 1);
                if (distanceEntitiesStr.length > 1 && Number(distanceEntitiesStr[1])) {
                    distanceEntities = Number(distanceEntitiesStr);
                } else if (Number(distanceEntitiesStr[0])) {
                    distanceEntities = Number(distanceEntitiesStr[0]);
                }
                phrases = phrases.slice(1, deIndex - 1).trim();
                if (distanceEntities > 0) {
                    currGroup.parameters.distanceEntities = { parameter: ComparisonParametersType.equal, quantity: distanceEntities };
                }
            } else if (nmIndex > 0) {
                let numberMatches = 0;
                const distanceEntitiesStr = phrases.slice(nmIndex + 1);
                if (distanceEntitiesStr.length > 1 && Number(distanceEntitiesStr[1])) {
                    numberMatches = Number(distanceEntitiesStr);
                } else if (Number(distanceEntitiesStr[0])) {
                    numberMatches = Number(distanceEntitiesStr[0]);
                }
                phrases = phrases.slice(1, nmIndex - 1).trim();
                if (numberMatches > 0) {
                    currGroup.parameters.numberMatches = numberMatches;
                }
            }
            currGroup.phrases = [...preparePhrases(phrases)];
            if (nestedGroup) {
                if (result.children[result.children.length - 1]) {
                    result.children[result.children.length - 1].indexNextGroup = currentIdx;
                }
            } else if (i + 1 < groups.length) {
                currGroup.indexNextGroup = currentIdx + 1;
            }
            const operatorIdx = result.children.length;
            if (operators[operatorIdx]?.phrasesString) {
                currGroup.operator = manticoreOperatorToType(operators[operatorIdx].phrasesString);
            }
            result.children.push({ ...currGroup });
        }
    }

    if (result.children?.length > 0 || result.phrases?.length > 0) {
        return result;
    }
    return null;
};

const parseSearchRequest = (data: IMidtermGroups[]): ISearchGroup | null => {
    if (!(data?.length > 0)) {
        return null;
    }
    const parentGroupIdx = 0;
    const firstNestedGroupIdx = 1;
    let currentIdx = 0;
    const result: ISearchGroup = getInitialGroup();
    result.id = currentIdx;
    result.index = currentIdx;
    const maxLevel = data.reduce((acc, curr) => (acc < curr.level ? curr.level : acc), parentGroupIdx);
    let nested: ISearchGroup = null;
    let groups: IMidtermGroups[] = [];

    const parentGroupOperators = data.filter(({ level, phrasesString }) => (
        level === parentGroupIdx && manticoreOperatorInType(phrasesString)
    ));
    const firstNestedGroupOperators = data.filter(({ level, phrasesString }) => (
        level === firstNestedGroupIdx && manticoreOperatorInType(phrasesString)
    ));

    if (maxLevel > firstNestedGroupIdx) {
        nested = getNestedGroups(data.filter(({ level }) => level > firstNestedGroupIdx), firstNestedGroupOperators, 2);
        if (nested) {
            if (parentGroupOperators?.length > 0) {
                nested.operator = manticoreOperatorToType(parentGroupOperators[0].phrasesString);
            }
            const maxIdxFromNested = getMaxIndexFromNestedGroups(nested);
            currentIdx = maxIdxFromNested > currentIdx ? maxIdxFromNested : currentIdx;
            result.children.push(nested);
        }
    }
    if (maxLevel > parentGroupIdx) {
        groups = data.filter(({ level, phrasesString }) => level === firstNestedGroupIdx && !manticoreOperatorInType(phrasesString));
    }
    if (data.length === 1) {
        // параметры групп
        const deIndex = data[0].phrasesString.indexOf('~');
        const nmIndex = data[0].phrasesString.indexOf('/');
        let phrases = data[0].phrasesString;
        if (deIndex > 0) {
            let distanceEntities = 0;
            const distanceEntitiesStr = phrases.slice(deIndex + 1);
            if (distanceEntitiesStr.length > 1 && Number(distanceEntitiesStr[1])) {
                distanceEntities = Number(distanceEntitiesStr);
            } else if (Number(distanceEntitiesStr[0])) {
                distanceEntities = Number(distanceEntitiesStr[0]);
            }
            phrases = phrases.slice(1, deIndex - 1).trim();
            if (distanceEntities > 0) {
                result.parameters.distanceEntities = { parameter: ComparisonParametersType.equal, quantity: distanceEntities };
            }
        } else if (nmIndex > 0) {
            let numberMatches = 0;
            const distanceEntitiesStr = phrases.slice(nmIndex + 1);
            if (distanceEntitiesStr.length > 1 && Number(distanceEntitiesStr[1])) {
                numberMatches = Number(distanceEntitiesStr);
            } else if (Number(distanceEntitiesStr[0])) {
                numberMatches = Number(distanceEntitiesStr[0]);
            }
            phrases = phrases.slice(1, nmIndex - 1).trim();
            if (numberMatches > 0) {
                result.parameters.numberMatches = numberMatches;
            }
        }
        result.phrases = [...preparePhrases(phrases)];
        groups = [];
    }

    for (let i = 0; i < groups?.length; i += 1) {
        if (!manticoreOperatorInType(groups[i]?.phrasesString)) {
            currentIdx += 1;
            if (nested && !Number.isInteger(nested.indexNextGroup)) {
                nested.indexNextGroup = currentIdx;
            }
            const currGroup = getInitialGroup();
            currGroup.id = currentIdx;
            currGroup.index = currentIdx;
            currGroup.parentIndex = 0;
            // параметры групп
            const deIndex = groups[i].phrasesString.indexOf('~');
            const nmIndex = groups[i].phrasesString.indexOf('/');
            let phrases = groups[i].phrasesString;
            if (deIndex > 0) {
                let distanceEntities = parentGroupIdx;
                const distanceEntitiesStr = phrases.slice(deIndex + 1);
                if (distanceEntitiesStr.length > 1 && Number(distanceEntitiesStr[1])) {
                    distanceEntities = Number(distanceEntitiesStr);
                } else if (Number(distanceEntitiesStr[0])) {
                    distanceEntities = Number(distanceEntitiesStr[0]);
                }
                phrases = phrases.slice(1, deIndex - 1).trim();
                if (distanceEntities > 0) {
                    currGroup.parameters.distanceEntities = { parameter: ComparisonParametersType.equal, quantity: distanceEntities };
                }
            } else if (nmIndex > 0) {
                let numberMatches = 0;
                const distanceEntitiesStr = phrases.slice(nmIndex + 1);
                if (distanceEntitiesStr.length > 1 && Number(distanceEntitiesStr[1])) {
                    numberMatches = Number(distanceEntitiesStr);
                } else if (Number(distanceEntitiesStr[0])) {
                    numberMatches = Number(distanceEntitiesStr[0]);
                }
                phrases = phrases.slice(1, nmIndex - 1).trim();
                if (numberMatches > 0) {
                    currGroup.parameters.numberMatches = numberMatches;
                }
            }
            currGroup.phrases = [...preparePhrases(phrases)];
            if (nested) {
                if (result.children[result.children.length - 1]) {
                    result.children[result.children.length - 1].indexNextGroup = currentIdx;
                }
            } else if (i + 1 < groups.length) {
                currGroup.indexNextGroup = currentIdx + 1;
            }
            const operatorIdx = nested ? i + 1 : i;
            if (parentGroupOperators[operatorIdx]?.phrasesString) {
                currGroup.operator = manticoreOperatorToType(parentGroupOperators[operatorIdx].phrasesString);
            }
            result.children.push({ ...currGroup });
        }
    }
    return result;
};

const requestStringObjToArray = (data: ISearchGroup, isPreview: boolean): string[] => {
    let result: string[] = [];
    let isSingleGroup = false;
    if (data?.children?.length > 0) {
        const withPreviews = isPreview
            ? data?.children.filter(({ previewChecked }) => previewChecked)
                // для последних групп в списке не берём логический оператор
                .map((item, index, array) => (
                    (array.length - 1) > index ? item : ({ ...item, operator: null })
                ))
            : (data?.children ?? []);
        for (let i = 0; i < withPreviews.length; i += 1) {
            const phrases = requestStringObjToArray(withPreviews[i], isPreview);
            if (phrases?.length > 0) {
                let withParameter: string[] = [];
                // параметры групп
                if (withPreviews[i]?.parameters) {
                    if (
                        !phrases.some((item) => item.includes('"~'))
                        && withPreviews[i].parameters.distanceEntities
                        && withPreviews[i].parameters.distanceEntities?.quantity > 0
                    ) {
                        withParameter = ['("', ...phrases, `"~${withPreviews[i].parameters.distanceEntities.quantity})`];
                    } else if (
                        !phrases.some((item) => item.includes('"/'))
                        && withPreviews[i].parameters?.numberMatches > 0
                    ) {
                        withParameter = ['("', ...phrases, `"/${withPreviews[i].parameters?.numberMatches})`];
                    } else if (phrases.every((item) => !(item.includes('~') || item.includes('/')))) {
                        withParameter = ['(', ...phrases, ')'];
                    } else {
                        withParameter = [...phrases];
                    }
                } else {
                    withParameter = ['(', ...phrases, ')'];
                }
                result = [...result, ...withParameter];
            }
            if (
                i + 1 < withPreviews.length
                && withPreviews[i].operator in PhraseWordLogicOperatorType
                && withPreviews[i + 1].phrases.some(({ parameters }) => Boolean(parameters?.value))
            ) {
                result.push(typeToManticoreOperator(withPreviews[i].operator));
            }
        }
    } else if (data?.parameters) {
        if (
            (data?.parameters.distanceEntities && data?.parameters.distanceEntities.quantity > 0)
            || (data?.parameters?.numberMatches > 0)
        ) {
            isSingleGroup = true;
            result.push('("');
        }
    }

    if (data?.phrases?.length > 0) {
        const sorted = data.phrases
            .filter(({ parameters }) => Boolean(parameters?.value))
            .sort((a, b) => (
                (a?.indexNextPhrase ?? Number.MAX_VALUE) - (b?.indexNextPhrase ?? Number.MAX_VALUE)
            ));
        const phrasesWithPreviews = isPreview
            ? sorted.filter(({ previewChecked }) => previewChecked)
                // для последних фраз в списке не берём логический оператор
                .map((item, index, array) => (
                    (array.length - 1) > index ? item : ({ ...item, logicOperator: null })
                ))
            : sorted;
        for (let i = 0; i < phrasesWithPreviews?.length; i += 1) {
            let value = phrasesWithPreviews[i]?.parameters?.value;
            if (value.split(' ')?.length > 1) {
                value = `"${value}"`;
            }
            // параметры для фразы или слова
            if (phrasesWithPreviews[i]?.parameters && value) {
                if (phrasesWithPreviews[i].parameters?.numberOccurrences?.quantity > 0) {
                    result.push('(');
                    for (let j = 0; j < phrasesWithPreviews[i].parameters?.numberOccurrences?.quantity; j += 1) {
                        result.push(value);
                        if (j + 1 < phrasesWithPreviews[i].parameters?.numberOccurrences?.quantity) {
                            result.push('*');
                        }
                    }
                    result.push(')');
                    value = '';
                } else if (!phrasesWithPreviews[i].parameters.isTrack) {
                    value = `!${value}`;
                } else {
                    if (phrasesWithPreviews[i].parameters.considerDeclensions) {
                        value = `=${value}`;
                    }
                    if (phrasesWithPreviews[i].parameters.placeInSentence in LocationType) {
                        if (phrasesWithPreviews[i].parameters.placeInSentence === LocationType.start) {
                            value = `^${value}`;
                        } else if (phrasesWithPreviews[i].parameters.placeInSentence === LocationType.end) {
                            value = `${value}$`;
                        }
                    }
                }
            }
            if (value) {
                result.push(value);
            }
            if (phrasesWithPreviews[i]?.indexNextPhrase && phrasesWithPreviews[i]?.logicOperator in PhraseWordLogicOperatorType) {
                result.push(typeToManticoreOperator(phrasesWithPreviews[i]?.logicOperator));
            }
        }
    }
    if (isSingleGroup) {
        if (data?.parameters.distanceEntities && data?.parameters.distanceEntities.quantity > 0) {
            result.push(`"~${data?.parameters.distanceEntities.quantity})`);
        } else if (data?.parameters?.numberMatches > 0) {
            result.push(`"/${data?.parameters?.numberMatches})`);
        }
    }
    return result;
};

const resetGroupIndex = (
    data: ISearchGroup,
    minIndex: number,
    indexNextGroup: number | null,
    parentIndex: number | null,
): ISearchGroup => {
    if (!data) {
        return data;
    }
    let currIndex = minIndex;
    const result = { ...data, children: [...data?.children ?? []] } as ISearchGroup;
    if (result?.id !== currIndex) {
        result.id = currIndex;
        result.index = result.id;
    }
    currIndex += 1;
    result.parentIndex = parentIndex;
    result.indexNextGroup = indexNextGroup;
    if (result?.children?.length > 0) {
        const children: ISearchGroup[] = [];
        for (let i = 0; i < result.children.length; i += 1) {
            const buffer = resetGroupIndex(
                result.children[i],
                currIndex,
                (i + 1 < result.children.length ? currIndex + 1 : null),
                result.index,
            );
            const maxIndex = getMaxIndexFromNestedGroups(buffer);
            if (maxIndex >= currIndex) {
                currIndex = maxIndex + 1;
            }
            children.push(buffer);
        }
        result.children = children;
    }
    return result;
};

interface INode {
    value: string,
    children: INode[]
}

// Define all separators in an array
const separators = ['|', '&', '!', '| !', '& !', '<<'];

function searchStringToNode(input: string): INode {
    let current: INode = { value: 'group', children: [] };
    const stack: INode[] = [];

    for (let i = 0; i < input.length; i += 1) {
        const char = input[i];

        if (char === '(') {
            const newLevel: INode[] = [];
            current.children.push({ value: 'group', children: newLevel });
            stack.push(current);
            current = { value: 'group', children: newLevel };
        } else if (char === ')') {
            current = stack.pop() || current;
        } else if (char !== ' ') {
            let separatorFound = false;
            for (let j = 0; j < separators.length; j += 1) {
                const separator = separators[j];
                if (input.substr(i, separator.length) === separator) {
                    current.children.push({ value: separator, children: [] });
                    i += separator.length - 1;
                    separatorFound = true;
                    break;
                }
            }

            if (!separatorFound) {
                let value = '';
                if (char === '"') {
                    i += 1;
                    while (i < input.length && input[i] !== '"') {
                        value += input[i];
                        i += 1;
                    }
                } else {
                    while (i < input.length && input[i] !== ' ' && input[i] !== '(' && input[i] !== ')') {
                        value += input[i];
                        i += 1;
                    }
                }
                i -= 1; // offset for-loop increment
                current.children.push({ value, children: [] });
            }
        }
    }

    return current;
}

function isSeparator(value: string): boolean {
    return ['|', '&', '!', '<<'].includes(value);
}

function serializeNode(node: INode): string {
    let result = '';

    node.children.forEach((child) => {
        if (child.value === 'group') {
            result += `(${serializeNode(child)})`;
        } else if (isSeparator(child.value)) {
            result += child.value.startsWith('!') ? `${child.value}` : ` ${child.value} `;
        } else {
            result += `${child.value}`;
        }
    });

    return result.trim(); // Trim any extra space at the ends
}

export default {
    getNameForLogicOperator,
    getGroup,
    typeToManticoreOperator,
    manticoreOperatorInType,
    manticoreOperatorToType,
    getGroups,
    parseSearchRequest,
    getGroupWithoutIndex,
    requestStringObjToArray,
    getInitialGroup,
    checkedPreview,
    resetGroupIndex,
    replaceRepetitionsCondition,
    searchStringToNode,
    serializeNode,
};
