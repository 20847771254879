import React, { useMemo, useState } from 'react';
import {
    Page, Text, View, StyleSheet, Font,
} from '@react-pdf/renderer';
// @ts-ignore
import montserratRegular from '../../../../../fonts/static/Montserrat-Regular.ttf';
// @ts-ignore
import montserratMedium from '../../../../../fonts/static/Montserrat-Medium.ttf';
// @ts-ignore
import montserratBold700 from '../../../../../fonts/static/Montserrat-Bold-700.ttf';

Font.register({
    family: 'MontserratRegular400',
    src: montserratRegular,
});
Font.register({
    family: 'MontserratMedium500',
    src: montserratMedium,
});
Font.register({
    family: 'montserratBoldNumber',
    src: montserratBold700,
});

const styles = StyleSheet.create({
    templatePage: { backgroundColor: 'white' },
    templateHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 24,
        paddingRight: 16,
        height: 73,
        backgroundColor: '#f5fefd',
    },
    templateRoot: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
    },
    templateBody: {
        display: 'flex',
        alignItems: 'flex-start',
        height: '100%',
        gap: '16px',
    },
    templateTitle: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '131%',
        textAlign: 'center',
        color: '#0EBFA1',
        textTransform: 'uppercase',
    },
    templateNumber: {
        fontFamily: 'montserratBoldNumber',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '131%',
        textAlign: 'center',
        color: '#0EBFA1',
        textTransform: 'uppercase',
        marginRight: 6,
    },
    templateReportName: {
        fontFamily: 'MontserratMedium500',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        color: '#787E93',
        marginBottom: 8,
    },
    templateDate: {
        fontFamily: 'MontserratRegular400',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        color: '#787E93',
    },
    templateReportDataBox: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        height: '100%',
    },
    templateReportTitleBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
});

interface ICampaignPDFTemplatePageProps {
    reactElementKey: string;
    title: string;
    reportName: string;
    date: string;
}

const CampaignPDFTemplatePage: React.FC<ICampaignPDFTemplatePageProps> = ({
    reactElementKey, title, date, reportName, children,
}): JSX.Element => {
    const [pageNumber] = useState<string>(() => title?.split(' ')[0] ?? '');
    const [pageTitle] = useState<string>(() => {
        const parts = title?.split(' ');
        return parts.slice(1, parts.length).join(' ');
    });
    const reportTitle = useMemo<string>(() => `${reportName.substring(0, 1)
        .toUpperCase()}${reportName.slice(1)}`
        .split('/')
        .join(' / '), [reportName]);
    return (
        <Page key={reactElementKey ?? ''} size="A4" style={styles.templatePage} orientation="landscape">
            <View style={styles.templateRoot}>
                <View style={styles.templateHeader}>
                    <View style={styles.templateReportTitleBox}>
                        <Text style={styles.templateNumber}>
                            {pageNumber}
                        </Text>
                        <Text style={styles.templateTitle}>
                            {pageTitle}
                        </Text>
                    </View>
                    <View style={styles.templateReportDataBox}>
                        <Text style={styles.templateReportName}>
                            {reportTitle}
                        </Text>
                        <Text style={styles.templateDate}>
                            {date}
                        </Text>
                    </View>
                </View>
                <View style={styles.templateBody}>
                    {children}
                </View>
            </View>
        </Page>
    );
};

export default CampaignPDFTemplatePage;
