/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
/* eslint-disable react/self-closing-comp */
import React, { useMemo } from 'react';
import ChartView from './Chart';
import { getRandomNumber } from '../../utils/commons/commonUtils';

const ChartTracking: React.FC<any> = ({
    data,
    postDate,
    postData,
    isFullScreen,
    setIsFullScreen,
}) => {
    const calcTime = (date) => {
        const d = new Date(date);
        const utc = d.getTime() + (-1 * d.getTimezoneOffset() * 60000);
        return utc;
    };
    const getPointFromNode = (node) => ({
        x: new Date(calcTime(node?.posted)).setHours(0, 0, 0, 0),
        y: new Date(calcTime(node?.posted)).setFullYear(1970, 1, 1),
    });
    const getListPoints = (posts) => {
        if (!posts) return [];
        const mainPoint = posts?.nodes?.find((node) => (node[1]?.label === postData?.label) || (node[1]?.label === `${postData?.post_id}`));
        const listPointsDatasets = posts?.nodes?.map((node) => {
            const nodeData = node[1];
            const point = {
                ...getPointFromNode(nodeData),
                content: nodeData,
            };
            return point;
        });
        const mainPointDataSet = {
            type: 'bubble',
            pointStyle: 'circle',
            pointRadius: 8,
            pointHoverRadius: 4,
            pointBorderWidth: 4,
            pointBackgroundColor: '#DFFCF7',
            pointBorderColor: '#0EBFA1',
            data: [{
                ...getPointFromNode(mainPoint[1]),
                content: mainPoint[1],
            }],
        };
        const datasets = {
            type: 'bubble',
            pointStyle: 'circle',
            pointRadius: 8,
            pointHoverRadius: 4,
            pointBorderWidth: 4,
            pointBackgroundColor: '#E1E4E7',
            pointBorderColor: '#787E93',
            data: [
                ...listPointsDatasets,
            ],
        };
        if (mainPoint[1]) return [mainPointDataSet, datasets];
        return [datasets];
    };
    const getPointsEdges = (ids: number[], nodes) => {
        const trackingNodes = nodes.filter((node) => node[0] === ids[0] || node[0] === ids[1]);
        const points = trackingNodes.map((node) => {
            const nodeData = node[1];
            return {
                ...getPointFromNode(nodeData),
                content: nodeData,
            };
        });
        return points;
    };
    const getDatasetsEdges = (posts) => {
        if (!posts) return [];
        const listDatasets = posts?.edges?.map((ids: number[], index) => {
            const listColorsLine = {
                repost: '#E67E22',
                mention: '#0EBFA1',
            };
            // 300000
            // 100000
            const points = getPointsEdges(ids, posts?.nodes);
            const offsetPositon = getRandomNumber(-1, 2) > 0 ? 1 : -1;
            const offsetY = getRandomNumber(200000, 600000);
            const centerX = (points[0].x + points[1].x) / 2;
            const centerY = (points[0].y + points[1].y) / 2;
            const curvY = (points[0].y - points[1].y) / getRandomNumber(1, 3);
            const psevdoPoint = {
                x: centerX,
                y: centerY + (curvY),
                content: points[0].content,
            };
            const dataset = {
                type: 'line',
                borderWidth: 1,
                borderColor: listColorsLine[posts?.edge_labels[index]] || '#787E93',
                pointStyle: false,
                pointRadius: 0,
                data: [points[0], psevdoPoint, points[1]],
                tension: getRandomNumber(20, 40) / 100,
                line: {
                    tension: 1,
                },
            };
            return dataset;
        });
        return listDatasets;
    };
    const getDatasets = useMemo(
        () => [
            ...getListPoints(data),
            ...getDatasetsEdges(data),
        ],
        [data, postDate],
    );
    return getDatasets?.length ? <ChartView listDatasets={getDatasets} setIsFullScreen={setIsFullScreen} isFullScreen={isFullScreen} /> : <div></div>;
};

export default ChartTracking;
