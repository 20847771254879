import React from 'react';
import {
    Swiper, SwiperSlide,
} from 'swiper/react';
import { Navigation } from 'swiper';
import classNames from 'classnames';
import styles from './styles.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import './swiper-styles.css';
import SvgIcon from '../../components/SvgIcon';

interface IProps {
    images: string[];
    id: string | number;
}

const PostImagesCarousel: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { images, id } = props;

    return (
        <div className={styles.wrapper}>
            <Swiper
                slidesPerView="auto"
                spaceBetween={24}
                pagination={{
                    clickable: true,
                }}
                modules={[Navigation]}
                className="mySwiper"
                navigation={{
                    enabled: true,
                    nextEl: `.arrowButtonRight_${id}`,
                    prevEl: `.arrowButtonLeft_${id}`,
                    disabledClass: 'arrowButtonDisabled',
                }}
                loop
            >
                {images
                    && images.length >= 1
                    && images.map((item) => (
                        <SwiperSlide key={`${item}_key`}>
                            <img src={item} alt="slide" />
                        </SwiperSlide>
                    ))}
            </Swiper>

            <div
                className={classNames(
                    styles.arrowWrapper,
                    styles.arrowWrapperLeft,
                )}
            >
                <button type="button" className={`arrowButtonLeft_${id}`}>
                    <SvgIcon id="ArrowCarousel" size={11} height={17} />
                </button>
            </div>
            <div
                className={classNames(
                    styles.arrowWrapper,
                    styles.arrowWrapperRight,
                )}
            >
                <button type="button" className={`arrowButtonRight_${id}`}>
                    <SvgIcon id="ArrowCarousel" size={11} height={17} />
                </button>
            </div>
        </div>
    );
};

export default PostImagesCarousel;
