import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFoundPostState } from './types';
import { convertApiToFoundPost } from './converter';

const initialState: IFoundPostState = {
    source: '',
    newsId: '',
    content: '',
    title: '',
    logo: '',
    link: '',
};

const { actions: analyzeFoundPostActions, reducer } = createSlice({
    name: 'analyzeFoundPost',
    initialState,
    reducers: {
        setFoundPost: (state: IFoundPostState, { payload }: PayloadAction<unknown>) => {
            const buffer = convertApiToFoundPost(payload);
            return buffer;
        },
    },
});

export { analyzeFoundPostActions };
export default reducer;
