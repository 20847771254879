import iconRotate from './rotateIcon.svg';
import styles from './styles.module.scss';

const CorrectDisplayInfoModal: React.FC = (): React.ReactElement => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent,
    );

    if (!isTablet) return null;

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <p>Для корректного отображения информации поверните экран</p>
                <img src={iconRotate} alt="Rotate icon" />
            </div>
        </div>
    );
};
export default CorrectDisplayInfoModal;
