/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import styles from './styles.module.scss';
import { userTagsCampaingRtqService } from '../../../services/campaigns/userTags';
// import EventBusForChartGraph from '../../pagesComponents/info-trace/PublicationChartGraph/EventBus';
// import QuickSVGLoader from '../QuickSVGLoader';
import SvgIcon from '../../SvgIcon';

interface IPropsInputSelect {
    campaignId: number,
    postId: number,
    listDefaults: string[],
    onAddTag: (id: number, name: string) => void,
    onRemoveTag: (id: number, name: string) => void,
    onChangeCreateTag: (name: string) => void,
}

const InputFindTags: React.FC<IPropsInputSelect> = ({
    campaignId,
    postId,
    listDefaults,
    onAddTag,
    onRemoveTag,
    onChangeCreateTag,
}): JSX.Element => {
    const { data, isLoading } = userTagsCampaingRtqService.useGetUserTagsQuery({ campaign_id: campaignId });
    const [listSelect, setListSelect] = useState<string[]>([...listDefaults]);
    const handleRemoveWord = (word: string) => {
        setListSelect((prev) => prev.filter((item) => item !== word));
        const isReal = data?.find((item) => item.title === word);
        if (isReal) onRemoveTag(isReal.id, isReal.title);
    };
    const getTamplateWords = (words: string[]) => words.map((word) => (
        <div className={`${styles.wordContainer}`} key={word}>
            <div className={styles.word}>
                {word}
            </div>
            <div className={styles.removeWordIcn} onClick={() => handleRemoveWord(word)}>
                <SvgIcon id="Close" size={16} />
            </div>
        </div>
    ));
    const handleOncSelect = (value, option) => {
        setListSelect((prev) => {
            const has = prev.some((item) => item === value);
            if (!has) return [value, ...prev];
            return prev.filter((item) => item !== value);
        });

        const isReal = data?.find((item) => item.title === value);
        const isSelected = listSelect?.find((item) => item === value);
        if (isReal && isSelected) onRemoveTag(isReal?.id, isReal.title);
        if (isReal && !isSelected) onAddTag(isReal?.id, isReal?.title);
        if (!isReal) onChangeCreateTag(value);
    };
    const tagOptions = useMemo(() => {
        if (!Array.isArray(data)) return [];
        const options = data.map((item) => ({
            label: item.title,
            value: item.title,
        }));
        const result = options.filter((item) => !listSelect.some((elem) => elem === item.value));

        return result;
    }, [data, listSelect]);
    const defaults = useMemo(() => listDefaults.map((item) => ({
        label: item,
        value: item,
    })), [data]);

    return (
        <div className={styles.containerTag}>
            <div>
                <div className={styles.listWords}>
                    {getTamplateWords(listSelect)}
                </div>
            </div>
            <Select
                mode="tags"
                options={tagOptions}
                defaultValue={defaults}
                style={{ width: 450, borderColor: 'red' }}
                tagRender={() => null}
                onSelect={handleOncSelect}
                tokenSeparators={[',']}
                className={styles.containerSelectTags}
            />
            {/* <input
                className={styles.tagNameInput}
                type="text"
                value={name}
                onKeyDown={handleOnKeyDownInput}
                onChange={(e) => setName(e.target.value)}
            /> */}
        </div>
    );
};

export default InputFindTags;
