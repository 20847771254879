import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';
import LinearLoader from '../../../common/Loader';
import { IListAvaibelsDestination } from '../../ChartFiltersModel/types';
import { IRawChart, Period } from '../../ChartFilters/types';
import styles from './styles.module.scss';
import { ISimpleEntity } from '../../../../store/types';
// import { getChartData } from '../../../ChartEngagementDynamics/HandlerAPI';
// import { getEnums } from '../../ChartFilters/HandlerAPI';
import { CountedEntity, DestinationEntity } from '../../ChartSetting/types';
import ChartView from '../../../../containers/ChartContainer';
import ChartWrapper from './ChartWrapper';
import { LIST_COLORS } from '../../../../utils/constants/Charts/colors';
import { chartsCampaingRtqService } from '../../../../services/campaigns/Charts';

interface IChartList {
    charts: IRawChart[];
    campaingId: string;
    getListImages: (list: any) => void;
    isLoading: boolean;
    clearImages: boolean;
    imagesCleared: () => void;
}
const DestinationEntityByCountedEntity: IListAvaibelsDestination = {
    POSTS: ['SOURCES', 'PLATFORM_TYPE', 'CAMPAIGN'],
    SOURCE: ['PLATFORM_TYPE', 'CAMPAIGN'],
    PLATFORM: ['CAMPAIGN'],
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const periodsFilterData: ISimpleEntity[] = [
    {
        value: 7,
        name: '7 дней',
        id: 0,
    },
    {
        value: null,
        name: 'Вся кампания',
        id: 1,
    },
];

const getGradient = (ctx, chartArea, start_color, stop_color) => {
    let width; let height; let
        gradient;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, stop_color);
        gradient.addColorStop(1, start_color);
    }
    return gradient;
};

const CampaignReportsChartList: React.FC<IChartList> = ({
    charts, campaingId, getListImages,
    isLoading, imagesCleared, clearImages,
}): JSX.Element => {
    const [getListEnums] = chartsCampaingRtqService.useLazyGetEnumsQuery();
    const [getChart] = chartsCampaingRtqService.useGetChartDataMutation();
    const [listCountedEntity, setListCountedEntity] = useState([]);
    const [images, setImages] = useState([]);
    const [chartList, setChartList] = useState([]);
    const getFormatedList = (stringList: string[] | DestinationEntity[]): ISimpleEntity[] => stringList.map((string, index) => ({
        id: index,
        name: string,
        value: string,
    }));
    const randomInteger = (max) => Math.floor(Math.random() * (max + 1));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const randomRgba = (alpha?:number) => {
        const r = randomInteger(255);
        const g = randomInteger(255);
        const b = randomInteger(255);
        return {
            fill: `rgb(${r}, ${g}, ${b})`,
            alpha: `rgba(${r}, ${g}, ${b}, ${alpha || 0.05})`,
        };
    };
    const formatingCountedEntity = (list) => {
        if (!list) return [];
        const listStringCountedEntity = list.find((item) => Array.isArray(item.counted_entity));
        const result = listStringCountedEntity ? getFormatedList(listStringCountedEntity.counted_entity) : [];
        return result;
    };
    const forrmatedPoints = (points) => points.map((item) => ({
        x: new Date(item.dt).getTime(),
        y: item.count,
        content: { label: `${item.count}` },
    }));
    const getListCountedEntity = useMemo(() => formatingCountedEntity(listCountedEntity), [listCountedEntity]);
    const getListRawDestinationByName = (distanationName: string): DestinationEntity[] => (distanationName ? DestinationEntityByCountedEntity[distanationName] : []);
    const calcPeriod = (countDays: number | null): Period => {
        if (!countDays) {
            return {
                from_date: '',
                to_date: '',
            };
        }
        return {
            from_date: moment(new Date()).subtract(countDays, 'd').format('YYYY-MM-DD'),
            to_date: moment(new Date()).format('YYYY-MM-DD'),
        };
    };
    const getListDatasets = useMemo(() => {
        // const matrixPoints = chartList.map((item) => item.dataList);
        const listDatasets = chartList.map((item, chartIndex) => {
            const datasets = item.dataList.map((points, index) => {
                const isBig = item.dataList.length === 1;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const currentColor = LIST_COLORS[isBig ? chartIndex : index];
                const dataset = {
                    backgroundColor(context) {
                        const {
                            ctx,
                            chartArea,
                        } = context.chart;
                        if (!chartArea) {
                            return null;
                        }
                        return getGradient(ctx, chartArea, LIST_COLORS[index].lightColor, LIST_COLORS[index].alphaColor);
                    },
                    allColors: LIST_COLORS[index],
                    borderColor: LIST_COLORS[index].color,
                    pointBackgroundColor: LIST_COLORS[index].color,
                    pointBorderColor: 'white',

                    // borderColor: currentColor.color,
                    // pointBackgroundColor: currentColor.color,
                    // backgroundColor(context) {
                    //     const {
                    //         ctx,
                    //         chartArea,
                    //     } = context.chart;
                    //     if (!chartArea) {
                    //         return null;
                    //     }
                    //     return getGradient(ctx, chartArea, currentColor.lightColor, currentColor.alphaColor);
                    // },
                    // pointBorderColor: 'white',

                    pointRadius: 4,
                    pointBorderWidth: 1.5,
                    id: item.id,
                    label: item.name,
                    borderWidth: item.dataList.length > 1 ? 1 : 2,
                    type: 'line',
                    isShow: true,
                    data: points,
                    fill: isBig,
                };
                return dataset;
            });
            return {
                name: item.name, data: item.dataList, id: item.id, list: datasets, filters: item.filter_apply,
            };
        });
        return listDatasets;
    }, [chartList]);
    const getImage = useCallback((id, name, listPoints, img, filters) => {
        const newImages = JSON.parse(JSON.stringify(images));
        const check = newImages.find((item) => item.id === id);
        if (!check) {
            newImages.push({
                id, name, img, isBig: listPoints[0]?.length > 8, filters,
            });
        }
        if (check) {
            newImages.map((oldChart) => {
                if (id === oldChart.id) {
                    const newChart = oldChart;
                    newChart.img = img;
                    return newChart;
                }
                return oldChart;
            });
        }
        setImages(newImages);
    }, [images]);
    useEffect(() => {
        if (clearImages) {
            setImages([]);
            imagesCleared();
        }
    }, [clearImages]);
    useEffect(() => {
        getListImages(images);
    }, [images, getListDatasets]);
    useEffect(() => {
        getListEnums().then((res) => {
            const newSettings = res?.data;
            if (newSettings) {
                setListCountedEntity(newSettings);
            }
        });
        // getEnums().then((res) => {
        //     setListCountedEntity(res);
        // });
        const listPromiseChartDataSets = charts.map((item) => {
            // const countedList = {
            //     1: 'POSTS',
            //     2: 'SOURCES',
            //     3: 'PLATFORM',
            // };
            const countedName = getListCountedEntity.find((counted) => counted.name === item.active_counted_name).name as CountedEntity;
            const destinationList = getFormatedList(getListRawDestinationByName(countedName));
            const destinationName = destinationList.find((destination) => destination.name === item.active_destination_name).name;
            const newItem = item;
            const needDays = periodsFilterData.find((period) => period.id === item.active_available_period_id).value;
            const getPeriod = calcPeriod(+needDays);
            const propsForChartData: any = {
                campaign_id: +campaingId,
                time_frame: 'DAY',
                chart_type: 'LINE_CHART',
                counted_entity: countedName,
                destination_entity: destinationName as DestinationEntity,
                from_date: getPeriod.from_date,
                to_date: getPeriod.to_date,
                filter_apply: newItem.filter_apply,
            };
            const dataList = newItem.filter_apply.length
                ? newItem.filter_apply.map((elem) => getChart({ ...propsForChartData, filter_apply: [elem] }))
                : [getChart(propsForChartData)];
            return Promise.all(dataList).then((res: any) => ({
                ...newItem,
                dataList: res.map((pointList) => forrmatedPoints(pointList?.data)),
            }));
        });
        Promise.all(listPromiseChartDataSets).then((res) => {
            setChartList(res);
        });
    }, [charts, campaingId]);
    return (
        <>
            <div className={styles.chartListCampaingReport}>
                {
                    getListDatasets.map((datasets, index) => {
                        const id = `${getListDatasets}_${index}_${getListDatasets.length}`;
                        return (
                            <ChartWrapper key={id} title={datasets.name}>
                                <ChartView
                                    animation={false}
                                    listDatasets={datasets.list}
                                    width={`${(datasets?.data[0]?.length > 8 ? 840 : 386) * 1.5}px`}
                                    height={`${(datasets?.data[0]?.length > 8 ? 300 : 232) * 1.5}px`}
                                    beginAtZero
                                    isActiveTooltip={false}
                                    withParsing
                                    getImage={(img) => getImage(datasets.id, datasets.name, datasets.data, img, datasets.filters)}
                                />
                            </ChartWrapper>
                        );
                    })
                }
            </div>
            {
                isLoading && (
                    <div className={styles.loaderWrapper}>
                        <LinearLoader />
                    </div>
                )
            }
        </>
    );
};

export default React.memo(CampaignReportsChartList);
