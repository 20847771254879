import React from 'react';
import UserNavigatePanel from '@components/UserProfile/NavigatePanel';
import UserInfoContainer from '@containers/UserProfile/UserIfo';
import ContentContainer from '../../deprecated/pagesComponents/filters/ContentContainer';

import styles from './styles.module.scss';

const UserProfilePage: React.FC = (): JSX.Element => (
    <ContentContainer>
        <div className={styles.root}>
            <UserInfoContainer />
            <UserNavigatePanel />
        </div>
    </ContentContainer>
);

export default UserProfilePage;
