import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import messageModalSelectors from '../../../store/common/message-modal/selectors';
import { useAppDispatch } from '../../../store/hooks';
import { messageModalActions } from '../../../store/common/message-modal';
import CommonSimpleMessageModal from '../../../components/Modal/common/SimpleMessage';

const CommonMessageModalContainer: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const message = useSelector(messageModalSelectors.message);
    const confirmButtonTagId = useSelector(messageModalSelectors.confirmButtonTagId);
    const confirmButtonText = useSelector(messageModalSelectors.confirmButtonText);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handlerClickConfirm = (): void => {
        dispatch(messageModalActions.clearMessage());
        setIsOpen(false);
    };

    useEffect(() => {
        if (!isOpen && message) {
            setIsOpen(true);
        }
    }, [message]);

    return (
        <CommonSimpleMessageModal
            message={message}
            isOpen={isOpen}
            confirmButtonText={confirmButtonText}
            tagId={confirmButtonTagId}
            onClickConfirm={handlerClickConfirm}
        />
    );
};

export default CommonMessageModalContainer;
