import classnames from 'classnames';
import { checkCookies } from 'cookies-next';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import useResizeObserver from 'use-resize-observer';
import Path from '@shared/lib/paths';
import LogoMenuIcon from '@assets/images/menu-logo.svg';
import NarrowMenuContainer from '@containers/common/NarrowMenu';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { campaignActions } from '@store/campaign/source';
import campaignSelectors from '@store/campaign/source/selectors';
import { CampaignActiveModalType, ModalAnswerType } from '@store/campaign/source/types';
import { currentPageActions } from '@store/common/current-page';
import { leftMenuActions } from '@store/common/left-menu';
import { selectLeftMenuState } from '@store/common/left-menu/selectors';
import { commonWindowActions } from '@store/common/window';
import { selectCommonWindowIsIPadSize, selectCommonWindowIsRetinaSize } from '@store/common/window/selectors';
import { selectHistoryLinksState } from '@store/history/links/selectors';
import { useAppDispatch } from '@store/hooks';
import { sendMetrics } from '../../utils/commons/metrics';
import { AUTH_COOKIE_NAME } from '../../utils/constants';
import HeaderContainer from '../HeaderContainer';
import IconChevron from './img/chevron.svg';
import CorrectDisplayInfoModal from './CorrectDisplayInfoModal';
import styles from './styles.module.scss';

const retinaWidthSize = 1524;
const iPadWidthSize = 1200;

const Layout = function Layout({ children }: { children: React.ReactNode }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { ref, width: layoutWidth, height: layoutHeight } = useResizeObserver<HTMLDivElement>();
    const dispatch = useAppDispatch();
    const historyLink = useSelector(selectHistoryLinksState);
    const { isExpanded: menuExpanded } = useSelector(selectLeftMenuState);
    const isRetinaSize = useSelector(selectCommonWindowIsRetinaSize);
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);
    const campaignCreatingBegun = useSelector(campaignSelectors.createBegun);
    const campaignActiveModal = useSelector(campaignSelectors.activeModal);
    const campaignModalAnswer = useSelector(campaignSelectors.modalAnswer);

    const [activeNavigateHref, setActiveNavHref] = useState<string>(null);

    // const [menuExpanded, setMenuExpanded] = useState<boolean>(false);

    const isAuth = checkCookies(AUTH_COOKIE_NAME);

    const handlerLogoClick = (): void => {
        // временно отменяем переход по лого
        return;

        sendMetrics('reachGoal', 'MenuLogoClick');
        if (campaignCreatingBegun) {
            dispatch(campaignActions.setActiveModal(CampaignActiveModalType.switchingPage));
            setActiveNavHref(Path.Campaign);
        } else {
            navigate(Path.Campaign);
            setActiveNavHref(null);
        }
    };

    // const onExpandMenu = (): void => {
    //     dispatch(leftMenuActions.setExpanded(!menuExpanded));
    //     // setMenuExpanded(!menuExpanded);
    // };

    const sendMetricsLinkFromHistory = (): void => {
        sendMetrics('reachGoal', 'LinkAnalyzeFromHistory');
    };
    useEffect(() => {
        if (!activeNavigateHref) {
            return;
        }
        if (campaignActiveModal === CampaignActiveModalType.switchingPage) {
            if (campaignModalAnswer === ModalAnswerType.accept) {
                navigate(activeNavigateHref);
                setActiveNavHref(null);
            }
            if (campaignModalAnswer !== ModalAnswerType.undefined) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
            }
        }
    }, [campaignModalAnswer]);
    useEffect(() => {
        const { pathname } = location;
        if (pathname?.length) {
            if (!pathname.includes('campaign')) {
                dispatch(currentPageActions.clearCurrentCampaign());
            } else if (pathname.includes('campaign') && pathname.includes('list')) {
                dispatch(currentPageActions.clearCurrentCampaign());
            }
            if (!pathname.includes('projects')) {
                dispatch(currentPageActions.clearCurrentProject());
            } else if (pathname.includes('projects') && pathname.includes('project-list')) {
                dispatch(currentPageActions.clearCurrentProject());
            }
        }
    }, [location]);

    useEffect(() => {
        if (historyLink.needChangeRoute) {
            sendMetricsLinkFromHistory();
            navigate(Path.InfoTrace);
        } else if (historyLink.needInfoBasketSearch) {
            navigate(Path.InfoBasket);
        }
    }, [historyLink]);

    useEffect(() => {
        if (layoutWidth > iPadWidthSize && layoutWidth < retinaWidthSize) {
            dispatch(commonWindowActions.setWidth(layoutWidth));
            if (isIPadSize) {
                dispatch(commonWindowActions.unsetIPadSize());
            }
            dispatch(commonWindowActions.setRetinaSize());
        } else if (layoutWidth > 0 && layoutWidth < iPadWidthSize) {
            dispatch(commonWindowActions.setWidth(layoutWidth));
            dispatch(commonWindowActions.setIPadSize());
        } else {
            if (isRetinaSize) {
                dispatch(commonWindowActions.unsetRetinaSize());
            }
            if (isIPadSize) {
                dispatch(commonWindowActions.unsetIPadSize());
            }
        }
    }, [layoutWidth, layoutHeight]);

    const siderRef = useRef();
    const setMenuExpanded = (bool: boolean): void => {
        dispatch(leftMenuActions.setExpanded(bool));
    };
    useOnClickOutside(siderRef, () => setMenuExpanded(false));

    return (
        <div className={styles.mainContainer} ref={ref}>
            {isAuth && (
                <div
                    ref={siderRef}
                    className={classnames(styles.siderContainer, menuExpanded ? styles.expanded : styles.normal)}
                >
                    <button
                        type="button"
                        className={classnames(styles.chevron, menuExpanded ? styles.expanded : styles.normal)}
                        onClick={() => setMenuExpanded(!menuExpanded)}
                    >
                        <img src={IconChevron} alt="" width={26} />
                    </button>
                    <button type="button" onClick={handlerLogoClick} className={styles.logoContainer} style={{ cursor: 'default' }}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className={styles.link} style={{ cursor: 'default' }}>
                            <img src={LogoMenuIcon} alt="logo" />
                        </a>
                        {/* <span className={styles.logoContainer_label}> */}
                        {/*    Кампании */}
                        {/* </span> */}
                    </button>
                    {/* <div className={styles.arrowsContainer}> */}
                    {/*    /!* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions *!/ */}
                    {/*    <div */}
                    {/*        id="common-menu" */}
                    {/*        className={ */}
                    {/*            classnames(styles.arrowsContainer_arrows, styles[menuExpanded ? 'expanded' : 'normal']) */}
                    {/*        } */}
                    {/*        onClick={onExpandMenu} */}
                    {/*    > */}
                    {/*        <img src={ArrowsMenuIcon} alt="arrows" /> */}
                    {/*    </div> */}
                    {/* </div> */}
                    {/* <NarrowMenu menuExpanded={menuExpanded} /> */}
                    <NarrowMenuContainer />
                    <span style={{ marginBottom: 20, marginTop: 'auto' }} />
                </div>
            )}
            <div className={styles.contentContainer}>
                {
                    location?.pathname?.length > 0 && (
                        <HeaderContainer />
                    )
                }
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
                <>
                    {children}
                </>
            </div>

            <CorrectDisplayInfoModal />
        </div>
    );
};

export default Layout;
