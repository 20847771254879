import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFeedback } from 'components/Modal/UGCMSFeedbackModal/types';
import { EmailSendStatus } from '../../types';
import {
    IFeedbackState,
    IIrrelevantNews,
    IIrrelevantNewsId,
    IIrrelevantNewsStatus,
} from './types';

const initialState: IFeedbackState = {
    email: '',
    content: '',
    userName: '',
    status: EmailSendStatus.undefined,
    irrelevantNews: [],
    irrelevantNewsComparison: [],
};

const getComparisonModel = (irrelevantNews: IIrrelevantNews[]): IIrrelevantNewsStatus[] => irrelevantNews.map(
    ({ id, irrelevantSendStatus }) => ({
        id,
        irrelevantSendStatus,
    } as IIrrelevantNewsStatus),
);

const { actions: feedbackActions, reducer } = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        setFeedbackData: (state: IFeedbackState, { payload }: PayloadAction<IFeedback>): void => {
            if (!payload) {
                return;
            }
            state.content = payload.content;
            state.email = payload.email;
            state.userName = payload.name;
        },
        setIrrelevantNewsData: (state: IFeedbackState, { payload }: PayloadAction<IIrrelevantNews>): void => {
            if (!payload || !payload.id) {
                return;
            }
            if (state.irrelevantNews.find((item) => item.id === payload.id && payload.irrelevantNewsLink)) {
                return;
            }
            state.irrelevantNews = [...state.irrelevantNews, { ...payload }];
            state.irrelevantNewsComparison = getComparisonModel(state.irrelevantNews);
        },
        sendingIrrelevantNews: (
            state: IFeedbackState,
            { payload }: PayloadAction<IIrrelevantNewsId>,
        ) => {
            if (!payload) {
                return;
            }
            const irrelevantNews = state.irrelevantNews.find(
                (item) => item.id === payload.id && item.irrelevantNewsLink === payload.link,
            );
            if (!irrelevantNews) {
                return;
            }
            irrelevantNews.irrelevantSendStatus = EmailSendStatus.sending;

            state.irrelevantNewsComparison = getComparisonModel([...state.irrelevantNews]);
        },
        sendIrrelevantNewsFailed: (
            state: IFeedbackState,
            { payload }: PayloadAction<IIrrelevantNewsId>,
        ) => {
            if (!payload) {
                return;
            }
            const irrelevantNews = state.irrelevantNews.find(
                (item) => item.id === payload.id && item.irrelevantNewsLink === payload.link,
            );
            if (!irrelevantNews) {
                return;
            }
            irrelevantNews.irrelevantSendStatus = EmailSendStatus.failed;
            state.irrelevantNewsComparison = getComparisonModel([...state.irrelevantNews]);
        },
        sendIrrelevantNewsComplete: (
            state: IFeedbackState,
            { payload }: PayloadAction<IIrrelevantNewsId>,
        ) => {
            if (!payload) {
                return;
            }
            const irrelevantNews = state.irrelevantNews.find(
                (item) => item.id === payload.id && item.irrelevantNewsLink === payload.link,
            );
            if (!irrelevantNews) {
                return;
            }
            irrelevantNews.irrelevantSendStatus = EmailSendStatus.sent;
            state.irrelevantNewsComparison = getComparisonModel([...state.irrelevantNews]);
        },
        sending: (
            state: IFeedbackState,
        ) => {
            state.status = EmailSendStatus.sending;
        },
        sendFailed: (
            state: IFeedbackState,
        ) => {
            state.status = EmailSendStatus.failed;
        },
        sendComplete: (
            state: IFeedbackState,
        ) => {
            state.status = EmailSendStatus.sent;
        },
    },
});

export { feedbackActions };
export default reducer;
