import styled from 'styled-components';

export const StyledListLink = styled.a`
    color: #096DD9;
    margin-right: 17px;
    font-size: 14px;
    text-overflow:ellipsis;
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
`;

export const NewsListContainer = styled.div`
    max-height: 300px;
    max-width: 3224px;
    overflow: hidden;
    margin-bottom: 24px;
    margin-right: 20px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 24px;
`;

export const Divider = styled.div`
    position: absolute;
    right: 100px;
    top: 16px;
    width: 1px;
    background-color: #EEEFF7;
    height: 24px;
`;

export const CardContainer = styled.div`
    padding: 20px 24px 24px;
    background: #FFFFFF;
    border-radius: 12px;
    max-width: 420px;
    height: 420px;
`;
export const ChartWithDescription = styled.div`
    display: flex;
    height: 100%;
`;
export const BubbleChartDescriptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
export const BubbleChartDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;
export const ChartContainer = styled.div`
    width: fill-available;
    height: 100%;
    min-width: 420px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 6px;
    max-width: 2770px;
    margin-bottom: 24px;
    margin-right:20px;
    overflow: auto;
`;

export const RadarContainer = styled.div`
    min-width: 420px;
    height: 420px;
    padding: 20px 15px 20px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-right: 20px;
`;

export const TopHeader = styled.h5`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #0EBFA1;
`;
export const TitleChart = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0EBFA1;
`;
export const SubTitleChart = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #4F4F4F;
`;
export const StyledLink = styled.a`
    display: block;
    margin-top: auto;
    color: #fff;
    padding: 10px 16px;
    width: 111px;
    height: 40px;
    background: #1D202F;
    border-radius: 6px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
`;

export const TitleContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
`;

export const CardTitle = styled.h6`
    width: 248px;
    margin-right: 20px;
    max-height: 160px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
`;

export const Description = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #787E93;

  margin-bottom: 0;
  max-height: 100px;
  overflow: hidden;
  word-break: break-word;
`;

export const LogoImage = styled.img`
    width: 72px;
    height: 72px;
    border-radius: 50%;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px 10px;
  
  min-height: 31px;

  background: #FFFFFF;
  border-radius: 6px;
`;

export const ErrorMessage = styled.div`
    display: flex;
    align-items: center;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ff4d4f;
`;

export const WarningMessage = styled.div`
    display: flex;
    align-items: center;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #E67E22;
`;

export const InfoMessage = styled(WarningMessage)`
    color: #00CC99;
`;

export const SubcategoryTag = styled.span`
    display: inline-block;
    padding: 0px 16px 2px;
    background: #EEF0F9;
    border-radius: 6px;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #1D202F;
    margin-right: 8px;
    margin-bottom: 8px;
`;
