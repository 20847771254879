import { combineReducers } from 'redux';
import historyLinksReducer from './links';
import historyPeriodReducer from './period';

const historyReducers = () => combineReducers({
    historyLinks: historyLinksReducer,
    historyPeriod: historyPeriodReducer,
});

export default historyReducers;
