import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import SvgIcon from '@components/SvgIcon';
import { ISimplePeriod } from '@store/campaign/comparison';
import { Button, Select } from '@shared/index';
import { ISelectItem } from '@shared/ui-components/Select/types';
import DateSlider from './DateSlider';
import styles from './styles.module.scss';

interface IProps {
    isOpen: boolean;
    referenceCampaign: ICampaignDataModel;
    compareCampaigns: ICampaignDataModel[];
    onCloseModal: () => void;
    onStartComparison: (period: ISimplePeriod) => void;
    onOpenListSelectCompany: (name: string) => void;
    onRemoveCampaign: (id: number) => void;
}

const listValues: ISelectItem[] = [
    { id: 1, value: 'Уже имеющуюся', label: 'Уже имеющуюся' },
    { id: 2, value: 'Создать новую', label: 'Создать новую' },
];

const granularityMaxInDays = 7;
const granularityMinInDays = 1;
const shiftStep = 3;
const dateNow = new Date();
const dateNowStr = dateNow.toLocaleDateString('ru-Ru');

const getDateFromString = (value: string): Date => {
    if (!value) {
        return null;
    }
    const dateParts = value.split('.');
    return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
};

const calculateShift = (startDate: Date, endDate: Date, granularity: number, isLeftShift: boolean): ISimplePeriod => {
    const bufferStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    bufferStart.setDate(startDate.getDate() + ((granularity * shiftStep) * (isLeftShift ? 1 : -1)));
    const bufferEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    bufferEnd.setDate(endDate.getDate() + ((granularity * shiftStep) * (isLeftShift ? 1 : -1)));
    return {
        start: bufferStart, end: bufferEnd,
    };
};

const ComparisonPeriodModal: React.FC<IProps> = ({
    isOpen,
    referenceCampaign,
    compareCampaigns,
    onCloseModal,
    onStartComparison,
    onOpenListSelectCompany,
    onRemoveCampaign,
}): JSX.Element => {
    const [comparisonPeriod, setComparisonPeriod] = useState<ISimplePeriod>(
        () => ({ start: referenceCampaign?.parseFromDate, end: dateNow }),
    );
    const [currentGranularity, setCurrentGranularity] = useState<number>(0);
    const [zoomValueInDays, setZoomValue] = useState<number>(0);
    const [currentChartStartDate, setStartDate] = useState<Date>(() => getDateFromString(referenceCampaign?.parseFrom));
    const [isWarningBlock, setIsWarningBlock] = useState<boolean>(true);
    const [currentChartEndDate, setEndDate] = useState<Date>(dateNow);

    const isDisabledCompareBtn = useMemo<boolean>(() => !(compareCampaigns?.length > 0), [compareCampaigns]);
    const defaultStartDate = useMemo<Date>(() => {
        const maxStartDate = compareCampaigns
            .reduce(
                (acc, cur) => (acc < cur?.parseFromDate ? cur.parseFromDate : acc),
                new Date(1000, 1, 1),
            );
        return referenceCampaign?.parseFromDate < maxStartDate ? maxStartDate : referenceCampaign?.parseFromDate;
    }, [compareCampaigns, referenceCampaign]);

    const handlerChangeComparisonPeriod = useCallback((period: ISimplePeriod): void => {
        if (comparisonPeriod !== period) {
            setComparisonPeriod(period);
        }
    }, [comparisonPeriod, setComparisonPeriod]);

    const handlerCloseModal = (): void => {
        setIsWarningBlock(true);
        onCloseModal();
        setZoomValue(0);
    };
    const handlerRemoveCampaign = (id: number) => {
        if (onRemoveCampaign) {
            onRemoveCampaign(id);
        }
    };
    const handlerZoomIn = (): void => {
        setZoomValue(currentGranularity <= granularityMinInDays ? zoomValueInDays : zoomValueInDays - 1);
    };
    const handlerZoomOut = (): void => {
        setZoomValue(currentGranularity >= granularityMaxInDays ? zoomValueInDays : zoomValueInDays + 1);
    };
    const handlerShift = (isLeft: boolean): void => {
        const { start, end } = calculateShift(currentChartStartDate, currentChartEndDate, currentGranularity, isLeft);
        setStartDate(start);
        setEndDate(end);
    };
    const handlerSelectControl = (id: number): void => {
        if (id === 1) {
            onOpenListSelectCompany('AddCompany');
        } else if (id === 2) {
            onOpenListSelectCompany('CreateCompany');
        }
    };
    const handlerButtonComparison = () => {
        setIsWarningBlock(true);
        onStartComparison(comparisonPeriod);
    };

    const dateSliderWithoutPeriodChoice = useMemo<JSX.Element>(() => (
        <div className={styles.contentContainer_compareCompany}>
            {compareCampaigns.map((company) => (
                <div key={company.id} className={styles.contentContainer_company}>
                    <div className={`${styles.contentContainer_companyBlock} ${styles.contentContainer_companyBlock_bgWhite}`}>
                        <div className={styles.contentContainer_companyInfo}>
                            <span className={styles.contentContainer_spanTitleCompany}>
                                {company.name}
                            </span>
                            <span className={styles.contentContainer_spanDateCompany}>
                                {`${company.parseFrom} - ${dateNowStr}`}
                            </span>
                        </div>
                        <button
                            className={styles.contentContainer_basketButton}
                            type="button"
                            onClick={() => handlerRemoveCampaign(company.id)}
                        >
                            <div className={styles.contentContainer_basket}>
                                <SvgIcon id="Trashcan" />
                            </div>
                        </button>
                    </div>

                    <DateSlider
                        stepWidth={20}
                        lineWidth={800}
                        countCompany={0}
                        minDate={currentChartStartDate}
                        maxDate={currentChartEndDate}
                        defaultStartDate={company.parseFromDate}
                        dateStart={company.parseFromDate}
                        dateEnd={dateNow}
                        zoomIncreaseDecrease={zoomValueInDays}
                        onChangeGranularity={setCurrentGranularity}
                    />
                </div>
            ))}
        </div>
    ), [
        compareCampaigns,
        dateNow,
        referenceCampaign,
        getDateFromString,
        zoomValueInDays,
        setCurrentGranularity,
        currentChartStartDate,
        currentChartEndDate,
    ]);

    useEffect(() => {
        setComparisonPeriod(({ start: referenceCampaign?.parseFromDate, end: new Date() }));
    }, [referenceCampaign]);
    useEffect(() => {
        if (referenceCampaign?.parseFrom) {
            setStartDate(getDateFromString(referenceCampaign?.parseFrom));
        }
    }, [referenceCampaign]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.comparisonPeriodModal_root}>
            <div className={styles.modalWindow}>
                <div className={styles.flexColumn}>
                    <div className={styles.flexRow} style={{ justifyContent: 'space-between', height: 38 }}>
                        <div className={styles.contentContainer_title}>
                            Конкурентный анализ кампаний
                        </div>
                        <div className={styles.modalCrossWrapper}>
                            <button type="button" style={{ padding: 0, background: 'none' }} onClick={handlerCloseModal}>
                                <SvgIcon id="BigCross" size={14} />
                            </button>
                        </div>
                    </div>
                    {isWarningBlock && (
                        <div className={styles.contentContainer_warningBlock}>
                            <p className={styles.contentContainer_warningText}>
                                Для конкурентного анализа
                                {' '}
                                <b>периоды кампаний должны совпадать</b>
                                . Перетаскивая ползунки,
                                вы можете задать оптимальный для себя период сравнений кампаний
                            </p>
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => null}
                                onClick={() => setIsWarningBlock(false)}
                            >
                                <div className={styles.contentContainer_warningIcon}>
                                    <SvgIcon id="HintCross" />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.flexRow} style={{ justifyContent: 'space-between', minHeight: 350 }}>
                        <div className={styles.contentContainer}>
                            <div className={styles.contentContainer_referenceCompany}>
                                <span className={styles.contentContainer_spanReferenceCompany}>Эталонная камания</span>
                                <div className={styles.contentContainer_companyBlock}>
                                    <span className={styles.contentContainer_spanTitleCompany}>
                                        {referenceCampaign.name}
                                    </span>
                                    <span className={styles.contentContainer_spanDateCompany}>
                                        {`${referenceCampaign.parseFrom} - ${dateNowStr}`}
                                    </span>
                                </div>
                            </div>
                            <DateSlider
                                stepWidth={20}
                                lineWidth={800}
                                countCompany={compareCampaigns.length}
                                minDate={currentChartStartDate}
                                maxDate={currentChartEndDate}
                                defaultStartDate={defaultStartDate}
                                dateStart={getDateFromString(referenceCampaign.parseFrom)}
                                dateEnd={dateNow}
                                onChangeComparisonPeriod={handlerChangeComparisonPeriod}
                                defaultPeriodChoice={{
                                    start: getDateFromString(referenceCampaign.parseFrom),
                                    end: dateNow,
                                }}
                                isPeriodChoice
                                zoomIncreaseDecrease={zoomValueInDays}
                                onChangeGranularity={setCurrentGranularity}
                            />
                            {dateSliderWithoutPeriodChoice}
                            <div className={styles.scrollWrapper}>
                                <div className={styles.contentContainer_lineSwitching}>
                                    <button
                                        type="button"
                                        className={styles.contentContainer_btnSwitching}
                                        onClick={() => handlerShift(false)}
                                    >
                                        <SvgIcon id="LeftArrowIcon" />
                                    </button>
                                    <button
                                        type="button"
                                        className={`${styles.contentContainer_btnSwitching} ${styles.contentContainer_btnSwitching_shift}`}
                                        onClick={() => handlerShift(true)}
                                    >
                                        <SvgIcon id="RightArrowIcon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.zoomWrapper}>
                            <div className={styles.modalWindow_line}>
                                <div className={styles.modalWindow_controllerBlock}>
                                    <button
                                        type="button"
                                        className={styles.modalWindow_btn}
                                        onClick={handlerZoomIn}
                                    >
                                        +
                                    </button>
                                    <button
                                        type="button"
                                        className={`${styles.modalWindow_btn} ${styles.modalWindow_btn_shift}`}
                                        onClick={handlerZoomOut}
                                    >
                                        -
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.controlsWrapper}>
                        <Select
                            size="medium"
                            placeholder="Добавить кампанию"
                            items={listValues}
                            onSelect={handlerSelectControl}
                        />
                        <Button
                            text="Сравнить"
                            type="secondary"
                            size="large"
                            disabled={isDisabledCompareBtn}
                            onClick={() => handlerButtonComparison()}
                        />
                        {/* <ButtonSecondary */}
                        {/*    style={{ width: 120, height: 34, cursor: isDisabledCompareBtn ? 'default' : 'pointer' }} */}
                        {/*    text="Сравнить" */}
                        {/*    disabled={isDisabledCompareBtn} */}
                        {/*    onClick={() => handlerButtonComparison()} */}
                        {/* /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComparisonPeriodModal;
