/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    ChartType, Dimension, ISavedChartModel, Metric, TimeFrame,
} from 'services/campaigns/Charts/models';
import { getPeriodForCurrentWeek } from '../../components/ChartSettings/helpers';
import ChartList from '../../components/ChartList';
import { selectCommonWindowIsIPadSize } from '../../store/common/window/selectors';
import { IApplyFilter, IChart } from './ChartTypes';
import styles from './styles.module.scss';
import ChartSettings from '../../components/ChartSettings';
import { chartsCampaingRtqService } from '../../services/campaigns/Charts';
import useDebouncedCallback from '../../shared/hooks/useDebounceCallback';

// type HandlerChangeOptionSetting = <T extends keyof IChart> (value?: IChart[T] | any, field?: T) => void;
type HandlerChangeOptionSetting = (value?: any, field?: string) => any;

interface IListHandlersChangeChart extends Record<keyof IChart, HandlerChangeOptionSetting> {}

const OLD_PERIOD_FORMAT = 'YYYY-MM-DD';
/**
 * @constructor
 * @param {string} from_date - format from_date YYYY-MM-DD
 * @param {string} to_date - format to_date YYYY-MM-DD
*/
const createChartByShablon = (intermediumId: number, campaign_id: number, from_date: string, to_date: string): IChart => ({
    intermediumId,
    isInitial: true,
    isSelect: true,
    created: new Date().getTime(),
    name: 'Новый график',
    time_frame: 'DAY',
    chart_type: 'TIME_CHART',
    campaign_id,
    from_date,
    to_date,
    metrics: 'POSTS',
    dimensions: ['SEMANTIC'],
    filters_include: [],
});

const formChartModel = (models: ISavedChartModel[]): IChart[] => models.map((model, index) => {
    const chart: IChart = {
        id: model.id,
        name: model.name,
        isInitial: false,
        isSelect: index === 0,
        created: model.created,
        updated: model.updated,
        time_frame: model.time_frame,
        chart_type: model.chart_type,
        campaign_id: model.campaign_id,
        from_date: model.from_date, // YYYY-MM-DD
        to_date: model.to_date, // YYYY-MM-DD
        metrics: model.metric,
        dimensions: model.dimensions,
        filters_include: model.filters_include,
    };
    return chart;
});

interface CampaignNewChartsProps {
    isSaved?: boolean
}
const CampaignNewCharts: React.FC<CampaignNewChartsProps> = ({ isSaved }): JSX.Element => {
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);
    const { Id: campaignId } = useParams();
    const [getChartsList] = chartsCampaingRtqService.useLazyGetChartsListQuery();
    const [removeChart] = chartsCampaingRtqService.useLazyRemoveChartQuery();
    const [newUpdateChart] = chartsCampaingRtqService.useLazyNewUpdateChartQuery();
    const debounceUpdateChart = useDebouncedCallback(newUpdateChart, 1000);
    const [chartList, setChartList] = useState<IChart[]>();

    const generateChartsFromModel = (models: ISavedChartModel[]) => {
        const charts: IChart[] = formChartModel(models);
        setChartList(charts);
    };
    const initializationChartList = (isSavedPage: boolean): void => {
        if (!isSavedPage) {
            const initalDates = getPeriodForCurrentWeek(30);
            createChartByShablon(
                new Date().getTime(),
                +campaignId,
                initalDates[0].format(OLD_PERIOD_FORMAT),
                initalDates[1].format(OLD_PERIOD_FORMAT),
            );
            return;
        }
        getChartsList({ campaingId: +campaignId }).then((res) => {
            if (Array.isArray(res?.data)) generateChartsFromModel([...res.data].reverse());
        });
    };
    /**
     * @argument value - Принимает в себя объект {filter_name: "negative", filters_category: "Тональность"}
     * @argument field - Принимает в поле filters_include типа IApplyFilter
    */
    const handleChangeFilter = (value, field): IApplyFilter[] => {
        const check = field.find((filter) => (value.filters_category === filter.filters_category && value.filter_name === filter.filter_name));
        if (check) {
            return field.filter((filter) => filter.filter_name !== value.filter_name);
        }
        return [...field, value];
    };
    const handleChangeMetric = (newMetric: Metric) => newMetric;
    const handleChangeChartType = (newChartType: ChartType) => newChartType;
    const handleChangeTimeFrame = (timeFrame: TimeFrame) => timeFrame;
    const handleChangeDimensions = (newChartDimensions: Dimension[]) => newChartDimensions;
    const handleChangeFromDate = (fromDate: string) => fromDate;
    const handleChangeToDate = (toDate: string) => toDate;
    const listHandlers: IListHandlersChangeChart = {
        filters_include: handleChangeFilter,
        metrics: handleChangeMetric,
        chart_type: handleChangeChartType,
        time_frame: handleChangeTimeFrame,
        dimensions: handleChangeDimensions,
        from_date: handleChangeFromDate,
        name: (name: string) => name,
        to_date: handleChangeToDate,
        id: () => null,
        campaign_id: () => null,
        intermediumId: () => null,
        isInitial: () => null,
        isSelect: () => null,
        created: () => null,
        updated: () => null,
    };
    const handleSetChartSettings = <T extends keyof IChart> (field?: T, value?: IChart[T]): void => {
        setChartList((prev) => prev.map((chart: IChart) => {
            if (!chart?.isSelect) return chart;
            const newChart: IChart = { ...chart };
            newChart[field] = listHandlers[field](value, newChart[field as any]);
            // newUpdateChart
            debounceUpdateChart({
                data: {
                    name: newChart?.name || 'Новый график',
                    time_frame: newChart?.time_frame,
                    chart_type: newChart?.chart_type,
                    from_date: `${newChart?.from_date}`,
                    to_date: `${newChart?.to_date}`,
                    metric: newChart?.metrics,
                    dimensions: newChart?.dimensions,
                    filters_include: newChart?.filters_include,
                    filters_exclude: [],
                },
                campaign_id: +campaignId,
                id: newChart.id,
            });
            return newChart;
        }));
    };
    const handleSetPrevSettings = (oldSettings: IChart): void => {
        setChartList((prev) => prev?.map((item) => {
            if (item.isSelect) return oldSettings;
            return item;
        }));
    };
    const removeChartFromChartList = (id: number, isSavedChart: boolean) => {
        setChartList((prev) => prev.filter((chart) => {
            if (isSavedChart) return chart.id !== id;
            return chart?.intermediumId !== id;
        }));
        setChartList((prev) => prev.map((item, index) => {
            if (index === 0) {
                return {
                    ...item,
                    isSelect: true,
                };
            }
            return item;
        }));
    };
    const handleRemoveChart = (id: number, isSavedChart: boolean): void => {
        if (isSavedChart) {
            removeChart({ campaign_id: +campaignId, id }).then((res) => {
                if (res.status === 'fulfilled') removeChartFromChartList(id, isSavedChart);
            });
        } else {
            setChartList((prev) => {
                const newChartList = prev.filter((chart) => chart?.intermediumId !== id);
                if (newChartList[0]) newChartList[0].isSelect = true;
                return newChartList;
            });
        }
    };
    const handleCreateNewChart = () => {
        setChartList((prev) => {
            const initalDates = getPeriodForCurrentWeek(30);
            const newChart = createChartByShablon(
                new Date().getTime(),
                +campaignId,
                initalDates[0].format(OLD_PERIOD_FORMAT),
                initalDates[1].format(OLD_PERIOD_FORMAT),
            );
            const clearSelectCharts = prev?.map((item) => {
                const updatedChart = { ...item };
                updatedChart.isSelect = false;
                updatedChart.isInitial = false;
                return updatedChart;
            });
            return [newChart, ...clearSelectCharts];
        });
    };
    const handleSaveChart = (id: number, newChart: IChart) => {
        setChartList((prev) => prev.map((chart: IChart) => {
            if (chart?.intermediumId === id) {
                const formChart = formChartModel([newChart] as any);
                return {
                    ...formChart[0], isSelect: true, isInitial: true, intermediumId: chart?.intermediumId,
                };
            }
            return chart;
        }));
    };
    const handleSelectChart = (id: number) => {
        setChartList((prev) => prev.map((chart) => {
            const newChart = { ...chart };
            newChart.isSelect = false;
            if (chart?.id === id || chart?.intermediumId === id) {
                newChart.isSelect = true;
            }
            return newChart;
        }));
    };
    const getCurrentChart: IChart = useMemo(() => chartList?.find((chart) => chart?.isSelect), [chartList]);

    // mounted or Update isSaved
    useEffect(() => {
        if (!isSaved) setChartList([]);
        initializationChartList(isSaved);
    }, [isSaved]);

    return (
        <div className={styles.myCompaignInfo}>
            <ChartList
                charts={chartList}
                setPrevSettings={handleSetPrevSettings}
                onCreateChart={handleCreateNewChart}
                onRemoveChart={handleRemoveChart}
                onSaveChart={handleSaveChart}
                onSelectChart={handleSelectChart}
                onUpdateChart={handleSetChartSettings}
            />
            <ChartSettings
                chartSetting={getCurrentChart}
                campaingId={+campaignId}
                onUpdateChart={handleSetChartSettings}
                isIPadSize={isIPadSize}
                // currentPeriod={currentPeriod}
                // onUpdatePeriod={handleUpdateCurrentPeriod}
            />
        </div>
    );
};

export default CampaignNewCharts;
