import React, { useCallback, useMemo, useState } from 'react';
import { IComparisonSummaryData, IFilterCampaigns } from 'store/campaign/comparison';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import styles from './styles.module.scss';
import SvgIcon from '../../../../../SvgIcon';

interface IElement {
    name: string,
    percent: number,
    quantity: number,
    quantityStr: string,
}

interface IProps {
    tagId: string;
    title: string;
    filters: IFilterCampaigns[];
    referenceCampaign: ICampaignDataModel | null;
    compareCampaigns: ICampaignDataModel[];
    comparisonSummary: IComparisonSummaryData[];
    isSummaryComponent: boolean;
    summaryField: string;
}

const CampaignComparisonStatistics: React.FC<IProps> = ({
    tagId,
    title,
    filters,
    referenceCampaign,
    compareCampaigns,
    comparisonSummary,
    isSummaryComponent,
    summaryField,
}): JSX.Element => {
    const findCampaignAndFilters = useCallback((campaignId: number, categoryName: string): number => {
        const result = filters
            .find(({ campaignId: itemId }) => itemId === campaignId)?.filters
            .find(({ filtersCategory }) => filtersCategory === categoryName)?.totalFiltersCount;
        return result ?? 0;
    }, [filters]);
    const getQuantityFromSummary = useCallback((campaignId: number, field: string): number => {
        const buffer = (comparisonSummary.find(({ campaignId: id }) => id === campaignId)?.summary[field] as number);
        return (Number.isNaN(buffer) ? 0 : buffer);
    }, [comparisonSummary]);
    const calculatePercent = (dividend : number, divisor: number) => {
        if (divisor === 0) {
            return dividend;
        }
        return Math.round(((dividend ?? 0) / divisor) * 10000) / 100;
    };
    const arrObj = useMemo<IElement[]>(() => {
        if (!isSummaryComponent) {
            return [
                {
                    name: referenceCampaign.name,
                    percent: findCampaignAndFilters(referenceCampaign.id, title),
                    quantity: findCampaignAndFilters(referenceCampaign.id, title),
                    quantityStr: findCampaignAndFilters(referenceCampaign.id, title).toLocaleString('ru-RU'),
                },
                ...compareCampaigns.map(({ id: itemId, name }) => {
                    const quantity = findCampaignAndFilters(itemId, title) ?? 0;
                    return {
                        name,
                        percent: calculatePercent(quantity, findCampaignAndFilters(referenceCampaign.id, title)),
                        quantity,
                        quantityStr: quantity.toLocaleString('ru-RU'),
                    } as IElement;
                }),
            ];
        }
        const quantityValue = getQuantityFromSummary(referenceCampaign.id, summaryField);
        return [
            {
                name: referenceCampaign.name,
                percent: quantityValue,
                quantity: quantityValue ?? 0,
                quantityStr: quantityValue?.toLocaleString('ru-RU') ?? '-',
            },
            ...compareCampaigns.map(({ id: itemId, name }) => {
                const value = getQuantityFromSummary(itemId, summaryField);
                return {
                    name,
                    percent: calculatePercent(value, quantityValue),
                    quantity: value ?? 0,
                    quantityStr: value?.toLocaleString('ru-RU') ?? '-',
                } as IElement;
            }),
        ];
    }, [referenceCampaign, compareCampaigns, isSummaryComponent]);

    const calculationOfPercentageDifference = (percent): string => {
        if (!percent || Number.isNaN(percent)) return ' - ';
        if (!(percent - 100)) return '0%';
        if (percent - 100 < 100) return `${(percent - 100)?.toLocaleString('ru-RU')}%`;
        return `${(percent)?.toLocaleString('ru-RU')}%`;
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const calculateCoefficientForGraph = (arrObj: IElement[]) : number => {
        let max = 0;
        arrObj.forEach((element) => {
            if (element.quantity > max) max = element.quantity;
        });
        return max ? 200 / max : max;
    };

    const [isOpen, setIsOpen] = useState<boolean>(true);
    const handleKeyDown = (): void => {
        // eslint-disable-next-line no-console
    };

    return (
        <div className={styles.dropDown}>
            <div className={styles.dropDown_lineStart}>
                <div className={styles.dropDown_name}>{title}</div>
                <div
                    id={tagId}
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    onClick={() => setIsOpen(!isOpen)}
                    className={styles.dropDown_arrow}
                >
                    <div style={isOpen ? { transform: ' rotate(0)' } : { transform: ' rotate(180deg)' }}>
                        <SvgIcon id="DropDownArrow" size={17} height={12} />
                    </div>
                </div>
            </div>
            {
                isOpen && (
                    <div className={styles.dropDown_list}>
                        <div className={styles.dropDown_title}>
                            {arrObj.map((element, index) => (
                                <div
                                    key={element.name}
                                    className={styles.dropDown_cell}
                                    style={!index ? { color: '#0ebfa1' } : null}
                                >
                                    {element.name}
                                </div>
                            ))}
                        </div>
                        <div className={styles.dropDown_schedule}>
                            {
                                arrObj.map((element) => (
                                    <div key={element.percent} className={styles.dropDown_cell}>
                                        <div
                                            className={styles.dropDown_line}
                                            style={{ width: `${Math.abs(element.quantity * calculateCoefficientForGraph(arrObj))}px` }}
                                        />
                                    </div>

                                ))
                            }
                        </div>
                        <div className={styles.dropDown_info}>
                            {
                                arrObj.map((element) => (
                                    <div
                                        key={element.quantityStr}
                                        className={styles.dropDown_cell}
                                    >
                                        {element.quantityStr}
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.dropDown_percent}>
                            {
                                arrObj.map((element, index) => {
                                    if (!index) return <div className={styles.dropDown_cell} />;
                                    return (
                                        <div
                                            key={element.percent}
                                            className={styles.dropDown_cell}
                                            style={element.percent >= 100 ? { color: 'green' } : { color: 'red' }}
                                        >
                                            {calculationOfPercentageDifference(element.percent)}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default CampaignComparisonStatistics;
