import moment from 'moment';
import { DashboardType } from '../../containers/history/HistoryTable/types';
import {
    BloggerProfileCommonMetricsType,
    BloggerProfileStatisticsType,
} from '../../components/info-basket/BloggerProfile/types';

export const getRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min) + min);

// export const isObjKey = <T>(key: PropertyKey, obj: T): key is keyof T => key in obj;

export const convertToDateString = (date: number): string => {
    const m = moment(new Date(date * 1000));
    return m.locale('ru').format('DD-MM-YY');
};

export const getPastDays = (days: number): Date => {
    const result = new Date();
    result.setDate(result.getDate() - days);
    return result;
};

export const convertToPeriodString = (dateStart: number, dateEnd: number): string => {
    const days = (dateEnd - dateStart) / 86400;

    if (days === 1) {
        return '1 день';
    }
    if (days === 7) {
        return '7 дней';
    }
    if (days > 7 && days <= 30) {
        return '30 дней';
    }

    if (days > 30 && days <= 60) {
        return '60 дней';
    }

    if (days > 60 && days <= 90) {
        return '90 дней';
    }

    return '1 год';
};

// export const convertToPeriodString = (date: number, compareModel: IDateCompareModel): string => {
//     const locDate = new Date(date * 1000);
//     if (locDate >= compareModel.day) {
//         return '1 день';
//     }
//     if (locDate < compareModel.day && locDate >= compareModel.week) {
//         return '7 дней';
//     }
//     if (locDate < compareModel.week && locDate >= compareModel.month) {
//         return '30 дней';
//     }
//
//     return '60 дней';
// };

export const convertToDateTimeString = (date: number):string => {
    const m = moment(new Date(date * 1000));
    return m.locale('ru').format('HH:mm:ss DD.MM.YY');
};

export const getTimeStampBeforeDays = (days: number): number => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate.getTime() / 1000;
};

export const getCurrentSearchLink = (link: string): string => {
    if (!link) {
        return '';
    }
    const parts = link.split('?analyzedLink=');
    if (parts && parts.length > 1) {
        return parts[1];
    }

    return '';
};

export const getDashboardName = (type: DashboardType): string => {
    switch (type) {
        case DashboardType.infoBasket:
            return 'Информационная корзина';
        case DashboardType.infoTrace:
            return 'Информационный шлейф';
        default:
            return '';
    }
};

export const getRegionDeclension = (amount: number): string => {
    if (amount > 10 && amount < 20) {
        return 'регионов';
    }

    switch (amount % 10) {
        case 1:
            return 'регион';
        case 2:
        case 3:
        case 4:
            return 'региона';
        default:
            return 'регионов';
    }
};

export const getCategoryDeclension = (amount: number): string => {
    if (amount > 10 && amount < 20) {
        return 'категорий';
    }

    switch (amount % 10) {
        case 1:
            return 'категория';
        case 2:
        case 3:
        case 4:
            return 'категории';
        default:
            return 'категорий';
    }
};

export const getBloggerMetricsTypeName = (value: BloggerProfileCommonMetricsType): string => {
    switch (value) {
        case BloggerProfileCommonMetricsType.audience:
            return 'Общая аудитория';
        case BloggerProfileCommonMetricsType.engagement:
            return 'Вовлеченность';
        case BloggerProfileCommonMetricsType.likes:
            return 'Количество лайков';
        case BloggerProfileCommonMetricsType.posts:
            return 'Количество постов';
        default:
            return '';
    }
};

export const getBloggerStatisticsTypeName = (value: BloggerProfileStatisticsType): string => {
    switch (value) {
        case BloggerProfileStatisticsType.rss:
            return 'Новости';
        case BloggerProfileStatisticsType.tg:
            return 'Теlegram';
        case BloggerProfileStatisticsType.ok:
            return 'Ok.ru';
        case BloggerProfileStatisticsType.vk:
            return 'Вконтакте';
        case BloggerProfileStatisticsType.zen:
            return 'Дзен';
        default:
            return '';
    }
};

const capitalize = (value: string): string => {
    if (!(value?.length > 1)) {
        return value;
    }
    return value[0].toUpperCase() + value.slice(1);
};

const daysInMonth = (today: Date): number => new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
const getMiddleDayInMonth = (today: Date): number => new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate() / 2;

export default {
    daysInMonth,
    capitalize,
    getMiddleDayInMonth,
};
