import { ICategory, ISimpleEntity } from 'store/types';
// import { getRandomNumber } from '../../../utils/commons/commonUtils';

const convertToObject = (item: unknown, mainId: string = ''): ICategory => {
    const result: ICategory = {} as ICategory;

    Object.keys(item).forEach((key: string) => {
        if (key === 'id') {
            result[key] = mainId ? `${mainId}_${item[key]}` : item[key];
        } else if (key === 'title') {
            result.mainCat = item[key];
        } else if (key === 'smi') {
            result.media = {
                name: 'СМИ',
                value: item[key] ?? '-',
                id: result.id,
            } as ISimpleEntity;
        } else if (key === 'blg') {
            result.bloggers = {
                name: 'Блогеры',
                value: item[key] ?? '-',
                id: result.id,
            } as ISimpleEntity;
        }
    });

    // result.media = { name: 'СМИ', value: getRandomNumber(140, 950) } as ISimpleEntity;
    // result.bloggers = { name: 'Блогеры', value: getRandomNumber(140, 950) } as ISimpleEntity;

    return result;
};

export const convertApiToCategory = (data: unknown): ICategory[] => {
    const result: ICategory[] = [];
    if (!data) {
        return result;
    }

    if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
            if (item) {
                result.push(convertToObject(item));
                // if (Array.isArray(item.subcategory) && item.subcategory.length > 0) {
                //     item.subcategory.forEach((subItem) => {
                //         if (item.id) {
                //             result.push(convertToObject(subItem, item.id));
                //         }
                //     });
                // }
            }
        });
    } else {
        result.push(convertToObject(data));
    }

    return result;
};
