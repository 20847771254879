import React, { useEffect, useState } from 'react';

const Wait: React.FC = (): JSX.Element => {
    const [waitString, setWaitString] = useState('');
    const [interval, setNewInterval] = useState<any>();
    const handleInterval = () => {
        setWaitString((prev) => `${prev}.`);
    };
    useEffect(() => {
        if (waitString.length > 3) setWaitString('');
    }, [waitString]);
    useEffect(() => {
        const newInterval = setInterval(() => { handleInterval(); }, 300);
        setNewInterval(newInterval);
        return () => {
            clearInterval(interval);
            setNewInterval(null);
        };
    }, []);
    return (
        <span>
            {waitString}
        </span>
    );
};

export default Wait;
