import { useEffect, useRef, useState } from 'react';
import {
    IInitialPropsValidation, TListDescriptionsDefaultRules, TResultCortageValidation, TRuleFunction,
} from './types';

// eslint-disable-next-line max-len
const createrDefHandlers = (replaceRegExp: RegExp | null, checkRegExp: RegExp | null, error: string = 'ошибка'): TRuleFunction => (newValue, callTooltip) => {
    const replaced = newValue.replace(replaceRegExp, '');
    if (callTooltip && replaced !== newValue) callTooltip();
    return {
        status: checkRegExp.test(replaced),
        replaced,
        error,
    };
};
type TStatusWithValue = {
    value: string,
    error: string | null
};
const DEFAULT_RULES: TListDescriptionsDefaultRules = {
    phone: createrDefHandlers(/[^0-9]/g, /^((\+7|7|8)+([0-9]){10})$/, 'Вы ввели не корректный номер телефона'),
    email: createrDefHandlers(null, /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Не верный формат'),
    allNumbers: createrDefHandlers(/[^0-9.]+/g, /^-?\d+(\.\d*)?$/),
    numbers: createrDefHandlers(/[^0-9]+/g, /^-?\d+$/),
    latin: createrDefHandlers(/[а-яёА-ЯЁ]/g, /^[a-zA-Z0-9\s!@#$%^&*()_+[\]{}|;:'",.<>?-]+$/),
    ciril: createrDefHandlers(/[a-zA-Z]/g, /^[а-яё0-9\s!@#$%^&*()_+[\]{}|;:'",.<>?-]+$/i),
};
function useValidation({
    value: initialValue,
    rules,
    onlyByTrigger,
    infoWithReplace,
}: IInitialPropsValidation): TResultCortageValidation {
    const [value, setValue] = useState<string>(() => initialValue);
    const [tooltip, setTooltip] = useState<string[]>(null);
    const [errors, setErrors] = useState<string>(null);
    const timer = useRef(null);
    const chekerReducer = (acc: TStatusWithValue, rule: TRuleFunction): TStatusWithValue => {
        const {
            error,
            status,
            replaced,
        } = rule(acc.value);
        if (!replaced) {
            return { value: '', error: null };
        }
        return { value: replaced, error: !status ? error : null };
    };
    const checker = (newValue: string) => {
        if (!newValue) return newValue;
        // eslint-disable-next-line no-nested-ternary
        const newRules = !Array.isArray(rules)
            ? typeof rules === 'string' ? [DEFAULT_RULES[rules]] : [rules]
            : rules.map((rule) => (typeof rule === 'string' ? DEFAULT_RULES[rule] : rule));
        const currentStatusWithValue: TStatusWithValue = newRules.reduce(chekerReducer, { value: newValue, error: null });
        setErrors(currentStatusWithValue.error);
        return currentStatusWithValue;
    };
    useEffect(() => {
        if (errors) setErrors(null);
        if (tooltip) setTooltip(null);
        if (!onlyByTrigger) {
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => checker(value), 450);
            } else {
                timer.current = setTimeout(() => checker(value), 450);
            }
        }
    }, [value]);
    const handlerCallTooltip = () => {
        if (infoWithReplace) setTooltip([infoWithReplace]);
    };
    const rulseReducer = (acc: string, rule: TRuleFunction): string => {
        const {
            replaced,
        } = rule(acc, handlerCallTooltip);
        if (!replaced) {
            return '';
        }
        return replaced;
    };
    const onSetValue = (newValue: string) => {
        // eslint-disable-next-line no-nested-ternary
        const newRules = !Array.isArray(rules)
            ? typeof rules === 'string' ? [DEFAULT_RULES[rules]] : [rules]
            : rules.map((rule) => (typeof rule === 'string' ? DEFAULT_RULES[rule] : rule));
        const resultFormatValue = newRules.reduce(rulseReducer, newValue);
        setValue(resultFormatValue);
    };
    const onSetTooltip = (info: string[]) => {
        setTooltip(info);
    };
    const onGetCheck = () => {
        checker(value);
    };
    return [
        value,
        onSetValue,
        errors,
        tooltip,
        onSetTooltip,
        onGetCheck,
    ];
}

export default useValidation;
