import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonDocumentCard = (): JSX.Element => (
    <div style={{
        height: 82,
        width: 72,
        position: 'relative',
    }}
    >
        <Skeleton
            height={32}
            width={32}
            style={{
                position: 'absolute',
                top: 0,
                borderRadius: '5px',
            }}
        />
        <Skeleton
            height={12}
            width={72}
            style={{
                position: 'absolute',
                top: 46,
                borderRadius: '5px',
            }}
        />
        <Skeleton
            height={12}
            style={{
                position: 'absolute',
                top: 68,
                borderRadius: '5px',
            }}
        />
    </div>
);

export default SkeletonDocumentCard;
