import CardPlotSkeleton from '../../../../../shared/CardPlotSkeleton';

const MyCompaignPlotListSkeleton : React.FC = (): React.ReactElement => (
    <div>
        <CardPlotSkeleton />
        <CardPlotSkeleton />
        <CardPlotSkeleton />
    </div>
);
export default MyCompaignPlotListSkeleton;
