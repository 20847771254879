import TextField from '@entities/TextField';
import { useState } from 'react';
import TextArea from '@entities/TextArea';
import styles from './styles.module.scss';

const TextFieldDemo : React.FC = (): React.ReactElement => {
    const [value, setValue] = useState<string>('');
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <h1>Текстовое поле</h1>
                <div className={styles.block}>
                    <TextField value={value} onChange={setValue} label="Заголовок" />
                    <TextField value={value} onChange={setValue} label="Заголовок и placeholder" placeholder="placeholder" />
                </div>

                <div className={styles.block}>
                    <TextField value="value" onChange={setValue} label="Крестик" />
                    <TextField value="value" onChange={setValue} label="Disabled" disabled />
                </div>

                <div className={styles.block}>
                    <TextField value="value" onChange={setValue} label="Ограничение по кол-ву" limit={10} />
                    <TextField value="value" onChange={setValue} label="Readonly" readonly />
                </div>

                <div className={styles.block}>
                    <TextField value="value" onChange={setValue} label="Невалидный" errorText="Текст ошибки" />
                    <TextField value="value" onChange={setValue} label="Предупреждение" warningText="Текст предупреждения" />
                </div>

                <div className={styles.block}>
                    <TextField value="" onChange={setValue} label="Поиск" isSearch />
                </div>

                <div className={styles.block}>
                    <TextField value="value" onChange={setValue} label="Medium size" size="medium" />
                    <TextField value="value" onChange={setValue} label="Small size" size="small" />
                </div>
            </div>

            <div className={styles.right}>
                <h1>Текстовая область</h1>

                <div className={styles.block}>
                    <TextArea value="" onChange={() => {}} label="Заголовок" />
                    <TextArea value="" onChange={() => {}} label="Заголовок и placeholder" placeholder="placeholder" />
                </div>

                <div className={styles.block}>
                    <TextArea value="value" onChange={() => {}} label="Лимит" />
                    <TextArea value="value" onChange={() => {}} label="Disabled" disabled />
                </div>

                <div className={styles.block}>
                    <TextArea value="value" onChange={() => {}} label="Readonly" readonly />
                </div>
            </div>
        </div>
    );
};
export default TextFieldDemo;
