/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
    IQPSourcesWithSortingAndFiltering, INumbersInterval, RangeFilter, ISourceRow,
} from '@services/sources/types';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Path from '@shared/lib/paths';
import { useNavigate } from 'react-router';
import { ISimpleEntity } from '@store/types';
import { useAppDispatch } from '@store/hooks';
import { categoriesRtkQService } from '@services/categories';
import { sourcesRtkQService } from '@services/sources';
import AddSourceModal from '@components/sources/AddSource';
import SourcesTopPanel from '@components/sources/TopPanel';
import AdvancedSearch from '@widgets/sources/sidepage/AdvancedSearch';
import sourceListSelectors from '@store/source/list/selectors';
import { sourceListActions } from '@store/source/list';
import sourceMaps from '@app/constants/sourceMaps';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TabelSources from '@features/source/TabelSources';
import { Button, SvgIcon } from '@shared/index';
import { ITestRow } from '@features/source/TabelSources/types';
import { useSelector } from 'react-redux';
import { deepCopy } from '../../../utils/helpers/deepCopy';
import styles from './styles.module.scss';

interface IPropsModalAddSources {
    isOpen: boolean,
    favoritId: number,
    exceptionsIds?: number[],
    success: (content: string) => void,
    error: (content: string) => void,
    close: () => void,
    onAddSources: (ids: number[]) => void,
}
const ModalAddSources: React.FC<IPropsModalAddSources> = ({
    isOpen,
    favoritId,
    success,
    error,
    exceptionsIds,
    close,
    onAddSources,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [updateSources] = sourcesRtkQService.useAddSourcesToListsMutation();
    const [removeSourcesFromList] = sourcesRtkQService.useRemoveSourcesFromListMutation();
    const [selectedSources, setSelectedSource] = useState<number[]>([]);
    const { data: lists } = sourcesRtkQService.useGetListsQuery();
    const [getSources, { isFetching, isLoading }] = sourcesRtkQService.useLazyPostLoadSourcesInfinityQuery();

    const [isModal, setIsModal] = useState<boolean>(false);
    const [activeSidePage, setActiveSidePage] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchValueTopPanel, setSearchValueTopPanel] = useState<string>('');
    const [nextToken, setNextToken] = useState<string>(null);
    const [sources, setSources] = useState<ISourceRow[]>([]);
    const currentSortingAndFilters = useSelector(sourceListSelectors.activeFilters);

    const [advancedFilters, setFilters] = useState<IQPSourcesWithSortingAndFiltering>(
        deepCopy(sourceMaps.initialFilters),
    );
    const [
        searchSourceTopPanel,
        {
            data: searchResultTopPanel,
            isLoading: isLoadingTopPanel,
            isFetching: isFetchingTopPanel,
            isError: isErrorTopPanel,
        },
    ] = sourcesRtkQService.useLazyGetSearchSourceQuery();
    const {
        data: platforms,
        isFetching: isFetchingPlatforms,
        isLoading: isLoadingPlatforms,
    } = sourcesRtkQService.useGetLoadPlatformsQuery();
    const {
        data: categories,
        isLoading: isLoadingCategories,
        isFetching: isFetchingCategories,
    } = categoriesRtkQService.useGetCategoriesQuery();
    const {
        data: filtersRanges,
        isLoading: isLoadingRanges,
        isFetching: isFetchingRanges,
        isError: isErrorRanges,
    } = sourcesRtkQService.useGetFiltersRangesQuery();
    const sourcesTopPanel = useMemo(() => {
        if (isLoadingTopPanel || isFetchingTopPanel) {
            return [];
        }
        return (isErrorTopPanel || searchValueTopPanel.length <= 3)
            ? []
            : searchResultTopPanel?.map((item) => ({
                id: item?.id, name: item?.title, value: item?.link,
            } as ISimpleEntity));
    }, [searchResultTopPanel, isErrorTopPanel, searchValueTopPanel, isLoadingTopPanel, isFetchingTopPanel]);

    const handlerStartSearchForTopPanel = (value: string): void => {
        searchSourceTopPanel({ limit: 15, substring: value });
    };

    const handlerSelectFilter = useCallback((ids: number[], filterName: string): void => {
        const newFilters = {
            ...advancedFilters,
            filterParams: { ...advancedFilters?.filterParams, [filterName]: ids },
            page: 1,
        } as IQPSourcesWithSortingAndFiltering;
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerSelectRangeFilter = useCallback((range: INumbersInterval | null, filterName: string): void => {
        let newFilters: IQPSourcesWithSortingAndFiltering = {
            ...advancedFilters,
            rangeParams: { ...advancedFilters?.rangeParams, [filterName]: range },
            page: 1,
        };
        if (range === null) {
            const ranges = null;
            Object.keys(newFilters.rangeParams).forEach((key) => {
                if (newFilters.rangeParams[key] !== null) {
                    ranges[key] = newFilters.rangeParams[key];
                }
            });
            newFilters = { ...newFilters, rangeParams: (ranges === null) ? null : ranges };
        }
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerSelectSource = (sourceId: number): void => {
        if (sourceId > 0) {
            navigate(`/${Path.Sources}/${sourceId}`);
        }
    };

    const handlerApplyAdvancedSearch = useCallback((ranges: RangeFilter, title: string): void => {
        const newFilters = {
            ...advancedFilters,
            page: 1,
            rangeParams: {},
            searchValue: title,
            limit: 50,
        };
        setSearchValue(title);
        dispatch(sourceListActions.setFilters(newFilters));
    }, [advancedFilters, nextToken]);

    const handleAddSourceToList = (list_ids: number[], sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        updateSources({
            list_ids,
            source_ids: [sourceId],
        }).then(() => {
            // setSources((prev) => [...prev.map((source) => (
            //     { ...source, title: 'HUE', lists: sourceId === source.id ? [...source.lists, ...list_ids] : source.lists }))]);
            setSources((prev) => {
                const clone = [...prev];
                const updated = clone.map((source) => ({
                    ...source,
                    lists: sourceId === source.id ? [...source.lists, ...list_ids] : source.lists,
                }));
                return updated;
            });
            success(`${title} успешно добавлен в выбранные списки`);
        }).catch((err) => {
            // message.error('При добавлении в избранное произошла ошибка');
            error(`При добавлении ${title} в списки произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При добавлении в списки произошла ошибка', err);
        });
    };
    const handleAddFavorite = (sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        if (typeof favoritId !== 'number') return;
        updateSources({
            list_ids: [favoritId],
            source_ids: [sourceId],
        }).then(() => {
            setSources((prev) => prev.map((source) => (
                { ...source, lists: sourceId === source.id ? [...source.lists, favoritId] : source.lists })));
            success(`${title} успешно добавлен в избранное`);
        }).catch((err) => {
            // message.error('При добавлении в избранное произошла ошибка');
            error(`При добавлении ${title} в избраное произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При добавлении в избраное произошла ошибка', err);
        });
    };
    const handleRemoveFromFavorite = (sourceId: number) => {
        const { title } = sources.find(({ id }) => id === sourceId);
        if (typeof favoritId !== 'number') return;
        removeSourcesFromList({
            list_id: favoritId,
            source_ids: [sourceId],
        }).then(() => {
            setSources((prev) => prev.map((source) => (
                { ...source, lists: sourceId === source.id ? source.lists.filter((id) => id !== favoritId) : source.lists })));
            success(`${title} успешно убран из избраного`);
        }).catch((err) => {
            error(`При удалении ${title} из избраного произошла ошибка`);
            // eslint-disable-next-line no-console
            console.log('При удалении из избранного произошла ошибка', err);
        });
    };
    const handleGetNextSource = () => {
        const newFilters = {
            ...advancedFilters,
            page: (advancedFilters?.page || 1) + 1,
            limit: 50,
            prevPageToken: nextToken,
            filtersRanges: null,
        };
        dispatch(sourceListActions.setFilters(newFilters));
    };
    const getLists = useMemo(() => {
        const filtred = lists?.filter(({ id }) => id !== favoritId) || [];
        return filtred;
    }, [lists]);
    // COMPONENT_MOUNT
    useEffect(() => {
        if (!sources.length) {
            getSources({
                ...currentSortingAndFilters,
                filterParams: {},
                rangeParams: {},
                limit: 50,
            }).then((res) => {
                if (res?.data) {
                    setSources(res.data.sources);
                    setNextToken(res.data.nextPageToken);
                }
            });
        } else if (currentSortingAndFilters.page === 1) {
            getSources({
                ...currentSortingAndFilters,
                filterParams: {},
                rangeParams: {},
                limit: 50,
            }).then((res) => {
                if (res?.data) {
                    setSources(res.data.sources);
                    setNextToken(res.data.nextPageToken);
                }
            });
        } else {
            getSources({
                ...currentSortingAndFilters,
                filterParams: {},
                rangeParams: {},
                limit: 50,
            }).then((res) => {
                if (res?.data) {
                    setSources((prev) => [...prev, ...res.data.sources]);
                    setNextToken(res.data.nextPageToken);
                }
            });
        }
    }, [currentSortingAndFilters]);
    const handleChangeSelectedSource = ({ id }: ITestRow) => {
        if (!selectedSources.some((idCurrent) => id === idCurrent)) {
            setSelectedSource((prev) => [...prev, id]);
            return;
        }
        setSelectedSource((prev) => prev.filter((idCurrent) => idCurrent !== id));
    };
    useEffect(() => () => {
        dispatch(sourceListActions.clear());
        setSources([]);
        setNextToken('');
    }, []);
    if (!isOpen) return null;
    return (
        <div className={styles.modalAddSources_root}>
            <div
                role="button"
                onClick={close}
                className={styles.modalAddSources_overlay}
            />
            <div className={styles.modalAddSources}>
                <div
                    role="button"
                    onClick={close}
                    className={styles.closeButton}
                >
                    <SvgIcon id="NewClose" size={18} />
                </div>
                {isModal && <AddSourceModal setIsModal={setIsModal} />}
                {activeSidePage && (
                    <AdvancedSearch
                        currentFilters={advancedFilters}
                        activeSidePage={activeSidePage}
                        setActiveSidePage={setActiveSidePage}
                        platforms={platforms}
                        categories={categories}
                        isLoadingPlatforms={isLoadingPlatforms || isFetchingPlatforms}
                        isLoadingCategories={isLoadingCategories || isFetchingCategories}
                        platformsIcon={sourceMaps.platformsIcon}
                        platformByIdMap={sourceMaps.platformByIdMap}
                        searchValue={searchValue}
                        onSelectFilter={handlerSelectFilter}
                        onSelectRangeFilters={handlerSelectRangeFilter}
                        onApply={handlerApplyAdvancedSearch}
                        ranges={filtersRanges}
                    />
                )}
                <SourcesTopPanel
                    isDarkSearch
                    isDisabledAddSource
                    searchValue={searchValueTopPanel}
                    isLoading={isLoadingTopPanel || isFetchingTopPanel}
                    setIsModal={setIsModal}
                    setSearchValue={setSearchValueTopPanel}
                    sources={sourcesTopPanel}
                    activeSidePage={activeSidePage}
                    setActiveSidePage={setActiveSidePage}
                    onSelectSource={handlerSelectSource}
                    onStartSearch={handlerStartSearchForTopPanel}
                />
                <div className={styles.modalAddSources_table}>
                    <TabelSources
                        listSources={sources}
                        lists={getLists}
                        isLoading={isFetching || isLoading}
                        favoriteId={favoritId}
                        isDisabledInfinityScroll={!nextToken}
                        listExceptedIds={exceptionsIds}
                        onAddSourceToList={handleAddSourceToList}
                        onAddFavorite={handleAddFavorite}
                        onRemoveFromFavorite={handleRemoveFromFavorite}
                        onGetNextSource={handleGetNextSource}
                        onChangeSelected={handleChangeSelectedSource}
                    />
                </div>
                <div className={styles.modalAddSources_controls}>
                    <Button
                        text="Добавить в список"
                        type="default"
                        size="large"
                        onClick={() => onAddSources(selectedSources)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ModalAddSources;
