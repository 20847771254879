import React, { useMemo } from 'react';

interface IPropsWrapperTooltip {
    isOpen: boolean,
    x: number,
    y: number,
    clearTimerTooltip: () => void
    hideTooltip: () => void
}
const TooltipWrapper: React.FC<IPropsWrapperTooltip> = ({
    isOpen, x, y, clearTimerTooltip, hideTooltip, children,
}) => {
    const getLeft = useMemo(() => `${x + 16}px`, [x]);
    const getTop = useMemo(() => `${y + 12}px`, [y]);
    if (!isOpen) return <div />;
    return (
        <div
            onMouseMove={() => clearTimerTooltip()}
            onMouseLeave={() => hideTooltip()}
            style={{
                zIndex: 2, position: 'absolute', left: getLeft, top: getTop,
            }}
        >
            {children}
        </div>
    );
};

export default TooltipWrapper;
