import React from 'react';
import { checkCookies } from 'cookies-next';
import { Navigate, useOutlet } from 'react-router-dom';
import Path from '../../shared/lib/paths';
import { AUTH_COOKIE_NAME } from '../../utils/constants';

const NonAuthLayout: React.FC = (): JSX.Element => {
    const outlet = useOutlet();
    const isAuth = checkCookies(AUTH_COOKIE_NAME);

    if (isAuth) {
        return <Navigate to={Path.Campaign} />;
    }
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {outlet}
        </>
    );
};

export default NonAuthLayout;
