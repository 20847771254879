import React from 'react';
import { Pie, measureTextWidth } from '@ant-design/plots';
import Skeleton from 'react-loading-skeleton';

interface IPropsPlatformsChart {
    campaignId: number,
    platforms: any
}

const CampaignDnDPlatformsChart: React.FC<IPropsPlatformsChart> = ({ platforms }): JSX.Element => {
    function renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(R ** 2 / ((textWidth / 2) ** 2 + textHeight ** 2))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const config = {
        appendPadding: 10,
        data: platforms,
        angleField: 'value',
        colorField: 'name', // or seriesField in some cases
        color: (data) => {
            const { name } = data;
            const listColors = {
                ok: '#E67E22',
                vk: '#0077FF',
                tg: '#2F9FE0',
                rss: '#0EBFA1',
                dzen: '#FFCC01',
            };
            if (listColors[name]) return listColors[name];
            return '#E8F1F9';
        },
        radius: 0.6,
        innerRadius: 0.54,
        meta: {
            value: {
                formatter: (v) => `${v}`,
            },
        },
        label: {
            type: 'outer',
            offset: 26,
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            content: '{value}',
        },
        statistic: {
            title: {
                offsetY: 2,
                customHtml: (container, view, datum) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt((width / 2) ** 2 + (height / 2) ** 2);
                    const text = datum ? (datum as any).name : 'Всего';
                    return renderStatistic(d + 15, text, {
                        fontSize: 10,
                    });
                },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '14px',
                },
                customHtml: (container, view, datum, data) => {
                    const { width } = container.getBoundingClientRect();
                    const text = datum ? `${datum.value}` : `${data.reduce((r, d) => r + d.value, 0)}`;
                    return renderStatistic(width, text, {
                        fontSize: 14,
                    });
                },
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    };
    if (!platforms?.length) {
        return (
            <div style={{
                width: 334, height: 232, background: 'white', borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
                <Skeleton height={232 - 16} width={334 - 16} />
            </div>
        );
    }

    return (
        <div style={{
            width: 334, height: 232, background: 'white', borderRadius: 6,
        }}
        >
            <Pie {...config as any} />
        </div>
    );
};

export default CampaignDnDPlatformsChart;
