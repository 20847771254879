import { NewsSourceType } from '../../../containers/info-basket/subpages/results/ResultTable/types';

export enum BloggerProfileCommonMetricsType {
    audience = 0,
    posts = 1,
    likes = 2,
    engagement = 3,
}

export enum BloggerProfileStatisticsType {
    rss = 0,
    tg = 1,
    ok = 2,
    vk = 3,
    zen = 4,
}

export interface IBloggerProfileCommonMetricsModel {
    type: BloggerProfileCommonMetricsType;
    value?: number;
}

export interface IBloggerProfileStatisticsModel {
    type: BloggerProfileStatisticsType;
    value?: number;
}

export interface IBloggerProfilePublicationsModel {
    id: number;
    content: string;
    img: object;
    date: string;
    time: string;
    link?: string;
    postedTimestamp: number;
}

export interface IBloggerProfileModel {
    name: string;
    type: NewsSourceType;
    isContract: boolean;
    actualDate: string;
    actualTime: string;
    logoLink: string;
    metrics: IBloggerProfileCommonMetricsModel[],
    statistics: IBloggerProfileStatisticsModel[],
    publications: IBloggerProfilePublicationsModel[],
}

export interface IBloggerProfileProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    data: IBloggerProfileModel;
    isLoadingData: boolean;
    isLoadingPublications: boolean;
    addVisiblePublication: () => void;
    isMaxPublicationsAmount: boolean;
    isRetinaSize: boolean;
}
