import { AppState } from 'store/store';
import { IPDFReportSummary } from 'store/pdf-handler/wrapper/types';

const getPeriod = (value: string): string => {
    switch (value.toLowerCase()) {
        case 'week':
            return '7';
        case 'month':
            return '30';
        default:
            break;
    }
    return value;
};
const pdfHandlerWrapperState = (state: AppState) => state.pdfHandler.wrapper;
const document = (state: AppState) => pdfHandlerWrapperState(state).document;
const pdfWrapperDownload = (state: AppState) => pdfHandlerWrapperState(state).pdfWrapperDownload;
const fileName = (state: AppState) => pdfHandlerWrapperState(state).fileName;
const reportId = (state: AppState) => pdfHandlerWrapperState(state).reportId;
const campaignId = (state: AppState) => pdfHandlerWrapperState(state).campaignId;
const reportName = (state: AppState) => pdfHandlerWrapperState(state).reportName;
const processing = (state: AppState) => pdfHandlerWrapperState(state).processing;
const timeFrame = (state: AppState) => pdfHandlerWrapperState(state).timeFrame;
const summaryData = (state: AppState) => pdfHandlerWrapperState(state).summaryData;
const chartsIds = (state: AppState) => pdfHandlerWrapperState(state).chartsIds;
const summaryDataFetchStatus = (state: AppState) => pdfHandlerWrapperState(state).summaryStatusFetch;
const regionData = (state: AppState) => pdfHandlerWrapperState(state).regionData;
const getSourcesType = (state: AppState) => pdfHandlerWrapperState(state).sourcesData;
const rssData = (state: AppState) => pdfHandlerWrapperState(state).rssData;
const authorData = (state: AppState) => pdfHandlerWrapperState(state).authorData;
const additionalData = (state: AppState) => pdfHandlerWrapperState(state).additionalData;
const getTags = (state: AppState) => pdfHandlerWrapperState(state).tagList;
const getCategoryData = (state: AppState) => pdfHandlerWrapperState(state).categoryData;
const getAuthorsData = (state: AppState) => pdfHandlerWrapperState(state).authorData;
const chartList = (state: AppState) => pdfHandlerWrapperState(state).chartList;
const reportCreateStatus = (state: AppState) => pdfHandlerWrapperState(state).reportCreateStatus;
const summaryDataByCampaignIdAndPeriod = (state: AppState, campId: number, period: string): IPDFReportSummary => pdfHandlerWrapperState(state)
    .summaryData.find(
        ({ campaignId: id, period: localPeriod }) => (
            id === campId && localPeriod === getPeriod(period)
        ),
    );

export default {
    document,
    pdfWrapperDownload,
    fileName,
    reportId,
    campaignId,
    reportName,
    getTags,
    processing,
    timeFrame,
    summaryData,
    chartsIds,
    summaryDataFetchStatus,
    getCategoryData,
    getAuthorsData,
    chartList,
    getSourcesType,
    regionData,
    rssData,
    authorData,
    additionalData,
    summaryDataByCampaignIdAndPeriod,
    reportCreateStatus,
};
