import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

interface IProps {
    isLoading: boolean;
    neutral: number;
    positive: number;
    negative: number;
}
const CampaignDnDTonality: React.FC<IProps> = ({
    isLoading,
    neutral,
    negative,
    positive,
}): JSX.Element => {
    const amountQuantity = useMemo<number>(() => neutral + negative + negative, [neutral, negative, negative]);
    const negativePercent = useMemo<number>(
        () => Math.round((negative / amountQuantity) * 100),
        [negative, amountQuantity],
    );
    const neutralPercent = useMemo<number>(
        () => Math.round((neutral / amountQuantity) * 100),
        [neutral, amountQuantity],
    );
    const positivePercent = useMemo<number>(
        () => Math.round((positive / amountQuantity) * 100),
        [positive, amountQuantity],
    );

    return (
        <div className={styles.campaignDnDTonality_root}>
            <div className={styles.tonality_title}>
                Тональность
            </div>
            {
                isLoading && (
                    <div className={styles.tonality}>
                        <Skeleton height={20} width={180} />
                        <Skeleton height={20} width={180} />
                        <Skeleton height={20} width={180} />
                    </div>
                )
            }
            {
                !isLoading && amountQuantity > 0 && (
                    <div className={styles.tonality}>
                        <div className={styles.tonality_row}>
                            <span className={styles.tonality_name} style={{ color: '#F36869' }}>Негативно</span>
                            <div className={styles.tonality_chart}>
                                <div className={styles.tonality_bar} style={{ width: negativePercent, background: '#F36869' }} />
                            </div>
                            <span className={styles.tonality_quantity}>{negative}</span>
                        </div>
                        <div className={styles.tonality_row}>
                            <span className={styles.tonality_name} style={{ color: '#E67E22' }}>Нейтрально</span>
                            <div className={styles.tonality_chart}>
                                <div className={styles.tonality_bar} style={{ width: neutralPercent, background: '#E67E22' }} />
                            </div>

                            <span className={styles.tonality_quantity}>{neutral}</span>
                        </div>
                        <div className={styles.tonality_row}>
                            <span className={styles.tonality_name} style={{ color: '#81D42F' }}>Позитивно</span>
                            <div className={styles.tonality_chart}>
                                <div className={styles.tonality_bar} style={{ width: positivePercent, background: '#81D42F' }} />
                            </div>
                            <span className={styles.tonality_quantity}>{positive}</span>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default CampaignDnDTonality;
