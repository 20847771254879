/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
    useState, useMemo, useRef,
} from 'react';
import { MainCategory } from '../Types';
import styles from './styles.module.scss';
import { useResizeObserver } from '../../../utils/hooks/useResizeObserver';
import SvgIcon from '../../SvgIcon';

interface IProps {
    category: MainCategory,
    handleClickOnCategory: (category: MainCategory) => void,
}
const Category: React.FC<IProps> = ({ category, handleClickOnCategory }) => {
    const containerRef = useRef(null);
    const labelRef = useRef(null);
    const percentRef = useRef(null);
    const [timeoutEnterCategory, setTimeoutEnterCategory] = useState(null);
    const [onHoveredCategory, setOnHoveredCategory] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [widthContainer, heightContainer] = useResizeObserver(containerRef);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [widthPercent, heightPercent] = useResizeObserver(percentRef);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [widthLabel, heigthLabel] = useResizeObserver(labelRef);
    const getWidthCategory = useMemo(() => {
        const defaultWidth = `${category.percent}%`;
        return onHoveredCategory ? `calc(${defaultWidth} + ${widthLabel + 70}px)` : defaultWidth;
    }, [onHoveredCategory]);
    const isHideLabelBySmall = useMemo(() => widthContainer <= 30, [widthContainer]);
    const getStylesForCategory = useMemo(() => ({
        background: category.color,
        width: getWidthCategory,
        minWidth: '10px',
    }), [category, onHoveredCategory]);
    const onMouseEnterCategory = () => {
        if (!timeoutEnterCategory) {
            const timeout = setTimeout(() => setOnHoveredCategory(true), 100);
            setTimeoutEnterCategory(timeout);
        }
    };
    const onMouseLeaveCategory = () => {
        if (timeoutEnterCategory) {
            clearTimeout(timeoutEnterCategory);
            setTimeoutEnterCategory(null);
            setOnHoveredCategory(false);
            return null;
        }
        setOnHoveredCategory(false);
        return null;
    };
    const getSubcategoriesCounter = useMemo(() => (
        category?.subCategories?.length || category?.isBack ? (
            <span className={styles.subCategoriesCounter}>
                {`(${category?.isBack ? category?.lengthForBack : category?.subCategories?.length})`}
            </span>
        )
            : ''
    ), [category, category?.subCategories, isHideLabelBySmall]);
    const getCategoryName = useMemo(() => (isHideLabelBySmall
        ? ''
        : (
            <div className={styles.categoryLabel}>
                {category.isBack
                    ? (
                        <span style={{ fill: onHoveredCategory ? 'white' : 'black', marginRight: '4px' }}>
                            <SvgIcon id="BackArrow" size={14} />
                        </span>
                    ) : ''}
                {category.name}
                {getSubcategoriesCounter}
                {category.isSubCategories
                    ? (
                        <span style={{ fill: onHoveredCategory ? 'white' : 'black', marginLeft: '4px' }}>
                            <SvgIcon id="NextArrow" size={10} />
                        </span>
                    ) : ''}
            </div>
        )), [isHideLabelBySmall, widthContainer]);
    const isShowCategoryPercent = useMemo(() => widthContainer >= widthPercent + 24, [widthContainer]);
    const getCategoryPercent = useMemo(() => (isShowCategoryPercent ? (
        <div className={styles.categoryPercent}>
            {`${category.isBack ? category.percentForBack : category.percent}%`}
        </div>
    ) : ''), [widthContainer]);
    return (
        <div
            ref={containerRef}
            style={getStylesForCategory}
            className={styles.categoryContainer}
            onMouseEnter={onMouseEnterCategory}
            onMouseLeave={onMouseLeaveCategory}
            onClick={() => handleClickOnCategory(category)}
        >
            <div ref={percentRef} className={styles.hiddenPercent}>{`${category.percent}%`}</div>
            <div ref={labelRef} className={styles.hiddenLabel}>{`${category.name}`}</div>
            {getCategoryName}
            {getCategoryPercent}
        </div>
    );
};

export default Category;
