import { favoriteSourcesActions } from '../../../store/campaign/favorite-sources';
import { rtkQApi } from '../../index';
import { IFavoriteSourceModel, IQueryParamsCreateSource } from './types';

export const favoriteSourcesRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getFavoriteSources: build.query<IFavoriteSourceModel[], void>({
            query: () => ({
                url: 'campaign/favorite_sources/',
                method: 'GET',
            }),
            async onQueryStarted(args: void, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(favoriteSourcesActions.setFavoriteSources(data));
                    });
            },
        }),
        postCreateFavoriteSource: build.query<IFavoriteSourceModel, IQueryParamsCreateSource>({
            query: (body) => ({
                url: 'campaign/favorite_sources/',
                method: 'POST',
                body,
            }),
        }),
        deleteFavoriteSource: build.query<void, { id: number }>({
            query: (params) => ({
                url: `campaign/favorite_sources/${params.id}`,
                method: 'DELETE',
            }),
        }),
    }),
});
