import { IDictionary } from 'store/types';
import { IBloggerCommonModel, IBloggerPublicationModel } from 'services/authors/types';

export const convertApiToBloggerCommonData = (data: unknown): IBloggerCommonModel => {
    if (!data) {
        return {} as IBloggerCommonModel;
    }

    const result = {} as IBloggerCommonModel;

    try {
        Object.keys(data).forEach((key: string) => {
            if (key === 'id') {
                result.id = data[key];
            } else if (key === 'title') {
                result.title = data[key];
            } else if (key === 'is_blogger') {
                if (data[key]) {
                    result.isBlogger = Boolean(data[key]);
                }
            } else if (key === 'views') {
                result.views = Number(data[key]);
            } else if (key === 'subscribers') {
                result.subscribers = Number(data[key]);
            } else if (key === 'posts_count') {
                result.posts = Number(data[key]);
            } else if (key === 'logo_link') {
                result.logoLink = data[key];
            } else if (key === 'forwards') {
                result.forwards = Number(data[key]);
            } else if (key === 'comments') {
                result.comments = Number(data[key]);
            } else if (key === 'likes') {
                result.likes = Number(data[key]);
            } else if (key === 'last_update') {
                if (data[key]) {
                    const date = new Date(data[key]);
                    result.lastUpdateDate = date.toLocaleDateString('ru-RU');
                    result.lastUpdateTime = date.toLocaleTimeString('ru-RU');
                    result.lastUpdateTimestamp = date.getTime() / 1000;
                }
            } else if (key === 'categories') {
                if (data[key]) {
                    result.categories = (
                        Object.keys(data[key]).reduce(
                            (acc: IDictionary<number>, cur: string) => {
                                acc[cur] = data[key][cur];
                                return acc;
                            },
                            {} as IDictionary<number>,
                        )
                    );
                }
            } else if (key === 'source_type_posts_count') {
                if (data[key]) {
                    result.sourceTypePostsAmount = (
                        Object.keys(data[key]).reduce(
                            (acc: IDictionary<number>, cur: string) => {
                                acc[cur] = data[key][cur];
                                return acc;
                            },
                            {} as IDictionary<number>,
                        )
                    );
                }
            }
        });
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error);
    }

    return result;
};

export const convertApiToBloggerPublicationData = (data: unknown): IBloggerPublicationModel[] => {
    if (!data || !Array.isArray(data)) {
        return [] as IBloggerPublicationModel[];
    }

    const result = [] as IBloggerPublicationModel[];

    try {
        data.forEach((item) => {
            const publication = {} as IBloggerPublicationModel;
            Object.keys(item).forEach((key: string) => {
                if (key === 'id') {
                    publication.id = item[key];
                } else if (key === 'content') {
                    publication.content = item[key];
                } else if (key === 'out_post_id') {
                    publication.outPostId = item[key];
                } else if (key === 'parsed') {
                    publication.parsedDateTime = item[key];
                } else if (key === 'posted') {
                    if (item[key]) {
                        const date = new Date(item[key]);
                        publication.postedDate = date.toLocaleDateString('ru-RU');
                        publication.postedTime = date.toLocaleTimeString('ru-RU');
                        publication.postedTimestamp = date.getTime() / 1000;
                    }
                } else if (key === 'post_link') {
                    publication.link = item[key];
                } else if (key === 'source_id') {
                    publication.sourceId = item[key];
                } else if (key === 'title') {
                    publication.title = item[key];
                }
            });
            result.push(publication);
        });
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error);
    }

    return result;
};
