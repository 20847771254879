import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import classnames from 'classnames';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { ICategory } from '@store/types';
import { Button } from '@shared/index';
import { ICampaignCategoryModel } from '../../../UpdateForm/CategorySelect/types';
import campaignUtils from '../../../../../utils/campaign';
import CampaignSwitcher from '../../../UpdateForm/Switcher';
import CampaignTrackedWordsBlock from '../../../UpdateForm/TrackedWords';
import { ITrackedWordModel } from '../../../UpdateForm/TrackedWords/types';
import ValidationTooltip from '../../../Validation';
import CampaignCategorySelect from '../../../UpdateForm/CategorySelect';
import CampaignNameInput from '../../../UpdateForm/NameInput';
import { ICampaignSimpleEntity } from '../../../UpdateForm/Sources/SourceTypes/types';
import CampaignSourcesBlock from '../../../UpdateForm/Sources';

import styles from './styles.module.scss';

interface IProps {
    isLoading: boolean;
    isDefaultExcludedLinks: boolean;
    selectedPeriodId: string;
    categories: ICategory[];
    selectedCategoriesIds: number[];
    onCreateClick: (data: any) => void;
    onDeleteTrackedSource: (isTracked: boolean, id: number) => void;
    isIPadSize: boolean;
    setCurrentModalWindow: (name: string) => void,
    campaign: ICampaignDataModel,
    onSelectedFavoriteSources: (value: ITrackedWordModel[]) => void;
    isLoadingCategories: boolean;
}

const topComponentsHeight = 132;

const prepareSource = (data: string[]): string[] => {
    if (data?.length <= 0) {
        return undefined;
    }
    if (data?.length === 1 && data[0] === 'ALL') {
        return null;
    }
    return data.filter((item) => item !== 'ALL');
};
const prepareCategories = (data: number[], categories: ICategory[]): ICampaignCategoryModel[] => {
    if (!categories || !categories?.length || !data || !data?.length) {
        return [];
    }
    return data
        .map((id) => {
            const res = categories.find((item) => Number(item.id) === id);
            return { id: Number(res?.id), name: res?.mainCat };
        });
};
const getBloggerValue = (checkedIds: number[]): boolean | null | undefined => {
    if (!checkedIds?.length) {
        return undefined;
    }
    if (checkedIds.some((id) => id === 1)) {
        return null;
    }
    if (checkedIds.some((id) => id === 2) && checkedIds.some((id) => id === 3)) {
        return null;
    }
    if (checkedIds.some((id) => id === 2)) {
        return false;
    }
    if (checkedIds.some((id) => id === 3)) {
        return true;
    }
    return undefined;
};
const bloggersBoolToArray = (value: boolean | null): number[] => {
    if (value === null) {
        return [1, 2, 3];
    }
    if (value === undefined) {
        return [];
    }
    return value ? [3] : [2];
};
const sourcesStringToNumber = (data: string[]): number[] => {
    const result = data
        .map((name) => Number(campaignUtils.campaignPlatformsTypeState
            .find((item) => item.name === name)?.id))
        .filter((value) => Boolean(value));
    return result;
};

const CreateFromReferenceCampaign: React.FC<IProps> = ({
    isLoading, isDefaultExcludedLinks, selectedPeriodId,
    categories, selectedCategoriesIds, onCreateClick,
    onDeleteTrackedSource, isIPadSize,
    setCurrentModalWindow, campaign,
    onSelectedFavoriteSources, isLoadingCategories,
}): JSX.Element => {
    const { ref, height: rootHeight } = useResizeObserver<HTMLDivElement>();

    const [isErrorHighlighting, setHighlighting] = useState<boolean>(false);
    const [selectedCategories, setSelectedCategories] = useState<ICampaignCategoryModel[]>([]);
    const [campaignName, setCampaignName] = useState<string>('');
    const [isBlogger, setIsBlogger] = useState<boolean>(campaign.isBlogger);
    const [sourceType, setSourceType] = useState<string[]>(campaign.sourceType);
    const [isTrackedLinks, setTrackedLinksState] = useState<boolean>(true);
    const [trackedLinks, setTrackedLinks] = useState<ITrackedWordModel[]>([]);
    const [excludedLinks, setExcludedLinks] = useState<ITrackedWordModel[]>([]);
    const [trackedWords, setTrackedWords] = useState<ITrackedWordModel[]>([]);
    const [excludedWords, setExcludedWords] = useState<ITrackedWordModel[]>([]);

    const handlerChangeName = (value: string): void => {
        setCampaignName(value);
    };
    const handlerDeleteCategory = (id: number): void => {
        setSelectedCategories([...selectedCategories.filter((item) => item.id !== id)]);
    };
    const handlerSelectCategory = (id: number): void => {
        const category = categories.find((item) => item.id.toString() === id.toString());
        if (category && !selectedCategories.some((item) => item.id === id)) {
            setSelectedCategories([...selectedCategories, { id, name: category.mainCat }]);
        }
    };

    const handlerSetBlogger = (data: ICampaignSimpleEntity[]): void => {
        setIsBlogger(getBloggerValue(data.filter(({ checked }) => checked).map(({ id }) => id)));
    };
    const handlerSetSourceType = (data: ICampaignSimpleEntity[]): void => {
        setSourceType(
            data
                .filter(({ checked }) => checked)
                .map(
                    ({ id }) => campaignUtils.campaignPlatformsTypeState
                        .find((item) => item.id === id).value.toString(),
                ),
        );
    };
    const handlerDeleteWords = useCallback((
        setFunc: React.Dispatch<React.SetStateAction<ITrackedWordModel[]>>,
        data: ITrackedWordModel[],
    ) => (id: number, isSource: boolean): void => {
        setFunc([...data.filter((item) => item.id !== id)]);
        if (isSource) {
            onDeleteTrackedSource(isTrackedLinks, id);
        }
    }, [isTrackedLinks]);
    const handlerAddWords = (
        setFunc: React.Dispatch<React.SetStateAction<ITrackedWordModel[]>>,
        data: ITrackedWordModel[],
    ) => (value: string, inputId: number): void => {
        if (data.some(({ id }) => id === inputId)) {
            return;
        }
        if (data.some(({ name }) => name === value)) {
            return;
        }
        const newId = data?.length ? (Math.max(...data.map(({ id }) => id)) + 1) : 1;
        setFunc([...data, { id: inputId ?? newId, name: value }]);
    };
    const getLinks = (isInclude: boolean = true): string[] => {
        if (isTrackedLinks && isInclude) {
            return trackedLinks?.length <= 0 ? [] : trackedLinks.map((item) => item.name);
        }
        if (!isTrackedLinks && !isInclude) {
            return excludedLinks?.length <= 0 ? [] : excludedLinks.map((item) => item.name);
        }
        return [];
    };
    const saveBtnDisabled = useMemo((): boolean => {
        if (isBlogger === undefined) {
            return true;
        }
        if (!campaignName) {
            return true;
        }
        if (!trackedWords?.length && !excludedWords?.length) {
            return true;
        }
        if (!sourceType?.length) {
            return true;
        }
        return false;
    }, [campaignName, isBlogger, trackedWords, excludedWords, sourceType]);

    const handlerCreateClick = (): void => {
        if (saveBtnDisabled) {
            setHighlighting(true);
            return;
        }
        const createDataModel: ICampaignDataModel = {
            isBlogger,
            sourceType: prepareSource(sourceType),
            name: campaignName,
            id: null,
            excludeLinks: getLinks(false),
            excludeWords: excludedWords?.length <= 0 ? [] : excludedWords.map((item) => item.name),
            includeWords: trackedWords?.length <= 0 ? [] : trackedWords.map((item) => item.name),
            parseFrom: campaignUtils.getCampaignParseFromDate(selectedPeriodId),
            categoriesIds: selectedCategories.length <= 0 ? [0] : selectedCategories.map((item) => item.id),
            isActive: false,
            userId: '',
            createTimestamp: '',
            createDate: '',
            owner: null,
            sources: [],
            excludeSources: [],
            noDelete: false,
            isTrackedLinks,
            parseFromDate: null,
            searchQuery: '',
            isComplexQuery: false,
        };
        onCreateClick(createDataModel);
    };

    const scrollContentHeight = useMemo((): number | string => (
        rootHeight ? (rootHeight - topComponentsHeight) : '100%'
    ), [rootHeight]);
    const validationTrackWords = useMemo((): boolean => (
        isErrorHighlighting && !trackedWords?.length && !excludedWords?.length
    ), [isErrorHighlighting, trackedWords, excludedWords]);

    useEffect(() => {
        if (!categories?.length || !selectedCategoriesIds?.length) {
            return;
        }
        setSelectedCategories(prepareCategories(selectedCategoriesIds, categories));
    }, [categories, selectedCategoriesIds]);
    useEffect(() => {
        if (!saveBtnDisabled && isErrorHighlighting) {
            setHighlighting(false);
        }
    }, [saveBtnDisabled]);
    useEffect(() => {
        if (isDefaultExcludedLinks) {
            if (campaign?.excludeSources?.length > 0) {
                setExcludedLinks([...campaign.excludeSources
                    .map((item) => ({ id: item.id, name: item.link })),
                ]);
            }
        } else if (campaign?.sources?.length > 0) {
            setTrackedLinks([...campaign.sources
                .map((item) => ({ id: item.id, name: item.link })),
            ]);
        }
    }, [campaign, isDefaultExcludedLinks]);
    useEffect(() => {
        setTrackedLinksState(!isDefaultExcludedLinks);
    }, [isDefaultExcludedLinks]);

    useEffect(() => {
        if (onSelectedFavoriteSources) {
            onSelectedFavoriteSources([...trackedLinks]);
        }
    }, [trackedLinks]);

    return (
        <div className={styles.campaignCreateForm_root}>
            <div className={styles.modalWindow} ref={ref}>
                <div className={styles.nameContainer}>
                    <CampaignNameInput
                        value={campaignName}
                        placeholder="Наименование кампании"
                        type="text"
                        setValue={handlerChangeName}
                        tagId="campaign-name"
                        isErrorHighlighting={isErrorHighlighting && !campaignName}
                        isView={false}
                        isLoading={isLoading}
                        isIPadSize={isIPadSize}
                    />
                </div>
                <div style={{ height: scrollContentHeight }}>
                    <div className={classnames(styles.subRoot, styles.scrollWrapper)}>
                        <CampaignCategorySelect
                            categories={categories}
                            selectedCategories={selectedCategories}
                            onSelectCategory={handlerSelectCategory}
                            onDeselectCategory={handlerDeleteCategory}
                            isView={false}
                            isLoading={isLoadingCategories}
                        />
                        <div className={styles.titleBlock}>Ключевые слова</div>
                        <div className={styles.wordsWrapper} style={isIPadSize ? { width: 820 } : null}>
                            <div className={styles.flexColumn}>
                                <ValidationTooltip
                                    isVisible={validationTrackWords}
                                    left={100}
                                    top={65}
                                    width={230}
                                    text="Пожалуйста, выберите тип слов"
                                >
                                    {/* <div className={styles.title} style={{ marginTop: -8 }}>Тип слов*:</div> */}
                                    <div className={styles.title} style={{ marginTop: -8 }} />
                                </ValidationTooltip>
                                <div className={styles.flexRow} style={isIPadSize ? { gap: 32 } : null}>
                                    <CampaignTrackedWordsBlock
                                        inputTagId="campaign-trackedwords"
                                        addBtnTagId="campaign-trackedwords-add"
                                        delBtnTagId="campaign-trackedwords-del"
                                        addFavoriteTagId="campaign-trackedwords-favorite"
                                        isDisabled={false}
                                        verifiable={false}
                                        isErrorHighlighting={validationTrackWords}
                                        isView={false}
                                        title="Отслеживаемые слова*:"
                                        placeholder="Введите текст"
                                        words={trackedWords}
                                        onDeleteWord={handlerDeleteWords(setTrackedWords, trackedWords)}
                                        onAddWord={handlerAddWords(setTrackedWords, trackedWords)}
                                        withSearch={false}
                                        sourceType={sourceType}
                                        fullScreen={excludedWords?.length <= 0}
                                        isTrackedSources={null}
                                        isIPadSize={isIPadSize}
                                    />
                                    <CampaignTrackedWordsBlock
                                        inputTagId="campaign-excludedwords"
                                        addBtnTagId="campaign-excludedwords-add"
                                        delBtnTagId="campaign-excludedwords-del"
                                        isDisabled={false}
                                        verifiable={false}
                                        isErrorHighlighting={validationTrackWords}
                                        isView={false}
                                        title="Исключаемые слова*:"
                                        placeholder="Введите текст"
                                        words={excludedWords}
                                        onDeleteWord={handlerDeleteWords(setExcludedWords, excludedWords)}
                                        onAddWord={handlerAddWords(setExcludedWords, excludedWords)}
                                        withSearch={false}
                                        sourceType={sourceType}
                                        fullScreen={trackedWords?.length <= 0}
                                        isTrackedSources={null}
                                        isIPadSize={isIPadSize}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.titleBlock} style={{ width: 114 }}>Источники</div>
                        <div className={styles.wordsWrapper} style={isIPadSize ? { width: 820 } : null}>
                            <CampaignSourcesBlock
                                checkedSourceTypeIds={sourcesStringToNumber(sourceType)}
                                checkedBloggerIds={bloggersBoolToArray(isBlogger)}
                                handlerSetBlogger={handlerSetBlogger}
                                handlerSetSourceType={handlerSetSourceType}
                                isErrorHighlighting={isErrorHighlighting}
                                isView={false}
                                isIPadSize={isIPadSize}
                            />
                            <div className={styles.flexRow} style={isIPadSize ? { gap: 32 } : null}>
                                <div className={styles.flexColumn}>
                                    <div className={styles.title} style={{ marginTop: -8 }}>Тип источника:</div>
                                    <div className={styles.switchContainer}>
                                        <CampaignSwitcher
                                            label="Отслеживаемые"
                                            isInverse={false}
                                            isActive={isTrackedLinks}
                                            onSwitch={setTrackedLinksState}
                                            isDisabled={false}
                                        />
                                        <CampaignSwitcher
                                            label="Исключаемые"
                                            isInverse
                                            isActive={isTrackedLinks}
                                            onSwitch={setTrackedLinksState}
                                            isDisabled={false}
                                        />
                                    </div>
                                    <div className={styles.flexRow}>
                                        <CampaignTrackedWordsBlock
                                            inputTagId="campaign-source-link"
                                            addBtnTagId="campaign-source-link-add"
                                            delBtnTagId="campaign-source-link-del"
                                            addFavoriteTagId="campaign-source-link-favorite"
                                            isDisabled={!isTrackedLinks}
                                            verifiable
                                            isErrorHighlighting={false}
                                            isView={false}
                                            title="Отслеживаемые источники:"
                                            placeholder="Введите краткое описание"
                                            words={trackedLinks}
                                            onDeleteWord={handlerDeleteWords(setTrackedLinks, trackedLinks)}
                                            onAddWord={handlerAddWords(setTrackedLinks, trackedLinks)}
                                            withSearch
                                            sourceType={sourceType}
                                            fullScreen={false}
                                            isTrackedSources={isTrackedLinks}
                                            isIPadSize={isIPadSize}
                                        />
                                        <CampaignTrackedWordsBlock
                                            inputTagId="campaign-source-excl-link"
                                            addBtnTagId="campaign-source-excl-link-add"
                                            delBtnTagId="campaign-source-excl-link-del"
                                            isDisabled={isTrackedLinks}
                                            verifiable
                                            isErrorHighlighting={false}
                                            isView={false}
                                            title="Исключаемые источники:"
                                            placeholder="Введите ссылку"
                                            words={excludedLinks}
                                            onDeleteWord={handlerDeleteWords(setExcludedLinks, excludedLinks)}
                                            onAddWord={handlerAddWords(setExcludedLinks, excludedLinks)}
                                            withSearch
                                            sourceType={sourceType}
                                            fullScreen={false}
                                            isTrackedSources={isTrackedLinks}
                                            isIPadSize={isIPadSize}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: -8 }}>
                                    {/* <CampaignSourceLinkInfo /> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.pinnedInfo}>
                        <div className={classnames(styles.label, styles.small)}>* Поля обязательные к заполнению</div>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Button
                            tagId="campaign-cancel"
                            text="Отмена"
                            type="secondary"
                            size="large"
                            onClick={() => setCurrentModalWindow('')}
                        />
                        {/* <ButtonSecondary */}
                        {/*    tagId="campaign-cancel" */}
                        {/*    style={{ */}
                        {/*        width: 130, height: 48, */}
                        {/*    }} */}
                        {/*    text="Отмена" */}
                        {/*    onClick={() => setCurrentModalWindow('')} */}
                        {/* /> */}
                        <Button
                            tagId="campaign-save"
                            text="Сохранить"
                            type="default"
                            size="large"
                            onClick={handlerCreateClick}
                        />
                        {/* <ButtonPrimary */}
                        {/*    tagId="campaign-save" */}
                        {/*    style={{ width: 130, height: 48 }} */}
                        {/*    text="Сохранить" */}
                        {/*    onClick={handlerCreateClick} */}
                        {/* /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateFromReferenceCampaign;
