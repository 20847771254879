import { IInfoBasketSource } from '../../../containers/info-basket/subpages/results/ResultTable/types';

// const forbiddenCharacters = '/[^]ࣩࣩࣩࣩ࣯ࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩ࣯ࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩ]/g';

const convertToObject = (item: unknown): IInfoBasketSource => {
    const result: IInfoBasketSource = {} as IInfoBasketSource;

    Object.keys(item).forEach((key: string) => {
        if (key === 'id') {
            result.id = item[key];
        } else if (key === 'title') {
            result.title = item[key].replace(']ࣩࣩࣩࣩ࣯ࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩࣩ', '');
        } else if (key === 'city') {
            result.city = item[key];
        } else if (key === 'logo_link') {
            result.avatar = item[key];
        } else if (key === 'is_blogger') {
            result.typeId = item[key];
        } else if (key === 'platforms') {
            result.resource = item[key];
        } else if (key === 'categories') {
            result.category = Object.keys(item[key]);
        } else if (key === 'price') {
            result.price = item[key];
        } else if (key === 'subscribers') {
            result.subscribers = item[key];
        } else if (key === 'comments') {
            result.postsComments = item[key];
        } else if (key === 'views') {
            result.postsViews = item[key];
        } else if (key === 'last_update') {
            if (item[key]) {
                const date = new Date(item[key]);
                result.lastUpdateDate = date.toLocaleDateString('ru-RU');
                result.lastUpdateTime = date.toLocaleTimeString('ru-RU');
                result.lastUpdateTimestamp = date.getTime() / 1000;
            }
        }
    });

    return result;
};

export const convertApiToInfoBasketSourceTable = (data: unknown): IInfoBasketSource[] => {
    const result: IInfoBasketSource[] = [];
    if (!data) {
        return result;
    }

    if (!Array.isArray(data) || data.length <= 0) {
        return result;
    }

    data.forEach((item) => {
        if (item) {
            result.push(convertToObject(item));
        }
    });

    return result;
};
