/* eslint-disable no-unsafe-optional-chaining */
import React, {
    useEffect,
    useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import SvgIcon from '@components/SvgIcon';
import { ITableColumn } from '@entities/Table/types';
import { SortOrder } from '@services/sources/types';
import { Popover } from 'antd';
import useOnClickOutside from '../hooks/useOnClickOutside';
import TableSort from '../../modals/TableSort';
import styles from './styles.module.scss';

interface IProps<T> {
    column: ITableColumn<T>;
    sort: { column: ITableColumn<T>; order: SortOrder } | null;
    search: { column: ITableColumn<T>, value: string } | null;
    classes?: string,
    onSort?: (column: ITableColumn<T>, order: SortOrder) => void;
    onSearch?: (column: ITableColumn<T>, value: string) => void;
    clearSearch: () => void;
    setTableMinHeight: (value: number) => void;
    escapePressed: boolean;
    setEscapePressed: React.Dispatch<React.SetStateAction<boolean>>;
    visibleHeaderPopoverId: number;
    onVisibleHeaderPopover: (id: number) => void;
}

/**
 * Компонент заголовка колонки, для компонента общей таблицы
 * @param props
 * @constructor
 */
const TableHeaderCell = <T extends {}>(
    props: IProps<T>,
): React.ReactElement => {
    const {
        column, sort, onSort, onSearch, search,
        clearSearch, setTableMinHeight, escapePressed,
        setEscapePressed, classes, visibleHeaderPopoverId, onVisibleHeaderPopover,
    } = props;
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [searchVisible, setSearchVisible] = useState<boolean>(false);
    const modalRef = useRef(null);
    const searchModalRef = useRef(null);
    const isSorted = useMemo(() => {
        if (!sort) return false;
        return sort.column.id === column.id;
    }, [sort]);

    const isModalOptionsExist = useMemo(
        () => column.sortable || column.renderFilter,
        [column.sortable, column.renderFilter],
    );

    const searchValue = useMemo(() => {
        if (!search) return '';
        if (search.column.id !== column.id) return '';
        return search.value;
    }, [search]);

    const closeModal = (): void => {
        setModalVisible(false);
        onVisibleHeaderPopover(column.id);
    };

    const handleClickSort = (sortOrder: SortOrder) => {
        onSort(column, sortOrder);
        closeModal();
    };

    // useOnClickOutside(modalRef, closeModal);
    useOnClickOutside(searchModalRef, () => setSearchVisible(false));

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onSearch(column, value);
    };

    const handlerChangeVisibleHeaderPopover = (): void => {
        // setModalVisible((prev) => !prev);
        onVisibleHeaderPopover(column.id);
    };

    useEffect(() => {
        if (isModalVisible || searchVisible) {
            setTableMinHeight(200);
        } else {
            setTableMinHeight(0);
        }
    }, [isModalVisible, searchVisible]);

    useEffect(() => {
        if (escapePressed) {
            setModalVisible(false);
            setEscapePressed(false);
            onVisibleHeaderPopover(column.id);
        }
    }, [escapePressed]);

    useEffect(() => {
        setModalVisible(visibleHeaderPopoverId === column?.id);
    }, [visibleHeaderPopoverId, column?.id]);

    return (
        <div className={styles.wrapper} ref={modalRef}>
            <div className={styles.container}>
                <div className={styles.cell}>
                    <div
                        onClick={handlerChangeVisibleHeaderPopover}
                        role="button"
                        className={classNames(
                            styles.cellValue,
                            isModalOptionsExist ? styles.cellButton : undefined,
                            isModalOptionsExist && isModalVisible ? styles.cellButtonActive : undefined,
                            classes,
                        )}
                    >
                        {column.title ?? ''}
                        {column.isIndex && '№'}

                        {isModalOptionsExist && !isSorted && (
                            <div className={styles.sortingArrow}>
                                <SvgIcon id="SortingArrow" size={12} height={6} />
                            </div>
                        )}

                        {isSorted && (
                            <div
                                style={{
                                    transform:
                                        sort?.order === SortOrder.asc
                                            ? 'scale(1, -1)'
                                            : '',
                                    height: 16,
                                }}
                            >
                                <SvgIcon id="SortingIcon" size={16} />
                            </div>
                        )}
                    </div>

                    {column.searchable && !searchVisible && (
                        <button
                            type="button"
                            onClick={() => setSearchVisible(true)}
                            className={styles.searchButton}
                        >
                            <SvgIcon
                                id="Search"
                                size={16}
                            />
                        </button>
                    )}
                </div>
                {searchVisible && column.searchable
                    && (
                        <div className={styles.cellSearch} ref={searchModalRef}>
                            <input type="text" value={searchValue} onChange={handleChangeSearch} />
                            <button type="button" onClick={clearSearch}>
                                <SvgIcon id={searchValue.length >= 1 ? 'Cross' : 'Search'} size={16} />
                            </button>
                        </div>
                    )}
            </div>

            {isModalOptionsExist && (
                <Popover
                    content={(
                        <>
                            {column.sortable
                            && (
                                <TableSort
                                    onSort={handleClickSort}
                                    type={column.sortType ?? 'number'}
                                    activeOrder={sort ? sort.order : null}
                                />
                            )}

                            {column.renderFilter && column.renderFilter(column)}
                        </>
                    )}
                    visible={isModalVisible}
                    showArrow={false}
                    placement="bottomRight"
                />
                // <div className={styles.modal} style={{ right: 0 }}>
                //     {column.sortable
                //         && (
                //             <TableSort
                //                 onSort={handleClickSort}
                //                 type={column.sortType ?? 'number'}
                //                 activeOrder={sort ? sort.order : null}
                //             />
                //         )}
                //
                //     {column.renderFilter && column.renderFilter(column)}
                // </div>
            )}
        </div>
    );
};
export default TableHeaderCell;
