import React, { useMemo } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface IValidationTooltipProps {
    text: string;
    isVisible: boolean;
    left: number;
    top: number;
    width: number;
    parentIsRelative?: boolean;
    parentHTMLElement?: HTMLElement;
}

const ValidationTooltip: React.FC<IValidationTooltipProps> = ({
    text,
    isVisible,
    left,
    top,
    width,
    children,
    parentHTMLElement,
    parentIsRelative = true,
}): JSX.Element => {
    const positioningStyle = useMemo<React.CSSProperties>(() => {
        if (parentIsRelative || !parentHTMLElement) return { left, top: -top, width };
        return { left: (parentHTMLElement?.offsetLeft ?? 0) + left, top: (parentHTMLElement?.offsetTop ?? 0) - 40 };
    }, [parentIsRelative, parentHTMLElement, left, top, width]);
    return (
        <div className={styles.root} style={parentIsRelative ? {} : { position: 'initial' }}>
            <div>{children}</div>
            <div
                className={classnames(styles.contentContainer, styles[isVisible ? '' : 'hidden'])}
                style={positioningStyle}
            >
                <span className={styles.text}>{text}</span>
                <div className={styles.triangleRight} />
            </div>
        </div>
    );
};

export default ValidationTooltip;
