import React from 'react';
import styles from './styles.module.scss';

interface IProps {
    message: string;
    active: boolean;
}
const MessageErrorValidation: React.FC<IProps> = ({ children, message, active }): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handler = () => {};
    return (
        <div className={styles.messageErrorValidation}>
            {children}
            {active && <div className={styles.messageErrorValidation_message}>{message}</div>}
        </div>
    );
};

export default MessageErrorValidation;
