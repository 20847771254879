import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { convertApiToLocation } from './converter';
import { ALL_REGIONS } from '../../../utils/constants';
import {
    FetchStatus, IDictionary, IRegion, IRegionState,
} from '../../types';

const initialState: IRegionState = {
    entities: {},
    ids: [],
    status: FetchStatus.undefined,
};

const { actions: infoBasketRegionActions, reducer } = createSlice({
    name: 'infoBasketRegion',
    initialState,
    reducers: {
        startLoading: (state: IRegionState) => {
            state.status = FetchStatus.loading;
        },
        failedLoading: (state: IRegionState) => {
            state.status = FetchStatus.failed;
        },
        setLoadingSuccess: (state: IRegionState) => {
            state.status = FetchStatus.success;
        },
        successLoading: (
            state: IRegionState,
            { payload }: PayloadAction<unknown>,
        ) => {
            const allLocation = {
                id: '-1', name: 'Все регионы', checked: false, visible: true,
            };
            const buffer = [allLocation, ...convertApiToLocation(payload)];
            if (state.ids.length > 0) {
                return { ...state };
            }
            const localState = {
                entities: {
                    ...state.entities,
                    ...buffer.reduce(
                        (acc: IDictionary<IRegion>, curr: IRegion) => {
                            acc[curr.id] = curr;
                            return acc;
                        },
                        {} as IDictionary<IRegion>,
                    ),
                },
                ids: [...state.ids, ...buffer.map(({ id }) => id)],
            } as IRegionState;
            return { ...localState };
        },
        onChecked: (
            state: IRegionState,
            { payload }: PayloadAction<string>,
        ) => {
            if (state.entities[payload].name === ALL_REGIONS) {
                if (!state.entities[payload].checked) {
                    state.ids.forEach((id) => {
                        state.entities[id].checked = true;
                    });
                } else {
                    state.ids.forEach((id) => {
                        state.entities[id].checked = false;
                    });
                }

                return;
            }
            state.entities[payload].checked = !state.entities[payload].checked;
        },
        onSelectGroup: (
            state: IRegionState,
            { payload }: PayloadAction<string[]>,
        ) => {
            if (payload.some((id) => id === '0')) {
                Object.keys(state.entities).forEach((key) => {
                    state.entities[key].checked = true;
                });
            } else {
                payload.forEach((id) => {
                    state.entities[id].checked = true;
                });
            }
        },
        setVisible: (
            state: IRegionState,
            { payload }: PayloadAction<string[]>,
        ): void => {
            state.ids.forEach((id) => {
                if (payload.includes(`${id}`)) {
                    state.entities[id].visible = true;
                }
            });
        },
        setInvisible: (
            state: IRegionState,
            { payload }: PayloadAction<string[]>,
        ): void => {
            state.ids.forEach((id) => {
                if (payload.includes(`${id}`)) {
                    state.entities[id].visible = false;
                }
            });
        },
        deselectAll: (state: IRegionState): void => {
            state.ids.forEach((id) => {
                state.entities[id].checked = false;
            });
        },
    },
});

export { infoBasketRegionActions };
export default reducer;
