import React, { useMemo } from 'react';
import { dateTimeToDate, dateTimeToTime } from '../../../../../../utils/helpers/dateConverter';
import { IDnDSinglePost } from '../types';
import LikesIcon from '../../../../../../assets/images/CampaignsDnD/like.svg';
import RepostIcon from '../../../../../../assets/images/CampaignsDnD/repost.svg';
import CommentsIcon from '../../../../../../assets/images/CampaignsDnD/comments.svg';
import ViewsIcon from '../../../../../../assets/images/CampaignsDnD/views.svg';
import TgIcon from '../../../../../../assets/images/CampaignsDnD/telegram.svg';
import OkIcon from '../../../../../../assets/images/CampaignsDnD/ok.svg';
import VkIcon from '../../../../../../assets/images/CampaignsDnD/vk.svg';
import RssIcon from '../../../../../../assets/images/CampaignsDnD/rss.svg';
import ZenIcon from '../../../../../../assets/images/CampaignsDnD/zen.svg';
// import helpers from '../../../../../../utils/helpers/dateConverter';
import styles from '../styles.module.scss';

const iconsMap = new Map([
    ['vk', VkIcon],
    ['ok', OkIcon],
    ['tg', TgIcon],
    ['rss', RssIcon],
    ['dzen', ZenIcon],
]);

const CampaignDnDSinglePost: React.FC<IDnDSinglePost> = ({
    title,
    content,
    likes,
    reposts,
    comments,
    views,
    sourceType,
    lastUpdate,
}): JSX.Element => {
    const updateDate = useMemo<string>(() => (
        `${dateTimeToDate(lastUpdate)} ${dateTimeToTime(lastUpdate)}`
    ), [lastUpdate]);
    return (
        <div className={styles.post}>
            <div className={styles.post_title}>
                <img src={iconsMap.get(sourceType)} alt={`${sourceType}-icon`} />
                <span>{title?.length > 40 ? `${title.substring(0, 40)}...` : title}</span>
            </div>
            <p className={styles.post_text}>{content?.length > 100 ? `${content.substring(0, 100)}...` : content}</p>
            <div className={styles.post_lineIcon}>
                <div className={styles.post_iconStatistic}>
                    <img src={LikesIcon} alt="LikesIcon" />
                    <span className={styles.post_count}>{likes ?? 0}</span>
                </div>
                <div className={styles.post_iconStatistic}>
                    <img src={RepostIcon} alt="RepostIcon" />
                    <span className={styles.post_count}>{reposts ?? 0}</span>
                </div>
                <div className={styles.post_iconStatistic}>
                    <img src={CommentsIcon} alt="CommentsIcon" />
                    <span className={styles.post_count}>{comments ?? 0}</span>
                </div>
                <div className={styles.post_iconStatistic}>
                    <img src={ViewsIcon} alt="ViewsIcon" />
                    <span className={styles.post_count}>{views ?? 0}</span>
                </div>
            </div>
            <span className={styles.post_date}>
                {updateDate}
            </span>
        </div>
    );
};

export default CampaignDnDSinglePost;
