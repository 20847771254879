import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import InputsSlider from '@shared/ui-components/InputsSlider';
import SortingIcon from '@assets/images/sorting.svg';
import SortingActiveIcon from '@assets/images/sortingActive.svg';
import { IQPSourcesWithSortingAndFiltering, RangeFilter, SortOrder } from '@services/sources/types';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import styles from './styles.module.scss';

// import MultiRangeSlider from '../MultiRangeSlider/index';

interface IProps {
    onSort: (field: string, direction: SortOrder) => void;
    onSelect: (value: [number, number], name: string) => void;
    currentSortingAndFilters: IQPSourcesWithSortingAndFiltering;
    onClose: () => void;
    rangeMin: number;
    rangeMax: number;
    isCommonTable?: boolean;
}

const name = 'subscribers';
// const rangeMin = 0;
// const rangeMax = 500000000;

const getValue = (input: RangeFilter | null | undefined, min: number, max: number): [number, number] => {
    if (!input || !(input[name]?.to >= 0) || !(input[name]?.from >= 0)) {
        return [min, max];
    }
    return [input[name]?.from, input[name]?.to];
};

const SubscribersFilter: React.FC<IProps> = ({
    onSort,
    onSelect,
    currentSortingAndFilters,
    onClose,
    rangeMin,
    rangeMax,
    isCommonTable,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const [value, setValue] = useState<[number, number]>(
        () => getValue(currentSortingAndFilters?.rangeParams, rangeMin, rangeMax),
    );
    const isAscFiltered = useMemo<boolean | null>(() => {
        if (currentSortingAndFilters?.orderBy !== name) {
            return null;
        }
        return currentSortingAndFilters?.orderDirection === SortOrder.asc;
    }, [currentSortingAndFilters]);
    const handlerChange = (newValue: [number, number]): void => {
        if (newValue[0] !== value[0] || newValue[1] !== value[1]) {
            onSelect(newValue, name);
            setValue(newValue);
        }
    };

    useEffect(() => {
        setValue(getValue(currentSortingAndFilters?.rangeParams, rangeMin, rangeMax));
    }, [currentSortingAndFilters]);

    return (
        <div className={isCommonTable ? '' : styles.subscribersFilter} ref={refModal}>
            <div className={styles.sortingContainer}>
                <span className={styles.title}>Сортировка</span>
                <button
                    type="button"
                    className={styles.sortingContainer_row}
                    onClick={() => onSort(name, SortOrder.desc)}
                >
                    <img
                        src={!isAscFiltered && isAscFiltered !== null ? SortingActiveIcon : SortingIcon}
                        alt="SortingIcon"
                        className={styles.sortingContainer_img}
                    />
                    <span
                        className={styles.sortingContainer_symbol}
                        style={!isAscFiltered && isAscFiltered !== null ? { color: '#0EBFA1' } : {}}
                    >
                        По убыванию
                    </span>
                </button>
                <button
                    type="button"
                    className={styles.sortingContainer_row}
                    onClick={() => onSort(name, SortOrder.asc)}
                >
                    <img
                        src={isAscFiltered ? SortingActiveIcon : SortingIcon}
                        alt="SortingIcon"
                        className={styles.sortingContainer_img}
                        style={{ transform: 'scale(1, -1)' }}
                    />
                    <span
                        className={styles.sortingContainer_symbol}
                        style={isAscFiltered ? { color: '#0EBFA1' } : {}}
                    >
                        По возрастанию
                    </span>
                </button>
            </div>
            <div className={styles.filterContainer}>
                <span className={styles.title}>Фильтры</span>
                <InputsSlider
                    defaultValue={[rangeMin, rangeMax]}
                    value={value}
                    onChange={handlerChange}
                    min={rangeMin}
                    max={rangeMax}
                    style={{ input: { width: 132 } }}
                />
            </div>
        </div>
    );
};

export default SubscribersFilter;
