import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import { IUserInfo } from 'services/common/types';
import Path from '../../../shared/lib/paths';
import { usersRtkQService } from '../../../services/common/users';
import { selectUserProfileFetchData } from '../../../store/user-profile/selectors';
import CampaignTable from '../../../components/campaign/List/Table/CampaignTable';
import campaignSourceSelectors from '../../../store/campaign/source/selectors';
import campaignTableSelectors from '../../../store/campaign/table/selectors';
import { useAppDispatch } from '../../../store/hooks';
import { FetchStatus } from '../../../store/types';
import { campaignActions } from '../../../store/campaign/source';
import { campaignComparisonActions } from '../../../store/campaign/comparison';
import { campaignCommonActions } from '../../../store/campaign/common';
import { CampaignActiveModalType, ModalAnswerType } from '../../../store/campaign/source/types';
import campaignComparisonSelectors from '../../../store/campaign/comparison/selectors';
import ComparisonPeriodModalContainer from '../Comparison/Modals/ComparisonPeriod';
import CampaignSelectList from '../../../components/campaign/Comparison/Modals/CampaignSelectList';
import ComparisonFromReferenceCampaignContainer from '../Comparison/Modals/ComparisonFromReferenceCampaign';
import { campaignsListRtkQService } from '../../../services/campaigns/campaigns-list';
import { singleCampaignRtkQService } from '../../../services/campaigns/single-campaign';

const dateEndForComparison = new Date(); // дата окончания отслеживания для кампаний, сейчас она едина для всех и равна текущей дате.

const CampaignsListContainer: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const campaigns = useSelector(campaignSourceSelectors.campaigns);
    // const isLoading = useSelector(campaignSourceSelectors.campaignsIsLoading);
    const isUsersLoading = useSelector(campaignSourceSelectors.usersIsLoading);
    const currentCampaignStatus = useSelector(campaignSourceSelectors.updateCampaignStatus);
    // const campaignLoadingStatus = useSelector(campaignSourceSelectors.campaignsFetchStatus);
    const headerStructure = useSelector(campaignTableSelectors.headerStructure);
    // const users = useSelector(campaignSourceSelectors.users);
    const updateCampaignStatus = useSelector(campaignSourceSelectors.updateCampaignStatus);
    const activeModal = useSelector(campaignSourceSelectors.activeModal);
    const modalAnswer = useSelector(campaignSourceSelectors.modalAnswer);
    const deleteStatus = useSelector(campaignSourceSelectors.deleteCampaignStatus);
    const currentUser = useSelector(selectUserProfileFetchData);
    const isCompare = useSelector(campaignComparisonSelectors.campaignCompareEnabled);
    const referenceCampaign = useSelector(campaignComparisonSelectors.referenceCampaign);
    const compareCampaigns = useSelector(campaignComparisonSelectors.compareCampaigns);

    const [deleteSingleCampaign] = singleCampaignRtkQService.useDeleteCampaignMutation();

    const [activeCampaign, setActiveCampaign] = useState<ICampaignDataModel>(null);
    // const [activeRowIds, setActiveRowIds] = useState<number[]>([]);
    const [currentModalWindow, setCurrentModalWindow] = useState<string>('');

    const [loadingUsersRtkQ, { isLoading: isLoadingUsersRtq, data: usersRtq }] = usersRtkQService.useLazyPostUsersFioQuery();
    const [
        getCampaigns,
        { isLoading: isLoadingRtkQ },
    ] = campaignsListRtkQService.useLazyGetCampaignsQuery();
    const [changeCampaignStatus] = singleCampaignRtkQService.usePostChangeCampaignStatusMutation();

    const tableData = useMemo<ICampaignDataModel[]>(() => {
        const result = (referenceCampaign
            ? campaigns?.filter(
                ({ parseFromDate }) => parseFromDate <= dateEndForComparison,
            )
            : campaigns) ?? [];
        return result.map((item) => {
            const user = usersRtq?.find(({ id }) => id === item.userId);
            return {
                ...item,
                owner: {
                    owner: true,
                    userId: user?.id,
                    lastName: user?.last_name,
                    middleName: user?.middle_name,
                    firstName: user?.first_name,
                    email: '',
                } as IUserInfo,
                noDelete: item.userId !== currentUser.userId,
            };
        });
    }, [campaigns, referenceCampaign, usersRtq]);
    const activeRowIds = useMemo<number[]>(() => [
        ...compareCampaigns.map(({ id }) => id), referenceCampaign?.id,
    ], [compareCampaigns, referenceCampaign]);

    const handlerClickRow = useCallback((item: ICampaignDataModel): void => {
        if (!isCompare) {
            return;
        }
        if (!activeRowIds.includes(item?.id)) {
            if (!referenceCampaign) {
                dispatch(campaignComparisonActions.setReferenceCampaign(item));
            } else {
                dispatch(campaignComparisonActions.addCampaignForCompare(item));
            }
        } else if (activeRowIds.includes(item?.id)) {
            if (referenceCampaign && item?.id === referenceCampaign?.id) {
                return;
            }
            dispatch(campaignComparisonActions.removeCampaignForCompare(item.id));
        }
    }, [activeRowIds, isCompare]);

    const handlerCheckedRow = useCallback((item: ICampaignDataModel) => (eventValChange: CheckboxChangeEvent): void => {
        if (!eventValChange || !eventValChange.target) {
            return;
        }
        if (!isCompare) {
            return;
        }
        if (eventValChange.target.checked && !activeRowIds.includes(item?.id)) {
            if (!referenceCampaign) {
                dispatch(campaignComparisonActions.setReferenceCampaign(item));
            } else {
                dispatch(campaignComparisonActions.addCampaignForCompare(item));
            }
        } else if (!eventValChange.target.checked && activeRowIds.includes(item?.id)) {
            if (referenceCampaign && item?.id === referenceCampaign?.id) {
                return;
            }
            dispatch(campaignComparisonActions.removeCampaignForCompare(item.id));
        }
    }, [activeRowIds, isCompare]);

    const handlerNavigateToCampaign = (id: number): void => {
        if (id) {
            dispatch(campaignActions.clearCurrentCampaign());
            dispatch(campaignActions.setViewActionCampaign());
            // dispatch(loadSingleCampaignIfNotExists(id));
            dispatch(campaignCommonActions.setLeftMenuCampaignId(id));
            navigate(`/${Path.Campaign}/${Path.CampaignUpdate}/${id}`);
        }
    };
    const handlerDeleteCampaign = (id: number): void => {
        const current = campaigns.find(({ id: itemId }) => itemId === id);
        if (current) {
            setActiveCampaign({ ...current });
            dispatch(campaignActions.setDataSingleCampaign(current));
            dispatch(campaignActions.setActiveModal(CampaignActiveModalType.deletingCampaign));
        }
    };
    const handlerActivateCampaign = (id: number): void => {
        const current = campaigns.find(({ id: itemId }) => itemId === id);
        if (current) {
            setActiveCampaign({ ...current });
            dispatch(campaignActions.setDataSingleCampaign(current));
            dispatch(campaignActions.setActiveModal(CampaignActiveModalType.activatingCampaign));
        }
    };
    const handlerSetNewCampaigns = (campaignsForCompare: ICampaignDataModel[]): void => {
        if (campaigns?.length > 0) {
            dispatch(campaignComparisonActions.setCampaignForCompare(campaignsForCompare));
        }
    };

    useEffect(() => {
        if (!isLoadingUsersRtq && !usersRtq?.length && campaigns?.length) {
            loadingUsersRtkQ({
                ids: campaigns?.reduce(
                    (acc, cur) => (!acc.includes(cur.userId) ? [...acc, cur.userId] : acc),
                    [],
                ) ?? [],
            });
        }
    }, [campaigns, usersRtq, isLoadingUsersRtq]);
    useEffect(() => {
        if (
            !(currentCampaignStatus === FetchStatus.undefined
                || currentCampaignStatus === FetchStatus.failed
                || currentCampaignStatus === FetchStatus.success)
        ) {
            return;
        }
        getCampaigns();
    }, [currentCampaignStatus]);
    useEffect(() => {
        if (activeModal === CampaignActiveModalType.deletingCampaign) {
            if (modalAnswer === ModalAnswerType.accept) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
                // dispatch(deleteCampaignThunk(activeCampaign?.id));
                if (Number.isInteger(activeCampaign?.id)) {
                    deleteSingleCampaign({ id: activeCampaign?.id });
                }
                return;
            }
            if (modalAnswer === ModalAnswerType.reject) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
                setActiveCampaign(null);
            }
            return;
        }
        if (activeModal === CampaignActiveModalType.activatingCampaign) {
            if (modalAnswer === ModalAnswerType.accept) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
                // dispatch(changeCampaignStatusThunk(activeCampaign?.id, activeCampaign?.isActive));
                changeCampaignStatus({ id: activeCampaign?.id, status: activeCampaign?.isActive });
                return;
            }
            if (modalAnswer === ModalAnswerType.reject) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
                setActiveCampaign(null);
            }
        }
    }, [activeModal, modalAnswer]);
    useEffect(() => {
        if (deleteStatus === FetchStatus.success) {
            setActiveCampaign(null);
        }
    }, [deleteStatus]);

    return (
        <>
            <CampaignTable
                data={tableData}
                header={headerStructure}
                isLoading={isLoadingRtkQ || isUsersLoading}
                isCampaignUpdate={updateCampaignStatus === FetchStatus.update}
                isCampaignDeleting={deleteStatus === FetchStatus.process}
                isCampaignCompare={isCompare}
                onDelete={handlerDeleteCampaign}
                onActivate={handlerActivateCampaign}
                onNavigateToCampaign={handlerNavigateToCampaign}
                onCheckedRow={handlerCheckedRow}
                activeRowIds={activeRowIds}
                referenceCampaignId={referenceCampaign?.id}
                onClickRow={handlerClickRow}
            />
            <ComparisonPeriodModalContainer
                currentModalWindow={currentModalWindow}
                setCurrentModalWindow={setCurrentModalWindow}
            />
            <ComparisonFromReferenceCampaignContainer
                isLoading={isLoadingRtkQ}
                currentModalWindow={currentModalWindow}
                setCurrentModalWindow={setCurrentModalWindow}
                referenceCampaign={referenceCampaign}
            />
            <CampaignSelectList
                campaigns={tableData}
                currentModalWindow={currentModalWindow}
                setCurrentModalWindow={setCurrentModalWindow}
                defaultActiveRowIds={activeRowIds}
                referenceCampaignId={referenceCampaign?.id}
                onSetNewCampaigns={handlerSetNewCampaigns}
            />
        </>
    );
};

export default CampaignsListContainer;
