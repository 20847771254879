import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import StageMessage from '@components/Project/Popup/Milestone';
import { IProjectStatus, ProjectStageTypes } from '@services/projects/types';
import Popup from '@entities/Popup';
import styles from './styles.module.scss';
import Success from '../imgs/success.svg';

interface IProps {
    status: IProjectStatus;
    projectIsComplete: boolean;
}

interface IMessage {
    nameStage: string;
    isSelected: boolean;
    text: string;
}

interface IMilestone {
    type: ProjectStageTypes;
    name: string;
    launch: boolean;
    atWork: boolean;
    success: boolean;
    textMessage: string;
}

const messages = [
    { nameStage: 'Бриф', isSelected: false },
    { nameStage: 'Лонглист', isSelected: false },
    { nameStage: 'Медиаплан', isSelected: false },
    { nameStage: 'Мониторинг', isSelected: false },
    { nameStage: 'Результаты проекта', isSelected: false },

] as IMessage[];
const milestones: IMilestone[] = [
    {
        type: ProjectStageTypes.brief,
        name: 'Бриф',
        launch: true,
        atWork: true,
        success: true,
        textMessage: 'На этом этапе необходимо внести основную информацию о проекте, контакты заказчика и приложить документ с его требованиями',
    }, {
        type: ProjectStageTypes.longList,
        name: 'Лонглист',
        launch: false,
        atWork: false,
        success: false,
        textMessage: 'Воспользуйтесь добавлением источников, чтобы подготовить кандидатов для заказчика и приступить к согласованию условий со всеми участниками',
    }, {
        type: ProjectStageTypes.mediaPlanStage,
        name: 'Медиаплан',
        launch: false,
        atWork: false,
        success: false,
        textMessage: 'Финализируйте даты активности и контент, также приложите все документы и акты согласований',
    }, {
        type: ProjectStageTypes.monitoring,
        name: 'Мониторинг',
        launch: false,
        atWork: false,
        success: false,
        textMessage: 'Отслеживайте статистику проекта в онлайне',
    }, {
        type: ProjectStageTypes.resultsStage,
        name: 'Результаты проекта',
        launch: false,
        atWork: false,
        success: false,
        textMessage: 'Подготовьте отчёт для заказчика и завершите проект',
    },
];

const MilestonesBlock: React.FC<IProps> = ({ status, projectIsComplete }): JSX.Element => {
    const [listMilestones, setListMilestones] = useState<IMilestone[]>(() => [...milestones]);
    const [listMessages, setListMessages] = useState<IMessage[]>(messages);

    // Задаёться последнее значения Этапа по которомы будет отрисовываться линия процесса
    const stage = useMemo(() => {
        let value = '';
        listMilestones.map((item) => {
            if (item.atWork) value = item.name;
            return item;
        });
        return value;
    }, [milestones, listMilestones]);

    const hoverMouseAtStage = (value: string, hover) => {
        if (hover) {
            setListMessages(listMessages.map((item) => ({
                nameStage: item.nameStage,
                isSelected: item.nameStage === value,
                text: item.text,
            } as IMessage)));
            return;
        }
        setListMessages(messages);
    };

    useEffect(() => {
        setListMilestones((prev) => prev.map((item) => {
            if (projectIsComplete) {
                return {
                    ...item, atWork: true, launch: true, success: true,
                };
            }
            const isWork = Number(item.type) === Number(status?.stage ?? -1);
            const isNext = Number(item.type) === Number(status?.stage ?? -1) + 1;
            const isSuccess = Number(item.type) < Number(status?.stage ?? -1);
            return {
                ...item, atWork: isWork, launch: isWork || isNext, success: isSuccess,
            };
        }));
    }, [status, projectIsComplete]);

    return (
        <div className={styles.milestonesBlock}>
            <div className={styles.milestonesBlock_list}>
                {
                    listMilestones.map((item) => (
                        <div
                            key={item.name}
                            className={styles.milestonesBlock_inputRadio}
                        >
                            <Popup
                                key={item.name}
                                content={(
                                    <StageMessage
                                        message={item.textMessage}
                                        link={item.name === 'Бриф'}
                                    />
                                )}
                                placement="bottom"
                                xOffset={14}
                            >
                                <div className={styles.milestonesBlock_radioWrapper}>
                                    <div
                                        className={styles.milestonesBlock_radio}
                                        onMouseEnter={() => hoverMouseAtStage(item.name, true)}
                                        style={{ borderColor: `${item.atWork ? '#0EBFA1' : '#DEE1E7'}` }}
                                    >

                                        {item.launch && <div className={styles.milestonesBlock_circle} />}
                                        {item.atWork && (
                                            <div
                                                className={styles.milestonesBlock_circleAtWork}
                                                style={{ background: `${item.launch ? '#0EBFA1' : null}` }}
                                            />
                                        )}
                                        {item.success
                                            && (
                                                <div className={styles.milestonesBlock_circleSuccess}>
                                                    <img
                                                        src={Success}
                                                        alt="Success"
                                                        className={styles.milestonesBlock_imgSuccess}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </Popup>
                            <span className={styles.milestonesBlock_title}>{item.name}</span>
                        </div>
                    ))
                }
                <div
                    className={styles.milestonesBlock_line}
                    style={{ border: `${stage === 'Результаты проекта' ? '1px solid #0EBFA1' : null}` }}
                />
                <div
                    className={classnames(styles.milestonesBlock_lineSuccess, {
                        [styles.milestonesBlock_lineSuccessWidth70]: stage === 'Бриф',
                        [styles.milestonesBlock_lineSuccessWidth182]: stage === 'Лонглист',
                        [styles.milestonesBlock_lineSuccessWidth320]: stage === 'Медиаплан',
                        [styles.milestonesBlock_lineSuccessWidth470]: stage === 'Мониторинг',
                    })}
                    style={{ borderImageSlice: `${stage === 'Результаты проекта' ? '0' : null}` }}
                />

            </div>

        </div>
    );
};

export default MilestonesBlock;
