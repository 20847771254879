/* eslint-disable no-underscore-dangle */
import { ISimpleEntity } from 'store/types';
import { transformInfinitySourcesResponse, transformSourcesResponse } from '@services/sources/transformers/getSources';
import {
    IQPAddSource,
    IQPSourcesWithSortingAndFiltering,
    ISource,
    IAuthor,
    IAuthors,
    IEntity,
    IQPLoadSourcePosts,
    ISourcePost,
    ILoadSourcePostsResponseFromApi,
    ILoadSourcePostsResponse,
    ISourceModelFromApi,
    ISourceModel, IQPAuthors, ISourceFiltersRanges,
    SortOrder,
    ICustomSourceListRaw,
    IAddSourceToListsQuery,
} from '../types/index';

import { rtkQApi } from '../../index';

const transformSourceTypesResponse = (
    baseQueryResponse: any[],
): ISimpleEntity[] => baseQueryResponse.map(
    (item) => ({
        id: item?.id,
        name: item?.title,
        value: item?.title,
    } as ISimpleEntity),
);

const transformSourceAuthorsResponse = (baseQueryResponse: any): IAuthors => ({
    data: baseQueryResponse?.data.map(
        (item) => ({
            id: item?.id,
            title: item?.title,
            isContracted: item?.is_contracted,
            isBlogger: item?.is_blogger,
            logoLink: item?.logo_link,
            isReliable: item?.is_reliable,
        } as IAuthor),
    ),
    nextPage: baseQueryResponse?.next_page,
    previousPage: baseQueryResponse?.previous_page,
});

const transformPlatformsResponse = (baseQueryResponse: any[]): IEntity[] => baseQueryResponse.map(
    (item) => ({
        id: item?.id,
        title: item?.title,
    } as IEntity),
);

const transformSourcePosts = (
    baseQueryResponse: ILoadSourcePostsResponseFromApi,
): ILoadSourcePostsResponse => {
    const items = baseQueryResponse.items.map(
        (item) => ({
            postId: item.post_id,
            content: item.content,
            posted: item.posted,
            postLink: item.post_link,
            images: item.images,
            likes: item.likes,
            views: item.views,
            comments: item.comments,
            forwards: item.forwards,
            lastUpdate: item.last_update,
        } as ISourcePost),
    );

    return {
        nextPageToken: baseQueryResponse.next_page_token,
        previousPageToken: baseQueryResponse.previous_page_token,
        items,
    };
};

const transformSourceResponse = (baseQueryResponse: ISourceModelFromApi): ISourceModel => ({
    id: baseQueryResponse.id,
    title: baseQueryResponse.title,
    link: baseQueryResponse.link,
    author: {
        id: baseQueryResponse.author.id,
        title: baseQueryResponse.author.title,
    },
    categories: baseQueryResponse.categories,
    sourceType: {
        title: baseQueryResponse.source_type.title,
        id: baseQueryResponse.source_type.id,
    },
    created: baseQueryResponse.created,
    logoUrl: baseQueryResponse?.logo_url,
    lists: baseQueryResponse.lists,
});

const transformSourceFiltersRanges = (baseQueryResponse: any): ISourceFiltersRanges => ({
    err: { from: baseQueryResponse?.err?.from_, to: baseQueryResponse?.err?.to_ },
    erAvg: { from: baseQueryResponse?.er_avg?.from_, to: baseQueryResponse?.er_avg?.to_ },
    err24: { from: baseQueryResponse?.err24?.from_, to: baseQueryResponse?.err24?.to_ },
    subscribers: { from: baseQueryResponse?.subscribers?.from_, to: baseQueryResponse?.subscribers?.to_ },
    viewsAvg: { from: baseQueryResponse?.views_avg?.from_, to: baseQueryResponse?.views_avg?.to_ },
});
export const sourcesRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getLoadSources: build.query<ISimpleEntity[], void>({
            query: () => ({
                url: 'sources_api/dicts/source_types',
                method: 'GET',
            }),
            transformResponse: transformSourceTypesResponse,
        }),
        postLoadSourcesInfinity: build.query<ISource, IQPSourcesWithSortingAndFiltering>({
            query: (params) => {
                const rangeParams = {};
                Object.keys(params?.rangeParams ?? {}).forEach((key) => {
                    rangeParams[key] = { from_: params?.rangeParams[key].from, to_: params?.rangeParams[key].to };
                });
                return {
                    url: 'sources_api/sources/get_sources_by_pagination',
                    method: 'POST',
                    body: {
                        list_id: params?.list_id,
                        title: !params?.searchValue ? null : params.searchValue,
                        limit: params.limit,
                        order_by: params.orderBy,
                        order_direction: SortOrder[params.orderDirection],
                        filter_params: { ...params.filterParams, ...rangeParams },
                        // range_params: { ...rangeParams },
                        pagination_token: (!params?.prevPageToken || params?.prevPageToken === '') ? null : params?.prevPageToken,
                    },
                };
            },
            transformResponse: transformInfinitySourcesResponse,
            providesTags: ['ChangeSources'],
        }),
        postLoadSources: build.query<ISource, IQPSourcesWithSortingAndFiltering>({
            query: (params) => {
                const rangeParams = {};
                Object.keys(params?.rangeParams ?? {}).forEach((key) => {
                    rangeParams[key] = { from_: params?.rangeParams[key].from, to_: params?.rangeParams[key].to };
                });
                return {
                    url: 'sources_api/sources/get_sources',
                    method: 'POST',
                    body: {
                        page: params.page,
                        limit: params.limit,
                        order_by: params.orderBy,
                        order_direction: SortOrder[params.orderDirection],
                        filter_params: { ...params.filterParams, ...rangeParams },
                    },
                };
            },
            transformResponse: transformSourcesResponse,
        }),
        postAddSources: build.query<any, IQPAddSource>({
            query: (params) => ({
                url: 'sources_api/sources/add_source_by_url',
                method: 'POST',
                body: {
                    tag: params.tag,
                    title: params.title,
                    parse_link: params.parseLink,
                    link: params.link,
                    enabled: params.enabled,
                    type_id: params.typeId,
                    categories: params.categories,
                },
            }),
        }),
        postLoadAuthors: build.query<IAuthors, IQPAuthors>({
            query: (params) => ({
                url: 'sources_api/dicts/authors_by_cursor',
                method: 'POST',
                body: {
                    direction: params.direction,
                    order_direction: params.orderDirection,
                    value: params.value,
                    limit: params.limit,
                    title: params.title,
                },
            }),
            transformResponse: transformSourceAuthorsResponse,
        }),
        getLoadPlatforms: build.query<IEntity[], void>({
            query: () => ({
                url: 'sources_api/dicts/source_types',
                method: 'GET',
            }),
            transformResponse: transformPlatformsResponse,
        }),
        postLoadSourcePosts: build.query<ILoadSourcePostsResponse, IQPLoadSourcePosts>({
            query: (params) => ({
                url: 'sources_api/posts/',
                method: 'POST',
                body: {
                    source_id: params.sourceId,
                    limit: params.limit,
                    pagination_token: params.paginationToken,
                    column_name: params.columnName,
                    direction: params.direction,
                    date_filter: params.dateDilter,
                    views_filter: params.viewsFilter,
                },
            }),
            transformResponse: transformSourcePosts,
        }),
        getSearchSource: build.query<any, { substring: string, limit: number }>({
            query: (params) => ({
                url: `sources_api/sources/search?substring=${params.substring}&limit=${params.limit}`,
                method: 'GET',
            }),
            async onQueryStarted(args: { substring: string, limit: number }, { queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        if (!args.substring) {
                            return [];
                        }
                        return data;
                    });
            },
        }),
        getSource: build.query<ISourceModel, { sourceId: number }>({
            query: (params) => ({
                url: `sources_api/sources/${params.sourceId}`,
                method: 'GET',
            }),
            transformResponse: transformSourceResponse,
        }),
        getStat: build.query<any, { id: number }>({
            query: ({ id }) => ({
                url: `sources_api/sources/get_stat/${id}`,
                method: 'GET',
            }),
        }),
        getFiltersRanges: build.query<ISourceFiltersRanges, void>({
            query: () => ({
                url: 'sources_api/user_source_filters/ranges',
                method: 'GET',
            }),
            transformResponse: transformSourceFiltersRanges,
        }),
        getLists: build.query<ICustomSourceListRaw[], void>({
            query: () => ({
                url: 'sources_api/lists/',
                method: 'GET',
            }),
            providesTags: ['ChangeListsSources'],
        }),
        addSourcesToLists: build.mutation<void, IAddSourceToListsQuery>({
            query: (body) => ({
                url: 'sources_api/source_list/',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['SourceListsCounts'],
        }),
        removeSourcesFromList: build.mutation<void, { list_id: number, source_ids: number[] }>({
            query: (body) => ({
                url: 'sources_api/source_list/',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ['SourceListsCounts'],
        }),
        removeSourcesList: build.mutation<void, number>({
            query: (id) => ({
                url: `sources_api/lists/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['SourceListsCounts'],
        }),
        removeSourcesFromMediaPlan: build.mutation<void, { project_id: number, source_id: number, comment: string }>({
            query: ({ project_id, source_id, comment }) => ({
                url: `media_projects/project/${project_id}/sources/${source_id}/mediaplan/delete`,
                method: 'PUT',
                body: { comment },
            }),
            invalidatesTags: ['LongLists'],
        }),
        removeSourcesFromLongList: build.mutation<void, { project_id: number, source_id: number }>({
            query: ({ project_id, source_id }) => ({
                url: `media_projects/project/${project_id}/sources/${source_id}/longlist/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['LongLists'],
        }),
    }),
});
