import React, { useMemo } from 'react';
import { Tooltip } from '@shared/index';
import styles from './styles.module.scss';
import { getAbbreviation } from '../../../../../utils/getAbbreviation';

interface IPropsNumberTooltipCell {
    value: number;
}
const NumberTooltipCell: React.FC<IPropsNumberTooltipCell> = ({ value }): JSX.Element => {
    const fixedSubscribers = +value.toFixed(0);
    const getSubscribers = useMemo(() => {
        if (typeof value !== 'number' || Number.isNaN(value)) return ' ';
        return getAbbreviation(fixedSubscribers);
    }, [value]);
    return (
        <div className={styles.numberTooltipCell}>
            <Tooltip
                content={<div style={{ padding: 4 }}>{fixedSubscribers.toLocaleString('ru-RU')}</div>}
                disabled={!value || value < 10000}
            >
                <div className={styles.numberTooltipCell_label}>
                    {getSubscribers}
                </div>
            </Tooltip>
        </div>
    );
};

export default React.memo(NumberTooltipCell);
