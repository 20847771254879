import DOMPurify from 'dompurify';
import { IPDFTopPostsModel } from '../../../../components/campaign/Report/PDFPages/TopPostsPage';
import { IDictionary } from '../../../../store/types';
import PptxSlide from '../../PptxSlide';
import { ICampaignPPTXTopPostsPageProps } from './types';

class CampaignPPTXTopPostsPage extends PptxSlide {
    render(props: ICampaignPPTXTopPostsPageProps) {
        if (props.topPosts[0]) this.renderPlatform(100, props.topPosts[0]);
        if (props.topPosts[1]) this.renderPlatform(350, props.topPosts[1]);

        this.renderHeader({
            date: props.date,
            title: props.title,
            layoutHeight: props.layoutHeight,
            layoutWidth: props.layoutWidth,
            reportName: props.reportName,
            header: {
                pageName: props.header.pageName,
                number: props.header.number,
            },
        });
        return this.slide;
    }

    private renderPlatform(startY: number, data: IDictionary<IPDFTopPostsModel>): void {
        const key = Object.keys(data)[0];
        const { posts } = Object.values(data)[0];

        const options = {
            rectHeight: 38,
            spaceBetweenPosts: 44,
            ellipseSize: 10,
        };

        this.addShape('rect', {
            y: startY,
            x: 0,
            w: 200,
            h: options.rectHeight,
            fill: { color: 'DFFCF7' },
        });

        this.slide.addImage({
            path: this.getPlatformIcon(key),
            x: this.getInches(24),
            y: this.getInches(startY + 7),
            w: this.getInches(24),
            h: this.getInches(24),
        });

        this.addText(this.getPlatformText(key), {
            y: startY + 12,
            x: 54,
            color: '262626',
            fontFace: 'Montserrat Medium',
            fontSize: 12,
            h: 13,
        });

        let postsStartY = startY + options.rectHeight + 25;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        posts.forEach((el) => {
            this.addShape('ellipse', {
                y: postsStartY,
                x: 24,
                w: options.ellipseSize,
                h: options.ellipseSize,
                fill: { color: '0EBFA1' },
            });

            this.addText(DOMPurify.sanitize(el.content.replaceAll('\n', '')), {
                y: postsStartY - 5,
                x: 24 + options.ellipseSize + 10,
                color: '129FD4',
                fontFace: 'Montserrat Medium',
                fontSize: 12,
                h: 13,
                w: '100%',
                hyperlink: { url: el.link },
            });

            this.addText(`${el.views.toLocaleString('ru-RU')} просмотров`, {
                y: postsStartY + 15,
                x: 24 + options.ellipseSize + 10,
                color: '262626',
                fontFace: 'Montserrat Medium',
                fontSize: 12,
                h: 13,
                w: '100%',
            });

            postsStartY += options.spaceBetweenPosts;
        });
    }
}

export default CampaignPPTXTopPostsPage;
