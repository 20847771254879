import { IContact, IContactPerson, ICustomerInfo } from 'services/projects/types';

export const transformCustomerInfoResponse = (baseQueryResponse: any): ICustomerInfo => ({
    address: baseQueryResponse?.customer?.address,
    companyName: baseQueryResponse?.customer?.company_name,
    contactPersons: [
        ({
            id: baseQueryResponse?.customer?.contact_persons[0]?.customer_info_id,
            fullName: baseQueryResponse?.customer?.contact_persons[0]?.full_name,
            contacts: baseQueryResponse?.customer?.contact_persons[0]?.contacts?.map((item) => ({
                personId: item?.contact_person_id,
                type: item?.contact_type_id,
                value: item?.value,
            } as IContact)) ?? [],
        } as IContactPerson),
    ],
} as ICustomerInfo);
