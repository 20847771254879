import React, { useMemo, useState } from 'react';
import { SvgIcon, Button } from '@shared/index';
import TextField from '@entities/TextField';
import styles from './styles.module.scss';
import ListSourcesForDropdown from '../ListSourcesForDropdown';

interface IItemSelcet {
    id: number,
    label: string,
    isSelect: boolean
}
interface IPropsDropDownControls {
    lists: IItemSelcet[];
    onSetSourceInList: (listId: number[]) => void;
    onCreateList: () => void;
}
const DropDownControls: React.FC<IPropsDropDownControls> = ({
    lists, onSetSourceInList, onCreateList,
}): JSX.Element => {
    const [isOpenDropDown, setIsOpenDropDown] = useState(() => false);
    const [clonnedList, setClonnedList] = useState(() => [...lists]);
    const [search, setSearch] = useState('');
    const checkSearched = ({ label }: IItemSelcet): boolean => {
        const smalledSearch = search.toLocaleLowerCase();
        const smalledStr = label.toLocaleLowerCase();
        return smalledStr.includes(smalledSearch);
    };
    const getFiltredList = useMemo(() => clonnedList.filter(checkSearched), [lists, search, clonnedList]);

    const getStyleForList: React.CSSProperties = useMemo(() => {
        if (isOpenDropDown) return { maxHeight: 328 };
        return { maxHeight: 0 };
    }, [isOpenDropDown]);
    const changeCheked = (id: number) => {
        // onSetSourceInList(id);
        setClonnedList((prev) => prev.map((item) => ({ ...item, isSelect: id === item.id ? !item.isSelect : item.isSelect })));
    };
    const handleCancel = () => {
        setClonnedList((prev) => prev.map((item) => ({ ...item, isSelect: false })));
        setIsOpenDropDown(false);
    };
    const handleAddSourceToLists = () => {
        const getListToAdd = clonnedList.filter(({ isSelect }) => isSelect);
        onSetSourceInList(getListToAdd.map(({ id }) => id));
        setClonnedList((prev) => prev.map((item) => ({ ...item, isSelect: false })));
    };
    return (
        <div className={styles.dropDownControls}>
            <div
                role="button"
                className={`${styles.control} ${isOpenDropDown ? styles.selected : ''} `}
                onClick={() => setIsOpenDropDown((prev) => !prev)}
            >
                <div className={styles.add} />
                <div className={styles.control_label}>Добавить в список</div>
            </div>
            <div
                className={styles.listsSourcs_root}
                style={getStyleForList}

            >
                <div className={styles.listsSourcs_search}>
                    <TextField
                        value={search}
                        onChange={setSearch}
                        isSearch
                        size="medium"
                    />
                </div>
                <ListSourcesForDropdown list={getFiltredList} onChangeChecked={changeCheked} />
                <div className={styles.listsSourcs_btns}>
                    <Button
                        text="Отменить"
                        type="secondary"
                        size="medium"
                        onClick={handleCancel}
                    />
                    <Button
                        text="Добавить"
                        type="default"
                        size="medium"
                        onClick={handleAddSourceToLists}
                    />
                </div>
            </div>
            <div
                role="button"
                className={styles.control}
                onClick={() => { onCreateList(); }}
            >
                <SvgIcon id="ChangeIcon" size={20} />
                <div className={styles.control_label} style={{ marginLeft: 7 }}>Создать список</div>
            </div>
        </div>
    );
};

export default DropDownControls;
