import { IQPPostPreview, IPostPreviewFromApi } from './types';
import { rtkQApi } from '../../index';
// import campaignUtils from '../../../utils/campaign';
// import {IPosts} from "services/campaigns/tracking/models";

// const transformSingleCampaignResponse = (baseQueryResponse: IPostPreviewFromApi): IPosts => {
//     const parseFromDate = new Date(baseQueryResponse.parse_from);
//     const result = {
//         campaign_id: baseQueryResponse.campaign_id,
//         id: baseQueryResponse.post_id,
//         posted: baseQueryResponse.posted,
//         post_link: baseQueryResponse.post_link,
//         content: baseQueryResponse.content,
//         author_name: baseQueryResponse.author_name
//
//         name: baseQueryResponse.source_name,
//         parseFrom: baseQueryResponse.par,
//         parseFromDate,
//         createDate: new Date(baseQueryResponse.create_time).toLocaleDateString('ru-RU'),
//         createTimestamp: baseQueryResponse.create_time,
//         isActive: campaignUtils.campaignStatusToBool(baseQueryResponse.status.name),
//         sourceType: baseQueryResponse?.criteria?.source_type,
//         categoriesIds: baseQueryResponse?.criteria?.categories,
//         isBlogger: baseQueryResponse?.criteria?.is_blogger,
//         includeWords: baseQueryResponse?.criteria?.include_words,
//         excludeWords: baseQueryResponse?.criteria?.exclude_words,
//         sources: null,
//         excludeSources: null,
//         isComplexQuery: baseQueryResponse?.criteria?.is_complex_query,
//         searchQuery: baseQueryResponse?.criteria?.search_query,
//     } as IPosts;
//     return result;
// };

export const previewSingleCampaignRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getCampaignPostPreview: build.query<IPostPreviewFromApi[], IQPPostPreview>({
            query: (params: IQPPostPreview) => ({
                url: 'campaign/post_preview',
                method: 'POST',
                body: {
                    name: params.name,
                    parse_from: params.parseFrom,
                    criteria: {
                        categories: params.categories,
                        include_words: params.includeWords,
                        exclude_words: params.excludeWords,
                        source_type: params.sourceType,
                        is_blogger: params.isBlogger,
                        is_complex_query: params.isComplexQuery,
                        search_query: !params.searchQuery ? null : params.searchQuery,
                        sources: null,
                        exclude_sources: null,
                    },
                    short_user: {
                        user_id: params.shortUser.id,
                        first_name: params.shortUser.first_name,
                        middle_name: params.shortUser.middle_name,
                        last_name: params.shortUser.last_name,
                        email: params.shortUser.email,
                    },
                },
            }),
        }),
    }),
});
