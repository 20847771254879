import { useState } from 'react';
import IconPlus from '../../../assets/images/plus.svg';
import AddUserModal from './AddUserModal';
import styles from './styles.module.scss';
import CampaignUserRightsTable from './Table';

const CampaignUserRightsContainer = (): JSX.Element => {
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [addUserSelectedCampaignId, setAddUserSelectedCampaignId] = useState<number>();

    const handleClickCloseAddUserModal = (): void => setOpenModal(false);
    const handleClickOpenAddUserModal = (campaignId?: number): void => {
        if (campaignId) setAddUserSelectedCampaignId(campaignId);
        setOpenModal(true);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <button
                    type="button"
                    className={styles.addUserButton}
                    onClick={() => handleClickOpenAddUserModal()}
                >
                    <span> Добавить нового пользователя</span>
                    <div>
                        <img src={IconPlus} alt="" />
                    </div>
                </button>
            </div>
            <AddUserModal
                isOpen={isOpenModal}
                onClose={handleClickCloseAddUserModal}
                selectedCampaignId={addUserSelectedCampaignId}
            />
            <CampaignUserRightsTable
                handleClickOpenAddUserModal={handleClickOpenAddUserModal}
            />
        </div>
    );
};

export default CampaignUserRightsContainer;
