/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
// import moment from 'moment';
import classNames from 'classnames';
import authorIcon from './author.svg';
import CalendarIcon from './CalendarIcon.svg';
import likeIcon from './likeIcon.svg';
import okIcon from './ok.svg';
import repostsIcon from './repostsIcon.svg';
import rssIcon from './rss.svg';
import styles from './styles.module.scss';
import tgIcon from './tg.svg';
import viewsIcon from './viewsIcon.svg';
import vkIcon from './vk.svg';
// import RemoveIcon from './RemoveIcon.svg';
import { IPost } from '../../sections/MyCampaignContainer/CampaingTypes';
import useGetDate from '../../shared/hooks/useFormatDate';
import CaruselFromListLinkImages from '../CaruselFromListLinkImages';
import SvgIcon from '../SvgIcon';
import TextHeightlighting from '../TextHeightlighting';
// import ModalCardPlotComments from './ModalCardPlotComments';
import ModalCardPlotLinks from './ModalCardPlotLinks';
// import ChartEngagementDynamics from '../ChartEngagementDynamics';
import IconLink from './linkIcon.svg';
import IconExpand from './expandIcon.svg';

const platformDescriptions = {
    tg: {
        icon: tgIcon,
        label: 'Telegram',
        color: '#129FD4',
    },
    vk: {
        icon: vkIcon,
        label: 'Вконтакте',
        color: '#0077FF',
    },
    ok: {
        icon: okIcon,
        label: 'Одноклассники',
        color: '#E67E22',
    },
    rss: {
        icon: rssIcon,
        label: 'RSS',
        color: '#0EBFA1',
    },
};
const tonalityDescription = {
    negative: {
        color: '#F36869',
        name: 'Негативно',
    },
    positive: {
        color: '#0EBFA1',
        name: 'Позитивно',
    },
    neutral: {
        color: '#E67E22',
        name: 'Нейтрально',
    },
    skip: {
        color: '#dfdfdf',
        name: 'Нет данных',
    },
};
interface IProps {
    isOpen: boolean,
    post: IPost,
    closeModal: () => void
}
const ModalCardPlot: React.FC<IProps> = ({
    isOpen, post, closeModal,
}): JSX.Element => {
    const [isShowChart, setIsShowChart] = useState<boolean>(false);
    const [isMoreText, setIsMoreText] = useState<boolean>(false);
    const [isExpand, setExpand] = useState<boolean>(false);

    const getHeight = (): number => {
        if (isShowChart) return isMoreText ? 150 : 65;
        return 250;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const openChart = () => {
        setIsShowChart(true);
        setIsMoreText(true);
    };
    const getHeaightInfo = useMemo(
        () => getHeight(),
        [isShowChart],
    );
    const getTags = (list) => list.map((tag) => (
        <div className={styles.cardTag} key={`${tag}_tag_in_modal`}>
            {tag}
        </div>
    ));
    // const getDate = (unix) => moment(new Date(unix)).format('DD.MM.YYYY HH:mm');
    const [posted, setPosted] = useGetDate(new Date(post?.posted), 'DD.MM.YYYY HH:mm');
    useEffect(() => {
        setPosted(new Date(post?.posted));
    }, [post]);
    if (!isOpen) return <div />;
    return (
        <div className={classNames(styles.modalContainer, isExpand ? styles.modalContainerExpand : '')}>
            <div className={styles.modalOverlay} onClick={() => closeModal()} />
            <div className={styles.cardContainer}>
                <div className={styles.cardHeader}>
                    <div className={styles.sourceType}>
                        {
                            platformDescriptions[post.source_type]?.icon
                        && <img src={platformDescriptions[post.source_type]?.icon} alt="icn" />
                        }
                    </div>
                    <div className={styles.author}>
                        <img src={authorIcon} alt="icn" />
                        <div className={styles.authorName}>
                            {post.source_name}
                        </div>
                    </div>
                    <div className={styles.postedDate}>
                        <img src={CalendarIcon} alt="icn" />
                        <div className={styles.postedDateValue}>{posted?.date}</div>
                        {/* <div className={styles.dateValue}>{getDate(post.posted)}</div> */}
                    </div>
                    <div className={styles.tonality}>
                        <div className={styles.tonalityLabel}>Тональность:</div>
                        <div className={styles.tonalityDescription}>
                            <div className={styles.tonalityIndicator} style={{ background: tonalityDescription[post.semantic].color }}> </div>
                            <div className={styles.tonalityStatus}>{tonalityDescription[post.semantic].name}</div>
                        </div>
                    </div>
                    <div className={styles.postLink} onClick={() => window.open(post.post_link, '_blank')}>
                        <img src={IconLink} alt="IconLink" />
                        <p>пост</p>
                    </div>

                    <div className={styles.cardHeaderActions}>
                        <button type="button" onClick={() => setExpand((prev) => !prev)}>
                            <img src={IconExpand} alt="IconExpand" />
                        </button>
                        <div onClick={() => closeModal()}>
                            <SvgIcon id="Close" size={20} />
                        </div>
                    </div>
                </div>
                <div className={styles.cardContnet}>
                    <div className={styles.cardDescripton}>
                        <div className={styles.cardDescriptonTitle}>
                            <TextHeightlighting text={post.title} keyWords={[]} colorHeightlight="#DFFCF7" maxHeight={65} />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <TextHeightlighting text={post.content} keyWords={post.matched_words} colorHeightlight="#DFFCF7" maxHeight={getHeaightInfo} scroll />
                            <CaruselFromListLinkImages
                                imageList={post.photo_links}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.cardReactions}>
                    <div className={styles.iconsWithLabel}>
                        <img className={styles.iconWithLabel} src={likeIcon} alt="icn" />
                        <div>{post.likes === null ? 'Нет данных' : post.likes}</div>
                    </div>
                    <div className={styles.iconsWithLabel}>
                        <img className={styles.iconWithLabel} src={repostsIcon} alt="icn" />
                        <div>{post.reposts === null ? 'Нет данных' : post.reposts}</div>
                    </div>
                    <div className={styles.iconsWithLabel}>
                        <img className={styles.iconWithLabel} src={viewsIcon} alt="icn" />
                        <div>{post.views === null ? 'Нет данных' : post.views}</div>
                    </div>
                </div>
                <div className={styles.cardTags}>
                    {getTags(post.tags || [])}
                    {/* <div className={styles.addTag}>+Добавить теги</div> */}
                </div>
                <ModalCardPlotLinks links={post.links} />
                {/* <ModalCardPlotComments postId={post.post_id} /> */}
                {/* <ChartEngagementDynamics isOpen={isShowChart} close={() => setIsShowChart(false)} /> */}
            </div>
        </div>
    );
};

export default ModalCardPlot;
