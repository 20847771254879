import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Path from '@shared/lib/paths';
import ScalesIcon from '@assets/images/NarrowMenu/scales.svg';
import { ISimpleEntity } from '@store/types';
import SvgIcon from '@components/SvgIcon';
import { CommonIconsIds } from '@app/constants/icons';
import { sourcesListsRtkQService } from '@services/sources/lists';
import { INarrowMenuData } from '../../NarrowMenu/types';
import CampaignMenuItems from './CampaignItems';
import styles from './styles.module.scss';

interface ILeftNarrowMenuProps {
    onNavigate: (path: string) => void;
    location: string;
    setMenuExpanded: (bool: boolean) => void;
    activeCampaignId: number | null;
    activeCampaign: ISimpleEntity | null;
    setActiveCampaign: (id: number) => void;
}

const campaignMenuId = '1';
const settingsMenuId = '2';
const sourcesMenuId = '3';
const projectMenuId = '5';

const prepareMenuData = (campaignId: number): INarrowMenuData[] => [
    {
        id: campaignMenuId,
        tagId: 'campaign',
        href: `/${Path.Campaign}/${Path.CampaignList}`,
        Icon: <SvgIcon id="CampaignMenuIcon" size={24} />,
        ActiveIcon: <SvgIcon id="CampaignMenuIcon" size={24} />,
        label: 'Кампании',
        title: 'Кампании',
        expanded: false,
        active: false,
        subItems: [
            {
                id: '31',
                tagId: 'campaign-updates',
                href: `/${Path.Campaign}/${Path.CampaignUpdate}/${campaignId}`,
                Icon: <SvgIcon id="ParametersIcon" size={16} />,
                ActiveIcon: <SvgIcon id="ParametersIcon" size={16} />,
                label: 'Параметры',
                title: 'Параметры',
                expanded: false,
                active: false,
                subItems: [],
            },
            {
                id: '32',
                tagId: 'campaign-tracking',
                href: `/${Path.Campaign}/${campaignId}`,
                Icon: <SvgIcon id="ClockIcon" size={16} />,
                ActiveIcon: <SvgIcon id="ClockIcon" size={16} />,
                label: 'Отслеживание',
                title: 'Отслеживание',
                expanded: false,
                active: false,
                subItems: [],
            },
            {
                id: '33',
                tagId: 'campaign-comparison',
                href: `/${Path.Campaign}/${Path.CampaignComparisonsList}`,
                Icon: <img src={ScalesIcon} alt="ScalesIcon" height="16" />,
                ActiveIcon: <img src={ScalesIcon} alt="ScalesIcon" height="16" />,
                label: 'Конкурентные анализы',
                title: 'Конкурентные анализы',
                expanded: false,
                active: false,
                subItems: [],
            },
            {
                id: '34',
                tagId: 'campaign-charts',
                href: `/${Path.Campaign}/${Path.Charts}/${campaignId}`,
                Icon: <SvgIcon id="ChartsIcon" size={16} />,
                ActiveIcon: <SvgIcon id="ChartsIcon" size={16} />,
                label: 'Графики',
                title: 'Графики',
                expanded: false,
                active: false,
                subItems: [],
            },
            {
                id: '35',
                tagId: 'campaign-reports',
                href: `/${Path.Campaign}/${Path.Reports}/${campaignId}`,
                Icon: <SvgIcon id="ReportsIcon" size={16} />,
                ActiveIcon: <SvgIcon id="ReportsIcon" size={16} />,
                label: 'Отчеты',
                title: 'Отчеты',
                expanded: false,
                active: false,
                subItems: [],
            },
            {
                id: '36',
                tagId: 'campaign-tags',
                href: `/${Path.Campaign}/${Path.Tags}/${campaignId}`,
                Icon: <SvgIcon id="TagsIcon" size={16} />,
                ActiveIcon: <SvgIcon id="TagsIcon" size={16} />,
                label: 'Теги',
                title: 'Теги',
                expanded: false,
                active: false,
                subItems: [],
            },
            {
                id: '37',
                tagId: 'campaign-authors',
                href: `/${Path.Campaign}/${Path.Authors}/${campaignId}`,
                Icon: <SvgIcon id="AuthorsIcon" size={16} />,
                ActiveIcon: <SvgIcon id="AuthorsIcon" size={16} />,
                label: 'Авторы',
                title: 'Авторы',
                expanded: false,
                active: false,
                subItems: [],
            },
            // {
            //     id: '38',
            //     tagId: 'campaign-links',
            //     href: `/${Path.Campaign}/${Path.CampaignList}`,
            //     Icon: <img src={LinksIcon} alt="LinksIcon" width="16" />,
            //     ActiveIcon: <img src={LinksIcon} alt="LinksIcon" width="16" />,
            //     label: 'Ссылки',
            //     title: 'Ссылки',
            //     expanded: false,
            //     active: false,
            //     subItems: [],
            // },
            {
                id: '39',
                tagId: 'campaign-trash-can',
                href: `/${Path.Campaign}/${Path.CampaignBasket}/${campaignId}`,
                Icon: <SvgIcon id="TrashCanIcon" size={16} />,
                ActiveIcon: <SvgIcon id="TrashCanIcon" size={16} />,
                label: 'Корзина',
                title: 'Корзина',
                expanded: false,
                active: false,
                subItems: [],
            },
        ],
    },
    {
        id: settingsMenuId,
        tagId: 'properties',
        href: null,
        Icon: <SvgIcon id="SettingsIcon" size={24} />,
        ActiveIcon: <SvgIcon id="SettingsIcon" size={24} />,
        label: 'Настройки',
        title: 'Настройки',
        active: false,
        expanded: false,
        subItems: [
            {
                id: '21',
                tagId: 'properties-rights',
                href: `/${Path.Settings}/${Path.UserRights}`,
                Icon: null,
                ActiveIcon: null,
                label: 'Права',
                title: 'Права',
                expanded: false,
                active: false,
                subItems: [],
            },
            {
                id: '22',
                tagId: 'properties-notifications',
                href: `/${Path.Settings}/${Path.Notification}`,
                Icon: null,
                ActiveIcon: null,
                label: 'Уведомления',
                title: 'Уведомления',
                active: false,
                expanded: false,
                subItems: [],
            },
        ],
    },
    {
        id: projectMenuId,
        tagId: 'projects',
        href: `/${Path.Projects}/${Path.ProjectList}`,
        Icon: <SvgIcon id="ProjectsIcon" size={24} />,
        ActiveIcon: <SvgIcon id="ProjectsIcon" size={24} />,
        label: 'Проекты',
        title: 'Проекты',
        active: false,
        expanded: false,
        subItems: [],
    },
    {
        id: sourcesMenuId,
        tagId: 'sources',
        href: null,
        Icon: <SvgIcon id="SourcesIcon" size={24} />,
        ActiveIcon: <SvgIcon id="SourcesIcon" size={24} />,
        label: 'Источники',
        title: 'Источники',
        active: false,
        expanded: false,
        subItems:
            [
                //     {
                //         id: '31',
                //         tagId: 'sources-comparison',
                //         href: `/${Path.Sources}/${Path.SourcesList}`,
                //         Icon: <img src={ScalesIcon} alt="ScalesIcon" height="16" />,
                //         ActiveIcon: <img src={ScalesIcon} alt="ScalesIcon" height="16" />,
                //         label: 'Сравнение источников',
                //         title: 'Сравнение источников',
                //         expanded: false,
                //         active: false,
                //         subItems: [],
                //     },
                //     {
                //         id: '32',
                //         tagId: 'sources-reports',
                //         href: `/${Path.Sources}/${Path.SourcesList}`,
                //         Icon: <img src={ReportsIcon} alt="ScalesIcon" height="16" />,
                //         ActiveIcon: <img src={ReportsIcon} alt="ScalesIcon" height="16" />,
                //         label: 'Отчеты',
                //         title: 'Отчеты',
                //         active: false,
                //         expanded: false,
                //         subItems: [],
                //     },
                {
                    id: '32',
                    tagId: 'sources-list',
                    href: `/${Path.Sources}/${Path.SourcesList}`,
                    Icon: <SvgIcon id={CommonIconsIds.SourceInactiveIcon} size={16} />,
                    ActiveIcon: <SvgIcon id={CommonIconsIds.SourcesIcon} size={16} />,
                    label: 'Источники',
                    title: 'Источники',
                    active: false,
                    expanded: false,
                    subItems: [],
                },
                {
                    id: '33',
                    tagId: 'sources-lists',
                    href: `/${Path.Sources}/${Path.SourcesRoll}`,
                    Icon: <SvgIcon id={CommonIconsIds.SourcesList} size={16} height={17} />,
                    ActiveIcon: <SvgIcon id={CommonIconsIds.SourcesListActive} size={16} height={17} />,
                    label: 'Списки',
                    title: 'Списки',
                    active: false,
                    expanded: false,
                    subItems: [],
                },
                {
                    id: '34',
                    tagId: 'sources-favourites',
                    href: `/${Path.Sources}/${Path.SourcesFavorite}`,
                    Icon: <SvgIcon id={CommonIconsIds.SourcesFavourite} size={18} height={20} />,
                    ActiveIcon: <SvgIcon id={CommonIconsIds.SourcesFavouriteActive} size={18} height={20} />,
                    label: 'Избранное',
                    title: 'Избранное',
                    active: false,
                    expanded: false,
                    subItems: [],
                },
                //     {
                //         id: '34',
                //         tagId: 'sources-authors',
                //         href: `/${Path.Sources}/${Path.SourcesList}`,
                //         Icon: <img src={AuthorsIcon} alt="ScalesIcon" height="16" />,
                //         ActiveIcon: <img src={AuthorsIcon} alt="ScalesIcon" height="16" />,
                //         label: 'Авторы',
                //         title: 'Авторы',
                //         active: false,
                //         expanded: false,
                //         subItems: [],
                //     },
            ],
    },
    // {
    //     id: '4',
    //     tagId: 'dashboard',
    //     href: `/${Path.CampaignDashboard}`,
    //     Icon: <SvgIcon id="DashboardIcon" size={24} />,
    //     ActiveIcon: <SvgIcon id="DashboardIcon" size={24} />,
    //     label: 'Дашборд',
    //     title: 'Дашборд',
    //     expanded: false,
    //     active: false,
    //     subItems: [],
    // },
    // {
    //     id: '3',
    //     tagId: 'info-basket',
    //     href: `${Path.InfoBasket}`,
    //     Icon: <img src={BasketIcon} alt="InfoTrace" width="24" style={{ minWidth: 24 }} />,
    //     ActiveIcon: <img src={BasketIcon} alt="PropertiesIcon" width="24" style={{ minWidth: 24 }} />,
    //     label: 'Корзина',
    //     title: 'Корзина',
    //     expanded: false,
    //     active: false,
    //     subItems: [],
    // },
];

const LeftNarrowMenu: React.FC<ILeftNarrowMenuProps> = ({
    onNavigate,
    location,
    setMenuExpanded,
    activeCampaignId,
    activeCampaign,
    setActiveCampaign,
}): JSX.Element => {
    const [menuData, setMenuData] = useState<INarrowMenuData[]>(() => prepareMenuData(activeCampaignId));

    const [getSourcesListsCounts,
        {
            data: sourcesListsCounts,
            isSuccess: isSuccessSourcesCounts, isError: isErrorSourcesCounts,
        }] = sourcesListsRtkQService.useLazyGetListsCountsQuery();

    const checkActiveItem = (id: string, href: string, path: string): boolean => {
        if (!href) {
            return false;
        }
        // if (['31', '32', '33', '34'].includes(id)) {
        //     return false;
        // }
        // TODO подумать как выделить пункт кампании если мы в дочерних элементах кампании
        if (
            (
                (id === campaignMenuId && path.includes(Path.Campaign))
                || (id === sourcesMenuId && path.includes(Path.Sources))
            )
            && !(
                path.includes(Path.UserRights)
                || path.includes(Path.Notification)
                || path.includes(Path.CampaignDashboard)
            )
        ) {
            return true;
        }
        return path.includes(href);
    };
    const checkActiveSubItems = (parentId: string, items: INarrowMenuData[], path: string): boolean => items?.some(
        (item) => checkActiveItem(parentId, item.href, path),
    );
    const getExpandedState = (item: INarrowMenuData, path: string): boolean => (
        checkActiveSubItems(item.id, item?.subItems, path) ? true : !item.expanded
    );
    const setMenuDataIfChangeLocation = useCallback((menu: INarrowMenuData[], compareId: string, comparePath: string): void => {
        if (!compareId || Number(compareId) === 0) {
            return;
        }
        const result = menu.map((element) => ({
            ...element,
            expanded: compareId === element.id ? getExpandedState(element, location) : false,
            subItems: [sourcesMenuId].includes(compareId)
                ? element.subItems
                : element.subItems.map((subElement) => ({
                    ...subElement, active: !comparePath ? subElement.active : subElement.href.includes(comparePath),
                })),
        }));
        setMenuData(result);
    }, [setMenuData, location]);

    useEffect(() => {
        getSourcesListsCounts();
    }, []);

    const handlerMenuClick = (item: INarrowMenuData, path: string): void => {
        if (item.tagId === 'properties' || item.tagId === 'sources') {
            setMenuExpanded(true);
        } else {
            setMenuExpanded(false);
        }
        setMenuDataIfChangeLocation(menuData, item.id, path);
        if (item.id === sourcesMenuId) {
            onNavigate(path);
            return;
        }
        if (!item.subItems?.length || item.id === campaignMenuId) {
            onNavigate(path);
        }
    };

    useEffect(() => {
        if (location.includes(Path.UserRights)) {
            setMenuDataIfChangeLocation(menuData, settingsMenuId, Path.UserRights);
            return;
        }
        if (location.includes(Path.Notification)) {
            setMenuDataIfChangeLocation(menuData, settingsMenuId, Path.Notification);
            return;
        }
        if (location.includes(`/${Path.Sources}/${Path.SourcesList}`)) {
            setMenuDataIfChangeLocation(menuData, sourcesMenuId, `/${Path.Sources}/${Path.SourcesList}`);
            return;
        }
        if (location.includes(`/${Path.Sources}/${Path.SourcesRoll}`)) {
            setMenuDataIfChangeLocation(menuData, sourcesMenuId, `/${Path.Sources}/${Path.SourcesRoll}`);
            return;
        }
        if (location.includes(`/${Path.Sources}/${Path.SourcesFavorite}`)) {
            setMenuDataIfChangeLocation(menuData, sourcesMenuId, `/${Path.Sources}/${Path.SourcesFavorite}`);
            return;
        }
        setMenuDataIfChangeLocation(menuData, '0', 'empty');
    }, [location]);
    useEffect(() => {
        if (Number.isInteger(activeCampaignId) && activeCampaignId > 0) {
            setMenuData(prepareMenuData(activeCampaignId));
        }
    }, [activeCampaignId]);
    useEffect(() => {
        if (isSuccessSourcesCounts || isErrorSourcesCounts) {
            setMenuData((prev) => prev.map((item) => {
                if (item.id !== sourcesMenuId) return item;
                return {
                    ...item,
                    subItems: item.subItems.map((subItem) => {
                        if (subItem.id === '33') {
                            return { ...subItem, counter: isSuccessSourcesCounts ? sourcesListsCounts?.listsCount : '?' };
                        }
                        if (subItem.id === '34') {
                            return { ...subItem, counter: isSuccessSourcesCounts ? sourcesListsCounts?.favoriteCount : '?' };
                        }
                        return subItem;
                    }),
                };
            }));
        }
    }, [sourcesListsCounts, isErrorSourcesCounts, isSuccessSourcesCounts]);
    return (
        <div className={styles.root}>
            {
                menuData.map((item) => (
                    <li
                        key={item.id}
                    >
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                        <div
                            id={item.tagId}
                            onClick={() => handlerMenuClick(item, item.href)}
                            className={classnames(styles.linkWrapper)}
                        >
                            {
                                checkActiveItem(item.id, item.href, location)
                                    ? <div className={styles.icon}>{item.ActiveIcon}</div>
                                    : <div className={styles.icon}>{item.Icon}</div>
                            }
                            <div className={classnames(styles.linkTitle, styles[checkActiveItem(item.id, item.href, location) ? 'active' : ''])}>
                                {item.title}
                            </div>
                            {
                                item.id !== campaignMenuId && item.subItems?.length > 0 && (
                                    item.expanded
                                        ? <SvgIcon id="InactiveArrowIcon" size={12} />
                                        : <SvgIcon id="ActiveArrowIcon" size={12} />
                                )
                            }
                        </div>
                        {
                            item.id === campaignMenuId && activeCampaignId > 0 && (
                                <div className={styles.subLink} style={{ gap: 16 }}>
                                    <CampaignMenuItems
                                        campaignId={activeCampaignId}
                                        items={item.subItems}
                                        location={location}
                                        campaignName={activeCampaign?.name ?? '...'}
                                        onNavigate={onNavigate}
                                        setActiveCampaign={setActiveCampaign}
                                    />
                                </div>
                            )
                        }
                        {
                            item.id !== campaignMenuId && Boolean(item.subItems?.length) && item?.expanded && (
                                <div className={styles.subLink}>
                                    {
                                        item.id !== campaignMenuId
                                        && item.subItems.map((subItem) => (
                                            <button
                                                id={subItem.tagId}
                                                type="button"
                                                key={subItem.id}
                                                className={classnames(styles.subLink_item, styles[subItem.active ? 'subLink_active' : ''])}
                                                onClick={() => handlerMenuClick(subItem, subItem.href)}
                                            >
                                                {
                                                    (subItem.Icon || subItem.ActiveIcon) && (
                                                        <div style={{
                                                            minWidth: 24, maxWidth: 24,
                                                        }}
                                                        >
                                                            {
                                                                checkActiveItem(subItem.id, subItem.href, location)
                                                                    ? subItem.ActiveIcon
                                                                    : subItem.Icon

                                                            }
                                                        </div>
                                                    )
                                                }
                                                <span style={{ display: 'flex', textAlign: 'left' }}>
                                                    {subItem.title}
                                                </span>
                                                {subItem.counter !== undefined
                                                && (
                                                    <div className={styles.subLinkCounter}>
                                                        {subItem.counter}
                                                    </div>
                                                )}
                                            </button>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </li>
                ))
            }
        </div>
    );
};

export default LeftNarrowMenu;
