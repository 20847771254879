import React from 'react';
import ModalBigCrossIcon from '@assets/images/crossLite.svg';
import styles from './styles.module.scss';
import CampaignSearchRequestContainer from '../../../../../containers/campaign/SearchRequest';
import { ISearchGroup } from '../../types';

interface IProps {
    searchRequest: ISearchGroup,
    isOpen: boolean;
    isView: boolean;
    onClose: () => void;
    onSave: (searchString) => void;
}

const SearchRequestPageModal: React.FC<IProps> = ({
    searchRequest,
    isView,
    isOpen,
    onClose,
    onSave,
}): JSX.Element => {
    const handlerCloseModal = (): void => {
        onClose();
    };

    const handlerSave = (searchString: string): void => {
        if (!isView) {
            onSave(searchString);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.searchRequestPageModal_root}>
            <div className={styles.modalWindow}>
                <div className={styles.flexColumn}>
                    <div className={styles.flexRow} style={{ justifyContent: 'space-between', height: 38 }}>
                        <div className={styles.contentContainer_title}>
                            Поисковый запрос
                        </div>
                        <div className={styles.modalCrossWrapper}>
                            <button type="button" style={{ padding: 0, background: 'none' }} onClick={handlerCloseModal}>
                                <img src={ModalBigCrossIcon} alt="ModalBigCrossIcon" />
                            </button>
                        </div>
                    </div>
                    <div style={{
                        maxWidth: 1476, minWidth: 1076, minHeight: 654,
                    }}
                    >
                        <CampaignSearchRequestContainer
                            isView={isView}
                            onSave={handlerSave}
                            searchRequest={searchRequest}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchRequestPageModal;
