import React, { useState } from 'react';
import { IUserInfoProps } from 'components/UserProfile/UserInfo/types';
import classnames from 'classnames';
import TextField from '@entities/TextField';
import styles from './styles.module.scss';

const UserInfo: React.FC<IUserInfoProps> = ({
    name,
    email,
    onChangeName,
    nameIsChanged,
    emailIsChanged,
    onSave,
    isLoading,
}: IUserInfoProps): JSX.Element => {
    const [nameIsEditing, setNameEdit] = useState<boolean>(false);
    // const [emailIsEditing, setEmailEdit] = useState<boolean>();

    const onEditingNameClick = () => {
        setNameEdit(!nameIsEditing);
    };

    const handlerChangeValue = (value: string): void => {
        onChangeName(value);
    };

    const handlerClickBtn = (): void => {
        onSave();
    };

    return (
        <div className={styles.root}>
            <div className={styles.itemContainer}>
                <div className={styles.itemContainer_title}>ФИО</div>
                <div className={styles.itemContainer_textContainer}>
                    {!nameIsEditing && (
                        <div
                            className={classnames(
                                styles.itemContainer_textContainer,
                                styles.itemContainer_textContainer_text,
                            )}
                        >
                            {name?.length > 50 ? `${name.substring(0, 50)}...` : name}
                        </div>
                    )}
                    {nameIsEditing && (
                        <TextField
                            value={name}
                            onChange={handlerChangeValue}
                            disabled={!nameIsEditing || isLoading}
                            limit={80}
                        />
                    )}
                </div>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img
                    className={styles.profileEditImage}
                    id="profile-edit"
                    style={{ cursor: 'pointer' }}
                    src="/images/edit.svg"
                    alt="edit"
                    onClick={onEditingNameClick}
                />
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                    id="profile-save"
                    className={classnames(
                        styles.saveButton,
                        styles[nameIsChanged ? '' : 'invisible'],
                    )}
                    disabled={!nameIsChanged || name?.length <= 0}
                    onClick={handlerClickBtn}
                >
                    <span
                        className={classnames(
                            styles.saveButton_text,
                            styles[name?.length > 0 ? 'enable' : 'disable'],
                        )}
                    >
                        Сохранить
                    </span>
                </button>
            </div>
            <div className={styles.itemContainer}>
                <div className={styles.itemContainer_title}>E-mail</div>
                <div className={styles.itemContainer_textContainer}>
                    <div
                        className={classnames(
                            styles.itemContainer_textContainer,
                            styles.itemContainer_textContainer_text,
                        )}
                    >
                        {email}
                    </div>
                </div>
                <div style={{ width: 100 }}>
                    {/* <img style={{ cursor: 'pointer' }} src="/images/edit.svg" alt="edit" /> */}
                </div>
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                    className={classnames(
                        styles.saveButton,
                        styles.saveButton_text,
                        styles[emailIsChanged ? '' : 'invisible'],
                    )}
                    disabled={!nameIsChanged}
                    onClick={handlerClickBtn}
                >
                    сохранить
                </button>
            </div>
        </div>
    );
};

export default UserInfo;
