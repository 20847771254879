import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FiltersValuesTypes } from 'store/infoTrace';
import { IMessage, MessageType } from '../../types';
import {
    AnalyzeLinkStatus, IAnalyzeFetchOutputData, IAnalyzeLinkState, IAnalyzeResult,
} from './types';

const initialAnalyzeResult: IAnalyzeResult = {
    mlResult: {
        analytics: null,
        quotations: null,
        authors: null,
    },
    link: null,
    activePeriod: 0,
    logo: null,
    content: '',
    newsId: '',
    source: '',
    taskId: '',
    title: null,
    postedTimestamp: '',
};

const initialState: IAnalyzeLinkState = {
    outputFetchData: {
        idempotencyKey: '',
        filter: 0,
        link: '',
    },
    outputTextFetchData: {
        idempotencyKey: '',
        filter: 0,
        text: '',
    },
    taskId: '',
    link: '',
    text: '',
    analyzeResult: initialAnalyzeResult,
    status: AnalyzeLinkStatus.undefined,
    mainPostStatus: AnalyzeLinkStatus.undefined,
    activeFilter: 168,
    needSearchForTextAfterChangeFilter: false,
    needSearchForLinkAfterChangeFilter: false,
};

const { actions: analyzeLinkActions, reducer } = createSlice({
    name: 'analyzeLink',
    initialState,
    reducers: {
        setLink: (state: IAnalyzeLinkState, { payload }: PayloadAction<string>): void => {
            state.link = payload;
        },
        setText: (state: IAnalyzeLinkState, { payload }: PayloadAction<string>): void => {
            state.text = payload;
        },
        setOutputFetchData: (state: IAnalyzeLinkState, { payload }: PayloadAction<IAnalyzeFetchOutputData>): void => {
            state.outputFetchData = { ...payload };
        },
        setOutputTextFetchData: (state: IAnalyzeLinkState, { payload }: PayloadAction<IAnalyzeFetchOutputData>): void => {
            state.outputTextFetchData = { ...payload };
        },
        startLoading: (state: IAnalyzeLinkState): void => {
            state.status = AnalyzeLinkStatus.process;
            state.mainPostStatus = AnalyzeLinkStatus.process;
        },
        stopCheckAnalyze: (state: IAnalyzeLinkState): void => {
            state.status = AnalyzeLinkStatus.analyzeStop;
        },
        successAnalyze: (state: IAnalyzeLinkState): void => {
            state.status = AnalyzeLinkStatus.success;
        },
        successLoading: (state: IAnalyzeLinkState, { payload }: PayloadAction<IAnalyzeResult>): void => {
            if (!payload.mlResult || !payload.mlResult.quotations?.length) {
                state.status = AnalyzeLinkStatus.checkAnalyze;
            } else {
                state.status = AnalyzeLinkStatus.success;
            }

            state.mainPostStatus = AnalyzeLinkStatus.success;
            state.analyzeResult = payload;
            state.taskId = payload.taskId;
        },
        failedLoading: (state: IAnalyzeLinkState, { payload }: PayloadAction<string>): void => {
            state.message = {
                type: MessageType.error,
                description: payload,
            };
            state.mainPostStatus = AnalyzeLinkStatus.failed;
            state.status = AnalyzeLinkStatus.failed;
        },
        setTaskId: (state: IAnalyzeLinkState, { payload }: PayloadAction<string>): void => {
            state.taskId = payload;
        },
        setMessage: (state: IAnalyzeLinkState, { payload }: PayloadAction<IMessage>): void => {
            state.message = !payload ? null : { ...payload };
        },
        clearMessages: (state: IAnalyzeLinkState): void => {
            state.message = null;
        },
        setActiveFilter: (state: IAnalyzeLinkState, { payload }: PayloadAction<FiltersValuesTypes>): void => {
            state.activeFilter = payload;
            state.status = AnalyzeLinkStatus.failed;
            state.needSearchForLinkAfterChangeFilter = true;
            state.needSearchForTextAfterChangeFilter = true;
        },
        completeSearchAfterChangeFilterForLink: (state: IAnalyzeLinkState): void => {
            state.needSearchForLinkAfterChangeFilter = false;
        },
        completeSearchAfterChangeFilterForText: (state: IAnalyzeLinkState): void => {
            state.needSearchForTextAfterChangeFilter = false;
        },
        setTextFieldMessage: (state: IAnalyzeLinkState, { payload }: PayloadAction<IMessage>): void => {
            state.textFieldMessage = !payload ? null : { ...payload };
        },
    },
});

export { analyzeLinkActions };
export default reducer;
