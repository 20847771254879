import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import SourcesList from '@components/sources/List';
import {
    IEntity,
    INumbersInterval,
    IQPSaveAdvancedFilters,
    IQPSourcesWithSortingAndFiltering,
    ISource,
    ISourceRow,
    RangeFilter,
} from '@services/sources/types';
import { ICategory } from '@store/types';
import sourceMaps from '@app/constants/sourceMaps';
import { Button } from '@shared/index';
import SvgIcon from '@components/SvgIcon';
import { projectLonglistRtkQService } from '@services/projects/longlist';
import QuickSVGLoader from '@components/QuickSVGLoader';
import { sourcesAdvancedFiltersRtkQService, sourcesRtkQService } from '@services/sources';
import AdvancedSearch from '@widgets/sources/sidepage/AdvancedSearch';
import { SourcesFiltersTypes } from '@sections/Source/types';
import { sourceListActions } from '@store/source/list';
import { useAppDispatch } from '@store/hooks';
import TextField from '@entities/TextField';
import { ReplaySubject } from 'rxjs';
import styles from './styles.module.scss';

interface IProps {
    isOpen: boolean;
    isLoadingPlatforms: boolean;
    isLoadingCategories: boolean;
    sources?: ISource;
    projectId: number;
    platforms: IEntity[];
    categories: ICategory[];
    onClose: () => void;
    onGetSources: () => void;
}

const searchValue$: ReplaySubject<string> = new ReplaySubject<string>();

// const changeSearchValueFlow = (value: string): void => searchValue$.next(value);

// @n.sychev TODO перейти на structuredClone после задач по "приборке" npm пакетов в packaje.json и обновлении ts
const deepCopy = (value: any): any => JSON.parse(JSON.stringify(value));

const SourcesForProject: React.FC<IProps> = ({
    isOpen,
    isLoadingPlatforms,
    isLoadingCategories,
    platforms,
    categories,
    projectId,
    onClose,
    sources,
    onGetSources,
}): JSX.Element => {
    const dispatch = useAppDispatch();

    const [activeRowIds, setActiveRowIds] = useState<number[]>([]);
    const [selectedSources, setSources] = useState<ISourceRow[]>(sources?.sources || []);
    const [activeSidePage, setActiveSidePage] = useState<boolean>(false);
    const [advancedFilters, setFilters] = useState<IQPSourcesWithSortingAndFiltering>(
        deepCopy(sourceMaps.initialFilters),
    );
    const [searchValue, setSearchValue] = useState<string>('');

    const [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        addSourcesInProject,
        {
            isLoading,
            isSuccess,
        },
    ] = projectLonglistRtkQService.useAddSourceInProjectMutation();
    const {
        data: filtersRanges,
        isLoading: isLoadingRanges,
        isFetching: isFetchingRanges,
        isError: isErrorRanges,
    } = sourcesRtkQService.useGetFiltersRangesQuery();
    const [
        saveAdvancedFilters,
    ] = sourcesAdvancedFiltersRtkQService.usePostSaveFiltersMutation();

    const addBtnIsDisable = useMemo<boolean>(() => (
        selectedSources?.length <= 0 || !projectId || isLoading
    ), [isLoading, projectId, selectedSources]);

    const handlerChangeSearchValue = (value: string): void => {
        setSearchValue(value);
        searchValue$.next(value);
    };

    const handlerSelectRow = (value: ISourceRow): void => {
        if (value?.id > 0) {
            setActiveRowIds((prev) => (
                prev.includes(value.id) ? prev.filter((id) => id !== value.id) : [...prev, value.id]
            ));
            setSources((prev) => (
                prev.some(({ id }) => id === value.id)
                    ? prev.filter(({ id }) => id !== value.id)
                    : [...prev, value]
            ));
        }
    };
    const handlerAppSources = useCallback((): void => {
        const newSources = selectedSources?.filter(({ sourceId }) => !sourceId);
        if (newSources?.length > 0 && projectId > 0) {
            addSourcesInProject({ projectId, sources: newSources });
        }
        setSources([]);
    }, [projectId, selectedSources]);

    const handlerSelectFilter = useCallback((ids: number[], filterName: string): void => {
        const newFilters = {
            ...advancedFilters,
            filterParams: { ...advancedFilters?.filterParams, [filterName]: ids },
            page: 1,
        } as IQPSourcesWithSortingAndFiltering;
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerSelectRangeFilter = useCallback((range: INumbersInterval | null, filterName: string): void => {
        let newFilters: IQPSourcesWithSortingAndFiltering = {
            ...advancedFilters,
            rangeParams: { ...advancedFilters?.rangeParams, [filterName]: range },
            page: 1,
        };
        if (range === null) {
            const ranges = null;
            Object.keys(newFilters.rangeParams).forEach((key) => {
                if (newFilters.rangeParams[key] !== null) {
                    ranges[key] = newFilters.rangeParams[key];
                }
            });
            newFilters = { ...newFilters, rangeParams: (ranges === null) ? null : ranges };
        }
        setFilters(newFilters);
    }, [advancedFilters]);

    const handlerApplyAdvancedSearch = useCallback((ranges: RangeFilter, title: string): void => {
        const rangesForApi = {};
        const rangesForStore = {};
        if (ranges) {
            Object.keys(ranges).forEach((key) => {
                rangesForApi[key] = { from_: ranges[key].from, to_: ranges[key].to };
                rangesForStore[key] = { from: ranges[key].from, to: ranges[key].to };
            });
        }
        const newFilters = {
            ...advancedFilters.filterParams,
            ...rangesForApi,
            project_id: projectId,
        } as IQPSaveAdvancedFilters;
        saveAdvancedFilters(newFilters);
        const filtersForSources = {
            ...advancedFilters,
            rangeParams: { ...rangesForStore },
            searchValue: title,
        } as IQPSourcesWithSortingAndFiltering;
        setSearchValue(title);
        dispatch(sourceListActions.setFilters(filtersForSources));
    }, [advancedFilters, projectId]);

    const handlerClearFilters = (): void => {
        setSearchValue('');
        handlerChangeSearchValue('');
    };

    useEffect(() => {
        if (!isLoading && isSuccess) {
            onGetSources();
            onClose();
        }
    }, [isLoading, isSuccess]);

    useEffect(() => {
        if (!isLoadingRanges && !isFetchingRanges && !isErrorRanges) {
            setFilters((prev) => (
                {
                    ...prev,
                    rangeParams: {
                        ...prev.rangeParams,
                        [SourcesFiltersTypes.subscribers]: {
                            from: prev.rangeParams[SourcesFiltersTypes.subscribers]?.from,
                            to: filtersRanges.subscribers?.to,
                        },
                        [SourcesFiltersTypes.views]: {
                            from: prev.rangeParams[SourcesFiltersTypes.views]?.from,
                            to: filtersRanges.viewsAvg?.to,
                        },
                        [SourcesFiltersTypes.er]: {
                            from: prev.rangeParams[SourcesFiltersTypes.er]?.from,
                            to: filtersRanges.erAvg?.to,
                        },
                    },
                }
            ));
        }
    }, [
        filtersRanges,
        isLoadingRanges,
        isFetchingRanges,
        isErrorRanges,
    ]);

    if (!isOpen) {
        return null;
    }
    return (
        <div className={styles.sourcesForProject_root}>
            <div className={styles.sourcesForProject_window}>
                <div className={styles.sourcesForProject_container}>
                    <div className={styles.sourcesForProject_top}>
                        <button
                            type="button"
                            onClick={onClose}
                        >
                            <SvgIcon id="BigCross" size={22} />
                        </button>
                    </div>
                    <div style={{ display: 'flex', gap: 24, width: 600 }}>
                        <TextField
                            size="large"
                            placeholder="Поиск источника"
                            value={searchValue}
                            onChange={handlerChangeSearchValue}
                        />
                        <Button text="Расширенный поиск" type="secondary" size="large" onClick={() => setActiveSidePage(true)} />
                    </div>
                    {
                        (isLoading) && (
                            <div className={styles.sourcesForProject_loadingBox}>
                                <QuickSVGLoader size="large" />
                                <span>Добавление источников в проект...</span>
                            </div>
                        )
                    }
                    {
                        (!isLoading) && (
                            <>
                                <SourcesList
                                    isLoadingPlatforms={isLoadingPlatforms}
                                    isLoadingCategories={isLoadingCategories}
                                    platforms={platforms}
                                    categoriesData={categories}
                                    platformByNameMap={sourceMaps.platformByNameMap}
                                    platformsIcon={sourceMaps.platformsIcon}
                                    platformByIdMap={sourceMaps.platformByIdMap}
                                    clearFilters={handlerClearFilters}
                                    limitSourcesInPage={35}
                                    isSelected
                                    activeRowIds={activeRowIds}
                                    onSelectRow={handlerSelectRow}
                                    ranges={filtersRanges}
                                    searchValueFlow={searchValue$}
                                    invisibleIds={sources?.sources?.map(({ sourceId }) => sourceId)}
                                />
                                <div className={styles.sourcesForProject_bottomControls}>
                                    <Button
                                        text="Добавить в лонглист"
                                        type="default"
                                        size="large"
                                        onClick={handlerAppSources}
                                        disabled={addBtnIsDisable}
                                    />
                                </div>
                            </>
                        )
                    }
                    {activeSidePage && (
                        <AdvancedSearch
                            currentFilters={advancedFilters}
                            activeSidePage={activeSidePage}
                            setActiveSidePage={setActiveSidePage}
                            platforms={platforms}
                            categories={categories}
                            isLoadingPlatforms={isLoadingPlatforms}
                            isLoadingCategories={isLoadingCategories}
                            platformsIcon={sourceMaps.platformsIcon}
                            platformByIdMap={sourceMaps.platformByIdMap}
                            searchValue={null}
                            onSelectFilter={handlerSelectFilter}
                            onSelectRangeFilters={handlerSelectRangeFilter}
                            onApply={handlerApplyAdvancedSearch}
                            ranges={filtersRanges}
                            withoutSearch
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SourcesForProject;
