import React from 'react';
import { Button } from '@shared/index';
import styles from '../styles.module.scss';

interface IProps {
    setOpen: (value: boolean) => void;
    isOpen: boolean;
    onCancel?: () => void;
}

const CampaignMenuHint: React.FC<IProps> = ({
    isOpen,
    setOpen,
    onCancel,
}): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlerCloseClick = (): void => {
        if (setOpen && isOpen) {
            setOpen(false);
        }
    };
    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.hint_root}>
            <div className={styles.hint_leftBox}>
                <div className={styles.hint_textBox}>
                    <span className={styles.hint_text}>
                        Выберите эталонную кампанию, которую хотите сравнить
                    </span>
                </div>
                {/* <button */}
                {/*    id="compare-hint-close" */}
                {/*    className={styles.hint_textButton} */}
                {/*    type="button" */}
                {/*    onClick={handlerCloseClick} */}
                {/* > */}
                {/*    понятно */}
                {/* </button> */}
            </div>
            {
                Boolean(onCancel) && (
                    <Button
                        text="Отмена"
                        type="secondary"
                        size="medium"
                        onClick={() => onCancel()}
                    />
                )
            }
        </div>
    );
};

export default CampaignMenuHint;
