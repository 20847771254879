import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import SvgIcon from '@components/SvgIcon';
import { Button } from '@shared/index';
import styles from './styles.module.scss';

interface IProps {
    campaigns: ICampaignDataModel[],
    currentModalWindow: string;
    setCurrentModalWindow: (name: string) => void;
    onSetNewCampaigns: (newCampaigns: ICampaignDataModel[]) => void;
    defaultActiveRowIds: number[];
    referenceCampaignId: number | null;
}

const dateNowStr = (new Date()).toLocaleDateString('ru-RU');

const CampaignSelectList: React.FC<IProps> = ({
    campaigns,
    currentModalWindow,
    setCurrentModalWindow,
    defaultActiveRowIds,
    referenceCampaignId,
    onSetNewCampaigns,
}): JSX.Element => {
    const [activeRowIds, setActiveRowIds] = useState<number[]>(defaultActiveRowIds ?? []);

    const isAddButtonDisable = useMemo<boolean>(() => (
        activeRowIds?.length === 1 && activeRowIds[0] === referenceCampaignId
    ), [referenceCampaignId, activeRowIds]);

    const handlerCheckedClick = (item: ICampaignDataModel) => (eventValChange: CheckboxChangeEvent): void => {
        if (!eventValChange || !eventValChange.target) {
            return;
        }

        if (eventValChange.target.checked && !activeRowIds.includes(item?.id) && activeRowIds.length < 5) {
            setActiveRowIds([...activeRowIds, item?.id]);
        } else if (!eventValChange.target.checked && activeRowIds.includes(item?.id)) {
            if (referenceCampaignId && item?.id === referenceCampaignId) {
                return;
            }
            setActiveRowIds([...activeRowIds.filter((id) => id !== item?.id)]);
        }
    };
    const handlerAddCampaignClick = (): void => {
        const ids = activeRowIds.filter((id) => id !== referenceCampaignId);
        const result = campaigns.filter(({ id }) => ids.includes(id));
        if (result?.length > 0 && onSetNewCampaigns) {
            onSetNewCampaigns(result);
        }
        setCurrentModalWindow('');
    };
    const handlerBackClick = (): void => {
        setCurrentModalWindow('');
        setActiveRowIds(defaultActiveRowIds ?? []);
    };

    useEffect(() => {
        setActiveRowIds(defaultActiveRowIds ?? []);
    }, [defaultActiveRowIds]);

    if (currentModalWindow !== 'AddCompany') {
        return null;
    }

    return (
        <div className={styles.campaignSelectList_root}>
            <div className={styles.modalWindow}>
                <div
                    role="button"
                    onKeyPress={() => null}
                    onClick={handlerBackClick}
                    tabIndex={0}
                    className={styles.modalWindow_btnBack}
                >
                    <div className={styles.modalWindow_arrow}>
                        <SvgIcon id="ArrowLeft" />
                    </div>
                    Назад
                </div>
                <div className={styles.modalWindow_list}>
                    <div className={styles.modalWindow_titleList}>
                        <div className={styles.modalWindow_checkedCell} />
                        <div className={styles.modalWindow_nameCell} style={{ color: '#787E93' }}>
                            Наименование
                        </div>
                        <div className={styles.modalWindow_dateCell}>
                            Дата
                        </div>
                        <div className={styles.modalWindow_periodCell}>Период</div>
                        <div className={styles.modalWindow_authorCell}>Автор компании</div>
                        <div className={styles.modalWindow_actionCell}>Статус</div>
                    </div>
                    {campaigns.map((campaign, index) => (
                        <div key={campaign.id} className={styles.modalWindow_row}>
                            <div className={styles.modalWindow_checkedCell}>
                                <Checkbox
                                    checked={activeRowIds.includes(campaign.id)}
                                    onChange={handlerCheckedClick(campaign)}
                                    disabled={campaign.id === referenceCampaignId}
                                />
                                {' '}
                                { index + 1 }
                            </div>
                            <div className={styles.modalWindow_nameCell}>{campaign.name}</div>
                            <div className={styles.modalWindow_dateCell}>{campaign.createDate}</div>
                            <div className={styles.modalWindow_periodCell}>{`${campaign.parseFrom} - ${dateNowStr}`}</div>
                            <div className={styles.modalWindow_authorCell}>{`${campaign.owner.firstName} ${campaign.owner.lastName}`}</div>
                            <div className={styles.modalWindow_actionCell}>
                                <span
                                    className={styles.modalWindow_controlBlock}
                                    style={{
                                        justifyContent: 'space-between',
                                        color: (campaign.isActive ? '#0EBFA1' : '#787E93'),
                                    }}
                                >
                                    <span
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {campaign.isActive ? 'Выкл.' : 'Вкл.'}
                                    </span>
                                    <Switch
                                        id={`campaign-activate-on-list-switch-${campaign.id}`}
                                        size="small"
                                        disabled
                                        checked={campaign.isActive}
                                    />
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.modalWindow_controller}>
                    <Button
                        text="Добавить"
                        type="default"
                        size="large"
                        onClick={handlerAddCampaignClick}
                        disabled={isAddButtonDisable}
                    />
                    {/* <ButtonPrimary */}
                    {/*    style={{ width: 122, height: 48 }} */}
                    {/*    text="Добавить" */}
                    {/*    onClick={handlerAddCampaignClick} */}
                    {/*    disabled={isAddButtonDisable} */}
                    {/* /> */}
                </div>
            </div>
        </div>
    );
};

export default CampaignSelectList;
