import React from 'react';
import CampaignDashboard from '../../components/campaign/Dashboard';
import styles from '../styles.module.scss';

const CampaignDashboardPage: React.FC = (): JSX.Element => (
    <div className={styles.root}>
        <CampaignDashboard />
    </div>
);

export default CampaignDashboardPage;
