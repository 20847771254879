import React from 'react';
import { SvgIcon } from '@shared/index';

interface IProps {
    onClick: () => void;
    iconId: string;
    iconSize: number;
}

const IconButton: React.FC<IProps> = ({ onClick, iconId, iconSize }): JSX.Element => (
    <button type="button" onClick={onClick} style={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon size={iconSize} id={iconId} />
    </button>
);

export default IconButton;
