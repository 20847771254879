import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Path from '@shared/lib/paths';
import { campaignActions } from '@store/campaign/source';
import { campaignComparisonActions } from '@store/campaign/comparison';
import { FetchStatus } from '@store/types';
import { useAppDispatch } from '@store/hooks';
import campaignSelectors from '@store/campaign/source/selectors';
import campaignComparisonSelectors from '@store/campaign/comparison/selectors';
import { CampaignActiveModalType, ModalAnswerType } from '@store/campaign/source/types';
import { singleCampaignRtkQService } from '@services/campaigns/single-campaign';
import { ICampaignMenuTab } from '@components/campaign/Menu/types';
import CampaignMenuTabs from '@components/campaign/Menu';
import campaignUtils from '../../../utils/campaign';
import CampaignMenuActionsContainers from './CampaignActions';

import styles from './styles.module.scss';

const initialTabsState: ICampaignMenuTab[] = [
    {
        id: 6, name: 'list', isLoading: false, title: '', isActive: false, onClick: null, tagId: '', items: [],
    },
    {
        id: 81, name: 'charts', isLoading: false, title: 'Графики', isActive: false, onClick: null, tagId: 'campaign-charts-tab', items: [],
    },
    {
        id: 82, name: 'saved', isLoading: false, title: 'Сохраненные графики', isActive: false, onClick: null, tagId: 'campaign-saved-charts-tab', items: [],
    },
    // {
    //     id: 1, name: 'update', isLoading: false, title: 'Параметры', isActive: false, onClick: null, tagId: 'campaign-parameters-tab', items: [],
    // },
    // {
    //     id: 2, name: 'tracking', isLoading: false, title: 'Отслеживание', isActive: false, onClick: null, tagId: 'campaign-tracking-tab', items: [],
    // },
    // {
    //     id: 8,
    //     name: 'charts-subtabs',
    //     isLoading: false,
    //     title: 'Графики',
    //     isActive: false,
    //     onClick: null,
    //     tagId: 'campaign-charts-tab-list',
    //     items: [
    //         {
    //             id: 81, name: 'charts', isLoading: false, title: 'Графики', isActive: false, onClick: null, tagId: 'campaign-charts-tab', items: [],
    //         },
    //         {
    //             id: 82, name: 'saved', isLoading: false, title: 'Сохраненные графики', isActive: false, onClick: null, tagId: 'campaign-saved-charts-tab', items: [],
    //         },
    //     ],
    // },
    // {
    //     id: 5, name: 'reports', isLoading: false, title: 'Отчёты', isActive: false, onClick: null, tagId: 'campaign-reports-tab', items: [],
    // },
    // {
    //     id: 6, name: 'list', isLoading: false, title: '', isActive: false, onClick: null, tagId: '', items: [],
    // },
    // {
    //     id: 7, name: 'create', isLoading: false, title: '', isActive: false, onClick: null, tagId: '', items: [],
    // },
];

const CampaignMenuTabsContainer: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { campaignId: urlCampaignId, Id: urlId } = useParams();

    const campaignActiveModal = useSelector(campaignSelectors.activeModal);
    const campaignCreatingBegun = useSelector(campaignSelectors.createBegun);
    // const currentCampaign = useSelector(campaignSelectors.currentCampaign);
    const campaignModalAnswer = useSelector(campaignSelectors.modalAnswer);
    // const campaignFetchStatus = useSelector(campaignSelectors.fetchCampaignStatus);
    const createStatus = useSelector(campaignSelectors.createStatus);
    const deleteStatus = useSelector(campaignSelectors.deleteCampaignStatus);
    const isCompare = useSelector(campaignComparisonSelectors.campaignCompareEnabled);
    const referenceCampaign = useSelector(campaignComparisonSelectors.referenceCampaign);
    // const canViewComparison = useSelector(campaignComparisonSelectors.comparisonCanView);

    const [deleteSingleCampaign] = singleCampaignRtkQService.useDeleteCampaignMutation();

    const [
        getCampaign,
        {
            data: campaign,
            isLoading: isLoadingCampaignRtkQ,
        },
    ] = singleCampaignRtkQService.useLazyGetCampaignQuery();

    const [activeNavigateHref, setActiveNavHref] = useState<string>(null);
    const [tabs, setTabs] = useState<ICampaignMenuTab[]>([...initialTabsState]);
    const [selectChartTabId, setSelectChartTabId] = useState<number>(81);

    const fromList = useMemo<boolean>(() => location.pathname.includes(Path.CampaignList), [location]);

    const campaignFromUrlId = useMemo((): number => {
        if (urlCampaignId) {
            return Number(urlCampaignId);
        }
        if (urlId) {
            return Number(urlId);
        }
        return null;
    }, [urlCampaignId, urlId]);
    const actionsBlockIsActive = useMemo((): boolean => {
        const { pathname } = location;
        return pathname.includes(Path.CampaignUpdate);
    }, [location]);
    const withoutMenu = useMemo<boolean>(() => (
        location?.pathname.includes(Path.CampaignComparisonsList)
        || !(
            location?.pathname.includes('list')
            || location?.pathname.includes('charts')
            || location?.pathname.includes('saved')
            || location?.pathname.includes(Path.CampaignUpdate)
        )
    ), [location]);

    // const campaignIsLoading = (): boolean => campaignFetchStatus === FetchStatus.loading;
    const checkSwitchingPage = useCallback((): boolean => {
        if (campaignCreatingBegun) {
            dispatch(campaignActions.setActiveModal(CampaignActiveModalType.switchingPage));
            return true;
        }
        return false;
    }, [campaignCreatingBegun]);
    const handlerTracking = useCallback((): void => {
        if (checkSwitchingPage()) {
            setActiveNavHref(`/${Path.Campaign}/${campaignFromUrlId}`);
            return;
        }
        if (campaignFromUrlId) {
            navigate(`/${Path.Campaign}/${campaignFromUrlId}`);
        }
    }, [campaignFromUrlId, urlId, checkSwitchingPage]);
    const handlerUpdate = useCallback((): void => {
        if (campaignFromUrlId) {
            dispatch(campaignActions.setViewActionCampaign());
            navigate(`/${Path.Campaign}/${Path.CampaignUpdate}/${campaignFromUrlId}`);
            return;
        }
        navigate(`/${Path.Campaign}/${Path.CampaignList}`);
    }, [campaignFromUrlId]);
    const handlerSavedCharts = useCallback((): void => {
        if (checkSwitchingPage()) {
            setActiveNavHref(`${Path.SavedCharts}`);
            return;
        }
        navigate(`/${Path.Campaign}/${Path.SavedCharts}/${campaignFromUrlId}`);
    }, [checkSwitchingPage, campaignFromUrlId]);
    const handlerNewCharts = useCallback((): void => {
        if (checkSwitchingPage()) {
            setActiveNavHref('new-charts');
            return;
        }
        navigate(`/${Path.Campaign}/new-charts/${campaignFromUrlId}`);
    }, [checkSwitchingPage, campaignFromUrlId]);
    const handlerReports = useCallback((): void => {
        if (checkSwitchingPage()) {
            setActiveNavHref(`${Path.Reports}`);
            return;
        }
        navigate(`/${Path.Campaign}/${Path.Reports}/${campaignFromUrlId}`);
    }, [checkSwitchingPage, campaignFromUrlId]);
    const handlerCharts = useCallback((): void => {
        if (checkSwitchingPage()) {
            setActiveNavHref(`${Path.Charts}`);
            return;
        }
        if (campaignFromUrlId) {
            navigate(`/${Path.Campaign}/${Path.Charts}/${campaignFromUrlId}`);
            return;
        }
        navigate(`/${Path.Campaign}/${Path.CampaignList}`);
    }, [campaignFromUrlId, checkSwitchingPage]);
    const handlerCreateCampaign = useCallback((): void => {
        if (checkSwitchingPage()) {
            setActiveNavHref(Path.CampaignCreate);
            return;
        }
        navigate(Path.CampaignCreate);
    }, [checkSwitchingPage]);
    const handlerCompareCampaign = useCallback((): void => {
        if (!isCompare) {
            dispatch(campaignComparisonActions.setSavedAnalysisDisable());
            dispatch(campaignComparisonActions.setCompareEnable());
        }
    }, [isCompare]);
    const handlerDeleteCampaign = useCallback((): void => {
        if (campaign) {
            dispatch(campaignActions.setDataSingleCampaign(campaign));
            dispatch(campaignActions.setActiveModal(CampaignActiveModalType.deletingCampaign));
        }
    }, [campaign, checkSwitchingPage]);
    const handlerSelectChartTab = useCallback((tabId: number): void => {
        if (campaign) {
            setSelectChartTabId(tabId);
        }
        if (tabId === 81) {
            handlerCharts();
        } else if (tabId === 82) {
            handlerSavedCharts();
        } else if (tabId === 83) {
            handlerNewCharts();
        }
    }, [campaign, handlerCharts, handlerSavedCharts]);
    const checkActiveSubTab = useCallback((subItem: ICampaignMenuTab, compareName: string): boolean => {
        if (subItem && subItem.name === compareName) {
            setSelectChartTabId(subItem.id);
            return true;
        }
        return false;
    }, [setSelectChartTabId]);
    const changeActiveTab = useCallback((path: string, localTabs: ICampaignMenuTab[]): void => {
        let curLocationName: string | null = null;
        if (path.includes(Path.CampaignComparisonsList)) {
            curLocationName = 'comparisons-list';
        } else if (campaignUtils.locationIsTrackingCampaign(path)) {
            curLocationName = 'tracking';
        } else if (path.includes(`new-${Path.Charts}`)) {
            curLocationName = 'new-charts';
        } else if (path.includes(Path.Charts)) {
            curLocationName = 'charts';
        } else if (path.includes(Path.SavedCharts)) {
            curLocationName = 'saved';
        } else if (path.includes(Path.CampaignList)) {
            curLocationName = 'list';
        } else if (path.includes(Path.Reports)) {
            curLocationName = 'reports';
        } else if (path.includes(Path.CampaignCreate)) {
            curLocationName = 'create';
        } else if (path.includes(Path.CampaignUpdate)) {
            curLocationName = 'update';
        }
        setTabs([
            ...localTabs.map((item) => ({
                ...item,
                isActive: item.name === curLocationName,
                items: item.items.map((subItem) => ({
                    ...subItem,
                    isActive: checkActiveSubTab(subItem, curLocationName),
                })),
            })),
        ]);
    }, [checkActiveSubTab]);
    const handlerCancelCompare = useCallback(() => {
        dispatch(campaignComparisonActions.clearAll());
    }, [dispatch]);

    useEffect(() => {
        if (campaignActiveModal === CampaignActiveModalType.switchingPage) {
            if (!activeNavigateHref) {
                return;
            }
            if (campaignModalAnswer === ModalAnswerType.accept) {
                navigate(activeNavigateHref);
                setActiveNavHref(null);
            }
            if (campaignModalAnswer !== ModalAnswerType.undefined) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
            }
        } else if (!fromList && campaignActiveModal === CampaignActiveModalType.deletingCampaign) {
            if (campaignModalAnswer === ModalAnswerType.accept) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
                // dispatch(deleteCampaignThunk(campaignRtkQ?.id));
                if (Number.isInteger(campaign?.id)) {
                    deleteSingleCampaign({ id: campaign?.id });
                }
                return;
            }
            if (campaignModalAnswer === ModalAnswerType.reject) {
                dispatch(campaignActions.setActiveModal(CampaignActiveModalType.undefined));
            }
        }
    }, [campaignModalAnswer, fromList]);

    useEffect(() => {
        const urlNumberId = Number(urlCampaignId || urlId);
        if (urlNumberId > 0 && campaign?.id !== urlNumberId) {
            getCampaign({ id: urlNumberId });
        }
    }, [urlCampaignId, urlId, campaign]);
    useEffect(() => {
        changeActiveTab(location.pathname, tabs);
    }, [location, changeActiveTab]);
    useEffect(() => {
        if (createStatus === FetchStatus.success) {
            if (location?.pathname.includes(Path.CampaignCreate)) {
                dispatch(campaignActions.clearListData());
                // dispatch(loadCampaignsIfNotExists());
                dispatch(campaignActions.setInitialCreatingStatus());
                dispatch(campaignActions.setEditActionCampaign());
                changeActiveTab(Path.CampaignUpdate, tabs);
                navigate(`/${Path.Campaign}/${Path.CampaignUpdate}/${campaignFromUrlId}`);
            }
            // else if (location?.pathname.includes(Path.CampaignUpdate) && campaignFromUrlId) {
            //     dispatch(loadSingleCampaignIfNotExists(campaignFromUrlId, true));
            // }
        }
    }, [createStatus, changeActiveTab, campaignFromUrlId]);
    useEffect(() => {
        if (location?.pathname) {
            if (!location.pathname.split('/').some((value) => value === 'charts' || value === 'saved')) {
                setSelectChartTabId(81);
            }
        }
    }, [campaignFromUrlId, location]);
    useEffect(() => {
        if (deleteStatus === FetchStatus.success) {
            dispatch(campaignActions.clearCampaignDeletingStatus());
            navigate(`/${Path.Campaign}/${Path.CampaignList}`);
        }
    }, [deleteStatus]);

    if (location.pathname.includes(Path.Notification) || location.pathname.includes(Path.UserRights)) {
        return null;
    }

    if (location.pathname.includes(`/${Path.Campaign}/${Path.CampaignComparison}`)) {
        return null;
    }
    if (location.pathname.includes(`/${Path.Campaign}/${Path.SearchRequest}`)) {
        return null;
    }

    return (
        <div
            className={
                `${styles.campaignMenuTabsContainer_root} ${(referenceCampaign && isCompare) ? styles.height300 : ''}`
            }
            style={withoutMenu ? { height: 'auto', minHeight: 0, marginBottom: 0 } : null}
        >
            <CampaignMenuTabs
                onClickTracking={handlerTracking}
                onClickUpdate={handlerUpdate}
                onClickCharts={handlerCharts}
                onCompareCampaign={handlerCompareCampaign}
                onClickReports={handlerReports}
                onClickSavedCharts={handlerSavedCharts}
                onClickNewCharts={handlerNewCharts}
                tabs={tabs}
                onCreateCampaign={handlerCreateCampaign}
                onSelectChartTab={handlerSelectChartTab}
                selectChartTabId={selectChartTabId}
                isCompare={isCompare}
                referenceCampaign={referenceCampaign}
                onCancelCompare={handlerCancelCompare}
            />
            {
                !isLoadingCampaignRtkQ && actionsBlockIsActive
                && <CampaignMenuActionsContainers onDelete={handlerDeleteCampaign} />
            }
        </div>
    );
};
export default CampaignMenuTabsContainer;
