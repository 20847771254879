import LinearLoader from '../../../components/common/Loader';
import MessageArrowIcon from '../imgs/MessageArrow.svg';
import styles from './styles.module.scss';

interface IProps {
    isLoading: boolean
    isError: boolean
}

const CampaignReportCreationProcess: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const { isLoading, isError } = props;
    return (
        <div className={styles.wrapper}>
            {isLoading && !isError
                && (
                    <div className={styles.messageBox}>
                        <span>Идет процесс создания отчета</span>
                        <span>Это может занять некоторое время.</span>
                        <span
                            className={styles.flexRow}
                            style={{ justifyContent: 'space-between' }}
                        >
                            Готовый отчет появится справа
                            <img src={MessageArrowIcon} alt={MessageArrowIcon} />
                        </span>
                        <div
                            className={styles.flexRow}
                            style={{ justifyContent: 'center' }}
                        >
                            <LinearLoader />
                        </div>
                    </div>
                )}
            {isError
                && (
                    <div className={styles.messageBox}>
                        <span className={styles.messageBox_error}>
                            При формировании отчёта произошла ошибка.
                        </span>
                        <span className={styles.messageBox_error}>
                            Повторите попытку через несколько минут.
                        </span>
                    </div>
                )}
        </div>
    );
};
export default CampaignReportCreationProcess;
