import styled from 'styled-components';

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  
  overflow: hidden;
`;

export default ContentContainer;
