export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export interface INumbersInterval {
    from: number;
    to: number;
}

export interface INumbersIntervalApi {
    from_: number;
    to_: number;
}

export type TStackDirection = 'NEXT' | 'PREVIOUS';

export interface IEntity {
    id: number;
    title: string;
}

export interface IPlatform {
    id: number;
    title: string;
    icon: string;
}

export interface IGrowth {
    day: number;
    week: number;
    month: number;
}

export interface ISource {
    nextPageToken?: string;
    previousPageToken?: string;
    total: number;
    sources: ISourceRow[];
}
export interface ISourceRow {
    id: number;
    title: string;
    author: IEntity;
    er: number;
    erAvg?: number;
    sourceType: IEntity;
    subscriberGrowth: IGrowth;
    subscriberGrowthPercent: IGrowth;
    categories: IEntity[];
    subscribers: number;
    link: string;
    price: number;
    startDate?: Date;
    endDate?: Date;
    is_deleted?: boolean;
    sourceId?: number;
    content?: string;
    integrationLink?: string;
    logoUrl?: string;
    comment?: string;
    planViews?: number;
    viewsAvg?: number;
    planEr?: number;
    planCpv?: number;
    cpv?: number;
    lists: number[];
}

export interface IAuthor {
    id: number;
    isBlogger: boolean;
    isContracted: boolean;
    isReliable: boolean;
    logoLink: string;
    title: string;
}

export interface IAuthors {
    data: IAuthor[];
    nextPage: string;
    previousPage: string;
}

export interface ISourcePost {
    postId: number;
    content: string;
    posted: string;
    postLink: string;
    images: string[];
    likes: number;
    views: number;
    comments: number;
    forwards: number;
    lastUpdate: string;
}

export interface ILoadSourcePostsResponse {
    nextPageToken: string | null;
    previousPageToken: string | null;
    items: ISourcePost[];
}

export interface ISourceModel {
    id: number;
    title: string;
    link: string;
    author: IEntity;
    categories: ISourceCategory[];
    sourceType: IEntity;
    created: string;
    logoUrl?: string;
    lists: number[];
}

export interface ISourceCategory {
    id: number
    title: string;
}

export interface ISourceAdvancedFilters {
    id: number;
    title: string[];
    authorId: number;
    typeId: number;
    categoryId: number;
    subscribers: number;
    erAvg: INumbersInterval;
    err: number;
    err24: number;
    viewsAvg: INumbersInterval;
}
export interface ISourceFiltersRanges {
    subscribers: INumbersInterval;
    erAvg: INumbersInterval;
    err: INumbersInterval;
    err24: INumbersInterval;
    viewsAvg: INumbersInterval;
}

export interface ICustomSourceList {
    id: number;
    title: string;
    description: string;
    count: number;
}

export interface ICustomSourceListRaw extends ICustomSourceList {
    isFavorite: any;
    is_favorite: boolean
}

export enum SourceFieldsEnum {
    title = 'title',
    link = 'link',
    typeTitle = 'type_title',
    authorTitle = 'author_title',
    categoryTitles = 'category_titles',
    subscribers = 'subscribers',
    viewsAvg = 'views_avg',
    erAvg = 'er_avg',
    err = 'err',
    err24 = 'err24',
    price = 'price',
    content = 'content',
    integrationLink = 'integration_link',
    startDate = 'start_date',
    endDate = 'end_date',
    cpv = 'cpv',
    ratioCpv = 'ratio_cpv',
    ratioER = 'ratio_er',
    ratioViews = 'ratio_views',
    planViews = 'plan_views',
    planER = 'plan_er',
    planCPV = 'plan_cpv',
    factViews = 'fact_views',
    avgViews = 'views_avg',
    factER = 'fact_er',
    avgER = 'er_avg',
    factCPV = 'fact_cpv',
    avgCPV = 'cpv',
    comment = 'comment',
    actions = 'actions',
}

export interface ISourceListModel {
    id: number;
    title: string;
    description: string;
    isFavorite: boolean;
    count: number;
}

export interface ISourceListsCountsModel {
    favoriteCount: number;
    listsCount: number;
}
