export const Regions = {
    yuksaha: {
        capital: 'Якутск',
        districtName: 'Якутия',
        id: 'yuksaha',
        weight: 'big',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    amu: {
        capital: 'Благовещенск',
        districtName: 'Амурская область',
        id: 'amu',
        weight: 'mid',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    yev: {
        capital: 'Биробиджан',
        districtName: 'Еврейский автономный округ',
        id: 'yev',
        weight: 'small',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    pri: {
        capital: 'Владивосток',
        districtName: 'Приморский край',
        id: 'pri',
        weight: 'small',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    mag: {
        capital: 'Магадан',
        districtName: 'Магаданская область',
        id: 'mag',
        weight: 'small',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    sakhalin_island: {
        capital: 'Южно-Сахалинск',
        districtName: 'Сахалинская область',
        id: 'sakhalin_island',
        weight: 'small',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    camchatskay_obl: {
        capital: 'Петропавловск-Камчатский',
        districtName: 'Камчатская область',
        id: 'camchatskay_obl',
        weight: 'small',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    chukotka: {
        capital: 'Анадырь',
        districtName: 'Чукосткая область',
        id: 'chukotka',
        weight: 'small',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    habarovscki_cray: {
        capital: 'Хабаровск',
        districtName: 'Хабаровский край',
        id: 'habarovscki_cray',
        weight: 'mid',
        region: 'dfo',
        regionName: 'Дальневосточный федеральный округ',
    },
    kc: {
        capital: 'Черкесск',
        districtName: 'Карачаево',
        id: 'kc',
        weight: 'small',
        region: 'sevkav',
        regionName: 'Северо-Кавказский округ',
    },
    se: {
        capital: 'Владикавказ',
        districtName: 'Республика Северная Осетия — Алания',
        id: 'se',
        weight: 'small',
        region: 'sevkav',
        regionName: 'Северо-Кавказский округ',
    },
    in: {
        capital: 'Магас',
        districtName: 'Республика Ингушетия',
        id: 'in',
        weight: 'small',
        region: 'sevkav',
        regionName: 'Северо-Кавказский округ',
    },
    da: {
        capital: 'Махачкала',
        districtName: 'Республика Дагестан',
        id: 'da',
        weight: 'small',
        region: 'sevkav',
        regionName: 'Северо-Кавказский округ',
    },
    sta: {
        capital: 'Ставрополь',
        districtName: 'Ставропольский край',
        id: 'sta',
        weight: 'mid',
        region: 'sevkav',
        regionName: 'Северо-Кавказский округ',
    },
    ce: {
        capital: 'Грозный',
        districtName: 'Чечня',
        id: 'ce',
        weight: 'mid',
        region: 'sevkav',
        regionName: 'Северо-Кавказский округ',
    },
    kb: {
        capital: 'Нальчик',
        districtName: 'Кабардино-Балкарская Республика',
        id: 'kb',
        weight: 'small',
        region: 'sevkav',
        regionName: 'Северо-Кавказский округ',
    },
    ad: {
        capital: 'Майкоп',
        districtName: 'Республика Адыгея',
        id: 'ad',
        weight: 'small',
        region: 'ufo',
        regionName: 'Южный федеральный округ',
    },
    kl: {
        capital: 'Элиста',
        districtName: 'Республика Калмыкия',
        id: 'kl',
        weight: 'small',
        region: 'ufo',
        regionName: 'Южный федеральный округ',
    },
    krym: {
        capital: 'Севостопль',
        districtName: 'Крым',
        id: 'krym',
        weight: 'small',
        region: 'ufo',
        regionName: 'Южный федеральный округ',
    },
    kda: {
        capital: 'Краснодар',
        districtName: 'Краснодарский край',
        id: 'kda',
        weight: 'mid',
        region: 'ufo',
        regionName: 'Южный федеральный округ',
    },
    vgg: {
        capital: 'Волгоград',
        districtName: 'Волгоградская область',
        id: 'vgg',
        weight: 'mid',
        region: 'ufo',
        regionName: 'Южный федеральный округ',
    },
    ros: {
        capital: 'Ростов-на-Дону',
        districtName: 'Ростовская область',
        id: 'ros',
        weight: 'mid',
        region: 'ufo',
        regionName: 'Южный федеральный округ',
    },
    ast: {
        capital: 'Астрахань',
        districtName: 'Астраханская область',
        id: 'ast',
        weight: 'mid',
        region: 'ufo',
        regionName: 'Южный федеральный округ',
    },
    yar: {
        capital: 'Ярославль',
        districtName: 'Ярославская область',
        id: 'yar',
        weight: 'mid',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    kos: {
        capital: 'Кострома',
        districtName: 'Костромская область',
        id: 'kos',
        weight: 'mid',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    iva: {
        capital: 'Иваново',
        districtName: 'Ивановская область',
        id: 'iva',
        weight: 'mid',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    vla: {
        capital: 'Владимир',
        districtName: 'Владимирская область',
        id: 'vla',
        weight: 'mid',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    tve: {
        capital: 'Тверь',
        districtName: 'Тверская область',
        id: 'tve',
        weight: 'mid',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    mos: {
        capital: 'Москва',
        districtName: 'Московская область',
        id: 'mos',
        weight: 'big',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    mov: {
        capital: 'Москва',
        districtName: 'Москва',
        id: 'mov',
        weight: 'max',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    klu: {
        capital: 'Калуга',
        districtName: 'Калужская',
        id: 'klu',
        weight: 'mid',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    orl: {
        capital: 'Орел',
        districtName: 'Орловская область',
        id: 'orl',
        weight: 'mid',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    lip: {
        capital: 'Липецк',
        districtName: 'Липецкая область',
        id: 'lip',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    tul: {
        capital: 'Тула',
        districtName: 'Тульская область',
        id: 'tul',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    rya: {
        capital: 'Рязань',
        districtName: 'Рязанская область',
        id: 'rya',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    tam: {
        capital: 'Тамбов',
        districtName: 'Тамбовская область',
        id: 'tam',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    smo: {
        capital: 'Смоленск',
        districtName: 'Смоленская область',
        id: 'smo',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    bry: {
        capital: 'Брянск',
        districtName: 'Брянская область',
        id: 'bry',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    krs: {
        capital: 'Курск',
        districtName: 'Курская область',
        id: 'krs',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    bel: {
        capital: 'Белгород',
        districtName: 'Белгородская область',
        id: 'bel',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    vor: {
        capital: 'Воронеж',
        districtName: 'Воронежская область',
        id: 'vor',
        weight: 'small',
        region: 'centr',
        regionName: 'Центральный федеральный округ',
    },
    ore: {
        capital: 'Оренбург',
        districtName: 'Оренбургская область',
        id: 'ore',
        weight: 'small',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    ba: {
        capital: 'Уфа',
        districtName: 'Башкортостан',
        id: 'ba',
        weight: 'big',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    per: {
        capital: 'Пермь',
        districtName: 'Пермский край',
        id: 'per',
        weight: 'mid',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    niz: {
        capital: 'Нижний Новгород',
        districtName: 'Нижегородская область',
        id: 'niz',
        weight: 'mid',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    cu: {
        capital: 'Чебоксары',
        districtName: 'Чувашия',
        id: 'cu',
        weight: 'small',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    kir: {
        capital: 'Киров',
        districtName: 'Кировская область',
        id: 'kir',
        weight: 'mid',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    me: {
        capital: 'Йошкар-Ола',
        districtName: 'Республика Марий Эл',
        id: 'me',
        weight: 'mid',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    ta: {
        capital: 'Казань',
        districtName: 'Татарстан',
        id: 'ta',
        weight: 'mid',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    ud: {
        capital: 'Ижевск',
        districtName: 'Республика Удмуртия',
        id: 'ud',
        weight: 'mid',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    mo: {
        capital: 'Саранск',
        districtName: 'Республика Мордовия',
        id: 'mo',
        weight: 'mid',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    pnz: {
        capital: 'Пенза',
        districtName: 'Пензинская область',
        id: 'pnz',
        weight: 'small',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    sar: {
        capital: 'Саратов',
        districtName: 'Саратовская область',
        id: 'sar',
        weight: 'small',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    uly: {
        capital: 'Ульяновск',
        districtName: 'Ульяновская область',
        id: 'uly',
        weight: 'small',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },
    sam: {
        capital: 'Самара',
        districtName: 'Самаровская область',
        id: 'sam',
        weight: 'small',
        region: 'privol',
        regionName: 'Приволжский федеральный округ',
    },

    kgn: {
        capital: 'Самара',
        districtName: 'Самаровская область',
        id: 'kgn',
        weight: 'small',
        region: 'ural',
        regionName: 'Уральский федеральный округ',
    },
    che: {
        capital: 'Курган',
        districtName: 'Курганская область',
        id: 'che',
        weight: 'small',
        region: 'ural',
        regionName: 'Уральский федеральный округ',
    },
    tyu: {
        capital: 'Тюмень',
        districtName: 'Тюменьская область',
        id: 'tyu',
        weight: 'mid',
        region: 'ural',
        regionName: 'Уральский федеральный округ',
    },
    sve: {
        capital: 'Екатеренбург',
        districtName: 'Свердловская область',
        id: 'sve',
        weight: 'big',
        region: 'ural',
        regionName: 'Уральский федеральный округ',
    },
    khm: {
        capital: 'Ханты-Мансийск',
        districtName: 'Ханты',
        id: 'khm',
        weight: 'small',
        region: 'ural',
        regionName: 'Уральский федеральный округ',
    },
    ymao: {
        capital: 'Салехард',
        districtName: 'Ямало-Ненецкий автономный округ',
        id: 'ymao',
        weight: 'small',
        region: 'ural',
        regionName: 'Уральский федеральный округ',
    },


    bu: {
        capital: 'Улан-Удэ',
        districtName: 'Бурятия',
        id: 'bu',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    zabaykalsky: {
        capital: 'Чита',
        districtName: 'Забайкальский край',
        id: 'zabaykalsky',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    irkutsk: {
        capital: 'Иркутск',
        districtName: 'Иркутская область',
        id: 'irkutsk',
        weight: 'mid',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    oms: {
        capital: 'Омск',
        districtName: 'Омская область',
        id: 'oms',
        weight: 'big',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    nvs: {
        capital: 'Новосибирск',
        districtName: 'Новосибирская область',
        id: 'nvs',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    al: {
        capital: 'Горно-Алтайск',
        districtName: 'Алтай',
        id: 'al',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    alo: {
        capital: 'Барнаул',
        districtName: 'Алтайский край',
        id: 'alo',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },

    ty: {
        capital: 'Кызыл',
        districtName: 'Тыва',
        id: 'ty',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    krasnoyarskykray: {
        capital: 'Красноярск',
        districtName: 'Красноярский край',
        id: 'krasnoyarskykray',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    tom: {
        capital: 'Томск',
        districtName: 'Томская область',
        id: 'tom',
        weight: 'mid',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    kem: {
        capital: 'Кемерово',
        districtName: 'Кемеровская область',
        id: 'kem',
        weight: 'big',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },
    kk: {
        capital: 'Абакан',
        districtName: 'Республика Хакасия',
        id: 'kk',
        weight: 'small',
        region: 'ural',
        regionName: 'Сибирский федеральный округ',
    },


    ko: {
        capital: 'Сыктывкар',
        districtName: 'Республика Коми',
        id: 'ko',
        weight: 'mid',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    vlg: {
        capital: 'Вологда',
        districtName: 'Вологодская область',
        id: 'vlg',
        weight: 'big',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    ngr: {
        capital: 'Великий Новгород',
        districtName: 'Новгородская область',
        id: 'ngr',
        weight: 'small',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    len: {
        capital: 'Санкт-Петербург',
        districtName: 'Ленинградская область',
        id: 'len',
        weight: 'small',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    kr: {
        capital: 'Петрозаводск',
        districtName: 'Карелия',
        id: 'kr',
        weight: 'small',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    arhanglobl: {
        capital: 'Архангельск',
        districtName: 'Архангельская область',
        id: 'arhanglobl',
        weight: 'small',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    kgd: {
        capital: 'Калининград',
        districtName: 'Калининградская область',
        id: 'kgd',
        weight: 'small',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    neneckiavok: {
        capital: 'Нарьян-Мар',
        districtName: 'Ненецкий автономный округ',
        id: 'neneckiavok',
        weight: 'mid',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    mur: {
        capital: 'Мурманск',
        districtName: 'Мурманская область',
        id: 'mur',
        weight: 'big',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
    psk: {
        capital: 'Псков',
        districtName: 'Псковская область',
        id: 'psk',
        weight: 'small',
        region: 'ural',
        regionName: 'Северо-Западный федеральный округ',
    },
}
