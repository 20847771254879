import React from 'react';
import { Button, SvgIcon } from '@shared/index';
import { CrossIconsIds } from '@app/constants/icons';
import { ButtonType } from '@shared/ui-components/buttons/Button';
import styles from './styles.module.scss';

interface IProps {
    style?: React.CSSProperties;
    isOpen: boolean;
    title: string;
    textContent: string;
    textButtonApply?: string;
    textButtonCancel?: string;
    typeButtonApply?: ButtonType;
    typeButtonCancel?: ButtonType;
    onClose: () => void;
    onApply: () => void;
}

/**
 * Компонент модального окна для удаления сущностей
 * @param {boolean} isOpen - флаг отображения модального окна
 * @param {() => void} onClose - callback функция для закрытия модального окна
 * @param {string} title - заголовок модального окна
 * @param {string} textContent - текст для содержимого модального окна модального окна
 * @param {() => void} onApply - callback функция для применения удаления сущности
 */
const DeleteModal: React.FC<IProps> = ({
    style,
    isOpen,
    title,
    textContent,
    textButtonApply = 'Удалить',
    textButtonCancel = 'Отменить',
    typeButtonApply = 'danger',
    typeButtonCancel = 'secondary',
    onApply,
    onClose,
}): JSX.Element => {
    const handlerClose = (): void => {
        onClose();
    };
    const handlerApply = (): void => {
        onApply();
        onClose();
    };
    if (!isOpen) {
        return null;
    }
    return (
        <div className={styles.deleteModal_root}>
            <div className={styles.deleteModal_window}>
                <div className={styles.deleteModal_container} style={style}>
                    <div className={styles.deleteModal_container_header}>
                        <span className={styles.deleteModal_container_title}>{title}</span>
                        <button type="button" onClick={handlerClose}>
                            <SvgIcon id={CrossIconsIds.BigCross} size={14} />
                        </button>
                    </div>
                    <div className={styles.deleteModal_container_content}>
                        <div className={styles.deleteModal_container_textContent}>
                            {textContent}
                        </div>
                    </div>
                    <div className={styles.deleteModal_container_controls}>
                        <Button text={textButtonApply} type={typeButtonApply} size="large" onClick={handlerApply} />
                        <Button text={textButtonCancel} type={typeButtonCancel} size="large" onClick={handlerClose} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;
