import { Slider } from 'antd';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import useDebounce from '../../hooks/useDebounce';
import './slider.css';

export type InputsSliderStyles = {
    input?: React.CSSProperties,
};

interface IProps {
    min?: number;
    max?: number;
    value: [number, number];
    onChange: (newValue: [number, number]) => void;
    defaultValue: [number, number];
    style?: InputsSliderStyles;
}

const InputsSlider: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        min = 0,
        max = 100,
        value,
        onChange,
        defaultValue,
        style,
    } = props;

    const [sliderValue, setSliderValue] = useState<[number, number] | null>(value);

    const onChangeSliderValue = (newSliderValue: [number, number]): void => {
        setSliderValue(newSliderValue);
    };

    const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue = Number(e.target.value.replace(/[^+\d]/g, ''));
        setSliderValue((prev) => {
            const currentFromValue = prev ? prev[1] : 0;
            const currentToValue = prev ? prev[1] : max;
            return [inputValue > currentToValue ? currentFromValue : inputValue, currentToValue];
        });
    };

    const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue = Number(e.target.value.replace(/[^+\d]/g, ''));
        setSliderValue((prev) => {
            const currentFromValue = prev ? prev[0] : min;
            return [currentFromValue, inputValue > max ? max : inputValue];
        });
    };

    useDebounce(() => {
        if (sliderValue) onChange(sliderValue);
    }, 1000, [sliderValue]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.inputs}>
                <input
                    type="text"
                    value={sliderValue ? sliderValue[0] : defaultValue[0]}
                    onChange={onChangeFrom}
                    style={style?.input ?? {}}
                />
                <input
                    type="text"
                    value={sliderValue ? sliderValue[1] : defaultValue[1]}
                    onChange={onChangeTo}
                    style={style?.input ?? {}}
                />
            </div>
            <Slider
                range
                min={min}
                max={max}
                value={sliderValue ?? defaultValue}
                onChange={onChangeSliderValue}
            />
        </div>
    );
};
export default InputsSlider;

// autocomplete="on"
