/* eslint-disable no-mixed-operators */
import { IPDFTopPostsModel } from '../../components/campaign/Report/PDFPages/TopPostsPage';
import { IPDFReportTopPlatform } from '../../store/pdf-handler/wrapper/types';
import { IDictionary } from '../../store/types';

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const randomHslColor = (): [number, number, number] => {
    const h = randomInt(0, 360);
    const s = randomInt(42, 98);
    const l = randomInt(60, 90);
    return [h, s, l];
};

const convertHslToHex = (h, s, l) => {
    const lightness = l / 100;
    const a = s * Math.min(lightness, 1 - lightness) / 100;
    const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
};

export const randomHexColor = (): string => {
    const hsl = randomHslColor();
    return convertHslToHex(hsl[0], hsl[1], hsl[2]);
};

export const formatTopPlatforms = (data: IPDFReportTopPlatform[]) => {
    const res = data.reduce(
        (acc: IDictionary<IPDFTopPostsModel>, curr: IPDFReportTopPlatform) => {
            if (!acc[curr.sourceType]) {
                acc[curr.sourceType] = { posts: [] };
            } else if (acc[curr.sourceType].posts.length >= 4) {
                return acc;
            }
            acc[curr.sourceType].posts.push({
                id: acc[curr.sourceType].posts.length + 1,
                content: curr.content,
                views: curr.views,
                link: curr.postLink,
            });
            return acc;
        },
        {} as IDictionary<IPDFTopPostsModel>,
    );
    const arr: IDictionary<IPDFTopPostsModel>[][] = [];
    if (res) {
        for (
            let index = 0;
            index <= Object.entries(res).length - 1;
            index += 1
        ) {
            const el = Object.entries(res)[index];
            let arrIndex: number = 0;

            if (index > 1 && index < 4) {
                arrIndex = 1;
            }
            if (index > 3 && index < 6) {
                arrIndex = 2;
            }

            if (!arr[arrIndex]) arr[arrIndex] = [];
            arr[arrIndex].push({
                [el[0]]: el[1],
            });
        }
    }

    return arr;
};
