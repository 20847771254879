export enum NewsSourceType {
    'blogger' = 0,
    'media' = 1,
}

export interface InfoBasketSourceType {
    colId: number;
    name: string;
    type: NewsSourceType
}

export interface IInfoBasketField {
    colId: number;
    value: string;
}

export interface IInfoBasketCategoryField {
    colId: number;
    value: string;
    allValues: string[];
}

export interface IInfoBasketNumberField {
    colId: number;
    valueStr: string;
    value: number;
}

export interface IInfoBasketFieldPlatform {
    colId: number;
    value: string[];
}

export interface IInfoBasketSource {
    id: number;
    title: string;
    city: string;
    avatar: string;
    typeId: string;
    category: string[];
    resource: string[];
    price: number;
    subscribers: number;
    postsComments: number;
    postsViews: number;
    lastUpdateDate: string;
    lastUpdateTime: string;
    lastUpdateTimestamp: number;
}

export interface IInfoBasketTable {
    id: number;
    name: IInfoBasketField;
    type: InfoBasketSourceType;
    category: IInfoBasketCategoryField;
    platform: IInfoBasketFieldPlatform;
    period?: IInfoBasketField;
    price: IInfoBasketNumberField;
    audience: IInfoBasketNumberField;
    city: IInfoBasketField;
    comments: number;
    views: number;
}
