import { Button, SvgIcon } from '@shared/index';
import { Checkbox } from 'antd';
import React, { useRef } from 'react';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import styles from './styles.module.scss';
import { IModalXlsxExportValue } from './types';

interface IProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    values: IModalXlsxExportValue[];
    setValues(values: IModalXlsxExportValue[]): void;
    onClickSubmit: () => void;
}

const ModalXlsxExport: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        visible, setVisible, values, setValues,
        onClickSubmit,
    } = props;

    const contentRef = useRef(null);
    const close = (): void => setVisible(false);

    useOnClickOutside(contentRef, close);

    const updateValues = (item: IModalXlsxExportValue, value: boolean) => {
        const newValues = values.map((i) => (i.name === item.name ? { ...i, selected: value } : i));
        setValues(newValues);
    };

    if (!visible) return null;

    return (
        <div className={styles.wrapper}>
            <div className={styles.content} ref={contentRef}>
                <button className={styles.buttonClose} type="button" onClick={close}>
                    <SvgIcon id="Cross" size={16} />
                </button>
                <span className={styles.contentTitle}>Скачивание файла в формате XLSX</span>
                <p className={styles.contentText}>Выберите для скачивания необходимые колонки</p>
                <div className={styles.list}>
                    {values.map((item) => (
                        <div key={item.name} className={styles.listElement}>
                            <Checkbox checked={item.selected} onChange={(e) => updateValues(item, e.target.checked)} />
                            <span>{item.name}</span>
                        </div>
                    ))}
                </div>
                <div className={styles.footer}>
                    <div className={styles.footerButtons}>
                        <Button
                            text="Отменить"
                            type="secondary"
                            size="large"
                            onClick={close}
                        />
                        <Button
                            tagId="campaign-save"
                            text="Сохранить"
                            type="default"
                            size="large"
                            onClick={onClickSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ModalXlsxExport;
