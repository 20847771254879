import { rtkQApi } from '../../index';
import { IPosts } from '../tracking/models';
import {
    ICampaignPostCommentFromApi,
    ICampaignPostCommentModel,
} from './types';

export const postsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        deletePost: build.mutation<void, { campaignId: number; id: number }>({
            query: (params) => ({
                url: `campaign/${params.campaignId}/posts/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CampaignDeletedPosts'],
        }),
        getGraphPosts: build.query<any, { id: number }>({
            query: (params) => ({
                url: `graph/${params.id}`,
                method: 'GET',
            }),
        }),
        getDeletedPosts: build.query<IPosts[], { campaignId: number }>({
            query: (params) => ({
                url: `campaign/${params.campaignId}/posts/deleted`,
                method: 'GET',
            }),
            providesTags: ['CampaignDeletedPosts'],
        }),
        postRestore: build.mutation<void, { campaignId: number; id: number }>({
            query: (params) => ({
                url: `campaign/${params.campaignId}/posts/${params.id}`,
                method: 'POST',
            }),
            invalidatesTags: ['CampaignDeletedPosts'],
        }),
        postRestoreAll: build.mutation<void, { campaignId: number }>({
            query: (params) => ({
                url: `campaign/${params.campaignId}/posts/restore_all`,
                method: 'POST',
            }),
            invalidatesTags: ['CampaignDeletedPosts'],
        }),
        getComments: build.query<
        ICampaignPostCommentModel[],
        { postId: number | string }
        >({
            query: (params) => ({
                url: `comments/${params.postId}`,
                method: 'GET',
            }),
            transformResponse: (
                baseQueryResponse: ICampaignPostCommentFromApi[],
            ): ICampaignPostCommentModel[] => baseQueryResponse.map((item) => {
                const postedfromDate = new Date(item.posted);
                return {
                    id: item.id,
                    content: item.content,
                    posted: item.posted,
                    postedDate: postedfromDate,
                    userName: item.user_name,
                    userLink: item.user_link,
                    images: item.images,
                    repliesCount: item.replies_count,
                } as ICampaignPostCommentModel;
            }),
        }),
        getRepliesComments: build.query<
        ICampaignPostCommentModel[],
        { postId: string; commentId: string }
        >({
            query: (params) => ({
                url: `comments/${params.postId}/comment/${params.commentId}`,
                method: 'GET',
            }),
            transformResponse: (
                baseQueryResponse: ICampaignPostCommentFromApi[],
            ): ICampaignPostCommentModel[] => baseQueryResponse.map((item) => {
                const postedfromDate = new Date(item.posted);
                return {
                    id: item.id,
                    content: item.content,
                    posted: item.posted,
                    postedDate: postedfromDate,
                    userName: item.user_name,
                    userLink: item.user_link,
                    images: item.images,
                    repliesCount: item.replies_count,
                } as ICampaignPostCommentModel;
            }),
        }),
        deletePostsByUserTag: build.query<void, { campaignId: number; id: number }>({
            query: (params) => ({
                url: `campaign/${params.campaignId}/posts/user_tag/${params.id}`,
                method: 'DELETE',
            }),
        }),
    }),
});
