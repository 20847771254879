import React, { useState } from 'react';
import UserNavigatePanel from '@components/UserProfile/NavigatePanel';
import { favoriteSourcesRtkQService } from '@services/campaigns/favorite-sources';
import { favoriteWordsRtkQService } from '@services/campaigns/favorite-words';
import { ISource } from '@services/campaigns/source/types';
import ContentContainer from '../../deprecated/pagesComponents/filters/ContentContainer';
import UserProfileFavoriteSources from './FavoriteSources';
import UserProfileFavoriteWords from './FavoriteWords';
import styles from './styles.module.scss';
import UserAddingFavoriteSource from './UserAddingFavoriteSource';
import UserAddingFavoriteWord from './UserAddingFavoriteWord';

const UserProfileFavoritesPage: React.FC = (): JSX.Element => {
    const [tab, setTab] = useState<0 | 1>(0);
    const {
        data: favoriteSources,
        isLoading: isFavoriteSourcesLoading,
        refetch: refetchSources,
        isError: isFavoriteSourcesError,
    } = favoriteSourcesRtkQService.useGetFavoriteSourcesQuery();
    const [addFavoriteSource] = favoriteSourcesRtkQService.useLazyPostCreateFavoriteSourceQuery();
    const [deleteFavoriteSource] = favoriteSourcesRtkQService.useLazyDeleteFavoriteSourceQuery();

    const {
        data: favoriteWords,
        isLoading: isFavoriteWordsLoading,
        refetch: refetchWords,
        isError: isFavoriteWordsError,
    } = favoriteWordsRtkQService.useGetFavoriteWordsQuery();
    const [addFavoriteWord] = favoriteWordsRtkQService.useLazyPostCreateFavoriteWordQuery();
    const [deleteFavoriteWord] = favoriteWordsRtkQService.useDeleteFavoriteWordMutation();

    const addSource = (source: ISource): void => {
        addFavoriteSource({
            source: {
                id: source.id,
                author_id: source.author_id,
                title: source.title,
                link: source.link,
                allow_in_russia: source.allow_in_russia,
                auto_discover: source.auto_discover,
                created: source.created,
                out_source_id: source.out_source_id,
                enabled: source.enabled,
                location_id: source.location_id,
                parse_link: source.parse_link,
                parsed: source.parsed,
                tag: source.tag,
                type_id: source.type_id,
            },
        }).then(() => refetchSources());
    };

    const deleteSource = (sourceId: number): void => {
        deleteFavoriteSource({ id: sourceId }).then(() => refetchSources());
    };

    const addWord = (word: string): void => {
        addFavoriteWord({ word }).then(() => refetchWords());
    };

    const deleteWord = (id: number): void => {
        deleteFavoriteWord({ id }).then(() => refetchWords());
    };

    return (
        <ContentContainer>
            <div className={styles.root}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.tabs}>
                            <button
                                type="button"
                                className={
                                    tab === 0 ? styles.tabsActive : undefined
                                }
                                onClick={() => setTab(0)}
                            >
                                Источники
                            </button>
                            <button
                                type="button"
                                className={
                                    tab === 1 ? styles.tabsActive : undefined
                                }
                                onClick={() => setTab(1)}
                            >
                                Слова
                            </button>
                        </div>

                        {tab === 0 && (
                            <UserAddingFavoriteSource addSource={addSource} />
                        )}
                        {tab === 1 && (
                            <UserAddingFavoriteWord addWord={addWord} />
                        )}
                    </div>
                    <div className={styles.content}>
                        {tab === 0 && (
                            <UserProfileFavoriteSources
                                sources={favoriteSources}
                                isLoading={isFavoriteSourcesLoading}
                                deleteSource={deleteSource}
                                isError={isFavoriteSourcesError}
                            />
                        )}
                        {tab === 1 && (
                            <UserProfileFavoriteWords
                                words={favoriteWords}
                                isLoading={isFavoriteWordsLoading}
                                deleteWord={deleteWord}
                                isError={isFavoriteWordsError}
                            />
                        )}
                    </div>
                </div>
                <UserNavigatePanel />
            </div>
        </ContentContainer>
    );
};

export default UserProfileFavoritesPage;
