import { IProjectStatus, ProjectStageTypes, ProjectStatusTypes } from '@services/projects/types';
import { t } from 'i18next';

export const transformProjectStatusResponse = (baseQueryResponse: any): IProjectStatus => ({
    status: baseQueryResponse?.status_id,
    stage: baseQueryResponse?.stage_id,
    statusLabel: t(ProjectStatusTypes[baseQueryResponse?.status_id]),
    stageLabel: t(ProjectStageTypes[baseQueryResponse?.stage_id]),

} as IProjectStatus);
