/* eslint-disable @typescript-eslint/no-unused-vars */
import { getCookie, removeCookies, setCookies } from 'cookies-next';
import newCheckCookies from '../../utils/CheckCookie';
import { AUTH_COOKIE_NAME } from '../../utils/constants';
import refreshToken from './handlerToken';

export default class HandlerStackApi {
    stack: any[];

    request: any;

    refreshing: any;

    resolveCheckToken: any;

    rejectCheckToken: any;

    isRefresh: boolean;

    refreshUrl: string;

    checkToken: Promise<unknown>;

    constructor() {
        this.resolveCheckToken = null;
        this.rejectCheckToken = null;
        this.checkToken = new Promise(this.createPromiseCheckToken());
        this.stack = [];
        this.isRefresh = false;
        this.refreshUrl = '/check_token';
    }

    clearCheckToken(): void {
        this.resolveCheckToken = null;
        this.rejectCheckToken = null;
        this.checkToken = new Promise(this.createPromiseCheckToken());
        this.stack = [];
        this.isRefresh = false;
    }

    addReaquest(request: any): void {
        if (request.options.url === this.refreshUrl) return;
        this.stack.push(request);
    }

    removeRequest(id: string): void {
        this.stack = this.stack.filter((item) => item.id !== id);
    }

    createPromiseCheckToken() {
        return (resolve, reject) => {
            this.resolveCheckToken = resolve;
            this.rejectCheckToken = reject;
            this.isRefresh = true;
            if (!getCookie(AUTH_COOKIE_NAME)) {
                resolve();
            } else {
                // refreshToken()
                //     .then((res) => {
                //         this.isRefresh = false;
                //         setCookies(AUTH_COOKIE_NAME, res.data.token);
                //         resolve(res);
                //     })
                //     .catch((err) => {
                //         this.isRefresh = false;
                //         const isRestore = window.location.pathname.indexOf('RestorePassword') + 1;
                //         const isRestoreLink = window.location.pathname.indexOf('restore_password') + 1;
                //         reject(err);
                //         if (window.location.pathname !== '/auth' && !isRestore && !isRestoreLink) {
                //             removeCookies(AUTH_COOKIE_NAME);
                //             newCheckCookies(AUTH_COOKIE_NAME);
                //         }
                //     });
                resolve();
            }
        };
    }

    getCheckToken() {
        return this.checkToken;
    }

    refreshCheckToken() {
        if (!this.isRefresh) this.checkToken = new Promise(this.createPromiseCheckToken());
        return this.checkToken;
    }

    createTest(request) {
        if (!this.request) { this.request = request; }
    }

    clearStack(): void {
        this.stack = [];
        this.isRefresh = false;
    }

    getStack() {
        return JSON.parse(JSON.stringify(this.stack));
    }
}
