import React from 'react';
import classnames from 'classnames';
import { ISelectItem } from '@shared/ui-components/Select/types';
import { Select } from '@shared/index';
import styles from '../styles.module.scss';
// import SingleSelectItem from '../../../../common/SingleSelectItem';

interface ICampaignReportsNameFieldProps {
    selectItemId: number;
    setValue: (id: number) => void;
    listValues: ISelectItem[];
}
const CampaignReportsPeriodField: React.FC<ICampaignReportsNameFieldProps> = ({
    setValue, selectItemId, listValues,
}): JSX.Element => (
    <div className={classnames(styles.flexColumn, styles.gap8)}>
        <div className={classnames(styles.flexRow, styles.gap20)}>
            <div className={classnames(styles.circle, styles.active)}>2</div>
            <span className={styles.label}>Период</span>
        </div>
        <div className={classnames(styles.flexRow, styles.gap20)}>
            <div style={{ width: 24 }} />
            <div className={styles.dropdownBorder} style={{ width: 257 }}>
                <Select
                    items={listValues}
                    onSelect={setValue}
                    defaultItem={selectItemId}
                    placeholder="7 дней"
                />
                {/* <SingleSelectItem
                    tagId="campaign-reports-filters-period"
                    onSelect={setValue}
                    selectedId={selectItemId}
                    defaultTitle={<span>7 дней</span>}
                    list={listValues}
                    height={30}
                    minWidth={220}
                    isLoading={false}
                /> */}
            </div>
        </div>
    </div>
);

export default CampaignReportsPeriodField;
