import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCallback, useRef } from 'react';
import { selectCommonBreadCrumbsPathsHistory } from '../../store/common/breadCrumbs/selectors';
import styles from './styles.module.scss';

const BreadCrumbs: React.FC = (): React.ReactElement => {
    const paths = useSelector(selectCommonBreadCrumbsPathsHistory);
    const wrapperRef = useRef(null);
    const containerRef = useRef(null);
    const intervalRef = useRef<number | null>(null);

    const startInterval = useCallback((action: 'scrollToLeft' | 'scrollToRight') => {
        intervalRef.current = window.setInterval(() => {
            if (action === 'scrollToLeft') wrapperRef.current.scrollLeft -= 10;
            if (action === 'scrollToRight') wrapperRef.current.scrollLeft += 10;
        }, 25);
    }, [intervalRef.current]);

    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const handleMouseMove = useCallback(
        (ev) => {
            const scrollStartLeftPos = wrapperRef.current.scrollLeft;
            const scrollXCenterPos = containerRef.current.offsetWidth / 2;
            const { layerX } = ev.nativeEvent;

            if (intervalRef.current) {
                stopInterval();
            }

            if (layerX > scrollXCenterPos && layerX > scrollStartLeftPos) {
                startInterval('scrollToRight');
            }

            if (layerX < scrollXCenterPos && scrollStartLeftPos > 0) {
                startInterval('scrollToLeft');
            }
        },
        [containerRef.current, wrapperRef.current],
    );

    const handleMouseLeave = () => {
        stopInterval();
    };

    return (
        <div
            className={styles.wrapper}
            ref={wrapperRef}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styles.container} ref={containerRef}>
                {paths
                    && paths.map((path) => (
                        <>
                            <Link key={path.pathname} to={path.pathname}>
                                {path.title}
                            </Link>
                            <p>/</p>
                        </>
                    ))}
            </div>
        </div>
    );
};
export default BreadCrumbs;
