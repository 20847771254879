import { rtkQApi } from '../../index';
import { IQueryParamsSearch, ISource } from './types';

export const sourcesRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postSearch: build.query<ISource[], IQueryParamsSearch>({
            query: (body) => ({
                url: 'source/search',
                method: 'POST',
                body,
            }),
        }),
    }),
});
