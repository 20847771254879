/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styles from './styles.module.scss';
// import SvgIcon from '../../../../SvgIcon';

const ChartWrapper: React.FC<any> = ({ children, title }): JSX.Element => (
    <div className={`${styles.chartWrapper}`}>
        <div className={styles.wrapperHeader}>
            <div>{title}</div>
        </div>
        {children}
    </div>
);

export default ChartWrapper;
