/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
import React from 'react';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IPropsTooltip {
    children: React.ReactNode;
    onClose: () => void;
}
const DefaultContent: React.FC<IPropsTooltip> = ({
    children,
    onClose,

}): JSX.Element => {
    return (
        <div className={styles.content_root}>
            {children}
            <div className={styles.icnContainer} onClick={() => onClose()}>
                <SvgIcon id="NewClose" size={11} />
            </div>
        </div>
    );
};

export default DefaultContent;
