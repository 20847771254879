import React, { useMemo } from 'react';
import classnames from 'classnames';
import Treemap from '../../../Treemap';
import styles from './styles.module.scss';

const BloggerProfileCategories: React.FC<any> = ({ dataSet, isRetinaSize }) => {
    const getTotalCount = (list) => list.reduce((acc, categoty) => {
        const summ = acc + categoty.count;
        return summ;
    }, 0);
    const randomColor = () => {
        const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
        const h = randomInt(0, 360);
        const s = randomInt(42, 98);
        const l = randomInt(60, 90);
        return `hsl(${h},${s}%,${l}%)`;
    };
    const getListWithPercent = (initialList, total) => initialList.map((category, index) => {
        const rawPercent = (category.count / total) * 100;
        const percent = +(rawPercent.toFixed(1));
        const categoryWithPercent = {
            ...category,
            percent,
            id: index,
            subCategories: [],
            color: randomColor(),
        };
        return categoryWithPercent;
    });
    const getRandomInt = (rawMin, rawMax) => {
        const min = Math.ceil(rawMin);
        const max = Math.floor(rawMax);
        return Math.floor(Math.random() * (max - min) + min);
    };
    const getSortedCategories = (initialList, isDescending) => {
        const comparer = isDescending
            ? (prev, value) => (value.count >= prev.count ? 1 : -1) // по убыванию
            : (prev, value) => (value.count <= prev.count ? 1 : -1); // по возрастанию
        return initialList.sort(comparer);
    };
    const getTotalPercent = (total, category) => {
        const drawSummedTotal = total + category.percent;
        return +(drawSummedTotal.toFixed(1));
    };
    const getLimitedList = (initialList: any[], limit) => {
        const cloneList: any[] = JSON.parse(JSON.stringify(initialList)).filter((item) => item);
        const bigestCategories = initialList.length >= limit ? cloneList.splice(0, limit) : [];
        const oldCategory = {
            name: 'Основное',
            count: getTotalCount(bigestCategories),
            subCategories: [],
            percentForBack: +(initialList.reduce(getTotalPercent, 0) - cloneList.reduce(getTotalPercent, 0)).toFixed(1),
            lengthForBack: (initialList.length - cloneList.length) + 1,
            percent: 15,
            color: bigestCategories[0]?.color,
            id: getRandomInt(543324232, 4324324123),
            isBack: true,
        };
        const newCategory = {
            name: 'Остальное',
            count: getTotalCount(bigestCategories),
            subCategories: [oldCategory, ...cloneList].filter((subCategory) => subCategory.percent > 0),
            percent: cloneList.reduce(getTotalPercent, 0),
            color: randomColor(),
            id: getRandomInt(5432, 402123),
            isSubCategories: true,
        };

        const limitedList = bigestCategories;
        if (cloneList.length > limit) {
            limitedList.push(newCategory);
        }
        return limitedList;
    };
    const getFormedList = (categoryList, limit): any[] => {
        const totalCountArticle = getTotalCount(categoryList);
        const categoryListWithPercent = getListWithPercent(categoryList, totalCountArticle);
        const sortedCategories = getSortedCategories(categoryListWithPercent, true);
        const limitedList = getLimitedList(sortedCategories, limit - 1);
        return limitedList;
    };
    // eslint-disable-next-line consistent-return
    const recursion = (list, oldList, lim, count) => {
        const currentCount = count + 1;
        const otherCategories = list[list.length - 1].subCategories;
        const baseCase = !otherCategories.length;
        const formedList = getFormedList(otherCategories, lim);
        if (baseCase) return [...oldList, formedList];
        return recursion(formedList, [...oldList, formedList], lim, currentCount);
    };
    const getData = (isPercent, limit) => {
        const objectListCategories = dataSet?.categories;
        if (!objectListCategories) return [];
        const categoryList = Object.keys(objectListCategories).map((name) => ({ name, count: objectListCategories[name] }));
        const list = getFormedList(categoryList, limit);
        if (isPercent) return list;
        return recursion(list, [list], limit, 0);
    };
    const listCategory = useMemo(() => getData(true, 5), [dataSet?.categories]);
    return (
        <div className={styles.root}>
            <div className={classnames(styles.title, styles[isRetinaSize && 'retina'])}>
                Категории
            </div>
            <div style={{ height: '200px' }}>
                {/* <TreemapChart /> */}
                {listCategory.length ? <Treemap list={listCategory} /> : ''}
            </div>
            <div className={styles.horizontalLine} />
        </div>
    );
};

export default React.memo(BloggerProfileCategories);
