import { ModalError, ModalAction } from '@entities/index';
import { useState } from 'react';
import { Button } from '@shared/index';
import styles from './styles.module.scss';

const ModalsDemo: React.FC = (): React.ReactElement => {
    const [isErrorVisible, setErrorVisible] = useState(false);
    const [isWarningVisible, setWarningVisible] = useState(false);
    const [isActionVisible, setActionVisible] = useState(false);

    return (
        <div className={styles.wrapper}>
            <div className={styles.buttons}>
                <Button
                    size="large"
                    type="danger"
                    onClick={() => setErrorVisible(true)}
                    text="Error"
                />
                <Button
                    size="large"
                    type="default"
                    onClick={() => setWarningVisible(true)}
                    text="Warning"
                />
                <Button
                    size="large"
                    type="default"
                    onClick={() => setActionVisible(true)}
                    text="Action"
                />
            </div>
            <ModalError
                visible={isErrorVisible}
                type="error"
                title="Ошибка валидации"
                text="Пример с текстом в одну строку"
                onClose={() => setErrorVisible(false)}
            />
            <ModalError
                visible={isWarningVisible}
                type="warning"
                title="Ошибка"
                text="источник уже создан"
                onClose={() => setWarningVisible(false)}
            />
            <ModalAction
                visible={isActionVisible}
                title="Удаление файла"
                text="Вы уверенны, что хотите удалить файл?"
                onClose={() => setActionVisible(false)}
                actions={[
                    <Button
                        key={1}
                        text="Удалить"
                        type="danger"
                        size="medium"
                        onClick={() => setActionVisible(false)}
                    />,
                    <Button
                        key={2}
                        text="Отменить"
                        type="secondary"
                        size="medium"
                        onClick={() => setActionVisible(false)}
                    />,
                ]}
            />
        </div>
    );
};
export default ModalsDemo;
