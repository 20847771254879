/* eslint-disable @typescript-eslint/no-unused-vars */
import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import Card from '../Card';
import BigBubbleDemo from '../../BigBubbleDemo';
import ChartGraphWitTooltipDemo from '../../ChartGraphWitTooltipDemo';
import TagCloudDemo from '../../TagCloudDemo';
import DoughnutChartDemo from '../../DoughnutChartDemo';

const style: any = {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '250px',
};
export const Container = () => {
    // eslint-disable-next-line no-lone-blocks
    {
        const [cards, setCards] = useState([
            {
                id: 1,
                text: 'SORTABLE TEST',
            },
            {
                id: 2,
                text: 'SORTABLE TEST 2',
                // component: <ChartGraphWitTooltipDemo />,
            },
            {
                id: 3,
                text: 'SORTABLE TEST 3',
                // component: <BigBubbleDemo />,
            },
            {
                id: 4,
                text: 'SORTABLE TEST 4',
                // component: <TagCloudDemo />,
            },
            {
                id: 5,
                text: 'SORTABLE TEST 5',
                // component: <DoughnutChartDemo />,
            },
            {
                id: 6,
                text: 'SORTABLE TEST 6',
            },
            {
                id: 7,
                text: 'SORTABLE TEST 7',
            },
        ]);
        const moveCard = useCallback((dragIndex, hoverIndex) => {
            setCards((prevCards) => update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            }));
        }, []);
        const renderCard = useCallback((card, index) => (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                text={card.text}
                moveCard={moveCard}
                component={card.component}
            />
        ), []);
        return (
            <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
        );
    }
};
