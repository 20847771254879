/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useCategories from '../../shared/hooks/campaign/useCategories';
import { selectCommonWindowIsIPadSize } from '../../store/common/window/selectors';
import CampaignFilters from '../../widgets/campaign/tracking/Filters';
import styles from './styles.module.scss';
import { campaignsListRtkQService } from '../../services/campaigns/campaigns-list';
import { DateRangePickerValue } from '../../components/DateRangePicker/types';
import { IApplyFilter, IFilter } from './CampaingTypes';
import BackgroundInformation from '../../entities/BackgroundInformation';
import { EmptyFilters, LinePosts, TonalityChart } from '../../widgets/campaign/tracking';

const MyCampaignContainer: React.FC = (): JSX.Element => {
    const { campaignId } = useParams();
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);
    const [period, setPeriod] = useState<DateRangePickerValue>();
    const [filtersTags, setFiltersTags] = useState<IFilter[]>([]);
    const [emptyPosts, setEmptyPosts] = useState(false);
    const [
        categories,
        filtersApply,
        update,
        handlers,
        isFetching,
        isLoading,
        isError,
        activeTags,
    ] = useCategories(+campaignId, period);
    const {
        init,
        sendRequest,
        repeat,
        integrate,
    } = handlers;
    const {
        filters_exclude: excludes,
        filters_include: includes,
    } = filtersApply;
    const {
        data: campaignsRtkQ,
    } = campaignsListRtkQService.useGetCampaignsQuery();
    useEffect(() => {}, [isFetching]);
    const currentCampaign = useMemo(() => {
        const currentCampaing = campaignsRtkQ?.find((item) => item.id === +campaignId);
        return currentCampaing;
    }, [campaignsRtkQ]);
    const handleSetFilters = () => {
        sendRequest();
    };
    const handlerSetPeriod = (preiod: DateRangePickerValue) => {
        setPeriod(preiod);
        sendRequest();
    };
    const handleCleanFilters = () => {
        init();
    };
    const handleRepeat = () => {
        repeat();
    };
    const handleUpdateCategories = (filter: IApplyFilter | IApplyFilter[], exclude: IApplyFilter | IApplyFilter[], upd?: boolean) => {
        if (upd) {
            const lInc = Array.isArray(filter) ? filter : [filter];
            const lExc = Array.isArray(exclude) ? exclude : [exclude];
            integrate(lInc, lExc);
            return;
        }
        update(filter, exclude);
        // sendRequest();
    };
    const handleSetTags = (data: any) => {
        setFiltersTags(data);
    };
    const blockInfo = (
        <div className={styles.root_campaignInfo}>
            <BackgroundInformation
                campaignId={+campaignId}
                filters={includes}
                period={period}
            />
            <TonalityChart
                campaignId={+campaignId}
                filters={includes}
                period={period}
                isIPad={isIPadSize}
            />
            <LinePosts
                campaignId={+campaignId}
                filters={includes}
                period={period}
                isIPad={isIPadSize}
                onError={(status) => setEmptyPosts(status)}
            />
        </div>
    );
    const emptyFiltersAfterFilters = (
        <div className={styles.root_campaignInfo}>
            <EmptyFilters
                onCleanFilters={handleCleanFilters}
                onRepeat={handleRepeat}
            />
        </div>
    );
    return (
        <div className={styles.root_campaign}>
            <div className={styles.container_Info}>
                {
                    emptyPosts ? emptyFiltersAfterFilters : blockInfo
                }
            </div>
            <CampaignFilters
                campaignId={+campaignId}
                campaignCreateDate={currentCampaign?.createDate}
                isIPad={isIPadSize}
                isActiveCampaign={currentCampaign?.isActive}
                isLoadingCategories={isFetching || isLoading}
                filtersTags={filtersTags}
                setFiltersTags={handleSetTags}
                filters={filtersApply}
                categories={categories}
                period={period}
                setPeriod={handlerSetPeriod}
                onSetFilters={handleSetFilters}
                onUpdateCategories={handleUpdateCategories}
                onCleanFilters={handleCleanFilters}
                activeTags={activeTags}
            />
        </div>
    );
};

export default React.memo(MyCampaignContainer);
