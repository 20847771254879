import campaignUtils from '../../../utils/campaign';
import { rtkQApi } from '../..';
import { ICampaignDataModel, ICampaignsListApi } from './types';
import { campaignActions } from '../../../store/campaign/source';

export const campaignsListRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getCampaigns: build.query<ICampaignDataModel[], void>({
            query: () => ({
                url: 'campaign/',
                method: 'GET',
            }),
            providesTags: ['CampaignsList'],
            transformResponse: (baseQueryResponse: ICampaignsListApi[]): ICampaignDataModel[] => baseQueryResponse.map((item) => {
                const parseFromDate = new Date(item.parse_from);
                return {
                    id: item.id,
                    name: item.name,
                    userId: item.owner_id,
                    parseFrom: parseFromDate.toLocaleDateString('ru-RU'),
                    parseFromDate,
                    createDate: new Date(item.create_time).toLocaleDateString('ru-RU'),
                    createTimestamp: item.create_time,
                    isActive: campaignUtils.campaignStatusToBool(item.status.name),
                } as ICampaignDataModel;
            }),
            async onQueryStarted(args: void, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(campaignActions.setDataFromRtkQApi(data));
                    });
            },
        }),
    }),
});
