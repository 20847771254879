/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import { Collapse } from 'antd';
import React, {
    useRef,
} from 'react';
import MapRussia from '../../../../../components/MapRussia';
import { TitleChart } from '../../../style';

const { Panel } = Collapse;

const MapOfRussiaDemo = () => {
    const chartRef = useRef(null);
    return (
        <div>
            <TitleChart style={{ marginBottom: '12px' }}>Карта</TitleChart>
            <Collapse>
                <Panel
                    key="MapOfAllColors"
                    header={<TitleChart style={{ marginBottom: '12px' }}>Вся</TitleChart>}
                >
                    <MapRussia listActiveRegions={['dfo', 'sevkav', 'ufo', 'center', 'privol', 'ural', 'sibir', 'sevzap']} />
                </Panel>
                <Panel
                    key="MapOfAllGray"
                    header={<TitleChart style={{ marginBottom: '12px' }}>Вся серая</TitleChart>}
                >
                    <MapRussia
                        listActiveRegions={['dfo', 'sevkav', 'ufo', 'center', 'privol', 'ural', 'sibir', 'sevzap']}
                        colorsRegion={{
                            dfo: '#dfdfdf',
                            sevkav: '#dfdfdf',
                            ufo: '#dfdfdf',
                            center: '#dfdfdf',
                            privol: '#dfdfdf',
                            ural: '#dfdfdf',
                            sibir: '#dfdfdf',
                            sevzap: '#dfdfdf',
                        }}
                        isTooltip={false}
                        defaulStroke="purple"
                        fillHover="chocolate"
                    />
                </Panel>
                <Panel
                    key="dfoKeyMap"
                    header={<TitleChart style={{ marginBottom: '12px' }}>Дальн восток</TitleChart>}
                >
                    <div style={{
                        position: 'relative', width: '500px', height: '350px', zIndex: 10,
                    }}
                    >

                        <div style={{ position: 'absolute', right: '90px', paddingRight: '60px' }}>
                            <MapRussia listActiveRegions={['dfo']} colorsRegion={{ dfo: 'purple' }} />
                        </div>
                    </div>
                </Panel>
                <Panel
                    key="sevkavOfMap"
                    header={<TitleChart style={{ marginBottom: '12px' }}>Сев. Кавказ</TitleChart>}
                >
                    <div style={{
                        position: 'relative', width: '500px', height: '350px', zIndex: 5, overflow: 'hidden',
                    }}
                    >
                        <TitleChart style={{ marginBottom: '12px' }}>Сев. Кавказ Тфу!!</TitleChart>
                        <div style={{ position: 'absolute', left: '150px', bottom: '80px' }}>
                            <MapRussia sizeMap={1600} listActiveRegions={['sevkav']} colorsRegion={{ sevkav: 'orchid' }} />
                        </div>
                    </div>
                </Panel>
                <Panel
                    key="ufoofMap"
                    header={<TitleChart style={{ marginBottom: '12px' }}>Юг</TitleChart>}
                >
                    <div style={{
                        position: 'relative', width: '500px', height: '350px', zIndex: 4, overflow: 'hidden',
                    }}
                    >
                        <div style={{ position: 'absolute', left: '150px', bottom: '50px' }}>
                            <MapRussia sizeMap={1200} listActiveRegions={['ufo']} colorsRegion={{ ufo: 'darkturquoise' }} />
                        </div>
                    </div>
                </Panel>
                <Panel
                    key="centerprivolofMAp"
                    header={<TitleChart style={{ marginBottom: '12px' }}>Центр + Поволжье</TitleChart>}
                >
                    <div style={{
                        position: 'relative', width: '500px', height: '350px', zIndex: 4, overflow: 'hidden',
                    }}
                    >
                        <div style={{ position: 'absolute', left: '150px', bottom: '10px' }}>
                            <MapRussia sizeMap={1000} listActiveRegions={['center', 'privol']} colorsRegion={{ privol: 'lightblue' }} />
                        </div>
                    </div>
                </Panel>
            </Collapse>

        </div>
    );
};
export default MapOfRussiaDemo;
