import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IProps {
    searchValue: string;
    onSearch: (value: string) => void;
}

const SourceSearchField: React.FC<IProps> = ({ searchValue, onSearch }): JSX.Element => {
    const [isInputFocused, setInputFocus] = useState<boolean>(false);

    const magnifierIsVisible = useMemo<boolean>(() => (
        !isInputFocused && !(searchValue?.length > 0)
    ), [isInputFocused, searchValue]);

    const handlerChangeSearchValue = (value: string): void => {
        if (onSearch) {
            onSearch(value);
        }
    };
    return (
        <div
            className={classnames(
                styles.searchField,
                isInputFocused ? styles.searchField_focus : '',
            )}
            style={magnifierIsVisible ? {} : { justifyContent: 'space-between' }}
        >
            {magnifierIsVisible && (
                <div className={styles.searchField_imgSearch}>
                    <SvgIcon id="Search" size={16} />
                </div>
            )}
            <input
                placeholder="Поиск категории"
                className={styles.searchField_input}
                style={!magnifierIsVisible ? { marginLeft: 10 } : {}}
                autoComplete="on"
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                onChange={(event) => handlerChangeSearchValue(event.target.value)}
                value={searchValue}
            />
            {
                searchValue?.length > 0 && (
                    <button
                        type="button"
                        className={styles.searchField_imgCross}
                        onClick={() => handlerChangeSearchValue('')}
                    >
                        <SvgIcon id="Cross" size={14} />
                    </button>
                )
            }
        </div>
    );
};

export default SourceSearchField;
