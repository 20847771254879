import { INotificationDataModel, INotificationFromApi } from 'services/campaigns/notification/types';
import { rtkQApi } from '../..';

export const campaignNotificationRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getNotification: build.query<INotificationDataModel[], void>({
            query: () => ({
                url: '/notify/',
                method: 'GET',
            }),
            transformResponse: (
                baseQueryResponse: INotificationFromApi[],
            ): INotificationDataModel[] => baseQueryResponse.map(
                (item) => ({
                    id: item?.id,
                    campUsersId: item?.camp_users_id,
                    enabled: Boolean(item?.enabled),
                    campaignName: item?.campaign_name,
                    type: item?.type,
                    destination: item?.destination,
                } as INotificationDataModel),
            ),
            providesTags: ['CampaignsNotificationList'],
        }),
        getSubscribeTgToken: build.query<{ token: string }, { id: number }>({
            query: (params) => ({
                url: `/notify/tg/${params.id}`,
                method: 'GET',
            }),
        }),
        putSetNotificationStatus: build.mutation<void, { id: number, enabled: boolean }>({
            query: (params) => ({
                url: `/notify/${params.id}`,
                method: 'PUT',
                body: {
                    enabled: params.enabled,
                },
            }),
            invalidatesTags: ['CampaignsNotificationList'],
        }),
    }),
});
