import React, { ChangeEvent } from 'react';
import { campaignReportsRtkQService } from '../../../../../services/campaigns/reports';
import Loader from '../../../../common/Loader';
import IconUpload from '../imgs/upload.svg';
import styles from './styles.module.scss';

interface IProps {
    campaignId: string;
}

const CampaignUploadReport: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { campaignId } = props;
    const [saveReportFile, { isLoading }] = campaignReportsRtkQService.usePostSaveReportFileMutation();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const extension = file.name.split('.').pop();
            saveReportFile({
                campaignId,
                data: {
                    file,
                    reportName: file.name,
                    reportTimeFrame: 'WEEK',
                    extension,
                    generated: false,
                    chart_ids: [],
                },
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            {isLoading ? <div className={styles.loader}><Loader /></div>
                : (
                    <div className={styles.container}>
                        <img src={IconUpload} alt="IconUpload" />
                        <p>Загрузить свой отчет</p>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept=".doc,.docx,.pptx,.pdf"
                        />
                    </div>
                ) }
        </div>
    );
};
export default CampaignUploadReport;
