import { rtkQApi } from '../..';
import { TFactResultModel, TStopProjectModel } from '../types/results/models';
import { TServiceDownloadResults, TServiceStopProject } from '../types/results/queryParams';

export const projectsResultsService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        // campaign/chart/download
        getResultFacts: build.query<TFactResultModel[], number>({
            query: (id) => ({
                url: `/media_projects/project/${id}/get_facts`,
                method: 'GET',
            }),
        }),
        stopProject: build.query<TStopProjectModel, TServiceStopProject>({
            query: ({ id, body }) => ({
                url: `/media_projects/project/${id}/stop`,
                method: 'POST',
                body,
            }),
        }),
        downloadResults: build.query<any, TServiceDownloadResults>({
            query: (body) => ({
                url: 'campaign/chart/download',
                method: 'POST',
                responseHandler: async (response) => {
                    const resp = await response.blob();
                    const file = new Blob([resp], { type: 'application/xlsx' });
                    const fileURL = URL.createObjectURL(file);
                    // window.open(fileURL, '_blank');
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        'Мониторинг.xlsx',
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    // return window.location.assign(window.URL.createObjectURL(await response.blob()))
                },
                cache: 'no-cache',
                body,
            }),
        }),
        updateComment: build.query<TFactResultModel[], { id: number, sourceId: number, comment: string }>({
            query: ({ id, comment, sourceId }) => ({
                url: `media_projects/project/${id}/sources`,
                method: 'PUT',
                body: {
                    sources: [
                        {
                            id: sourceId,
                            comment,
                        },
                    ],
                },
            }),
        }),

    }),
});
