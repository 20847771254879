import React from 'react';
import classnames from 'classnames';
import { ISimpleEntity } from 'store/types';
import { useTranslation } from 'react-i18next';
import LinearLoader from '../../Loader';
import styles from './styles.module.scss';

interface ISingleDropdownListProps {
    tagId?: string;
    items: ISimpleEntity[];
    onSelect: (id: number) => void;
    isLoading: boolean,
}
const SingleDropdownList: React.FunctionComponent<ISingleDropdownListProps> = ({
    items, onSelect, tagId, isLoading,
}): JSX.Element => {
    const onHandlerClick = (id: string | number): void => {
        onSelect(Number(id));
    };
    const { t } = useTranslation();

    if (!isLoading && !items?.length) {
        return null;
    }

    return (
        <div className={classnames(styles.container)}>
            <div className={styles.wrapper}>
                {
                    isLoading && !items?.length && (
                        <div className={styles.loaderContainer}>
                            Подгружаем список
                            <LinearLoader />
                        </div>
                    )
                }
                {
                    !isLoading && items?.length > 0 && items.map((item) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        <span
                            id={`${tagId ?? ''}-${item.name?.toLowerCase()}`}
                            className={classnames(
                                styles.text,
                            )}
                            style={{ whiteSpace: 'nowrap' }}
                            key={item.id}
                            onClick={() => onHandlerClick(item.id)}
                        >
                            {t(item.name)}
                        </span>
                    ))
                }
            </div>
        </div>
    );
};

export default SingleDropdownList;
