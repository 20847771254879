/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Checkbox } from 'antd';
import styles from './styles.module.scss';

interface IItemSelcet {
    id: number,
    label: string,
    isSelect: boolean
}
interface IPropsListSourcesForDropdown {
    list: IItemSelcet[];
    onChangeChecked: (id: number) => void;
}
const ListSourcesForDropdown: React.FC<IPropsListSourcesForDropdown> = ({ list, onChangeChecked }): JSX.Element => {
    const listCheckboxes = list.map((item) => (
        <div className={styles.list} key={`lists_souces_dropdown_${item.id}`}>
            <div className={styles.list_chkbx}>
                <Checkbox
                    checked={item.isSelect}
                    onChange={() => onChangeChecked(item.id)}
                />
            </div>
            <div className={styles.list_label}>{item.label}</div>
        </div>
    ));
    const empty = (
        <div className={styles.empty}>
            <div className={styles.list_label} style={{ fontSize: 12 }}>Подходящих списков не найденно</div>
        </div>
    );
    return (
        <div className={styles.listsSourcs}>
            {listCheckboxes?.length ? listCheckboxes : empty}
        </div>
    );
};

export default React.memo(ListSourcesForDropdown);
