import React from 'react';
import { Drawer } from 'antd';
import { IconButtonSidebar } from '@shared/index';
import styles from './styles.module.scss';

interface IProps {
    title: string;
    visible: boolean;
    onClose: () => void;
}

const Sidebar: React.FC<IProps> = ({
    title,
    visible,
    onClose,
    children,
}): JSX.Element => (
    <Drawer
        visible={visible}
        bodyStyle={{ padding: 0 }}
        width="800px"
        size="large"
        closable={false}
        onClose={onClose ?? onClose}
    >
        <div className={styles.sidebar_wrapper}>
            <span className={styles.sidebar_title}>{title}</span>
            <IconButtonSidebar className={styles.sidebar_close} iconId="Cross" iconSize={21} onClick={onClose} />
            <div className={styles.sidebar_info}>
                {children}
            </div>
        </div>
    </Drawer>
);

export default Sidebar;
