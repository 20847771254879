/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import {
    View, StyleSheet, Font, Text, Image,
} from '@react-pdf/renderer';
import CampaignPDFTemplatePage from '../TemplatePage';

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const styles = StyleSheet.create({
    root: {
        width: '100%',
        height: '100%',
        padding: 24,
        display: 'flex',
    },
});

interface IPDFChartList {
    data: any;
    reactElementKey: string;
    title: string;
    reportName: string;
    date: string;
}
const TemplateChartRegionData: React.FC<IPDFChartList> = ({
    data,
    reactElementKey,
    reportName,
    date,
    title,
}): JSX.Element => (
    <CampaignPDFTemplatePage reactElementKey={reactElementKey} reportName={reportName} date={date} title={title}>
        <View style={styles.root}>
            <Text>
                {/* test */}
            </Text>
            <View>
                <View
                    style={{
                        position: 'absolute',
                        top: '16px',
                        left: '16px',
                        width: `${260 * 1.3}px`,
                        height: `${270 * 1.3}px`,
                    }}
                >
                    <Text style={{
                        fontFamily: 'MontserratMedium500',
                        marginLeft: '0px',
                        marginBottom: '8px',
                        backgroundColor: '#f5fefd',
                        fontSize: '16px',
                        color: '#787E93',
                        paddingTop: '8px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',
                    }}
                    >
                        {'СМИ по регионам'}
                    </Text>
                    <Image
                        src={data?.rss}
                    />
                </View>
                <View
                    style={{
                        position: 'absolute',
                        top: '16px',
                        left: '392px',
                        width: `${260 * 1.3}px`,
                        height: `${270 * 1.3}px`,
                    }}
                >
                    <Text style={{
                        fontFamily: 'MontserratMedium500',
                        marginLeft: '0px',
                        marginBottom: '8px',
                        backgroundColor: '#f5fefd',
                        fontSize: '16px',
                        color: '#787E93',
                        paddingTop: '8px',
                        paddingBottom: '12px',
                        paddingLeft: '24px',
                    }}
                    >
                        {'Блоггеры по регионам'}
                    </Text>
                    <Image
                        src={data?.blogger}
                    />
                </View>
            </View>
        </View>
    </CampaignPDFTemplatePage>
);

export default TemplateChartRegionData;
