/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import NextArrow from './nextArrow.svg';
import NextArrowWhite from './nextArrowWhite.svg';
import BackArrow from './backArrow.svg';
import BackArrowWhite from './backArrowWhite.svg';
import { MainCategory } from './Types';
import Category from './Category';

const Treemap: React.FC<{ list: MainCategory[] }> = ({ list }) => {
    const [hoveredCategory, setHoveredCategory]: any = useState(null);
    const [categoriesListUpdate, setCategiesList] = useState([]);
    const categoriesList = useMemo(() => (categoriesListUpdate.length ? categoriesListUpdate : list), [list, categoriesListUpdate]);
    const categoriesPercent = useMemo(() => {
        const listForCalc = list;
        return listForCalc.reduce((acc, item) => {
            const drawSummPercent = item.isSubCategories || item.isBack ? acc : acc + item.percent;
            return +(drawSummPercent.toFixed(0));
        }, 0);
    }, [list, categoriesListUpdate]);
    const getTypeCategory = (percent) => {
        if (percent > 16) return 'bigCategory';
        if (percent <= 9) return 'smallCategory';
        return 'category';
    };
    const handleMouseEnterOnCategory = (e, category) => {
        if (category.percent > 27.4 && category.name.length < 26) return;
        setHoveredCategory(category.id);
    };
    const getJustifyContentFoCategory = (isHovered, isBig) => {
        if (isHovered || isBig) return 'start';
        return 'center';
    };
    const getDisplayDescription = (category) => {
        const isHovered = hoveredCategory === category.id;
        if ((hoveredCategory && isHovered)) {
            return 'flex';
        }
        if (category.name.length > 25 && category.percent < 35) return 'none';
        if (category.name.length > 15 && category.percent < 23) return 'none';
        if (category.name.length > 8 && category.percent < 14) return 'none';
        if (category.percent < 9) return 'none';
        return 'flex';
    };
    const handleClickOnCategory = (category) => {
        if (category.isBack) {
            setCategiesList(list);
            return;
        }
        if (!category.isSubCategories) return;
        setCategiesList(category.subCategories);
    };
    const getArrow = (category, isBack) => {
        const checkHover = category.id === hoveredCategory;
        const style = {
            marginLeft: '4px',
        };
        const img = isBack ? BackArrow : NextArrow;
        const imgWhite = isBack ? BackArrowWhite : NextArrowWhite;
        const arrowMarkup = (
            <div style={style}>
                <img src={checkHover ? imgWhite : img} alt="img" />
            </div>
        );
        if (isBack) return arrowMarkup;
        return category.subCategories.length > 0 ? arrowMarkup : '';
    };
    const getPercentLabel = (category) => {
        const checkHovered = hoveredCategory === category.id;
        const label = (
            <div>
                {category.isBack ? categoriesPercent : category.percent}
                {category.percent < 5 ? '' : '%'}
            </div>
        );
        if (!hoveredCategory) { return label; }
        if (hoveredCategory && checkHovered) return label;
        if (hoveredCategory && !checkHovered && category.percent < 2.5) return '';
        return label;
    };
    const getList = () => categoriesList.map((category) => {
        const currentType = getTypeCategory(category.percent);
        const classesForCategory = `${styles[currentType]} ${category.percent <= 27.4 ? styles.withChangeSize : ''}`;
        const checkHovered = hoveredCategory === category.id;
        const isSubCategories = category.subCategories.length > 0;
        const stylesForCategory = {
            width: `${category.percent}%`,
            background: category.color,
            height: '100%',
            minWidth: hoveredCategory ? '0px' : '35px',
            cursor: isSubCategories || category.isBack ? 'pointer' : 'default',
            fontSize: category.percent <= 10 && !checkHovered ? '14px' : '24px',
            fontWeight: category.percent <= 10 && !checkHovered ? 400 : 'bold',
            justifyContent: getJustifyContentFoCategory(checkHovered, category.percent > 12.5),
        };
        const counter = isSubCategories
            ? (
                <div className={styles.descriptionContainer}>
                    <div>
                        {` (${category.subCategories.length})`}
                        {' '}
                    </div>
                </div>
            ) : null;
        const stylesForDescription = {
            display: getDisplayDescription(category),
        };
        return (
            <Category
                key={`markup_category_key_${category.id}`}
                category={category}
                handleClickOnCategory={handleClickOnCategory}
            />
            // <div
            //     id={`markup_category_id_${category.id}`}
            //     key={`markup_category_key_${category.id}`}
            //     className={classesForCategory}
            //     style={stylesForCategory}
            //     onMouseEnter={(e) => handleMouseEnterOnCategory(e, category)}
            //     onMouseLeave={() => setHoveredCategory(null)}
            //     onClick={() => handleClickOnCategory(category)}
            // >
            //     {getPercentLabel(category)}
            //     <div>
            //         <div style={stylesForDescription} className={styles.description}>
            //             {category.isBack ? getArrow(category, true) : ''}
            //             <span className={styles.name}>{`${category.name}`}</span>
            //             {hoveredCategory === category.id ? counter : ''}
            //             {category.isSubCategories ? getArrow(category, false) : ''}
            //         </div>
            //         {/* {getArrow(category)} */}
            //     </div>
            // </div>
        );
    });
    return (
        <div className={styles.root}>
            {categoriesList.length > 0 && getList()}
        </div>
    );
};

export default Treemap;
