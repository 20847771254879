import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from '../styles.module.scss';

const CampaignComparisonResultSkeleton: React.FC = (): JSX.Element => (
    <div className={styles.campaignComparisonResultSkeleton}>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
        <div className={styles.campaignComparisonResultSkeleton_cell}>
            <Skeleton height={40} width={250} />
            <Skeleton height={100} width={500} />
        </div>
    </div>

);

export default CampaignComparisonResultSkeleton;
