import React, { useMemo, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import HorizontalDotsIcon from '../../imgs/horizontalDots.svg';
import SearchGroupContextMenu from '../../ContextMenu/Group';
import CampaignSearchGroup from '..';
import CustomCheckbox from '../../../../Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';
import {
    IGroupParameters,
    ISearchGroup,
    ISearchPhraseWord,
    PhraseWordLogicOperatorType,
} from '../../types';
import SearchGroupLogicOperator from '../GroupLogicOperator';
import SearchGroupParametersContextMenu from '../../ContextMenu/GroupParameters';
import styles from '../../styles.module.scss';

interface IProps {
    isPreview: boolean;
    isView: boolean;
    group: ISearchGroup;
    onAddGroup: (groupIndex: number) => void;
    onDeleteGroup: (groupIndex: number) => void;
    onChangeGroupParameters: (groupIndex: number, value: IGroupParameters) => void;
    onAddPhrase: (groupIndex: number, value: ISearchPhraseWord[]) => void;
    setOperator: (groupIndex: number, operatorIndex: number) => void;
    deleteOperator: (groupIndex: number) => void;
    onGroupPreviewChecked: (groupIndex: number, value: boolean) => void;
}

const groupName = 'Группа';
const contextMenuOffset = 10;
const addNewGroupMenuId = 1;

const SearchNestedGroup: React.FC<IProps> = ({
    isPreview,
    isView,
    group,
    onAddGroup,
    onDeleteGroup,
    onChangeGroupParameters,
    onAddPhrase,
    setOperator,
    deleteOperator,
    onGroupPreviewChecked,
}): JSX.Element => {
    const { ref: groupRef, width: groupWidth } = useResizeObserver<HTMLDivElement>();

    const [isOpenGroupMenu, setOpenGroupMenu] = useState<boolean>(false);
    const [isOpenGroupParameters, setOpenGroupParameters] = useState<boolean>(false);

    const excludedParams = useMemo<number[]>(() => {
        if (isView) {
            return isView ? [addNewGroupMenuId, 2] : [];
        }
        return group?.indexNextGroup > 0 ? [addNewGroupMenuId] : [];
    }, [isView, group]);
    const groupsExists = useMemo<boolean>(
        () => group?.children?.filter(
            ({ operator }) => operator in PhraseWordLogicOperatorType,
        )?.length > 0,
        [group],
    );
    const onlyOrderOperator = useMemo<boolean>(() => (
        groupsExists
        && group?.children?.some(({ operator }) => operator === PhraseWordLogicOperatorType.order)
    ), [group, groupsExists]);
    const withoutOrderOperator = useMemo<boolean>(() => (
        groupsExists
        && group?.children?.every(({ operator }) => operator !== PhraseWordLogicOperatorType.order)
    ), [group, groupsExists]);

    const handlerOpenGroupMenu = (): void => {
        setOpenGroupMenu(true);
    };
    const handlerCloseGroupMenu = (): void => {
        setOpenGroupMenu(false);
    };
    const handlerCloseGroupParametersMenu = (): void => {
        setOpenGroupParameters(false);
    };
    const handlerSelectGroupMenu = (groupMenuId: number): void => {
        if (groupMenuId === 1) {
            onAddGroup(group.index);
        }
        if (groupMenuId === 2) {
            onDeleteGroup(group.index);
        }
        if (groupMenuId === 3) {
            setOpenGroupParameters(true);
        }
        setOpenGroupMenu(false);
    };
    const handlerSetOperator = (groupIndex: number, operatorIndex: number): void => {
        if (setOperator) {
            setOperator(groupIndex, operatorIndex);
        }
    };
    const handlerDeleteOperator = (groupIndex: number): void => {
        if (deleteOperator) {
            deleteOperator(groupIndex);
        }
    };
    const handlerChangeGroupParameters = (groupIndex: number, value: IGroupParameters): void => {
        if (onChangeGroupParameters) {
            onChangeGroupParameters(groupIndex, value);
        }
        setOpenGroupParameters(false);
    };
    const handlerGroupPreviewChecked = (groupIndex: number, value: boolean): void => {
        if (onGroupPreviewChecked) {
            onGroupPreviewChecked(groupIndex, value);
        }
    };

    return (
        <div className={styles.group_container}>
            <div className={styles.group_top} ref={groupRef} style={{ position: 'relative' }}>
                <span
                    className={styles.group_title}
                    style={isPreview ? { marginLeft: 24 } : null}
                >
                    {`${groupName} ${group.index}`}
                </span>
                <button
                    type="button"
                    onClick={handlerOpenGroupMenu}
                >
                    <img src={HorizontalDotsIcon} alt="HorizontalDots" />
                </button>
                <SearchGroupContextMenu
                    isView={isView}
                    top={0}
                    left={groupWidth + contextMenuOffset}
                    isOpen={isOpenGroupMenu}
                    onSelect={handlerSelectGroupMenu}
                    onClose={handlerCloseGroupMenu}
                    excludedItems={excludedParams}
                />
                <SearchGroupParametersContextMenu
                    index={group.index}
                    isView={isView}
                    top={0}
                    left={groupWidth + contextMenuOffset}
                    isOpen={isOpenGroupParameters}
                    parameters={group.parameters}
                    onChangeParameters={handlerChangeGroupParameters}
                    onClose={handlerCloseGroupParametersMenu}
                />
            </div>
            <div style={{ display: 'flex' }}>
                {
                    isPreview && (group.children?.length > 0) && (
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <CustomCheckbox
                                id={`checkbox-group-preview-${group.index}`}
                                style={{ gap: 0 }}
                                isDisabled={isView}
                                textClassName={styles.checkboxText}
                                name=""
                                checked={group.previewChecked}
                                onChecked={() => handlerGroupPreviewChecked(group.index, !group.previewChecked)}
                                isRetinaSize={false}
                            />
                        </div>
                    )
                }
                <div className={styles.group_box}>
                    {
                        group.children.map((item) => (
                            <>
                                {
                                    item?.children?.length > 0
                                        ? (
                                            <SearchNestedGroup
                                                key={item.index}
                                                isView={isView}
                                                isPreview={isPreview}
                                                group={item}
                                                onAddGroup={onAddGroup}
                                                onChangeGroupParameters={handlerChangeGroupParameters}
                                                onDeleteGroup={onDeleteGroup}
                                                onAddPhrase={onAddPhrase}
                                                onGroupPreviewChecked={handlerGroupPreviewChecked}
                                                setOperator={handlerSetOperator}
                                                deleteOperator={handlerDeleteOperator}
                                            />
                                        )
                                        : (
                                            <CampaignSearchGroup
                                                key={item.index}
                                                id={item.index}
                                                isView={isView}
                                                isPreview={isPreview}
                                                index={item.index}
                                                nextGroupIndex={item.indexNextGroup}
                                                phrases={item.phrases}
                                                groupParameters={item.parameters}
                                                onChangeGroupParameters={handlerChangeGroupParameters}
                                                onAddGroup={onAddGroup}
                                                onDeleteGroup={onDeleteGroup}
                                                setPhrases={onAddPhrase}
                                                groupPreviewChecked={item.previewChecked}
                                                onCheckedPreview={handlerGroupPreviewChecked}
                                            />
                                        )
                                }
                                {
                                    item?.indexNextGroup > 0 && (
                                        <SearchGroupLogicOperator
                                            style={isPreview ? { marginLeft: 24 } : null}
                                            isView={isView}
                                            groupIndex={item.index}
                                            operator={item.operator}
                                            onlyOrderOperator={onlyOrderOperator}
                                            withoutOrderOperator={withoutOrderOperator}
                                            setOperator={handlerSetOperator}
                                            deleteOperator={handlerDeleteOperator}
                                        />
                                    )
                                }
                            </>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchNestedGroup;
