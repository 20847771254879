/* eslint-disable @typescript-eslint/no-unused-vars */
// import request from '../request';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { sha512 } from 'js-sha512';
import { createRequest } from '../newRequest';

export const initRecoveryPassword = async (
    userEmail,
) => {
    const options = {
        method: 'POST',
        url: '/init_password_recovery',
        data: {
            email: userEmail,
        },
    };
    const instanceRequest = await createRequest(
        {
            headers: {},
        },
    );
    const request = instanceRequest.request(options);
    return (await request).data;
};

export const recoveryPassword = async (
    token,
    new_password,
    repeat_new_password,
) => {
    const options = {
        method: 'POST',
        url: '/password_recovery',
        data: {
            token,
            new_password: sha512(new_password),
            repeat_new_password: sha512(repeat_new_password),
        },
    };
    const instanceRequest = await createRequest(
        {
            headers: {},
        },
    );
    const request = instanceRequest.request(options);
    return (await request).data;
};
