import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

const CardPlotSkeleton : React.FC = (): React.ReactElement => (
    <div className={styles.wrapper}>
        <Skeleton width="100%" height={36} />
        <Skeleton width="100%" height={168} />
        <Skeleton width="100%" height={34} />
    </div>
);
export default CardPlotSkeleton;
