/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import Skeleton from 'react-loading-skeleton';
import DetailInfo from '../DetailInfo';
import { IDetailInfo } from '../types';
import styles from './styles.module.scss';

interface IPropsInformationBlockSource {
    label: string,
    importantDetail: string | JSX.Element,
    isHaveImortantDetail: boolean,
    detailInfo?: IDetailInfo[],
    isLoading: boolean,
    children?: JSX.Element,
    style?: React.CSSProperties,
    skeleton: {
        width: number,
        height: number
    }
}

const SourceFilter: React.FC<IPropsInformationBlockSource> = ({
    label,
    importantDetail,
    isHaveImortantDetail = true,
    detailInfo,
    isLoading,
    children,
    style,
    skeleton,
}): JSX.Element => {
    const getChildren = () => {
        if (children) return children;
        return null;
    };
    const getImportantDetail = () => {
        if (!isHaveImortantDetail) return null;
        const detail = <div className={styles.importantDetail}>{importantDetail}</div>;
        const empty = <div className={styles.emptyInportant}>Данных нет</div>;
        return importantDetail ? detail : empty;
    };
    const getDetailInfo = () => {
        if (!detailInfo?.length) return null;
        return <DetailInfo data={detailInfo} />;
    };
    if (isLoading) {
        return (
            <div className={styles.root_InformationBlock} style={{ ...style }}>
                <Skeleton width={130} style={{ marginBottom: 16 }} />
                {
                    skeleton ? <Skeleton width={skeleton.width} height={skeleton.height} style={{ marginBottom: 16 }} /> : null
                }
                <Skeleton width={150} height={28} style={{ marginBottom: 8 }} />
                <Skeleton width={190} height={36} />
            </div>
        );
    }
    return (
        <div className={styles.root_InformationBlock} style={{ ...style }}>
            <div className={styles.title}>{label}</div>
            <div className={styles.content}>
                {getChildren()}
            </div>
            {getImportantDetail()}
            {getDetailInfo()}
        </div>
    );
};
export default SourceFilter;
