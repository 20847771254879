import React from 'react';
import SvgIcon from '../../components/SvgIcon';
import styles from './styles.module.scss';

interface IProps {
    likes: number | null
    reposts: number | null
    comments: number | null
    views: number | null
}

const PostIndocators: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        likes, reposts, comments, views,
    } = props;
    return (
        <div className={styles.wrapper}>
            <span>
                <SvgIcon id="Like" size={24} />
                <p>{likes === null ? 'Нет данных' : likes}</p>
            </span>
            <span>
                <SvgIcon id="Reports" size={24} />
                <p>{reposts === null ? 'Нет данных' : reposts}</p>
            </span>
            <span>
                <SvgIcon id="Comments" size={24} />
                <p>{comments === null ? 'Нет данных' : comments}</p>
            </span>
            <span>
                <SvgIcon id="Views" size={24} />
                <p>{views === null ? 'Нет данных' : views}</p>
            </span>
        </div>
    );
};
export default PostIndocators;
