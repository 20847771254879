import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { ICampaignTrackedWordsInputContainerProps } from 'containers/campaign/CampaignTrackedWords/types';
import { useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import CampaignTrackedWordsInput from '../../../components/campaign/UpdateForm/TrackedWords/input';
import trackedSourcesSelectors from '../../../store/campaign/tracked-sources/selectors';
import { useAppDispatch } from '../../../store/hooks';
import loadCampaignTrackedSourcesThunk from '../../../store/campaign/tracked-sources/thunks/load-tracked-sources';
import { FetchStatus, ISimpleEntity } from '../../../store/types';
import CampaignAutoCompleteTrackedLinks from '../../../components/campaign/UpdateForm/TrackedWords/AutoComplete';
import { campaignTrackedSourcesActions } from '../../../store/campaign/tracked-sources';

const CampaignTrackedWordsInputContainer: React.FC<ICampaignTrackedWordsInputContainerProps> = ({
    inputTagId,
    addBtnTagId,
    placeholder,
    isErrorHighlighting,
    withSearch,
    sourceType,
    isTrackedSources,
    isIPadSize,
    onAddedSource,
    onAddWord,
    onChangeWord,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const trackedSourcesFetchStatus = useSelector(trackedSourcesSelectors.fetchStatus);
    const trackedSourceData = useSelector(trackedSourcesSelectors.data);

    const [needSendSearch, setNeedSendSearch] = useState<boolean>(false);
    const [currentInputValue, setCurrentInputValue] = useState<string>('');
    const [menuIsVisible, setVisibleMenu] = useState<boolean>(true);
    const [needClearInput, setClearInput] = useState<boolean>(false);

    const sourcesList = useMemo<ISimpleEntity[]>(() => (
        trackedSourceData?.map(({ id, title, link }) => ({
            id, name: title, value: link,
        })) ?? []
    ), [trackedSourceData]);

    const platforms = useMemo(() => sourceType?.filter(
        (value) => value !== 'ALL',
    ), [sourceType]);

    const handlerChangeWord = useCallback((value: string): void => {
        if (withSearch && value && value?.length > 2) {
            setNeedSendSearch(true);
            setCurrentInputValue(value);
        }
        if (currentInputValue !== value) {
            onChangeWord(value);
        }
    }, [currentInputValue, onChangeWord, withSearch]);

    const onMouseLeave = (): void => {
        if (menuIsVisible) {
            setVisibleMenu(false);
        }
    };
    const onMouseEnter = (): void => {
        if (!menuIsVisible) {
            setVisibleMenu(true);
        }
    };
    const handlerAddWord = (value: string, id: number): void => {
        onAddWord(value, id);
    };
    const handlerClearedInput = (): void => {
        setClearInput(false);
    };
    const onSelect = (selectedId: number) => {
        if (selectedId) {
            const item = { ...trackedSourceData.find(({ id }) => selectedId === id) };
            if (item?.link && item?.id) {
                if (onAddedSource) {
                    onAddedSource(item);
                }
                handlerAddWord(item.link, item.id);
                setCurrentInputValue('');
                onChangeWord('');
                setNeedSendSearch(false);
                setClearInput(true);
                dispatch(campaignTrackedSourcesActions.addSelectedSource({
                    isTracked: isTrackedSources, data: item,
                }));
                dispatch(campaignTrackedSourcesActions.clearData());
            }
        }
    };

    useEffect(() => {
        if (withSearch) {
            if (!currentInputValue?.length) {
                dispatch(campaignTrackedSourcesActions.clearData());
            }
            if (needSendSearch && trackedSourcesFetchStatus !== FetchStatus.loading) {
                dispatch(loadCampaignTrackedSourcesThunk(currentInputValue, platforms));
                setNeedSendSearch(false);
            }
        }
    }, [needSendSearch, currentInputValue, trackedSourcesFetchStatus, withSearch, platforms]);
    // useEffect(() => {
    //     if (sourceLinksCheckStatus === FetchCheckStatus.correct) {
    //         onAddWord(linkBuffer);
    //     }
    // }, [sourceLinksCheckStatus]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {
                withSearch
                    ? (
                        <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
                            <Dropdown
                                visible={menuIsVisible && (
                                    Boolean(sourcesList?.length) || trackedSourcesFetchStatus === FetchStatus.loading
                                )}
                                overlay={(
                                    <CampaignAutoCompleteTrackedLinks
                                        list={currentInputValue?.length ? sourcesList : []}
                                        onSelect={onSelect}
                                        isLoading={trackedSourcesFetchStatus === FetchStatus.loading}
                                    />
                                )}
                            >
                                <CampaignTrackedWordsInput
                                    inputTagId={inputTagId}
                                    addBtnTagId={addBtnTagId}
                                    placeholder={placeholder}
                                    onAddWord={handlerAddWord}
                                    isErrorHighlighting={isErrorHighlighting}
                                    onChangeWord={handlerChangeWord}
                                    withSearch
                                    onClear={handlerClearedInput}
                                    needClearInput={needClearInput}
                                    isTrackedSources={isTrackedSources}
                                    isIPadSize={isIPadSize}
                                />
                            </Dropdown>
                        </div>
                    )
                    : (
                        <CampaignTrackedWordsInput
                            inputTagId={inputTagId}
                            addBtnTagId={addBtnTagId}
                            placeholder={placeholder}
                            onAddWord={handlerAddWord}
                            isErrorHighlighting={isErrorHighlighting}
                            onChangeWord={handlerChangeWord}
                            withSearch={false}
                            needClearInput={false}
                            onClear={null}
                            isTrackedSources={isTrackedSources}
                            isIPadSize={isIPadSize}
                        />
                    )
            }
        </>
    );
};

export default CampaignTrackedWordsInputContainer;
