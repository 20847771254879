import {
    IContact, IContactPerson, ICustomerInfo, IProjectInfo, IUserInProject,
} from 'services/projects/types';

export const transformProjectInfoResponse = (baseQueryResponse: any): IProjectInfo => ({
    users: baseQueryResponse?.users.map((item) => ({
        userId: item?.user_id,
        email: item?.email,
        roleId: item?.role_id,
        firstName: item?.first_name,
        lastName: item?.last_name,
        middleName: item?.middle_name,
    } as IUserInProject)),
    id: baseQueryResponse?.id,
    name: baseQueryResponse?.name,
    created: new Date(baseQueryResponse?.created),
    start: new Date(baseQueryResponse?.start),
    finish: new Date(baseQueryResponse?.finish),
    comment: baseQueryResponse?.comment || 'Комментарий не найден',
    description: baseQueryResponse?.description,
    customer: ({
        address: baseQueryResponse?.customer?.address,
        companyName: baseQueryResponse?.customer?.company_name,
        contactPersons: [
            ({
                id: baseQueryResponse?.customer?.contact_persons[0]?.customer_info_id,
                fullName: baseQueryResponse?.customer?.contact_persons[0]?.full_name,
                contacts: baseQueryResponse?.customer?.contact_persons[0]?.contacts?.map((item) => ({
                    personId: item?.contact_person_id,
                    type: item?.contact_type_id,
                    value: item?.value,
                } as IContact)) ?? [],
            } as IContactPerson),
        ],
    } as ICustomerInfo),
});
