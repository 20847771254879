import PptxSlide from '../../PptxSlide';
import { barChartBaseOptions } from '../../chartOptions';
import { ICampaignPPTXRegionPageProps } from './types';

class CampaignPPTXRegionPage extends PptxSlide {
    render(props: ICampaignPPTXRegionPageProps) {
        this.renderHeader({
            date: props.date,
            title: props.title,
            layoutHeight: props.layoutHeight,
            layoutWidth: props.layoutWidth,
            reportName: props.reportName,
            header: {
                pageName: props.header.pageName,
                number: props.header.number,
            },
        });

        const options = {
            startY: 90,
            chartWidth: 350,
            chartHeight: 310,
            spaceBetween: 83,
        };

        if (props.rssByRegion) {
            const labels: string[] = [];
            const values: number[] = [];
            props.rssByRegion.forEach((item) => {
                labels.push(item.regionTitle);
                values.push(item.amountSources);
            });

            const chartData = [
                {
                    name: 'СМИ по регионам',
                    labels,
                    values,
                },
            ];

            this.addTitle('СМИ по регионам', 0, options.startY, 370);

            const chartOptions = {
                ...barChartBaseOptions,
                x: this.getInches(20),
                y: this.getInches(157),
                w: this.getInches(options.chartWidth),
                h: this.getInches(options.chartHeight),
            };

            // @ts-ignore
            this.slide.addChart('bar', chartData, chartOptions);
        }

        if (props.bloggerByRegion) {
            const labels: string[] = [];
            const values: number[] = [];
            props.bloggerByRegion.forEach((item) => {
                labels.push(item.regionTitle);
                values.push(item.amountSources);
            });

            const chartData = [
                {
                    name: 'Блоггеры по регионам',
                    labels,
                    values,
                },
            ];

            this.addTitle('Блоггеры по регионам', options.chartWidth + options.spaceBetween, options.startY, 370);

            const chartOptions = {
                ...barChartBaseOptions,
                x: this.getInches(options.spaceBetween + options.chartWidth),
                y: this.getInches(157),
                w: this.getInches(options.chartWidth),
                h: this.getInches(options.chartHeight),
                chartColors: ['9545D8'],
            };

            // @ts-ignore
            this.slide.addChart('bar', chartData, chartOptions);
        }

        return this.slide;
    }
}

export default CampaignPPTXRegionPage;
