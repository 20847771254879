import React from 'react';
import { IProjectStatus } from 'services/projects/types';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';
import MilestonesBlock from './Info';

interface IPropsMilestonesProject {
    isLoading: boolean;
    status: IProjectStatus;
    projectIsComplete: boolean;
}

// eslint-disable-next-line arrow-body-style
const MilestonesProject: React.FC<IPropsMilestonesProject> = ({
    isLoading, status, projectIsComplete,
}): JSX.Element => (
    <div className={styles.root_MilestonesProject}>
        {
            isLoading && <Skeleton height={40} width="100%" />
        }
        {
            !isLoading && <MilestonesBlock status={status} projectIsComplete={projectIsComplete} />
        }
    </div>
);

export default MilestonesProject;
