import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
// import { useTranslation } from 'react-i18next';
// import CustomCheckbox from '../../../../Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';
import { Button } from '@shared/index';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import RadioButton from '../../../PlatformsSelectButtons/RadioButton';
import { ComparisonParametersType, IPhraseParameters, LocationType } from '../../types';
// import MoreOrEqualIcon from '../../imgs/moreOrEqual.svg';
// import MoreOrEqualActiveIcon from '../../imgs/moreOrEqualActive.svg';
// import LessOrEqualIcon from '../../imgs/lessOrEqual.svg';
// import LessOrEqualActiveIcon from '../../imgs/lessOrEqualActive.svg';
// import UnequalIcon from '../../imgs/unequal.svg';
// import UnequalActiveIcon from '../../imgs/unequalActive.svg';
// import EqualIcon from '../../imgs/equal.svg';
// import EqualActiveIcon from '../../imgs/equalActive.svg';
// import SplitLineIcon from '../../imgs/splitLine.svg';
// import EnterButtonIcon from '../../imgs/enterButton.svg';
import styles from './styles.module.scss';

interface IProps {
    isView;
    top: number;
    left: number;
    isOpen: boolean;
    parameters: IPhraseParameters;
    onClose: () => void;
    onChangeParameters: (value: IPhraseParameters) => void;
}

// const locationTypeMap = Object.keys(LocationType)
//     .slice(Object.keys(LocationType).length / 2, Object.keys(LocationType).length)
//     .map((value, index) => ({ index, name: value, label: value }));

// const locationTypeMap = new Map(
//     Object.keys(LocationType)
//         .slice(Object.keys(LocationType).length / 2, Object.keys(LocationType).length)
//         .map((value, index) => [index, value]),
// );

const SearchPhraseParametersContextMenu: React.FC<IProps> = ({
    isView,
    top,
    left,
    isOpen,
    parameters,
    onClose,
    onChangeParameters,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);
    // const { t } = useTranslation();

    const [currentParameters, setParameters] = useState<IPhraseParameters | null>(parameters);
    const [occurrencesNumber, setOccurrencesNumber] = useState<number>(parameters?.numberOccurrences?.quantity ?? 0);

    const disabledSubmitBtn = useMemo<boolean>(() => (
        !currentParameters?.value
    ), [currentParameters]);
    const placeInSentenceActive = useMemo<boolean>(() => (
        currentParameters?.placeInSentence in LocationType
    ), [currentParameters]);
    const leftCorrection = useMemo<number>(() => (left - 300 < 0 ? 0 : left - 300), [left]);

    const handlerChangePhrase = (): void => {
        onChangeParameters({
            ...currentParameters,
            numberOccurrences: currentParameters?.numberOccurrences
                ? {
                    parameter: currentParameters?.numberOccurrences?.parameter,
                    quantity: occurrencesNumber,
                }
                : null,
        });
    };
    const handlerChangeTrack = (value: boolean): void => {
        setParameters({ ...currentParameters, isTrack: value });
    };
    const handlerChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setParameters({ ...currentParameters, value: event.currentTarget.value });
    };
    const handlerChangeConsiderDeclensions = (value: boolean): void => {
        setParameters({ ...currentParameters, considerDeclensions: value });
    };
    const handlerChangeNumberOccurrences = (value: boolean): void => {
        setParameters({
            ...currentParameters,
            numberOccurrences: value ? { parameter: ComparisonParametersType.equal, quantity: 0 } : null,
        });
    };
    // const handlerChangeNumberOccurrencesParameter = (value: ComparisonParametersType): void => {
    //     setParameters({
    //         ...currentParameters,
    //         numberOccurrences: { parameter: value, quantity: currentParameters.numberOccurrences.quantity },
    //     });
    // };
    // const handlerEnterOccurrencesNumber = (): void => {
    //     setParameters({
    //         ...currentParameters,
    //         numberOccurrences: { parameter: currentParameters.numberOccurrences.parameter, quantity: occurrencesNumber },
    //     });
    // };
    const handlerChangeOccurrencesNumber = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (Number.isInteger(Number(event.currentTarget.value))) {
            let value = Math.round(Number(event.currentTarget.value));
            value = value < 0 ? 0 : value;
            setOccurrencesNumber(value > 1000 ? 1000 : value);
        }
    };
    const handlerActivatePlaceInSentence = (value: boolean): void => {
        if (value) {
            setParameters(
                {
                    ...currentParameters,
                    placeInSentence: LocationType.start,
                },
            );
        } else {
            setParameters(
                {
                    ...currentParameters,
                    placeInSentence: null,
                },
            );
        }
    };
    const handlerChangePlaceInSentence = (value: LocationType): void => {
        if (value in LocationType) {
            setParameters(
                {
                    ...currentParameters,
                    placeInSentence: value,
                },
            );
        }
    };

    useEffect(() => {
        if (!currentParameters) {
            setParameters({ ...parameters });
        }
    }, [parameters, currentParameters]);

    useEffect(() => {
        if (!isOpen) {
            setParameters(null);
            setOccurrencesNumber(parameters?.numberOccurrences?.quantity ?? 0);
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <div
            className={styles.searchPhraseParametersContextMenu_root}
            style={{ top: (top + 100), left: leftCorrection }}
            ref={refModal}
        >
            <div className={styles.modalContextMenu}>
                <div className={styles.contentWrapper}>
                    <div className={styles.inputBox}>
                        <input
                            className={`${styles.input} ${styles.input_text}`}
                            readOnly={isView}
                            placeholder="Введите фразу"
                            value={currentParameters?.value ?? ''}
                            onChange={handlerChangeValue}
                        />
                    </div>
                    <div className={styles.parameterWrapper}>
                        <span className={styles.label}>Результаты  с этим словом</span>
                        <div className={`${styles.flexRow} ${styles.gap16}`}>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeTrack(true)}
                                value={0}
                                isActive={currentParameters?.isTrack ?? false}
                                className={styles.radioButton}
                            >
                                Отслеживать
                            </RadioButton>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeTrack(false)}
                                value={0}
                                isActive={!currentParameters?.isTrack ?? false}
                                className={styles.radioButton}
                            >
                                Исключить
                            </RadioButton>
                        </div>
                    </div>
                    <div className={styles.parameterWrapper}>
                        <span className={styles.label}>Словоформа (падежи, склонения и тд)</span>
                        <div className={`${styles.flexRow} ${styles.gap16}`}>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeConsiderDeclensions(false)}
                                value={0}
                                isActive={!currentParameters?.considerDeclensions ?? false}
                                className={styles.radioButton}
                            >
                                Не учитывать
                            </RadioButton>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeConsiderDeclensions(true)}
                                value={0}
                                isActive={currentParameters?.considerDeclensions ?? false}
                                className={styles.radioButton}
                            >
                                Учитывать все
                            </RadioButton>
                        </div>
                    </div>
                    <div className={styles.parameterWrapper}>
                        <span className={styles.label}>Количество вхождений</span>
                        <div className={`${styles.flexRow} ${styles.gap16}`}>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeNumberOccurrences(false)}
                                value={0}
                                isActive={Boolean(currentParameters?.numberOccurrences) === false}
                                className={styles.radioButton}
                            >
                                Не учитывать
                            </RadioButton>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                onClick={() => handlerChangeNumberOccurrences(true)}
                                value={0}
                                isActive={Boolean(currentParameters?.numberOccurrences)}
                                className={styles.radioButton}
                            >
                                Задать
                            </RadioButton>
                        </div>
                        {
                            currentParameters?.numberOccurrences && (
                                <div className={styles.numberOccurrences}>
                                    {/* { */}
                                    {/*    currentParameters?.numberOccurrences.parameter === ComparisonParametersType.moreOrEqual */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={MoreOrEqualActiveIcon} alt="MoreOrEqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeNumberOccurrencesParameter(ComparisonParametersType.moreOrEqual)} */}
                                    {/*            > */}
                                    {/*                <img src={MoreOrEqualIcon} alt="MoreOrEqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* { */}
                                    {/*    currentParameters?.numberOccurrences.parameter === ComparisonParametersType.lessOrEqual */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={LessOrEqualActiveIcon} alt="LessOrEqualActiveIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeNumberOccurrencesParameter(ComparisonParametersType.lessOrEqual)} */}
                                    {/*            > */}
                                    {/*                <img src={LessOrEqualIcon} alt="LessOrEqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* { */}
                                    {/*    currentParameters?.numberOccurrences.parameter === ComparisonParametersType.unequal */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={UnequalActiveIcon} alt="UnequalActiveIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeNumberOccurrencesParameter(ComparisonParametersType.unequal)} */}
                                    {/*            > */}
                                    {/*                <img src={UnequalIcon} alt="UnequalIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* { */}
                                    {/*    currentParameters?.numberOccurrences.parameter === ComparisonParametersType.equal */}
                                    {/*        ? ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*            > */}
                                    {/*                <img src={EqualActiveIcon} alt="EqualActiveIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/*        : ( */}
                                    {/*            <button */}
                                    {/*                type="button" */}
                                    {/*                onClick={() => handlerChangeNumberOccurrencesParameter(ComparisonParametersType.equal)} */}
                                    {/*            > */}
                                    {/*                <img src={EqualIcon} alt="EqualIcon" /> */}
                                    {/*            </button> */}
                                    {/*        ) */}
                                    {/* } */}
                                    {/* <img src={SplitLineIcon} alt="SplitLineIcon" /> */}
                                    <input
                                        className={`${styles.input} ${styles.input_text} ${styles.backgroundNone}`}
                                        readOnly={isView}
                                        placeholder="Введите число"
                                        value={occurrencesNumber}
                                        onChange={handlerChangeOccurrencesNumber}
                                    />
                                    {/* <button */}
                                    {/*    type="button" */}
                                    {/*    onClick={handlerEnterOccurrencesNumber} */}
                                    {/* > */}
                                    {/*    <img src={EnterButtonIcon} alt="EnterButtonIcon" /> */}
                                    {/* </button> */}
                                </div>
                            )
                        }
                        <span className={styles.label}>Место в строке</span>
                        <div className={`${styles.flexRow} ${styles.gap16}`}>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                value={0}
                                onClick={() => handlerActivatePlaceInSentence(false)}
                                isActive={!placeInSentenceActive}
                                className={styles.radioButton}
                            >
                                Не учитывать
                            </RadioButton>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                value={0}
                                onClick={() => handlerChangePlaceInSentence(LocationType.start)}
                                isActive={currentParameters?.placeInSentence === LocationType.start}
                                className={styles.radioButton}
                            >
                                В начале
                            </RadioButton>
                            <RadioButton
                                tagId={null}
                                disabled={isView}
                                value={0}
                                onClick={() => handlerChangePlaceInSentence(LocationType.end)}
                                isActive={currentParameters?.placeInSentence === LocationType.end}
                                className={styles.radioButton}
                            >
                                В конце
                            </RadioButton>
                        </div>
                        {/* { */}
                        {/*    (currentParameters?.placeInSentence in LocationType) && ( */}
                        {/*        <div className={`${styles.flexRow} ${styles.gap8}`}> */}
                        {/*            { */}
                        {/*                locationTypeMap.map((item) => ( */}
                        {/*                    <CustomCheckbox */}
                        {/*                        key={item[0]} */}
                        {/*                        id={`${item.index}`} */}
                        {/*                        isDisabled={isView} */}
                        {/*                        textClassName={styles.checkboxText} */}
                        {/*                        name={t(item.name)} */}
                        {/*                        checked={currentParameters.placeInSentence === item.index} */}
                        {/*                        onChecked={() => handlerChangePlaceInSentence(item.index)} */}
                        {/*                        isRetinaSize={false} */}
                        {/*                    /> */}
                        {/*                )) */}
                        {/*            } */}
                        {/*        </div> */}
                        {/*    ) */}
                        {/* } */}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
                    <Button
                        text="Ok"
                        type="default"
                        size="medium"
                        disabled={disabledSubmitBtn || isView}
                        onClick={handlerChangePhrase}
                    />
                    {/* <ButtonPrimary */}
                    {/*    text="Ok" */}
                    {/*    onClick={handlerChangePhrase} */}
                    {/*    style={{ width: 64, height: 48 }} */}
                    {/*    disabled={disabledSubmitBtn || isView} */}
                    {/* /> */}
                </div>
            </div>
        </div>
    );
};

export default SearchPhraseParametersContextMenu;
