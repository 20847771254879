import React, { useMemo, useRef } from 'react';
import useOnClickOutside from '../../../../../shared/hooks/useOnClickOutside';
import { PhraseWordLogicOperatorType } from '../../types';
import helpers from '../../helpers';

import styles from './styles.module.scss';

interface IProps {
    isView;
    top: number;
    left: number;
    isOpen: boolean;
    onlyOrderOperators: boolean;
    withoutOrderOperators: boolean;
    onSelectItem: (index: number) => void;
    onClose: () => void;
}

const LogicOperatorTypeMap = Object.keys(PhraseWordLogicOperatorType)
    .slice(Object.keys(PhraseWordLogicOperatorType).length / 2, Object.keys(PhraseWordLogicOperatorType).length)
    .map((value, index) => ({ index, name: value, label: value }));

const SearchLogicOperatorContextMenu: React.FC<IProps> = ({
    isView,
    top,
    left,
    isOpen,
    onlyOrderOperators,
    withoutOrderOperators,
    onSelectItem,
    onClose,
}): JSX.Element => {
    const refModal = useRef();
    useOnClickOutside(refModal, onClose);

    const list = useMemo<{ index: number, name: string, label: string }[]>(() => {
        if (withoutOrderOperators) {
            return LogicOperatorTypeMap.filter(({ index }) => index !== PhraseWordLogicOperatorType.order);
        }
        if (onlyOrderOperators) {
            return LogicOperatorTypeMap.filter(({ index }) => index === PhraseWordLogicOperatorType.order);
        }
        return [...LogicOperatorTypeMap];
    }, [onlyOrderOperators, withoutOrderOperators]);

    const handlerSelectItem = (idx: number): void => {
        if (onSelectItem) {
            onSelectItem(idx);
        }
    };

    if (!isOpen || isView) {
        return null;
    }

    return (
        <div className={styles.searchLogicOperatorContextMenu_root} style={{ top, left }} ref={refModal}>
            <div className={styles.modalContextMenu}>
                <div className={styles.list_container}>
                    {
                        list.map((item) => (
                            <button
                                type="button"
                                key={item.index}
                                className={`${styles.list_item} ${styles.list_text}`}
                                onClick={() => handlerSelectItem(item.index)}
                            >
                                {
                                    helpers.getNameForLogicOperator(item.index)
                                }
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchLogicOperatorContextMenu;
