/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Tooltip } from '@shared/index';
import { getRandomNumber } from '../../../utils/commons/commonUtils';
import { IDetailInfo } from '../types';
import styles from './styles.module.scss';
import { getAbbreviation } from '../../../utils/getAbbreviation';

interface IPropsInformationBlockSource {
    data: IDetailInfo[],
}

const DetailInfo: React.FC<IPropsInformationBlockSource> = ({
    data,
}): JSX.Element => {
    const getKay = () => getRandomNumber(new Date().getTime(), new Date().getTime() + new Date().getTime());
    const getStylesOffset = (item: IDetailInfo) => `${styles.offset} ${item?.offset > 0 ? styles.plus : styles.minus}`;
    const getLabel = (str, isAbb = true) => {
        if (!str) return '-';
        if (str === '0') return '-';
        const strToNumber = +str;
        if (Number.isNaN(strToNumber)) return '-';
        if (strToNumber > 0) return isAbb ? `${getAbbreviation(strToNumber)}` : `${strToNumber.toLocaleString('RU-ru')}`;
        return strToNumber.toLocaleString('RU-ru');
    };
    return (
        <div className={styles.root_DetailInfo}>
            {data?.map((item) => (
                <div className={styles.container} key={getKay()}>
                    <Tooltip content={<span>{getLabel(item?.data, false)}</span>}>
                        <div className={styles.info}>
                            <div>{getLabel(item?.data)}</div>
                            {!!item.offset && <div className={getStylesOffset(item)}>{item?.offset > 0 ? item?.offset : `${item?.offset}`}</div>}
                        </div>
                    </Tooltip>

                    <div className={styles.label}>
                        {item?.label}
                    </div>
                </div>
            ))}
        </div>
    );
};
export default DetailInfo;
