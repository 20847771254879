import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnalyzeQuotationsState } from './types';
import { convertApiToAnalyzeQuotations } from './converter';

const initialState: IAnalyzeQuotationsState[] = [];

const { actions: analyzeQuotationsActions, reducer } = createSlice({
    name: 'analyzeQuotations',
    initialState,
    reducers: {
        setQuotations: (state: IAnalyzeQuotationsState[], { payload }: PayloadAction<unknown>) => {
            const buffer = convertApiToAnalyzeQuotations(payload);
            return buffer;
        },
    },
});

export { analyzeQuotationsActions };
export default reducer;
