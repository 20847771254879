import React, { useRef } from 'react';
import NoSourceIco from '@assets/images/sources/noSource.svg';

interface IProps {
    logoUrl: string;
    height?: number;
    width?: number;
}

const LogoImageInList: React.FC<IProps> = ({ logoUrl, height = 64, width = 64 }): JSX.Element => {
    const imgRef = useRef(null);
    const handlerChangeToDefaultImg = (): void => {
        if (imgRef?.current?.src) {
            imgRef.current.src = NoSourceIco;
        }
    };
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height,
            width,
        }}
        >
            <img
                ref={imgRef}
                style={{ maxHeight: '100%', maxWidth: '100%' }}
                src={logoUrl || NoSourceIco}
                alt="author"
                onError={handlerChangeToDefaultImg}
            />
        </div>
    );
};

export default LogoImageInList;
