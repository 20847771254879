import { combineReducers } from 'redux';
import sourceListReducer from '@store/source/list';
import advancedSearchReducer from '@store/source/advanced-search';

const sourceReducers = () => combineReducers({
    sourceList: sourceListReducer,
    advancedSearch: advancedSearchReducer,
});

export default sourceReducers;
