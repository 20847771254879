import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { saveUserNameThunk } from '@store/user-profile/thunks/save-user-name';
import { selectUserProfileFetchData, selectUserProfileFetchStatus } from '@store/user-profile/selectors';
import { useAppDispatch } from '@store/hooks';
import UserInfo from '@components/UserProfile/UserInfo';
import { FetchStatus } from '@store/types';
import { usersRtkQService } from '@services/common/users';

const UserInfoContainer: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const {
        lastName, firstName, middleName, email, userId,
    } = useSelector(selectUserProfileFetchData);
    const status = useSelector(selectUserProfileFetchStatus);

    const [getUserInfo, { isLoading }] = usersRtkQService.useLazyGetUserInfoQuery();

    const [firstNameState, SetFirstNameState] = useState<string>(lastName);
    const [userName, setUserName] = useState<string>(lastName);

    const handlerSaveUserInfo = (): void => {
        dispatch(saveUserNameThunk({
            userId,
            email: '',
            firstName: '',
            middleName: '',
            lastName: userName.trim(),
            owner: false,
        }));
        SetFirstNameState('');
    };

    useEffect(() => {
        if (!isLoading && !middleName && !firstName && !lastName) {
            getUserInfo();
        }
    }, [lastName, firstName, middleName]);

    useEffect(() => {
        const name = `${lastName ?? ''} ${firstName ?? ''} ${middleName ?? ''}`.trim();
        setUserName(name);
        if (!firstNameState) {
            SetFirstNameState(name);
        }
    }, [lastName, firstName, middleName]);

    return (
        <UserInfo
            name={userName}
            nameIsChanged={firstNameState !== userName}
            isLoading={status === FetchStatus.loading}
            email={email?.length > 0 ? email : '-'}
            onChangeName={setUserName}
            emailIsChanged={false}
            onSave={handlerSaveUserInfo}
        />
    );
};

export default UserInfoContainer;
