export class EventBus {
    subscribers = [];
    on(eventName, callback) {
        const isPresent = this.subscribers.find(item => item.callback === callback && item.eventName === eventName);
        if(isPresent) return;
        this.subscribers.push({
            eventName,
            callback,
        })
    }
    off(eventName, callback) {
        const index = this.subscribers.find(item => item.callback === callback && item.eventName === eventName);
        if(index < 0 ) return
        if(!this.subscribers.length) return
        this.subscribers.splice(index, 1)
    }
    emit(eventName, listArgs) {
        const listCalls =  this.subscribers.filter(item => item.eventName === eventName);
        if(!listCalls.length) return
        listCalls.forEach((subscriber) => {subscriber.callback(...listArgs)})
    }
}