/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { IPost } from '@sections/MyCampaignContainer/CampaingTypes';
import useGetDate from '@shared/hooks/useFormatDate';
import SvgIcon from '@components/SvgIcon';
import TextHeightlighting from '@components/TextHeightlighting';
import ModalCreateManualTag from '@components/ModalCreateManualTag';
import { LogoIconsIds } from '@app/constants/icons';
import authorIcon from './author.svg';
import CalendarIcon from './CalendarIcon.svg';
import likeIcon from './likeIcon.svg';
import repostsIcon from './repostsIcon.svg';
import styles from './styles.module.scss';
import viewsIcon from './viewsIcon.svg';
import TrashIcon from './trash.svg';
import IconLink from './linkIcon.svg';
import IconArrowLeft from './arrowLeft.svg';
import IconRecover from './recoverIcon.svg';

const platformDescriptions = {
    dzen: {
        label: 'Дзен',
        color: '#ffcc01',
        icon: <SvgIcon id={LogoIconsIds.ZenLogo} size={24} />,
    },
    rutube: {
        label: 'Rotube',
        color: '#ffcc01',
        icon: <SvgIcon id={LogoIconsIds.RuTubeLogo} size={24} />,
    },
    youtube: {
        label: 'Youtube',
        color: '#ffcc01',
        icon: <SvgIcon id={LogoIconsIds.YouTubeLogo} size={24} />,
    },
    tg: {
        icon: <SvgIcon id={LogoIconsIds.TgLogo} size={24} />,
        label: 'Telegram',
        color: '#129FD4',
    },
    vk: {
        icon: <SvgIcon id={LogoIconsIds.VkLogo} size={24} />,
        label: 'Вконтакте',
        color: '#0077FF',
    },
    ok: {
        icon: <SvgIcon id={LogoIconsIds.OkLogo} size={24} />,
        label: 'Одноклассники',
        color: '#E67E22',
    },
    rss: {
        icon: <SvgIcon id={LogoIconsIds.RssLogo} size={24} />,
        label: 'RSS',
        color: '#0EBFA1',
    },
    vk_video: {
        icon: <SvgIcon id={LogoIconsIds.VkVideo} size={24} />,
        label: 'VkVideo',
        color: '#0077FF',
    },
};
const tonalityDescription = {
    negative: {
        color: '#F36869',
        name: 'Негативно',
    },
    positive: {
        color: '#0EBFA1',
        name: 'Позитивно',
    },
    neutral: {
        color: '#E67E22',
        name: 'Нейтрально',
    },
    skip: {
        color: '#dfdfdf',
        name: 'Нет данных',
    },
};
interface IProps {
    post: IPost,
    openBloggerModal: (id: number, status: boolean) => void,
    openMoreModal: (post: IPost) => void,
    openModalChartDinamiyc: (date: any, id: any, post: any) => void,
    isVisibleDinamic?: boolean,
    isIPadSize: boolean,
    allowDelete?: boolean
    allowRestore?: boolean
    handleDeletePost?: (postId: number) => void
    handleRestorePost: (postId: number) => void
}
const CardPlot: React.FC<IProps> = ({
    post, openBloggerModal, openMoreModal, openModalChartDinamiyc, isVisibleDinamic = false, isIPadSize,
    allowDelete, allowRestore, handleDeletePost, handleRestorePost,
}): JSX.Element => {
    const [isOpenModalCreateManualTag, setIsOpenModalCreateManualTag] = useState(false);
    const [newTags, setNewTags] = useState<string[]>([]);
    useEffect(() => {
        if (!post || !post?.filters?.length) return;
        const listAllRaws = [...post.filters];
        const rawTags = listAllRaws.filter((str) => str.includes('user_tags:'));
        setNewTags([...newTags, ...rawTags].map((tag) => tag.replaceAll('user_tags:', '').replaceAll('_', ' ')));
    }, [post]);
    const handleCreateTags = (listTags, remove) => {
        setNewTags((prev) => [...listTags, ...prev]);
        if (remove?.length) {
            setNewTags((prev) => prev.filter((name) => {
                const has = remove.some((rem) => rem === name);
                return !has;
            }));
        }
    };
    const getTags = (list) => list.map((tag, index) => {
        const key = `${tag}_${index}_${post.post_id}_tag`;
        return (
            <div className={styles.cardTag} key={key}>
                {tag}
                {/* <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={CloseIcon} alt="cls" /> */}
            </div>
        );
    });
    // const getDate = (unix) => moment(new Date(unix)).format('DD.MM.YYYY HH:mm');
    const [posted] = useGetDate(new Date(post?.posted), 'DD.MM.YYYY HH:mm');
    const [updatedTime] = useGetDate(new Date(post?.last_update), 'HH:mm');
    const [updatedDate] = useGetDate(new Date(post?.last_update), 'DD.MM.YYYY');
    const [isDeleted, setDeleted] = useState<boolean>(false);

    const handleClickDelete = (): void => {
        handleDeletePost(post.id);
        setDeleted(true);
    };

    const handleClickRestore = (): void => {
        handleRestorePost(post.id);
        setDeleted(false);
    };

    if (isDeleted) {
        return (
            <div className={styles.deletedCardContainer}>
                <p>Пост удален.</p>
                <button type="button" onClick={handleClickRestore}>Восстановить</button>
            </div>
        );
    }

    return (
        <div className={styles.cardContainer}>
            <ModalCreateManualTag
                onCreateTags={handleCreateTags}
                listTags={newTags}
                postId={post?.id}
                campaignId={post?.campaign_id}
                isOpen={isOpenModalCreateManualTag}
                onClose={() => { setIsOpenModalCreateManualTag(false); }}
            />
            <div className={styles.removeButton}>
                {/* <img src={RemoveIcon} alt="" /> */}
            </div>
            <div className={styles.cardHeader} style={isIPadSize ? { flexWrap: 'wrap', alignItems: 'stretch' } : null}>
                <div className={styles.sourceType}>
                    {
                        platformDescriptions[post.source_type]?.icon
                        // && <img src={platformDescriptions[post.source_type]?.icon} alt="icn" />
                    }
                </div>
                <div className={styles.author}>
                    <img src={authorIcon} alt="icn" />
                    <div className={styles.authorName} onClick={() => openBloggerModal(post.author_id, true)}>
                        {post.source_name}
                    </div>
                </div>
                <div className={styles.postedDate}>
                    <img src={CalendarIcon} alt="icn" />
                    <div className={styles.postedDateValue}>{posted?.date}</div>
                    {/* <div className={styles.dateValue}>{getDate(post.posted)}</div> */}
                </div>
                <div className={styles.tonality}>
                    <div className={styles.tonalityLabel}>Тональность:</div>
                    <div className={styles.tonalityDescription}>
                        <div className={styles.tonalityIndicator} style={{ background: tonalityDescription[post.semantic].color }}> </div>
                        <div className={styles.tonalityStatus}>{tonalityDescription[post.semantic].name}</div>
                    </div>
                </div>
                <div className={styles.postLink} onClick={() => window.open(post.post_link, '_blank')}>
                    <img src={IconLink} alt="IconLink" />
                    <p>пост</p>
                </div>
                {allowDelete
                    && (
                        <button type="button" className={styles.deleteButton} onClick={handleClickDelete}>
                            <img src={TrashIcon} alt="Trash Icon" />
                        </button>
                    )}
                {allowRestore
                    && (
                        <button type="button" onClick={handleClickRestore} className={styles.restoreButton}>
                            <img src={IconRecover} alt="Icon Recover" />
                            Восстановить
                        </button>
                    )}
                <div
                    onClick={() => openModalChartDinamiyc(post?.posted, post.post_id, post)}
                    style={{
                        cursor: 'pointer', marginTop: '6px',
                    }}
                >
                    <SvgIcon id="ChartUp" size={24} />
                    <div
                        onClick={() => openModalChartDinamiyc(post?.posted, post.post_id, post)}
                        className={styles.platfromLabel}
                        style={{
                            marginLeft: '8px', color: '#E67E22', cursor: 'pointer', userSelect: 'none',
                        }}
                    >
                        {/* Динамика вовлеченности */}
                    </div>
                </div>
            </div>
            <div className={styles.cardContnet}>
                {
                    post.photo_links[0] ? (
                        <div
                            className={styles.cardPreview}
                            style={{ backgroundImage: `url(${post.photo_links[0]})` }}
                        >
                            {/* {
                                content.type === 'video'
                                    ? <SvgIcon id="Play_in_circle" size={57} /> : ''
                            } */}
                        </div>
                    ) : ''
                }
                <div className={styles.cardDescripton}>
                    <div className={styles.cardDescriptonTitle}>
                        {/* {post.title} */}
                        <TextHeightlighting text={post.title} keyWords={[]} colorHeightlight="#DFFCF7" maxHeight={65} />
                    </div>
                    <TextHeightlighting text={post?.content_preview || post?.content} keyWords={post.matched_words} colorHeightlight="#DFFCF7" maxHeight={65} />
                    {/* <div className={styles.cardDescriptonContent}>{description}</div> */}
                </div>
            </div>
            <div className={styles.cardInformation}>
                <div className={styles.toggleOpenModalMoreInformation}>
                    <span onClick={() => openMoreModal(post)}>Читать дальше</span>
                    <img src={IconArrowLeft} alt="" />
                </div>

                <div className={styles.cardUpdate}>
                    <span style={{ marginRight: '8px' }}>Обновленно:</span>
                    <span style={{ marginRight: '10px' }}>{updatedTime.date}</span>
                    <span style={{ marginRight: '10px' }}>{updatedDate.date}</span>
                </div>
            </div>
            <div className={styles.cardReactionsContainer} style={isIPadSize ? { flexWrap: 'wrap', alignItems: 'stretch' } : null}>
                <div className={styles.cardReactions}>
                    <div className={styles.iconsWithLabel}>
                        <img src={likeIcon} alt="icn" />
                        <div>{post.likes === null ? 'Нет данных' : post.likes}</div>
                    </div>
                    <div className={styles.iconsWithLabel}>
                        <img src={repostsIcon} alt="icn" />
                        <div>{post.reposts === null ? 'Нет данных' : post.reposts}</div>
                    </div>
                    <div className={styles.iconsWithLabel}>
                        <img src={viewsIcon} alt="icn" />
                        <div>{post.views === null ? 'Нет данных' : post.views}</div>
                    </div>
                </div>
            </div>
            <div className={styles.cardTags}>
                {getTags(newTags || [])}
                <div className={styles.addTag} onClick={() => setIsOpenModalCreateManualTag(true)}>+Добавить теги</div>
            </div>
        </div>
    );
};

export default CardPlot;
