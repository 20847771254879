/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import NoSourceIco from '@assets/images/sources/noSource.svg';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from '@shared/index';
import { Checkbox } from 'antd';
import { useNavigate } from 'react-router';
import Path from '@shared/lib/paths';
import styles from './styles.module.scss';

interface IPropsSourceCell {
    id?: number,
    logoUrl: string,
    name: string,
    isDisabledCheckboxes?: boolean,
    onChangeChecked: () => void
}
const SourceCell: React.FC<IPropsSourceCell> = ({
    id,
    logoUrl,
    name,
    isDisabledCheckboxes,
    onChangeChecked,
}): JSX.Element => {
    const navigate = useNavigate();
    const imgRef = useRef(null);
    const [isSkeleton, setIsSkeleton] = useState(() => true);
    const handlerChangeToDefaultImg = (): void => {
        if (imgRef?.current?.src) {
            imgRef.current.src = NoSourceIco;
            // setIsSkeleton(false)
        }
    };
    const handleClickOnSource = () => {
        navigate(`/${Path.Sources}/${id}`);
    };
    const checkbox = isDisabledCheckboxes ? null : (
        <div className={styles.sourceCell_checkbox}>
            <Checkbox
                onChange={onChangeChecked}
            />
        </div>
    );
    return (
        <div className={styles.sourceCell}>
            <div className={styles.sourceCell_index}>{id}</div>
            <div className={styles.sourceCell_img}>
                {checkbox}
                {isSkeleton && <div onClick={handleClickOnSource}><Skeleton width={64} height={64} /></div>}
                <img
                    onClick={handleClickOnSource}
                    style={{ width: 64, borderRadius: 6 }}
                    ref={imgRef}
                    src={logoUrl || NoSourceIco}
                    alt="author-logo"
                    onError={handlerChangeToDefaultImg}
                    onLoad={() => setIsSkeleton(false)}
                />
            </div>
            <Tooltip
                disabled={name?.length < 16}
                content={<div style={{ padding: 4 }}>{name}</div>}
            >
                <div onClick={handleClickOnSource} className={styles.sourceCell_name}>{name}</div>
            </Tooltip>
            <div className={styles.hiddenField} />
        </div>
    );
};

export default React.memo(SourceCell);
