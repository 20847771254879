/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { IUserFilter } from '../../../../services/campaigns/userFilters/types';
import SvgIcon from '../../../SvgIcon';
import styles from './styles.module.scss';

interface IPropsSort {
    userFilters: IUserFilter[],
    selectFilterId?: number,
    onSelectFilter?: (id: number) => void,
    openModalChange?: (id: number) => void,
    openModalInfo?: (id: number) => void,
    removeFilter?: (id: number) => void,
}

const FiltersWithSettings: React.FC<IPropsSort> = ({
    userFilters,
    selectFilterId,
    onSelectFilter,
    openModalChange,
    openModalInfo,
    removeFilter,
}): JSX.Element => {
    const handleClickOnMenu = (id: number) => {

    };
    const handleOnClickItem = (key: string, id: number) => {
        const list = {
            openModalChange,
            openModalInfo,
            removeFilter,
        };
        list[key](id);
    };

    const createFilter = (item: IUserFilter) => (
        <div className={styles.containerFilter}>
            <div className={`${styles.containerContent} ${selectFilterId === item.id && styles.select}`}>
                <div className={styles.side} style={{ width: '100%' }} onClick={() => onSelectFilter(item.id)}>
                    <div className={styles.name}>{item.title}</div>
                </div>
                <div
                    className={`${styles.side} ${styles.right}`}
                    onClick={() => handleClickOnMenu(item.id)}
                >
                    <Dropdown
                        placement="bottomLeft"
                        overlay={(
                            <Menu>
                                <Menu.Item
                                    key="openModalChange"
                                >
                                    <div
                                        style={{ width: 100 }}
                                        onClick={() => handleOnClickItem('openModalChange', item.id)}
                                    >
                                        Просмотреть
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    key="openModalInfo"
                                >
                                    <div
                                        style={{ width: 100 }}
                                        onClick={() => handleOnClickItem('openModalInfo', item.id)}
                                    >
                                        Редактировать
                                    </div>
                                </Menu.Item>
                                <Menu.Item
                                    key="removeFilter"
                                >
                                    <div
                                        style={{ width: 100 }}
                                        onClick={() => handleOnClickItem('removeFilter', item.id)}
                                    >
                                        Удалить
                                    </div>
                                </Menu.Item>
                            </Menu>
                        )}
                        trigger={['click']}
                    >
                        <div>
                            <SvgIcon id="ThreeDot" size={19} />
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
    return (

        <div className={styles.root_ListFilters}>
            {
                userFilters?.length > 0 ? userFilters.map(createFilter)
                    : <div className={styles.emptyFilters}>пусто</div>
            }
        </div>
    );
};
export default React.memo(FiltersWithSettings);
