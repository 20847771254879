import React, { useEffect, useState } from 'react';
import Table from '@entities/Table';
import { ITableColumn } from '@entities/Table/types';
import { campaignAuthorsRtkQService } from '@services/campaigns/authors';
import { DateRangePickerValue } from '@components/DateRangePicker/types';
import {
    IAuthorModel,
    IQueryParamsLoadAuthors,
    IQuerySortFieldsLoadAuthors,
} from '@services/campaigns/authors/types';
import { SortOrder } from '@services/sources/types';
import styles from './styles.module.scss';

interface IProps {
    period: DateRangePickerValue;
    campaignid: number;
}

const limit = 100;

const CampaignAuthorsTable: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { period, campaignid } = props;
    const [
        getAuthors,
        {
            data: authors,
            isFetching: isAuthorsFetching,
            isSuccess: isAuthorsSuccess,
            isError: isAuthorsError,
        },
    ] = campaignAuthorsRtkQService.useLazyPostLoadQuery();
    const [authorsList, setAuthorsList] = useState<IAuthorModel[]>([]);
    const [acceptIntersection, setAcceptIntersection] = useState<boolean>(true);
    const [params, setParams] = useState<IQueryParamsLoadAuthors>({
        campaign_id: campaignid,
        page: 1,
        from_date: period[0].format('YYYY-MM-DD'),
        to_date: period[1].format('YYYY-MM-DD'),
        search_query: '',
    });

    useEffect(() => {
        getAuthors(params).finally(() => setAcceptIntersection(true));
    }, [params]);

    const onSort = (
        column: ITableColumn<IAuthorModel>,
        order: SortOrder,
    ): void => {
        if (params.order !== order || params.column !== IQuerySortFieldsLoadAuthors[column.dataIndex]) {
            setAcceptIntersection(false);
            setParams((prev) => ({
                ...prev,
                order,
                column: IQuerySortFieldsLoadAuthors[column.dataIndex],
                page: 1,
            }));
            setAuthorsList([]);
        }
    };

    useEffect(() => {
        if (period[0].format('YYYY-MM-DD') !== params.from_date || period[1].format('YYYY-MM-DD') !== params.to_date) {
            setAcceptIntersection(false);
            setParams((prev) => ({
                ...prev,
                from_date: period[0].format('YYYY-MM-DD'),
                to_date: period[1].format('YYYY-MM-DD'),
                page: 1,
            }));
            setAuthorsList([]);
        }
    }, [period]);

    useEffect(() => {
        if (authors && authors.length >= 1) {
            setAuthorsList((prev) => prev.concat(authors));
        }
    }, [authors]);

    const columns: ITableColumn<IAuthorModel>[] = [
        {
            id: 1,
            title: null,
            dataIndex: 'id',
            width: 70,
            isIndex: true,
        },
        {
            id: 2,
            title: 'Автор',
            dataIndex: 'authorName',
            searchable: true,
            maxLengthForTooltipe: 20,
        },
        {
            id: 3,
            title: 'Сообщения',
            dataIndex: 'postsCount',
            sortable: true,
        },
        {
            id: 4,
            title: '% ',
            dataIndex: 'percent',
            render: (value) => (
                <span>
                    {value}
                    {' '}
                    %
                </span>
            ),
            sortable: true,
        },
        {
            id: 5,
            title: 'Лайки',
            dataIndex: 'likes',
            sortable: true,
        },
        {
            id: 6,
            title: 'Комментарии',
            dataIndex: 'comments',
            sortable: true,
        },
        {
            id: 7,
            title: 'Просмотры',
            dataIndex: 'views',
            sortable: true,
        },
        {
            id: 8,
            title: 'Вовлеченность',
            dataIndex: 'reactions',
            sortable: true,
        },
    ];

    const onScroll = (): void => {
        if (authorsList && authorsList.length <= limit * params.page && authors?.length >= limit && !isAuthorsFetching) {
            setParams((prev) => ({ ...prev, page: prev.page + 1 }));
            setAcceptIntersection(false);
        }
    };

    const onSearch = (
        _column: ITableColumn<IAuthorModel>,
        value: string,
    ): void => {
        setAcceptIntersection(false);
        setParams((prev) => ({
            ...prev,
            search_query: value,
            page: 1,
        }));
        setAuthorsList([]);
    };

    return (
        <>
            <Table
                columns={columns}
                rows={authorsList}
                isLoading={isAuthorsFetching}
                onSort={onSort}
                scrollCallbackEnable={acceptIntersection}
                onScroll={onScroll}
                onSearch={onSearch}
            />
            <div className={styles.table}>
                <div className={styles.body}>
                    {authorsList?.length < 1
                        && isAuthorsSuccess
                        && !isAuthorsFetching && (
                        <div className={styles.noData}>
                            <p>Нет данных</p>
                        </div>
                    )}
                    {isAuthorsError && (
                        <div className={styles.error}>
                            <p>Произошла ошибка при получении данных</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default CampaignAuthorsTable;
