/* eslint-disable class-methods-use-this */
import { ICampaignSummaryData } from '../../../../store/campaign/summary/types';
import { IPDFReportTopPostsAndViews } from '../../../../store/pdf-handler/wrapper/types';
import PptxSlide from '../../PptxSlide';
import { ICampaignPPTXSummaryDataPageProps } from './types';

class CampaignPPTXSummaryDataPage extends PptxSlide {
    render(props: ICampaignPPTXSummaryDataPageProps) {
        this.renderCoverage(props.amounts.quantityViews);

        this.renderTop(
            323,
            'по количеству сообщений',
            props.topAuthorsByPosts,
            'posts',
        );
        this.renderTop(
            557,
            'по охвату',
            props.topAuthorsByViews,
            'views',
            true,
        );

        this.renderTotalInfo(props.amounts);

        this.renderHeader({
            date: props.date,
            title: props.title,
            layoutHeight: props.layoutHeight,
            layoutWidth: props.layoutWidth,
            reportName: props.reportName,
            header: {
                pageName: props.header.pageName,
                number: props.header.number,
            },
        });
        return this.slide;
    }

    private renderCoverage(amountViews: number): void {
        const getTextValue = (value: number): string => {
            if (!value) {
                return '0';
            }
            const result = value > 1000000 ? Math.round(value / 100000) / 10 : value;
            return value > 1000000
                ? `${result.toLocaleString('ru-RU')} млн`
                : result.toLocaleString('ru-RU');
        };

        this.addShape('roundRect', {
            w: 216,
            h: 103,
            x: 42,
            y: 119,
            fill: { color: 'EDFFFC' },
            rectRadius: 6,
        });

        this.addText(getTextValue(amountViews), {
            x: 67,
            y: 144,
            h: 20,
            color: '262626',
            fontSize: 36,
            fontFace: 'Montserrat medium',
        });

        this.addText('охват', {
            x: 67,
            y: 187,
            h: 20,
            fontFace: 'Montserrat regular',
            fontSize: 14,
            color: '000000',
        });
    }

    private renderTop(
        startX: number,
        categoryText: string,
        data: IPDFReportTopPostsAndViews[],
        type: 'posts' | 'views',
        borderLeft?: boolean,
    ): void {
        this.addText('ТОП-5', {
            y: 124,
            x: startX + 20,
            h: 32,
            color: '262626',
            fontFace: 'Montserrat SemiBold',
            fontSize: 24,
        });
        this.addText('авторов', {
            x: startX + 20,
            y: 160,
            fontFace: 'Montserrat Medium',
            color: '787E93',
            fontSize: 12,
            h: 13,
        });
        this.addText(categoryText, {
            x: startX + 20,
            y: 175,
            fontFace: 'Montserrat Medium',
            color: '787E93',
            fontSize: 12,
            h: 13,
        });

        this.addShape('rect', {
            w: 165,
            h: 156,
            x: startX,
            fill: { color: 'EDFFFC', transparency: 70 },
            y: 205,
        });

        if (!data) return;

        data.forEach((item, index) => {
            const y = index >= 1 ? 213 + 31 * index : 213;
            const value = type === 'posts' ? item.amountPosts : item.amountViews;
            this.addText(this.cutAuthorName(item.authorName), {
                y,
                x: startX + 20,
                h: 13,
                fontFace: 'Montserrat Medium',
                color: '000000',
                fontSize: 12,
            });
            this.addText(value.toLocaleString('ru-Ru') ?? '0', {
                x: startX + 165,
                y,
                h: 13,
                color: '787E93',
                fontFace: 'Montserrat SemiBold',
                fontSize: 9,
                w: 75,
                align: 'center',
            });

            const spaceBetweenLines = 31;
            const lineY = index >= 1 ? 205 + spaceBetweenLines * index : 205;
            const lineOptions = {
                x: startX,
                w: 232,
                y: lineY,
                h: 1,
                fill: { color: 'EDFFFC' },
            };
            this.addShape('rect', lineOptions);

            if (index === data.length - 1) {
                this.addShape('rect', {
                    ...lineOptions,
                    y: lineY + spaceBetweenLines,
                });
            }
        });

        if (borderLeft) {
            this.addShape('rect', {
                x: startX,
                y: 113,
                rotate: 0,
                w: 1,
                h: 248,
                fill: { color: 'EDFFFC' },
            });
        }
    }

    private renderTotalInfo(amounts: ICampaignSummaryData) {
        this.addText('ВСЕГО', {
            h: 32,
            x: 61,
            y: 348,
            fontFace: 'Montserrat SemiBold',
            fontSize: 24,
            color: '787E93',
        });

        const startY = 414;
        const startX = 61;
        const spaceInsideBlock = 16;
        const spaceBetweenBlock = 44;

        const textValueOptions = {
            h: 38,
            fontFace: 'Montserrat Regular',
            fontSize: 36,
            color: '262626',
            w: 180,
        };
        const textLabelOptions = {
            fontFace: 'Montserrat Regular',
            h: 20,
            fontSize: 14,
            color: '262626',
            w: 150,
        };

        const quantityPost = this.formatValue(amounts?.quantityPost);
        const quantityPostWidth = this.getTitleWidth(quantityPost);
        this.addText(quantityPost, {
            ...textValueOptions,
            y: startY,
            x: startX,
            w: quantityPostWidth,
        });

        this.addText('публикаций', {
            ...textLabelOptions,
            y: startY + 12,
            x: startX + quantityPostWidth,
        });

        const quantityAuthors = this.formatValue(amounts?.quantityAuthors);
        const quantityAuthorsWidth = this.getTitleWidth(quantityAuthors);
        this.addText(quantityAuthors, {
            ...textValueOptions,
            y: startY,
            x: startX + textValueOptions.w + textLabelOptions.w + spaceInsideBlock + spaceBetweenBlock,
            w: quantityAuthorsWidth,
        });

        this.addText('авторов', {
            ...textLabelOptions,
            y: startY + 12,
            x: startX + textValueOptions.w + quantityAuthorsWidth + textLabelOptions.w + spaceInsideBlock + spaceBetweenBlock,
        });

        const quantityBloggerPost = this.formatValue(amounts.quantityBloggerPost);
        const quantityBloggerPostWidth = this.getTitleWidth(quantityBloggerPost);
        this.addText(quantityBloggerPost, {
            ...textValueOptions,
            y: startY + 100,
            x: startX,
            w: quantityBloggerPostWidth,
        });

        this.addText('публикаций среди блогеров', {
            ...textLabelOptions,
            y: startY + 100 + 6,
            x: startX + quantityBloggerPostWidth,
        });

        const quantityReactions = this.formatValue(amounts?.quantityReactions);
        const quantityReactionsWidth = this.getTitleWidth(quantityReactions);
        this.addText(quantityReactions, {
            ...textValueOptions,
            y: startY + 100,
            x: startX + textValueOptions.w + textLabelOptions.w + spaceInsideBlock + spaceBetweenBlock,
            w: quantityReactionsWidth,
        });

        this.addText('реакций', {
            ...textLabelOptions,
            y: startY + 100 + 12,
            x: startX + textValueOptions.w + quantityReactionsWidth + textLabelOptions.w + spaceInsideBlock + spaceBetweenBlock,
        });
    }

    private getTitleWidth(value: string): number {
        const letterWidth = value.length > 4 ? 25 : 30;
        return value.length * letterWidth;
    }

    private formatValue(value: string | number | null): string {
        if (!value) return '0';
        return value.toLocaleString('ru-RU');
    }

    private cutAuthorName(value: string): string {
        if (!value) {
            return '-';
        }

        return value.length > 17 ? `${value.substring(0, 17)}...` : value;
    }
}

export default CampaignPPTXSummaryDataPage;
