import React from 'react';
import styles from './styles.module.scss';

interface IPropsErCell {
    value: number;
}
const ErCell: React.FC<IPropsErCell> = ({ value }): JSX.Element => (
    <div className={styles.erCell}>
        <div className={styles.erCell_label}>{value ? `${value.toFixed(2)}%` : ''}</div>
    </div>
);

export default React.memo(ErCell);
