import { AppState } from 'store/store';

const modalState = (state: AppState) => state.common.messageModal;
const message = (state: AppState) => modalState(state).message;
const confirmButtonText = (state: AppState) => modalState(state).confirmButtonText;
const confirmButtonTagId = (state: AppState) => modalState(state).confirmButtonTagId;

export default {
    message,
    confirmButtonText,
    confirmButtonTagId,
};
