import React from 'react';
import { CheckBoxList } from '@shared/index';
import { ICheckItem } from '@shared/ui-components/CheckBoxList/types';

interface IProps {
    items: ICheckItem[];
    onSelect: (id: number, isChecked: boolean) => void;
}

const SimpleFilterForTable: React.FC<IProps> = ({ items, onSelect }): JSX.Element => {
    const handleCheckItem = (id: number, isChecked: boolean) => {
        onSelect(id, isChecked);
    };
    return (
        <div>
            <CheckBoxList
                onCheck={handleCheckItem}
                items={items}
                haveCheckAll
            />
        </div>
    );
};

export default SimpleFilterForTable;
