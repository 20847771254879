import { useEffect, useRef } from 'react';

export default function useDebouncedCallback<T extends any[]>(
    callback: (...args: T) => void,
    delay: number,
) {
    // track args & timeout handle between calls
    const argsRef = useRef<T>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }

    useEffect(() => cleanup, []);

    return function debouncedCallback(
        ...args: T
    ) {
        argsRef.current = args;
        cleanup();

        timeout.current = setTimeout(() => {
            if (argsRef.current) {
                callback(...argsRef.current);
            }
        }, delay);
    };
}
