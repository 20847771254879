/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import { IChart } from 'containers/CampaignNewCharts/ChartTypes';
import { ICategory } from 'sections/MyCampaignContainer/CampaingTypes';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { ChartType, ChartMetricsModel, Dimension } from 'services/campaigns/Charts/models';
import { ISimpleEntity } from 'store/types';
import MyCompaignFilters from '../CampaingCategories';

interface IPropsChartMetrics {
    onUpdateChartDimensions: (dimensions: Dimension[]) => void,
    listSettingForChart: ChartMetricsModel,
    isLoading: boolean,
    chartSetting: IChart,
}

const ChartDimensions: React.FC<IPropsChartMetrics> = ({
    onUpdateChartDimensions,
    listSettingForChart,
    isLoading,
    chartSetting,
}): JSX.Element => {
    if (isLoading) return <div> Loading</div>;
    if (!listSettingForChart || !listSettingForChart?.length) return <div>Данных нет</div>;

    const chartDimensions = useMemo((): Dimension[] => {
        const listRawMetrics = listSettingForChart.find((item) => item?.dimensions_entity);
        return listRawMetrics.dimensions_entity;
    }, [listSettingForChart]);

    const getDimensionsCategory = useMemo((): ICategory => {
        const listFilters = chartDimensions.map((dimension: Dimension) => ({
            filter_name: dimension,
            count: 0,
            include: !!chartSetting?.dimensions?.find((chartDimenision: Dimension) => chartDimenision === dimension),
        }));
        const acceptedFilters = listFilters.filter((item) => item.filter_name !== chartSetting.metrics);
        const newCtegory = {
            filters_category: 'Разделить по',
            total_filters_count: 0,
            disableCounter: true,
            filters: acceptedFilters,
        };
        return newCtegory;
    }, [chartSetting]);
    const updateListSelectedDimensions = (categoryName: string, filterName: string, type?: string) => {
        const check = chartSetting?.dimensions?.find((dimension: Dimension) => dimension === filterName);
        const isAcceptedDisabled = getDimensionsCategory.filters.reduce((a, i) => a + +i.include, 0) > 1;
        if (categoryName === 'Разделить по' && !isAcceptedDisabled && check) return;
        if (check) {
            onUpdateChartDimensions(chartSetting?.dimensions?.filter((dimension: Dimension) => t(dimension) !== t(filterName)));
            return;
        }

        onUpdateChartDimensions([...chartSetting.dimensions, filterName] as Dimension[]);
    };
    return (
        <MyCompaignFilters
            updateCategories={updateListSelectedDimensions}
            category={getDimensionsCategory}
            isImportant
            seporatorLine
            isExclude={false}
            changeTypeFiltersApply={() => null}
        />
    );
};

export default React.memo(ChartDimensions);
