/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import styled from 'styled-components';
import {
    ChartContainer,
} from '../../style';
import MapOfRussiaDemo from './MapOfRussia';
import ChartGraphWitTooltipDemo from './ChartGraphWitTooltipDemo';
import BigBubbleDemo from './BigBubbleDemo';
import TagCloudDemo from './TagCloudDemo';
import DoughnutChartDemo from './DoughnutChartDemo';
import SortableDemo from './SortableDemo';

export const Height = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #DFFCF7;
    border-radius: 6px;
`;
export const Low = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
    background: #FEF8E8;
    border-radius: 6px;
`;

const PublicationChartBubble = function PublicationChartBubble() {
    return (
        <ChartContainer>
            <ChartGraphWitTooltipDemo />
            <SortableDemo />
            <BigBubbleDemo />
            <TagCloudDemo />
            <DoughnutChartDemo />
            <MapOfRussiaDemo />
        </ChartContainer>
    );
};
export default React.memo(PublicationChartBubble);
