/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { t } from 'i18next';
import styles from './styles.module.scss';
import { IApplyFilter, ICategory, ListCategory } from '../../sections/MyCampaignContainer/CampaingTypes';
import MyCompaignFilters from '../CampaingCategories';
import DatePicker from '../DatePicker';
import { DateRangePickerValue } from '../DateRangePicker/types';
import ChartMetrics from '../ChartMetrics';
import { chartsCampaingRtqService } from '../../services/campaigns/Charts';
import SingleSelectItem from '../common/SingleSelectItem';
import ColumnRadioButtons from '../ColumnRadioButtons';
import { ISimpleEntity } from '../../store/types';
import { IQueryParamsGetPointsForChart } from '../../services/campaigns/Charts/types';
import { Metric } from '../../services/campaigns/Charts/models';
import { ChartType, TimeFrame } from '../../services/campaigns/new-charts/models';
import { IChart } from '../../containers/CampaignNewCharts/ChartTypes';
import { trackingCampaingRtqService } from '../../services/campaigns/tracking';

const DEMENSION_LABEL = 'Разделить по';

const axis = [
    {
        name: 'Y',
        value: 'Y',
        id: 1,
    },
    {
        name: 'X',
        value: 'X',
        id: 12,
    },
];
interface IFiltersProps {
    onChangeFiltersInclude: (filtersInclude: IApplyFilter) => void,
    chartSetting: IChart,
    campaingId: number,
    isIPadSize: boolean,
    children?: React.ReactNode;
}
const NewRightBar: React.FC<IFiltersProps> = ({
    onChangeFiltersInclude,
    chartSetting,
    campaingId,
    isIPadSize,
    children,
}): JSX.Element => {
    const [getFilters] = trackingCampaingRtqService.useLazyGetFiltersQuery();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const updateCategories = (filters_category: string, filter_name: string) => {
        onChangeFiltersInclude({
            filter_name,
            filters_category,
        });
    };
    const categoriesWithChecked = useMemo((): ICategory[] => {
        if (!categories || !chartSetting) return [];
        const categoryWithSelectedFilters = categories?.map((category: ICategory) => {
            const newCategory: ICategory = { ...category };
            newCategory.filters = category?.filters?.map((filter) => {
                const isChecked = chartSetting?.filters_include?.find((include: IApplyFilter) => {
                    const check = include?.filters_category === category?.filters_category && filter.filter_name === include.filter_name;
                    return check;
                });
                if (isChecked) {
                    return { ...filter, include: true };
                }
                return filter;
            });
            return newCategory;
        });
        return categoryWithSelectedFilters;
    }, [categories, chartSetting]);
    // mounted, chartSettings
    useEffect(() => {
        if (chartSetting && !categories.length) {
            getFilters({
                campaign_id: campaingId,
                filters_include: chartSetting?.filters_include,
            }).then((res) => {
                // Фильтры больше не имеют поля Checked - теперь там include/exclude
                if (res?.data && Array.isArray(res?.data)) { setCategories(res?.data); }
            });
        }
    }, [chartSetting]);
    return (
        <div className={styles.rootNewRightBar}>
            {
                children
            }
            {
                categoriesWithChecked?.map((category, index) => (
                    <MyCompaignFilters
                        key={`${category?.filters_category}_${category?.total_filters_count}`}
                        updateCategories={updateCategories}
                        category={category}
                        seporatorLine={index < (categories.length - 1)}
                        isExclude={false}
                        changeTypeFiltersApply={() => null}
                    />
                ))
            }

        </div>
    );
};

export default React.memo(NewRightBar);
