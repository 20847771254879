/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IconButton } from '@shared/index';
import styles from './styles.module.scss';

interface IProps {
    label: string;
    iconId: string;
    iconSize: number;
    onClick: () => void;
    labelStyle?: React.CSSProperties;
}

const IconButtonWithLabel: React.FC<IProps> = ({
    label, onClick, iconId, iconSize, labelStyle,
}): JSX.Element => (
    <div className={styles.iconButtonWithLabel_root}>
        <div className={styles.iconButtonWithLabel_label} onClick={onClick} style={labelStyle}>{label ?? '---'}</div>
        <IconButton iconSize={iconSize} iconId={iconId} onClick={onClick} />
    </div>
);

export default IconButtonWithLabel;
