import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const style = {
    cursor: 'pointer',
};

interface IProps {
    id: number;
    currentDragCardId: number;
    text: string;
    onMoveCard: (toContainerIndex: number, fromContainerIndex: number, dragIndex: number, hoverIndex: number) => void;
    onFindCard: (id: number) => { containerId: number, cardIndex: number };
    onSetDragCard: (id: number) => void;
    component: JSX.Element;
}
const CampaignDnDCard: React.FC<IProps> = ({
    id,
    currentDragCardId,
    text,
    onMoveCard,
    onFindCard,
    onSetDragCard,
    component,
}): JSX.Element => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'card',
            item: () => ({ id }),
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            end: (item) => {
                if (currentDragCardId === item?.id) {
                    onSetDragCard(-1);
                }
            },
        }),
        [id, onMoveCard, currentDragCardId, onSetDragCard],
    );
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const cardHover = onFindCard(id);
            const cardDrag = onFindCard(item.id);
            if (item.id === id || !(cardDrag?.cardIndex >= 0) || !(cardHover?.cardIndex >= 0)) {
                return;
            }
            if (cardDrag.cardIndex === cardHover.cardIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (cardDrag.cardIndex < cardHover.cardIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (cardDrag.cardIndex > cardHover.cardIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            onMoveCard(cardHover.containerId, cardDrag.containerId, item.id, cardHover.cardIndex);
        },
    }, [onMoveCard, ref, onFindCard]);

    drag(drop(ref));

    useEffect(() => {
        if (isDragging) {
            onSetDragCard(id);
        }
    }, [isDragging, onSetDragCard]);

    return (
        <div ref={ref} style={{ position: 'relative', ...style }} data-handler-id={handlerId}>
            {
                (isDragging || currentDragCardId === id)
                    ? (<div style={{ height: 2, background: '#1890ff' }} />)
                    : (component || text)
            }
        </div>
    );
};

export default CampaignDnDCard;
