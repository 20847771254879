import React from 'react';
import { Rose } from '@ant-design/plots';

const RoseChart: React.FC<any> = ({ data }) => {
    const config = {
        data,
        xField: 'name',
        yField: 'value',
        seriesField: 'name',
        label: false,
        radius: 0.9,
        legend: {
            position: 'bottom',
        },
    };

    return <Rose {...config as any} />;
};

export default RoseChart;
