import React from 'react';
import ContentContainer from '../../deprecated/pagesComponents/filters/ContentContainer';
import styles from './styles.module.scss';

const NotFoundPage: React.FC = (): JSX.Element => (
    <ContentContainer>
        <div className={styles.root}>
            <h1 className={styles.numberError}>404</h1>
            <div className={styles.textErrorContainer}>
                <h2 className={styles.textError}>Эта страница не может быть найдена.</h2>
            </div>
        </div>
    </ContentContainer>
);

export default NotFoundPage;
