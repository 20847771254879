import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ITrackedWordModel } from 'components/campaign/UpdateForm/TrackedWords/types';
import { useParams } from 'react-router-dom';
import { categoriesRtkQService } from '../../../../../services/categories';
import { IFavoriteSourceModel } from '../../../../../services/campaigns/favorite-sources/types';
import favoriteSourcesSelectors from '../../../../../store/campaign/favorite-sources/selectors';
import { ITrackedSourceModel } from '../../../../../store/campaign/tracked-sources/types';
import trackedSourcesSelectors from '../../../../../store/campaign/tracked-sources/selectors';
import { ICampaignDataModel } from '../../../../../services/campaigns/campaigns-list/types';
import { singleCampaignRtkQService } from '../../../../../services/campaigns/single-campaign';
import { FetchStatus } from '../../../../../store/types';
import CreateFromReferenceCampaign
    from '../../../../../components/campaign/Comparison/Modals/CreateFromReferenceCampaign';
import campaignSelectors from '../../../../../store/campaign/source/selectors';
import { selectCommonWindowIsIPadSize } from '../../../../../store/common/window/selectors';
import { useAppDispatch } from '../../../../../store/hooks';
import { campaignTrackedSourcesActions } from '../../../../../store/campaign/tracked-sources';
import campaignUtils from '../../../../../utils/campaign';
import { campaignActions } from '../../../../../store/campaign/source';
import { messageModalActions } from '../../../../../store/common/message-modal';

interface IComparisonFromReferenceCampaign {
    isLoading: boolean,
    currentModalWindow: string,
    setCurrentModalWindow: (name: string) => void,
    referenceCampaign: ICampaignDataModel,
}

const getCategoriesIds = (ids: number[]): number[] => {
    if (Array.isArray(ids) && ids.every((id) => id === 0)) {
        return [];
    }
    return ids;
};

const ComparisonFromReferenceCampaignContainer: React.FC<IComparisonFromReferenceCampaign> = ({
    isLoading,
    currentModalWindow,
    setCurrentModalWindow,
    referenceCampaign,
}): JSX.Element => {
    const { Id: urlParametersId } = useParams();

    const dispatch = useAppDispatch();
    const campaignCategoriesIds = useSelector(campaignSelectors.selectedCategories);
    const isIPadSize = useSelector(selectCommonWindowIsIPadSize);
    const trackedSources = useSelector(trackedSourcesSelectors.tracked);
    const excludedSources = useSelector(trackedSourcesSelectors.excluded);
    const favoriteSourcesData = useSelector(favoriteSourcesSelectors.favoriteSources);
    const campaignName = useSelector(campaignSelectors.currentCampaignName);
    const campaignId = useSelector(campaignSelectors.currentCampaignId);
    const createStatus = useSelector(campaignSelectors.createStatus);

    const [
        getCampaign,
        {
            data: campaignRtkQ,
            isLoading: isLoadingCampaignRtkQ,
        },
    ] = singleCampaignRtkQService.useLazyGetCampaignQuery();
    const [createCampaign] = singleCampaignRtkQService.usePostCreateCampaignMutation();
    const [
        getCategories,
        { data: categories, isLoading: isLoadingCategories },
    ] = categoriesRtkQService.useLazyGetCategoriesQuery();

    const [dataForCreate, setDataForCreate] = useState<ICampaignDataModel | null>(null);

    // const [isCreatingNewCampaign, setCreatingNewCampaign] = useState<boolean>(false);
    const [selectedFavoriteSources, setFavoriteSources] = useState<IFavoriteSourceModel[]>([]);

    const isExcludedLinks = useMemo<boolean>(() => (
        !(campaignRtkQ?.sources?.length) && campaignRtkQ?.excludeSources?.length > 0
    ), [campaignRtkQ]);

    const selectedCategoriesIds = useMemo<number[]>(
        () => getCategoriesIds(campaignCategoriesIds) ?? [],
        [],
    );
    const selectedPeriodId = useMemo<string>(() => {
        if (referenceCampaign) {
            getCampaign({ id: referenceCampaign.id });
        }
        return (
            campaignUtils.getCampaignPeriodIdFromParsedDate(
                referenceCampaign?.parseFromDate?.toISOString()?.split('T')[0] ?? null,
                referenceCampaign?.createDate ?? null,
            )
        );
    }, [referenceCampaign]);

    const handlerCreateClick = (data: ICampaignDataModel): void => {
        // dispatch(createCampaignThunk(data, true));
        setDataForCreate(data);
        // setCreatingNewCampaign(true);
    };
    const handlerDeleteTrackedSource = (isTracked: boolean, id: number): void => {
        dispatch(campaignTrackedSourcesActions.removeSelectedSource({ isTracked, id }));
    };

    const handlerSetFavoriteSources = (value: ITrackedWordModel[]): void => {
        const selectedIds = value.map(({ id }) => id);
        const result = favoriteSourcesData?.data?.filter((item) => selectedIds.includes(item.id));
        setFavoriteSources([...result]);
    };

    useEffect(() => {
        getCategories();
    }, []);
    useEffect(() => {
        if (createStatus === FetchStatus.success) {
            dispatch(campaignActions.setInitialCreatingStatus());

            dispatch(messageModalActions.setMessage(
                (
                    <>
                        <span>
                            Вы только что создали Кампанию
                            {` ${campaignName ?? '-'}`}
                            .
                        </span>
                        <span>Для просмотра результатов отслеживания</span>
                        <span>перейдите во вкладку Отслеживание</span>
                    </>
                ),
            ));
            dispatch(messageModalActions.setButtonTagId('button-campaign-create-ok'));
            dispatch(messageModalActions.setButtonText('Ok'));

            const id = Number(urlParametersId);
            if (campaignId !== id || !urlParametersId) {
                dispatch(campaignActions.setCreateStatus(false));
                dispatch(campaignActions.setViewActionCampaign());
                setCurrentModalWindow('');
            }
        }
    }, [createStatus, campaignName, dispatch, urlParametersId, campaignId]);

    // useEffect(() => {
    //     if (isCreatingNewCampaign && dataForCreate && referenceCampaign?.id && referenceCampaign.id !== campaignRtkQ.id) {
    //         createCampaign({
    //             name: dataForCreate.name,
    //             isTrackedLinks: dataForCreate.isTrackedLinks,
    //             categories: dataForCreate.categoriesIds,
    //             parseFrom: dataForCreate.parseFrom,
    //             sourceType: dataForCreate.sourceType,
    //             includeWords: dataForCreate.includeWords,
    //             excludeWords: dataForCreate.excludeWords,
    //             isBlogger: dataForCreate.isBlogger,
    //             isComplexQuery: false,
    //             searchQuery: null,
    //             withoutPopup: true,
    //             sources: (dataForCreate.isTrackedLinks ? trackedSources : excludedSources),
    //         });
    //         setCreatingNewCampaign(false);
    //         setDataForCreate(null);
    //         dispatch(campaignComparisonActions.addCampaignForCompare(campaignRtkQ));
    //         // dispatch(loadCampaignsIfNotExists());
    //         setCurrentModalWindow('');
    //     }
    // }, [isCreatingNewCampaign, campaignRtkQ, referenceCampaign, dataForCreate]);

    useEffect(() => {
        if (dataForCreate) {
            const sourcesBuffer = [
                ...selectedFavoriteSources.map((item) => ({
                    id: item?.source?.id,
                    allowInRussia: item?.source?.allow_in_russia,
                    authorId: item?.source?.author_id,
                    created: item?.source?.created,
                    autoDiscover: item?.source?.auto_discover,
                    enabled: item?.source?.enabled,
                    link: item?.source?.link,
                    outSourceId: item?.source?.out_source_id,
                    parsed: item?.source?.parsed ?? '',
                    tag: item?.source?.tag,
                    locationId: item?.source?.location_id,
                    title: item?.source?.title,
                    parseLink: item?.source?.parse_link,
                    typeId: item?.source?.type_id,
                } as ITrackedSourceModel)),
                ...(dataForCreate.isTrackedLinks ? trackedSources : excludedSources),
            ];
            createCampaign({
                name: dataForCreate.name,
                isTrackedLinks: dataForCreate.isTrackedLinks,
                categories: dataForCreate.categoriesIds,
                parseFrom: dataForCreate.parseFrom,
                sourceType: dataForCreate.sourceType,
                includeWords: dataForCreate.includeWords,
                excludeWords: dataForCreate.excludeWords,
                isBlogger: dataForCreate.isBlogger,
                withoutPopup: false,
                isComplexQuery: false,
                searchQuery: null,
                sources: sourcesBuffer,
            });
            setDataForCreate(null);
        }
    }, [dataForCreate]);

    if (currentModalWindow !== 'CreateCompany') {
        return null;
    }

    return (
        <CreateFromReferenceCampaign
            isLoading={isLoading || isLoadingCampaignRtkQ}
            isDefaultExcludedLinks={isExcludedLinks}
            selectedCategoriesIds={selectedCategoriesIds}
            isIPadSize={isIPadSize}
            selectedPeriodId={selectedPeriodId}
            categories={categories}
            onCreateClick={handlerCreateClick}
            onDeleteTrackedSource={handlerDeleteTrackedSource}
            setCurrentModalWindow={setCurrentModalWindow}
            campaign={campaignRtkQ}
            onSelectedFavoriteSources={handlerSetFavoriteSources}
            isLoadingCategories={isLoadingCategories}
        />
    );
};

export default ComparisonFromReferenceCampaignContainer;
