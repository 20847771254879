import React, { useMemo, useState } from 'react';
import { ICommonInfo } from '@features/project/types';
import { Button } from '@shared/index';
import TextField from '@entities/TextField';
import TextArea from '@entities/TextArea';
import styles from './styles.module.scss';

interface IProps {
    info: ICommonInfo;
    onCreate: (value: ICommonInfo) => void;
}

const ProjectInfo: React.FC<IProps> = ({ info, onCreate }): JSX.Element => {
    const [name, setName] = useState<string>(info?.name ?? '');
    const [description, setDescription] = useState<string>(
        info?.description ?? '',
    );

    const isCreateDisable = useMemo<boolean>(() => !name, [name]);

    const handlerCreate = (): void => {
        onCreate({
            id: null,
            name,
            description,
        });
    };
    return (
        <div className={styles.projectInfoFeatures_root}>
            <div className={styles.projectInfoFeatures_blockName}>
                <TextField
                    value={name}
                    label="Наименование"
                    placeholder="Введите наименование"
                    onChange={(value: string) => setName(value)}
                />
            </div>
            <TextArea
                label="Описание"
                placeholder="0 / 3000 символов"
                value={description}
                onChange={(value: string) => setDescription(value)}
            />
            <div className={styles.projectInfoFeatures_buttonContainer}>
                <Button
                    text="Создать"
                    type="default"
                    size="large"
                    onClick={handlerCreate}
                    disabled={isCreateDisable}
                />
            </div>
        </div>
    );
};

export default ProjectInfo;
