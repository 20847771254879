import React from 'react';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SvgIcon from '../../../SvgIcon';
import { ICampaignCategorySelectProps } from './types';
import CampaignCategoryDropdownList from './DropdownList/index';

import styles from './styles.module.scss';

const CampaignCategorySelect: React.FC<ICampaignCategorySelectProps> = ({
    selectedCategories,
    categories,
    onSelectCategory,
    onDeselectCategory,
    isView, isLoading,
}): JSX.Element => {
    if (isView && !selectedCategories?.length) {
        return null;
    }
    return (
        <>
            {
                isLoading && <Skeleton width="50%" height={25} />
            }
            {
                !isLoading && (
                    <div className={classnames(styles.root, styles[selectedCategories?.length > 1 ? 'maxWidth' : 'minWidth'])}>
                        <div className={styles.headerContainer}>
                            <div className={styles.label}>Выбранные категории:</div>
                            <div className={styles.dropdownContainer}>
                                {
                                    !isView
                                && (
                                    <CampaignCategoryDropdownList
                                        categories={categories}
                                        onSelectCategory={onSelectCategory}
                                        label="Все категории"
                                    />
                                )
                                }
                            </div>
                        </div>
                        {
                            selectedCategories?.length > 0 && (
                                <div
                                    className={classnames(styles.listContainer, styles[selectedCategories?.length > 0 ? 'maxWidth' : 'minWidth'])}
                                >
                                    {
                                        selectedCategories?.length > 0 && (
                                            selectedCategories.map((item, index) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <div className={styles.categoryBox} key={`${item.id}-${index}`}>
                                                    <span className={styles.categoryBox_text}>{item.name}</span>
                                                    {
                                                        !isView && (
                                                            <button type="button" className={styles.buttonCross} onClick={() => onDeselectCategory(item.id)}>
                                                                <SvgIcon id="Cross" size={14} />
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    );
};

export default CampaignCategorySelect;
