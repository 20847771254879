/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import Skeleton from 'react-loading-skeleton';
import SourceFilter from '../InformationBlock';

interface IPropsVerticalInfo {
    isLoading: boolean,
    err: string,
    err24: string
}

const WIDTH_SMALL_IMPORTANT_INFO = 105;
const HEIGHT_SMALL_IMPORTANT_INFO = '50%';

const VerticalInfo: React.FC<IPropsVerticalInfo> = ({
    isLoading,
    err,
    err24,
}): JSX.Element => {
    if (isLoading) {
        return (
            <div style={{
                marginRight: 24, background: '#fff', borderRadius: 6, padding: 16,
            }}
            >
                <Skeleton
                    width={WIDTH_SMALL_IMPORTANT_INFO}
                    height={WIDTH_SMALL_IMPORTANT_INFO}
                />
                <Skeleton
                    width={WIDTH_SMALL_IMPORTANT_INFO}
                    height={WIDTH_SMALL_IMPORTANT_INFO}
                />
            </div>

        );
    }
    return (

        <div style={{ marginRight: 24, position: 'relative' }}>
            <SourceFilter
                label="ERR"
                importantDetail={<span style={{ whiteSpace: 'nowrap' }}>{err}</span>}
                isHaveImortantDetail
                isLoading={isLoading}
                style={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    marginRight: 0,
                    height: HEIGHT_SMALL_IMPORTANT_INFO,
                    minWidth: WIDTH_SMALL_IMPORTANT_INFO,
                    width: '100%',
                }}
                skeleton={null}
            />
            <SourceFilter
                label="ERR24"
                importantDetail={(
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {err24}
                    </span>
                )}
                isHaveImortantDetail
                isLoading={isLoading}
                style={{
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    marginRight: 0,
                    height: HEIGHT_SMALL_IMPORTANT_INFO,
                    minWidth: WIDTH_SMALL_IMPORTANT_INFO,
                    width: '100%',
                }}
                skeleton={null}
            />
        </div>
    );
};
export default VerticalInfo;
