import React, { ErrorInfo, ReactNode } from 'react';
import styles from './styles.module.scss';
import { CURRENT_STAND } from '../../../utils/api';

interface IErrorBoundaryProps {
    children?: ReactNode;
}

interface IErrorBoundaryState {
    hasError: boolean;
    error: Error;
    errorInfo: ErrorInfo;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    public static getDerivedStateFromError(error: Error): IErrorBoundaryState {
        return {
            error,
            hasError: true,
            errorInfo: null,
        };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.setState({
            error,
            errorInfo,
            hasError: true,
        });
    }

    render() {
        if (this.state?.hasError) {
            return (
                <div className={styles.errorBoundary_root}>
                    <h2>Something went wrong.</h2>
                    {
                        CURRENT_STAND !== 'test' && (
                            <details style={{ whiteSpace: 'pre-wrap' }}>
                                {this.state?.error && this.state?.error.toString()}
                                <br />
                                {this.state?.errorInfo?.componentStack}
                            </details>
                        )
                    }
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
