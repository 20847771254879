import classnames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Path from '../../../shared/lib/paths';
import PersonIcon from '../../../assets/images/person.svg';
import StarIcon from '../../../assets/images/star.svg';
import { authActions } from '../../../store/auth';
import { useAppDispatch } from '../../../store/hooks';
import { sendMetrics } from '../../../utils/commons/metrics';
import styles from './styles.module.scss';

const sendMetricsLinkToHistory = (): void => {
    sendMetrics('reachGoal', 'LinkToHistory');
};

const handleKeyDown = () => {
    // eslint-disable-next-line no-console
};

const UserNavigatePanel: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleNavigateClick = (key: string) => {
        switch (key) {
            case '1':
                break;
            case '2':
                sendMetricsLinkToHistory();
                navigate(Path.History);
                break;
            case '4':
                dispatch(authActions.removeAuth());
                navigate(Path.Auth);
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.userNavigatePanel_root}>
            <NavLink
                to={Path.UserProfile}
                className={({ isActive }) => classnames(
                    styles.link,
                    isActive ? styles.linkActive : undefined,
                )}
            >
                <img src={PersonIcon} alt="PersonIcon" />
                <span>Мой профиль</span>
            </NavLink>
            <NavLink
                to={Path.UserProfileFavorites}
                className={({ isActive }) => classnames(
                    styles.link,
                    isActive ? styles.linkActive : undefined,
                )}
            >
                <img src={StarIcon} alt="StarIcon" />
                <span>Избранное</span>
            </NavLink>
            {/* <div */}
            {/*    className={styles.itemContainer} */}
            {/*    role="button" */}
            {/*    tabIndex={0} */}
            {/*    onKeyDown={handleKeyDown} */}
            {/*    onClick={() => handleNavigateClick('1')} */}
            {/* > */}
            {/*    <img src="/images/settings.svg" alt="settings" width="20" /> */}
            {/*    <span className={styles.itemContainer_text}>Настройки</span> */}
            {/* </div> */}
            {/* <div */}
            {/*    id="profile-history" */}
            {/*    className={styles.itemContainer} */}
            {/*    role="button" */}
            {/*    tabIndex={0} */}
            {/*    onKeyDown={handleKeyDown} */}
            {/*    onClick={() => handleNavigateClick('2')} */}
            {/* > */}
            {/*    <img src="/images/history.svg" alt="history" width="20" /> */}
            {/*    <span className={styles.itemContainer_text}>История</span> */}
            {/* </div> */}
            <div
                id="profile-logout"
                className={styles.itemContainer}
                role="button"
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onClick={() => handleNavigateClick('4')}
            >
                <img src="/images/exit.svg" alt="exit" width="24" />
                <span className={styles.itemContainer_text}>Выход</span>
            </div>
        </div>
    );
};

export default UserNavigatePanel;
