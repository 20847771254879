import React from 'react';
import DocumentCard from '../DocumentCard';
import { IDocument } from '../types';
import SkeletonList from './Skeleton';
import styles from './styles.module.scss';

interface IPropsDocumentList {
    isLoading: boolean,
    isLoadFile?: boolean,
    documentList: IDocument[],
    onRemoveFile: (id: number) => void
    onChangeNameInList: (id: number, name: string) => void
}

// eslint-disable-next-line arrow-body-style
const DocumentList: React.FC<IPropsDocumentList> = ({
    isLoading,
    documentList,
    isLoadFile = false,
    onChangeNameInList,
    onRemoveFile,
}): JSX.Element => {
    const getBriefLabel = (isShablons) => (isShablons ? 'Шаблон Брифа' : 'Бриф');
    const jsxDocumentList = documentList.map(({
        id, type, label, date, type_id,
    }, index) => (
        <DocumentCard
            isBrief={index === 0}
            type_id={type_id}
            key={`DocumentCard_${id}`}
            onDelete={onRemoveFile}
            onChangeNameInList={onChangeNameInList}
            isLoading={isLoading || ((index === documentList.length - 1) && isLoadFile)}
            id={id}
            type={type}
            label={index === 0 ? getBriefLabel(type_id === 2) : label}
            date={date}
        />
    ));
    const ListDocs = (
        <div className={styles.documentsContainer}>
            {jsxDocumentList}
        </div>
    );
    const content = isLoading ? <SkeletonList /> : ListDocs;
    return (
        <div
            className={styles.root_DocumentsList}
            style={{ marginRight: documentList.length ? 72 : 0 }}
        >
            {content}
        </div>
    );
};

export default DocumentList;
