export interface ICampaignUserRightsState {
    usersState: ICampaignUsersData[]
}

export interface ICampaignUsersData {
    campaignId: number;
    users: ICampaignUserRightsUserInfo[];
}

export interface ICampaignUserRightsUserInfo {
    campaignId: number
    email: string
    firstName: string
    id: number
    lastName: string
    middleName: string | null
    role: {
        id: ICampaignUserRole
        name: string
    }
    userId: string
}

export enum ICampaignUserRole {
    readOnly = 3,
    owner = 2,
}
