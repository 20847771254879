import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInfoBasketSubpage, InfoBasketSubpage } from './types';

const initialState: IInfoBasketSubpage = {
    currentSubpage: InfoBasketSubpage.filters,
};

const { actions: infoBasketSubpageActions, reducer } = createSlice({
    name: 'infoBasketSubpage',
    initialState,
    reducers: {
        changeSubpage: (
            state: IInfoBasketSubpage,
            { payload }: PayloadAction<InfoBasketSubpage>,
        ) => {
            state.currentSubpage = payload;
        },
    },
});

export { infoBasketSubpageActions };
export default reducer;
