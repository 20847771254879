/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
// import CampaignNameInput from '../../../../components/campaign/UpdateForm/NameInput';
import { IApplyFilter } from '@sections/MyCampaignContainer/CampaingTypes';
import { userFiltersCampaingRtqService } from '@services/campaigns/userFilters';
import { Button } from '@shared/index';
import styles from './styles.module.scss';

interface IPropsFooterCreateUserFilter {
    campaignId: number,
    includes: IApplyFilter[],
    excludes: IApplyFilter[],
    onSave: (name: string) => void,
    onCancel: () => void,
}

const FooterCreateUserFilter: React.FC<IPropsFooterCreateUserFilter> = ({
    campaignId,
    includes,
    excludes,
    onSave,
    onCancel,
}): JSX.Element => {
    const [createNewFilter, data] = userFiltersCampaingRtqService.useCreateUserFilterMutation();
    const {
        isLoading,
        isError,
        isSuccess,
        error,
    } = data;
    const [isShowErrorOnInput, setShowErrorOnInput] = useState(false);
    const [isShowSucces, setShowSucces] = useState(false);
    const [name, setName] = useState<string>();
    const handleChangeInput = (e) => {
        setName(e.target.value);
        setShowErrorOnInput(false);
        setShowSucces(false);
    };
    useEffect(() => {
        if (data.isSuccess) {
            onSave(data?.data);
        }
    }, [data]);
    useEffect(() => {
        if (isError) setShowErrorOnInput(true);
    }, [isError]);
    const getErrorMsg = useMemo(() => {
        const msg = (error as any)?.data?.detail[0]?.msg;
        if (!msg) return 'Произошла непредвиденная ошибка, попробуйте позже';
        if (msg === 'field required') return 'Заполните поле имени';
        return msg;
    }, [error]);
    const handleCreateFilters = (nameFilter: string) => {
        createNewFilter({
            campaign_id: campaignId,
            title: nameFilter,
            filters_exclude: [],
            // todo excludes,
            filters_include: excludes,
        }).then(() => {
            setShowSucces(true);
            setName('');
        });
    };
    return (
        <div className={styles.root_footerFilters}>
            <input
                className={`${styles.input_filterName} ${isError && isShowErrorOnInput && styles.errorInput}`}
                type="text"
                value={name}
                onChange={handleChangeInput}
            />
            <div
                style={{
                    color: isError ? '#F36869' : '#0EBFA1',
                }}
                className={`${styles.msg} ${((isError && isShowErrorOnInput) || (isSuccess && isShowSucces)) && styles.show}`}
            >
                {isError ? getErrorMsg : 'Новый фильтр успешно создан'}
            </div>
            <div
                style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }}
            >
                {
                    isLoading ? (
                        <div>
                            <Skeleton width={150} height={48} style={{ marginBottom: 10 }} />
                            <Skeleton width={150} height={48} />
                        </div>
                    ) : (
                        <div>
                            <Button
                                text="Сохранить"
                                type="default"
                                size="large"
                                onClick={() => handleCreateFilters(name)}
                            />
                            {/* <ButtonPrimary */}
                            {/*    style={{ width: 150, height: 48, fontSize: 16 }} */}
                            {/*    text="Сохранить" */}
                            {/*    onClick={() => handleCreateFilters(name)} */}
                            {/* /> */}
                            <Button
                                text="Назад"
                                type="secondary"
                                size="large"
                                onClick={() => onCancel()}
                            />
                            {/* <ButtonSecondary */}
                            {/*    style={{ */}
                            {/*        width: 150, height: 48, fontSize: 16, marginTop: 10, */}
                            {/*    }} */}
                            {/*    text="Назад" */}
                            {/*    onClick={() => onCancel()} */}
                            {/* /> */}
                        </div>
                    )
                }

            </div>
        </div>

    );
};
export default FooterCreateUserFilter;
