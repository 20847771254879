import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBloggerCommonModel, IBloggerPublicationModel } from 'services/authors/types';
import { IBloggerInfoState } from './types';
import { FetchStatus } from '../../types';
import { convertApiToBloggerCommonData, convertApiToBloggerPublicationData } from './converter';

const initialState: IBloggerInfoState = {
    commonData: {} as IBloggerCommonModel,
    publicationData: [] as IBloggerPublicationModel[],
    commonStatus: FetchStatus.undefined,
    publicationStatus: FetchStatus.undefined,
    commonError: '',
    publicationError: '',
};

const { actions: infoBasketBloggerInfoActions, reducer } = createSlice({
    name: 'infoBasketBloggerInfo',
    initialState,
    reducers: {
        startCommonLoading: (state: IBloggerInfoState): void => {
            state.commonStatus = FetchStatus.loading;
        },
        successCommonLoading: (state: IBloggerInfoState): void => {
            state.commonStatus = FetchStatus.success;
        },
        failedCommonLoading: (state: IBloggerInfoState, { payload }: PayloadAction<string>): void => {
            state.commonStatus = FetchStatus.failed;
            state.commonError = payload;
        },
        startPublicationLoading: (state: IBloggerInfoState): void => {
            state.publicationStatus = FetchStatus.loading;
        },
        successPublicationLoading: (state: IBloggerInfoState): void => {
            state.publicationStatus = FetchStatus.success;
        },
        failedPublicationLoading: (state: IBloggerInfoState, { payload }: PayloadAction<string>): void => {
            state.publicationStatus = FetchStatus.failed;
            state.publicationError = payload;
        },
        setCommonData: (state: IBloggerInfoState, { payload }: PayloadAction<unknown>): void => {
            state.commonData = convertApiToBloggerCommonData(payload);
        },
        clearAll: (state: IBloggerInfoState): void => {
            state.commonData = {} as IBloggerCommonModel;
            state.publicationData = [] as IBloggerPublicationModel[];
            state.commonStatus = FetchStatus.undefined;
            state.publicationStatus = FetchStatus.undefined;
            state.commonError = '';
            state.publicationError = '';
        },
        setPublicationData: (state: IBloggerInfoState, { payload }: PayloadAction<unknown>): void => {
            state.publicationData = convertApiToBloggerPublicationData(payload);
        },
        appendPublicationData: (state: IBloggerInfoState, { payload }: PayloadAction<unknown>): void => {
            state.publicationData.push(...convertApiToBloggerPublicationData(payload));
        },
        clearData: (state: IBloggerInfoState): void => {
            state.commonData = {} as IBloggerCommonModel;
        },
    },
});
export { infoBasketBloggerInfoActions };
export default reducer;
