import React, { useMemo } from 'react';
import { IEntity, IPlatform } from '@services/sources/types';
import CustomCheckbox from '@components/Select/SearchSelect/DropdownMenu/CheckboxList/Checkbox/Checkbox';
import SvgIcon from '@components/SvgIcon';
import styles from './styles.module.scss';

interface IProps {
    platformsFromApi: IEntity[];
    platformByNameMap: Map<string, IEntity>;
    platformByIdMap: Map<number, IPlatform>;
    platformsIcon: Map<string, string>;
    onSelect: (ids: number[], filterType: string) => void;
    checkedFiltersIds: number[];
}

type PlatformCheckbox = { id: number, name: string, icon: string, checked: boolean };

const initialStatePlatforms: PlatformCheckbox[] = [];

const getStatePlatforms = (platforms: IEntity[], platformsMap: Map<number, IPlatform>): PlatformCheckbox[] => (
    initialStatePlatforms?.length < 1
        ? (
            platforms?.map((item) => (platformsMap.get(item.id)
                ? {
                    id: item.id,
                    name: platformsMap.get(item.id).title,
                    icon: platformsMap.get(item.id).icon,
                    checked: false,
                }
                : {
                    id: item.id,
                    name: item.title,
                    icon: null,
                    checked: false,
                }))
        )
        : initialStatePlatforms
);

const PlatformFilterForTable: React.FC<IProps> = ({
    platformsFromApi,
    platformByNameMap,
    platformByIdMap,
    platformsIcon,
    onSelect,
    checkedFiltersIds,
}): JSX.Element => {
    const initialPlatforms = useMemo<PlatformCheckbox[]>(() => (
        getStatePlatforms(platformsFromApi, platformByIdMap)
    ), [platformsFromApi, platformByIdMap]);

    const platforms = useMemo<PlatformCheckbox[]>(() => (
        (initialPlatforms ?? []).map((item) => ({ ...item, checked: checkedFiltersIds.includes(item.id) }))
    ), [initialPlatforms, checkedFiltersIds]);

    const isAllChecked = useMemo<boolean>(() => (
        platforms.every(({ checked }) => checked)
    ), [platforms]);

    const selectAllPlatform = () => {
        onSelect(
            !isAllChecked ? [...platformByNameMap.values()].map(({ id }) => id) : [],
            'type_id',
        );
    };
    const handlerCheckedPlatform = (name: string): void => {
        const ids = (checkedFiltersIds ?? []).includes(platformByNameMap.get(name).id)
            ? (checkedFiltersIds ?? []).filter((id) => id !== platformByNameMap.get(name).id)
            : [...(checkedFiltersIds ?? []), platformByNameMap.get(name).id];
        onSelect(ids, 'type_id');
    };

    return (
        <div className={styles.tablePlatformFilter_listPlatforms}>
            <div className={styles.tablePlatformFilter_headCheckBox}>
                <CustomCheckbox
                    id="1"
                    checked={isAllChecked}
                    isRetinaSize={false}
                    name="Все"
                    onChecked={() => selectAllPlatform()}
                    textClassName={styles.tablePlatformFilter_label}
                    isError={false}
                    isDisabled={false}
                />
            </div>
            <div className={styles.tablePlatformFilter_listIcon}>
                {platforms.map((item) => (
                    <div key={item.name} className={styles.tablePlatformFilter_lineIcon}>
                        <CustomCheckbox
                            id="1"
                            checked={item.checked}
                            isRetinaSize={false}
                            name={null}
                            labelElement={(
                                <div className={styles.tablePlatformFilter_icon} style={{ marginLeft: -12 }}>
                                    <SvgIcon id={platformsIcon.get(item.icon)} size={24} />
                                </div>
                            )}
                            onChecked={() => handlerCheckedPlatform(item.name)}
                            isError={false}
                            isDisabled={false}
                        />
                        <button
                            type="button"
                            onClick={() => handlerCheckedPlatform(item.name)}
                            className={styles.tablePlatformFilter_label}
                        >
                            {item.name}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlatformFilterForTable;
