/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { IApplyFilter, ListCategory } from '@sections/MyCampaignContainer/CampaingTypes';
import { IQueryParamsCampaingFilters } from '@services/campaigns/tracking/types';
import useGetFilters from '@shared/hooks/campaign/useGetFilters';
import {
    IModelUserFilter,
} from '@services/campaigns/userFilters/types';
import { userFiltersCampaingRtqService } from '@services/campaigns/userFilters';
import { Button } from '@shared/index';
import { message } from 'antd';
import QuickSVGLoader from '../../../QuickSVGLoader';
import { getListFilters } from '../../TrackingLeftBar/helpers';
import TrackingLeftBar from '../../TrackingLeftBar';
import styles from './styles.module.scss';
import ModalChangeSkeleton from './Skeleton';

interface IPropsLastItem {
    campaignId: number,
    data: IModelUserFilter,
    onClose: () => void,
    onChangeFilters: (...args: any) => void
}
// TODO 23.10.23 A.Pushkin добавить валидацию и заменить стандартный инпут.
const ModalChange: React.FC<IPropsLastItem> = ({
    campaignId,
    data,
    onClose,
    onChangeFilters,
}): JSX.Element => {
    const [listExclude, setListExclude] = useState<string[]>([]);
    const [listOpenFilters, setListOpenFilters] = useState<string[]>([]);
    const [newTitle, setNewTitle] = useState<string>(() => data?.title);
    const [currentFilters, setCurrentFilters] = useState<ListCategory>([]);
    const [isLoader, setLoader] = useState(Boolean);
    const paramsRequestFilters = useMemo((): IQueryParamsCampaingFilters => {
        const newQuery: any = {
            campaign_id: campaignId,
            filters_include: [],
            filter_exclude: [],
        };
        return newQuery;
    }, [campaignId, data]);
    const getListApply = useMemo((): IApplyFilter[] => getListFilters(currentFilters), [currentFilters]);
    const [updateFilters] = userFiltersCampaingRtqService.useUpdateUserFilterMutation();
    const [createFilters] = userFiltersCampaingRtqService.useCreateUserFilterMutation();
    const [setRequestParams, setNewApplyFilter, filters, isLoadingFilters] = useGetFilters(paramsRequestFilters);
    useEffect(() => {
        if (filters) { setCurrentFilters(filters); }
    }, [filters]);
    const compareFilters = (listFilters: ListCategory, ex: IApplyFilter[], ic: IApplyFilter[]) => {
        const newListFilters: ListCategory = listFilters.map((it) => {
            const newCategory = { ...it };
            newCategory.filters = newCategory.filters.map((f) => {
                const newFilter = { ...f };
                // todo BIG AP
                const isExclude = ex.find((e) => e.filter_name.replaceAll('_', ' ') === f.filter_name.replaceAll('_', ' ') && e.filters_category === it.filters_category);
                const isInclude = ic.find((i) => i.filter_name.replaceAll('_', ' ') === f.filter_name.replaceAll('_', ' ') && i.filters_category === it.filters_category);
                if (isExclude) newFilter.exclude = !newFilter.exclude;
                if (isInclude) newFilter.include = !newFilter.include;
                return newFilter;
            });
            return newCategory;
        });
        return newListFilters;
    };
    useEffect(() => {
        if (data && filters?.length) {
            const newCategories = compareFilters(filters, data.filters_exclude, data.filters_include);
            setCurrentFilters(newCategories);
            setNewTitle(data?.title);
        }
    }, [data, filters]);
    const handleUpdateOpenFilters = useCallback((name: string): void => {
        setListOpenFilters((prev) => {
            const hasFilter = prev.some((filter) => filter === name);
            if (hasFilter) return prev.filter((filter) => filter !== name);
            return [name, ...prev];
        });
    }, []);
    const handlerUpdateApplyFilters = (applyFilter: IApplyFilter, type: string = 'include') => {
        setCurrentFilters((prev) => prev?.map((item) => {
            if (item.filters_category === applyFilter.filters_category) {
                // eslint-disable-next-line no-param-reassign
                item.filters = item.filters.map((elem) => {
                    if (elem.filter_name.replaceAll('_', ' ') === applyFilter.filter_name.replaceAll('_', ' ')) {
                        const newElem = { ...elem };
                        newElem[type] = !newElem[type];
                        return newElem;
                    }
                    return elem;
                });
                return item;
            }
            return item;
        }));
    };
    const handleSaveSettings = () => {
        if (!newTitle?.length) {
            message.error('Введите название для конфигурации');
            return;
        }
        setLoader(true);
        if (data.id) {
            updateFilters({
                id: data.id,
                body: {
                    title: newTitle,
                    campaign_id: campaignId,
                    filters_include: getListApply,
                    filters_exclude: data.filters_exclude,
                },
            }).finally(() => {
                setLoader(false);
            });
        } else {
            createFilters({
                campaign_id: campaignId, title: newTitle, filters_exclude: [], filters_include: getListApply,
            }).then((res) => {
                const newData = (res as any)?.data;
                if (newData) {
                    onChangeFilters({
                        id: newData?.id,
                        title: newData?.title,
                    });
                    setListOpenFilters([]);
                    setNewTitle('');
                    setCurrentFilters([]);
                    onClose();
                }
            }).finally(() => {
                setLoader(false);
            });
        }
    };
    const handleChangeTypeChangeFilters = (type: 1 | 2, name: string) => {
        const isSome = listExclude.some((filter) => filter === name);
        if (isSome && type === 1) setListExclude((prev) => prev.filter((filter) => filter !== name));
        if (!isSome && type === 2) setListExclude((prev) => [name, ...prev]);
    };
    if (isLoadingFilters) { return <ModalChangeSkeleton />; }
    return (
        <div
            className={styles.root_ModalInfo}
        >
            { isLoader
            && (
                <div className={styles.containerModal}>
                    <QuickSVGLoader />
                </div>
            )}
            <div className={styles.item}>
                <div className={styles.item_label}>
                    Наименование конфигурации фильтров
                </div>
                <input
                    className={styles.input_newFilterName}
                    type="text"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                />
            </div>
            <TrackingLeftBar
                listExclude={[]}
                disabledSeporator
                filters={currentFilters}
                listOpenFilters={listOpenFilters}
                isLoading={isLoadingFilters}
                isIPad={false}
                changeTypeFiltersApply={handleChangeTypeChangeFilters}
                onUpdateApplyFilters={handlerUpdateApplyFilters}
                onUpdateOpenFilters={handleUpdateOpenFilters}
            />
            {
                !isLoadingFilters && (
                    <div className={styles.footer_ModalChange}>
                        <Button
                            text="Сохранить"
                            type="default"
                            disabled={!newTitle?.length}
                            size="large"
                            onClick={handleSaveSettings}
                        />
                    </div>
                )
            }
        </div>

    );
};
export default React.memo(ModalChange);
