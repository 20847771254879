import React from 'react';
import SvgIcon from '../../../components/SvgIcon';
import { TStatus } from '../types';
import styles from './styles.module.scss';

interface IPropsErrorLoad {
    onChangeStatus: (newStatus: TStatus) => void,
}
const ErrorLoad: React.FC<IPropsErrorLoad> = ({ onChangeStatus }): JSX.Element => (
    <div className={styles.blockLoadFile_root} onMouseEnter={() => { onChangeStatus('default'); }}>
        <div className={styles.btnWithLabel}>
            <span className={styles.label}>Не удалось</span>
            <div style={{
                position: 'relative',
                display: 'block',
                flexGrow: 1,
            }}
            >
                <SvgIcon size={31} id="FailCross" />
            </div>
        </div>
        <div className={styles.additionlInfo}>
            <span>добавить фаил </span>
        </div>
    </div>
);

export default ErrorLoad;
