import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { IPostPreviewFromApi } from '@services/campaigns/single-preview/types';
import { ICampaignDataModel } from '@services/campaigns/campaigns-list/types';
import { ICategory } from '@store/types';
import { Button } from '@shared/index';
import SvgIcon from '@components/SvgIcon';
import { ICampaignCategoryModel } from '../UpdateForm/CategorySelect/types';
import campaignUtils from '../../../utils/campaign';
import CampaignSwitcher from '../UpdateForm/Switcher';
import CampaignTrackedWordsBlock from '../UpdateForm/TrackedWords';
import { ITrackedWordModel } from '../UpdateForm/TrackedWords/types';
import ValidationTooltip from '../Validation';
import SimpleSelectItem from '../../Select/SimpleSelect/SimpleSelectItem';
import { campaignPeriodsMock } from '../../../utils/constants/mocks/periods';
import CampaignCategorySelect from '../UpdateForm/CategorySelect';
import CampaignNameInput from '../UpdateForm/NameInput';
import { ICampaignSimpleEntity } from '../UpdateForm/Sources/SourceTypes/types';
import CampaignSourcesBlock from '../UpdateForm/Sources';
import helpers from './helpers';
import styles from './styles.module.scss';

const PlatformsIcon = new Map([
    ['rss', 'RssLogo'],
    ['vk', 'VkLogo'],
    ['ok', 'OkLogo'],
    ['tg', 'TgLogo'],
    ['dzen', 'ZenLogo'],
]);

interface IPostsPreview extends IPostPreviewFromApi {
    contentExpanded?: boolean | null;
}

interface ICampaignCreateFormProps {
    isLoading: boolean;
    categories: ICategory[];
    selectedCategoriesIds: number[];
    onCreateBegun: () => void;
    onCancelClick: () => void;
    onCreateClick: (data: any) => void;
    onDeleteTrackedSource: (isTracked: boolean, id: number) => void;
    needClear: boolean;
    onClearAllSelectedSources: () => void;
    onCleared: () => void;
    onCreateSearchRequest: () => void;
    isIPadSize: boolean;
    isSearchRequest: boolean;
    checkedSearchRequest: (value: boolean) => void;
    searchRequestStr: string;
    onSelectedFavoriteSources: (value: ITrackedWordModel[]) => void;
    onGetPreviewPosts: (data: ICampaignDataModel) => void;
    previewPosts: IPostsPreview[];
    isLoadingPreviewPosts: boolean;
    isLoadingCategories: boolean;
}

const topComponentsHeight = 152;
const initialPeriod = '2'; // 30 дней

const prepareSource = (data: string[]): string[] => {
    if (data?.length <= 0) {
        return undefined;
    }
    if (data?.length === 1 && data[0] === 'ALL') {
        return null;
    }
    return data.filter((item) => item !== 'ALL');
};
const prepareCategories = (data: number[], categories: ICategory[]): ICampaignCategoryModel[] => {
    if (!categories || !categories?.length || !data || !data?.length) {
        return [];
    }
    return data
        .map((id) => {
            const res = categories.find((item) => Number(item.id) === id);
            return { id: Number(res?.id), name: res?.mainCat };
        });
};
const getBloggerValue = (checkedIds: number[]): boolean | null | undefined => {
    if (!checkedIds?.length) {
        return undefined;
    }
    if (checkedIds.some((id) => id === 1)) {
        return null;
    }
    if (checkedIds.some((id) => id === 2) && checkedIds.some((id) => id === 3)) {
        return null;
    }
    if (checkedIds.some((id) => id === 2)) {
        return false;
    }
    if (checkedIds.some((id) => id === 3)) {
        return true;
    }
    return undefined;
};
const bloggersBoolToArray = (value: boolean | null): number[] => {
    if (value === null) {
        return [1, 2, 3];
    }
    if (value === undefined) {
        return [];
    }
    return value ? [3] : [2];
};
const sourcesStringToNumber = (data: string[]): number[] => {
    const result = data
        .map((name) => Number(campaignUtils.campaignPlatformsTypeState
            .find((item) => item.name === name)?.id))
        .filter((value) => Boolean(value));
    return result;
};

const initialMediaType = null;
const initialPlatformType = campaignUtils.campaignPlatformsTypeState.map(({ name }) => name);

const CampaignCreateForm: React.FC<ICampaignCreateFormProps> = ({
    isLoading,
    categories, selectedCategoriesIds,
    onCreateBegun, onCancelClick, onCreateClick,
    onDeleteTrackedSource, needClear,
    onCleared, onClearAllSelectedSources,
    isIPadSize, onCreateSearchRequest, isSearchRequest,
    checkedSearchRequest, searchRequestStr,
    onSelectedFavoriteSources, onGetPreviewPosts,
    previewPosts, isLoadingPreviewPosts,
    isLoadingCategories,
}): JSX.Element => {
    const { ref, height: rootHeight } = useResizeObserver<HTMLDivElement>();

    const [isErrorHighlighting, setHighlighting] = useState<boolean>(false);
    const [selectedCategories, setSelectedCategories] = useState<ICampaignCategoryModel[]>([]);
    const [campaignName, setCampaignName] = useState<string>('');
    const [isBlogger, setIsBlogger] = useState<boolean>(initialMediaType);
    const [sourceType, setSourceType] = useState<string[]>(initialPlatformType);
    const [isTrackedLinks, setTrackedLinksState] = useState<boolean>(true);
    const [trackedLinks, setTrackedLinks] = useState<ITrackedWordModel[]>([]);
    const [excludedLinks, setExcludedLinks] = useState<ITrackedWordModel[]>([]);
    const [trackedWords, setTrackedWords] = useState<ITrackedWordModel[]>([]);
    const [excludedWords, setExcludedWords] = useState<ITrackedWordModel[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<string>(initialPeriod);
    const [isCreateBegun, setCreateBegun] = useState<boolean>(false);
    const [expandPostsPreview, setExpandPostsPreview] = useState<boolean>(false);
    const [expandedSinglePosts, setExpandedSinglePosts] = useState<number[]>([]);

    const filteredPreviewPosts = useMemo<IPostsPreview[]>(() => previewPosts.map((item) => (
        {
            ...item,
            contentExpanded: expandedSinglePosts.includes(item?.post_id),
            last_update: `${helpers.dateTimeToTime(item?.last_update)} ${helpers.dateTimeToDate(item?.last_update)}`,
            posted: `${helpers.dateTimeToTime(item?.posted)} ${helpers.dateTimeToDate(item?.posted)}`,
        }
    )), [previewPosts, expandedSinglePosts]);

    const handlerCreateBegun = (): void => {
        onCreateBegun();
        setCreateBegun(true);
    };
    const handlerChangeName = (value: string): void => {
        handlerCreateBegun();
        setCampaignName(value);
    };
    const handlerDeleteCategory = (id: number): void => {
        handlerCreateBegun();
        setSelectedCategories([...selectedCategories.filter((item) => item.id !== id)]);
    };
    const handlerSelectCategory = (id: number): void => {
        handlerCreateBegun();
        const category = categories.find((item) => item.id.toString() === id.toString());
        if (category && !selectedCategories.some((item) => item.id === id)) {
            setSelectedCategories([...selectedCategories, { id, name: category.mainCat }]);
        }
    };
    const handlerCancelClick = (): void => {
        onCancelClick();
    };
    const handlerSetBlogger = (data: ICampaignSimpleEntity[]): void => {
        handlerCreateBegun();
        setIsBlogger(getBloggerValue(data.filter(({ checked }) => checked).map(({ id }) => id)));
    };
    const handlerSetSourceType = (data: ICampaignSimpleEntity[]): void => {
        handlerCreateBegun();
        setSourceType(
            data
                .filter(({ checked }) => checked)
                .map(
                    ({ id }) => campaignUtils.campaignPlatformsTypeState
                        .find((item) => item.id === id).value.toString(),
                ),
        );
    };
    const handlerExpandPreviewPosts = (): void => {
        setExpandPostsPreview(false);
    };
    const handlerExpandPostPreview = (value: IPostsPreview): void => {
        if (!expandedSinglePosts.includes(value.post_id)) {
            setExpandedSinglePosts([...expandedSinglePosts, value.post_id]);
        } else {
            setExpandedSinglePosts(expandedSinglePosts.filter((id) => id !== value.post_id));
        }
    };

    const handlerDeleteWords = useCallback((
        setFunc: React.Dispatch<React.SetStateAction<ITrackedWordModel[]>>,
        data: ITrackedWordModel[],
    ) => (id: number, isSource: boolean): void => {
        setFunc([...data.filter((item) => item.id !== id)]);
        if (isSource) {
            onDeleteTrackedSource(isTrackedLinks, id);
        }
        handlerCreateBegun();
    }, [isTrackedLinks]);
    const handlerAddWords = (
        setFunc: React.Dispatch<React.SetStateAction<ITrackedWordModel[]>>,
        data: ITrackedWordModel[],
    ) => (value: string, inputId: number): void => {
        const newId = data?.length ? (Math.max(...data.map(({ id }) => id)) + 1) : 1;
        setFunc([...data, { id: inputId ?? newId, name: value }]);
        handlerCreateBegun();
    };

    const getLinks = (isInclude: boolean = true): string[] => {
        if (isTrackedLinks && isInclude) {
            return trackedLinks?.length <= 0 ? [] : trackedLinks.map((item) => item.name);
        }
        if (!isTrackedLinks && !isInclude) {
            return excludedLinks?.length <= 0 ? [] : excludedLinks.map((item) => item.name);
        }
        return [];
    };
    const saveBtnDisabled = useMemo((): boolean => {
        if (isBlogger === undefined) {
            return true;
        }
        if (!campaignName) {
            return true;
        }
        if ((isSearchRequest && !searchRequestStr) || !isSearchRequest) {
            if (!trackedWords?.length && !excludedWords?.length) {
                return true;
            }
        }
        if (!sourceType?.length) {
            return true;
        }
        return false;
    }, [
        campaignName,
        isBlogger,
        trackedWords,
        excludedWords,
        sourceType,
        isSearchRequest,
        searchRequestStr,
    ]);

    const prepareCreateData = (): ICampaignDataModel => ({
        isBlogger,
        sourceType: prepareSource(sourceType),
        name: campaignName,
        id: null,
        excludeLinks: getLinks(false),
        excludeWords: excludedWords?.length <= 0 ? [] : excludedWords.map((item) => item.name),
        includeWords: trackedWords?.length <= 0 ? [] : trackedWords.map((item) => item.name),
        parseFrom: campaignUtils.getCampaignParseFromDate(selectedPeriod),
        categoriesIds: selectedCategories.length <= 0 ? [0] : selectedCategories.map((item) => item.id),
        isActive: false,
        userId: '',
        createTimestamp: '',
        createDate: '',
        owner: null,
        sources: [],
        excludeSources: [],
        noDelete: false,
        isTrackedLinks,
        parseFromDate: null,
        searchQuery: '',
        isComplexQuery: isSearchRequest,
    });

    const handlerCreateClick = (): void => {
        if (saveBtnDisabled) {
            setHighlighting(true);
            return;
        }
        const createDataModel: ICampaignDataModel = prepareCreateData();
        onCreateClick(createDataModel);
    };
    const handlerGetPreviewPosts = (): void => {
        if (saveBtnDisabled) {
            setHighlighting(true);
            return;
        }
        const createDataModel: ICampaignDataModel = prepareCreateData();
        onGetPreviewPosts(createDataModel);
        setExpandPostsPreview(true);
    };

    const handlerSelectPeriod = useCallback(
        (id: string) => {
            setSelectedPeriod(id);
            handlerCreateBegun();
        },
        [setSelectedPeriod],
    );
    const scrollContentHeight = useMemo((): number | string => (
        rootHeight ? (rootHeight - topComponentsHeight) : '100%'
    ), [rootHeight]);
    const validationTrackWords = useMemo((): boolean => (
        isErrorHighlighting && !(trackedWords?.length > 0) && !(excludedWords?.length > 0)
    ), [isErrorHighlighting, trackedWords, excludedWords]);
    const validationSearchRequest = useMemo((): boolean => (
        isErrorHighlighting && ((isSearchRequest && !searchRequestStr) || !isSearchRequest)
    ), [isErrorHighlighting, isSearchRequest, searchRequestStr]);

    useEffect(() => {
        if (!categories?.length || !selectedCategoriesIds?.length) {
            return;
        }
        setSelectedCategories(prepareCategories(selectedCategoriesIds, categories));
    }, [categories, selectedCategoriesIds]);
    useEffect(() => {
        if (!saveBtnDisabled && isErrorHighlighting) {
            setHighlighting(false);
        }
    }, [saveBtnDisabled]);
    useEffect(() => {
        if (needClear) {
            setCampaignName('');
            setSelectedCategories([]);
            setSelectedPeriod(initialPeriod);
            setIsBlogger(initialMediaType);
            setSourceType(initialPlatformType);
            setTrackedWords([]);
            setTrackedLinks([]);
            setExcludedWords([]);
            setExcludedLinks([]);
            onClearAllSelectedSources();
            onCleared();
            setCreateBegun(false);
        }
    }, [needClear]);
    useEffect(() => {
        if (onSelectedFavoriteSources) {
            onSelectedFavoriteSources([...trackedLinks]);
        }
    }, [trackedLinks]);

    return (
        <div className={styles.campaignCreateForm_root} ref={ref}>
            <div className={styles.nameContainer}>
                <CampaignNameInput
                    value={campaignName}
                    placeholder="Наименование кампании"
                    type="text"
                    setValue={handlerChangeName}
                    tagId="campaign-name"
                    isErrorHighlighting={isErrorHighlighting && !campaignName}
                    parentValidationIsRelative={false}
                    isView={false}
                    isLoading={isLoading}
                    isIPadSize={isIPadSize}
                />
            </div>
            <div style={{ height: scrollContentHeight }}>
                <div className={classnames(styles.subRoot, styles.scrollWrapper)}>
                    <CampaignCategorySelect
                        categories={categories}
                        selectedCategories={selectedCategories}
                        onSelectCategory={handlerSelectCategory}
                        onDeselectCategory={handlerDeleteCategory}
                        isView={false}
                        isLoading={isLoadingCategories}
                    />
                    <div className={styles.titleBlock}>Ключевые слова</div>
                    <div className={styles.wordsWrapper} style={isIPadSize ? { width: 820 } : null}>
                        <div className={styles.flexColumn}>
                            <div className={styles.searchRadioBtn}>
                                Поиск:
                            </div>
                            <div className={styles.switchContainer}>
                                <CampaignSwitcher
                                    label="Обычный"
                                    isInverse
                                    isActive={isSearchRequest}
                                    onSwitch={() => checkedSearchRequest(false)}
                                    isDisabled={false}
                                />
                                <CampaignSwitcher
                                    label="Расширенный"
                                    isInverse={false}
                                    isActive={isSearchRequest}
                                    onSwitch={() => checkedSearchRequest(true)}
                                    isDisabled={false}
                                />
                            </div>
                            {
                                isSearchRequest && (
                                    <div className={styles.searchRequest_container}>
                                        <button
                                            type="button"
                                            className={styles.searchRequest_btn}
                                            onClick={onCreateSearchRequest}
                                        >
                                            <span className={styles.searchRequest_text}>
                                                {
                                                    searchRequestStr
                                                        ? 'Изменить запрос'
                                                        : 'Добавить запрос'
                                                }
                                            </span>
                                        </button>
                                        {
                                            !searchRequestStr && (
                                                <button
                                                    type="button"
                                                    className={classnames(styles.searchRequest_btn, styles.searchRequest_btnCircle)}
                                                    onClick={onCreateSearchRequest}
                                                >
                                                    <SvgIcon id="Add_PLUS" />
                                                </button>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                !isSearchRequest && (
                                    <>
                                        <ValidationTooltip
                                            isVisible={validationTrackWords && validationSearchRequest}
                                            left={100}
                                            top={65}
                                            width={230}
                                            text="Пожалуйста, выберите тип слов"
                                        >
                                            {/* <div className={styles.title}>Тип слов*:</div> */}
                                            <div className={styles.title} />
                                        </ValidationTooltip>
                                        <div className={styles.flexRow} style={isIPadSize ? { gap: 32 } : null}>
                                            <CampaignTrackedWordsBlock
                                                inputTagId="campaign-trackedwords"
                                                addBtnTagId="campaign-trackedwords-add"
                                                delBtnTagId="campaign-trackedwords-del"
                                                addFavoriteTagId="campaign-trackedwords-favorite"
                                                isDisabled={false}
                                                verifiable={false}
                                                isErrorHighlighting={validationTrackWords}
                                                isView={false}
                                                title="Отслеживаемые*:"
                                                placeholder="Введите текст"
                                                words={trackedWords}
                                                onDeleteWord={handlerDeleteWords(setTrackedWords, trackedWords)}
                                                onAddWord={handlerAddWords(setTrackedWords, trackedWords)}
                                                withSearch={false}
                                                sourceType={sourceType}
                                                fullScreen={excludedWords?.length <= 0}
                                                isTrackedSources={null}
                                                isIPadSize={isIPadSize}
                                            />
                                            <CampaignTrackedWordsBlock
                                                inputTagId="campaign-excludedwords"
                                                addBtnTagId="campaign-excludedwords-add"
                                                delBtnTagId="campaign-excludedwords-del"
                                                isDisabled={false}
                                                verifiable={false}
                                                isErrorHighlighting={validationTrackWords}
                                                isView={false}
                                                title="Исключаемые*:"
                                                placeholder="Введите текст"
                                                words={excludedWords}
                                                onDeleteWord={handlerDeleteWords(setExcludedWords, excludedWords)}
                                                onAddWord={handlerAddWords(setExcludedWords, excludedWords)}
                                                withSearch={false}
                                                sourceType={sourceType}
                                                fullScreen={trackedWords?.length <= 0}
                                                isTrackedSources={null}
                                                isIPadSize={isIPadSize}
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className={styles.titleBlock} style={{ width: 114 }}>Источники</div>
                    <div className={styles.wordsWrapper} style={isIPadSize ? { width: 820 } : null}>
                        <CampaignSourcesBlock
                            checkedSourceTypeIds={sourcesStringToNumber(sourceType)}
                            checkedBloggerIds={bloggersBoolToArray(isBlogger)}
                            handlerSetBlogger={handlerSetBlogger}
                            handlerSetSourceType={handlerSetSourceType}
                            isErrorHighlighting={isErrorHighlighting}
                            isView={false}
                            isIPadSize={isIPadSize}
                        />
                        <div className={styles.flexRow} style={isIPadSize ? { gap: 32 } : null}>
                            <div className={styles.flexColumn}>
                                <div className={styles.title} style={{ marginTop: -8 }}>Тип источника:</div>
                                <div className={styles.switchContainer}>
                                    <CampaignSwitcher
                                        label="Отслеживаемые"
                                        isInverse={false}
                                        isActive={isTrackedLinks}
                                        onSwitch={setTrackedLinksState}
                                        isDisabled={false}
                                    />
                                    <CampaignSwitcher
                                        label="Исключаемые"
                                        isInverse
                                        isActive={isTrackedLinks}
                                        onSwitch={setTrackedLinksState}
                                        isDisabled={false}
                                    />
                                </div>
                                <div className={styles.flexRow}>
                                    <CampaignTrackedWordsBlock
                                        inputTagId="campaign-source-link"
                                        addBtnTagId="campaign-source-link-add"
                                        delBtnTagId="campaign-source-link-del"
                                        addFavoriteTagId="campaign-source-link-favorite"
                                        isDisabled={!isTrackedLinks}
                                        verifiable
                                        isErrorHighlighting={false}
                                        isView={false}
                                        title="Отслеживаемые источники:"
                                        placeholder="Введите краткое описание"
                                        words={trackedLinks}
                                        onDeleteWord={handlerDeleteWords(setTrackedLinks, trackedLinks)}
                                        onAddWord={handlerAddWords(setTrackedLinks, trackedLinks)}
                                        withSearch
                                        sourceType={sourceType}
                                        fullScreen={false}
                                        isTrackedSources={isTrackedLinks}
                                        isIPadSize={isIPadSize}
                                    />
                                    <CampaignTrackedWordsBlock
                                        inputTagId="campaign-source-excl-link"
                                        addBtnTagId="campaign-source-excl-link-add"
                                        delBtnTagId="campaign-source-excl-link-del"
                                        isDisabled={isTrackedLinks}
                                        verifiable
                                        isErrorHighlighting={false}
                                        isView={false}
                                        title="Исключаемые источники:"
                                        placeholder="Введите ссылку"
                                        words={excludedLinks}
                                        onDeleteWord={handlerDeleteWords(setExcludedLinks, excludedLinks)}
                                        onAddWord={handlerAddWords(setExcludedLinks, excludedLinks)}
                                        withSearch
                                        sourceType={sourceType}
                                        fullScreen={false}
                                        isTrackedSources={isTrackedLinks}
                                        isIPadSize={isIPadSize}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: -8 }}>
                                {/* <CampaignSourceLinkInfo /> */}
                            </div>
                        </div>
                    </div>
                    <div className={classnames(styles.titleBlock)} style={{ width: 390 }}>Сбор информации на предыдущий период</div>
                    <div>
                        <SimpleSelectItem
                            tagId="campaign-period"
                            onSelect={handlerSelectPeriod}
                            selectedId={selectedPeriod}
                            defaultTitle="Период"
                            list={campaignPeriodsMock}
                            isRetinaSize={false}
                            isDisabled={false}
                            notShadow
                        />
                    </div>
                    <div>
                        <div className={styles.postPreview_controlsBox}>
                            <button type="button" onClick={handlerGetPreviewPosts}>
                                <span
                                    className={`${styles.postPreview_text} ${saveBtnDisabled ? styles.postPreview_disabled : ''}`}
                                >
                                    Предварительный просмотр сообщений
                                </span>
                            </button>
                            {
                                expandPostsPreview && (
                                    <div style={{ display: 'flex', gap: 30 }}>
                                        <span className={styles.postPreview_controlsBox_hint}>
                                            {`(доступно ${previewPosts?.length} сообщений)`}
                                        </span>
                                        <button
                                            className={styles.postPreview_controlsBox_hint}
                                            type="button"
                                            onClick={handlerExpandPreviewPosts}
                                        >
                                            Скрыть
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                        {
                            expandPostsPreview && (
                                <div className={styles.postPreview_list}>
                                    {
                                        isLoadingPreviewPosts && (
                                            <div>
                                                <Skeleton width={600} height={150} />
                                                <Skeleton width={600} height={150} />
                                            </div>
                                        )
                                    }
                                    {
                                        !isLoadingPreviewPosts && filteredPreviewPosts?.map((item) => (
                                            <div key={item.post_id} className={styles.postPreview_box}>
                                                <div className={styles.postPreview_box_header}>
                                                    <SvgIcon id={PlatformsIcon.get(item.source_type)} />
                                                    <div className={styles.postPreview_box_headerSubBox}>
                                                        <SvgIcon id="Person" />
                                                        <span className={styles.postPreview_authorName}>{item.author_name}</span>
                                                    </div>
                                                    <div className={styles.postPreview_box_headerSubBox} style={{ minWidth: 140 }}>
                                                        <SvgIcon id="Calendar" />
                                                        <span className={styles.postPreview_dateText}>{item.posted}</span>
                                                    </div>
                                                    <div className={styles.postPreview_box_headerSubBox}>
                                                        <SvgIcon id="LinkIcon" size={12} />
                                                        <a
                                                            className={styles.postPreview_postLink}
                                                            target="_blank"
                                                            href={item.post_link || '-'}
                                                            rel="noreferrer"
                                                        >
                                                            пост
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className={styles.postPreview_box_content}>
                                                    <div className={styles.postPreview_titleText}>
                                                        {item.title}
                                                    </div>
                                                    <div className={styles.postPreview_contentText}>
                                                        {item?.contentExpanded ? item.content : `${item.content.slice(0, 150)}...`}
                                                    </div>
                                                </div>
                                                <div className={styles.postPreview_box_footer}>
                                                    <button
                                                        type="button"
                                                        style={{ display: 'flex', gap: 6 }}
                                                        onClick={() => handlerExpandPostPreview(item)}
                                                    >
                                                        <span className={styles.postPreview_postLink}>
                                                            {
                                                                item.contentExpanded ? 'Свернуть' : 'Читать дальше'
                                                            }
                                                        </span>
                                                        <div style={item.contentExpanded ? { transform: 'rotate(180deg)' } : null}>
                                                            <SvgIcon id="ArrowDownBlue" />
                                                        </div>
                                                    </button>
                                                    <span className={styles.postPreview_dateFooter}>
                                                        {`Обновлено ${item.last_update}`}
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.pinnedInfo}>
                    <div className={classnames(styles.label, styles.small)}>* Поля обязательные к заполнению</div>
                </div>
                <div className={styles.buttonsContainer}>
                    {
                        isCreateBegun && (
                            <Button
                                tagId="campaign-cancel"
                                text="Очистить"
                                type="secondary"
                                size="large"
                                onClick={handlerCancelClick}
                            />
                        )
                    }
                    <Button
                        tagId="campaign-save"
                        text="Сохранить"
                        type="default"
                        size="large"
                        onClick={handlerCreateClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default CampaignCreateForm;
