import React, { useCallback, useMemo, useState } from 'react';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import SvgIcon from '@components/SvgIcon';
import { IFilterCampaigns } from '@store/campaign/comparison';
import styles from './styles.module.scss';
import { PlatformType } from '../../types';

const platformTypeMap = new Map(
    Object.keys(PlatformType)
        .slice(Object.keys(PlatformType).length / 2, Object.keys(PlatformType).length)
        .map((value, index) => [index, value]),
);

interface IElement {
    id: number;
    name: string;
    percent: number;
    quantity: number;
    quantityStr: string;
}

interface IPlatformComponentModel {
    platformType: PlatformType;
    data: IElement[];
}

interface IProps {
    tagId: string;
    filters: IFilterCampaigns[];
    referenceCampaign: ICampaignDataModel | null;
    compareCampaigns: ICampaignDataModel[];
}
const handleKeyDown = (): void => {
    // eslint-disable-next-line no-console
};
const arraySvgIcon = ['RssLogo', 'VkLogo', 'OkLogo', 'TgLogo'];
const filterType = 'Типы платформ';
const title = 'Платформы';

const CampaignComparisonPlatforms: React.FC<IProps> = ({
    tagId,
    filters,
    referenceCampaign,
    compareCampaigns,
}): JSX.Element => {
    const findCampaignAndFilters = useCallback((campaignId: number, type: string, filterName: string): number => {
        const result = (filters
            .find(({ campaignId: itemId }) => itemId === campaignId)?.filters
            .find((item) => item.filtersCategory === type)?.filters
            .find(({ filter_name }) => filter_name === filterName)?.count) ?? 0;
        return (Number.isNaN(result) ? 0 : result);
    }, [filters]);

    const calculatePercent = (dividend : number, divisor: number) => {
        if (divisor === 0) {
            return dividend;
        }
        return Math.round(((dividend ?? 0) / divisor) * 10000) / 100;
    };
    const arrObj = useMemo<IPlatformComponentModel[]>(
        () => {
            const result = [];
            platformTypeMap.forEach((value, key) => {
                result.push({
                    platformType: key,
                    data: [
                        {
                            id: referenceCampaign.id,
                            name: referenceCampaign.name,
                            percent: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantity: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantityStr: findCampaignAndFilters(referenceCampaign.id, filterType, value)
                                .toLocaleString('ru-RU'),
                        } as IElement,
                        ...compareCampaigns.map(({ id: itemId, name }) => {
                            const quantity = findCampaignAndFilters(itemId, filterType, value) ?? 0;
                            return {
                                id: itemId,
                                name,
                                percent: calculatePercent(quantity, findCampaignAndFilters(referenceCampaign.id, filterType, value)),
                                quantity,
                                quantityStr: quantity.toLocaleString('ru-RU'),
                            } as IElement;
                        }),
                    ],
                } as IPlatformComponentModel);
            });
            return result;
        },
        [referenceCampaign, compareCampaigns],
    );

    const calculationOfPercentageDifference = (percent): string => {
        if (!percent || Number.isNaN(percent)) return ' - ';
        if (!(percent - 100)) return '0%';
        if (percent - 100 < 100) return `${(percent - 100)?.toLocaleString('ru-RU')}%`;
        return `${(percent)?.toLocaleString('ru-RU')}%`;
    };
    const [isOpen, setIsOpen] = useState<boolean>(true);

    return (
        <div className={styles.dropDownPlatform}>
            <div className={styles.dropDownPlatform_lineStart}>
                <div className={styles.dropDownPlatform_name}>{title}</div>
                <div
                    id={tagId}
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    onClick={() => setIsOpen(!isOpen)}
                    className={styles.dropDownPlatform_arrow}
                >
                    <div style={isOpen ? { transform: ' rotate(0)' } : { transform: ' rotate(180deg)' }}>
                        <SvgIcon id="DropDownArrow" size={17} height={12} />
                    </div>
                </div>
            </div>
            {
                isOpen && (
                    <div className={styles.dropDownPlatform_list}>
                        <div className={styles.dropDownPlatform_title}>
                            <img src="" alt="" className={styles.dropDownPlatform_first} />
                            {
                                arrObj[0].data.map(({ id, name }, index) => (
                                    <div
                                        key={`name_${id}`}
                                        className={styles.dropDownPlatform_cell}
                                        style={!index ? { color: '#0ebfa1' } : null}
                                    >
                                        {name}
                                    </div>
                                ))
                            }
                        </div>
                        {
                            arrObj.map((item, indexArrObj) => (
                                <>
                                    <div key={`quantity_column_${item.platformType}`} className={styles.dropDownPlatform_info}>
                                        <div className={styles.dropDownPlatform_first}>
                                            <SvgIcon id={arraySvgIcon[indexArrObj]} />
                                        </div>
                                        {
                                            item.data.map((element) => (
                                                <div
                                                    key={`quantity_${element.id}`}
                                                    className={styles.dropDownPlatform_cell}
                                                >
                                                    {element.quantityStr}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div key={`percent_column_${item.platformType}`} className={styles.dropDownPlatform_percent}>
                                        <img src="" alt="" className={styles.dropDownPlatform_first} />
                                        {
                                            item.data.map((element, index) => {
                                                if (!index) return <div className={styles.dropDownPlatform_cell} />;
                                                if (!arrObj[indexArrObj].data[0].quantity) {
                                                    return (
                                                        <div
                                                            key={element.id}
                                                            className={styles.dropDownPlatform_cell}
                                                            style={element.percent ? { color: 'green' } : { color: 'red' }}
                                                        >
                                                            {element.percent ? `${element.percent * 100}%` : ' - '}
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div
                                                        key={`percent_${element.id}`}
                                                        className={styles.dropDownPlatform_cell}
                                                        style={element.percent >= 100 ? { color: 'green' } : { color: 'red' }}
                                                    >
                                                        {calculationOfPercentageDifference(element.percent)}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default CampaignComparisonPlatforms;
