import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import useGetUserRole from '@shared/hooks/campaign/useGetUserRole';
import { ICampaignUserRole } from '@store/campaign/userRights/types';
import styles from '../../../../components/campaign/UpdateForm/styles.module.scss';
import campaignSelectors from '../../../../store/campaign/source/selectors';
import CampaignStatus from '../../../../components/campaign/Menu/CampaignStatus';
import { FetchStatus } from '../../../../store/types';
import 'react-loading-skeleton/dist/skeleton.css';
import { singleCampaignRtkQService } from '../../../../services/campaigns/single-campaign';

interface ICampaignStatusProps {
    isActive: boolean;
    campaignId: number;
    isView: boolean;
}

const CampaignStatusWithSkeletonContainer: React.FC<ICampaignStatusProps> = ({
    isActive,
    campaignId,
    isView,
}): JSX.Element => {
    const isUpdate = useSelector(campaignSelectors.updateCampaignStatus);
    const userRole = useGetUserRole();

    const [
        getCampaign,
        {
            data: campaign,
            isLoading: isLoadingCampaignRtkQ,
        },
    ] = singleCampaignRtkQService.useLazyGetCampaignQuery();

    const [changeCampaignStatus] = singleCampaignRtkQService.usePostChangeCampaignStatusMutation();

    const handlerActivateCampaign = (value: boolean): void => {
        if (campaign) {
            changeCampaignStatus({ id: campaignId, status: value });
        }
    };
    const componentIsLoading = useMemo(
        () => !campaign || isLoadingCampaignRtkQ || isUpdate === FetchStatus.update,
        [campaign, isLoadingCampaignRtkQ, isUpdate, FetchStatus],
    );
    useEffect(() => {
        if (campaignId > 0 && campaign?.id !== campaignId) {
            getCampaign({ id: campaignId });
        }
    }, [campaignId, campaign]);

    return (
        <>
            {
                componentIsLoading && <Skeleton width={250} height={25} />
            }
            {
                !componentIsLoading && (
                    <div
                        className={classnames(
                            styles.nameContainer_status,
                            styles[isActive ? 'activeStatus' : 'inactiveStatus'],
                        )}
                    >
                        <span>
                            Статус кампании:
                        </span>
                        <CampaignStatus
                            isActive={campaign?.isActive}
                            isLoading={componentIsLoading}
                            disabled={isView || userRole === ICampaignUserRole.readOnly}
                            onChangeStatus={handlerActivateCampaign}
                        />
                    </div>
                )
            }
        </>
    );
};

export default CampaignStatusWithSkeletonContainer;
