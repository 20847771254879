import request from '../requestWrapper';

// export const getCampaigns = async () => request({
//     method: 'GET',
//     url: 'campaign/',
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const getSingleCampaign = async (campaignId) => request({
//     method: 'GET',
//     url: `campaign/${campaignId}`,
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const deleteSingleCampaign = async (campaignId) => request({
//     method: 'DELETE',
//     url: `campaign/${campaignId}`,
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const changeCampaignStatus = async (
//     id,
//     curStatus,
//     newStatus,
// ) => request({
//     method: 'POST',
//     url: `campaign/${id}/status`,
//     data: {
//         cur_status: curStatus,
//         new_status: newStatus,
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const createCampaign = async (
//     name,
//     parseFrom,
//     categories,
//     includeWords,
//     excludeWords,
//     sourceType,
//     isBlogger,
//     sources,
//     isTrackedLinks,
// ) => request({
//     method: 'POST',
//     url: 'campaign/',
//     data: {
//         name,
//         parse_from: parseFrom,
//         criteria: {
//             categories,
//             include_words: includeWords,
//             exclude_words: excludeWords,
//             source_type: sourceType,
//             is_blogger: isBlogger,
//             // source_links: sourceLinks,
//             sources: isTrackedLinks ? sources : [],
//             exclude_sources: isTrackedLinks ? [] : sources,
//         },
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const addUserCampaign = async (campaignId, email) => request({
//     method: 'POST',
//     url: `campaign/${campaignId}/user`,
//     data: {
//         email,
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const loadCampaignUsers = async (
//     campaignId,
// ) => request({
//     method: 'GET',
//     url: `campaign/${campaignId}/user`,
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const checkSourceLink = async (
//     link,
// ) => request({
//     method: 'GET',
//     url: 'campaign/source/check_source',
//     data: {
//         body: {
//             link,
//         },
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

// export const updateCampaign = async (
//     id,
//     name,
//     parseFrom,
//     categories,
//     includeWords,
//     excludeWords,
//     sourceType,
//     isBlogger,
//     sources,
//     isTrackedLinks,
// ) => request({
//     method: 'PATCH',
//     url: `campaign/${id}`,
//     data: {
//         name,
//         criteria: {
//             categories,
//             include_words: includeWords,
//             exclude_words: excludeWords,
//             source_type: sourceType,
//             is_blogger: isBlogger,
//             sources: isTrackedLinks ? sources : [],
//             exclude_sources: isTrackedLinks ? [] : sources,
//         },
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

export const loadCampaignsNotificationsDataApi = async () => request({
    method: 'GET',
    url: 'notify/',
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

// export const loadCampaignNotificationStatusApi = async (
//     campaignId,
// ) => request({
//     method: 'POST',
//     url: 'notify/',
//     data: {
//         campaign_id: campaignId,
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

export const subscribeToNotificationsApi = async (
    campaignId,
) => request({
    method: 'GET',
    url: `notify/tg/${campaignId}`,
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

export const setCampaignNotificationStatusApi = async (
    campaignId,
    enabled,
) => request({
    method: 'PUT',
    url: `notify/${campaignId}`,
    data: {
        enabled,
    },
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

// export const getCampaignSummary = async (
//     campaignId,
//     fromDate: string,
//     toDate: string,
// ) => request({
//     method: 'POST',
//     url: `campaign/${campaignId}/campaign_agg`,
//     data: {
//         from_date: fromDate,
//         to_date: toDate,
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });

export const getSourcesForSearchLink = async (
    searchString: string,
    platforms: string[],
) => request({
    method: 'POST',
    url: 'source/search',
    data: {
        pattern: searchString,
        platform: platforms,
    },
}, {
    headers: {},
    isAuthRequest: false,
    isNotHandleErrors: true,
});

// export const deleteUserCampaign = async (campaignId, userId) => request({
//     method: 'DELETE',
//     url: `campaign/${campaignId}/user`,
//     data: {
//         user_id: userId,
//     },
// }, {
//     headers: {},
//     isAuthRequest: false,
//     isNotHandleErrors: true,
// });
