import { IAnalyzeResultState } from './types';

export const convertApiToAnalyzeResult = (data: unknown): IAnalyzeResultState => {
    const result = {} as IAnalyzeResultState;
    if (!data) {
        return result;
    }
    try {
        Object.keys((data)).forEach((key) => {
            switch (key) {
                case 'ml_result':
                    result.mlResult = {
                        analytics: data[key]?.analytics,
                        quotations: data[key]?.quotations,
                        authors: data[key]?.authors,
                    };
                    break;
                case 'task_id':
                    result.taskId = data[key];
                    break;
                case 'posted_timestamp':
                    result.postedTimestamp = data[key];
                    break;
                case 'title':
                    result.title = data[key];
                    break;
                case 'post_link':
                    result.link = data[key];
                    break;
                case 'source':
                    result.source = data[key];
                    break;
                case 'cleared_text':
                case 'content':
                    result.content = data[key];
                    break;
                default:
                    break;
            }
        });
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('convertApiToAnalyzeResult', error);
        return {} as IAnalyzeResultState;
    }

    return result;
};
