import { rtkQApi } from '../../index';
import {
    IQueryParamsTonality,
    IQueryParamsReportAggs,
    IQueryParamsCampaingFilters,
    IQueryParamsCampaingPosts,
} from './types';
import {
    ITonalitySourceModel,
    IReportAggSourceModel,
    IFilterSourceModel,
    PostsSourceModel,
} from './models';

export const trackingCampaingRtqService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getTonality: build.query<ITonalitySourceModel[], IQueryParamsTonality>({
            query: ({
                filters_include, campaign_id, from_date, to_date,
            }) => ({
                url: `campaign/${campaign_id}/tonality`,
                method: 'POST',
                body: {
                    filters_include,
                    from_date,
                    to_date,
                },
            }),
        }),
        getReportAgg: build.query<IReportAggSourceModel, IQueryParamsReportAggs>({
            query: ({
                campaign_id, from_date, to_date, filters_include,
            }) => ({
                url: `campaign/${campaign_id}/campaign_agg`,
                method: 'POST',
                body: {
                    from_date,
                    to_date,
                    filters_include,
                },
            }),
        }),
        getFilters: build.query<IFilterSourceModel, IQueryParamsCampaingFilters>({
            query: (body) => ({
                url: 'campaign/filters',
                method: 'POST',
                body,
            }),
        }),
        getPosts: build.query<PostsSourceModel, IQueryParamsCampaingPosts>({
            query: ({ data, campaign_id }) => ({
                url: `campaign/${campaign_id}/posts`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});
