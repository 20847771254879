import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import SvgIcon from '@components/SvgIcon';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';
import { IMediaPlaneTable } from '@widgets/project/MediaPlan/types';
import {
    EditDate, EditText, IconButtonWithLabel, ModalXlsxExport, SourceCell, Table,
} from '@entities/index';
import { ICustomTableColumn } from '@entities/TableMediaPlan/types';
import { IColumnProperties } from '@store/common/tableProperties/types';
import classnames from 'classnames';
import ColumnsViewer from '@entities/Table/ColumnsViewer';
import { useSelector } from 'react-redux';
import tablePropertiesSelectors from '@store/common/tableProperties/selectors';
import projectConstants from '@app/constants/projects';
import {
    ISourceRow, SortOrder, SourceFieldsEnum,
} from '@services/sources/types';
import { projectLonglistRtkQService } from '@services/projects/longlist';
import Button from '@shared/ui-components/buttons/Button';
import { IModalXlsxExportValue } from '@entities/ModalXlsxExport/types';
import { projectMediaPlanRtkQService } from '@services/projects/media-plan';
import ModalRemoveWithCommment from '@modals/ModalRemoveWithCommment';
import { sourcesRtkQService } from '@services/sources';
import { message } from 'antd';
import styles from './styles.module.scss';

const MAX_LENGTH_FOR_PROJECT_NAME = 60;

const initialModalExportValues: IModalXlsxExportValue[] = [
    { selected: true, name: 'Источник', value: SourceFieldsEnum.title },
    { selected: true, name: 'Цена', value: SourceFieldsEnum.price },
    { selected: true, name: 'Дата начала', value: SourceFieldsEnum.startDate },
    { selected: true, name: 'Дата завершения', value: SourceFieldsEnum.endDate },
    { selected: true, name: 'Текст', value: SourceFieldsEnum.content },
];

const getProjectNameForFile = (name: string): string => {
    if (!name) {
        return '-';
    }
    return name.length > MAX_LENGTH_FOR_PROJECT_NAME ? name.substring(0, MAX_LENGTH_FOR_PROJECT_NAME) : name;
};

const dateNow = new Date();
interface IProps {
    projectId: number;
    projectName: string;
    stage: number;
    monitoringIsError: boolean,
    onClickStartMonitoring: (sources: ISourceRow[]) => void
}

const MediaPlan: React.FC<IProps> = ({
    projectId,
    projectName = '',
    stage,
    monitoringIsError,
    onClickStartMonitoring,
}): JSX.Element => {
    const [modalExportVisible, setModalExportVisible] = useState<boolean>(false);
    const [modalExportValues, setModalExportVisibleValues] = useState<IModalXlsxExportValue[]>(initialModalExportValues);
    const [currentOpenCellId, setOpenCellId] = useState<string>('');
    const [isOnlyDeletedSources, setInOnlyDeletedSources] = useState<boolean>(false);
    const [modalForRemove, setModalForRemove] = useState<{ id: number, name: string }>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const success = (content) => {
        messageApi.open({
            type: 'success',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_succes,
        });
    };
    const error = (content) => {
        messageApi.open({
            type: 'error',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_error,
        });
    };
    const [
        loadSources,
        {
            data: sourcesMedia,
            isLoading: isLoadingSources,
        },
    ] = projectLonglistRtkQService.useLazyLoadSourcesForLonglistQuery();
    const [removeSource] = sourcesRtkQService.useRemoveSourcesFromMediaPlanMutation();
    const updateModalExportValues = (values: IModalXlsxExportValue[]): void => {
        setModalExportVisibleValues(values);
    };
    const [isVisibleViewer, setViewerVisibility] = useState<boolean>(false);
    const columnProperties = useSelector(tablePropertiesSelectors.mediaPlanColumnProperties);
    const [
        visibilityColumns,
        setColumnsVisibility,
    ] = useState<IColumnProperties[]>(() => (
        projectConstants.excelMediaplanColumns.map((item) => (
            // eslint-disable-next-line no-prototype-builtins
            columnProperties.hasOwnProperty(item.name) ? { ...columnProperties[item.name], title: item.title } : item
        ))
    ));
    const [isOpen, setOpen] = useState<boolean>(true);

    const visibleColumnsAmount = useMemo(() => (
        visibilityColumns.filter((item) => item.visible).length
    ), [visibilityColumns]);

    const [rows, setRows] = useState<IMediaPlaneTable[]>(
        () => (sourcesMedia?.sources ?? []).map((item) => ({
            id: item.id,
            sourceId: item.sourceId,
            source: item?.title ?? '-',
            sourceLink: item?.link,
            content: item?.is_deleted ? item?.comment : item?.content,
            price: item?.price,
            startDate: item?.startDate?.toLocaleDateString('ru-RU'),
            endDate: item?.endDate?.toLocaleDateString('ru-RU'),
            actions: false,
            integrationLink: item?.integrationLink,
            logoUrl: item?.logoUrl,
            isDeleted: item?.is_deleted,
        })),
    );

    /**
     * Все значения цен одинаковы
     */
    const allPricesAreSame = useMemo<boolean>(() => {
        if (rows?.length > 0) {
            return rows.every(({ price }) => rows[0]?.price === price);
        }
        return true;
    }, [rows]);

    const getFiltredRows = useMemo(() => {
        if (isOnlyDeletedSources) return rows.filter(({ isDeleted }) => isDeleted);
        return rows.filter(({ isDeleted }) => !isDeleted);
    }, [rows, isOnlyDeletedSources]);
    const [
        updateMediaPlanSource,
        { isLoading: isUpdatingContentLoading },
    ] = projectLonglistRtkQService.usePutUpdateMediaPlanSourceMutation();

    const updateMediaPlanSourceText = (sourceId: number, content: string, integrationLink: string): void => {
        const source = sourcesMedia?.sources.filter((item) => item.sourceId === sourceId)[0];
        setRows([]);
        updateMediaPlanSource({
            projectId,
            source: {
                ...source,
                content,
                integrationLink,
            },
        });
    };

    const handleSort = useCallback((column, value) => {
        if (column?.dataIndex === 'price' && allPricesAreSame) {
            return;
        }
        const filtred = rows.sort((a, b) => {
            if (a[column?.dataIndex] > b[column?.dataIndex]) return 1;
            if (a[column?.dataIndex] < b[column?.dataIndex]) return -1;
            return 0;
        });
        setRows(value === 'desc' ? filtred.reverse() : filtred);
    }, [rows]);

    const setActionRows = (id: number) => {
        setRows((prev) => prev.map((row) => ({ ...row, actions: row.id === id })));
    };
    const handleClickOnRemoveSource = (row) => {
        setModalForRemove({ id: row.id, name: row.source });
    };
    const canStartMonitoring = useMemo<boolean>(() => (
        rows.every((item) => Boolean(item.content)) && stage === 3
    ), [rows, stage]);

    const [downloadSources, { data: downloadedSourcesXlsx }] = projectMediaPlanRtkQService.useLazyPostDownloadSourcesQuery();

    const columns: ICustomTableColumn<IMediaPlaneTable>[] = useMemo(() => [
        {
            id: 1,
            title: null,
            dataIndex: 'id',
            width: 40,
            isIndex: true,
            icon: null,
        },
        {
            id: 2,
            title: 'Источник',
            dataIndex: 'source',
            width: 330,
            sortable: false,
            render: (value, record) => (
                <div style={{ padding: '6px 0' }}>
                    <SourceCell value={value} logoUrl={record?.logoUrl} sourceId={record?.sourceId} />
                </div>
            ),
            icon: null,
        },
        {
            id: 3,
            title: 'Цена',
            dataIndex: 'price',
            width: 130,
            sortable: true,
            sortType: 'number',
            render: (value) => (value?.toLocaleString('ru-RU') ?? 0),
            icon: null,
        },
        {
            id: 4,
            title: 'Дата начала',
            dataIndex: 'startDate',
            width: 165,
            render: (value) => (<EditDate text={value} />),
            sortable: false,
            icon: <SvgIcon id="Calendar" size={18} />,
        },
        {
            id: 5,
            title: 'Дата завершения',
            dataIndex: 'endDate',
            width: 205,
            render: (value) => (<EditDate text={value} />),
            sortable: false,
            icon: <SvgIcon id="Calendar" size={18} />,
        },
        {
            id: 6,
            title: 'Текст',
            dataIndex: 'content',
            width: 192,
            render: (value, record) => (
                <EditText
                    disabled={record.isDeleted}
                    cellId={`${record?.id}content`}
                    id={record?.id}
                    sourceId={record?.sourceId}
                    currentOpenId={currentOpenCellId}
                    text={value ?? ''}
                    integrationLink={record?.integrationLink ?? ''}
                    isText
                    setActionRows={setActionRows}
                    onChange={updateMediaPlanSourceText}
                    onOpen={(id: string) => setOpenCellId(id)}
                />
            ),
            icon: null,
        },
        {
            id: 7,
            title: 'Действия',
            dataIndex: 'actions',
            width: 105,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render: (_, row) => (
                <div
                    role="button"
                    className={styles.btnRemove}
                    onClick={() => handleClickOnRemoveSource(row)}
                >
                    <SvgIcon size={24} id="RemoveIcon" />
                </div>
            ),
            icon: null,
        },
    ], [rows]);

    const handleClickDownload = (values: IModalXlsxExportValue[]) => {
        const fields = values.reduce((acc, item) => {
            if (item.selected) {
                acc.push(item.value as SourceFieldsEnum);
            }
            return acc;
        }, []);
        downloadSources({
            id: projectId,
            set_type_id: 2,
            fields,
        });
        setModalExportVisible(false);
    };

    const tableStyle = useMemo<React.CSSProperties>(() => (
        visibleColumnsAmount === visibilityColumns?.length
            ? { width: '100%', overflowX: 'auto' }
            : { width: 'fit-content', overflowX: 'auto' }
    ), [visibilityColumns, visibleColumnsAmount]);

    useEffect(() => {
        if (downloadedSourcesXlsx) {
            const hiddenElement = document.createElement('a');
            const url = window.URL || window.webkitURL;
            const blobPDF = url.createObjectURL(downloadedSourcesXlsx);
            hiddenElement.href = blobPDF;
            hiddenElement.target = '_blank';
            hiddenElement.download = `Медиаплан ${getProjectNameForFile(projectName)} ${dateNow.toLocaleDateString('ru-RU')}.xlsx`;
            hiddenElement.click();
        }
    }, [downloadedSourcesXlsx, projectId, projectName]);

    const columnsToDisplay = useMemo(() => {
        const activeColumns = columns.filter((column) => (
            visibilityColumns.find((item) => item.name === column.dataIndex)?.visible
        ));
        if (isOnlyDeletedSources) return activeColumns.filter((column) => column.dataIndex !== 'actions');
        return activeColumns;
    }, [columns, visibilityColumns, isOnlyDeletedSources]);
    useEffect(() => {
        setRows((sourcesMedia?.sources ?? []).map((item) => ({
            id: item.id,
            source: item?.title ?? '-',
            sourceLink: item?.link,
            content: item?.is_deleted ? item?.comment : item?.content,
            price: item?.price,
            startDate: item?.startDate?.toLocaleDateString('ru-RU'),
            endDate: item?.endDate?.toLocaleDateString('ru-RU'),
            actions: false,
            sourceId: item.sourceId,
            integrationLink: item?.integrationLink,
            logoUrl: item?.logoUrl,
            isDeleted: item?.is_deleted,
        })));
    }, [sourcesMedia]);

    useEffect(() => {
        if (projectId && stage > 1) {
            loadSources({
                projectId,
                limit: 100,
                page: 1,
                set_type_id: 2,
                orderBy: 'subscribers',
                orderDirection: SortOrder.desc,
                filterParams: null,
                rangeParams: null,
            });
        }
    }, [projectId, stage]);
    const onRemoveSource = (comment: string) => {
        removeSource({ comment, project_id: projectId, source_id: modalForRemove.id }).then((res) => {
            if ((res as any).isError || (res as any)?.error) {
                error('При удалении источника из медиаплана произошла ошибка');
                return;
            }
            success('Источник успешно удален из медиаплана');
        })
            .catch(() => {
                error('При удалении источника из медиаплана произошла ошибка');
            });
        setModalForRemove(null);
    };
    if (!sourcesMedia?.sources?.length || stage < 3) return null;
    return (
        <DefaultWrapperProject style={{ marginTop: 16 }}>
            <div className={styles.mediaPlan_root}>
                {contextHolder}
                <ModalRemoveWithCommment
                    title="Удаление источника"
                    textContent="Для того чтобы удалить источник, оставьте комментарий"
                    isOpen={!!modalForRemove}
                    onClose={() => setModalForRemove(null)}
                    onAccept={onRemoveSource}
                />
                <div className={styles.mediaPlan_header}>
                    <span className={styles.mediaPlan_header_title}>Медиаплан</span>
                    <div className={styles.mediaPlan_controls}>
                        <div className={classnames(styles.flexRowVCenter, styles.gap8)}>
                            <button
                                type="button"
                                onClick={() => setViewerVisibility(!isVisibleViewer)}
                                className={styles.flexRowVCenter}
                            >
                                {isVisibleViewer ? <SvgIcon id="Views" size={24} /> : <SvgIcon id="Visibility" size={24} />}
                            </button>
                            <button
                                type="button"
                                onClick={() => setViewerVisibility(!isVisibleViewer)}
                                className={classnames(styles.btnSelectColumns, styles.text)}
                            >
                                {`${visibleColumnsAmount} колонок`}
                            </button>
                            <div
                                role="button"
                                className={`${styles.meidaPlan_deleted} ${isOnlyDeletedSources ? styles.isOnlyDeleted : ''}`}
                                onClick={() => {
                                    if (!sourcesMedia?.sources?.some((({ is_deleted }) => is_deleted))) return;
                                    setInOnlyDeletedSources((old) => !old);
                                }}
                            >
                                <span> Удаленные </span>
                                { isOnlyDeletedSources ? <SvgIcon id="Cross" size={14} /> : null }
                            </div>
                        </div>
                        {isVisibleViewer && (
                            <ColumnsViewer
                                selectedColumns={visibilityColumns}
                                setSelectedColumns={setColumnsVisibility}
                                onVisible={setViewerVisibility}
                            />
                        )}
                    </div>
                    <div className={styles.mediaPlan_header_actions}>
                        {
                            stage > 1 && !isOpen && (
                                <IconButtonWithLabel
                                    iconSize={16}
                                    iconId="Download"
                                    label="Скачать"
                                    onClick={() => setModalExportVisible(true)}
                                    labelStyle={{ color: '#0EBFA1' }}
                                />
                            )
                        }
                        <div
                            role="button"
                            className={`${styles.mediaPlan_controller} ${isOpen ? styles.close : styles.open}`}
                            onClick={() => setOpen(!isOpen)}
                        >
                            <SvgIcon size={16} id="dropdownArrow" />
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <>
                        <div style={tableStyle}>
                            <Table
                                rows={getFiltredRows}
                                columns={columnsToDisplay}
                                isLoading={isLoadingSources || isUpdatingContentLoading || !rows?.length}
                                onSort={handleSort}
                            />
                        </div>
                        <div className={styles.mediaPlan_footer}>
                            {
                                stage > 1 && (
                                    <>
                                        <IconButtonWithLabel
                                            iconSize={16}
                                            iconId="Download"
                                            label="Скачать"
                                            onClick={() => setModalExportVisible(true)}
                                            labelStyle={{ color: '#0EBFA1' }}
                                        />
                                        <ModalXlsxExport
                                            visible={modalExportVisible}
                                            setVisible={setModalExportVisible}
                                            values={modalExportValues}
                                            setValues={updateModalExportValues}
                                            onClickSubmit={() => handleClickDownload(modalExportValues)}
                                        />
                                    </>
                                )
                            }
                            {
                                stage <= 3 && (
                                    <Button
                                        disabled={!!monitoringIsError || stage > 3 || !canStartMonitoring}
                                        text="Начать мониторинг"
                                        size="large"
                                        type="default"
                                        onClick={() => onClickStartMonitoring(sourcesMedia.sources)}
                                    />
                                )
                            }
                        </div>
                    </>
                )}
            </div>
        </DefaultWrapperProject>
    );
};

export default MediaPlan;
