import { rtkQApi } from '../../index';
import {
    IChartDescriptionModel,
    IChartEnumDesription,
    ChartMetricsModel,
    IWithParamsCommonModel,
    ISavedChartModel,
} from './models';
import {
    DefalutChartQuery, IQueryParamsChartData, IQueryParamsDescriptionChart, IQueryParamsGetPointsForChart,
} from './types';

export const chartsCampaingRtqService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        // New
        getSettingsForChart: build.query<ChartMetricsModel, void>({
            query: () => ({
                url: 'campaign/info/enums',
                method: 'GET',
            }),
        }),
        getEnums: build.query<IChartEnumDesription[], void>({
            query: () => ({
                url: 'campaign/info/enums',
                method: 'GET',
            }),
        }),
        newGetChartData: build.mutation<IWithParamsCommonModel, IQueryParamsGetPointsForChart>({
            query: (body) => ({
                url: 'campaign/chart/with_params_common',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['CampaignDeletedPosts'],
        }),
        newGetChartDataLzy: build.query<IWithParamsCommonModel, IQueryParamsGetPointsForChart>({
            query: (body) => ({
                url: 'campaign/chart/with_params_common',
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 0,
        }),
        getChartsList: build.query<ISavedChartModel[], any>({
            query: ({ campaingId }) => ({
                url: `campaign/${campaingId}/chart_params`,
                method: 'GET',
            }),
        }),
        saveChart: build.mutation<any, IQueryParamsChartData>({
            query: ({ body, campaingId }) => ({
                url: `campaign/${campaingId}/chart_params`,
                method: 'POST',
                body,
            }),
        }),
        removeChart: build.query<any, IQueryParamsDescriptionChart>({
            query: ({ campaign_id, id }) => ({
                url: `campaign/${campaign_id}/chart_params/${id}`,
                method: 'DELETE',
            }),
        }),
        newUpdateChart: build.query<any, { data: IQueryParamsChartData['body'], campaign_id, id }>({
            query: ({ data, campaign_id, id }) => ({
                url: `campaign/${campaign_id}/chart_params/${id}`,
                method: 'PUT',
                body: data,
            }),
        }),
        // /lib/v2/campaign/{campaign_id::int}/chart_params/{char_id::int}
        // Old
        getChartData: build.mutation<any, IQueryParamsChartData>({
            query: ({ body, campaingId }) => ({
                url: `campaign/${campaingId}/chart_params`,
                method: 'POST',
                body,
            }),
        }),
        getCharts: build.query<IChartDescriptionModel[], DefalutChartQuery>({
            query: ({ campaign_id }) => ({
                url: `campaign/${campaign_id}/chart`,
                method: 'GET',
            }),
        }),
        createChart: build.query<IChartDescriptionModel, IQueryParamsDescriptionChart>({
            query: (body) => ({
                url: `campaign/${body.campaign_id}/chart`,
                method: 'POST',
                body,
            }),
        }),
        updateChart: build.query<IChartDescriptionModel, IQueryParamsDescriptionChart>({
            query: (body) => ({
                url: `campaign/${body.campaign_id}/chart/${body.id}`,
                method: 'PUT',
                body,
            }),
        }),
        deleteChart: build.query<void, IQueryParamsDescriptionChart>({
            query: (body) => ({
                url: `campaign/${body.campaign_id}/chart/${body.id}`,
                method: 'DELETE',
            }),
        }),
    }),
});
