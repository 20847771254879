import React from 'react';
import classnames from 'classnames';
import { ICampaignHeaderCellProps } from './types';
import { CampaignColumnFilterType } from '../types';
import DropdownColumnFilterTable from '../../../../common/DropdownColumnFilterTable';

import styles from '../styles.module.scss';

const CampaignHeaderCell: React.FC<ICampaignHeaderCellProps> = ({
    columnId, searchIsActive, name, columnType,
}): JSX.Element => (
    <div className={
        classnames(
            styles.headerColumn,
            styles[columnType !== CampaignColumnFilterType.default ? '' : 'notFiltering'],
        )
    }
    >
        {
            columnType !== CampaignColumnFilterType.default && (
                <div style={{ marginLeft: columnId === 1 ? 20 : 0 }}>
                    <DropdownColumnFilterTable
                        filterType={columnType}
                        name={name}
                        invisible={searchIsActive}
                    />
                </div>
            )
        }
        {
            columnType === CampaignColumnFilterType.default
                    && (<div className={styles.textContainer} style={{ marginLeft: 12 }}>{name}</div>)
        }
    </div>
);

export default CampaignHeaderCell;
