import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnalyzeInputForFetch, IAnalyzeResultState } from './types';
import { convertApiToAnalyzeResult } from './converter';

const initialState: IAnalyzeResultState = {
    link: '',
    logo: '',
    newsId: '',
    source: '',
    title: '',
    content: '',
    postedTimestamp: '',
    inProcess: false,
    isAnalyzeStatus: false,
    activePeriod: 0,
    forFetchData: {
        description: '',
        idempotencyKey: '',
        filter: 0,
    },
    taskId: '',
    mlResult: {
        analytics: null,
        quotations: null,
        authors: null,
    },
};

const { actions: analyzeResultActions, reducer } = createSlice({
    name: 'analyzeResult',
    initialState,
    reducers: {
        setDataForAnalyze: (state: IAnalyzeResultState, { payload }: PayloadAction<IAnalyzeInputForFetch>): void => {
            state.forFetchData = payload;
            state.activePeriod = payload.filter;
            state.inProcess = true;
        },
        setActivePeriod: (state: IAnalyzeResultState, { payload }: PayloadAction<number>): void => {
            state.activePeriod = payload;
        },
        successLoading: (state: IAnalyzeResultState, { payload }: PayloadAction<unknown>) => (
            { inProcess: false, ...convertApiToAnalyzeResult(payload), activePeriod: state.activePeriod } as IAnalyzeResultState
        ),
        loadingIsComplete: (state: IAnalyzeResultState): void => {
            state.inProcess = false;
        },
        startAnalyzeStatus: (state: IAnalyzeResultState): void => {
            state.isAnalyzeStatus = true;
        },
        finishAnalyzeStatus: (state: IAnalyzeResultState): void => {
            state.isAnalyzeStatus = false;
        },
    },
});

export { analyzeResultActions };
export default reducer;
