/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './styles.module.scss';

const ModalChangeSkeleton: React.FC = (): JSX.Element => (
    <div
        className={styles.root_ModalInfo}
    >
        <Skeleton width={300} height={16} style={{ marginBottom: 12 }} />
        <Skeleton width={450} height={24} style={{ marginBottom: 20 }} />
        <Skeleton width={300} height={469} style={{ marginLeft: 20 }} />
    </div>

);
export default ModalChangeSkeleton;
