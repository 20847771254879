import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableLocalStorageKeys } from '@store/types';

export interface IAdvancedSearchHistory {
    id: number;
    title: string;
    link: string;
    platformIconName: string;
}

export interface IAdvancedSearchState {
    localStorageKey: TableLocalStorageKeys;
    history: IAdvancedSearchHistory[];
}

const initialState: IAdvancedSearchState = {
    history: [
        ...JSON.parse(localStorage.getItem(TableLocalStorageKeys.AdvancedSearch)) as IAdvancedSearchHistory[] ?? [],
    ],
    localStorageKey: TableLocalStorageKeys.AdvancedSearch,
};

const { actions: advancedSearchActions, reducer } = createSlice({
    name: 'advancedSearch',
    initialState,
    reducers: {
        addHistory: (state: IAdvancedSearchState, { payload }: PayloadAction<IAdvancedSearchHistory>): void => {
            const buffer = [
                payload,
                ...(JSON.parse(localStorage.getItem(state.localStorageKey)) as IAdvancedSearchHistory[] ?? []).slice(0, 10),
            ];
            // update store
            state.history = [...buffer];
            // update localStorage
            localStorage.setItem(state.localStorageKey, JSON.stringify(buffer));
        },
        clearHistory: (state: IAdvancedSearchState): void => {
            state.history = [];
            // update localStorage
            localStorage.setItem(state.localStorageKey, JSON.stringify([]));
        },
    },
});

export { advancedSearchActions };
export default reducer;
