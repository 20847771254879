/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, SvgIcon } from '@shared/index';
import TextField from '@entities/TextField';
import {
    useEffect, useMemo, useRef, useState,
} from 'react';
import useOnClickOutside from '@shared/hooks/useOnClickOutside';
import { sourcesListsRtkQService } from '@services/sources/lists';
import { Checkbox } from 'antd';
import { SourceListCreateModal } from '@entities/index';
import { CommonIconsIds } from '@app/constants/icons';
import styles from './styles.module.scss';

interface IProps {
    isVisible: boolean
    onClose: () => void;
    sourceIds: number[];
    selectedSourceTitle: string;
}

const SourceAddingListsModal: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        isVisible, onClose, sourceIds, selectedSourceTitle,
    } = props;
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedLists, setSelectedLists] = useState<number[]>([]);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const modalRef = useRef(null);

    const { data: dataLists } = sourcesListsRtkQService.useGetListsQuery();
    const [addSourcesToLists, { isSuccess: isAddingSuccess, isFetching: isAddingFetching }] = sourcesListsRtkQService.useLazyPostAddSourcesToListsQuery();

    const lists = useMemo(() => {
        if (!dataLists) return null;
        return dataLists.filter((list) => {
            if (searchValue) return list.title.includes(searchValue) && !list.isFavorite;
            return !list.isFavorite;
        });
    }, [dataLists, searchValue]);

    const handleClickAdd = (): void => {
        addSourcesToLists({ sourceIds, listIds: selectedLists });
    };

    const handleClickSelect = (e, id: number): void => {
        const value = e.target.checked;
        if (value) setSelectedLists((prev) => [...prev, id]);
        if (!value) setSelectedLists(selectedLists.filter((list) => list !== id));
    };

    const handleClickClose = (): void => {
        onClose();
        setSelectedLists([]);
        setSuccess(false);
    };

    useOnClickOutside(modalRef, handleClickClose);

    useEffect(() => {
        if (!isAddingFetching) {
            setSuccess(isAddingSuccess);
            if (isAddingSuccess) {
                setSelectedLists([]);
            }
        }
    }, [isAddingSuccess, isAddingFetching]);

    if (!isVisible) return null;

    return (
        <div className={styles.modal}>
            {isSuccess ? (
                <div ref={modalRef} className={styles.successMessage}>
                    <SvgIcon id={CommonIconsIds.IconComplete} size={32} />
                    <p>
                        Источник добавлен
                        {' '}
                        <br />
                        {' '}
                        в созданный список
                    </p>
                </div>
            ) : (
                <div className={styles.modalContainer}>
                    <div className={styles.searchInput}>
                        <TextField
                            value={searchValue}
                            onChange={(value) => setSearchValue(value)}
                            isSearch
                            size="medium"
                            placeholder="Поиск"
                        />
                    </div>

                    <div className={styles.list}>
                        {lists && lists.map((list) => (
                            <div key={list.title}>
                                <Checkbox
                                    checked={selectedLists.includes(list.id)}
                                    onClick={(e) => handleClickSelect(e, list.id)}
                                />
                                <span>{list.title}</span>
                            </div>
                        ))}
                    </div>

                    <div className={styles.buttons}>
                        <Button text="Отменить" type="secondary" size="small" onClick={onClose} />
                        <Button text="Добавить" type="default" size="small" onClick={handleClickAdd} disabled={Boolean(!selectedLists?.length)} />
                    </div>

                    {/* <SourceListCreateModal sourceId={sourceIds[0]} sourceTitle={selectedSourceTitle} /> */}
                </div>
            )}
        </div>
    );
};
export default SourceAddingListsModal;
