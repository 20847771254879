/* eslint-disable @typescript-eslint/no-unused-vars */
import { Popover } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import styles from './styles.module.scss';

interface IPropsTooltip {
    children: React.ReactNode;
    content: JSX.Element;
    trigger?: 'click' | 'hover';
    placement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom',
    yOffset?: number,
    arrow?: boolean,
    xOffset?: number,
    isOpen?: boolean,
    disabled?: boolean,
    onOpenChange?: (status: boolean) => void
}
const Tooltip: React.FC<IPropsTooltip> = ({
    children,
    content,
    trigger = 'hover',
    placement = 'bottom',
    arrow = true,
    yOffset,
    xOffset,
    isOpen,
    disabled = false,
    onOpenChange,
}): JSX.Element => {
    const childrenRef = useRef(null);
    const yOffsetMemo = useMemo(() => {
        if (yOffset) return yOffset;
        if (placement.toLocaleLowerCase().includes('bottom')) return -6;
        if (placement.toLocaleLowerCase().includes('top')) return 6;
        return 0;
    }, [placement, yOffset]);
    const xOffsetMemo = useMemo(() => {
        if (xOffset) return xOffset;
        if (placement.toLocaleLowerCase().includes('right')) return -6;
        if (placement.toLocaleLowerCase().includes('left')) return 6;
        return 0;
    }, [placement, xOffset]);
    const getAdditionProps = useMemo(() => ({ visible: disabled ? false : isOpen, onVisibleChange: disabled ? null : onOpenChange }), [isOpen, disabled]);
    return (
        <Popover
            content={content}
            title={null}
            trigger={trigger}
            placement={placement}
            overlayClassName={styles.tooltipOverlay}
            autoAdjustOverflow
            align={{ offset: [xOffsetMemo, yOffsetMemo] }}
            showArrow={arrow}
            {...getAdditionProps}
        >
            <div ref={childrenRef} style={{ width: 'fit-content', height: 'fit-content' }}>
                {children}
            </div>
        </Popover>
    );
};

export default Tooltip;
