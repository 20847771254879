/* eslint-disable class-methods-use-this */
/* eslint-disable new-cap */
import pptxgen from 'pptxgenjs';
import CampaignPPTXAdditionalDataPage from './Pages/CampaignPPTXAdditionalDataPage';
import CampaignPPTXAuthorsPage from './Pages/CampaignPPTXAuthorsPage';
import CampaignPPTXCategoriesPage from './Pages/CampaignPPTXCategoriesPage';
import CampaignPPTXFrontPage from './Pages/CampaignPPTXFrontPage';
import CampaignPPTXRegionPage from './Pages/CampaignPPTXRegionPage';
import CampaignPPTXSearchParamsPage from './Pages/CampaignPPTXSearchParamsPage';
import CampaignPPTXSummaryDataPage from './Pages/CampaignPPTXSummaryDataPage';
import CampaignPPTXTopPostsPage from './Pages/CampaignPPTXTopPostsPage';
import { ICampaignPPTXCreateProps } from './types';

class PptxFactor {
    instance: pptxgen;

    layoutWidth = 8.42;

    layoutHeight = 5.95;

    layoutName = 'A4';

    constructor() {
        const pptx = new pptxgen();
        pptx.defineLayout({
            name: this.layoutName,
            width: this.layoutWidth,
            height: this.layoutHeight,
        });
        pptx.layout = this.layoutName;
        this.instance = pptx;
    }

    async createSlides(props: ICampaignPPTXCreateProps): Promise<File> {
        let pageNumber = 1;
        const commonProps = {
            layoutHeight: this.layoutHeight,
            layoutWidth: this.layoutWidth,
            date: props.common.date,
            title: props.common.title,
            reportName: props.common.reportName,
        };

        new CampaignPPTXFrontPage(this.instance).render({
            ...commonProps,
        });

        new CampaignPPTXSearchParamsPage(this.instance).render({
            ...commonProps,
            ...props.searchParamsData,
            header: {
                number: this.formatPageNumber(pageNumber),
                pageName: 'ПАРАМЕТРЫ ПОИСКА',
            },
        });
        pageNumber += 1;

        new CampaignPPTXSummaryDataPage(this.instance).render({
            ...commonProps,
            ...props.summaryData,
            header: {
                number: this.formatPageNumber(pageNumber),
                pageName: 'Сводная часть',
            },
        });
        pageNumber += 1;

        // props.listGroups.forEach((element) => {
        //     new CampaignPPTXChartsPage(this.instance).render({
        //         ...commonProps,
        //         header: {
        //             number: this.formatPageNumber(pageNumber),
        //             pageName: 'Графики',
        //         },
        //         data: element,
        //     });
        //     pageNumber += 1;
        // });

        new CampaignPPTXRegionPage(this.instance).render({
            ...commonProps,
            header: {
                number: this.formatPageNumber(pageNumber),
                pageName: 'Регионы',
            },
            rssByRegion: props.rssByRegion,
            bloggerByRegion: props.bloggerByRegion,
        });
        pageNumber += 1;

        new CampaignPPTXCategoriesPage(this.instance).render({
            ...commonProps,
            header: {
                number: this.formatPageNumber(pageNumber),
                pageName: 'Категории',
            },
            categiries: props.categiries,
        });
        pageNumber += 1;

        new CampaignPPTXAdditionalDataPage(this.instance).render({
            ...commonProps,
            header: {
                number: this.formatPageNumber(pageNumber),
                pageName: 'Регионы',
            },
            additionalData: props.additionalData,
            tags: props.tags,
            sourcesTypes: props.sourcesTypes,
        });
        pageNumber += 1;

        new CampaignPPTXAuthorsPage(this.instance).render({
            ...commonProps,
            header: {
                number: this.formatPageNumber(pageNumber),
                pageName: 'Авторы',
            },
            authorsData: props.authorsData,
        });
        pageNumber += 1;

        props.topPlatforms.forEach((arr) => {
            new CampaignPPTXTopPostsPage(this.instance).render({
                ...commonProps,
                header: {
                    number: this.formatPageNumber(pageNumber),
                    pageName: 'Топ постов',
                },
                topPosts: arr,
            });

            pageNumber += 1;
        });

        const file = await this.instance.write({ outputType: 'blob' });
        return new File([file], `${props.common.reportName}.pptx`, { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    }

    writeFile(fileName: string) {
        this.instance.writeFile({ fileName });
    }

    private formatPageNumber(value: number | string): string {
        return String(value).padStart(2, '0');
    }
}

export default PptxFactor;
