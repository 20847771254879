import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Button, Select } from '@shared/index';
import { ISelectItem } from '@shared/ui-components/Select/types';
import TextField from '@entities/TextField';
import IconComplete from '@assets/images/complete.svg';
import IconCrossModalRed from '@assets/images/cross_modal_red.svg';
import Loader from '@components/Loader';
import SvgIcon from '@components/SvgIcon';
// import SingleSelectItem from '../../../../components/common/SingleSelectItem';
import { campaignsListRtkQService } from '@services/campaigns/campaigns-list';
import { campaignUsersRtkQService } from '@services/campaigns/users';
import { campaignActions } from '@store/campaign/source';
import campaignSelectors from '@store/campaign/source/selectors';
// import addUserToCampaignThunk from '../../../../store/campaign/source/thunks/add-user-campaign';
// import loadCampaignsIfNotExists from '../../../../store/campaign/source/thunks/load-campaigns';
import { useAppDispatch } from '@store/hooks';
import { FetchStatus } from '@store/types';

import useOnClickOutside from '../../../../shared/hooks/useOnClickOutside';
import styles from './styles.module.scss';

const validateEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    selectedCampaignId?: number;
}

const AddUserModal: React.FC<IProps> = ({
    isOpen,
    onClose,
    selectedCampaignId,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    // const campaigns = useSelector(campaignSelectors.campaigns);
    const [campaignId, setCampaignId] = useState<number | null>(null);
    const addUserStatus = useSelector(campaignSelectors.addUserToCampaignStatus);

    const { data: campaigns } = campaignsListRtkQService.useGetCampaignsQuery();
    const [addUserCampaign] = campaignUsersRtkQService.useLazyPostAddUserCampaignQuery();

    const handlerSelectCampaign = (id: number): void => {
        setCampaignId(id);
    };

    const list = useMemo<ISelectItem[]>(
        () => campaigns?.map((item) => ({
            id: item.id,
            label: item.name,
            value: item.name,
        })),
        [campaigns],
    );

    // useEffect(() => {
    //     dispatch(loadCampaignsIfNotExists());
    // }, []);

    useEffect(() => {
        if (selectedCampaignId) setCampaignId(selectedCampaignId);
    }, [selectedCampaignId]);

    const [email, setEmail] = useState<string>('');
    const [emailValid, setValidEmail] = useState<boolean>(true);

    const handlerChangeEmail = (value: string): void => {
        setEmail(value);
        setValidEmail(validateEmail(value));
    };

    const handlerSendEmail = (): void => {
        if (email && emailValid) {
            // dispatch(addUserToCampaignThunk(campaignId, email));
            addUserCampaign({ id: campaignId, email });
        }
    };

    const handleClickClose = (): void => {
        onClose();
        dispatch(campaignActions.clearUserAdding());
        setEmail('');
        setValidEmail(true);
        setCampaignId(null);
    };

    const refSuccessModal = useRef();

    useOnClickOutside(refSuccessModal, () => {
        if (addUserStatus === FetchStatus.success) {
            handleClickClose();
        }
    });

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modalWindow}>
                {addUserStatus === FetchStatus.undefined
                    && (
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <span>Добавление пользователя</span>
                                <button type="button" onClick={handleClickClose}>
                                    <SvgIcon id="BigCross" size={14} />
                                </button>
                            </div>

                            <p className={styles.text}>
                                Выберите кампанию, доступ к которой будет предоставлен
                                пользователю
                            </p>

                            <div className={styles.form}>
                                <div className={styles.campaign}>
                                    <div className={styles.step}>
                                        <div className={styles.stepNumber}>1</div>
                                        <p className={styles.stepLabel}>
                                            Наименование кампании:
                                        </p>
                                    </div>
                                    <Select
                                        items={list}
                                        onSelect={handlerSelectCampaign}
                                    />
                                    {/* <SingleSelectItem
                                        tagId="notification-campaign-list"
                                        selectedId={campaignId}
                                        onSelect={handlerSelectCampaign}
                                        defaultTitle={<span>выбор</span>}
                                        list={list}
                                        isLoading={false}
                                    /> */}
                                </div>

                                <p className={styles.text}>
                                    Введите в поле e-mail пользователя, которого хотите
                                    добавить. Если пользователь не будет найден в системе,
                                    ему на почту придет письмо с инструкцией.
                                </p>

                                <div className={styles.email}>
                                    <div className={styles.step}>
                                        <div className={styles.stepNumber}>2</div>
                                    </div>
                                    <TextField
                                        label="E-mail"
                                        value={email}
                                        onChange={handlerChangeEmail}
                                        className={emailValid ? '' : styles.invalid}
                                    />
                                </div>

                                <div className={styles.buttonConfirm}>
                                    <Button
                                        text="Добавить пользователя"
                                        type="default"
                                        size="large"
                                        onClick={handlerSendEmail}
                                        disabled={!email || !emailValid}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                {addUserStatus === FetchStatus.failed && (
                    <div className={styles.errorContainer}>
                        <p>
                            Что-то пошло не так!
                            <br />
                            Пожалуйста, повторите попытку чуть позже
                        </p>
                        <button type="button" onClick={handleClickClose}>
                            <img src={IconCrossModalRed} alt="" />
                        </button>
                    </div>
                )}

                {addUserStatus === FetchStatus.process && (
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                )}

                {addUserStatus === FetchStatus.success && (
                    <div
                        className={styles.success}
                        ref={refSuccessModal}
                    >
                        <p>Успешно отправлено</p>
                        <img src={IconComplete} alt="" />

                        <button type="button" onClick={handleClickClose}>
                            <SvgIcon id="BigCross" size={14} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddUserModal;
