import React, { useMemo } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

export type ButtonSizeType = 'large' | 'medium' | 'small';
export type ButtonType = 'default' | 'secondary' | 'danger' | 'secondaryDanger';

interface IProps{
    tagId?: string;
    text: string;
    disabled?: boolean;
    type: ButtonType;
    size: ButtonSizeType;
    onClick: () => void;
}

const typeStylesMap = new Map([
    ['default', styles.commonButton_default],
    ['secondary', styles.commonButton_secondary],
    ['danger', styles.commonButton_danger],
    ['secondaryDanger', styles.commonButton_secondaryDanger],
    ['disabled', styles.commonButton_disabled],
    ['disabledSecondary', styles.commonButton_disabledSecondary],
]);

const sizeStylesMap = new Map([
    ['large', styles.commonButton_large],
    ['medium', styles.commonButton_medium],
    ['small', styles.commonButton_small],
]);

const Button: React.FC<IProps> = ({
    tagId,
    text,
    disabled,
    type,
    size,
    onClick,
}): JSX.Element => {
    const disabledStyle = useMemo<string>(() => {
        if (disabled && type === 'secondary') {
            return typeStylesMap.get('disabledSecondary');
        }
        if (disabled) {
            return typeStylesMap.get('disabled');
        }
        return typeStylesMap.get(type);
    }, [disabled, type]);
    return (
        <div className={styles.commonButton_root}>
            <button
                id={tagId}
                type="button"
                onClick={() => onClick()}
                disabled={disabled}
                className={classnames(
                    styles.commonButton_button,
                    disabledStyle,
                    sizeStylesMap.get(size),
                )}
            >
                {text}
            </button>
        </div>
    );
};

export default Button;
