import React, { useCallback, useMemo, useState } from 'react';
import { IFilterCampaigns } from 'store/campaign/comparison';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import styles from './styles.module.scss';
import SvgIcon from '../../../../../SvgIcon';

interface ITonality {
    name: string,
    data: IElement[]
}

interface IElement {
    id: number;
    name: string;
    percent: number;
    quantity: number;
    quantityStr: string;
}

interface IProps {
    filters: IFilterCampaigns[];
    referenceCampaign: ICampaignDataModel | null;
    compareCampaigns: ICampaignDataModel[];
}

const filterType = 'Тональность';
const title = 'Тональность';
const tonality = ['neutral', 'positive', 'skip'];

const nameTonalityMap: { key: string, value: string }[] = [
    { key: 'neutral', value: 'Нейтрально' },
    { key: 'positive', value: 'Позитивно' },
    { key: 'skip', value: 'Негативно' },
];

const tonalityMap: { key: string, value: string }[] = [
    { key: 'Нейтрально', value: '#FEF8E8' },
    { key: 'Позитивно', value: '#DFFCF7' },
    { key: 'Негативно', value: '#FDEBEB' },
];

const CampaignComparisonTonality: React.FC<IProps> = ({ filters, referenceCampaign, compareCampaigns }): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const handleKeyDown = (): void => {
        // eslint-disable-next-line no-console
    };

    const findCampaignAndFilters = useCallback((campaignId: number, type: string, filterName: string): number => {
        const result = (filters
            .find(({ campaignId: itemId }) => itemId === campaignId)?.filters
            .find((item) => item.filtersCategory === type)?.filters
            .find(({ filter_name }) => filter_name === filterName)?.count) ?? 0;
        return (Number.isNaN(result) ? 0 : result);
    }, [filters]);
    const calculatePercent = (dividend : number, divisor: number) => {
        if (divisor === 0) {
            return dividend;
        }
        return Math.round(((dividend ?? 0) / divisor) * 10000) / 100;
    };

    const arrObj = useMemo<ITonality[]>(
        () => {
            const result = [];
            tonality.forEach((value) => {
                result.push({
                    name: nameTonalityMap.find((key) => key.key === value).value,
                    data: [
                        {
                            id: referenceCampaign.id,
                            name: referenceCampaign.name,
                            percent: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantity: findCampaignAndFilters(referenceCampaign.id, filterType, value),
                            quantityStr: findCampaignAndFilters(referenceCampaign.id, filterType, value)
                                .toLocaleString('ru-RU'),
                        } as IElement,
                        ...compareCampaigns.map(({ id: itemId, name }) => {
                            const quantity = findCampaignAndFilters(itemId, filterType, value) ?? 0;
                            return {
                                id: itemId,
                                name,
                                percent: calculatePercent(quantity, findCampaignAndFilters(referenceCampaign.id, filterType, value)),
                                quantity,
                                quantityStr: quantity.toLocaleString('ru-RU'),
                            } as IElement;
                        }),
                    ],
                } as ITonality);
            });
            return result;
        },
        [referenceCampaign, compareCampaigns],
    );

    const calculationOfPercentageDifference = (percent): string => {
        if (!percent || Number.isNaN(percent)) return ' - ';
        if (!(percent - 100)) return '0%';
        if (percent - 100 < 100) return `${(percent - 100)?.toLocaleString('ru-RU')}%`;
        return `${(percent)?.toLocaleString('ru-RU')}%`;
    };

    return (
        <div className={styles.tonality}>
            <div className={styles.tonality_lineStart}>
                <div className={styles.tonality_name}>{title}</div>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    onClick={() => setIsOpen(!isOpen)}
                    className={styles.tonality_arrow}
                >
                    <div style={isOpen ? { transform: ' rotate(0)' } : { transform: ' rotate(180deg)' }}>
                        <SvgIcon id="DropDownArrow" size={17} height={12} />
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className={styles.tonality_list}>
                    <div className={styles.tonality_title}>
                        {arrObj[0].data.map((element, index) => (
                            <div
                                key={element.id}
                                className={styles.tonality_cell}
                                style={!index ? { color: '#0ebfa1' } : null}
                            >
                                {element.name}
                            </div>
                        ))}
                    </div>
                    {arrObj.map((element, indexArrObj) => (
                        <div key={element.name} className={styles.tonality_block}>
                            <div
                                className={styles.tonality_nameTonality}
                                style={{ background: tonalityMap.find(({ key }) => key === element.name)?.value }}
                            >
                                {element.name}
                            </div>
                            <div className={styles.tonality_info}>
                                <div className={styles.tonality_quantity}>
                                    {element.data.map((item) => (
                                        <div
                                            key={item.id}
                                            className={styles.tonality_cell}
                                        >
                                            {item.quantityStr}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.tonality_percent}>
                                    {element.data.map((item, index) => {
                                        if (!index) return <div className={styles.tonality_cell} />;
                                        if (!arrObj[indexArrObj].data[0].quantity) {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className={styles.tonality_cell}
                                                    style={item.percent ? { color: 'green' } : { color: 'red' }}
                                                >
                                                    {item.percent ? `${item.percent * 100}%` : ' - '}
                                                </div>
                                            );
                                        }
                                        return (
                                            <div
                                                key={item.id}
                                                className={styles.tonality_cell}
                                                style={item.percent >= 100 ? { color: 'green' } : { color: 'red' }}
                                            >
                                                {calculationOfPercentageDifference(item.percent)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CampaignComparisonTonality;
