import { SvgIcon, ModalMessageContainer } from '@shared/index';
import { CrossIconsIds } from '@app/constants/icons';
import styles from './styles.module.scss';

interface IProps {
    visible: boolean;
    title: string;
    text: string;
    onClose: () => void;
    actions: React.ReactNode[];
}

const ModalAction: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        visible, title, text, onClose, actions,
    } = props;

    if (!visible) return null;

    return (
        <ModalMessageContainer>
            <div className={styles.header}>
                <span>{title}</span>
                <button type="button" onClick={onClose}>
                    <SvgIcon id={CrossIconsIds.Close} size={18} />
                </button>
            </div>
            <div className={styles.content}>
                <p>{text}</p>
            </div>
            <div className={styles.footer}>
                {actions}
            </div>
        </ModalMessageContainer>
    );
};
export default ModalAction;
