import { IProjectHistoryModel, IProjectStatus } from '@services/projects/types';
import { transformProjectStatusResponse } from '@services/projects/transformers/getStatus';
import { transformProjectHistoryResponse } from '@services/projects/transformers/getHistory';
import { IQPUpdateStatus } from '@services/projects/types/stages/queryParams';
import { rtkQApi } from '../..';

export const projectsStageService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        getAllEnums: build.query<any, void>({
            query: () => ({
                url: '/media_projects/info/enums',
                method: 'GET',
            }),
        }),
        getStatus: build.query<IProjectStatus, { id: number }>({
            query: (params) => ({
                url: `media_projects/project/${params.id}/status/`,
                method: 'GET',
            }),
            transformResponse: transformProjectStatusResponse,
            providesTags: ['ProjectStatus'],
        }),
        postUpdateStatus: build.mutation<IProjectStatus, IQPUpdateStatus>({
            query: (params) => ({
                url: `media_projects/${params.projectId}/status/update/`,
                method: 'POST',
                body: {
                    status_id: params.currentStatus,
                },
            }),
            transformResponse: transformProjectStatusResponse,
            invalidatesTags: ['ProjectStatus'],
        }),
        getHistory: build.query<IProjectHistoryModel[], { id: number }>({
            query: (params) => ({
                url: `/media_projects/project/${params.id}/history`,
                method: 'GET',
            }),
            transformResponse: transformProjectHistoryResponse,
        }),
    }),
});
