import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store/hooks';
import CampaignComparisonList from '../../../../components/campaign/List/Comparison';
import campaignComparisonSelectors from '../../../../store/campaign/comparison/selectors';
import { campaignComparisonActions } from '../../../../store/campaign/comparison';

const CampaignComparisonListContainer: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const campaigns = useSelector(campaignComparisonSelectors.compareCampaigns);
    const referenceCampaign = useSelector(campaignComparisonSelectors.referenceCampaign);
    const handlerRemoveCampaign = useCallback((id: number) => {
        dispatch(campaignComparisonActions.removeCampaignForCompare(id));
    }, [dispatch]);
    const handlerCancelCompare = useCallback(() => {
        dispatch(campaignComparisonActions.clearAll());
    }, [dispatch]);
    const handlerStartCompare = useCallback(() => {
        dispatch(campaignComparisonActions.setSelectPeriodState(true));
    }, [dispatch]);
    return (
        <CampaignComparisonList
            compareCampaigns={campaigns}
            referenceCampaign={referenceCampaign}
            onRemoveCampaign={handlerRemoveCampaign}
            onCancelCompare={handlerCancelCompare}
            onStartCompare={handlerStartCompare}
        />
    );
};

export default CampaignComparisonListContainer;
