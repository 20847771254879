import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import DatePicker from '../../components/DatePicker';
import { DateRangePickerValue } from '../../components/DateRangePicker/types';
import CampaignAuthorsTable from './CampaignAuthorsTable';
import styles from './styles.module.scss';

const CampaignAuthorsPage: React.FC = (): React.ReactElement => {
    const { Id: campaignId } = useParams<{ Id: string }>();
    const [currentPeriod, setCurrentPeriod] = useState<DateRangePickerValue>([
        moment().subtract(30, 'day'),
        moment(),
    ]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.calendar}>
                    <DatePicker
                        value={currentPeriod}
                        onChange={setCurrentPeriod}
                    />
                </div>
            </div>

            <CampaignAuthorsTable
                period={currentPeriod}
                campaignid={Number(campaignId)}
            />
        </div>
    );
};
export default CampaignAuthorsPage;
