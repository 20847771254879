/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
import {
    Chart as ChartJS,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import TooltipWrapper from '../../components/TooltipWrapper';
import styles from './styles.module.scss';
import { IPropsChartView } from './Types';
import LoaderForChart from './LoaderForChart';

ChartJS.register(
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    TimeScale,
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChartView: React.FC<IPropsChartView> = ({
    animation = true,
    isActiveTooltip = false,
    beginAtZero = false,
    listDatasets,
    width,
    height,
    getTooltip,
    getImage,
    onLoadPrevData,
    onLoadNextData,
}) => {
    const chartRef = useRef(null);
    const timerTooltip = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isShowModal, setIsShowModal] = useState(false);
    const [positionAndData, setPositionAndData] = useState({ top: 0, left: 0, data: null });
    const dataSet = {
        events: ['click'],
        datasets: listDatasets,
        plugin: [zoomPlugin],
    };
    const compare = (a, b) => {
        if (a.x < b.x) {
            return -1;
        }
        if (a.x > b.x) {
            return 1;
        }
        return 0;
    };
    const objectHandler:any = {
        // eslint-disable-next-line func-names, object-shorthand, react/no-this-in-sfc
        update: function (n, v) { this[n] = v; },
    };
    const handlerGetNextData = (maxDatasets, lastTick?: moment.Moment) => {
        if (objectHandler?.setter) objectHandler?.setter(true);
        const periods = maxDatasets.map((dataset) => {
            const { data } = dataset;
            // const lastPoint = data?.reverse()?.at(-1);
            const maxDate = data.reduce((max, current) => {
                if (current.x > max.x) return current;
                return max;
            }, data?.reverse()?.at(-1));
            const pointMoment = moment(maxDate.x);
            const periodEndFromFirstTick = lastTick?.clone().add(45, 'd');
            const periodInit = pointMoment.clone().add(1, 'day');
            const periodEnd = periodInit.clone().add(31, 'day');
            return {
                init: periodInit,
                end: periodEndFromFirstTick || periodEnd,
                filter: dataset.filter,
                id: dataset.id,
            };
        });
        periods.forEach((period) => {
            onLoadNextData(
                {
                    init: period?.init.format('YYYY-MM-DD'),
                    end: period?.end.format('YYYY-MM-DD'),
                },
                period.filter,
            ).then((res) => {
                const newPoints = res?.data.map((el) => ({
                    x: new Date(el.dt).getTime(),
                    y: el.count,
                    label: { content: `${el.count}` },
                }));
                dataSet.datasets.forEach((dataset) => {
                    if (dataset.id === period.id) {
                        // eslint-disable-next-line no-param-reassign
                        dataset.data.push(...newPoints.reverse());
                        // eslint-disable-next-line no-param-reassign
                        dataset.data = dataset.data.sort(compare);
                        chartRef.current.update('none');
                        chartRef.current.zoom(1.001);
                    }
                });
                // currentDataset.data = [...currentDataset.data, ...newPoints.reverse()];
                if (objectHandler?.setter) objectHandler?.setter(false);
            });
        });
    };
    const handlerGetPrevData = (minDatasets, firstTick?: moment.Moment) => {
        if (objectHandler?.setter) objectHandler?.setter(true);
        const periods = minDatasets.map((dataset) => {
            const { data } = dataset;
            // const firstPoint = data.at(-1);
            const minDate = data.reduce((min, current) => {
                if (current.x < min.x) return current;
                return min;
            }, data.at(-1));
            const pointMoment = moment(minDate.x);
            const periodInitFromFirstTick = firstTick?.clone().subtract(45, 'd');
            const periodInit = pointMoment.clone().subtract(31, 'day');
            const periodEnd = pointMoment.clone();
            return {
                init: periodInitFromFirstTick || periodInit,
                end: periodEnd,
                filter: dataset.filter,
                id: dataset.id,
            };
        });
        periods.forEach((period) => {
            onLoadPrevData(
                {
                    init: period?.init.format('YYYY-MM-DD'),
                    end: period?.end.format('YYYY-MM-DD'),
                },
                period.filter,
            ).then((res) => {
                const newPoints = res?.data.map((el) => ({
                    x: new Date(el.dt).getTime(),
                    y: el.count,
                    label: { content: `${el.count} ${el.dt}` },
                }));
                dataSet.datasets.forEach((dataset) => {
                    if (dataset.id === period.id) {
                        // eslint-disable-next-line no-param-reassign
                        dataset.data.unshift(...newPoints.reverse());
                        // eslint-disable-next-line no-param-reassign
                        dataset.data = dataset.data.sort(compare);
                        chartRef.current.update();
                    }
                    chartRef.current.update();
                    if (objectHandler?.setter) objectHandler?.setter(false);
                });
            });
        });
    };
    const onPanComplete = async (e) => {
        const lastTick = e.chart?.scales?.x?.ticks.at(-1);
        const firstTick = e.chart?.scales?.x?.ticks[0];
        const momentLastTick = moment(lastTick.value);
        const momentFirstTick = moment(firstTick.value);
        const maxDatasets = listDatasets.filter((dataset) => {
            const maxDate = dataset?.data?.reduce((max, current) => {
                if (current.x > max.x) return current;
                return max;
            }, dataset?.data?.at(0)?.x);
            const result = moment(maxDate?.x).isBefore(momentLastTick);
            return result;
        });
        const minDatasets = listDatasets.filter((dataset) => {
            const minDate = dataset?.data?.reduce((min, current) => {
                if (current.x < min.x) return current;
                return min;
            }, dataset?.data?.at(-1));
            const result = moment(minDate?.x).isAfter(momentFirstTick);
            return result;
        });
        if (maxDatasets.length && onLoadNextData) handlerGetNextData(maxDatasets, momentLastTick);
        if (minDatasets.length && onLoadPrevData) handlerGetPrevData(minDatasets, momentFirstTick);
    };
    const onZoomComplete = (e) => {
        const lastTick = e.chart?.scales?.x?.ticks.at(-1);
        const firstTick = e.chart?.scales?.x?.ticks[0];
        const momentLastTick = moment(lastTick.value);
        const momentFirstTick = moment(firstTick.value);
        const maxDatasets = listDatasets.filter((dataset) => {
            const maxDate = dataset?.data?.reduce((max, current) => {
                if (current.x > max.x) return current;
                return max;
            }, dataset?.data?.at(0)?.x);
            const result = moment(maxDate?.x).isBefore(momentLastTick);
            return result;
        });
        const minDatasets = listDatasets.filter((dataset) => {
            const minDate = dataset?.data?.reduce((min, current) => {
                if (current.x < min.x) return current;
                return min;
            }, dataset?.data?.at(-1));
            const result = moment(minDate?.x).isAfter(momentFirstTick);
            return result;
        });
        if (maxDatasets.length && onLoadNextData) handlerGetNextData(maxDatasets, momentLastTick);
        if (minDatasets.length && onLoadPrevData) handlerGetPrevData(minDatasets, momentFirstTick);
    };
    // useEffect(() => {
    //     if (chartRef.current && listDatasets?.length) {
    //         // handlerGetNextData(listDatasets);
    //         handlerGetPrevData(listDatasets);
    //     }
    // }, [chartRef?.current, listDatasets]);
    const tooltipHtml = {
        enabled: false,
        // external: customTooltipData,
    };
    const panOptions = {
        enabled: true,
        mode: 'x',
        threshold: 5,
        scaleMode: 'x',
        onPanComplete,
    };
    const zoomOptions = {
        mode: 'x',
        wheel: {
            speed: 0.05,
            enabled: true,
        },
        pinch: {
            enabled: true,
        },
        onZoomComplete,
    };
    const plugins = {
        zoom: {
            pan: panOptions,
            zoom: zoomOptions,
        },
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        // tooltip: tooltipHtml,
        tooltip: {

            bodyFont: {
                size: '14px',
                weight: 500,
            },
            footerFont: {
                size: '14px',
                weight: 400,
            },
            titleFont: {
                size: '14px',
                weight: 400,
            },
            callbacks: {
                label(data) {
                    return data?.dataset?.label;
                },
                title(data) {
                    return moment(data[0]?.parsed?.x).format('DD.MM.YYYY  hh:mm');
                },
                footer(data) {
                    return data[0]?.parsed?.y.toLocaleString('ru');
                },
            },
            displayColors: false,
            backgroundColor: 'white',
            borderColor(data) {
                return data?.tooltipItems[0]?.dataset?.allColors?.color || '#dfdfdf';
            },
            // borderColor: '#dfdfdf',
            bodyColor: '#787E93',
            footerColor: '#787E93',
            titleColor: '#787E93',
            borderWidth: 1,
            borderRadius: 6,
        },
    };
    const scales = {
        x: {
            ticks: {
                padding: 10,
            },
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                    day: 'DD.MM',
                },
            },
        },
        y: {
            min: 0,
            ticks: {
                padding: 10,
                maxTicksLimit: 7,
                callback(value) {
                    if (Math.floor(value) === value) { return `${value}`; }
                    return '';
                },
            },
        },
    };
    const options: any = {
        animation: true,
        // spanGaps: 1000 * 60 * 60 * 24 * 1,
        responsive: true,
        maintainAspectRatio: false,
        beginAtZero,
        stacked: false,
        // events: ['click'],
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: listDatasets.length > 1,
            // intersect: listDatasets.length > 1,
        },
        elements: {
            line: {
                tension: 0.37,
            },
            point: {
                borderColor: 'white',
            },
        },
        scales,
        plugins,
    };
    useEffect(() => {
        // const ctx = chartRef?.current?.ctx;
        if (getImage && chartRef?.current) {
            // const imgChart = chartRef?.current?.toBase64Image('image/png');
            setTimeout(() => getImage(chartRef?.current?.toBase64Image('image/png')), 600);
        }
    }, [listDatasets]);
    const hideTooltip = () => {
        if (timerTooltip.current) return;
        timerTooltip.current = setTimeout(() => {
            setIsShowModal(false);
        }, 300);
    };
    const clearTimerTooltip = () => {
        if (!timerTooltip.current) return;
        clearTimeout(timerTooltip.current);
        timerTooltip.current = null;
    };
    const customTooltipData = (tooltipModel) => {
        if (!isActiveTooltip) return;
        if (tooltipModel.replay) return;
        const { tooltip } = tooltipModel;
        if (tooltip.opacity === 0) {
            clearTimerTooltip();
            hideTooltip();
            return;
        }
        setIsShowModal(true);
        // const position = chartRef.current.canvas.getBoundingClientRect();
        const left = tooltip.caretX;
        const top = tooltip.caretY;
        const data = tooltip.dataPoints[0].raw;
        clearTimerTooltip();
        setPositionAndData({
            top, left, data,
        });
    };

    const getOptions = useMemo(() => options, [options]);
    const getStyleContainer = useMemo(() => {
        const style: any = { width: width || '650px' };
        if (height) style.height = height;
        return style;
    }, [width, height]);
    return (
        <div className={styles.chartContainer} style={getStyleContainer}>
            <LoaderForChart data={objectHandler} />

            <Line plugins={[zoomPlugin]} ref={chartRef} options={getOptions} data={dataSet as any} />
            <TooltipWrapper
                isOpen={isShowModal}
                x={positionAndData.left}
                y={positionAndData.top}
                clearTimerTooltip={clearTimerTooltip}
                hideTooltip={hideTooltip}
            >
                {getTooltip ? getTooltip(positionAndData?.data?.content) : ''}
            </TooltipWrapper>
        </div>
    );
};

export default React.memo(ChartView);
