import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import classnames from 'classnames';
import useResizeObserver from 'use-resize-observer';
import Skeleton from 'react-loading-skeleton';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import { ICategory } from '@store/types';
import CampaignStatusWithSkeletonContainer from '@containers/campaign/Menu/CampaignStatusSkeleton';
import CampaignUsersSelectContainer from '@containers/campaign/UsersSelect';
import { Button } from '@shared/index';
import { ITrackedWordModel } from './TrackedWords/types';
import { ICampaignSimpleEntity } from './Sources/SourceTypes/types';
import CampaignSwitcher from './Switcher';
import { ICampaignUpdateFormProps, ICampaignUpdateFormState } from './types';
import CampaignNameInput from './NameInput/index';
import CampaignCategorySelect from './CategorySelect/index';
import { ICampaignCategoryModel } from './CategorySelect/types';
import CampaignSourcesBlock from './Sources';
import CampaignMessagesBlock from './Messages';
import CampaignTrackedWordsBlock from './TrackedWords';
import CampaignTonalityBlock from './Tonality';
import campaignUtils from '../../../utils/campaign';
import ValidationTooltip from '../Validation';
import styles from './styles.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import SvgIcon from '../../SvgIcon';

const prepareSource = (data: string[]): string[] => {
    if (data?.length <= 0) {
        return undefined;
    }
    if (data?.length === 1 && data[0] === 'ALL') {
        return null;
    }
    return data.filter((item) => item !== 'ALL');
};
const bloggersArrayToBool = (data: number[]): boolean | null => {
    if (
        data.some((value) => value === 1)
        || (data.find((value) => value === 2) && data.find((value) => value === 3))
    ) {
        return null;
    }
    return data.some((value) => value === 3);
};
const bloggersBoolToArray = (value: boolean | null): number[] => {
    if (value === null) {
        return [1, 2, 3];
    }
    if (value === undefined) {
        return [];
    }
    return value ? [3] : [2];
};
const sourcesStringToNumber = (data: string[]): number[] => {
    const result = data
        .map((name) => Number(campaignUtils.campaignPlatformsTypeState
            .find((item) => item.name === name)?.id))
        .filter((value) => Boolean(value));
    return result;
};
const sourcesNumberToString = (data: number[]): string[] => data
    .map((id) => campaignUtils.campaignPlatformsTypeState
        .find((item) => item.id === id)?.name)
    .filter((value) => !!value);
const prepareCategories = (data: number[], categories: ICategory[]): ICampaignCategoryModel[] => {
    if (!categories || !categories?.length || !data || !data?.length) {
        return [];
    }
    return data
        .map((id) => {
            const res = categories.find((item) => Number(item.id) === id);
            return { id: Number(res?.id), name: res?.mainCat };
        });
};
const TopComponentsHeight = 152;

const CampaignUpdateForm: React.FC<ICampaignUpdateFormProps> = ({
    name, categories,
    onCreateBegun, isCreating,
    needClear, onCleared,
    onCancelClick, onUpdateClick,
    isUpdate, isView,
    checkedSourceTypes, checkedBloggers,
    selectedCategories, selectedTrackedWords,
    selectedExcludedWords, selectedExcludedLinks,
    campaignId, isActive,
    onDeleteTrackedSource, isLoading,
    bufferSelectedTrackedSource,
    onClearAllSelectedSources, isExcludedLinks,
    isIPadSize, parseFrom,
    isSearchRequest, searchRequestStr,
    onCreateSearchRequest,
    selectedTrackedSource, onSelectedFavoriteSources,
    isLoadingCategories,
}): JSX.Element => {
    const { ref, height: rootHeight } = useResizeObserver<HTMLDivElement>();
    const [campaignName, setCampaignName] = useState<string>(name ?? '');
    const [isBlogger, setIsBlogger] = useState<boolean>(undefined);
    const [sourceType, setSourceType] = useState<string[]>([]);
    const [categoryList, setCategoryList] = useState<ICampaignCategoryModel[]>([]);
    const [trackedWords, setTrackedWords] = useState<ITrackedWordModel[]>([]);
    const [trackedLinks, setTrackedLinks] = useState<ITrackedWordModel[]>([]);
    const [excludedWords, setExcludedWords] = useState<ITrackedWordModel[]>([]);
    const [excludedLinks, setExcludedLinks] = useState<ITrackedWordModel[]>([]);
    const [isTrackedLinks, setTrackedLinksState] = useState<boolean>(true);
    const [isChanged, setChangedStatus] = useState<boolean>(false);
    const [isErrorHighlighting, setHighlighting] = useState<boolean>(false);

    const searchRequestLabel = useMemo<string>(() => {
        if (isView) {
            return 'Промотр запроса';
        }
        return searchRequestStr ? 'Изменить запрос' : 'Добавить запрос';
    }, [isView, searchRequestStr]);
    const stateBeforeUpdate = useMemo((): ICampaignUpdateFormState => ({
        campaignName: name,
        checkedBloggers,
        selectedCategories,
        selectedExcludedLinks,
        parseFrom,
        selectedExcludedWords,
        selectedTrackedWords,
        checkedSourceTypes,
        isExcludedLinks,
    }), [
        name, checkedBloggers,
        selectedCategories, selectedExcludedLinks,
        parseFrom, selectedExcludedWords,
        selectedTrackedWords,
        checkedSourceTypes, isExcludedLinks,
    ]);

    const scrollContentHeight = useMemo((): number | string => (rootHeight ? (rootHeight - TopComponentsHeight) : '100%'), [rootHeight]);
    const validationTrackWords = useMemo((): boolean => (
        isErrorHighlighting && !trackedWords?.length && !excludedWords?.length
    ), [isErrorHighlighting, trackedWords, excludedWords]);
    const validationSearchRequest = useMemo((): boolean => (
        isErrorHighlighting && ((isSearchRequest && !searchRequestStr) || !isSearchRequest)
    ), [isErrorHighlighting, isSearchRequest, searchRequestStr]);
    const isVisibleTrackedLinks = useMemo(
        (): boolean => (isView ? (Boolean(trackedLinks?.length) || Boolean(excludedLinks?.length)) : true),
        [trackedLinks, excludedLinks, isView],
    );

    const handlerDeleteWords = useCallback((
        setFunc: React.Dispatch<React.SetStateAction<ITrackedWordModel[]>>,
        data: ITrackedWordModel[],
    ) => (id: number, isSource: boolean): void => {
        setFunc([...data.filter((item) => item.id !== id)]);
        setChangedStatus(true);
        if (isSource) {
            onDeleteTrackedSource(isTrackedLinks, id);
        }
        onCreateBegun();
    }, [isTrackedLinks]);
    const handlerAddWords = (
        setFunc: React.Dispatch<React.SetStateAction<ITrackedWordModel[]>>,
        data: ITrackedWordModel[],
    ) => (value: string, inputId: number): void => {
        if (data.some(({ id, name: dName }) => id === inputId || dName === value)) {
            return;
        }
        const newId = data?.length ? (Math.max(...data.map(({ id }) => id)) + 1) : 1;
        setFunc([...data, { id: inputId ?? newId, name: value }]);
        setChangedStatus(true);
        onCreateBegun();
    };
    const handlerCancelClick = (): void => {
        onCancelClick();
    };
    const getBloggerValue = (checkedIds: number[]): boolean | null | undefined => {
        if (!checkedIds?.length) {
            return undefined;
        }
        if (checkedIds.some((id) => id === 1)) {
            return null;
        }
        if (checkedIds.some((id) => id === 2) && checkedIds.some((id) => id === 3)) {
            return null;
        }
        if (checkedIds.some((id) => id === 2)) {
            return false;
        }
        if (checkedIds.some((id) => id === 3)) {
            return true;
        }
        return undefined;
    };
    const handlerSetBlogger = (data: ICampaignSimpleEntity[]): void => {
        setChangedStatus(true);
        onCreateBegun();
        setIsBlogger(getBloggerValue(data.filter(({ checked }) => checked).map(({ id }) => id)));
    };
    const handlerSetSourceType = (data: ICampaignSimpleEntity[]): void => {
        setChangedStatus(true);
        onCreateBegun();
        setSourceType(
            data
                .filter(({ checked }) => checked)
                .map(
                    ({ id }) => campaignUtils.campaignPlatformsTypeState
                        .find((item) => item.id === id).value.toString(),
                ),
        );
    };
    const handlerDeleteCategory = (id: number): void => {
        setChangedStatus(true);
        onCreateBegun();
        setCategoryList([...categoryList.filter((item) => item.id !== id)]);
    };
    const handlerSelectCategory = (id: number): void => {
        setChangedStatus(true);
        onCreateBegun();
        const category = categories.find((item) => item.id.toString() === id.toString());
        if (category && !categoryList.some((item) => item.id === id)) {
            setCategoryList([...categoryList, { id, name: category.mainCat }]);
        }
    };
    const handlerChangeName = (value: string): void => {
        setChangedStatus(true);
        onCreateBegun();
        setCampaignName(value);
    };
    const getLinks = (isInclude: boolean = true): string[] => {
        if (isTrackedLinks && isInclude) {
            return trackedLinks?.length <= 0 ? [] : trackedLinks.map((item) => item.name);
        }
        if (!isTrackedLinks && !isInclude) {
            return excludedLinks?.length <= 0 ? [] : excludedLinks.map((item) => item.name);
        }
        return [];
    };

    const saveBtnDisabled = useMemo((): boolean => {
        if (isUpdate && !isChanged) {
            return true;
        }
        if (isBlogger === undefined) {
            return true;
        }
        if (!campaignName) {
            return true;
        }
        if ((isSearchRequest && !searchRequestStr) || !isSearchRequest) {
            if (!trackedWords?.length && !excludedWords?.length) {
                return true;
            }
        }
        if (!sourceType?.length) {
            return true;
        }
        return false;
    }, [
        sourceType, isBlogger, campaignName,
        trackedWords, excludedWords, isChanged,
        isUpdate,
    ]);

    const handlerUpdateClick = (): void => {
        if (saveBtnDisabled) {
            setHighlighting(true);
            return;
        }
        const createData: ICampaignDataModel = {
            isBlogger,
            sourceType: prepareSource(sourceType),
            name: campaignName,
            id: isUpdate ? campaignId : null,
            excludeLinks: getLinks(false),
            excludeWords: excludedWords?.length <= 0 ? [] : excludedWords.map((item) => item.name),
            includeWords: trackedWords?.length <= 0 ? [] : trackedWords.map((item) => item.name),
            parseFrom,
            categoriesIds: categoryList.length <= 0 ? [0] : categoryList.map((item) => item.id),
            isActive: false,
            userId: '',
            createTimestamp: '',
            createDate: '',
            owner: null,
            sources: selectedTrackedSource,
            excludeSources: [],
            noDelete: false,
            isTrackedLinks,
            parseFromDate: null,
            isComplexQuery: false,
            searchQuery: '',
        };
        setChangedStatus(false);
        onUpdateClick(createData);
    };

    useEffect(() => {
        if (needClear && !isUpdate) {
            setCampaignName(name);
            setCategoryList([]);
            setIsBlogger(undefined);
            setSourceType([]);
            setTrackedWords([]);
            setTrackedLinks([]);
            setExcludedWords([]);
            setExcludedLinks([]);
            onClearAllSelectedSources();
            onCleared();
        }
        if (needClear && isUpdate) {
            setCampaignName(stateBeforeUpdate.campaignName);
            setCategoryList(prepareCategories(stateBeforeUpdate.selectedCategories, categories));
            setIsBlogger(bloggersArrayToBool(stateBeforeUpdate.checkedBloggers));
            setSourceType(sourcesNumberToString(stateBeforeUpdate.checkedSourceTypes));
            setTrackedWords(stateBeforeUpdate.selectedTrackedWords.map((item, index) => ({ id: index, name: item })));
            setExcludedWords(stateBeforeUpdate.selectedExcludedWords.map((item, index) => ({ id: index, name: item })));
            const buffLinksSources = bufferSelectedTrackedSource.map((item) => ({ id: item.id, name: item.link }));
            setExcludedLinks(isExcludedLinks ? buffLinksSources : []);
            setTrackedLinks(isExcludedLinks ? [] : buffLinksSources);
            onCleared();
        }
    }, [needClear, stateBeforeUpdate, isUpdate, bufferSelectedTrackedSource, isExcludedLinks]);
    useEffect(() => {
        setCampaignName(name ?? '');
    }, [name]);
    useEffect(() => {
        if (!categories || !categories?.length || !selectedCategories || !selectedCategories?.length) {
            return;
        }
        setCategoryList(prepareCategories(selectedCategories, categories));
    }, [selectedCategories, categories, isUpdate]);
    useEffect(() => {
        setTrackedWords([...selectedTrackedWords
            .map((item, index) => ({ id: index, name: item })),
        ]);
    }, [selectedTrackedWords, isUpdate]);
    useEffect(() => {
        setExcludedWords([...selectedExcludedWords
            .map((item, index) => ({ id: index, name: item })),
        ]);
    }, [selectedExcludedWords, isUpdate]);
    useEffect(() => {
        if (!isExcludedLinks) {
            setTrackedLinks([...bufferSelectedTrackedSource
                .map((item) => ({ id: item.id, name: item.link })),
            ]);
        }
    }, [bufferSelectedTrackedSource, isUpdate, isExcludedLinks]);
    useEffect(() => {
        if (isExcludedLinks) {
            setExcludedLinks([...bufferSelectedTrackedSource
                .map((item) => ({ id: item.id, name: item.link })),
            ]);
        }
    }, [bufferSelectedTrackedSource, isUpdate, isExcludedLinks]);
    useEffect(() => {
        setSourceType(campaignUtils.getCampaignPlatformNamesByIds(checkedSourceTypes));
    }, [checkedSourceTypes]);
    useEffect(() => {
        setIsBlogger(getBloggerValue(checkedBloggers));
    }, [checkedBloggers]);

    useEffect(() => {
        if (!saveBtnDisabled && isErrorHighlighting) {
            setHighlighting(false);
        }
    }, [saveBtnDisabled]);
    useEffect(() => {
        setTrackedLinksState(!isExcludedLinks);
    }, [isExcludedLinks]);
    useEffect(() => {
        if (onSelectedFavoriteSources) {
            onSelectedFavoriteSources([...trackedLinks]);
        }
    }, [trackedLinks]);

    return (
        <div className={styles.root} ref={ref}>
            <div className={styles.nameContainer} style={isIPadSize ? { minWidth: 600, width: 850 } : null}>
                <CampaignNameInput
                    value={campaignName}
                    placeholder="Наименование кампании"
                    type="text"
                    setValue={handlerChangeName}
                    tagId="campaign-name"
                    isErrorHighlighting={isErrorHighlighting && !campaignName}
                    isView={isView}
                    isLoading={isLoading}
                    isIPadSize={isIPadSize}
                />
                {
                    (isUpdate || isView) && (
                        <CampaignStatusWithSkeletonContainer
                            campaignId={campaignId}
                            isView={isView}
                            isActive={isActive}
                        />
                    )
                }
            </div>
            <div style={{ height: scrollContentHeight }}>
                <div className={classnames(styles.subRoot, styles.scrollWrapper)}>
                    <CampaignCategorySelect
                        categories={categories}
                        selectedCategories={categoryList}
                        onSelectCategory={handlerSelectCategory}
                        onDeselectCategory={handlerDeleteCategory}
                        isView={isView}
                        isLoading={isLoading}
                    />
                    {
                        isLoadingCategories && (
                            <>
                                <Skeleton width={100} height={40} />
                                <Skeleton width={700} height={100} />
                            </>
                        )
                    }
                    {
                        !isLoading && (
                            <>
                                <div className={styles.titleBlock}>Ключевые слова</div>
                                {
                                    isSearchRequest && (
                                        <div className={styles.flexColumn}>
                                            <ValidationTooltip
                                                isVisible={validationSearchRequest}
                                                left={100}
                                                top={65}
                                                width={230}
                                                text="Пожалуйста, задайте поисковый запрос"
                                            />
                                            <div
                                                className={styles.title}
                                                style={{ marginTop: -8 }}
                                            >
                                                Поисковый запрос:
                                            </div>
                                            <div className={styles.searchRequest_container}>
                                                <button
                                                    type="button"
                                                    className={styles.searchRequest_btn}
                                                    onClick={onCreateSearchRequest}
                                                >
                                                    <span className={styles.searchRequest_text}>
                                                        {searchRequestLabel}
                                                    </span>
                                                </button>
                                                {
                                                    !searchRequestStr && (
                                                        <button
                                                            type="button"
                                                            className={classnames(styles.searchRequest_btn, styles.searchRequest_btnCircle)}
                                                            onClick={onCreateSearchRequest}
                                                        >
                                                            <SvgIcon id="Add_PLUS" />
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    !isSearchRequest && (
                                        <div className={styles.wordsWrapper} style={isIPadSize ? { width: 820 } : null}>
                                            <div className={styles.flexColumn}>
                                                <ValidationTooltip
                                                    isVisible={validationTrackWords}
                                                    left={100}
                                                    top={65}
                                                    width={230}
                                                    text="Пожалуйста, выберите тип слов"
                                                >
                                                    {/* <div */}
                                                    {/*    className={styles.title} */}
                                                    {/*    style={{ marginTop: -8 }} */}
                                                    {/* > */}
                                                    {/*    {`Тип слов${isView ? '' : '*'}:`} */}
                                                    {/* </div> */}
                                                    <div
                                                        className={styles.title}
                                                        style={{ marginTop: -8 }}
                                                    />
                                                </ValidationTooltip>
                                                <div className={styles.flexRow} style={isIPadSize ? { gap: 32 } : null}>
                                                    <CampaignTrackedWordsBlock
                                                        inputTagId="campaign-trackedwords"
                                                        addBtnTagId="campaign-trackedwords-add"
                                                        delBtnTagId="campaign-trackedwords-del"
                                                        addFavoriteTagId="campaign-trackedwords-favorite"
                                                        isDisabled={false}
                                                        verifiable={false}
                                                        isErrorHighlighting={validationTrackWords}
                                                        isView={isView}
                                                        title={`Отслеживаемые слова${isView ? '' : '*'}:`}
                                                        placeholder="Введите текст"
                                                        words={trackedWords}
                                                        onDeleteWord={handlerDeleteWords(setTrackedWords, trackedWords)}
                                                        onAddWord={handlerAddWords(setTrackedWords, trackedWords)}
                                                        withSearch={false}
                                                        sourceType={sourceType}
                                                        fullScreen={excludedWords?.length <= 0}
                                                        isTrackedSources={null}
                                                        isIPadSize={isIPadSize}
                                                    />
                                                    <CampaignTrackedWordsBlock
                                                        inputTagId="campaign-excludedwords"
                                                        addBtnTagId="campaign-excludedwords-add"
                                                        delBtnTagId="campaign-excludedwords-del"
                                                        isDisabled={false}
                                                        verifiable={false}
                                                        isErrorHighlighting={validationTrackWords}
                                                        isView={isView}
                                                        title={`Исключаемые слова${isView ? '' : '*'}:`}
                                                        placeholder="Введите текст"
                                                        words={excludedWords}
                                                        onDeleteWord={handlerDeleteWords(setExcludedWords, excludedWords)}
                                                        onAddWord={handlerAddWords(setExcludedWords, excludedWords)}
                                                        withSearch={false}
                                                        sourceType={sourceType}
                                                        fullScreen={trackedWords?.length <= 0}
                                                        isTrackedSources={null}
                                                        isIPadSize={isIPadSize}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        isLoading && (
                            <>
                                <Skeleton width={100} height={50} />
                                <Skeleton width={700} height={100} />
                            </>
                        )
                    }
                    {
                        !isLoading && (
                            <>
                                <div className={styles.titleBlock} style={{ width: 114 }}>Источники</div>
                                <div className={styles.wordsWrapper} style={isIPadSize ? { width: 820 } : null}>
                                    <CampaignSourcesBlock
                                        checkedSourceTypeIds={sourcesStringToNumber(sourceType)}
                                        checkedBloggerIds={bloggersBoolToArray(isBlogger)}
                                        handlerSetBlogger={handlerSetBlogger}
                                        handlerSetSourceType={handlerSetSourceType}
                                        isErrorHighlighting={isErrorHighlighting}
                                        isView={isView}
                                        isIPadSize={isIPadSize}
                                    />
                                    {
                                        isVisibleTrackedLinks && (
                                            <div className={styles.flexRow} style={isIPadSize ? { gap: 24 } : null}>
                                                <div className={styles.flexColumn}>
                                                    <div className={styles.title} style={{ marginTop: -8 }}>Тип источника:</div>
                                                    <div className={styles.switchContainer}>
                                                        <CampaignSwitcher
                                                            label="Отслеживаемые"
                                                            isInverse={false}
                                                            isActive={isTrackedLinks}
                                                            onSwitch={setTrackedLinksState}
                                                            isDisabled={false}
                                                        />
                                                        <CampaignSwitcher
                                                            label="Исключаемые"
                                                            isInverse
                                                            isActive={isTrackedLinks}
                                                            onSwitch={setTrackedLinksState}
                                                            isDisabled={false}
                                                        />
                                                    </div>
                                                    <div className={styles.flexRow} style={isIPadSize ? { gap: 32 } : null}>
                                                        <CampaignTrackedWordsBlock
                                                            inputTagId="campaign-source-link"
                                                            addBtnTagId="campaign-source-link-add"
                                                            delBtnTagId="campaign-source-link-del"
                                                            addFavoriteTagId="campaign-source-link-favorite"
                                                            isDisabled={!isTrackedLinks}
                                                            verifiable
                                                            isErrorHighlighting={false}
                                                            isView={isView}
                                                            title="Отслеживаемые источники:"
                                                            placeholder="Введите краткое описание"
                                                            words={trackedLinks}
                                                            onDeleteWord={handlerDeleteWords(setTrackedLinks, trackedLinks)}
                                                            onAddWord={handlerAddWords(setTrackedLinks, trackedLinks)}
                                                            withSearch
                                                            sourceType={sourceType}
                                                            fullScreen={false}
                                                            isTrackedSources={isTrackedLinks}
                                                            isIPadSize={isIPadSize}
                                                        />
                                                        <CampaignTrackedWordsBlock
                                                            inputTagId="campaign-source-excl-link"
                                                            addBtnTagId="campaign-source-excl-link-add"
                                                            delBtnTagId="campaign-source-excl-link-del"
                                                            isDisabled={isTrackedLinks}
                                                            verifiable
                                                            isErrorHighlighting={false}
                                                            isView={isView}
                                                            title="Исключаемые источники:"
                                                            placeholder="Введите ссылку"
                                                            words={excludedLinks}
                                                            onDeleteWord={handlerDeleteWords(setExcludedLinks, excludedLinks)}
                                                            onAddWord={handlerAddWords(setExcludedLinks, excludedLinks)}
                                                            withSearch
                                                            sourceType={sourceType}
                                                            fullScreen={false}
                                                            isTrackedSources={isTrackedLinks}
                                                            isIPadSize={isIPadSize}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: -8 }}>
                                                    {/* <CampaignSourceLinkInfo /> */}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                    {
                        false && (
                            <div className={styles.subRow}>
                                <div className={styles.subRow_column}>
                                    <div className={styles.titleBlockDisabled} style={{ width: 120 }}>Сообщения</div>
                                    <CampaignMessagesBlock />
                                </div>
                                <div className={styles.subRow_column}>
                                    <div className={styles.titleBlockDisabled} style={{ width: 120 }}>Тональность</div>
                                    <CampaignTonalityBlock />
                                </div>
                            </div>
                        )
                    }
                    {
                        isLoading && (
                            <>
                                <Skeleton width={100} height={50} />
                                <Skeleton width={700} height={100} />
                            </>
                        )
                    }
                    {
                        !isLoading && (
                            <>
                                <div className={classnames(styles.titleBlock)} style={{ width: 300 }}>
                                    Дата начала сбора информации
                                </div>
                                <div className={styles.parseFromBox}>
                                    <span className={styles.parseFromBox_text}>{parseFrom}</span>
                                </div>
                            </>
                        )
                    }
                    {
                        isUpdate && (
                            <div className={styles.usersBox}>
                                <span className={classnames(styles.label, styles.middle)}>Пользователи кампании:</span>
                                <CampaignUsersSelectContainer tagId="campaign-users-select" campaignId={campaignId} />
                            </div>
                        )
                    }
                </div>
            </div>
            {
                !isView && (
                    <div className={styles.bottomContainer}>
                        <div className={styles.pinnedInfo}>
                            <div className={classnames(styles.label, styles.small)}>* Поля обязательные к заполнению</div>
                        </div>
                        <div className={styles.buttonsContainer}>
                            {
                                (isCreating || isUpdate) && (
                                    <Button
                                        tagId="campaign-cancel"
                                        text={isUpdate ? 'Отмена' : 'Очистить'}
                                        type="secondary"
                                        size="large"
                                        onClick={handlerCancelClick}
                                    />
                                )
                            }
                            <Button
                                tagId="campaign-save"
                                text="Сохранить"
                                type="default"
                                size="large"
                                onClick={handlerUpdateClick}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default CampaignUpdateForm;
