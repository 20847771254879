import React, { useState } from 'react';
import classnames from 'classnames';
import RequestAccessInput from '../Input/RequestAccessInput';
import PostalEnvelopeIcon from '../../../assets/images/postal-envelope-white.svg';
import PostalEnvelopeGrayIcon from '../../../assets/images/postal-envelope-gray.svg';
import { IAccessFormProps } from './types';
import { IRequestAccess } from '../../../store/request-access/types';

import styles from './styles.module.scss';

const AccessForm: React.FC<IAccessFormProps> = ({ onSendRequest }): JSX.Element => {
    const [userName, setUserName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [organizationName, setOrganization] = useState<string>('');
    const [userPost, setUserPost] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    // const [errorEmail, setErrorEmail] = useState<string>('');
    // const [errorPhone, setErrorPhone] = useState<string>('');

    const btnIsDisabled = (): boolean => (!userName || !email || !phone || !organizationName || !userPost);

    const handleChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setComment(event.target.value);
    };

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                Чтобы провести демонстрацию наших услуг, заполните форму ниже:
            </div>
            <RequestAccessInput
                tagId="request-name"
                placeholder="Как вас зовут?*"
                value={userName}
                type="text"
                setValue={setUserName}
            />
            <RequestAccessInput
                tagId="request-workemail"
                placeholder="Рабочая почта*"
                value={email}
                type="email"
                setValue={setEmail}
            />
            <RequestAccessInput
                tagId="request-tel"
                placeholder="Телефон*"
                value={phone}
                type="phone"
                setValue={setPhone}
            />
            <RequestAccessInput
                tagId="request-ogranisation"
                placeholder="Название организации*"
                value={organizationName}
                type="text"
                setValue={setOrganization}
            />
            <RequestAccessInput
                tagId="request-jobtitle"
                placeholder="Должность*"
                value={userPost}
                type="text"
                setValue={setUserPost}
            />
            <div className={styles.labelText}>
                Дополнительная информация
            </div>
            <textarea
                id="request-dopinfo"
                className={styles.textareaField}
                value={comment}
                onChange={handleChangeComment}
            />
            <div className={styles.buttonContainer}>
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                    id="request-send"
                    className={classnames(
                        styles.buttonContainer_button,
                        styles[btnIsDisabled() ? 'disabled' : ''],
                    )}
                    disabled={btnIsDisabled()}
                    onClick={() => onSendRequest(
                        {
                            email,
                            phone,
                            comment,
                            organizationName,
                            userName,
                            userPost,
                        } as IRequestAccess,
                    )}
                >
                    <span className={classnames(
                        styles.buttonContainer_button_text,
                        styles[btnIsDisabled() ? 'disabled' : ''],
                    )}
                    >
                        Отправить
                    </span>
                    {
                        btnIsDisabled()
                            ? <img src={PostalEnvelopeGrayIcon} alt="email" />
                            : <img src={PostalEnvelopeIcon} alt="email" />
                    }
                </button>
            </div>
        </div>
    );
};

export default AccessForm;
