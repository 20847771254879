/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Carousel } from 'antd';
import React, { useMemo, useRef } from 'react';
import SvgIcon from '../SvgIcon';

interface IPropsCarousel {
    imageList?: string[]
}

const CaruselFromListLinkImages: React.FC<IPropsCarousel> = ({ imageList = [] }) => {
    const carouselRef = useRef(null);
    const changeSlide = (isNext) => {
        if (carouselRef.current) {
            if (isNext) carouselRef.current.next();
            else carouselRef.current.prev();
        }
    };
    const getSlides = useMemo(() => imageList.map((link) => (
        <img key={`${link}_key`} src={link} alt="slide" style={{ height: '160px', width: '160px' }} />
    )), [imageList]);
    if (!imageList.length) return <div />;
    const getArrowRight = useMemo(() => (
        imageList.length > 1 ? (
            <div style={{ cursor: 'pointer', marginLeft: '16px' }} onClick={() => changeSlide(true)}>
                <SvgIcon id="ArrowRight" />
            </div>
        ) : <div />
    ), [imageList]);
    const getArrowLeft = useMemo(() => (
        imageList.length > 1 ? (
            <div
                style={{
                    cursor: 'pointer', transform: 'rotate(180deg)', marginTop: '-28px', marginRight: '18px',
                }}
                onClick={() => changeSlide(false)}
            >
                <SvgIcon id="ArrowRight" />
            </div>
        ) : <div />
    ), [imageList]);
    return (
        <div style={{
            display: 'flex', alignItems: 'center', marginLeft: '16px',
        }}
        >
            {getArrowLeft}
            <div style={{ width: '160px' }}>
                <Carousel ref={carouselRef}>
                    {getSlides}
                </Carousel>
            </div>
            {getArrowRight}
        </div>
    );
};

export default CaruselFromListLinkImages;
