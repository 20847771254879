import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutlet } from 'react-router';
import { checkCookies } from 'cookies-next';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sourceListActions } from '@store/source/list';
import Path from '@shared/lib/paths';
import CommonMessageModalContainer from '@containers/modal/MessageModal';
import { authActions } from '@store/auth';
import { useAppDispatch } from '@store/hooks';
import { selectIsAuth } from '@store/auth/selectors';
import Layout from '@components/Layout';
// import CheckAnalizedTrace from '@components/CheckAnalizedTrace';
import { campaignActions } from '@store/campaign/source';
import { CampaignActionTypes } from '@store/campaign/source/types';
import EventBusCookie from '../../utils/CheckCookie/EventBusCookie';
import campaignSelectors from '../../store/campaign/source/selectors';
import { AUTH_COOKIE_NAME } from '../../utils/constants';
import HandlerStack from '../../deprecated/api/HandlerAPI';

const ProtectedLayout: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const outlet = useOutlet();
    const location = useLocation();
    const navigate = useNavigate();

    const actionCampaign = useSelector(campaignSelectors.actionCampaign);
    const isAuthFromStore = useSelector(selectIsAuth);

    const [isAuth, setIsAuth] = useState(checkCookies(AUTH_COOKIE_NAME));

    const unauthorization = useCallback(() => {
        if (isAuth) setIsAuth(false);
        dispatch(campaignActions.clearAll());
    }, [isAuth]);
    useEffect(() => {
        EventBusCookie.on('unauthorization', unauthorization);
        return () => {
            EventBusCookie.off('unauthorization', unauthorization);
        };
    }, [unauthorization]);
    useEffect(() => {
        if (actionCampaign === CampaignActionTypes.edit && !location.pathname.includes('update')) {
            dispatch(campaignActions.clearActionCampaign());
        }
    }, [location, actionCampaign]);
    useEffect(() => {
        const toPath = `${Path.Campaign}/${Path.CampaignList}`;
        const compareStr = `/${Path.Campaign}/${Path.CampaignUpdate}`;
        if (location.pathname === `${compareStr}/` || location.pathname === compareStr) {
            navigate(toPath);
        }

        // чистим фильтры и страницы в таблице с источниками TODO нужен обработчик для роутинга
        if (!location.pathname.includes(Path.Sources)) {
            dispatch(sourceListActions.clear());
        }
    }, [location]);

    if (!isAuth) {
        dispatch(campaignActions.clearAll());
        HandlerStack.clearCheckToken();
        return <Navigate to="/auth" />;
    }
    if (!isAuthFromStore) {
        dispatch(authActions.setAuth());
    }
    return (
        <Layout>
            {/* <CheckAnalizedTrace> */}
            {/*    {outlet} */}
            {/* </CheckAnalizedTrace> */}
            {outlet}
            <CommonMessageModalContainer />
        </Layout>
    );
};

export default ProtectedLayout;
