import React from 'react';
import SourcePosts from '../../features/source/posts';
import ContentWidget from '../../components/Source/ContentWidget';
import SourceHeader from './SourceHeader';
import styles from './styles.module.scss';
import { SourcePageTab } from './types';

interface IProps {
    sourceId: number;
    tab: SourcePageTab;
    handleChangeTab: (value: SourcePageTab) => void;
}

const SourcePageComponent: React.FC<IProps> = (
    props: IProps,
): React.ReactElement => {
    const { sourceId, tab, handleChangeTab } = props;
    return (
        <div className={styles.wrapper} key={sourceId}>
            <SourceHeader sourceId={sourceId} />
            <div className={styles.tabs}>
                <button
                    type="button"
                    onClick={() => handleChangeTab(SourcePageTab.info)}
                    data-active={tab === SourcePageTab.info}
                >
                    Сводная информация
                </button>
                <button
                    type="button"
                    onClick={() => handleChangeTab(SourcePageTab.posts)}
                    data-active={tab === SourcePageTab.posts}
                >
                    Посты
                </button>
            </div>

            {tab === SourcePageTab.info && (
                <ContentWidget
                    sourceId={sourceId}
                    tab={tab}
                />
            )}

            {tab === SourcePageTab.posts && <SourcePosts sourceId={sourceId} />}
        </div>
    );
};
export default SourcePageComponent;
