import { IFilter } from 'sections/MyCampaignContainer/CampaingTypes';
import { rtkQApi } from '../..';
import {
    ICommonFiltersFromApi, IQPCampaignAgg, IQPSummary, ISummaryFromApi,
} from './types';
import campaignUtils from '../../../utils/campaign';
import { ICampaignSummaryData } from '../../../store/campaign/summary/types';
import { campaignComparisonActions, IComparisonFilters, IFilterCampaigns } from '../../../store/campaign/comparison';

const getFromDate = (timeFrame: string): string => campaignUtils.getCampaignParseFromDateNoString(
    timeFrame === '7' ? '2' : '3',
).toISOString();

export const campaignStatisticsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postLoadCampaignFilters: build.query<IFilterCampaigns, IQPCampaignAgg>({
            query: (params: IQPCampaignAgg) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: 'campaign/filters',
                    method: 'POST',
                    body: {
                        campaign_id: params.id,
                        filters_include: [],
                        date_periods: [{ from_date: params.fromDate, to_date: params.toDate }],
                        days: [],
                    },
                };
            },
            transformResponse: (baseQueryResponse: ICommonFiltersFromApi[], meta, arg): IFilterCampaigns => (
                {
                    campaignId: arg.id,
                    filters: baseQueryResponse.map((item) => ({
                        filtersCategory: item?.filters_category,
                        totalFiltersCount: item?.total_filters_count,
                        filters: item?.filters?.map((filter) => ({
                            checked: filter?.checked,
                            filter_name: filter?.filter_name,
                            count: filter?.count,
                        } as IFilter)),
                    } as IComparisonFilters)),
                }
            ),
            async onQueryStarted(args: IQPCampaignAgg, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(campaignComparisonActions.addFilters(data));
                        dispatch(campaignComparisonActions.removeIdForFetchFilters(args.id));
                    })
                    .catch((error) => {
                        dispatch(campaignComparisonActions.setFiltersFailedStatus());
                        dispatch(campaignComparisonActions.setFetchErrors(error.message));
                    });
            },
        }),
        postLoadCampaignAgg: build.query<ICampaignSummaryData | null, IQPCampaignAgg>({
            query: (params: IQPCampaignAgg) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                return {
                    url: `campaign/${params.id}/campaign_agg`,
                    method: 'POST',
                    body: {
                        from_date: params.fromDate,
                        to_date: params.toDate,
                    },
                };
            },
            transformResponse: (baseQueryResponse: ISummaryFromApi): ICampaignSummaryData | null => ({
                quantityAuthors: baseQueryResponse?.count_authors,
                quantityBloggerPost: baseQueryResponse?.count_blogger_post,
                quantityPost: baseQueryResponse?.count_post,
                quantityLikes: baseQueryResponse?.count_likes,
                quantitySmiPost: baseQueryResponse?.count_smi_post,
                quantityViews: baseQueryResponse?.count_views,
                quantityReactions: baseQueryResponse?.count_reactions,
                quantityComments: baseQueryResponse?.count_comments,
            }),
            async onQueryStarted(args: IQPCampaignAgg, { dispatch, queryFulfilled }) {
                queryFulfilled
                    .then(({ data }) => {
                        dispatch(campaignComparisonActions.addSummaryData({ data, campaignId: args.id }));
                        dispatch(campaignComparisonActions.removeIdForFetchAgg(args.id));
                    })
                    .catch((error) => {
                        dispatch(campaignComparisonActions.setSummaryFailedStatus());
                        dispatch(campaignComparisonActions.setFetchErrors(error.message));
                    });
            },
        }),
        postLoadCampaignSummary: build.query<ICampaignSummaryData | null, IQPSummary>({
            query: (params: IQPSummary) => {
                if (!Number.isInteger(params?.id)) {
                    throw new Error('The "id" parameter should be a number');
                }
                const fromDate = getFromDate(params.period);
                const toDate = new Date().toISOString();
                return {
                    url: `campaign/${params.id}/campaign_agg`,
                    method: 'POST',
                    body: {
                        from_date: fromDate,
                        to_date: toDate,
                    },
                };
            },
            transformResponse: (baseQueryResponse: ISummaryFromApi): ICampaignSummaryData | null => ({
                quantityAuthors: baseQueryResponse?.count_authors,
                quantityBloggerPost: baseQueryResponse?.count_blogger_post,
                quantityPost: baseQueryResponse?.count_post,
                quantityLikes: baseQueryResponse?.count_likes,
                quantitySmiPost: baseQueryResponse?.count_smi_post,
                quantityViews: baseQueryResponse?.count_views,
                quantityReactions: baseQueryResponse?.count_reactions,
                quantityComments: baseQueryResponse?.count_comments,
            }),
        }),
    }),
});
