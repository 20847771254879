import { Button, SvgIcon, ModalMessageContainer } from '@shared/index';
import { CommonIconsIds, CrossIconsIds } from '@app/constants/icons';
import classnames from 'classnames';
import styles from './styles.module.scss';

type ModalErrorType = 'error' | 'warning';

interface IProps {
    visible: boolean;
    type: ModalErrorType;
    title: string;
    text: string;
    onClose: () => void;
}

const typeStylesMap = new Map([
    ['error', styles.error],
    ['warning', styles.warning],
]);

const ModalError: React.FC<IProps> = (props: IProps): React.ReactElement => {
    const {
        visible, type, title, text, onClose,
    } = props;

    if (!visible) return null;

    return (
        <ModalMessageContainer>
            <div className={classnames(styles.header, typeStylesMap.get(type))}>
                <div className={styles.headerIcon}>
                    {type === 'error' && <SvgIcon id={CommonIconsIds.Error} size={24} />}
                    {type === 'warning' && <SvgIcon id={CommonIconsIds.Warning} size={24} />}
                </div>
                <span>{title}</span>
                <button type="button" onClick={onClose}>
                    <SvgIcon id={CrossIconsIds.WhiteCross} size={18} />
                </button>
            </div>
            <div className={styles.content}>
                <p>{text}</p>
            </div>
            <div className={styles.footer}>
                <Button
                    text="Закрыть"
                    type="danger"
                    size="medium"
                    onClick={onClose}
                />
            </div>
        </ModalMessageContainer>
    );
};
export default ModalError;
