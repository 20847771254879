import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import DefaultWrapperProject from '@components/Project/DefaultWrapper';
import IconButtonWithLabel from '@entities/IconButtonWithLabel';
import {
    Button, PlatformCell, SvgIcon, Tooltip,
} from '@shared/index';
import {
    IEntity, ISourceRow, SortOrder, SourceFieldsEnum,
} from '@services/sources/types';
import { ILongListRow } from '@entities/TableLongList/types';
import PlatformFilterForTable from '@entities/table-components/PlatformFilter';
import sourceMaps from '@app/constants/sourceMaps';
import { projectLonglistRtkQService } from '@services/projects/longlist';
import {
    EditNumber, SimpleFilter, SourceCell, Table,
} from '@entities/index';
import SavedFilters from '@widgets/project/SavedFilters';
import Sidebar from '@modals/Sidebar';
import ModalXlsxExport from '@entities/ModalXlsxExport';
import { IModalXlsxExportValue } from '@entities/ModalXlsxExport/types';
import { ITableColumn } from '@entities/Table/types';
import { IQPPlanFields } from 'services/projects/types/longlist/queryParams';
import classnames from 'classnames';
import { longlistHeaderCellsTooltips } from '@app/constants/tooltip';
import { ICategory, ISimpleEntity } from 'store/types';
import { ICheckItem } from 'shared/ui-components/CheckBoxList/types';
import AddMediaPlan from '@modals/AddMediaPlan';
import SourcesForProject from '@modals/SourcesForProject';
import DeleteModal from '@modals/DeleteModal';
import { sourcesAdvancedFiltersRtkQService, sourcesRtkQService } from '@services/sources';
import { message } from 'antd';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import LongListTooltip from './Tooltip';
import styles from './styles.module.scss';

const getSentences = (arr) => {
    const res = arr.reduce((summ, elem) => `${summ} ${elem}`);
    return res;
};
interface IProps {
    projectId: number;
    projectName: string;
    stage: number;
    isLoadingMediaPlan?: boolean;
    platforms: IEntity[];
    addSourcesIsOpen: boolean;
    isLoadingPlatforms: boolean;
    isLoadingCategories: boolean;
    categories: ICategory[];
    openAdd: () => void;
    setSourcesListState: (status: boolean) => void;
    onCreateMediaPlan: (startDate: Date, endDate: Date, sources: ISourceRow[]) => void;
}

const MAX_LENGTH_FOR_PROJECT_NAME = 60;

const initialModalExportValues: IModalXlsxExportValue[] = [
    { selected: true, name: 'Источник', value: SourceFieldsEnum.title },
    { selected: true, name: 'Платформа', value: SourceFieldsEnum.typeTitle },
    { selected: true, name: 'Автор', value: SourceFieldsEnum.authorTitle },
    { selected: true, name: 'Категории', value: SourceFieldsEnum.categoryTitles },
    { selected: true, name: 'Подписчики', value: SourceFieldsEnum.subscribers },
    // { selected: true, name: 'ER', value: SourceFieldsEnum.erAvg },
    {
        selected: true,
        name: 'Просмотры План',
        value: SourceFieldsEnum.planViews,
    },
    {
        selected: true,
        name: 'Просмотры Среднее',
        value: SourceFieldsEnum.avgViews,
    },
    {
        selected: true,
        name: 'ER План',
        value: SourceFieldsEnum.planER,
    },
    {
        selected: true,
        name: 'ER Среднее',
        value: SourceFieldsEnum.avgER,
    },
    {
        selected: true,
        name: 'CPV План',
        value: SourceFieldsEnum.planCPV,
    },
    {
        selected: true,
        name: 'CPV Среднее',
        value: SourceFieldsEnum.avgCPV,
    },
    { selected: true, name: 'Цена', value: SourceFieldsEnum.price },
];

const getProjectNameForFile = (name: string): string => {
    if (!name) {
        return '-';
    }
    return name.length > MAX_LENGTH_FOR_PROJECT_NAME ? name.substring(0, MAX_LENGTH_FOR_PROJECT_NAME) : name;
};

const calcCpv = (price: number, viewsAvg: number): number => {
    if (!price || !viewsAvg || price <= 0 || viewsAvg <= 0) {
        return 0;
    }
    return price / viewsAvg;
};

const getValueWithUnit = (value: string, unit: string, fractionalPart: number): JSX.Element => {
    if (!value || Number.isNaN(Number(value))) {
        return <div>-</div>;
    }
    const rounded = Math.round(Number(value) * fractionalPart) / fractionalPart;
    return (
        <div>
            {`${rounded?.toLocaleString('ru-RU')} ${unit}`}
        </div>
    );
};

const getRoundValue = (value: string, fractionalPart: number): number => {
    if (!value || Number.isNaN(Number(value))) {
        return 0;
    }
    return Math.round(Number(value) * fractionalPart) / fractionalPart;
};

const dateNow = new Date();

const LongList: React.FC<IProps> = ({
    projectId,
    projectName,
    stage,
    isLoadingMediaPlan,
    addSourcesIsOpen,
    platforms,
    isLoadingPlatforms,
    isLoadingCategories,
    categories,
    openAdd,
    setSourcesListState,
    onCreateMediaPlan,
}): JSX.Element => {
    const [checkedPlatformsIds, setPlatformFilter] = useState<number[]>([]);
    const [showSavedSearch, setShowSavedSearchState] = useState<boolean>(false);
    const [modalExportVisible, setModalExportVisible] = useState<boolean>(false);
    const [isOpen, setOpen] = useState<boolean>(true);
    const [addMediaPlanIsOpen, setMediaPlanModalState] = useState<boolean>(false);
    const [modalExportValues, setModalExportVisibleValues] = useState<IModalXlsxExportValue[]>(initialModalExportValues);
    const [modalRemoveSource, setModalRemoveSource] = useState<{ id: number }>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const success = (content) => {
        messageApi.open({
            type: 'success',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_succes,
        });
    };
    const error = (content) => {
        messageApi.open({
            type: 'error',
            content,
            duration: 3,
            icon: <div />,
            className: styles.message_error,
        });
    };
    const [
        loadSources,
        {
            data: sourcesLongList,
            isLoading: isLoadingSources,
        },
    ] = projectLonglistRtkQService.useLazyLoadSourcesForLonglistQuery();
    const updateModalExportValues = (values: IModalXlsxExportValue[]): void => {
        setModalExportVisibleValues(values);
    };
    const [removeSource] = sourcesRtkQService.useRemoveSourcesFromLongListMutation();
    const [
        getSavedFilters,
        {
            data: savedFilters,
            isLoading: isLoadingFilters,
            isFetching: isFetchingFilters,
            isError: isErrorFilters,
            isSuccess: isSuccessFilters,
            error: errorFilters,
        },
    ] = sourcesAdvancedFiltersRtkQService.useLazyGetLoadSavedFiltersQuery();

    /**
     * Проверка на пустые сохранённые фильтры
     */
    const savedFiltersIsAbsent = useMemo<boolean>(() => {
        if (!isLoadingFilters && !isFetchingFilters && !isSuccessFilters) {
            return isErrorFilters && (errorFilters as FetchBaseQueryError)?.status === 404;
        }
        if (savedFilters && isSuccessFilters) {
            return !(
                savedFilters?.author_id?.length > 0
                || savedFilters?.category_id?.length > 0
                || savedFilters?.type_id?.length > 0
                || savedFilters?.er_avg
                || savedFilters?.err
                || savedFilters?.err24
                || savedFilters?.subscribers
                || savedFilters?.views_avg
            );
        }
        return false;
    }, [isLoadingFilters, isFetchingFilters, isErrorFilters, errorFilters, isSuccessFilters, savedFilters]);

    const [currentOpenCellId, setOpenCellId] = useState<string>('');
    const defaultSources = useMemo((): ILongListRow[] => sourcesLongList?.sources.map((source) => ({
        id: source?.id,
        source: source?.title,
        sourceLink: source?.link,
        sourceId: source?.sourceId,
        icon: null,
        author: source?.author?.title || 'автор',
        authorId: source?.author?.id ?? -1,
        category: source?.categories?.length > 0 ? source?.categories : [],
        subscribers: source?.subscribers,
        er: source?.er,
        price: source?.price || 0,
        platform: source?.sourceType?.title || 'интернет',
        logoUrl: source?.logoUrl,
        planViews: source?.planViews,
        planEr: source?.planEr,
        planCpv: source?.planCpv,
        viewsAvg: source?.viewsAvg,
        cpv: source?.cpv ?? calcCpv(source?.price, source?.viewsAvg),
        actions: null,
    })), [sourcesLongList]);

    const [downloadSources, { data: downloadedSourcesXlsx }] = projectLonglistRtkQService.useLazyPostDownloadSourcesQuery();
    const [updatePlanFields] = projectLonglistRtkQService.usePutUpdatePlanFieldsMutation();

    const [rows, setRows] = useState<ILongListRow[]>(defaultSources);
    const [selectedAuthorsId, setSelectedAuthorsId] = useState<number[]>([]);
    const [selectedCategoriesId, setSelectedCategoriesId] = useState<number[]>([]);

    const platformsInSources = useMemo(() => [
        ...(new Set(defaultSources?.map(({ platform }) => platform))).values(),
    ], [defaultSources]);

    const authorsInSources = useMemo(() => {
        const result: ISimpleEntity[] = [];
        defaultSources?.forEach(({ author, authorId }) => {
            if (!result.some(({ id }) => id === authorId)) {
                result.push({
                    id: authorId ?? -1,
                    value: author,
                } as ISimpleEntity);
            }
        });
        return result;
    }, [defaultSources]);

    const categoriesInSources = useMemo(() => {
        const result: ISimpleEntity[] = [];
        defaultSources?.forEach(({ category }) => {
            category.forEach((item) => {
                if (result.every(({ id }) => item.id !== id)) {
                    result.push({
                        id: item?.id ?? -1,
                        value: item?.title ?? '-',
                    } as ISimpleEntity);
                }
            });
        });
        return result;
    }, [defaultSources]);

    const authorsForFilter = useMemo<ICheckItem[]>(() => authorsInSources.map((item) => ({
        id: item.id,
        isChecked: selectedAuthorsId.includes(Number(item.id)),
        label: item.value,
        value: item.value,
    } as ICheckItem)), [authorsInSources, selectedAuthorsId]);

    const categoriesForFilter = useMemo<ICheckItem[]>(() => categoriesInSources.map((item) => ({
        id: item.id,
        isChecked: selectedCategoriesId.includes(Number(item.id)),
        label: item.value,
        value: item.value,
    } as ICheckItem)), [selectedCategoriesId, categoriesInSources]);

    const handlerSelectPlatform = (ids: number[]): void => {
        setPlatformFilter(ids);
        if (ids?.length > 0) {
            setRows(
                defaultSources?.filter((item) => ids.includes(sourceMaps.platformsShortsMap.get(item.platform)?.id)),
            );
        } else {
            setRows(defaultSources);
        }
    };

    const handlerChangePlanViews = (id: number, sourceId: number, inputValue: string): void => {
        const planViews = Number(inputValue);
        let planValues = {
            planViews,
            planEr: null,
            planCpv: null,
            price: null,
        };
        setRows((prev) => prev.map((item) => {
            if (item.id === id) {
                planValues = {
                    ...planValues,
                    planEr: item?.planEr,
                    planCpv: item?.planCpv,
                    price: item?.price,
                };
                return { ...item, planViews };
            }
            return item;
        }));
        updatePlanFields({
            projectId,
            sourceId,
            id,
            planViews,
            planEr: planValues.planEr,
            planCpv: planValues.planCpv,
            price: planValues.price,
        } as IQPPlanFields);
    };

    const handlerChangePlanEr = (id: number, sourceId: number, inputValue: string): void => {
        const planEr = Number(inputValue);
        let planValues = {
            planViews: null,
            planEr,
            planCpv: null,
            price: null,
        };
        setRows((prev) => prev.map((item) => {
            if (item.id === id) {
                planValues = {
                    ...planValues,
                    planViews: item?.planViews,
                    planCpv: item?.planCpv,
                    price: item?.price,
                };
                return { ...item, planEr };
            }
            return item;
        }));
        updatePlanFields({
            projectId,
            sourceId,
            id,
            planViews: planValues.planViews,
            planEr,
            planCpv: planValues.planCpv,
            price: planValues.price,
        } as IQPPlanFields);
    };

    const handlerChangePlanCpv = (id: number, sourceId: number, inputValue: string): void => {
        const planCpv = Number(inputValue);
        let planValues = {
            planViews: null,
            planEr: null,
            planCpv,
            price: null,
        };
        setRows((prev) => prev.map((item) => {
            if (item.id === id) {
                planValues = {
                    ...planValues,
                    planViews: item?.planViews,
                    planEr: item?.planEr,
                    price: item?.price,
                };
                return { ...item, planCpv };
            }
            return item;
        }));
        updatePlanFields({
            projectId,
            sourceId,
            id,
            planViews: planValues.planViews,
            planEr: planValues.planEr,
            planCpv,
            price: planValues.price,
        } as IQPPlanFields);
    };

    const handlerChangePrice = (id: number, sourceId: number, inputValue: string): void => {
        const price = Number(inputValue);
        let planValues = {
            planViews: null,
            planEr: null,
            planCpv: null,
            price,
        };
        setRows((prev) => prev.map((item) => {
            if (item.id === id) {
                planValues = {
                    ...planValues,
                    planViews: item?.planViews,
                    planEr: item?.planEr,
                    planCpv: item?.planCpv,
                };
                if (item.viewsAvg !== 0) {
                    planValues.planCpv = price / item.viewsAvg;
                }
                return { ...item, price, planCpv: planValues.planCpv };
            }
            return item;
        }));
        updatePlanFields({
            projectId,
            sourceId,
            id,
            planViews: planValues.planViews,
            planEr: planValues.planEr,
            planCpv: planValues.planCpv,
            price,
        } as IQPPlanFields);
    };

    /**
     * Обновляет список выбранных фильтров, для компонентов SimpleFilter
     * @param {number} filterItemId - id элемента в списке фильтров
     * @param {boolean} isChecked - флаг (выбран или нет)
     * @param {React.Dispatch<React.SetStateAction<number[]>>} setStateAction - функция по изменению state списка выбранных фильтров
     */
    const handlerSelectSimpleFilters = (
        filterItemId: number,
        isChecked: boolean,
        setStateAction: React.Dispatch<React.SetStateAction<number[]>>,
    ): void => {
        setStateAction((prev) => {
            if (prev.includes(filterItemId)) {
                if (!isChecked) {
                    return prev.filter((item) => item !== filterItemId);
                }
                return prev;
            }
            if (isChecked) {
                return [...prev, filterItemId];
            }
            return prev;
        });
    };
    const handleClickOnRemoveSource = (row) => {
        setModalRemoveSource({ id: row.id });
    };
    const columns: ITableColumn<ILongListRow>[] = [
        {
            id: 1,
            title: null,
            dataIndex: 'id',
            width: 40,
            isIndex: true,
        },
        {
            id: 2,
            title: 'Источник ',
            dataIndex: 'source',
            sortable: false,
            width: 332,
            render: (value, row) => (
                <div style={{ padding: '6px 0px' }}>
                    <SourceCell value={value} logoUrl={row?.logoUrl} sourceId={row?.sourceId} />
                </div>
            ),
        },
        {
            id: 3,
            title: 'Платформа ',
            dataIndex: 'platform',
            sortable: false,
            width: 160,
            render: (value) => (<PlatformCell platformName={value} />),
            renderFilter: () => (
                <PlatformFilterForTable
                    onSelect={handlerSelectPlatform}
                    platformByIdMap={sourceMaps.platformByIdMap}
                    platformsIcon={sourceMaps.platformsIcon}
                    platformByNameMap={sourceMaps.platformByNameMap}
                    platformsFromApi={platforms?.filter(({ title }) => platformsInSources.includes(title))}
                    checkedFiltersIds={checkedPlatformsIds}
                />
            ),
            filterModalParam: {
                width: 180,
            },
        },
        {
            id: 4,
            title: 'Автор ',
            dataIndex: 'author',
            sortable: false,
            width: 150,
            renderFilter: () => (
                <SimpleFilter
                    items={authorsForFilter}
                    onSelect={
                        (id: number, isChecked: boolean) => handlerSelectSimpleFilters(id, isChecked, setSelectedAuthorsId)
                    }
                />
            ),
        },
        {
            id: 5,
            title: 'Категории ',
            dataIndex: 'category',
            sortable: false,
            width: 168,
            render: (item: IEntity[]) => (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {item.map((el) => (<span key={el.id}>{el.title}</span>))}
                </div>
            ),
            renderFilter: categoriesForFilter?.length > 0 ? () => (
                <SimpleFilter
                    items={categoriesForFilter}
                    onSelect={
                        (id: number, isChecked: boolean) => handlerSelectSimpleFilters(id, isChecked, setSelectedCategoriesId)
                    }
                />
            ) : null,
        },
        {
            id: 6,
            title: 'Подписчики ',
            dataIndex: 'subscribers',
            sortable: true,
            width: 160,
            render: (value) => (value?.toLocaleString('ru-RU') ?? '-'),
            filterModalParam: {
                width: 170,
            },
        },
        {
            id: 12,
            title: 'Просмотры',
            width: 300,
            headerColumnClasses: styles.headerCommon,
            subColumns: [
                {
                    id: 121,
                    title: 'План',
                    dataIndex: 'planViews',
                    sortable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    headerCellRender: () => (
                        <LongListTooltip
                            tooltipIconId="help-circle"
                            tooltipIconSize={18}
                            isPlan
                            tooltipTxt={getSentences(longlistHeaderCellsTooltips.views.plan)}
                        />
                    ),
                    render: (value, row) => (
                        <EditNumber
                            id={row?.id}
                            currentOpenId={currentOpenCellId}
                            cellId={`${row?.id}planViews`}
                            sourceId={row?.sourceId}
                            value={value ?? ''}
                            classesValue={styles.planCell}
                            onChange={handlerChangePlanViews}
                            onOpen={(id: string) => setOpenCellId(id)}
                            isEditable={stage === 2}
                        />
                    ),
                },
                // render: (value) => (<span>{Math.round(value ?? 0).toLocaleString('ru-RU') ?? '-'}</span>),
                {
                    id: 122,
                    title: 'Среднее',
                    dataIndex: 'viewsAvg',
                    sortable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    headerCellRender: () => (
                        <LongListTooltip
                            tooltipIconId="help-circle"
                            tooltipIconSize={18}
                            tooltipTxt={getSentences(longlistHeaderCellsTooltips.views.avg)}
                        />
                    ),
                    render: (value) => (
                        <span>
                            {/* {!value ? '-' : Math.round(value ?? 0).toLocaleString('ru-RU') ?? '-'} */}
                            {getRoundValue(value, 1).toLocaleString('ru-RU')}
                        </span>
                    ),
                },
            ],
        },
        {
            id: 13,
            title: 'ER',
            width: 300,
            headerColumnClasses: styles.headerCommon,
            subColumns: [
                {
                    id: 131,
                    title: 'План',
                    dataIndex: 'planEr',
                    sortable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    headerCellRender: () => (
                        <LongListTooltip
                            tooltipIconId="help-circle"
                            tooltipIconSize={18}
                            isPlan
                            tooltipTxt={getSentences(longlistHeaderCellsTooltips.ER.plan)}
                        />
                    ),
                    render: (value, record) => (
                        <EditNumber
                            id={record?.id}
                            currentOpenId={currentOpenCellId}
                            cellId={`${record?.id}planEr`}
                            sourceId={record?.sourceId}
                            value={`${getRoundValue(value, 100)}`}
                            unit="%"
                            classesValue={styles.planCell}
                            onChange={handlerChangePlanEr}
                            onOpen={(id: string) => setOpenCellId(id)}
                            isEditable={stage === 2}
                        />
                    ),
                },
                {
                    id: 132,
                    title: 'Среднее',
                    dataIndex: 'er',
                    sortable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    headerCellRender: () => (
                        <LongListTooltip
                            tooltipIconId="help-circle"
                            tooltipIconSize={18}
                            tooltipTxt={getSentences(longlistHeaderCellsTooltips.ER.avg)}
                        />
                    ),
                    render: (value) => getValueWithUnit(value, '%', 100),
                },
            ],
        },
        {
            id: 14,
            title: 'CPV',
            width: 300,
            headerColumnClasses: styles.headerCommon,
            subColumns: [
                {
                    id: 141,
                    title: 'План',
                    dataIndex: 'planCpv',
                    sortable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    headerCellRender: () => (
                        <LongListTooltip
                            tooltipIconId="help-circle"
                            tooltipIconSize={18}
                            isPlan
                            tooltipTxt={getSentences(longlistHeaderCellsTooltips.CPV.plan)}
                        />
                    ),
                    render: (value, row) => (
                        <EditNumber
                            id={row?.id}
                            currentOpenId={currentOpenCellId}
                            cellId={`${row?.id}planCpv`}
                            sourceId={row?.sourceId}
                            value={`${getRoundValue(value, 100)}`}
                            unit="руб."
                            classesValue={styles.planCell}
                            onChange={handlerChangePlanCpv}
                            onOpen={(id: string) => setOpenCellId(id)}
                            isEditable={stage === 2}
                        />
                    ),
                },
                {
                    id: 142,
                    title: 'Среднее',
                    dataIndex: 'cpv',
                    sortable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    headerCellRender: () => (
                        <LongListTooltip
                            tooltipIconId="help-circle"
                            tooltipIconSize={18}
                            tooltipTxt={getSentences(longlistHeaderCellsTooltips.CPV.avg)}
                        />
                    ),
                    render: (value, row) => (row.price
                        ? getValueWithUnit(value, 'руб.', 100)
                        : (
                            <Tooltip
                                content={(
                                    <div style={{ boxSizing: 'border-box', padding: '8px', width: '232px' }}>
                                        Для отображения данных заполните поле Цена
                                    </div>
                                )}
                                placement="right"
                            >
                                <div className={styles.icon_layout}><SvgIcon id="help-circle" size={18} /></div>
                            </Tooltip>
                        )),
                },
            ],
        },
        {
            id: 9,
            title: 'Цена ',
            dataIndex: 'price',
            sortable: true,
            width: 150,
            render: (value, row) => (
                <EditNumber
                    id={row?.id}
                    currentOpenId={currentOpenCellId}
                    cellId={`${row?.id}price`}
                    sourceId={row?.sourceId}
                    value={value ?? ''}
                    classesValue={classnames(styles.planCell, styles.colorWhite)}
                    onChange={handlerChangePrice}
                    onOpen={(id: string) => setOpenCellId(id)}
                    isEditable={stage === 2}
                />
            ),
        },
        {
            id: 10,
            title: 'Действия',
            dataIndex: 'actions',
            width: 105,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render: (_, row) => (
                <div
                    role="button"
                    className={styles.btnRemove}
                    onClick={() => handleClickOnRemoveSource(row)}
                >
                    <SvgIcon size={24} id="RemoveIcon" />
                </div>
            ),
        },
    ];

    const handleClickDownload = (values: IModalXlsxExportValue[]) => {
        const fields = values.reduce((acc, item) => {
            if (item.selected) {
                acc.push(item.value as SourceFieldsEnum);
            }
            return acc;
        }, []);
        downloadSources({
            id: projectId,
            set_type_id: 1,
            fields,
        });
        setModalExportVisible(false);
    };

    useEffect(() => {
        if (downloadedSourcesXlsx) {
            const hiddenElement = document.createElement('a');
            const url = window.URL || window.webkitURL;
            const blobPDF = url.createObjectURL(downloadedSourcesXlsx);
            hiddenElement.href = blobPDF;
            hiddenElement.target = '_blank';
            hiddenElement.download = `Лонглист ${getProjectNameForFile(projectName)} ${dateNow.toLocaleDateString('ru-RU')}.xlsx`;
            hiddenElement.click();
        }
    }, [downloadedSourcesXlsx, projectId, projectName]);

    /**
     * Все значения цен одинаковы
     */
    const allPricesAreSame = useMemo<boolean>(() => {
        if (rows?.length > 0) {
            return rows.every(({ price }) => rows[0]?.price === price);
        }
        return true;
    }, [rows]);

    const handleSort = useCallback((column, value) => {
        if (column?.dataIndex === 'price' && allPricesAreSame) {
            return;
        }
        const filtred = rows.sort((a, b) => {
            if (a[column?.dataIndex] > b[column?.dataIndex]) return 1;
            if (a[column?.dataIndex] < b[column?.dataIndex]) return -1;
            return 0;
        });
        setRows(value === 'desc' ? filtred.reverse() : filtred);
    }, [rows]);
    useEffect(() => {
        setRows(defaultSources);
    }, [defaultSources]);
    useEffect(() => {
        if (projectId && stage > 1) {
            loadSources({
                projectId,
                limit: 100,
                page: 1,
                set_type_id: 1,
                orderBy: 'subscribers',
                orderDirection: SortOrder.desc,
                filterParams: null,
                rangeParams: null,
            });
        }
    }, [projectId, stage]);
    useEffect(() => {
        setRows(
            selectedAuthorsId?.length > 0
                ? defaultSources?.filter((item) => selectedAuthorsId.includes(item.authorId))
                : defaultSources,
        );
    }, [selectedAuthorsId, defaultSources]);
    useEffect(() => {
        setRows(
            selectedCategoriesId?.length > 0
                ? defaultSources?.filter(
                    (item) => selectedCategoriesId.some(
                        (ctgId) => item.category.map(({ id }) => id).includes(ctgId),
                    ),
                )
                : defaultSources,
        );
    }, [selectedCategoriesId, defaultSources]);

    /**
     * используется для проверки на отображение линка на сохранённый поиск
     * если он пустой то линк не показываем
     */
    useEffect(() => {
        if (projectId > 0) {
            getSavedFilters({ projectId });
        }
    }, [projectId]);

    const handlerApplyAddMediaPlan = (start: Date, end: Date) => {
        onCreateMediaPlan(start, end, sourcesLongList?.sources || []);
    };
    const handleRemoveSource = () => {
        removeSource({ project_id: projectId, source_id: modalRemoveSource.id }).then((res) => {
            if ((res as any).isError || (res as any)?.error) {
                error('При удалении источника из лонглиста произошла ошибка');
                return;
            }
            success('Источник успешно удален из лонглиста');
        })
            .catch(() => {
                error('При удалении источника из лонглиста произошла ошибка');
            });
    };
    const getColumns = () => {
        if (stage === 2) return columns;
        return columns.filter(({ dataIndex }) => dataIndex !== 'actions');
    };
    if (stage < 2) return null;
    if (!sourcesLongList?.sources?.length && stage === 2) {
        return (
            <>
                {contextHolder}
                <div className={styles.longlistControlContainer}>
                    <Button
                        text="Создать лонглист"
                        type="default"
                        size="large"
                        onClick={() => setSourcesListState(true)}
                    />
                </div>
                <AddMediaPlan
                    isOpen={addMediaPlanIsOpen}
                    onClose={() => setMediaPlanModalState(false)}
                    onApply={handlerApplyAddMediaPlan}
                />
                <SourcesForProject
                    isOpen={addSourcesIsOpen}
                    projectId={projectId}
                    sources={sourcesLongList}
                    isLoadingPlatforms={isLoadingPlatforms}
                    isLoadingCategories={isLoadingCategories}
                    platforms={platforms}
                    categories={categories}
                    onClose={() => setSourcesListState(false)}
                    onGetSources={() => null}
                />
            </>
        );
    }
    return (
        <>
            {contextHolder}
            <DeleteModal
                style={{ maxWidth: 430 }}
                isOpen={!!modalRemoveSource}
                title="Удаление источника"
                textContent="Подтвердить удаление источника из лонглиста"
                textButtonApply="Подтвердить"
                textButtonCancel="Отменить"
                typeButtonCancel="secondaryDanger"
                onClose={() => setModalRemoveSource(null)}
                onApply={handleRemoveSource}
            />
            <AddMediaPlan
                isOpen={addMediaPlanIsOpen}
                onClose={() => setMediaPlanModalState(false)}
                onApply={handlerApplyAddMediaPlan}
            />
            <SourcesForProject
                isOpen={addSourcesIsOpen}
                projectId={projectId}
                sources={sourcesLongList}
                isLoadingPlatforms={isLoadingPlatforms}
                isLoadingCategories={isLoadingCategories}
                platforms={platforms}
                categories={categories}
                onClose={() => setSourcesListState(false)}
                onGetSources={() => null}
            />
            <DefaultWrapperProject style={{ marginTop: 16 }}>
                <div className={styles.longList_root}>
                    <div className={styles.longList_header}>
                        <span className={styles.longList_header_title}>Лонглист</span>
                        {
                            !savedFiltersIsAbsent && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
                                    <button
                                        type="button"
                                        className={styles.longList_header_saveSearch}
                                        onClick={() => setShowSavedSearchState(true)}
                                    >
                                        Сохраненный поиск
                                    </button>
                                </div>
                            )
                        }
                        <div className={styles.longList_header_actions}>
                            {
                                stage > 1 && !isOpen && (
                                    <IconButtonWithLabel
                                        iconSize={16}
                                        iconId="Download"
                                        label="Скачать"
                                        onClick={() => setModalExportVisible(true)}
                                        labelStyle={{ color: '#0EBFA1' }}
                                    />
                                )
                            }
                            <div
                                role="button"
                                className={`${styles.longList_controller} ${isOpen ? styles.close : styles.open}`}
                                onClick={() => setOpen(!isOpen)}
                            >
                                <SvgIcon size={16} id="dropdownArrow" />
                            </div>
                        </div>
                    </div>
                    {isOpen
                    && (
                        <>
                            <div className={styles.longList_tableWrapper}>
                                <Table
                                    columns={getColumns()}
                                    rows={rows}
                                    isLoading={isLoadingSources}
                                    onSearch={() => null}
                                    onSort={handleSort}
                                    onSelect={() => null}
                                    className={styles.longList_table}
                                />
                            </div>
                            <div className={styles.longList_footer}>
                                {
                                    stage > 1 && (
                                        <IconButtonWithLabel
                                            iconSize={16}
                                            iconId="Download"
                                            label="Скачать"
                                            onClick={() => setModalExportVisible(true)}
                                            labelStyle={{ color: '#0EBFA1' }}
                                        />
                                    )
                                }
                                {
                                    stage === 2 && (
                                        <IconButtonWithLabel
                                            iconSize={31}
                                            iconId="RoundAdd_Icn"
                                            label="Добавить в лонглист"
                                            onClick={openAdd}
                                            labelStyle={{ color: '#0EBFA1' }}
                                        />
                                    )
                                }
                                {
                                    stage === 2 && (
                                        <Button
                                            text="Сформировать медиаплан"
                                            type="default"
                                            size="large"
                                            disabled={isLoadingMediaPlan}
                                            onClick={() => setMediaPlanModalState(true)}
                                        />
                                    )
                                }
                            </div>
                        </>
                    )}
                    <Sidebar
                        title="Сохранённый поиск"
                        visible={showSavedSearch}
                        onClose={() => setShowSavedSearchState(false)}
                    >
                        <SavedFilters
                            projectId={projectId}
                            platformsFromApi={platforms}
                            visible={showSavedSearch}
                            onCancel={() => setShowSavedSearchState(false)}
                            onRepeatSearch={openAdd}
                        />
                    </Sidebar>
                    <ModalXlsxExport
                        visible={modalExportVisible}
                        setVisible={setModalExportVisible}
                        values={modalExportValues}
                        setValues={updateModalExportValues}
                        onClickSubmit={() => handleClickDownload(modalExportValues)}
                    />
                </div>
            </DefaultWrapperProject>
        </>
    );
};

export default LongList;
