import { rtkQApi } from '@services/index';
import {
    IQPCreateMediaPlan,
    IQPDownloadSources,
    IQPProjectSources,
} from '@services/projects/types/longlist/queryParams';
import { ISource, ISourceRow, SortOrder } from '@services/sources/types';
import { transformSourcesResponse } from '@services/sources/transformers/getSources';
import { TProjectMonitoring } from './types/models';
import { TQueryParamsCreateMonitoring } from './types/queryParams';

export const projectMediaPlanRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postAddSourcesInMediaPlan: build.mutation<void, IQPCreateMediaPlan>({
            query: (params) => ({
                url: `media_projects/project/${params.projectId}/make_mediaplan`,
                method: 'POST',
                body: {
                    sources: params.sources.map((item: ISourceRow) => ({
                        id: item.id,
                        source_id: item.sourceId,
                        title: item?.title,
                        link: item?.link,
                        type_id: item?.sourceType?.id,
                        type_title: item?.sourceType?.title,
                        author_id: item?.author?.id,
                        author_title: item?.author?.title,
                        is_blogger: 'True',
                        category_ids: item?.categories?.map(({ id }) => id) ?? [],
                        category_titles: item?.categories?.map(({ title }) => title) ?? [],
                        subscribers: item?.subscribers ?? 0,
                        er_avg: item?.erAvg,
                        views_avg: item?.viewsAvg,
                        err: 0,
                        err24: 0,
                        price: item?.price,
                        content: '',
                        integration_link: null,
                        start_date: item?.startDate?.toISOString() ?? null,
                        end_date: item?.endDate?.toISOString() ?? null,
                        plan_cpv: item?.planCpv,
                        plan_er: item?.planEr,
                        plan_views: item?.planViews,
                    })),
                },
            }),
            invalidatesTags: ['LongLists', 'ProjectStatus'],
        }),
        postLoadSourcesForMediaPlan: build.query<ISource, IQPProjectSources>({
            query: (params) => ({
                url: `media_projects/project/${params.projectId}/get_sources`,
                method: 'POST',
                body: {
                    page: params.page,
                    limit: params.limit,
                    order_by: params.orderBy,
                    order_direction: SortOrder[params.orderDirection],
                    filter_params: null,
                },
            }),
            transformResponse: transformSourcesResponse,
            providesTags: ['LongLists'],
        }),
        postStartMonitoring: build.mutation<TProjectMonitoring, TQueryParamsCreateMonitoring>({
            query: ({
                projectId, parse_from, sources, include_words, project_name,
            }) => ({
                url: `media_projects/project/${projectId}/monitoring`,
                method: 'POST',
                body: {
                    name: `Кампания по проекту ${project_name}`,
                    parse_from,
                    criteria: {
                        is_complex_query: false,
                        collect_comments: false,
                        include_words,
                        sources,
                    },
                },
            }),
        }),
        getMonitoring: build.query<TProjectMonitoring, number>({
            query: (projectId) => ({
                url: `media_projects/project/${projectId}/monitoring`,
                method: 'GET',
            }),
        }),
        postDownloadSources: build.query<Blob, IQPDownloadSources>({
            query: (params) => ({
                url: `media_projects/project/${params.id}/download_project_sources`,
                method: 'POST',
                body: {
                    fields: params.fields,
                },
                responseHandler: ((response) => response.blob()),
                cache: 'no-cache',
            }),
        }),
        // /api/v2/media_projects/project/{project_id}/monitoring
    }),
});
