export enum SourcesFiltersTypes {
    platforms = 'type_id',
    subscribers = 'subscribers',
    authors = 'author_id',
    categories = 'category_id',
    er = 'er_avg',
    err = 'err',
    err24 = 'err24',
    views = 'views_avg',
    title = 'title',
}
