const COLOR_PALETE = [
    '#5b8ff9',
    '#5ad8a6',
    '#5e7092',
    '#f6bd18',
    '#6f5efa',
    '#6ec8ec',
    '#945fb9',
    '#ff9845',
    '#299796',
    '#fe99c3',
    '#47ca8d',
    '#af8f72',
    '#691010',
    '#2e22d6',
    '#d17878',
    '#cd5af0',
];

export default {
    COLOR_PALETE,
};

export const AUTH_COOKIE_NAME = 'Authorization';
export const ALL_REGIONS = 'Все регионы';

export const EXCLUDE_CATEGORIES = [
    'волонтерство',
    'гаданиие',
    'мистика',
    'насилие над животными',
    'психология личности',
    'суицид',
    'ufoлогия',
    'уфология',
    'контент с издевательствами над животными',
    'контент с насилием и убийством животных',
    'оправдание браконьерства',
    'мотивация к издевательству над животными',
    'торговля охраняемыми видами животных',
    'описание способов ухода из жизни',
    'мотивация к суициду',
    'рассказ о группах про суицид',
    'рассказ об опасных или суицид-ориентированных квестах и испытаниях',
    'оправдание суицида',
    'вызовы и поведенческие модели в различные периоды жизни',
    'личные вызовы и рекомендации по управлению жизненными целями',
    'кризисные ситуации и способы выхода из них',
    'профессиональные психология',
    'социальные, культурные и поведенческие особенности поведения представителей различных поколений в обществе',
    'коучинг и личностный рост',
    'помощь инвалидам',
    'помощь сиротам',
    'помощь тяжело больным и неизлечимо больным',
    'помощь пожилым без родственников',
    'помощь животным',
    'очистка окружающей среды',
    'гадание',
    'заговоры',
    'астрология',
    'хиромантия',
    'таро',
    'руны',
    'ворожба',
    'книга перемен',
    'оккультизм',
    'легенды и их историческая подоплека',
    'мифология',
    'полтергейст',
    'оккультизм',
    'загадочные события',
    'загадочные предметы',
    'загадочные существа',
    'аномалии в природе',
    'фэншуй',
];
