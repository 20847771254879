import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISimpleEntity } from 'store/types';

export interface ICampaignCommonState {
    leftMenuActiveCampaignId: number | null;
    leftMenuActiveCampaign: ISimpleEntity | null;
}

const initialState: ICampaignCommonState = {
    leftMenuActiveCampaignId: null,
    leftMenuActiveCampaign: null,
};

const { actions: campaignCommonActions, reducer } = createSlice({
    name: 'campaignCommon',
    initialState,
    reducers: {
        setLeftMenuCampaignId: (state: ICampaignCommonState, { payload }: PayloadAction<number>): void => {
            state.leftMenuActiveCampaignId = payload;
        },
        setLeftMenuCampaign: (state: ICampaignCommonState, { payload }: PayloadAction<ISimpleEntity>): void => {
            state.leftMenuActiveCampaign = payload;
        },
    },
});

export { campaignCommonActions };
export default reducer;
