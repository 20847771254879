import React, { useState } from 'react';
import { SvgIcon } from '@shared/index';
import { Popover } from 'antd';
import styles from './styles.module.scss';
import DropDownControls from '../DropDownControls';

interface IItemSelcet {
    id: number,
    label: string,
    isSelect: boolean
}
interface IPropsActionCell {
    sourceId: number,
    isFavorits: boolean,
    listsSources: IItemSelcet[]
    onSetSourceInList: (listId: number[], sourceId: number) => void;
    onCreateList: (sourceId: number) => void;
    onChangeStatusFavorite: (sourceId: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActionCell: React.FC<IPropsActionCell> = ({
    sourceId, isFavorits, listsSources, onSetSourceInList, onCreateList, onChangeStatusFavorite,
}): JSX.Element => {
    const [isOpenMenu, setIsOpenMenu] = useState(() => false);
    const handleCreateList = () => {
        setIsOpenMenu(false);
        onCreateList(sourceId);
    };
    const handleSetSourceInLists = (listId) => {
        setIsOpenMenu(false);
        onSetSourceInList(listId, sourceId);
    };
    return (
        <div className={styles.actionCell}>
            <div
                role="button"
                className={`${isFavorits ? styles.active : ''} ${styles.favoritBtn}`}
                onClick={() => onChangeStatusFavorite(sourceId)}
            >
                <SvgIcon id="FavoriteIcon_New" size={28} />
            </div>
            <Popover
                content={(
                    <DropDownControls
                        key={`${JSON.stringify(listsSources)}_source_${sourceId}`}
                        lists={[...listsSources]}
                        onSetSourceInList={handleSetSourceInLists}
                        onCreateList={handleCreateList}
                    />
                )}
                trigger="click"
                visible={isOpenMenu}
                onVisibleChange={setIsOpenMenu}
                overlayClassName={styles.tooltipOverlay}
                placement="bottomRight"
                align={{ offset: [-10, -8] }}
                showArrow={false}
                overlayInnerStyle={{ borderRadius: 6, overflow: 'hidden', padding: 0 }}
            >
                <div className={styles.menuBtn}>
                    <span />
                </div>
            </Popover>
        </div>
    );
};

export default ActionCell;
