import { rtkQApi } from '../..';
import {
    IQueryParamsDeleteUserTag, IQueryParamsLoadTags, ITagFromApi, ITagModel,
} from './types';

export const campaignTagsRtkQService = rtkQApi.injectEndpoints({
    endpoints: (build) => ({
        postLoadTags: build.query<ITagModel[], IQueryParamsLoadTags>({
            query: (params) => ({
                url: 'campaign/summary_data/tags',
                method: 'POST',
                body: params,
            }),
            transformResponse: (
                baseQueryResponse: ITagFromApi[],
            ): ITagModel[] => baseQueryResponse.map(
                (item) => ({
                    postsCount: item.posts_count,
                    likes: item.likes ?? 0,
                    views: item.views ?? 0,
                    comments: item.comments ?? 0,
                    percent: item.percent,
                    reactions: item.reactions ?? 0,
                    title: item.title,
                } as ITagModel),
            ),
        }),
        postLoadUserTags: build.query<ITagModel[], IQueryParamsLoadTags>({
            query: (params) => ({
                url: 'campaign/summary_data/user_tags',
                method: 'POST',
                body: params,
            }),
            transformResponse: (
                baseQueryResponse: ITagFromApi[],
            ): ITagModel[] => baseQueryResponse.map(
                (item) => ({
                    postsCount: item.posts_count,
                    likes: item.likes ?? 0,
                    views: item.views ?? 0,
                    comments: item.comments ?? 0,
                    percent: item.percent,
                    reactions: item.reactions ?? 0,
                    title: item.title,
                    manual: item.manual,
                    id: item.id,
                } as ITagModel),
            ),
        }),
        deleteUserTag: build.query<void, IQueryParamsDeleteUserTag>({
            query: (params) => ({
                url: `campaign/user_tag/${params.id}?campaign_id=${params.campaign_id}`,
                method: 'DELETE',
            }),
        }),
    }),
});
