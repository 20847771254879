import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampaignDataModel } from 'services/campaigns/campaigns-list/types';
import { FetchStatus } from '../../types';
import { IFilter } from '../../../sections/MyCampaignContainer/CampaingTypes';

export interface ISimplePeriod {
    start: Date;
    end: Date;
}

export interface IComparisonFilters {
    filtersCategory: string;
    totalFiltersCount: number;
    filters: IFilter[];
}

export interface IFilterCampaigns {
    campaignId: number;
    filters: IComparisonFilters[];
}

export interface ISummaryData {
    quantityAuthors: number;
    quantityBloggerPost: number;
    quantityComments: number;
    quantityLikes: number;
    quantityPost: number;
    quantityReactions: number;
    quantitySmiPost: number;
    quantityViews: number;
}

export interface IComparisonSummaryData {
    campaignId: number;
    summary: ISummaryData;
}

export interface ICampaignComparisonState {
    comparisonPeriod: ISimplePeriod | null;
    comparisonCanView: boolean;
    isCompare: boolean;
    isSavedAnalysis: boolean;
    isSelectPeriod: boolean;
    referenceCampaign: ICampaignDataModel | null;
    compareCampaigns: ICampaignDataModel[];
    comparisonFilters: IFilterCampaigns[];
    comparisonSummary: IComparisonSummaryData[];

    filtersFetchStatus: FetchStatus;
    summaryFetchStatus: FetchStatus;
    fetchErrors: string;
    campaignsIdsForFetchAgg: number[];
    campaignsIdsForFetchFilters: number[];
}

const initialState: ICampaignComparisonState = {
    comparisonPeriod: null,
    comparisonCanView: false,
    isCompare: false,
    isSavedAnalysis: false,
    isSelectPeriod: false,
    referenceCampaign: null,
    compareCampaigns: [],
    comparisonFilters: [],
    comparisonSummary: [],
    filtersFetchStatus: FetchStatus.undefined,
    fetchErrors: '',
    summaryFetchStatus: FetchStatus.undefined,
    campaignsIdsForFetchAgg: [],
    campaignsIdsForFetchFilters: [],
};

const { actions: campaignComparisonActions, reducer } = createSlice({
    name: 'comparisonCommon',
    initialState,
    reducers: {
        setCampaignsIdsForFetchFilters: (state: ICampaignComparisonState, { payload }: PayloadAction<number[]>): void => {
            state.campaignsIdsForFetchFilters = payload;
        },
        removeIdForFetchFilters: (state: ICampaignComparisonState, { payload }: PayloadAction<number>): void => {
            const buffer = state.campaignsIdsForFetchFilters.filter((id) => id !== payload);
            state.campaignsIdsForFetchFilters = buffer;
            if (buffer.length <= 0) {
                state.filtersFetchStatus = FetchStatus.success;
            }
        },
        setCampaignsIdsForFetchAgg: (state: ICampaignComparisonState, { payload }: PayloadAction<number[]>): void => {
            state.campaignsIdsForFetchAgg = payload;
        },
        removeIdForFetchAgg: (state: ICampaignComparisonState, { payload }: PayloadAction<number>): void => {
            const buffer = state.campaignsIdsForFetchAgg.filter((id) => id !== payload);
            state.campaignsIdsForFetchAgg = buffer;
            if (buffer.length <= 0) {
                state.summaryFetchStatus = FetchStatus.success;
            }
        },
        setCompareEnable: (state: ICampaignComparisonState): void => {
            state.isCompare = true;
        },
        setCompareDisable: (state: ICampaignComparisonState): void => {
            state.isCompare = false;
        },
        setSavedAnalysisEnable: (state: ICampaignComparisonState): void => {
            state.isSavedAnalysis = true;
        },
        setSavedAnalysisDisable: (state: ICampaignComparisonState): void => {
            state.isSavedAnalysis = false;
        },
        setReferenceCampaign: (state: ICampaignComparisonState, { payload }: PayloadAction<ICampaignDataModel>): void => {
            state.referenceCampaign = payload;
        },
        clearReferenceCampaign: (state: ICampaignComparisonState): void => {
            state.referenceCampaign = null;
        },
        setCampaignForCompare: (state: ICampaignComparisonState, { payload }: PayloadAction<ICampaignDataModel[]>): void => {
            if (!payload) {
                return;
            }
            state.compareCampaigns = payload.slice(0, 4);
        },
        addCampaignForCompare: (state: ICampaignComparisonState, { payload }: PayloadAction<ICampaignDataModel>): void => {
            if (!payload || state.compareCampaigns.length > 3) {
                return;
            }
            state.compareCampaigns = [...state.compareCampaigns, payload];
        },
        removeCampaignForCompare: (state: ICampaignComparisonState, { payload }: PayloadAction<number>): void => {
            if (!payload) {
                return;
            }
            state.compareCampaigns = state.compareCampaigns.filter(({ id }) => id !== payload);
        },
        setSelectPeriodState: (state: ICampaignComparisonState, { payload }: PayloadAction<boolean>): void => {
            state.isSelectPeriod = payload;
        },
        setComparisonPeriod: (state: ICampaignComparisonState, { payload }: PayloadAction<ISimplePeriod>): void => {
            if (!payload?.start || !payload?.end) {
                return;
            }
            state.comparisonPeriod = payload;
        },
        setComparisonCanView: (state: ICampaignComparisonState, { payload }: PayloadAction<boolean>): void => {
            state.comparisonCanView = payload;
        },
        setFiltersLoadingStatus: (state: ICampaignComparisonState): void => {
            state.filtersFetchStatus = FetchStatus.loading;
        },
        setFiltersFailedStatus: (state: ICampaignComparisonState): void => {
            state.filtersFetchStatus = FetchStatus.failed;
        },
        setFiltersSuccessStatus: (state: ICampaignComparisonState): void => {
            state.filtersFetchStatus = FetchStatus.success;
        },
        setSummaryLoadingStatus: (state: ICampaignComparisonState): void => {
            state.summaryFetchStatus = FetchStatus.loading;
        },
        setSummaryFailedStatus: (state: ICampaignComparisonState): void => {
            state.summaryFetchStatus = FetchStatus.failed;
        },
        setSummarySuccessStatus: (state: ICampaignComparisonState): void => {
            state.summaryFetchStatus = FetchStatus.success;
        },
        setFetchErrors: (state: ICampaignComparisonState, { payload }: PayloadAction<string>): void => {
            state.fetchErrors = payload;
        },
        cleanSummaryData: (state: ICampaignComparisonState): void => {
            state.comparisonSummary = [];
        },
        cleanFiltersData: (state: ICampaignComparisonState): void => {
            state.comparisonFilters = [];
        },
        addSummaryData: (state: ICampaignComparisonState, { payload }: PayloadAction<{ data: ISummaryData, campaignId: number }>): void => {
            const { campaignId, data } = payload;
            if (!data || !campaignId || state.comparisonSummary.some(({ campaignId: id }) => id === campaignId)) {
                return;
            }
            state.comparisonSummary.push({
                campaignId,
                summary: data,
            });
        },
        addSummaryDataFromApi: (state: ICampaignComparisonState, { payload }: PayloadAction<{ data: any, campaignId: number }>): void => {
            const { campaignId, data } = payload;
            if (!data || !campaignId || state.comparisonSummary.some(({ campaignId: id }) => id === campaignId)) {
                return;
            }
            state.comparisonSummary.push({
                campaignId,
                summary: {
                    quantityAuthors: data?.count_authors,
                    quantityBloggerPost: data?.count_blogger_post,
                    quantityComments: data?.count_comments,
                    quantityLikes: data?.count_likes,
                    quantityPost: data?.count_post,
                    quantitySmiPost: data?.count_smi_post,
                    quantityViews: data?.count_views,
                    quantityReactions: data?.count_reactions,
                } as ISummaryData,
            });
        },
        addFilters: (state: ICampaignComparisonState, { payload }: PayloadAction<IFilterCampaigns>): void => {
            const { campaignId, filters: data } = payload;
            if (!Array.isArray(data)) {
                state.comparisonFilters.push({ campaignId, filters: [] });
            } else {
                state.comparisonFilters.push({
                    campaignId,
                    filters: data,
                });
            }
        },
        addFiltersFromApi: (state: ICampaignComparisonState, { payload }: PayloadAction<{ data: unknown, campaignId: number }>): void => {
            const { campaignId, data } = payload;
            if (!Array.isArray(data)) {
                state.comparisonFilters.push({ campaignId, filters: [] });
            } else {
                state.comparisonFilters.push({
                    campaignId,
                    filters: data.map((item) => ({
                        filtersCategory: item?.filters_category,
                        totalFiltersCount: item?.total_filters_count,
                        filters: item?.filters?.map((filter) => ({
                            checked: filter?.checked,
                            filter_name: filter?.filter_name,
                            count: filter?.count,
                        } as IFilter)),
                    } as IComparisonFilters)),
                });
            }
        },
        clearAll: () => ({ ...initialState }),
    },
});

export { campaignComparisonActions };
export default reducer;
